import { AuthControllerApiFactory, UserControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import type { UserRole } from ".";
import { useUserStore } from ".";

export async function login(credentials: { email: string; password: string } | { token: string }): Promise<void> {
	const authApi = getApiGen(AuthControllerApiFactory);
	const userApi = getApiGen(UserControllerApiFactory);
	let email: string | undefined;
	let access_token: string | undefined;

	let userInfo;
	if ("email" in credentials) {
		access_token = (await authApi.login(credentials)).data.access_token;
		if (!access_token) {
			throw new Error("missing access token");
		}
		email = credentials.email;
		const { data } = await userApi.infoUser({
			headers: {
				Authorization: "Bearer " + access_token,
			},
		});
		userInfo = data;
	} else {
		const { data } = await userApi.infoUser({
			headers: {
				Authorization: "Bearer " + credentials.token,
			},
		});
		userInfo = data;
		access_token = credentials.token;
		email = userInfo.email;
		if (!email) {
			throw new Error("missing email");
		}
	}

	useUserStore.getState().set({
		id: userInfo.id!,
		email,
		signedIn: true,
		token: access_token,
		availableServices: userInfo.services || [],
		roles: (userInfo.roles || []) as UserRole[],
		impersonating: false,
		customFields: userInfo.customFields,
		name: userInfo.name,
		surname: userInfo.surname,
		customerName: userInfo.customerName ?? "",
		importFormats: userInfo.importFormats,
		exportFormats: userInfo.exportFormats,
		importEnabled: userInfo.importEnabled,
		exportEnabled: userInfo.exportEnabled,
		automaticImport: userInfo.importEnabled,
		customerId: userInfo.customerId,
	});
}
