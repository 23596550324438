import type { ReactNode } from "react";
import type { Control, FormState, Path } from "react-hook-form";
import type { NotificationMap } from "../map";
import { FormFields } from "$root/ui-lib/form/FormFields";

type NotificationCheckboxProps = {
	children?: string;
	value: boolean | "indeterminate";
	control: Control<NotificationMap, any>;
	formState: FormState<NotificationMap>;
	name: Path<NotificationMap>;
};

type NotificationBoxProps = {
	infoGraph: ReactNode;
	children: ReactNode;
	checkbox: NotificationCheckboxProps;
};

export function NotificationBox(props: NotificationBoxProps): JSX.Element {
	return (
		<div className="flex gap-4">
			<div>{props.infoGraph}</div>
			<div>
				<FormFields.Checkbox
					classList="text-base mb-2 font-semibold"
					control={props.checkbox.control}
					formState={props.checkbox.formState}
					name={props.checkbox.name}
				>
					{props.checkbox.children}
				</FormFields.Checkbox>
				<div className="ml-8">
					<div>{props.children}</div>
				</div>
			</div>
		</div>
	);
}
