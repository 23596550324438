import type { ReactNode } from "react";
import { useRef } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button, Dialog, DialogFooter, DialogHeader, Icon } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useTranslation } from "react-i18next";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";

export type LeavePromptProps = {
	when: boolean;
	title: ReactNode;
	children: ReactNode;
	pathToNotBlock?: string[];
	showCancelIcon?: boolean;
	variant?: "info" | "warning" | "error";
};

function _LeavePrompt({ children, title, variant, showCancelIcon = true, ...forward }: LeavePromptProps): JSX.Element {
	const icon = useMemo(
		() =>
			({
				info: <Icon icon="Icon-full-info" color={themeCSSVars.MessageSeverity_success} size={22} />,
				warning: <Icon icon="Icon-full-alert" color={themeCSSVars.MessageSeverity_warning} size={22} />,
				error: (
					<Icon icon="Icon-full-info" color={themeCSSVars.MessageSeverity_error} size={22} classList="rotate-180" />
				),
				none: undefined,
			})[variant ?? "warning"],
		[variant],
	);

	const { t } = useTranslation();

	return (
		<LeavePromptBase {...forward}>
			{({ onCancelNavigation, onContinueNavigation, showPrompt }) => (
				<Dialog
					show={showPrompt}
					onClose={showCancelIcon ? onCancelNavigation : undefined}
					header={<DialogHeader icon={icon}>{title}</DialogHeader>}
					footer={
						<DialogFooter
							primaryAction={
								<Button onClick={onContinueNavigation} palette="primary">
									{t("LEAVE")}
								</Button>
							}
							neutralAction={
								<Button onClick={onCancelNavigation} palette="tertiary">
									{t("STAY")}
								</Button>
							}
						/>
					}
				>
					{children}
				</Dialog>
			)}
		</LeavePromptBase>
	);
}

export const LeavePrompt = React.memo(_LeavePrompt) as typeof _LeavePrompt;

export type LeavePromptBaseProps = {
	when: boolean;
	// compared with startsWith
	pathToNotBlock?: string[];
	children: NodeOrFn<{
		showPrompt: boolean;
		onCancelNavigation(): void;
		onContinueNavigation(): void;
	}>;
};

export function LeavePromptBase({ children, when, pathToNotBlock }: LeavePromptBaseProps): JSX.Element {
	const history = useHistory();
	const historyRef = useRef(history);

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState("");
	const [currentSearch, setCurrentSearch] = useState("");
	const [currentState, setCurrentState] = useState<unknown>({});

	const unregisterPromptRef = useRef<null | (() => void)>(null);
	useEffect(() => {
		if (when) {
			unregisterPromptRef.current = historyRef.current.block((prompt) => {
				if (
					pathToNotBlock &&
					pathToNotBlock.some((p) => `${prompt.pathname}${prompt.search}${prompt.hash}`.startsWith(p))
				) {
					setCurrentPath(prompt.pathname);
					setCurrentSearch(prompt.search);
					setShowPrompt(false);

					unregisterPromptRef.current?.();
					unregisterPromptRef.current = null;
					return;
				}
				setCurrentPath(prompt.pathname);
				setCurrentSearch(prompt.search);
				setCurrentState(prompt.state);
				setShowPrompt(true);
				return "true";
			});
		} else {
			unregisterPromptRef.current?.();
			unregisterPromptRef.current = null;
		}

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps

			unregisterPromptRef.current?.();
			unregisterPromptRef.current = null;
		};
	}, [pathToNotBlock, when]);

	const handleOK = useCallback(() => {
		setShowPrompt(false);

		unregisterPromptRef.current?.();
		unregisterPromptRef.current = null;
		historyRef.current.push(currentPath + currentSearch, currentState);
	}, [currentPath, currentSearch, currentState]);

	const handleCancel = useCallback(() => {
		setShowPrompt(false);
	}, []);

	return (
		<>
			{renderNodeOrFn(children, {
				showPrompt,
				onCancelNavigation: handleCancel,
				onContinueNavigation: handleOK,
			})}
		</>
	);
}
