import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import WaterfallChart from "../../../ui-lib/charts/WaterfallChart";
import { useApiGen } from "$root/api/hooks";
import { InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { Select } from "@mdotm/mdotui/components";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { HorizonType, horizonOptions } from "../const";

const Attribution = (props: ContextContent<typeof PortfolioContext>) => {
	const { t } = useTranslation();
	const uuid = props.portfolio?.uuid;
	const benchmarkId = props.selectedBenchmark;
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const [horizon, setHorizon] = useState<HorizonType>(horizonOptions[0].value);

	const query = useQueryNoRefetch(
		[
			"Attribution",
			uuid,
			horizon,
			benchmarkId,
			props.portfolio?.status,
			props.reportExcutionCounter,
		],
		{
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Attribution"),
					};
				}

				const realizedPerformanceAttribution = await axiosExtract(
					investmentReportsApi.getRealizedPerformanceAttribution(uuid, benchmarkId, horizon),
				);

				if (!realizedPerformanceAttribution.current) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Attribution"),
					};
				}

				const computedSeries = realizedPerformanceAttribution.current.map((el) => ({
					label: `<div class="h-[40px] flex flex-col text-end"><p><strong>${el.assetClass}</strong></p><p>${el.type}</p></div>`,
					tooltipTitle: `${el.assetClass} - ${el.type}`,
					value: el.value ?? 0,
				}));

				return {
					data: computedSeries,
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	useWidgetOptions(
		() => ({
			actionHeader: <InfoTooltip>{t("PORTFOLIO_ATTRIBUTION.TOOLTIP")}</InfoTooltip>,
			title: t("PORTFOLIO_ATTRIBUTION.TITLE"),
		}),
		[t],
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(realizedPerformanceAttribution) => (
				<div className="flex ">
					<div className="w-2/3">
						<div className="flex justify-end mb-4">
							<Select value={horizon} onChange={setHorizon} options={horizonOptions} />
						</div>
						<WaterfallChart dataSeries={realizedPerformanceAttribution} height="360px" />
					</div>
					<div className="w-1/3 px-12">
						<p className="mb-2">
							The <strong>allocation effect</strong> refers to the returns generated by allocating portfolio weights to
							specific asset classes. It determines whether taking active positions in a particular segment against the
							benchmark was a contributor or a detractor to the overall portfolio performance.
						</p>
						<p className="mb-2">
							The <strong>selection effect</strong> refers to the impact of the selection of specific stocks within a
							segment on the portfolio’s overall return. The weight of the segment in the portfolio determines the size
							of the effect—the larger the segment, the larger the effect is.
						</p>
						<p className="mb-2">
							The <strong>interaction effect</strong> captures the value added that is not attributable solely to the
							asset allocation and stock selection decisions. It is positive when outperformance is overweighted and
							when underperformance is underweighted.
						</p>
					</div>
				</div>
			)}
		</PortfolioQueryWidgetBase>
	);
};

export default withContext(PortfolioContext)(Attribution);
