import type { InvestmentExposureEntry, InvestmentExposureResponseExposureTypeEnum } from "$root/api/api-gen";
import { sumArrayLike } from "$root/utils/collections";
import type { ExposureChartData } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ExposureChart, exposureCategoryInfo } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, objectMap } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";
import CompositionList from "./CompositionList";
import { exposureAvailableHeightForItemSlotsByChartHeight, exposureChartHeight } from "./exposure-shared";

type ExposureProps =
	| CommonSetUpReportProps<UnionMapData["portfolio-details"]>
	| CommonSetUpReportProps<UnionMapData["portfolio-enhanced"]>
	| CommonSetUpReportProps<UnionMapData["portfolio-reference"]>;

export function exposureEntriesToSplittableProps(
	composition: InvestmentExposureEntry[],
): Array<
	| { type: "title"; label: string; groupName: string }
	| { type: "entry"; label: string; weight: number; groupName: string; netLong: boolean }
	| { type: "footer"; label: string; weight: number; groupName: string }
> {
	const groups: string[] = [];
	for (const { firstQualityLevel } of composition) {
		if (!groups.includes(firstQualityLevel!)) {
			groups.push(firstQualityLevel!);
		}
	}

	return composition.length === 0
		? []
		: Array.from(
				Object.values(
					objectMap(
						groupBy(composition, (entry) => entry.firstQualityLevel!),
						(entries) => [
							{
								type: "title",
								label: entries?.at(0)?.firstQualityLevel ?? "",
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
							...(entries ?? []).map(
								(x) =>
									({
										type: "entry",
										label: x.secondQualityLevel ?? "",
										weight: x.weight!,
										netLong: x.netLong ?? true,
										groupName: entries?.at(0)?.firstQualityLevel ?? "",
									}) as const,
							),
							{
								type: "footer",
								label: "",
								weight: sumArrayLike(entries ?? [], (x) => x.weight!),
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
						],
					),
				),
		  ).flat();
}

const Exposure = ({
	comparison,
	composition,
	list,
	firstRender,
}: PrintableProps<
	{
		data: ExposureProps;
		comparison: InvestmentExposureResponseExposureTypeEnum;
		composition: Array<InvestmentExposureEntry>;
	},
	Array<
		| { type: "title"; label: string; groupName: string }
		| { type: "entry"; label: string; weight: number; groupName: string; netLong: boolean }
		| { type: "footer"; label: string; weight: number; groupName: string }
	>
>): JSX.Element => {
	const chartData = useMemo(() => {
		const data = composition.reduce(
			(acc, cur) => {
				if (!acc[cur.firstQualityLevel!]) {
					acc[cur.firstQualityLevel!] = {
						label: cur.firstQualityLevel!,
						weight: 0,
						drillDown: [],
						groupName: cur.firstQualityLevel!,
					};
				}
				acc[cur.firstQualityLevel!]!.weight += cur.weight!;

				acc[cur.firstQualityLevel!]!.drillDown.push({
					label: cur.secondQualityLevel!,
					weight: cur.weight!,
					netLong: cur.netLong ?? true,
				});
				return acc;
			},
			{} as Record<string, ExposureChartData[number]>,
		);

		return Object.values(data);
	}, [composition]);

	if (list.length === 0) {
		return <></>;
	}
	return (
		<>
			{firstRender && (
				<Card style={{ marginBottom: 0 }} title="Exposure" subTitle={exposureCategoryInfo[comparison].label}>
					<div className={`border border-[color:${themeCSSVars.palette_N100}] rounded pt-5 mb-4`}>
						<ExposureChart
							animated={false}
							customHeight={`${exposureChartHeight - 20 /* pt-5 */ - 16 /* mb-4 */ - 60 /* card extra spacing */}px`}
							data={chartData}
						/>
					</div>
				</Card>
			)}
			<ForEach collection={list}>
				{({ item: subList }) => (
					<CompositionList
						availableHeight={exposureAvailableHeightForItemSlotsByChartHeight(
							firstRender ? exposureChartHeight : 0,
							"portrait",
						)}
						list={subList}
					/>
				)}
			</ForEach>
		</>
	);
};

export default Exposure;
