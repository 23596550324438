import { useCallback, useEffect, useState } from "react";

interface TConnectivity {
	isOnline: boolean;
	since: number;
}

const useConnectivity = (): TConnectivity => {
	// Assuming online if the browser doesn't support navigator.onLine (navigator.onLine === undefined).
	const initConnection: TConnectivity = { isOnline: navigator.onLine ?? true, since: new Date().getTime() };
	const [connectivity, setConnectivity] = useState<TConnectivity>(initConnection);

	const updateConnectivity = useCallback(
		(state: boolean) => () => {
			setConnectivity({
				isOnline: state,
				since: new Date().getTime(),
			});
		},
		[],
	);

	useEffect(() => {
		const handleOnline = updateConnectivity(true);
		const handleOffline = updateConnectivity(false);

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, [updateConnectivity]);

	return connectivity;
};

export default useConnectivity;
