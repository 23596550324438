import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import { type FC } from "react";

const CreateMarketViewCustomIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 114,
		height: 113,
	},
	...props
}) => {
	return (
		<Svg viewBox={{ width: 114, height: 113 }} style={style} {...props}>
			<g filter="url(#filter0_d_1099_34166)">
				<path
					fill="#C3C9D5"
					fillRule="evenodd"
					d="M68.444 66.445v.777a.778.778 0 001.556 0v-.777h.778a.778.778 0 000-1.556H70v-.778a.778.778 0 10-1.556 0v.778h-.778a.778.778 0 000 1.556h.778zm.843-.844a1.775 1.775 0 00-.13 0 1.775 1.775 0 000 .131 1.775 1.775 0 00.13 0 1.775 1.775 0 000-.13zM34.222 27.556h-.778a.778.778 0 100 1.555h.778v.778a.778.778 0 001.556 0v-.778h.777a.778.778 0 000-1.555h-.777v-.778a.778.778 0 10-1.556 0v.778zm.712.712a1.57 1.57 0 000 .13 1.57 1.57 0 00.131 0 1.578 1.578 0 000-.13 1.578 1.578 0 00-.13 0zM51.333 33h-.777a.778.778 0 000 1.556h.777v.777a.778.778 0 101.556 0v-.777h.778a.778.778 0 000-1.556h-.778v-.778a.778.778 0 00-1.556 0V33zm.713.712a1.578 1.578 0 000 .131 1.775 1.775 0 00.13 0 1.578 1.578 0 000-.13 1.765 1.765 0 00-.13 0zM46.666 71.111v.778a.778.778 0 001.556 0v-.778H49a.778.778 0 100-1.555h-.778v-.778a.778.778 0 00-1.556 0v.778h-.777a.778.778 0 000 1.555h.777zm.844-.843a1.57 1.57 0 00-.131 0 1.57 1.57 0 000 .13 1.578 1.578 0 00.13 0 1.578 1.578 0 000-.13z"
					clipRule="evenodd"
				/>
				<g filter="url(#filter1_d_1099_34166)">
					<rect width="46.667" height="18.667" x="32.666" y="44.667" fill="#fff" rx="4" />
				</g>
				<rect
					width="44.467"
					height="16.467"
					x="33.767"
					y="45.767"
					fill="#fff"
					stroke="#C3C9D5"
					strokeWidth="2.2"
					rx="2.9"
				/>
				<path stroke="#C3C9D5" strokeLinecap="round" strokeWidth="4" d="M39.333 53.556L72.666 53.556" />
				<path
					fill="#fff"
					d="M79.787 26.2c-1.616-1.598-4.24-1.6-5.847-.007l-15.07 14.72c-1.362.072-2.567.36-3.584.858a6.902 6.902 0 00-2.375 1.91c-1.178 1.475-1.679 3.242-2.081 4.661l.422.121-.423-.12c-.318 1.124-.525 1.715-.716 2.041-.162.277-.263.29-.414.29h-2.025A1.67 1.67 0 0046 52.338c0 .917.75 1.663 1.674 1.663h6.962c3.89 0 6.764-.992 8.543-2.95a6.906 6.906 0 001.764-4.133l14.844-14.905A4.064 4.064 0 0081 29.106c0-1.1-.43-2.132-1.213-2.907z"
				/>
				<path
					fill="#4CB09C"
					d="M54.649 54h-7.347A1.303 1.303 0 0146 52.696c0-.722.584-1.305 1.302-1.305h2.138c.696 0 .987-.502 1.638-2.829.85-3.028 2.13-7.605 8.78-7.605.346 0 .678.137.921.382l3.907 3.913c.211.211.344.488.374.785.013.111.263 2.737-1.741 4.97C61.533 52.993 58.616 54 54.649 54zm-1.757-2.609h1.757c3.152 0 5.48-.737 6.733-2.13.236-.264.422-.535.567-.8.559-1.021.044-2.218-.777-3.04l-.588-.589c-.798-.799-1.917-1.296-3.001-.983-2.697.779-3.332 3.046-3.996 5.419-.193.688-.403 1.433-.695 2.123zm10.874-3.913c-.331 0-.662-.125-.914-.375a1.306 1.306 0 01-.013-1.844l16.171-16.42a1.303 1.303 0 10-1.836-1.85L60.773 43.19a1.302 1.302 0 01-1.842-.013 1.305 1.305 0 01.014-1.844l16.394-16.194a3.907 3.907 0 015.52.005 3.924 3.924 0 010 5.534L64.692 47.09a1.299 1.299 0 01-.927.388zm2.604-3.912c-.333 0-.666-.128-.92-.383l-2.605-2.608a1.304 1.304 0 011.841-1.844l2.605 2.608a1.304 1.304 0 01-.92 2.227z"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1099_34166"
					width="120"
					height="122"
					x="-4"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="16" />
					<feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.188235 0 0 0 0 0.235294 0 0 0 0.16 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1099_34166" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1099_34166" result="shape" />
				</filter>
				<filter
					id="filter1_d_1099_34166"
					width="74.666"
					height="46.667"
					x="18.666"
					y="32.667"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1099_34166" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1099_34166" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CreateMarketViewCustomIcon;
