import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Collapsible, Icon, Overlay, StackingContext } from "@mdotm/mdotui/components";
import type { SideBarOptions } from "./PlatformRouter/RoutesDef";
import { extractPath, extractSearch, useCurrentRoutesMap } from "./PlatformRouter/RoutesDef";
import { PaletteColors } from "$root/styles/themePalette";
import SPHEREIconWhite from "$root/assets/images/sphere-logo-white.svg";
import { Link } from "react-router-dom";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop } from "@mdotm/mdotui/utils";
import { collapsedDrawerWidth, drawerWidth } from "./AppDrawer-shared";
import { filterMap } from "$root/utils/collections";

function AppMenuItem({
	label,
	url,
	sidebarOptions,
	expanded = true,
	current = false,
	qualifier,
}: {
	qualifier: string;
	label: string;
	url: string;
	sidebarOptions: SideBarOptions;
	expanded?: boolean;
	current?: boolean;
}): JSX.Element {
	const subMenuItems = sidebarOptions.subMenuItems;
	const icon = <Icon icon={sidebarOptions.icon ?? "Icon-full-alert"} color={PaletteColors.PALE_GREY_TWO} size={18} />;
	const dynamicLabel = (subMenuOpen: boolean | null) => (
		<div className="!bg-transparent h-9 hover:!bg-slate-600 rounded flex flex-1 items-center px-3 truncate">
			{expanded ? (
				<div className="flex items-center justify-center flex-1">
					{icon}
					<span className="pl-3 flex-1 text-left">{label}</span>
					{subMenuOpen !== null && (
						<span
							className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex"
							aria-expanded={subMenuOpen}
						>
							<Icon icon="Down" />
						</span>
					)}
				</div>
			) : (
				icon
			)}
		</div>
	);
	const location = useLocation();
	return (
		<div
			className={toClassName({ "px-1": !expanded, "px-3": expanded, "pb-1 rounded transition-[padding]": true })}
			data-qualifier={qualifier}
		>
			<div className={toClassName({ rounded: true, "!bg-slate-600": current })}>
				{!subMenuItems || !expanded ? (
					<Link
						data-highlight={current}
						to={url}
						className={toClassName({
							"cursor-pointer transition-colors flex items-center justify-between flex-1 data-[highlight=true]:!bg-slate-600 rounded w-full":
								true,
						})}
					>
						{dynamicLabel(null)}
					</Link>
				) : (
					<Collapsible
						expand={current}
						header={({ onClick, expand }) => (
							<button
								data-highlight={expand || current}
								onClick={onClick}
								className={toClassName({
									"font-bold cursor-pointer transition-colors flex items-center justify-between flex-1 data-[highlight=true]:!bg-slate-600 rounded":
										true,
								})}
								type="button"
							>
								{dynamicLabel(expand)}
							</button>
						)}
					>
						{({ expand }) => (
							<div aria-expanded={expand} className="pt-1 pb-3 aria-[expanded=true]:!bg-slate-700 relative z-0 rounded">
								<div className="ml-4 border-l-[1px] border-current absolute left-0 top-1 bottom-3 z-10" />
								{subMenuItems.map((subItem) => (
									<div
										key={subItem.label}
										data-qualifier={`${qualifier}/subItem(${subItem.label})`}
										className={toClassName({
											"hover:!bg-slate-600 w-full relative z-0": true,
											"!bg-slate-600": extractSearch(subItem.url) === location.search,
										})}
									>
										<div className="flex h-9">
											<Link
												aria-label={subItem.label}
												to={subItem.url}
												className="flex-1 h-9 pl-10 text-left cursor-pointer flex items-center"
											>
												<span className="truncate">{subItem.label}</span>
											</Link>
											{subItem.actions && (
												<div className="pr-4 flex h-9">
													{subItem.actions.map((action, i) => (
														<Link
															key={`subAction-${i}`}
															className="pl-2 cursor-pointer h-9 flex items-center"
															to={action.url}
														>
															<Icon icon={action.icon} color={PaletteColors.PALE_GREY_TWO} size={14} />
														</Link>
													))}
												</div>
											)}
										</div>
									</div>
								))}
							</div>
						)}
					</Collapsible>
				)}
			</div>
		</div>
	);
}

export const appDrawerZIndexOffset = {
	closed: 0,
	open: 2,
};

function Drawer({
	children,
	open,
	...forward
}: {
	children: ReactNode;
	open: boolean;
	onMouseEnter(): void;
	onMouseLeave(): void;
}) {
	return (
		<StackingContext.Consumer>
			{({ zIndex }) => (
				<>
					<Overlay style={{ zIndex }} show={open} />
					<div
						className="fixed left-0 inset-y-0 bg-[#2f3541] text-white transition-[width]"
						style={{
							zIndex: open ? zIndex + appDrawerZIndexOffset.open : zIndex + appDrawerZIndexOffset.closed,
							width: open ? drawerWidth : collapsedDrawerWidth,
						}}
						{...forward}
					>
						{children}
					</div>
				</>
			)}
		</StackingContext.Consumer>
	);
}

export function AppDrawer(): JSX.Element {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		noop(location); // track
		setOpen(false);
	}, [location]);

	const isPathEqual = (route: string, loc: string) => (route === "/" ? loc === route : loc.indexOf(route) > -1);
	const currentRoutesMap = useCurrentRoutesMap();
	const sideBarEntries = useMemo(
		() =>
			filterMap(Object.values(currentRoutesMap), (x) =>
				x.sideBarOptions?.show
					? {
							value: {
								...x,
								sideBarOptions: x.sideBarOptions,
							},
					  }
					: null,
			),
		[currentRoutesMap],
	);

	return (
		<div>
			<Drawer
				data-qualifier="appDrawer"
				open={open}
				onMouseEnter={() => {
					setOpen(true);
				}}
				onMouseLeave={() => {
					setOpen(false);
				}}
			>
				<div className={`px-3 py-3 bg-[${themeCSSVars.palette_P500}] flex justify-start items-center text-white`}>
					<Link to="/" data-qualifier="appDrawer/sphere">
						<div className={toClassName({ "flex gap-2 transition-[padding]": true, "pl-2": open })}>
							<img src={SPHEREIconWhite} className="h-[24px] w-auto" alt="Sphere Logo" />
							<span
								aria-hidden={open === false}
								className="mt-auto text-base tracking-tight aria-hidden:hidden block truncate"
							>
								Sphere
							</span>
						</div>
					</Link>
				</div>
				<div className="mt-4">
					{sideBarEntries.map(({ name, baseUrl, sideBarOptions }) => (
						<AppMenuItem
							qualifier={`appDrawer/drawerItem(${name})`}
							key={name}
							expanded={open}
							current={isPathEqual(extractPath(baseUrl), location.pathname)}
							label={sideBarOptions.title}
							url={baseUrl}
							sidebarOptions={sideBarOptions}
						/>
					))}
				</div>
			</Drawer>
		</div>
	);
}
