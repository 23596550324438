import type {
	InvestmentStatuses,
	ReferenceUniverseDetails,
	ReviewTicker,
	V2MarketRegimeProbabilityDTORegimeProbability,
} from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { useEventBus } from "$root/event-bus";
import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { formatDate } from "$root/localization/formatters";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "$root/utils/runtime";
import { ellipsis } from "$root/utils/strings";
import { ModeVariants, UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { Card } from "$root/widgets-architecture/layout/Card";
import {
	AsyncButton,
	Banner,
	Button,
	CircularProgressBar,
	DropdownMenuActionButton,
	ProgressBar,
	Select,
	Table,
	Text,
} from "@mdotm/mdotui/components";

import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { SmallUniverseSummary } from "$root/functional-areas/universe/SmallUniverseSummary";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import type { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { match } from "ts-pattern";
import "./Portfolios/Portfolios.scss";
import { CrudModal, PortfolioStudioTab } from "./PortfoliosStudio";
import { MaybePromise } from "@mdotm/mdotui/headless";

type PageSubtitleProps = { universe?: ReferenceUniverseDetails } & (
	| {
			mode: Extract<ModeVariants, "editor">;
			onSave?(): void;
			onCancel?(): void;
			disabledOnSave?: boolean;
	  }
	| { mode: Extract<ModeVariants, "viewer">; onChangeAcl?: () => Promise<unknown>; onEdit: () => void }
	| {
			mode: Extract<ModeVariants, "review">;
			onSave?: () => Promise<void>;
			isSaveDiabled: boolean;
			onChangeAcl?: () => Promise<unknown>;
	  }
);

const PageSubtitle = (props: PageSubtitleProps) => {
	const { t } = useTranslation();
	const { universe } = props;
	const { richAcl } = universe ?? {};
	const user = useUserValue();
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (props.mode === "editor") {
		return (
			<div className="flex justify-between items-center py-2.5">
				<span className="text-[14px]">Personalise your universe by adding and removing instruments, scores, tags.</span>
				<div className="flex items-center space-x-2">
					<Button size="small" onClick={props.onCancel} palette="tertiary">
						{t("BUTTON.CANCEL")}
					</Button>
					<Button size="small" onClick={props.onSave} palette="primary" disabled={props.disabledOnSave}>
						{t("BUTTON.SAVE")}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className="flex justify-between items-center py-2.5 text-[#585D68]">
			<div className="flex gap-4 items-center">
				<div className="flex items-center" data-qualifier="UniverseDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: universe?.status ?? "-", component: "" }} />
				</div>
				<div>
					<span className="mr-2 uppercase">{t("INCEPTION_DATE")}:</span>
					{universe?.creationTime ? formatDate(universe.creationTime) : "..."}
				</div>
				<div>
					<span className="mr-2 uppercase">{t("PORTFOLIOS.PF_LAST_ACTION")}:</span>
					<span className="font-semibold text-[#616161]">{universe?.action}</span>
					&nbsp;
					{universe?.modificationTime ? <span>({formatDate(universe?.modificationTime)})</span> : "..."}
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="UNIVERSE"
					entityId={universe?.uuid}
					entityName={universe?.name}
					refetch={props.onChangeAcl}
				/>
			</div>
			{match(props)
				.with(
					{ mode: "review" },
					(x) =>
						universe?.status === "REVIEW" && (
							<AsyncButton palette="primary" size="small" onClickAsync={x.onSave ?? noop} disabled={x.isSaveDiabled}>
								{t("BUTTON.SAVE")}
							</AsyncButton>
						),
				)
				.when(
					(x) => x.mode === "viewer" && canEditComposition,
					(x) => (
						<Button palette="secondary" size="small" onClick={x.onEdit}>
							{t("BUTTON.EDIT_UNIVERSE")}
						</Button>
					),
				)
				.otherwise(() => (
					<></>
				))}
		</div>
	);
};

type UniverseDetailsMapProps = {
	titleBlock: {
		title: string;
		showFilter: boolean;
	};
};

type PickedStatus = Extract<InvestmentStatuses, "CALCULATING" | "REVIEW" | "RETRIEVING_DATA" | "READY" | "ERROR">;
interface IpageUrl {
	universeUuid: string;
	mode?: ModeVariants;
}

const severityByStatus: Record<InvestmentStatuses, NonNullable<PageHeaderProps["severity"]>> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: "info",
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

const UNIVERSE_DETAILS_MAP: Record<ModeVariants, UniverseDetailsMapProps> = {
	viewer: {
		titleBlock: {
			title: "Universe details",
			showFilter: true,
		},
	},
	editor: {
		titleBlock: {
			title: "Edit universe composition",
			showFilter: false,
		},
	},
	review: {
		titleBlock: {
			title: "Universe details",
			showFilter: true,
		},
	},
};

export const MODES: Record<PickedStatus, ModeVariants> = {
	READY: "viewer",
	CALCULATING: "viewer",
	ERROR: "viewer",
	RETRIEVING_DATA: "review",
	REVIEW: "review",
};
const invalidStatus: Array<InvestmentStatuses> = ["REVIEW", "CALCULATING", "RETRIEVING_DATA", "PROPOSAL_READY"];

function UniverseDetails(): JSX.Element {
	const { universeUuid, mode = "viewer" } = useParams<IpageUrl>();

	const [isOpen, setIsOpen] = useState(false);
	const [editorComposition, setEditorComposition] = useState<{ disabled: boolean } | null>(null);
	const [pathToNotBlock, setPathToNotBlock] = useState(["/login"]);

	const [crudAction, setCrudAction] = useState<UsePerformCrudActions | undefined>(undefined);
	const [isUniverseNameAvailable, setIsUniverseNameAvailable] = useState(true);
	const [reviewUniverseSubmit, setReviewUniverseSubmit] = useState<{
		disabled?: boolean;
		onSubmitAsync?(): Promise<void>;
	}>({});
	const history = useHistory();
	const user = useUserValue();
	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const { performAction } = usePerformCrud("universe");

	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const {
		titleBlock: { showFilter },
	} = UNIVERSE_DETAILS_MAP[mode];

	useEffect(() => {
		if (!universeUuid) {
			history.push({ pathname: "/portfolios", search: `status=notFound&tab=${encodeURIComponent("Universes")}` });
		}
	}, [history, universeUuid]);

	const { data, isLoading, refetch } = useQueryNoRefetch(["universeList", mode, universeUuid], {
		enabled: Boolean(universeUuid),
		queryFn: async () => {
			const { data: universeList } = await referenceUniverseV4Api.getUserUniverses();
			const { data: universe } = await referenceUniverseV4Api.getUniverse(universeUuid);

			const mapUniverseList = universeList.map(({ name, uuid }) => ({ label: name!, value: uuid! })).reverse();

			return { mapUniverseList, universe };
		},
		onSuccess: ({ universe }) => {
			const modeByStatus = universe?.status ? MODES[universe?.status as keyof typeof MODES] : MODES.ERROR;
			if (mode !== modeByStatus && mode !== "editor") {
				push("UniverseDetails", { mode: modeByStatus, universeUuid });
			}
		},
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.Universes });
		},
	});

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { universe, mapUniverseList } = data ?? {};
	const { status, referralInvestments, uuid, name } = universe ?? {};
	const isDeleteButtonDisabled = (referralInvestments ?? []).length > 0 || ["RETRIEVING_DATA"].includes(status ?? "");
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, universe?.richAcl?.acl ?? []);

	const onCloseModal = useCallback(() => setCrudAction(undefined), []);

	const onChangeUniverseUuid = useCallback(
		(uuid: string) => {
			push("UniverseDetails", { mode: ModeVariants.viewer, universeUuid: uuid });
		},
		[push],
	);

	const onDelete = useCallback(
		async (name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				await performAction({ action: "delete", uuid });
				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_UNIVERSE", { name }),
					severity: "success",
					icon: "Portfolio",
				});
				onCloseModal();
				setTimeout(() => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }), 1000);
			} catch (error) {
				reportPlatformError(error, "ERROR", "universe", `delete universe "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[performAction, t, onCloseModal, push],
	);

	const onPerformCrudAction = useCallback(
		async (actionToPerform: Exclude<UsePerformCrudActions, "delete">, name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				const { data: isNameAvailable } = await referenceUniverseV4Api.isUniverseNameAvailable(name);
				if (!isNameAvailable) {
					setIsUniverseNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action: actionToPerform, uuid, name });
				const notificationMessage: Record<typeof actionToPerform, string> = {
					duplicate: t("PORTFOLIOS.DUPLICATE_OK_MESSAGE", { portfolioName: name }),
					rename: t("PORTFOLIOS.RENAME_OK_MESSAGE", { portfolioName: name }),
				};
				platformToast({
					children: notificationMessage[actionToPerform],
					severity: "success",
					icon: "Portfolio",
				});
				onCloseModal();
				setTimeout(() => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }), 1000);
			} catch (error) {
				reportPlatformError(error, "ERROR", "universe", `${actionToPerform} universe "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[onCloseModal, performAction, push, referenceUniverseV4Api, t],
	);

	const manageFinish = useCallback(
		(path: string) => {
			setPathToNotBlock([path]);
			setTimeout(() => history.push(path));
		},
		[history],
	);

	const onSave = useCallback(
		async (payload: {
			composition: ReviewTicker[];
			formData: {
				saveMode: "SAVE" | "SAVE_AS_NEW";
				name: string;
			};
		}) => {
			try {
				if (payload.formData.saveMode === "SAVE_AS_NEW") {
					const { identifier } = await axiosExtract(
						editorApi.saveEditorNewEntity("UNIVERSE", {
							name: payload.formData.name,
							tickerComposition: payload.composition,
						}),
					);
					setIsOpen(false);
					manageFinish(`/universe_details/viewer/${identifier}`);
					return;
				}

				if (universeUuid === undefined) {
					return reportPlatformError(
						universeUuid,
						"ERROR",
						"universe",
						`unable to submit a valid universe of undefined`,
					);
				}

				const { identifier } = await axiosExtract(
					editorApi.saveEditorEditEntity("UNIVERSE", {
						identifier: universeUuid,
						tickerComposition: payload.composition,
					}),
				);

				setIsOpen(false);
				manageFinish(`/universe_details/viewer/${identifier}`);
			} catch (error) {
				reportPlatformError(
					error,
					"ERROR",
					"portfolio",
					`unable to save the edited universe composition (${universeUuid})`,
				);
				throw error;
			}
		},
		[editorApi, universeUuid, manageFinish],
	);

	return (
		<>
			<PageHeader
				name="UniverseDetails"
				severity={severityByStatus[status as InvestmentStatuses]} // TODO: is this the same enum from Portfolio?
				title={isLoading ? "..." : name ?? "Untitled"} // TODO: translate
				crumbs={[
					{
						children: "Portfolio studio", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", {}),
					},
					{
						children: "Universes", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
					},
					...(mode === "editor"
						? [
								{
									children: ellipsis(name ?? "", 20),
									href: typedUrlForRoute("UniverseDetails", { mode: ModeVariants.viewer, universeUuid }),
								},
								{ children: "Edit" },
						  ]
						: [
								{
									children: !showFilter ? (
										ellipsis(name ?? "", 20)
									) : isLoading ? (
										<CircularProgressBar value="indeterminate" style={{ width: 12, height: "auto" }} />
									) : (
										<Select
											unstyled
											classList="flex items-center w-40 truncate"
											strategy="fixed"
											enableSearch
											options={mapUniverseList ?? []}
											value={uuid}
											i18n={{ triggerPlaceholder: () => t("UNIVERSE.SELECT_PLACEHOLDER") }}
											onChange={onChangeUniverseUuid}
										/>
									),
								},
						  ]),
				]}
				subTitle={match(mode)
					.with("editor", (x) => (
						<PageSubtitle
							mode={x}
							universe={universe}
							disabledOnSave={editorComposition?.disabled ?? true}
							onCancel={() => history.goBack()}
							onSave={() => setIsOpen(!isOpen)}
						/>
					))
					.with("review", (x) => (
						<PageSubtitle
							mode={x}
							universe={universe}
							onChangeAcl={refetch}
							onSave={reviewUniverseSubmit?.onSubmitAsync}
							isSaveDiabled={reviewUniverseSubmit?.disabled ?? true}
						/>
					))
					.with("viewer", (x) => (
						<PageSubtitle
							mode={x}
							universe={universe}
							onChangeAcl={refetch}
							onEdit={() => push("UniverseDetails", { universeUuid: universe?.uuid ?? "", mode: ModeVariants.editor })}
						/>
					))
					.exhaustive()}
				titleMenuActions={
					mode === "editor"
						? undefined
						: [
								({ onClose }) => (
									<AuthorizationGuard
										permissionChecker={aclByArea.universe.canDelete}
										acl={universe?.richAcl?.acl ?? []}
									>
										<DropdownMenuActionButton
											icon="Delete"
											data-qualifier="UniverseDetails/DropdownMenu/DropdownItem(Delete)"
											disabled={isDeleteButtonDisabled}
											onClick={() => {
												setCrudAction("delete");
												onClose();
											}}
										>
											Delete
										</DropdownMenuActionButton>
									</AuthorizationGuard>
								),
								({ onClose }) => (
									<DropdownMenuActionButton
										icon="Content-Copy"
										data-qualifier="UniverseDetails/DropdownMenu/DropdownItem(Duplicate)"
										disabled={status ? invalidStatus.includes(status as InvestmentStatuses) : true}
										onClick={() => {
											setCrudAction("duplicate");
											onClose();
										}}
									>
										Duplicate
									</DropdownMenuActionButton>
								),
								({ onClose }) => (
									<AuthorizationGuard
										permissionChecker={aclByArea.universe.canRename}
										acl={universe?.richAcl?.acl ?? []}
									>
										<DropdownMenuActionButton
											icon="Edit"
											data-qualifier="UniverseDetails/DropdownMenu/DropdownItem(Rename)"
											onClick={() => {
												setCrudAction("rename");
												onClose();
											}}
										>
											Rename
										</DropdownMenuActionButton>
									</AuthorizationGuard>
								),
						  ]
				}
			/>

			<CrudModal
				show={crudAction === "delete"}
				action="delete"
				title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
				portfolioName={name ?? ""}
				onCancel={onCloseModal}
				onClose={onCloseModal}
				onSubmit={() => onDelete(name ?? "", uuid)}
				name="UniverseDetails"
			/>

			<CrudModal
				show={crudAction === "duplicate"}
				action="duplicate"
				title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: name })}
				onCancel={onCloseModal}
				onClose={onCloseModal}
				isInvalid={!isUniverseNameAvailable}
				onSubmit={(name) => onPerformCrudAction("duplicate", name, uuid)}
				portfolioName={name ?? ""}
				name="UniverseDetails"
			/>

			<CrudModal
				show={crudAction === "rename"}
				action="rename"
				title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: name })}
				onCancel={onCloseModal}
				onClose={onCloseModal}
				isInvalid={!isUniverseNameAvailable}
				onSubmit={(name) => onPerformCrudAction("rename", name, uuid)}
				portfolioName={name ?? ""}
				name="UniverseDetails"
			/>

			<UniverseContext.Provider value={{ mode, universe: universe ?? null }}>
				{mode !== "editor" && (
					<div className="mb-5">
						<SmallUniverseSummary universe={universe} />
					</div>
				)}
				{/* TODO: test review errors */}
				{mode === "review" && (
					<ReactQueryWrapper
						queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"))}
						queryKey={["universeUploadComposition", universe?.uuid, universe?.status]}
						enabled={Boolean(universe?.uuid)}
						loadingFallback={<ProgressBar value="indeterminate" />}
					>
						{(upload) => (
							<Card>
								<Text type="Body/XL/Bold" classList="mb-4">
									Instruments
								</Text>
								<ReviewCompositionErrors errors={upload?.uploadErrors} />
								<div className="mb-4" hidden={status === "RETRIEVING_DATA"}>
									<Banner severity="warning" title="Some instruments require your attention">
										Please review and complete the classification for the following instruments in your portfolio. Some
										instruments may require additional information to ensure accurate classification.
									</Banner>
								</div>

								{status === "RETRIEVING_DATA" ? (
									<>
										<div className="mb-4">
											<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
										</div>
										<Table
											columns={[
												{ header: "name", content: (row) => row.instrument ?? "-" },
												{ header: "Identifier", content: (row) => row.identifier ?? "-" },
												{ header: "Asset Class", content: (row) => row.assetClass ?? "-" },
												{ header: "Micro Asset Class", content: (row) => row.microAssetClass ?? "-" },
											]}
											rows={upload?.composition ?? []}
											visibleRows={Math.min(upload?.composition?.length ?? 0, 12)}
										/>
									</>
								) : (
									<EditProxiedInstrumentSection
										uuid={universe?.uuid}
										disabled={canEditComposition === false}
										section="universe"
										composition={upload?.composition ?? []}
										onSaveAsync={async (tickerComposition) => {
											await editorApi.saveEditorReviewEntity("UNIVERSE", {
												identifier: universe?.uuid,
												tickerComposition,
											});
											await refetch();
										}}
										customActions={function Block({ isSaveDisabled, onSaveAsync }) {
											useEffect(
												() =>
													setReviewUniverseSubmit({
														onSubmitAsync: onSaveAsync,
														disabled: isSaveDisabled,
													}),
												// eslint-disable-next-line react-hooks/exhaustive-deps
												[isSaveDisabled],
											);

											return <></>;
										}}
									/>
								)}
							</Card>
						)}
					</ReactQueryWrapper>
				)}

				{mode === "editor" && (
					<EditCompositionSection
						instrumentsLimit={1500}
						uploadEntity="UNIVERSE"
						uuid={universe?.uuid}
						ref={setEditorComposition}
						submitForm={{
							isOpen,
							onCancel: () => setIsOpen(!isOpen),
							onSubmit: onSave,
						}}
						pathToNotBlock={pathToNotBlock}
					/>
				)}

				{mode === "viewer" && <WidgetsMapper style={{ minHeight: "120px" }} widgetName="ReadonlyUniverseComposition" />}
			</UniverseContext.Provider>
		</>
	);
}

export default UniverseDetails;
