import type { StepMetadata } from "./MultiStepFormV2";

export function defaultInitialStepMetadata(): Omit<StepMetadata, "name"> {
	return {
		optional: true,
		completed: false,
		visited: false,
		enabled: true,
		visible: true,
		hasErrors: false,
		dirty: false,
		processing: false,
		persisting: false,
		solveWithAi: false,
	};
}
