export function FeedbackSectionIcon(): JSX.Element {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="16" fill="#EBEEF8" />
			<path
				d="M25.99 8.76C25.99 8.03 25.7 7.33 25.18 6.81C24.66 6.29 23.96 6 23.23 6H22.04C19.56 6 17.18 6.99 15.43 8.74L14.94 9.23H10.19C7.88 9.23 6 11.11 6 13.42C6 13.67 6.1 13.92 6.28 14.1L11.4 19.22L9.53 21.09C9.36 21.26 9.24 21.48 9.22 21.72C9.2 22.02 9.3 22.3 9.52 22.5C9.9 22.86 10.53 22.82 10.9 22.45L12.77 20.58L17.89 25.7C18.07 25.88 18.32 25.98 18.57 25.98C20.88 25.98 22.76 24.1 22.76 21.79V17.03L23.25 16.54C25 14.79 25.99 12.41 25.99 9.93V8.74V8.76ZM7.96 13.05L8.01 12.87C8.26 11.87 9.16 11.17 10.2 11.17H13.02L9.55 14.64L7.96 13.05ZM20.83 21.81C20.83 22.84 20.13 23.74 19.13 24L18.95 24.05L17.36 22.46L20.83 18.99V21.81ZM24.05 9.96C24.05 11.93 23.27 13.81 21.88 15.2L15.99 21.09L14.13 19.23L16 17.36C16.17 17.19 16.28 16.98 16.31 16.74C16.34 16.45 16.24 16.17 16.03 15.97C15.65 15.59 15.04 15.59 14.66 15.97L12.76 17.87L10.9 16.01L16.79 10.12C18.19 8.72 20.05 7.95 22.03 7.95H23.39C23.74 7.95 24.03 8.24 24.03 8.59V9.95L24.05 9.96Z"
				fill="#6678CC"
			/>
		</svg>
	);
}
