import type { BatchActionsProps } from "@mdotm/mdotui/components";
import type { TFunction } from "i18next";
import type { Set } from "immutable";

export const commonBatchActions = {
	deleteRestore<T>({
		t,
		deleted,
		selection,
		onDeletedChange,
	}: {
		t: TFunction;
		deleted: Set<T>;
		selection: Set<T>;
		onDeletedChange(newDeleted: Set<T>): void;
	}): BatchActionsProps["actions"][number] {
		return selection.some((ticker) => !deleted.has(ticker))
			? {
					label: t("BUTTON.DELETE"),
					icon: "Delete",
					onClick: () => onDeletedChange(deleted.concat(selection)),
			  }
			: {
					label: t("BUTTON.RESTORE"),
					icon: "restore",
					onClick: () => onDeletedChange(deleted.subtract(selection)),
			  };
	},
};
