import type { AccessPermission, AclEntityMinInfoEntityTypeEnum, RichAccessControl } from "$root/api/api-gen";
import type { IconProps } from "@mdotm/mdotui/components";
import { Icon } from "@mdotm/mdotui/components";
import type { Areas } from "./checkers/all";
import { roleByArea } from "./checkers/all";
import { validateACLPermissions } from "./checkers/shared";

const UserShareIcon = (
	props: {
		userId?: string;
		permission?: Array<AccessPermission>;
		entity: AclEntityMinInfoEntityTypeEnum;
		acl?: Array<RichAccessControl>;
	} & Omit<IconProps, "icon">,
): JSX.Element => {
	const areasByEntity = {
		BENCHMARK: "benchmark",
		INVESTMENT: "portfolio",
		MARKET_VIEW: "marketView",
		UNIVERSE: "universe",
	} satisfies { [key in AclEntityMinInfoEntityTypeEnum]: Areas };
	const currentEntity = areasByEntity[props.entity];

	if ((props.acl?.length ?? 0) <= 1) {
		return <></>;
	}

	if (
		validateACLPermissions(
			props.userId!,
			[{ userId: props.userId, permissions: props.permission }],
			roleByArea[currentEntity].OWNER,
		)
	) {
		return <Icon icon="share" size={16} {...props} />;
	}

	if (
		validateACLPermissions(
			props.userId!,
			[{ userId: props.userId, permissions: props.permission }],
			roleByArea[currentEntity].EDITOR,
		)
	) {
		return <Icon icon="share" size={16} {...props} />;
	}

	if (
		validateACLPermissions(
			props.userId!,
			[{ userId: props.userId, permissions: props.permission }],
			roleByArea[currentEntity].VIEWER,
		)
	) {
		return <Icon icon="show" size={16} {...props} />;
	}

	return <></>;
};

export default UserShareIcon;
