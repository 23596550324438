export type PreferenceExpressedEvent = {
	timestamp: string;
	version: string;
	purposes: Partial<Record<number, boolean>>;
	id: number;
	cons: {
		rand: string;
	};
};

const initIubenda = (lang: string, onPreferenceExpressed: (preferences: PreferenceExpressedEvent) => void): void => {
	(window as any)._iub = (window as any)._iub || [];
	(window as any)._iub.csConfiguration = {
		consentOnContinuedBrowsing: false,
		countryDetection: true,
		floatingPreferencesButtonDisplay: "bottom-left",
		gdprAppliesGlobally: false,
		invalidateConsentWithoutLog: true,
		lang,
		perPurposeConsent: true,
		siteId: 2696993,
		whitelabel: false,
		cookiePolicyId: 48016693,
		floatingPreferencesButtonZIndex: 1400, // above sidebar
		banner: {
			acceptButtonCaptionColor: "#FFFFFF",
			acceptButtonColor: "#0073CE",
			acceptButtonDisplay: true,
			backgroundColor: "#FFFFFF",
			brandBackgroundColor: "#FFFFFF",
			brandTextColor: "#000000",
			closeButtonDisplay: false,
			customizeButtonCaptionColor: "#4D4D4D",
			customizeButtonColor: "#DADADA",
			customizeButtonDisplay: true,
			explicitWithdrawal: true,
			fontSize: "14px",
			listPurposes: true,
			logo: null,
			position: "float-bottom-center",
			rejectButtonCaptionColor: "#FFFFFF",
			rejectButtonColor: "#0073CE",
			rejectButtonDisplay: true,
			textColor: "#000000",
		},
		callback: {
			onPreferenceExpressed,
		},
	};

	const iubScript = document.createElement("script");
	iubScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
	iubScript.type = "text/javascript";
	iubScript.async = true;
	iubScript.onload = () => {
		const iubendaStyleOverride = document.createElement("style");
		iubendaStyleOverride.innerHTML = `
			.iubenda-tp-btn[data-tp-float]:not([data-tp-anchored]),
			.iubenda-uspr-btn[data-tp-float]:not([data-tp-anchored]) {
				margin: 8px !important;
			}`;
		document.body.appendChild(iubendaStyleOverride);
	};

	document.head.appendChild(iubScript);
};

export default initIubenda;
