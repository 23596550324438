import { toClassName } from "$root/utils/react-dom-extra";
import { Text, type InnerRefProps, type StylableProps } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { useMemo } from "react";
import type React from "react";

export type CardProps = StylableProps &
	InnerRefProps<HTMLDivElement> & {
		title?: NodeOrFn;
		subTitle?: NodeOrFn;
		children: NodeOrFn;
		onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
		onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
		onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
		footer?: NodeOrFn;
		actionHeader?: NodeOrFn;
	};

// NOTE: this component is used in the PDF pages, in particular the exposure and exposure mixed widgets.
// If you change the height of the title/subtitle, make sure to update the space reserved for this in those print widgets.
export function Card({
	title,
	subTitle,
	children,
	actionHeader,
	style,
	classList,
	innerRef,
	...handlers
}: CardProps): JSX.Element {
	const renderedTitle = useMemo(() => {
		if (!title) {
			return undefined;
		}
		const rendered = renderNodeOrFn(title);
		if (typeof rendered === "string") {
			return <Text type="Body/XL/Bold">{rendered}</Text>;
		}
		return rendered;
	}, [title]);
	return (
		<div
			className={toClassName({ "rounded bg-white px-8 pb-4 flex flex-col": true, ...toClassListRecord(classList) })}
			style={style}
			ref={innerRef}
			{...handlers}
		>
			{renderedTitle || actionHeader ? (
				<div className="flex items-center justify-between py-5 Header">
					{renderedTitle}
					<div>{renderNodeOrFn(actionHeader)}</div>
				</div>
			) : (
				<div className="pt-3" />
			)}
			<div className="min-h-0 flex flex-col flex-1">
				{subTitle && <div className="text-[14px] pb-1.5">{renderNodeOrFn(subTitle)}</div>}
				<div className="min-h-0 flex flex-col flex-1">{renderNodeOrFn(children)}</div>
			</div>
		</div>
	);
}
