import type { ContributionEntry } from "$root/api/api-gen";
import { formatNumber } from "$root/localization/formatters";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import {
	DrillDownByValueMap,
	customObjectValuesFn,
	getGraphMarkers,
	objSumRounded,
	roundCustomByStep,
} from "$root/utils/experimental";
import { Card } from "../Card";
import { TableWithGroups } from "@mdotm/mdotui/components";
import { groupBy } from "@mdotm/mdotui/utils";
import Immutable from "immutable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";

type PerformanceContributionVolatilitySelectionProps =
	| {
			selector: "assetClass";
			vsSelector: "microAssetClass";
	  }
	| {
			selector: "assetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "microAssetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "geography";
			vsSelector: "assetClass";
	  }
	| {
			selector: "geography";
			vsSelector: "microAssetClass";
	  };

type PerformanceContributionVolatilityProps = CommonSetUpReportProps<UnionMapData["portfolio-details"]>;

const defaultOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
	},
];

type DrillDownProps = {
	index: number;
	value: string | number | ContributionEntry | undefined;
	computedData: Record<"volatilityValue" | "performanceValue" | "maxVol" | "minVol" | "maxPer" | "minPer", number>;
};

type GetPerformanceVolatilityContributionProps = {
	graphColumnMarkers: {
		markersVolatility: {
			value: number;
			label: string;
		}[];
		markersPerformance: {
			value: number;
			label: string;
		}[];
	};
	graphLimit: {
		maxVolatility: number;
		maxPerformance: number;
		minVolatility: number;
		minPerformance: number;
	};
	list: Array<{ group: DrillDownProps; drilldown: DrillDownProps }>;
	opts: PerformanceContributionVolatilitySelectionProps;
};

export function getPerformanceVolatilityContributionProps(
	data: PerformanceContributionVolatilityProps,
	comparison: PerformanceContributionVolatilitySelectionProps,
): GetPerformanceVolatilityContributionProps {
	const { contributionVolatility, performanceVolatility } = data;
	const { selector, vsSelector } = comparison;

	const contribution = {
		currentVolatility: contributionVolatility.current,
		currentPerformance: performanceVolatility.current,
	};

	const aggregateData = {
		volatility: (contribution.currentVolatility ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalVolatility: el,
			volatilityValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),

		performance: (contribution.currentPerformance ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalPerformance: el,
			performanceValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
	};

	const decupledByID = [
		...new window.Set([
			...aggregateData.performance.map((el) => el.id),
			...aggregateData.volatility.map((el) => el.id),
		]),
	];

	const result = decupledByID.map((id) => {
		const currentVTK = aggregateData.volatility.find((el) => el.id === id);
		const currentPTK = aggregateData.performance.find((el) => el.id === id);

		return {
			...currentVTK,
			...currentPTK,
		};
	});

	const computedContribution = DrillDownByValueMap(
		result ?? [],
		selector,
		vsSelector,
		{
			volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
			performanceValue: (val) => objSumRounded(val, "performanceValue"),
			maxVol: (val) => objSumRounded(val, "volatilityValue"),
			minVol: (val) => objSumRounded(val, "volatilityValue"),
			maxPer: (val) => objSumRounded(val, "performanceValue"),
			minPer: (val) => objSumRounded(val, "performanceValue"),
		},
		(a, b) => b.volatilityValue - a.volatilityValue,
	);

	const { drilldownData } = computedContribution;

	const list = drilldownData.flatMap(({ drilldown, ...props }) =>
		drilldown
			.sort((a, b) => b.computedData.performanceValue - a.computedData.performanceValue)
			.map((item) => ({
				drilldown: { ...item },
				group: { ...props },
			})),
	);

	const graphLimit = drilldownData.reduce(
		(acc, group) => {
			const rowValues = group.drilldown.reduce(
				(a, row) => {
					return {
						maxVolatility: Math.max(a.maxVolatility, row.computedData.maxVol, group.computedData.maxVol),
						maxPerformance: Math.max(a.maxPerformance, row.computedData.maxPer, group.computedData.maxPer),
						minVolatility: Math.min(a.minVolatility, row.computedData.minVol, group.computedData.minVol),
						minPerformance: Math.min(a.minPerformance, row.computedData.minPer, group.computedData.minPer),
					};
				},
				{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
			);
			return {
				maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
				maxPerformance: Math.max(acc.maxPerformance, rowValues.maxPerformance),
				minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
				minPerformance: Math.min(acc.minPerformance, rowValues.minPerformance),
			};
		},
		{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
	);

	const scaledDrilldown = {
		maxVolatility: roundCustomByStep(graphLimit.maxVolatility, 0.5),
		maxPerformance: roundCustomByStep(graphLimit.maxPerformance, 0.5),
		minVolatility: roundCustomByStep(graphLimit.minVolatility, 0.5),
		minPerformance: roundCustomByStep(graphLimit.minPerformance, 0.5),
	};

	const graphColumnMarkers = {
		markersVolatility: getGraphMarkers(scaledDrilldown.maxVolatility, scaledDrilldown.minVolatility, 0.5, "%", 10),
		markersPerformance: getGraphMarkers(scaledDrilldown.maxPerformance, scaledDrilldown.minPerformance, 0.5, "%", 10),
	};
	return { list, graphColumnMarkers, opts: comparison, graphLimit };
}

const PerformanceVolatilityContribution = ({
	comparison,
	graphLimit,
	graphColumnMarkers,
	list,
}: PrintableProps<
	{
		data: PerformanceContributionVolatilityProps;
		comparison: PerformanceContributionVolatilitySelectionProps;
		graphLimit: GetPerformanceVolatilityContributionProps["graphLimit"];
		graphColumnMarkers: GetPerformanceVolatilityContributionProps["graphColumnMarkers"];
	},
	GetPerformanceVolatilityContributionProps["list"][number]
>): JSX.Element => {
	const { t } = useTranslation();
	const contributionT = t(`PORTFOLIO_CONTRIBUTION_VOLATILITY`, {
		returnObjects: true,
	});

	const opts = defaultOptions.find(
		(opt) => opt.selector === comparison.selector && opt.vsSelector === comparison.vsSelector,
	)!;

	const tableGroupRows = useMemo(() => {
		const groupList = groupBy(list, ({ group }) => String(group.index));
		return customObjectValuesFn(groupList).map((group) => ({
			...group![0].group,
			rows:
				group?.map(({ drilldown }) => ({
					...drilldown,
				})) ?? [],
		}));
	}, [list]);

	if (list.length === 0) {
		return <></>;
	}

	return (
		<Card title="Performance and volatility contribution" subTitle={opts.label}>
			<TableWithGroups
				palette="uniform"
				groupRowKey={(r) => r.index}
				expandGroupByKey={Immutable.Set(tableGroupRows.map(({ index }) => index))}
				groupedRows={tableGroupRows}
				classList="pointer-events-none [&_.transition-\[height\]]:!transition-none [&_.transition-\[transform\,opacity\]]:!transition-none"
				columns={[
					{
						header: contributionT.TABLE[opts.selector as keyof typeof contributionT.TABLE],

						groupContent: (groupedRow) => <strong>{groupedRow.value as any /* TODO: fix types */}</strong>,
						content: (row) => row.value as any /* TODO: fix types */,

						orderable: false,
						relativeWidth: 20,
					},
					{
						header: <span className="whitespace-pre-line">{"PORTFOLIO\nPERFORMANCE"}</span>,
						groupContent: (groupedRow) => (
							<strong className="text-[#005C8B] tabular-nums">
								{formatNumber(groupedRow.computedData.performanceValue, 2)}%
							</strong>
						),
						content: (row) => (
							<span className="text-[#005C8B] tabular-nums">{formatNumber(row.computedData.performanceValue, 2)}%</span>
						),

						orderable: false,
						relativeWidth: 15,
					},
					{
						orderable: false,
						header: (
							<div className="flex grow justify-between">
								{graphColumnMarkers.markersPerformance.map((m, i) => (
									<div key={`marker-${i}`} className="">
										{m.label}
									</div>
								))}
							</div>
						),
						groupContent: ({ computedData: { performanceValue } }) => {
							return (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimit.maxPerformance, min: graphLimit.minPerformance },
									}}
									data={[{ value: performanceValue, color: "#005C8B" }]}
								/>
							);
						},
						content: ({ computedData: { performanceValue } }) => {
							return (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimit.maxPerformance, min: graphLimit.minPerformance },
									}}
									data={[{ value: performanceValue, color: "#005C8B" }]}
								/>
							);
						},
						relativeWidth: 20,
					},
					{
						header: <span>{"PORTFOLIO\nVOLATILITY"}</span>,
						groupContent: (groupedRow) => (
							<strong className="text-[#572C7F] tabular-nums">
								{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
							</strong>
						),
						content: (row) => (
							<span className="text-[#572C7F] tabular-nums">{formatNumber(row.computedData.volatilityValue, 2)}%</span>
						),

						orderable: false,
						relativeWidth: 10,
					},
					{
						orderable: false,
						header: (
							<div className="flex grow justify-between">
								{graphColumnMarkers.markersVolatility.map((m, i) => (
									<div key={`marker-${i}`} className="">
										{m.label}
									</div>
								))}
							</div>
						),
						groupContent: ({ computedData: { volatilityValue } }) => {
							return (
								<BarGraphPCSvg
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
									}}
									data={[{ value: volatilityValue, color: "#572C7F" }]}
								/>
							);
						},
						content: ({ computedData: { volatilityValue } }) => {
							return (
								<BarGraphPCSvg
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
									}}
									data={[{ value: volatilityValue, color: "#572C7F" }]}
								/>
							);
						},
						relativeWidth: 20,
					},
				]}
			/>
		</Card>
	);
};

export default PerformanceVolatilityContribution;
