import type { CSSProperties } from "react";
import { memo } from "react";

export type TagBadgeProps = {
	color: string;
	children?: React.ReactNode;
	style?: CSSProperties;
	className?: string;
};

// TODO: rename to "Badge" as it's quite generic and move it the main components folder
function _TagBadge({ color, children, style, className }: TagBadgeProps): JSX.Element {
	return (
		<span
			style={{
				backgroundColor: color,
				color: "white",
				...style,
			}}
			className={`py-0.5 px-2 overflow-hidden rounded-full inline-block font-bold ${className ?? ""} truncate`}
		>
			{children}
		</span>
	);
}

export const TagBadge = memo(_TagBadge) as typeof _TagBadge;
