import { hasAccess } from "$root/components/AuthorizationGuard";
import { customObjectValuesFn } from "$root/utils/experimental";
import { Tab, TabGroup } from "@mdotm/mdotui/components";
import { switchExpr } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import type { IUser } from "../user";
import { useUserValue } from "../user";
import ExplainabilitySettings from "./settings/ExplainabilitySettings";
import MarketViewSetting from "./settings/MarketViewSettings";
import { PageHeader } from "$root/components/PageHeader";
import { PortfolioStudioSettingsCtx } from "./context";
import type { ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { useImperativeHandlesRef } from "$root/utils/react-extra";
import InstrumentCommentarySetting from "./settings/InstrumentCommentarySetting";
import ReportCustomisation from "./settings/ReportCustomisation";

export const PortfolioStudioSettingTabEnum = {
	MarketViewSetting: "Market view settings",
	ExplainabilitySettings: "Explainability options",
	InstrumentsCustomisation: "Instruments customisation",
	ReportCustomisation: "Report customisation",
} as const;

export type PortfolioStudioSettingTabEnum =
	(typeof PortfolioStudioSettingTabEnum)[keyof typeof PortfolioStudioSettingTabEnum];

const Tabs = {
	"Instruments customisation": {
		label: "Instruments customisation",
	},
	"Market view settings": {
		label: "Market view settings",
		hasAcces: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	"Explainability options": {
		label: "Explainability options",
		hasAcces: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	"Report customisation": {
		label: "Report customisation",
		hasAcces: (user) => hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" }),
	},
} as Record<
	PortfolioStudioSettingTabEnum,
	{ label: PortfolioStudioSettingTabEnum; hasAcces?: (user: IUser) => boolean }
>;

const StudioSettings = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const user = useUserValue();
	const TABS = useMemo(() => {
		return customObjectValuesFn(Tabs).flatMap((x) => {
			const guard = x.hasAcces;
			if (guard) {
				return guard(user) ? [x.label] : [];
			}

			return [x.label];
		});
	}, [user]);

	const currentTab = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		if (!queryParamTab) {
			return 0;
		}
		return TABS.findIndex((v) => v === queryParamTab);
	}, [TABS, location.search]);
	const imperativeHandlesRefs = useRef<ImperativePageHeaderHandles | null>(null);

	const setCurrentTab = useCallback(
		(newTab: number) => {
			if (currentTab === newTab) {
				return;
			}
			const newSearchParams = new URLSearchParams(location.search);
			newSearchParams.set("tab", String(TABS.find((_t, i) => i === newTab) ?? TABS[0]));
			history.replace({ pathname: location.pathname, search: newSearchParams.toString() });
		},
		[TABS, currentTab, history, location.pathname, location.search],
	);

	const SettingsComponent = useMemo(
		() =>
			switchExpr(TABS[currentTab], {
				"Explainability options": () => ExplainabilitySettings,
				"Market view settings": () => MarketViewSetting,
				"Instruments customisation": () => InstrumentCommentarySetting,
				"Report customisation": () => ReportCustomisation,
			}),
		[TABS, currentTab],
	);

	const prevTabRef = useRef(currentTab);
	if (currentTab !== prevTabRef.current) {
		imperativeHandlesRefs.current?.setHeaderSubtitle(headerPlaceholder);
		prevTabRef.current = currentTab; // should be alright as this doesn't change the output of the component
	}

	return (
		<>
			<ImperativePageHeader handlesRef={imperativeHandlesRefs} />
			<PortfolioStudioSettingsCtx.Provider
				value={useMemo(
					() => ({ setHeaderSubTitle: (node) => imperativeHandlesRefs.current?.setHeaderSubtitle(node) }),
					[],
				)}
			>
				<TabGroup palette="primary" tabIndex={currentTab} onTabChange={(n) => setCurrentTab(n)}>
					{TABS.map((tabname, idx) => (
						<Tab classList="rounded bg-white px-4 py-6 overflow-hidden" key={idx} title={tabname}>
							<SettingsComponent active={currentTab === idx} />
						</Tab>
					))}
				</TabGroup>
			</PortfolioStudioSettingsCtx.Provider>
		</>
	);
};

type ImperativePageHeaderHandles = { setHeaderSubtitle(node: ReactNode | null): void };
const headerPlaceholder = <div className="min-h-[54px]" />;

// component split to prevent infinite re-renders by localizing the state inside a subtree
function ImperativePageHeader({ handlesRef }: ImperativeHandlesRefProps<ImperativePageHeaderHandles>): JSX.Element {
	const [subTitle, setSubTitle] = useState<ReactNode | null>(null);
	useImperativeHandlesRef(handlesRef, {
		setHeaderSubtitle: setSubTitle,
	});
	return <PageHeader title="Portfolio studio settings" subTitle={subTitle} />;
}

export default StudioSettings;
