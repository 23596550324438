import type { ReviewTicker, SelectableBasket, SelectableBasketBasketTypeEnum } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useInstrumentColumns } from "$root/functional-areas/instruments/hooks";
import { type rowType } from "$root/functional-areas/instruments/instrumentEditorTable";
import { type UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn, customObjectKeysFn } from "$root/utils/experimental";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { DialogProps, Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	Select,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useDebouncedSearch, useMultiSelect } from "@mdotm/mdotui/headless";
import { generateUniqueDOMId, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { useSearchableInstrumentTable } from "../../instruments/hooks";
import type { CompositionEditorAreaProps } from "../EditorComposition";
import { instrumentFilters } from "../tools/const";

type ExtendedReviewTicker = ReviewTicker & { id: string; rowType: rowType };
export type InstrumentsDialogProps = {
	area: CompositionEditorAreaProps;
	entity: UploadEntity;
	instruments: string[];
	opts?: { mode?: "combined" | "default" };
	show: boolean;
	onClose(): void;
	onSubmit(instruments: Array<ExtendedReviewTicker>): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

function eraseDuplicate(tickers?: Array<ExtendedReviewTicker>) {
	let duplicatedAlias = (tickers ?? []).reduce(
		(acc, el) => {
			const alias = el.alias;

			if (!alias) {
				return acc;
			}

			if (acc.comp.includes(alias)) {
				return { ...acc, included: [...acc.included, alias] };
			}

			return { ...acc, comp: [...acc.comp, alias] };
		},
		{ comp: [] as string[], included: [] as string[] },
	);

	return tickers?.filter((x) => {
		const someDuplicate = duplicatedAlias?.included;
		if (someDuplicate === undefined || someDuplicate.length === 0) {
			return true;
		}
		const indexOfDuplicate = someDuplicate.indexOf(x.alias ?? "") ?? -1;
		if (indexOfDuplicate >= 0) {
			duplicatedAlias = { ...duplicatedAlias, included: someDuplicate.splice(indexOfDuplicate, 1) };
			return false;
		}

		return true;
	});
}

const defaultFilters = {
	assetClass: [] as string[],
	geography: [] as string[],
	microAssetClass: [] as string[],
	baskets: [] as SelectableBasket[],
	instrumentType: [] as string[],
	granularity: [] as string[],
};

export const InstrumentsDialog = ({
	area,
	entity,
	instruments,
	opts,
	show,
	onClose,
	onSubmit,
	onAnimationStateChange,
}: InstrumentsDialogProps): JSX.Element => {
	const { mode = "default" } = opts ?? {};
	const [filters, setFilters] = useState(defaultFilters);

	const { t } = useTranslation();
	const multiSelectCtx = useMultiSelect<string>();
	const instrumentColumns = useInstrumentColumns("InstrumentList/Table");
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);

	const { data, isLoading } = useQueryNoRefetch(["queryAssetClasses", filters.baskets], {
		queryFn: async () => {
			const selectablePayload = { entity, selectableBaskets: filters.baskets };
			const { availableTickers } = await match(area)
				.with({ name: "edit" }, (x) =>
					axiosExtract(entityEditorApi.getEditorEditSelectableInstruments(x.uuid, selectablePayload)),
				)
				.with({ name: "create" }, () =>
					axiosExtract(entityEditorApi.getEditorNewSelectableInstruments(selectablePayload)),
				)
				.exhaustive();

			const tickerWithIds = availableTickers?.map((el) => ({
				id: generateUniqueDOMId(),
				rowType: "select" as rowType,
				...el,
			}));

			return eraseDuplicate(tickerWithIds);
		},
	});

	const { data: baskets } = useQueryNoRefetch(["querySelectBaskets", area], {
		queryFn: () =>
			match(area)
				.with({ name: "edit" }, (x) => axiosExtract(entityEditorApi.getEditorEditSelectableBaskets(x.uuid, entity)))
				.with({ name: "create" }, () => axiosExtract(entityEditorApi.getEditorNewSelectableBaskets2(entity)))
				.exhaustive(),
	});

	const filteredRows = useMemo(() => {
		function includesGuardFn(filteredKeys: string[], field: string) {
			if (filteredKeys.length === 0) {
				return true;
			}
			return filteredKeys.filter((k) => field.toLowerCase().search(k.toLowerCase()) >= 0).length > 0;
		}

		const filterKeyList = (Object.keys(filters) as Array<keyof typeof filters>).map((key) => filters[key]).flat();

		if (filterKeyList.length === 0) {
			return data;
		}

		return data?.filter((row) => {
			return (
				includesGuardFn(filters.assetClass, row.assetClass ?? "") &&
				includesGuardFn(filters.geography, row.microAssetClass ?? "") &&
				includesGuardFn(filters.microAssetClass, row.microAssetClass ?? "") &&
				includesGuardFn(filters.instrumentType, row.type ?? "") &&
				includesGuardFn(filters.granularity, row.granularity ?? "")
			);
		});
	}, [filters, data]);

	const { debouncedNormalizedQuery, query, setQuery } = useDebouncedSearch("", 500);
	const searchable = useSearchableInstrumentTable(filteredRows ?? [], {
		mode: "keyword",
		query: debouncedNormalizedQuery,
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows: searchable.filtered,
		multiSelectCtx,
		selectBy: ({ ticker }) => ticker ?? "",
		mode: "checkbox",
		preSelectedRowIds: instruments,
	});

	const memoCategories = useMemo(() => {
		const granularity = data?.reduce<{ [key: string]: { value: string; label: string } }>((granularityMap, ac) => {
			if (!ac.granularity) {
				return granularityMap;
			}

			return {
				...granularityMap,
				[ac.granularity]: { value: ac.granularity, label: ac.granularity },
			};
		}, {});

		instrumentFilters.granularity = Object.values(granularity ?? []);

		return customObjectEntriesFn(instrumentFilters).reduce<{
			[K in keyof typeof instrumentFilters]: Option<string>[];
		}>(
			(acc, [category, values]) => {
				return {
					...acc,
					[category]: values
						.filter((filterValue) => {
							if (!data) {
								return false;
							}
							return data?.some((row) => {
								// unify function to validate filter options
								if (category === "assetClass") {
									return row.assetClass?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								if (category === "instrumentType") {
									return row.type?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								if (category === "granularity") {
									return row.granularity?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								return row.microAssetClass?.toLowerCase()?.includes(filterValue.value.toLowerCase());
							});
						}) // checking if there existing ac within the fallback filters
						.sort()
						.map((el) => ({ label: el.label, value: el.value, disabled: false })),
				};
			},
			{ assetClass: [], geography: [], microAssetClass: [], instrumentType: [], granularity: [] },
		);
	}, [data]);

	const basketOptions = useMemo(() => {
		const BasketMap: Record<SelectableBasketBasketTypeEnum | "Default", string> = {
			INVESTMENT: "Portfolios",
			UNIVERSE: "Universes",
			TEMPLATE: "Universe templates",
			TARGET_INVESTMENT: "Target potfolios",
			BENCHMARK: "Custom benchmarks",
			Default: "Others",
		};
		return (
			baskets?.selectableBaskets
				?.filter((el) => el.basketName && el.basketIdentifier)
				.map(
					(el): OptionWithOptionalGroup<SelectableBasket, string> => ({
						label: el.basketName!,
						value: el,
						disabled: false,
						group: BasketMap[el.basketType ?? "Default"],
					}),
				) ?? []
		);
	}, [baskets?.selectableBaskets]);

	const optionsByCategory = useMemo(() => {
		if (mode === "combined") {
			return memoCategories;
		}
		const categories = customObjectKeysFn(memoCategories);
		const clickableFilters = categories.reduce<{
			[K in keyof typeof memoCategories]: Array<{ value: string; counter: number }>;
		}>(
			(acc, filterCategory) => {
				acc = {
					...acc,
					[filterCategory]: memoCategories[filterCategory].map((filterValue) => ({
						value: filterValue.value,
						counter:
							searchable.filtered?.filter((y) => {
								// unify function to validate filter options
								if (filterCategory === "assetClass") {
									return y.assetClass?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								if (filterCategory === "instrumentType") {
									return y.type?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								if (filterCategory === "granularity") {
									return y.granularity?.toLowerCase().includes(filterValue.value.toLowerCase());
								}

								return y.microAssetClass?.toLowerCase()?.includes(filterValue.value.toLowerCase());
							}).length ?? 0,
					})),
				};
				return acc;
			},
			{ assetClass: [], geography: [], microAssetClass: [], instrumentType: [], granularity: [] },
		);

		return customObjectEntriesFn(clickableFilters).reduce(
			(acc, [filterCategoryKey, filterCategoryValue]) => {
				if (filterCategoryValue.length === 0) {
					acc[filterCategoryKey] = acc[filterCategoryKey].map((el) => ({ ...el, disabled: true }));
					return acc;
				}

				acc[filterCategoryKey] = acc[filterCategoryKey].map((filter) => {
					const exactFilter = filterCategoryValue.find((x) => filter.value === x.value);
					return {
						...filter,
						disabled: exactFilter === undefined || exactFilter.counter === 0,
						label: `${filter.label} ${exactFilter && exactFilter.counter > 0 ? `(${exactFilter.counter})` : ""}`,
					};
				});
				return acc;
			},
			{ ...memoCategories },
		);
	}, [mode, memoCategories, searchable.filtered]);

	const columns = useMemo(
		() => [
			checkBoxColumn,
			{ ...instrumentColumns.instrument },
			{ ...instrumentColumns.alias },
			{ ...instrumentColumns.assetClass },
			{ ...instrumentColumns.microAssetClass },
		],
		[
			checkBoxColumn,
			instrumentColumns.alias,
			instrumentColumns.assetClass,
			instrumentColumns.instrument,
			instrumentColumns.microAssetClass,
		],
	);

	const onConfirm = useCallback(() => {
		const selection = data?.filter((ac) => ac.ticker && multiSelectCtx.data.selection.has(ac.ticker));
		onSubmit(selection ?? []);
	}, [data, multiSelectCtx.data.selection, onSubmit]);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onClose={onClose}
			onAnimationStateChange={onAnimationStateChange}
			header="Select"
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<Button onClick={onConfirm} palette="primary">
							{t("BUTTON.CONFIRM")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<TextInput
					placeholder="Filter by name"
					classList="max-w-[280px]"
					value={query}
					onChangeText={setQuery}
					data-qualifier="InstrumentModal/Input/Search"
				/>
			</div>
			<div className="flex items-center gap-4 mb-2">
				<p className="font-semibold">Filter by: </p>
				<Select
					classList="flex-1"
					options={basketOptions}
					value={filters.baskets}
					multi
					i18n={{ triggerPlaceholder: () => "Basket" }}
					onChange={(selectedBasket) => {
						setFilters(() => ({
							baskets: selectedBasket,
							assetClass: [],
							geography: [],
							granularity: [],
							instrumentType: [],
							microAssetClass: [],
						}));
					}}
					disabled={basketOptions.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Basket)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Basket)")}
				/>
				<Select
					classList="flex-1"
					options={optionsByCategory.granularity}
					value={filters.granularity}
					multi
					i18n={{ triggerPlaceholder: () => "Granularity" }}
					onChange={(granularity) => setFilters((cur) => ({ ...cur, granularity }))}
					disabled={optionsByCategory.granularity.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Granularity)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Granularity)")}
				/>
				<Select
					classList="flex-1"
					options={optionsByCategory.instrumentType}
					value={filters.instrumentType}
					multi
					i18n={{ triggerPlaceholder: () => "Asset type" }}
					onChange={(instrumentType) => setFilters((cur) => ({ ...cur, instrumentType }))}
					disabled={optionsByCategory.instrumentType.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Asset Type)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Asset Type)")}
				/>
				<Select
					classList="flex-1"
					options={optionsByCategory.assetClass}
					value={filters.assetClass}
					multi
					i18n={{ triggerPlaceholder: () => "Macro asset class" }}
					onChange={(assetClass) => setFilters((cur) => ({ ...cur, assetClass }))}
					disabled={optionsByCategory.assetClass.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Marco Asset Class)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Marco Asset Class)")}
				/>
				<Select
					classList="flex-1"
					options={optionsByCategory.geography}
					value={filters.geography}
					multi
					i18n={{ triggerPlaceholder: () => "Geography" }}
					onChange={(geography) => setFilters((cur) => ({ ...cur, geography }))}
					disabled={optionsByCategory.geography.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Geography)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Geography)")}
				/>
				<Select
					classList="flex-1"
					options={optionsByCategory.microAssetClass}
					value={filters.microAssetClass}
					multi
					i18n={{ triggerPlaceholder: () => "Micro asset class" }}
					onChange={(microAssetClass) => setFilters((cur) => ({ ...cur, microAssetClass }))}
					disabled={optionsByCategory.microAssetClass.length === 0}
					enableSearch
					listboxAppearance={{ classList: "!max-h-[220px]" }}
					data-qualifier="InstrumentModal/Filter(Micro asset class)/Options"
					innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Micro asset class)")}
				/>
			</div>
			<Table
				rows={searchable.filtered}
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassList(row, rowIndex)),
					InstrumentListTableRow: true,
				})}
				columns={columns}
				classList="min-h-[410px]"
				visibleRows={Math.min(searchable.filtered.length, 9)}
				onRowClick={({ ticker }) => toggle(ticker ?? "")}
				enableVirtualScroll
				noDataText={
					isLoading ? (
						<div className="h-80 w-full flex justify-center items-center relative">
							<CircularProgressBar value="indeterminate" />
						</div>
					) : (
						"no instruments available"
					)
				}
			/>
		</Dialog>
	);
};
