import type { StylableProps } from "@mdotm/mdotui/components";
import { Text } from "@mdotm/mdotui/components";
import { toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type PositioningType = "STRONG_UNDERWEIGHT" | "OVERWEIGHT" | "NEUTRAL" | "UNDERWEIGHT" | "STRONG_OVERWEIGHT";
export const positioningPresets: Record<
	PositioningType,
	{ textColor: string; backgroundColor: string; label: string }
> = {
	STRONG_UNDERWEIGHT: {
		textColor: themeCSSVars.palette_R600,
		backgroundColor: themeCSSVars.palette_R50,
		label: "Strong underweight",
	},
	UNDERWEIGHT: {
		textColor: themeCSSVars.palette_R500,
		backgroundColor: themeCSSVars.palette_R50,
		label: "Underweight",
	},
	NEUTRAL: {
		textColor: themeCSSVars.palette_S400,
		backgroundColor: themeCSSVars.palette_S50,
		label: "Neutral",
	},
	OVERWEIGHT: {
		textColor: themeCSSVars.palette_P400,
		backgroundColor: themeCSSVars.palette_P50,
		label: "Overweight",
	},
	STRONG_OVERWEIGHT: {
		textColor: themeCSSVars.palette_P600,
		backgroundColor: themeCSSVars.palette_P50,
		label: "Strong overweight",
	},
};

export const positioningValueTypeMap: Record<1 | 2 | 3 | 4 | 5, PositioningType> = {
	1: "STRONG_UNDERWEIGHT",
	2: "UNDERWEIGHT",
	3: "NEUTRAL",
	4: "OVERWEIGHT",
	5: "STRONG_OVERWEIGHT",
};

function PositioningBadge({
	positioning,
	classList,
	style,
}: { positioning: PositioningType } & StylableProps): JSX.Element {
	return (
		<div
			className={toClassName({
				"relative z-0 py-1 px-4 overflow-hidden rounded-[4px]": true,
				...toClassListRecord(classList),
			})}
			style={{
				backgroundColor: positioningPresets[positioning].backgroundColor,
				...style,
			}}
		>
			<div
				className="absolute inset-y-0 left-0 border-r-[6px]"
				style={{
					borderColor: positioningPresets[positioning].textColor,
				}}
			/>
			<Text type="Title/S" style={{ textTransform: "capitalize" }} color={positioningPresets[positioning].textColor}>
				{positioningPresets[positioning].label}
			</Text>
		</div>
	);
}

export default PositioningBadge;
