import { objectMap } from "$root/utils/objects";
import { AutoTooltip, ScrollWrapper, TooltipContent } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { Fragment, useMemo } from "react";

export type ConstraintAdder<Types extends string> = {
	maxOfType?: number;
	disabled?: boolean;
	hidden?: boolean;
	limitTooltip?: ReactNode;
	label: string | ReactNode;
	type: Types;
	status?: "calculating";
};

export type AddConstraintSectionProps<Types extends string> = {
	constraintAdders: Array<ConstraintAdder<Types>>;
	constraintList: Array<{ type: Types }>;
	onAdd(type: Types): void;
	customLabel?: string;
};

export function AddConstraintSection<Types extends string>({
	constraintAdders,
	constraintList,
	onAdd,
	customLabel = "Add Constraint",
}: AddConstraintSectionProps<Types>): JSX.Element {
	const constraintCounters = useMemo(
		() =>
			objectMap(
				groupBy(constraintList, (constraint) => constraint.type),
				(v) => v?.length,
			),
		[constraintList],
	);
	return (
		<div
			data-qualifier="portfolioWizard/constraintListAdders"
			className={`bg-[${themeCSSVars.global_palette_neutral_50}] z-0 relative -mx-4 mb-6 border-y border-[${themeCSSVars.global_palette_neutral_200}]`}
		>
			<div className="font-bold text-[16px] relative top-[16px] px-4">{customLabel}</div>
			<ScrollWrapper
				direction="horizontal"
				// endShadow={({ style, classList, scrollTillEnd }) => (
				// 	<div
				// 		className={toClassName({
				// 			"absolute inset-0 pointer-events-none": true,
				// 			...toClassListRecord(classList),
				// 		})}
				// 		style={{
				// 			...style,
				// 			opacity: scrollTillEnd < 10 ? 0 : 1,
				// 		}}
				// 	>
				// 		<ContentShadow position="right" show style={{ zIndex: 0 }} />
				// 		<div
				// 			className={`absolute right-0 top-0 flex space-x-2 z-10 items-center text-[color:${themeCSSVars.global_palette_neutral_400}]`}
				// 		>
				// 			<span className="text-[10px] font-medium">MORE</span>
				// 			<Icon size={16} icon="Right1" />
				// 		</div>
				// 	</div>
				// )}
			>
				<div className="flex space-x-2 pb-5 pt-[25px] px-4">
					{constraintAdders.map((x, i) => {
						const maxReached = typeof x.maxOfType === "number" && (constraintCounters[x.type] ?? 0) >= x.maxOfType;
						const disabled = Boolean(x.disabled);
						const disabledOrMaxReached = disabled || maxReached;
						if (x.hidden) {
							return <Fragment key={i} />;
						}

						return (
							<div
								key={i}
								className={toClassName({
									"relative z-0": true,
									"transition-transform hover:-translate-y-1": !disabledOrMaxReached,
								})}
							>
								<div
									className={toClassName({
										[`flex transition-[shadow,opacity] flex-col justify-end relative z-0 w-[146px] h-28 bg-[${themeCSSVars.global_palette_neutral_0}] border border-[${themeCSSVars.global_palette_neutral_300}] rounded-[4px] px-4 py-2`]:
											true,
										"opacity-60": disabledOrMaxReached,
										"shadow-md": !disabledOrMaxReached,
									})}
								>
									<div className="mb-2">
										{/* TODO: replace with <Icon icon={...} /> */}
										{x.status === "calculating" ? (
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="animate-spin ease-in-out"
											>
												<rect width="24" height="24" rx="12" fill="white" />
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M2 12C2 6.48889 6.48889 2 12 2C17.5111 2 22 6.48889 22 12C22 17.5111 17.5111 22 12 22C6.48889 22 2 17.5111 2 12ZM12.1532 5.63636C11.3151 5.63636 10.6328 6.31329 10.6328 7.15139C10.6328 7.98948 11.3151 8.66641 12.1532 8.66641C12.9913 8.66641 13.6736 7.98948 13.6736 7.15139C13.6736 6.31329 12.9967 5.63636 12.1532 5.63636ZM7.0435 8.704C7.0435 7.94112 7.66133 7.32329 8.42421 7.32329C9.1871 7.32329 9.80493 7.94112 9.80493 8.704C9.80493 9.46689 9.1871 10.0847 8.42421 10.0847C7.66133 10.0847 7.0435 9.46689 7.0435 8.704ZM8.11842 12.4271C8.11842 11.7448 7.55969 11.1861 6.87739 11.1861C6.1951 11.1861 5.63636 11.7448 5.63636 12.4271C5.63636 13.1094 6.1951 13.6681 6.87739 13.6681C7.56506 13.6681 8.11842 13.1148 8.11842 12.4271ZM7.64017 15.3712C7.21038 15.801 7.21038 16.4994 7.64017 16.9292C8.06997 17.359 8.76838 17.359 9.20355 16.9292C9.63871 16.4994 9.63334 15.801 9.20355 15.3712C8.77375 14.9414 8.07534 14.9414 7.64017 15.3712ZM11.1859 17.402C11.1859 16.8647 11.6211 16.4349 12.1529 16.4349C12.6848 16.4349 13.12 16.8647 13.12 17.402C13.12 17.9392 12.6902 18.369 12.1529 18.369C11.6157 18.369 11.1859 17.9392 11.1859 17.402ZM17.4284 11.7341C17.047 11.7341 16.7408 12.0403 16.7408 12.4217C16.7408 12.8032 17.047 13.1094 17.4284 13.1094C17.8099 13.1094 18.1215 12.8032 18.1215 12.4217C18.1215 12.0403 17.8099 11.7341 17.4284 11.7341ZM15.2957 16.7358C14.9734 16.4134 14.9734 15.8869 15.2957 15.5646C15.6181 15.2422 16.1446 15.2422 16.4669 15.5646C16.7893 15.8869 16.7893 16.4134 16.4669 16.7358C16.1446 17.0581 15.6181 17.0581 15.2957 16.7358ZM15.9349 9.19828C16.2412 9.19828 16.4883 8.95115 16.4883 8.64492C16.4883 8.33869 16.2412 8.09156 15.9349 8.09156C15.6287 8.09156 15.3816 8.33869 15.3816 8.64492C15.3816 8.95115 15.6287 9.19828 15.9349 9.19828Z"
													fill="#FFBE00"
												/>
											</svg>
										) : maxReached ? (
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
												<circle cx="12" cy="12" r="7.5" stroke="#BFC4CE" />
												<path
													d="M10.7743 14.7877C10.7077 14.7877 10.6452 14.7772 10.5868 14.7562C10.5285 14.7355 10.4743 14.7002 10.4243 14.6502L8.27433 12.5002C8.18266 12.4085 8.13883 12.2897 8.14283 12.1437C8.14716 11.998 8.19516 11.8794 8.28683 11.7877C8.3785 11.696 8.49516 11.6502 8.63683 11.6502C8.7785 11.6502 8.89516 11.696 8.98683 11.7877L10.7743 13.5752L15.0118 9.3377C15.1035 9.24603 15.2223 9.2002 15.3683 9.2002C15.514 9.2002 15.6327 9.24603 15.7243 9.3377C15.816 9.42936 15.8618 9.54803 15.8618 9.6937C15.8618 9.8397 15.816 9.95853 15.7243 10.0502L11.1243 14.6502C11.0743 14.7002 11.0202 14.7355 10.9618 14.7562C10.9035 14.7772 10.841 14.7877 10.7743 14.7877Z"
													fill="#585D68"
												/>
												<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#BFC4CE" />
											</svg>
										) : (
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
												<path
													d="M11.9998 4.67123C10.0409 4.67123 8.19714 5.43176 6.81436 6.81455C3.9566 9.67231 3.9566 14.3277 6.81436 17.1854C9.67211 20.0432 14.3275 20.0432 17.1853 17.1854C20.043 14.3277 20.043 9.67231 17.1853 6.81455C15.8025 5.43176 13.9588 4.67123 11.9998 4.67123ZM16.056 12C16.056 12.2189 15.9696 12.4321 15.814 12.5877C15.6584 12.7432 15.4452 12.8297 15.2263 12.8297L12.841 12.8297L12.841 15.215C12.841 15.6759 12.4665 16.0504 12.0056 16.0504C11.7866 16.0504 11.5734 15.964 11.4179 15.8084C11.2623 15.6529 11.1759 15.4397 11.1759 15.2207L11.1759 12.8354H8.7445C8.30085 12.8181 7.95515 12.4494 7.94939 12.0058C7.94939 11.5448 8.3239 11.1703 8.78483 11.1703L11.1701 11.1703L11.1701 8.78502C11.1701 8.33562 11.5562 7.94959 12.0056 7.94959C12.455 7.94959 12.818 8.30105 12.8352 8.74469L12.841 8.78502L12.841 11.1703L15.2263 11.1703C15.4452 11.1703 15.6584 11.2568 15.814 11.4123C15.9696 11.5679 16.056 11.7811 16.056 12Z"
													fill="#A0A7B6"
												/>
												<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#BFC4CE" />
											</svg>
										)}
									</div>
									<div
										className={`text-[color:${themeCSSVars.global_palette_neutral_700}] font-bold text-[12px] h-8 mb-1.5`}
									>
										{x.label}
									</div>
									{x.status === "calculating" ? (
										<div className={`text-[color:${themeCSSVars.palette_N600}] h-4 font-bold text-[10px]`}>
											Calculating limits
										</div>
									) : (
										<div
											className={`text-[color:${themeCSSVars.global_palette_primary_600}] h-4 font-bold text-[10px]`}
										>
											{typeof constraintCounters[x.type] === "number" && <>{constraintCounters[x.type]} constraints</>}
										</div>
									)}
								</div>
								<button
									data-qualifier={`portfolioWizard/constraintListAdders/add(${x.type})`}
									className="absolute w-full inset-0 h-full z-10"
									disabled={disabledOrMaxReached}
									type="button"
									onClick={() => onAdd(x.type)}
									aria-label="add"
								/>
								{disabledOrMaxReached && x.limitTooltip && (
									<AutoTooltip
										severity="info"
										position="bottom"
										overrideColor={themeCSSVars.global_palette_neutral_300}
										mode="hover"
										trigger={({ innerRef }) => <div ref={innerRef} className="absolute w-full inset-0 h-full z-10" />}
									>
										<TooltipContent>{x.limitTooltip}</TooltipContent>
									</AutoTooltip>
								)}
							</div>
						);
					})}
				</div>
			</ScrollWrapper>
		</div>
	);
}
