type NotificationTypes = "ROOT" | "PORTFOLIO" | "HMM" | "MARKET";

type NotificationMapperParams = {
	name: string;
	enable: boolean;
	threshold: number | null | string;
	type: NotificationTypes;
	formDisabled: boolean;
	path: string;
	child: string[];
};

export type InotificationMapper = {
	ROOT: NotificationMapperParams & {
		PortfolioAlertCheck: NotificationMapperParams & {
			PortfolioStatusUpdateAlertCheck: NotificationMapperParams;
			PortfolioMonitoringAlertCheck: NotificationMapperParams & {
				PortfolioBreachesAlertCheck: NotificationMapperParams & {
					RiskConstraintAlertCheck: NotificationMapperParams;
					AssetAllocationExposureConstraintsAlertCheck: NotificationMapperParams & {
						EquityAssetAllocationExposureConstraintsAlertCheck: NotificationMapperParams;
						FixedIncomeAssetAllocationExposureConstraintsAlertCheck: NotificationMapperParams;
						CommoditiesAssetAllocationExposureConstraintsAlertCheck: NotificationMapperParams;
					};
					ScoreBreachedAlertCheck: NotificationMapperParams;
					TagBreachedAlertCheck: NotificationMapperParams;
				};
				ConstraintEarlyWarningsAlertCheck: NotificationMapperParams & {
					RiskConstraintWarningAlertCheck: NotificationMapperParams;
					AssetAllocationExposureWarningAlertCheck: NotificationMapperParams & {
						EquityAssetAllocationExposureWarningAlertCheck: NotificationMapperParams;
						FixedIncomeAssetAllocationExposureWarningAlertCheck: NotificationMapperParams;
						CommoditiesAssetAllocationExposureWarningAlertCheck: NotificationMapperParams;
					};
					ScoreWarningAlertCheck: NotificationMapperParams;
					TagWarningAlertCheck: NotificationMapperParams;
				};
				InstrumentsChangesAlertCheck: NotificationMapperParams & {
					InstrumentsChangesChildAlertCheck: NotificationMapperParams;
				};
				MarketScenariosAlertCheck: NotificationMapperParams & {
					MarketViewExpiredAlertCheck: NotificationMapperParams;
					MarketViewAlignmentAlertCheck: NotificationMapperParams & {
						GrowthMarketViewAlignmentAlertCheck: NotificationMapperParams;
						LateralPhaseMarketViewAlignmentAlertCheck: NotificationMapperParams;
						HighStressMarketViewAlignmentAlertCheck: NotificationMapperParams;
					};
				};
			};
		};
		MarketAlertCheck: NotificationMapperParams & {
			MarketUpdateAlertCheck: NotificationMapperParams & {
				MarketPositioningChangesAlertCheck: NotificationMapperParams & {
					EquityMarketPositioningAlertCheck: NotificationMapperParams;
					FixedIncomeMarketPositioningAlertCheck: NotificationMapperParams;
					CommoditiesMarketPositioningAlertCheck: NotificationMapperParams;
				};
				MarketRegimeChangesAlertCheck: NotificationMapperParams & {
					EquityMarketRegimeChangesAlertCheck: NotificationMapperParams;
					FixedIncomeMarketRegimeChangesAlertCheck: NotificationMapperParams;
					CommoditiesMarketRegimeChangesAlertCheck: NotificationMapperParams;
				};
				MarketRegimeEarlySignals: NotificationMapperParams & {
					EquityUsMarketRegimeEarlySignals: NotificationMapperParams & {
						GrowthEquityUsMarketRegimeEarlySignals: NotificationMapperParams;
						LateralPhaseEquityUsMarketRegimeEarlySignals: NotificationMapperParams;
						HighStressEquityUsMarketRegimeEarlySignals: NotificationMapperParams;
					};
					EquityEUMarketRegimeEarlySignals: NotificationMapperParams & {
						GrowthEquityEUMarketRegimeEarlySignals: NotificationMapperParams;
						LateralPhaseEquityEUMarketRegimeEarlySignals: NotificationMapperParams;
						HighStressEquityEUMarketRegimeEarlySignals: NotificationMapperParams;
					};
				};
			};
		};
		HmmAlertCheck: NotificationMapperParams;
	};
};

export const notificationMapper: InotificationMapper = {
	ROOT: {
		PortfolioAlertCheck: {
			enable: false,
			formDisabled: true,
			name: "PortfolioAlertCheck",
			path: "ROOT.PortfolioAlertCheck",
			threshold: null,
			type: "PORTFOLIO",
			child: ["PortfolioStatusUpdateAlertCheck", "PortfolioMonitoringAlertCheck"],
			PortfolioStatusUpdateAlertCheck: {
				name: "PortfolioStatusUpdateAlertCheck",
				enable: false,
				threshold: null,
				type: "PORTFOLIO",
				formDisabled: true,
				path: "ROOT.PortfolioAlertCheck.PortfolioStatusUpdateAlertCheck",
				child: [],
			},
			PortfolioMonitoringAlertCheck: {
				PortfolioBreachesAlertCheck: {
					RiskConstraintAlertCheck: {
						name: "RiskConstraintAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.RiskConstraintAlertCheck",
						child: [],
					},
					AssetAllocationExposureConstraintsAlertCheck: {
						child: [
							"EquityAssetAllocationExposureConstraintsAlertCheck",
							"FixedIncomeAssetAllocationExposureConstraintsAlertCheck",
							"CommoditiesAssetAllocationExposureConstraintsAlertCheck",
						],
						EquityAssetAllocationExposureConstraintsAlertCheck: {
							name: "EquityAssetAllocationExposureConstraintsAlertCheck",
							enable: false,
							threshold: null,
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.AssetAllocationExposureConstraintsAlertCheck.EquityAssetAllocationExposureConstraintsAlertCheck",
							child: [],
						},
						name: "AssetAllocationExposureConstraintsAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.AssetAllocationExposureConstraintsAlertCheck",
						FixedIncomeAssetAllocationExposureConstraintsAlertCheck: {
							name: "FixedIncomeAssetAllocationExposureConstraintsAlertCheck",
							enable: false,
							threshold: null,
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.AssetAllocationExposureConstraintsAlertCheck.FixedIncomeAssetAllocationExposureConstraintsAlertCheck",
							child: [],
						},
						CommoditiesAssetAllocationExposureConstraintsAlertCheck: {
							name: "CommoditiesAssetAllocationExposureConstraintsAlertCheck",
							enable: false,
							threshold: null,
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.AssetAllocationExposureConstraintsAlertCheck.CommoditiesAssetAllocationExposureConstraintsAlertCheck",
							child: [],
						},
					},
					TagBreachedAlertCheck: {
						name: "TagBreachedAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.TagBreachedAlertCheck",
						child: [],
					},
					ScoreBreachedAlertCheck: {
						name: "ScoreBreachedAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck.ScoreBreachedAlertCheck",
						child: [],
					},
					name: "PortfolioBreachesAlertCheck",
					enable: false,
					threshold: null,
					type: "PORTFOLIO",
					formDisabled: true,
					path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.PortfolioBreachesAlertCheck",
					child: [
						"RiskConstraintAlertCheck",
						"AssetAllocationExposureConstraintsAlertCheck",
						"TagBreachedAlertCheck",
						"ScoreBreachedAlertCheck",
					],
				},
				ConstraintEarlyWarningsAlertCheck: {
					RiskConstraintWarningAlertCheck: {
						name: "RiskConstraintWarningAlertCheck",
						enable: false,
						threshold: "1",
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.RiskConstraintWarningAlertCheck",
						child: [],
					},
					AssetAllocationExposureWarningAlertCheck: {
						EquityAssetAllocationExposureWarningAlertCheck: {
							name: "EquityAssetAllocationExposureWarningAlertCheck",
							enable: false,
							threshold: "2",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.AssetAllocationExposureWarningAlertCheck.EquityAssetAllocationExposureWarningAlertCheck",
							child: [],
						},
						FixedIncomeAssetAllocationExposureWarningAlertCheck: {
							name: "FixedIncomeAssetAllocationExposureWarningAlertCheck",
							enable: false,
							threshold: "2",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.AssetAllocationExposureWarningAlertCheck.FixedIncomeAssetAllocationExposureWarningAlertCheck",
							child: [],
						},
						CommoditiesAssetAllocationExposureWarningAlertCheck: {
							name: "CommoditiesAssetAllocationExposureWarningAlertCheck",
							enable: false,
							threshold: "2",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.AssetAllocationExposureWarningAlertCheck.CommoditiesAssetAllocationExposureWarningAlertCheck",
							child: [],
						},
						name: "AssetAllocationExposureWarningAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.AssetAllocationExposureWarningAlertCheck",
						child: [
							"EquityAssetAllocationExposureWarningAlertCheck",
							"FixedIncomeAssetAllocationExposureWarningAlertCheck",
							"CommoditiesAssetAllocationExposureWarningAlertCheck",
						],
					},
					ScoreWarningAlertCheck: {
						name: "ScoreWarningAlertCheck",
						enable: false,
						threshold: "2",
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.ScoreWarningAlertCheck",
						child: [],
					},
					TagWarningAlertCheck: {
						name: "TagWarningAlertCheck",
						enable: false,
						threshold: 2,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck.TagWarningAlertCheck",
						child: [],
					},
					name: "ConstraintEarlyWarningsAlertCheck",
					enable: false,
					threshold: null,
					type: "PORTFOLIO",
					formDisabled: true,
					path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.ConstraintEarlyWarningsAlertCheck",
					child: [
						"TagWarningAlertCheck",
						"AssetAllocationExposureWarningAlertCheck",
						"RiskConstraintWarningAlertCheck",
						"ScoreWarningAlertCheck",
					],
				},
				InstrumentsChangesAlertCheck: {
					child: ["InstrumentsChangesChildAlertCheck"],
					InstrumentsChangesChildAlertCheck: {
						name: "InstrumentsChangesChildAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.InstrumentsChangesAlertCheck.InstrumentsChangesChildAlertCheck",
						child: [],
					},
					name: "InstrumentsChangesAlertCheck",
					enable: false,
					threshold: null,
					type: "PORTFOLIO",
					formDisabled: true,
					path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.InstrumentsChangesAlertCheck",
				},
				MarketScenariosAlertCheck: {
					MarketViewExpiredAlertCheck: {
						name: "MarketViewExpiredAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck.MarketViewExpiredAlertCheck",
						child: [],
					},
					MarketViewAlignmentAlertCheck: {
						GrowthMarketViewAlignmentAlertCheck: {
							name: "GrowthMarketViewAlignmentAlertCheck",
							enable: false,
							threshold: "10",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck.MarketViewAlignmentAlertCheck.GrowthMarketViewAlignmentAlertCheck",
							child: [],
						},
						LateralPhaseMarketViewAlignmentAlertCheck: {
							name: "LateralPhaseMarketViewAlignmentAlertCheck",
							enable: false,
							threshold: "10",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck.MarketViewAlignmentAlertCheck.LateralPhaseMarketViewAlignmentAlertCheck",
							child: [],
						},
						HighStressMarketViewAlignmentAlertCheck: {
							name: "HighStressMarketViewAlignmentAlertCheck",
							enable: false,
							threshold: "10",
							type: "PORTFOLIO",
							formDisabled: true,
							path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck.MarketViewAlignmentAlertCheck.HighStressMarketViewAlignmentAlertCheck",
							child: [],
						},
						name: "MarketViewAlignmentAlertCheck",
						enable: false,
						threshold: null,
						type: "PORTFOLIO",
						formDisabled: true,
						path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck.MarketViewAlignmentAlertCheck",
						child: [
							"GrowthMarketViewAlignmentAlertCheck",
							"LateralPhaseMarketViewAlignmentAlertCheck",
							"HighStressMarketViewAlignmentAlertCheck",
						],
					},
					name: "MarketScenariosAlertCheck",
					enable: false,
					threshold: null,
					type: "PORTFOLIO",
					formDisabled: true,
					path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck.MarketScenariosAlertCheck",
					child: ["MarketViewExpiredAlertCheck", "MarketViewAlignmentAlertCheck"],
				},
				name: "PortfolioMonitoringAlertCheck",
				enable: false,
				threshold: null,
				type: "PORTFOLIO",
				formDisabled: true,
				path: "ROOT.PortfolioAlertCheck.PortfolioMonitoringAlertCheck",
				child: [
					"PortfolioBreachesAlertCheck",
					"ConstraintEarlyWarningsAlertCheck",
					"InstrumentsChangesAlertCheck",
					"MarketScenariosAlertCheck",
				],
			},
		},
		MarketAlertCheck: {
			MarketUpdateAlertCheck: {
				MarketPositioningChangesAlertCheck: {
					EquityMarketPositioningAlertCheck: {
						name: "EquityMarketPositioningAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketPositioningChangesAlertCheck.EquityMarketPositioningAlertCheck",
						child: [],
					},
					FixedIncomeMarketPositioningAlertCheck: {
						name: "FixedIncomeMarketPositioningAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketPositioningChangesAlertCheck.FixedIncomeMarketPositioningAlertCheck",
						child: [],
					},
					CommoditiesMarketPositioningAlertCheck: {
						name: "CommoditiesMarketPositioningAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketPositioningChangesAlertCheck.CommoditiesMarketPositioningAlertCheck",
						child: [],
					},
					name: "MarketPositioningChangesAlertCheck",
					enable: false,
					threshold: null,
					type: "MARKET",
					formDisabled: true,
					path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketPositioningChangesAlertCheck",
					child: [
						"EquityMarketPositioningAlertCheck",
						"FixedIncomeMarketPositioningAlertCheck",
						"CommoditiesMarketPositioningAlertCheck",
					],
				},
				MarketRegimeChangesAlertCheck: {
					EquityMarketRegimeChangesAlertCheck: {
						name: "EquityMarketRegimeChangesAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeChangesAlertCheck.EquityMarketRegimeChangesAlertCheck",
						child: [],
					},
					FixedIncomeMarketRegimeChangesAlertCheck: {
						name: "FixedIncomeMarketRegimeChangesAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeChangesAlertCheck.FixedIncomeMarketRegimeChangesAlertCheck",
						child: [],
					},
					CommoditiesMarketRegimeChangesAlertCheck: {
						name: "CommoditiesMarketRegimeChangesAlertCheck",
						enable: false,
						threshold: "10",
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeChangesAlertCheck.CommoditiesMarketRegimeChangesAlertCheck",
						child: [],
					},
					name: "MarketRegimeChangesAlertCheck",
					enable: false,
					threshold: null,
					type: "MARKET",
					formDisabled: true,
					path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeChangesAlertCheck",
					child: [
						"EquityMarketRegimeChangesAlertCheck",
						"FixedIncomeMarketRegimeChangesAlertCheck",
						"CommoditiesMarketRegimeChangesAlertCheck",
					],
				},
				MarketRegimeEarlySignals: {
					EquityUsMarketRegimeEarlySignals: {
						GrowthEquityUsMarketRegimeEarlySignals: {
							name: "GrowthEquityUsMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityUsMarketRegimeEarlySignals.GrowthEquityUsMarketRegimeEarlySignals",
							child: [],
						},
						LateralPhaseEquityUsMarketRegimeEarlySignals: {
							name: "LateralPhaseEquityUsMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityUsMarketRegimeEarlySignals.LateralPhaseEquityUsMarketRegimeEarlySignals",
							child: [],
						},
						HighStressEquityUsMarketRegimeEarlySignals: {
							name: "HighStressEquityUsMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityUsMarketRegimeEarlySignals.HighStressEquityUsMarketRegimeEarlySignals",
							child: [],
						},
						name: "EquityUsMarketRegimeEarlySignals",
						enable: false,
						threshold: null,
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityUsMarketRegimeEarlySignals",
						child: [
							"GrowthEquityUsMarketRegimeEarlySignals",
							"LateralPhaseEquityUsMarketRegimeEarlySignals",
							"HighStressEquityUsMarketRegimeEarlySignals",
						],
					},
					EquityEUMarketRegimeEarlySignals: {
						GrowthEquityEUMarketRegimeEarlySignals: {
							name: "GrowthEquityEUMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityEUMarketRegimeEarlySignals.GrowthEquityEUMarketRegimeEarlySignals",
							child: [],
						},
						LateralPhaseEquityEUMarketRegimeEarlySignals: {
							name: "LateralPhaseEquityEUMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityEUMarketRegimeEarlySignals.LateralPhaseEquityEUMarketRegimeEarlySignals",
							child: [],
						},
						HighStressEquityEUMarketRegimeEarlySignals: {
							name: "HighStressEquityEUMarketRegimeEarlySignals",
							enable: false,
							threshold: "10",
							type: "MARKET",
							formDisabled: true,
							path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityEUMarketRegimeEarlySignals.HighStressEquityEUMarketRegimeEarlySignals",
							child: [],
						},
						name: "EquityEUMarketRegimeEarlySignals",
						enable: false,
						threshold: null,
						type: "MARKET",
						formDisabled: true,
						path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals.EquityEUMarketRegimeEarlySignals",
						child: [
							"GrowthEquityEUMarketRegimeEarlySignals",
							"LateralPhaseEquityEUMarketRegimeEarlySignals",
							"HighStressEquityEUMarketRegimeEarlySignals",
						],
					},
					name: "MarketRegimeEarlySignals",
					enable: false,
					threshold: null,
					type: "MARKET",
					formDisabled: true,
					path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck.MarketRegimeEarlySignals",
					child: ["EquityUsMarketRegimeEarlySignals", "EquityEUMarketRegimeEarlySignals"],
				},
				child: ["MarketPositioningChangesAlertCheck", "MarketRegimeChangesAlertCheck", "MarketRegimeEarlySignals"],
				enable: false,
				formDisabled: true,
				name: "MarketUpdateAlertCheck",
				path: "ROOT.MarketAlertCheck.MarketUpdateAlertCheck",
				threshold: null,
				type: "MARKET",
			},
			child: ["MarketUpdateAlertCheck"],
			enable: false,
			formDisabled: true,
			name: "MarketAlertCheck",
			path: "ROOT.MarketAlertCheck",
			threshold: 0,
			type: "MARKET",
		},
		HmmAlertCheck: {
			child: [],
			enable: false,
			formDisabled: true,
			name: "HmmAlertCheck",
			path: "ROOT.HmmAlertCheck",
			threshold: null,
			type: "HMM",
		},
		child: ["PortfolioAlertCheck", "MarketAlertCheck", "HmmAlertCheck"],
		enable: false,
		formDisabled: true,
		name: "ROOT",
		path: "ROOT",
		threshold: null,
		type: "ROOT",
	},
};

export const notificationsGroupMap = {
	// PTF Alerts
	EquityAssetAllocationExposureConstraintsAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	FixedIncomeAssetAllocationExposureConstraintsAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	CommoditiesAssetAllocationExposureConstraintsAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	ScoreBreachedAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	TagBreachedAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	MarketViewExpiredAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	EquityAssetAllocationExposureWarningAlertCheck: "NOTIFICATION_PORTFOLIO_WARNING",
	FixedIncomeAssetAllocationExposureWarningAlertCheck: "NOTIFICATION_PORTFOLIO_WARNING",
	CommoditiesAssetAllocationExposureWarningAlertCheck: "NOTIFICATION_PORTFOLIO_WARNING",
	ScoreWarningAlertCheck: "NOTIFICATION_PORTFOLIO_WARNING",
	TagWarningAlertCheck: "NOTIFICATION_PORTFOLIO_WARNING",
	InstrumentsChangesChildAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	GrowthMarketViewAlignmentAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	LateralPhaseMarketViewAlignmentAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	HighStressMarketViewAlignmentAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	RISK_CONSTRAINTS_BREACHED: "NOTIFICATION_PORTFOLIO_ALERT", // Not Correctly Mapped
	MARKET_VIEW_EXPIRED: "NOTIFICATION_PORTFOLIO_ALERT", // Not Correctly Mapped
	RISK_CONSTRAINTS_WARNING: "NOTIFICATION_PORTFOLIO_WARNING",
	PortfolioStatusUpdateAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT",
	AssetAllocationExposureWarningAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT_CUSTOM",
	AssetAllocationExposureConstraintsAlertCheck: "NOTIFICATION_PORTFOLIO_ALERT_CUSTOM",
	// Market Alerts"
	EquityMarketPositioningAlertCheck: "GENERAL",
	FixedIncomeMarketPositioningAlertCheck: "GENERAL",
	CommoditiesMarketPositioningAlertCheck: "GENERAL",
	EquityMarketRegimeChangesAlertCheck: "GENERAL",
	FixedIncomeMarketRegimeChangesAlertCheck: "GENERAL",
	CommoditiesMarketRegimeChangesAlertCheck: "GENERAL",
	GrowthEquityUsMarketRegimeEarlySignals: "GENERAL",
	LateralPhaseEquityUsMarketRegimeEarlySignals: "GENERAL",
	HighStressEquityUsMarketRegimeEarlySignals: "GENERAL",
	GrowthEquityEUMarketRegimeEarlySignals: "GENERAL",
	LateralPhaseEquityEUMarketRegimeEarlySignals: "GENERAL",
	HighStressEquityEUMarketRegimeEarlySignals: "GENERAL",
	HmmAlertCheck: "GENERAL",
	default: "GENERAL",
};
