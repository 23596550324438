import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

//FIXME: standardize the svg

const InvalidBox: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 72,
		height: "auto",
	},
	...others
}) => {
	return (
		<Svg style={style} viewBox={{ width: 72, height: 71 }} {...others}>
			<g clipPath="url(#clip0_199_68041)">
				<g filter="url(#filter0_d_199_68041)">
					<path
						d="M61.3709 11H10.6093C9.11812 11.0053 7.68994 11.603 6.6383 12.6619C5.58666 13.7209 4.99749 15.1545 5.00014 16.6481V47.2334C4.99489 47.9754 5.13596 48.7112 5.41525 49.3986C5.69454 50.086 6.10656 50.7113 6.62769 51.2388C7.14881 51.7663 7.76878 52.1856 8.45204 52.4726C9.1353 52.7597 9.86841 52.9087 10.6093 52.9113H26.9686L22.5151 59.8966H16.8561C16.4667 59.9279 16.1033 60.1049 15.8383 60.3925C15.5734 60.68 15.4262 61.057 15.4262 61.4483C15.4262 61.8396 15.5734 62.2165 15.8383 62.5041C16.1033 62.7916 16.4667 62.9687 16.8561 63H55.134C55.5428 62.9974 55.9341 62.8336 56.2231 62.5441C56.5122 62.2546 56.6757 61.8627 56.6783 61.4533C56.6772 61.0429 56.5164 60.6491 56.23 60.3556C56.0866 60.2104 55.9159 60.0951 55.7278 60.0163C55.5397 59.9375 55.3379 59.8968 55.134 59.8966H49.4651L45.0315 52.9113H61.3709C62.8647 52.9087 64.2965 52.3125 65.3519 51.2535C66.4073 50.1946 67 48.7595 67 47.2633V16.6481C67 15.1518 66.4073 13.7167 65.3519 12.6578C64.2965 11.5989 62.8647 11.0026 61.3709 11V11Z"
						fill="white"
					/>
					<path
						d="M61.5801 11H10.6301C9.1334 11.0053 7.69992 11.6043 6.64438 12.6654C5.58883 13.7266 4.99748 15.1633 5.00014 16.66V47.31C4.99487 48.0536 5.13646 48.791 5.41679 49.4798C5.69712 50.1686 6.11067 50.7953 6.63373 51.324C7.15678 51.8526 7.77906 52.2728 8.46485 52.5604C9.15065 52.848 9.88648 52.9974 10.6301 53H27.0501L22.5801 60H16.9001C16.5093 60.0314 16.1445 60.2088 15.8786 60.497C15.6126 60.7851 15.4649 61.1629 15.4649 61.555C15.4649 61.9471 15.6126 62.3249 15.8786 62.613C16.1445 62.9012 16.5093 63.0786 16.9001 63.11H55.3201C55.7304 63.1074 56.1231 62.9432 56.4133 62.6531C56.7034 62.363 56.8675 61.9703 56.8701 61.56C56.869 61.1487 56.7076 60.7541 56.4201 60.46C56.2762 60.3145 56.1049 60.199 55.9161 60.12C55.7273 60.041 55.5248 60.0003 55.3201 60H49.6301L45.1801 53H61.5801C63.0795 52.9974 64.5166 52.3999 65.5759 51.3387C66.6352 50.2775 67.2301 48.8394 67.2301 47.34V16.66C67.2301 15.1606 66.6352 13.7225 65.5759 12.6613C64.5166 11.6001 63.0795 11.0026 61.5801 11V11ZM46.0001 60H26.2701L30.7301 53H41.4901L46.0001 60ZM64.1201 16.66V47.31C64.1241 47.6451 64.0612 47.9777 63.9351 48.2882C63.809 48.5988 63.6223 48.881 63.3857 49.1185C63.1492 49.3559 62.8677 49.5438 62.5576 49.6712C62.2476 49.7985 61.9153 49.8627 61.5801 49.86H10.6301C10.2963 49.8614 9.96552 49.7961 9.65719 49.6681C9.34887 49.5401 9.06918 49.3518 8.83451 49.1143C8.59984 48.8769 8.41491 48.595 8.29052 48.2852C8.16614 47.9753 8.10482 47.6438 8.11014 47.31V16.66C8.10482 16.3262 8.16614 15.9947 8.29052 15.6848C8.41491 15.375 8.59984 15.0931 8.83451 14.8557C9.06918 14.6182 9.34887 14.4299 9.65719 14.3019C9.96552 14.1739 10.2963 14.1086 10.6301 14.11H61.5801C61.9153 14.1073 62.2476 14.1715 62.5576 14.2988C62.8677 14.4262 63.1492 14.6141 63.3857 14.8515C63.6223 15.089 63.809 15.3712 63.9351 15.6818C64.0612 15.9923 64.1241 16.3249 64.1201 16.66Z"
						fill="#D1D2DC"
					/>
				</g>
				<circle cx="36" cy="20.999" r="16" fill="white" />
				<path
					d="M39.2211 13.6898L36.0001 16.9108L32.779 13.6898C31.664 12.5748 29.8057 12.5748 28.6907 13.6898C27.5757 14.8047 27.5757 16.663 28.6907 17.778L31.9118 20.9991L28.6907 24.2201C27.5757 25.3351 27.5757 27.1934 28.6907 28.3084C29.2689 28.8866 30.0122 29.1343 30.7142 29.1343C31.4162 29.1343 32.2009 28.8453 32.7377 28.3084L35.9588 25.0874L39.1798 28.3084C40.2948 29.4234 42.1531 29.4234 43.2681 28.3084C44.4244 27.1521 44.4244 25.3351 43.3094 24.2201L40.0883 20.9991L43.3094 17.778C44.4244 16.663 44.4244 14.8047 43.3094 13.6898C42.1944 12.5748 40.3361 12.5748 39.2211 13.6898ZM42.1118 14.8877C42.6074 15.3832 42.6074 16.1678 42.1118 16.6221L38.3126 20.4213C37.9823 20.7517 37.9823 21.2472 38.3126 21.5776L42.1118 25.3768C42.6074 25.8723 42.6074 26.6569 42.1118 27.1112C41.6163 27.6067 40.8317 27.6067 40.3774 27.1112L36.5782 23.312C36.2478 22.9816 35.7523 22.9816 35.4219 23.312L31.6227 27.1112C31.1272 27.6067 30.3426 27.6067 29.8883 27.1112C29.3928 26.6156 29.3928 25.831 29.8883 25.3768L33.6875 21.5776C34.0179 21.2472 34.0179 20.7517 33.6875 20.4213L29.8883 16.6221C29.3928 16.1265 29.3928 15.3419 29.8883 14.8877C30.1361 14.6399 30.4664 14.516 30.7555 14.516C31.0859 14.516 31.3749 14.6399 31.6227 14.8877L35.4219 18.6869C35.7523 19.0172 36.2478 19.0172 36.5782 18.6869L40.3774 14.8877C40.8729 14.3921 41.6576 14.3921 42.1118 14.8877Z"
					fill="#4CB09C"
				/>
				<path
					d="M36 36.999C33.8402 36.999 31.7448 36.5757 29.7719 35.7412C27.8665 34.9353 26.1555 33.7817 24.6864 32.3127C23.2173 30.8436 22.0638 29.1325 21.2578 27.2271C20.4233 25.2542 20 23.1588 20 20.999C20 18.8393 20.4233 16.7439 21.2578 14.771C22.0638 12.8655 23.2173 11.1545 24.6864 9.68539C26.1555 8.2163 27.8665 7.06277 29.7719 6.25682C31.7448 5.4223 33.8402 4.99902 36 4.99902C38.1598 4.99902 40.2552 5.4223 42.2281 6.25682C44.1335 7.06277 45.8445 8.2163 47.3136 9.68539C48.7827 11.1545 49.9363 12.8655 50.7422 14.771C51.5767 16.7442 52 18.8396 52 20.999C52 23.1584 51.5767 25.2542 50.7422 27.2271C49.9363 29.1325 48.7827 30.8436 47.3136 32.3127C45.8445 33.7817 44.1335 34.9353 42.2281 35.7412C40.2548 36.5757 38.1594 36.999 36 36.999ZM36 6.71968C34.0718 6.71968 32.2018 7.09719 30.4419 7.84154C28.7416 8.56077 27.2144 9.59041 25.9029 10.9022C24.591 12.2141 23.5614 13.741 22.8422 15.4413C22.0978 17.2012 21.7203 19.0712 21.7203 20.9994C21.7203 22.9275 22.0978 24.7975 22.8422 26.5574C23.5614 28.2578 24.591 29.785 25.9029 31.0965C27.2147 32.4083 28.7416 33.438 30.4419 34.1572C32.2018 34.9015 34.0718 35.2791 36 35.2791C37.9282 35.2791 39.7982 34.9015 41.5581 34.1572C43.2584 33.438 44.7857 32.4083 46.0971 31.0965C47.409 29.7847 48.4386 28.2578 49.1578 26.5574C49.9022 24.7975 50.2797 22.9275 50.2797 20.9994C50.2797 19.0712 49.9022 17.2012 49.1578 15.4413C48.4386 13.741 47.409 12.2137 46.0971 10.9022C44.7853 9.59041 43.2584 8.56077 41.5581 7.84154C39.7982 7.09719 37.9282 6.71968 36 6.71968Z"
					fill="#4CB09C"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_199_68041"
					x="-9"
					y="-1"
					width="90.23"
					height="80.1104"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_199_68041" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_199_68041" result="shape" />
				</filter>
				<clipPath id="clip0_199_68041">
					<rect width="72" height="72" fill="white" />
				</clipPath>
			</defs>
		</Svg>
	);
};

export default InvalidBox;
