import type { Map } from "immutable";
import { Set, Map as MapFn } from "immutable";
import BigNumber from "bignumber.js";
import { useCallback, useMemo, useRef, useState } from "react";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall, noop } from "@mdotm/mdotui/utils";

export type CompositionBuilderActions = {
	setWeight(ticker: string, newWeight: BigNumber | null): CompositionBuilder;
	getWeight(ticker: string): BigNumber | null;
	excessToCash(): CompositionBuilder;
	normalise(distributeTo: Set<string>): CompositionBuilder;
	getTotalWeight(): BigNumber;
	getTotalWeightWithoutCash(): BigNumber;
	getDeleted(): Set<string>;
	toggleDeleted(ticker: string | Iterable<string>, deleted: boolean): CompositionBuilder;
	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, BigNumber | null>;
	getIdentifiers(): Map<string, string>;
	getIdentifier(identifier: string): string;
	updateIdentifier(identifier: string, value: string): CompositionBuilder;
	getTags(): Map<string, string>;
	updateTag(identifier: string, value: string): CompositionBuilder;
	deleteTags(tagList: Array<string>): CompositionBuilder;
	getTag(identifier: string): string;
	multiTagsUpdate(values: Iterable<[string, string]>): CompositionBuilder;
	toggleRemove(ticker: string): CompositionBuilder;
	getScores(opts?: { excludeDeleted?: boolean }): Map<string, BigNumber | null>;
	getScore(identifier: string): BigNumber | null;
	setScore(identifier: string, newWeight: BigNumber | number | null): CompositionBuilder;
	multiScoresUpdate(values: Iterable<[string, BigNumber | null]>): CompositionBuilder;
	hardDeleteComposition(identifiersToDelete: Iterable<string>): CompositionBuilder;
	getDecimalPlaces(): number;
	isDirty: boolean;
};

export type UseCompositionBuilderResult = CompositionBuilderActions & {
	reset(
		resetDataOrFn: MaybeFn<{
			composition: Map<string, BigNumber | null> | Map<string, number | null>;
			identifiers?: Map<string, string>;
			tags?: Map<string, string>;
			scores?: Map<string, BigNumber | null>;
			deleted?: Set<string>;
			cashId?: string;
			decimalPlaces?: number;
		}>,
	): void;
};

/**
 * A hook that creates a {@link CompositionBuilder composition builder} and wraps every direct operation that would
 * normally return a new instance in a React "setState".
 *
 * Example:
 * ```tsx
 * const compositionBuilder = useCompositionBuilder(() => ({
 * 	composition: Map<string, number>([["demo", 20]]),
 * }));
 *
 * return (
 * 	<Button onClick={() => compositionBuilder.setWeight("demo", 30)}>
 * 		Current weight: {compositionBuilder.getWeight("demo")?.toNumber() ?? '-'}
 * 	</Button>
 * );
 * ```
 *
 * @param initializer The initial value or a function that produces it.
 */
export function useCompositionBuilder(
	initializer: MaybeFn<{
		composition: Map<string, BigNumber | null> | Map<string, number | null>;
		identifiers?: Map<string, string>;
		tags?: Map<string, string>;
		deleted?: Set<string>;
		scores?: Map<string, BigNumber | null>;
		cashId?: string;
		decimalPlaces?: number;
	}>,
): UseCompositionBuilderResult {
	const [builder, setBuilder] = useState(() => {
		const { composition, deleted, cashId, identifiers, tags, scores, decimalPlaces } = maybeCall(initializer);
		return new CompositionBuilder(composition, deleted, identifiers, tags, scores, cashId, decimalPlaces);
	});
	const latestCompositionBuilderRef = useRef(builder);
	const dirtyFlagRef = useRef(false);
	const updateBuilder = useCallback((newBuilder: CompositionBuilder) => {
		setBuilder(newBuilder);
		dirtyFlagRef.current = true;
		latestCompositionBuilderRef.current = newBuilder;
		return newBuilder;
	}, []);

	return useMemo(() => {
		noop(builder); // track to trigger re-renders
		return {
			setWeight: (...args) => updateBuilder(latestCompositionBuilderRef.current.setWeight(...args)),
			getWeight: (...args) => latestCompositionBuilderRef.current.getWeight(...args),
			excessToCash: (...args) => updateBuilder(latestCompositionBuilderRef.current.excessToCash(...args)),
			normalise: (...args) => updateBuilder(latestCompositionBuilderRef.current.normalise(...args)),
			getTotalWeight: (...args) => latestCompositionBuilderRef.current.getTotalWeight(...args),
			getTotalWeightWithoutCash: (...args) => latestCompositionBuilderRef.current.getTotalWeightWithoutCash(...args),
			toggleDeleted: (...args) => updateBuilder(latestCompositionBuilderRef.current.toggleDeleted(...args)),
			toggleRemove: (...args) => updateBuilder(latestCompositionBuilderRef.current.toggleRemove(...args)),
			getDeleted: (...args) => latestCompositionBuilderRef.current.getDeleted(...args),
			getComposition: (...args) => latestCompositionBuilderRef.current.getComposition(...args),
			getIdentifiers: (...args) => latestCompositionBuilderRef.current.getIdentifiers(...args),
			updateIdentifier: (...args) => updateBuilder(latestCompositionBuilderRef.current.updateIdentifier(...args)),
			getIdentifier: (...args) => latestCompositionBuilderRef.current.getIdentifier(...args),
			updateTag: (...args) => updateBuilder(latestCompositionBuilderRef.current.updateTag(...args)),
			getTags: (...args) => latestCompositionBuilderRef.current.getTags(...args),
			getTag: (...args) => latestCompositionBuilderRef.current.getTag(...args),
			deleteTags: (...args) => updateBuilder(latestCompositionBuilderRef.current.deleteTags(...args)),
			multiTagsUpdate: (...args) => updateBuilder(latestCompositionBuilderRef.current.multiTagsUpdate(...args)),
			getScore: (...args) => latestCompositionBuilderRef.current.getScore(...args),
			getScores: (...args) => latestCompositionBuilderRef.current.getScores(...args),
			setScore: (...args) => updateBuilder(latestCompositionBuilderRef.current.setScore(...args)),
			multiScoresUpdate: (...args) => updateBuilder(latestCompositionBuilderRef.current.multiScoresUpdate(...args)),
			hardDeleteComposition: (...args) =>
				updateBuilder(latestCompositionBuilderRef.current.hardDeleteComposition(...args)),
			getDecimalPlaces: () => latestCompositionBuilderRef.current.decimalPlaces,
			isDirty: dirtyFlagRef.current,
			reset(resetDataOrFn: typeof initializer) {
				const { composition, deleted, cashId, identifiers, tags, scores, decimalPlaces } = maybeCall(resetDataOrFn);
				updateBuilder(new CompositionBuilder(composition, deleted, identifiers, tags, scores, cashId, decimalPlaces));
			},
		};
	}, [updateBuilder, builder]);
}

/**
 * A class that represents a collection of instruments that constitute a composition and exposes utility methods to interact with it.
 * A composition is the combination of tickers and their respective weights. When normalised, all the weights should
 * sum up to 100 (%).
 */
export class CompositionBuilder implements Omit<CompositionBuilderActions, "isDirty"> {
	composition: Map<string, BigNumber | null>;
	deleted: Set<string>;
	identifiers: Map<string, string>;
	tags: Map<string, string>;
	scores: Map<string, BigNumber | null>;
	cashId?: string;
	decimalPlaces: number;
	constructor(
		composition: Map<string, BigNumber | null> | Map<string, number | null>,
		deleted?: Set<string>,
		identifiers?: Map<string, string>,
		tags?: Map<string, string>,
		scores?: Map<string, BigNumber | null>,
		cashId?: string,
		decimalPlaces?: number,
	) {
		this.composition =
			typeof composition.first() === "number"
				? composition.map((n) => (n === null ? null : BigNumber(n)))
				: (composition as Map<string, BigNumber | null>);
		this.deleted = deleted ?? Set();
		this.identifiers = identifiers ?? MapFn();
		this.tags = tags ?? MapFn();
		this.scores = scores ?? MapFn();
		this.cashId = cashId;
		this.decimalPlaces = decimalPlaces ?? 2;
	}

	hardDeleteComposition(identifiersToDelete: Iterable<string>): CompositionBuilder {
		return new CompositionBuilder(
			this.composition.deleteAll(identifiersToDelete),
			Set(this.deleted.toArray().filter((ids) => !Array.from(identifiersToDelete).includes(ids))),
			this.identifiers.deleteAll(identifiersToDelete),
			this.tags.deleteAll(identifiersToDelete),
			this.scores.deleteAll(identifiersToDelete),
			this.cashId,
			this.decimalPlaces,
		);
	}

	multiScoresUpdate(values: Iterable<[string, BigNumber | null]>): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted,
			this.identifiers,
			this.tags,
			this.scores.merge(values),
			this.cashId,
			this.decimalPlaces,
		);
	}

	setScore(identifier: string, newWeight: BigNumber | number | null): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted.remove(identifier),
			this.identifiers,
			this.tags,
			this.scores.set(identifier, typeof newWeight === "number" ? BigNumber(newWeight) : newWeight),
			this.cashId,
			this.decimalPlaces,
		);
	}

	getScore(identifier: string): BigNumber | null {
		return this.scores.get(identifier) ?? null;
	}

	getScores(opts?: { excludeDeleted?: boolean }): Map<string, BigNumber | null> {
		if (opts?.excludeDeleted) {
			return this.scores.removeAll(this.deleted);
		}
		return this.scores;
	}

	deleteTags(tagList: Array<string>): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted,
			this.identifiers,
			this.tags.deleteAll(tagList),
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	multiTagsUpdate(values: Iterable<[string, string]>): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted,
			this.identifiers,
			this.tags.merge(values),
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	updateTag(identifier: string, value: string): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted,
			this.identifiers,
			this.tags.set(identifier, value),
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	getTag(identifier: string): string {
		return this.tags.get(identifier) ?? "";
	}

	getTags(): Map<string, string> {
		return this.tags;
	}

	updateIdentifier(identifier: string, value: string): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			this.deleted,
			this.identifiers.set(identifier, value),
			this.tags,
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	getIdentifiers(): Map<string, string> {
		const identifiersWithoutDeleted = this.identifiers.removeAll(this.deleted);
		return identifiersWithoutDeleted;
	}

	getIdentifier(identifier: string): string {
		return this.identifiers.get(identifier) ?? "";
	}

	setWeight(ticker: string, newWeight: BigNumber | number | null): CompositionBuilder {
		return new CompositionBuilder(
			this.composition.set(ticker, typeof newWeight === "number" ? BigNumber(newWeight) : newWeight),
			this.deleted.remove(ticker),
			this.identifiers,
			this.tags,
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	getWeight(ticker: string): BigNumber | null {
		return this.composition.get(ticker) ?? null;
	}

	toggleRemove(ticker: string): CompositionBuilder {
		return new CompositionBuilder(
			this.composition.delete(ticker),
			this.deleted.delete(ticker),
			this.identifiers.remove(ticker),
			this.tags.remove(ticker),
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	toggleDeleted(ticker: string | Iterable<string>, deleted: boolean): CompositionBuilder {
		return new CompositionBuilder(
			this.composition,
			typeof ticker === "string"
				? deleted
					? this.deleted.add(ticker)
					: this.deleted.remove(ticker)
				: deleted
				  ? this.deleted.union(ticker)
				  : this.deleted.subtract(ticker),
			this.identifiers,
			this.tags,
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	excessToCash(): CompositionBuilder {
		if (this.cashId === undefined) {
			console.warn("No cashId specified, cannot move excess to cash");
			return this;
		}
		if (this.getTotalWeight().gt(100)) {
			console.warn("No excess to move to cash available, the total weight is already above 100");
			return this;
		}
		const totalExcludingCash = this.composition
			.delete(this.cashId)
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(cur ?? BigNumber(0)), BigNumber(0));
		return this.setWeight(this.cashId, BigNumber(100).minus(totalExcludingCash).decimalPlaces(this.decimalPlaces));
	}

	normalise(distributeTo: Iterable<string>): CompositionBuilder {
		const totalOvershot = this.getTotalWeight().minus(100).decimalPlaces(this.decimalPlaces);

		if (totalOvershot.eq(0)) {
			// Nothing to normalise.
			return this;
		}

		const editableTickerIds = Set(distributeTo).subtract(this.deleted);
		const totalWeightOfSelected = editableTickerIds.reduce(
			(sum, cur) => sum.plus(this.composition.get(cur) ?? BigNumber(0)),
			BigNumber(0),
		);
		if (totalWeightOfSelected.lt(totalOvershot)) {
			// Normalisation is impossible to achieve but we can approach it by setting all the weights to 0.
			return new CompositionBuilder(
				this.composition.withMutations((editableWeights) => {
					for (const tickerId of editableTickerIds) {
						editableWeights.set(tickerId, BigNumber(0));
					}
				}),
				this.deleted,
				this.identifiers,
				this.tags,
				this.scores,
				this.cashId,
				this.decimalPlaces,
			);
		}
		if (totalWeightOfSelected.eq(0)) {
			if (totalOvershot.gt(0)) {
				// Normalisation is impossible to achieve with the current selection.
				console.warn(
					"Cannot normalise: the current selection has a total weight of 0 and we have to reduce the total, therefore by proceeding we would have to set negative weights, which are not allowed.",
				);
				return this;
			}
			const newWeightsTmp = this.composition.withMutations((editableWeights) => {
				const partialWeight = totalOvershot.times(-1).div(editableTickerIds.size).decimalPlaces(this.decimalPlaces);
				for (const tickerId of editableTickerIds) {
					editableWeights.set(tickerId, partialWeight);
				}
			});
			const remainingOvershot = newWeightsTmp
				.removeAll(this.deleted)
				.reduce((sum, cur) => sum.plus(cur ?? BigNumber(0)), BigNumber(0))
				.minus(100)
				.decimalPlaces(this.decimalPlaces);
			const newWeights = newWeightsTmp.update(editableTickerIds.first(), (w) => (!w ? w : w.minus(remainingOvershot)));

			return new CompositionBuilder(
				newWeights,
				this.deleted,
				this.identifiers,
				this.tags,
				this.scores,
				this.cashId,
				this.decimalPlaces,
			);
		}

		const newWeightsTmp = this.composition.withMutations((editableWeights) => {
			for (const tickerId of editableTickerIds) {
				editableWeights.set(
					tickerId,
					(editableWeights.get(tickerId) ?? BigNumber(0))
						.minus((editableWeights.get(tickerId) ?? BigNumber(0)).div(totalWeightOfSelected).times(totalOvershot))
						.decimalPlaces(this.decimalPlaces),
				);
			}
		});
		const remainingOvershot = newWeightsTmp
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(cur ?? BigNumber(0)), BigNumber(0))
			.minus(100)
			.decimalPlaces(this.decimalPlaces);
		const newWeights = newWeightsTmp.update(editableTickerIds.first(), (w) => (!w ? w : w.minus(remainingOvershot)));

		return new CompositionBuilder(
			newWeights,
			this.deleted,
			this.identifiers,
			this.tags,
			this.scores,
			this.cashId,
			this.decimalPlaces,
		);
	}

	getTotalWeight(): BigNumber {
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted);
		return compositionWithoutDeleted.reduce((sum, cur) => sum.plus(cur ?? BigNumber(0)), BigNumber(0));
	}

	getTotalWeightWithoutCash(): BigNumber {
		if (this.cashId === undefined) {
			return this.getTotalWeight();
		}
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted).delete(this.cashId);
		return compositionWithoutDeleted.reduce((sum, cur) => sum.plus(cur ?? BigNumber(0)), BigNumber(0));
	}

	getDeleted(): Set<string> {
		return this.deleted;
	}

	getDecimalPlaces(): number {
		return this.decimalPlaces;
	}

	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, BigNumber | null> {
		if (opts?.excludeDeleted) {
			return this.composition.removeAll(this.deleted);
		}
		return this.composition;
	}
}
