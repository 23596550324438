import { FunctionalAreasContext, useFunctionalAreas } from "$root/App/context";
import type { AiConversationMessageTypeEnum, ConversationMinInfo, DefaultMessagesResponse } from "$root/api/api-gen";
import { AIConversationsControllerApiFactory, LogDtoLevelEnum } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import type { VirtualListImperativeHandles } from "$root/components/VirtualList";
import { VirtualList } from "$root/components/VirtualList";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { useImperativeHandlesRef, type ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { Button, CircularProgressBar, Icon, Transition } from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { Switch, generateUniqueDOMId, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { debugValue, noop, removeById, replaceById } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import EventEmitter from "eventemitter3";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { SphereAISuggestionOverlayContent } from "./SphereAISuggestionOverlayContent";
import { AIBox } from "./boxes/AIBox";
import { ThinkingBoxV2 } from "./boxes/ThinkingBox";
import { UserBox } from "./boxes/UserBox";
import { SendIcon } from "./icons/SendIcon";
import { SuggestionsIcon } from "./icons/SuggestionIcon";
import type { SmartTextAreaImperativeHandles } from "./inputs/SmartTextArea";
import { SmartTextArea } from "./inputs/SmartTextArea";
import { AbortError } from "$root/utils/promise";

export type ChatMessagePayloadMap = {
	user: {
		content: string;
		localId?: string;
		starred: boolean;
		status: "sending" | "sent" | "error";
		type?: AiConversationMessageTypeEnum;
	};
	ai: {
		localId?: string;
		content: string;
		type?: AiConversationMessageTypeEnum;
		feedback?: number;
	};
	aiPlaceholder: {
		// used to show the AI thinking state
		localId: string;
		abortController: AbortController;
		doneEmitter: EventEmitter<"done">;
	};
};

export type ChatMessageMap = { [K in keyof ChatMessagePayloadMap]: ChatMessagePayloadMap[K] & { author: K } };

export type ChatMessage = {
	[K in keyof ChatMessagePayloadMap]: ChatMessagePayloadMap[K] & { author: K };
}[keyof ChatMessagePayloadMap];

export function SphereAIChatSection({
	chatId,
	suggestions,
	setCurrentChat,
	refetch,
}: {
	chatId: string | null;
	suggestions: string[];
	setCurrentChat: (chatId: string | null) => void;
	refetch: QueryObserverBaseResult<{
		chatHistory: {
			conversations: ConversationMinInfo[] | undefined;
		};
		suggestedQuestions: DefaultMessagesResponse;
		lastChatUUID: string | undefined;
	}>["refetch"];
}): JSX.Element {
	const AIChatApi = useApiGen(AIConversationsControllerApiFactory);
	const { state } = useContext(FunctionalAreasContext);
	const [messages, setMessages] = useState<Array<ChatMessage>>([]);
	const { refetch: refetchConversation } = useQueryNoRefetch(["retrieveSelectedChatAIData", chatId], {
		queryFn: async () => {
			if (!chatId) {
				return { remappedMessages: [] };
			}
			const AiChatData = await axiosExtract(AIChatApi.getConversation(chatId));

			const remappedMessages = (AiChatData.messages ?? []).map((msg) =>
				msg.sender === "USER"
					? ({
							author: "user",
							content: msg.content ?? "",
							localId: msg.uuid,
							starred: msg.favourite ?? false,
							status: "sent" as ChatMessagePayloadMap["user"]["status"],
							type: msg.type,
					  } satisfies ChatMessageMap["user"])
					: ({
							author: "ai",
							localId: msg.uuid,
							content: msg.content ?? "",
							type: msg.type,
							feedback: msg.feedback,
					  } satisfies ChatMessageMap["ai"]),
			);
			return { remappedMessages };
		},
		onSuccess({ remappedMessages }) {
			setMessages(remappedMessages);
			setShowSuggestionOverlay(remappedMessages.length === 0);
		},
	});

	const virtualListHandlesRef = useRef<VirtualListImperativeHandles | null>(null);
	const { run: sendMessageAsync, loading: waitingForServerResponse } = useAsync<void, ChatMessageMap["user"]>({
		asyncFn: async ({ param: message }) => {
			const aiThinkingId = generateUniqueDOMId();
			const aiThinkingAbortController = new AbortController();
			const aiThinkingAnimationDoneEmitter = new EventEmitter<"done">();
			const isFirstChatMessage = !chatId;
			setShowSuggestionOverlay(false);
			flushSync(() =>
				setMessages((cur) => {
					const tmp = cur.slice();
					tmp.push({
						author: "aiPlaceholder",
						localId: aiThinkingId,
						abortController: aiThinkingAbortController,
						doneEmitter: aiThinkingAnimationDoneEmitter,
					});
					return tmp;
				}),
			);

			const thinkingBoxAnimation = async () => {
				aiThinkingAbortController.abort(new AbortError("done"));
				let cleanup = noop;
				await new Promise((resolve) => {
					aiThinkingAnimationDoneEmitter.addListener("done", resolve);
					cleanup = () => aiThinkingAnimationDoneEmitter.removeListener("done", resolve);
				});
				cleanup();
			};

			try {
				virtualListHandlesRef.current?.scrollToBottom();
				debugValue(message);
				flushSync(() =>
					setMessages((cur) =>
						replaceById(
							cur,
							{
								...message,
								status: "sent",
							},
							(msg) => msg.localId,
						),
					),
				);
				virtualListHandlesRef.current?.scrollToBottom();
				// FIXME: @Ben Actually we save the last visited portfolio, we have to upgrade this Feature Later
				const contextedMessage = {
					message: message.content,
					context: state.portfolio?.uuid
						? {
								area: "portfolio",
								uuid: state.portfolio.uuid,
						  }
						: undefined,
				};
				const responseMessage = isFirstChatMessage
					? await AIChatApi.createConversation(contextedMessage)
					: await AIChatApi.enqueueMessageInConversation(chatId ?? "", contextedMessage);

				await thinkingBoxAnimation();

				flushSync(() =>
					setMessages((cur) =>
						replaceById(
							cur,
							{
								author: "ai",
								localId: aiThinkingId,
								content: responseMessage.data.messages?.slice(-1)[0].content ?? "",
								type: responseMessage.data.messages?.slice(-1)[0].type,
							},
							(msg) => msg.localId,
						),
					),
				);
				setCurrentChat(responseMessage.data.uuid ?? null);
				virtualListHandlesRef.current?.scrollToBottom();
				if (isFirstChatMessage) {
					await refetch();
				}
			} catch (err) {
				await thinkingBoxAnimation();

				flushSync(() =>
					setMessages((cur) =>
						// default fallback for 510 error
						removeById(
							replaceById(
								cur,
								{
									...message,
									status: "error",
								},
								(msg) => (msg.author === "user" ? msg.localId : undefined),
							),
							aiThinkingId,
							(msg) => msg.localId,
						),
					),
				);

				const aiThinkingId2 = generateUniqueDOMId();
				flushSync(() =>
					setMessages((cur) => [
						...cur,
						{
							author: "ai",
							localId: aiThinkingId2,
							content: "Sphere AI is currently experiencing an error, please try later",
							type: "AI_ERROR",
						},
					]),
				);

				virtualListHandlesRef.current?.scrollToBottom();
				reportPlatformError(err, LogDtoLevelEnum.Error, "ask-intelligence");
				throw err;
			}
		},
	});

	async function appendUserMessageAndSend(userMessage: string) {
		try {
			const trimmed = userMessage.trimStart();
			const newMessage: ChatMessageMap["user"] = {
				localId: generateUniqueDOMId(),
				author: "user" as const,
				content: trimmed,
				starred: false,
				status: "sending",
			};
			flushSync(() => {
				setMessages((cur) => {
					const tmp = cur.slice();
					tmp.push(newMessage);
					return tmp;
				});
			});
			virtualListHandlesRef.current?.scrollToBottom();
			await sendMessageAsync(newMessage);

			trackMixPanelEvent("ChatAI", {
				Type: "Question",
				Action: "send-Ask",
				Question: userMessage,
			});
		} catch (error) {
			console.log(error);
		}
	}

	useFunctionalAreas(
		() => ({
			areas: "chat",
			data: {
				appendUserMessageAndSend,
			},
		}),
		[],
	);

	const lastMessage = messages[messages.length - 1];

	const isRegenerateVisible = useMemo(() => {
		return lastMessage?.author === "ai" && lastMessage.type === "AI_ERROR";
	}, [lastMessage]);

	const lastMessageIsSuggestion = useMemo(() => {
		return lastMessage?.author === "ai" && lastMessage.type === "AI_RESPONSE";
	}, [lastMessage]);

	const noMessage = messages.length === 0;
	const userMessageBoxImperativeHandlesRef = useRef<UserMessageBoxImperativeHandles | null>(null);
	const [showSuggestionOverlay, setShowSuggestionOverlay] = useState(false);
	const [, setDynamicKey] = useState(() => String(new Date()));

	const rerenderList = useCallback(() => {
		setDynamicKey(String(new Date()));
	}, []);

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<div className="relative z-0 flex flex-col flex-1 min-h-0 overflow-hidden">
				<VirtualList
					keyProvider={(i) => `${messages[i].author}-${i}-${messages[i].localId ?? "-"}`}
					handlesRef={virtualListHandlesRef}
					items={messages}
					scrollToBottomChildren={({ onClick }) => (
						<button type="button" onClick={onClick}>
							<Icon size={32} icon="down" color={themeCSSVars.palette_B500} />
						</button>
					)}
				>
					{({ item: message }) => (
						<Switch
							case={message.author}
							match={{
								ai: () => {
									const aiMessage = message as ChatMessageMap["ai"];
									const isError = aiMessage.type === "AI_ERROR";
									return (
										<AIBox
											mode={isError ? "error" : "normal"}
											type={aiMessage.type}
											messageId={aiMessage.localId}
											conversationId={chatId}
											refetchConversation={refetchConversation}
											feedbackValue={aiMessage.feedback}
											rerenderList={rerenderList}
											isLastMessage={aiMessage.localId === lastMessage.localId}
											onlySuggestion={lastMessageIsSuggestion}
										>
											{aiMessage.content}
										</AIBox>
									);
								},
								aiPlaceholder: () => {
									const aiMessage = message as ChatMessageMap["aiPlaceholder"];
									return (
										<ThinkingBoxV2
											scrollToBottom={() => virtualListHandlesRef.current?.scrollToBottom()}
											signal={aiMessage.abortController.signal}
											onDone={() => aiMessage.doneEmitter.emit("done")}
										/>
									);
								},
								user: () => {
									const userMessage = message as ChatMessageMap["user"];
									return (
										<UserBox
											classList={{
												"animate-pulse": userMessage.status === "sending",
												"bg-white": true,
												[`opacity-75 bg-[${themeCSSVars.palette_D200}]`]: userMessage.status === "error",
											}}
										>
											{userMessage.content}
										</UserBox>
									);
								},
							}}
						/>
					)}
				</VirtualList>
				<Transition
					in={showSuggestionOverlay}
					duration={500}
					classList={{
						"transition-[transform,opacity] duration-500 absolute z-20 inset-x-0 bottom-0 flex flex-col min-h-0": true,
						"top-0": noMessage,
						"top-12": !noMessage,
					}}
					enterFromClassList="opacity-0 translate-y-full"
					enterToClassList="opacity-100"
					style={{
						boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
					}}
					unmountOnExit
				>
					{({ classList, style }) => (
						<div className={toClassName(classList)} style={style}>
							<SphereAISuggestionOverlayContent
								showBetaDisclaimer={noMessage}
								suggestedQuestions={suggestions}
								onCopy={(suggestion) => {
									userMessageBoxImperativeHandlesRef.current?.setMessage(suggestion ?? "");
									trackMixPanelEvent("ChatAI", {
										Type: "Question",
										Action: "Select-suggested",
										Question: suggestion,
									});
								}}
								//onClose={() => setShowSuggestionOverlay(false)}
							/>
						</div>
					)}
				</Transition>
			</div>
			<Transition
				in={isRegenerateVisible}
				duration={500}
				classList={{
					"transition-[transform,opacity] duration-500 absolute z-10 inset-x-0 bottom-0 flex flex-col min-h-0": true,
					"top-8": noMessage,
				}}
				enterFromClassList="opacity-0 translate-y-full"
				enterToClassList="opacity-100"
				style={{
					boxShadow: "0px -2px 10px rgba(0,0,0,0.2)",
				}}
				unmountOnExit
			>
				<div className="px-4 py-2 flex justify-end">
					<Button
						size="small"
						palette="secondary"
						onClick={() => {
							const userMessages = messages.filter((el) => el.author === "user") as Array<
								ChatMessagePayloadMap["user"]
							>;
							const message = userMessages[userMessages.length - 1]?.content ?? "";
							appendUserMessageAndSend(message).catch(noop);
						}}
					>
						Regenerate
					</Button>
				</div>
			</Transition>
			<UserMessageInputBox
				onlySuggestion={lastMessageIsSuggestion}
				appendUserMessageAndSend={appendUserMessageAndSend}
				noMessage={noMessage}
				handlesRef={userMessageBoxImperativeHandlesRef}
				showSuggestionOverlay={showSuggestionOverlay}
				setShowSuggestionOverlay={setShowSuggestionOverlay}
				waitingForServerResponse={waitingForServerResponse}
			/>
		</div>
	);
}

export type UserMessageBoxImperativeHandles = {
	setMessage(text: string): void;
};

function UserMessageInputBox({
	noMessage,
	handlesRef,
	appendUserMessageAndSend,
	showSuggestionOverlay,
	setShowSuggestionOverlay,
	waitingForServerResponse,
	onlySuggestion = false,
}: ImperativeHandlesRefProps<UserMessageBoxImperativeHandles> & {
	noMessage: boolean;
	appendUserMessageAndSend(message: string): Promise<void>;
	showSuggestionOverlay: boolean;
	setShowSuggestionOverlay(show: boolean): void;
	waitingForServerResponse: boolean;
	onlySuggestion?: boolean;
}) {
	const [message, setMessage] = useState("");
	useImperativeHandlesRef(handlesRef, { setMessage });
	const [showUserInput, setShowUserInput] = useState(true);

	useEffect(() => {
		setShowUserInput((cur) => cur || !(noMessage && message.length === 0));
	}, [message.length, noMessage]);

	const sendCurrentMessage = () => {
		if (message.length === 0) {
			return;
		}

		setMessage("");
		smartTextAreaHandlesRef.current?.setExpand(false);
		appendUserMessageAndSend(message).catch(noop);
	};
	const smartTextAreaHandlesRef = useRef<SmartTextAreaImperativeHandles | null>(null);

	return (
		<>
			{/* {!showUserInput ? null : ( */}
			<div className={`px-4 py-2 flex items-end border-t border-[${themeCSSVars.palette_N100}]`}>
				{/* {!onlySuggestion || showSuggestionOverlay ? ( */}
				<>
					<Button
						disabled={waitingForServerResponse}
						size="small"
						palette={showSuggestionOverlay && !noMessage ? "neutral" : "neutralOutline"}
						classList={toClassName({
							[`mr-2 !border-[${themeCSSVars.palette_N500}] !text-[color:${themeCSSVars.palette_N500}]`]: true,
							"cursor-default pointer-events-none": noMessage,
						})}
						onClick={() => {
							if (noMessage) {
								return;
							}
							setShowSuggestionOverlay(!showSuggestionOverlay);
						}}
					>
						<SuggestionsIcon color={showSuggestionOverlay && !noMessage ? "white" : undefined} />
					</Button>
					<div className="relative z-0 min-w-0 flex-grow">
						<SmartTextArea
							handlesRef={smartTextAreaHandlesRef}
							onChangeText={setMessage}
							value={message}
							onEnter={waitingForServerResponse || message.length === 0 ? undefined : sendCurrentMessage}
						/>
						<button
							type="button"
							className="absolute bottom-0 right-2 disabled:cursor-default cursor-pointer z-20"
							disabled={message.length === 0 || waitingForServerResponse}
							onClick={sendCurrentMessage}
						>
							{!waitingForServerResponse ? (
								<div className="relative bottom-1">
									<SendIcon color={waitingForServerResponse ? themeCSSVars.palette_N300 : "#4CB09C"} />
								</div>
							) : (
								<CircularProgressBar classList="w-4 relative top-1" value="indeterminate" />
							)}
						</button>
					</div>
				</>
				{/* // ) : (
					// 	<Button
					// 		disabled={waitingForServerResponse}
					// 		size="small"
					// 		classList={toClassName({
					// 			[`mr-2 w-full !bg-[${themeCSSVars.palette_graph_B500}] !text-[color:white`]: true,
					// 			"cursor-default pointer-events-none": noMessage,
					// 		})}
					// 		onClick={() => {
					// 			if (noMessage) {
					// 				return;
					// 			}
					// 			setShowSuggestionOverlay(!showSuggestionOverlay);
					// 		}}
					// 	>
					// 		<div className="flex w-full justify-center">
					// 			<SuggestionsIcon color="white" /> Ask me a new question
					// 		</div>
					// 	</Button>
					// )} */}
			</div>
			{/* )} */}
		</>
	);
}
