import { HmmControllerApiFactory, GlobalRisk } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { WorldGrid, WorldGridMapSnapshot } from "$root/components/RegimeAnalysisTool/WorldGrid";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "@mdotm/mdotui/react-extensions";
import { withContext } from "@mdotm/mdotui/react-extensions";

function generateSnapshotLevel(data: Array<GlobalRisk>): Array<WorldGridMapSnapshot> {
	return data.map(({ dateTime, risks }) => ({
		time: dateTime!,
		snapshot: (risks ?? []).map(({ assetClassess, geo }) => ({
			firstLevel: {
				name: geo!,
				riskLevel: 0,
				weight: 1,
				secondLevels: (assetClassess ?? []).map((ac) => ({
					name: ac.macroName!,
					riskLevel: ac.valueOnMap!,
					weight: ac.value!,
					thirdLevels: (ac.sectors ?? []).map((sector) => ({
						name: sector.assetClassIdentifier!,
						riskLevel: sector.valueOnMap!,
						weight: sector.value!,
						fourthLevels: (sector.subSectors ?? []).map((sub) => ({
							name: sub.assetClassIdentifier!,
							riskLevel: sub.valueOnMap!,
							weight: sub.value!,
						})),
					})),
				})),
			},
		})) satisfies WorldGridMapSnapshot["snapshot"],
	}));
}

const WorldGridMapBlock = (props: ContextContent<typeof RegionContext>) => {
	const hmmApi = useApiGen(HmmControllerApiFactory);
	useWidgetOptions(
		() => ({
			title: "Global risk map",
			alertsActive: false,
			actionHeader: <></>,
		}),
		[],
	);
	// hmmApi.retrieveGlobalRiskMap
	// hmmApi.retrieveGlobalRiskMapDates

	return (
		// <ReactQueryWrapper
		// 	queryKey={["queryGlobalRiskMap"]}
		// 	queryFn={async () => {
		// 		const dates = await axiosExtract(hmmApi.retrieveGlobalRiskMapDates("REGIME_PROBABILITIES"));
		// 		return axiosExtract(
		// 			hmmApi.retrieveGlobalRiskMap(
		// 				"REGIME_PROBABILITIES",
		// 				new Date(dates.at(-10) ?? "").toString(),
		// 				new Date(dates.at(-1) ?? "").toString(),
		// 			),
		// 		).toString();
		// 	}}
		// >
		// 	{(timeSeries) =>

		// 	}
		// </ReactQueryWrapper>
		<div className="w-[calc(100%_-_150px)] mx-auto">
			<WorldGrid
				dataProvider={async () => {
					const dates = await axiosExtract(hmmApi.retrieveGlobalRiskMapDates("REGIME_PROBABILITIES"));
					return generateSnapshotLevel(
						await axiosExtract(
							hmmApi.retrieveGlobalRiskMap(
								"REGIME_PROBABILITIES",
								new Date(dates.at(0) ?? "").toString(),
								new Date(dates.at(-1) ?? "").toString(),
							),
						),
					);
				}}
			/>
		</div>
	);
};

export default withContext(RegionContext)(WorldGridMapBlock);
