import type { FieldSize } from "@mdotm/mdotui/components";
import { Badge } from "@mdotm/mdotui/components";
import type { ClassList } from "@mdotm/mdotui/react-extensions";
import { ForEach, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import { SentimentSymbol } from "./icons/SentimentSymbol";
import type { IndicatorType, SentimentType } from "./sentiment";
import { match } from "ts-pattern";
import { categorizeStringAsSentiment } from "$root/functional-areas/commentary/sentiment-badges";
import { alwaysArray } from "@mdotm/mdotui/utils";

export function SentimentBadge(props: {
	size?: FieldSize;
	children: ReactNode;
	classList?: ClassList;
	indicator: IndicatorType;
	sentiment: SentimentType;
}): JSX.Element {
	const { classList, sentiment, indicator, children, size = "small" } = props;

	return (
		<Badge
			size={size}
			classList={{ capitalize: true, ...toClassListRecord(classList) }}
			backgroundColor={match({ indicator: props.indicator, sentiment: props.sentiment })
				.returnType<string>()
				.with({ indicator: "driver", sentiment: "super-positive" }, () => themeCSSVars.palette_P50)
				.with({ indicator: "driver", sentiment: "positive" }, () => themeCSSVars.palette_P50)
				.with({ indicator: "driver", sentiment: "neutral" }, () => themeCSSVars.palette_S50)
				.with({ indicator: "driver", sentiment: "negative" }, () => themeCSSVars.palette_graph_R50)
				.with({ indicator: "driver", sentiment: "super-negative" }, () => themeCSSVars.palette_graph_R50)

				.with({ indicator: "regime", sentiment: "super-positive" }, () => themeCSSVars.palette_P50)
				.with({ indicator: "regime", sentiment: "positive" }, () => themeCSSVars.palette_P50)
				.with({ indicator: "regime", sentiment: "neutral" }, () => themeCSSVars.palette_A50)
				.with({ indicator: "regime", sentiment: "negative" }, () => themeCSSVars.palette_graph_R50)
				.with({ indicator: "regime", sentiment: "super-negative" }, () => themeCSSVars.palette_graph_R50)

				.with({ indicator: "positioning", sentiment: "super-positive" }, () => themeCSSVars.palette_P100)
				.with({ indicator: "positioning", sentiment: "positive" }, () => themeCSSVars.palette_P50)
				.with({ indicator: "positioning", sentiment: "neutral" }, () => themeCSSVars.palette_S50)
				.with({ indicator: "positioning", sentiment: "negative" }, () => themeCSSVars.palette_graph_R50)
				.with({ indicator: "positioning", sentiment: "super-negative" }, () => themeCSSVars.palette_graph_R100)
				.run()}
			color={themeCSSVars.palette_N600}
			leftContent={<SentimentSymbol sentiment={sentiment} indicator={indicator} classList="w-full h-full" />}
		>
			{children}
		</Badge>
	);
}

export function AutoSentimentBadge(props: { children?: string | string[]; size?: FieldSize }): JSX.Element {
	const children = alwaysArray(props.children ?? []);
	return (
		<ForEach collection={children}>
			{({ item }) => {
				const data = categorizeStringAsSentiment(item);
				if (!data) {
					return item;
				}
				return (
					<SentimentBadge size={props.size} {...data}>
						{item}
					</SentimentBadge>
				);
			}}
		</ForEach>
	);
}
