import { ScrollWrapper } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import { useCallback, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { toClassName } from "$root/utils/react-dom-extra";
import { useImperativeHandlesRef, type ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { debugValue } from "@mdotm/mdotui/utils";

export type VirtualListImperativeHandles = {
	scrollToBottom(): void;
};

export type VirtualListProps<T> = {
	items: ArrayLike<T>;
	children: NodeOrFn<{ item: T; index: number }>;
	scrollToBottomChildren?: (props: { onClick(): void }) => ReactNode;
	keyProvider?(index: number): string | number;
} & ImperativeHandlesRefProps<VirtualListImperativeHandles>;

export function VirtualList<T>({
	items,
	children,
	scrollToBottomChildren,
	handlesRef,
	keyProvider,
}: VirtualListProps<T>): JSX.Element {
	const scrollableElementRef = useRef<HTMLDivElement | null>(null);
	const rowVirtualizer = useVirtualizer({
		getScrollElement: () => scrollableElementRef.current,
		count: items.length,
		estimateSize: () => 35,
		getItemKey: keyProvider,
	});
	const itemsRef = useUpdatedRef(items);
	const scrollToBottom = useCallback(
		() => rowVirtualizer.scrollToIndex(debugValue(itemsRef.current.length) - 1),
		[itemsRef, rowVirtualizer],
	);
	useImperativeHandlesRef(handlesRef, { scrollToBottom });
	return (
		<div className="relative z-0 flex-1 min-h-0 flex flex-col">
			<ScrollWrapper
				endShadow={({ scrollTillEnd }) =>
					scrollToBottomChildren && (
						<div
							className={toClassName({
								"absolute z-10 bottom-4 right-4 transition-opacity": true,
								"opacity-0": (scrollTillEnd ?? 0) < 10,
								"opacity-100": (scrollTillEnd ?? 0) >= 10,
							})}
						>
							{scrollToBottomChildren({
								onClick: scrollToBottom,
							})}
						</div>
					)
				}
				classList="flex-1"
				outerContainerAppearance={{ classList: "flex-1 relative z-0" }}
				innerRef={scrollableElementRef}
			>
				<div
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						width: "100%",
						position: "relative",
					}}
				>
					{/* Only the visible items in the virtualizer, manually positioned to be in view */}
					{rowVirtualizer.getVirtualItems().map((virtualItem) => (
						<div
							key={virtualItem.key}
							data-index={virtualItem.index}
							ref={rowVirtualizer.measureElement}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								// minHeight: `${virtualItem.size}px`,
								transform: `translateY(${virtualItem.start}px)`,
							}}
						>
							{renderNodeOrFn(children, {
								item: items[virtualItem.index],
								index: virtualItem.index,
							})}
						</div>
					))}
				</div>
			</ScrollWrapper>
		</div>
	);
}
