export function SendIcon({ color = "#4CB09C" }: { color?: string }): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
			<path
				fill={color}
				d="M22.47 3.197a.859.859 0 00-.872-.133L2.815 10.708l-.012.006c-.52.234-.833.748-.8 1.31v.004c.035.565.407 1.034.95 1.197l7.11 2.15.257 4.814v.002a.858.858 0 00.576.758.853.853 0 00.93-.237l2.385-2.64 3.423 2.368c.226.159.488.241.762.241.627 0 1.18-.45 1.312-1.069 0-.002 0-.004.002-.006L22.768 4.03a.875.875 0 00-.298-.832zM20.505 6.62l-2.372 12.074-5.893-4.08 8.264-7.994zm-7.72 10.46l-.862.953-.085-1.607.946.655zm5.678-10.882l-7.81 7.555-6.17-1.865 13.98-5.69z"
			/>
		</svg>
	);
}
