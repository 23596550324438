import { useSize } from "$root/utils/react-dom-extra";
import { useUpdatedRef } from "$root/utils/react-extra";
import { noop } from "$root/utils/runtime";
import type { CSSProperties } from "react";
import type React from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import type { TransientNotificationData } from "../hooks";
import { animationDuration } from "../hooks";
import { AnimatedSnackWrapper } from "./snack";

type NotificationCollection = {
	notifications: Partial<Record<number, TransientNotificationData>>;
	lastId: number;
};

export const TransientNotificationContext = createContext<{
	io?: {
		setNotifications: (newValue: NotificationCollection) => void;
		getNotifications: () => NotificationCollection;
	};
	setNotificationsIO: (
		getter: () => NotificationCollection,
		setter: (newValue: NotificationCollection) => void,
	) => void;
}>({
	setNotificationsIO: noop,
});

/**
 * Wrap your app using this component to provide notification functionalities to all
 * children components.
 */
export function TransientNotificationProvider({ children }: { children: React.ReactNode }): JSX.Element {
	const [gettersAndSetters, setGettersAndSetters] = useState({});
	const setNotificationsIO = useCallback(
		(getter: () => NotificationCollection, setter: (v: NotificationCollection) => void) => {
			setGettersAndSetters((prev) => ({ ...prev, io: { setNotifications: setter, getNotifications: getter } }));
		},
		[],
	);
	return (
		<TransientNotificationContext.Provider value={{ ...gettersAndSetters, setNotificationsIO }}>
			{children}
		</TransientNotificationContext.Provider>
	);
}

export type TransientNotificationSlotProps = {
	overlayStyle?: CSSProperties;
	overlayClassName?: string;
	inPageStyle?: CSSProperties;
	inPageClassName?: string;
};

/**
 * Place this element where you'd like notifications to appear when they're location
 * is set to "in-page". The overlay notifications will automatically be ported to the `document.body`
 * by this same component.
 */
export function TransientNotificationSlot({
	overlayStyle,
	overlayClassName,
	inPageStyle,
	inPageClassName,
}: TransientNotificationSlotProps): JSX.Element {
	const [notifications, setNotifications] = useState<NotificationCollection>({ lastId: 0, notifications: {} });
	const [ownContainerDiv, setOwnContainerDiv] = useState<HTMLDivElement | null>(null);
	const ownContainerDivSize = useSize(ownContainerDiv);

	const [portalContainerDiv, setPortalContainerDiv] = useState<HTMLDivElement | null>(null);
	const portalContainerDivSize = useSize(portalContainerDiv);

	const ctx = useContext(TransientNotificationContext);
	const notificationsRef = useRef(notifications);
	const ctxRef = useUpdatedRef(ctx);
	useEffect(() => {
		ctxRef.current.setNotificationsIO(
			() => notificationsRef.current,
			(newValue) => {
				notificationsRef.current = newValue;
				setNotifications(newValue);
			},
		);
	}, [ctxRef, notificationsRef]);

	const ownChildren = useMemo(() => {
		return (
			Object.entries(notifications.notifications).filter(
				([, data]) => data !== undefined && data.location === "in-page",
			) as [string, TransientNotificationData][]
		).map(([key, data]) => <AnimatedSnackWrapper {...data} key={key} />);
	}, [notifications]);

	const portalChildren = useMemo(() => {
		return (
			Object.entries(notifications.notifications).filter(
				([, data]) => data !== undefined && data.location === "overlay",
			) as [string, TransientNotificationData][]
		).map(([key, data]) => <AnimatedSnackWrapper {...data} key={key} />);
	}, [notifications]);

	return (
		<>
			<div
				className={`relative transition-[height] overflow-hidden ${inPageClassName ?? ""}`}
				style={{
					height: ownContainerDivSize?.height ?? 0,
					transitionDuration: `${animationDuration}ms`,
					...inPageStyle,
				}}
			>
				<div className="absolute top-0 left-0 right-0" ref={setOwnContainerDiv}>
					{ownChildren}
				</div>
			</div>
			{createPortal(
				<div
					className={`fixed top-0 left-0 right-0 transition-[height] overflow-hidden ${overlayClassName ?? ""}`}
					style={{
						zIndex: 1500,
						height: portalContainerDivSize?.height ?? 0,
						transitionDuration: `${animationDuration}ms`,
						...overlayStyle,
					}}
				>
					<div className="absolute top-0 left-0 right-0" ref={(ref) => setPortalContainerDiv(ref)}>
						{portalChildren}
					</div>
				</div>,
				document.body,
			)}
		</>
	);
}
