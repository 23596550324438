import { forwardRef, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import "./TrendChart.scss";
import { PaletteColors } from "$root/styles/themePalette";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import type { Chart } from "highcharts";
import { useTranslation } from "react-i18next";
import { useLocaleFormatters } from "$root/localization/hooks";

type TrendChartChartProps = {
	series: {
		name: string;
		data: number[];
		color: string;
	};
	xMin: number;
	xMax: number;
	yMax?: number;
};

const TrendChart = forwardRef<{ chart: Chart }, TrendChartChartProps>(function TrendChartInner(
	{ series, xMin, xMax, yMax = 200 },
	ref,
) {
	const { t } = useTranslation();

	const { formatNumber } = useLocaleFormatters();

	const options = useMemo(() => {
		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
					styledMode: true,
				},
			},
			credits: {
				enabled: false,
			},
			navigation: {
				buttonOptions: {
					enabled: false,
				},
			},
			title: {
				text: "",
			},
			xAxis: {
				title: {
					text: "",
				},
				min: xMin,
				max: xMax,
				minorGridLineColor: "#C5EEFA",
				labels: {
					formatter(this: { value: string | number }) {
						return formatNumber(Number(this.value) * 100, 0) + "%";
					},
				},
			},
			yAxis: {
				title: {
					text: "",
				},
				min: 0,
				max: yMax,
				labels: {
					formatter(this: { value: string | number }) {
						return formatNumber(this.value, { maxDecimalPlaces: 2, minDecimalPlaces: 1 });
					},
				},
			},
			series: [
				{ type: "scatter", data: series.data, visible: false },
				{
					type: "histogram",
					xAxis: 0,
					yAxis: 0,
					baseSeries: 0,
					color: series.color,
					// binsNumber: 15, // 30, square-root, sturges or rice.
					// binWidth: 0.01,
				},
			],
			tooltip: {
				enabled: false,
				shared: false,
				useHTML: true,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: PaletteColors.WHITE,
				formatter(this: { x: number; y: number }) {
					return `
						<table class="trend-graph-tooltip">
							<tr>
								<th colspan="2" class="title" style="
								background-color: #eeeef1;
								color: #9699b3;">${t("POINT")}</th>
							</tr>
							<tr>
								<td class='left-text'>X</td>
								<td class='right-text'><b>${formatNumber(Number(this.x) * 100, {
									maxDecimalPlaces: 2,
									minDecimalPlaces: 1,
								})}%</b></td>
							</tr>
							<tr>
								<td class='left-text'>Y</td>
								<td class='right-text'><b>${formatNumber(Number(this.y) * 100, {
									maxDecimalPlaces: 2,
									minDecimalPlaces: 1,
								})}%</b></td>
							</tr>
						</table>
					`;
				},
			},
			legend: {
				enabled: false,
			},
			plotOptions: {
				line: {
					enableMouseTracking: false,
					marker: {
						enabled: false,
					},
				},
				series: {
					enableMouseTracking: false,
					label: {
						connectorAllowed: false,
					},
				},
			},
		};
	}, [series, t, formatNumber]);

	return (
		<div className="TrendChart">
			<HighchartsReact
				containerProps={{ style: { height: "100%" } }}
				highcharts={Highcharts}
				constructorType="chart"
				options={options}
				ref={ref as any}
			/>
		</div>
	);
});

export default TrendChart;
