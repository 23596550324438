// TODO: add sphere default template in frontend

import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectEntriesFn, customObjectKeysFn } from "$root/utils/experimental";
import { AsyncButton, Button, Collapsible, DefaultCollapsibleHeader, Icon, Text } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { sleep } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ReportTemplateVariant } from "./report-latest";
import { itemToLabelMap, type ReportTemplateItemFor, type ReportTemplateItemMap } from "./report-latest";
import { capitalizeString } from "$root/utils/strings";

export type SetConfigurationReportTemplateHelper<K extends keyof ReportTemplateItemMap> = {
	templateVariant: ReportTemplateVariant;
	configuration: ReportTemplateItemFor<K>;
	onChange(newConfiguration: ReportTemplateItemFor<K>): void;
	onCancel(): void;
};

export function SideHeaderContent(props: {
	title: string;
	onCancel?(): void;
	onSubmit(): MaybePromise<void>;
}): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className={`flex items-center border-b border-solid border-b-[color:${themeCSSVars.palette_N100}] p-4`}>
			{/* <Icon icon="share" classList="mr-1" size={22} color={themeCSSVars.palette_N700} /> */}
			<Text type="Body/XL/Bold" as="p" classList="grow h-fit line-clamp-2">
				{props.title}
			</Text>
			{/* <Button unstyled>
					<Icon icon="ask-positionleftsmall" color={themeCSSVars.palette_N700} size={18} />
				</Button> */}
			<div className="flex justify-end py-2 px-2 space-x-2 bg-white">
				<Button palette="tertiary" onClick={props.onCancel}>
					{t("BUTTON.CANCEL")}
				</Button>
				<AsyncButton palette="primary" onClickAsync={props.onSubmit}>
					{t("BUTTON.SAVE")}
				</AsyncButton>
			</div>
		</div>
	);
}

export function SideBarContent(props: {
	title: string;
	onSubmit(): MaybePromise<void>;
	onCancel(): void;
	children: ReactNode;
}): JSX.Element {
	// bg-[color:${themeCSSVars.palette_N20}]
	return (
		<div className="grow bg-white overflow-y-auto pb-6">
			<SideHeaderContent title={props.title} onSubmit={props.onSubmit} onCancel={props.onCancel} />
			<div className={`px-4 pt-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				{props.children}
			</div>
		</div>
	);
}

export function Summary(props: SetConfigurationReportTemplateHelper<"summary">): JSX.Element {
	const {
		configuration: { id, kind, ...opts },
	} = props;
	const { t } = useTranslation();
	const { getValues, control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`PORTFOLIO_SUMMARY.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Disclaimer(props: SetConfigurationReportTemplateHelper<"disclaimer">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<FormFields.TextArea label="Content" name="content" control={control} formState={formState} />
		</SideBarContent>
	);
}

export function Cover(props: SetConfigurationReportTemplateHelper<"cover">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the title you want to display</p>
			<FormFields.Text label="Title" name="title" control={control} formState={formState} />
		</SideBarContent>
	);
}

export function PortfolioMetrics(props: SetConfigurationReportTemplateHelper<"portfolioMetrics">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	const volatilityOpts = watch("volatility");
	const selectedVolatilityOpts = Object.values(volatilityOpts).filter((opt) => opt);

	const efficiencyRatioOpts = watch("efficiencyRatio");
	const selectedEfficiencyRatioOpts = Object.values(efficiencyRatioOpts).filter((opt) => opt);

	const maxDrawdownOpts = watch("maxDrawdown");
	const selectedMaxDrawdownOpts = Object.values(maxDrawdownOpts).filter((opt) => opt);

	const performanceOpts = watch("performance");
	const selectedPerformanceOpts = Object.values(performanceOpts).filter((opt) => opt);

	const sortinoOpts = watch("sortino");
	const selectedSortinoOpts = Object.values(sortinoOpts).filter((opt) => opt);

	function PortfolioMetricsCollapsibleContent(props: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{props.children}</div>;
	}

	function selectionToLabel(list: Array<any>) {
		return list.length > 0 ? `(${list.length})` : "";
	}

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Select the elements you want to display</p>
			<div className="flex flex-col space-y-2">
				{/* volatility */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Volatility {selectionToLabel(selectedVolatilityOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(volatilityOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`volatility.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Efficiency ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Efficiency ratio {selectionToLabel(selectedEfficiencyRatioOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(efficiencyRatioOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`efficiencyRatio.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Max drawdown ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Max drawdown {selectionToLabel(selectedMaxDrawdownOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(maxDrawdownOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`maxDrawdown.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Performance */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Performance {selectionToLabel(selectedPerformanceOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(performanceOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`performance.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Sortino */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Sortino {selectionToLabel(selectedSortinoOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(sortinoOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox control={control} formState={formState} name={`sortino.${key}`} classList="mb-1">
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>
			</div>
			<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				<div className="px-4">
					<p className="mb-2 font-semibold">Compare</p>
					<FormFields.Checkbox control={control} formState={formState} name="benchmark">
						Show benchmark
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function ExAntePortfolioMetrics(
	props: SetConfigurationReportTemplateHelper<"exAntePortfolioMetrics">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	console.log(opts)
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	const volatilityOpts = watch("volatility");
	const selectedVolatilityOpts = Object.values(volatilityOpts).filter((opt) => opt);

	const diversificationRatioOpts = watch("diversificationRatio");
	const selectedDiversificationRatioOpts = Object.values(diversificationRatioOpts).filter((opt) => opt);

	const efficiencyRatioOpts = watch("efficiencyRatio");
	const selectedEfficiencyRatioOpts = Object.values(efficiencyRatioOpts).filter((opt) => opt);

	const maxDrawdownOpts = watch("maxDrawdown");
	const selectedMaxDrawdownOpts = Object.values(maxDrawdownOpts).filter((opt) => opt);

	const historicalVarOpts = watch("historicalVar");
	const selectedHistoricalVarOpts = Object.values(historicalVarOpts).filter((opt) => opt);

	// const parametricVarOpts = watch("parametricVar");
	// const selectedParametricVarOpts = Object.values(parametricVarOpts).filter((opt) => opt);

	const returnOpts = watch("return");
	const selectedReturnOpts = Object.values(returnOpts).filter((opt) => opt);

	const trackingErrorOpts = watch("trackingError");
	const selectedTrackingErrorOpts = Object.values(trackingErrorOpts).filter((opt) => opt);

	const riskConstraintOpts = watch("riskConstraint");
	const selectedRiskConstraintOpts = Object.values(riskConstraintOpts).filter((opt) => opt);

	function PortfolioMetricsCollapsibleContent(props: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{props.children}</div>;
	}

	function selectionToLabel(list: Array<any>) {
		return list.length > 0 ? `(${list.length})` : "";
	}

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Select the elements you want to display</p>
			<div className="flex flex-col space-y-2">
				{/* volatility */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Volatility {selectionToLabel(selectedVolatilityOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(volatilityOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`volatility.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Efficiency ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Efficiency ratio {selectionToLabel(selectedEfficiencyRatioOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(efficiencyRatioOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`efficiencyRatio.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Max drawdown ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Max drawdown {selectionToLabel(selectedMaxDrawdownOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(maxDrawdownOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`maxDrawdown.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Diversification ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Diversification ratio {selectionToLabel(selectedDiversificationRatioOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(diversificationRatioOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`diversificationRatio.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Historical Var */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Historical Var {selectionToLabel(selectedHistoricalVarOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(historicalVarOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`historicalVar.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Parametric Var */}
				{/* <Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Parametric Var {selectionToLabel(selectedParametricVarOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(parametricVarOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`parametricVar.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible> */}

				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Risk constraint {selectionToLabel(selectedRiskConstraintOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(riskConstraintOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`riskConstraint.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Return */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Return {selectionToLabel(selectedReturnOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(returnOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox control={control} formState={formState} name={`return.${key}`} classList="mb-1">
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Tarcking error */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Tarcking error {selectionToLabel(selectedTrackingErrorOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={customObjectEntriesFn(trackingErrorOpts)}>
							{({ item: [key] }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`trackingError.${key}`}
										classList="mb-1"
									>
										{t(`EXANTE_METRICS.METRICS.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>
			</div>
			<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				<div className="px-4">
					<p className="mb-2 font-semibold">Compare</p>
					<FormFields.Checkbox control={control} formState={formState} name="benchmark">
						Show benchmark
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function FreeText(props: SetConfigurationReportTemplateHelper<"freeText">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the description you want to display</p>
			<FormFields.TextArea label="Description" name="content" control={control} formState={formState} />
		</SideBarContent>
	);
}

export function Commentary(props: SetConfigurationReportTemplateHelper<"commentary">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{capitalizeString(key)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Performance(props: SetConfigurationReportTemplateHelper<"performance">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{capitalizeString(key)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Composition(props: SetConfigurationReportTemplateHelper<"composition">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: {
			currentColumnPreferences: props.templateVariant === "current" ? opts.currentColumnPreferences : [],
			proposalColumnPreferences: props.templateVariant === "proposal" ? opts.proposalColumnPreferences : [],
		},
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>

				<ForEach
					collection={
						props.templateVariant === "current" ? options.currentColumnPreferences : opts.proposalColumnPreferences
					}
				>
					{({ item: { column }, index }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={
									props.templateVariant === "current"
										? `currentColumnPreferences.${index}.enabled`
										: `proposalColumnPreferences.${index}.enabled`
								}
								classList="mb-1"
							>
								{t(`TABLE.HEADERS.${column}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAttribution(
	props: SetConfigurationReportTemplateHelper<"performanceAttribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`FORECAST_HORIZON.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAndVolatilityContribution(
	props: SetConfigurationReportTemplateHelper<"performanceAndVolatilityContribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function ExAnteContributionVolatility(
	props: SetConfigurationReportTemplateHelper<"exAnteContributionVolatility">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function FactorExposure(props: SetConfigurationReportTemplateHelper<"factorExposure">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectEntriesFn(options)}>
					{({ item: [key] }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{capitalizeString(key)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Exposure(props: SetConfigurationReportTemplateHelper<"exposure">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, getValues, formState } = useForm({
		defaultValues: opts,
	});

	const options = getValues();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await sleep(1000);
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={customObjectKeysFn(options)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}
