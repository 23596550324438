export const notificationMap = {
	PortfolioStatusUpdateAlertCheck: { checked: false, type: "PORTFOLIO" },

	PortfolioConstraintsAlertCheck: { checked: false, type: "PORTFOLIO" },
	RiskConstraintAlertCheck: { checked: false, type: "PORTFOLIO" },
	PortfolioStrategyBreachedAlertCheck: { checked: false, type: "PORTFOLIO" },

	PortfolioConstraintWarningAlertCheck: { checked: false, type: "PORTFOLIO" },
	RiskConstraintWarningAlertCheck: { checked: false, type: "PORTFOLIO" },
	PortfolioStrategyWarningAlertCheck: { checked: false, type: "PORTFOLIO" },

	InstrumentsChangesChildAlertCheck: { checked: false, type: "PORTFOLIO" },

	MarketViewExpiredAlertCheck: { checked: false, type: "PORTFOLIO" },
	// GrowthMarketViewAlignmentAlertCheck: { checked: false, threshold: 0, type: "PORTFOLIO" },
	// LateralPhaseMarketViewAlignmentAlertCheck: { checked: false, threshold: 0, type: "PORTFOLIO" },
	// HighStressMarketViewAlignmentAlertCheck: { checked: false, threshold: 0, type: "PORTFOLIO" },

	EquityMarketPositioningAlertCheck: { checked: false, type: "MARKET" },
	FixedIncomeMarketPositioningAlertCheck: { checked: false, type: "MARKET" },
	CommoditiesMarketPositioningAlertCheck: { checked: false, type: "MARKET" },

	EquityMarketRegimeChangesAlertCheck: { checked: false, type: "MARKET" },
	FixedIncomeMarketRegimeChangesAlertCheck: { checked: false, type: "MARKET" },
	CommoditiesMarketRegimeChangesAlertCheck: { checked: false, type: "MARKET" },

	SharingEntityEmailOnEditCheck: { checked: false, type: "ENTITY_SHARED" },
	SharingEntityEmailOnOwnerPassedCheck: { checked: false, type: "ENTITY_SHARED " },
	SharingEntityEmailOnShareCheck: { checked: false, type: "ENTITY_SHARED" },
};

export type NotificationMap = Record<
	keyof typeof notificationMap,
	{ checked: boolean; threshold?: number; type?: string }
>;
