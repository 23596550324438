import type { FactorsDto, FactorsMarketDto } from "$root/api/api-gen";
import { MarketControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { useTranslation } from "react-i18next";
import { ActionText, AutoTooltip, Checkbox, Table, TooltipContent } from "@mdotm/mdotui/components";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ScrollWrapper } from "@mdotm/mdotui/components";
import {
	customObjectEntriesFn,
	customObjectValuesFn,
	getGraphMarkers,
	roundCustomByStep,
} from "$root/utils/experimental";
import { useMemo, useState } from "react";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import { builtInSort, noop } from "@mdotm/mdotui/utils";
import { useLocaleFormatters } from "$root/localization/hooks";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { pxToRem } from "$root/utils/pxToRem";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { useEventBus } from "$root/event-bus";
import InfiniteLoader from "$root/components/InfiniteLoader";
import { IconWalls } from "$root/components/IconWall";

type Unpacked<T> = T extends (infer U)[] ? U : T;

type ReducedResultProps = {
	[key: string]: {
		[key in keyof FactorsMarketDto]?: FactorsDto;
	} & { groupKey: string };
};

const FactorsExposure = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter, thirdFilter } = props;
	const { assetClass, geography, sector } = useOutlookComposition(firstFilter, secondFilter, thirdFilter);
	const [filterBySignificant, setFilterBySignificant] = useState(true);
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const marketApi = useApiGen(MarketControllerApiFactory);

	const FactorsDescription = useMemo(
		() =>
			t("EXPOSUREFACTORS.TOOLTIPDESCRIPTIONS", {
				returnObjects: true,
			}),
		[t],
	);

	// UseQuery
	const {
		data: FactorsData,
		isLoading,
		isError,
		refetch,
	} = useQueryNoRefetch(["FactorsExposure", assetClass, geography, sector], {
		queryFn: async () => {
			const { data: rawData } = await marketApi.retrieveFactorsByAssetClass(assetClass, geography, sector);

			const aggregateData = customObjectValuesFn(
				customObjectEntriesFn(rawData).reduce<ReducedResultProps>((acc, [key, factorList]) => {
					factorList?.forEach((factor) => {
						if (factor.key === undefined) {
							acc["undefinedKey"] = {
								...acc["undefinedKey"],
								[key]: factor,
								groupKey: "undefinedKey",
							};
						} else {
							acc[factor.key] = {
								...acc[factor.key],
								[key]: factor,
								groupKey: factor.key,
							};
						}
					});
					return acc;
				}, {}),
			);

			return aggregateData;
		},
		onError: (e) => console.warn(e),
	});

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	const calculatedData = useMemo(() => {
		const filteredData = filterBySignificant ? FactorsData?.filter(({ market }) => market?.value !== 0) : FactorsData;
		return filteredData;
	}, [FactorsData, filterBySignificant]);

	const graphLimits = useMemo(() => {
		const limits = (calculatedData ?? []).reduce(
			(a, c) => {
				return {
					maxValue: Math.max(a.maxValue, c.market?.value ?? 0),
					minValue: Math.min(a.minValue, c.market?.value ?? 0),
				};
			},
			{ maxValue: 0, minValue: 0 },
		);
		return {
			maxValue: roundCustomByStep(limits.maxValue, 0.5),
			minValue: roundCustomByStep(limits.minValue, 0.5),
		};
	}, [calculatedData]);

	const graphColumnMarkers = useMemo(() => {
		const markers = getGraphMarkers(graphLimits.maxValue, graphLimits.minValue, 0.5, "", 10);
		return markers;
	}, [graphLimits.maxValue, graphLimits.minValue]);

	const columns = useMemo<Array<TableColumn<Unpacked<NonNullable<typeof calculatedData>>>>>(
		() => [
			{
				relativeWidth: 50,
				header: t("FACTORSEXPOSURE.TABLE.FACTORS"),
				content: ({ market }) => (
					<AutoTooltip
						severity="info"
						trigger={({ innerRef }) => (
							<span className="font-bold underline" ref={innerRef}>
								{market?.label}
							</span>
						)}
						position="bottom"
						align="startToEnd"
					>
						<TooltipContent>
							{FactorsDescription[market?.key as keyof typeof FactorsDescription] ?? market?.key}&nbsp;
							<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
								Read more
							</ActionText>
						</TooltipContent>
					</AutoTooltip>
				),
				sortFn: (a, b) => builtInSort(a.market?.label, b.market?.label),
				name: "factors",
			},
			{
				relativeWidth: 10,
				header: t("FACTORSEXPOSURE.TABLE.MARKET"),
				content: ({ market }) => formatNumber(market?.value),
				sortFn: (a, b) => builtInSort(Math.abs(a.market?.value ?? 0), Math.abs(b.market?.value ?? 0)),
				name: "market",
				align: "end",
				cellClassList: "tabular-nums",
			},
			{
				relativeWidth: 40,
				header: () => (
					<div className="flex justify-between grow">
						{graphColumnMarkers.map((m, i) => (
							<div key={`marker-${i}`} className="">
								{m.label}
							</div>
						))}
					</div>
				),
				content: ({ market }) => (
					<BarGraphPCSvg
						classList="w-full"
						options={{
							resize: true,
							marksLabels: false,
							markerStep: 0.5,
							scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
						}}
						data={[{ value: market?.value ?? 0, color: market?.relevant ? "#005C8B" : "red" }]}
					/>
				),
			},
		],
		[FactorsDescription, formatNumber, graphColumnMarkers, graphLimits.maxValue, graphLimits.minValue, t],
	);

	useWidgetOptions(
		() => ({
			title: t("FACTORSEXPOSURE.TITLE"),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						<InfoTooltip>
							<TooltipContent>
								{/* TODO: add translation */}
								<p>
									Gain insights into the performance drivers of the asset class through factor exposure analysis.
									Discover how Sphere calculates its factors by accessing more information&nbsp;
									<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
										here.
									</ActionText>
								</p>
							</TooltipContent>
						</InfoTooltip>
					</div>
				);
			},
		}),
		[t],
	);

	return isLoading ? (
		<InfiniteLoader />
	) : isError || !calculatedData ? (
		<IconWalls.ErrorData />
	) : calculatedData.length === 0 ? (
		<IconWalls.DataNotAvailable />
	) : (
		<>
			<div className="flex justify-end">
				<Checkbox
					onChange={() => setFilterBySignificant(!filterBySignificant)}
					checked={filterBySignificant}
					switchPosition="start"
					switchType="switch"
				>
					Significant factors only
				</Checkbox>
			</div>
			<ScrollWrapper startShadow={false} classList="mb-6">
				<Table
					headerRowClassList="sticky top-0 z-10"
					columns={columns}
					rows={calculatedData.sort(
						(a, b) => builtInSort(Math.abs(a.market?.value ?? 0), Math.abs(b.market?.value ?? 0)) * -1,
					)}
				/>
			</ScrollWrapper>
			<GraphLegend>
				<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
					<ColoredRectangle color="#005C8B" variant="vertical" /> Market
				</div>
			</GraphLegend>
		</>
	);
};

export default withContext(UniverseFiltersContext)(FactorsExposure);
