import type { InvestmentSummary } from "$root/api/api-gen";
import { IconWalls } from "$root/components/IconWall";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { defaultWidgetsGridConfig } from "$root/widgets-architecture/layout/WidgetsGrid/default-grids";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import type { UseQueryResult } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

const { PORTFOLIO_DETAILS, PORTFOLIO_ENHANCEMENT, PORTFOLIO_REFERENCE } = defaultWidgetsGridConfig;
const widgetKeys = [PORTFOLIO_DETAILS, PORTFOLIO_ENHANCEMENT, PORTFOLIO_REFERENCE].flatMap((config) =>
	config.configuration.map(({ i }) => i),
);

type PortfolioWidgetStatusIconWalls = {
	error?: ReactNode; // TODO: add defaults
	calculating?: ReactNode;
	waitingForHistoricalData?: ReactNode;
	empty?: ReactNode; // for portfoliometrics (use Portfolio	Monitoring Empty Data variant)
};

type WidgetKeys = (typeof widgetKeys)[number];
type PortfolioWidgetStatusProps = {
	summary: InvestmentSummary;
	widgetKey: WidgetKeys;
	iconWalls?: PortfolioWidgetStatusIconWalls;
};

export enum WidgetStatus {
	ERROR = "error",
	CALCULATING = "calculating",
	WAIT_FOR_HISTORICAL_DATA = "waitingForHistoricalData",
	EMPTY = "empty",
	READY = "ready",
}

function missingHistoricalDataReason(summary: InvestmentSummary) {
	const interval = new Date().getTime() - new Date(summary.creationDate ?? 0).getTime();
	if (interval < 24 * 60 * 60 * 1000) {
		return WidgetStatus.WAIT_FOR_HISTORICAL_DATA;
	}
	return WidgetStatus.CALCULATING;
}

export type WidgetStatusUnion = `${WidgetStatus}`;
export function PortfolioQueryWidgetBase<TData = unknown, TError = unknown>(props: {
	query: UseQueryResult<
		| { widgetStatus: WidgetStatus.READY; data: NonNullable<TData> }
		| { widgetStatus: Exclude<WidgetStatusUnion, "ready">; data: TData },
		TError
	>;
	// children: NodeOrFn<NonNullable<TData>>;
	children(
		queryResult: NonNullable<TData>,
		query: UseQueryResult<
			| { widgetStatus: WidgetStatus.READY; data: NonNullable<TData> }
			| { widgetStatus: Exclude<WidgetStatusUnion, "ready">; data: TData },
			TError
		>,
	): React.ReactNode;
	errorFallback?: NodeOrFn<{ error?: TError; retry(): Promise<void> }>;
	iconWalls?: PortfolioWidgetStatusIconWalls;
}): JSX.Element {
	const iconWalls: PortfolioWidgetStatusIconWalls = {
		error: <IconWalls.ErrorPortfolioWidgetData />,
		calculating: <IconWalls.CalculatingPortfolioWidgetData />,
		waitingForHistoricalData: <IconWalls.WaitForHistoricalPortfolioWidgetData />,
		empty: <IconWalls.DataNotAvailable />,
		...props.iconWalls,
	};
	return (
		// TODO: customize default loading and error fallback, e.g. errorFallback={props.errorFallback ?? errorFallbackWithIconWallOrSomething}
		<ReactQueryWrapperBase
			query={props.query}
			errorFallback={props.errorFallback}
			loadingFallback={<IconWalls.CalculatingApi />}
		>
			{({ data, widgetStatus }, rq) => {
				return <>{widgetStatus === "ready" ? props.children(data, rq) : iconWalls[widgetStatus]}</>;
			}}
		</ReactQueryWrapperBase>
	);
}

type PortfolioWidgetMissingDataReasonReturn = Exclude<WidgetStatusUnion, "ready">;
export function portfolioWidgetMissingDataReason(
	summary: InvestmentSummary,
	widgetName: WidgetKeys,
): PortfolioWidgetMissingDataReasonReturn {
	switch (widgetName) {
		case "PortfolioPerformance":
		case "PerformanceMetricsBlock":
		case "ContributionVolatility":
		case "Attribution":
			return match(summary)
				.returnType<PortfolioWidgetMissingDataReasonReturn>()
				.with({ status: "ERROR" }, () => WidgetStatus.ERROR)
				.with({ status: "CALCULATING", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "CREATION" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: false }, (summary2) =>
					missingHistoricalDataReason(summary2),
				)
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: true }, (summary2) =>
					missingHistoricalDataReason(summary2),
				)
				.with({ status: "ACCEPTED", action: "ENHANCEMENT" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "ACCEPTED", action: "EDIT" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "ACCEPTED", action: "UPLOAD" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "READY", action: "CREATION" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "READY", action: "EDIT" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "READY", action: "UPLOAD" }, (summary2) => missingHistoricalDataReason(summary2))
				.with({ status: "PROPOSAL_READY", action: "ENHANCEMENT" }, (summary2) => missingHistoricalDataReason(summary2))
				.otherwise(() => WidgetStatus.ERROR);
		case "PortfolioMonitoringBlock":
		case "PortfolioCommentaryMDBlock":
		case "ExanteMetricsBlock":
		case "ExanteContributionVolatility":
		case "PortfolioFactorsExposure":
		case "ExposureEvolve":
		case "ExposureCompare":
		case "PortfolioHistoryBlock":
			return match(summary)
				.returnType<PortfolioWidgetMissingDataReasonReturn>()
				.with({ status: "ERROR" }, () => WidgetStatus.ERROR)
				.with({ status: "CALCULATING", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "CREATION" }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: false }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: true }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "ENHANCEMENT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "CREATION" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "PROPOSAL_READY", action: "ENHANCEMENT" }, () => WidgetStatus.CALCULATING)
				.otherwise(() => WidgetStatus.ERROR);
		case "Composition":
			return match(summary)
				.returnType<PortfolioWidgetMissingDataReasonReturn>()
				.with({ status: "ERROR" }, () => WidgetStatus.ERROR)
				.with({ status: "CALCULATING", action: "EDIT" }, () => WidgetStatus.CALCULATING) // be: req change status behaviour
				.with({ status: "CALCULATING", action: "UPLOAD" }, () => WidgetStatus.CALCULATING) // be: req change status behaviour
				.with({ status: "CALCULATING", action: "CREATION" }, () => WidgetStatus.CALCULATING) // be: req change status behaviour
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: false }, () => WidgetStatus.CALCULATING)
				.with({ status: "CALCULATING", action: "ENHANCEMENT", hasProposalDraft: true }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "ENHANCEMENT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "ACCEPTED", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "CREATION" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "EDIT" }, () => WidgetStatus.CALCULATING)
				.with({ status: "READY", action: "UPLOAD" }, () => WidgetStatus.CALCULATING)
				.with({ status: "PROPOSAL_READY", action: "ENHANCEMENT" }, () => WidgetStatus.CALCULATING)
				.otherwise(() => WidgetStatus.ERROR);
		default:
			return "error";
	}
}

function PortfolioWidgetStatus(props: PortfolioWidgetStatusProps): ReactNode {
	const iconWalls: PortfolioWidgetStatusIconWalls = {
		error: <IconWalls.ErrorData />,
		calculating: <IconWalls.CalculatingData />,
		waitingForHistoricalData: <IconWalls.HistoricalDataNotAvailable />,
		empty: <IconWalls.DataNotAvailable />,
		...props.iconWalls,
	};
	return iconWalls[portfolioWidgetMissingDataReason(props.summary, props.widgetKey)];
}

export default PortfolioWidgetStatus;
