import { unpromisify } from "$root/utils/functions";
import type { DataAttributesProps } from "@mdotm/mdotui/components";
import { Button, Icon, Transition, defaultTailwindTransitionDurationMs } from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { sleep } from "@mdotm/mdotui/utils";
import type { Set } from "immutable";
import type { MouseEvent } from "react";
import { useState } from "react";

export const CommonItemActions = {
	Copy({ disabled, onCopied, text }: { disabled?: boolean; onCopied(): void; text: string }): JSX.Element {
		const [pulse, setPulse] = useState(false);
		const { run, loading } = useAsync({
			asyncFn: async () => {
				setPulse(true);
				await Promise.all([
					window.navigator.clipboard.writeText(text).catch(console.error),
					sleep(defaultTailwindTransitionDurationMs),
				]);
				setPulse(false);
				await sleep(defaultTailwindTransitionDurationMs);
				onCopied();
			},
		});
		return (
			<Transition
				in={pulse}
				classList="transition-transform"
				enterFromClassList="scale-100"
				enterToClassList="scale-125"
			>
				{({ classList }) => (
					<Button disabled={disabled || loading} unstyled classList={classList} onClick={unpromisify(() => run())}>
						<Icon
							icon="Content-Copy"
							size={21}
							color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.palette_P500}
						/>
					</Button>
				)}
			</Transition>
		);
	},
	Edit({
		disabled,
		onClick,
		...dataAttributes
	}: { disabled?: boolean; onClick(e: MouseEvent): void } & DataAttributesProps): JSX.Element {
		return (
			<Button disabled={disabled} unstyled onClick={onClick} {...dataAttributes}>
				<Icon icon="Edit" size={21} color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.palette_P500} />
			</Button>
		);
	},
	Settings({ disabled, onClick }: { disabled?: boolean; onClick(e: MouseEvent): void }): JSX.Element {
		return (
			<Button disabled={disabled} unstyled onClick={onClick}>
				<Icon icon="Settings" size={21} color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.palette_P500} />
			</Button>
		);
	},
	DeleteRestore<T>({
		disabled,
		deleted,
		item,
		onDeletedChange,
		...dataAttributes
	}: {
		disabled?: boolean;
		deleted: Set<T>;
		item: T;
		onDeletedChange(newDeleted: Set<T>): void;
	} & DataAttributesProps): JSX.Element {
		return (
			<Button
				disabled={disabled}
				unstyled
				onClick={(e) => {
					e.stopPropagation();
					onDeletedChange(!deleted.has(item) ? deleted.add(item) : deleted.remove(item));
				}}
				{...dataAttributes}
			>
				{!deleted.has(item) ? (
					<Icon icon="Delete" color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.Button_bg_primary} size={21} />
				) : (
					<Icon
						icon="restore"
						color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.Button_bg_primary}
						size={21}
					/>
				)}
			</Button>
		);
	},
	Delete({
		disabled,
		onDelete,
		...dataAttributes
	}: {
		disabled?: boolean;
		onDelete(): void;
	} & DataAttributesProps): JSX.Element {
		return (
			<Button
				disabled={disabled}
				unstyled
				onClick={(e) => {
					e.stopPropagation();
					onDelete();
				}}
				{...dataAttributes}
			>
				<Icon icon="Delete" color={disabled ? themeCSSVars.palette_N100 : themeCSSVars.Button_bg_primary} size={21} />
			</Button>
		);
	},
};
