import SPHERELogo from "$root/assets/images/SPHERE_logo.svg";
import type { SyntheticEvent } from "react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import type { PageHeaderFooterProps } from "../../configuration";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { unpromisify } from "$root/utils/functions";
import type { FileResponseContentType } from "$root/api/api-gen";
import { generateImageObjectUrlFromFileResponse, getDataUri } from "../../configuration/utils";
import { CustomReportEditorContext, updateCustomReportFile, updateCustomReportText } from "$root/pages/Report/context";
import UploadButton from "$root/components/UploadButton";

const Footer = ({
	customLogo,
	customDescription,
}: PageHeaderFooterProps & {
	customLogo?: FileResponseContentType;
	customDescription?: string;
}): JSX.Element => {
	const { t } = useTranslation();

	const reportCtx = useContext(CustomReportEditorContext);
	const editable = Boolean(reportCtx);
	const [logo, setLogo] = useState<{ src?: string; file: File | null }>(() => ({
		src: customLogo?.realData ? generateImageObjectUrlFromFileResponse(customLogo) : SPHERELogo,
		file: null,
	}));
	const handleFileChange = useCallback(
		async (file: File | null) => {
			const url = await getDataUri(file);
			setLogo({ src: url, file });
			if (reportCtx) {
				updateCustomReportFile(reportCtx.update, "footerImage", file);
			}
		},
		[reportCtx],
	);
	const handleTextChange = useCallback(
		(e: SyntheticEvent) => {
			if (reportCtx && e.currentTarget.textContent !== null) {
				updateCustomReportText(reportCtx.update, "footerDescription", e.currentTarget.textContent);
			}
		},
		[reportCtx],
	);

	return (
		<div className="px-8">
			<div className="bg-white border-t-2 border-gray-300 pt-4">
				<div className="flex gap-4">
					<DropzoneArea
						classList="group dropzone"
						onChange={unpromisify(handleFileChange)}
						accept="image/*"
						disabled={!editable}
					>
						<div className="flex flex-1 items-center">
							<img src={logo.src} alt="Sphere Logo" className="h-9 w-36 object-contain" id="footerImage" />
						</div>
						{editable && (
							<div className="absolute inset-0 z-10 flex items-center justify-center">
								<UploadButton
									classList="transition-opacity opacity-0 group-hover-[.dropzone]:opacity-100 focus:opacity-100"
									label=""
									size="small"
									onChange={unpromisify(handleFileChange)}
									accept="image/*"
								/>
							</div>
						)}
					</DropzoneArea>
					<div
						className="grow text-[0.65rem]"
						contentEditable={editable}
						suppressContentEditableWarning
						onBlur={handleTextChange}
					>
						{customDescription ?? t("PRINT_MODE.FOOTER_DESCRIPTION")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
