import type { MarketViewMonitoringMetric, MonitoringMetric, MonitoringMetricCategoryEnum } from "$root/api/api-gen";
import {
	ConstraintRelation,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCustomScore } from "$root/components/CustomLabels";
import { IconWalls } from "$root/components/IconWall";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { countIf } from "$root/utils/collections";
import { UnreachableError } from "$root/utils/errors";
import { switchExpr } from "$root/utils/flow";
import type { ContextContent } from "$root/utils/react-extra";
import { withContexts } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { WidgetAlert } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import {
	ActionText,
	AsyncButton,
	AutoTooltip,
	Button,
	CollapsibleBase,
	ComputedSizeContainer,
	Controller,
	Dialog,
	DialogFooter,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	ScrollWrapper,
	Svg,
	Text,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { ForEach, Switch, generateUniqueDOMId, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, groupBy, stableEmptyArray } from "@mdotm/mdotui/utils";
import type { CSSProperties, ReactNode } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { PortfolioContext } from "../contexts/portfolio";

export type PortfolioMonitoringBlockEntry = {
	groupId: string;
	severity: PortfolioMonitoringSeverity;
	type: MonitoringMetricCategoryEnum;
	subType: string;
	value: number;
	isSoftConstraint: boolean;
	hidePercentage?: boolean;
} & (
	| {
			constraintRelation: "MIN";
			min: [/* threshold */ number, /* warning threshold */ number];
			target?: undefined;
			max?: undefined;
	  }
	| {
			constraintRelation: "MAX";
			min?: undefined;
			target?: undefined;
			max: [/* warning threshold */ number, /* threshold */ number];
	  }
	| {
			constraintRelation: "BETWEEN";
			min: [/* threshold */ number, /* warning threshold */ number];
			target?: undefined;
			max: [/* warning threshold */ number, /* threshold */ number];
	  }
	| {
			constraintRelation: "EQUAL";
			min: [/* threshold */ number, /* warning threshold */ number];
			target: number;
			max: [/* warning threshold */ number, /* threshold */ number];
	  }
);

type Releations = "ok" | "close-to" | "breached";
export function getSeverityByRelation(
	relation: Exclude<ConstraintRelation, "EXCLUDE">,
	metric: {
		value?: number;
		minThreshold?: number;
		maxThreshold?: number;
		minWarningDelta?: number;
		maxWarningDelta?: number;
		target?: boolean;
	},
): Releations {
	const { value = 0, maxThreshold = 100, minThreshold = 0, maxWarningDelta = 0, minWarningDelta = 0 } = metric;

	if (metric.target) {
		return "ok";
	}

	function exceedMaxThreshold(constraintValue: number, max: number) {
		return constraintValue > max;
	}

	function exceedMinThreshold(constraintValue: number, min: number) {
		return constraintValue < min;
	}

	function closeToMinThreshold(constraintValue: number, opts: { minWarningDelta: number; minThreshold: number }) {
		return constraintValue - opts.minWarningDelta < opts.minThreshold;
	}

	function closeToMaxThreshold(constraintValue: number, opts: { maxWarningDelta: number; maxThreshold: number }) {
		return constraintValue + opts.maxWarningDelta > opts.maxThreshold;
	}

	function equalsThreshold(constraintValue: number, threshold: number) {
		return constraintValue === threshold;
	}

	return switchExpr(relation, {
		BETWEEN: () =>
			exceedMinThreshold(value, minThreshold) || exceedMaxThreshold(value, maxThreshold)
				? "breached"
				: closeToMinThreshold(value, { minThreshold, minWarningDelta }) ||
				    closeToMaxThreshold(value, { maxWarningDelta, maxThreshold })
				  ? "close-to"
				  : "ok",
		EQUAL: () => (equalsThreshold(value, minThreshold) || equalsThreshold(value, maxThreshold) ? "ok" : "breached"),
		// exceedMinThreshold(value, minThreshold - minWarningDelta) ||
		// exceedMaxThreshold(value, minThreshold + minWarningDelta)
		// 	? "breached"
		// 	: closeToMinThreshold(value, { minThreshold, minWarningDelta }) ||
		// 	    closeToMaxThreshold(value, { maxWarningDelta: minThreshold, maxThreshold: minWarningDelta })
		// 	  ? "ok"
		// 	  : "ok",
		MAX: () =>
			exceedMaxThreshold(value, maxThreshold)
				? "breached"
				: closeToMaxThreshold(value, { maxWarningDelta, maxThreshold })
				  ? "close-to"
				  : "ok",
		MIN: () =>
			exceedMinThreshold(value, minThreshold)
				? "breached"
				: closeToMinThreshold(value, { minThreshold, minWarningDelta })
				  ? "close-to"
				  : "ok",
	});
}

function isMetricTypeAbsolute(metric: MonitoringMetricCategoryEnum) {
	const ABSOLUTE_METRICS = new Set(["NUMBER_OF_INSTRUMENTS", "AVERAGE_SCORE"]);
	return ABSOLUTE_METRICS.has(metric);
}

function PortfolioMonitoringBlock(props: ContextContent<typeof PortfolioContext>): JSX.Element {
	const monitoringApi = useApiGen(InvestmentReportsControllerApiFactory);
	const monitoringForEnhancedApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	useWidgetOptions(
		() => ({
			title: "Portfolio Monitoring",
		}),
		[],
	);

	const query = useQueryNoRefetch(
		["monitoringQuery", props.portfolio?.uuid, props.enhanced, props.portfolio?.status, props.reportExcutionCounter],
		{
			enabled: Boolean(props.portfolio?.uuid),
			queryFn: async () => {
				const portfolioUuid = props.portfolio?.uuid;
				if (!portfolioUuid) {
					throw new Error("missing portfolio uuid");
				}
				const { marketViewMonitoringMetric, monitoringMetrics } = await axiosExtract(
					!props.enhanced
						? monitoringApi.getMonitoringMetrics(portfolioUuid)
						: monitoringForEnhancedApi.getMonitoringMetrics1(portfolioUuid),
				);

				if (!monitoringMetrics) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PortfolioMonitoringBlock"),
					};
				}

				return {
					data: { marketViewMonitoringMetric, monitoringMetrics },
					widgetStatus: WidgetStatus.READY as const,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{({ monitoringMetrics, marketViewMonitoringMetric }) => (
				<PortfolioMonitoringBlockInner
					{...props}
					marketViewMonitoringMetric={marketViewMonitoringMetric}
					monitoringMetrics={monitoringMetrics ?? stableEmptyArray}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
}

const excludeSoftConstraint: MonitoringMetricCategoryEnum[] = ["NUMBER_OF_INSTRUMENTS", "WEIGHT_ON_SINGLE_INSTRUMENT"];

export function PortfolioMonitoringBlockInner({
	requestEnhance,
	monitoringMetrics,
	portfolio,
	enhanced,
	marketViewMonitoringMetric,
}: ContextContent<typeof PortfolioContext> & {
	monitoringMetrics: Array<MonitoringMetric>;
	marketViewMonitoringMetric?: MarketViewMonitoringMetric;
}): JSX.Element {
	const [filter, setFilter] = useState<"hide-ok" | "breached-first" | "risk-first" | null>("breached-first");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const user = useUserValue();
	const { t } = useTranslation();
	const { getScoreLabel } = useCustomScore();
	const investmentsEnhanceApi = useApiGen(InvestmentEnhancementControllerV4ApiFactory);
	const riskConstraintMap = useMemo(() => t(`VAR`, { returnObjects: true }), [t]);
	const { richAcl } = portfolio ?? {};
	const createMetricSubType = useCallback((entity?: string, relation?: ConstraintRelation, target?: boolean) => {
		if (!entity || !relation) {
			return "";
		}

		return entity;
	}, []);

	const confirmOptimization = useCallback(async () => {
		try {
			if (!portfolio || !portfolio.uuid) {
				console.warn("missing portfolioData, confirm ignored.");
				return;
			}
			await investmentsEnhanceApi.fixVar(portfolio.uuid);

			platformToast({
				children: t("PORTFOLIOS.OPTIMIZE_ACCEPTED"),
				severity: "success",
				icon: "Portfolio",
			});
		} catch (error) {
			platformToast({
				children: "There are some conflicting constraints, please review your portfolio configuration",
				severity: "warning",
				icon: "Portfolio",
			});
			throw error;
		} finally {
			setIsModalOpen(false);
		}
	}, [portfolio, investmentsEnhanceApi, t, setIsModalOpen]);

	const rows = useMemo<Array<PortfolioMonitoringBlockEntry> | undefined>(
		() =>
			monitoringMetrics
				?.flatMap((metric) =>
					metric.relation !== ConstraintRelation.Exclude
						? [
								{
									...metric,
									entity:
										metric.category === "AVERAGE_SCORE" && metric.entity
											? getScoreLabel(metric.entity, t("SCORE"))
											: metric.category === "RISK" && metric.entity
											  ? riskConstraintMap[metric.entity as keyof typeof riskConstraintMap]
											  : metric.entity,
								},
						  ]
						: [],
				)
				.map((metric) =>
					switchExpr<ConstraintRelation, Record<ConstraintRelation, () => PortfolioMonitoringBlockEntry>>(
						metric.relation!,
						{
							[ConstraintRelation.Exclude]: () => {
								throw new UnreachableError();
							},
							[ConstraintRelation.Between]: () => {
								const minBreach = metric.minThreshold ?? 0;
								const maxBreach = metric.maxThreshold ?? 0;
								const minCloseTo = Math.min(
									minBreach + (metric.minWarningDelta ?? 0),
									maxBreach - (metric.maxWarningDelta ?? 0),
								);
								const maxCloseTo = Math.max(
									minBreach + (metric.minWarningDelta ?? 0),
									maxBreach - (metric.maxWarningDelta ?? 0),
								);
								return {
									groupId: metric.identifier ?? generateUniqueDOMId(),
									constraintRelation: "BETWEEN",
									min: [minBreach, minCloseTo],
									max: [maxCloseTo, maxBreach],
									type: metric.category!,
									subType: createMetricSubType(metric.entity, metric.relation, metric.target),
									isSoftConstraint: Boolean(metric.target),
									severity: getSeverityByRelation("BETWEEN", metric),
									value: metric.value ?? 0,
								};
							},
							[ConstraintRelation.Equal]: () => {
								const target = metric.minThreshold ?? 0;
								const distance = metric.minWarningDelta ?? 0;
								const minBreach = Math.max(0, target - distance * 2);
								const maxBreach = Math.min(100, target + distance * 2);
								const minCloseTo = Math.max(minBreach, target - distance);
								const maxCloseTo = Math.min(maxBreach, target + distance);

								return {
									groupId: metric.identifier ?? generateUniqueDOMId(),
									constraintRelation: "EQUAL",
									type: metric.category!,
									subType: createMetricSubType(metric.entity, metric.relation, metric.target),
									isSoftConstraint: Boolean(metric.target),
									severity: getSeverityByRelation("EQUAL", metric),
									value: metric.value ?? 0,
									// EQUAL doesn't use maxThreshold
									min: [minBreach, minCloseTo],
									target,
									max: [maxCloseTo, maxBreach],
								};
							},
							[ConstraintRelation.Min]: () => ({
								groupId: metric.identifier ?? generateUniqueDOMId(),
								constraintRelation: "MIN",
								min: [metric.minThreshold ?? 0, (metric.minThreshold ?? 0) + (metric.minWarningDelta ?? 0)],
								type: metric.category!,
								subType: createMetricSubType(metric.entity, metric.relation, metric.target),
								isSoftConstraint: Boolean(metric.target),
								severity: getSeverityByRelation("MIN", metric),
								value: metric.value ?? 0,
							}),
							[ConstraintRelation.Max]: () => ({
								groupId: metric.identifier ?? generateUniqueDOMId(),
								constraintRelation: "MAX",
								max: [(metric.maxThreshold ?? 100) - (metric.maxWarningDelta ?? 0), metric.maxThreshold ?? 100],
								type: metric.category!,
								subType: createMetricSubType(metric.entity, metric.relation),
								isSoftConstraint: Boolean(metric.target),
								severity: getSeverityByRelation("MAX", metric),
								value: metric.value ?? 0,
							}),
						},
					),
				),
		[createMetricSubType, getScoreLabel, monitoringMetrics, riskConstraintMap, t],
	);

	const areSomeAlertBreached = useMemo(() => rows?.some((row) => row.severity === "breached"), [rows]);

	const filteredRows = useMemo(
		() =>
			rows
				?.filter(({ severity }) => filter !== "hide-ok" || severity !== "ok")
				.sort(
					filter === "breached-first"
						? builtInSortFnFor("severity")
						: (a, b) => {
								if (a.severity === "close-to" && b.severity !== "close-to") {
									return -1;
								}
								if (a.severity !== "close-to" && b.severity === "close-to") {
									return 1;
								}
								return 0;
						  },
				) ?? [],
		[filter, rows],
	);

	const groupedRows = Object.entries(groupBy(filteredRows, (x) => x.groupId)).map(([, group]) => group);

	const isMarketViewExpired = useMemo(() => {
		if (!marketViewMonitoringMetric || marketViewMonitoringMetric?.forecastDates?.to === undefined) {
			return false;
		}

		return new Date(marketViewMonitoringMetric.forecastDates.to).getTime() < Date.now();
	}, [marketViewMonitoringMetric]);

	const actionHeaderMemo = useMemo(
		() => (
			<DropdownMenu
				trigger={({ innerRef, open: _open, ...forward }) => (
					<button ref={innerRef} type="button" {...forward}>
						<Icon icon="Filter" size={20} color={themeCSSVars.palette_P500} />
					</button>
				)}
				actions={[
					{
						children: ({ onClose }) => (
							<DropdownMenuActionButton
								disabled={!rows || !rows.some(({ severity }) => severity === "ok")}
								onClick={() => {
									setFilter((f) => (f === "hide-ok" ? null : "hide-ok"));
									onClose();
								}}
								icon={filter === "hide-ok" ? "Outline" : undefined}
							>
								Hide OK
							</DropdownMenuActionButton>
						),
					},
					{
						children: ({ onClose }) => (
							<DropdownMenuActionButton
								onClick={() => {
									setFilter((f) => (f === "breached-first" ? null : "breached-first"));
									onClose();
								}}
								icon={filter === "breached-first" ? "Outline" : undefined}
							>
								Show breached first
							</DropdownMenuActionButton>
						),
					},
					{
						children: ({ onClose }) => (
							<DropdownMenuActionButton
								onClick={() => {
									setFilter((f) => (f === "risk-first" ? null : "risk-first"));
									onClose();
								}}
								icon={filter === "risk-first" ? "Outline" : undefined}
							>
								Show risk first
							</DropdownMenuActionButton>
						),
					},
				]}
			/>
		),
		[filter, rows],
	);

	const alertsMemo = useMemo(() => {
		if (enhanced && (filteredRows.filter((x) => x.severity === "breached").length ?? 0) > 0) {
			return [
				{
					variant: "warning",
					content: (
						<p>You can modify the proposal or you can accept the closest optimal solution generated by Sphere</p>
					),
					title: "Your portfolio has warnings",
				},
			];
		}

		if (!rows || portfolio?.status === "CALCULATING") {
			return undefined;
		}

		return new Array(
			countIf(groupedRows, (rows) => rows?.some((x) => x.severity !== "ok") ?? false) + (isMarketViewExpired ? 1 : 0),
		).fill({
			variant: "warning",
		} satisfies WidgetAlert);
	}, [enhanced, filteredRows, groupedRows, isMarketViewExpired, portfolio?.status, rows]);

	const alertsRef = useRef(alertsMemo);
	useWidgetOptions(
		() => ({
			alertsActive: true,
			alerts: alertsRef.current,
			hideAlertsNumber: enhanced,
			actionHeader: actionHeaderMemo,
			title: "Portfolio Monitoring",
		}),
		[actionHeaderMemo, enhanced, alertsRef],
	);

	const canCreateProposal = aclByArea.portfolio.canCreateProposal(user.id, richAcl?.acl ?? []);

	const { formatDate } = useLocaleFormatters();

	if ((!rows || rows.length === 0) && portfolio?.status === "PROPOSAL_READY" && !isMarketViewExpired) {
		return <IconWalls.PortfolioMonitoringUnconsistencyV2 opaque />;
	}

	return (
		<>
			{(!rows || rows.length === 0) && !isMarketViewExpired ? (
				<IconWalls.PortfolioMonitoringEmptyDataV2 opaque onCreate={() => requestEnhance?.()} />
			) : filteredRows.length === 0 && !isMarketViewExpired ? (
				<IconWalls.PortfolioMonitoringHiddenV2 opaque />
			) : (
				<div className="h-full flex flex-col min-h-0 w-full">
					<div className="h-full min-h-0 flex flex-col flex-1 mb-2">
						<ScrollWrapper>
							{isMarketViewExpired && marketViewMonitoringMetric && (
								<div
									className={toClassName({
										[`flex flex-row gap-2 py-2 items-center justify-between border-b-[${themeCSSVars.palette_N100}]`]:
											true,
									})}
									style={{
										borderBottomWidth: 1,
									}}
								>
									<div>
										<div className="flex items-center gap-2">
											<div>
												<Text type="Body/M/Bold">Market view</Text>
												<Text type="Body/M/Book">
													{` - Expired on ${formatDate(marketViewMonitoringMetric.forecastDates?.to)}`}
												</Text>
											</div>
											{iconBySeverity["breached"]}
										</div>
										<div>
											<ActionText
												type="Body/M/Bold"
												href={
													marketViewMonitoringMetric.scenarioIdentifier === "CUSTOM_PORTFOLIO_MARKET_VIEW"
														? `${typedUrlForRoute("PortfolioDetails", {
																// redirect to portfolio settings
																portfolioUid: portfolio?.uuid ?? "",
														  })}?tab=2#marketView`
														: typedUrlForRoute("MarketViewWorkSpace", {
																uuid: marketViewMonitoringMetric.scenarioIdentifier!,
																isCustom: String(marketViewMonitoringMetric.custom!),
																action: "view",
																type: String(marketViewMonitoringMetric.marketViewType),
														  })
												}
											>
												{!marketViewMonitoringMetric.scenarioName
													? `Market view ${formatDate(marketViewMonitoringMetric.forecastDates?.from)}`
													: marketViewMonitoringMetric.scenarioName}
											</ActionText>
										</div>
									</div>
								</div>
							)}
							<ForEach collection={groupedRows}>
								{({ item: group }) => {
									if (!group?.length) {
										return null;
									} else {
										const renderEntry = (
											entry: PortfolioMonitoringBlockEntry,
											opts?: {
												severity?: PortfolioMonitoringSeverity;
												bar?: boolean;
												innerRef?: (el: HTMLDivElement | null) => void;
												showType?: boolean;
												style?: CSSProperties;
												textClassList?: string;
											},
										) => (
											<div
												ref={opts?.innerRef}
												className={toClassName({
													[`flex flex-row gap-2 py-2 items-center border-b-[${themeCSSVars.palette_N100}]`]: true,
												})}
												style={{
													borderBottomWidth: 1,
													...opts?.style,
												}}
											>
												<div className="grow min-w-0 overflow-hidden">
													{(opts?.showType ?? true) && (
														<div className="flex items-center gap-2">
															<div>
																<Text type="Body/M/Bold">{t(`MONITORING_METRICS.${entry.type}`)} </Text>
																{(!excludeSoftConstraint.includes(entry.type) || entry.isSoftConstraint) && (
																	<Text type="Body/M/Bold">
																		(
																		{excludeSoftConstraint.includes(entry.type)
																			? ""
																			: t(`RELATION.${entry.constraintRelation}`)}
																		{!excludeSoftConstraint.includes(entry.type) && entry.isSoftConstraint ? " - " : ""}
																		{entry.isSoftConstraint ? "Soft" : ""})
																	</Text>
																)}
															</div>
															{opts?.severity && iconBySeverity[opts.severity]}
														</div>
													)}
													<Text
														type="Body/M/Book"
														classList={{
															"truncate w-full block": true,
															[opts?.textClassList ?? ""]: opts?.textClassList !== undefined,
														}}
														title={entry.subType}
													>
														{entry.subType}
													</Text>
												</div>
												{(opts?.bar ?? true) && (
													<div className="shrink">
														<PortfolioMonitoringIndicator
															{...entry}
															hidePercentage={isMetricTypeAbsolute(entry.type)}
														/>
													</div>
												)}
											</div>
										);
										if (group.length === 1) {
											return renderEntry(group[0], {
												style: { minHeight: 41 },
											});
										}
										if (group[0].type === "LOCK_INSTRUMENT") {
											const transitionDuration = 400;

											return (
												<Controller value={false}>
													{({ value: expand, onChange: setExpand }) => (
														<>
															<button
																type="button"
																className="flex w-full text-left items-center py-2 border-b transition-[border-color]"
																onClick={() => setExpand(!expand)}
																style={{
																	minHeight: 41,
																	borderBottomColor: expand ? "transparent" : themeCSSVars.palette_N100,
																	transitionDuration: `${transitionDuration}ms`,
																}}
															>
																<div className="grow min-w-0">
																	<div className="flex items-center gap-2">
																		<Text type="Body/M/Bold">Lock instrument</Text>
																		{iconBySeverity[groupSeverity(group)]}
																	</div>
																	<Text
																		type="Body/M/Book"
																		classList="truncate w-full block"
																		title={`Constraint applied to ${group.length} instruments`}
																	>
																		{`Constraint applied to ${group.length} instruments`}
																	</Text>
																</div>

																<span
																	style={{
																		transitionDuration: `${transitionDuration}ms`,
																	}}
																	className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex shrink"
																	aria-expanded={expand}
																>
																	<Icon icon="Down" size={14} />
																</span>
															</button>
															<CollapsibleBase expand={expand} transitionDuration={transitionDuration}>
																<div className="py-px pl-2">
																	<ForEach collection={group}>
																		{({ item: entry }) =>
																			renderEntry(entry, {
																				showType: false,
																			})
																		}
																	</ForEach>
																</div>
															</CollapsibleBase>
														</>
													)}
												</Controller>
											);
										}
										if (group[0].type === "FOR_EACH") {
											return (
												<AutoTooltip
													position="right"
													overrideColor={colorByPortfolioMonitoringSeverity[groupSeverity(group)]}
													trigger={({ innerRef }) =>
														renderEntry(
															{
																...group[0],
																subType: `Constraint applied to ${group.length} instruments`,
															},
															{
																innerRef,
																severity: groupSeverity(group),
																bar: false,
																style: { minHeight: 41 },
															},
														)
													}
												>
													<TooltipContent>
														<ForEach collection={group}>
															{({ item: entry, index }) =>
																renderEntry(entry, {
																	style: {
																		paddingTop: index === 0 ? 0 : undefined,
																		paddingBottom: index === group.length - 1 ? 0 : undefined,
																		borderBottomColor: index === group.length - 1 ? "transparent" : undefined,
																	},
																	showType: false,
																})
															}
														</ForEach>
													</TooltipContent>
												</AutoTooltip>
											);
										}
										if (group[0].type === "INSTRUMENT" || group[0].type === "ASSET_ALLOCATION") {
											return (
												<AutoTooltip
													position="left"
													overrideColor={colorByPortfolioMonitoringSeverity[groupSeverity(group)]}
													trigger={({ innerRef }) =>
														renderEntry(
															{
																...group[0],
																subType: `Constraint applied to ${group.length} instruments`,
															},
															{
																innerRef,
																severity: groupSeverity(group),
																style: { minHeight: 41 },
															},
														)
													}
												>
													<TooltipContent>
														<ForEach collection={group}>
															{({ item: entry, index }) =>
																renderEntry(entry, {
																	style: {
																		paddingTop: index === 0 ? 0 : undefined,
																		paddingBottom: index === group.length - 1 ? 0 : undefined,
																		borderBottomColor: index === group.length - 1 ? "transparent" : undefined,
																	},
																	textClassList: "max-w-[300px]",
																	showType: false,
																	bar: false,
																})
															}
														</ForEach>
													</TooltipContent>
												</AutoTooltip>
											);
										}
										console.warn("unhandled type", group[0].type);
										return null;
									}
								}}
							</ForEach>
						</ScrollWrapper>
					</div>
					<div className="flex justify-between">
						<Legend />
						{areSomeAlertBreached &&
							!enhanced &&
							(portfolio?.status === "READY" || portfolio?.status === "ACCEPTED") &&
							canCreateProposal && (
								<Button size="x-small" palette="secondary" onClick={() => setIsModalOpen(true)}>
									<Icon icon="Icon-full-small" classList="mr-1" />
									{t("FIX_ISSUES")}
								</Button>
							)}
					</div>
				</div>
			)}

			<Dialog
				classList="flex-1"
				show={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				header={t("PORTFOLIOS.PORTFOLIO_OPTIMIZATION")}
				footer={({ loading }) => (
					<DialogFooter
						primaryAction={
							<AsyncButton palette="primary" size="small" onClickAsync={confirmOptimization}>
								{t("PROCEED")}
							</AsyncButton>
						}
						neutralAction={
							<Button palette="tertiary" size="small" disabled={loading} onClick={() => setIsModalOpen(false)}>
								{t("LEAVE")}
							</Button>
						}
					/>
				)}
			>
				{t("PORTFOLIOS.ALICE_SUGGEST_ENHANCHED")}
			</Dialog>
		</>
	);
}

export default withContexts([PortfolioContext])(PortfolioMonitoringBlock);

function LegendIcon({ fill }: { fill: string }): JSX.Element {
	return (
		<Svg viewBox={{ width: 12, height: 12 }}>
			<rect fill={fill} x="0" y="0" height="12" width="12" rx="2" ry="2" />
		</Svg>
	);
}

function Legend(): JSX.Element {
	return (
		<div className="flex gap-2 items-center">
			<div className="flex items-center">
				<LegendIcon fill={colorByPortfolioMonitoringSeverity.ok} />
				&nbsp;
				<Text type="Body/S/Book" color={themeCSSVars.palette_N500}>
					OK
				</Text>
			</div>
			<div className="flex items-center">
				<LegendIcon fill={colorByPortfolioMonitoringSeverity["close-to"]} />
				&nbsp;
				<Text type="Body/S/Book" color={themeCSSVars.palette_N500}>
					Close-To
				</Text>
			</div>
			<div className="flex items-center">
				<LegendIcon fill={colorByPortfolioMonitoringSeverity.breached} />
				&nbsp;
				<Text type="Body/S/Book" color={themeCSSVars.palette_N500}>
					Breached
				</Text>
			</div>
			<div className="flex items-center">
				<Svg viewBox={{ width: 2, height: 12 }}>
					<rect fill={themeCSSVars.palette_N300} x="0" y="0" height="12" width="2" rx="1" ry="1" />
				</Svg>
				&nbsp;
				<Text type="Body/S/Book" color={themeCSSVars.palette_N500}>
					Constraint
				</Text>
			</div>
		</div>
	);
}

export const portfolioMonitoringSeverity = ["ok", "close-to", "breached"] as const;
export type PortfolioMonitoringSeverity = (typeof portfolioMonitoringSeverity)[number];
export const colorByPortfolioMonitoringSeverity: Record<PortfolioMonitoringSeverity, string> = {
	ok: themeCSSVars.palette_S300,
	"close-to": themeCSSVars.palette_W300,
	breached: themeCSSVars.palette_D300,
};

export function PortfolioMonitoringIndicator(props: PortfolioMonitoringBlockEntry): JSX.Element {
	const height = 36;
	const barY = 14;

	const segmentsWidthPercentages = match(props)
		.with({ constraintRelation: "BETWEEN" }, () => [0, 30, 35, 65, 70, 100])
		.with({ constraintRelation: "MIN" }, () => [0, 30, 35, 100])
		.with({ constraintRelation: "MAX" }, () => [0, 65, 70, 100])
		// Very close to better represent the equal constraint
		.with({ constraintRelation: "EQUAL" }, () => [0, 49.98, 49.99, 50, 50.01, 50.02, 100])
		.exhaustive();
	const segmentsValues = match(props)
		.with({ constraintRelation: "BETWEEN" }, (p) => [0, p.min[0], p.min[1], p.max[0], p.max[1], 100])
		.with({ constraintRelation: "MIN" }, (p) => [0, p.min[0], p.min[1], 100])
		.with({ constraintRelation: "MAX" }, (p) => [0, p.max[0], p.max[1], 100])
		.with({ constraintRelation: "EQUAL" }, (p) => [0, p.min[0], p.min[1], p.target, p.max[0], p.max[1], 100])
		.exhaustive();

	const startSegmentIndex = segmentsValues.findIndex((v) => v > props.value) - 1;
	const x =
		// center
		((props.value - segmentsValues[startSegmentIndex]) /
			// normalize
			(segmentsValues[startSegmentIndex + 1] - segmentsValues[startSegmentIndex])) *
			// scale up
			(segmentsWidthPercentages[startSegmentIndex + 1] - segmentsWidthPercentages[startSegmentIndex]) +
		// translate
		segmentsWidthPercentages[startSegmentIndex];

	const { formatNumber } = useLocaleFormatters();

	const valueToText = (index: number) =>
		segmentsValues.at(index) && (
			<text
				textAnchor="middle"
				x={`${segmentsWidthPercentages.at(index)}%`}
				y={barY + 20}
				fontSize={10}
				fontFamily="Gotham"
				fill="#697796"
			>
				{formatNumber(segmentsValues.at(index))}
				{props.hidePercentage ? "" : "%"}
			</text>
		);

	return (
		<div className="flex items-center">
			<div className="font-bold shrink pr-2" style={{ color: colorByPortfolioMonitoringSeverity[props.severity] }}>
				{formatNumber(props.value)}
			</div>
			<ComputedSizeContainer classList="grow min-w-[160px]">
				{({ width }) => (
					<Svg viewBox={{ width, height }}>
						<line x1={0} x2={width} y1={barY} y2={barY} strokeWidth={4} strokeLinecap="butt" stroke="#E1E4EA" />
						<Switch
							case={props.constraintRelation}
							match={{
								BETWEEN: () => (
									<line
										x1={`${segmentsWidthPercentages.at(1)}%`}
										x2={`${segmentsWidthPercentages.at(-2)}%`}
										y1={barY}
										y2={barY}
										strokeWidth={4}
										strokeLinecap="butt"
										stroke="#C3C9D5"
									/>
								),
								MIN: () => (
									<line
										x1={`${segmentsWidthPercentages.at(1)}%`}
										x2={`${segmentsWidthPercentages.at(-1)}%`}
										y1={barY}
										y2={barY}
										strokeWidth={4}
										strokeLinecap="butt"
										stroke="#C3C9D5"
									/>
								),
								MAX: () => (
									<line
										x1={`${segmentsWidthPercentages.at(0)}%`}
										x2={`${segmentsWidthPercentages.at(-2)}%`}
										y1={barY}
										y2={barY}
										strokeWidth={4}
										strokeLinecap="butt"
										stroke="#C3C9D5"
									/>
								),
								EQUAL: () => <></>,
							}}
						/>
						{(props.constraintRelation === "BETWEEN" || props.constraintRelation === "MIN") && (
							<>
								<line
									stroke="#A5AEC0"
									strokeWidth={2}
									strokeLinecap="round"
									x1={`${segmentsWidthPercentages.at(1)}%`}
									y1={barY + 8}
									x2={`${segmentsWidthPercentages.at(1)}%`}
									y2={barY - 8}
								/>
								{valueToText(1)}
							</>
						)}
						{(props.constraintRelation === "BETWEEN" || props.constraintRelation === "MAX") && (
							<>
								<line
									stroke="#A5AEC0"
									strokeWidth={2}
									strokeLinecap="round"
									x1={`${segmentsWidthPercentages.at(-2)}%`}
									y1={barY + 8}
									x2={`${segmentsWidthPercentages.at(-2)}%`}
									y2={barY - 8}
								/>
								{valueToText(-2)}
							</>
						)}
						{props.constraintRelation === "EQUAL" && (
							<>
								<line
									stroke="#A5AEC0"
									strokeWidth={2}
									strokeLinecap="round"
									x1={`${segmentsWidthPercentages.at(3)}%`}
									y1={barY + 8}
									x2={`${segmentsWidthPercentages.at(3)}%`}
									y2={barY - 8}
								/>
								{valueToText(3)}
							</>
						)}
						<line
							x1={0}
							x2={`${x}%`}
							y1={barY}
							y2={barY}
							strokeWidth={4}
							strokeLinecap="butt"
							stroke={colorByPortfolioMonitoringSeverity[props.severity]}
						/>
					</Svg>
				)}
			</ComputedSizeContainer>
		</div>
	);
}
const iconBySeverity: Record<PortfolioMonitoringSeverity, ReactNode> = {
	ok: null,
	"close-to": <Icon color={colorByPortfolioMonitoringSeverity["close-to"]} icon="Icon-full-alert" />,
	breached: <Icon color={colorByPortfolioMonitoringSeverity.breached} icon="Icon-full-alert" />,
};

function groupSeverity(group: PortfolioMonitoringBlockEntry[]): PortfolioMonitoringSeverity {
	return group.some((x) => x.severity === "breached")
		? "breached"
		: group.some((x) => x.severity === "close-to")
		  ? "close-to"
		  : "ok";
}
