import type { FileResponse } from "$root/api/api-gen";
import { PoweredBySphere } from "$root/components/EvolvedPrint/components/pdf/Icons/PoweredBySphere";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";

import Sphere_background from "$root/assets/images/bg_login.jpg";
import Sphere_logo from "$root/assets/images/logo-MDOTM-white.png";
import { generateImageObjectUrlFromFileResponse } from "$root/components/EvolvedPrint/configuration/utils";

export function Cover(
	props: PrintableProps<
		{
			title: string;
			subtitle: string;
			footerDescription?: string;
			backgroundImage?: FileResponse;
			clientLogo?: FileResponse;
		},
		null
	>,
): JSX.Element {
	return (
		<div className="h-[297mm] min-h-56 relative @container">
			<img
				// TODO: check types
				src={
					(props.backgroundImage && generateImageObjectUrlFromFileResponse(props.backgroundImage)) || Sphere_background
				}
				alt="sphere_bg"
				className="object-cover h-[297mm] w-[210mm] md:w-full"
				style={{ objectPosition: "28% 0" }}
				loading="lazy"
			/>
			{props.backgroundImage?.realData && (
				<div className="absolute top-3 inset-x-0">
					<div className="flex -ml-3">
						<div className="mx-auto">
							<PoweredBySphere />
						</div>
					</div>
				</div>
			)}

			<div className="absolute top-[20%] inset-x-0">
				<div className="flex h-20">
					<img
						src={(props.clientLogo && generateImageObjectUrlFromFileResponse(props.clientLogo)) || Sphere_logo}
						alt="sphere_logo"
						className="object-cover mx-auto"
					/>
				</div>
			</div>

			<div className="absolute bottom-[25%] inset-x-0">
				<div className="w-full text-center ml-auto py-4 px-6 bg-[#2F3541]/75">
					<p className="text-2xl text-white font-semibold">{props.title}</p>
					<p className="text-xl text-white">{props.subtitle}</p>
				</div>
			</div>
			<div className="absolute bottom-0 inset-x-0">
				<p className="text-white w-fit mx-auto mb-4 text-[0.425rem] text-center xl:text-xs min-w-[100px]">
					{props.footerDescription ??
						"MDOTM Ltd (FRN: 824056) is an appointed representative of Thornbridge Investment Management LLP (FRN: 713859) which is authorised and regulated by the Financial Conduct Authority."}
				</p>
			</div>
		</div>
	);
}
