import { useUserValue } from "$root/functional-areas/user";
import type { AxiosInstance } from "axios";
import { useMemo } from "react";
import type { ControllerFactory } from "./factory";
import { makeAuthController } from "./factory";

/**
 * Example:
 * ```tsx
 * const portfolioApi = useApiGen(InvestmentsControllerApiFactory);
 * ```
 */
export function useApiGen<T>(controllerFactory: ControllerFactory<T>, axiosInstance?: AxiosInstance): T {
	const user = useUserValue();
	const api = useMemo(
		() => makeAuthController({ controllerFactory, axiosInstance, token: user.token }),
		[controllerFactory, user.token, axiosInstance],
	);
	return api;
}
