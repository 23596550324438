export const ScatterPlotLimits = {
	EQUITYGEO: {
		RETURNS: [-3, -1, 1, 3],
		VOLATILITY: [10, 15, 20, 25],
	},
	EQUITYSECTORS: {
		RETURNS: [-3, -1, 1, 3],
		VOLATILITY: [10, 15, 20, 25],
	},
	COMMODITIES: {
		RETURNS: [-3, -1, 1, 3],
		VOLATILITY: [10, 15, 20, 25],
	},
	FIXED_INCOME: {
		RETURNS: [-1.5, -0.5, 0.5, 1.5],
		VOLATILITY: [2.5, 5, 7.5, 10],
	},
	FIXEDINCOME: {
		RETURNS: [-1.5, -0.5, 0.5, 1.5],
		VOLATILITY: [2.5, 5, 7.5, 10],
	},
};
