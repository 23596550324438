import { CommentariesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import InfiniteLoader from "$root/components/InfiniteLoader";
import { useEventBus } from "$root/event-bus";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import type { ContextContent } from "$root/utils/react-extra";
import { withContexts } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { CommentaryContext } from "$root/widgets-architecture/contexts/commentary";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { noop } from "@mdotm/mdotui/utils";
import styled from "styled-components";
import { ScrollWrapper } from "@mdotm/mdotui/components";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { applySentimentBadges } from "$root/functional-areas/commentary/sentiment-badges";

const BasePositioning = styled("div")({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	height: "100%",

	div: {
		maxHeight: "100%",
	},

	"div.ComDiv": {
		paddingRight: ".7rem",
		width: "100%",
	},
});

function CommentaryMDBlock(
	props: ContextContent<typeof CommentaryContext> & ContextContent<typeof UniverseFiltersContext>,
) {
	useWidgetOptions(
		() => ({
			actionHeader: <></>,
			title: (() => {
				if (props.fixedCommentary || (!props.firstFilter && !props.secondFilter && !props.thirdFilter)) {
					return "Commentary - Generative AI";
				}
				return "Commentary";
			})(),
		}),
		[props.fixedCommentary, props.firstFilter, props.secondFilter, props.thirdFilter],
	);

	return <CommentaryMDBlockContent {...props} />;
}

export function CommentaryMDBlockContent(
	props: ContextContent<typeof CommentaryContext> & ContextContent<typeof UniverseFiltersContext>,
): JSX.Element {
	const commentaryApi = useApiGen(CommentariesControllerApiFactory);
	const { assetClass, geography, sector } = useOutlookComposition(
		props.firstFilter,
		props.secondFilter,
		props.thirdFilter,
	);

	const { data, isError, isLoading, refetch } = useQueryNoRefetch(
		["commentary", props.firstFilter, props.secondFilter, props.thirdFilter, props.fixedCommentary],
		{
			queryFn: async () => {
				if (!props.firstFilter && !props.secondFilter && !props.thirdFilter) {
					const { data: dashboardCommentary } = await commentaryApi.getCommentary3(); // dashboard commentaries
					return dashboardCommentary.text && applySentimentBadges(dashboardCommentary.text);
				}

				const REGEX = { $regex: "^DASHBOARD$" };
				if (!props.fixedCommentary) {
					if (props.firstFilter && props.firstFilter?.length > 0) {
						REGEX.$regex = `^${props.firstFilter}$`;
						if (
							props.secondFilter &&
							props.secondFilter?.length > 0 &&
							props.firstFilter === "EQ" &&
							props.secondFilter !== "MARKETS"
						) {
							REGEX.$regex = `^${props.firstFilter}_${props.secondFilter}${
								props.thirdFilter && props.thirdFilter !== "ALL_SECTORS" ? `_${props.thirdFilter}` : ""
							}$`;
						}
					}
				}

				const commData = props.fixedCommentary
					? await commentaryApi.getCommentary2(assetClass, geography, sector).then((response) => ({
							...response,
							data: {
								...response.data,
								text: response.data.text && applySentimentBadges(response.data.text),
							},
					  })) // outlook focus commentaries
					: await commentaryApi.getCommentary1(JSON.stringify(REGEX)).then((response) => ({
							...response,
							data: {
								...response.data,
								text: response.data.text && applySentimentBadges(response.data.text),
							},
					  })); // outlook commentaries
				return commData.data.text;
			},
		},
	);

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	return (
		<>
			<BasePositioning>
				{isError ? (
					"Generative AI commentary is not available at the moment. It will be generated soon!"
				) : isLoading || !data ? (
					<InfiniteLoader />
				) : (
					<div className="relative w-full">
						<ScrollWrapper>
							<MarkdownRenderer useRehype>{data}</MarkdownRenderer>
						</ScrollWrapper>
					</div>
				)}
			</BasePositioning>
		</>
	);
}

export default withContexts([CommentaryContext, UniverseFiltersContext])(CommentaryMDBlock);

/* function LabelWithSentimentIcon({
	children,
	sentiment,
	indicator,
}: {
	children: ReactNode;
	sentiment: SentimentType;
	indicator: IndicatorType;
}): JSX.Element {
	return (
		<div className="inline-flex items-center">
			<SentimentSymbol classList="scale-125 mr-1" sentiment={sentiment} indicator={indicator} />
			<Text as="span" type="Body/M/Bold">
				{children}
			</Text>
		</div>
	);
}
 */
