import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
//import reportWebVitals from './reportWebVitals';

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import ScrollToTop from "$root/components/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";

import { initI18n } from "$root/localization/i18nConfig";
import initIubenda from "$root/third-party-integrations/initIubenda";
import initMixPanel from "$root/third-party-integrations/initMixPanel";
import initHotjar from "$root/third-party-integrations/initHotjar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import env from "$root/env";
import { initMDOTUI } from "@mdotm/mdotui/components";
import { createRoot } from "react-dom/client";

import "$root/style.scss";
import "@mdotm/mdotui/font.css";
import "@mdotm/mdotui/style.css";
import "$root/styles/customizations.scss";

/* (async () => { */ (() => {
	initMDOTUI();

	initI18n().catch(console.warn);

	// TODO: remove once we have a proper consent solution.
	const bypassIubenda = true;

	// TODO find a better solution
	if (env.appEnv === "production") {
		console.log = () => undefined;
		console.error = () => undefined;
		console.warn = () => undefined;
	}

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	const renderRoot = createRoot(document.getElementById("root")!);

	renderRoot.render(
		<QueryClientProvider client={queryClient}>
			<Router
				getUserConfirmation={() => {
					/* Empty callback to block the default browser prompt */
				}}
			>
				<ScrollToTop />
				<App />
			</Router>
		</QueryClientProvider>,
	);

	const hotjarKey = env.appEnv === "production" ? 2966711 : 2966749;
	// TODO: remove once the test of the code below is complete
	if (env.appEnv === "production") {
		// hotjar.initialize(hotjarKey, 6);
		const iubendaPurposeId = 4;
		let previousPreference: boolean | null = null;
		initIubenda("en", (preferences) => {
			if (previousPreference === null) {
				previousPreference = preferences.purposes[4] ?? false;
				return;
			}
			// Page reload to stop hotjar/mixpanel once they have been initialized
			if (!preferences.purposes[iubendaPurposeId] && previousPreference) {
				window.location.reload();
			}

			previousPreference = preferences.purposes[iubendaPurposeId] ?? false;
		}); // Iubenda Script
		initHotjar(hotjarKey, 6, String(iubendaPurposeId), bypassIubenda); // Hotjar test ( with Iubenda activate params Script - level 4)
		initMixPanel("43b4dc19e0992a635165e64635f868ca", true, String(iubendaPurposeId), bypassIubenda); // MixPanel Script( with Iubenda activate params Script - level 4)
	}

	// TODO: remove if-block once the test is complete
	if (env.appEnv !== "production") {
		const iubendaPurposeId = 4;
		let previousPreference: boolean | null = null;
		initIubenda("en", (preferences) => {
			if (previousPreference === null) {
				previousPreference = preferences.purposes[4] ?? false;
				return;
			}
			// Page reload to stop hotjar/mixpanel once they have been initialized
			if (!preferences.purposes[iubendaPurposeId] && previousPreference) {
				window.location.reload();
			}

			previousPreference = preferences.purposes[iubendaPurposeId] ?? false;
		}); // Iubenda Script
		initHotjar(hotjarKey, 6, String(iubendaPurposeId), bypassIubenda); // Hotjar test ( with Iubenda activate params Script - level 4)
		// FIXME: Disable Mixpanel for Billing Problems
		// initMixPanel("43b4dc19e0992a635165e64635f868ca", true, String(iubendaPurposeId), bypassIubenda); // MixPanel Script( with Iubenda activate params Script - level 4)
	}

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	//reportWebVitals();
})(); /* .catch(console.warn); */
