import { CircularProgressBar } from "@mdotm/mdotui/components";
import { toClassName, type ClassList, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import type { CSSProperties } from "react";

export type InfiniteLoaderProps = {
	classList?: ClassList;
	style?: CSSProperties;
};

const InfiniteLoader = ({ style, classList }: InfiniteLoaderProps): JSX.Element => {
	return (
		<div
			className={toClassName({ "h-full flex items-center justify-center": true, ...toClassListRecord(classList) })}
			style={style}
		>
			<CircularProgressBar value="indeterminate" style={{ width: 42 }} />
		</div>
	);
};

export default InfiniteLoader;
