import type { RichAcl } from "$root/api/api-gen";
import type { TableV2 } from "@mdotm/mdotui/components";
import { overrideClassList } from "@mdotm/mdotui/react-extensions";
import type { UserListEntryProps } from "./UsersList";
import { UsersList } from "./UsersList";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";

export function sharingWithColumn<TRow extends { richAcl?: RichAcl }>(params: {
	users: MaybeFn<UserListEntryProps[], [TRow]>;
}): TableV2.TableColumn<TRow, "sharingViews"> {
	return {
		content: (row, props) => {
			return (
				<UsersList
					{...props}
					classList={overrideClassList("px-2", props.classList)}
					users={maybeCall(params.users, row)}
					visibleSlots={3}
					size="small"
				/>
			);
		},
		sortFn: (a, b) => (a.richAcl?.acl?.length ?? 0) - (b.richAcl?.acl?.length ?? 0),
		name: "sharingViews",
		header: "Sharing with",
		width: 192,
	};
}
