import type { UploadErrorCodes } from "$root/api/api-gen";

interface IUploadError {
	translationKey: string;
	type: "error" | "warning";
	referred: ("portfolio" | "universe" | "portfolioReference")[];
	isAliasReferred: boolean;
}

type IUploadErrorMap = {
	[errorKey in UploadErrorCodes]: IUploadError;
};

export const uploadErrorMap: IUploadErrorMap = {
	// User uploaded an invalid/parsable excel
	INVALID_WORKBOOK: {
		translationKey: "INVALID_WORKBOOK",
		type: "error",
		referred: [],
		isAliasReferred: false,
	},
	// The alias is not an isin or cusip
	INVALID_ALIAS: {
		translationKey: "INVALID_ALIAS",
		type: "error",
		referred: [],
		isAliasReferred: true,
	},
	// The weight entered is not in a valid format. Not present in the verify universe
	// INVALID_WEIGHT: { /* deprecated */
	// 	translationKey: "INVALID_WEIGHT",
	// 	type: "error",
	// 	referred: [],
	// 	isAliasReferred: true,
	// },
	// The sum of the weights is greater than 100. The sum of the weights is included in the payload. Not present in the verify universe
	WEIGHT_OVER_100: {
		/* deprecated */ translationKey: "WEIGHT_OVER_100",
		type: "error",
		referred: [],
		isAliasReferred: false,
	},
	// The sum of the weights is less than 100. The sum of the weights is included in the payload. Not present in the verify universe
	WEIGHT_UNDER_100: {
		/* deprecated */ translationKey: "WEIGHT_UNDER_100",
		type: "warning",
		referred: [],
		isAliasReferred: false,
	},
	// Include in the weight field the total number of tools loaded by the user.
	MORE_THAN_MAX_INSTRUMENTS: {
		translationKey: "MORE_THAN_MAX_INSTRUMENTS",
		type: "error",
		referred: [],
		isAliasReferred: false,
	},
	// In this phase, it indicates that the user has loaded two rows with the same alias (although perhaps with different weights). An entry is generated for each duplicate row.
	DUPLICATE_ROW: {
		translationKey: "DUPLICATE_ROW",
		type: "warning",
		referred: [],
		isAliasReferred: true,
	},
	// Can only happen in single stock portfolios. It means that the tool cannot be managed by sphere because it is not present on the factset or because the factset data is insufficient. The notified ticker does not appear inside the uploadComposition
	MISSING_DATA: {
		/* deprecated */ translationKey: "MISSING_DATA",
		type: "warning",
		referred: [],
		isAliasReferred: true,
	},
	// It can only happen in single stocks portfolios, in case the user enters an instrument that can be managed by sphere but which is not a stock
	// INCOMPATIBLE_WITH_TYPE: { /* deprecated */
	// 	translationKey: "INCOMPATIBLE_WITH_TYPE",
	// 	type: "warning",
	// 	referred: [],
	// 	isAliasReferred: true,
	// },
	// If all the tools are removed due to one of the two previous errors, this case occurs again.
	EMPTY_SS_PORTFOLIO: {
		/* deprecated */ translationKey: "EMPTY_SS_PORTFOLIO",
		type: "error",
		referred: [],
		isAliasReferred: true,
	},
	// Please note that instruments weighing less than 0.01 have been assigned to cash.
	// WEIGHT_TOO_SMALL: { /* deprecated */
	// 	translationKey: "WEIGHT_TOO_SMALL",
	// 	type: "warning",
	// 	referred: [],
	// 	isAliasReferred: true,
	// },
	// Please note that instruments weighing less than 0.01 have been assigned to cash.
	DELISTED: {
		translationKey: "DELISTED",
		type: "error",
		referred: [],
		isAliasReferred: true,
	},
};
