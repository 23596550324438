import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import css from "./MarkdownRenderer.module.scss";
import { memo } from "react";
import type { SpecialComponents } from "react-markdown/lib/ast-to-react";
import type { NormalComponents } from "react-markdown/lib/complex-types";
import { SentimentBadge } from "$root/functional-areas/market-view/analysis/SentimentBadge";

export type MarkdownRendererProps = {
	children: string;
	componentOverrides?: Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>;
	useRehype?: boolean;
};

export const MarkdownRenderer = memo(_MarkdownRenderer) as typeof _MarkdownRenderer;

function _MarkdownRenderer({ children, componentOverrides, useRehype = false }: MarkdownRendererProps): JSX.Element {
	return (
		<ReactMarkdown
			className={css.markdown}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={useRehype ? [rehypeRaw as any] : undefined} // TODO: updating this in a few months should fix the type issue
			components={{
				h1: ({ node: _node, ...props }) => (
					<h1 className="text-[16px] font-bold pb-2 pt-2" {...props}>
						{props.children}
					</h1>
				),
				h2: ({ node: _node, ...props }) => (
					<h2 className="text-[14px] font-bold pb-2 pt-2" {...props}>
						{props.children}
					</h2>
				),
				h3: ({ node: _node, ...props }) => (
					<h3 className="text-[12px] font-bold pb-2 pt-2" {...props}>
						{props.children}
					</h3>
				),
				ul: ({ node: _node, ...props }) => <ul className="list-disc pl-4" {...props} />,
				ol: ({ node: _node, ...props }) => <ul className="list-decimal pl-4" {...props} />,
				li: ({ node: _node, ...props }) => <li {...props} />,
				p: ({ node: _node, ...props }) => <div className="text pb-2" {...props} />,
				blockquote: ({ node: _node, ...props }) => <blockquote className="bg-sky-100 px-2 py-1 rounded" {...props} />,
				table: ({ node: _node, ...props }) => <table {...props} />,
				code: ({ node: _node, ...props }) => <span {...props} />,
				pre: ({ node: _node, ...props }) => <i {...props} />,
				template: ({ node: _node, ...forward }) => {
					switch ((forward as any)["data-custom-element"]) {
						case "sentiment":
							return (
								<SentimentBadge
									size="x-small"
									//we can't really guarantee type safety here, we'll just use a sprinkle of "as" to make TypeScript happy
									sentiment={((forward as any)["data-sentiment"] ?? "neutral") as "neutral"}
									indicator={((forward as any)["data-indicator"] ?? "driver") as "driver"}
								>
									{(forward as any)["data-label"] ?? ""}
								</SentimentBadge>
							);
						default:
							return <template {...forward} />;
					}
				},
				...componentOverrides,
			}}
		>
			{children}
		</ReactMarkdown>
	);
}
