import type { InvestmentBenchmarkDTO, InvestmentBenchmarkDTOBenchmarkTypeEnum, ReviewTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { DataAttributesProps, SearchableProps } from "@mdotm/mdotui/components";
import {
	Banner,
	Button,
	Dialog,
	DialogFooter,
	DialogHeader,
	Icon,
	Searchable,
	SubmitButton,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useCallback, useState } from "react";
import type { NonUndefined } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";

type CopyTemplateButtonProps = {
	onDuplicateBenchmark?(data: ReviewTicker[]): void;
} & DataAttributesProps;

const CopyTemplateButton = (props: CopyTemplateButtonProps): JSX.Element => {
	const [showModal, setShowModal] = useState(false);
	const { onDuplicateBenchmark, ...dataAttributes } = props;
	const { t } = useTranslation();

	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const mappedBenchmarkType: Record<InvestmentBenchmarkDTOBenchmarkTypeEnum, string> = {
		CUSTOM_BENCHMARK: "Custom benchmark",
		REFERENCE_INVESTMENT: "Target portfolio",
		STANDARD_BENCHMARK: "Standard benchmark",
		CURRENT_PORTFOLIO: "Current portfolio",
	};

	const { data } = useQueryNoRefetch(["queryBenchmarkIndicies"], {
		queryFn: async () => {
			const { availableCurrencies, availablePrimaryBenchmarks } = await axiosExtract(
				editorApi.getEditorNewSelectableMainInfo(),
			);

			return { availableCurrencies, availablePrimaryBenchmarks };
		},
	});

	const { availablePrimaryBenchmarks } = data ?? {};

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		rows: availablePrimaryBenchmarks ?? [],
		selectBy: ({ benchmarkIdentifier }) => benchmarkIdentifier ?? "",
		mode: "radio",
	});

	const handleModalState = useCallback(() => setShowModal(!showModal), [showModal]);

	const matchFn = useCallback<SearchableProps<NonUndefined<typeof availablePrimaryBenchmarks>[number]>["matchFn"]>(
		(row, query) => Object.values(row).join("-").toLowerCase().includes(query.toLowerCase()),
		[],
	);

	const loadBenchmarkComposition = useCallback(
		async (selectedBenchmark: InvestmentBenchmarkDTO | undefined) => {
			try {
				if (selectedBenchmark?.benchmarkIdentifier === undefined || selectedBenchmark === undefined) {
					throw new Error("unable to find an identifier");
				}
				const benchmark = await axiosExtract(editorApi.getBenchmarkInstruments(selectedBenchmark.benchmarkIdentifier));

				return benchmark;
			} catch (error) {
				reportPlatformError(error, "ERROR", "benchmark", "try to load benchmark composition");
			}
		},
		[editorApi],
	);

	const onSubmitAsync = useCallback(async () => {
		const selectedBenchmark = multiSelectCtx.data.selection.first();
		const item = availablePrimaryBenchmarks?.find((row) => row.benchmarkIdentifier === selectedBenchmark);
		const response = await loadBenchmarkComposition(item);
		onDuplicateBenchmark?.(response?.composition ?? []);
		handleModalState();
	}, [
		multiSelectCtx.data.selection,
		availablePrimaryBenchmarks,
		loadBenchmarkComposition,
		handleModalState,
		onDuplicateBenchmark,
	]);

	return (
		<>
			<Button onClick={handleModalState} size="small" palette="secondary" {...dataAttributes}>
				<Icon icon="Content-Copy" size={18} />
				Copy Template
			</Button>

			<Dialog
				show={showModal}
				onClose={handleModalState}
				size="xxlarge"
				header="Duplicate existing benchmark"
				footer={
					<DialogFooter
						neutralAction={
							<Button palette="tertiary" onClick={handleModalState}>
								Cancel
							</Button>
						}
						primaryAction={
							<SubmitButton disabled={multiSelectCtx.data.selection.size === 0}>Duplicate benchmark</SubmitButton>
						}
					/>
				}
				onSubmitAsync={onSubmitAsync}
			>
				<Searchable matchFn={matchFn} collection={availablePrimaryBenchmarks ?? []}>
					{({ filtered, query, setQuery }) => (
						<div className="grid gap-4">
							<Banner severity="warning">{t("BANNER.BENCHMARK_OVERRIDE")}</Banner>
							<TextInput placeholder="Filter by name" value={query} onChangeText={setQuery} />

							<Table
								rows={filtered}
								rowClassList={(row, rowIndex) => ({
									...toClassListRecord(rowClassList(row, rowIndex)),
									CopyTemplateRow: true,
								})}
								columns={[
									checkBoxColumn,
									{
										header: "Name",
										content: (row) => row.benchmarkName,
										sortFn: builtInSortFnFor("benchmarkIdentifier"),
										name: "benchmarkIdentifier",
										relativeWidth: 0.8,
									},
									{
										header: "Type",
										content: (row) => (row.benchmarkType ? mappedBenchmarkType[row.benchmarkType] : "-"),
										sortFn: builtInSortFnFor("benchmarkType"),
										name: "benchmarkType",
										relativeWidth: 0.2,
									},
								]}
								visibleRows={Math.min(filtered.length, 9)}
								onRowClick={({ benchmarkIdentifier }) => toggle(benchmarkIdentifier ?? "")}
							/>
						</div>
					)}
				</Searchable>
			</Dialog>
		</>
	);
};

export default CopyTemplateButton;
