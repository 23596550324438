import {
	AsyncButton,
	Button,
	Dialog,
	DialogHeader,
	Searchable,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";
import type { ReactNode } from "react";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type BasicModalprops<
	T extends {
		id: string;
	},
> = {
	showModal: boolean;
	rows: Array<T>;
	field: keyof T;
	title?: string;
	selectableRowIds?: string[];
	onClose: () => void;
	matchFn(item: T, query: string): boolean;
	header?: ReactNode;
	rowClassList?: string;
} & (
	| {
			multiSelect: true;
			onLoad?: (item?: T[]) => Promise<void> | void;
	  }
	| {
			multiSelect: false;
			onLoad?: (item?: T) => Promise<void> | void;
	  }
);

function _basicModal<
	T extends {
		id: string;
	},
>(props: BasicModalprops<T>) {
	const { field, showModal, rows, onClose, onLoad, matchFn, multiSelect, title, selectableRowIds, header } = props;
	const { t } = useTranslation();

	const {
		column: checkBoxColumn,
		rowClassList,
		multiSelectCtx,
		toggle,
	} = useSelectableTableColumn({
		rows,
		selectBy: ({ id }) => id ?? "",
		mode: multiSelect ? "checkbox" : "radio",
		selectableRowIds,
	});

	const handleLoad = useCallback(async () => {
		if (multiSelect === false) {
			const selectedItem = multiSelectCtx.data.selection.first();
			const item = rows.find((row) => row.id === selectedItem);
			return onLoad?.(item);
		}

		const selectedItems = rows.filter(({ id }) => multiSelectCtx.data.selection.get(id));
		return onLoad?.(selectedItems);
	}, [multiSelect, multiSelectCtx.data.selection, onLoad, rows]);

	const isAsyncButtonDisable = useMemo(() => {
		if (multiSelect) {
			return multiSelectCtx.data.selection.size === 0;
		}
		return multiSelectCtx.data.selection.size === 0 || multiSelectCtx.data.selection.size > 1;
	}, [multiSelect, multiSelectCtx.data.selection.size]);

	return (
		<Dialog
			show={showModal}
			onClose={onClose}
			header={title}
			footer={
				<div className="flex justify-between">
					<Button palette="tertiary" size="small" onClick={onClose}>
						{t("BUTTON.CANCEL")}
					</Button>
					<AsyncButton
						palette="primary"
						size="small"
						onClickAsync={async () => await handleLoad()}
						disabled={isAsyncButtonDisable}
					>
						{t("BUTTON.LOAD")}
					</AsyncButton>
				</div>
			}
			size="xxlarge"
		>
			<Searchable matchFn={matchFn} collection={rows ?? []}>
				{({ filtered, query, setQuery }) => (
					<div className="grid gap-4">
						{props.header}
						<TextInput placeholder="Filter by name" value={query} onChangeText={setQuery} />

						<Table
							rows={filtered}
							rowClassList={(row, rowIndex) => ({
								...toClassListRecord(rowClassList(row, rowIndex)),
								[props.rowClassList ?? ""]: Boolean(props.rowClassList),
							})}
							columns={[
								checkBoxColumn,
								{
									header: "",
									content: (row) => row[field] as any /* TODO: better types */,
								},
							]}
							visibleRows={Math.min(filtered.length, 9)}
							classList="min-h-[410px]"
							onRowClick={({ id }) => toggle(id)}
						/>
					</div>
				)}
			</Searchable>
		</Dialog>
	);
}

export const BasicModal = memo(_basicModal) as typeof _basicModal;
