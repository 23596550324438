import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type RenameDialogCommon = EntityManagementBaseDialogCommon & { currentName: string };
export type RenameDialogProps = EntityManagementBaseDialogProps & { currentName: string };
export type SpawnRenameDialogParams = SpawnEntityManagementBaseDialogParams & { currentName: string };

function withDefaults<T extends SpawnRenameDialogParams | RenameDialogProps>(
	params: T,
): EntityManagementBaseDialogCommon & T {
	return {
		header: params.header ?? (params.entityType ? `Rename ${params.entityType}` : "Rename"),
		inputLabel: params.inputLabel ?? `Rename "${params.currentName}" to...`,
		size: "medium",
		...params,
	};
}

export function spawnRenameDialog(params: SpawnRenameDialogParams): Promise<string | null> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function RenameDialog(props: RenameDialogProps): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
