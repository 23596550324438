import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";

function CalendarDrawIcon({
	style = { height: "auto", width: 82 },
	...props
}: Omit<SvgProps, "viewBox" | "children">): JSX.Element {
	return (
		<Svg style={style} viewBox={{ height: 82, width: 82 }} {...props}>
			<g filter="url(#filter0_d_2511_14823)">
				<path
					fill="#fff"
					d="M64.375 16.963h-4.35v-1.841c0-1.721-1.412-3.122-3.149-3.122-1.736 0-3.149 1.4-3.149 3.122v1.841h-9.625v-1.841c0-1.721-1.412-3.122-3.15-3.122-1.736 0-3.148 1.4-3.148 3.122v1.841H28.27v-1.841C28.271 13.4 26.86 12 25.122 12c-1.736 0-3.149 1.4-3.149 3.122v1.841h-4.35A3.63 3.63 0 0014 20.59v40.902c0 2 1.626 3.628 3.625 3.628h46.751A3.63 3.63 0 0068 61.492V20.59c0-2-1.625-3.627-3.624-3.627zm-8.96-1.841c0-.79.655-1.434 1.462-1.434.806 0 1.461.643 1.461 1.434v5.37c0 .79-.655 1.434-1.461 1.434-.807 0-1.462-.644-1.462-1.435v-5.37zm-15.923 0c0-.79.655-1.434 1.461-1.434.807 0 1.462.643 1.462 1.434v5.37c0 .79-.655 1.434-1.462 1.434-.806 0-1.461-.644-1.461-1.435v-5.37zm-15.831 0c0-.79.655-1.434 1.461-1.434.807 0 1.462.643 1.462 1.434v5.37c0 .79-.655 1.434-1.462 1.434-.806 0-1.461-.644-1.461-1.435v-5.37z"
				/>
			</g>
			<path
				fill="#C3C9D5"
				stroke="#C3C9D5"
				strokeWidth="2"
				d="M15 58.903v-.12c.031.063.065.124.1.183l-.1-.063zm52 0l-.103.065c.036-.06.07-.123.102-.187v.122z"
			/>
			<path
				fill="#8792AB"
				d="M61.098 32H21.9c-1.6 0-2.9 1.283-2.9 2.86v19.28C19 55.717 20.3 57 21.9 57H61.1c1.6 0 2.9-1.283 2.9-2.86V34.86c0-1.577-1.301-2.86-2.901-2.86zm-31.27 23.333H21.9c-.668 0-1.21-.535-1.21-1.193v-4.918h9.136v6.111zm0-7.779H20.69v-6.108h9.137v6.108zm0-7.775h-9.137V34.86c0-.658.542-1.193 1.21-1.193h7.927v6.112zm10.828 15.554h-9.137v-6.111h9.137v6.111zm0-7.779h-9.137v-6.108h9.137v6.108zm0-7.775h-9.137v-6.112h9.137v6.112zM51.48 55.333h-9.134v-6.111h9.134v6.111zm0-7.779h-9.134v-6.108h9.134v6.108zm0-7.775h-9.134v-6.112h9.134v6.112zm1.69-6.112h7.927c.668 0 1.21.535 1.21 1.193v4.919h-9.137v-6.112zm0 7.78h9.137v6.107h-9.136v-6.108zm9.137 12.693c0 .658-.542 1.193-1.21 1.193h-7.926v-6.111h9.137v4.918z"
			/>
			<defs>
				<filter
					id="filter0_d_2511_14823"
					width="82"
					height="81.12"
					x="0"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2511_14823" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_2511_14823" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
}

export default CalendarDrawIcon;
