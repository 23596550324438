import { ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { countIf } from "$root/utils/collections";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { ProgressBar, Text } from "@mdotm/mdotui/components";

export type SmallUniverseSummaryProps = { universe?: { uuid?: string } };

export function SmallUniverseSummary({ universe: externalUniverse }: SmallUniverseSummaryProps): JSX.Element {
	const universeUuid = externalUniverse?.uuid || "";

	const referenceUniversesV4Api = useApiGen(ReferenceUniversesControllerApiFactory);

	const {
		data: universe,
		isError,
		isLoading,
	} = useQueryNoRefetch(["universeDetails", externalUniverse], {
		enabled: Boolean(universeUuid),
		queryFn: async () => {
			const { data: universeDetails } = await referenceUniversesV4Api.getUniverse(universeUuid);
			return universeDetails;
		},
	});
	if (isLoading) {
		return (
			<div className="bg-white rounded px-2 py-2">
				<ProgressBar value="indeterminate" />
			</div>
		);
	}

	if (isError || universe === undefined) {
		return (
			<div className="bg-white rounded px-2 py-2">
				<IconWalls.ErrorData />
			</div>
		);
	}

	const relevantComposition = ["RETRIEVING_DATA", "REVIEW"].includes(universe.status ?? "")
		? universe.uploadComposition
		: universe.universeComposition;

	return (
		<div className="bg-white rounded px-2 py-2">
			{universe ? (
				<div className="flex items-center gap-4">
					<div>
						<Text type="Body/M/Book">Total instruments</Text>
						&nbsp;
						<Text type="Body/M/Bold">{relevantComposition?.length ?? "-"}</Text>
					</div>
					<div>
						<Text type="Body/M/Book">Instruments tagged</Text>
						&nbsp;
						<Text type="Body/M/Bold">
							{/* TODO: what about the uploadComposition? */}
							{relevantComposition ? countIf(relevantComposition, (x) => typeof x.tagLabel === "string") : "-"}
						</Text>
					</div>
					<div>
						<Text type="Body/M/Book">Instruments scored</Text>
						&nbsp;
						<Text type="Body/M/Bold">
							{/* TODO: what about the uploadComposition? */}
							{relevantComposition ? countIf(relevantComposition, (x) => typeof x.score === "number") : "-"}
						</Text>
					</div>
					<div>
						<Text type="Body/M/Book">Used in</Text>
						&nbsp;
						<Text type="Body/M/Bold">
							{typeof universe.referralInvestments?.length !== "number"
								? "-"
								: universe.referralInvestments.length === 1
								  ? `${universe.referralInvestments.length} portfolio`
								  : `${universe.referralInvestments.length} portfolios`}
						</Text>
					</div>
				</div>
			) : (
				<div className="h-4 flex items-center justify-center">No Data</div>
			)}
		</div>
	);
}
