import { IconTooltip, TableV2, type TableColumn } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { ReactNode } from "react";
import { useMemo } from "react";
import type { MinimumViableInstrument } from "./InstrumentsSelectorTable";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import { CopyableText } from "$root/components/CopyableText";
import { overrideClassList } from "@mdotm/mdotui/react-extensions";
import type { SortFn } from "@mdotm/mdotui/utils";
import { builtInSort, builtInSortFnFor } from "@mdotm/mdotui/utils";
import { InstrumentCommentaryFormCell } from "./table-cells/InstrumentCommentaryFormCell";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { InstrumentNameFormCell } from "./table-cells/InstrumentNameFormCell";
import { objectTextSearchMatchFns } from "$root/utils/strings";

/** @deprecated */
export type CommonInstrumentColumnKey =
	| "instrument"
	| "isin"
	| "assetClass"
	| "microAssetClass"
	| "alias"
	| "identifier";

const TrucateWithTitle = (props: { title?: string; qualifier?: string }) => (
	<span className="truncate" title={props?.title} data-qualifier={props.qualifier}>
		{props?.title}
	</span>
);

export function useInstrumentColumns(
	qualfier?: string,
): Record<CommonInstrumentColumnKey, TableColumn<MinimumViableInstrument>> {
	function concatQualifier(qualifier: string, parentQualifier?: string) {
		return parentQualifier ? `${parentQualifier}/Column(${qualifier})` : `Column(${qualifier})`;
	}

	return useMemo<Record<CommonInstrumentColumnKey, TableColumn<MinimumViableInstrument>>>(
		() => ({
			// TODO: translate
			instrument: {
				header: "Instrument name",
				content: (row) => (
					<TrucateWithTitle title={row.instrument ?? ""} qualifier={concatQualifier("Instrument", qualfier)} />
				),
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
			},
			isin: {
				header: "Identifier",
				content: (row) => (
					<CopyableText
						onClick={(e) => e.stopPropagation()}
						text={row.isin ?? ""}
						qualifier={concatQualifier("Instrument", qualfier)}
					/>
				),
				sortFn: builtInCaseInsensitiveSortFor("isin"),
				name: "isin",
			},
			identifier: {
				header: "Identifier",
				content: (row) => (
					<CopyableText
						onClick={(e) => e.stopPropagation()}
						text={row.identifier ?? ""}
						qualifier={concatQualifier("Instrument", qualfier)}
					/>
				),
				sortFn: builtInCaseInsensitiveSortFor("identifier"),
				name: "identifier",
			},
			assetClass: {
				header: "Asset class",
				content: (row) => (
					<TrucateWithTitle title={row.assetClass ?? ""} qualifier={concatQualifier("AssetClass", qualfier)} />
				),
				sortFn: builtInCaseInsensitiveSortFor("assetClass"),
				name: "assetClass",
			},
			microAssetClass: {
				header: "Micro asset class",
				content: (row) => (
					<TrucateWithTitle
						title={row.microAssetClass ?? ""}
						qualifier={concatQualifier("MicroAssetClass", qualfier)}
					/>
				),
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
			alias: {
				header: "Identifier",
				content: (row) => (
					<CopyableText
						onClick={(e) => e.stopPropagation()}
						text={row.alias ?? ""}
						qualifier={concatQualifier("Instrument", qualfier)}
					/>
				),
				sortFn: builtInCaseInsensitiveSortFor("alias"),
				name: "alias",
			},
		}),
		[qualfier],
	);
}

export const instrumentColumnsMetadataByKey = {
	instrument: {
		name: "instrument",
		sortFn: builtInCaseInsensitiveSortFor("instrument"),
	},
	identifier: {
		name: "identifier",
		sortFn: builtInCaseInsensitiveSortFor("identifier"),
	},
	isin: {
		name: "isin",
		sortFn: builtInCaseInsensitiveSortFor("isin"),
	},
	assetClass: {
		name: "assetClass",
		sortFn: builtInCaseInsensitiveSortFor("assetClass"),
	},
	microAssetClass: {
		name: "microAssetClass",
		sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
	},
	alias: {
		name: "alias",
		sortFn: builtInCaseInsensitiveSortFor("alias"),
	},
	commentary: {
		sortFn: (a, b) => builtInSort(a.descriptionDate, b.descriptionDate),
		name: "descriptionDate",
	},
	needsCustomProxy: {
		name: "needsCustomProxy",
		sortFn: builtInSortFnFor("needsCustomProxy"),
	},
	linkedPortfolios: {
		name: "linkedPortfolios",
		sortFn: builtInSortFnFor("linkedPortfolios"),
	},
} satisfies Record<string, { sortFn: SortFn<MinimumViableInstrument>; name: string }>;
export const instrumentColumnsMetadata = Object.values(instrumentColumnsMetadataByKey);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInstrumentColumnsTableV2(qualfier?: string) {
	function concatQualifier(qualifier: string, parentQualifier?: string) {
		return parentQualifier ? `${parentQualifier}/Column(${qualifier})` : `Column(${qualifier})`;
	}

	return useMemo(
		() =>
			({
				// TODO: translate
				instrument(params?: { onSubmitAsync?(ticker: string, newInstrumentName: string): MaybePromise<void> }) {
					return {
						header: "Instrument name",
						content: (row, props) => (
							<InstrumentNameFormCell
								{...props}
								onSubmitAsync={params?.onSubmitAsync && ((newName) => params?.onSubmitAsync?.(row.ticker!, newName))}
								value={row.instrument}
								data-qualifier={concatQualifier("Instrument", qualfier)}
							/>
						),
						minWidth: 244,
						...instrumentColumnsMetadataByKey.instrument,
					};
				},
				identifier: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							classList={overrideClassList("px-2", props.classList)}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.identifier ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 244,
					...instrumentColumnsMetadataByKey.identifier,
				},
				isin: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							classList={overrideClassList("px-2", props.classList)}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.isin ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 128,
					...instrumentColumnsMetadataByKey.isin,
				},
				assetClass: {
					header: "Asset class",
					content: (row, props) => (
						<TableV2.TableDataCell {...props} data-qualifier={concatQualifier("AssetClass", qualfier)}>
							{row.assetClass}
						</TableV2.TableDataCell>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.assetClass,
				},
				microAssetClass: {
					header: "Micro AC",
					content: (row, props) => (
						<TableV2.TableDataCell {...props} data-qualifier={concatQualifier("MicroAssetClass", qualfier)}>
							{row.microAssetClass}
						</TableV2.TableDataCell>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.microAssetClass,
				},
				alias: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							classList={overrideClassList("px-2", props.classList)}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.alias ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.alias,
				},
				commentary(params?: {
					tooltip?: ReactNode;
					onGenerate?(ticker: string, isin: string): MaybePromise<void>;
					onSubmitAsync?(ticker: string, newCommentaryText: string): MaybePromise<void>;
				}) {
					return {
						header: (props) =>
							!params?.tooltip ? (
								"Description"
							) : (
								<TableV2.TableHeadCell {...props}>
									<span className="inline-flex items-center gap-2 min-w-0">
										<span className="line-clamp-2">Description</span>
										<IconTooltip severity="info" overrideColor={themeCSSVars.palette_N300} iconSize={14}>
											{params?.tooltip}
										</IconTooltip>
									</span>
								</TableV2.TableHeadCell>
							),
						content: (
							{ ticker, descriptionStatus, descriptionDate, description, isin, descriptionCreator, type },
							props,
						) => (
							<InstrumentCommentaryFormCell
								{...props}
								commentaryAuthor={descriptionCreator}
								onGenerate={
									isin && type !== "index" && params?.onGenerate
										? () => params?.onGenerate?.(ticker!, isin!)
										: undefined
								}
								onSubmitAsync={
									params?.onSubmitAsync && ((newCommentaryText) => params?.onSubmitAsync?.(ticker!, newCommentaryText))
								}
								commentaryText={description}
								lastDescriptionUpdate={descriptionDate}
								isGenerating={descriptionStatus === "CALCULATING"}
								isGenerationDisabled={descriptionStatus === "MISSING_DATA"}
							/>
						),
						width: 400,
						...instrumentColumnsMetadataByKey.commentary,
					};
				},
			}) satisfies Record<
				string,
				| TableV2.TableColumn<MinimumViableInstrument>
				| ((...params: any[]) => TableV2.TableColumn<MinimumViableInstrument>)
			>,
		[qualfier],
	);
}

export function useSearchableInstrumentTable<T extends MinimumViableInstrument>(
	collection: T[],
	options?: { mode?: "keyword" | "substring"; query?: string },
): {
	query: string;
	setQuery(newQuery: string): void;
	filtered: T[];
	debouncedQuery: string;
} {
	const { filtered, query, setQuery, debouncedQuery } = useSearchable<T>({
		collection,
		matchFn: options?.mode === "keyword" ? objectTextSearchMatchFns.keyword : objectTextSearchMatchFns.substring,
		query: options?.query,
	});

	const memo = useMemo(
		() => ({
			query,
			setQuery,
			filtered,
			debouncedQuery,
		}),
		[filtered, query, setQuery, debouncedQuery],
	);

	return memo;
}
