import type { IndexTicker, ReviewTicker } from "$root/api/api-gen";
import { InstrumentCustomizationControllerApiFactory, ProxyPreferencesControllerV4ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useCompositionBuilder } from "$root/functional-areas/universe/composition";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { useUpdatedRef } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { MaybePromise } from "$root/utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogFooter, SubmitButton, Tab, TabGroup } from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import type BigNumber from "bignumber.js";
import { Map, Set } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import type { MinimumViableInstrument } from "../InstrumentsSelectorTable";
import { InstrumentsSelectorTable } from "../InstrumentsSelectorTable";
import { InstrumentsWeightsTable } from "../InstrumentsWeightsTable";

export type InstrumentClassificationSubmitParams = {
	categorization: {
		instrumentName: string | null;
		macroAssetClass: string;
		microAssetClass: string;
		instrumentCurrencyExposure: string;
	};
	indices: MinimumViableInstrument[];
	weights: Map<string, BigNumber | null>;
};
export type InstrumentClassificationDialogProps = {
	show: boolean;
	onClose?(): void;
	onHidden?(): void;
	askForName: boolean;
	defaultInstrumentProxy?: ReviewTicker;
	onSubmitAsync: (params: InstrumentClassificationSubmitParams) => MaybePromise<void>;
};

export function InstrumentClassificationDialog({
	defaultInstrumentProxy,
	askForName,
	show,
	onClose,
	onHidden,
	onSubmitAsync,
}: InstrumentClassificationDialogProps): JSX.Element {
	const [tabIndex, setTabIndex] = useState<0 | 1 | 2>(
		(() => {
			const isFirstStepFilled =
				defaultInstrumentProxy?.instrument &&
				defaultInstrumentProxy.microAssetClass &&
				defaultInstrumentProxy.assetClass &&
				defaultInstrumentProxy.currency;

			const isSecondStepFileld = !!defaultInstrumentProxy?.proxies?.length;

			if (isFirstStepFilled && isSecondStepFileld) {
				return 2;
			}

			if (isFirstStepFilled) {
				return 1;
			}

			return 0;
		})(),
	);
	const { t } = useTranslation();

	const proxyLiveApi = useApiGen(ProxyPreferencesControllerV4ApiFactory);
	const instrumentCustomizationApi = useApiGen(InstrumentCustomizationControllerApiFactory);
	const proxyCompositionQuery = useQueryNoRefetch({
		queryKey: ["instrumentProxySelectable"],
		queryFn: async () => {
			const macro = await axiosExtract(instrumentCustomizationApi.getProxySelectableAssetClasses1());
			const micro = await axiosExtract(instrumentCustomizationApi.getProxySelectableMicroAssetClasses1());
			const currencies = await axiosExtract(instrumentCustomizationApi.getProxySelectableCurrencies1());
			const indices = await axiosExtract(instrumentCustomizationApi.getProxyPreferencesSelectableProxies1());

			return {
				macroAssetClasses: macro,
				microAssetClasses: micro,
				currencies,
				indices:
					indices?.availableProxies?.map(
						(x) =>
							({
								assetClass: x.assetClass!,
								geography: x.geography!,
								granularity: x.granularity!,
								instrument: x.instrument!,
								microAssetClass: x.microAssetClass!,
								microGeography: x.microGeography!,
								ticker: x.ticker!,
								tickerId: x.tickerId!,
								weight: x.weight ?? 0,
							}) satisfies IndexTicker,
					) ?? [],
			};
		},
		onSuccess: ({ indices }) => {
			const existingInstrumentOnSelectableIndices =
				defaultInstrumentProxy?.proxies?.filter((instrument) =>
					indices.find((index) => index.ticker === instrument.ticker),
				) ?? [];

			selectedInstrumentsMultiSelectCtx.actions.setSelection(
				existingInstrumentOnSelectableIndices.map((instrument) => instrument.ticker!),
			);

			refs.current.compositionBuilder.reset({
				composition: Map<string, number>(
					existingInstrumentOnSelectableIndices.map(({ ticker, weight }) => [ticker!, weight ?? 0]) ?? [],
				),
				decimalPlaces,
			});
		},
	});

	// Step 1
	const [categorizationDefaultValues] = useState(() => ({
		instrumentName: askForName ? defaultInstrumentProxy?.instrument ?? "" : null,
		macroAssetClass: (defaultInstrumentProxy?.assetClass ?? "") satisfies string,
		microAssetClass: (defaultInstrumentProxy?.microAssetClass ?? "") satisfies string,
		instrumentCurrencyExposure: (() => {
			if (defaultInstrumentProxy?.currency === "XXX") {
				return "";
			}
			return defaultInstrumentProxy?.currency ?? "";
		})() satisfies string,
	}));

	const resolver = useMemo(
		() =>
			zodResolver(
				z.object({
					instrumentName: askForName ? z.string().nonempty(t("REQUIRED_FIELD")) : z.any(),
					macroAssetClass: z.string().nonempty(t("REQUIRED_FIELD")),
					microAssetClass: z.string().nonempty(t("REQUIRED_FIELD")),
					instrumentCurrencyExposure: z.string().nonempty(t("REQUIRED_FIELD")),
				} satisfies Record<keyof typeof categorizationDefaultValues, unknown>),
			),
		[askForName, t],
	);

	const { formState, control, handleSubmit, getValues, watch } = useForm({
		defaultValues: categorizationDefaultValues,
		resolver,
	});

	// Step 2
	const selectedInstrumentsMultiSelectCtx = useMultiSelect<string>();

	// Step 3
	const selectedInstruments = useMemo(() => {
		if (proxyCompositionQuery.data?.indices) {
			return proxyCompositionQuery.data.indices.filter((x) =>
				selectedInstrumentsMultiSelectCtx.data.selection.has(x.ticker),
			);
		}
		return [];
	}, [proxyCompositionQuery.data?.indices, selectedInstrumentsMultiSelectCtx]);

	const decimalPlaces = 4;

	const compositionBuilder = useCompositionBuilder(() => ({
		composition: Map<string, number>(),
		decimalPlaces,
	}));

	const refs = useUpdatedRef({ compositionBuilder });

	useEffect(() => {
		if (tabIndex === 2 && selectedInstruments.length > 0) {
			refs.current.compositionBuilder.reset({
				composition: Map<string, number>(
					selectedInstrumentsMultiSelectCtx.data.selection
						.toArray()
						.map((ticker) => [
							ticker,
							refs.current.compositionBuilder.getWeight(ticker ?? "")?.toNumber() ??
								selectedInstruments.find((el) => el.ticker === ticker)?.weight ??
								0,
						]),
				),
				decimalPlaces,
			});
		}
	}, [refs, selectedInstrumentsMultiSelectCtx.data, tabIndex, selectedInstruments]);

	const innerSubmitAsync = useCallback(async () => {
		if (tabIndex === 0) {
			await handleSubmit(() => {
				setTabIndex(1);
			})();
		} else if (tabIndex === 1) {
			setTabIndex(2);
		} else {
			const categorization = getValues();
			if (askForName) {
				await onSubmitAsync({
					categorization: {
						...categorization,
						instrumentCurrencyExposure: categorization.instrumentCurrencyExposure ?? "",
						instrumentName: categorization.instrumentName ?? "",
						macroAssetClass: categorization.macroAssetClass ?? "",
						microAssetClass: categorization.microAssetClass,
					},
					indices: selectedInstruments,
					weights: compositionBuilder.getComposition(),
				});
			} else {
				await onSubmitAsync({
					categorization: {
						...categorization,
						instrumentCurrencyExposure: categorization.instrumentCurrencyExposure ?? "",
						instrumentName: null,
						macroAssetClass: categorization.macroAssetClass ?? "",
						microAssetClass: categorization.microAssetClass,
					},
					indices: selectedInstruments,
					weights: compositionBuilder.getComposition(),
				});
			}
			onClose?.();
		}
	}, [askForName, compositionBuilder, getValues, handleSubmit, onClose, onSubmitAsync, selectedInstruments, tabIndex]);

	return (
		<Dialog
			noValidate
			onAnimationStateChange={(state) => {
				if (state === "hidden") {
					setTabIndex(0);
					onHidden?.();
				}
			}}
			size="xxlarge"
			onSubmitAsync={innerSubmitAsync}
			header={t("INSTRUMENT_CLASSIFICATION.DIALOG.TITLE")}
			footer={
				<DialogFooter
					primaryAction={
						tabIndex === 0 ? (
							<SubmitButton>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.NEXT")}
								{/* {t("INSTRUMENT_CLASSIFICATION.DIALOG.SET_COMP")} */}
							</SubmitButton>
						) : tabIndex === 1 ? (
							<SubmitButton disabled={selectedInstrumentsMultiSelectCtx.data.selection.size === 0}>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.NEXT")}
								{/* {t("INSTRUMENT_CLASSIFICATION.DIALOG.SET_COMP")} */}
							</SubmitButton>
						) : (
							<SubmitButton
								disabled={Boolean(
									compositionBuilder.getComposition().findEntry((x) => x === null) ||
										!compositionBuilder.getTotalWeight().eq(100),
								)}
							>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.SAVE")}
							</SubmitButton>
						)
					}
					secondaryAction={
						tabIndex > 0 ? (
							<Button palette="secondary" onClick={() => setTabIndex((n) => (n - 1) as 0 | 1)}>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.PREVIOUS")}
							</Button>
						) : undefined
					}
					neutralAction={
						<Button palette="tertiary" onClick={() => onClose?.()}>
							{t("INSTRUMENT_CLASSIFICATION.DIALOG.CLOSE")}
						</Button>
					}
				/>
			}
			show={show}
			onClose={onClose}
		>
			<ReactQueryWrapperBase query={proxyCompositionQuery}>
				{(selectable) => (
					<TabGroup
						tabIndex={tabIndex}
						onTabChange={(tabNumber) => {
							if (tabNumber === 1) {
								return (() => {
									handleSubmit(() => setTabIndex(tabNumber as 0 | 1 | 2))()
										.then(noop)
										.catch(console.warn);
								})();
							}

							if (tabNumber === 2 && selectedInstrumentsMultiSelectCtx.data.selection.size === 0) {
								return;
							}

							return setTabIndex(tabNumber as 0 | 1 | 2);
						}}
					>
						<Tab title={`1. ${t("INSTRUMENT_CLASSIFICATION.TABS.CLASS_NAME")}`}>
							<div className="max-w-[400px] space-y-4">
								{askForName && (
									<FormFields.Text
										formState={formState}
										control={control}
										label={t("INSTRUMENT_CLASSIFICATION.TAB_CLASS_NAME.INSTRUMENT_NAME")}
										name="instrumentName"
										classList="w-full"
									/>
								)}
								<FormFields.Autocomplete
									formState={formState}
									control={control}
									label={t("INSTRUMENT_CLASSIFICATION.TAB_CLASS_NAME.MACRO_ASSET_CLASS")}
									name="macroAssetClass"
									classList="w-full"
									suggestions={Array.from(Set(selectable.macroAssetClasses))}
								/>
								{(function () {
									const macro = watch("macroAssetClass");

									return (
										<FormFields.Autocomplete
											formState={formState}
											control={control}
											label={t("INSTRUMENT_CLASSIFICATION.TAB_CLASS_NAME.MICRO_ASSET_CLASS")}
											name="microAssetClass"
											classList="w-full"
											disabled={!macro}
											suggestions={Array.from(Set(selectable.microAssetClasses))}
										/>
									);
								})()}
								{/* SUB LVL 2 e 3 sono diventati microAssetClass */}
								<FormFields.Select
									enableSearch
									formState={formState}
									control={control}
									label={t("INSTRUMENT_CLASSIFICATION.TAB_CLASS_NAME.INSTRUMENT_CURRENCY_EXPOSURE")}
									name="instrumentCurrencyExposure"
									classList="w-full"
									options={Array.from(Set(selectable.currencies)).map((x) => ({ label: x, value: x }))}
								/>
							</div>
						</Tab>
						<Tab title={`2. ${t("INSTRUMENT_CLASSIFICATION.TABS.SELECT_INDICES")}`}>
							<InstrumentsSelectorTable
								instruments={selectable.indices}
								multiSelectCtx={selectedInstrumentsMultiSelectCtx}
								mode="checkbox"
							/>
						</Tab>
						<Tab title={`3. ${t("INSTRUMENT_CLASSIFICATION.TABS.COMP_WEIGHTS")}`}>
							<InstrumentsWeightsTable
								instruments={selectedInstruments}
								onRemoveInstrument={(ticker) => {
									selectedInstrumentsMultiSelectCtx.actions.remove(ticker);
									compositionBuilder.toggleRemove(ticker ?? "");
								}}
								compositionBuilder={compositionBuilder}
							/>
						</Tab>
					</TabGroup>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

export type SpawnInstrumentClassificationDialogParams = Omit<InstrumentClassificationDialogProps, "onClose" | "show">;
export function spawnInstrumentClassificationDialog(
	params: SpawnInstrumentClassificationDialogParams,
): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<InstrumentClassificationDialog
				{...params}
				show={show}
				onClose={() => resolve()}
				onHidden={onHidden}
				onSubmitAsync={async (submitParams) => {
					await params.onSubmitAsync(submitParams);
					resolve();
				}}
			/>
		)),
	);
}
