import type { FactorsDto, InvestmentFactorsResponse } from "$root/api/api-gen";
import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentExportControllerApiFactory,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { ContextContent } from "$root/utils/react-extra";
import { useUpdatedRef } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { useTranslation } from "react-i18next";
import {
	ActionText,
	AutoTooltip,
	Button,
	Checkbox,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	Table,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ScrollWrapper } from "@mdotm/mdotui/components";
import { getGraphMarkers, roundCustomByStep } from "$root/utils/experimental";
import { useCallback, useMemo, useState } from "react";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import { builtInSort } from "@mdotm/mdotui/utils";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import GraphLegend from "$root/components/GraphLegend";
import { pxToRem } from "$root/utils/pxToRem";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { axiosExtract } from "$root/third-party-integrations/axios";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { downloadFileBlob, downloadFileResponse } from "$root/utils/files";

type ReducedResultProps<T> = {
	[key: string]: {
		[key in keyof T]?: FactorsDto;
	} & { groupKey: string };
};

type Unpacked<T> = T extends (infer U)[] ? U : T;

type orderOptionsT = "PortfolioProposal" | "PortfolioBenchmark" | "ProposalBenchmark" | "Default";

const PortfolioFactorsExposure = (props: ContextContent<typeof PortfolioContext>) => {
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);

	const { portfolio, selectedBenchmark, enhanced, reportsExecution, reportExcutionCounter } = props;
	const uuid = portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier ?? "";
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("PORTFOLIOFACTORSEXPOSURE.TITLE"),
			actionHeader: (
				<InfoTooltip>
					<TooltipContent>
						{/* TODO: add translation */}
						<p>
							Gain insights into the performance drivers of the asset class through factor exposure analysis. Discover
							how Sphere calculates its factors by accessing more information&nbsp;
							<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
								here
							</ActionText>
						</p>
					</TooltipContent>
				</InfoTooltip>
			),
		}),
		[t],
	);

	const query = useQueryNoRefetch(
		["PortfolioFactorsExposure", props.portfolio?.status, benchmarkId, reportExcutionCounter],
		{
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				const factors = enhanced
					? await axiosExtract(investmentEnhancementReportApi.getInvestmentFactors1(uuid, benchmarkId))
					: await axiosExtract(investmentReportsApi.getInvestmentFactors(uuid, benchmarkId));

				if (enhanced && !factors.current && !factors.benchmark && !factors.proposal) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				if (!enhanced && !factors.current && !factors.benchmark) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				return {
					data: factors,
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(investmentFactors) => <PortfolioFactorsExposureInner ctx={props} investmentFactors={investmentFactors} />}
		</PortfolioQueryWidgetBase>
	);
};

const PortfolioFactorsExposureInner = ({
	ctx,
	investmentFactors,
}: {
	ctx: ContextContent<typeof PortfolioContext>;
	investmentFactors: InvestmentFactorsResponse;
}) => {
	const { enhanced, portfolio, selectedBenchmark } = ctx;
	const uuid = portfolio!.uuid!;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier ?? "";

	const [initialOrder, setInitialOrder] = useState<orderOptionsT>("Default");
	const [filterBySignificant, setFilterBySignificant] = useState(true);

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const customObjectEntriesFn = Object.entries as <T, K extends keyof T>(obj: T) => Array<[K, T[K]]>;
	const customObjectValuesFn = Object.values as <T, K extends keyof T>(obj: T) => Array<T[K]>;

	const factorsDescription = useMemo(
		() =>
			t("EXPOSUREFACTORS.TOOLTIPDESCRIPTIONS", {
				returnObjects: true,
			}),
		[t],
	);

	const orderOptions: { label: string; value: orderOptionsT; onlyEnhance: boolean; show: boolean }[] = useMemo(
		() => [
			{ label: "Beta", value: "Default", onlyEnhance: false, show: true },
			{ label: "Portfolio vs proposal", value: "PortfolioProposal", onlyEnhance: true, show: true },
			{ label: "Portfolio vs benchmark", value: "PortfolioBenchmark", onlyEnhance: false, show: true },
			{ label: "Proposal vs benchmark", value: "ProposalBenchmark", onlyEnhance: true, show: true },
		],
		[],
	);

	// Download
	const exportFactorsExposure = async (uuid: string, benchmarkId: string) => {
		const { data, headers } = enhanced
			? await exportEnhancedApi.exportFactors1(uuid, { responseType: "blob" })
			: await exportApi.exportFactors(uuid, { responseType: "blob" });
		const fileType = headers["content-type"];
		const fileName = headers["content-disposition"].split("filename=")[1];
		trackMixPanelEvent("Portfolio", {
			Type: `Export`,
			Area: "Factors",
			ID: uuid,
		});
		downloadFileBlob({ data, name: fileName, type: fileType });
	};

	const exportFactorsExposureRef = useUpdatedRef(exportFactorsExposure);

	const customOrderFn = useCallback(
		(a: NonNullable<typeof factorsData>[number], b: NonNullable<typeof factorsData>[number]) => {
			switch (initialOrder) {
				case "PortfolioProposal":
					return (
						Math.abs((b.current?.value ?? 0) - (b.proposal?.value ?? 0)) -
						Math.abs((a.current?.value ?? 0) - (a.proposal?.value ?? 0))
					);
				case "PortfolioBenchmark":
					return (
						Math.abs((b.current?.value ?? 0) - (b.benchmark?.value ?? 0)) -
						Math.abs((a.current?.value ?? 0) - (a.benchmark?.value ?? 0))
					);
				case "ProposalBenchmark":
					return (
						Math.abs((b.proposal?.value ?? 0) - (b.benchmark?.value ?? 0)) -
						Math.abs((a.proposal?.value ?? 0) - (a.benchmark?.value ?? 0))
					);
				default:
					return Math.abs(b.current?.value ?? 0) - Math.abs(a.current?.value ?? 0);
			}
		},
		[initialOrder],
	);

	const factorsData = useMemo(() => {
		const aggregateData = customObjectValuesFn(
			customObjectEntriesFn(investmentFactors).reduce<ReducedResultProps<typeof investmentFactors>>(
				(acc, [key, factorList]) => {
					factorList?.forEach((factor) => {
						if (factor.key === undefined) {
							acc["undefinedKey"] = {
								...acc["undefinedKey"],
								[key]: factor,
								groupKey: "undefinedKey",
							};
						} else {
							acc[factor.key] = {
								...acc[factor.key],
								[key]: factor,
								groupKey: factor.key,
							};
						}
					});
					return acc;
				},
				{},
			),
		);
		return aggregateData;
	}, [customObjectEntriesFn, customObjectValuesFn, investmentFactors]);

	const calculatedData = useMemo(() => {
		const filteredData = filterBySignificant
			? factorsData?.filter(({ benchmark, current, proposal }) =>
					// Actually .relevant is not used because the model return 0 as value in those cases, we only check if all values are 0
					enhanced
						? [benchmark?.value, current?.value, proposal?.value].some((e) => e !== 0)
						: [benchmark?.value, current?.value].some((e) => e !== 0),
			  )
			: factorsData;
		return filteredData?.sort((a, b) => customOrderFn(a, b));
	}, [factorsData, customOrderFn, enhanced, filterBySignificant]);

	const graphLimits = useMemo(() => {
		const limits = (calculatedData ?? []).reduce(
			(a, c) => {
				return {
					maxValue: Math.max(a.maxValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
					minValue: Math.min(a.minValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
				};
			},
			{ maxValue: 0, minValue: 0 },
		);
		return {
			maxValue: roundCustomByStep(limits.maxValue, 0.5),
			minValue: roundCustomByStep(limits.minValue, 0.5),
		};
	}, [calculatedData]);

	const graphColumnMarkers = useMemo(() => {
		const markers = getGraphMarkers(graphLimits.maxValue, graphLimits.minValue, 0.5, "", 10);
		return markers;
	}, [graphLimits.maxValue, graphLimits.minValue]);

	const columns = useMemo<Array<TableColumn<NonNullable<Unpacked<typeof calculatedData>>>>>(
		() =>
			enhanced
				? [
						{
							relativeWidth: 50,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.FACTORS"),
							content: ({ current }) => (
								<AutoTooltip
									severity="info"
									trigger={({ innerRef }) => (
										<span className="font-bold underline" ref={innerRef}>
											{current?.label}
										</span>
									)}
									position="bottom"
									align="startToEnd"
								>
									<TooltipContent>
										{factorsDescription[current?.key as keyof typeof factorsDescription] ?? current?.key}&nbsp;
										<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
											Read more
										</ActionText>
									</TooltipContent>
								</AutoTooltip>
							),
							sortFn: (a, b) => builtInSort(a.current?.label, b.current?.label),
							name: "label",
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.CURRENT"),
							content: ({ current }) => `${formatNumber(current?.value)}`,
							sortFn: (a, b) => builtInSort(Math.abs(a.current?.value ?? 0), Math.abs(b.current?.value ?? 0)),
							name: "current",
							align: "end",
							cellClassList: "tabular-nums",
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.PROPOSAL"),
							content: ({ proposal }) => `${formatNumber(proposal?.value)}`,
							sortFn: (a, b) => builtInSort(Math.abs(a.proposal?.value ?? 0), Math.abs(b.proposal?.value ?? 0)),
							name: "proposal",
							align: "end",
							cellClassList: "tabular-nums",
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.BENCHMARK"),
							content: ({ benchmark }) => `${formatNumber(benchmark?.value)}`,
							sortFn: (a, b) => builtInSort(Math.abs(a.benchmark?.value ?? 0), Math.abs(b.benchmark?.value ?? 0)),
							name: "benchmark",
							align: "end",
							cellClassList: "tabular-nums",
						},
						{
							relativeWidth: 40,
							header: () => (
								<div className="flex justify-between grow">
									{graphColumnMarkers.map((m, i) => (
										<div key={`marker-${i}`} className="">
											{m.label}
										</div>
									))}
								</div>
							),
							content: ({ benchmark, current, proposal }) => (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
									}}
									data={[
										{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "#ccc" },
										{ value: proposal?.value ?? 0, color: proposal?.relevant ? "#00AEEF" : "#ccc" },
										{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#A0A7B6" : "#ccc" },
									]}
								/>
							),
						},
				  ]
				: [
						{
							relativeWidth: 50,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.FACTORS"),
							content: ({ current }) => (
								<AutoTooltip
									severity="info"
									trigger={({ innerRef }) => (
										<span className="font-bold underline" ref={innerRef}>
											{current?.label}
										</span>
									)}
									position="bottom"
									align="startToEnd"
								>
									<TooltipContent>
										{factorsDescription[current?.key as keyof typeof factorsDescription] ?? current?.key}&nbsp;
										<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
											Read more
										</ActionText>
									</TooltipContent>
								</AutoTooltip>
							),
							sortFn: (a, b) => builtInSort(a.current?.label, b.current?.label),
							name: "label",
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.CURRENT"),
							content: ({ current }) => `${formatNumber(current?.value)}`,
							sortFn: (a, b) => builtInSort(Math.abs(a.current?.value ?? 0), Math.abs(b.current?.value ?? 0)),
							name: "current",
							align: "end",
							cellClassList: "tabular-nums",
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.BENCHMARK"),
							content: ({ benchmark }) => `${formatNumber(benchmark?.value)}`,
							sortFn: (a, b) => builtInSort(Math.abs(a.benchmark?.value ?? 0), Math.abs(b.benchmark?.value ?? 0)),
							name: "benchmark",
							align: "end",
							cellClassList: "tabular-nums",
						},
						{
							relativeWidth: 50,
							header: () => (
								<div className="flex justify-between grow">
									{graphColumnMarkers.map((m, i) => (
										<div key={`marker-${i}`} className="">
											{m.label}
										</div>
									))}
								</div>
							),
							content: ({ benchmark, current }) => (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
									}}
									data={[
										{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "#ccc" },
										{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#A0A7B6" : "#ccc" },
									]}
								/>
							),
						},
				  ],
		[factorsDescription, enhanced, formatNumber, graphColumnMarkers, graphLimits.maxValue, graphLimits.minValue, t],
	);

	useWidgetOptions(
		() => ({
			title: t("PORTFOLIOFACTORSEXPOSURE.TITLE"),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{calculatedData && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									({ onClose }) => (
										<DropdownMenuActionButton
											key="factorsExposure"
											icon="Dowload"
											onClickAsync={async () => {
												await exportFactorsExposureRef.current(uuid, benchmarkId);
												onClose();
											}}
										>
											{t("PORTFOLIOFACTORSEXPOSURE.DOWNLOAD_TITLE")}
										</DropdownMenuActionButton>
									),
								]}
							/>
						)}
						<InfoTooltip>
							<TooltipContent>
								{/* TODO: add translation */}
								<p>
									Gain insights into the performance drivers of the asset class through factor exposure analysis.
									Discover how Sphere calculates its factors by accessing more information&nbsp;
									<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("marketRegimeAnalysysTool")}>
										here
									</ActionText>
								</p>
							</TooltipContent>
						</InfoTooltip>
					</div>
				);
			},
		}),
		[calculatedData, exportFactorsExposureRef, t, uuid, benchmarkId],
	);

	return (
		<>
			<div className="flex justify-between">
				<div className="flex gap-1">
					<p className="leading-loose">OrderBy:</p>
					{orderOptions
						.filter((o) => (enhanced ? true : !o.onlyEnhance) && o.show)
						.map((option) => (
							<Button
								key={option.value}
								size="x-small"
								palette={option.value === initialOrder ? "neutral" : "neutralOutline"}
								onClick={() => setInitialOrder(option.value === initialOrder ? "Default" : option.value)}
							>
								{option.label}
							</Button>
						))}
				</div>
				<Checkbox
					onChange={() => setFilterBySignificant(!filterBySignificant)}
					checked={filterBySignificant}
					switchPosition="start"
					switchType="switch"
				>
					Significant factors only
				</Checkbox>
			</div>
			<ScrollWrapper startShadow={false} classList="mb-6">
				<Table headerRowClassList="sticky top-0 z-10" columns={columns} rows={calculatedData} key={initialOrder} />
			</ScrollWrapper>
			<GraphLegend>
				<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
					<ColoredRectangle color="#005C8B" variant="vertical" /> Current
				</div>
				{enhanced && (
					<div className="legend-container light book">
						<ColoredRectangle color="#00AEEF" variant="vertical" /> Proposal
					</div>
				)}
				<div className="legend-container light book">
					<ColoredRectangle color="#A0A7B6" variant="vertical" /> Benchmark
				</div>
			</GraphLegend>
		</>
	);
};

export default withContext(PortfolioContext)(PortfolioFactorsExposure);
