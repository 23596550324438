import { useTranslation } from "react-i18next";
import type { LabelValueV2 } from "$root/api/api-gen";
import GraphLegend from "$root/components/GraphLegend";
import { Icon } from "@mdotm/mdotui/components";
import { PaletteColors } from "$root/styles/themePalette";
import { handlePreviousMarketData } from "$root/utils/marketUtils";
import type { FC } from "react";
import { useCallback } from "react";
import type { TableParams } from "../OutlookExpected/OutlookExpectedTable";
import OutlookExpectedTable from "../OutlookExpected/OutlookExpectedTable";

type HistorycalVariants = "compare_to_peers_regime" | "compare_to_market_regime";

type ACRHistoricalValuePercentileProps = {
	data: LabelValueV2[];
};

const ACRHistoricalValuePercentile: FC<ACRHistoricalValuePercentileProps> = ({ data: ACRData }) => {
	const { t } = useTranslation();

	const composeTableRowData = useCallback(
		(data: Array<LabelValueV2>) => {
			const composeLabel = (historycalLabel?: HistorycalVariants) =>
				historycalLabel
					? t(`ASSET_CLASS_REGIME.HISTORICAL_VALUE_PERCENTILE.SERIES.${historycalLabel}`, {
							returnObjects: true,
					  })
					: {
							LABEL: "-",
							TOOLTIP: "-",
					  };

			return data.reduce((acc, { value, prev_value, label }, i) => {
				if (value?.value === undefined) {
					return acc;
				}
				const { LABEL, TOOLTIP: headerTooltip } = composeLabel(label as HistorycalVariants | undefined);

				const { previous, tooltip } = handlePreviousMarketData(
					t,
					"ASSET_CLASS_REGIME.HISTORICAL_VALUE_PERCENTILE.AXIS" as never,
					"outlook",
					{ value: value.value, data: value.data },
					{ value: prev_value?.value, data: prev_value?.data },
				);

				return [
					...acc,
					{
						dynamics: LABEL,
						dynamicsDescription: headerTooltip,
						current: value.value,
						prev: previous,
						tooltip,
						index: i,
					},
				];
			}, [] as Array<TableParams>);
		},
		[t],
	);

	const mappedData = composeTableRowData(ACRData);

	return (
		<>
			<div className="mt-5 max-h-[11.25rem]">
				<OutlookExpectedTable data={mappedData} title="DIVERSIFICATION" sortable />
			</div>
			<GraphLegend>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-doubledown" color={PaletteColors.NEON_RED} size={16} />
					{t("ASSET_CLASS_REGIME.HISTORICAL_VALUE_PERCENTILE.LEGEND.LOW")}
				</div>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-center" color={PaletteColors.AZURE} size={16} />
					{t("ASSET_CLASS_REGIME.HISTORICAL_VALUE_PERCENTILE.LEGEND.NEUTRAL")}
				</div>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-doubleup" color={PaletteColors.GREENY_BLUE} size={16} />
					{t("ASSET_CLASS_REGIME.HISTORICAL_VALUE_PERCENTILE.LEGEND.HIGH")}
				</div>
			</GraphLegend>
		</>
	);
};

export default ACRHistoricalValuePercentile;
