export function PositioningChangeIcon(): JSX.Element {
	return (
		<svg
			width="136"
			height="136"
			viewBox="0 0 136 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="136" height="136" fill="url(#pattern0)" />
			<rect x="8.5" y="8.5" width="119" height="119" fill="white" />
			<path d="M19.9998 44.1304H116" stroke="#E1E4EA" />
			<path d="M90.5855 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M90.5855 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M46.1745 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M67.9998 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M23.58 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M112.8 55.2609V33" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M67.9998 55.2609V33" stroke="#A5AEC0" strokeWidth="2" strokeLinejoin="round" />
			<rect width="19.5" height="10" transform="translate(28.9998 39.1304)" fill="#FFA5A7" />
			<rect width="19.5" height="10" transform="translate(48.4998 39.1304)" fill="#FFA5A7" />
			<circle cx="28.9998" cy="44.1304" r="6" fill="#CA405A" />
			<circle cx="67.9998" cy="44.1304" r="4" fill="white" stroke="#8792AB" strokeWidth="2" />
			<path d="M19.9998 82.1304H116" stroke="#E1E4EA" />
			<path d="M90.5855 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M90.5855 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M46.1745 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M67.9998 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M23.58 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M112.8 93.2609V71" stroke="#C3C9D5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
			<path d="M67.9998 93.2609V71" stroke="#A5AEC0" strokeWidth="2" strokeLinejoin="round" />
			<rect width="19.5" height="10" transform="translate(67.9998 77.1304)" fill="#B7DFD7" />
			<circle cx="67.9998" cy="82.1304" r="4" fill="white" stroke="#8792AB" strokeWidth="2" />
			<circle cx="87.4998" cy="82.1304" r="6" fill="#4CB09C" />
			<rect x="8.5" y="8.5" width="119" height="119" stroke="#EFF0F3" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0294118" height="0.0294118">
					<use xlinkHref="#image0_659_272837" transform="scale(0.00735294)" />
				</pattern>
				<image
					id="image0_659_272837"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
