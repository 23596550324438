import { ScoreNamesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import {
	useCustomLabelsValue,
	type CustomLabels,
	useCustomLabelsState,
} from "$root/functional-areas/system/CustomLabelsStore";
import { AutoTooltip, Button, CircularProgressBar, Icon, TextInput, Tooltip } from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

type CustomLabelsProps = {
	labelKey: string;
	fallback: string;
	labelsObj?: CustomLabels;
};

type CustomLabelsEditorProps = CustomLabelsProps & {
	mode: "view" | "edit";
	isEditable?: boolean;
};

const getLabel = <T extends string | number | symbol>(tKey: T, tDictObj: Record<T, string>, tFallback: string) => {
	return tDictObj[tKey] ?? tFallback;
};

export function CustomLabels({ labelKey, fallback, labelsObj }: CustomLabelsProps): JSX.Element {
	const { customLabels } = useCustomLabelsValue();

	const translations = useMemo(() => {
		return labelsObj ?? customLabels;
	}, [customLabels, labelsObj]); // Get from a zustand Atoms
	const trans = getLabel(labelKey, translations, fallback);
	return <>{trans}</>;
}

export function useCustomScore(): { getScoreLabel: (labelKey: string, fallback: string) => string } {
	const { customLabels } = useCustomLabelsValue();

	const getScoreLabel = useCallback(
		(labelKey: string, fallback: string) => {
			return getLabel(labelKey, customLabels, fallback);
		},
		[customLabels],
	);

	return useMemo(() => ({ getScoreLabel }), [getScoreLabel]);
}

function CustomLabelsEditor({
	mode,
	labelKey,
	fallback,
	labelsObj,
	isEditable = true,
}: CustomLabelsEditorProps): JSX.Element {
	const CustomLabelsApi = useApiGen(ScoreNamesControllerApiFactory);
	const { customLabelStatus, setCustomLabelsStatus } = useCustomLabelsState();
	const { customLabels } = useCustomLabelsValue();
	const [currentMode, setCurrentModeMode] = useState(mode);
	const [isError, setIsError] = useState(false);
	const [translation, setTranslation] = useState<string | null>(null);
	const translations = useMemo(() => {
		return labelsObj ?? customLabels;
	}, [customLabels, labelsObj]); // Get from a Zustand Atoms
	const isEditor = currentMode === "edit" && isEditable;

	const customLabelSchema = z
		.string()
		.regex(/^[a-zA-Z0-9_]*$/)
		.min(1)
		.max(15);

	const { run: handleTranslationChange, loading: isLoading } = useAsync({
		asyncFn: async () => {
			if (!translation) {
				return;
			}
			try {
				await CustomLabelsApi.insertScoreName(labelKey, translation);
				const newStatus = { customLabels: { ...customLabelStatus.customLabels, [labelKey]: translation } };
				setCustomLabelsStatus(newStatus);
			} catch (err) {
				setIsError(true);
				throw err;
			} finally {
				setCurrentModeMode("view");
			}
		},
	});

	useEffect(() => {
		const trans = getLabel(labelKey, translations, fallback);
		setTranslation(trans);
	}, [fallback, labelKey, translations, customLabels]);

	return isEditor ? (
		<>
			<Tooltip
				align="middle"
				severity="error"
				open={isError}
				position="top"
				trigger={({ innerRef }) => (
					<TextInput
						innerRef={innerRef}
						invalid={isError}
						placeholder="From"
						type="text"
						classList="w-full"
						autoFocus
						onChangeText={(value) => {
							const validation = customLabelSchema.safeParse(value);
							if (validation.success) {
								setIsError(false);
							} else {
								setIsError(true);
							}
							setTranslation(value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !isError) {
								e.stopPropagation();
								e.preventDefault();
								unpromisify(() => handleTranslationChange())();
							}
						}}
						value={translation ?? ""}
						rightContent={
							<Button
								unstyled
								disabled={isError}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									unpromisify(() => handleTranslationChange())();
								}}
							>
								<Icon
									icon={isError ? "Yes" : "No"}
									size={16}
									color={isError ? themeCSSVars.MessageSeverity_error : themeCSSVars.Button_bg_primary}
								/>
							</Button>
						}
					/>
				)}
			>
				You can use letters, numbers and &quot;_&quot; and max length should be between 1 and 15 characters
			</Tooltip>
		</>
	) : (
		<div className="flex justify-start">
			{translation}
			{isEditable &&
				(isLoading ? (
					<CircularProgressBar style={{ width: "12px", height: "12px", marginLeft: "6px" }} value="indeterminate" />
				) : (
					<>
						<Button
							unstyled
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setCurrentModeMode("edit");
							}}
							classList="pl-1"
						>
							<AutoTooltip
								disabled={!isError}
								severity="error"
								position="bottom"
								mode="hover"
								trigger={({ innerRef }) => (
									<div ref={innerRef}>
										<Icon
											icon="Edit"
											color={isError ? themeCSSVars.MessageSeverity_error : themeCSSVars.Button_bg_primary}
										/>
									</div>
								)}
							>
								The new label could not be saved, please try again.
							</AutoTooltip>
						</Button>
					</>
				))}
		</div>
	);
}

export default CustomLabelsEditor;
