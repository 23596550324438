import type { ReactNode } from "react";
import { Icon } from "@mdotm/mdotui/components";
import { Link } from "react-router-dom";

export type Crumb = { children: ReactNode } & (
	| {
			href?: string;
			onClick?: undefined;
	  }
	| {
			href?: undefined;
			onClick?(): void;
	  }
);

export function Breadcrumbs({ crumbs }: { crumbs: Array<Crumb> }): JSX.Element {
	return (
		<div className="flex flex-row items-center">
			{crumbs.map(({ href, children, onClick }, i) => {
				const content = (
					<div className="flex items-center space-x-2 py-2 px-1">
						<div className={i < crumbs.length - 1 ? "text-[#4CB09C]" : ""}>{children}</div>
						{i < crumbs.length - 1 && (
							<div>
								<Icon size="0.85rem" color="#80848B" icon="Right" />
							</div>
						)}
					</div>
				);

				if (href) {
					return (
						<Link key={href + i} to={href}>
							{content}
						</Link>
					);
				} else if (onClick) {
					return (
						<button key={"button" + i} type="button" onClick={onClick}>
							{content}
						</button>
					);
				}
				return <div key={"div" + i}>{content}</div>;
			})}
		</div>
	);
}
