export function SuggestionsIcon({ color = "#697796" }: { color?: string }): JSX.Element {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7276 2.79481C12.2118 3.27902 12.5804 3.81628 12.8231 4.39157C13.0661 4.96736 13.1892 5.59645 13.1892 6.26128C13.1892 7.14437 12.9787 7.94949 12.5635 8.65413C12.1498 9.35635 11.5707 9.94957 10.8421 10.4171L10.8122 10.4362H5.70945L5.67943 10.4166C4.96207 9.94919 4.3859 9.35635 3.96706 8.65464C3.54631 7.94987 3.33301 7.14462 3.33301 6.26128C3.33301 5.59645 3.45613 4.96749 3.69906 4.39169C3.94175 3.81628 4.31035 3.27902 4.79456 2.79481C5.27878 2.31059 5.81604 1.94199 6.39132 1.69931C6.96712 1.45637 7.59621 1.33325 8.26103 1.33325C8.92586 1.33325 9.55495 1.45637 10.1309 1.69931C10.7062 1.94199 11.2434 2.31059 11.7276 2.79481ZM4.96996 7.96577C5.25232 8.48013 5.64395 8.90635 6.13414 9.2331H10.4038C10.8837 8.90635 11.2703 8.48013 11.5526 7.96577C11.8405 7.44161 11.9863 6.86811 11.9863 6.26128C11.9863 5.75099 11.8944 5.27122 11.7131 4.83546C11.5319 4.39958 11.2556 3.99409 10.892 3.63058C10.5283 3.26707 10.123 2.99068 9.6871 2.80943C9.25135 2.62819 8.77158 2.53623 8.26129 2.53623C7.751 2.53623 7.27123 2.62819 6.83547 2.80943C6.39959 2.99068 5.9941 3.26694 5.63059 3.63058C5.26695 3.99422 4.99069 4.39958 4.80944 4.83546C4.6282 5.27122 4.53624 5.75099 4.53624 6.26128C4.53624 6.86798 4.68213 7.44149 4.96996 7.96577ZM10.3765 11.172H6.14608C5.97463 11.172 5.83001 11.2298 5.71618 11.3436C5.60234 11.4575 5.5446 11.6022 5.5446 11.7735C5.5446 11.9449 5.60234 12.0896 5.71618 12.2034C5.83001 12.3173 5.97463 12.375 6.14608 12.375H10.3765C10.5479 12.375 10.6925 12.3173 10.8064 12.2034C10.9202 12.0896 10.978 11.945 10.978 11.7735C10.978 11.6021 10.9202 11.4575 10.8064 11.3436C10.6925 11.2298 10.5479 11.172 10.3765 11.172ZM6.84212 13.2478C6.84212 13.6425 6.98076 13.9813 7.25409 14.2546C7.52743 14.528 7.86614 14.6666 8.26094 14.6666C8.65574 14.6666 8.99445 14.528 9.26779 14.2546C9.54112 13.9813 9.67976 13.6425 9.67976 13.2478V13.127H6.84225V13.2478H6.84212Z"
				fill={color}
			/>
		</svg>
	);
}
