import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { PageHeader } from "$root/components/PageHeader";
import { TextEditor } from "$root/components/TextEditor/TextEditor";
import { Tab, TabGroup } from "@mdotm/mdotui/components";
import type { editor } from "monaco-editor";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const AdvancedEditor = (): JSX.Element => {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(0);
	const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
	const language = "custom-xml";
	const content = `<breakfast_menu>
	<food>
	<name>Belgian Waffles</name>
	<price>$5.95</price>
	<description>Two of our famous Belgian Waffles with plenty of real maple syrup</description>
	<calories>650</calories>
	</food>
	<food>
	<name>Strawberry Belgian Waffles</name>
	<price>$7.95</price>
	<description>Light Belgian waffles covered with strawberries and whipped cream</description>
	<calories>900</calories>
	</food>
	<food>
	<name>Berry-Berry Belgian Waffles</name>
	<price>$8.95</price>
	<description>Light Belgian waffles covered with an assortment of fresh berries and whipped cream</description>
	<calories>900</calories>
	</food>
	<food>
	<name>French Toast</name>
	<price>$4.50</price>
	<description>Thick slices made from our homemade sourdough bread</description>
	<calories>600</calories>
	</food>
	<food>
	<name>Homestyle Breakfast</name>
	<price>$6.95</price>
	<description>Two eggs, bacon or sausage, toast, and our ever-popular hash browns</description>
	<calories>950</calories>
	</food>
	</breakfast_menu>`;

	return (
		<>
			<PageHeader
				title="Advanced Commentary" // TODO: translate
			/>
			<AuthorizationGuard requiredRole="ROOT" placeholder={t("NOT_AUTHORIZED")}>
				{() => (
					<>
						<div className="mt-2 w-full">
							<TabGroup palette="primary" tabIndex={selectedTab} onTabChange={setSelectedTab}>
								<Tab title="Editor">
									<div className="p-4 rounded bg-white">
										<h2 className="font-semibold text-lg mb-4">Editor</h2>
										<TextEditor
											classList="h-[80vh] overflow-hidden"
											language={language}
											content={content}
											editorRef={editorRef}
										/>
									</div>
								</Tab>
								<Tab title="Settings">
									<div className="p-4 rounded bg-white">
										<h2 className="font-semibold text-lg mb-4">Settings</h2>
										<p>{language}</p>
									</div>
								</Tab>
							</TabGroup>
						</div>
					</>
				)}
			</AuthorizationGuard>
		</>
	);
};

export default AdvancedEditor;
