import * as m from "monaco-editor/esm/vs/editor/editor.api";

//https://stackoverflow.com/questions/376373/pretty-printing-xml-with-javascript
function prettify(sourceXml: string): string {
	const xmlDoc = new DOMParser().parseFromString(sourceXml, "application/xml");
	const xsltDoc = new DOMParser().parseFromString(
		[
			// describes how we want to modify the XML - indent everything
			'<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
			'  <xsl:strip-space elements="*"/>',
			'  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
			'    <xsl:value-of select="normalize-space(.)"/>',
			"  </xsl:template>",
			'  <xsl:template match="node()|@*">',
			'    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
			"  </xsl:template>",
			'  <xsl:output indent="yes"/>',
			"</xsl:stylesheet>",
		].join("\n"),
		"application/xml",
	);

	const xsltProcessor = new XSLTProcessor();
	xsltProcessor.importStylesheet(xsltDoc);
	const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
	const resultXml = new XMLSerializer().serializeToString(resultDoc);
	return resultXml;
}

const configuration: m.languages.LanguageConfiguration = {
	comments: {
		blockComment: ["<!--", "-->"],
	},
	brackets: [["<", ">"]],
	autoClosingPairs: [
		{ open: "<", close: ">" },
		{ open: "'", close: "'" },
		{ open: '"', close: '"' },
	],
	surroundingPairs: [
		{ open: "<", close: ">" },
		{ open: "'", close: "'" },
		{ open: '"', close: '"' },
	],
	onEnterRules: [
		{
			beforeText: new RegExp(`<([_:\\w][_:\\w-.\\d]*)([^/>]*(?!/)>)[^<]*$`, "i"),
			afterText: /^<\/([_:\w][_:\w-.\d]*)\s*>$/i,
			action: {
				indentAction: m.languages.IndentAction.IndentOutdent,
			},
		},
		{
			beforeText: new RegExp(`<(\\w[\\w\\d]*)([^/>]*(?!/)>)[^<]*$`, "i"),
			action: { indentAction: m.languages.IndentAction.Indent },
		},
	],
};

const provider = <m.languages.IMonarchLanguage>{
	defaultToken: "",
	tokenPostfix: ".xml",
	ignoreCase: true,
	// Useful regular expressions
	qualifiedName: /(?:[\w.-]+:)?[\w.-]+/,

	tokenizer: {
		root: [
			[/[^<&]+/, ""],

			{ include: "@whitespace" },

			// Standard opening tag
			[/(<)(@qualifiedName)/, [{ token: "delimiter" }, { token: "tag", next: "@tag" }]],

			// Standard closing tag
			[/(<\/)(@qualifiedName)(\s*)(>)/, [{ token: "delimiter" }, { token: "tag" }, "", { token: "delimiter" }]],

			// Meta tags - instruction
			[/(<\?)(@qualifiedName)/, [{ token: "delimiter" }, { token: "metatag", next: "@tag" }]],

			// Meta tags - declaration
			[/(<!)(@qualifiedName)/, [{ token: "delimiter" }, { token: "metatag", next: "@tag" }]],

			// CDATA
			[/<!\[CDATA\[/, { token: "delimiter.cdata", next: "@cdata" }],

			[/&\w+;/, "string.escape"],
		],

		cdata: [
			[/[^\]]+/, ""],
			[/\]\]>/, { token: "delimiter.cdata", next: "@pop" }],
			[/\]/, ""],
		],

		tag: [
			[/[ \t\r\n]+/, ""],
			[/(@qualifiedName)(\s*=\s*)("[^"]*"|'[^']*')/, ["attribute.name", "", "attribute.value"]],
			[/(@qualifiedName)(\s*=\s*)("[^">?/]*|'[^'>?/]*)(?=[?/]>)/, ["attribute.name", "", "attribute.value"]],
			[/(@qualifiedName)(\s*=\s*)("[^">]*|'[^'>]*)/, ["attribute.name", "", "attribute.value"]],
			[/@qualifiedName/, "attribute.name"],
			[/\?>/, { token: "delimiter", next: "@pop" }],
			[/(\/)(>)/, [{ token: "tag" }, { token: "delimiter", next: "@pop" }]],
			[/>/, { token: "delimiter", next: "@pop" }],
		],

		whitespace: [
			[/[ \t\r\n]+/, ""],
			[/<!--/, { token: "comment", next: "@comment" }],
		],

		comment: [
			[/[^<-]+/, "comment.content"],
			[/-->/, { token: "comment", next: "@pop" }],
			[/<!--/, "comment.content.invalid"],
			[/[<-]/, "comment.content"],
		],
	},
};

const CompletionItemProvider = <m.languages.CompletionItemProvider>{
	triggerCharacters: [">", ""],
	provideCompletionItems: (model, position) => {
		const codePre: string = model.getValueInRange({
			startLineNumber: position.lineNumber,
			startColumn: 1,
			endLineNumber: position.lineNumber,
			endColumn: position.column,
		});

		const suggestions = [];
		const tag = codePre.match(/.*<(\w+)>$/)?.[1];
		const word = model.getWordUntilPosition(position);

		const range = {
			startLineNumber: position.lineNumber,
			endLineNumber: position.lineNumber,
			startColumn: word.startColumn,
			endColumn: word.endColumn,
		};

		if (tag) {
			suggestions.push({
				label: `</${tag}>`,
				kind: m.languages.CompletionItemKind.EnumMember,
				insertText: `</${tag}>`,
				range,
			});
		}

		return {
			suggestions: [
				...suggestions,
				{
					triggerCharacters: [""],
					label: "FunctorHistory",
					kind: m.languages.CompletionItemKind.Snippet,
					insertText: `<bean id="returnHistoryFunctor" class="eu.mdotm.coldwar.strategy.filters.functors.functorHistory.FunctorHistory">
	<property name="functor" ref="returnFunctor"/>
</bean>`,
					insertTextRules: m.languages.CompletionItemInsertTextRule.InsertAsSnippet,
					documentation: "FunctorHistory - Functor Description...",
					range,
				},
			],
		};
	},
};

export { prettify, configuration, provider, CompletionItemProvider };
