import type { InvestmentListEntry, RichTicker } from "$root/api/api-gen";
import { DefaultTagLabels, EntityEditorControllerApiFactory, type ReviewTicker } from "$root/api/api-gen";
import EditScoreButton from "$root/functional-areas/upload/Actions/EditScroreButton";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import colorGenerator from "$root/utils/chart/colorGenerator";
import type { BatchAction } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoTooltip,
	Banner,
	BatchActions,
	Button,
	Icon,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import BigNumber from "bignumber.js";
import { Map, Set } from "immutable";
import type { FC, ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompositionBuilder, type UseCompositionBuilderResult } from "../../universe/composition";
import { TagButton } from "../edit-tags";
import { useSearchableInstrumentTable as useSearchableInstrumentCollection } from "../hooks";
import type { EditorCompositionIntruments, EditorTableMode } from "./instrumentEditorColumns";
import { useInstrumentEditorColumns } from "./instrumentEditorColumns";
import { unpromisify } from "@mdotm/mdotui/utils";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useApiGen } from "$root/api/hooks";
import { parallelize } from "$root/utils/promise";
import { IconWalls } from "$root/components/IconWall";

enum RowTypeEnum {
	upload = "upload",
	add = "add",
	select = "select",
	cash = "cash",
}
export type rowType = keyof typeof RowTypeEnum;

type InstrumentEditorTableProps = {
	instruments?: EditorCompositionIntruments[];
	moneyMarket?: ReviewTicker;
	actionHeader?: NodeOrFn<{
		onResetInstruments(newInstruments: EditorCompositionIntruments[]): void;
		onAddInstruments(newInstruments: EditorCompositionIntruments[]): void;
		compositionBuilder: UseCompositionBuilderResult;
		uploadInstruments: EditorCompositionIntruments[];
		tagList?: Array<{ color: string; value: string }>;
		tagCrud: {
			add: (tag: string) => void;
			remove: (tag: string) => void;
			reset(tagList: string[]): void;
		};
	}>;
	children?: NodeOrFn<{
		compositionBuilder: UseCompositionBuilderResult;
		uploadInstruments: EditorCompositionIntruments[];
		rowsMultiSelectCtx: MultiSelectCtx<string>;
		instrumentNumber: number;
	}>;
	mode?: EditorTableMode; // TODO: is not really good
	entity: UploadEntity;
	enableHardDelete?: boolean;
	uuid?: string;
	onCompare?(selection: EditorCompositionIntruments[], action: "remove" | "add"): void;
	compareSelection: Map<string, InvestmentListEntry>;
	limit?: number;
};

// const disabledWallWrapperTitle: Record<UploadEntity, string> = {
// 	BENCHMARK: "benchmark",
// 	INVESTMENT: "portfolio",
// 	INVESTMENT_ENHANCEMENT: "portfolio",
// 	TARGET_INVESTMENT: "portfolio target",
// 	UNIVERSE: "universe",
// 	INVESTMENT_DRAFT: "portfolio",
// };

export const If: FC<{ condition: boolean; children?: ReactNode }> = (props) => {
	return props.condition ? <>{props.children}</> : <></>;
};

/**
 * to set current cashId: the table will check if the money market is in the current composition, if yes it will use his id has cash Id,
 * otherwise it will use the moneyMarket.ticker if present otherwise, it will use the hardcoded 'Moneymarket' id.
 *
 * currently there is no way to reset the current composition builder without passing the whole configuration, so each time there's a reset,
 * or new item is added to the uploadInstrument the compositionbuilder.reset(...conf) is called and each time the flow to set the cashId is repeated
 *
 * i know this is hard to track
 *
 * @param props
 * @returns
 */
const InstrumentEditorTable = (props: InstrumentEditorTableProps): JSX.Element => {
	const {
		children,
		actionHeader,
		mode,
		instruments,
		moneyMarket,
		entity,
		enableHardDelete,
		uuid,
		compareSelection,
		onCompare,
		limit,
	} = props;
	const [uploadInstruments, setUploadInstruments] = useState<EditorCompositionIntruments[]>(instruments ?? []);
	const [tags, setTags] = useState(
		(() => {
			const defaultLabels = Object.values(DefaultTagLabels) as string[];
			const labelsIncomposition = uploadInstruments.filter((x) => x.tagLabel).map((x) => x.tagLabel!);
			return Array.from(Set(defaultLabels.concat(labelsIncomposition))).map((tag, i) => ({
				value: tag as string,
				color: colorGenerator(i),
				deletable: defaultLabels.includes(tag) === false,
			}));
		})(),
	);

	const [exceededWeight, setExceededWeight] = useState<Map<string, Array<RichTicker>>>(Map());

	const { query, filtered, setQuery } = useSearchableInstrumentCollection(uploadInstruments);
	const { t } = useTranslation();
	const rowsMultiSelectCtx = useMultiSelect<string>();
	const compositionBuilder = useCompositionBuilder({
		composition: Map(uploadInstruments.map(({ id, weight }): [string, BigNumber] => [id, BigNumber(weight ?? 0)])),
		cashId:
			uploadInstruments.find((instrument) => instrument.ticker === moneyMarket?.ticker)?.id ?? moneyMarket?.ticker,
		identifiers: Map(
			uploadInstruments.filter(({ rowType }) => rowType === "add").map(({ id, alias }) => [id, alias ?? ""]),
		),
		tags: Map(uploadInstruments.filter(({ tagLabel }) => tagLabel).map(({ id, tagLabel }) => [id, tagLabel ?? ""])),
		scores: Map(
			uploadInstruments.map(({ id, score }): [string, BigNumber | null] => [
				id,
				score ? BigNumber(score.toFixed(2)) : null,
			]),
		),
	});

	const deleted = compositionBuilder.getDeleted();
	const getTotalWeight = compositionBuilder.getTotalWeight();
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows: uploadInstruments,
		multiSelectCtx: rowsMultiSelectCtx,
		selectBy: ({ id }) => id ?? "",
	});

	const existingIdentifiers = useMemo(() => {
		return Set(uploadInstruments.filter(({ id, alias }) => deleted.has(id) === false && alias).map((x) => x.alias));
	}, [uploadInstruments, deleted]);

	const tagCrud = useMemo(() => {
		function remove(tag: string) {
			const idsToDelete = compositionBuilder
				.getTags()
				.toArray()
				.filter(([_id, value]) => value === tag)
				.map(([id]) => id);
			compositionBuilder.deleteTags(idsToDelete);
			setTags((list) => {
				const cloned = [...list];
				const index = list.findIndex((x) => x.value === tag);
				cloned.splice(index, 1);
				return cloned;
			});
		}

		function reset(tagList: string[]) {
			const defaultLabels = Object.values(DefaultTagLabels) as string[];
			const newTagList = Array.from(Set(defaultLabels.concat(tagList))).map((tag, i) => ({
				value: tag as string,
				color: colorGenerator(i),
				deletable: defaultLabels.includes(tag) === false,
			}));
			setTags(newTagList);
		}

		function add(tag: string) {
			setTags((list) => [...list, { value: tag, deletable: true, color: colorGenerator(list.length) }]);
		}

		return { add, remove, reset };
	}, [compositionBuilder]);

	const onDelete = useCallback(
		(deleteId: Set<string>, instrument: EditorCompositionIntruments) => {
			if (!enableHardDelete) {
				return compositionBuilder.toggleDeleted(instrument.id ?? "-", deleteId.has(instrument.id ?? "-"));
			}
			rowsMultiSelectCtx.actions.remove(instrument.id);
			setUploadInstruments((prev) => {
				const latestInstruments = [...prev];
				const indexToDelete = latestInstruments.findIndex((x) => x.id === instrument.id);
				if (indexToDelete >= 0) {
					latestInstruments.splice(indexToDelete, 1);
					return latestInstruments;
				}
				return prev;
			});
			compositionBuilder.hardDeleteComposition([instrument.id]);
		},
		[compositionBuilder, enableHardDelete, rowsMultiSelectCtx.actions],
	);

	const onAddInstruments = useCallback(
		(newInstruments: Array<EditorCompositionIntruments>) => {
			compositionBuilder.reset({
				composition: Map(
					compositionBuilder
						.getComposition()
						.merge(newInstruments.map(({ id, weight }): [string, BigNumber] => [id, BigNumber(weight ?? 0)])),
				),
				cashId:
					uploadInstruments.find((instrument) => instrument.ticker === moneyMarket?.ticker)?.id ?? moneyMarket?.ticker,
				deleted: Set(compositionBuilder.getDeleted().toArray()),
				identifiers: Map(
					compositionBuilder
						.getIdentifiers()
						.merge(newInstruments.filter(({ rowType }) => rowType === "add").map(({ id, alias }) => [id, alias ?? ""])),
				),
				tags: Map(
					compositionBuilder
						.getTags()
						.merge(newInstruments.filter(({ tagLabel }) => tagLabel).map(({ id, tagLabel }) => [id, tagLabel ?? ""])),
				),
				scores: Map(
					compositionBuilder
						.getScores()
						.merge(
							newInstruments.map(({ id, score }): [string, BigNumber | null] => [
								id,
								score ? BigNumber(score.toFixed(2)) : null,
							]),
						),
				),
			});

			setUploadInstruments((prev) => [
				...newInstruments,
				...prev.map((instrument) => ({
					...instrument,
					weight: compositionBuilder.getWeight(instrument.id)?.toNumber() ?? 0,
				})),
			]);
		},
		[compositionBuilder, moneyMarket?.ticker, uploadInstruments],
	);

	const onResetInstruments = useCallback(
		(newInstruments: Array<EditorCompositionIntruments>) => {
			compositionBuilder.reset({
				composition: Map(newInstruments.map(({ id, weight }): [string, BigNumber] => [id, BigNumber(weight ?? 0)])),
				cashId:
					newInstruments.find((instrument) => instrument.ticker === moneyMarket?.ticker)?.id ?? moneyMarket?.ticker,
				deleted: Set(),
				identifiers: Map(
					newInstruments.filter(({ rowType }) => rowType === "add").map(({ id, alias }) => [id, alias ?? ""]),
				),
				tags: Map(newInstruments.filter(({ tagLabel }) => tagLabel).map(({ id, tagLabel }) => [id, tagLabel ?? ""])),
				scores: Map(
					newInstruments.map(({ id, score }): [string, BigNumber | null] => [
						id,
						score ? BigNumber(score.toFixed(2)) : null,
					]),
				),
			});
			const listOfTags = newInstruments.flatMap((x) => (x.tagLabel ? [x.tagLabel] : []));
			tagCrud.reset(listOfTags);
			setUploadInstruments(newInstruments);
			rowsMultiSelectCtx.actions.reset();
		},
		[compositionBuilder, moneyMarket?.ticker, tagCrud, rowsMultiSelectCtx],
	);

	const excessToCash = useCallback(() => {
		setUploadInstruments((prev) => {
			const isCompositionWithCash = prev.find((instrument) => instrument.ticker === moneyMarket?.ticker);

			if (isCompositionWithCash === undefined && moneyMarket?.ticker) {
				return [
					{
						id: moneyMarket?.ticker,
						instrument: "Money market",
						rowType: "select",
						alias: moneyMarket?.alias,
						weight: 0,
						assetClass: moneyMarket?.assetClass,
						microAssetClass: moneyMarket?.microAssetClass,
						ticker: moneyMarket?.ticker,
					},
					...prev,
				];
			}

			return prev;
		});

		compositionBuilder.excessToCash();
	}, [
		compositionBuilder,
		moneyMarket?.alias,
		moneyMarket?.assetClass,
		moneyMarket?.microAssetClass,
		moneyMarket?.ticker,
	]);

	const bulkDelete = useCallback(() => {
		const idsToDelete = rowsMultiSelectCtx.data.selection.toArray();

		if (enableHardDelete) {
			const newList = rowsMultiSelectCtx.data.selection.toArray().filter((x) => idsToDelete.includes(x) === false);
			rowsMultiSelectCtx.actions.setSelection(newList);
			setUploadInstruments((prev) => prev.filter((x) => idsToDelete.includes(x.id) === false));
			compositionBuilder.hardDeleteComposition(idsToDelete);
		} else {
			compositionBuilder.toggleDeleted(idsToDelete, true);
		}
	}, [compositionBuilder, enableHardDelete, rowsMultiSelectCtx.actions, rowsMultiSelectCtx.data.selection]);

	const bulkRestore = useCallback(() => {
		const idsToDelete = rowsMultiSelectCtx.data.selection.toArray();
		compositionBuilder.toggleDeleted(idsToDelete, false);
	}, [compositionBuilder, rowsMultiSelectCtx.data.selection]);

	const bulkEditTags = useCallback(
		(selectedTag: string) => {
			const tagsToUpdate = rowsMultiSelectCtx.data.selection.toArray().map((v): [string, string] => [v, selectedTag]);
			compositionBuilder.multiTagsUpdate(tagsToUpdate);
		},
		[compositionBuilder, rowsMultiSelectCtx.data.selection],
	);

	const bulkEditScore = useCallback(
		(score: number | null) => {
			const scoresToUpdate = rowsMultiSelectCtx.data.selection
				.toArray()
				.map((v): [string, BigNumber | null] => [v, score ? BigNumber(score) : null]);
			compositionBuilder.multiScoresUpdate(scoresToUpdate);
		},
		[compositionBuilder, rowsMultiSelectCtx.data.selection],
	);
	const willPortfolioCompositionBeShrinked = useCallback(
		async (id: string, ptfUuid?: string, weight?: number) => {
			try {
				if (ptfUuid === undefined) {
					throw Error("missing uuid");
				}
				if (weight === undefined || weight < 0.01) {
					throw Error("weight too low");
				}
				const { removedTickers } = await axiosExtract(editorApi.verifyEditorPortfolio(ptfUuid, weight, entity));

				if (removedTickers) {
					setExceededWeight((map) => map.set(id, removedTickers));
				}
			} catch (error) {
				console.error(error);
			}
		},
		[editorApi, entity],
	);

	const selectedRow = useMemo(() => {
		const compositionMap = Map(uploadInstruments.map((x) => [x.id, x]));
		return rowsMultiSelectCtx.data.selection.toArray().flatMap((id) => {
			const entry = compositionMap.get(id);
			return entry ? [entry] : [];
		});
	}, [rowsMultiSelectCtx.data.selection, uploadInstruments]);

	const instrumentNumber = useMemo(
		() =>
			uploadInstruments.reduce((acc, inst) => {
				if (deleted.has(inst.id)) {
					return acc;
				}

				if (inst.proxyOverwriteType === "PORTFOLIO_MIXED") {
					return (acc += inst.investment?.nofInstruments ?? 0);
				}

				return (acc += 1);
			}, 0),
		[deleted, uploadInstruments.length], // re-trigger
	);

	const columns = useInstrumentEditorColumns({
		compositionBuilder,
		entity,
		existingIdentifiers,
		existingTags: tags,
		mode,
		uuid,
		onDelete,
		compareSelection,
		instrumentNumber,
		onCompare,
		exceededWeight,
		onExceededWeight: (id, removedTickers) => {
			if (removedTickers) {
				setExceededWeight((map) => map.set(id, removedTickers));
			}
		},
	});

	const compareStatus = useMemo(() => {
		const areAllPortfolioSelected = rowsMultiSelectCtx.data.selection.toArray().every((id) => compareSelection.has(id));
		if (areAllPortfolioSelected) {
			return "ALL_SELECTED";
		}
		const noPortfolioSelected = selectedRow.some((x) => x.proxyOverwriteType !== "PORTFOLIO_MIXED");
		if (noPortfolioSelected) {
			return "DIRTY_SELECTION";
		}

		return undefined;
	}, [compareSelection, rowsMultiSelectCtx.data.selection, selectedRow]);

	return (
		<div>
			<div className="flex justify-between mb-4">
				<div className="max-w-[500px] flex-1">
					<TextInput
						leftContent={<Icon icon="Search" />}
						value={query}
						onChangeText={setQuery}
						placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
					/>
				</div>
				<div>
					{renderNodeOrFn(actionHeader, {
						onAddInstruments,
						onResetInstruments,
						compositionBuilder,
						uploadInstruments,
						tagList: tags,
						tagCrud,
					})}
				</div>
			</div>

			<div className="mb-4">
				<div className="flex gap-4 my-2 h-6">
					<BatchActions
						selected={rowsMultiSelectCtx.data.selection.size}
						total={uploadInstruments.length}
						actions={[
							{
								label: "Delete",
								icon: "Delete",
								onClick: bulkDelete,
							},
							...(!enableHardDelete
								? [
										{
											label: "Restore",
											icon: "restore",
											onClick: bulkRestore,
										} satisfies BatchAction,
								  ]
								: []),
							...(entity === "INVESTMENT" || entity === "INVESTMENT_ENHANCEMENT" || entity === "INVESTMENT_DRAFT"
								? [
										{
											label: "Compare",
											icon: "compare",
											disabled: compareStatus !== undefined,
											onClick: () => props.onCompare?.(selectedRow, "add"),
											tooltip: {
												children:
													compareStatus === "ALL_SELECTED"
														? "All portfolio are being compared, please add new one"
														: compareStatus === "DIRTY_SELECTION"
														  ? "Please select only valid portfolio"
														  : "",
												overrideColor: themeCSSVars.palette_N300,
												disabled: compareStatus === undefined,
											},
										} satisfies BatchAction,
								  ]
								: []),
						]}
					/>

					{entity === "UNIVERSE" && rowsMultiSelectCtx.data.selection.size ? (
						<>
							<TagButton
								options={tags}
								onClick={bulkEditTags}
								value={null}
								enableDebounce
								trigger={({ innerRef, handleFloatingStatus }) => (
									<Button
										palette="tertiary"
										size="x-small"
										onClick={rowsMultiSelectCtx.data.selection.size === 0 ? console.log : handleFloatingStatus}
										disabled={rowsMultiSelectCtx.data.selection.size === 0}
										innerRef={innerRef}
									>
										<Icon icon="Edit" size={20} />
										&nbsp; Edit Tags
									</Button>
								)}
							/>

							<Button
								palette="tertiary"
								size="x-small"
								onClick={() => bulkEditTags("")}
								disabled={rowsMultiSelectCtx.data.selection.size === 0}
							>
								<Icon icon="restore" size={20} />
								&nbsp; Restore Tags
							</Button>

							<EditScoreButton disabled={rowsMultiSelectCtx.data.selection.size === 0} onApply={bulkEditScore} />
						</>
					) : (
						<></>
					)}
				</div>
			</div>

			{exceededWeight.size > 0 &&
				exceededWeight.some((x) => x.length > 0) &&
				(entity === "INVESTMENT" || entity === "INVESTMENT_ENHANCEMENT") && (
					<Banner
						severity="info"
						title="Instruments with a normalised weight <0.01% will be excluded from the new portfolio."
						classList="mb-4"
					>
						The new portfolio contains instruments with a normalised weight lower than 0.01%. Due to the low weight,
						these instruments have become irrelevant and will be excluded. If you wish to include all instruments, you
						can add them manually or increase the weight of the highlighted portfolio or instruments here below.
					</Banner>
				)}

			<Table
				palette="uniform"
				rows={filtered}
				columns={[checkBoxColumn, ...columns]}
				visibleRows={Math.min(filtered.length, 12)}
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassList(row, rowIndex)),
					CompositionEditorTableRow: true,
				})}
				enableVirtualScroll={filtered.length > 300}
				noDataText={
					<div className="h-[60dvh] w-full">
						<IconWalls.EditorEmptyData entity={entity} />
					</div>
				}
				rowStyle={({ ticker, id }) => ({
					backgroundColor:
						(exceededWeight?.get(id) ?? []).length > 0
							? themeCSSVars.global_palette_warning_100
							: deleted.has(ticker ?? "-")
							  ? themeCSSVars.Table_highlightedRowBackgroundColor
							  : undefined,
				})}
				onRowClick={({ id }) => toggle(id)}
			/>
			{limit && instrumentNumber > limit && (entity === "INVESTMENT" || entity === "INVESTMENT_ENHANCEMENT") && (
				<Banner severity="warning" title="Instrument limit reached" classList="mb-4">
					You have exceeded the maximum number of instruments allowed for your portfolio ({">"}{" "}
					<strong>{limit ?? 0}</strong>). Please remove instruments or one or more portfolios from your mixed portfolio
					to stay within this limit of {limit} instruments.
				</Banner>
			)}

			<If condition={!getTotalWeight.eq(100) && filtered.length > 0 && entity !== "UNIVERSE"}>
				<Banner
					title={
						getTotalWeight.lt(100)
							? t("EDIT_COMPOSITION.NORMALIZE_BOX.LESS_THAN_100.TITLE")
							: t("EDIT_COMPOSITION.NORMALIZE_BOX.MORE_THAN_100.TITLE")
					}
					severity="warning"
				>
					<span>
						Select at least one instrument and click&nbsp;
						<AutoTooltip
							severity="warning"
							disabled={rowsMultiSelectCtx.data.selection.size > 0}
							trigger={({ innerRef }) => (
								<ActionText
									classList="cursor-pointer"
									innerRef={innerRef}
									disabled={rowsMultiSelectCtx.data.selection.size === 0}
									onClick={unpromisify(async () => {
										if (rowsMultiSelectCtx.data.selection.size > 0) {
											compositionBuilder.normalise(rowsMultiSelectCtx.data.selection);

											const portfolios = selectedRow.filter((x) => x.proxyOverwriteType === "PORTFOLIO_MIXED");
											if (portfolios.length > 0) {
												await parallelize(
													portfolios.map(
														(x) => () =>
															willPortfolioCompositionBeShrinked(
																x.id,
																x.investment?.uuid,
																compositionBuilder.getWeight(x.id)?.toNumber(),
															),
													),
												);
											}
										}
									})}
								>
									Normalise.
								</ActionText>
							)}
						>
							Select at least one instrument
						</AutoTooltip>
						&nbsp;
						{getTotalWeight.lt(100)
							? "Sphere will proportionally add weights to the selected instruments."
							: "Sphere will proportionally remove weights to the selected instruments."}
					</span>
					<p>
						Otherwise&nbsp;
						<ActionText onClick={excessToCash} disabled={moneyMarket === undefined || moneyMarket.ticker === undefined}>
							Excess to Cash
						</ActionText>
					</p>
				</Banner>
			</If>

			<div>
				{renderNodeOrFn(children, { compositionBuilder, uploadInstruments, rowsMultiSelectCtx, instrumentNumber })}
			</div>
		</div>
	);
};

export default InstrumentEditorTable;
