import { useDebouncedMemo } from "$root/utils/react-extra";
import { Button, FloatingContent, Icon, ProgressBar, TextInput } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { MouseEvent} from "react";
import { forwardRef, useMemo, useState } from "react";

export type ReadOnlyTagOptions = Array<{ value: string; color: string }>;

type TagButtonProps = {
	color?: string;
	options: ReadOnlyTagOptions;
	enableDebounce?: boolean;
	disabled?: boolean;
	value: string | null;
	onClick?(option: string, e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void;
	trigger?: NodeOrFn<{ innerRef: (instance: HTMLElement | null) => void; handleFloatingStatus: () => void }>;
};
const ReadyOnlyButton = forwardRef<
	HTMLButtonElement,
	{ value: string | null; color?: string; disabled?: boolean; onClick?(): void; onRemove?(): void }
>(function _ReadyOnlyButton({ value, color, disabled, onClick, onRemove }, ref) {
	if (value) {
		return (
			<div
				className="rounded-xl px-2 py-0.5 flex gap-2 text-white bg-neutral-300 "
				style={{ backgroundColor: disabled ? themeCSSVars.palette_N200 : color }}
			>
				<Button unstyled innerRef={ref} onClick={onClick} classList="font-semibold truncate" disabled={disabled}>
					{value}
				</Button>
				<Button unstyled onClick={onRemove} disabled={disabled} classList="active:scale-90">
					<Icon icon="Close" size={18} />
				</Button>
			</div>
		);
	}

	return (
		<Button
			unstyled
			classList={toClassName({
				"rounded-xl border-2 bg-white px-2 py-0.5 flex gap-2": true,
				[`!bg-[color:${themeCSSVars.palette_N200}] border-none`]: disabled,
			})}
			innerRef={ref}
			onClick={onClick}
			disabled={disabled}
		>
			<span
				className={toClassName({
					[`font-semibold text-[color:${themeCSSVars.palette_N600}]`]: true,
					[`!text-[color:${themeCSSVars.palette_N20}]`]: disabled,
				})}
			>
				Assign Tag
			</span>
			<Icon icon="Outline1" color={disabled ? themeCSSVars.palette_N20 : themeCSSVars.palette_N600} size={18} />
		</Button>
	);
});

export function TagButton(props: TagButtonProps): JSX.Element {
	const { options, color, enableDebounce, disabled, trigger } = props;

	const [isOpen, setIsOpen] = useState(false);
	const [searchedTag, setSearchedTag] = useState("");

	const { value: debouncedSearch, state } = useDebouncedMemo(() => searchedTag, [searchedTag], {
		debounceInterval: enableDebounce ? 800 : 0,
	});

	const filteredTags = useMemo(() => {
		return options.filter((option) => option.value.toLowerCase().includes(debouncedSearch.toLowerCase()));
	}, [options, debouncedSearch]);

	const handleFloatingStatus = () => setIsOpen(!isOpen);

	return (
		<FloatingContent
			open={isOpen}
			position="bottom"
			onClickAway={handleFloatingStatus}
			trigger={({ innerRef }) => (
				<>
					{trigger ? (
						renderNodeOrFn(trigger, { innerRef, handleFloatingStatus })
					) : (
						<ReadyOnlyButton
							value={props.value}
							color={color}
							onClick={handleFloatingStatus}
							onRemove={() => props.onClick?.("")}
							ref={innerRef}
							disabled={disabled}
						/>
					)}
				</>
			)}
		>
			<div className="mt-2 pb-2 bg-white rounded-lg shadow-xl border-2 w-48">
				<div className="sticky top-0">
					<TextInput
						leftContent={<Icon icon="Search" size={16} />}
						onChangeText={setSearchedTag}
						placeholder="Search for a tag.."
						size="small"
						value={searchedTag}
						classList="[&>input]:border-none [&>input]:h-12 flex-1"
					/>
					<hr />
					{state === "debouncing" && <ProgressBar value="indeterminate" />}
				</div>
				<div className="overflow-y-auto custom-scrollbar-y max-h-48">
					{filteredTags.length === 0 && (
						<div className="h-10 flex items-center hover:bg-slate-100 px-4">
							<i>No tag found</i>
						</div>
					)}

					{filteredTags.map((tags, i) => {
						return (
							<div className="h-10 flex items-center hover:bg-slate-100 px-4" key={i}>
								<Button
									unstyled
									classList="rounded-full h-fit px-4 py-0.5 text-white font-semibold transition-transform active:scale-90"
									style={{ backgroundColor: tags.color }}
									onClick={(e) => {
										props.onClick?.(tags.value, e);
										handleFloatingStatus();
									}}
								>
									{tags.value}
								</Button>
							</div>
						);
					})}
				</div>
			</div>
		</FloatingContent>
	);
}
