import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { AutoTooltip, Checkbox, NullableNumberInput, TooltipContent } from "@mdotm/mdotui/components";
import { useCallback, useEffect, useMemo, useState, type ReactNode } from "react";
import { type Control, type FormState, type Path } from "react-hook-form";
import type { NotificationMap } from "../map";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

type NotificationCheckboxProps = {
	children: string;
	value: boolean;
	control: Control<NotificationMap>;
	formState: FormState<NotificationMap>;
	name: Path<NotificationMap>;
	threshold?: { name: Path<NotificationMap> };
};

type NotificationBoxProps = {
	infoGraph: ReactNode;
	children?: ReactNode;
	label: string;
	onChange(newValue: boolean): void;
	checkboxes: Array<NotificationCheckboxProps>;
};

export function MultipleNotificationBox(props: NotificationBoxProps): JSX.Element {
	const [mainCheckState, setMainCheckState] = useState<boolean | "indeterminate">(false);

	const memoCheckboxes = useMemo(() => {
		const checkboxStatus = props.checkboxes.map(({ value }) => value);
		const checkboxStatusSet = new Set(checkboxStatus);
		return checkboxStatusSet.size === 1 ? Array.from(checkboxStatusSet.values())[0] : "indeterminate";
	}, [props.checkboxes]);

	const onChangeMainCheckState = useCallback(
		(newValue: boolean) => {
			props.onChange(newValue);
			setMainCheckState(newValue);
		},
		[props],
	);

	useEffect(() => {
		setMainCheckState(memoCheckboxes);
	}, [memoCheckboxes]);

	return (
		<div className="flex gap-4">
			<div>{props.infoGraph}</div>
			<div>
				<Checkbox classList="text-base mb-2 font-semibold" checked={mainCheckState} onChange={onChangeMainCheckState}>
					{props.label}
				</Checkbox>
				<div className="ml-8">
					<div>{props.children}</div>
					{props.checkboxes?.map((checkboxProps) => (
						<div className="flex items-center gap-2 mb-2" key={checkboxProps.name}>
							<FormFields.Checkbox classList="text-sm" {...checkboxProps} switchType="box" />
							{checkboxProps.threshold && (
								<FormController
									control={checkboxProps.control}
									name={checkboxProps.threshold.name}
									render={({ field: { value, onChange, name, onBlur }, fieldState }) => (
										<AutoTooltip
											position="top"
											severity="error"
											disabled={fieldState.error?.message === undefined}
											trigger={({ innerRef }) => (
												<NullableNumberInput
													innerRef={innerRef}
													rightContent="%"
													name={name}
													onBlur={onBlur}
													value={typeof value === "number" ? value : null}
													onChange={onChange}
													classList={toClassName({
														"w-16": true,
														[`[&>input]:border-[color:${themeCSSVars.palette_D500}]`]:
															fieldState.error?.message !== undefined,
													})}
													size="x-small"
													step={0.01}
													max={100}
													min={0}
													disabled={value === undefined}
												/>
											)}
										>
											<TooltipContent>
												<p>{fieldState.error?.message}</p>
											</TooltipContent>
										</AutoTooltip>
									)}
								/>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
