export function getQueryParam(searchString: string, key: string): string | undefined {
	const query = searchString.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split("=");
		// eslint-disable-next-line eqeqeq
		if (decodeURIComponent(pair[0]) == key) {
			return decodeURIComponent(pair[1]);
		}
	}
	return undefined;
}
