import { useLocaleFormatters } from "$root/localization/hooks";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { exposureCompositionListRowHeight } from "./exposure-shared";

const CompositionList = ({
	availableHeight,
	list,
}: {
	availableHeight: number;
	list: Array<
		| { type: "title"; label: string; groupName: string }
		| { type: "entry"; label: string; weight: number; groupName: string; netLong: boolean }
		| { type: "footer"; label: string; weight: number; groupName: string }
	>;
}): JSX.Element => {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div
			className="columns-3 px-8"
			style={{
				columnFill: "auto",
				height: availableHeight, // Math.ceil(list.length / exposureCompositionListItemsPerRow) * exposureCompositionListRowHeight,
			}}
		>
			{list.map((row, i) => (
				<div className="flex" key={`exposure-singleColumn-item-key-${i}`}>
					<div className="pr-2 rounded-lg shrink-0">
						<div className="w-1 h-full" style={{ backgroundColor: stableColorGenerator(row.groupName) }} />
					</div>

					<div
						className={toClassName({
							"flex border-b border-zinc-500 items-center justify-between flex-1 overflow-hidden": true,
							"font-semibold uppercase": row.type === "title" || row.type === "footer",
						})}
						style={{ height: exposureCompositionListRowHeight }}
					>
						<span className="whitespace-nowrap text-ellipsis w-4/5 overflow-hidden">
							{row.label}

							{row.type === "entry" && row.netLong === false && (
								<>
									&nbsp;-&nbsp;[&nbsp;
									<svg
										className="inline w-[8px] mb-1"
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect opacity="0.3" width="8" height="8" rx="4" fill={stableColorGenerator(row.groupName)} />
									</svg>
									&nbsp;
									<span className="truncate">Net Short</span>&nbsp;]
								</>
							)}
						</span>
						{(row.type === "entry" || row.type === "footer") && (
							<span className="grow">{formatNumber(row.weight)}%</span>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default CompositionList;
