import type { DialogProps } from "@mdotm/mdotui/components";
import { Button, Dialog, Text } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import UnindentifiedFlyingObjectBox from "./boxes/UnindentifiedFlyingObjectBox";

type AccessDialogProps = {
	onClick(onClose: () => void): void;
	onClose(): void;
	show: boolean;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};
const AccessDialog = (props: AccessDialogProps) => {
	const { show, onClose, onClick, onAnimationStateChange } = props;
	return (
		<Dialog size="large" show={show} onAnimationStateChange={onAnimationStateChange} onClose={onClose}>
			<div className="text-center">
				<div className="mb-4">
					<UnindentifiedFlyingObjectBox className="m-auto" />
				</div>
				<Text type="Body/L/Bold" as="h3" classList="mb-w">
					Sorry, you do not have access to the item.
				</Text>
				<Text type="Body/M/Book" as="p" classList="mb-4">
					It may have been removed or access permissions may have changed.
				</Text>
				<Button palette="primary" onClick={() => onClick(onClose)} classList="mb-4" size="small">
					Back to portfolio studio
				</Button>
				<Text type="Body/S/Book" as="p">
					In case you need additional information, please contact{" "}
					<a
						style={{ color: "black" }}
						href="mailto:support@mdotm.ai?subject=Additional%20Information&body=Dear%20MDOTM%20Team,%20I%E2%80%99d%20like%20to%20have%20more%20information%20on%20the%20current%20platform%20outage.%20Thank%20you"
					>
						support@mdotm.ai
					</a>
				</Text>
			</div>
		</Dialog>
	);
};

type SpawnAccessDialogProps = Omit<AccessDialogProps, "show" | "onClose">;
export function spawnAccessDialog(props: SpawnAccessDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<AccessDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
			/>
		)),
	);
}
