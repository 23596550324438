import { Button, Icon, ScrollWrapper, Text } from "@mdotm/mdotui/components";
import { SuggestedQuestionBox } from "./boxes/SuggestedQuestionBox";
import { SuggestionSectionIcon } from "./icons/SuggestionSectionIcon";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { FeedbackSectionIcon } from "./icons/FeedbackSectionIcon";
import { BetaIcon } from "./icons/BetaIcon";

type SphereAISuggestionOverlayContentProps = {
	showBetaDisclaimer?: boolean;
	suggestedQuestions: Array<string>;
	onCopy?(message?: string): void;
	onClose?(): void;
};

export function SphereAISuggestionOverlayContent(props: SphereAISuggestionOverlayContentProps): JSX.Element {
	return (
		<div className="h-full w-full flex flex-col bg-white relative">
			{props.onClose && (
				<div className="absolute top-4 right-4 z-20">
					<Button unstyled onClick={props.onClose} classList="border-2 rounded-md hover:border-black ml-auto">
						<Icon icon="Close" color={themeCSSVars.palette_N700} size={18} />
					</Button>
				</div>
			)}
			<ScrollWrapper classList="flex-1">
				{props.showBetaDisclaimer && (
					<div>
						<div className="p-4">
							<div className={`p-4 rounded-lg space-y-2 bg-[${themeCSSVars.palette_graph_B500}]`}>
								<div className="flex items-start">
									<div className="pr-2">
										<BetaIcon />
									</div>
									<div>
										<Text as="div" color="white" type="Body/M/Bold">
											Welcome!
										</Text>
										<Text as="div" color="white" type="Body/M/Book">
											Ask Sphere AI is a new feature that you can try in{" "}
											<Text color="white" type="Body/M/Bold">
												Beta testing.
											</Text>
										</Text>
									</div>
								</div>
								<div className="flex items-start">
									<div className="pr-2">
										<FeedbackSectionIcon />
									</div>
									<div>
										<Text as="div" color="white" type="Body/M/Book">
											<Text color="white" type="Body/M/Bold">
												Your input
											</Text>{" "}
											will be invaluable to us in{" "}
											<Text color="white" type="Body/M/Bold">
												building the future version
											</Text>
										</Text>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div
					className={`flex justify-between pt-2 pl-4 pr-1 items-center border-t border-[${themeCSSVars.palette_N200}]`}
				>
					<div>
						<Text as="div" type="Body/L/Bold">
							Questions
						</Text>
						<Text as="div" type="Body/L/Book">
							For now, I can help you with these insights
						</Text>
					</div>
					<div>
						<SuggestionSectionIcon />
					</div>
				</div>

				<div className="space-y-4 pt-3 pb-4">
					{props.suggestedQuestions.map((text, i) => {
						return <SuggestedQuestionBox message={text} key={i} onClick={() => props.onCopy?.(text)} />;
					})}
					{props.suggestedQuestions.length === 0 && (
						<Text type="Body/L/Book" as="div" classList="px-2 mx-4 py-1.5">
							{`"Sorry, no suggestion is available at the moment. Please try again later."`}
						</Text>
					)}
				</div>
			</ScrollWrapper>
		</div>
	);
}
