import { useUserStore } from "$root/functional-areas/user";
import type { AxiosInstance } from "axios";
import { Configuration } from "./api-gen";
import { preconfiguredAxiosInstance } from "./config";
import env from "$root/env";

export type ControllerFactory<T> = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T;

/**
 * Example:
 * ```tsx
 * const portfolioApi = makeAuthController({ controllerFactory: InvestmentsControllerApiFactory });
 * ```
 */
export function makeAuthController<T>({
	controllerFactory,
	axiosInstance,
	token,
}: {
	controllerFactory: ControllerFactory<T>;
	axiosInstance?: AxiosInstance;
	token?: string;
}): T {
	return controllerFactory(
		new Configuration({
			// For some reason this doesn't work after login (until page reload),
			// but explicitly setting the header (see below) does the trick.
			// accessToken: token,
			baseOptions: !token
				? undefined
				: {
						headers: {
							Authorization: "Bearer " + token,
						},
				  },
			basePath: env.apiOrigin,
		}),
		undefined,
		axiosInstance ?? preconfiguredAxiosInstance,
	);
}

/**
 * Like {@link useApiGen}, but it's not a hook, so it can be called anywhere in the code.
 *
 * Example:
 * ```tsx
 * const portfolioApi = getApiGen(InvestmentsControllerApiFactory);
 * ```
 */
export function getApiGen<T>(controllerFactory: ControllerFactory<T>, axiosInstance?: AxiosInstance): T {
	const user = useUserStore.getState().value;
	const api = makeAuthController({ controllerFactory, axiosInstance, token: user.token });
	return api;
}
