import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import type { FC } from "react";

const LineIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: "3px",
		height: "32px",
		fill: "none",
		stroke: "#A0A7B6",
	},
	...props
}) => {
	return (
		<Svg strokeWidth="2" strokeLinejoin="round" style={style} viewBox={{ width: 3, height: 32 }} {...props}>
			<path d="M1.5 32V0" />
		</Svg>
	);
};

export default LineIcon;
