import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "@mdotm/mdotui/react-extensions";
import { withContext } from "@mdotm/mdotui/react-extensions";
import type { FC } from "react";

const _EditUniverseComposition: FC<ContextContent<typeof UniverseContext>> = (props) => {
	const { universe } = props;

	useWidgetOptions(() => ({ title: "", actionHeader: <></> }), []);

	return <EditCompositionSection uploadEntity="UNIVERSE" uuid={universe?.uuid} />;
};

export const EditUniverseComposition = withContext(UniverseContext)(_EditUniverseComposition);
