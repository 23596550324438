import { ClickableArea } from "@mdotm/mdotui/components";
import { QuotedQuestionBox } from "./QuotedQuestionBox";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type SuggestedQuestionBoxProps = {
	message: string;
	onClick?(): void;
};

export function SuggestedQuestionBox({ message, onClick }: SuggestedQuestionBoxProps): JSX.Element {
	return (
		<ClickableArea
			wrapperAppearance={{
				classList: {
					[`[&>*>*]:hover:bg-[${themeCSSVars.palette_B50}]`]: true,
				},
			}}
			onClick={onClick}
		>
			<QuotedQuestionBox>
				<div style={{ wordBreak: "break-word" }}>{`“${message}”`}</div>
			</QuotedQuestionBox>
		</ClickableArea>
	);
}
