import type {
	ForecastHorizons,
	InvestmentSummary,
	MarketScenario,
	MarketViewMicroAssetClasses,
	PerformanceContributionEntry,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	InvestmentsEnhancementStaticConfigurationControllerApiFactory,
	InvestmentsEnhancementStaticConfigurationControllerApiFp,
	InvestmentsStaticConfigurationControllerApiFactory,
	InvestmentsStaticConfigurationControllerApiFp,
	MarketViewControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import type { MarketViewProps } from "$root/components/Portfolio/MarketView";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import EllipsisText from "$root/ui-lib/ellipsisText";
import { builtInSortFnFor } from "$root/utils/collections";
import { useSize } from "$root/utils/react-dom-extra";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import { ProgressBar, Table, Text } from "@mdotm/mdotui/components";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	applyDeltaToSelectedMarketView,
	removeDeltaFromSelectedMarketView,
	round2Dec,
	roundCustom,
} from "../../../components/Portfolio/MarketView/utilsV2";
import { BarGraph } from "./BarGraph";
import { MarketViewProbabilities } from "$root/functional-areas/market-view/MarketViewProbabilities";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Map } from "immutable";
import { assetClassRetrocompatibilityMap } from "./retroCompatibility";
import { marketViewMap } from "$root/components/Portfolio/MarketView/mapV2";
import { useLocaleFormatters } from "$root/localization/hooks";
import { IconWalls } from "$root/components/IconWall";

type portfolioPerformanceColumnProps = {
	metrics: string;
	portfolio: string;
	benchmark: string;
	graph: string;
};

type portfolioPerformanceContributionColumnProps = {
	portfolioReturns: number;
	portfolioExposure: number;
	benchmarkReturns: number;
	assetClass: string;
};

type ScenarioCategoryProps = {
	value: string;
	label: string;
	group: "Sphere forecasts" | "My views" | "Historical scenarios" | "";
	scenarioType: MarketViewProps["scenario"];
	forecastHorizon?: ForecastHorizons;
	enhancable: boolean;
	custom?: boolean;
};

const EXPECTED_PTF_PERFORMANCE_LEGENDS = [
	{ name: "Portfolio", color: "#00AEEF" },
	{ name: "Benchmark", color: "#D1D2DC" },
];
const EXPECTED_PTF_PERFORMANCE_CONTRIBUTION_LEGENDS = [
	{ name: "Portfolio returns", color: "#00AEEF" },
	{ name: "Benchmark returns", color: "#D1D2DC" },
];

const ScenarioAnalysisEditableContentMemo = memo<{
	sphereScenario: MarketScenario;
	summary: InvestmentSummary;
	benchmarkId: string | null;
	performanceContributions: PerformanceContributionEntry[];
	enhanced: boolean;
	registerStartEnhanceCallback:
		| ((onStartEnhance: () => void, onStartMarketViewEnhance: () => Promise<void>) => void)
		| undefined;
}>(function ScenarioAnalysisEditableContent({
	sphereScenario,
	summary,
	benchmarkId,
	performanceContributions,
	enhanced,
}) {
	const [size, setSize] = useState<HTMLDivElement | null>(null);
	const portfolioContributionPerformanceRowSize = useSize(size)?.width;
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhanceReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const investmentsEnhancementStaticConfigurationApi = useApiGen(
		InvestmentsEnhancementStaticConfigurationControllerApiFactory,
	);
	const InvestmentsStaticConfigurationApi = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);

	const showContributionGraph = useMemo(() => {
		if (!portfolioContributionPerformanceRowSize) {
			return true;
		}
		const percentOfGraphWidth = 20;
		const graphColumnWidth = (portfolioContributionPerformanceRowSize * percentOfGraphWidth) / 100;
		return graphColumnWidth > 110;
	}, [portfolioContributionPerformanceRowSize]);

	const { uuid } = summary ?? {};
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const [currentScenarioId, setCurrentScenarioId] = useState<string | null>("SPHERE_FORECAST_ONE_MONTH");
	const [originalScenario, setOriginalScenario] = useState<MarketScenario>(sphereScenario);
	const [marketScenario, setMarketScenario] = useState<MarketScenario>(sphereScenario);

	// Get Options List
	const { data: scenarioAnalysisList } = useQueryNoRefetch(["initScenarioEditable"], {
		enabled: Boolean(uuid),
		queryFn: async () => {
			const { data } = await marketViewApi.getMarketViewScenarioList(
				true,
				uuid,
				enhanced,
				"EXPECTED_RETURNS_VOLATILITY",
			);
			return data;
		},
		onError: (err) => {
			console.error(err);
		},
		onSuccess: (_data) => {
			const defaultScenario = "SPHERE_FORECAST_ONE_MONTH";
			setCurrentScenarioId(defaultScenario);
		},
	});

	const { data: marketViewSettings } = useQueryNoRefetch(["queryMarkseScenarioEditableTemplateConfig"], {
		queryFn: async () => ({
			setting: await axiosExtract(marketViewApi.getMarketViewUserSettings()),
			alias: await axiosExtract(portfolioStudioPreferenceApi.getMarketViewAssetClassAliases()),
		}),
	});

	const { formatDate } = useLocaleFormatters();

	const scenarioOptions = useMemo(() => {
		if (!scenarioAnalysisList || !scenarioAnalysisList) {
			return [];
		}
		const composeAcc = (
			value: string,
			label: string,
			group: ScenarioCategoryProps["group"],
			scenarioType: ScenarioCategoryProps["scenarioType"],
			enhancable: boolean,
			forecastHorizon?: ForecastHorizons,
			custom?: boolean,
		) => ({ value, label, group, enhancable, forecastHorizon, scenarioType, custom });

		const { custom, historical, standard } = scenarioAnalysisList.reduce(
			(acc, el) => {
				const { historical: historicalAcc, standard: standardAcc, custom: customAcc } = acc;
				const { id, label, userCustomScenario, forecastHorizon } = el;

				if ((id ?? "").includes("CURRENT_PORTFOLIO") || (id ?? "").includes("SPHERE_FORECAST")) {
					const newLabel =
						id === "CURRENT_PORTFOLIO"
							? `Market view ${formatDate(marketScenario?.historicalInvestmentHorizon?.from)}`
							: label ?? "-";
					const currPtf = composeAcc(
						id ?? "-",
						newLabel,
						"",
						id === "CURRENT_PORTFOLIO" ? "custom" : "sphere",
						(id ?? "").includes("SPHERE_FORECAST"),
						forecastHorizon,
					);
					return { historical: historicalAcc, custom: customAcc, standard: [...standardAcc, currPtf] };
				}

				if (userCustomScenario) {
					return {
						standard: standardAcc,
						historical: historicalAcc,
						custom: [
							...customAcc,
							composeAcc(id ?? "-", label ?? "-", "My views", "custom", true, forecastHorizon, el.customMarketView),
						],
					};
				}

				return {
					standard: standardAcc,
					custom: customAcc,
					historical: [
						...historicalAcc,
						composeAcc(id ?? "-", label ?? "-", "Historical scenarios", "historical", false, forecastHorizon),
					],
				};
			},
			{
				historical: [] as Array<ScenarioCategoryProps>,
				standard: [] as Array<ScenarioCategoryProps>,
				custom: [] as Array<ScenarioCategoryProps>,
			},
		);

		const sortHistorycalRegimes = (a: ScenarioCategoryProps, b: ScenarioCategoryProps) => {
			const rgxHelper = (s: string) => {
				const rgx = new RegExp(/[0-9]{4}/);
				const matches = rgx.exec(s);
				return matches ? matches[0] : "";
			};

			const valueA = rgxHelper(a.label);
			const valueB = rgxHelper(b.label);

			if (valueA > valueB) {
				return 1;
			}
			if (valueA < valueB) {
				return -1;
			}
			return 0;
		};

		return standard.concat(custom.sort(builtInSortFnFor("value"))).concat(historical.sort(sortHistorycalRegimes));
	}, [formatDate, marketScenario?.historicalInvestmentHorizon?.from, scenarioAnalysisList]);

	const compositionMetricsQueryFn = useCallback(async () => {
		if (!summary.uuid || !benchmarkId) {
			throw new Error("missing portfolio");
		}

		if (enhanced) {
			const { data } = await investmentEnhanceReportApi.getPortfolioExAnteMetrics1(summary.uuid, benchmarkId);
			return data;
		}

		const { data } = await investmentReportApi.getPortfolioExAnteMetrics(summary.uuid, benchmarkId);
		return data;
	}, [benchmarkId, enhanced, investmentEnhanceReportApi, investmentReportApi, summary.uuid]);

	const { data: compositionMetrics } = useQueryNoRefetch(
		["GetCompositionMetrics", currentScenarioId, uuid, benchmarkId],
		{
			enabled: Boolean(uuid) && Boolean(benchmarkId),
			queryFn: compositionMetricsQueryFn,
		},
	);

	// Calculating All derived Metrics From MarketView Data
	// Data Calculating from MV Delta Absolute System to PTF performanc
	const geoParserMap = [
		{
			id: "EU_EQUITY_LARGE_CAP",
			perfomanceContributionsKey: "equityEu",
		},
		{
			id: "USA_EQUITY_LARGE_CAP",
			perfomanceContributionsKey: "equityUs",
		},
	] satisfies Array<{
		perfomanceContributionsKey: string;
		id: MarketViewMicroAssetClasses;
	}>;

	const portfolioContributionPerformanceRow = useMemo(() => {
		const { assetClasses: deltaMarketView } = marketScenario.flexibleExpectedReturnsVolatility ?? {};
		const deltaOriginalMarketView = applyDeltaToSelectedMarketView(
			originalScenario.regimeUserProbability!,
			originalScenario.flexibleExpectedReturnsVolatility?.assetClasses,
		);

		const deltaMarketViewMap = Map(deltaMarketView?.map((x) => [x.microAssetClass!, x]) ?? []);
		const deltaOriginalMarketViewMap = Map(deltaOriginalMarketView?.map((x) => [x.microAssetClass!, x]) ?? []);

		const calculatedData = performanceContributions.map((performanceAC) => {
			const geoLink = performanceAC.geographicalLink;
			const needGeoDelta = geoParserMap.find((geo) => geo.perfomanceContributionsKey === geoLink);

			const geoDelta =
				needGeoDelta !== undefined
					? {
							geoDeltaReturns:
								(deltaMarketViewMap.get(needGeoDelta.id)?.expectedReturnsUserDelta ?? 0) -
								(deltaOriginalMarketViewMap.get(needGeoDelta.id)?.expectedReturnsUserDelta ?? 0),
							geoDeltaVolatility:
								(deltaMarketViewMap.get(needGeoDelta.id)?.expectedVolatilityUserDelta ?? 0) -
								(deltaOriginalMarketViewMap.get(needGeoDelta.id)?.expectedVolatilityUserDelta ?? 0),
					  }
					: {
							geoDeltaReturns: 0,
							geoDeltaVolatility: 0,
					  };

			const calculatedACPerformance = (performanceAC.marketViewAssetClassLabel ?? []).reduce(
				(acc, curr) => {
					const selectedFromDeltaMV = deltaMarketViewMap.get(
						assetClassRetrocompatibilityMap[curr.tickerName! as keyof typeof assetClassRetrocompatibilityMap],
					);

					const userDeltaSelectedReturns = selectedFromDeltaMV?.expectedReturnsUserDelta ?? 0;
					const userDeltaSelectedVolatility = selectedFromDeltaMV?.expectedVolatilityUserDelta ?? 0;

					const selectedReturnsWeight = ((curr.weight ?? 0) / 100) * userDeltaSelectedReturns;
					const selectedVolatilityWeight = ((curr.weight ?? 0) / 100) * userDeltaSelectedVolatility;
					return [acc[0] + selectedReturnsWeight, acc[1] + selectedVolatilityWeight];
				},
				[0, 0],
			);

			return {
				portfolioExposure: round2Dec(performanceAC.portfolioExposure!),
				portfolioReturns: round2Dec(
					((calculatedACPerformance[0] + geoDelta.geoDeltaReturns) / 100) * performanceAC.portfolioExposure!,
				),
				benchmarkReturns: round2Dec(
					((calculatedACPerformance[0] + geoDelta.geoDeltaReturns) / 100) * performanceAC.benchmarkExposure!,
				),
				portfolioVolatility: round2Dec(
					((calculatedACPerformance[1] + geoDelta.geoDeltaVolatility) / 100) * performanceAC.portfolioExposure!,
				),
				benchmarkVolatility: round2Dec(
					((calculatedACPerformance[1] + geoDelta.geoDeltaVolatility) / 100) * performanceAC.benchmarkExposure!,
				),
				assetClass: performanceAC.label!,
			};
		});
		return calculatedData;
	}, [
		geoParserMap,
		marketScenario.flexibleExpectedReturnsVolatility,
		originalScenario.flexibleExpectedReturnsVolatility?.assetClasses,
		originalScenario.regimeUserProbability,
		performanceContributions,
	]);

	// Totals PTF Performance
	const portfolioPerformanceRow = useMemo(() => {
		const exAnteDiversificationRatio = compositionMetrics?.portfolioExAnteMetrics?.find(
			({ type }) => type === "DIVERSIFICATION_RATIO_3Y",
		);

		const ptfBDRatio = exAnteDiversificationRatio?.benchmark ?? 1;
		const ptfIDRatio = exAnteDiversificationRatio?.current ?? 1;
		// The API sends 0 ! so the result of volatility is infinite
		const diversificationRatioBenchmark = ptfBDRatio === 0 ? 1 : ptfBDRatio;
		const diversificationRatioPortfolio = ptfIDRatio === 0 ? 1 : ptfIDRatio;

		const portfolioReturns = round2Dec(
			portfolioContributionPerformanceRow.reduce((acc, curr) => acc + curr.portfolioReturns, 0),
		);
		const benchmarkReturns = round2Dec(
			portfolioContributionPerformanceRow.reduce((acc, curr) => acc + curr.benchmarkReturns, 0),
		);
		const portfolioVolatility = round2Dec(
			portfolioContributionPerformanceRow.reduce((acc, curr) => acc + curr.portfolioVolatility, 0) /
				diversificationRatioPortfolio,
		);
		const benchmarkVolatility = round2Dec(
			portfolioContributionPerformanceRow.reduce((acc, curr) => acc + curr.benchmarkVolatility, 0) /
				diversificationRatioBenchmark,
		);
		return [
			{
				benchmark: String(benchmarkReturns),
				graph: "",
				metrics: "Annualised returns",
				portfolio: String(portfolioReturns),
			},
			{
				benchmark: String(benchmarkVolatility),
				graph: "",
				metrics: "Annualised volatility",
				portfolio: String(portfolioVolatility),
			},
		];
	}, [compositionMetrics, portfolioContributionPerformanceRow]);

	const derivedContributionGraphLimits = useMemo(() => {
		const portfolioAndBenchmarkCReturns = portfolioContributionPerformanceRow
			.map((el) => Math.abs(el.benchmarkReturns))
			.concat(portfolioContributionPerformanceRow.map((el) => Math.abs(el.portfolioReturns)));
		const MAX = roundCustom(Math.max(...portfolioAndBenchmarkCReturns));
		return {
			max: MAX,
			min: MAX * -1,
		};
	}, [portfolioContributionPerformanceRow]);

	const derivedPerformanceGraphLimits = useMemo(() => {
		const portfolioAndBenchmarkVolatilityReturns = portfolioPerformanceRow.map((el) => [
			Math.abs(Number(el.benchmark)),
			Math.abs(Number(el.portfolio)),
		]);
		const MAX = roundCustom(Math.max(...portfolioAndBenchmarkVolatilityReturns.flat()));
		return {
			max: MAX,
			min: MAX * -1,
		};
	}, [portfolioPerformanceRow]);

	// Highlight data that is related to the portfolio
	const highlightedAssetClass = useMemo(() => {
		const filteredAC = performanceContributions.filter((el) => (el.portfolioExposure ?? 0) > 0);
		const selectedAC = filteredAC.reduce((acc, curr) => {
			const newAC =
				curr.marketViewAssetClassLabel && curr.marketViewAssetClassLabel.length > 0
					? curr.marketViewAssetClassLabel.map((el) => el.tickerName!)
					: [];
			if (curr.geographicalLink) {
				newAC.push(curr.geographicalLink);
			}
			return [...acc, ...newAC];
		}, [] as string[]);
		return [...new Set(selectedAC)];
	}, [performanceContributions]);

	// TableColumns
	const portfolioPerformanceColumn = useMemo<TableColumn<portfolioPerformanceColumnProps>[]>(
		() => [
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.METRIC"),
				content: ({ metrics }) => metrics,
				relativeWidth: !showContributionGraph ? 5.5 : 3.0,
				footer: () => (
					<div className="flex gap-4 py-4 flex-1">
						{EXPECTED_PTF_PERFORMANCE_LEGENDS.map(({ name, color }) => (
							<div key={color} className="flex items-center gap-1">
								<ColoredRectangle variant="vertical" color={color} />
								{name}
							</div>
						))}
					</div>
				),
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO"),
				cellClassList: "tabular-nums",
				content: ({ portfolio }) => `${formatNumber(portfolio)}%`,
				align: "end",
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.BENCHMARK"),
				cellClassList: "tabular-nums",
				content: ({ benchmark }) => `${formatNumber(benchmark)}%`,
				align: "end",
			},
			{
				header: () => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round(142 - INLINE_PADDING);
					const metrics = [`${derivedPerformanceGraphLimits.min}%`, "0", `${derivedPerformanceGraphLimits.max}%`];
					return (
						<div className="flex items-center justify-center grow">
							<div style={{ maxWidth: `${containerWidth}px` }} className="flex flex-grow relative">
								<p>{metrics[0]}</p>
								<p className="mx-auto">{metrics[1]}</p>
								<p>{metrics[2]}</p>
							</div>
						</div>
					);
				},
				content: ({ portfolio, benchmark }) => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round((142 - INLINE_PADDING) / 2);
					const containerHeight = 40 - 16;
					return (
						// <div className="mx-auto">
						<BarGraph
							containerHeigth={containerHeight}
							containerWidth={containerWidth}
							firstBar={portfolio}
							secondBar={benchmark}
							scale={{
								min: derivedPerformanceGraphLimits.min,
								max: derivedPerformanceGraphLimits.max,
							}}
						/>
						// </div>
					);
				},
				cellClassList: "grow",
				relativeWidth: showContributionGraph ? 2.0 : 0,
				hideColumn: !showContributionGraph,
				footer: () => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round(142 - INLINE_PADDING);
					const metrics = [`${derivedPerformanceGraphLimits.min}%`, "0", `${derivedPerformanceGraphLimits.max}%`];
					return (
						<div className="flex items-center justify-center grow">
							<div style={{ maxWidth: `${containerWidth}px` }} className="flex flex-grow relative">
								<p>{metrics[0]}</p>
								<p className="mx-auto">{metrics[1]}</p>
								<p>{metrics[2]}</p>
							</div>
						</div>
					);
				},
				footerCellClassList: "flex-grow mx-auto text-[10px]",
				footerCellWrapperClassName: showContributionGraph ? "" : "hidden",
			},
		],
		[derivedPerformanceGraphLimits.max, derivedPerformanceGraphLimits.min, formatNumber, showContributionGraph, t],
	);

	const portfolioPerformanceContributionColumn = useMemo<
		TableColumn<portfolioPerformanceContributionColumnProps>[]
	>(() => {
		return [
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.ASSET_CLASS"),
				content: ({ assetClass }) => <EllipsisText text={assetClass} />,
				relativeWidth: !showContributionGraph ? 4.0 : 3.5,
				sortFn: builtInSortFnFor("assetClass"),
				name: "assetClass",
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO_EXPOSURE"),
				cellClassList: "tabular-nums",
				content: ({ portfolioExposure }) => `${formatNumber(portfolioExposure)}%`,
				relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("portfolioExposure"),
				name: "portfolioExposure",
				hiderOrderableIcon: false,
				align: "end",
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO_RETURNS"),
				cellClassList: "tabular-nums",
				content: ({ portfolioReturns }) => `${formatNumber(portfolioReturns)}%`,
				align: "end",
				relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("portfolioReturns"),
				name: "portfolioReturns",
				hiderOrderableIcon: false,
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.BENCHMARK_RETURNS"),
				cellClassList: "tabular-nums",
				content: ({ benchmarkReturns }) => `${formatNumber(benchmarkReturns)}%`,
				align: "end",
				relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("benchmarkReturns"),
				name: "benchmarkReturns",
				hiderOrderableIcon: false,
			},
			{
				headerCellTitleClassList: "grow mx-auto mr-1.5",
				header: () => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round(142 - INLINE_PADDING);
					const metrics = [`${derivedContributionGraphLimits.min}%`, "0", `${derivedContributionGraphLimits.max}%`];
					return (
						<div className="flex items-center justify-center grow">
							<div style={{ maxWidth: `${containerWidth}px` }} className="flex flex-grow relative">
								<p>{metrics[0]}</p>
								<p className="mx-auto">{metrics[1]}</p>
								<p>{metrics[2]}</p>
							</div>
						</div>
					);
				},
				content: ({ portfolioReturns, benchmarkReturns }) => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round((142 - INLINE_PADDING) / 2);
					const containerHeight = 40 - 16;
					return (
						<BarGraph
							containerHeigth={containerHeight}
							containerWidth={containerWidth}
							firstBar={String(portfolioReturns)}
							secondBar={String(benchmarkReturns)}
							scale={{
								min: derivedContributionGraphLimits.min,
								max: derivedContributionGraphLimits.max,
							}}
						/>
					);
				},
				relativeWidth: showContributionGraph ? 2.0 : 0,
				cellClassList: showContributionGraph ? "mx-auto grow" : "",
				hideColumn: !showContributionGraph,
				footerCellTitleClassList: "grow mx-auto mr-1.5 text-[10px]",
				footer: () => {
					const INLINE_PADDING = 24;
					const containerWidth = Math.round(142 - INLINE_PADDING);
					const metrics = [`${derivedContributionGraphLimits.min}%`, "0", `${derivedContributionGraphLimits.max}%`];
					return (
						<div className={`flex items-center justify-center grow ${!showContributionGraph && "hidden"}`}>
							<div style={{ maxWidth: `${containerWidth}px` }} className="flex flex-grow relative">
								<p>{metrics[0]}</p>
								<p className="mx-auto">{metrics[1]}</p>
								<p>{metrics[2]}</p>
							</div>
						</div>
					);
				},
			},
		];
	}, [t, showContributionGraph, formatNumber, derivedContributionGraphLimits.min, derivedContributionGraphLimits.max]);

	const selectedScenario = useMemo(
		() => scenarioOptions.find((x) => x.value === currentScenarioId),
		[currentScenarioId, scenarioOptions],
	);

	return (
		<>
			<div className="grid" style={{ gridTemplateColumns: "805px 1fr" }}>
				<div className="py-4 pr-4 overflow-hidden border-r-2 border-[#EFF0F3]">
					{/* Market Scenario */}
					<div className="flex justify-between w-full mb-4">
						<Text type="Title/S">{t("SCENARIO_ANALYSIS_EDITABLE.MARKET_SCENARIO.MARKET_SCENARIO_TITLE")}</Text>
					</div>
					<div className="mb-4">
						<p>{t("SCENARIO_ANALYSIS_EDITABLE.MARKET_SCENARIO.MARKET_SCENARIO_DESCRIPTION")}</p>
					</div>
					<MarketViewProbabilities
						classList="!px-0"
						mode={selectedScenario?.scenarioType === "sphere" ? "edit" : "view"}
						forceSelectMode="edit"
						marketScenarioProvider={async (scenarioId) => {
							const loadedScenario = await axiosExtract(marketViewApi.getMarketViewScenario(scenarioId, uuid));
							setOriginalScenario(loadedScenario);
							return loadedScenario;
						}}
						marketScenario={marketScenario}
						onMarketScenarioChange={setMarketScenario}
						scenarioOptions={scenarioOptions}
						selectedScenarioId={currentScenarioId}
						onSelectedScenarioChange={setCurrentScenarioId}
						highlightedAssetClass={highlightedAssetClass}
						alias={marketViewSettings?.alias.assetClassAliases}
						hideCommentary
					/>
				</div>
				<div className="py-4 pl-4 min-w-0">
					<div className="mb-4">
						<Text type="Title/S">Expected portfolio performance</Text>
					</div>
					<div className="mb-12">
						<Table columns={portfolioPerformanceColumn} rows={portfolioPerformanceRow} />
					</div>
					<div className="mb-12">
						<h3 className="!text-xl text-[#2F3541] font-semibold mb-3">
							Expected performance contribution by asset class
						</h3>
						<div ref={setSize} className="relative">
							<Table
								columns={portfolioPerformanceContributionColumn}
								rows={portfolioContributionPerformanceRow}
								orderBy={defaultPortfolioPerformanceContributionOrderBy}
							/>

							<div className="absolute inset-x-0 bottom-0">
								<div className="flex gap-4 py-4">
									{EXPECTED_PTF_PERFORMANCE_CONTRIBUTION_LEGENDS.map(({ name, color }) => (
										<div key={color} className="flex items-center gap-1">
											<ColoredRectangle variant="vertical" color={color} />
											{name}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<DataDisplayOverlay dataSource="Edit MarketView" data={marketScenario} />
				<DataDisplayOverlay
					data={(function () {
						if (!marketScenario || !originalScenario) {
							return [];
						}
						return removeDeltaFromSelectedMarketView(
							marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
							originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
							marketScenario.regimeUserProbability!,
						);
					})()}
					dataSource="ReConverted MarketView"
				/>
			</div>
		</>
	);
});

const defaultPortfolioPerformanceContributionOrderBy: OrderBy[] = [
	{ columnName: "portfolioExposure", direction: "desc" },
];

const ScenarioAnalysisEditable = ({
	portfolio,
	registerStartEnhanceCallback,
	enhanced,
	selectedBenchmark,
}: ContextContent<typeof PortfolioContext>) => {
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const reportEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const { uuid } = portfolio ?? {};
	const benchmarkId = selectedBenchmark;

	const { data: performanceContributions, isLoading: isLoadingPerformanceContributions } = useQueryNoRefetch(
		["performanceContributions", uuid, benchmarkId],
		{
			enabled: Boolean(uuid) && Boolean(benchmarkId),
			queryFn: async () => {
				const { data } = enhanced
					? await reportEnhanceApi.getExposureDecomposition1(uuid ?? "", benchmarkId ?? "")
					: await reportInvestmentApi.getExposureDecomposition(uuid ?? "", benchmarkId ?? "");
				return data;
			},
			onError: (err) => {
				console.error(err);
			},
		},
	);

	const { data: sphereScenario, isLoading: isLoadingSphereScenario } = useQueryNoRefetch(["sphereScenario"], {
		enabled: Boolean(portfolio),
		queryFn: async () => {
			const { data } = await marketViewApi.getMarketViewScenario("SPHERE_FORECAST_ONE_MONTH", uuid, enhanced);
			return data;
			// return {
			// 	regimesMarketView: {
			// 		assetClasses: extractData(data, "assetClasses"),
			// 		historicalInvestmentHorizon: {
			// 			from: data.historicalInvestmentHorizon?.from ?? "",
			// 			to: data.historicalInvestmentHorizon?.to ?? "",
			// 		},
			// 		regimeProbabilities: extractData(data, "regimeProbabilities"),
			// 		regimeUserProbability: extractData(data, "regimeUserProbability"),
			// 		yield: extractData(data, "yield"),
			// 	},
			// 	principalRegimes: extractData(data, "regimeUserProbability"),
			// 	deltaMarketView: applyDeltaToSelectedMarketViewOld(
			// 		extractData(data, "assetClasses"),
			// 		extractData(data, "regimeUserProbability"),
			// 	),
			// };
		},
		onError: (err) => {
			console.error(err);
		},
	});

	return (
		<>
			{isLoadingSphereScenario || isLoadingPerformanceContributions ? (
				<ProgressBar value="indeterminate" />
			) : sphereScenario && performanceContributions && portfolio ? (
				<ScenarioAnalysisEditableContentMemo
					registerStartEnhanceCallback={registerStartEnhanceCallback}
					performanceContributions={performanceContributions}
					sphereScenario={sphereScenario}
					summary={portfolio}
					benchmarkId={benchmarkId}
					enhanced={enhanced}
				/>
			) : (
				<div className="h-[720px] bg-white w-full">
					<IconWalls.ErrorData />
				</div>
			)}
		</>
	);
};

export default withContext(PortfolioContext)(ScenarioAnalysisEditable);
