/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon, StylableProps, defaultTailwindTransitionDurationMs } from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, sleep } from "@mdotm/mdotui/utils";
import { useState, type ReactNode, type MouseEvent, useRef } from "react";

export type CopyableTextProps = {
	text: string;
	children?: ReactNode;
	onClick?(e: MouseEvent): void;
	qualifier?: string;
	textAppearance?: StylableProps;
} & StylableProps;

export function CopyableText({
	text,
	children = text,
	onClick,
	qualifier,
	style,
	classList,
	textAppearance,
}: CopyableTextProps): JSX.Element {
	const [opacity, setOpacity] = useState(0);
	const handleEnter = () => {
		mouseOverRef.current = true;
		setOpacity(1);
	};
	const mouseOverRef = useRef(false);
	const handleLeave = () => {
		mouseOverRef.current = false;
		setPulse(false);
		if (!loading) {
			setOpacity(0);
		}
	};

	const [pulse, setPulse] = useState(false);
	const { run, loading } = useAsync({
		asyncFn: async () => {
			setPulse(true);
			await Promise.all([
				window.navigator.clipboard.writeText(text).catch(console.error),
				sleep(defaultTailwindTransitionDurationMs),
			]);
			if (!mouseOverRef.current) {
				setOpacity(0);
			}
		},
	});

	if (text === "") {
		return (
			<span style={style} className={overrideClassName("flex items-center gap-2 text-left min-w-0", classList)}>
				{children}
			</span>
		);
	}

	return (
		<button
			type="button"
			style={style}
			className={overrideClassName("flex items-center gap-2 text-left min-w-0", classList)}
			onMouseEnter={handleEnter}
			onTouchStart={handleEnter}
			onMouseLeave={handleLeave}
			onTouchEnd={handleLeave}
			onClick={(e) => {
				onClick?.(e);
				run().catch(noop);
			}}
			disabled={loading}
			data-qualifier={qualifier}
		>
			<div
				title={text}
				className={overrideClassName("min-w-0", textAppearance?.classList)}
				style={textAppearance?.style}
			>
				{children}
			</div>
			<div className="transition-opacity" style={{ opacity }} onTouchEnd={(e) => e.stopPropagation()}>
				<Icon icon={!pulse ? "Content-Copy" : "Outline"} size={21} color={themeCSSVars.global_palette_primary_600} />
			</div>
		</button>
	);
}
