import { CherryBankControllerApiFactory, PdfControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import env from "$root/env";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadBlob, downloadFileResponse } from "$root/utils/files";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { Button, DropdownMenu, DropdownMenuActionButton } from "@mdotm/mdotui/components";
import { useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import * as dateFn from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const CustomReports = (): JSX.Element => {
	const { t } = useTranslation();
	const cherryBankApi = useApiGen(CherryBankControllerApiFactory);
	const pdfV3Api = useApiGen(PdfControllerApiFactory);
	const user = useUserValue();

	const downloadPf = useCallback(async () => {
		const pageUrl = encodeURIComponent(`${env.appOrigin}/login?from=/report/custom-report`);
		const pdf = await axiosExtract(
			pdfV3Api.generateInvestmentsPdf(
				pageUrl,
				"TPLPortraitNoMargin",
				`Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}`,
				{ responseType: "blob" },
			),
		);
		trackMixPanelEvent("Custom-reports", {
			Type: "Export",
			Mode: "PDF",
		});
		downloadBlob(pdf as Blob, {
			fileName: `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}.pdf`,
		});
	}, [pdfV3Api]);

	const downloadXls = async () => {
		const template = await axiosExtract(cherryBankApi.retrieveReport());
		trackMixPanelEvent("Custom-reports", {
			Type: "Export",
			Mode: "XLS",
		});
		downloadFileResponse(template, {
			fileName: `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}.xlsx`,
		});
	};

	const downloadPfRef = useUpdatedRef(downloadPf);
	const downloadXlsRef = useUpdatedRef(downloadXls);

	return (
		<AuthorizationGuard requiredService="CUSTOM_REPORT_CB1" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
			{() => (
				<>
					<PageHeader
						title="Custom reports" // TODO: translate
						titleAction={<OutlookWidgetData />}
						subTitle={
							<div className="flex justify-between items-center py-2.5 gap-4">
								<span className="text-[14px]">{t("CUSTOM_REPORT.CHERRYBANK")}</span>
								<DropdownMenu
									trigger={(props) => (
										<Button palette="primary" size="small" {...props}>
											{t("BUTTON.DOWNLOAD")}
										</Button>
									)}
									actions={[
										({ onClose }) =>
											!hasAccess(user, { requiredRoles: ["ROOT"] }) ? null : (
												<DropdownMenuActionButton
													icon="Expand"
													onClick={() => {
														window.open(typedUrlForRoute("Report/Demo", { templateId: "custom-report" }), "_blank");
														onClose();
													}}
												>
													Vertical Report
												</DropdownMenuActionButton>
											),
										({ onClose }) => (
											<DropdownMenuActionButton
												icon="Dowload"
												onClickAsync={async () => {
													await downloadXlsRef.current();
													onClose();
												}}
											>
												Xls report
											</DropdownMenuActionButton>
										),
										({ onClose }) => (
											<DropdownMenuActionButton
												icon="Dowload"
												onClickAsync={async () => {
													await downloadPfRef.current();
													onClose();
												}}
											>
												Pdf report
											</DropdownMenuActionButton>
										),
									]}
								/>
							</div>
						}
					/>
					<div className="relative">
						<WidgetsMapper
							widgetName="AssetClassOverviewBlock"
							style={{ paddingBottom: "1rem", marginBottom: "1rem" }}
						/>
						<WidgetsMapper widgetName="CustomOutlookBlock" style={{ paddingBottom: "1rem" }} />
					</div>
				</>
			)}
		</AuthorizationGuard>
	);
};

export default CustomReports;
