export function MarketChangeIcon(): JSX.Element {
	return (
		<svg
			width="136"
			height="136"
			viewBox="0 0 136 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="136" height="136" fill="url(#pattern0)" />
			<rect x="8.5" y="8.5" width="119" height="119" fill="white" />
			<path d="M88.8028 43.3599H118V91.6399H80.7271L88.8028 43.3599Z" fill="#FFD2D3" />
			<path d="M18 43.3599H49.3853L70.7273 91.6399H18V43.3599Z" fill="#DBEFEB" />
			<path d="M48.9092 43.3599H88.9092L80.6552 91.6399H70.4965L48.9092 43.3599Z" fill="#FFF2CC" />
			<rect x="18" y="91.6401" width="52.7273" height="11.36" fill="#94D0C4" />
			<rect x="70.7271" y="91.6401" width="10" height="11.36" fill="#FFD866" />
			<rect x="80.7271" y="91.6401" width="37.2727" height="11.36" fill="#FF777B" />
			<rect x="18" y="32" width="30.9091" height="11.36" fill="#94D0C4" />
			<rect x="48.9092" y="32" width="40" height="11.36" fill="#FFD866" />
			<rect x="88.9092" y="32" width="29.0909" height="11.36" fill="#FF777B" />
			<path
				d="M31.0613 54.4828C31.2929 54.2513 31.5653 54.1358 31.8785 54.1363C32.1918 54.1358 32.4642 54.2513 32.6957 54.4828L41.828 63.615L41.828 55.6473C41.828 55.3204 41.9405 55.0445 42.1655 54.8195C42.3899 54.595 42.6656 54.4828 42.9925 54.4828C43.3194 54.4828 43.5918 54.5918 43.8097 54.8097C44.0276 55.0276 44.1366 55.3 44.1366 55.6269L44.1366 66.414C44.1366 66.5774 44.1061 66.724 44.045 66.8536C43.9835 66.9827 43.8982 67.1018 43.7893 67.2107C43.6803 67.3197 43.5577 67.4085 43.4215 67.4771C43.2853 67.5447 43.1423 67.5717 42.9925 67.558L32.2054 67.558C31.8785 67.558 31.6061 67.4491 31.3882 67.2312C31.1703 67.0132 31.0613 66.7408 31.0613 66.414C31.0613 66.0871 31.1738 65.8111 31.3988 65.5861C31.6233 65.3617 31.899 65.2495 32.2259 65.2495L40.1936 65.2495L31.0613 56.1172C30.8298 55.8857 30.714 55.6135 30.714 55.3008C30.714 54.987 30.8298 54.7144 31.0613 54.4828Z"
				fill="#94D0C4"
			/>
			<path
				d="M31.0613 54.4828C31.2929 54.2513 31.5653 54.1358 31.8785 54.1363C32.1918 54.1358 32.4642 54.2513 32.6957 54.4828L41.828 63.615L41.828 55.6473C41.828 55.3204 41.9405 55.0445 42.1655 54.8195C42.3899 54.595 42.6656 54.4828 42.9925 54.4828C43.3194 54.4828 43.5918 54.5918 43.8097 54.8097C44.0276 55.0276 44.1366 55.3 44.1366 55.6269L44.1366 66.414C44.1366 66.5774 44.1061 66.724 44.045 66.8536C43.9835 66.9827 43.8982 67.1018 43.7893 67.2107C43.6803 67.3197 43.5577 67.4085 43.4215 67.4771C43.2853 67.5447 43.1423 67.5717 42.9925 67.558L32.2054 67.558C31.8785 67.558 31.6061 67.4491 31.3882 67.2312C31.1703 67.0132 31.0613 66.7408 31.0613 66.414C31.0613 66.0871 31.1738 65.8111 31.3988 65.5861C31.6233 65.3617 31.899 65.2495 32.2259 65.2495L40.1936 65.2495L31.0613 56.1172C30.8298 55.8857 30.714 55.6135 30.714 55.3008C30.714 54.987 30.8298 54.7144 31.0613 54.4828Z"
				fill="#94D0C4"
			/>
			<path
				d="M72.1207 52.3337C72.404 52.3337 72.6414 52.4297 72.8327 52.6217C73.0247 52.813 73.1207 53.0504 73.1207 53.3337V64.5087L77.9957 59.6337C78.1957 59.4337 78.4334 59.3337 78.7087 59.3337C78.9834 59.3337 79.2207 59.4337 79.4207 59.6337C79.6207 59.8337 79.7207 60.067 79.7207 60.3337C79.7207 60.6004 79.6207 60.8337 79.4207 61.0337L72.8207 67.6337C72.7207 67.7337 72.6124 67.8047 72.4957 67.8467C72.379 67.888 72.254 67.9087 72.1207 67.9087C71.9874 67.9087 71.858 67.888 71.7327 67.8467C71.608 67.8047 71.504 67.7337 71.4207 67.6337L64.8207 61.0337C64.6207 60.8337 64.5207 60.6004 64.5207 60.3337C64.5207 60.067 64.6207 59.8337 64.8207 59.6337C65.0207 59.4337 65.2584 59.3337 65.5337 59.3337C65.8084 59.3337 66.0457 59.4337 66.2457 59.6337L71.1207 64.5087V53.3337C71.1207 53.0504 71.2164 52.813 71.4077 52.6217C71.5997 52.4297 71.8374 52.3337 72.1207 52.3337Z"
				fill="#FFCB33"
			/>
			<path
				d="M108.477 54.6768C108.677 54.8771 108.777 55.1128 108.777 55.3839C108.777 55.6549 108.677 55.8906 108.477 56.091L100.575 63.9929H107.469C107.752 63.9929 107.991 64.0902 108.185 64.2849C108.38 64.4792 108.477 64.7177 108.477 65.0005C108.477 65.2834 108.383 65.5191 108.194 65.7076C108.005 65.8962 107.77 65.9905 107.487 65.9905H98.1531C98.0116 65.9905 97.8848 65.9641 97.7726 65.9113C97.6609 65.858 97.5579 65.7842 97.4636 65.69C97.3694 65.5957 97.2925 65.4896 97.2331 65.3718C97.1747 65.2539 97.1513 65.1302 97.1631 65.0005V55.6667C97.1631 55.3839 97.2574 55.1482 97.446 54.9596C97.6345 54.771 97.8702 54.6768 98.1531 54.6768C98.4359 54.6768 98.6747 54.7741 98.8694 54.9688C99.0636 55.163 99.1607 55.4016 99.1607 55.6844V62.5787L107.063 54.6768C107.263 54.4764 107.498 54.3762 107.769 54.3762C108.041 54.3762 108.276 54.4764 108.477 54.6768Z"
				fill="#FF777B"
			/>
			<rect x="8.5" y="8.5" width="119" height="119" stroke="#EFF0F3" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0294118" height="0.0294118">
					<use xlinkHref="#image0_659_265099" transform="scale(0.00735294)" />
				</pattern>
				{/* <clipPath id="clip0_659_265099">
					<rect width="24" height="24" fill="white" transform="translate(49 49) rotate(90)" />
				</clipPath> */}
				<image
					id="image0_659_265099"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
