import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";
import { debugValue } from "@mdotm/mdotui/utils";
import { differenceInSeconds } from "date-fns";

export const steps = ["recovering", "analysing", "preparing"] as const;
export type Step = (typeof steps)[number];

type AnimationProgressProps = {
	id: string;
	progress: number;
	step: Step;
	date: Date;
};

const initProgress: Array<AnimationProgressProps> = [];

export const animationProgressStore = createPersistentAtom<Array<AnimationProgressProps>>(
	initProgress,
	"animation-progress",
);

export function getAnimationProgressById(id: string): AnimationProgressProps | undefined {
	const animations = animationProgressStore((animation) => animation.value);
	return animations.find((animation) => animation.id === id);
}

export function animationProgressState(): {
	setAnimationProgress: (newValue: AnimationProgressProps[] | Updater<AnimationProgressProps[]>) => void;
	animationProgress: Array<AnimationProgressProps>;
} {
	const { set, value } = animationProgressStore();
	return { setAnimationProgress: set, animationProgress: value };
}

export function simulateAnimationProgress(
	date: Date,
	progress: number,
	step: Step,
	expCoeff: number,
): { step: Step; progress: number } {
	const deltaProgressInSeconds = differenceInSeconds(new Date(), new Date(date));
	const exceededProgression = Number(expCoeff * deltaProgressInSeconds);
	const simulatedProgression = exceededProgression + Number(progress.toFixed(3))

	if (simulatedProgression <= 1.0) {
		return { step, progress: simulatedProgression };
	}

	const deltaSimulatedProgressToNewStep = Math.abs(Number(Math.floor(simulatedProgression - 1).toFixed(3)));
	const currentStepIndex = steps.indexOf(step);
	const nextStep = currentStepIndex > -1 && step !== "preparing" ? steps[currentStepIndex + 1] : step;
	return { step: nextStep, progress: deltaSimulatedProgressToNewStep >= 1 ? 1 : deltaSimulatedProgressToNewStep };
}
