import type { MarketOutlookDTO, ValueWithData } from "$root/api/api-gen";
import { MarketControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { GraphLegendLabel, GraphLegendTextKey } from "$root/components/GraphLegend";
import DonoutCircle from "$root/components/icons/DonoutCircle";
import { useEventBus } from "$root/event-bus";
import { PaletteColors } from "$root/styles/themePalette";
import { handlePreviousMarketData } from "$root/utils/marketUtils";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Icon } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { TableParams } from "./OutlookBlockTable";
import OutlookBlockTable from "./OutlookBlockTable";
import useWidgetsData from "$root/hooks/useWidgetsData";

export type CustomSectorModel = {
	label: string;
	type: string;
	prevValue?: ValueWithData;
	currentValue?: ValueWithData;
};

const OutlookBlock: FC = () => {
	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerApiFactory);
	const { currentWidgetsData } = useWidgetsData();
	const dateRange = new Date(Date.parse(currentWidgetsData.creation_time.toString()));
	dateRange.setFullYear(dateRange.getFullYear() - 1);
	dateRange.setHours(0, 0, 0, 0);

	const { data: outlookTimeSeries } = useQueryNoRefetch(["retrieveOutlookTimeSeries"], {
		queryFn: async () => {
			const { data } = await marketApi.retrieveOutlookContinuousTimeSeries(String(dateRange));
			return data;
		},
	});

	const assetClassTimeSeries = useMemo(() => {
		if (!outlookTimeSeries) {
			return undefined;
		}

		return outlookTimeSeries.reduce<{ [key: string]: [number, number, boolean][] }>((acc, el) => {
			const { type, values, inverted } = el;
			if (!type || !values) {
				return acc;
			}

			acc[type] = values
				.filter(([_x, y]) => y ?? false)
				.map(([dateSeconds, y]) => [dateSeconds * 1000, y, inverted]) as [number, number, boolean][];
			return acc;
		}, {});
	}, [outlookTimeSeries]);

	const composeTableRowData = useCallback(
		(data: Array<CustomSectorModel>) => {
			return data.reduce((acc, { currentValue, prevValue, type }, i) => {
				if (currentValue?.value === undefined) {
					return acc;
				}
				const { previous, tooltip } = handlePreviousMarketData(
					t,
					"DASHBOARD.OUTLOOK" as never,
					"dashboard",
					{ value: currentValue.value, data: currentValue.data },
					{ value: prevValue?.value, data: prevValue?.data },
				);

				return [
					...acc,
					{
						assetClass: type,
						current: currentValue.value,
						prev: previous,
						tooltip,
						index: i,
					},
				];
			}, [] as Array<TableParams>);
		},
		[t],
	);

	const sanitaizeData = useCallback(
		({ label, type, currentValue, prevValue }: MarketOutlookDTO) => ({
			label: label ?? "-",
			type: type ?? "-",
			prevValue,
			currentValue,
		}),
		[],
	);

	const { data, refetch } = useQueryNoRefetch(["retrieveOutlookApi"], {
		queryFn: async () => {
			const { data: outlook } = await marketApi.retrieveOutlook();

			const refinedData = outlook.reduce<{ [key: string]: CustomSectorModel[] }>((acc, ot) => {
				const { assetClass } = ot;
				if (assetClass) {
					acc[assetClass] = [...(acc[assetClass] ? acc[assetClass] : []), sanitaizeData(ot)];
				}
				return acc;
			}, {});

			return {
				Equity: composeTableRowData(refinedData["EQUITY"] ?? []),
				FixedIncome: composeTableRowData(refinedData["FIXED_INCOME"] ?? []),
				Commodities: composeTableRowData(refinedData["COMMODITIES"] ?? []),
			};
		},
	});

	const { Commodities, Equity, FixedIncome } = data ?? {};

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			title: t("OUTLOOK.OUTLOOK_TITLE"),
			isDraggable: false,
			actionHeader: <InfoTooltip>{t("OUTLOOK.TOOLTIP")}</InfoTooltip>,
		}),
		[t],
	);


	return (
		<div className="relative">
			<div className="grid grid-cols-3 gap-6 mb-6">
				<OutlookBlockTable
					title="DASHBOARD_EQUITY"
					data={Equity ?? []}
					className="!h-[22rem]"
					pushTo="/outlook"
					assetClassTimeSeries={assetClassTimeSeries ?? {}}
					sortable
				/>
				<OutlookBlockTable
					title="DASHBOARD_FIXED_INCOME"
					data={FixedIncome ?? []}
					className="!h-[22rem]"
					pushTo="/outlook?filter=FI"
					assetClassTimeSeries={assetClassTimeSeries ?? {}}
					sortable
				/>
				<OutlookBlockTable
					title="DASHBOARD_COMMODITIES"
					data={Commodities ?? []}
					className="!h-[22rem]"
					pushTo="/outlook?filter=CO"
					assetClassTimeSeries={assetClassTimeSeries ?? {}}
					sortable
				/>
			</div>
			<div className="flex gap-4">
				<div className="flex items-center">
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.AZURE} size={16} />
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.NEON_RED} size={16} />
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.GREEN_BLUE} size={16} />
					<GraphLegendLabel>{t("CURRENT_POSITIONING")}</GraphLegendLabel>
				</div>
				<div className="flex items-center gap-1">
					<DonoutCircle />
					<GraphLegendLabel>{t("PREVIOUS_POSITIONING")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.SU_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.SU_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.U_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.U_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.N_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.N_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.O_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.O_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.SO_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.SO_LABEL")}</GraphLegendLabel>
				</div>
			</div>
		</div>
	);
};

export default OutlookBlock;
