import type { ReviewTicker } from "$root/api/api-gen";
import { BenchmarksControllerApiFactory, EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { useUserValue } from "$root/functional-areas/user";
import { AsyncButton, Button } from "@mdotm/mdotui/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { PortfolioStudioTab } from "../PortfoliosStudio";

const EditCustomBenchmark = (): JSX.Element => {
	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([]);
	const [editorComposition, setEditorComposition] = useState<{
		disabled: boolean;
		onSubmit(fn: (data: ReviewTicker[]) => Promise<void>): Promise<void>;
	} | null>(null);

	const history = useHistory();
	const { t } = useTranslation();
	const { benchmarkId } = useParams<{ benchmarkId: string }>();

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const benchmarksV4Api = useApiGen(BenchmarksControllerApiFactory);
	const { push } = useTypedNavigation();
	const user = useUserValue();

	const manageFinish = useCallback(() => {
		setPathToNotBlock([typedUrlForRoute("CustomBenchmark", { benchmarkId })]);
		setTimeout(() => push("CustomBenchmark", { benchmarkId }));
	}, [push, benchmarkId]);

	const onSave = useCallback(async () => {
		await editorComposition?.onSubmit(async (submitData) => {
			try {
				if (benchmarkId === undefined) {
					return reportPlatformError(
						benchmarkId,
						"ERROR",
						"benchmark",
						`unable to submit a valid benchmark of undefined`,
					);
				}
				await editorApi.saveEditorEditEntity("BENCHMARK", {
					identifier: benchmarkId,
					tickerComposition: submitData,
				});
			} catch (error) {
				reportPlatformError(
					error,
					"ERROR",
					"portfolio",
					`unable to save the edited benchmark composition (${benchmarkId})`,
				);
			} finally {
				manageFinish();
			}
		});
	}, [benchmarkId, editorApi, editorComposition, manageFinish]);

	return (
		<ReactQueryWrapper
			queryKey={["queryBenchmark", benchmarkId]}
			queryFn={() => benchmarksV4Api.getBenchmarkSummary(benchmarkId).then(({ data }) => data)}
			onSuccess={(summary) => {
				const canEditComposition = aclByArea.benchmark.canEditComposition(user.id, summary?.richAcl?.acl ?? []);
				if (!canEditComposition) {
					push("CustomBenchmark", { benchmarkId });
				}
			}}
		>
			{({ name }) => (
				<>
					<PageHeader
						title={t("PLACEHOLDER.TITLE.EDIT_PORTFOLIO_COMPOSITION")}
						crumbs={[
							{
								children: t("PLACEHOLDER.TITLE.PORTFOLIOS"),
								href: typedUrlForRoute("PortfoliosStudio", {}),
							},
							{
								children: "References",
								href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
							},
							{
								children: name,
								href: typedUrlForRoute("CustomBenchmark", { benchmarkId }),
							},
							{
								children: t("PLACEHOLDER.TITLE.EDIT"),
							},
						]}
						subTitle={
							<div className="flex justify-between items-center py-2.5">
								<span className="text-[14px]">
									Customize your portfolio by adding and removing instruments, or by tweaking their weights.
								</span>
								<div className="flex items-center space-x-2">
									<Button size="small" onClick={() => history.goBack()} palette="tertiary">
										Cancel
									</Button>
									<AsyncButton
										size="small"
										palette="primary"
										disabled={editorComposition?.disabled ?? true}
										onClickAsync={onSave}
									>
										Save
									</AsyncButton>
								</div>
							</div>
						}
					/>

					<EditCompositionSection
						uploadEntity="BENCHMARK"
						uuid={benchmarkId}
						ref={setEditorComposition}
						instrumentsLimit={1000}
						pathToNotBlock={pathToNotBlock}
					/>
				</>
			)}
		</ReactQueryWrapper>
	);
};

export default EditCustomBenchmark;
