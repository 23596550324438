import { routesMap, typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useUserValue } from "$root/functional-areas/user";
import { useMemo } from "react";
import { Redirect } from "react-router";

export function IndexRedirect(): JSX.Element {
	const user = useUserValue();
	const routeName = useMemo(
		() =>
			([routesMap.Dashboard, routesMap.PortfoliosStudio, routesMap.RegimeAnalysisTool] as const).find(
				(route) => route.guard({ user }).passed,
			)?.name,
		[user],
	);
	return <>{routeName ? <Redirect to={typedUrlForRoute(routeName, {})} /> : "No default service available"}</>;
}

export default IndexRedirect;
