import type { IUser } from "$root/functional-areas/user";
import { useUserValue } from "$root/functional-areas/user";
import { TransientNotificationSlot } from "$root/notification-system/transient";
import { PaletteColors } from "$root/styles/themePalette";
import type { ReactNode } from "react";
import { memo } from "react";
import { Footer } from "./Footer/Footer";
import type { PlatformLayoutOptions } from "./PlatformRouter/layout-options";

export type PageLayoutProps = {
	children?: ReactNode;
	title?: string;
	backgroundImage?: string;
	routeKey?: string;
	mode?: PlatformLayoutOptions["mode"];
	hideSideBar?: boolean;
	hideAppBar?: boolean | ((user: IUser) => boolean);
	hideFooter?: boolean;
};

export type DefaultModeProps = Omit<PageLayoutProps, "mode">;

const DefaultMode = ({ children, hideSideBar = false, hideFooter = false, backgroundImage }: DefaultModeProps) => {
	const user = useUserValue();
	const isLogin = window.location.pathname === "/login";
	const isForgotPassword = window.location.pathname === "/forgot_password";

	return (
		<>
			<div
				style={{
					flex: 1,
					marginLeft: hideSideBar ? "0px" : "48px",
					backgroundColor: PaletteColors.PALE_GREY_TWO,
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: "cover",
				}}
			>
				<div>
					<TransientNotificationSlot
						overlayStyle={{
							marginLeft: user.signedIn ? "3rem" : "",
						}}
					/>
				</div>
				<div
					className="FatherBox"
					style={{
						position: "relative",
						flex: 1,
						padding: "0.9375rem",
						width: "100%",
					}}
				>
					<div
						data-islogin={isLogin}
						data-isforgotpassword={isForgotPassword}
						className="data-[islogin=true]:!min-h-[calc(100vh_-_70px)] data-[isforgotpassword=true]:!min-h-[calc(100vh_-_65px)] min-h-[calc(100vh_-_155px)]"
					>
						{children}
					</div>

					{!hideFooter && <Footer isLogin={isLogin} isForgotPassword={isForgotPassword} />}
				</div>
			</div>
		</>
	);
};

function PageLayout(props: PageLayoutProps) {
	const { mode = "default", children } = props;

	if (mode === "print") {
		return <>{children}</>;
	}

	return (
		<div className="AncestorBox flex flex-col w-full min-h-[calc(100vh_-_48px)] min-w-[85.375rem]" style={{ flex: 1 }}>
			<DefaultMode {...props} />
		</div>
	);
}

export default memo<PageLayoutProps>(PageLayout);
