import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageHeader } from "$root/components/PageHeader";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";

const PAGE_NAME = "DASHBOARD";

const Dashboard = (): JSX.Element => {
	return (
		<>
			<PageHeader
				title="Dashboard" // TODO: translate
				titleAction={<OutlookWidgetData />}
			/>
			<WidgetsGrid gridName={PAGE_NAME} isDraggable={false} />
		</>
	);
};

export default Dashboard;
