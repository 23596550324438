export function ConstraintBreachIcon(): JSX.Element {
	return (
		<svg
			width="136"
			height="136"
			viewBox="0 0 136 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="136" height="136" fill="url(#pattern0)" />
			<rect x="8.5" y="8.5" width="119" height="119" fill="white" />
			<rect x="24" y="68" width="80" height="20" fill="#FF777B" />
			<path d="M90 68H112V88H90V68Z" fill="#E1E4EA" />
			<line x1="90.5" y1="56.1201" x2="90.5" y2="88.1201" stroke="#8792AB" />
			<path
				d="M90.4983 54.24C88.4783 54.24 86.9883 52.65 86.9883 50.64V50.62C86.9883 48.64 88.4683 47 90.5283 47C91.7983 47 92.5583 47.45 93.2583 48.11L92.7183 48.69C92.1283 48.13 91.4683 47.73 90.5183 47.73C88.9683 47.73 87.8083 48.99 87.8083 50.6V50.62C87.8083 52.24 88.9783 53.51 90.5183 53.51C91.4783 53.51 92.1083 53.14 92.7783 52.5L93.2983 53.01C92.5683 53.75 91.7683 54.24 90.4983 54.24Z"
				fill="#8792AB"
			/>
			<path
				d="M97.3 103.7C97.1167 103.883 96.8834 103.979 96.6 103.988C96.3167 103.996 96.0834 103.908 95.9 103.725L91 98.825L91 110C91 110.283 90.904 110.521 90.712 110.713C90.5207 110.904 90.2834 111 90 111C89.7167 111 89.479 110.904 89.287 110.713C89.0957 110.521 89 110.283 89 110L89 98.825L84.1 103.725C83.9167 103.908 83.6834 103.996 83.4 103.988C83.1167 103.979 82.8834 103.883 82.7 103.7C82.5167 103.517 82.425 103.283 82.425 103C82.425 102.717 82.5167 102.483 82.7 102.3L89.3 95.7C89.3834 95.6 89.4874 95.529 89.612 95.487C89.7374 95.4457 89.8667 95.425 90 95.425C90.1334 95.425 90.2584 95.4457 90.375 95.487C90.4917 95.529 90.6 95.6 90.7 95.7L97.3 102.3C97.4834 102.483 97.575 102.717 97.575 103C97.575 103.283 97.4834 103.517 97.3 103.7Z"
				fill="#FF777B"
			/>
			<path
				d="M82.7 31.3C82.8833 31.1167 83.1166 31.0207 83.4 31.012C83.6833 31.004 83.9166 31.0917 84.1 31.275L89 36.175L89 25C89 24.7167 89.096 24.479 89.288 24.287C89.4793 24.0957 89.7166 24 90 24C90.2833 24 90.521 24.0957 90.713 24.287C90.9043 24.479 91 24.7167 91 25L91 36.175L95.9 31.275C96.0833 31.0917 96.3166 31.004 96.6 31.012C96.8833 31.0207 97.1166 31.1167 97.3 31.3C97.4833 31.4833 97.575 31.7167 97.575 32C97.575 32.2833 97.4833 32.5167 97.3 32.7L90.7 39.3C90.6166 39.4 90.5126 39.471 90.388 39.513C90.2626 39.5543 90.1333 39.575 90 39.575C89.8666 39.575 89.7416 39.5543 89.625 39.513C89.5083 39.471 89.4 39.4 89.3 39.3L82.7 32.7C82.5166 32.5167 82.425 32.2833 82.425 32C82.425 31.7167 82.5166 31.4833 82.7 31.3Z"
				fill="#FF777B"
			/>
			<rect x="8.5" y="8.5" width="119" height="119" stroke="#EFF0F3" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0294118" height="0.0294118">
					<use xlinkHref="#image0_659_265005" transform="scale(0.00735294)" />
				</pattern>
				<image
					id="image0_659_265005"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
