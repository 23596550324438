import { useFunctionalAreas } from "$root/App/context";
import type {
	AlertDto,
	CompatibleBenchmarkEntry,
	InvestmentListEntry,
	InvestmentReferenceListEntry,
	InvestmentReportsBatchOrderingEnum,
	InvestmentsModel,
	InvestmentStatuses,
	InvestmentSummary,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentActions,
	InvestmentBenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	InvestmentsReportTemplateControllerApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import type { DefaultTemplateId } from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import { LeavePromptBase } from "$root/components/LeavePrompt";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import {
	EditPortfolioV4ContextProvider,
	EditPortfolioV4InContext,
} from "$root/components/Portfolio/CreatePortfolio/EditPortfolio";
import { makeStepToRequestMapper, responseToStepMapper } from "$root/components/Portfolio/CreatePortfolio/requests";
import type { EditPortfolioV4StepPayloadMap } from "$root/components/Portfolio/CreatePortfolio/shared";
import { makeUnsavedDataHandler } from "$root/components/Portfolio/CreatePortfolio/shared";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { downloadPdf } from "$root/functional-areas/pdf";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import {
	defaultPortfolioTemplates,
	DefaultReportTemplateName,
	isDefaultReportTemplate,
} from "$root/functional-areas/reports/default-templates";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { PaletteColors } from "$root/styles/themePalette";
import { axiosExtract, type CustomAxiosError } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { dateToStringWithoutTime } from "$root/utils/dates";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { downloadFileBlob } from "$root/utils/files";
import type { Setter } from "$root/utils/functions";
import { objMatchFn } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import type { ContextContent } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useSearchParams } from "$root/utils/react-router-extra";
import { noop } from "$root/utils/runtime";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import type { ActionOrActionWithGroup, ActionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	DialogHeader,
	DropdownMenuActionButton,
	FormField,
	Icon,
	ProgressBar,
	Searchable,
	Select,
	SubmitButton,
	Tab,
	TabGroup,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import * as dateFn from "date-fns";
import equal from "fast-deep-equal";
import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import type { ReportTemplateVariant } from "../PortfolioStudioSettings/ReportEditor/version/report-v1";
import "../Portfolios/Portfolios.scss";
import { CrudModal, PortfolioStudioTab } from "../PortfoliosStudio";
import { spawnPortfolioTemplateChooser } from "../PortfoliosStudio/PortfolioList";
import Overview from "./Overview";
import { PortfolioCommentary as PortfolioCommentaryV2 } from "./PortfolioCommentaryV2";
import { PortfolioCommentary } from "./PortfolioCommentary";

type PortfolioStatusVariants =
	| "isOptimize"
	| "isEnhance"
	| "isEnhancable"
	| "Error"
	| "enhanceRequested"
	| "isInit"
	| "isUpload";
export type TgridName = "PORTFOLIO_DETAILS" | "PORTFOLIO_ENHANCEMENT" | "PORTFOLIO_REFERENCE";
type PortfolioStatusProps = Record<PortfolioStatusVariants, boolean>;
export interface IextendedInvestmentsModel extends InvestmentSummary, PortfolioStatusProps {}
export interface IextendedInvestmentEnhancedDetails extends InvestmentSummary, PortfolioStatusProps {
	alerts?: AlertDto[];
}

type PortfolioConfiguration<T extends string> = {
	[StatusKey in InvestmentStatuses]?: { [ActionKey in InvestmentActions]?: T };
};

interface InvestmentsProps {
	investmentList: InvestmentListEntry[] | InvestmentReferenceListEntry[];
	investmentsHistory: InvestmentsModel[];
}

interface PortfolioMetricsProps {
	portfolioUid: string;
	isEnhanceSelected: boolean;
	userToggledEnhancedRef: React.MutableRefObject<boolean>;
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	setIsEnhanceSelected: Setter<boolean>;
	setCrudAction: React.Dispatch<React.SetStateAction<UsePerformCrudActions | undefined>>;
	requestEnhance: () => void;
	setCurrentTab: (tabIndex: number) => void;
	setIsEnhanceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isEnhanceModalOpen: boolean;
	refetchPortfolioDetails: () => Promise<unknown>;
	refetchComparativeBenchmark: () => Promise<unknown>;
	isMarketScenarioEnhancable?: boolean;
	currentTab: number;
	editable: boolean;
	edit: boolean;
	onEdit: () => void;
	onCancelEdit: () => void;
	canSave: boolean;
	onSave: () => MaybePromise<void>;
	customActions?: ReactNode;
}

type PageProps = {
	portfolioUid: string;
};

const PAGE_NAME = "PORTFOLIO_DETAILS";
const ENH_PAGE_NAME = "PORTFOLIO_ENHANCEMENT";
const REFERENCE_PAGE_NAME = "PORTFOLIO_REFERENCE";

const PortfolioMetrics: FC<PortfolioMetricsProps> = ({
	portfolio,
	isEnhanceSelected,
	setIsEnhanceSelected,
	refetchPortfolioDetails,
	refetchComparativeBenchmark,
	currentTab,
	editable,
	edit,
	onEdit,
	canSave,
	onSave,
	onCancelEdit,
	customActions,
}) => {
	const {
		status,
		action,
		actualAction,
		isEnhance,
		isEnhancable,
		isOptimize,
		isUpload,
		reference,
		modificationDate,
		uuid,
		syncDate,
		lastActionDate,
		importedExternally,
		richAcl,
	} = portfolio ?? {};
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const [isEnhancing, setIsEnhancing] = useState(false);
	const investmentEnhanceApi = useApiGen(InvestmentEnhancementControllerV4ApiFactory);

	useGroupEventBus(
		"investment-update",
		() => {
			refetchComparativeBenchmark()
				.then(() => refetchPortfolioDetails().catch(noop))
				.catch(noop);
		},
		{
			filter: objMatchFn({ uuid }),
		},
	);

	useGroupEventBus(
		"shared-entity",
		() => {
			refetchComparativeBenchmark()
				.then(() => refetchPortfolioDetails().catch(noop))
				.catch(noop);
		},
		{
			filter: objMatchFn({ sharedEntityUuid: uuid }),
		},
	);

	const { push } = useTypedNavigation();
	const handleEnhanceStatus = async (accept: boolean) => {
		try {
			setIsEnhancing(true);
			if (!uuid) {
				throw new Error("portfolio not found");
			}

			if (accept) {
				await investmentEnhanceApi.acceptEnhancement(uuid);
			} else {
				await investmentEnhanceApi.rejectEnhancement(uuid);
			}

			trackMixPanelEvent("Portfolio", {
				Type: accept ? "Accept" : "Reject",
				ID: uuid,
			});

			setIsEnhancing(false);
			setIsEnhanceSelected(false); // portfolio is unmounting set state no needed
		} catch (error) {
			reportPlatformError(
				error,
				"ERROR",
				"portfolio",
				`${action ? "accept" : "reject"} enhancement on investment "${uuid}"`,
			);
			setIsEnhancing(false);
			setIsEnhanceSelected(false);
		}
	};

	const user = useUserValue();

	if (!portfolio) {
		return <div className="h-16 bg-white rounded" />;
	}

	return (
		<div className="flex justify-between py-2.5">
			<div className="w-full flex items-center space-x-4 text-[#585D68] overflow-x-auto pr-2">
				<div className="flex items-center" data-qualifier="PortfolioDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: status ?? "-", component: "" }} />
				</div>
				<div className="flex items-center space-x-2" data-qualifier="PortfolioDetails/PageHeader/Action">
					<p className="uppercase whitespace-nowrap">{t("PORTFOLIOS.PF_LAST_ACTION")} </p>
					<p className="font-bold uppercase whitespace-nowrap">
						{action ? t(`PORTFOLIO_ACTION_STATUS.${actualAction as InvestmentActions}`) : "-"}
					</p>
					{modificationDate && <div className="whitespace-nowrap">({formatDate(lastActionDate)})</div>}
				</div>
				{importedExternally && syncDate && user.automaticImport && (
					<div className="flex space-x-2 items-center">
						<Icon icon="Sync" color={themeCSSVars.palette_N400} size={20} />
						<div className="flex items-center">
							<span className="uppercase whitespace-nowrap">AUTO-SYNC </span>&nbsp;
							<span className="font-bold uppercase whitespace-nowrap">ACTIVE</span> &nbsp; -&nbsp;
							<span className="uppercase whitespace-nowrap">LAST IMPORT</span>&nbsp;
							<span className="font-bold uppercase whitespace-nowrap">
								{dateFn.format(new Date(syncDate), "MM/dd/yyyy KK:mm")}
							</span>
						</div>
					</div>
				)}
				<EntityStatus
					accessControl={richAcl}
					entity="INVESTMENT"
					entityId={portfolio?.uuid}
					entityName={portfolio?.name}
					refetch={refetchPortfolioDetails}
				/>
			</div>
			<div className="flex items-center space-x-1">
				{customActions}
				{!reference && isEnhance ? (
					!isEnhanceSelected ? (
						currentTab === 2 ? (
							<div className="flex gap-2">
								<AuthorizationGuard permissionChecker={aclByArea.portfolio.canEditSettings} acl={richAcl?.acl ?? []}>
									{!edit ? (
										<Button
											type="button"
											palette="secondary"
											size="small"
											disabled={!editable}
											onClick={onEdit}
											classList={{ "!px-3": true }}
										>
											<Icon icon="Edit" color={editable ? themeCSSVars.palette_P500 : undefined} size={20} />
											&nbsp;Edit Settings
										</Button>
									) : (
										<>
											<Button
												type="button"
												palette="tertiary"
												size="small"
												onClick={onCancelEdit}
												classList={{ "!px-3": true }}
											>
												Cancel
											</Button>
											<AsyncButton
												palette="secondary"
												size="small"
												onClickAsync={onSave}
												disabled={!canSave}
												classList={{ "!px-3": true }}
											>
												<Icon icon="save" size={20} />
												&nbsp;Save Settings
											</AsyncButton>
										</>
									)}

									<Button size="small" palette="info" onClick={() => setIsEnhanceSelected(true)} disabled={edit}>
										<Icon icon="Icon-full-small" />
										&nbsp;Open Proposal
									</Button>
								</AuthorizationGuard>
							</div>
						) : (
							<Button size="small" palette="info" onClick={() => setIsEnhanceSelected(true)}>
								<Icon icon="Icon-full-small" />
								&nbsp;Open Proposal
							</Button>
						) // TODO: translate
					) : isOptimize || !isEnhance || !isEnhanceSelected || isUpload ? (
						<></>
					) : (
						<div className="space-x-2 flex items-center">
							<Button
								size="small"
								palette="infoOutline"
								disabled={isEnhancing}
								onClick={() => {
									setIsEnhanceSelected(false);
								}}
								style={{ borderColor: "#03a0dc" }}
							>
								Back to current {/* TODO: translate */}
							</Button>

							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
								<Button
									size="small"
									palette="info"
									disabled={isEnhancing}
									onClick={unpromisify(() => handleEnhanceStatus(true))}
								>
									<Icon icon="Outline" />
									&nbsp;Accept {/* TODO: translate */}
								</Button>
							</AuthorizationGuard>

							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
								<Button
									size="small"
									palette="info"
									onClick={() => push("EditPortfolioCompositionPage", { portfolioUid: uuid! })}
									disabled={isEnhancing}
								>
									<Icon icon="Edit" />
									&nbsp;Edit {/* TODO: translate */}
								</Button>
							</AuthorizationGuard>

							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
								<Button
									size="small"
									palette="info"
									disabled={isEnhancing}
									onClick={unpromisify(() => handleEnhanceStatus(false))}
								>
									<Icon icon="Close" />
									&nbsp;Reject {/* TODO: translate */}
								</Button>
							</AuthorizationGuard>
						</div>
					)
				) : !portfolio?.reference ? (
					currentTab === 2 ? (
						<div className="flex gap-2">
							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canEditSettings} acl={richAcl?.acl ?? []}>
								{!edit ? (
									<Button
										type="button"
										palette="secondary"
										size="small"
										disabled={!editable}
										onClick={onEdit}
										classList={{ "!px-3": true }}
									>
										<Icon icon="Edit" color={editable ? themeCSSVars.palette_P500 : undefined} size={20} />
										&nbsp;Edit Settings
									</Button>
								) : (
									<>
										<Button
											type="button"
											palette="tertiary"
											size="small"
											onClick={onCancelEdit}
											classList={{ "!px-3": true }}
										>
											Cancel
										</Button>
										<AsyncButton
											palette="secondary"
											size="small"
											onClickAsync={onSave}
											disabled={!canSave}
											classList={{ "!px-3": true }}
										>
											<Icon icon="save" size={20} />
											&nbsp;Save Settings
										</AsyncButton>
									</>
								)}
							</AuthorizationGuard>{" "}
							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
								<Button
									type="button"
									palette="primary"
									size="small"
									disabled={!isEnhancable || edit}
									onClick={() => push("Portfolios/EditPortfolio", { portfolioUid: uuid! })}
									classList={{ "!px-3": true }}
								>
									<Icon icon="Enhance" color={PaletteColors.WHITE} size={20} />
									&nbsp;Create Proposal
								</Button>
							</AuthorizationGuard>
						</div>
					) : (
						<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
							<Button
								type="button"
								palette="primary"
								size="small"
								disabled={!isEnhancable}
								onClick={() => push("Portfolios/EditPortfolio", { portfolioUid: uuid! })}
								classList={{ "!px-3": true }}
							>
								<Icon icon="Enhance" color={PaletteColors.WHITE} size={20} />
								&nbsp;Create Proposal
							</Button>
						</AuthorizationGuard>
					)
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

type SelectBenchmarkModalProps = {
	comparativeBenchmarks: CompatibleBenchmarkEntry[];
	show: boolean;
	onClose(): void;
	onSubmitAsync(params: { benchmarks: CompatibleBenchmarkEntry[] }): Promise<void>;
};

function SelectBenchmarkModal({ comparativeBenchmarks, show, onSubmitAsync, onClose }: SelectBenchmarkModalProps) {
	const multiSelectCtx = useMultiSelect<string>();
	useEffect(() => {
		multiSelectCtx.actions.setSelection(
			comparativeBenchmarks.filter((x) => x.linked).map(({ identifier }) => identifier ?? ""),
		);
	}, [comparativeBenchmarks, multiSelectCtx.actions]);

	return (
		<Dialog
			header="Select benchmarks" // TODO: translate
			show={show}
			onClose={onClose}
			size="large"
			onSubmitAsync={async () =>
				onSubmitAsync({
					benchmarks: comparativeBenchmarks.filter(({ identifier }) =>
						identifier ? multiSelectCtx.data.selection.toArray().includes(identifier) : false,
					),
				})
			}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							Close
						</Button>
					}
					primaryAction={<SubmitButton>Confirm</SubmitButton>}
				/>
			}
			classList="!w-[960px]"
		>
			<Searchable
				matchFn={(a, query) => (a.name ?? "").toLowerCase().includes(query.toLowerCase())}
				collection={comparativeBenchmarks ?? []}
			>
				{function Inner({ filtered, query, setQuery }) {
					const { column: checkboxColumn, rowClassList } = useSelectableTableColumn({
						rows: comparativeBenchmarks,
						multiSelectCtx,
						filteredRows: filtered,
						selectBy: (r) => r.identifier!,
						selectableRowIds: comparativeBenchmarks
							.filter(({ primary }) => primary === false)
							.map(({ identifier }) => identifier ?? ""),
					});
					return (
						<>
							<div className="pb-4">
								<TextInput placeholder="Search..." value={query} onChangeText={setQuery} />
							</div>
							<Table
								rows={filtered}
								rowClassList={rowClassList}
								columns={[
									checkboxColumn,
									{
										//FIXME: if linked add push to custom benchmarks page
										header: "Name",
										content: ({ name }) => name,
										relativeWidth: 65,
									}, // TODO: will be added when the benchmark area will be build
									// {
									// 	header: "Macro Exposure",
									// 	content: ({ exposureSummary }) => exposureSummary,
									// },
									{
										header: "Type",
										content: ({ primary }) => (primary ? "Portfolio benchmark" : "Benchmark"),
										relativeWidth: 25,
									},
								]}
								visibleRows={8}
								rowStyle={({ identifier }) => {
									const { Table_highlightedRowBackgroundColor } = themeCSSVars;
									const checked = multiSelectCtx.data.selection.get(identifier ?? "");
									if (checked) {
										return { backgroundColor: Table_highlightedRowBackgroundColor };
									}

									return {};
								}}
								noDataText={
									<div className="flex items-center justify-center h-20">
										<p>No Data</p>
									</div>
								}
							/>
						</>
					);
				}}
			</Searchable>
		</Dialog>
	);
}

const LeaveUnsavedDataDialog = ({
	when,
	pathToNotBlock,
	onDiscard,
	onProceed,
	onStay,
}: {
	when: boolean;
	pathToNotBlock?: string[];
	onDiscard(): MaybePromise<void>;
	onProceed(): MaybePromise<void>;
	onStay?(): void;
}) => {
	const { t } = useTranslation();
	return (
		<LeavePromptBase when={when} pathToNotBlock={pathToNotBlock}>
			{({ showPrompt, onCancelNavigation, onContinueNavigation }) => (
				<Dialog
					show={showPrompt}
					size="large"
					onClose={() => {
						onStay?.();
						onCancelNavigation();
					}}
					header={
						<DialogHeader icon={<Icon icon="Icon-full-alert" color={themeCSSVars.MessageSeverity_warning} size={22} />}>
							{t("LEAVE_PAGE")}
						</DialogHeader>
					}
					footer={
						<DialogFooter
							primaryAction={
								<AsyncButton
									onClickAsync={async () => {
										await onProceed();
										onContinueNavigation();
									}}
									palette="primary"
								>
									{t("BUTTON.SAVE_PROCEED")}
								</AsyncButton>
							}
							secondaryAction={
								<AsyncButton
									onClickAsync={async () => {
										await onDiscard();
										onContinueNavigation();
									}}
									palette="secondary"
								>
									{t("BUTTON.DISCARD_PROCEED")}
								</AsyncButton>
							}
							neutralAction={
								<Button
									onClick={() => {
										onStay?.();
										onCancelNavigation();
									}}
									palette="tertiary"
								>
									{t("BUTTON.STAY")}
								</Button>
							}
						/>
					}
				>
					{t("PORTFOLIOS.CREATE_LEAVE")}
				</Dialog>
			)}
		</LeavePromptBase>
	);
};

const severityByStatus: Record<InvestmentStatuses, NonNullable<PageHeaderProps["severity"]>> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: "info",
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

const dropdownMenuActionClassList = "w-[290px]";
const PortfolioDetails = (): JSX.Element => {
	const isEnhanceSelected = useSearchParams().proposal === "true";
	const history = useHistory();
	const setIsEnhanceSelected = useCallback(
		(newIsEnhanceSelected: boolean) => {
			const queryParams = new URLSearchParams(history.location.search);
			if (newIsEnhanceSelected === isEnhanceSelected) {
				return;
			}
			if (newIsEnhanceSelected) {
				queryParams.set("proposal", "true");
			} else {
				queryParams.delete("proposal");
			}
			history.replace({ search: queryParams.toString() });
		},
		[history, isEnhanceSelected],
	);

	const [crudAction, setCrudAction] = useState<UsePerformCrudActions | undefined>(undefined);
	const [isMarketScenarioEnhancableState, setIsMarketScenarioEnhancableState] = useState(false);
	const [isPortfolioNameAvailable, setIsPorfolioNameAvailable] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEnhanceModalOpen, setIsEnhanceModalOpen] = useState(false);
	const [reportsExecution, setReportsExecution] = useState<{ [key in InvestmentReportsBatchOrderingEnum]?: boolean }>(
		{},
	);
	const [reportExcutionCounter, setReportExcutionCounter] = useState(0);

	const [showComparativeBenchmarkModal, setShowComparativeBenchmarkModal] = useState(false);
	const [selectedComparativeBenchmark, setSelectedComparativeBenchmark] = useState<string | null>(null);

	const freezedConfigurationStepRef = useRef<EditPortfolioV4StepPayloadMap | undefined>(undefined);

	const [onStartEnhanceWrap, setOnStartEnhanceWrap] = useState<null | {
		onStartEnhance: () => void;
	}>(null);

	const userToggledEnhancedRef = useRef(false);
	const user = useUserValue();
	const { t } = useTranslation();
	const currentTab = Number(useSearchParams().tab ?? "0");

	const { performAction } = usePerformCrud("portfolio");
	const { portfolioUid } = useParams<PageProps>();
	const investmentsApi = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentBenchmarksController = useApiGen(InvestmentBenchmarksControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const staticApi = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const setCurrentTab = useCallback(
		(tabIndex: number) => {
			if (currentTab === tabIndex) {
				return;
			}
			const newSearchParams = new URLSearchParams(history.location.search);
			if (tabIndex !== 2) {
				newSearchParams.delete("edit");
			}
			newSearchParams.set("tab", String(tabIndex));
			history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
		},
		[currentTab, history],
	);

	const { push } = useTypedNavigation();
	const pushRef = useUpdatedRef(push);

	// check if portfolioUid exist
	useEffect(() => {
		if (!portfolioUid) {
			pushRef.current("PortfoliosStudio", { status: "notFound" });
		}
	}, [history, portfolioUid, pushRef]);

	useFunctionalAreas(
		() => ({
			areas: "portfolio",
			data: { uuid: portfolioUid },
		}),
		[portfolioUid],
	);

	const onChangePortfolioUid = useCallback(
		(uuid: string) => {
			setIsEnhanceSelected(false);
			push("PortfolioDetails", { portfolioUid: uuid! });
		},
		[push, setIsEnhanceSelected],
	);

	const createPortfolioStatus = (action?: InvestmentActions, status?: InvestmentStatuses) => {
		const STATUS = [
			"isOptimize",
			"isEnhance",
			"isEnhancable",
			"Error",
			"enhanceRequested",
			"isInit",
			"isUpload",
			"isReview",
		] satisfies Array<
			"isOptimize" | "isEnhance" | "isEnhancable" | "Error" | "enhanceRequested" | "isInit" | "isUpload" | "isReview"
		>;

		const configuration: PortfolioConfiguration<(typeof STATUS)[number]> = {
			CALCULATING: {
				ENHANCEMENT: "enhanceRequested",
				UPLOAD: "isInit",
				CREATION: "isInit",
				CLONE: "isInit",
			},
			PROPOSAL_READY: {
				OPTIMIZATION: "isOptimize",
				ENHANCEMENT: "isEnhance",
			},
			RETRIEVING_DATA: {
				UPLOAD: "isUpload",
				CREATION: "isUpload",
				ENHANCEMENT: "isUpload",
				OPTIMIZATION: "isUpload",
			},
			REVIEW: {
				UPLOAD: "isUpload",
				CREATION: "isUpload",
				ENHANCEMENT: "isUpload",
				OPTIMIZATION: "isUpload",
			},
			ERROR: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "Error" }),
				{},
			),
			READY: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "isEnhancable" }),
				{},
			),
			ACCEPTED: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "isEnhancable" }),
				{},
			),
		};

		const assocUndefinedStatus = (portfolioStatus: string) => ({ [portfolioStatus]: false });
		return !action || !status
			? STATUS.map(assocUndefinedStatus)
			: STATUS.map((portfolioStatus) => {
					const portfolioConf = configuration?.[status]?.[action] ?? "Error";
					return { [portfolioStatus]: portfolioConf === portfolioStatus };
			  });
	};

	// UseQueryNoRefetch

	// current portfolio data api call
	const {
		data: portfolio,
		isLoading: isLoadingInvestmentSummary,
		isError,
		refetch: refetchPortfolioDetails,
	} = useQueryNoRefetch<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails, AxiosError<CustomAxiosError>>(
		["portoflioDetails", isEnhanceSelected, portfolioUid],
		{
			enabled: Boolean(portfolioUid),
			queryFn: async () => {
				let response;
				if (!isEnhanceSelected) {
					const { data } = await investmentReportApi.getInvestmentSummary(portfolioUid);
					response = data;
				} else {
					const { data } = await investmentEnhancementReportApi.getInvestmentEnhancementSummary(portfolioUid);
					response = data;
				}

				const { action, status } = response;
				const mappedPortfolioStatus = createPortfolioStatus(action, status);

				const portfolioStatus = mappedPortfolioStatus.reduce((acc, curr) => {
					const currentValue = Object.values(curr)[0];
					const currentKey = Object.keys(curr)[0] as string;
					return {
						...acc,
						[currentKey]: currentValue,
					};
				}, {}) as PortfolioStatusProps;

				return {
					...response,
					...portfolioStatus,
				};
			},
			onSuccess: (data) => {
				if (!isEnhanceSelected) {
					const { modificationDate } = data ?? {};
					const date = modificationDate ? dateToStringWithoutTime(new Date(modificationDate)) : "";
					const newSearchParams = new URLSearchParams(history.location.search);
					newSearchParams.set("date", String(date));
					history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
					if (!userToggledEnhancedRef.current) {
						const { status, action } = data ?? {};
						// setIsEnhanceSelected(enhanceToBeApproved); // TODO:
					}
				}
			},
			onError: (error) => {
				const { data } = error.response ?? {};
				if (data?.message === "Accesso negato" || data?.code === 404) {
					spawnAccessDialog({
						onClick: (onClose) => {
							push("PortfoliosStudio", {});
							onClose();
						},
					});
					return;
				}

				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
				push("PortfoliosStudio", { status: "error" });
			},
		},
	);

	useGroupEventBus(
		"investment-report-update",
		() => {
			refetchComparativeBenchmark()
				.then(() => refetchPortfolioDetails().catch(noop))
				.catch(noop);
		},
		{
			filter: objMatchFn({ uuid: portfolioUid }),
		},
	);

	useEventBus("investment-report-update", (e) => {
		if (e.executedReports) {
			setReportsExecution((executions) => {
				const shallowCopy = { ...executions };
				customObjectEntriesFn(executions).forEach(([key, v]) => {
					if (shallowCopy[key]) {
						shallowCopy[key] = v;
					}
				});
				return shallowCopy;
			});
		}
	});

	useGroupEventBus(
		"investment-report-update",
		(e) => {
			setReportExcutionCounter((c) => c + 1);
		},
		{
			timeout: 10000,
		},
	);

	// user portfolio list api call
	const {
		data: investments,
		refetch: refetchPortfolioInvesments,
		isLoading: areInvestmentsLoading,
	} = useQueryNoRefetch<InvestmentsProps, AxiosError<CustomAxiosError>>(["portfolioInvesments"], {
		enabled: Boolean(portfolioUid),
		queryFn: async () => {
			const { data: investment } = await investmentReportApi.getInvestmentSummary(portfolioUid);
			const { action, uuid, modificationDate, reference } = investment;
			if (reference) {
				const { data: investmentList } = await investmentsApi.getInvestmentReferenceList();
				return {
					investmentList,
					investmentsHistory: [{ modificationDate, action, _id: uuid }],
				};
			}

			const { data: investmentList } = await investmentsApi.getInvestmentList();

			return {
				investmentList,
				investmentsHistory: [{ modificationDate, action, _id: uuid }],
			};
		},
		onError: (err) => {
			console.error(err);
		},
	});

	// portfolio alert api call
	const { data: portfolioAlert } = useQueryNoRefetch(["portfolioAlerts", isEnhanceSelected, portfolioUid], {
		//enabled: Boolean(portfolioUid && !isEnhance && portfolio),
		enabled: Boolean(portfolioUid),
		queryFn: async () => {
			// FIXME: Verify and Unify Data with Alerts
			let alerts;
			if (!isEnhanceSelected) {
				const { data } = await investmentReportApi.getInvestmentSummary(portfolioUid);
				alerts = (data.alerts ?? []).filter((a) => Boolean(a.type)) as PortfolioAlert[];
			} else {
				const { data } = await investmentEnhancementReportApi.getInvestmentEnhancementSummary(portfolioUid);
				alerts = (data.alerts ?? []).filter((a) => Boolean(a.type)) as PortfolioAlert[];
			}
			return alerts;
		},
		onError: (err) => {
			console.error(err);
		},
	});

	// associated benchmark to the portfolio api call
	const {
		data: comparativeBenchmarks,
		isLoading: isLoadingComparativeBenchmarks,
		refetch: refetchComparativeBenchmark,
	} = useQueryNoRefetch(["comparativeBenchmarks", portfolioUid], {
		queryFn: async () => {
			if (!portfolioUid) {
				throw Error(`cannot find portfolio with uuid:${portfolioUid}`);
			}
			const { data } = await investmentBenchmarksController.getInvestmentBenchmarks(portfolioUid);
			return data;
		},
		onSuccess: (benchmarks) => {
			const hasPortfolioBenchmarksId = benchmarks.find((b) => b.primary === true);
			if (hasPortfolioBenchmarksId?.identifier) {
				setSelectedComparativeBenchmark(hasPortfolioBenchmarksId.identifier);
			}
		},
	});

	// benchmarks that could be associated with the current investments
	const { data: compatibleBenchmarksIds, isLoading: isLoadingCompatibleBenchmarksIds } = useQueryNoRefetch(
		["compatibleBenchmarks", portfolioUid],
		{
			enabled: Boolean(portfolioUid),
			queryFn: async () => {
				if (!portfolioUid) {
					throw Error(`cannot find portfolio with uuid:${portfolioUid}`);
				}
				const { data } = await investmentBenchmarksController.getCompatibleBenchmarks(portfolioUid);
				return data;
			},
		},
	);

	// api resp data splitting
	const { reference, isEnhancable, status, uuid } = portfolio ?? {};
	const isPortfolioEnhancable = isEnhancable;

	//  widgetGridName
	const composeGridName = useCallback((isPortfolioEnhance: boolean, isPortfolioReference: boolean) => {
		if (isPortfolioReference) {
			return REFERENCE_PAGE_NAME;
		}

		if (isPortfolioEnhance) {
			return ENH_PAGE_NAME;
		}

		return PAGE_NAME;
	}, []);

	const gridName = composeGridName(isEnhanceSelected, Boolean(reference));

	const registerStartEnhanceCallback = useCallback((onStartEnhance: () => void) => {
		setOnStartEnhanceWrap({ onStartEnhance });
	}, []);

	const requestEnhance = useCallback(() => {
		push("Portfolios/EditPortfolio", { portfolioUid });
	}, [push, portfolioUid]);

	// context
	// those callback context make widgets depending to ptf details
	const portfolioContextData = useMemo<ContextContent<typeof PortfolioContext>>(
		() => ({
			enhanced: isEnhanceSelected,
			portfolio: portfolio ?? null,
			selectedBenchmark: selectedComparativeBenchmark,
			alerts: portfolioAlert ?? [],
			isMarketScenarioEnhancable: isMarketScenarioEnhancableState,
			currentTab,
			updateMarketScenario: setIsMarketScenarioEnhancableState,
			requestEnhance: isPortfolioEnhancable ? requestEnhance : undefined,
			requestOptimize: isPortfolioEnhancable ? () => setIsModalOpen(true) : undefined,
			onChangePortfolioUid,
			registerStartEnhanceCallback,
			reportsExecution,
			reportExcutionCounter,
		}),
		[
			isEnhanceSelected,
			portfolio,
			selectedComparativeBenchmark,
			portfolioAlert,
			isMarketScenarioEnhancableState,
			currentTab,
			isPortfolioEnhancable,
			requestEnhance,
			onChangePortfolioUid,
			registerStartEnhanceCallback,
			reportsExecution,
			reportExcutionCounter,
		],
	);

	// crud actions
	const onCloseModal = useCallback(() => setCrudAction(undefined), []);
	const onDelete = useCallback(
		async (name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				await performAction({ action: "delete", uuid });
				platformToast({
					children: t("PORTFOLIOS.DELETE_OK_MESSAGE", { portfolioName: name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `delete investment "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				onCloseModal();
				setTimeout(() => push("PortfoliosStudio", {}), 1000);
			}
		},
		[performAction, t, onCloseModal, push],
	);

	const onSubmit = useCallback(
		async (action: Exclude<UsePerformCrudActions, "delete">, name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				const { data: isNameAvailable } = await investmentsApi.isInvestmentNameAvailable(name);
				if (isNameAvailable === false) {
					setIsPorfolioNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });
				const notficationMessage: Record<typeof action, string> = {
					duplicate: t("PORTFOLIOS.DUPLICATE_OK_MESSAGE", { portfolioName: name }),
					rename: t("PORTFOLIOS.RENAME_OK_MESSAGE", { portfolioName: name }),
				};
				platformToast({
					children: notficationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				if (action === "rename") {
					await refetchPortfolioDetails();
					await refetchPortfolioInvesments();
					onCloseModal();
					return;
				}

				if (action === "duplicate") {
					onCloseModal();
					setTimeout(() => push("PortfoliosStudio", {}), 1000);
				}
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `${action} investment "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[investmentsApi, performAction, t, refetchPortfolioDetails, refetchPortfolioInvesments, onCloseModal, push],
	);

	const investmentsPool = useMemo(() => {
		if (investments) {
			const generateLabels = ({
				name,
				uuid,
				status,
			}: {
				name?: string;
				uuid?: string;
				status?: InvestmentStatuses;
			}) => ({
				label: `${name}${status === "DRAFT" ? "(draft)" : ""}`,
				value: uuid!,
				disabled: status === "DRAFT",
			});
			return investments.investmentList.sort(builtInSortFnFor("creationTime")).reverse().map(generateLabels);
		}

		return [{ label: "No portfolio available", value: portfolioUid }];
	}, [investments, portfolioUid]);

	let linkedPortfolios = 0;
	const { formatDate } = useLocaleFormatters();

	const invalidStatus: Array<InvestmentStatuses> = [
		"ERROR",
		"REVIEW",
		"CALCULATING",
		"RETRIEVING_DATA",
		"PROPOSAL_READY",
	];

	const downloadPtfPdf = useCallback(
		async (pdfRelativeUrl: string) => {
			await downloadPdf(
				pdfRelativeUrl,
				isEnhanceSelected
					? `Portfolio_Proposal_Report_${dateFn.format(new Date(), "MMddyyyy")}`
					: `Portfolio_Report_${dateFn.format(new Date(), "MMddyyyy")}`,
			);
		},
		[isEnhanceSelected],
	);

	if (reference) {
		linkedPortfolios = (portfolio as IextendedInvestmentsModel).linkedPortfolios ?? 0;
	}

	const proposalName = !portfolio ? "" : `Proposal ${formatDate(portfolio.modificationDate)}`; // TODO: translate

	// benchmarks
	const comparativeBenchmarkOptions = useMemo(
		() =>
			comparativeBenchmarks
				?.filter(({ identifier }) => identifier !== undefined)
				.map((x) => ({ label: x.name ?? "", value: x.identifier ?? "", disabled: x.ready === false })),
		[comparativeBenchmarks],
	);

	const handleSaveVisibleComparativeBenchmarks = useCallback(
		async ({ benchmarks }: { benchmarks: CompatibleBenchmarkEntry[] }) => {
			try {
				if (!portfolio?.uuid) {
					throw Error(`cannot find portfolio with uuid:${portfolio?.uuid}`);
				}
				await investmentBenchmarksController.setInvestmentBenchmarks({
					benchmarks,
					uuid: portfolio.uuid,
				});
				window.location.reload();
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `set benchmark on investment "${portfolio?.uuid}"`);
				if (typeof error === "string") {
					throw new Error(error);
				} else if (error instanceof Error) {
					throw new Error(error.message);
				}
			}
		},
		[investmentBenchmarksController, portfolio],
	);

	const { edit: editStr } = useSearchParams();
	const edit = editStr === "1";

	const setEdit = useCallback(
		(newEdit: boolean) => {
			const newSearchParams = new URLSearchParams(history.location.search);

			if (newEdit) {
				newSearchParams.set("edit", "1");
			} else {
				newSearchParams.delete("edit");
			}
			history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
		},
		[history],
	);

	const area = useMemo(() => {
		const areaName = isEnhanceSelected ? ("settings-enhanced" as const) : ("settings-current" as const);
		const editable =
			(areaName === "settings-current" &&
				portfolio?.status !== "CALCULATING" &&
				portfolio?.status !== "ERROR" &&
				aclByArea.portfolio.canEditSettings(user.id, portfolio?.richAcl?.acl ?? [])) ||
			areaName !== "settings-enhanced";

		return {
			name: areaName,
			editable,
			portfolioUid,
			edit,
		};
	}, [edit, isEnhanceSelected, portfolio?.richAcl?.acl, portfolio?.status, portfolioUid, user.id]);

	const { investmentConvertionToFile } = useCompositionDownload();
	const [customActions, setCustomActions] = useState<ReactNode | null>(null);

	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const { data: templateList } = useQueryNoRefetch(["queryReportTemplateList"], {
		queryFn: async () => {
			const defautlts = [
				...defaultPortfolioTemplates.map((defaultTemplate) => ({
					...defaultTemplate,
					visible: true,
				})),
			];
			const list = await axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates());
			return [
				...defautlts.filter((x) => !list.find((y) => x.templateName === y.templateName)),
				...list.filter((x) => x.visible),
			];
		},
	});

	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	return (
		<PortfolioContext.Provider value={portfolioContextData ?? {}}>
			<EditPortfolioV4ContextProvider area={area}>
				{({ stepsMetadata, getStepsData, stepsData, currentStep, setStepsMetadata, submittersRef, setStepsData }) => {
					const saveHandler = makeUnsavedDataHandler({
						currentStep,
						setStepsMetadata,
						submittersRef,
					});

					async function handleSubmit<T>(onSuccess: () => Promise<T> | T, onError: () => void) {
						if (stepsMetadata[currentStep].dirty === false) {
							await onSuccess();
							return;
						}

						const shouldContinue = (await saveHandler()) === "continue";
						if (!shouldContinue) {
							onError();
							throw new Error("unable to proceed, some step may require additional data or corrections");
						}
						await onSuccess();
					}

					function pushMissingFields() {
						platformToast({
							children: "Unable to proceed, this step require additional data or correction",
							severity: "info",
							icon: "Portfolio",
						});
					}

					async function onSave() {
						await handleSubmit(async () => {
							try {
								const {
									mainInfo,
									investableUniverse,
									portfolioConstraints,
									portfolioStrategy,
									riskConstraints,
									marketView,
								} = getStepsData();

								const mapper = makeStepToRequestMapper(area.portfolioUid);
								const steps = await axiosExtract(
									staticApi.setStaticConfiguration({
										mainInfo: mapper.mainInfo(mainInfo),
										investableUniverse: mapper.investableUniverse({
											...investableUniverse,
											investableUniverseSelectionStrategy:
												investableUniverse.investableUniverseSelectionStrategy ?? "SELL_INSTRUMENTS",
										}),
										allocationConstraints: mapper.portfolioConstraints(portfolioConstraints),
										strategyConstraints: mapper.portfolioStrategy(portfolioStrategy),
										riskConstraints: mapper.riskConstraints(riskConstraints),
										marketView: mapper.marketView(marketView),
										investmentUuid: portfolioUid,
									}),
								);

								const newStepData = {
									mainInfo: responseToStepMapper.mainInfo(steps.mainInfo ?? {}, area),
									investableUniverse: responseToStepMapper.investableUniverse(steps.investableUniverse ?? {}, area),
									portfolioConstraints: responseToStepMapper.portfolioConstraints(
										steps.allocationConstraints ?? {},
										area,
									),
									portfolioStrategy: responseToStepMapper.portfolioStrategy(steps.strategyConstraints ?? {}, area),
									riskConstraints: responseToStepMapper.riskConstraints(steps.riskConstraints ?? {}, area),
									marketView: responseToStepMapper.marketView(steps.marketView ?? {}, area),
								} satisfies EditPortfolioV4StepPayloadMap;

								freezedConfigurationStepRef.current = newStepData;
								setStepsData(newStepData);
								setEdit(false);
							} catch (error) {
								platformToast({
									children: t("SOMETHING_WENT_WRONG"),
									icon: "Portfolio",
									severity: "error",
								});
								throw error;
							}
						}, pushMissingFields);
					}

					return (
						<PortfolioContext.Consumer>
							{({ isMarketScenarioEnhancable }) => (
								<>
									<PageHeader
										name="PortfolioDetails"
										severity={portfolio?.status ? severityByStatus[portfolio?.status as InvestmentStatuses] : undefined}
										// title={referenceInvestment ? "Portfolio Reference Details" : "Portfolio Details"} // TODO: translate
										title={
											areInvestmentsLoading || isLoadingInvestmentSummary
												? "..."
												: isEnhanceSelected
												  ? proposalName
												  : portfolio?.name ?? "Untitled"
										} // TODO: translate
										titleAction={
											isLoadingComparativeBenchmarks || portfolio?.reference ? undefined : (
												// TODO: translate
												<FormField label="Comparative Benchmark">
													{({ id }) => (
														<div className="flex items-center space-x-2">
															<Select
																style={{ width: 280 }}
																strategy="fixed"
																size="x-small"
																id={id}
																options={comparativeBenchmarkOptions ?? []}
																value={selectedComparativeBenchmark}
																onChange={setSelectedComparativeBenchmark}
															/>
															<Button
																size="x-small"
																palette="neutralOutline"
																disabled={
																	isLoadingCompatibleBenchmarksIds ||
																	["CALCULATING", "RETRIEVING_DATA", "REVIEW"].includes(portfolio?.status ?? "")
																}
																onClick={() => setShowComparativeBenchmarkModal(true)}
															>
																<Icon icon="Outline1" />
															</Button>
														</div>
													)}
												</FormField>
											)
										}
										crumbs={[
											{
												children: "Portfolio studio",
												href: typedUrlForRoute("PortfoliosStudio", {}),
											},
											{
												children: reference ? "References" : "Portfolios", // TODO: translate
												href: reference
													? typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References })
													: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
											},
											...(!isEnhanceSelected
												? [
														{
															children:
																areInvestmentsLoading || isLoadingInvestmentSummary ? (
																	<CircularProgressBar value="indeterminate" style={{ width: 12, height: "auto" }} />
																) : (
																	<Select
																		classList="flex items-center w-40 truncate"
																		strategy="fixed"
																		value={portfolio?.uuid}
																		onChange={(value) => {
																			push("PortfolioDetails", { portfolioUid: value! });
																		}}
																		enableSearch
																		unstyled
																		options={investmentsPool}
																	/>
																),
														},
												  ]
												: [
														{
															children: portfolio?.name,
															onClick: () => setIsEnhanceSelected(false),
														},
												  ]),
											...(!isEnhanceSelected
												? []
												: [
														{
															children: !portfolio ? (
																<CircularProgressBar value="indeterminate" style={{ width: 12, height: "auto" }} />
															) : (
																proposalName
															),
														},
												  ]),
										]}
										subTitle={
											<PortfolioMetrics
												portfolio={portfolio}
												portfolioUid={portfolioUid}
												isEnhanceSelected={isEnhanceSelected}
												userToggledEnhancedRef={userToggledEnhancedRef}
												setIsEnhanceSelected={setIsEnhanceSelected}
												requestEnhance={requestEnhance}
												setCurrentTab={setCurrentTab}
												setCrudAction={setCrudAction}
												currentTab={currentTab}
												setIsEnhanceModalOpen={setIsEnhanceModalOpen}
												isEnhanceModalOpen={isEnhanceModalOpen}
												refetchPortfolioDetails={refetchPortfolioDetails}
												isMarketScenarioEnhancable={isMarketScenarioEnhancable}
												refetchComparativeBenchmark={refetchComparativeBenchmark}
												editable={
													area.editable &&
													status !== "ERROR" &&
													status !== "RETRIEVING_DATA" &&
													status !== "CALCULATING"
												}
												edit={edit}
												onEdit={() => {
													if (freezedConfigurationStepRef.current === undefined) {
														freezedConfigurationStepRef.current = getStepsData();
													}
													setEdit(true);
												}}
												onCancelEdit={() => {
													if (freezedConfigurationStepRef.current !== undefined) {
														setStepsData(freezedConfigurationStepRef.current);
														freezedConfigurationStepRef.current = undefined;
													}
													setEdit(false);
												}}
												canSave //canSubmit
												onSave={onSave}
												customActions={customActions}
											/>
										}
										titleMenuActions={
											isEnhanceSelected
												? [
														...(templateList ?? []).map(
															(template): ActionWithOptionalGroup<""> => ({
																group: "DOWNLOAD",
																children: ({ onClose }) => (
																	<DropdownMenuActionButton
																		icon="pdf"
																		onClickAsync={async () => {
																			if (portfolio?.status === "PROPOSAL_READY") {
																				spawnPortfolioTemplateChooser({
																					investments: [
																						{ uuid: uuid!, name: portfolio.name, status: portfolio.status },
																					],
																					onSubmitAsync: async (data) => {
																						const pages = data.map((ptf) =>
																							typedUrlForRoute("Report", {
																								templateId: isDefaultReportTemplate(template)
																									? template.id
																									: template.uuid!,
																								objectId: ptf.uuid!,
																								variant:
																									ptf.choice === "current"
																										? "current"
																										: ("proposal" satisfies ReportTemplateVariant),
																							}),
																						);

																						await parallelize(pages.map((url) => () => downloadPtfPdf(url)));
																					},
																				});
																				onClose();
																				return;
																			}

																			await downloadPtfPdf(
																				typedUrlForRoute("Report", {
																					templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
																					objectId: uuid!,
																					variant: "proposal" satisfies ReportTemplateVariant,
																				}),
																			);
																			onClose();
																		}}
																	>
																		{(template.templateName ?? "").concat(" (pdf)")}
																	</DropdownMenuActionButton>
																),
															}),
														),
														...(portfolio?.uuid
															? [
																	{
																		group: "DOWNLOAD",
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				key="composition"
																				icon="Dowload"
																				onClickAsync={async () => {
																					const { data, headers } = await exportEnhancedApi.exportEnhancedComposition(
																						portfolio.uuid!,
																						"FULL_COMPOSITION",
																						{ responseType: "blob" },
																					);
																					const fileType = headers["content-type"];
																					const fileName = headers["content-disposition"].split("filename=")[1];
																					downloadFileBlob({ data, name: fileName, type: fileType });
																					onClose();
																				}}
																			>
																				Instrument composition (xlsx)
																			</DropdownMenuActionButton>
																		),
																	} satisfies ActionOrActionWithGroup<"">,
															  ]
															: []),
														...(hasAccess(user, { requiredService: "EXPORT" }) && portfolio?.uuid
															? (user.exportFormats ?? []).map(
																	(format): ActionOrActionWithGroup<""> => ({
																		group: "DOWNLOAD",
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				icon="xls"
																				key="customDownload"
																				onClickAsync={async () => {
																					if (format === InvestmentExportConverterType.EasimTemplateConverter) {
																						const composition = await axiosExtract(
																							integrationsApi.exportInvestment(portfolio.uuid!, true),
																						);
																						await axiosExtract(integrationsApi.convertTo(format, [composition], true));
																						platformToast({
																							children: "Sphere has taken over your request",
																							severity: "info",
																							icon: "Dowload",
																						});
																						return;
																					}

																					const conversion = await investmentConvertionToFile(
																						format,
																						portfolio.uuid!,
																						true,
																						// format === InvestmentExportConverterType.EasimTemplateConverter,
																					);
																					// if (format !== InvestmentExportConverterType.EasimTemplateConverter) {
																					downloadFileBlob(conversion, {
																						// TODO: remove extension once BE updates their filenames so that it's included
																						fileName: `${conversion.name}`,
																					});
																					// } else {
																					// platformToast({
																					// 	children: "Sphere has taken over your request",
																					// 	severity: "info",
																					// 	icon: "Dowload",
																					// });
																					// }
																					trackMixPanelEvent("Portfolio", {
																						Type: "Export",
																						Area: "composition",
																						ID: portfolio!.uuid!,
																					});
																					onClose();
																				}}
																			>
																				{`Export ${t(`EXPORT.${format}`).toLowerCase()} template (${
																					format === "EASIM_TEMPLATE_CONVERTER" ? "zip" : "xlsx"
																				})`}
																			</DropdownMenuActionButton>
																		),
																	}),
															  )
															: []),
														...(hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" })
															? [
																	{
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				icon="Settings"
																				key="report"
																				classList={`border-t border-[${themeCSSVars.palette_N200}]`}
																				onClickAsync={() => {
																					push("PortfolioStudioSettings", {
																						tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
																					});
																					onClose();
																				}}
																			>
																				Report customisation
																			</DropdownMenuActionButton>
																		),
																	} satisfies ActionOrActionWithGroup<"">,
															  ]
															: []),
												  ]
												: [
														{
															group: "EDIT",
															children: ({ onClose }) => (
																<AuthorizationGuard
																	permissionChecker={aclByArea.portfolio.canDelete}
																	acl={portfolio?.richAcl?.acl ?? []}
																>
																	<DropdownMenuActionButton
																		icon="Delete"
																		data-qualifier="PortfolioDetails/DropdownMenu/DropdownItem(Delete)"
																		classList={dropdownMenuActionClassList}
																		onClick={() => {
																			setCrudAction("delete");
																			onClose();
																		}}
																		disabled={linkedPortfolios > 0}
																	>
																		Delete
																	</DropdownMenuActionButton>
																</AuthorizationGuard>
															),
														},
														{
															group: "EDIT",
															children: ({ onClose }) => (
																<DropdownMenuActionButton
																	icon="Content-Copy"
																	data-qualifier="PortfolioDetails/DropdownMenu/DropdownItem(Duplicate)"
																	classList={dropdownMenuActionClassList}
																	onClick={() => {
																		setCrudAction("duplicate");
																		onClose();
																	}}
																	disabled={status ? invalidStatus.includes(status as InvestmentStatuses) : true}
																>
																	Duplicate
																</DropdownMenuActionButton>
															),
														},
														{
															group: "EDIT",
															children: ({ onClose }) => (
																<AuthorizationGuard
																	permissionChecker={aclByArea.portfolio.canRename}
																	acl={portfolio?.richAcl?.acl ?? []}
																	data-qualifier="PortfolioDetails/DropdownMenu/DropdownItem(Rename)"
																>
																	<DropdownMenuActionButton
																		icon="Edit"
																		classList={dropdownMenuActionClassList}
																		onClick={() => {
																			setCrudAction("rename");
																			onClose();
																		}}
																	>
																		Rename
																	</DropdownMenuActionButton>
																</AuthorizationGuard>
															),
														},

														...(hasAccess(user, { requiredRole: "ROOT" })
															? [
																	{
																		group: "ROOT FEATURE",
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				icon="Expand"
																				data-qualifier="PortfolioDetails/DropdownMenu/DropdownItem(PreviewReport)"
																				classList={dropdownMenuActionClassList}
																				onClick={() => {
																					window.open(
																						typedUrlForRoute("Report", {
																							templateId: reference
																								? ("portfolio-reference" satisfies DefaultTemplateId)
																								: DefaultReportTemplateName.sphere,
																							objectId: uuid!,
																							variant: "current" satisfies ReportTemplateVariant,
																						}),
																						"_blank",
																					);
																					onClose();
																				}}
																				disabled={status === "ERROR" || status === undefined}
																			>
																				Preview Report
																			</DropdownMenuActionButton>
																		),
																	} satisfies ActionOrActionWithGroup<"">,
															  ]
															: []),
														...(templateList ?? []).map(
															(template): ActionWithOptionalGroup<""> => ({
																group: "DOWNLOAD",
																children: ({ onClose }) => (
																	<DropdownMenuActionButton
																		icon="pdf"
																		data-qualifier="PortfolioDetails/DropdownMenu/DropdownItem(portfolioTemplate)"
																		onClickAsync={async () => {
																			if (portfolio?.status === "PROPOSAL_READY") {
																				spawnPortfolioTemplateChooser({
																					investments: [
																						{ uuid: uuid!, name: portfolio.name, status: portfolio.status },
																					],
																					onSubmitAsync: async (data) => {
																						const pages = data.map((ptf) =>
																							typedUrlForRoute("Report", {
																								templateId: isDefaultReportTemplate(template)
																									? template.id
																									: template.uuid!,
																								objectId: ptf.uuid!,
																								variant:
																									ptf.choice === "current"
																										? "current"
																										: ("proposal" satisfies ReportTemplateVariant),
																							}),
																						);

																						await parallelize(pages.map((url) => () => downloadPtfPdf(url)));
																					},
																				});
																				onClose();
																				return;
																			}

																			await downloadPtfPdf(
																				typedUrlForRoute("Report", {
																					templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
																					objectId: uuid!,
																					variant: "current" satisfies ReportTemplateVariant,
																				}),
																			);
																			onClose();
																		}}
																	>
																		{(template.templateName ?? "").concat(" (pdf)")}
																	</DropdownMenuActionButton>
																),
															}),
														),
														...(portfolio?.uuid
															? [
																	{
																		group: "DOWNLOAD",
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				key="composition"
																				icon="xls"
																				onClickAsync={async () => {
																					const { data, headers } = await exportApi.exportComposition(portfolio.uuid!, {
																						responseType: "blob",
																					});

																					const fileType = headers["content-type"];
																					const fileName = headers["content-disposition"].split("filename=")[1];
																					downloadFileBlob({ data, name: fileName, type: fileType });

																					onClose();
																				}}
																			>
																				Instrument composition (xlsx)
																			</DropdownMenuActionButton>
																		),
																	} satisfies ActionOrActionWithGroup<"">,
															  ]
															: []),
														...(hasAccess(user, { requiredService: "EXPORT" }) && portfolio?.uuid
															? (user.exportFormats ?? []).map(
																	(format): ActionOrActionWithGroup<""> => ({
																		group: "DOWNLOAD",
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				icon="xls"
																				key="customDownload"
																				onClickAsync={async () => {
																					if (format === InvestmentExportConverterType.EasimTemplateConverter) {
																						const composition = await axiosExtract(
																							integrationsApi.exportInvestment(portfolio.uuid!, false),
																						);
																						await axiosExtract(integrationsApi.convertTo(format, [composition], true));
																						platformToast({
																							children: "Sphere has taken over your request",
																							severity: "info",
																							icon: "Dowload",
																						});
																						return;
																					}

																					const conversion = await investmentConvertionToFile(
																						format,
																						portfolio.uuid!,
																						false,
																						// format === InvestmentExportConverterType.EasimTemplateConverter,
																					);
																					// if (format !== InvestmentExportConverterType.EasimTemplateConverter) {
																					downloadFileBlob(conversion, {
																						// TODO: remove extension once BE updates their filenames so that it's included
																						fileName: `${conversion.name}`,
																					});
																					// } else {
																					// 	platformToast({
																					// 		children: "Sphere has taken over your request",
																					// 		severity: "info",
																					// 		icon: "Dowload",
																					// 	});
																					// }
																					trackMixPanelEvent("Portfolio", {
																						Type: "Export",
																						Area: "composition",
																						ID: portfolio!.uuid!,
																					});
																					onClose();
																				}}
																			>
																				{`Export ${t(`EXPORT.${format}`).toLowerCase()} template (${
																					format === "EASIM_TEMPLATE_CONVERTER" ? "zip" : "xlsx"
																				})`}
																			</DropdownMenuActionButton>
																		),
																	}),
															  )
															: []),
														...(hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" })
															? [
																	{
																		children: ({ onClose }) => (
																			<DropdownMenuActionButton
																				icon="Settings"
																				key="report"
																				classList={`border-t border-[${themeCSSVars.palette_N200}]`}
																				onClickAsync={() => {
																					push("PortfolioStudioSettings", {
																						tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
																					});
																					onClose();
																				}}
																			>
																				Report customisation
																			</DropdownMenuActionButton>
																		),
																	} satisfies ActionOrActionWithGroup<"">,
															  ]
															: []),
												  ]
										}
									/>
									<SelectBenchmarkModal
										comparativeBenchmarks={compatibleBenchmarksIds ?? []}
										onClose={() => setShowComparativeBenchmarkModal(false)}
										onSubmitAsync={handleSaveVisibleComparativeBenchmarks}
										show={showComparativeBenchmarkModal}
									/>
									{isLoadingInvestmentSummary && (
										<div className="h-1">
											<ProgressBar value="indeterminate" />
										</div>
									)}
									<WallOverlay showOverlay={isError} classList="min-h-[40vh]" overlay={<IconWalls.ErrorData />}>
										{portfolio === undefined ? (
											<></>
										) : portfolio.reference || portfolio.isUpload ? (
											<Overview
												isModalOpen={isModalOpen}
												gridName={gridName}
												portfolio={portfolio}
												portfolioUid={portfolioUid}
												isEnhanceSelected={isEnhanceSelected}
												portfolioAlert={portfolioAlert ?? []}
												setIsModalOpen={setIsModalOpen}
												requestEnhance={requestEnhance}
												refetchPortfolio={refetchPortfolioDetails}
											/>
										) : (
											<TabGroup
												palette="primary"
												tabIndex={currentTab}
												onTabChange={(tab) => {
													const stepDataAreMatching = equal(freezedConfigurationStepRef.current, getStepsData());
													if (
														freezedConfigurationStepRef.current &&
														(stepsMetadata[currentStep].dirty || edit || !stepDataAreMatching) &&
														currentTab === 2
													) {
														setCurrentTab(tab);
														return;
													}
													setCustomActions(null);
													setCurrentTab(tab);
												}}
											>
												<Tab title="Overview">
													{currentTab === 0 && (
														<Overview
															isModalOpen={isModalOpen}
															gridName={gridName}
															portfolio={portfolio}
															portfolioUid={portfolioUid}
															isEnhanceSelected={isEnhanceSelected}
															portfolioAlert={portfolioAlert ?? []}
															setIsModalOpen={setIsModalOpen}
															requestEnhance={requestEnhance}
															refetchPortfolio={refetchPortfolioDetails}
														/>
													)}
												</Tab>
												<Tab title="Scenario Analysis">
													{currentTab === 1 && <WidgetsMapper widgetName="ScenarioAnalysisEditable" />}
												</Tab>
												<Tab title="Portfolio Settings">{currentTab === 2 && <EditPortfolioV4InContext />}</Tab>
												<AuthorizationGuard requiredService="PORTFOLIO_STUDIO_COMMENTARY_TAB">
													{() => (
														<Tab title="Commentary">
															{currentTab === 3 && (
																// (hasAccess(user, { requiredService: "COMMENTARY_BUILDER" }) ? (
																// 	<PortfolioCommentaryV2
																// 		enhanced={isEnhanceSelected}
																// 		portfolio={portfolio}
																// 		setCustomActions={setCustomActions}
																// 		reportExcutionCounter={reportExcutionCounter}
																// 	/>
																// TODO: storyfolio
																// ) : (
																<PortfolioCommentary
																	enhanced={isEnhanceSelected}
																	portfolio={portfolio}
																	setCustomActions={setCustomActions}
																	reportExcutionCounter={reportExcutionCounter}
																/>
																// ))}
															)}
														</Tab>
													)}
												</AuthorizationGuard>
											</TabGroup>
										)}
									</WallOverlay>

									<LeaveUnsavedDataDialog
										when={
											edit &&
											(stepsMetadata[currentStep].dirty || !equal(freezedConfigurationStepRef.current, stepsData)) &&
											currentTab === 2
										}
										pathToNotBlock={[
											"/login",
											history.location.pathname + history.location.search,
											history.location.pathname +
												(() => {
													const url = new URLSearchParams(history.location.search);
													url.delete("edit");
													return `?${url.toString()}`;
												})(),
										]}
										onDiscard={() => {
											if (freezedConfigurationStepRef.current !== undefined) {
												setStepsData(freezedConfigurationStepRef.current);
												freezedConfigurationStepRef.current = undefined;
											}
											setEdit(false);
										}}
										onProceed={async () => {
											await onSave();
											setEdit(false);
										}}
									/>

									{crudAction === "delete" && (
										<CrudModal
											show={Boolean(crudAction)}
											action={crudAction}
											title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
											portfolioName={portfolio?.name ?? ""}
											onCancel={onCloseModal}
											onClose={onCloseModal}
											onSubmit={() => onDelete(portfolio?.name ?? "", portfolio?.uuid)}
											name="PortfolioDetails"
										/>
									)}

									{crudAction === "duplicate" && (
										<CrudModal
											show={Boolean(crudAction)}
											action={crudAction}
											title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: portfolio?.name })}
											onCancel={onCloseModal}
											onClose={onCloseModal}
											isInvalid={!isPortfolioNameAvailable}
											onSubmit={(name) => onSubmit("duplicate", name, portfolio?.uuid)}
											portfolioName={portfolio?.name ?? ""}
											name="PortfolioDetails"
										/>
									)}

									{crudAction === "rename" && (
										<CrudModal
											show={Boolean(crudAction)}
											action={crudAction}
											title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: portfolio?.name })}
											onCancel={onCloseModal}
											onClose={onCloseModal}
											isInvalid={!isPortfolioNameAvailable}
											onSubmit={(name) => onSubmit("rename", name, portfolio?.uuid)}
											portfolioName={portfolio?.name ?? ""}
											name="PortfolioDetails"
										/>
									)}
								</>
							)}
						</PortfolioContext.Consumer>
					);
				}}
			</EditPortfolioV4ContextProvider>
		</PortfolioContext.Provider>
	);
};

export default PortfolioDetails;
