import type { StylableProps } from "@mdotm/mdotui/components";
import { AutoTooltip, Icon } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";

export type InfoTooltipProps = { children: ReactNode } & StylableProps;

export function InfoTooltip({ children, style, classList }: InfoTooltipProps): JSX.Element {
	return (
		<AutoTooltip
			severity="success"
			trigger={({ innerRef }) => (
				<Icon
					innerRef={innerRef}
					icon="Information"
					color={themeCSSVars.MessageSeverity_success}
					size={20}
					classList={classList}
					style={style}
				/>
			)}
			position="top"
			align="endToStart"
		>
			{children}
		</AutoTooltip>
	);
}
