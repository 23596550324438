/**
 * given a themeCSSVars extract the value
 *
 * ```tsx
 *  const color = getThemeCssVars(themeCSSVars.palette_S500);
 *  console.log(color) // #00aeef
 * ```
 * @param cssVar
 * @returns
 */
export function getThemeCssVars(cssVar: string): string {
	const regex = /var\((--[^)]+)\)/; //regex to extract var from var()
	const rawVar = cssVar.match(regex);

	const body = document.body;
	const style = getComputedStyle(body);
	return style.getPropertyValue(rawVar![1]!);
}
