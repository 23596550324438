import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";

type BarGraphPCSvgProps = StylableProps & {
	options?: {
		/** @default true */
		animated?: boolean;
		/**
		 * Select options applied to all the Bars
		 */
		decorator?: string;
		/**
		 * Select a step value for markers
		 */
		markerStep?: number;
		/**
		 * Select if markers labels should be rendered
		 */
		marksLabels?: boolean;
		/**
		 * Choose if the graph should be resized (using the selected scale or the Max series value)
		 */
		resize?: boolean;
		/**
		 * Add a vertical Padding near the Bars Area
		 */
		vPadding?: number;
		/**
		 * Select options applied to all the Bars
		 */
		bars?: {
			/**
			 * Choose an height in pixels
			 */
			height: number;
			/**
			 * Choose a gap in pixels between bars
			 */
			gap: number;
			/**
			 * Choose an color for the bars background
			 */
			bgColor: string;
		};
		/**
		 * Choose a forced scale max fot the graph (only applied if resize is set "true")
		 */
		scale?: {
			max: number;
			min: number;
		};
	};
	/**
	 * Data Series
	 */
	data: {
		/**
		 * Bar value
		 */
		value: number;
		/**
		 * Bar color
		 */
		color: string;
		/**
		 * Bar back color
		 */
		bgColor?: string;
	}[];
};

const defaultOptions = {
	mode: "Default",
	decorator: "",
	markerStep: 10,
	marksLabels: true,
	vPadding: 10,
	bars: { height: 10, gap: 0, bgColor: "transparent" },
	scale: { max: 0, min: 0 },
	resize: true,
	animated: true,
};

export const BarGraphPCSvg: React.FC<BarGraphPCSvgProps> = ({ options = defaultOptions, data, classList, style }) => {
	const {
		decorator = "",
		markerStep = 10,
		marksLabels = true,
		vPadding = 10,
		resize = true,
		bars = { height: 10, gap: 0, bgColor: "transparent" },
		scale = { max: 0, min: 0 },
		animated = true,
	} = options;
	// Custom Rounding
	const roundCustom = (value: number) => {
		const roundValue = markerStep;
		const isZ = value === 0;
		const absValue = Math.abs(value);
		const rounded = isZ ? 0 : Math.ceil(absValue / roundValue) * roundValue;
		return value >= 0 ? rounded : rounded * -1;
	};
	// Max and Min of Values
	const minValue = Math.min(...data.map((el) => el.value));
	const maxValue = Math.max(...data.map((el) => el.value));

	// Max and Min Of Graph => Evaluating Marks
	const graphMinValue = roundCustom(minValue);
	const graphMaxValue = roundCustom(maxValue);
	const scaleMinValue = roundCustom(scale.min);
	const scaleMaxValue = roundCustom(scale.max);

	// Use Scale if needed
	const scaledMin = resize ? Math.min(scaleMinValue, graphMinValue) : graphMinValue;
	const scaledMax = resize ? Math.max(scaleMaxValue, graphMaxValue) : graphMaxValue;
	const deltaScale = scaledMax - scaledMin;

	// Resize Values to %
	const resizeVal = (value: number) => {
		const resizedValue = (value * 100) / deltaScale;
		return resizedValue;
	};

	// Traslate settings
	const defaultOrigin = 0;
	const needTraslate = scaledMin < 0;
	const newOrigin = needTraslate ? defaultOrigin - scaledMin : defaultOrigin;
	const newOriginScaled = resizeVal(newOrigin);
	const resizedScaleMax = resizeVal(scaledMax);

	// Support Functions
	const tranValue = (value: number) => {
		return value + newOrigin;
	};

	// Generate Markers
	const markerStepSeries = [];
	for (let i = scaledMin; i <= scaledMax; i = i + markerStep) {
		const newValue = resizeVal(tranValue(i));
		markerStepSeries.push({
			originalValue: i,
			value: `${newValue || 0}%`,
			label: `${i}${decorator}`,
		});
	}

	// Generate Bars
	const resizedBars = data.map((el) => {
		const isPositive = el.value > 0;
		const newValue = resizeVal(tranValue(el.value));
		const barDataOrigin = isPositive ? newOriginScaled : newValue;
		const barOrigin = isPositive ? newOriginScaled : 0;
		return {
			...el,
			xBar: `${barOrigin || 0}%`,
			xDataBar: `${barDataOrigin || 0}%`,
			barWidth: `${(isPositive ? resizedScaleMax : newOriginScaled) || 0}%`,
			dataBarWidth: `${(isPositive ? newValue - barDataOrigin : newOriginScaled - barDataOrigin) || 0}%`,
		};
	});

	// Calculate Graph Params
	const barsHeight = data.length * (bars.height + bars.gap);
	const graphHeight = barsHeight + vPadding * 2;
	return (
		<>
			<div style={{ padding: `0px ${marksLabels ? "20px" : "0px"}`, ...style }} className={toClassName(classList)}>
				<svg width="100%" height={`${graphHeight}px`}>
					{animated && (
						<style>
							{`
					@keyframes load {
						0% { width: 0; }
					}

					.bar .data.animated {
						animation: load 2s 0s ease-in-out;
					}
				`}
						</style>
					)}
					<g className="graphDraw">
						<g className="markers">
							{markerStepSeries.map((b, index) => (
								<g
									key={`marker-k-${index}`}
									className="marker"
									// Prevent line from overflowing due to its x being 100% by subtracting 1px
									transform={index === markerStepSeries.length - 1 ? "translate(-1,0)" : undefined}
								>
									{b.originalValue === 0 ? (
										<line x1={b.value} y1="0" x2={b.value} y2={`${barsHeight + vPadding + 10}px`} stroke="#000" />
									) : (
										<line
											x1={b.value}
											y1="0"
											x2={b.value}
											y2={`${barsHeight + vPadding + 10}px`}
											stroke="#D1D2DC"
											strokeDasharray="3 3"
										/>
									)}
								</g>
							))}
						</g>
						<g className="bars">
							{resizedBars.map((b, index) => (
								<g key={`bar-k-${index}`} className="bar">
									<rect
										className="bg"
										fill={`${b.bgColor ?? bars.bgColor}`}
										width={b.barWidth}
										height={bars.height}
										y={index * (bars.height + bars.gap) + vPadding}
										x={b.xBar}
									/>
									<rect
										className="data animated"
										fill={b.color}
										width={b.dataBarWidth}
										height={bars.height}
										y={index * (bars.height + bars.gap) + vPadding}
										x={b.xDataBar}
										rx="2"
									/>
								</g>
							))}
						</g>
					</g>
				</svg>
			</div>
			{marksLabels && (
				<div className="markersLabels flex justify-between">
					{markerStepSeries.map((b, index) => (
						<div key={`marker-labels-k-${index}`} className="marker" style={{ width: "42px", textAlign: "center" }}>
							{b.label}
						</div>
					))}
				</div>
			)}
		</>
	);
};
