import type { InvestmentListEntry, InvestmentStatuses } from "$root/api/api-gen";
import { CustomerControllerV3ApiFactory, InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import InfiniteLoader from "$root/components/InfiniteLoader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus } from "$root/event-bus";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { mapAlert } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import { formatDate, formatNumber } from "$root/localization/formatters";
import { useLocaleFormatters } from "$root/localization/hooks";
import { checkUndefined, sortingFn, statusIconMap } from "$root/pages/PortfoliosStudio";
import { PaletteColors } from "$root/styles/themePalette";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import AlertTooltip from "$root/widgets-architecture/layout/WidgetsMapper/AlertTooltip";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoTooltip, Button, Icon, Table, TooltipContent } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { addMinutes, format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NoData = (): JSX.Element => {
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	return (
		<div>
			{t("PORTFOLIOS.NO_PORTFOLIO_YET", { returnObjects: true }).map((tt) => (
				<div key={tt} style={{ color: PaletteColors.LIGHT_GREY }}>
					{tt}
				</div>
			))}
			<div className="flex flex-row gap-4 mt-3">
				<Button size="small" palette="primary" onClick={() => push("Portfolios/UploadPortfolioPage/Portfolio", {})}>
					<Icon icon="Upload" />
					&nbsp;
					{t("PORTFOLIOS.UPLOAD_PORTFOLIO").toLowerCase()}
				</Button>
				<Button size="small" palette="primary" onClick={() => push("Portfolios/CreatePortfolio", { uuid: "" })}>
					<Icon icon="Outline1" />
					&nbsp;
					{t("PORTFOLIOS.CREATE_PORTFOLIO").toLowerCase()}
				</Button>
			</div>
		</div>
	);
};

const PortfolioOverview = (): JSX.Element => {
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const { t } = useTranslation();
	const formatters = useLocaleFormatters();
	const user = useUserValue();
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);

	const {
		isFetching,
		refetch,
		data: portfolioList,
	} = useQueryNoRefetch(["queryPortfolioOverview"], {
		queryFn: async () => {
			const { data } = await investmentApi.getInvestmentList();

			return data;
		},
	});

	const { data: userSyncInfo } = useQueryNoRefetch(["queryUserSyncInfoDashboard"], {
		enabled: user.automaticImport,
		queryFn: () => axiosExtract(customerV3Api.users1()),
	});

	useWidgetOptions(
		() => ({
			title: "Portfolio Overview",
			actionHeader: <InfoTooltip>{t("PORTFOLIO_ALIGNMENT_OUTLOOK.TOOLTIP")}</InfoTooltip>,
			isDraggable: false,
		}),
		[t],
	);

	useEventBus("investment-update", () => {
		refetch().catch(noop);
	});

	const columns = useMemo<TableColumn<InvestmentListEntry>[]>(
		() => [
			{
				header: t("PORTFOLIOS.PORTFOLIO_NAME"),
				content: ({ uuid, name }) => (
					<Link
						className="underline text-xs font-bold truncate w-full pr-4"
						style={{ color: PaletteColors.CHARCOAL_GREY_TWO, cursor: "pointer" }}
						to={typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid! })}
						title={name}
					>
						{name}
					</Link>
				),
				sortFn: builtInSortFnFor("name"),
				name: "name",
				relativeWidth: 2.2,
			},
			{
				header: t("INCEPTION_DATE"),
				content: ({ creationTime }) => {
					const sanitaizeCreationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
					return sanitaizeCreationTime;
				},
				sortFn: ({ creationTime: a }, { creationTime: b }) => {
					const rowA = new Date(checkUndefined<string>(a, "")).getTime();
					const rowB = new Date(checkUndefined<string>(b, "")).getTime();
					return sortingFn<number>({ rowA, rowB });
				},
				name: "inception",
				relativeWidth: 1.6,
			},
			{
				header: t("STATUS"),
				content: ({ status }) => {
					const sanitizedStatus = status ? statusIconMap[status as InvestmentStatuses] : "-";
					if (sanitizedStatus === "-") {
						return sanitizedStatus;
					}
					return (
						<div className="flex items-center w-full" title={sanitizedStatus.title}>
							<Icon
								classList="mr-1"
								icon={sanitizedStatus.icon}
								color={PaletteColors.DARK_GREY}
								size={sanitizedStatus.size}
							/>
							<div style={{ fontSize: 12, fontFamily: "Gotham Book", fontWeight: "300" }} className="truncate">
								{sanitizedStatus.title}
							</div>
						</div>
					);
				},
				sortFn: builtInSortFnFor("status"),
				name: "status",
				relativeWidth: 1.9,
			},
			{
				header: t("TABLE.HEADERS.PERFORMANCE_1M"),
				cellClassList: "tabular-nums",
				content: ({ performance1M }) => {
					return `${performance1M}%`;
				},
				sortFn: builtInSortFnFor("performance1M"),
				name: "performance1M",
				relativeWidth: user.automaticImport ? 1.1 : 1.6,
				headerCellClassName: "text-left xl:w-[70%] 2xl:w-full",
				align: "end",
			},
			{
				header: t("TABLE.HEADERS.BENCHMARK_PERFORMANCE_1M"),
				cellClassList: "tabular-nums",
				content: ({ benchmarkPerformance1M }) => {
					return `${benchmarkPerformance1M}%`;
				},
				sortFn: builtInSortFnFor("benchmarkPerformance1M"),
				name: "benchmarkPerformance1M",
				relativeWidth: user.automaticImport ? 1.1 : 1.6,
				headerCellClassName: "text-left xl:w-[70%] 2xl:w-full",
				align: "end",
			},
			{
				header: t("TABLE.HEADERS.AUTO_SYNC"),
				content: (row) => {
					if (row.importedExternally && userSyncInfo && userSyncInfo.lastExecutionTime && userSyncInfo.frequency) {
						const lastAutoImportSync = new Date(userSyncInfo.lastExecutionTime);
						const nextAutoImportSync = addMinutes(lastAutoImportSync, userSyncInfo.frequency);
						return (
							<AutoTooltip
								position="top"
								overrideColor={themeCSSVars.palette_N400}
								trigger={({ innerRef }) => (
									<span ref={innerRef}>
										<Icon icon="Outline" color={themeCSSVars.palette_N400} size={20} />
									</span>
								)}
							>
								<TooltipContent>
									LAST CHECK: {format(lastAutoImportSync, "KK:mm")} - NEXT CHECK: {format(nextAutoImportSync, "KK:mm")}
								</TooltipContent>
							</AutoTooltip>
						);
					}

					return <></>;
				},
				relativeWidth: user.automaticImport ? 1.2 : 0,
				hidden: !user.automaticImport,
				sortFn: builtInSortFnFor("importedExternally"),
				name: "importedExternally",
			},
			{
				header: t("SCORE"),
				content: (row) => {
					return (
						<div className="tabular-nums">
							{row.score && (
								<>
									(<CustomLabels labelKey={row.scoreIdentifier ?? "-"} fallback="" />){" "}
									{row.score ? formatNumber(row.score) : ""}
								</>
							)}
						</div>
					);
				},
				sortFn: builtInSortFnFor("score"),
				name: "score",
				relativeWidth: 1.4,
				align: "end",
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			{
				header: t("WARNINGS"),
				content: ({ alerts }) => {
					if (!alerts || alerts.length === 0) {
						return null;
					}
					return (
						<AlertTooltip
							alerts={alerts
								.filter((a) => Boolean(a.type))
								.map((a) => mapAlert({ t, ...formatters }, a as PortfolioAlert))}
							summary
						/>
					);
				},
				sortFn: builtInSortFnFor("alerts"),
				name: "alerts",
				relativeWidth: 1.7,
			},
		],
		[t, user, userSyncInfo, formatters],
	);

	if (isFetching) {
		return <InfiniteLoader />;
	}

	if (!portfolioList) {
		throw new Error("failed to load the portfolio list");
	}

	if (portfolioList.length === 0) {
		return <NoData />;
	}

	return (
		<Table
			key="portfolioListTable"
			columns={columns}
			rows={portfolioList}
			noDataText="No data available"
			visibleRows={9}
		/>
	);
};

export default PortfolioOverview;
