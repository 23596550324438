/**
 * Create a function which takes zero parameters from a function that takes some optional
 * parameters. This is particularly useful in callbacks, for example:
 *
 * ```tsx
 * function greetings(name?: string) {
 * 	if (name) {
 * 		console.log(`Hello ${name}!`);
 * 	} else {
 * 		console.log(`Hello!`);
 * 	}
 * }
 *
 * // ...
 *
 * <button type="button" onClick={greetings}></button>
 * ```
 *
 * On click, the above code snipped would result in an error, as onClick always passes
 * the click event as the first argument to the registered callback. The JSX line could
 * be rewritten as:
 *
 * ```tsx
 * <button type="button" onClick={() => greetings()}></button>
 * ```
 *
 * Bus as the above use case is quite common, this utility function can improve readability,
 * making the same JSX line as follows:
 *
 * ```tsx
 * <button type="button" onClick={nullary(greetings)}></button>
 * ```
 *
 * @param fn a function that optionally takes some parameters.
 * @returns a function that calls `fn` without any arguments and returns its result.
 */
export function nullary<TOut>(fn: (...args: any[]) => TOut): () => TOut {
	return () => fn();
}

/**
 * Make a promise look like a synchronous function by wrapping it in an arrow function
 * that calls it and attaches a rejection handler.
 *
 * @param fn a function that may return a Promise.
 * @returns a function that calls `fn` and catches the error (if any).
 */
export function unpromisify<T extends (...args: any[]) => Promise<void> | void>(
	fn: T,
): (...args: Parameters<T>) => void {
	return (...args) => {
		(async () => {
			await fn(...args);
		})().catch(console.warn);
	};
}

export type Updater<T> = (v: T) => T;
export type Update<T> = (updater: Updater<T>) => void;
export type Setter<T> = (v: T) => void;
export type SetterOrUpdater<T> = (v: T | Updater<T>) => void;
