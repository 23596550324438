import type { ReviewTicker, UploadError } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { uploadErrorMap } from "$root/components/Portfolio/UploadPortfolio/uploadErrorMap";
import UploadButton from "$root/components/UploadButton";
import EmptyImportBox from "$root/functional-areas/portfolio/box/EmptyImportBox";
import ErrorImportBox from "$root/functional-areas/portfolio/box/ErrorImportBox";
import SuccessImportBox from "$root/functional-areas/portfolio/box/SuccessImportBox";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { downloadFileBlob } from "$root/utils/files";
import type { ButtonProps, DataAttributesProps, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	Banner,
	Button,
	Dialog,
	DialogFooter,
	Icon,
	Radio,
	RadioGroup,
	SubmitButton,
	Table,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm, type Control } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const ACCEPTED_FILE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const downloadTitle = {
	INVESTMENT: "Portfolio",
	BENCHMARK: "Benchmark",
	TARGET_INVESTMENT: "Target portfolio",
	UNIVERSE: "universe",
	INVESTMENT_ENHANCEMENT: "Portfolio",
	INVESTMENT_DRAFT: "Portfolio",
} satisfies Record<UploadEntity, string>;

const defaultCompositionBoxOrderBy: OrderBy[] = [
	{
		columnName: "alias",
		direction: "desc",
	},
];

const Description = ({ onDownload, uploadType }: { onDownload: () => Promise<void>; uploadType: UploadEntity }) => {
	const { t } = useTranslation();
	const PORTFOLIO = t("PORTFOLIOS", { returnObjects: true });

	const portfolio18Key = {
		INVESTMENT: "PORTFOLIOS_UPLOAD_STEP",
		BENCHMARK: "PORTFOLIOS_UPLOAD_STEP",
		TARGET_INVESTMENT: "PORTFOLIOS_UPLOAD_STEP",
		UNIVERSE: "UNIVERSE_UPLOAD_STEP",
		INVESTMENT_ENHANCEMENT: "PORTFOLIOS_UPLOAD_STEP",
		INVESTMENT_DRAFT: "PORTFOLIOS_UPLOAD_STEP",
	} satisfies Record<UploadEntity, keyof typeof PORTFOLIO>;

	return (
		<ol className="list-decimal ml-4 mb-5">
			{customObjectEntriesFn(t(`PORTFOLIOS.${portfolio18Key[uploadType]}`, { returnObjects: true })).map(
				([step], i) => (
					<li key={i}>
						<Trans
							t={t}
							i18nKey={`PORTFOLIOS.${portfolio18Key[uploadType]}.${step}`}
							components={{
								u: <u className="cursor-pointer font-semibold" />,
								download: (
									<ActionText aria-label="download" onClickAsync={onDownload}>
										{" "}
									</ActionText>
								),
							}}
							values={{ type: downloadTitle[uploadType].toLowerCase() }}
						/>
					</li>
				),
			)}
		</ol>
	);
};

class LocalizedError extends Error {
	constructor(
		msg: string,
		public localizedMsg: string,
	) {
		super(msg);
	}
}

const MainInfoBlockErrors = (props: {
	errors: UploadError[];
	uploadType: UploadEntity;
	uploadPlaceholders: Record<UploadEntity, { type: string; confirmText: string }>;
}) => {
	const { errors, uploadType, uploadPlaceholders } = props;
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const localUploadStateErrors = useMemo(
		() => errors?.filter((el) => uploadErrorMap[el.code!].isAliasReferred === true),
		[errors],
	);

	const globalUploadStateErrors = useMemo(
		() =>
			errors
				?.filter((el) => uploadErrorMap[el.code!].isAliasReferred === false)
				.map((el) => ({
					mappedError: uploadErrorMap[el.code!],
					errorData: el,
				})),
		[errors],
	);

	const isBlockError = useMemo(
		() => (localUploadStateErrors ?? []).filter((el) => uploadErrorMap[el.code!].type === "error").length > 0,
		[localUploadStateErrors],
	);

	const PTF_ERRORS = t("PORTFOLIO_UPLOAD_ALERTS", { returnObjects: true });

	const errorColumn = useMemo<TableColumn<UploadError>[]>(
		() =>
			uploadType === "UNIVERSE"
				? [
						{
							header: t("PORTFOLIOS.TB_HEADER_ALIAS"),
							cellClassList: "underline",
							content: ({ alias }) => alias,
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							relativeWidth: 40,
						},
						{
							header: t("PORTFOLIOS.TB_HEADER_ERROR"),
							content: ({ code }) => t(code!),
							relativeWidth: 60,
						},
				  ]
				: [
						{
							header: t("PORTFOLIOS.TB_HEADER_ALIAS"),
							cellClassList: "underline",
							content: ({ alias }) => alias,
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							relativeWidth: 40,
						},
						{
							header: t("PORTFOLIOS.TB_HEADER_WEIGHT"),
							content: ({ weight }) => `${formatNumber(weight)}%`,
							relativeWidth: 10,
						},
						{
							header: t("PORTFOLIOS.TB_HEADER_ERROR"),
							content: ({ code }) => t(code!),
							relativeWidth: 50,
						},
				  ],
		[uploadType, t, formatNumber],
	);

	const handleDownloadErrorReport = useCallback(
		(report: Array<UploadError>) => {
			const mapReport = report.map(({ code, weight, alias }) =>
				uploadType === "UNIVERSE"
					? { identifier: alias, code: t(code!) }
					: { identifier: alias, weight, code: t(code!) },
			);
			const reportWS = XLSX.utils.json_to_sheet(mapReport);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
			XLSX.writeFile(wb, "Errors file.xlsx");
		},
		[uploadType, t],
	);

	return (
		<>
			{globalUploadStateErrors &&
				globalUploadStateErrors.length > 0 &&
				globalUploadStateErrors.map((el, i) => (
					<div className="my-4" key={i}>
						<Banner
							severity={el.mappedError.type === "error" ? "error" : "info"}
							title={
								el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
								el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
									<Trans
										i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.title`}
										values={{
											weight: formatNumber(el.errorData.weight),
											remaining: formatNumber(100 - (el.errorData.weight ?? 0), 2),
										}}
									/>
								) : (
									(
										PTF_ERRORS[el.mappedError.translationKey as keyof typeof PTF_ERRORS] as {
											title: string;
											description: string;
										}
									).title
								)
							}
						>
							{el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
							el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
								<Trans i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.description`} />
							) : (
								(
									PTF_ERRORS[el.mappedError.translationKey as keyof typeof PTF_ERRORS] as {
										title: string;
										description: string;
									}
								).description
							)}
						</Banner>
					</div>
				))}

			{localUploadStateErrors && localUploadStateErrors.length > 0 && (
				<div className="my-4">
					<Banner
						severity={isBlockError ? "error" : "info"}
						title={
							isBlockError ? (
								t("PORTFOLIO_UPLOAD_ALERTS.UPLOAD_ERROR.title")
							) : (
								<Trans
									i18nKey="PORTFOLIO_UPLOAD_ALERTS.UPLOAD_WARNING.title"
									values={{
										type: uploadPlaceholders[uploadType].type,
									}}
								/>
							)
						}
					>
						{isBlockError ? (
							t("PORTFOLIO_UPLOAD_ALERTS.UPLOAD_ERROR.description")
						) : (
							<Trans
								i18nKey="PORTFOLIO_UPLOAD_ALERTS.UPLOAD_WARNING.description"
								values={{
									type: uploadPlaceholders[uploadType].type,
								}}
								className="whitespace-pre-wrap"
							/>
						)}
						<div className="mt-1">
							<button
								type="button"
								className="underline cursor-pointer font-bold"
								onClick={() => handleDownloadErrorReport(localUploadStateErrors)}
							>
								{isBlockError ? t("EXPORT_ERROR") : t("EXPORT_WARNING")}
							</button>
						</div>
					</Banner>

					<Table
						columns={errorColumn}
						rows={localUploadStateErrors}
						orderBy={defaultCompositionBoxOrderBy}
						classList="[&>div:nth-child(2)>div:last-child]:!border-[#d1d2dc] mt-2"
						visibleRows={5}
					/>
				</div>
			)}
		</>
	);
};

type FileProps = {
	filename: string;
	fileContent: string | ArrayBuffer | null;
	tickerComposition: any[];
	uploadStrategy: "PRIORITY_TO_EDITOR_WEIGHTS" | "PRIORITY_TO_EXCEL_WEIGHTS" | undefined;
	fileErrors: UploadError[];
	status: "empty" | "calculating" | "ready" | "error" | undefined;
};

const MainBlock = ({
	uploadType,
	onChangeFile,
	onReset,
	file,
	control,
}: {
	file: FileProps;
	control: Control<FileProps, any>;
	uploadType: UploadEntity;
	onChangeFile?(file: File | null): MaybePromise<void>;
	onReset?(): void;
}) => {
	const { t } = useTranslation();
	const status = file.status;

	// TODO: maybe application/vnd.ms-excel is compatible too?
	const uploadPlaceholders: Record<UploadEntity, { type: string; confirmText: string }> = {
		BENCHMARK: { type: "Benchmark", confirmText: "Upload benchmark" },
		INVESTMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
		INVESTMENT_ENHANCEMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
		TARGET_INVESTMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
		UNIVERSE: { type: "Universe", confirmText: "Upload universe" },
		INVESTMENT_DRAFT: { type: "Portfolio", confirmText: "Upload portfolio" },
	};

	const hasBlockError = useMemo(
		() => (file.fileErrors ?? []).filter((el) => uploadErrorMap[el.code!].type === "error").length > 0,
		[file.fileErrors],
	);

	const uploadState = useMemo(() => {
		if (hasBlockError) {
			return "error";
		}
		if (file.fileContent) {
			return "success";
		}

		return "empty";
	}, [file.fileContent, hasBlockError]);

	const BoxMap = {
		empty: <EmptyImportBox />,
		error: <ErrorImportBox />,
		success: <SuccessImportBox />,
	} satisfies Record<typeof uploadState, ReactNode>;

	return (
		<>
			{/* {fileContentController.field.value === null && ( */}
			<div className="mb-5">
				<p className="font-semibold">
					{uploadType === "UNIVERSE"
						? "In case the template has existing instruments, which information should be considered?"
						: "In case the template has existing instruments, which weights should be considered?"}
				</p>
				<FormController
					control={control}
					name="uploadStrategy"
					render={({ field: { value, onChange } }) => (
						<RadioGroup value={value} onChange={onChange} disabled={status === "calculating"}>
							<div className="flex flex-row flex-wrap gap-4">
								<Radio
									value="PRIORITY_TO_EDITOR_WEIGHTS"
									classList={`text-[${themeCSSVars.palette_N400}]`}
									disabled={status === "calculating"}
								>
									{uploadType === "UNIVERSE" ? "Keep information from the editor" : "Keep the weights from the editor"}
								</Radio>
								<Radio
									value="PRIORITY_TO_EXCEL_WEIGHTS"
									classList={`text-[${themeCSSVars.palette_N400}]`}
									disabled={status === "calculating"}
								>
									{uploadType === "UNIVERSE"
										? "Keep information from the template file"
										: "Keep the weights from the template file"}
								</Radio>
							</div>
						</RadioGroup>
					)}
				/>
			</div>
			{/* )} */}
			<DropzoneArea
				onChange={
					file.uploadStrategy === undefined && uploadType !== "UNIVERSE"
						? console.log
						: onChangeFile
						  ? unpromisify(onChangeFile)
						  : console.log
				}
				accept={ACCEPTED_FILE}
				disabled={(file.uploadStrategy === undefined && uploadType !== "UNIVERSE") || status === "calculating"}
				childrenWrapperAppearance={{
					classList: {
						[`relative rounded flex flex-1 gap-4 p-4 justify-between items-center border-2 `]: true,
						[`bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`]:
							file.fileContent === null && hasBlockError === false,
						[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]: Boolean(
							file.fileContent && hasBlockError === false,
						),
						[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`]: hasBlockError,
					},
				}}
			>
				<div className="flex gap-2 items-center">
					{BoxMap[uploadState]}
					<p className="font-semibold">{file.filename || "Select a file or drag and drop here"}</p>
				</div>

				{file.fileContent ? (
					<Button unstyled size="small" onClick={onReset} disabled={status === "calculating"}>
						<Icon
							icon="Delete"
							size={20}
							color={status === "calculating" ? themeCSSVars.palette_N500 : themeCSSVars.palette_P500}
						/>
					</Button>
				) : (
					<UploadButton
						size="small"
						onChange={
							file.uploadStrategy === undefined && uploadType !== "UNIVERSE"
								? console.log
								: onChangeFile
								  ? unpromisify(onChangeFile)
								  : console.log
						}
						accept={ACCEPTED_FILE}
						disabled={(file.uploadStrategy === undefined && uploadType !== "UNIVERSE") || status === "calculating"}
						loading={status === "calculating"}
						label={file.fileContent ? t("SELECT_NEW_FILE") : t("BUTTON.SELECT")}
					/>
				)}
			</DropzoneArea>

			{/* {file.filename && (
				<div className="mt-5">
					<div className="my-4">
						<Banner severity="success" title={<Trans i18nKey="PORTFOLIO_UPLOAD_ALERTS.VALID_UPLOAD.title" />}>
							<Trans
								i18nKey="PORTFOLIO_UPLOAD_ALERTS.VALID_UPLOAD.description"
								values={{
									fileName: file.filename,
									type: uploadPlaceholders[uploadType].type,
									confirmText: uploadPlaceholders[uploadType].confirmText,
								}}
							/>
						</Banner>
					</div>
				</div>
			)} */}

			{status === "calculating" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}

			<MainInfoBlockErrors errors={file.fileErrors} uploadPlaceholders={uploadPlaceholders} uploadType={uploadType} />
		</>
	);
};

type UploadButtonProps = {
	uploadEntity: UploadEntity;
	currentComposition?: ReviewTicker[];
	palette?: ButtonProps["palette"];
	onSave?(data: ReviewTicker[]): void;
	renderCustomButton?: NodeOrFn<{ setShowDialog(show: boolean): void }>;
} & DataAttributesProps;

const UploadInstrumentButton = (props: UploadButtonProps): JSX.Element => {
	const { uploadEntity, palette = "secondary", currentComposition, renderCustomButton, ...dataAttributes } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const { t } = useTranslation();

	const { control, handleSubmit, watch, reset, resetField, getValues, setValue } = useForm({
		defaultValues: {
			filename: "",
			fileContent: null as string | ArrayBuffer | null,
			tickerComposition: [] as Array<ReviewTicker>, // replace this ticker-composition
			uploadStrategy: "PRIORITY_TO_EDITOR_WEIGHTS" as
				| "PRIORITY_TO_EDITOR_WEIGHTS"
				| "PRIORITY_TO_EXCEL_WEIGHTS"
				| undefined,
			fileErrors: [] as UploadError[],
			status: undefined as "calculating" | "ready" | "empty" | "error" | undefined,
		},
	});

	const observedFileContent = watch("fileContent");

	const downloadDescription = {
		INVESTMENT: t("PORTFOLIOS.PORTFOLIOS_UPLOAD_DESCRIPTION_1"),
		BENCHMARK: t("PORTFOLIOS.PORTFOLIOS_UPLOAD_DESCRIPTION_1"),
		TARGET_INVESTMENT: t("PORTFOLIOS.PORTFOLIOS_UPLOAD_DESCRIPTION_1"),
		UNIVERSE: t("PORTFOLIOS.UNIVERSE_UPLOAD_DESCRIPTION_1"),
		INVESTMENT_ENHANCEMENT: t("PORTFOLIOS.UNIVERSE_UPLOAD_DESCRIPTION_1"),
		INVESTMENT_DRAFT: t("PORTFOLIOS.PORTFOLIOS_UPLOAD_DESCRIPTION_1"),
	} satisfies Record<UploadEntity, string>;

	const handleDownloadTemplate = useCallback(
		() =>
			editorApi
				.getEditorUploadTemplate(uploadEntity, {
					responseType: "blob",
				})
				.then(({ data, headers }) => {
					const fileType = headers["content-type"];
					const fileName = headers["content-disposition"].split("filename=")[1];
					downloadFileBlob(
						{ data: data as Blob, name: fileName, type: fileType },
						{
							fileName: `Template ${downloadTitle[uploadEntity]}.xlsx`,
						},
					);
				})
				.catch((e) => {
					reportPlatformError(e, "ERROR", "portfolio", "unable to download editor template");
					throw e;
				}),
		[editorApi, uploadEntity],
	);

	const handleModalStatus = () => setIsModalOpen(!isModalOpen);

	const onReset = useCallback(() => {
		resetField("status");
		resetField("filename");
		resetField("fileContent");
		resetField("tickerComposition");
	}, [resetField]);

	const onChangeFile = useCallback(
		async (file: File | null) => {
			try {
				onReset();
				if (!file) {
					setValue("filename", "");
					setValue("fileContent", null);
					return;
				}

				if (file.type !== ACCEPTED_FILE) {
					throw new LocalizedError("wrong file type", t("PORTFOLIOS.WRONG_FILE_TYPE"));
				}

				const fileReader = new FileReader();
				await new Promise((resolve, reject) => {
					fileReader.onload = resolve;
					fileReader.onerror = reject;
					fileReader.readAsArrayBuffer(file);
				});

				setValue("fileContent", fileReader.result);
				setValue("filename", file.name);
			} catch (err) {
				if (err instanceof LocalizedError) {
					platformToast({
						children: err.localizedMsg,
						severity: "error",
						icon: "Portfolio",
					});
				} else {
					platformToast({
						children: t("PORTFOLIOS.PORTFOLIO_GENERIC_UPLOAD_ERROR"),
						severity: "error",
						icon: "Portfolio",
					});
				}
				reportPlatformError(err, "ERROR", "portfolio", "upload portfolio");
				throw err;
			}
		},
		[onReset, setValue, t],
	);

	const verifyFile = useCallback(
		async (body: { composition: Blob }) => {
			const verifiedFile = await axiosExtract(
				editorApi.verifyEditorExcel(body.composition, {
					entity: uploadEntity,
					policy: getValues("uploadStrategy"),
					composition: currentComposition ?? [],
				}),
			);

			if (verifiedFile.errors && verifiedFile.errors.length > 0) {
				setValue("fileErrors", verifiedFile.errors);
				if (verifiedFile.errors.filter((error) => uploadErrorMap[error.code!].type === "error").length > 0) {
					throw new LocalizedError("file is not uploadable", t("FILE_KNOWN_ERROR"));
				}
			}

			return verifiedFile.composition;
		},
		[currentComposition, editorApi, getValues, setValue, t, uploadEntity],
	);

	const onSubmitAsync = useCallback(async () => {
		await handleSubmit(async (formData) => {
			try {
				setValue("status", "calculating");

				const fileReaderResult = formData.fileContent;
				if (!fileReaderResult) {
					throw new Error("missing file");
				}

				const body = {
					composition: new Blob([fileReaderResult as ArrayBuffer]),
				};
				const verifiedComposition = await verifyFile(body);
				setValue("status", "ready");
				props.onSave?.(verifiedComposition ?? []);

				setIsModalOpen(false);
				reset();
			} catch (error) {
				if (error instanceof LocalizedError) {
					platformToast({
						children: error.localizedMsg,
						severity: "error",
						icon: "Icon-full-error",
					});
				} else {
					platformToast({
						children: "Unable to generate the composition",
						severity: "error",
						icon: "Icon-full-error",
					});
				}

				setValue("status", "error");
				throw error;
			}
		})();
	}, [handleSubmit, props, reset, setValue, verifyFile]);

	return (
		<>
			{renderCustomButton ? (
				renderNodeOrFn(renderCustomButton, {
					setShowDialog: setIsModalOpen,
				})
			) : (
				<Button classList="flex gap-2" palette={palette} size="small" onClick={handleModalStatus} {...dataAttributes}>
					<Icon icon="Upload" size={18} />
					<p>Upload excel</p>
				</Button>
			)}
			<Dialog
				size="xlarge"
				show={isModalOpen}
				onClose={handleModalStatus}
				onSubmitAsync={onSubmitAsync}
				header={`Upload ${downloadTitle[uploadEntity]}`}
				footer={
					<DialogFooter
						primaryAction={<SubmitButton disabled={observedFileContent === null}>Save</SubmitButton>}
						neutralAction={
							<Button palette="tertiary" onClick={handleModalStatus}>
								Cancel
							</Button>
						}
					/>
				}
			>
				<Description onDownload={handleDownloadTemplate} uploadType={uploadEntity} />
				{/* <p className="mb-2">{downloadDescription[uploadEntity]}</p> */}
				<MainBlock
					control={control}
					uploadType={uploadEntity}
					onChangeFile={onChangeFile}
					onReset={() => reset({ uploadStrategy: getValues("uploadStrategy") })}
					file={watch()}
				/>
			</Dialog>
		</>
	);
};

export default UploadInstrumentButton;
