import type { CSSProperties } from "react";
import type React from "react";
import { useEffect, useState } from "react";
import { Icon } from "@mdotm/mdotui/components";
import type { UseTransientNotificationParams } from "$root/notification-system/transient";
import { useSize } from "$root/utils/react-dom-extra";
import type { TransientNotificationData } from "../hooks";
import { animationDuration } from "../hooks";

const colorByVariant: Record<UseTransientNotificationParams["variant"], string> = {
	info: "#00AEEF",
	warning: "#F26D2D",
	error: "#ea401f",
	success: "#4cb09c",
	// default: "#313131",
};

const iconByVariant: Record<UseTransientNotificationParams["variant"], React.ReactNode> = {
	error: <Icon icon="Icon-full-error" color="white" size="1.5rem" />,
	info: <Icon icon="info" color="white" size="1.5rem" />,
	success: <Icon icon="Icon-full-clock" color="white" size="1.5rem" />,
	warning: <Icon icon="Icon-full-alert" color="white" size="1.5rem" />,
	/* default: <></>, */
};

export type SnackMessageProps = {
	children: React.ReactNode | ((dismiss: () => void) => React.ReactNode);
	style?: CSSProperties;
	className?: string;
	variant: UseTransientNotificationParams["variant"];
	dismissible?: boolean;
	action?: React.ReactNode | ((dismiss: () => void) => React.ReactNode);
	dismiss: () => void;
};

/**
 * Component used to configure the default Snackbar (see src/App/index.tsx).
 */
export function SnackMessage(props: SnackMessageProps): JSX.Element {
	return (
		<div
			className={`px-4 py-4 w-full text-white flex flex-row text-base ${props.className ?? ""}`}
			style={{
				backgroundColor: colorByVariant[props.variant],
				...props.style,
			}}
		>
			<div className="overflow-hidden grow h-full flex flex-row items-center justify-between">
				<div className="flex flex-row min-w-0 items-start grow">
					<div className="mr-[10px]">{iconByVariant[props.variant]}</div>
					<div className="relative min-w-0 grow font-bold" style={{ fontSize: 12 }}>
						{typeof props.children === "function" ? props.children(props.dismiss) : props.children}
					</div>
					{props.action && <div>{typeof props.action === "function" ? props.action(props.dismiss) : props.action}</div>}
				</div>
				{(props.dismissible ?? true) && (
					<div>
						<button type="button" className="block" onClick={props.dismiss}>
							<Icon
								icon="Close"
								style={{
									color: "white",
								}}
								size="1.5rem"
							/>
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

export function AnimatedSnackWrapper({ props, animationStatus }: TransientNotificationData): JSX.Element {
	const [div, setDiv] = useState<HTMLDivElement | null>(null);
	const size = useSize(div, animationStatus === "hiding");
	// By postponing the moment we set the max-height property, the notification can adjust its size
	// automatically until it's time to hide it. At that point, the max-height is set to the latest available height,
	// the DOM updated and the max-height set to 0 immediately after, so that the CSS transition can take place.
	const [animationPhase, setAnimationPhase] = useState<"initial" | "set-height" | "hide">("initial");
	useEffect(() => {
		if (animationStatus === "hiding") {
			setAnimationPhase("set-height");
			requestAnimationFrame(() => setAnimationPhase("hide"));
		} else {
			setAnimationPhase("initial");
		}
	}, [animationStatus]);
	return (
		<div
			ref={setDiv}
			className="transition-[opacity,max-height] overflow-hidden"
			style={{
				opacity: animationStatus === "showing" ? 1 : 0,
				maxHeight: animationPhase === "initial" ? "none" : animationPhase === "set-height" ? size?.height : 0,
				transitionDuration: `${animationDuration}ms`,
			}}
		>
			<SnackMessage {...props} />
		</div>
	);
}
