import { useUserValue } from "$root/functional-areas/user";
import { useApiGen } from "$root/api/hooks";
import { DashboardControllerV3ApiFactory } from "$root/api/api-gen";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { createAtom } from "$root/third-party-integrations/zustand";

// TODO: avoid always refetching dashboard trace when call hook
// use atom and update it just when is the date is different
export const useTraceAtom = createAtom<{ creation_time: string }>({
	creation_time: new Date().toISOString(),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useWidgetsData = () => {
	const { value: currentWidgetsData, set: setWidgetsData } = useTraceAtom();
	const currentUser = useUserValue();

	const widgetsData = useApiGen(DashboardControllerV3ApiFactory);

	const { refetch: getCurrentWidgetsData } = useQueryNoRefetch(["retrieveTraceOptionData"], {
		enabled: Boolean(currentUser.id),
		queryFn: async () => {
			const { data } = await widgetsData.dashboardControllerGetTrace();
			return data;
		},
		onSuccess: (data) => {
			setWidgetsData({ creation_time: data });
		},
		onError: (e) => {
			console.error(e);
		},
	});

	return { currentWidgetsData, getCurrentWidgetsData };
};

export default useWidgetsData;
