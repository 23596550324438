import { MarketControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import DataSeriesModal from "$root/components/DataSeriesModal";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useEventBus } from "$root/event-bus";
import { noop } from "@mdotm/mdotui/utils";
import useWidgetsData from "$root/hooks/useWidgetsData";
import ChartStepperLined from "$root/ui-lib/charts/ChartStepperLined";
import { Text } from "@mdotm/mdotui/components";

type IndicatorsVariants = "equity_indicators" | "fixed_income_indicators" | "commodities_indicators";

const AssetClassOverviewBlock = (): JSX.Element => {
	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerApiFactory);
	const { currentWidgetsData } = useWidgetsData();

	const { data: indicators, refetch } = useQueryNoRefetch(["assetClassRegimeData"], {
		queryFn: async () => {
			const { data } = await marketApi.retrieveOverview();
			return {
				equity_indicators: data.equityIndicators,
				fixed_income_indicators: data.fixedIncomeIndicators,
				commodities_indicators: data.commoditiesIndicators,
			};
		},
		onError: (e) => console.warn(e),
	});

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: (
				<div className="flex">
					<DataSeriesModal typeMode="DASHBOARD" splitted={false} />
					<InfoTooltip>{t("ASSET_CLASS_OVERVIEW.TOOLTIP")}</InfoTooltip>
				</div>
			),
			title: t("ASSET_CLASS_OVERVIEW.TITLE"),
		}),
		[t],
	);

	return (
		<>
			{indicators && (
				<div className="grid grid-cols-3 gap-6">
					{(["equity_indicators", "fixed_income_indicators", "commodities_indicators"] as IndicatorsVariants[])
						.filter((ind) => indicators[ind])
						.map((indicator, i) =>
							(() => {
								const data = indicators[indicator];
								const { currentValue, prevValue } = data ?? {};
								if (currentValue?.value === undefined) {
									return <></>;
								}
								const sanitaizedPrevValue = prevValue?.value === currentValue.value ? undefined : prevValue?.value;
								const indicatorStrings = t(`OUTLOOK.indicators.${indicator}`, { returnObjects: true });
								const labels = indicatorStrings.labels;
								return (
									<div key={i} className="grow">
										<Text type="Body/M/Bold" classList="mb-2">
											{indicatorStrings.title}
										</Text>
										<div className="h-20 flex items-center">
											<ChartStepperLined
												columnNumber={5}
												current={{
													value: currentValue.value - 1,
													tooltip: [
														{
															label: t("CURRENT"),
															value: indicatorStrings.tooltips[currentValue.value - 1],
															data: currentValue?.data,
														},
													],
												}}
												previous={{
													value: sanitaizedPrevValue ? sanitaizedPrevValue - 1 : sanitaizedPrevValue,
													tooltip: sanitaizedPrevValue
														? [
																{
																	label: t("PREVIOUS"),
																	value: indicatorStrings.tooltips[sanitaizedPrevValue - 1],
																	data: prevValue?.data,
																},
														  ]
														: [],
												}}
												labels={labels}
												line="dotted"
												lastDashboardUpdate={currentWidgetsData.creation_time.toString()}
											/>
										</div>
									</div>
								);
							})(),
						)}
				</div>
			)}
		</>
	);
};

export default AssetClassOverviewBlock;
