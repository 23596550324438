export type FooterProps = {
	isLogin: boolean;
	isForgotPassword: boolean;
};

export const Footer = (props: FooterProps): JSX.Element => {
	return (
		<div style={{ width: "100%", color: "#8C8EA8", height: "41px", display: "flex", alignItems: "end" }}>
			<div>
				<div
					style={{
						fontSize: "12px",
						lineHeight: "1.5625rem",
						textAlign: "left",
						display: "flex",
						justifyContent: props.isLogin || props.isForgotPassword ? "center" : "inherit",
						position: props.isLogin || props.isForgotPassword ? "fixed" : "static",
						inset: "auto 0",
						bottom: 0,
						backgroundColor: props.isLogin || props.isForgotPassword ? "#2F3541" : "transparent",
						padding: "8px",
					}}
				>
					<span>MDOTM LTD 13, Austin Friars, EC2N 2HE London</span>
					<span style={{ margin: "0 20px" }}>|</span>
					{/* <a
        style={{ color: "#8C8EA8" }}
        onClick={(e) => {
          e.preventDefault();
        }}
        href="https://www.iubenda.com/privacy-policy/48016693"
        className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
        title="Privacy Policy"
      >
        Terms & conditions
      </a>
      <span style={{ margin: "0 5px" }}>-</span> */}
					<a
						style={{ color: "#8C8EA8" }}
						onClick={(e) => {
							e.preventDefault();
						}}
						href="https://www.iubenda.com/privacy-policy/48016693"
						className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
						title="Privacy Policy "
					>
						Privacy Policy
					</a>
				</div>
			</div>
		</div>
	);
};
