import {
	IntegrationsControllerApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	InvestmentSummary,
	ReviewTicker
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { filterMapAlerts } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload, { File } from "$root/hooks/useCompositionDownload";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { builtInSortFnFor } from "$root/utils/collections";
import { downloadFileBlob } from "$root/utils/files";
import type { ContextContent } from "$root/utils/react-extra";
import { useUpdatedRef, withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { Action, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Button,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	Table,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const defaultCompositionOrderBy: OrderBy[] = [{ columnName: "weight", direction: "desc" }];

const Composition = (props: ContextContent<typeof PortfolioContext>) => {
	const { enhanced, reportsExecution, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("COMPOSITION.TITLE"),
		}),
		[t],
	);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const query = useQueryNoRefetch(["compositionProvider", uuid, enhanced, portfolio?.status, reportExcutionCounter], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			}

			const data = await axiosExtract(
				props.enhanced
					? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
					: investmentReportApi.getInvestmentComposition(uuid),
			);
			// PROPOSAL: we can either remap the response here or ask the BE to give us the
			// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
			if (data.composition) {
				return {
					data: data.composition,
					widgetStatus: WidgetStatus.READY,
				};
			}

			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
			};
		},
	});

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(composition) => <CompositionInner ctx={props} composition={composition} />}
		</PortfolioQueryWidgetBase>
	);
};

const CompositionInner = (props: { ctx: ContextContent<typeof PortfolioContext>; composition: ReviewTicker[] }) => {
	const { t } = useTranslation();
	const user = useUserValue();
	const { composition, ctx } = props;
	const { enhanced, portfolio, currentTab, requestEnhance, requestOptimize, alerts } = ctx;

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const { investmentConvertionToFile } = useCompositionDownload();

	const fileConversion = useCallback((template: File) => {
		downloadFileBlob(template, {
			// TODO: remove extension once BE updates their filenames so that it's included
			fileName: template.name,
		});
	}, []);

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		const { data, headers } = enhanced
			? downloadTarget === "composition"
				? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
						responseType: "blob",
				  })
				: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
			: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });

		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
		const fileType = headers["content-type"];
		const fileName = headers["content-disposition"].split("filename=")[1];
		fileConversion({ data: data as Blob, name: fileName, type: fileType });
	};

	const customExportComposition = async (
		exportFormat: InvestmentExportConverterType,
		uuid: string,
		enhance?: boolean,
	) => {
		if (exportFormat === InvestmentExportConverterType.EasimTemplateConverter) {
			const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhance));
			await axiosExtract(integrationsApi.convertTo(exportFormat, [composition], true));
			platformToast({
				children: "Sphere has taken over your request",
				severity: "info",
				icon: "Dowload",
			});
			return;
		}

		const conversion = await investmentConvertionToFile(
			exportFormat,
			uuid,
			enhance,
			// exportFormat === InvestmentExportConverterType.EasimTemplateConverter,
		);
		// if (exportFormat !== InvestmentExportConverterType.EasimTemplateConverter) {
		// TODO: remove extension once BE updates their filenames so that it's included
		downloadFileBlob(conversion, { fileName: `${conversion.name}` });
		// } else {
		// 	platformToast({
		// 		children: "Sphere has taken over your request",
		// 		severity: "info",
		// 		icon: "Dowload",
		// 	});
		// }
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: "composition",
			ID: portfolio!.uuid!,
		});
	};

	const exportPortfolioCompositionRef = useUpdatedRef(exportPortfolioComposition);
	const exportCustomCompositionRef = useUpdatedRef(customExportComposition);

	const formatters = useLocaleFormatters();

	useWidgetOptions(
		() => ({
			alertsActive: currentTab !== 1,
			alerts: filterMapAlerts({ t, ...formatters, requestOptimize, requestEnhance }, "Composition", alerts),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								({ onClose }) => (
									<DropdownMenuActionButton
										key="composition"
										icon="Dowload"
										onClickAsync={async () => {
											await exportPortfolioCompositionRef.current("composition");
											onClose();
										}}
									>
										{t("COMPOSITION.DOWNLOAD_TITLE")}
									</DropdownMenuActionButton>
								),
								...(hasAccess(user, { requiredService: "EXPORT" }) &&
								user.exportFormats &&
								user.exportFormats.length > 0
									? user.exportFormats!.map(
											(exportConverter): Action =>
												function Exports({ onClose }) {
													return (
														<DropdownMenuActionButton
															icon="Dowload"
															key="customDownload"
															onClickAsync={async () => {
																if (user.exportFormats) {
																	await exportCustomCompositionRef.current(
																		exportConverter,
																		portfolio?.uuid ?? "",
																		enhanced,
																	);
																}
																onClose();
															}}
														>
															{`Export ${t(`EXPORT.${exportConverter}`).toLowerCase()} portfolio`}
														</DropdownMenuActionButton>
													);
												},
									  )
									: []),
								portfolio?.status === "PROPOSAL_READY" &&
									enhanced &&
									(({ onClose }) => (
										<DropdownMenuActionButton
											key="trades"
											icon="Dowload"
											onClickAsync={async () => {
												await exportPortfolioCompositionRef.current("trades");
												onClose();
											}}
										>
											{t("COMPOSITION.DOWNLOAD_TRADES_TITLE")}
										</DropdownMenuActionButton>
									)),
							]}
						/>
						{/* <InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip> */}
					</div>
				);
			},
			title: t("COMPOSITION.TITLE"),
		}),
		[
			alerts,
			currentTab,
			enhanced,
			exportCustomCompositionRef,
			exportPortfolioCompositionRef,
			formatters,
			portfolio?.status,
			portfolio?.uuid,
			requestEnhance,
			requestOptimize,
			t,
			user,
		],
	);

	return <CompositionTable {...ctx} rows={composition} />;
};

const CompositionTable = (props: {
	portfolio: InvestmentSummary | null;
	enhanced: boolean;
	alerts: PortfolioAlert[];
	rows: ReviewTicker[];
	// refetchComposition: QueryObserverBaseResult<ReviewTicker[]>["refetch"];
}) => {
	const { push } = useTypedNavigation();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const user = useUserValue();

	// useEventBus("investment-update", {
	// 	filter: objMatchFn({ uuid: props.portfolio?.uuid }),
	// 	listener: () => {
	// 		props.refetchComposition().then().catch(noop);
	// 	},
	// });

	const edit =
		props.portfolio?.status !== "PROPOSAL_READY" &&
		props.portfolio?.status !== "ERROR" &&
		props.portfolio?.status !== "CALCULATING" &&
		aclByArea.portfolio.canEditComposition(user.id, [
			{ userId: user.id, permissions: props.portfolio?.richAcl?.currentUserPermissions },
		]);

	const columns = useMemo<Array<TableColumn<ReviewTicker>>>(
		() =>
			props.enhanced
				? [
						{
							relativeWidth: 40,
							header: t("COMPOSITION.INSTRUMENT"),
							sortFn: builtInSortFnFor("instrument"),
							name: "instrument",
							content: ({ instrument, tickerId, commentary, commentaryCreator }) => (
								<AutoTooltip
									align="startToStart"
									disabled={!commentary}
									trigger={({ innerRef }) => (
										<div className="flex items-center flex-nowrap w-full pr-4" ref={innerRef}>
											<div
												className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
													underline: Boolean(commentary),
												})}
											>
												{instrument ?? ""}
											</div>
											{props.alerts.some(
												(a) =>
													(a.type === "InstrumentsChangesAlertCheck" ||
														a.type === "InstrumentsChangesChildAlertCheck") &&
													a.value.tickerId === tickerId,
											) && (
												<span
													className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
													style={{ fontSize: 8 }}
												>
													{t("UPDATED")}
												</span>
											)}
										</div>
									)}
								>
									{commentaryCreator === "SPHERE" ? (
										<TooltipContent>
											<MarkdownRenderer>{commentary ?? ""}</MarkdownRenderer>
										</TooltipContent>
									) : (
										commentary
									)}
								</AutoTooltip>
							),
						},
						{
							relativeWidth: 11,
							header: t("COMPOSITION.IDENTFIER"),
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							content: ({ identifier }) => identifier,
						},
						{
							relativeWidth: 10,
							header: t("COMPOSITION.ASSET_CLASS"),
							sortFn: builtInSortFnFor("assetClass"),
							name: "assetClass",
							content: ({ assetClass }) => assetClass,
						},
						{
							relativeWidth: 15,
							header: t("COMPOSITION.MICRO_ASSET_CLASS"),
							sortFn: builtInSortFnFor("microAssetClass"),
							name: "microAssetClass",
							content: ({ microAssetClass }) => microAssetClass,
						},
						{
							relativeWidth: 8,
							header: t("COMPOSITION.CURRENT_WEIGHT"),
							sortFn: builtInSortFnFor("previousWeight"),
							name: "previousWeight",
							cellClassList: "tabular-nums",
							content: ({ previousWeight }) => `${formatNumber(previousWeight ?? 0)}%`,
							align: "end",
						},
						{
							relativeWidth: 8,
							header: t("COMPOSITION.ENHANCED_WEIGHT"),
							sortFn: builtInSortFnFor("weight"),
							name: "weight",
							cellClassList: "tabular-nums",
							content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
							align: "end",
						},
						{
							relativeWidth: 10,
							header: t("COMPOSITION.DIFFERENCE"),
							align: "end",
							cellClassName: "pr-1",
							content: (instrument) => {
								const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
								return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
							},
							sortFn: (rowa, rowb) => {
								const deltaA = (rowa.weight ?? 0) - (rowa.previousWeight ?? 0);
								const deltaB = (rowb.weight ?? 0) - (rowb.previousWeight ?? 0);

								if (deltaA > deltaB) {
									return 1;
								}

								if (deltaA < deltaB) {
									return -1;
								}

								return 0;
							},
							name: "difference",
						},
						{
							header: props.portfolio?.scoreIdentifier ? (
								<CustomLabels labelKey={props.portfolio?.scoreIdentifier} fallback={t("SCORE")} />
							) : (
								t("SCORE")
							),
							content: ({ score }) => (score ? formatNumber(score) : ""),
							sortFn: builtInSortFnFor("score"),
							name: "score",
							relativeWidth: 10,
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
						},
				  ]
				: [
						{
							relativeWidth: 30,
							header: t("COMPOSITION.INSTRUMENT"),
							sortFn: builtInSortFnFor("instrument"),
							name: "instrument",
							content: ({ instrument, tickerId, commentary, commentaryCreator }) => (
								<AutoTooltip
									align="startToStart"
									disabled={!commentary}
									trigger={({ innerRef }) => (
										<div className="flex flex-row items-center flex-nowrap w-full pr-4" ref={innerRef}>
											<div
												className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
													underline: Boolean(commentary),
												})}
											>
												{instrument ?? ""}
											</div>
											{props.alerts.some(
												(a) =>
													(a.type === "InstrumentsChangesAlertCheck" ||
														a.type === "InstrumentsChangesChildAlertCheck") &&
													a.value.tickerId === tickerId,
											) && (
												<span
													className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
													style={{ fontSize: 8 }}
												>
													{t("UPDATED")}
												</span>
											)}
										</div>
									)}
								>
									{commentaryCreator === "SPHERE" ? (
										<TooltipContent>
											<MarkdownRenderer>{commentary ?? ""}</MarkdownRenderer>
										</TooltipContent>
									) : (
										commentary
									)}
								</AutoTooltip>
							),
						},
						{
							relativeWidth: 15,
							header: t("COMPOSITION.IDENTFIER"),
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							content: ({ identifier }) => identifier,
						},
						{
							relativeWidth: 10,
							header: t("COMPOSITION.ASSET_CLASS"),
							sortFn: builtInSortFnFor("assetClass"),
							name: "assetClass",
							content: ({ assetClass }) => assetClass,
						},
						{
							relativeWidth: 25,
							header: t("COMPOSITION.MICRO_ASSET_CLASS"),
							sortFn: builtInSortFnFor("microAssetClass"),
							name: "microAssetClass",
							content: ({ microAssetClass }) => microAssetClass,
						},
						{
							relativeWidth: 10,
							header: t("COMPOSITION.WEIGHT"),
							sortFn: builtInSortFnFor("weight"),
							name: "weight",
							align: "end",
							cellClassList: "tabular-nums",
							content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
						},
						{
							header: props.portfolio?.scoreIdentifier ? (
								<CustomLabels labelKey={props.portfolio?.scoreIdentifier} fallback={t("SCORE")} />
							) : (
								t("SCORE")
							),
							content: ({ score }) => (score ? formatNumber(score) : ""),
							sortFn: builtInSortFnFor("score"),
							name: "score",
							relativeWidth: 10,
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
						},
				  ],
		[props.enhanced, props.portfolio?.scoreIdentifier, props.alerts, t, user, formatNumber],
	);

	return (
		<div className="h-full flex flex-col">
			<div className="grow mb-4">
				<Table
					visibleRows={edit ? 8 : 9}
					columns={columns}
					rows={props.rows}
					noDataText={t("COMPOSITION.NO_COMPOSITION")}
					orderBy={defaultCompositionOrderBy}
				/>
			</div>
			{edit && (
				<div className="flex justify-end shrink">
					<Button
						size="small"
						palette="primary"
						onClick={() => push("EditPortfolioCompositionPage", { portfolioUid: props.portfolio!.uuid! })}
					>
						{t("EDIT_COMPOSITION.EDIT_COMPOSITION_BUTTON")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default withContext(PortfolioContext)(Composition);
