export function maybeMap<TIn, TOut>(x: TIn | undefined | null, mapFn: (x: TIn) => TOut): TOut | undefined {
	if (x === undefined || x === null) {
		return undefined;
	}
	return mapFn(x);
}

export function findWithIndex<T>(list: T[], filter: (item: T) => boolean): { item: T; index: number } | undefined {
	const index = list.findIndex(filter);
	return index > -1 ? { item: list[index]!, index } : undefined;
}
