import { Chip } from "$root/api/api-gen";
import { useTranslation } from "react-i18next";

function usePositionComposer(): {
	positioningAreaByValue: Record<number, string>;
	positioningAreaByLabel: Record<string, number>;
} {
	const { t } = useTranslation();

	const positioningAreaByLabel: Record<string, number> = {
		[t("STRONG_UNDERWEIGHT")]: 1,
		[t("UNDERWEIGHT")]: 2,
		[t("NEUTRAL")]: 3,
		[t("OVERWEIGHT")]: 4,
		[t("S_OVERWEIGHT")]: 5,
	};

	const positioningAreaByValue: Record<number, string> = {
		1: t("STRONG_UNDERWEIGHT"),
		2: t("UNDERWEIGHT"),
		3: t("NEUTRAL"),
		4: t("OVERWEIGHT"),
		5: t("S_OVERWEIGHT"),
	};

	return { positioningAreaByValue, positioningAreaByLabel };
}

export type Drivers = {
	type?: "RETURN" | "VOLATILITY" | "DIVERSIFICATION";
	value?: 1 | 2 | 3 | 4 | 5;
};

function useDriverComposer(): {
	composeDrivers: (positioning: string, driver?: Chip[]) => { label: string; value: number }[];
	TYPE_DICT: {
		EQ: string;
		FI: string;
		CO: string;
		1: string;
		2: string;
		3: string;
		4: string;
		5: string;
		DRIVER: {
			RETURN: { 1: string; 2: string; 3: string; 4: string; 5: string };
			VOLATILITY: { 1: string; 2: string; 3: string; 4: string; 5: string };
			DIVERSIFICATION: { 1: string; 2: string; 3: string; 4: string; 5: string };
		};
	};
} {
	const { t } = useTranslation();
	const TYPE_DICT = {
		EQ: t("EQUITY"),
		FI: t("OUTLOOK.FI"),
		CO: t("OUTLOOK.CO"),
		1: t("STRONG_UNDERWEIGHT"),
		2: t("UNDERWEIGHT"),
		3: t("NEUTRAL"),
		4: t("OVERWEIGHT"),
		5: t("S_OVERWEIGHT"),
		DRIVER: {
			RETURN: {
				1: "LOW_RETURN",
				2: "LOW_RETURN",
				3: "NEUTRAL",
				4: "HIGH_RETURN",
				5: "HIGH_RETURN",
			},
			VOLATILITY: {
				1: "LOW_VOLATILITY",
				2: "LOW_VOLATILITY",
				3: "NEUTRAL",
				4: "HIGH_VOLATILITY",
				5: "HIGH_VOLATILITY",
			},
			DIVERSIFICATION: {
				1: "LOW_DIVERSIFICATION",
				2: "LOW_DIVERSIFICATION",
				3: "NEUTRAL",
				4: "HIGH_DIVERSIFICATION",
				5: "HIGH_DIVERSIFICATION",
			},
		},
	};

	const composeDrivers = (positioning: string, driver?: Chip[]) => {
		if (!driver || !driver.length) {
			const isCombined = positioning.toUpperCase() !== "NEUTRAL";
			return isCombined ? [{ label: "Combined", value: 6 }] : [{ label: "-", value: 6 }];
		}
		const chips = t("ASSET_CLASS_SUMMARY.UO_WEIGHT.CHIPS", { returnObjects: true });
		return driver.reduce<Array<{ label: string; value: number }>>((acc, { type, value }) => {
			if (!type || !value) {
				return acc;
			}
			const composeChips =
				TYPE_DICT["DRIVER"][type as "RETURN" | "VOLATILITY" | "DIVERSIFICATION"][value as 1 | 2 | 3 | 4 | 5];
			const regime = chips[composeChips as keyof typeof chips];
			return [...acc, { label: regime, value }];
		}, []);
	};

	return { composeDrivers, TYPE_DICT };
}

const composeOutlookLink = (filter: string, filter2: string, sector: string, geo: string): string => {
	let pathAssetFilter = `/${sector === "ALL" ? "outlook" : "asset_class"}?filter=${filter}`;

	if (filter === "CO") {
		return (pathAssetFilter += `&area=${sector}`);
	}

	if (filter === "EQ" && filter2 === "MARKETS") {
		return (pathAssetFilter += `&area=${geo}`);
	}

	return (pathAssetFilter += `&area=${geo}&sector=${sector}`);
};

export { usePositionComposer, useDriverComposer, composeOutlookLink };
