import { PageHeader } from "$root/components/PageHeader";
import { EditorCompositionContextProvider } from "$root/functional-areas/edit-composition/EditorComposition";
import CompositionEditor from "$root/functional-areas/edit-composition/EditorCompositionTable";
import { EditorTableAreaEnum } from "$root/functional-areas/edit-composition/tools/shared";
import { Button } from "@mdotm/mdotui/components";

const Subtitle = (props: { onCancel?(): void; onSubmit?(): void }) => {
	return (
		<div className="flex justify-between items-center py-2.5">
			<p>
				Select assets available in you account, add an empty row to specify the asset identifier, or upload a list of
				assets and weights by uploading an excel with your desired composition.
			</p>
			<div className="flex gap-2">
				{props.onCancel && (
					<Button size="small" palette="secondary" onClick={props.onCancel}>
						Cancel
					</Button>
				)}
				{props.onSubmit && (
					<Button palette="primary" size="small" onClick={props.onSubmit}>
						Done
					</Button>
				)}
			</div>
		</div>
	);
};

const Editor = () => {
	return (
		<EditorCompositionContextProvider area={{ name: EditorTableAreaEnum.CREATE }} entity="INVESTMENT">
			{({ compositionBuilder, area, entity }) => (
				<>
					<PageHeader title="New portfolio" subTitle={<Subtitle onSubmit={() => console.log("submit")} />} />
					<CompositionEditor compositionBuilder={compositionBuilder} area={area} deleteMode="hard" entity={entity} />
				</>
			)}
		</EditorCompositionContextProvider>
	);
};

export default Editor;
