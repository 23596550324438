import type { DropdownMenuProps, StylableProps } from "@mdotm/mdotui/components";
import { Button, Icon } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";

export type DropdownMenuTriggerProps<T extends string> = Parameters<
	DropdownMenuProps<HTMLButtonElement, T>["trigger"]
>[0] & {
	children?: ReactNode;
	disabled?: boolean;
} & StylableProps;

export function DropdownMenuTrigger<D extends string>({
	children,
	open,
	...forwardProps
}: DropdownMenuTriggerProps<D>): JSX.Element {
	return (
		<Button palette="secondary" size="small" {...forwardProps}>
			{children}&nbsp;
			<span className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex" aria-expanded={open}>
				<Icon icon="Down" />
			</span>
		</Button>
	);
}

export type TinyDropdownMenuTriggerProps<T extends string> = Parameters<
	DropdownMenuProps<HTMLButtonElement, T>["trigger"]
>[0] & {
	children?: ReactNode;
};

export function TinyDropdownMenuTrigger<D extends string>({
	open: _open,
	children,
	...forwardProps
}: TinyDropdownMenuTriggerProps<D>): JSX.Element {
	return (
		<Button unstyled {...forwardProps}>
			{children ?? <Icon icon="More" classList="rotate-90" size={20} color={themeCSSVars.MessageSeverity_success} />}
		</Button>
	);
}
