import type {
	MarketViewBulkSaveRequest,
	MarketViewResponse,
	SelectableStrategyConstraintsResponse,
} from "$root/api/api-gen";
import type { EditPortfolioMultiV4Props } from "./EditPortfolioMulti";
import type { EditPortfolioMultiV4StepPayloadMap } from "./shared-multi";

type EditPortfolioMultiV4BackendRequestPayloadMap = {
	marketView: MarketViewBulkSaveRequest;
};

type EditPortfolioMultiV4BackendResponsePayloadMap = {
	portfolioStrategy: SelectableStrategyConstraintsResponse;
	marketView: MarketViewResponse;
};

export const responseToStepMapper: {
	[K in keyof EditPortfolioMultiV4StepPayloadMap]: (
		backendData: EditPortfolioMultiV4BackendResponsePayloadMap[K],
		area: EditPortfolioMultiV4Props["area"],
	) => EditPortfolioMultiV4StepPayloadMap[K];
} = {
	marketView: (mv) => ({
		scenarioData: mv.scenarioData,
		scenarioIdentifier: mv.scenarioIdentifier,
		forecastHorizon: mv.scenarioData?.forecastHorizon,
		custom: undefined,
		name: undefined
	}),
};

export function makeStepToRequestMapper(proposalUuid: string | undefined): {
	[K in keyof EditPortfolioMultiV4StepPayloadMap]: (
		frontendData: EditPortfolioMultiV4StepPayloadMap[K],
	) => EditPortfolioMultiV4BackendRequestPayloadMap[K];
} {
	return {
		marketView: (a) =>
			({
				// marketView: {
				// 	regimeDefaultProbability: a.scenarioData?.regimeProbabilities,
				// 	regimeUserProbability: a.scenarioData?.regimeUserProbability,
				// 	scenarioIdentifier: a.scenarioIdentifier,
				// 	expectedReturnsVolatility: a.scenarioData?.assetClasses,
				// 	forecastHorizon: a.scenarioData?.forecastHorizon,
				// 	positioningIndicators: a.scenarioData?.positioningIndicators,
				// 	marketViewType: a.scenarioData?.marketViewType,
				// },
				bulkUUID: proposalUuid,
				scenarioIdentifier: a.scenarioIdentifier,
				custom: a.custom,
				flexibleExpectedReturnsVolatility: a.scenarioData?.flexibleExpectedReturnsVolatility,
				defaultRegimeProbabilities: a.scenarioData?.regimeProbabilities,
				userRegimeProbabilities: a.scenarioData?.regimeUserProbability,
				forecastHorizon: a.scenarioData?.forecastHorizon,
				positioningIndicators: a.scenarioData?.positioningIndicators,
				marketViewType: a.scenarioData?.marketViewType,
				// TODO: investmentUuids: investmentUuids,
			}) satisfies NonNullable<EditPortfolioMultiV4BackendRequestPayloadMap["marketView"]>,
	};
}
