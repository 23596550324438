import type { InvestmentSummary, ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { InfoDelta } from "$root/components/InfoDelta";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type {
	ReportTemplateItemMap,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { noop } from "$root/utils/runtime";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

const rowHeight = 41;

function useCompositionColumn(
	preferences:
		| ReportTemplateItemMap["composition"]["currentColumnPreferences"]
		| ReportTemplateItemMap["composition"]["proposalColumnPreferences"],
	portfolio: InvestmentSummary,
) {
	const { t } = useTranslation();
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const columns = useMemo(
		() =>
			preferences.map(
				(preference) =>
					({
						header: t(`TABLE.HEADERS.${preference.column}`),
						hidden: preference.enabled === false,
						sortFn: undefined,
						hiderOrderableIcon: true,
						...match(preference)
							.with(
								{ column: "INSTRUMENT_NAME" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									content: ({ instrument }) => instrument ?? "",
									relativeWidth: hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) ? 2 : 2.5,
								}),
							)
							.with(
								{ column: "IDENTIFIER" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									content: ({ identifier }) => identifier ?? "",
									relativeWidth: hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) ? 2 : 1.5,
								}),
							)
							.with(
								{ column: "ASSET_CLASS" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									content: ({ assetClass }) => assetClass ?? "",
								}),
							)
							.with(
								{ column: "SCORE" },
								(): TableColumn<ReviewTicker> => ({
									header: portfolio?.scoreIdentifier ? (
										<CustomLabels labelKey={portfolio?.scoreIdentifier} fallback={t("SCORE")} />
									) : (
										t("SCORE")
									),
									content: ({ score }) => (score ? formatNumber(score) : ""),
									name: "score",
									relativeWidth: 1,
									hidden: preference.enabled === false || !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
								}),
							)
							.with(
								{ column: "WEIGHT" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									cellClassList: "tabular-nums",
									content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
									align: "end",
								}),
							)
							.with(
								{ column: "MICRO_ASSET_CLASS" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									cellClassList: "tabular-nums",
									content: ({ microAssetClass }) => microAssetClass ?? "",
									align: "end",
								}),
							)
							.with(
								{ column: "CURRENT_WEIGHT" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									cellClassList: "tabular-nums",
									content: ({ previousWeight }) => `${formatNumber(previousWeight ?? 0)}%`,
									align: "end",
									relativeWidth: 1.0,
								}),
							)
							.with(
								{ column: "ENHANCED_WEIGHT" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									cellClassList: "tabular-nums",
									content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
									align: "end",
									relativeWidth: 1.0,
								}),
							)
							.with(
								{ column: "DIFFERENCE" },
								(): Omit<TableColumn<ReviewTicker>, "header"> => ({
									align: "end",
									cellClassList: "pr-1",
									content: (instrument) => {
										const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
										return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
									},
									relativeWidth: 1.5,
								}),
							)
							.exhaustive(),
					}) as TableColumn<ReviewTicker>,
			),
		[formatNumber, portfolio?.scoreIdentifier, preferences, t, user],
	);

	return columns;
}

const Composition = ({
	config,
	variant,
	portfolio,
	list,
}: PrintableProps<
	{ config: ReportTemplateItemMap["composition"]; variant: ReportTemplateVariant; portfolio: InvestmentSummary },
	ReviewTicker
>): JSX.Element => {
	const { t } = useTranslation();
	const columns = useCompositionColumn(
		variant === "current" ? config.currentColumnPreferences : config.proposalColumnPreferences,
		portfolio,
	);

	return (
		<Card title="Composition">
			<Table
				palette="uniform"
				columns={columns}
				rows={list}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
				rowHeight={rowHeight}
				headerRowStyle={{ height: "40px" }}
			/>
		</Card>
	);
};

export default Composition;
