import type { PropsWithChildren } from "react";

type RenderingGuardProps<T> =
	| {
			/**
			 *  `value` must be a valid option provided in `options` to rendered the component,
			 *  otherwise it will removed from the dom
			 */
			type: "variant";
			/**
			 * Insert the list of valid variants
			 */
			options: T[];
			/**
			 * Insert a valid value
			 */
			value: T;
	  }
	| {
			type: "condition";
			/**
			 *  Assert condition if `true`, the component will be rendered,
			 *  otherwise it will removed from the dom
			 */
			when: boolean;
	  };

const RenderingGuard = <D,>(props: PropsWithChildren<RenderingGuardProps<D>>): JSX.Element => {
	switch (props.type) {
		case "variant":
			if (!props.options.includes(props.value)) {
				return <></>;
			}
			return <>{props.children}</>;
		case "condition":
			if (props.when === false) {
				return <></>;
			}
			return <>{props.children}</>;
		default:
			return <></>;
	}
};

export default RenderingGuard;
