import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type CreateDialogCommon = Omit<EntityManagementBaseDialogCommon, "currentName">;
export type CreateDialogProps = Omit<EntityManagementBaseDialogProps, "currentName">;
export type SpawnCreateDialogParams = Omit<SpawnEntityManagementBaseDialogParams, "currentName">;

function withDefaults<T extends SpawnCreateDialogParams | CreateDialogProps>(
	params: T,
): EntityManagementBaseDialogCommon & T {
	return {
		header: params.header ?? (params.entityType ? `Create ${params.entityType}` : "Create"),
		inputLabel: params.inputLabel ?? "Name",
		size: "medium",
		...params,
	};
}

export function spawnCreateDialog(params: SpawnCreateDialogParams): Promise<string | null> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function CreateDialog(props: CreateDialogProps): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
