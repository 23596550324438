import type { ReferenceUniverseDetails } from "$root/api/api-gen";
import { createContext } from "react";

export const ModeVariants = { editor: "editor", viewer: "viewer", review: "review" } as const;
export type ModeVariants = (typeof ModeVariants)[keyof typeof ModeVariants];

export const UniverseContext = createContext<{
	universe: ReferenceUniverseDetails | null;
	mode: ModeVariants;
}>({
	universe: null,
	mode: ModeVariants.viewer,
});
