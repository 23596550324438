import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AuthorizationGuard from "./AuthorizationGuard";
import type { ButtonProps, StylableProps } from "@mdotm/mdotui/components";
import { AsyncButton, Button, Dialog, DialogFooter, DialogHeader, Icon, ScrollWrapper } from "@mdotm/mdotui/components";
import { createPortal } from "react-dom";
import ReactJson from "react-json-view";
import { toClassName, useEventListener, useUniqueDOMId, useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { Map } from "immutable";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import type { ContextContent } from "$root/utils/react-extra";

export type DataDisplayOverlayProps = StylableProps & { dataSource?: string } & (
		| { data: any; dataProvider?: undefined }
		| { data?: undefined; dataProvider: () => any }
	);

type Overlay = { dataSource: string; dataProvider: () => any };

export const DataDisplayOverlayContext = createContext<{
	upsertOverlay(id: string, props: Overlay): void;
	removeOverlay(id: string): void;
	toggleVisibility(id: string): void;
} | null>(null);

export function DataDisplayOverlayProvider({ children }: { children: ReactNode }): JSX.Element {
	const [overlays, setOverlays] = useState(() => Map<string, Overlay>());
	const [onlyVisibleId, setOnlyVisibleId] = useState<string | null>(null);

	const sortedVisibleOverlays = useMemo(() => {
		const visibleOverlay = onlyVisibleId && overlays.get(onlyVisibleId);
		return visibleOverlay
			? [{ id: onlyVisibleId, ...visibleOverlay }]
			: Array.from(overlays.entries())
					.map(([id, { dataProvider, dataSource }]) => ({ id, dataProvider, dataSource }))
					.sort(builtInSortFnFor("dataSource"));
	}, [onlyVisibleId, overlays]);

	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const [isClickable, setIsClickable] = useState(false);
	const [asModal, setAsModal] = useState(false);

	useEffect(() => {
		if (!isOverlayOpen) {
			setOnlyVisibleId(null);
		}
	}, [isOverlayOpen]);

	const onlyVisibleIdRef = useUpdatedRef(onlyVisibleId);
	const ctx: ContextContent<typeof DataDisplayOverlayContext> = useMemo(
		() => ({
			removeOverlay: (id) => setOverlays((o) => o.remove(id)),
			upsertOverlay: (id, overlay) => setOverlays((o) => o.set(id, overlay)),
			toggleVisibility: (id) => {
				if (onlyVisibleIdRef.current === id) {
					setOnlyVisibleId(null);
					setIsOverlayOpen(false);
				} else {
					setOnlyVisibleId(id);
					setIsOverlayOpen(true);
				}
			},
		}),
		[onlyVisibleIdRef],
	);

	useEventListener(window, "keydown", (e) => {
		if (e.key === "Alt" && !asModal) {
			e.preventDefault();
			setIsClickable(true);
		}
	});
	useEventListener(window, "keydown", (e) => {
		if (e.key === "F6") {
			e.preventDefault();
			setIsOverlayOpen(!isOverlayOpen);
		}
	});
	useEventListener(window, "keyup", () => setIsClickable(false));

	const [isCopied, setIsCopied] = useState(false);
	const copyToClipboard = useCallback(async () => {
		try {
			await navigator.clipboard.writeText(
				JSON.stringify(
					Object.fromEntries(sortedVisibleOverlays.map(({ dataSource, dataProvider }) => [dataSource, dataProvider()])),
				),
			);
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
	}, [sortedVisibleOverlays]);
	const copyToClipboardButton = useCallback(
		(size?: ButtonProps["size"]) => (
			<AsyncButton size={size} palette={isCopied ? "primary" : "tertiary"} onClickAsync={copyToClipboard}>
				{isCopied ? "Copied!" : "Copy"}
			</AsyncButton>
		),
		[copyToClipboard, isCopied],
	);

	const viewer = useMemo(
		() =>
			sortedVisibleOverlays.map(({ id, dataSource, dataProvider }) => (
				<div key={id}>
					<div>
						<span className={toClassName({ "font-bold": true, "bg-blue-300/80": !isClickable })}>{dataSource}</span>
					</div>
					<div>
						<ReactJson src={dataProvider()} />
					</div>
				</div>
			)),
		[isClickable, sortedVisibleOverlays],
	);

	return (
		<>
			<AuthorizationGuard requiredRole="ROOT">
				{() => (
					<>
						{asModal ? (
							<Dialog
								show={isOverlayOpen}
								size="xlarge"
								header="Data"
								footer={
									<DialogFooter
										neutralAction={copyToClipboardButton()}
										secondaryAction={<Button onClick={() => setAsModal(false)}>Show as Overlay</Button>}
										primaryAction={<Button onClick={() => setIsOverlayOpen(false)}>Close</Button>}
									/>
								}
								onClose={() => setIsOverlayOpen(false)}
								startShadow={false}
							>
								{viewer}
							</Dialog>
						) : (
							isOverlayOpen &&
							createPortal(
								<div
									className={toClassName({
										"fixed inset-0 flex flex-col": true,
										"pointer-events-none": !isClickable,
										"bg-white/90": isClickable,
									})}
									style={{ zIndex: 2147483648 }}
								>
									<div className="absolute right-0 top-0 z-10">
										<button
											type="button"
											onClick={() => setIsOverlayOpen(false)}
											className="text-white bg-black pointer-events-auto"
										>
											<Icon icon="Close" size={32} />
										</button>
									</div>
									<div className="relative z-0">
										<span className="bg-black/40 text-white">
											HINT: Use Alt+Click to interact with the JSON viewer. When Alt is NOT pressed, your clicks are
											propagated to the underlying elements.
										</span>
										<div className="py-2 flex space-x-4">
											{copyToClipboardButton("x-small")}
											<Button size="x-small" onClick={() => setAsModal(true)}>
												Show as Dialog
											</Button>
										</div>
									</div>
									<div
										className={toClassName({
											"flex-1 justify-items-stretch flex-col flex min-h-0 relative z-0": true,
											"[&_span]:bg-blue-300/80": !isClickable,
										})}
									>
										<ScrollWrapper>{viewer}</ScrollWrapper>
									</div>
								</div>,
								document.body,
							)
						)}
					</>
				)}
			</AuthorizationGuard>
			<DataDisplayOverlayContext.Provider value={ctx}>{children}</DataDisplayOverlayContext.Provider>
		</>
	);
}

export function DataDisplayOverlay({
	dataProvider: optionalDataProvider,
	dataSource,
	data: optionalData,
	classList,
	style,
}: DataDisplayOverlayProps): JSX.Element {
	const dataProvider = useMemo(
		() => optionalDataProvider ?? (() => optionalData),
		[optionalData, optionalDataProvider],
	);
	const ctx = useContext(DataDisplayOverlayContext);
	if (!ctx) {
		throw new Error("missing DataDisplayOverlayContext");
	}
	const ctxRef = useUpdatedRef(ctx);
	const id = useUniqueDOMId();
	useEffect(() => {
		ctxRef.current.upsertOverlay(id, { dataSource: dataSource ?? id, dataProvider });
	}, [ctxRef, dataProvider, dataSource, id]);
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => ctxRef.current.removeOverlay(id);
	}, [ctxRef, id]);

	return (
		<AuthorizationGuard requiredRole="ROOT">
			{() => (
				<Button
					palette="inverted"
					size="x-small"
					color="info"
					onClick={() => ctx.toggleVisibility(id)}
					classList={classList}
					style={style}
				>
					Toggle Data [{dataSource ?? ""}]
				</Button>
			)}
		</AuthorizationGuard>
	);
}
