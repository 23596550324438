import type {
	Currencies,
	EditorSaveNewRequestMandateTypeEnum,
	EditorSaveNewResponse,
	InvestmentBenchmark,
	InvestmentReference,
	ReviewTicker,
} from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import MonitorWithHourglass2 from "$root/components/glyphs/MonitorWithHourglass2";
import { useEventBus } from "$root/event-bus";
import UploadCompositionSection from "$root/functional-areas/upload/UploadCompositionSection";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { ActionText, Button, Icon, Modal, ProgressBar } from "@mdotm/mdotui/components";
import { type ReactNode, useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { PortfolioStudioTab } from "../PortfoliosStudio";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { ModeVariants } from "$root/widgets-architecture/contexts/universe";
import { platformToast } from "$root/notification-system/toast";
import type { AxiosError } from "axios";
import { useUserValue } from "$root/functional-areas/user";
import env from "$root/env";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { flushSync } from "react-dom";

export enum UploadEnum {
	INVESTMENT = "INVESTMENT",
	UNIVERSE = "UNIVERSE",
	TARGET_INVESTMENT = "TARGET_INVESTMENT",
	BENCHMARK = "BENCHMARK",
	INVESTMENT_ENHANCEMENT = "INVESTMENT_ENHANCEMENT",
	INVESTMENT_DRAFT = "INVESTMENT_DRAFT",
}

type useParmsProps = {
	uploadType: "portfolio" | "target" | "benchmark" | "universe" | "portfolioDraft";
	portfolioUid?: string;
};

const uploadEntityMap: Record<useParmsProps["uploadType"], UploadEnum> = {
	benchmark: UploadEnum.BENCHMARK,
	portfolio: UploadEnum.INVESTMENT,
	target: UploadEnum.TARGET_INVESTMENT,
	universe: UploadEnum.UNIVERSE,
	portfolioDraft: UploadEnum.INVESTMENT_DRAFT,
};

export type UploadEntity = keyof typeof UploadEnum;
const UploadPortfolioPage = (): JSX.Element => {
	const location = useLocation();
	const { uploadType, portfolioUid } = useParams<useParmsProps>();
	const [uploadEntity, setUploadEntity] = useState<UploadEnum>(uploadEntityMap[uploadType]);
	const [isCompositionDirty, setIsCompositionDirty] = useState<{ isDirty: boolean } | null>({ isDirty: false });
	const [pathToNotBlock, setPathToNotBlock] = useState(["/login"]);
	const [isForModalOpen, setIsFormModalOpen] = useState(false);
	console.log({ isCompositionDirty })
	/* pooling states */
	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
	const [newUploadUUID, setNewUploadUUID] = useState("");

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		if (
			uploadType === undefined ||
			["portfolio", "target", "benchmark", "universe", "portfolioDraft"].includes(uploadType) === false
		) {
			history.push("/portfolios");
		}
	}, [history, uploadType]);

	const uuids = useMemo(() => {
		const queryParamUUIDS = new URLSearchParams(location.search).get("uuids");
		if (!queryParamUUIDS) {
			return undefined;
		}
		return queryParamUUIDS.split(",");
	}, [location.search]);

	const uploadPath = useMemo<Record<useParmsProps["uploadType"], Array<{ children: ReactNode; href: string }>>>(
		() => ({
			portfolio: [
				{
					children: "Portfolio", // TODO: translate
					href: typedUrlForRoute("PortfoliosStudio", {}),
				},
			],
			target: [
				{
					children: "References", // TODO: translate
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
				},
			],
			benchmark: [
				{
					children: "References", // TODO: translate
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
				},
			],
			universe: [
				{
					children: "Universe", // TODO: translate
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
				},
			],
			portfolioDraft: [
				{
					children: "Portfolio draft",
					href: typedUrlForRoute("PortfoliosStudio", {}),
				},
			],
		}),
		[],
	);

	const uploadTitle: Record<useParmsProps["uploadType"], string> = {
		benchmark: "New benchmark",
		portfolio: "New portfolio",
		target: "New target portfolio",
		universe: "New universe",
		portfolioDraft: "New portfolio draft",
	};

	const endSubmitUpload = useCallback(
		(path: string) => {
			history.push(path);
		},
		[history],
	);

	const redirectMap = useCallback((uploadType: useParmsProps["uploadType"], uuid: string) => {
		switch (uploadType) {
			case "benchmark":
				return typedUrlForRoute("CustomBenchmark", { benchmarkId: uuid });
			case "portfolio":
				return typedUrlForRoute("PortfolioDetails", {
					portfolioUid: uuid,
				});
			case "target":
				return typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid });
			case "universe":
				return typedUrlForRoute("UniverseDetails", {
					universeUuid: uuid,
					mode: ModeVariants.viewer,
				});
			case "portfolioDraft":
				return typedUrlForRoute("PortfolioDetails", {
					portfolioUid: uuid,
				});
			default:
				return typedUrlForRoute("PortfoliosStudio", {});
		}
	}, []);

	const startWaitingModal = useCallback(
		({ identifier }: EditorSaveNewResponse) => {
			setIsFormModalOpen(false);
			setNewUploadUUID(identifier ?? "");
			setIsSubmitModalOpen(true);
			const path = redirectMap(uploadType, identifier ?? "");
			setPathToNotBlock(["/login", path]);
		},
		[redirectMap, uploadType],
	);

	const onSubmitFactory = useMemo(() => {
		type PayloadProps = {
			composition: ReviewTicker[];
			formData: {
				baseCurrency?: Currencies;
				name: string;
				primaryBenchmark?: InvestmentBenchmark;
				mandateType?: EditorSaveNewRequestMandateTypeEnum;
				investmentReference?: InvestmentReference;
			};
		};

		async function submitPortfolio(payload: PayloadProps, portfolioUid?: string) {
			try {
				let portfolioResponse;
				if (portfolioUid) {
					portfolioResponse = await axiosExtract(
						editorApi.saveEditorEditEntity("INVESTMENT_DRAFT", {
							name: payload.formData.name,
							benchmarkIdentifier: payload.formData.primaryBenchmark?.benchmarkIdentifier,
							currency: payload.formData.baseCurrency,
							tickerComposition: payload.composition,
							mandateType: payload.formData.mandateType,
							investmentReference: payload.formData.investmentReference,
							identifier: portfolioUid,
						}),
					);
				} else {
					portfolioResponse = await axiosExtract(
						editorApi.saveEditorNewEntity("INVESTMENT", {
							name: payload.formData.name,
							benchmarkIdentifier: payload.formData.primaryBenchmark?.benchmarkIdentifier,
							currency: payload.formData.baseCurrency,
							tickerComposition: payload.composition,
							mandateType: payload.formData.mandateType,
							investmentReference: payload.formData.investmentReference,
						}),
					);
				}

				trackMixPanelEvent("Portfolio", {
					Type: "Upload",
					ID: portfolioResponse.identifier ?? "",
					Name: payload.formData.name,
					Currency: payload.formData.baseCurrency ?? "",
					Benchmark: payload.formData.primaryBenchmark?.benchmarkIdentifier ?? "",
				});
				startWaitingModal(portfolioResponse);
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				}

				if (message !== "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});

					if (env.appEnv === "development") {
						platformToast({
							children: message,
							severity: "error",
							icon: "Portfolio",
						});
					}
				}

				setIsSubmitModalOpen(false);
				reportPlatformError(error, "ERROR", "portfolio", "unable to create portfolio from upload editor");
			}
		}

		async function savePortfolioAsDrat(payload: PayloadProps, portfolioUid?: string) {
			try {
				if (portfolioUid) {
					await axiosExtract(
						editorApi.saveEditorEditEntityAsDraft("INVESTMENT_DRAFT", {
							identifier: portfolioUid,
							tickerComposition: payload.composition,
							name: payload.formData.name,
							benchmarkIdentifier: payload.formData.primaryBenchmark?.benchmarkIdentifier,
							currency: payload.formData.baseCurrency,
							mandateType: payload.formData.mandateType,
							investmentReference: payload.formData.investmentReference,
						}),
					);
				} else {
					const { identifier } = await axiosExtract(
						editorApi.saveEditorNewEntityAsDraft("INVESTMENT", {
							name: payload.formData.name,
							benchmarkIdentifier: payload.formData.primaryBenchmark?.benchmarkIdentifier,
							currency: payload.formData.baseCurrency,
							tickerComposition: payload.composition,
							mandateType: payload.formData.mandateType,
							investmentReference: payload.formData.investmentReference,
						}),
					);
					flushSync(() => setIsCompositionDirty({ isDirty: false }));
					push("Portfolios/DraftManualCreation", {
						portfolioUid: identifier ?? "",
						uploadType: "portfolioDraft",
					});
				}

				// trackMixPanelEvent("Portfolio", {
				// 	Type: "Upload",
				// 	ID: portfolioResponse.identifier ?? "",
				// 	Name: payload.formData.name,
				// 	Currency: payload.formData.baseCurrency ?? "",
				// 	Benchmark: payload.formData.primaryBenchmark?.benchmarkIdentifier ?? "",
				// });
				setIsFormModalOpen(false);
				platformToast({
					children: `Portfolio ${payload.formData.name} saved successfully`,
					severity: "success",
					icon: "Portfolio",
				});

				// history.push(redirectMap("portfolioDraft", ""));
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				}

				if (message !== "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});
				}

				setIsSubmitModalOpen(false);
				reportPlatformError(error, "ERROR", "portfolio", "unable to create portfolio from upload editor");
			} finally {
				setIsSubmitModalOpen(false);
			}
		}

		/** this can be unified ["TARGET_INVESTMENT", "BENCHMARK", "UNIVERSE"] behave in the same way, the only thing to map is the reportPlatformError */
		async function submitTargetPortfolio(payload: PayloadProps) {
			try {
				const targetPortfolioResponse = await axiosExtract(
					editorApi.saveEditorNewEntity("TARGET_INVESTMENT", {
						currency: payload.formData.baseCurrency,
						name: payload.formData.name,
						tickerComposition: payload.composition,
					}),
				);
				trackMixPanelEvent("Portfolio", {
					Type: "Upload",
					ID: targetPortfolioResponse.identifier ?? "",
					Name: payload.formData.name,
					Benchmark: "",
					Currency: "",
				});
				startWaitingModal(targetPortfolioResponse);
			} catch (error) {
				platformToast({
					children: "Something went wrong, please try later",
					severity: "error",
					icon: "Portfolio",
				});
				setIsSubmitModalOpen(false);
				reportPlatformError(error, "ERROR", "portfolio", "unable to create protfolio target from upload editor");
			}
		}

		/** this can be unified ["TARGET_INVESTMENT", "BENCHMARK", "UNIVERSE"] behave in the same way, the only thing to map is the reportPlatformError */
		async function submitBenchmark(payload: PayloadProps) {
			try {
				const benchmarkResponse = await axiosExtract(
					editorApi.saveEditorNewEntity("BENCHMARK", {
						name: payload.formData.name,
						tickerComposition: payload.composition,
					}),
				);

				trackMixPanelEvent("Benchmark", {
					Type: "Creation",
					Action: "save",
					ID: benchmarkResponse.identifier,
				});
				startWaitingModal(benchmarkResponse);
			} catch (error) {
				platformToast({
					children: "Something went wrong, please try later",
					severity: "error",
					icon: "Portfolio",
				});
				setIsSubmitModalOpen(false);
				reportPlatformError(error, "ERROR", "benchmark", "unable to create benchmark from upload editor");
			}
		}

		/** this can be unified ["TARGET_INVESTMENT", "BENCHMARK", "UNIVERSE"] behave in the same way, the only thing to map is the reportPlatformError */
		async function submitUniverse(payload: PayloadProps) {
			try {
				const universeResponse = await axiosExtract(
					editorApi.saveEditorNewEntity("UNIVERSE", {
						name: payload.formData.name,
						tickerComposition: payload.composition,
					}),
				);
				trackMixPanelEvent("Universe", {
					Type: "Async Upload",
					Action: "save",
					ID: universeResponse.identifier,
					Name: payload.formData.name,
					estimatedTime: universeResponse.estimatedRetrieveTimeInMs ?? 0,
				});
				startWaitingModal(universeResponse);
			} catch (error) {
				platformToast({
					children: "Something went wrong, please try later",
					severity: "error",
					icon: "Portfolio",
				});
				setIsSubmitModalOpen(false);
				reportPlatformError(error, "ERROR", "universe", "unable to create universe from upload editor");
			}
		}

		return { submitPortfolio, savePortfolioAsDrat, submitTargetPortfolio, submitUniverse, submitBenchmark };
	}, [editorApi, push, startWaitingModal]);

	const onSubmit = useCallback(
		(
			entity: UploadEntity,
			payload: {
				composition: ReviewTicker[];
				formData: { baseCurrency?: Currencies; name: string; primaryBenchmark?: InvestmentBenchmark };
			},
			canSubmit: boolean,
			uuid?: string,
		) => {
			if (!canSubmit) {
				return setIsFormModalOpen(false);
			}
			switch (entity) {
				case "INVESTMENT":
					return onSubmitFactory.submitPortfolio(payload, uuid);
				case "TARGET_INVESTMENT":
					return onSubmitFactory.submitTargetPortfolio(payload);
				case "BENCHMARK":
					return onSubmitFactory.submitBenchmark(payload);
				case "UNIVERSE":
					return onSubmitFactory.submitUniverse(payload);
				case "INVESTMENT_DRAFT":
					return onSubmitFactory.savePortfolioAsDrat(payload, uuid);
				default:
					throw Error("unable to find a valid upload definition");
			}
		},
		[onSubmitFactory],
	);

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: newUploadUUID }),
		listener: () => {
			const path = redirectMap(uploadType, newUploadUUID);
			endSubmitUpload(path);
		},
	});

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: newUploadUUID }),
		listener: () => {
			const path = redirectMap(uploadType, newUploadUUID);
			endSubmitUpload(path);
		},
	});

	const summary = useQueryNoRefetch(["queryInvestmentSummary"], {
		enabled: (uploadEntity === "INVESTMENT" || uploadEntity === "INVESTMENT_DRAFT") && portfolioUid !== undefined,
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(portfolioUid!)),
	});

	return (
		<>
			<PageHeader
				title={uploadTitle[uploadType]}
				crumbs={[
					{
						children: "Portfolios", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", {}),
					},
					...uploadPath[uploadType],
					...(uploadType === "portfolioDraft"
						? [
								{
									children: summary.data?.name ?? "...", // TODO: translate
								},
						  ]
						: [
								{
									children: uploadTitle[uploadType], // TODO: translate
								},
						  ]),
				]}
				subTitle={
					<div className="flex justify-between items-center py-2.5">
						<span className="text-[14px]">
							Select assets available in you account, add an empty row to specify the asset identifier, or upload a list
							of assets and weights by uploading an excel with your desired composition.
						</span>
						<div className="flex items-center space-x-2">
							<Button
								size="small"
								onClick={() =>
									portfolioUid && summary.data?.status !== "DRAFT"
										? push("PortfolioDetails", { portfolioUid })
										: push("PortfoliosStudio", {})
								}
								palette="tertiary"
								data-qualifier="CompositionEditor/Cancel"
							>
								Cancel
							</Button>

							<Button
								size="small"
								onClick={() => setIsFormModalOpen(true)}
								palette="primary"
								data-qualifier="CompositionEditor/Save"
							>
								Done
							</Button>
						</div>
					</div>
				}
			/>
			<UploadCompositionSection
				ref={setIsCompositionDirty}
				uploadEntity={uploadEntity}
				instrumentsLimit={env.appEnv === "development" ? undefined : uploadType === "universe" ? 1500 : 1000}
				submitForm={{
					isOpen: isForModalOpen,
					onCancel: () => setIsFormModalOpen(false),
					onSubmit,
				}}
				uuids={uuids}
				enitityUuid={portfolioUid}
				investmentSummary={summary.data}
			/>

			<Modal show={isSubmitModalOpen}>
				<div className="w-[560px] p-[21px] bg-white rounded shadow relative z-0">
					<div className="absolute top-0 right-0">
						<button
							type="button"
							aria-label="close"
							className="cursor-pointer p-5"
							disabled={!newUploadUUID}
							onClick={() => {
								const path = redirectMap(uploadType, newUploadUUID);
								endSubmitUpload(path);
							}}
						>
							<Icon icon="Close" classList="text-[16px]" />
						</button>
					</div>
					<div className="flex flex-col items-center text-center px-[43px] pb-[43px] pt-[22px] ">
						<div className="flex justify-center">
							<MonitorWithHourglass2 style={{ margin: "0 auto" }} />
						</div>
						<h2 className="font-bold text-[20px] leading-[24px] mb-[26px] whitespace-pre-line">
							{t("PORTFOLIO_UPLOAD_DIALOG.title")}
						</h2>
						<div className="flex justify-center mb-[26px]">
							<div className="w-[290px]">
								<ProgressBar value="indeterminate" classList="w-full" />
							</div>
						</div>
						<p className="text-[14px] leading-[20px] whitespace-pre-line">{t("PORTFOLIO_UPLOAD_DIALOG.description")}</p>
						<ActionText
							classList="block mt-1"
							disabled={!newUploadUUID}
							onClick={() => {
								const path = redirectMap(uploadType, newUploadUUID);
								endSubmitUpload(path);
							}}
							data-qualifier="CompositionEditor/WaitingDialog/SendToBackground"
						>
							{t("PORTFOLIO_UPLOAD_DIALOG.actionable")}
						</ActionText>
					</div>
				</div>
			</Modal>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={isCompositionDirty?.isDirty === true}
				pathToNotBlock={pathToNotBlock}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
};

export default UploadPortfolioPage;
