import { Tooltip, Icon, TooltipContent } from "@mdotm/mdotui/components";
import type { IconName } from "@mdotm/mdotui/components";
import { groupBy } from "$root/utils/collections";
import { objectMap } from "$root/utils/objects";
import type React from "react";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import type { WidgetAlert } from "./context";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";

export type AlertTooltipProps = {
	alerts: WidgetAlert[];
	summary?: boolean;
	hideAlertsNumber?: boolean;
	footnote?: ReactNode;
};

const iconColorByVariant: Record<WidgetAlert["variant"], string> = {
	info: themeCSSVars.palette_S500,
	warning: themeCSSVars.palette_W500,
	// error: "#ea401f",
	// success: "#4cb09c",
};

// const colorByVariant: Record<WidgetAlert["variant"], string> = {
// 	info: themeCSSVars.palette_S500,
// 	warning: themeCSSVars.palette_W500,
// 	// error: "#ea401f",
// 	// success: "#4cb09c",
// };

const backgroundByVariant: Record<WidgetAlert["variant"], string> = {
	info: themeCSSVars.palette_S100,
	warning: themeCSSVars.palette_W100,
	// error: "#ea401f",
	// success: "#4cb09c",
};

const iconByVariant: Record<WidgetAlert["variant"], IconName> = {
	// error: "Icon-full-error",
	info: "info",
	// success: "Icon-full-clock",
	warning: "Icon-full-alert",
	// default: null,
};

// const textByVariant: Record<WidgetAlert["variant"], (t: TFunction, count: number) => React.ReactNode> = {
// 	info: (t, count) => t("WIDGET_ALERT.INFO", { count }),
// 	warning: (t, count) => t("WIDGET_ALERT.WARNING", { count }),
// 	/* success: (t, count) => t("WIDGET_ALERT.SUCCESS", { count }), */
// };

const AlertTooltip: React.FC<AlertTooltipProps> = ({ alerts = [], summary = false, hideAlertsNumber, footnote }) => {
	const [open, setOpen] = useState(false);
	const numberOfAlertsByVariant = useMemo<Record<WidgetAlert["variant"], number>>(() => {
		const { warning = 0, info = 0 } = objectMap(
			groupBy(alerts, (alert) => alert.variant),
			(group) => group?.length,
		);
		return { warning, info };
	}, [alerts]);
	const mainVariant = useMemo<WidgetAlert["variant"] | null>(() => {
		if (numberOfAlertsByVariant.warning > 0) {
			return "warning";
		}
		if (numberOfAlertsByVariant.info > 0) {
			return "info";
		}
		/* if (numberOfAlertsByVariant.success > 0) {
			return "success";
		} */
		return null;
	}, [numberOfAlertsByVariant]);
	const { t } = useTranslation();

	// const [unboundedButtonWrapper, setUnboundedButtonWrapper] = useState<HTMLDivElement | null>(null);
	// const [boundedButtonWrapper, setBoundedButtonWrapper] = useState<HTMLDivElement | null>(null);
	// const unboundedButtonWrapperWidth = useSize(unboundedButtonWrapper)?.width;
	// const boundedButtonWrapperWidth = useSize(boundedButtonWrapper)?.width;

	// const compactMode = useMemo(
	// 	() => (unboundedButtonWrapperWidth ?? 0) >= (boundedButtonWrapperWidth ?? 0),
	// 	[boundedButtonWrapperWidth, unboundedButtonWrapperWidth],
	// );
	const compactMode = true;

	return (
		<>
			{mainVariant && (
				<div
					className="relative z-0"
					// ref={setBoundedButtonWrapper}
				>
					{!alerts.some((x) => x.title || x.content || x.shortTitle) ? (
						<div
							className="relative z-10 rounded-full px-2 py-0.5 inline-flex flex-row items-center max-w-full"
							style={{
								color: iconColorByVariant[mainVariant],
								backgroundColor: backgroundByVariant[mainVariant],
							}}
						>
							<Icon icon={iconByVariant[mainVariant]} color={iconColorByVariant[mainVariant]} size="1rem" />
							<span
								className="px-0.5 font-bold self-end"
								style={{
									display: compactMode && !hideAlertsNumber ? "" : "none",
								}}
							>
								{numberOfAlertsByVariant[mainVariant]}
							</span>
							{/* <div
						style={{
							display: compactMode ? "none" : "",
						}}
						className="font-bold whitespace-nowrap overflow-hidden text-ellipsis px-2 self-end"
					>
						{textByVariant[mainVariant](t, numberOfAlertsByVariant[mainVariant])}
					</div> */}
							{/* <div className="flex items-center justify-center mr-2">
						<Icon
							classList="duration-400 transition-transform"
							style={{
								transform: `rotateX(${open ? "180deg" : "0deg"})`,
							}}
							icon="Down"
							color="currentColor"
							size="1rem"
						/>
					</div> */}
						</div>
					) : (
						<Tooltip
							open={open}
							position="bottom"
							severity={mainVariant ?? "info"}
							onClose={() => setOpen(false)}
							trigger={({ innerRef }) => (
								<button
									ref={innerRef}
									data-widget-alert-tooltip={open ? "open" : "closed"}
									className="relative z-10 rounded-full px-2 py-0.5 inline-flex flex-row items-center max-w-full"
									style={{
										color: iconColorByVariant[mainVariant],
										backgroundColor: backgroundByVariant[mainVariant],
									}}
									type="button"
									onClick={() => setOpen((o) => !o)}
								>
									<Icon icon={iconByVariant[mainVariant]} color={iconColorByVariant[mainVariant]} size="1rem" />
									<span
										className="px-0.5 font-bold self-end"
										style={{
											display: compactMode && !hideAlertsNumber ? "" : "none",
										}}
									>
										{numberOfAlertsByVariant[mainVariant]}
									</span>
									{/* <div
									style={{
										display: compactMode ? "none" : "",
									}}
									className="font-bold whitespace-nowrap overflow-hidden text-ellipsis px-2 self-end"
								>
									{textByVariant[mainVariant](t, numberOfAlertsByVariant[mainVariant])}
								</div> */}
									{/* <div className="flex items-center justify-center mr-2">
									<Icon
										classList="duration-400 transition-transform"
										style={{
											transform: `rotateX(${open ? "180deg" : "0deg"})`,
										}}
										icon="Down"
										color="currentColor"
										size="1rem"
									/>
								</div> */}
								</button>
							)}
						>
							<TooltipContent>
								<div
									style={{
										color: themeCSSVars.global_palette_text_icon_primary,
									}}
									className="bg-white relative min-w-[300px] max-w-[600px] font-normal"
								>
									<div className="relative z-0">
										{summary
											? alerts.map((alert, i, { length }) => (
													<div key={i}>
														{alert.shortTitle ?? alert.title}
														{i !== length - 1 ? ", " : ""}
													</div>
											  ))
											: alerts.map((alert, i) => (
													<div key={i} className={i === alerts.length - 1 ? "" : "mb-4"}>
														<div className="flex flex-row items-center mb-1">
															<Icon
																icon={iconByVariant[alert.variant]}
																color={iconColorByVariant[alert.variant]}
																size="1.2rem"
															/>
															<div className="font-bold ml-2">{alert.title}</div>
														</div>
														<div className="whitespace-pre-line">{alert.content}</div>
													</div>
											  ))}
										<div className="border-t my-4 border-[#D1D2DC] -mx-[1rem]" />
										{footnote || (
											<div>
												<Trans
													t={t}
													i18nKey="WIDGET_ALERT.CUSTOMIZE_YOUR_SETTINGS"
													components={{
														l: <Link className="underline" to={typedUrlForRoute("Notification", {})} />,
													}}
												/>
											</div>
										)}
									</div>
								</div>
							</TooltipContent>
						</Tooltip>
					)}
					{/* <div className="absolute pointer-events-none z-0 left-0 opacity-0 right-0 top-0 overflow-hidden">
						<div className="w-min" ref={setUnboundedButtonWrapper}>
							<button
								data-comment="used as target portfolio to determine whether to collapse the visible button"
								className="rounded-full pl-2 py-0.5 flex flex-row items-center"
								type="button"
							>
								<Icon icon={iconByVariant[mainVariant]} color={iconColorByVariant[mainVariant]} size="1rem" />
								<div className="font-bold whitespace-nowrap px-2">
									{textByVariant[mainVariant](t, numberOfAlertsByVariant[mainVariant])}
								</div>
								<div className="flex items-center justify-center mr-2">
									<Icon classList="duration-400 transition-transform" icon="Down" color="currentColor" size="1rem" />
								</div>
							</button>
						</div>
					</div> */}
				</div>
			)}
		</>
	);
};

export default AlertTooltip;
