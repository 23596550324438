export type HorizonType = "ONE_MONTH" | "THREE_MONTHS" | "SIX_MONTHS" | "ONE_YEAR" | "YEAR_TO_DATE" | "FROM_INCEPTION";
export const horizonOptions = [
	{
		label: "1m",
		value: "ONE_MONTH",
	},
	{
		label: "3m",
		value: "THREE_MONTHS",
	},
	{
		label: "6m",
		value: "SIX_MONTHS",
	},
	{
		label: "YTD",
		value: "YEAR_TO_DATE",
	},
	{
		label: "1y",
		value: "ONE_YEAR",
	},
	{
		label: "All",
		value: "FROM_INCEPTION",
	},
] satisfies Array<{ label: string; value: HorizonType }>;
