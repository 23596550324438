import type { QueryKey, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type React from "react";
import { useCallback } from "react";
import { noRefetchDefaults } from "$root/utils/react-query";
import { useTranslation } from "react-i18next";
import { AsyncButton, ProgressBar } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";

export type NonUndefined<T> = T extends undefined ? never : T;

export type ReactQueryWrapperProps<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = {
	/** @default 'noRefetch' */
	mode?: "noRefetch" | "auto";
	children(queryResult: NonUndefined<TData>, query: UseQueryResult<TData, TError>): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ error: TError; retry: () => Promise<void> }>;
	/**
	 * @default false
	 */
	keepChildren?: boolean;
} & UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

export const DefaultErrorFallback: React.FC<{ retry: () => Promise<void>; text?: string }> = ({ retry, text }) => {
	const { t } = useTranslation();
	return (
		<div className="inline-flex items-center">
			<div className="mr-4">{text ?? t("SOMETHING_WENT_WRONG")}</div>
			<div>
				<AsyncButton
					onClickAsync={() => retry()}
					style={{ minWidth: 0, borderRadius: 9999 }}
					palette="secondary"
					size="small"
				>
					{t("RETRY")}
				</AsyncButton>
			</div>
		</div>
	);
};

export function customizeErrorFallback(text: string) {
	return function CustomizedErrorFallback({ retry }: { retry: () => Promise<void> }): JSX.Element {
		return <DefaultErrorFallback retry={retry} text={text} />;
	};
}

export default function ReactQueryWrapper<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>({
	children,
	mode,
	errorFallback,
	loadingFallback,
	keepChildren,
	...useQueryParams
}: ReactQueryWrapperProps<TQueryFnData, TError, TData, TQueryKey>): JSX.Element {
	const query = useQuery({
		...(mode === "auto"
			? {}
			: {
					...noRefetchDefaults,
			  }),
		...useQueryParams,
	});

	return (
		<ReactQueryWrapperBase
			query={query}
			errorFallback={errorFallback}
			loadingFallback={loadingFallback}
			keepChildren={keepChildren}
		>
			{children}
		</ReactQueryWrapperBase>
	);
}

export type ReactQueryWrapperBaseProps<TData = unknown, TError = unknown> = {
	query: UseQueryResult<TData, TError>;
	children(queryResult: NonUndefined<TData>, query: UseQueryResult<TData, TError>): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ error: TError; retry: () => Promise<void> }>;
	/**
	 * @default false
	 */
	keepChildren?: boolean; // TODO: may be redundant, we can simply base our decision on the presence of query.data
};

export function ReactQueryWrapperBase<TData = unknown, TError = unknown>(
	props: ReactQueryWrapperBaseProps<TData, TError>,
): JSX.Element {
	const retry = useCallback(async () => {
		await props.query.refetch();
	}, [props.query]);
	const errorFallback = props.errorFallback ?? <DefaultErrorFallback retry={retry} />;

	const keepChildren = props.keepChildren ?? false;

	return (
		<>
			{props.query.isError ? (
				renderNodeOrFn(errorFallback, {
					retry,
					error: props.query.error,
				})
			) : (props.query.isLoading || props.query.data === undefined) && !keepChildren ? (
				typeof props.loadingFallback === "undefined" ? (
					<ProgressBar value="indeterminate" />
				) : (
					props.loadingFallback
				)
			) : (
				props.children(props.query.data as NonUndefined<TData>, props.query)
			)}
		</>
	);
}
