import type { InvestmentListEntry } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useLocaleFormatters } from "$root/localization/hooks";
import { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { statusIconMap } from "$root/pages/PortfoliosStudio";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import {
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	DialogHeader,
	Icon,
	SubmitButton,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useDebouncedSearch, useSearchable } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { generateUniqueDOMId, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomizedInvestmentEntry = InvestmentListEntry & { domId: string };

type AddPortfolioButtonProps = {
	uploadEntity: UploadEntity;
	selectedPortfolios: string[];
	renderCustomButton?: NodeOrFn<{ setShowDialog(show: boolean): void }>;
	onConfirm?(portfolios: Array<CustomizedInvestmentEntry>): MaybePromise<void>;
	uuid?: string;
};

const AddPortfolioButton = (props: AddPortfolioButtonProps): JSX.Element => {
	const { selectedPortfolios, renderCustomButton, uploadEntity, uuid, onConfirm } = props;
	const [showDialog, setShowDialog] = useState(false);

	const formatters = useLocaleFormatters();
	const { formatDate, formatNumber } = formatters;
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const { t } = useTranslation();

	const { data, isLoading } = useQueryNoRefetch(["queryComposition", "investment"], {
		queryFn: async () => {
			let portfolioList: InvestmentListEntry[] | undefined = undefined;
			if (uuid) {
				portfolioList = (await axiosExtract(editorApi.getEditorEditSelectablePortfolios(uuid, uploadEntity)))
					.selectablePortfolios;
			} else {
				portfolioList = (await axiosExtract(editorApi.getEditorNewSelectablePortfolios(uploadEntity)))
					.selectablePortfolios;
			}

			return (
				portfolioList?.flatMap((x): CustomizedInvestmentEntry[] =>
					x.canUseAsMixedPortfolio ? [{ ...x, domId: generateUniqueDOMId() }] : [],
				) ?? []
			);
		},
	});

	const { debouncedNormalizedQuery, query, setQuery } = useDebouncedSearch("", 10);
	const { filtered } = useSearchable({
		collection: data ?? [],
		query: debouncedNormalizedQuery,
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row.name).toLowerCase();
			const words = q.toLowerCase().split(" ");
			return words.every((keys) => stringifiedRow.indexOf(keys) >= 0);
		},
	});

	const selectableRowIds = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.flatMap(({ uuid }) => (selectedPortfolios.some((x) => x === uuid) === false && uuid ? [uuid] : []));
	}, [data, selectedPortfolios]);

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		filteredRows: filtered,
		rows: data ?? [],
		selectBy: ({ uuid }) => uuid ?? "",
		selectableRowIds,
		preSelectedRowIds: selectedPortfolios,
	});

	return (
		<>
			<Dialog
				size="xxlarge"
				noValidate
				show={showDialog}
				onClose={() => setShowDialog(false)}
				header="Select"
				footer={
					<DialogFooter
						neutralAction={
							<Button palette="tertiary" onClick={() => setShowDialog(false)}>
								Cancel
							</Button>
						}
						primaryAction={<SubmitButton>Confirm</SubmitButton>}
					/>
				}
				onSubmitAsync={async () => {
					const filteredComposition =
						data?.filter(
							(x) => x.uuid && !selectedPortfolios.includes(x.uuid ?? "") && multiSelectCtx.data.selection.has(x.uuid),
						) ?? [];

					await onConfirm?.(filteredComposition);
					setShowDialog(false);
				}}
			>
				<div className="mb-4">
					<TextInput placeholder="Search a portfolio" classList="max-w-[280px]" value={query} onChangeText={setQuery} />
				</div>
				<Table
					rows={filtered}
					rowClassList={rowClassList}
					columns={[
						checkBoxColumn,
						{
							header: t("TABLE.HEADERS.NAME"),
							content: (row) => row.name,
							sortFn: builtInSortFnFor("name"),
							name: "name",
						},
						{
							header: t("TABLE.HEADERS.STATUS"),
							content: ({ status }) => {
								const sanitizedStatus = status ? statusIconMap[status] : "-";
								if (sanitizedStatus === "-") {
									return <div>{sanitizedStatus}</div>;
								}
								return (
									<div title={sanitizedStatus.title} className="flex items-center">
										<Icon
											classList="mr-1"
											icon={sanitizedStatus.icon}
											color={themeCSSVars.palette_N800} // changed
											size={sanitizedStatus.size}
										/>
										<div style={{ fontSize: 12, fontFamily: "Gotham Book", fontWeight: "300" }}>
											{sanitizedStatus.title}
										</div>
									</div>
								);
							},
							sortFn: builtInSortFnFor("status"),
							name: "status",
						},
						{
							header: t("TABLE.HEADERS.LAST_STATUS_UPDATE"),
							content: ({ modificationTime }) => {
								const sanitizedModificationTime = modificationTime ? formatDate(new Date(modificationTime)) : "-";
								return sanitizedModificationTime;
							},
							sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
								const rowA = new Date(a ?? "").getTime();
								const rowB = new Date(b ?? "").getTime();
								return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
							},
							name: "lastReportsUpdate",
						},
						{
							header: t("TABLE.HEADERS.UNIVERSE_NAME"),
							content: (row) => row.universeName,
							name: "universeName",
							sortFn: builtInSortFnFor("universeName"),
						},
					]}
					classList="min-h-[410px]"
					visibleRows={Math.min(filtered.length, 9)}
					onRowClick={({ uuid }) => toggle(uuid ?? "")}
					enableVirtualScroll
					noDataText={
						isLoading ? (
							<div className="h-80 w-full flex justify-center items-center relative">
								<CircularProgressBar value="indeterminate" />
							</div>
						) : (
							"no instruments available"
						)
					}
				/>
			</Dialog>
			{renderCustomButton ? (
				renderNodeOrFn(renderCustomButton, {
					setShowDialog,
				})
			) : (
				<Button palette="secondary" size="small" onClick={() => setShowDialog(true)} classList="flex gap-2">
					<Icon icon="add-ptf" size={18} />
					add portfolios
				</Button>
			)}
		</>
	);
};

export default AddPortfolioButton;
