import type { ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { useUserValue } from "$root/functional-areas/user";
import { Card } from "../Card";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { useLocaleFormatters } from "$root/localization/hooks";

const rowHeight = 41;

type CompositionProps =
	| CommonSetUpReportProps<UnionMapData["portfolio-reference"]>
	| CommonSetUpReportProps<UnionMapData["portfolio-details"]>
	| CommonSetUpReportProps<UnionMapData["portfolio-enhanced"]>;

const Composition = ({ data, list }: PrintableProps<{ data: CompositionProps }, ReviewTicker>): JSX.Element => {
	const { responseType, portfolio } = data;
	const user = useUserValue();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = useMemo<Array<TableColumn<ReviewTicker>>>(
		() =>
			responseType === "enhancedDetails"
				? [
						{
							relativeWidth: hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) ? 2 : 2.5,
							header: t("COMPOSITION.INSTRUMENT"),
							content: ({ instrument }) => instrument ?? "",
						},
						{
							relativeWidth: hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) ? 2 : 1.5,
							header: t("COMPOSITION.IDENTFIER"),
							content: ({ identifier }) => identifier ?? "",
						},
						{
							relativeWidth: 2,
							header: t("COMPOSITION.ASSET_CLASS"),
							content: ({ assetClass }) => assetClass ?? "",
						},
						{
							relativeWidth: 1.0,
							header: t("COMPOSITION.CURRENT_WEIGHT"),
							cellClassList: "tabular-nums",
							content: ({ previousWeight }) => `${formatNumber(previousWeight ?? 0)}%`,
							align: "end",
						},
						{
							relativeWidth: 1.0,
							header: t("COMPOSITION.ENHANCED_WEIGHT"),
							cellClassList: "tabular-nums",
							content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
							align: "end",
						},
						{
							relativeWidth: 1.5,
							header: t("COMPOSITION.DIFFERENCE"),
							align: "end",
							cellClassName: "pr-1",
							content: (instrument) => {
								const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
								return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
							},
						},
						{
							header: portfolio?.scoreIdentifier ? (
								<CustomLabels labelKey={portfolio?.scoreIdentifier} fallback={t("SCORE")} />
							) : (
								t("SCORE")
							),
							content: ({ score }) => (score ? formatNumber(score) : ""),
							relativeWidth: 1,
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
						},
				  ]
				: [
						{
							relativeWidth: hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) ? 2 : 3,
							header: t("COMPOSITION.INSTRUMENT"),
							content: ({ instrument }) => instrument ?? "",
						},
						{
							relativeWidth: 1.9,
							header: t("COMPOSITION.IDENTFIER"),
							content: ({ identifier }) => identifier ?? "",
						},
						{
							relativeWidth: 1.5,
							header: t("COMPOSITION.ASSET_CLASS"),
							content: ({ assetClass }) => assetClass ?? "",
						},
						{
							relativeWidth: 2.6,
							header: t("COMPOSITION.MICRO_ASSET_CLASS"),
							content: ({ microAssetClass }) => microAssetClass ?? "",
						},
						{
							relativeWidth: 1,
							header: t("COMPOSITION.WEIGHT"),
							cellClassList: "tabular-nums",
							content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
							align: "end",
						},
						{
							header: portfolio?.scoreIdentifier ? (
								<CustomLabels labelKey={portfolio?.scoreIdentifier} fallback={t("SCORE")} />
							) : (
								t("SCORE")
							),
							content: ({ score }) => (score ? formatNumber(score) : ""),
							relativeWidth: 1,
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
						},
				  ],
		[portfolio?.scoreIdentifier, responseType, t, user, formatNumber],
	);

	return (
		<Card title="Composition">
			<Table
				palette="uniform"
				columns={columns}
				rows={list}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
				rowHeight={rowHeight}
				headerRowStyle={{ height: "40px" }}
			/>
		</Card>
	);
};

export default Composition;
