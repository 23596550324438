export function StatusChangeIcon(): JSX.Element {
	return (
		<svg
			width="136"
			height="136"
			viewBox="0 0 136 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="136" height="136" fill="url(#pattern0)" />
			<rect x="8.5" y="8.5" width="119" height="119" fill="white" />
			<rect x="46" y="84" width="67" height="32" rx="16" fill="#DBEFEB" />
			<rect x="46" y="84" width="32" height="32" rx="16" fill="#DBEFEB" />
			<path
				d="M67.2793 95.3091L60.0584 102.53L56.7444 99.216C56.3472 98.8188 55.6951 98.8188 55.2979 99.216C54.9007 99.6132 54.9007 100.265 55.2979 100.662L59.3115 104.676C59.7087 105.073 60.3608 105.073 60.758 104.676L68.7021 96.7379C69.0993 96.3407 69.0993 95.6885 68.7021 95.2913C68.3049 94.8941 67.6824 94.906 67.2733 95.3091H67.2793Z"
				fill="#4CB09C"
			/>
			<path d="M78 100H102" stroke="#94D0C4" strokeWidth="9" strokeLinecap="round" />
			<rect x="23" y="20" width="67" height="32" rx="16" fill="#FFF2CC" />
			<g clipPath="url(#clip0_659_264981)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M37.4961 30.6665C37.4961 29.7446 38.2466 29 39.1685 29C40.0963 29 40.841 29.7446 40.841 30.6665C40.841 31.5884 40.0904 32.3331 39.1685 32.3331C38.2466 32.3331 37.4961 31.5884 37.4961 30.6665ZM35.0666 30.8556C34.2275 30.8556 33.5479 31.5352 33.5479 32.3744C33.5479 33.2136 34.2275 33.8932 35.0666 33.8932C35.9058 33.8932 36.5854 33.2136 36.5854 32.3744C36.5854 31.5352 35.9058 30.8556 35.0666 30.8556ZM33.3651 35.1047C34.1157 35.1047 34.7303 35.7193 34.7303 36.4698C34.7303 37.2262 34.1216 37.8349 33.3651 37.8349C32.6146 37.8349 32 37.2203 32 36.4698C32 35.7193 32.6146 35.1047 33.3651 35.1047ZM34.2042 41.4221C33.7314 40.9493 33.7314 40.1811 34.2042 39.7083C34.6829 39.2355 35.4511 39.2355 35.9239 39.7083C36.3967 40.1811 36.4026 40.9493 35.9239 41.4221C35.4452 41.8949 34.677 41.8949 34.2042 41.4221ZM39.1682 40.8784C38.5832 40.8784 38.1045 41.3512 38.1045 41.9422C38.1045 42.5331 38.5773 43.0059 39.1682 43.0059C39.7592 43.0059 40.232 42.5331 40.232 41.9422C40.232 41.3512 39.7533 40.8784 39.1682 40.8784ZM44.2148 36.4639C44.2148 36.0443 44.5517 35.7075 44.9713 35.7075C45.3909 35.7075 45.7336 36.0443 45.7336 36.4639C45.7336 36.8835 45.3909 37.2203 44.9713 37.2203C44.5517 37.2203 44.2148 36.8835 44.2148 36.4639ZM42.6253 39.9211C42.2707 40.2756 42.2707 40.8548 42.6253 41.2094C42.9799 41.5639 43.559 41.5639 43.9136 41.2094C44.2682 40.8548 44.2682 40.2756 43.9136 39.9211C43.559 39.5665 42.9799 39.5665 42.6253 39.9211ZM43.9371 32.3094C43.9371 32.6463 43.6653 32.9181 43.3284 32.9181C42.9916 32.9181 42.7197 32.6463 42.7197 32.3094C42.7197 31.9726 42.9916 31.7007 43.3284 31.7007C43.6653 31.7007 43.9371 31.9726 43.9371 32.3094Z"
					fill="#FFBE00"
				/>
			</g>
			<path d="M55 36H79" stroke="#FFD866" strokeWidth="9" strokeLinecap="round" />
			<path
				d="M61.7 67.3C61.8833 67.1167 62.1166 67.0207 62.4 67.012C62.6833 67.004 62.9166 67.0917 63.1 67.275L68 72.175L68 61C68 60.7167 68.096 60.479 68.288 60.287C68.4793 60.0957 68.7166 60 69 60C69.2833 60 69.521 60.0957 69.713 60.287C69.9043 60.479 70 60.7167 70 61L70 72.175L74.9 67.275C75.0833 67.0917 75.3166 67.004 75.6 67.012C75.8833 67.0207 76.1166 67.1167 76.3 67.3C76.4833 67.4833 76.575 67.7167 76.575 68C76.575 68.2833 76.4833 68.5167 76.3 68.7L69.7 75.3C69.6166 75.4 69.5126 75.471 69.388 75.513C69.2626 75.5543 69.1333 75.575 69 75.575C68.8666 75.575 68.7416 75.5543 68.625 75.513C68.5083 75.471 68.4 75.4 68.3 75.3L61.7 68.7C61.5166 68.5167 61.425 68.2833 61.425 68C61.425 67.7167 61.5166 67.4833 61.7 67.3Z"
				fill="#C3C9D5"
			/>
			<rect x="8.5" y="8.5" width="119" height="119" stroke="#EFF0F3" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0294118" height="0.0294118">
					<use xlinkHref="#image0_659_264981" transform="scale(0.00735294)" />
				</pattern>
				<clipPath id="clip0_659_264981">
					<rect width="13.7282" height="14" fill="white" transform="translate(32 29)" />
				</clipPath>
				<image
					id="image0_659_264981"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
