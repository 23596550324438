import type { StylableProps } from "@mdotm/mdotui/components";
import { Text } from "@mdotm/mdotui/components";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { type ReactNode } from "react";

export type QuotedQuestionBoxProps = StylableProps & {
	children: ReactNode;
};

export function QuotedQuestionBox({ children, classList, style }: QuotedQuestionBoxProps): JSX.Element {
	return (
		<Text
			type="Body/L/Book"
			as="div"
			classList={{
				[`relative z-0 px-2 mx-4 py-1.5 transition-colors bg-[${themeCSSVars.palette_N20}]`]: true,
				...toClassListRecord(classList),
			}}
			style={style}
		>
			{children}
		</Text>
	);
}
