import { useUserValue } from "$root/functional-areas/user";
import type { StylableProps } from "@mdotm/mdotui/components";
import { Text } from "@mdotm/mdotui/components";
import { toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";

export function UserBox({ children, classList, style }: { children: ReactNode } & StylableProps): JSX.Element {
	return (
		<div
			className={toClassName({
				[`bg-[${themeCSSVars.palette_N20}] px-8 flex items-start py-4 border-y border-y-[${themeCSSVars.palette_N100}]`]:
					true,
				...toClassListRecord(classList),
			})}
			style={style}
		>
			<div>
				<UserAvatar style={{ width: 24 }} />
			</div>
			<Text type="Body/L/Book" as="div" color={themeCSSVars.palette_N700} classList="ml-3 whitespace-pre-line">
				{children}
			</Text>
		</div>
	);
}

function UserAvatar({ style, classList }: StylableProps) {
	const user = useUserValue();
	const initials =
		user.name && user.surname
			? user.name[0] + user.surname[0]
			: user.name
			  ? user.name.slice(0, 2)
			  : user.surname
			    ? user.surname.slice(0, 2)
			    : user.email.slice(0, 2);
	return (
		<svg
			style={style}
			className={toClassName(classList)}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="24" height="24" rx="12" fill="#697796" />
			<text
				textAnchor="middle"
				x="12"
				y="16"
				style={{
					fontFamily: "Gotham",
					fontSize: "10px",
					fontStyle: "normal",
					fontWeight: "700",
					lineHeight: "14px",
				}}
				fill="#ffffff"
			>
				{initials}
			</text>
		</svg>
	);
}
