type BarGraphProps = {
	containerWidth: number;
	containerHeigth: number;
	firstBar: string;
	secondBar: string;
	scale: {
		min: number;
		max: number;
	};
};

const barPercent = function (value: number, min: number, max: number) {
	const computedValue = value > 0 ? (value * 100) / max : (value * 100) / min;
	return String(computedValue);
};

export const BarGraph: React.FC<BarGraphProps> = ({ containerHeigth, containerWidth, firstBar, secondBar, scale }) => {
	const composeBarWidth = (val: string) => val.replace(",", ".").replace("-", "").concat("%");

	const firstBarPercent = barPercent(Number(firstBar), scale.min, scale.max);
	const secondBarPercent = barPercent(Number(secondBar), scale.min, scale.max);

	return (
		<div className="py-2 flex items-center justify-center grow">
			<div
				className="flex flex-col grow relative"
				style={{ maxWidth: `${containerWidth}px`, height: `${containerHeigth}px` }}
			>
				<div
					data-position={firstBar.includes("-") ? "negative" : "positive"}
					className="h-2 absolute animate-progress bg-cyan-500 data-[position=negative]:right-[50.5%] data-[position=positive]:left-[50.5%] top-[15%] transition-all duration-300"
					style={{ maxWidth: composeBarWidth(firstBarPercent) }}
				/>
				<div className="w-px bg-zinc-200 h-full m-auto" />
				<div
					data-position={secondBar.includes("-") ? "negative" : "positive"}
					className="h-2 absolute animate-progress bg-slate-500 data-[position=negative]:right-[50.5%] data-[position=positive]:left-[50.5%] bottom-[15%] transition-all duration-300"
					style={{ maxWidth: composeBarWidth(secondBarPercent) }}
				/>
			</div>
		</div>
	);
};
