export function ConstraintBreachWarningIcon(): JSX.Element {
	return (
		<svg
			width="136"
			height="136"
			viewBox="0 0 136 136"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="136" height="136" fill="url(#pattern0)" />
			<rect x="8.5" y="8.5" width="119" height="119" fill="white" />
			<rect x="22" y="60" width="69" height="20" fill="#FCE2D5" />
			<path d="M22 60H73V80H22V60Z" fill="#F69B6F" />
			<path d="M91 60H113V80H91V60Z" fill="#E1E4EA" />
			<line x1="91.5" y1="48" x2="91.5" y2="80" stroke="#8792AB" />
			<path
				d="M91.51 45.24C89.49 45.24 88 43.65 88 41.64V41.62C88 39.64 89.48 38 91.54 38C92.81 38 93.57 38.45 94.27 39.11L93.73 39.69C93.14 39.13 92.48 38.73 91.53 38.73C89.98 38.73 88.82 39.99 88.82 41.6V41.62C88.82 43.24 89.99 44.51 91.53 44.51C92.49 44.51 93.12 44.14 93.79 43.5L94.31 44.01C93.58 44.75 92.78 45.24 91.51 45.24Z"
				fill="#8792AB"
			/>
			<path
				d="M101.7 84.7002C101.883 84.8835 101.979 85.1169 101.988 85.4002C101.996 85.6835 101.908 85.9169 101.725 86.1002L96.825 91.0002L108 91.0002C108.283 91.0002 108.521 91.0962 108.713 91.2882C108.904 91.4795 109 91.7169 109 92.0002C109 92.2835 108.904 92.5212 108.713 92.7132C108.521 92.9045 108.283 93.0002 108 93.0002L96.825 93.0002L101.725 97.9002C101.908 98.0835 101.996 98.3169 101.988 98.6002C101.979 98.8835 101.883 99.1169 101.7 99.3002C101.517 99.4835 101.283 99.5752 101 99.5752C100.717 99.5752 100.483 99.4835 100.3 99.3002L93.7 92.7002C93.6 92.6169 93.529 92.5129 93.487 92.3882C93.4457 92.2629 93.425 92.1335 93.425 92.0002C93.425 91.8669 93.4457 91.7419 93.487 91.6252C93.529 91.5085 93.6 91.4002 93.7 91.3002L100.3 84.7002C100.483 84.5169 100.717 84.4252 101 84.4252C101.283 84.4252 101.517 84.5169 101.7 84.7002Z"
				fill="#F69B6F"
			/>
			<path
				d="M63.3 99.2998C63.1167 99.1165 63.0207 98.8831 63.012 98.5998C63.004 98.3165 63.0917 98.0831 63.275 97.8998L68.175 92.9998L57 92.9998C56.7167 92.9998 56.479 92.9038 56.287 92.7118C56.0957 92.5205 56 92.2831 56 91.9998C56 91.7165 56.0957 91.4788 56.287 91.2868C56.479 91.0955 56.7167 90.9998 57 90.9998L68.175 90.9998L63.275 86.0998C63.0917 85.9165 63.004 85.6831 63.012 85.3998C63.0207 85.1165 63.1167 84.8831 63.3 84.6998C63.4833 84.5165 63.7167 84.4248 64 84.4248C64.2833 84.4248 64.5167 84.5165 64.7 84.6998L71.3 91.2998C71.4 91.3831 71.471 91.4871 71.513 91.6118C71.5543 91.7371 71.575 91.8665 71.575 91.9998C71.575 92.1331 71.5543 92.2581 71.513 92.3748C71.471 92.4915 71.4 92.5998 71.3 92.6998L64.7 99.2998C64.5167 99.4831 64.2833 99.5748 64 99.5748C63.7167 99.5748 63.4833 99.4831 63.3 99.2998Z"
				fill="#F69B6F"
			/>
			<rect x="8.5" y="8.5" width="119" height="119" stroke="#EFF0F3" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0294118" height="0.0294118">
					<use xlinkHref="#image0_659_265034" transform="scale(0.00735294)" />
				</pattern>
				<image
					id="image0_659_265034"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
