import type React from "react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import HighchartsReact from "highcharts-react-official";
import Color from "color";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import type { HMMRegion } from "$root/widgets-architecture/contexts/region";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useApiGen } from "$root/api/hooks";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import { IconWalls } from "$root/components/IconWall";

type PercentageChartProps = {
	series: Array<{
		name: string;
		legendName?: string;
		color: string;
		yValues: number[];
	}>;
	xTimestamps: number[];
};

const PercentageChart: React.FC<PercentageChartProps> = ({ series, xTimestamps }) => {
	const { t } = useTranslation();
	const chartRef = useRef<HighchartsReact.RefObject>(null);
	const { formatNumber, formatDate } = useLocaleFormatters();

	const options = useMemo(() => {
		// const pointsPerSeries = series[0].yValues.length;
		// const allXValues = range(0, pointsPerSeries).map(
		// 	(_, i) => xTimestamps[0] + (i * (xTimestamps[xTimestamps.length - 1] - xTimestamps[0])) / pointsPerSeries,
		// );

		return {
			chart: {
				type: "area",
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
			legend: { enabled: false },
			title: { text: undefined },
			subtitle: { text: undefined },
			navigator: {
				series: {
					lineColor: "transparent",
					color: "transparent",
				},
			},
			xAxis: [
				{
					type: "datetime",
					tickWidth: 0,
					minRange: 1000 * 60 * 60 * 24,
					endOnTick: false,
					startOnTick: false,
					min: xTimestamps[0],
					max: xTimestamps[xTimestamps.length - 1],
					crosshair: {
						width: 1,
						color: "black",
						dashStyle: "shortdash",
						zIndex: 2,
					},
					gridLineWidth: 0,
				},
			],
			yAxis: {
				gridLineWidth: 0,
				min: 0,
				max: 1,
				labels: {
					align: "center",
					formatter: ({ value }: { value: number }) => formatNumber(Math.floor(value * 100), 0) + "%",
				},
				title: {
					enabled: false,
				},
				crosshair: {
					width: 1,
					color: "black",
					dashStyle: "shortdash",
					zIndex: 2,
				},
			},
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				shared: true,
				useHTML: true,
				distance: 72,
				backgroundColor: "#FFFFFF",
				borderColor: "#FFFFFF",
				borderRadius: 8,
				shadow: {
					color: "#4a4a4a",
				},
				style: {
					padding: "0",
					color: "#656d78",
					fontSize: "12px",
				},
				formatter(this: {
					points: {
						x: number;
						y: number;
						series: Highcharts.Series & { options: { marker: { fillColor: string } } };
					}[];
					x: number;
					y: number;
				}) {
					const content = `<div style="min-width: 12.5rem">
							<div style="text-align: center;
								border-radius: 0.25rem;
								background-color: #eeeef1;
								color: #9699b3;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								line-height: 1.25rem;
								padding: 0.2rem 0;">
								<div>${t("EVOLUTION_OF_REGIME_PROBABILITIES.tooltip")}</div>
							</div>
							<div
								style="color: #9699b3;
								text-align: center;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								padding: 0.4rem 0"
							>${formatDate(this.x)} - ${formatDate(this.x + 7 * 24 * 60 * 60 * 1000)}</div>
							<table style="width: 100%">
								<tbody>${(this.points || [])
									.map(
										(point) => `
										<tr>
											<td style="border-bottom: solid 1px transparent; padding: 5px 8px 0 0">
											<span style="display: flex; flex-direction: row; align-items: center">
												<span style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 100px; background-color: ${
													point.series.options.marker.fillColor
												}">
											</span>
											${point.series.name}</span>
											</td>
											<td
												style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
											>
												${formatNumber(point.y * 100, 0)}%
											</td>
										</tr>
									`,
									)
									.reverse()
									.join("")}
								</tbody>
							</table>
						</div>`;
					return content;
				},
			},
			plotOptions: {
				area: {
					stacking: "normal",
					lineColor: "#ffffff",
					lineWidth: 2,
					marker: {
						lineWidth: 1,
						symbol: "dot",
					},
				},
			},
			series: [...series].reverse().map((singleSeries) => {
				const baseColor = singleSeries.color;
				const transparentColor = Color(singleSeries.color).alpha(0.35).toString();
				return {
					lineColor: baseColor,
					color: transparentColor,
					marker: {
						color: baseColor,
						fillColor: baseColor,
					},
					name: singleSeries.name,
					data: singleSeries.yValues,
					pointStart: xTimestamps[0],
					pointInterval: 1000 * 60 * 60 * 24 * 7, // 1 week
				};
			}),
			exporting: {
				enabled: true,
				chartOptions: {
					title: {
						text: t("EVOLUTION_OF_REGIME_PROBABILITIES.title"),
					},
					subtitle: {
						text: `Data Exported in ${new Date().getUTCFullYear()}-${
							new Date().getUTCMonth() + 1
						}-${new Date().getUTCDate()}`,
					},
					chart: {
						margin: 40,
						marginTop: 80,
						marginLeft: 80,
						marginBottom: 80,
					},
					scrollbar: {
						enabled: false,
					},
				},
				buttons: {
					contextButton: {
						menuItems: ["downloadJPEG", "downloadPDF"],
					},
				},
				allowHTML: true,
				printMaxWidth: 1080,
				filename: t("EVOLUTION_OF_REGIME_PROBABILITIES.title"),
				sourceHeight: 400,
				sourceWidth: 1440,
			},
		};
	}, [xTimestamps, series, t, formatNumber, formatDate]);

	// useEffect(() => {
	// 	chartRef.current?.chart.xAxis[0].update({ tickInterval: 1000 * 60 * 60 * 24 * 365 * 2 });
	// }, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<div style={{ borderRadius: 4, borderColor: "#eeeef1", borderWidth: 2, borderStyle: "solid", flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="stockChart"
					options={options}
					ref={chartRef}
				/>
			</div>
			<div>
				<div style={{ flex: 0 }}>
					<GraphLegend style={{ position: "static", padding: "1rem 0", margin: 0 }}>
						{series.map((singleSeries) => (
							<div key={singleSeries.color} className="legend-container return-analysis light more-space book">
								<ColoredRectangle variant="vertical" color={singleSeries.color} />
								{singleSeries.legendName ?? singleSeries.name}
							</div>
						))}
					</GraphLegend>
				</div>
			</div>
		</div>
	);
};

const EvolutionOfRegimeProbabilitiesBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const region: HMMRegion = props.region;

	function fromObjToArray<T>(obj: { [x: string]: T }) {
		const dataArray = Object.keys(obj).map((key) => {
			return obj[key];
		});
		return dataArray;
	}

	const hmmApi = useApiGen(HmmControllerApiFactory);

	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmEvolutionOfRegime", region], {
		queryFn: async () => {
			const { data: EvolutionOfRegime } = await hmmApi.retrieveEvolutionOfRegime(region);

			const DateSeries = Object.keys(EvolutionOfRegime.growth ?? {}).map((yyyymmdd) => {
				return new Date(`${yyyymmdd.slice(0, 4)}-${yyyymmdd.slice(4, 6)}-${yyyymmdd.slice(-2)}T12:00:00Z`).getTime();
			}); // No control if dates are not equal for all series
			const GrowSerie = fromObjToArray(EvolutionOfRegime.growth ?? {});
			const LateralSerie = fromObjToArray(EvolutionOfRegime.lateralPhase ?? {});
			const HIghSerie = fromObjToArray(EvolutionOfRegime.highStress ?? {});

			const EvolutionOfRegimeData = {
				DateSeries,
				GrowSerie,
				LateralSerie,
				HIghSerie,
			};

			return EvolutionOfRegimeData;
		},
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("EVOLUTION_OF_REGIME_PROBABILITIES.title"),
		}),
		[t],
	);

	return (
		<>
			<div style={{ paddingBottom: "1rem" }}>{t("EVOLUTION_OF_REGIME_PROBABILITIES.description")}</div>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<PercentageChart
					xTimestamps={data.DateSeries}
					series={[
						{
							color: "#4cb09c",
							name: t("EVOLUTION_OF_REGIME_PROBABILITIES.growth_tooltip"),
							legendName: t("EVOLUTION_OF_REGIME_PROBABILITIES.growth_legend"),
							yValues: data.GrowSerie,
						},
						{
							color: "#ffa800",
							name: t("EVOLUTION_OF_REGIME_PROBABILITIES.lateral_phase_tooltip"),
							legendName: t("EVOLUTION_OF_REGIME_PROBABILITIES.lateral_phase_legend"),
							yValues: data.LateralSerie,
						},
						{
							color: "#e94020",
							name: t("EVOLUTION_OF_REGIME_PROBABILITIES.high_stress_tooltip"),
							legendName: t("EVOLUTION_OF_REGIME_PROBABILITIES.high_stress_legend"),
							yValues: data.HIghSerie,
						},
					]}
				/>
			)}
		</>
	);
};

export default withContext(RegionContext)(EvolutionOfRegimeProbabilitiesBlock);
