import type { MarketViewSettings, ReferenceUniverseListEntry } from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	MarketViewSettingsActiveMarketViewTypesEnum,
	type InvestmentListEntry,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import SPHERELogo from "$root/assets/images/SPHERE_logo.svg";
import CloudClaculatingDrawIcon from "$root/components/glyphs/CloudClaculatingDrawIcon";
import EmptyBox from "$root/components/glyphs/EmptyBox";
import InvalidBox from "$root/components/glyphs/InvalidBox";
import MonitorWithHourglass from "$root/components/glyphs/MonitorWithHourglass";
import SadMachineDrawIcon from "$root/components/glyphs/SadMachineDrawIcon";
import { spawnInvestmentImportDialog } from "$root/functional-areas/portfolio/Import";
import { spawnUniverseImportDialog } from "$root/functional-areas/universe/Import";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { parallelize } from "$root/utils/promise";
import type { StylableProps } from "@mdotm/mdotui/components";
import { AsyncButton, Button, CircularProgressBar, Dialog, Icon, ProgressBar } from "@mdotm/mdotui/components";
import { MaybePromise } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverResult } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthorizationGuard from "../AuthorizationGuard";
import { typedUrlForRoute } from "../PlatformRouter/RoutesDef";
import ReactQueryWrapper from "../ReactQueryWrapper";
import CalendarDrawIcon from "../glyphs/CalendarDrawIcon";
import CreateMarketViewCustomIcon from "../glyphs/CreateMarketViewCustomIcon";
import CreateMarketViewPositioningIconV2 from "../glyphs/CreateMarketViewPositioningIconV2";
import CreateMarketViewRegimeIconV2 from "../glyphs/CreateMarketViewRegimeIconV2";
import CreatePortfolioIcon from "../glyphs/CreatePortfolioIcon";
import MonitorCalculatingDrawIcon from "../glyphs/MonitorCalculatingDrawIcon";
import MonitorErrorDrawIcon from "../glyphs/MonitorErrorDrawIcon";
import MonitoringMetricsIcon from "../glyphs/MonitoringMetricsIcon";
import TextDescriptionDrawIcon from "../glyphs/TextDescriptionDrawIcon";
import UploadPortfolioIcon from "../glyphs/UploadPortfolioIcon";
import "./style.scss";

export type WallOverlayProps = {
	children: NodeOrFn;
	overlay: NodeOrFn;
	showOverlay: boolean;
	walledAppearance?: StylableProps;
} & StylableProps;

export function WallOverlay({
	children,
	overlay,
	classList,
	style,
	showOverlay,
	walledAppearance,
}: WallOverlayProps): JSX.Element {
	return (
		<div className={toClassName({ "relative z-0": true, ...toClassListRecord(classList) })} style={style}>
			{showOverlay && (
				<div
					className="absolute inset-0 z-10"
					style={{
						backgroundColor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					{renderNodeOrFn(overlay)}
				</div>
			)}
			<div
				className={toClassName({ "relative z-0": true, ...toClassListRecord(walledAppearance?.classList) })}
				style={walledAppearance?.style}
			>
				{renderNodeOrFn(children)}
			</div>
		</div>
	);
}

export type IconWallBaseProps = { defaultBackground?: boolean; opaque?: boolean; children: NodeOrFn };

export function IconWallBase(props: IconWallBaseProps): JSX.Element {
	return (
		<div
			className={toClassName({
				"h-full w-full flex items-center justify-center py-4 relative relative": true,
				DefaultIconWallBackground: (props.defaultBackground || props.opaque) ?? true,
				opaque: props.opaque ?? true,
			})}
		>
			{renderNodeOrFn(props.children)}
		</div>
	);
}

function decorate<TProps extends Record<string, any>>(
	Fn: (props: TProps) => JSX.Element,
): (props: TProps & { defaultBackground?: boolean; opaque?: boolean }) => JSX.Element {
	return function WallDecoratorImpl({ opaque, defaultBackground, ...innerProps }): JSX.Element {
		return (
			<IconWallBase opaque={opaque} defaultBackground={defaultBackground}>
				<Fn {...(innerProps as TProps)} />
			</IconWallBase>
		);
	};
}

export const IconWalls = {
	LoadingData: decorate(function WallImpl(props: { hideDrawIcon?: boolean }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<CircularProgressBar
					value="indeterminate"
					classList={{ hidden: props.hideDrawIcon, "mx-auto mt-0 mb-2": true }}
				/>
				<h4 className="text-center">{t("WALL_STATES.LOADING_DATA.TITLE")}</h4>
			</div>
		);
	}),
	InfiniteProgress: decorate(function WallImpl(): JSX.Element {
		const { t } = useTranslation();
		return (
			<>
				<ProgressBar value="indeterminate" classList="absolute top-0 inset-x-0" />
				<h4 className="text-center">{t("WALL_STATES.LOADING_DATA.TITLE")}</h4>
			</>
		);
	}),
	CalculatingData: decorate(function WallImpl(props: { hideDrawIcon?: boolean }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<CloudClaculatingDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center">{t("WALL_STATES.CALCULATING_DATA.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.CALCULATING_DATA.DESCRIPTION")}</h4>
			</div>
		);
	}),
	DataNotAvailable: decorate(function WallImpl(props: { hideDrawIcon?: boolean }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<MonitorWithHourglass classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center">{t("WALL_STATES.DATA_NOT_YET_AVAILABLE.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.DATA_NOT_YET_AVAILABLE.DESCRIPTION")}</h4>
			</div>
		);
	}),
	HistoricalDataNotAvailable: decorate(function WallImpl(props: { hideDrawIcon?: boolean }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<MonitorWithHourglass classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center">{t("WALL_STATES.HISTORICAL_DATA_NOT_YET_AVAILABLE.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.HISTORICAL_DATA_NOT_YET_AVAILABLE.DESCRIPTION")}</h4>
			</div>
		);
	}),
	ConstraintNotSet: decorate(function WallImpl(props: { constraintName: string }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col items-center">
				<EmptyBox />
				<p className="whitespace-pre-line font-semibold">
					<Trans i18nKey="WALL_STATES.CONSTRAINT_NOT_SET.TITLE" values={{ step: props.constraintName }} />
				</p>
				<p>{t("WALL_STATES.CONSTRAINT_NOT_SET.DESCRIPTION")}</p>
			</div>
		);
	}),
	ErrorData: decorate(function WallImpl(props: {
		redirect?: {
			path: string;
			title: string;
		};
		hideDrawIcon?: boolean;
	}): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<SadMachineDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center">{t("WALL_STATES.NO_DATA.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.NO_DATA.DESCRIPTION")}</h4>
				{props.redirect && (
					<Link
						to={props.redirect.path}
						className={`mt-4 text-center underline text-[color:${themeCSSVars.global_palette_primary_600}]`}
					>
						<strong>Return to {props.redirect.title}</strong>
					</Link>
				)}
			</div>
		);
	}),
	GenericError: decorate(function WallImpl(): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col space-y-2 items-center h-screen pt-40 bg-gray-100">
				<div>
					<img src={SPHERELogo} alt="Sphere logo" />
				</div>
				<h1 className="font-bold text-4xl">{t("SOMETHING_WENT_WRONG")}</h1>
				<div>
					<Link to="/">
						<h2 className="font-bold underline text-2xl">{t("RETURN_TO_HOME")}</h2>
					</Link>
				</div>
			</div>
		);
	}),
	ReviewErrorData: decorate(function WallImpl(props: {
		section: "portfolio" | "universe";
		onDelete: () => void;
		hideDrawIcon?: boolean;
	}): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex justify-center items-center grow px-3">
				<article className="flex flex-col justify-center items-center gap-4">
					<InvalidBox classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
					<div className="text-center">
						<strong className="text-sm">{t("WALL_STATES.EMPTY_SS_PORTFOLIO.TITLE", { type: props.section })}</strong>
						<p className="whitespace-pre-line">
							{t("WALL_STATES.EMPTY_SS_PORTFOLIO.DESCRIPTION", { type: props.section })}
						</p>
					</div>
					<Button
						onClick={(e) => {
							e.currentTarget.setAttribute("disabled", "true");
							props.onDelete?.();
						}}
						size="small"
						palette="secondary"
					>
						Delete {props.section}
					</Button>
				</article>
			</div>
		);
	}),
	PortfolioListEmptyData: decorate(function WallImpl(props: {
		// user?: IUser;
		// onCreate?: () => void;
		// onUpload?: () => void;
		refetch?: () => Promise<QueryObserverResult<InvestmentListEntry[], unknown>>;
	}): JSX.Element {
		const user = useUserValue();
		const [showNewPortfolio, setShowNewPortfolio] = useState(false);
		const integrationsApiV2 = useApiGen(IntegrationsControllerApiFactory);

		return (
			<div className="flex justify-between items-center grow px-3">
				<div className="flex items-center">
					<EmptyBox />
					<p className="whitespace-pre-line">
						<Trans
							i18nKey="PORTFOLIO_STUDIO.empty"
							values={{ ptfType: "portfolio" }}
							components={{
								strong: <strong />,
							}}
						/>
					</p>
				</div>
				<div className="flex gap-2.5">
					<AuthorizationGuard requiredServices={["IMPORT"]}>
						{() => (
							<ReactQueryWrapper
								queryFn={async () => {
									const importConverters = await axiosExtract(
										integrationsApiV2.retrieveInvestmentImportConverterType(),
									);
									return importConverters.filter((converter) => user.importFormats?.includes(converter));
								}}
								queryKey={["importFormat", "portfolio"]}
							>
								{(converters) => (
									<Button
										size="small"
										palette="secondary"
										data-qualifier="IconWall/NewPortfolio/Import"
										onClick={() =>
											spawnInvestmentImportDialog({
												converters,
												onSubmit: async (investments) => {
													try {
														const failed: unknown[] = [];
														const callStack = investments.map(
															(investment) => () =>
																integrationsApiV2.importInvestment(investment).catch((e) => failed.push(e)),
														);
														await parallelize(callStack);

														if (callStack.length > 0 && failed.length === callStack.length) {
															throw new Error("No portfolio imported", { cause: failed });
														}

														platformToast({
															children:
																callStack.length === 0
																	? "No portfolios were imported"
																	: "Sphere has taken over your request",
															severity: callStack.length === 0 ? "warning" : "info",
															icon: "Dowload",
														});
													} catch (error) {
														platformToast({
															children: "No portfolio imported",
															severity: "error",
															icon: "Dowload",
														});
														throw error;
													} finally {
														unpromisify(async () => {
															await props.refetch?.();
														})();
													}
												},
											})
										}
									>
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import external
									</Button>
								)}
							</ReactQueryWrapper>
						)}
					</AuthorizationGuard>
					<Button
						size="small"
						palette="secondary"
						onClick={() => setShowNewPortfolio(true)}
						data-qualifier="IconWall/NewPortfolio"
					>
						<Icon icon="Outline1" size={16} />
						New Portfolio
					</Button>

					<Dialog show={showNewPortfolio} size="medium" onClose={() => setShowNewPortfolio(false)}>
						<div className="grid grid-cols-2 gap-4 ">
							<Link
								to={typedUrlForRoute("Portfolios/CreatePortfolio", {})}
								className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
							>
								<CreatePortfolioIcon classList="mx-auto" data-qualifier="IconWall/NewPortfolio/Create" />
								<p className="text-lg font-semibold text-center">Create</p>
								<p className="text-center">Set constraints and targets and let Sphere create a portfolio for you</p>
							</Link>
							<Link
								to={typedUrlForRoute("Portfolios/UploadPortfolioPage/Portfolio", {})}
								className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
							>
								<UploadPortfolioIcon classList="mx-auto" data-qualifier="IconWall/NewPortfolio/UploadEditor" />
								<p className="text-lg font-semibold text-center">Upload and/or Set Weights</p>
								<p className="text-center">Import existing portfolios and set weights</p>
							</Link>
						</div>
					</Dialog>
				</div>
			</div>
		);
	}),
	ReferenceListEmptyData: decorate(function WallImpl(props: {
		// user?: IUser;
		onCreate?: () => void;
		onUpload?: () => void;
		// refetch?: () => Promise<QueryObserverResult<InvestmentListEntry[], unknown>>;
	}): JSX.Element {
		return (
			<div className="flex justify-between items-center grow px-3">
				<div className="flex items-center">
					<EmptyBox />
					<p className="whitespace-pre-line">
						<Trans
							i18nKey="PORTFOLIO_STUDIO.empty"
							values={{ ptfType: "portfolio reference" }}
							components={{
								strong: <strong />,
							}}
						/>
					</p>
				</div>
				<div className="flex gap-2.5">
					<Button
						palette="secondary"
						size="small"
						onClick={props.onUpload}
						data-qualifier="IconWall/NewTargetPortfolio"
					>
						<div className="flex h-3 items-center">
							<span className="flex mr-1.5 h-fit">
								<Icon icon="Outline1" />
							</span>
							New target portfolio
						</div>
					</Button>
					<Button palette="secondary" size="small" onClick={props.onCreate} data-qualifier="IconWall/NewBenchmark">
						<div className="flex h-3 items-center">
							<span className="flex mr-1.5 h-fit">
								<Icon icon="Outline1" />
							</span>
							New Benchmark
						</div>
					</Button>
				</div>
			</div>
		);
	}),
	UniverseListEmptyData: decorate(function WallImpl(props: {
		onUpload?: () => void;
		refetch?: () => Promise<QueryObserverResult<ReferenceUniverseListEntry[], unknown>>;
	}): JSX.Element {
		const user = useUserValue();
		const integrationsApiV2 = useApiGen(IntegrationsControllerApiFactory);
		return (
			<div className="flex justify-between items-center grow px-3">
				<div className="flex items-center">
					<EmptyBox />
					<p className="whitespace-pre-line">
						<Trans
							i18nKey="PORTFOLIO_STUDIO.empty"
							values={{ ptfType: "universe" }}
							components={{
								strong: <strong />,
							}}
						/>
					</p>
				</div>
				<div className="flex gap-2.5">
					<AuthorizationGuard
						requiredServices={["IMPORT"]}
						requiredParam={({ importFormats }) => importFormats !== undefined && importFormats.length > 0}
					>
						{() => (
							<ReactQueryWrapper
								queryFn={async () => {
									const importConverters = await axiosExtract(integrationsApiV2.retrieveUniverseImportConverterType());
									return importConverters.filter((converter) => user.importFormats?.includes(converter));
								}}
								queryKey={["importFormat", "universe"]}
							>
								{(converters) => (
									<Button
										size="small"
										palette="secondary"
										data-qualifier="IconWall/NewUniverse/Import"
										onClick={() =>
											spawnUniverseImportDialog({
												converters,
												onSubmit: async (universe) => {
													try {
														const failed: unknown[] = [];
														const callStack = universe.map(
															(universe) => () =>
																integrationsApiV2.importUniverse(universe).catch((e) => failed.push(e)),
														);
														await parallelize(callStack);

														if (callStack.length > 0 && failed.length === callStack.length) {
															throw new Error("No portfolio imported", { cause: failed });
														}

														platformToast({
															children:
																callStack.length === 0
																	? "No universe were imported"
																	: "Sphere has taken over your request",
															severity: callStack.length === 0 ? "warning" : "info",
															icon: "Dowload",
														});
													} catch (error) {
														platformToast({
															children: "No universe imported",
															severity: "error",
															icon: "Dowload",
														});
														throw error;
													} finally {
														unpromisify(async () => {
															await props.refetch?.();
														})();
													}
												},
											})
										}
									>
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import external
									</Button>
								)}
							</ReactQueryWrapper>
						)}
					</AuthorizationGuard>
					<Button palette="secondary" size="small" onClick={props.onUpload} data-qualifier="IconWall/NewUniverse">
						<div className="flex h-3 items-center">
							<span className="flex mr-1.5 h-fit">
								<Icon icon="Outline1" />
							</span>
							New universe
						</div>
					</Button>
				</div>
			</div>
		);
	}),
	MarketViewListEmptyData: decorate(function WallImpl(props: { marketViewSetting?: MarketViewSettings }): JSX.Element {
		const [showNewMarketView, setShowNewMarketView] = useState(false);
		return (
			<div className="flex justify-between items-center grow px-3">
				<div className="flex items-center">
					<EmptyBox />
					<p className="whitespace-pre-line">
						<Trans
							i18nKey="PORTFOLIO_STUDIO.EMPTY_SCENARIO"
							components={{
								strong: <strong />,
							}}
						/>
					</p>
				</div>
				<Button
					palette="secondary"
					size="small"
					onClick={() => setShowNewMarketView(true)}
					data-qualifier="IconWall/NewMarketView"
				>
					<span className="mr-1.5 h-fit">
						<Icon icon="Outline1" />
					</span>
					<p className="tracking-wide">Create market view</p>
				</Button>
				<Dialog
					show={showNewMarketView}
					size={
						props.marketViewSetting?.activeMarketViewTypes?.length === 3
							? "large"
							: props.marketViewSetting?.activeMarketViewTypes?.length === 2
							  ? "medium"
							  : "small"
					}
					onClose={() => setShowNewMarketView(false)}
				>
					<div className="flex gap-4 mx-auto">
						{props.marketViewSetting?.activeMarketViewTypes?.includes(
							MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
						) && (
							<Link
								to={typedUrlForRoute("MarketViewWorkSpace/New", {
									type: MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
								})}
								className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							>
								<div className="w-[112px] h-[115px] overflow-hidden mx-auto">
									<CreateMarketViewRegimeIconV2
										style={{ width: 117, height: 120 }}
										viewBox={{ width: 117, height: 120 }}
										data-qualifier="IconWall/NewMarketView/ExpectedReturn"
									/>
								</div>
								<p className="text-lg font-semibold text-center">Expected returns</p>
								<p className="text-center">Set your market view regimes</p>
							</Link>
						)}
						{props.marketViewSetting?.activeMarketViewTypes?.includes(
							MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
						) && (
							<Link
								to={typedUrlForRoute("MarketViewWorkSpace/New", {
									type: MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
								})}
								className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							>
								<CreateMarketViewPositioningIconV2
									classList="mx-auto"
									data-qualifier="IconWall/NewMarketView/Positioning"
								/>
								<p className="text-lg font-semibold text-center">Under / over weight</p>
								<p className="text-center">Set your market view positioning</p>
							</Link>
						)}
						{props.marketViewSetting?.activeMarketViewTypes?.includes(
							MarketViewSettingsActiveMarketViewTypesEnum.Custom,
						) && (
							<Link
								to={typedUrlForRoute("MarketViewWorkSpace/New", {
									type: props.marketViewSetting!.customMarketViewType!,
									isCustom: "true",
								})}
								className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							>
								<CreateMarketViewCustomIcon classList="mx-auto" data-qualifier="IconWall/NewMarketView/Custom" />
								<p className="text-lg font-semibold text-center">
									{props.marketViewSetting.customMarketViewName ?? "Custom"}
								</p>
								<p className="text-center">Set your custom market view</p>
							</Link>
						)}
					</div>
				</Dialog>
			</div>
		);
	}),
	PortfolioMonitoringEmptyData: decorate(function WallImpl(props: { onCreate: () => void }): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<EmptyBox />
					<p className="whitespace-pre-line text-center">
						<strong>You haven’t set anything to be monitored yet.</strong>
						{"\n"}
						Create a proposal to enhance and monitor this portfolio
					</p>
				</div>
				<Button palette="secondary" size="small" classList="mt-3" onClick={props.onCreate}>
					Create proposal
				</Button>
			</div>
		);
	}),
	PortfolioMonitoringUnconsistency: decorate(function WallImpl(): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<EmptyBox />
					<p className="whitespace-pre-line text-center">
						<strong>You haven&apos;t set anything to be monitored yet.</strong>
					</p>
				</div>
			</div>
		);
	}),
	PortfolioMonitoringHidden: decorate(function WallImpl(): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<EmptyBox />
					<p className="whitespace-pre-line text-center">
						<strong>Your data monitoring is hidden</strong>
						{"\n"}
						Check your filters in the upper right hand side to show your data monitoring
					</p>
				</div>
			</div>
		);
	}),
	ProxyEmptyData: decorate(function WallImpl(): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex justify-between items-center grow px-3">
				<div className="flex items-center">
					<EmptyBox />
					<p className="whitespace-pre-line">
						<strong>{t("WALL_STATES.PROXY_EMPTY.DESCRIPTION")}</strong>
					</p>
				</div>
			</div>
		);
	}),
	Loader: decorate(function WallImpl(): JSX.Element {
		return <CircularProgressBar value="indeterminate" />;
	}),
	WizardError: decorate(function WallImpl(props: { onAsyncClear: () => MaybePromise<void> }): JSX.Element {
		const { t } = useTranslation();
		return (
			<div>
				<SadMachineDrawIcon classList={{ "mx-auto my-0": true }} />
				<div className="flex items-center justify-center h-full">
					<div className="grid justify-center">
						<p className="text-lg text-center mb-2">{t("WALL_STATES.NO_DATA.TITLE")}</p>
						<AsyncButton
							palette="primary"
							size="small"
							classList="mx-auto"
							onClickAsync={props.onAsyncClear}
							data-qualifier="portfolioWizard/iconWall/missingData/clear"
						>
							Clear your portfolio
						</AsyncButton>
					</div>
				</div>
			</div>
		);
	}),
	EditorEmptyData: decorate(function WallImpl(props: { entity: UploadEntity }) {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col items-center justify-center h-full">
				<div className="w-fit">
					<EmptyBox style={{ width: 103, height: 101 }} />
				</div>

				<div className="grid justify-center text-center !not-italic">
					<p className="font-semibold">There are no assets in your {t(`ENTITY.${props.entity}`)} yet.</p>
					<p className="mb-4">
						Add them either by uploading them from excel, selecting them from your account or add a single row manually
						from the options above
					</p>
				</div>
			</div>
		);
	}),
	CalculatingApi: decorate(function WallImpl() {
		return (
			<>
				<ProgressBar
					value="indeterminate"
					accentColor={themeCSSVars.palette_A300}
					backgroundColor={themeCSSVars.palette_N100}
					classList="absolute top-0 inset-x-0"
					barHeight={8}
				/>
				<div className="flex flex-col">
					<h4 className="text-center">Calculating...</h4>
				</div>
			</>
		);
	}),
	CalculatingPortfolioWidgetData: decorate(function WallImpl(props: { hideDrawIcon?: boolean }) {
		const { t } = useTranslation();

		return (
			<div className="flex flex-col">
				<MonitorCalculatingDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center font-semibold">{t("WALL_STATES.CALCULATING_DATA.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.CALCULATING_DATA.DESCRIPTION")}</h4>
			</div>
		);
	}),
	ErrorPortfolioWidgetData: decorate(function WallImpl(props: {
		redirect?: {
			path: string;
			title: string;
		};
		hideDrawIcon?: boolean;
	}): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<MonitorErrorDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center font-semibold">{t("WALL_STATES.NO_DATA.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.NO_DATA.DESCRIPTION")}</h4>
				{props.redirect && (
					<Link
						to={props.redirect.path}
						className={`mt-4 text-center underline text-[color:${themeCSSVars.global_palette_primary_600}]`}
					>
						<strong>Return to {props.redirect.title}</strong>
					</Link>
				)}
			</div>
		);
	}),
	WaitForHistoricalPortfolioWidgetData: decorate(function WallImpl(props: {
		redirect?: {
			path: string;
			title: string;
		};
		hideDrawIcon?: boolean;
	}): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col">
				<CalendarDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
				<h4 className="text-center">{t("WALL_STATES.HISTORICAL_DATA_NOT_YET_AVAILABLE.TITLE")}</h4>
				<h4 className="text-center">{t("WALL_STATES.HISTORICAL_DATA_NOT_YET_AVAILABLE.DESCRIPTION")}</h4>
			</div>
		);
	}),
	PortfolioMonitoringUnconsistencyV2: decorate(function WallImpl(): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<MonitoringMetricsIcon />
					<p className="whitespace-pre-line text-center">
						<strong>You haven&apos;t set anything to be monitored yet.</strong>
					</p>
				</div>
			</div>
		);
	}),
	PortfolioMonitoringEmptyDataV2: decorate(function WallImpl(props: { onCreate: () => void }): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<MonitoringMetricsIcon />
					<p className="whitespace-pre-line text-center">
						<strong>You haven&apos;t set anything to be monitored yet.</strong>
						{"\n"}
						Add them to enhance and monitor this portfolio
					</p>
				</div>
				<Button palette="secondary" size="small" classList="mt-3" onClick={props.onCreate}>
					<Icon icon="Outline1" />
					Add constraint
				</Button>
			</div>
		);
	}),
	PortfolioMonitoringHiddenV2: decorate(function WallImpl(): JSX.Element {
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<MonitoringMetricsIcon />
					<p className="whitespace-pre-line text-center">
						<strong>Your data monitoring is hidden</strong>
						{"\n"}
						Check your filters in the upper right hand side to show your data monitoring
					</p>
				</div>
			</div>
		);
	}),
	PortfolioCommentaryEmpty: decorate(function WallImpl(props: {
		onGenerate(): Promise<void>;
		hideDrawIcon?: boolean;
	}): JSX.Element {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col items-center">
				<div className="flex items-center flex-col">
					<TextDescriptionDrawIcon classList={{ hidden: props.hideDrawIcon, "mx-auto my-0": true }} />
					<p className="whitespace-pre-line text-center">
						<strong>No comments yet</strong>
						{"\n"}
						Comment could not be generated automatically
					</p>
				</div>
				<AsyncButton palette="secondary" size="small" classList="mt-3" onClickAsync={props.onGenerate}>
					<Icon icon="Outline1" />
					{t("BUTTON.GENERATE")}
				</AsyncButton>
			</div>
		);
	}),
};

export const walls = Object.keys(IconWalls) as (keyof typeof IconWalls)[];
