import React from "react";

// TODO: remove
/**
 * @deprecated
 */
export function useRedrawUtils(
	chartRef: any,
	redrawFunction: any,
	whenToRedraw: any[],
	keyCondition: boolean,
	id: string = new Date().getTime().toString(),
) {
	const TimerWrapper = {
		[id]: () => {
			// this is need for performances, if you use drawSpiderweb directly for resize
			// it will get called a hundred of times and this will ko the browser
			// @ts-ignore
			if (window[id]) {
				clearTimeout((window as any)[id]);
			}
			// @ts-ignore
			window[id] = setTimeout(function () {
				if (chartRef?.current) {
					redrawFunction();
				}
			}, 250);
		},
	};

	React.useEffect(() => {
		if (chartRef.current && keyCondition) {
			redrawFunction();
		}
		window.removeEventListener("resize", TimerWrapper[id]);
		setTimeout(() => window.addEventListener("resize", TimerWrapper[id]));
		return () => window.removeEventListener("resize", TimerWrapper[id]);
	}, whenToRedraw);
}
