import PositioninGraph from "$root/components/OutlookPositioningGraph";
import TimeSeriesModal from "$root/functional-areas/outlook/TimeSeriesModal";
import useWidgetsData from "$root/hooks/useWidgetsData";
import { builtInSortFnFor } from "$root/utils/collections";
import { toClassName } from "$root/utils/react-dom-extra";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Button, ScrollWrapper, Table } from "@mdotm/mdotui/components";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export type TableVariants =
	| "EQUITY"
	| "FIXED_INCOME"
	| "COMMODITIES"
	| "DASHBOARD_EQUITY"
	| "DASHBOARD_FIXED_INCOME"
	| "DASHBOARD_COMMODITIES"
	| "EQUITY_EU"
	| "EQUITY_USA";

export type TableParams = {
	assetClass: string;
	current: number;
	prev?: number;
	tooltip: Array<{ label: string; value: string; date?: string }>;
	index: number;
};

type OutlookBlockTableProps = {
	data: Array<TableParams>;
	title: TableVariants;
	className?: string;
	rowClassName?: string;
	pushTo?: string;
	sortable?: boolean;
	hideHeader?: boolean;
	assetClassTimeSeries?: {
		[key: string]: [number, number, boolean][];
	};
};

const OutlookBlockTable: FC<OutlookBlockTableProps> = ({
	data,
	title,
	sortable = false,
	className = "",
	rowClassName = "",
	pushTo = "",
	hideHeader,
	assetClassTimeSeries,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { currentWidgetsData } = useWidgetsData();

	const TABLE_TITLE: Record<TableVariants, string> = {
		COMMODITIES: "Commodities",
		EQUITY: "Equity",
		FIXED_INCOME: "Fixed income",
		DASHBOARD_EQUITY: `${t("EQUITY")} outlook`,
		DASHBOARD_FIXED_INCOME: `${t("FI")} outlook`,
		DASHBOARD_COMMODITIES: `${t("COMMODITIES")} outlook`,
		EQUITY_EU: "Equity Europe Large Cap",
		EQUITY_USA: "Equity USA Large Cap",
	};

	const TABLE_HEADER: Record<TableVariants, string> = {
		COMMODITIES: "MICRO ASSET CLASS",
		EQUITY: "MICRO ASSET CLASS",
		FIXED_INCOME: "MICRO ASSET CLASS",
		EQUITY_EU: "MICRO ASSET CLASS",
		EQUITY_USA: "MICRO ASSET CLASS",
		DASHBOARD_EQUITY: t("EQUITY"),
		DASHBOARD_FIXED_INCOME: t("FI"),
		DASHBOARD_COMMODITIES: t("SHORT_COMMODITIES"),
	};

	const outLookTile = TABLE_TITLE[title];
	const outLookHeader = TABLE_HEADER[title];
	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });
	const columns = useMemo<Array<TableColumn<TableParams>>>(
		() => [
			{
				header: outLookHeader,
				content: ({ assetClass }) => assetClassLabels[assetClass as keyof typeof assetClassLabels] || assetClass,
				relativeWidth: 53,
				name: "assetClass",
				...(sortable ? { sortFn: builtInSortFnFor("assetClass") } : {}),
			},
			{
				header: () => (
					<div className="flex justify-between grow px-2">
						{["SU", "U", "N", "O", "SO"].map((el) => (
							<div key={el}>{el}</div>
						))}
					</div>
				),
				headerCellTitleClassList: "grow",
				headerCellWrapperClassList: "[&>div:nth-child(2)]:!hidden",
				content: (content) => (
					<div className="w-full flex h-10">
						<PositioninGraph
							{...content}
							tooltip={content.tooltip}
							tootlipTitle={assetClassLabels[content.assetClass as keyof typeof assetClassLabels] || content.assetClass}
							lastDashboardUpdate={currentWidgetsData.creation_time.toString()}
						/>
					</div>
				),
				relativeWidth: 40,
			},
			{
				header: "",
				content: function Graph(p) {
					const disabled =
						assetClassTimeSeries === undefined ||
						assetClassTimeSeries[p.assetClass] === undefined ||
						assetClassTimeSeries[p.assetClass].length === 0;

					const currentRegime = useMemo(
						() => ({
							value: p.current,
							label: p.tooltip.find(({ label }) => label.toUpperCase() === "CURRENT")?.value ?? "",
						}),
						[p.current, p.tooltip],
					);

					const previousRegime = useMemo(
						() => ({
							value: p.prev,
							label:
								p.tooltip.find(({ label }) => label.toUpperCase() === "PREVIOUS")?.value ??
								p.tooltip.find(({ label }) => label.toUpperCase() === "CURRENT")?.value ??
								"",
						}),
						[p.prev, p.tooltip],
					);

					return (
						<TimeSeriesModal
							disabled={disabled}
							assetClass={p.assetClass}
							series={assetClassTimeSeries}
							currentRegime={currentRegime}
							previousRegime={previousRegime}
						/>
					);
				},
				relativeWidth: 7,
				minWidth: 40,
				hidden: assetClassTimeSeries === undefined,
			},
		],
		[outLookHeader, sortable, assetClassLabels, currentWidgetsData.creation_time, assetClassTimeSeries],
	);

	return (
		<div>
			<div className="flex justify-between">
				<h3 className={toClassName({ hidden: hideHeader, "text-xs font-semibold": true })}>{outLookTile}</h3>
				{pushTo && (
					<Button onClick={() => history.push(pushTo)} size="x-small" palette="tertiary">
						{t("VIEW")}
					</Button>
				)}
				{/* <DataSeriesModal typeMode="DASHBOARD" splitted ACFilter={title} blockedPath="/report" /> */}
			</div>
			<ScrollWrapper classList={{ "relative z-0": true, [className]: true }} startShadow={false}>
				{/** table */}
				<Table
					columns={columns}
					rows={data}
					headerRowClassList={toClassName({
						"sticky top-0 z-10 bg-white": true,
						hidden: hideHeader,
					})}
					rowClassList={`OutlookRowsTable ${rowClassName}`}
					noDataText={
						<div className="flex items-center justify-center h-20">
							<p className="!bg-transparent">No data available</p>
						</div>
					}
				/>
			</ScrollWrapper>
		</div>
	);
};

export default OutlookBlockTable;
