import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall, noop } from "@mdotm/mdotui/utils";
import BigNumber from "bignumber.js";
import type { Map } from "immutable";
import { Set } from "immutable";
import { useCallback, useMemo, useRef, useState } from "react";
import type { CompositionEntry } from "./shared";

export type EditorBuilderActions = {
	getTotalWeight(): BigNumber;
	getWeight(ticker: string): BigNumber | null;
	getDeleted(): Set<string>;
	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, CompositionEntry>;

	delete(mode: "soft", keys: string | Iterable<string>, toggle: boolean): EditorBuilder;
	delete(mode: "hard", keys: string | Iterable<string>): EditorBuilder;
	setWeight(ticker: string, newWeight: BigNumber | null): EditorBuilder;
	set(key: string, entry: CompositionEntry): EditorBuilder;
	normalize(distributeTo: Set<string>): EditorBuilder;
	excessToCash(): EditorBuilder;
};

export type UseEditorBuilderResult = EditorBuilderActions & {
	reset(
		resetDataOrFn: MaybeFn<{
			composition: Map<string, CompositionEntry>;
			deleted?: Set<string>;
			cashId?: string;
			decimalPlaces?: number;
		}>,
	): void;
};

/**
 * A hook that creates a {@link EditorBuilder composition builder} and wraps every direct operation that would
 * normally return a new instance in a React "setState".
 *
 * Example:
 * ```tsx
 * const compositionBuilder = useCompositionBuilder(() => ({
 * 	composition: Map<string, number>([["demo", 20]]),
 * }));
 *
 * return (
 * 	<Button onClick={() => compositionBuilder.setWeight("demo", 30)}>
 * 		Current weight: {compositionBuilder.getWeight("demo")?.toNumber() ?? '-'}
 * 	</Button>
 * );
 * ```
 *
 * @param initializer The initial value or a function that produces it.
 */
export function useEditorBuilder(
	initializer: MaybeFn<{
		composition: Map<string, CompositionEntry>;
		deleted?: Set<string>;
		cashId?: string;
		decimalPlaces?: number;
	}>,
): UseEditorBuilderResult {
	const [builder, setBuilder] = useState(() => {
		const { composition, decimalPlaces, deleted, cashId } = maybeCall(initializer);
		return new EditorBuilder(composition, deleted, cashId, decimalPlaces);
	});
	const latestCompositionBuilderRef = useRef(builder);
	const updateBuilder = useCallback((newBuilder: EditorBuilder) => {
		setBuilder(newBuilder);
		latestCompositionBuilderRef.current = newBuilder;
		return newBuilder;
	}, []);

	return useMemo(() => {
		noop(builder); // track to trigger re-renders
		return {
			setWeight: (...args) => updateBuilder(latestCompositionBuilderRef.current.setWeight(...args)),
			getWeight: (...args) => latestCompositionBuilderRef.current.getWeight(...args),
			excessToCash: (...args) => updateBuilder(latestCompositionBuilderRef.current.excessToCash(...args)),
			normalize: (...args) => updateBuilder(latestCompositionBuilderRef.current.normalize(...args)),
			getTotalWeight: (...args) => latestCompositionBuilderRef.current.getTotalWeight(...args),
			getComposition: (...args) => latestCompositionBuilderRef.current.getComposition(...args),
			getDeleted: (...args) => latestCompositionBuilderRef.current.getDeleted(...args),
			set: (...args) => updateBuilder(latestCompositionBuilderRef.current.set(...args)),

			delete: (
				...args:
					| [mode: "soft", keys: string | Iterable<string>, toggle: boolean]
					| [mode: "hard", keys: string | Iterable<string>]
			) => {
				if (args[0] === "hard") {
					return updateBuilder(latestCompositionBuilderRef.current.delete(...args));
				}
				return updateBuilder(latestCompositionBuilderRef.current.delete(...args));
			},
			reset(resetDataOrFn: typeof initializer) {
				const { composition, deleted, cashId, decimalPlaces } = maybeCall(resetDataOrFn);
				updateBuilder(new EditorBuilder(composition, deleted, cashId, decimalPlaces));
			},
		};
	}, [updateBuilder, builder]);
}

/**
 * A class that represents a collection of instruments that constitute a composition and exposes utility methods to interact with it.
 * A composition is the combination of tickers and their respective weights. When normalised, all the weights should
 * sum up to 100 (%).
 */
export class EditorBuilder implements EditorBuilderActions {
	composition: Map<string, CompositionEntry>;
	deleted: Set<string>;
	cashId?: string;
	decimalPlaces: number;
	constructor(
		composition: Map<string, CompositionEntry>,
		deleted?: Set<string>,
		cashId?: string,
		decimalPlaces?: number,
	) {
		this.composition = composition;
		this.deleted = deleted ?? Set();
		this.cashId = cashId;
		this.decimalPlaces = decimalPlaces ?? 2;
	}

	set(key: string, entry: CompositionEntry): EditorBuilder {
		return new EditorBuilder(this.composition.set(key, entry), this.deleted, this.cashId, this.decimalPlaces);
	}

	delete(mode: "soft", keys: string | Iterable<string>, toggle: boolean): EditorBuilder;
	delete(mode: "hard", keys: string | Iterable<string>): EditorBuilder;
	delete(mode: "hard" | "soft", keys: string | Iterable<string>, toggle?: boolean): EditorBuilder {
		if (mode === "hard") {
			return new EditorBuilder(
				typeof keys === "string" ? this.composition.delete(keys) : this.composition.deleteAll(keys),
				this.deleted,
				this.cashId,
			);
		}

		return new EditorBuilder(
			this.composition,
			typeof keys === "string"
				? toggle
					? this.deleted.add(keys)
					: this.deleted.remove(keys)
				: toggle
				  ? this.deleted.union(keys)
				  : this.deleted.subtract(keys),
			this.cashId,
			this.decimalPlaces,
		);
	}

	setWeight(ticker: string, newWeight: BigNumber | number | null): EditorBuilder {
		const entry = this.composition.get(ticker);
		if (entry) {
			return new EditorBuilder(
				this.composition.set(ticker, {
					...entry,
					weight: typeof newWeight === "number" ? BigNumber(newWeight) : newWeight,
				}),
				this.deleted,
				this.cashId,
				this.decimalPlaces,
			);
		}

		return new EditorBuilder(this.composition, this.deleted, this.cashId);
	}

	getWeight(ticker: string): BigNumber | null {
		const weight = this.composition.get(ticker)?.weight;
		return weight ? BigNumber(weight) : null;
	}

	excessToCash(): EditorBuilder {
		if (this.cashId === undefined) {
			console.warn("No cashId specified, cannot move excess to cash");
			return this;
		}
		if (this.getTotalWeight().gt(100)) {
			console.warn("No excess to move to cash available, the total weight is already above 100");
			return this;
		}
		const totalExcludingCash = this.composition
			.delete(this.cashId)
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0));
		return this.setWeight(this.cashId, BigNumber(100).minus(totalExcludingCash).decimalPlaces(this.decimalPlaces));
	}

	normalize(distributeTo: Iterable<string>): EditorBuilder {
		const totalOvershot = this.getTotalWeight().minus(100).decimalPlaces(this.decimalPlaces);

		if (totalOvershot.eq(0)) {
			// Nothing to normalise.
			return this;
		}

		const editableTickerIds = Set(distributeTo).subtract(this.deleted);
		const totalWeightOfSelected = editableTickerIds.reduce(
			(sum, cur) => sum.plus(this.composition.get(cur)?.weight ?? BigNumber(0)),
			BigNumber(0),
		);
		if (totalWeightOfSelected.lt(totalOvershot)) {
			// Normalisation is impossible to achieve but we can approach it by setting all the weights to 0.
			return new EditorBuilder(
				this.composition.withMutations((editableCompositionEntry) => {
					for (const tickerId of editableTickerIds) {
						const entry = editableCompositionEntry.get(tickerId);
						if (entry) {
							editableCompositionEntry.set(tickerId, { ...entry, weight: BigNumber(0) });
						}
					}
				}),
				this.deleted,
				this.cashId,
				this.decimalPlaces,
			);
		}
		if (totalWeightOfSelected.eq(0)) {
			if (totalOvershot.gt(0)) {
				// Normalisation is impossible to achieve with the current selection.
				console.warn(
					"Cannot normalise: the current selection has a total weight of 0 and we have to reduce the total, therefore by proceeding we would have to set negative weights, which are not allowed.",
				);
				return this;
			}
			const newWeightsTmp = this.composition.withMutations((editableCompositionEntry) => {
				const partialWeight = totalOvershot.times(-1).div(editableTickerIds.size).decimalPlaces(this.decimalPlaces);
				for (const tickerId of editableTickerIds) {
					const entry = editableCompositionEntry.get(tickerId);
					if (entry) {
						editableCompositionEntry.set(tickerId, { ...entry, weight: partialWeight });
					}
				}
			});

			const remainingOvershot = newWeightsTmp
				.removeAll(this.deleted)
				.reduce((sum, cur) => sum.plus(BigNumber(cur.weight ?? 0)), BigNumber(0))
				.minus(100)
				.decimalPlaces(this.decimalPlaces);

			const newWeights = newWeightsTmp.update(editableTickerIds.first(), (entry) => {
				if (!entry?.weight || !entry) {
					return entry;
				}
				return { ...entry, weight: entry.weight.minus(remainingOvershot) };
			});

			return new EditorBuilder(newWeights, this.deleted, this.cashId, this.decimalPlaces);
		}

		const newWeightsTmp = this.composition.withMutations((editableCompositionEntry) => {
			for (const tickerId of editableTickerIds) {
				const entry = editableCompositionEntry.get(tickerId);
				if (entry) {
					editableCompositionEntry.set(tickerId, {
						...entry,
						weight: (entry?.weight ?? BigNumber(0))
							.minus((entry?.weight ?? BigNumber(0)).div(totalWeightOfSelected).times(totalOvershot))
							.decimalPlaces(this.decimalPlaces),
					});
				}
			}
		});
		const remainingOvershot = newWeightsTmp
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0))
			.minus(100)
			.decimalPlaces(this.decimalPlaces);
		const newWeights = newWeightsTmp.update(editableTickerIds.first(), (entry) => {
			if (!entry?.weight || !entry) {
				return entry;
			}
			return { ...entry, weight: entry.weight.minus(remainingOvershot) };
		});

		return new EditorBuilder(newWeights, this.deleted, this.cashId, this.decimalPlaces);
	}

	getTotalWeight(): BigNumber {
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted);
		return compositionWithoutDeleted.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0));
	}

	getTotalWeightWithoutCash(): BigNumber {
		if (this.cashId === undefined) {
			return this.getTotalWeight();
		}
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted).delete(this.cashId);
		return compositionWithoutDeleted.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0));
	}

	getDeleted(): Set<string> {
		return this.deleted;
	}

	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, CompositionEntry> {
		if (opts?.excludeDeleted) {
			return this.composition.removeAll(this.deleted);
		}
		return this.composition;
	}
}
