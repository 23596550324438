import type { PrintableProps } from "../../configuration";
import { Card } from "../Card";

const Box = (props: PrintableProps<{ list: string[] }, string>): JSX.Element => {
	return (
		<Card title="">
			<div className="grid space-y-8">
				{props.list.map((title, i) => (
					<div
						className="h-40 w-full rounded"
						style={{ backgroundColor: `rgb(${(i * 87 + 87) % 256},${(i * 221 + 56) % 256},${(i * 144 + 98) % 256})` }}
						key={title}
					>
						{title}
					</div>
				))}
			</div>
		</Card>
	);
};

export default Box;
