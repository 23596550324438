import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";

export function FeedbackIcon({ color, style, classList }: { color: string } & StylableProps): JSX.Element {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
			className={toClassName(classList)}
		>
			<path
				d="M14.6597 3.17398C14.6597 2.68732 14.4663 2.22065 14.1197 1.87398C13.773 1.52732 13.3063 1.33398 12.8197 1.33398H12.0263C10.373 1.33398 8.78634 1.99398 7.61967 3.16065L7.29301 3.48732H4.12634C2.58634 3.48732 1.33301 4.74065 1.33301 6.28065C1.33301 6.44732 1.39967 6.61398 1.51967 6.73398L4.93301 10.1473L3.68634 11.394C3.57301 11.5073 3.49301 11.654 3.47967 11.814C3.46634 12.014 3.53301 12.2007 3.67967 12.334C3.93301 12.574 4.35301 12.5473 4.59967 12.3007L5.84634 11.054L9.25968 14.4673C9.37967 14.5873 9.54634 14.654 9.71301 14.654C11.253 14.654 12.5063 13.4007 12.5063 11.8607V8.68732L12.833 8.36065C13.9997 7.19398 14.6597 5.60732 14.6597 3.95398V3.16065V3.17398ZM2.63967 6.03398L2.67301 5.91398C2.83967 5.24732 3.43967 4.78065 4.13301 4.78065H6.01301L3.69967 7.09398L2.63967 6.03398ZM11.2197 11.874C11.2197 12.5607 10.753 13.1607 10.0863 13.334L9.96634 13.3673L8.90634 12.3073L11.2197 9.99398V11.874ZM13.3663 3.97398C13.3663 5.28732 12.8463 6.54065 11.9197 7.46732L7.99301 11.394L6.75301 10.154L7.99967 8.90732C8.11301 8.79398 8.18634 8.65398 8.20634 8.49398C8.22634 8.30065 8.15967 8.11398 8.01967 7.98065C7.76634 7.72732 7.35967 7.72732 7.10634 7.98065L5.83967 9.24732L4.59967 8.00732L8.52634 4.08065C9.45967 3.14732 10.6997 2.63398 12.0197 2.63398H12.9263C13.1597 2.63398 13.353 2.82732 13.353 3.06065V3.96732L13.3663 3.97398Z"
				fill={color}
			/>
		</svg>
	);
}
