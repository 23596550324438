import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import DashedRectangle from "$root/components/icons/DashedRectangle";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";

const CustomizedChart: React.FC<{
	performanceSeries: { portfolioPerformance: [number, number][]; benchmarkPerformance: [number, number][] };
}> = ({ performanceSeries }) => {
	const { t } = useTranslation();
	const colorCategories = useMemo(
		() => [
			{ name: t("PORTFOLIO_PERFORMANCE.CURRENT_PERF"), color: "#00AEEF", Component: ColoredRectangle },
			{ name: t("PORTFOLIO_PERFORMANCE.BENCHMARK"), color: "#8C8EA8", Component: DashedRectangle },
		],
		[t],
	);
	const chartRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		if (chartRef.current) {
			chartRef.current.chart.reflow();
			chartRef.current.chart.redraw();
		}
	}, [chartRef]);

	const series = useMemo(() => {
		// Sorting Requested By Highcharts
		const tmpSeries = {
			portfolioPerformance: performanceSeries.portfolioPerformance
				.map(([x, y]) => ({
					x,
					y,
				}))
				.sort((a, b) => a.x - b.x),
			benchmarkPerformance: performanceSeries.benchmarkPerformance
				.map(([x, y]) => ({
					x,
					y,
				}))
				.sort((a, b) => a.x - b.x),
		};

		// tricky fix for highcharts start compare
		// TODO: find a better way
		const { benchmarkPerformance, portfolioPerformance } = tmpSeries;
		portfolioPerformance[0].y = portfolioPerformance[0].y === 0 ? 0.00001 : portfolioPerformance[0].y;
		benchmarkPerformance[0].y = benchmarkPerformance[0].y === 0 ? 0.00001 : benchmarkPerformance[0].y;
		return tmpSeries;
	}, [performanceSeries]);

	const options = useMemo(() => {
		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
				reflow: true,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			navigator: {
				enabled: false,
			},
			scrollbar: {
				enabled: false,
			},
			exporting: {
				enabled: false,
				floating: false,
			},
			rangeSelector: {
				// inputEnabled: true,
				// allButtonsEnabled: false,
				// enabled or disabled
				enabled: false,
			},
			yAxis: {
				opposite: false,
				title: {
					enabled: false,
				},
				gridLine: 1,
				gridLineColor: "#CCCCCC",
			},
			plotOptions: {
				series: {
					animation: {
						duration: 0,
					},
				},
			},
			series: [
				{
					data: series.portfolioPerformance,
					color: colorCategories[0].color,
					marker: {
						fillColor: colorCategories[0].color,
					},
					name: t("PORTFOLIO_PERFORMANCE.CURRENT_PERF"),
				},
				{
					data: series.benchmarkPerformance,
					color: colorCategories[1].color,
					dashStyle: "Dash",
					marker: {
						fillColor: colorCategories[1].color,
					},
					name: t("PORTFOLIO_PERFORMANCE.BENCHMARK"),
				},
			],
			time: {
				// timezoneOffset: new Date().getTimezoneOffset(),
				timezoneOffset: 12 * 60, // Method to Fix Backend Date Time
				useUTC: false,
			},
		};
	}, [colorCategories, series.benchmarkPerformance, series.portfolioPerformance, t]);

	return (
		<div className="h-[250px]">
			<HighchartsReact
				containerProps={{ style: { height: "calc(100% - 75px)" } }}
				highcharts={Highcharts}
				constructorType="stockChart"
				options={options}
				ref={chartRef}
			/>
			<div>
				<GraphLegend style={{ position: "static", margin: 0, paddingLeft: "16px" }}>
					{colorCategories.map(({ name, color, Component }) => (
						<div key={color} className="legend-container light more-space book">
							<Component variant="vertical" color={color} />
							{name}
						</div>
					))}
				</GraphLegend>
			</div>
		</div>
	);
};

type PortfolioPerformanceProps = CommonSetUpReportProps<UnionMapData["portfolio-details"]>;
const PortfolioPerformance = ({ data }: PrintableProps<{ data: PortfolioPerformanceProps }, number>) => {
	const { investmentPerformance } = data;
	const performanceSeries = useMemo(() => {
		if (
			investmentPerformance.current === undefined ||
			investmentPerformance.benchmark === undefined ||
			investmentPerformance.current.length === 0 ||
			investmentPerformance.benchmark?.length === 0
		) {
			return { portfolioPerformance: undefined, benchmarkPerformance: undefined };
		}
		return {
			portfolioPerformance: investmentPerformance.current.map(([dateSeconds, y]) => [dateSeconds * 1000, y]) as [
				number,
				number,
			][],
			benchmarkPerformance: investmentPerformance.benchmark.map(([dateSeconds, y]) => [dateSeconds * 1000, y]) as [
				number,
				number,
			][],
		};
	}, [investmentPerformance]);

	return performanceSeries.benchmarkPerformance && performanceSeries.portfolioPerformance ? (
		<Card title="Performance">
			<CustomizedChart performanceSeries={performanceSeries} />
		</Card>
	) : (
		<></>
	);
};

export default PortfolioPerformance;
