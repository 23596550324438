import InfiniteLoader from "$root/components/InfiniteLoader";
import PageLayout from "$root/components/PageLayout";
import { useUserValue } from "$root/functional-areas/user";
import { Suspense, useEffect, useLayoutEffect, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { MyAppBar } from "../AppBar";
import GlobalErrorBoundary from "../GlobalErrorBoundary";
import { extractPath, routesMap, typedPropsFromUrl, typedUrlForRoute } from "./RoutesDef";
import { useSystemValue } from "$root/functional-areas/system";
import { AppDrawer } from "../AppDrawer";
import { usePlatformLayoutOptionsState, usePlatformLayoutOptionsValue } from "./layout-options";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { maybeCall } from "@mdotm/mdotui/utils";
import { useEventListener } from "@mdotm/mdotui/react-extensions";

const PlatformRouter = (): JSX.Element => {
	return (
		<GlobalErrorBoundary>
			<PlatformRouterInner />
		</GlobalErrorBoundary>
	);
};

const PlatformRouterInner = (): JSX.Element => {
	const { systemMode } = useSystemValue();
	const history = useHistory();

	useEffect(() => {
		// MixPanel Page View => Need Refactor
		trackMixPanelEvent("Page View", {
			Page: location.pathname,
		});
	}, [history.location.pathname]);

	// Intercept regular <a href="...">...</a> when the origin is the same as the window
	useEventListener(window, "click", (e) => {
		if (!(e.target as HTMLElement | undefined)?.getAttribute("download")) {
			if ((e.target as HTMLElement | undefined)?.tagName?.toLowerCase() === "a") {
				if (!e.metaKey && !e.shiftKey && !e.altKey && !e.ctrlKey && e.button === 0) {
					const anchor = e.target as HTMLAnchorElement;
					if (anchor.origin === window.location.origin) {
						e.preventDefault();
						history.push(anchor.pathname + anchor.search + anchor.hash);
					}
				}
			}
		}
	});

	const platformLayoutOptions = usePlatformLayoutOptionsValue();
	const currentUser = useUserValue();

	const routes = useMemo(
		() =>
			systemMode === "MAINTENANCE" ? (
				<Route path="*">
					<PageWrapper routeKey="Maintenance" route={routesMap["Maintenance"]} />
				</Route>
			) : (
				Object.entries(routesMap).map(([key, route]) => {
					const routePath = route.baseUrl === "*" ? route.baseUrl : extractPath(route.baseUrl);
					return (
						<Route key={key} exact={route.baseUrl !== "*"} path={routePath}>
							<PageWrapper routeKey={key as keyof typeof routesMap} route={route} />
						</Route>
					);
				})
			),
		[systemMode],
	);

	return (
		<>
			{platformLayoutOptions.topBar && currentUser.signedIn && <MyAppBar key={currentUser.id + "topBar"} />}
			{platformLayoutOptions.sideBar && currentUser.signedIn && <AppDrawer key={currentUser.id + "sideBar"} />}
			<Switch>{routes}</Switch>
		</>
	);
};

function PageWrapper<K extends keyof typeof routesMap>({
	routeKey,
	route,
}: {
	routeKey: K;
	route: (typeof routesMap)[K];
}) {
	const currentUser = useUserValue();
	const { setPlatformLayoutOptions, platformLayoutOptions } = usePlatformLayoutOptionsState();
	useLayoutEffect(() => {
		setPlatformLayoutOptions(maybeCall(route.layoutOptions, { user: currentUser }));
	}, [currentUser, route.layoutOptions, setPlatformLayoutOptions]);
	const history = useHistory();
	const props = useMemo(() => {
		return typedPropsFromUrl(routeKey, history.location.pathname + history.location.search) ?? ({} as any);
	}, [history.location.pathname, history.location.search, routeKey]);

	const DynamicallyImportedPage = useMemo(() => {
		return route.component;
	}, [route]);

	if (route.guard) {
		const guardResult = route.guard({ user: currentUser });
		if (!guardResult.passed) {
			if (guardResult.reason === "requires-login") {
				return <Redirect to={typedUrlForRoute("Login", { from: window.location.pathname + window.location.search })} />;
			}
			return <Redirect to={typedUrlForRoute("Index", {})} />;
		}
	}
	return (
		<>
			<PageLayout
				hideAppBar={!platformLayoutOptions.topBar}
				hideSideBar={!platformLayoutOptions.sideBar}
				backgroundImage={platformLayoutOptions.backgroundImage}
				mode={platformLayoutOptions.mode}
			>
				<Suspense fallback={<InfiniteLoader />}>
					<DynamicallyImportedPage {...props} />
				</Suspense>
			</PageLayout>
		</>
	);
}

export default PlatformRouter;
