import { type HMMRegion } from "$root/widgets-architecture/contexts/region";
import type { DependencyList, Dispatch, ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";

type FunctionalAreasContextProps = {
	outlook?: {
		firstFilter?: string;
		secondFilter?: string;
		thirdFilter?: string;
	};
	outlookFocus?: {
		firstFilter?: string;
		secondFilter?: string;
		thirdFilter?: string;
	};
	hmm?: {
		region?: HMMRegion;
	};
	portfolio?: { uuid?: string };
	chat?: {
		appendUserMessageAndSend?: (message: string) => Promise<void>;
	};
};

const defaultContextValue: FunctionalAreasContextProps = {
	outlook: {},
	outlookFocus: {},
	hmm: {},
	portfolio: {},
	chat: {},
};

const FunctionalAreasContext = createContext<{
	state: FunctionalAreasContextProps;
	dispatch: Dispatch<{
		areas: keyof FunctionalAreasContextProps;
		data: FunctionalAreasContextProps[keyof FunctionalAreasContextProps];
	}>;
}>({ dispatch: () => null, state: defaultContextValue });

function reducer<TKey extends keyof FunctionalAreasContextProps>(
	state: FunctionalAreasContextProps,
	payload: { areas: TKey; data: FunctionalAreasContextProps[TKey] },
) {
	return { ...state, [payload.areas]: { ...payload.data } };
}

function FunctionalAreasProvider({ children }: { children: ReactNode }): JSX.Element {
	const [state, dispatch] = useReducer(reducer, defaultContextValue);
	const value = { state, dispatch };

	return <FunctionalAreasContext.Provider value={value}>{children}</FunctionalAreasContext.Provider>;
}

function useFunctionalAreas<TKey extends keyof FunctionalAreasContextProps>(
	optsProvider: () => { areas: TKey; data: FunctionalAreasContextProps[TKey] },
	deps: DependencyList,
): void {
	const { dispatch } = useContext(FunctionalAreasContext);
	useEffect(() => {
		dispatch(optsProvider());
		// FIXME
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, ...deps]);
}

export { FunctionalAreasContext, FunctionalAreasProvider, useFunctionalAreas };
