export function NewMarketChangeIcon(): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="136"
			height="136"
			fill="none"
			viewBox="0 0 136 136"
		>
			<path fill="url(#pattern0)" d="M0 0H136V136H0z" />
			<g clipPath="url(#clip0_157_23559)">
				<path fill="#fff" d="M0 0H120V120H0z" transform="translate(8 8)" />
				<path
					fill="#C3C9D5"
					d="M119 58.25l-11.565-11.566H94.871v-4.119L83.306 31H40.252v24.129H17V99h54.62v-8.445H119V58.249zm-10.858-7.447l6.739 6.739h-6.739v-6.74zm-24.13-15.684l6.74 6.74h-6.74v-6.74zm-41.348-1.706H81.6V44.27h10.858v28.187H71.62v-5.764L60.054 55.13h-17.39V33.413zm18.097 25.835l6.74 6.74h-6.74v-6.74zm-41.348 37.34V57.541h38.935V68.4h10.859v28.187H19.413zm52.206-8.446V74.87l23.252-.16V49.096h10.858v10.858h10.858v28.187H71.619zM118.909 105.702a1.164 1.164 0 00-.579-.595.927.927 0 00-.458-.107h-3.047c-.64 0-1.143.503-1.143 1.144 0 .641.503 1.144 1.143 1.144h.29l-4.861 4.865-3.764-3.767a1.123 1.123 0 00-1.615 0l-2.62 2.624-2.621-2.624a1.123 1.123 0 00-1.615 0l-5.684 5.613a1.143 1.143 0 00.016 1.632c.441.412 1.157.412 1.569.076l4.89-4.896 1.814 1.815-3.382 3.386a1.146 1.146 0 00-.336.809c0 .305.122.595.35.823.458.412 1.25.382 1.6-.03l3.383-3.386 6.415 6.421c.198.229.503.351.823.351.32 0 .609-.122.807-.336l7.619-7.626a1.143 1.143 0 000-1.617 1.123 1.123 0 00-1.615 0l-6.796 6.818-5.622-5.628 1.813-1.815 3.763 3.767a1.122 1.122 0 001.616 0l5.652-5.674v.29c0 .641.503 1.144 1.143 1.144s1.143-.503 1.143-1.144v-3.035c0-.168-.03-.32-.091-.442z"
				/>
				<path
					fill="#94D0C4"
					fillRule="evenodd"
					d="M46.785 44.158a.97.97 0 111.373-1.373l.532.533-1.372 1.373-.533-.533zm1.594 1.593l4.178 4.175a.25.25 0 00.177.074H54v-1.265a.248.248 0 00-.072-.176l-4.176-4.18-1.372 1.372zm-2.655-.533a2.47 2.47 0 113.495-3.493l5.769 5.774c.328.328.511.773.511 1.236v2.015a.75.75 0 01-.75.75h-2.015a1.75 1.75 0 01-1.237-.513l-5.773-5.769z"
					clipRule="evenodd"
				/>
				<path stroke="#C3C9D5" strokeLinecap="round" strokeWidth="2" d="M29 88h32M29 80h32M29 72h32" />
				<circle cx="45" cy="72" r="3" fill="#66CEF5" />
				<circle cx="37.5" cy="80" r="3" fill="#DD8596" />
				<circle cx="60" cy="88" r="3" fill="#71C1B1" />
				<path stroke="#C3C9D5" strokeLinecap="round" strokeWidth="2" d="M64 59h21M61 51h24" />
				<circle cx="69" cy="51" r="3" fill="#66CEF5" />
				<circle cx="84.5" cy="59" r="3" fill="#DD8596" />
			</g>
			<defs>
				<pattern id="pattern0" width="0.029" height="0.029" patternContentUnits="objectBoundingBox">
					<use transform="scale(.00735)" xlinkHref="#image0_157_23559" />
				</pattern>
				<clipPath id="clip0_157_23559">
					<path fill="#fff" d="M0 0H120V120H0z" transform="translate(8 8)" />
				</clipPath>
				<image
					id="image0_157_23559"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
