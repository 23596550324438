import type { ReactNode } from "react";
import { useContext, useEffect } from "react";
import { PortfolioStudioSettingsCtx } from "./context";

export function HeaderSubTitle({ children }: { children: ReactNode }): JSX.Element {
	const ctx = useContext(PortfolioStudioSettingsCtx);
	if (!ctx) {
		throw new Error("[HeaderActions] missing context");
	}

	useEffect(() => {
		ctx.setHeaderSubTitle(children);
		// return () => {
		// 	ctx.setHeaderSubTitle(null);
		// };
	}, [children, ctx]);

	return <></>;
}
