export function BetaIcon(): JSX.Element {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="16" fill="#EBEEF8" />
			<path
				d="M18.8137 13.5938C19.5899 12.7658 20.0298 11.6533 20.0298 10.5149C20.0298 8.03105 18.0116 6 15.5149 6H15.0233C12.8111 6 11 7.79819 11 10.0233V24.9651C11 25.5343 11.4657 26 12.0349 26C12.6041 26 13.0699 25.5343 13.0699 24.9651V22.4554C13.9884 23.1669 15.1138 23.542 16.2911 23.542C19.2147 23.542 21.5821 21.1617 21.5821 18.251C21.5821 16.2846 20.5213 14.5252 18.8008 13.5938H18.8137ZM16.2911 21.4722C14.5188 21.4722 13.0699 20.0233 13.0699 18.251V14.9392C13.1992 14.9909 13.3415 15.0168 13.4709 15.0168H16.2911C18.0634 15.0168 19.5123 16.4657 19.5123 18.238C19.5123 20.0104 18.0634 21.4592 16.2911 21.4592V21.4722ZM15.5278 12.9599H13.4709C13.3286 12.9599 13.1992 12.9858 13.0699 13.0375V10.0233C13.0699 8.94955 13.9366 8.08279 15.0103 8.08279H15.5019C16.8473 8.08279 17.947 9.18241 17.947 10.5278C17.947 11.8732 16.8603 12.9599 15.5278 12.9728V12.9599Z"
				fill="#6678CC"
			/>
		</svg>
	);
}
