import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTypeToLabel = () => {
	const { t } = useTranslation();
	const SEPARATOR = "_";

	const getLabelWithEquity = (type: string) => {
		const SPLITTED = type.split(SEPARATOR);
		// @ts-ignore
		const LABEL_TO_TYPE = t(`ASSET_CLASS_LABEL.${type}`, SPLITTED[1]);
		return `${LABEL_TO_TYPE}`;
	};

	const getLabel = (type: string) => {
		const SPLITTED = type.split(SEPARATOR);
		// @ts-ignore
		const LABEL_TO_TYPE = t(`ASSET_CLASS_LABEL.${type}`, SPLITTED[1]);
		return LABEL_TO_TYPE;
	};

	return { getLabelWithEquity, getLabel };
};

export default useTypeToLabel;
