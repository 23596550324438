import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { InstrumentDialog, type InstrumentDialogProps } from "./InstrumentDialog";

type SpawnInstrumentDialogProps = Omit<InstrumentDialogProps, "show" | "onClose">;
export function spawnInstrumentDialog(props: SpawnInstrumentDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<InstrumentDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(instruments) => {
					props.onSubmit(instruments);
					resolve();
				}}
			/>
		)),
	);
}
