import { maybeCall } from "@mdotm/mdotui/utils";
import type { Context, DependencyList, ForwardedRef, Key, ReactNode } from "react";
import { Fragment, memo, useMemo } from "react";
import { useRef, useEffect, useState, useCallback } from "react";
import fastDeepEqual from "fast-deep-equal/es6";
import type { Updater } from "./functions";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { propagateRef, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { mapArrayLike } from "./collections";
import { Range } from "immutable";

/**
 * A custom hook that can be used to determine if the component is still mounted.
 *
 * When using async functions, a component can get unmounted before an async handler
 * has finished its task, triggering
 * a warning like "Can't call setState (or forceUpdate) on an unmounted component".
 * Using this hook can prevent this, by making the handler aware of the current status
 * of the component.
 *
 * Example:
 * ```ts
 * function MyComponent() {
 * 	const isMountedRef = useMountedRef();
 * 	const [count, setCount] = useState(0);
 * 	async function submit() {
 * 		await something();
 * 		if (isMountedRef.current) {
 * 			setCount((c) => c + 1);
 * 		}
 * 	}
 * }
 * ```
 *
 * @returns a MutableRefObject containing true or false depending on whether the component is mounted or unmounted.
 */
export function useMountedRef(): { current: boolean } {
	const ref = useRef(true);
	useEffect(() => {
		ref.current = true;
		return () => {
			ref.current = false;
		};
	}, [ref]);
	return ref;
}

/**
 * A custom hook based on useRef that can be used to optimize re-renders.
 *
 * A possible use case is storing callbacks instead of using them directly in the dependencies arrays
 * of useCallbacks/useEffect/etc. is that they usually don't need to be called immediately, therefore
 * it's not necessary to trigger hooks when they change.
 *
 * Example:
 *
 * The following code will cause useCallback to regenerate the handleClick
 * function reference every time onClick changes, which could mean at every
 * re-render of the parent component if the parent is passing an inline function.
 * ```tsx
 * function MyComponent({onClick}) {
 * 	const handleClick = useCallback(() => {
 * 		console.log('clicked');
 * 		onClick();
 * 	}, [onClick]);
 *
 * 	return <button onClick={handleClick}>click me</button>
 * }
 * ```
 * The above code could be rewritten as follows:
 * ```tsx
 * function MyComponent({onClick}) {
 * 	const onClickRef = useUpdatedRef(onClick);
 * 	const handleClick = useCallback(() => {
 * 		console.log('clicked');
 * 		onClickRef.current();
 * 	}, [callbacksRef]);
 *
 * 	return <button onClick={handleClick}>click me</button>
 * }
 * ```
 * Note that in the second snipped the onClickRef object will not change, only its content will, therefore making useCallback
 * only run once, when MyComponent gets mounted.
 *
 * @param observedProps something that could change between re-renders (a function, a subset of props, a variable, etc.).
 * @returns a MutableRefObject having the latest values of observedProps in its `.current` property.
 */
export function useUpdatedRef<T>(observedProps: T): React.MutableRefObject<T> {
	const ref = useRef(observedProps);
	useEffect(() => {
		ref.current = observedProps;
	}, [observedProps]);

	return ref;
}

/**
 * Create a Component-factory for wrapping component inside a Context.Consumer.
 *
 * The original component will receive its props from the Context. Thus, the component
 * generated using withContext(<context>)(<component-with-props>) won't accept any prop.
 *
 *
 * Example:
 * ```tsx
 * const CustomH1: React.FC<{title: string}> = ({title}) => <h1>{title}</h1>;
 *
 * const TitleContext = createContext<{title: string}>({title: ''});
 *
 * const CustomH1WithContext = withContext(TitleContext)(CustomH1);
 * ```
 *
 * @param Ctx the context to consume.
 * @returns a function that takes a component whose props are compatible with the context.
 */
export function withContext<
	TContent extends Record<string, unknown>,
	TExtraProps extends Record<string, unknown> = Record<string, unknown>,
>(Ctx: Context<TContent>): (Component: React.FC<TContent & TExtraProps>) => React.FC<TExtraProps> {
	return (Component) =>
		function Inner(extraProps) {
			return (
				<Ctx.Consumer>
					{function InnerInner(props) {
						return <Component {...{ ...props, ...extraProps }} />;
					}}
				</Ctx.Consumer>
			);
		};
}

type MergeTwo<A, B> = {
	[KA in keyof A]: A[KA];
} & {
	[KB in keyof B]: B[KB];
};

type Merge<Others extends [...unknown[]]> = Others extends [infer First, ...infer Rest]
	? MergeTwo<First, Merge<Rest>>
	: Others;

function MultipleContextConsumerHelper<
	TContents extends [] | [Record<string, unknown>, ...Record<string, unknown>[]],
	K extends number = 0,
>({
	previousContextsContents,
	Contexts,
	index,
	Component,
}: {
	Contexts: {
		[KInner in keyof TContents]: Context<TContents[KInner]>;
	} & Array<unknown>;
	previousContextsContents: Partial<Merge<TContents>>;
	index: K;
	Component: React.FC<Merge<TContents>>;
}) {
	const CurrentContext = index < Contexts.length ? Contexts[index] : undefined;
	return !CurrentContext ? (
		<Component {...(previousContextsContents as any)} />
	) : (
		<CurrentContext.Consumer>
			{(currentContextContent: TContents[K]) => (
				<MultipleContextConsumerHelper
					Component={Component}
					index={index + 1}
					previousContextsContents={{ ...previousContextsContents, ...currentContextContent }}
					Contexts={Contexts}
				/>
			)}
		</CurrentContext.Consumer>
	);
}

/**
 * Create a Component-factory for wrapping component inside multiple Context.Consumers.
 *
 * The original component will receive its props from the Contexts. Thus, the component
 * generated using withContexts([<context1>, <context2>, ...])(<component-with-props>) won't accept any prop.
 *
 *
 * Example:
 * ```tsx
 * const MyCard: React.FC<{title: string; content: string}> = ({title, content}) => (
 * 	<div>
 * 		<h1>{title}</h1>
 * 		<div>{content}</div>
 * 	</div>
 * );
 *
 * const TitleContext = createContext<{title: string}>({title: ''});
 * const ContentContext = createContext<{content: string}>({content: ''});
 *
 * const MyCardWithContexts = withContexts([TitleContext, ContentContext])(MyCard);
 * ```
 *
 * @param Contexts the contexts to consume.
 * @returns a function that takes a component whose props are compatible with the context.
 */
export function withContexts<TContents extends [] | [Record<string, unknown>, ...Record<string, unknown>[]]>(
	Contexts: {
		[K in keyof TContents]: Context<TContents[K]>;
	} & Array<unknown>,
): (Component: React.FC<Merge<TContents>>) => React.FC {
	return (Component) =>
		function Inner() {
			return (
				<MultipleContextConsumerHelper
					Component={Component}
					Contexts={Contexts}
					index={0}
					previousContextsContents={{}}
				/>
			);
		};
}

export type ContextContent<T> = T extends Context<infer P> ? P : never;

/** The possible states of the useDebouncedMemo hook. */
export type UseDebouncedMemoState = "idle" | "debouncing";

/**
 * Like useMemo, but debounced to reduce computations.
 *
 * A common use case for this hook is a scenario where there is a list of elements and a text filter. The filter
 * can be debounced to avoid recomputing the search results on every keystroke.
 *
 * @param fn a function that produces the value and will be called using a debounce strategy.
 * @param deps an array of dependencies. When these change, `fn` will be scheduled to be called within the debounce interval.
 * @param opts additional configuration options, such as the debounce interval (which defaults to 100ms).
 * @returns an object containing the value produced by the last call to `fn`, a function to forcibly update the cached value and the state that indicates
 * whether the hook is currently debouncing or idle.
 */
export function useDebouncedMemo<TOut>(
	fn: () => TOut,
	deps: DependencyList,
	opts?: { debounceInterval?: number },
): { value: TOut; forceUpdate: () => void; state: UseDebouncedMemoState } {
	const debounceInterval = opts?.debounceInterval ?? 100;
	const [value, setValue] = useState(() => fn());
	const [state, setState] = useState<UseDebouncedMemoState>("idle");
	const first = useRef(true);
	const ctx = useUpdatedRef({ fn, debounceInterval });
	const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	useEffect(() => {
		if (first.current) {
			first.current = false;
			return;
		}
		timeoutIdRef.current = setTimeout(() => {
			timeoutIdRef.current = null;
			setValue(ctx.current.fn());
			setState("idle");
		}, ctx.current.debounceInterval);
		setState("debouncing");
		return () => {
			if (timeoutIdRef.current !== null) {
				clearTimeout(timeoutIdRef.current);
				timeoutIdRef.current = null;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	const forceUpdate = useCallback(() => {
		if (timeoutIdRef.current !== null) {
			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = null;
		}
		setValue(ctx.current.fn());
		setState("idle");
	}, [ctx]);

	return { value, forceUpdate, state };
}

/**
 * Utility function created to wrap components that use generics
 */
export const genericMemo: <T>(component: T) => T = memo;

export type SwitchProps<K extends string, Match extends Record<K, () => ReactNode>> = {
	case: K;
	match: Match;
};

export function Switch<K extends string, Match extends Record<K, () => ReactNode>>({
	case: caseKey,
	match,
}: SwitchProps<K, Match>): JSX.Element {
	return <Fragment key={caseKey}>{match[caseKey]()}</Fragment>;
}

export type ImperativeHandlesRef<T> = {
	current: T | null;
};

export type ImperativeHandlesRefProps<T> = {
	handlesRef?: ImperativeHandlesRef<T>;
};

export function useImperativeHandlesRef<HandlesRef extends ImperativeHandlesRef<any>>(
	handlesRef: HandlesRef | null | undefined,
	handles: HandlesRef extends ImperativeHandlesRef<infer Handles> ? Handles : never,
): void {
	useEffect(() => {
		if (handlesRef) {
			handlesRef.current = handles;
		}
	}, [handles, handlesRef]);
	const handlesRefRef = useUpdatedRef(handlesRef);
	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			const latestHandlesRef = handlesRefRef.current;
			if (latestHandlesRef) {
				latestHandlesRef.current = null;
			}
		};
	}, [handlesRefRef]);
}

export function useDeepEqualState<T>(valueOrInitializer: T | (() => T)): [T, (valueOrUpdater: T | Updater<T>) => void] {
	const [value, _setValue] = useState(valueOrInitializer);
	const setValue = useCallback<typeof _setValue>(
		(params) => {
			_setValue((cur) => {
				const next = maybeCall(params, cur);
				if (fastDeepEqual(next, cur)) {
					return cur;
				}
				return next;
			});
		},
		[_setValue],
	);
	return useMemo(() => [value, setValue], [setValue, value]);
}

export function ForEach<TArr extends ArrayLike<any>>({
	collection,
	keyProvider,
	children,
	fallback,
}: {
	collection: TArr;
	keyProvider?(item: TArr[number], index: number): Key;
	children: NodeOrFn<{ item: TArr[number]; index: number }>;
	fallback?: NodeOrFn<{ collection: TArr }>;
}): JSX.Element {
	return (
		<>
			{collection.length === 0 && fallback
				? fallback
				: mapArrayLike(collection, (item, index) => (
						<Fragment key={keyProvider?.(item, index) ?? index}>{renderNodeOrFn(children, { item, index })}</Fragment>
				  ))}
		</>
	);
}

export function For({
	times,
	keyProvider,
	children,
}: {
	times: number;
	keyProvider?(index: number): Key;
	children: NodeOrFn<{ index: number }>;
}): JSX.Element {
	return (
		<>
			{Range(0, times).map((index) => (
				<Fragment key={keyProvider?.(index) ?? index}>{renderNodeOrFn(children, { index })}</Fragment>
			))}
		</>
	);
}

export function multiRef<T>(...refs: ForwardedRef<T>[]): (el: T | null) => void {
	return (el) => {
		for (let i = 0; i < refs.length; i++) {
			propagateRef(el, refs[i]);
		}
	};
}
