import { useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { useEffect } from "react";

/**
 * Utility hook used to synchronize local state of a step with the outer state of the entire stepper when the outer one changes
 * and to synchronize the "dirty" state of the step with the stepper.
 */
export function useStepSync<T>({
	stepData,
	reset,
	toggleDirty,
	isDirty,
}: {
	stepData: T;
	reset: (outerData: T) => void;
	toggleDirty: (dirty: boolean) => void;
	isDirty: boolean;
}): void {
	const refs = useUpdatedRef({ reset, toggleDirty });
	useEffect(() => {
		refs.current.reset(stepData);
	}, [stepData, refs]);

	useEffect(() => {
		refs.current.toggleDirty(isDirty);
	}, [isDirty, refs]);
}
