import { useLocaleFormatters } from "$root/localization/hooks";
import { Card } from "../Card";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";

type ExAnteMetricsProps = CommonSetUpReportProps<
	UnionMapData["portfolio-details"] | UnionMapData["portfolio-enhanced"]
>;

type Column = {
	benchmark: number;
	current: number;
	proposal: number;
	label: string;
	name: string;
	visible: boolean;
};

const ExAnteMetrics = ({ data }: PrintableProps<{ data: ExAnteMetricsProps }, Column>): JSX.Element => {
	const { responseType, exAnteMetrics } = data;
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const { portfolioExAnteMetrics } = exAnteMetrics;

	const rows =
		portfolioExAnteMetrics?.flatMap((metric) =>
			metric.type
				? [
						{
							label: t(`EXANTE_METRICS.METRICS.${metric.type}`),
							name: metric.type,
							visible: true,
							current: metric.current ?? 0,
							benchmark: metric.benchmark ?? 0,
							proposal: metric.proposal ?? 0,
						},
				  ]
				: [],
		) ?? [];

	const columns = [
		{
			relativeWidth: 2.0,
			header: t("METRICS"),
			content: ({ label }) => label,
		},
		{
			relativeWidth: 1.0,
			cellClassList: "tabular-nums",
			header: "Current",
			align: "end",
			content: ({ current, label }) => `${formatNumber(current)}${label.toLowerCase().includes("ratio") ? "" : "%"}`,
		},
		{
			relativeWidth: 1.0,
			cellClassList: "tabular-nums",
			header: "Benchmark",
			align: "end",
			content: ({ benchmark, label }) =>
				`${formatNumber(benchmark)}${label.toLowerCase().includes("ratio") ? "" : "%"}`,
		},

		{
			relativeWidth: 1.0,
			cellClassList: "tabular-nums",
			header: "Proposal",
			align: "end",
			content: ({ label, proposal }) => `${formatNumber(proposal)}${label.toLowerCase().includes("ratio") ? "" : "%"}`,
			hidden: responseType === "details",
		},
	] satisfies TableColumn<Column>[];

	return (
		<Card title="Ex-ante portfolio metrics">
			<Table columns={columns} rows={rows} noDataText="No metrics" />
		</Card>
	);
};

export default ExAnteMetrics;
