import type { AccessPermission, RichAccessControl } from "$root/api/api-gen";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray, stableEmptyArray } from "@mdotm/mdotui/utils";
import { useUserValue } from "../../user";

export function validateACLPermissions(
	userId: string,
	acl: RichAccessControl[],
	requiredPermission: MaybeArray<AccessPermission>,
): boolean {
	const requiredPermissions = alwaysArray(requiredPermission);
	const allPermissions = acl.flatMap((entry) => (entry.userId === userId ? entry.permissions : stableEmptyArray));
	return requiredPermissions.every((required) => allPermissions.includes(required));
}

export type ACLPermissionChecker = (userId: string, acl: RichAccessControl[]) => boolean;

export function useACLPermissionChecker(baseChecker: ACLPermissionChecker, acl: RichAccessControl[]): boolean {
	const user = useUserValue();
	return baseChecker(user.id, acl);
}
