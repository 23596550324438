import type { ExposureContributionRequestExposureContributionTypeEnum } from "$root/api/api-gen";

export const exposureCompareCategoryInfo: Record<ExposureContributionRequestExposureContributionTypeEnum, string> = {
	MACRO_ASSET_CLASS: "Macro Asset Class",
	MICRO_ASSET_CLASS: "Micro Asset Class",
	GEOGRAPHY: "Micro Geography",
	MACRO_GEOGRAPHY: "Macro Geography",
	CURRENCY: "Currency",
	TAG: "Tag",
};

const exposureCompareCategoriesToShow = [
	"MACRO_ASSET_CLASS",
	"MACRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<ExposureContributionRequestExposureContributionTypeEnum>;

export const exposureCompareOptions = exposureCompareCategoriesToShow.map((category) => ({
	label: exposureCompareCategoryInfo[category],
	value: category,
}));
