// TODO: move all business logic to functional-areas/reports

import type { ReportTemplate as ReportTemplateV1 } from "./version/report-v1";
import { migrateV1ToV2, type ReportTemplate as ReportTemplateLatest } from "./version/report-v2";

export * from "./version/report-v2";

const migrationFns: Record<number, (reportTemplate: any) => any> = {
	2: migrateV1ToV2,
};
export const latestVersion = Number(Object.keys(migrationFns).at(-1)!);
export type ReportTemplateUnion = ReportTemplateV1 | ReportTemplateLatest;
export function migrateToLatest(reportTemplate: ReportTemplateUnion): ReportTemplateLatest {
	let currentReportTemplate = reportTemplate;
	for (let v = reportTemplate.data.version + 1; v <= latestVersion; v++) {
		currentReportTemplate = migrationFns[v](currentReportTemplate);
	}

	return currentReportTemplate as ReportTemplateLatest;
}
