import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { StylableProps } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Checkbox,
	ComputedSizeContainer,
	Icon,
	ScrollWrapper,
	Select,
	Text,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactQueryWrapperBase } from "../ReactQueryWrapper";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { ForEach, toClassName, useDrivenState } from "@mdotm/mdotui/react-extensions";
import { WorldMapSvg } from "./WorldMapSvg";
import { mapBetweenRanges, type Vec2D } from "@mdotm/mdotui/utils";
import { useLocaleFormatters } from "$root/localization/hooks";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import HighchartsReact from "highcharts-react-official";
import { linearGradient, type Gradient } from "$root/utils/colors";
import { maxArrayLike, minArrayLike, sumArrayLike } from "$root/utils/collections";
import Color from "color";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { dateYYYY_W, weekRange } from "$root/utils/dates";
import { Range } from "immutable";
import { DateTime } from "luxon";
import type { AssetClassIdentifierEnum } from "$root/api/api-gen";
import { createPortal } from "react-dom";
import type { MaybePromise } from "@mdotm/mdotui/headless";

export type TempFixAssetClassIdentifierEnum = AssetClassIdentifierEnum | "FIXED_INCOME_JAPAN"; // TODO: this doesn't exist in the BE enum!!
export type ValueOnMap = "probability-distance" | "value-at-risk" | "volatility";
export type Level = {
	name: string;
	weight: number;
	riskLevel: number;
};

export type WorldGridMapSnapshot = {
	time: string;
	snapshot: Array<{
		firstLevel: Level & {
			secondLevels: Array<
				Level & {
					thirdLevels?: Array<
						Level & {
							fourthLevels?: Array<Level>;
						}
					>;
				}
			>;
		};
	}>;
};


export type WorldGridProps = { gradient?: Gradient; dataProvider(): MaybePromise<Array<WorldGridMapSnapshot>> };

export function WorldGrid({ gradient = defaultWorldGridGradient, dataProvider }: WorldGridProps): JSX.Element {
	useWidgetOptions(
		() => ({
			title: "Global risk map",
			alertsActive: false,
			actionHeader: <></>,
		}),
		[],
	);

	const [valueOnMap, setValueOnMap] = useState<ValueOnMap | null>("probability-distance");
	const query = useQueryNoRefetch({
		queryFn: () => dataProvider(),
		queryKey: ["globalRiskMap", valueOnMap],
	});

	const [showSectorOrDuration, setShowSectorOrDuration] = useState(true);
	const [selectedSnapshotTime, setSelectedSnapshotTime] = useState<string | null>(null);
	const selectedSnapshot = useMemo(
		() => query.data?.find((x) => x.time === selectedSnapshotTime)?.snapshot,
		[query.data, selectedSnapshotTime],
	);

	useEffect(() => {
		if (!selectedSnapshotTime || !query.data?.some((x) => x.time === selectedSnapshotTime)) {
			const newSnapshotTime = query.data?.at(-1)?.time ?? null;
			setSelectedSnapshotTime(newSnapshotTime);
		}
	}, [query.data, selectedSnapshotTime]);

	return (
		<div className="flex flex-col">
			<div className="ml-auto">
				<Checkbox switchType="switch" checked={showSectorOrDuration} onChange={setShowSectorOrDuration}>
					Show sector/Duration
				</Checkbox>
			</div>
			<div className="flex flex-1 border-b" style={{ borderColor: themeCSSVars.palette_N100 }}>
				{/* <div className="flex flex-col min-w-[160px] pt-8"> */}
				{/* <FormField label="Value on map" classList="mb-4">
						{({ id }) => <Select id={id} value={valueOnMap} onChange={setValueOnMap} options={valueOnMapOptions} />}
					</FormField>
					<FormField label="Time granularity">
						{({ id }) => (
							<Select id={id} value={timeGranularity} onChange={setTimeGranularity} options={timeGranularityOptions} />
						)}
					</FormField> */}
				{/* <div className="mt-4">
						<Checkbox switchType="switch" checked={showSectorOrDuration} onChange={setShowSectorOrDuration}>
							Show sector/Duration
						</Checkbox>
					</div> */}
				{/* </div> */}
				<div className="p-2 flex-1">
					<ComputedSizeContainer>
						{({ width }) => (
							<div className="relative z-0">
								<div className="relative z-0">
									<WorldMapSvg classList="w-full h-auto" />
								</div>
								<ReactQueryWrapperBase query={query}>
									{() => {
										if (!selectedSnapshot) {
											return <></>;
										}

										const min = minArrayLike(selectedSnapshot, (x) =>
											minArrayLike(x.firstLevel.secondLevels, (y) => y.weight),
										);
										const max = maxArrayLike(selectedSnapshot, (x) =>
											maxArrayLike(x.firstLevel.secondLevels, (y) => y.weight),
										);
										return (
											<ForEach collection={selectedSnapshot}>
												{({ item: { firstLevel } }) =>
													firstLevelToCoords[firstLevel.name] && (
														<ForEach collection={firstLevel.secondLevels}>
															{({ item }) => {
																if (!firstLevelToCoords[firstLevel.name]![item.name]) {
																	console.log(
																		`firstLevelToCoords is missing a definition for ${firstLevel.name}.${item.name}`,
																	);
																	return null;
																}
																return (
																	<BoxWithTitle
																		subLevels={item.thirdLevels}
																		gradient={gradient}
																		riskLevel={item.riskLevel}
																		name={item.name}
																		classList="absolute z-10 hover:z-20 shadow"
																		style={{
																			left: `${firstLevelToCoords[firstLevel.name]![item.name]!.x * 100}%`,
																			top: `${firstLevelToCoords[firstLevel.name]![item.name]!.y * 100}%`,
																			transformOrigin: "top left",
																			transform: `scale(${mapBetweenRanges(width, 0, 1280, 0, 1)})`,
																		}}
																	>
																		<div
																			className="px-1 pb-1 flex flex-row gap-[2px]"
																			style={{
																				width: mapBetweenRanges(item.weight, min, max, 180, 392),
																				height: 190,
																			}}
																		>
																			{item.name.startsWith("FIXED_INCOME") &&
																			item.thirdLevels?.some((x) => x.fourthLevels?.length) ? (
																				!showSectorOrDuration ? (
																					<Treemap levels={item.thirdLevels ?? []} gradient={gradient} />
																				) : (
																					(() => {
																						const sum = sumArrayLike(item.thirdLevels ?? [], (x) => x.weight);
																						return (
																							<ForEach collection={item.thirdLevels ?? []}>
																								{({ item: thirdLevel }) => {
																									return (
																										<BoxWithTitle
																											riskLevel={thirdLevel.riskLevel}
																											name={thirdLevel.name}
																											gradient={gradient}
																											style={{
																												width: `${mapBetweenRanges(
																													thirdLevel.weight,
																													0,
																													sum,
																													0,
																													100,
																												)}%`,
																											}}
																										>
																											<div
																												className="px-1 pb-1 min-h-0"
																												style={{
																													height: 165,
																												}}
																											>
																												<Treemap
																													levels={thirdLevel.fourthLevels ?? []}
																													gradient={gradient}
																												/>
																											</div>
																										</BoxWithTitle>
																									);
																								}}
																							</ForEach>
																						);
																					})()
																				)
																			) : (
																				showSectorOrDuration && (
																					<Treemap
																						levels={
																							item.thirdLevels?.concat(
																								item.thirdLevels?.flatMap((x) => x.fourthLevels ?? []),
																							) ?? []
																						}
																						gradient={gradient}
																					/>
																				)
																			)}
																		</div>
																	</BoxWithTitle>
																);
															}}
														</ForEach>
													)
												}
											</ForEach>
										);
									}}
								</ReactQueryWrapperBase>
							</div>
						)}
					</ComputedSizeContainer>
				</div>
			</div>
			<ReactQueryWrapperBase query={query}>
				{(data) => (
					<WeekSelector
						min={(data.at(0)?.time && dateYYYY_W(data.at(0)!.time)) || dateYYYY_W(new Date())}
						max={(data.at(-1)?.time && dateYYYY_W(data.at(-1)!.time)) || dateYYYY_W(new Date())}
						value={
							(selectedSnapshotTime && dateYYYY_W(selectedSnapshotTime)) || dateYYYY_W(data.at(-1)?.time ?? new Date())
						}
						selectableWeeks={data.map((x) => dateYYYY_W(x.time))}
						onChange={(week) => setSelectedSnapshotTime(data?.find((x) => dateYYYY_W(x.time) === week)?.time ?? null)}
					/>
				)}
			</ReactQueryWrapperBase>
			{/* gradient test <div>
				<For times={gradient2.length - 1}>
					{({ index }) => (
						<>
							<div
								className="h-2 border"
								style={{
									backgroundColor: gradient2[index].color,
								}}
							/>
							<For times={100}>
								{({ index: subIndex }) => (
									<div
										className="h-2"
										style={{
											backgroundColor: linearGradient(
												gradient2[index].offset +
													(subIndex / 99) * ((gradient2[index + 1]?.offset ?? 1) - gradient2[index].offset),
												gradient2,
											),
										}}
									/>
								)}
							</For>
						</>
					)}
				</For>
			</div> */}
		</div>
	);
}

const valueOnMapOptions = [
	{
		label: "Probability distance",
		value: "probability-distance",
	} as const,
	{
		label: "Value at risk",
		value: "value-at-risk",
	} as const,
	{
		label: "Volatility",
		value: "volatility",
	} as const,
];

// x,y are percentages on the left-to-right and top-to-bottom axis
const firstLevelToCoords: Partial<Record<string, Partial<Record<string, Vec2D>>>> = {
	USA: {
		EQUITY_USA: { x: 0.03, y: 0.2 },
		FIXED_INCOME_USA_AND_CANADA: { x: 0.03, y: 0.2 + 0.32 },
	},
	EUROPE: {
		EQUITY_EUROPE: { x: 0.4, y: 0.05 },
		FIXED_INCOME_EUROPE: { x: 0.4, y: 0.05 + 0.32 },
	},
	PACIFIC_EX_JAPAN: {
		EQUITY_PACIFIC_EX_JAPAN: { x: 0.65, y: 0.01 },
	},
	JAPAN: {
		EQUITY_JAPAN: { x: 0.85, y: 0.14 },
		FIXED_INCOME_JAPAN: { x: 0.8, y: 0.14 + 0.32 },
	},
	EMERGING_MARKETS: {
		EQUITY_EMERGING_MARKETS: { x: 0.68, y: 0.4 },
		FIXED_INCOME_EMERGING_MARKETS: { x: 0.68, y: 0.4 + 0.32 },
	},
} satisfies Partial<Record<TempFixAssetClassIdentifierEnum, Partial<Record<TempFixAssetClassIdentifierEnum, Vec2D>>>>;

function WeekSelector(props: {
	selectableWeeks?: string[];
	min: string;
	max: string;
	value: string;
	onChange(v: string): void;
}): ReactNode {
	const options = useMemo(
		() =>
			Range(DateTime.fromISO(props.min).weekYear, DateTime.fromISO(props.max).weekYear + 1)
				.map((year) => ({
					label: year.toString(),
					value: year,
				}))
				.toArray(),
		[props.max, props.min],
	);

	const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		const scrollEl = scrollWrapperRef.current;
		if (scrollEl) {
			const scrollBehaviorSnapshot = scrollEl.style.scrollBehavior;
			scrollEl.style.scrollBehavior = "";
			scrollEl.scrollLeft = scrollEl.querySelector<HTMLElement>('[data-selected="true"]')?.offsetLeft ?? 0;
			scrollEl.style.scrollBehavior = scrollBehaviorSnapshot;
		}
	}, []);

	const [selectedYear, setSelectedYear] = useDrivenState(DateTime.fromISO(props.value).weekYear);
	const weeks = useMemo(() => weekRange(`${selectedYear}-W01`, `${selectedYear + 1}-W01`), [selectedYear]);
	return (
		<div className="flex items-center gap-2" style={{ backgroundColor: themeCSSVars.palette_N50 }}>
			<div className="flex-0 pl-2">
				<Select value={selectedYear} options={options} onChange={setSelectedYear} />
			</div>
			<div className="flex-1 min-w-0">
				<ScrollWrapper innerRef={scrollWrapperRef} direction="horizontal" classList="py-2 pr-2">
					{(() => {
						let prevMonth: number | null = null;
						return (
							<div className="flex flex-row bg-white rounded py-0.5">
								<ForEach collection={weeks}>
									{({ item, index }) => {
										const date = DateTime.fromISO(item);
										const curMonth = date.month;
										const printMonth = prevMonth !== curMonth && date.year === selectedYear;
										prevMonth = curMonth;
										return (
											<div className="relative z-0">
												{printMonth && index > 0 && (
													<div
														className="absolute left-0 inset-y-1 border-l border-dashed"
														style={{
															borderColor: themeCSSVars.palette_N200,
														}}
													/>
												)}
												<div className="mb-1 overflow-visible w-0 min-w-0 pl-2">
													<Text color={themeCSSVars.palette_N400} type="Body/S/Book">
														{printMonth ? monthNameOffset1[curMonth as keyof typeof monthNameOffset1] : <>&nbsp;</>}
													</Text>
												</div>
												<button
													data-selected={props.value === item}
													type="button"
													onClick={() => props.onChange(item)}
													className={`w-[32px] mx-1 py-0.5 rounded text-center disabled:text-[color:${themeCSSVars.palette_N400}] data-[selected="true"]:bg-[${themeCSSVars.palette_P400}] data-[selected="true"]:text-white`}
													disabled={!props.selectableWeeks || !props.selectableWeeks.includes(item)}
												>
													<Text color="inherit" type="Body/M/Bold">
														W{DateTime.fromISO(item).weekNumber.toString().padStart(2, "0")}
													</Text>
												</button>
											</div>
										);
									}}
								</ForEach>
							</div>
						);
					})()}
				</ScrollWrapper>
			</div>
		</div>
	);
}

// function Dot({ fill }: { fill: string }) {
// 	return (
// 		<Svg
// 			classList="inline-block mr-1.5 min-w-[8px] mb-1"
// 			viewBox={{
// 				width: 8,
// 				height: 8,
// 			}}
// 			fill="none"
// 		>
// 			<circle r="4" cx="4" cy="4" fill={fill} />
// 		</Svg>
// 	);
// }

function Treemap({ levels, gradient }: { levels: Array<Level>; gradient: Gradient }): JSX.Element {
	const { formatNumber } = useLocaleFormatters();

	const data = useMemo(() => {
		return levels.map((y) => {
			return {
				name: assetClassShortForm[y.name] ?? y.name,
				longLabel: assetClassLongForm[y.name] ?? y.name,
				value: y.weight,
				riskLevel: y.riskLevel,
				color: linearGradient(mapBetweenRanges(y.riskLevel, -100, 100, 0, 1), gradient),
			};
		});
	}, [gradient, levels]);

	const customTooltipRef = useRef<HTMLDivElement | null>(null);

	const options: Highcharts.Options = useMemo(
		() => ({
			chart: {
				type: "treemap",
				reflow: true,
				margin: 0,
				style: {
					fontFamily: "Gotham,sans-serif",
				},
			},
			plotOptions: {
				treemap: {
					animation: {
						duration: 0,
					},
				},
			},
			legend: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			title: { text: undefined },
			subtitle: { text: undefined },
			exporting: { enabled: false },
			tooltip: { enabled: false },
			// tooltip: {
			// 	useHTML: true,
			// 	formatter(this) {
			// 		return `<div class="whitespace-nowrap">${this.point.name}\n(${formatNumber(
			// 			(this.point as any as { riskLevel: number }).riskLevel,
			// 		)}%)</div>`;
			// 	},
			// },
			series: [
				{
					point: {
						events: {
							mouseOver: (e) => {
								if (!customTooltipRef.current) {
									return;
								}
								const element = (e.target as any).graphic.element as SVGRect & HTMLElement;
								const box = element.getBoundingClientRect();
								customTooltipRef.current.style.top = `${box.top + box.height / 2}px`;
								if (box.left > window.innerWidth / 2) {
									customTooltipRef.current.style.left = "auto";
									customTooltipRef.current.style.right = `${window.innerWidth - box.left}px`;
								} else {
									customTooltipRef.current.style.left = `${box.left + box.width}px`;
									customTooltipRef.current.style.right = "auto";
								}
								customTooltipRef.current.style.display = "block";
								customTooltipRef.current.textContent = `${(e.target as any).longLabel} (${formatNumber(
									(e.target as any as { riskLevel: number }).riskLevel,
								)}%)`;
							},
							mouseOut: () => {
								if (!customTooltipRef.current) {
									return;
								}
								customTooltipRef.current.style.display = "none";
							},
						},
					},
					dataLabels: {
						style: {
							textOutline: "none",
							textOverflow: "ellipsis",
						},
						useHTML: true,
						formatter(this, cell) {
							const width = cell.style?.width ?? 0;
							if (width < 40) {
								return "";
							}
							return `<div style="width: ${width}px" class="flex flex-col items-center"><span class="whitespace-nowrap">${
								this.point.name
							}</span><span class="whitespace-nowrap">${formatNumber(
								(this.point as any as { riskLevel: number }).riskLevel,
							)}%</span></div>`;
						},
					},
					name: "Regions",
					type: "treemap",
					levels: [
						{
							layoutAlgorithm: "squarified",
							level: 1,
							// dataLabels: {
							// 	enabled: true,
							// 	formatter() {
							// 		const temp = this as any;

							// 		if (!temp.point.value || temp.point.value < 5 || temp.point.node.children.length > 0) {
							// 			return "";
							// 		}

							// 		return `${temp.point.name}\n(${formatNumber(temp.point.riskLevel)}%)`;
							// 	},
							// },
							borderWidth: 1,
							borderColor: "white",
						},
					],
					accessibility: {
						exposeAsGroupOnly: true,
					},
					data,
				},
			],
		}),
		[data, formatNumber],
	);

	if (data.length === 0) {
		return <></>;
	}

	return (
		<>
			{createPortal(
				<div
					ref={customTooltipRef}
					className="fixed pointer-events-none shadow border -translate-y-1/2 p-2 bg-white rounded mx-1"
				/>,
				document.body,
			)}
			<HighchartsReact
				containerProps={{ style: { height: "100%", width: "100%" } }}
				highcharts={Highcharts}
				constructorType="chart"
				options={options}
			/>
		</>
	);
}

// top-to-bottom = low-to-high risk
export const defaultWorldGridGradient: Gradient = [
	{ offset: 0, color: "#AF1D25" },
	{ offset: 0.1, color: "#E81E25" },
	{ offset: 0.25, color: "#F87C7F" },
	{ offset: 0.43, color: "#FFA5A7" },
	{ offset: 0.5, color: "#FDE59B" },
	{ offset: 0.57, color: "#D0EAE5" },
	{ offset: 0.75, color: "#7FC7B8" },
	{ offset: 0.9, color: "#4CB09C" },
	{ offset: 1, color: "#35786B" },
];

function BoxWithTitle(
	props: StylableProps & {
		name: string;
		subLevels?: Array<Level>;
		riskLevel: number;
		children: ReactNode;
		gradient: Gradient;
	},
) {
	const { formatNumber } = useLocaleFormatters();

	const backgroundColor = linearGradient(mapBetweenRanges(props.riskLevel, -100, 100, 0, 1), props.gradient);
	const foregroundColor = Color(Color("#ffffff")).contrast(Color(backgroundColor)) > 3 ? "#ffffff" : "#000000";

	return (
		<div className={toClassName(props.classList)} style={{ ...props.style, backgroundColor }}>
			<div className="flex justify-between">
				<AutoTooltip
					disabled={!props.subLevels}
					overrideColor="white"
					trigger={({ innerRef }) => (
						<div
							ref={innerRef}
							className="px-2 pt-0.5 flex items-center gap-2"
							title={assetClassLongForm[props.name] ?? props.name}
						>
							<Text type="Body/S/Bold" classList="text-left truncate min-w-0" color={foregroundColor}>
								{assetClassShortForm[props.name] ?? props.name}
							</Text>
							{props.subLevels && <Icon icon="menu" />}
						</div>
					)}
				>
					<TooltipContent>
						{props.subLevels && (
							<div className="w-[226px]">
								<Text
									as="div"
									type="Body/L/Bold"
									classList="py-1 text-center mb-2 rounded"
									style={{
										backgroundColor: themeCSSVars.palette_N50,
									}}
								>
									{assetClassLongForm[props.name] ?? props.name}
								</Text>
								<div className="space-y-1">
									<ForEach collection={props.subLevels}>
										{({ item }) => (
											<div className="flex flex-row items-center">
												<Text as="div" type="Body/M/Book" classList="grow min-w-0 truncate">
													{assetClassLongForm[item.name] ?? item.name}
												</Text>
												<Text as="div" type="Body/M/Bold" classList="">
													{formatNumber(item.riskLevel)}%
												</Text>
											</div>
										)}
									</ForEach>
								</div>
							</div>
						)}
					</TooltipContent>
				</AutoTooltip>
				<div className="text-left truncate px-2 pt-0.5" title={`${formatNumber(props.riskLevel)}%`}>
					<Text type="Body/S/Bold" color={foregroundColor}>
						{formatNumber(props.riskLevel)}%
					</Text>
				</div>
			</div>
			{props.children}
		</div>
	);
}

const monthNameOffset1 = {
	1: "January",
	2: "February",
	3: "March",
	4: "April",
	5: "May",
	6: "June",
	7: "July",
	8: "August",
	9: "September",
	10: "October",
	11: "November",
	12: "December",
};

/*EQUITY per tutte le geo
Comm Services: COMM
Consumer Discretionary: COND
Consumer Staples: CONS
Energy: ENRG
Financials: FIN
Healthcare: HLTH
Industrials: IND
Information Technology: INFT
Materials: MATR
Real Estate: RLST
Utilities: UTIL

per se vuoi dare per coerenza un codice di lunghezza 4 a tutti possiamo aggiungere una lettera a FIN e IND)

per le sottogeografie dentro e.g. Pacific ex-Japan, direi metti tu dei codici sensati, tipo Australia=AUS (latino amarica LATAM)

Fixed income:

Lato "macro"

Government = GOVT

Corporate = CORP

Lato "micro"

Government Short = SHORT

Government Medium = MID

Government Long = LONG

Corporate IG = IG

Corporate HY = HY*/
const assetClassShortForm: Partial<Record<string, string>> = {
	USA: "US",
	EQUITY: "Equity",
	FIXED_INCOME: "Fixed Income",
	JAPAN: "JP",
	COMMODITIES_PRECIOUS_METALS: "METP", // ?
	COMMODITIES_INDUSTRIAL_METALS: "METI", // ?
	COMMODITIES_ENERGY: "ENRG",
	COMMODITIES_AGRICULTURE: "AGRI", // ?
	ALTERNATIVE: "ALTN", // ?
	MONEY_MARKET: "MNEY", // ?
	EMERGING_MARKETS: "EM", // ?
	PACIFIC_EX_JAPAN: "PACI", // ?
	UNITED_KINGDOM: "UK", // ?
	EUROPE: "EU", // ?
	COMMODITIES_COMMODITIES: "COMD", // ?
	EQUITY_EMERGING_MARKETS: "Equity EM",
	EQUITY_EUROPE: "Equity EU", // ?
	EQUITY_JAPAN: "Equity JP", // ?
	EQUITY_USA: "Equity US", // ?
	EQUITY_USA_COUNTRY: "Equity US", // ?
	EQUITY_COMMUNICATION_SERVICES_EUROPE: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_EUROPE: "COND",
	EQUITY_CONSUMER_STAPLES_EUROPE: "CONS",
	EQUITY_ENERGY_EUROPE: "ENRG",
	EQUITY_FINANCIALS_EUROPE: "FIN",
	EQUITY_HEALTH_CARE_EUROPE: "HLTH",
	EQUITY_INDUSTRIALS_EUROPE: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_EUROPE: "INFT",
	EQUITY_MATERIALS_EUROPE: "MATR",
	EQUITY_REAL_ESTATE_EUROPE: "RLST",
	EQUITY_UTILITIES_EUROPE: "UTIL",
	EQUITY_COMMUNICATION_SERVICES_USA: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_USA: "COND",
	EQUITY_CONSUMER_STAPLES_USA: "CONS",
	EQUITY_ENERGY_USA: "ENRG",
	EQUITY_FINANCIALS_USA: "FIN",
	EQUITY_HEALTH_CARE_USA: "HLTH",
	EQUITY_INDUSTRIALS_USA: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_USA: "INFT",
	EQUITY_MATERIALS_USA: "MATR",
	EQUITY_REAL_ESTATE_USA: "RLST",
	EQUITY_UTILITIES_USA: "UTIL",
	FIXED_INCOME_EUROPE: "Fixed Income EU", // ?
	FIXED_INCOME_JAPAN: "Fixed Income JP", // ?
	FIXED_INCOME_USA_AND_CANADA: "Fixed Income US",
	FIXED_INCOME_EMERGING_MARKETS: "Fixed Income EM", // ?
	FIXED_INCOME_CORPORATE_EMERGING_MARKETS: "CORP",
	FIXED_INCOME_GOVERNMENT_EMERGING_MARKETS: "GOVT",
	FIXED_INCOME_CORPORATE_MEDIUM_EUROPE: "IG",
	FIXED_INCOME_CORPORATE_SHORT_EUROPE: "HY",
	FIXED_INCOME_CORPORATE_LONG_EUROPE: "LONG",
	FIXED_INCOME_CORPORATE_EUROPE: "CORP",
	FIXED_INCOME_GOVERNMENT_EUROPE: "GOVT",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_EUROPE: "IL", // ?
	FIXED_INCOME_GOVERNMENT_LONG_EUROPE: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_EUROPE: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_EUROPE: "SHORT",
	FIXED_INCOME_HIGH_YIELD_EUROPE: "HY",
	FIXED_INCOME_CONVERTIBLES_EUROPE: "CONV", // ?
	FIXED_INCOME_CORPORATE_MEDIUM_USA: "IG",
	FIXED_INCOME_CORPORATE_SHORT_USA: "HY",
	FIXED_INCOME_CORPORATE_LONG_USA: "LONG",
	FIXED_INCOME_CORPORATE_USA: "CORP",
	FIXED_INCOME_GOVERNMENT_USA: "GOVT",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_USA: "IL", // ?
	FIXED_INCOME_GOVERNMENT_LONG_USA: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_USA: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_USA: "SHORT",
	FIXED_INCOME_HIGH_YIELD_USA: "HY",
	FIXED_INCOME_LEVERAGED_LOANS_USA: "LL", // ?
	FIXED_INCOME_CONVERTIBLES_USA: "CONV", // ?
	MONEY_MARKET_EUROPE: "MNEY", // ?
	EQUITY_ITALY: "ITA",
	EQUITY_AUSTRALIA: "AUS",
	EQUITY_BRAZIL: "BRA",
	EQUITY_CANADA: "CAD",
	EQUITY_CHINA: "CHN",
	EQUITY_COMMUNICATION_SERVICES_CHINA: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_CHINA: "COND",
	EQUITY_CONSUMER_STAPLES_CHINA: "CONS",
	EQUITY_ENERGY_CHINA: "ENRG",
	EQUITY_FINANCIALS_CHINA: "FIN",
	EQUITY_HEALTH_CARE_CHINA: "HLTH",
	EQUITY_INDUSTRIALS_CHINA: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_CHINA: "INFT",
	EQUITY_MATERIALS_CHINA: "MATR",
	EQUITY_REAL_ESTATE_CHINA: "RLST",
	EQUITY_UTILITIES_CHINA: "UTIL",
	EQUITY_EAFE: "EAFE", // ?
	EQUITY_EMEA: "EMEA", // ?
	EQUITY_COMMUNICATION_SERVICES_EMERGING_MARKETS: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_EMERGING_MARKETS: "COND",
	EQUITY_CONSUMER_STAPLES_EMERGING_MARKETS: "CONS",
	EQUITY_ENERGY_EMERGING_MARKETS: "ENRG",
	EQUITY_FINANCIALS_EMERGING_MARKETS: "FIN",
	EQUITY_HEALTH_CARE_EMERGING_MARKETS: "HLTH",
	EQUITY_INDUSTRIALS_EMERGING_MARKETS: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_EMERGING_MARKETS: "INFT",
	EQUITY_MATERIALS_EMERGING_MARKETS: "MATR",
	EQUITY_REAL_ESTATE_EMERGING_MARKETS: "RLST",
	EQUITY_UTILITIES_EMERGING_MARKETS: "UTIL",
	EQUITY_EMERGING_MARKETS_EX_CHINA: "EM", // ?
	EQUITY_EUROPE_EX_UK: "EU", // ?
	EQUITY_COMMUNICATION_SERVICES_EUROZONE: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_EUROZONE: "COND",
	EQUITY_CONSUMER_STAPLES_EUROZONE: "CONS",
	EQUITY_ENERGY_EUROZONE: "ENRG",
	EQUITY_FINANCIALS_EUROZONE: "FIN",
	EQUITY_HEALTH_CARE_EUROZONE: "HLTH",
	EQUITY_INDUSTRIALS_EUROZONE: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_EUROZONE: "INFT",
	EQUITY_MATERIALS_EUROZONE: "MATR",
	EQUITY_REAL_ESTATE_EUROZONE: "RLST",
	EQUITY_UTILITIES_EUROZONE: "UTIL",
	EQUITY_EUROZONE: "EU",
	EQUITY_FRANCE: "FRA",
	EQUITY_GERMANY: "DEU",
	EQUITY_COMMUNICATION_SERVICES: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY: "COND",
	EQUITY_CONSUMER_STAPLES: "CONS",
	EQUITY_ENERGY: "ENRG",
	EQUITY_FINANCIALS: "FIN",
	EQUITY_HEALTH_CARE: "HLTH",
	EQUITY_INDUSTRIALS: "IND",
	EQUITY_INFORMATION_TECHNOLOGY: "INFT",
	EQUITY_MATERIALS: "MATR",
	EQUITY_REAL_ESTATE: "RLST",
	EQUITY_UTILITIES: "UTIL",
	EQUITY_SMALL_CAP: "SMCP", // ?
	EQUITY_INFRASTRUCTURE: "INFR", // ?
	EQUITY_GLOBAL_EX_EUROPE: "GLBL", // ?
	EQUITY_GLOBAL_EX_UK: "GLBL", // ?
	EQUITY_HONG_KONG: "HKG", // ?
	EQUITY_INDIA: "IND",
	EQUITY_SMALL_CAP_JAPAN: "SMCP", // ?
	EQUITY_COMMUNICATION_SERVICES_JAPAN: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_JAPAN: "COND",
	EQUITY_CONSUMER_STAPLES_JAPAN: "CONS",
	EQUITY_ENERGY_JAPAN: "ENRG",
	EQUITY_FINANCIALS_JAPAN: "FIN",
	EQUITY_HEALTH_CARE_JAPAN: "HLTH",
	EQUITY_INDUSTRIALS_JAPAN: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_JAPAN: "INFT",
	EQUITY_MATERIALS_JAPAN: "MATR",
	EQUITY_REAL_ESTATE_JAPAN: "RLST",
	EQUITY_UTILITIES_JAPAN: "UTIL",
	EQUITY_KOREA: "KOR",
	EQUITY_LATIN_AMERICA: "LATAM",
	EQUITY_PACIFIC_EX_JAPAN: "Equity PAX",
	EQUITY_SINGAPORE: "SGP",
	EQUITY_SPAIN: "ESP",
	EQUITY_SWITZERLAND: "CHE",
	EQUITY_COMMUNICATION_SERVICES_SWITZERLAND: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_SWITZERLAND: "COND",
	EQUITY_CONSUMER_STAPLES_SWITZERLAND: "CONS",
	EQUITY_ENERGY_SWITZERLAND: "ENRG",
	EQUITY_FINANCIALS_SWITZERLAND: "FIN",
	EQUITY_HEALTH_CARE_SWITZERLAND: "HLTH",
	EQUITY_INDUSTRIALS_SWITZERLAND: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_SWITZERLAND: "INFT",
	EQUITY_MATERIALS_SWITZERLAND: "MATR",
	EQUITY_REAL_ESTATE_SWITZERLAND: "RLST",
	EQUITY_UTILITIES_SWITZERLAND: "UTIL",
	EQUITY_TURKEY: "TUR",
	EQUITY_COMMUNICATION_SERVICES_UNITED_KINGDOM: "COMM",
	EQUITY_CONSUMER_DISCRETIONARY_UNITED_KINGDOM: "COND",
	EQUITY_CONSUMER_STAPLES_UNITED_KINGDOM: "CONS",
	EQUITY_ENERGY_UNITED_KINGDOM: "ENRG",
	EQUITY_FINANCIALS_UNITED_KINGDOM: "FIN",
	EQUITY_HEALTH_CARE_UNITED_KINGDOM: "HLTH",
	EQUITY_INDUSTRIALS_UNITED_KINGDOM: "IND",
	EQUITY_INFORMATION_TECHNOLOGY_UNITED_KINGDOM: "INFT",
	EQUITY_MATERIALS_UNITED_KINGDOM: "MATR",
	EQUITY_REAL_ESTATE_UNITED_KINGDOM: "RLST",
	EQUITY_UTILITIES_UNITED_KINGDOM: "UTIL",
	EQUITY_UNITED_KINGDOM: "UK",
	EQUITY_SMALL_CAP_USA: "SMCP", // ?
	EQUITY_MID_CAP_USA: "MDCP", // ?
	MONEY_MARKET_UNITED_KINGDOM: "MNEY", // ?
	MONEY_MARKET_SWITZERLAND: "MNEY", // ?
	MONEY_MARKET_AUSTRALIA: "MNEY", // ?
	MONEY_MARKET_JAPAN: "MNEY", // ?
	FIXED_INCOME_GOVERNMENT_MEDIUM_AUSTRALIA: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_AUSTRALIA: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_CHINA: "SHORT",
	FIXED_INCOME_GOVERNMENT_CHINA: "GOVT",
	FIXED_INCOME_GOVERNMENT_LOCAL_EMERGING_MARKETS: "GOVT",
	FIXED_INCOME_GOVERNMENT_LONG_FRANCE: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_FRANCE: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_FRANCE: "SHORT",
	FIXED_INCOME_GOVERNMENT_LONG_GERMANY: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_GERMANY: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_GERMANY: "SHORT",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: "IL", // ?
	MONEY_MARKET_USA: "MNEY", // ?
	FIXED_INCOME_HIGH_YIELD: "HY",
	FIXED_INCOME_CONVERTIBLES: "CONV", // ?
	FIXED_INCOME_GOVERNMENT_MEDIUM: "MID",
	FIXED_INCOME_GOVERNMENT_LONG: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT: "SHORT",
	FIXED_INCOME_CORPORATE_SHORT: "HY",
	FIXED_INCOME_CORPORATE_MEDIUM: "IG",
	FIXED_INCOME_CORPORATE_LONG: "LONG",
	FIXED_INCOME_GOVERNMENT_AGGREGATE: "AGGR", // ?
	FIXED_INCOME_CORPORATE_AGGREGATE: "AGGR", // ?
	FIXED_INCOME_GOVERNMENT_MEDIUM_ITALY: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_ITALY: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_ITALY: "SHORT",
	FIXED_INCOME_GOVERNMENT_MEDIUM_JAPAN: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_JAPAN: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_SPAIN: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_SPAIN: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_SPAIN: "SHORT",
	FIXED_INCOME_GOVERNMENT_MEDIUM_UNITED_KINGDOM: "MID",
	FIXED_INCOME_GOVERNMENT_LINKED_UNITED_KINGDOM: "GL", // ?
	FIXED_INCOME_CORPORATE_MEDIUM_UNITED_KINGDOM: "IG",
	ASIA_OCEANIA: "ASIA", // ?
	EQUITY_EMERGING_MARKETS_ASIA_EX_CHINA: "EM", // ?
	EQUITY_ASIA: "ASIA", // ?
	FIXED_INCOME_CORPORATE_FINANCIALS_EUROPE: "CORP", // ?
	FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_EUROPE: "HY",
	FIXED_INCOME_CORPORATE_EX_FINANCIALS_EUROPE: "CORP", // ?
	FIXED_INCOME_HIGH_YIELD_FINANCIALS_EUROPE: "HY",
	FIXED_INCOME_CORPORATE_FINANCIALS_USA_AND_CANADA: "FIN",
	FIXED_INCOME_CORPORATE_EX_FINANCIALS_USA_AND_CANADA: "FIN",
	FIXED_INCOME_HIGH_YIELD_FINANCIALS_USA_AND_CANADA: "HY",
	FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_USA_AND_CANADA: "HY",
	// random mapped
	EQUITY_FRONTIER_MARKET: "Equity Frontier Market",
	FIXED_INCOME_CORPORATE_ASIA: "Fixed Income Corporate Asia",
	FIXED_INCOME_CORPORATE_GLOBAL: "Fixed Income Corporate Global",
	FIXED_INCOME_GOVERNMENT_ASIA: "Fixed Income Gov Asia",
	FIXED_INCOME_GOVERNMENT_GLOBAL: "Fixed  Income Government Global",
	FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_ASIA: "Fixed Income Government Local Currency Asia",
	FIXED_INCOME_HIGH_YIELD_ASIA: "Fixed Income High Yield Asia",
	MONEY_MARKET_CANADA: "Money Market USD Canada",
	MONEY_MARKET_SINGAPORE: "Money Market Singapore",
} satisfies Record<TempFixAssetClassIdentifierEnum, string>;

const assetClassLongForm: Partial<Record<string, string>> = {
	USA: "US",
	EQUITY: "Equity",
	FIXED_INCOME: "Fixed Income",
	JAPAN: "Japan",
	COMMODITIES_PRECIOUS_METALS: "Precious Metals",
	COMMODITIES_INDUSTRIAL_METALS: "Industrial Metals",
	COMMODITIES_ENERGY: "Energy",
	COMMODITIES_AGRICULTURE: "Agriculture",
	ALTERNATIVE: "Alternative",
	MONEY_MARKET: "Money Market",
	EMERGING_MARKETS: "Emerging Markets",
	PACIFIC_EX_JAPAN: "Pacific Ex Japan",
	UNITED_KINGDOM: "UK",
	EUROPE: "EU",
	COMMODITIES_COMMODITIES: "Commodities",
	EQUITY_EMERGING_MARKETS: "Equity Emerging Markets",
	EQUITY_EUROPE: "Equity EU",
	EQUITY_JAPAN: "Equity Japan",
	EQUITY_USA: "Equity US",
	EQUITY_USA_COUNTRY: "Equity US",
	EQUITY_COMMUNICATION_SERVICES_EUROPE: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_EUROPE: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_EUROPE: "Consumer Staples",
	EQUITY_ENERGY_EUROPE: "Energy",
	EQUITY_FINANCIALS_EUROPE: "Financial",
	EQUITY_HEALTH_CARE_EUROPE: "Health Care",
	EQUITY_INDUSTRIALS_EUROPE: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_EUROPE: "Information Technology",
	EQUITY_MATERIALS_EUROPE: "Materials",
	EQUITY_REAL_ESTATE_EUROPE: "Real Estate",
	EQUITY_UTILITIES_EUROPE: "Utilities",
	EQUITY_COMMUNICATION_SERVICES_USA: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_USA: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_USA: "Consumer Staples",
	EQUITY_ENERGY_USA: "Energy",
	EQUITY_FINANCIALS_USA: "Financial",
	EQUITY_HEALTH_CARE_USA: "Health Care",
	EQUITY_INDUSTRIALS_USA: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_USA: "Information Technology",
	EQUITY_MATERIALS_USA: "Materials",
	EQUITY_REAL_ESTATE_USA: "Real Estate",
	EQUITY_UTILITIES_USA: "Utilities",
	FIXED_INCOME_EUROPE: "Fixed Income EU",
	FIXED_INCOME_JAPAN: "Fixed Income JP",
	FIXED_INCOME_USA_AND_CANADA: "Fixed Income US",
	FIXED_INCOME_EMERGING_MARKETS: "Fixed Income EM",
	FIXED_INCOME_CORPORATE_EMERGING_MARKETS: "Corporate",
	FIXED_INCOME_GOVERNMENT_EMERGING_MARKETS: "Government",
	FIXED_INCOME_CORPORATE_MEDIUM_EUROPE: "Investment Grade",
	FIXED_INCOME_CORPORATE_SHORT_EUROPE: "High Yield",
	FIXED_INCOME_CORPORATE_LONG_EUROPE: "LONG",
	FIXED_INCOME_CORPORATE_EUROPE: "Corporate",
	FIXED_INCOME_GOVERNMENT_EUROPE: "Government",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_EUROPE: "Inflation Linked",
	FIXED_INCOME_GOVERNMENT_LONG_EUROPE: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_EUROPE: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_EUROPE: "SHORT",
	FIXED_INCOME_HIGH_YIELD_EUROPE: "High Yield",
	FIXED_INCOME_CONVERTIBLES_EUROPE: "Convertibles",
	FIXED_INCOME_CORPORATE_MEDIUM_USA: "Investment Grade",
	FIXED_INCOME_CORPORATE_SHORT_USA: "High Yield",
	FIXED_INCOME_CORPORATE_LONG_USA: "LONG",
	FIXED_INCOME_CORPORATE_USA: "Corporate",
	FIXED_INCOME_GOVERNMENT_USA: "Government",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_USA: "Inflation Linked",
	FIXED_INCOME_GOVERNMENT_LONG_USA: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_USA: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_USA: "SHORT",
	FIXED_INCOME_HIGH_YIELD_USA: "High Yield",
	FIXED_INCOME_LEVERAGED_LOANS_USA: "Leveraged Loans",
	FIXED_INCOME_CONVERTIBLES_USA: "Convertibles",
	MONEY_MARKET_EUROPE: "Money Market",
	EQUITY_ITALY: "Italy",
	EQUITY_AUSTRALIA: "Australia",
	EQUITY_BRAZIL: "Brazil",
	EQUITY_CANADA: "Canada",
	EQUITY_CHINA: "China",
	EQUITY_COMMUNICATION_SERVICES_CHINA: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_CHINA: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_CHINA: "Consumer Staples",
	EQUITY_ENERGY_CHINA: "Energy",
	EQUITY_FINANCIALS_CHINA: "Financial",
	EQUITY_HEALTH_CARE_CHINA: "Health Care",
	EQUITY_INDUSTRIALS_CHINA: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_CHINA: "Information Technology",
	EQUITY_MATERIALS_CHINA: "Materials",
	EQUITY_REAL_ESTATE_CHINA: "Real Estate",
	EQUITY_UTILITIES_CHINA: "Utilities",
	EQUITY_EAFE: "EAFE",
	EQUITY_EMEA: "EMEA",
	EQUITY_COMMUNICATION_SERVICES_EMERGING_MARKETS: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_EMERGING_MARKETS: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_EMERGING_MARKETS: "Consumer Staples",
	EQUITY_ENERGY_EMERGING_MARKETS: "Energy",
	EQUITY_FINANCIALS_EMERGING_MARKETS: "Financial",
	EQUITY_HEALTH_CARE_EMERGING_MARKETS: "Health Care",
	EQUITY_INDUSTRIALS_EMERGING_MARKETS: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_EMERGING_MARKETS: "Information Technology",
	EQUITY_MATERIALS_EMERGING_MARKETS: "Materials",
	EQUITY_REAL_ESTATE_EMERGING_MARKETS: "Real Estate",
	EQUITY_UTILITIES_EMERGING_MARKETS: "Utilities",
	EQUITY_EMERGING_MARKETS_EX_CHINA: "EM ex China",
	EQUITY_EUROPE_EX_UK: "EU",
	EQUITY_COMMUNICATION_SERVICES_EUROZONE: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_EUROZONE: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_EUROZONE: "Consumer Staples",
	EQUITY_ENERGY_EUROZONE: "Energy",
	EQUITY_FINANCIALS_EUROZONE: "Financial",
	EQUITY_HEALTH_CARE_EUROZONE: "Health Care",
	EQUITY_INDUSTRIALS_EUROZONE: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_EUROZONE: "Information Technology",
	EQUITY_MATERIALS_EUROZONE: "Materials",
	EQUITY_REAL_ESTATE_EUROZONE: "Real Estate",
	EQUITY_UTILITIES_EUROZONE: "Utilities",
	EQUITY_EUROZONE: "EU",
	EQUITY_FRANCE: "France",
	EQUITY_GERMANY: "Germany",
	EQUITY_COMMUNICATION_SERVICES: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES: "Consumer Staples",
	EQUITY_ENERGY: "Energy",
	EQUITY_FINANCIALS: "Financial",
	EQUITY_HEALTH_CARE: "Health Care",
	EQUITY_INDUSTRIALS: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY: "Information Technology",
	EQUITY_MATERIALS: "Materials",
	EQUITY_REAL_ESTATE: "Real Estate",
	EQUITY_UTILITIES: "Utilities",
	EQUITY_SMALL_CAP: "Small Cap",
	EQUITY_INFRASTRUCTURE: "Infrastructure",
	EQUITY_GLOBAL_EX_EUROPE: "Global ex EU",
	EQUITY_GLOBAL_EX_UK: "Global ex UK",
	EQUITY_HONG_KONG: "Hong Kong",
	EQUITY_INDIA: "India",
	EQUITY_SMALL_CAP_JAPAN: "Small Cap",
	EQUITY_COMMUNICATION_SERVICES_JAPAN: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_JAPAN: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_JAPAN: "Consumer Staples",
	EQUITY_ENERGY_JAPAN: "Energy",
	EQUITY_FINANCIALS_JAPAN: "Financial",
	EQUITY_HEALTH_CARE_JAPAN: "Health Care",
	EQUITY_INDUSTRIALS_JAPAN: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_JAPAN: "Information Technology",
	EQUITY_MATERIALS_JAPAN: "Materials",
	EQUITY_REAL_ESTATE_JAPAN: "Real Estate",
	EQUITY_UTILITIES_JAPAN: "Utilities",
	EQUITY_KOREA: "Korea",
	EQUITY_LATIN_AMERICA: "Latin America",
	EQUITY_PACIFIC_EX_JAPAN: "Equity PAX",
	EQUITY_SINGAPORE: "Singapore",
	EQUITY_SPAIN: "Spain",
	EQUITY_SWITZERLAND: "Switzerland",
	EQUITY_COMMUNICATION_SERVICES_SWITZERLAND: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_SWITZERLAND: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_SWITZERLAND: "Consumer Staples",
	EQUITY_ENERGY_SWITZERLAND: "Energy",
	EQUITY_FINANCIALS_SWITZERLAND: "Financial",
	EQUITY_HEALTH_CARE_SWITZERLAND: "Health Care",
	EQUITY_INDUSTRIALS_SWITZERLAND: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_SWITZERLAND: "Information Technology",
	EQUITY_MATERIALS_SWITZERLAND: "Materials",
	EQUITY_REAL_ESTATE_SWITZERLAND: "Real Estate",
	EQUITY_UTILITIES_SWITZERLAND: "Utilities",
	EQUITY_TURKEY: "Turkey",
	EQUITY_COMMUNICATION_SERVICES_UNITED_KINGDOM: "Communication Services",
	EQUITY_CONSUMER_DISCRETIONARY_UNITED_KINGDOM: "Consumer Discretionary",
	EQUITY_CONSUMER_STAPLES_UNITED_KINGDOM: "Consumer Staples",
	EQUITY_ENERGY_UNITED_KINGDOM: "Energy",
	EQUITY_FINANCIALS_UNITED_KINGDOM: "Financial",
	EQUITY_HEALTH_CARE_UNITED_KINGDOM: "Health Care",
	EQUITY_INDUSTRIALS_UNITED_KINGDOM: "Industrials",
	EQUITY_INFORMATION_TECHNOLOGY_UNITED_KINGDOM: "Information Technology",
	EQUITY_MATERIALS_UNITED_KINGDOM: "Materials",
	EQUITY_REAL_ESTATE_UNITED_KINGDOM: "Real Estate",
	EQUITY_UTILITIES_UNITED_KINGDOM: "Utilities",
	EQUITY_UNITED_KINGDOM: "UK",
	EQUITY_SMALL_CAP_USA: "Small Cap",
	EQUITY_MID_CAP_USA: "Mid Cap",
	MONEY_MARKET_UNITED_KINGDOM: "Money Market",
	MONEY_MARKET_SWITZERLAND: "Money Market",
	MONEY_MARKET_AUSTRALIA: "Money Market",
	MONEY_MARKET_JAPAN: "Money Market",
	FIXED_INCOME_GOVERNMENT_MEDIUM_AUSTRALIA: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_AUSTRALIA: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_CHINA: "SHORT",
	FIXED_INCOME_GOVERNMENT_CHINA: "Government",
	FIXED_INCOME_GOVERNMENT_LOCAL_EMERGING_MARKETS: "Government",
	FIXED_INCOME_GOVERNMENT_LONG_FRANCE: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_FRANCE: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_FRANCE: "SHORT",
	FIXED_INCOME_GOVERNMENT_LONG_GERMANY: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_GERMANY: "MID",
	FIXED_INCOME_GOVERNMENT_SHORT_GERMANY: "SHORT",
	FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: "Inflation Linked",
	MONEY_MARKET_USA: "Money Market",
	FIXED_INCOME_HIGH_YIELD: "High Yield",
	FIXED_INCOME_CONVERTIBLES: "Convertibles",
	FIXED_INCOME_GOVERNMENT_MEDIUM: "MID",
	FIXED_INCOME_GOVERNMENT_LONG: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT: "SHORT",
	FIXED_INCOME_CORPORATE_SHORT: "High Yield",
	FIXED_INCOME_CORPORATE_MEDIUM: "Investment Grade",
	FIXED_INCOME_CORPORATE_LONG: "LONG",
	FIXED_INCOME_GOVERNMENT_AGGREGATE: "Aggregate",
	FIXED_INCOME_CORPORATE_AGGREGATE: "Aggregate",
	FIXED_INCOME_GOVERNMENT_MEDIUM_ITALY: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_ITALY: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_ITALY: "SHORT",
	FIXED_INCOME_GOVERNMENT_MEDIUM_JAPAN: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_JAPAN: "LONG",
	FIXED_INCOME_GOVERNMENT_MEDIUM_SPAIN: "MID",
	FIXED_INCOME_GOVERNMENT_LONG_SPAIN: "LONG",
	FIXED_INCOME_GOVERNMENT_SHORT_SPAIN: "SHORT",
	FIXED_INCOME_GOVERNMENT_MEDIUM_UNITED_KINGDOM: "MID",
	FIXED_INCOME_GOVERNMENT_LINKED_UNITED_KINGDOM: "Government Linked",
	FIXED_INCOME_CORPORATE_MEDIUM_UNITED_KINGDOM: "Investment Grade",
	ASIA_OCEANIA: "Asia",
	EQUITY_EMERGING_MARKETS_ASIA_EX_CHINA: "EM Asia ex China",
	EQUITY_ASIA: "Asia",
	FIXED_INCOME_CORPORATE_FINANCIALS_EUROPE: "Corporate",
	FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_EUROPE: "High Yield",
	FIXED_INCOME_CORPORATE_EX_FINANCIALS_EUROPE: "Corporate",
	FIXED_INCOME_HIGH_YIELD_FINANCIALS_EUROPE: "High Yield",
	FIXED_INCOME_CORPORATE_FINANCIALS_USA_AND_CANADA: "Financial",
	FIXED_INCOME_CORPORATE_EX_FINANCIALS_USA_AND_CANADA: "Financial",
	FIXED_INCOME_HIGH_YIELD_FINANCIALS_USA_AND_CANADA: "High Yield",
	FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_USA_AND_CANADA: "High Yield",

	// random mapped
	EQUITY_FRONTIER_MARKET: "Frontier Market",
	FIXED_INCOME_CORPORATE_ASIA: "Government",
	FIXED_INCOME_CORPORATE_GLOBAL: "Government",
	FIXED_INCOME_GOVERNMENT_ASIA: "Government",
	FIXED_INCOME_GOVERNMENT_GLOBAL: "Government",
	FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_ASIA: "Government",
	FIXED_INCOME_HIGH_YIELD_ASIA: "High Yield",
	MONEY_MARKET_CANADA: "Money Market",
	MONEY_MARKET_SINGAPORE: "Money Market",
} satisfies Record<TempFixAssetClassIdentifierEnum, string>;
