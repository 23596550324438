import { For, ForEach } from "$root/utils/react-extra";
import type { StylableProps } from "@mdotm/mdotui/components";
import { ComputedSizeContainer, Svg } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, clamp, mapBetweenRanges, switchExpr } from "@mdotm/mdotui/utils";
import { Range } from "immutable";
import { useMemo, type SyntheticEvent } from "react";

export type BubbleChartItem = {
	/** Label shown when selected */
	label: string;
	/** Control the position along the main axis */
	value: number;
	/** Control the size of the bubble */
	size: number;
	/** Control the color.  */
	color: string;
	/** Control is it is present or not.  */
	hide?: boolean;
};

export type BubbleChartSvgProps = StylableProps & {
	/**
	 * bounded => first and last label thresholds are the bounds of the chart
	 * unbounded => min(bubbles.value) and max(bubbles.value) are the bounds of the chart
	 */
	boundsMode: "bounded" | "unbounded";
	bubbles: BubbleChartItem[];
	/** The first and last label thresholds are interpreted as <X and >X respectively. */
	labels: Array<{
		threshold: number;
		text: string;
	}>;
	selectedBubbleIndex?: number | null;
	hoveringBubbleIndex?: number | null;
	bottomLabel?: string;
	bottomLabelColor?: string;
	onCircleMouseEnter?(bubble: BubbleChartItem, index: number, e: SyntheticEvent): void;
	onCircleMouseLeave?(bubble: BubbleChartItem, index: number, e: SyntheticEvent): void;
	onCircleClick?(bubble: BubbleChartItem, index: number, e: SyntheticEvent): void;
	flipY?: boolean;
};

export function BubbleChartSvg({
	bubbles,
	labels: propsLabels,
	boundsMode,
	bottomLabel,
	bottomLabelColor,
	onCircleMouseEnter,
	onCircleMouseLeave,
	onCircleClick,
	selectedBubbleIndex,
	hoveringBubbleIndex,
	flipY = false,
	...stylableProps
}: BubbleChartSvgProps): JSX.Element {
	const labels = useMemo(() => propsLabels.slice().sort(builtInSortFnFor("threshold")), [propsLabels]);

	const intervals = useMemo(
		() =>
			Range(
				0,
				switchExpr(boundsMode, {
					bounded: () => labels.length - 1 /* intervals are only between labels */,
					unbounded: () =>
						labels.length + 1 /* intervals are between labels, beyond the first and beyond the last label */,
				}),
			)
				.map(
					switchExpr(boundsMode, {
						unbounded: () => (i: number) =>
							i === 0
								? {
										min: bubbles.reduce((acc, cur) => Math.min(acc, cur.value), bubbles[0].value),
										max: labels[i].threshold,
								  }
								: i === labels.length
								  ? {
											min: labels[i - 1].threshold,
											max: bubbles.reduce((acc, cur) => Math.max(acc, cur.value), bubbles[0].value),
								    }
								  : { min: labels[i - 1].threshold, max: labels[i].threshold },
						bounded: () => (i: number) => ({ min: labels[i].threshold, max: labels[i + 1].threshold }),
					}),
				)
				.toArray(),
		[boundsMode, bubbles, labels],
	);
	const categorizedBubbles = useMemo(
		() =>
			bubbles.map((bubble) => {
				let intervalIndex = intervals.findIndex(({ min, max }) => min <= bubble.value && bubble.value <= max);
				if (intervalIndex === -1) {
					if (bubble.value <= intervals[0].min) {
						intervalIndex = 0;
					} else {
						intervalIndex = intervals.length - 1;
					}
				}

				return { bubble, intervalIndex };
			}),
		[bubbles, intervals],
	);

	const maxBubbleSize = useMemo(() => bubbles.reduce((acc, b) => Math.max(acc, b.size), Number.EPSILON), [bubbles]);

	return (
		<ComputedSizeContainer {...stylableProps}>
			{({ htmlEl: _htmlEl, ...rect }) => {
				const maxDiameter = 120;
				const maxRadius = maxDiameter / 2;
				const minY = maxRadius;
				const maxY = rect.height - maxRadius;
				const axisLength = maxY - minY;
				const intervalLength = axisLength / intervals.length;
				const centerX = rect.width / 2;

				const maybeFlipY = !flipY // Inverted because the default Y axis direction is bottom to top.
					? (y: number) =>
							mapBetweenRanges(
								y,
								// flip y axis
								minY,
								maxY,
								maxY,
								minY,
							)
					: (y: number) => y;

				return (
					<Svg viewBox={rect} classList="pointer-events-none">
						<pattern id="stripes" x="0" y="0" width={(1 / rect.width) * 4} height="1">
							<rect x="0" y="0" width="1" height="8" stroke="transparent" fill={themeCSSVars.palette_N50} />
							<rect x="1" y="0" width="2" height="8" stroke="transparent" fill="transparent" />
							<rect x="3" y="0" width="1" height="8" stroke="transparent" fill={themeCSSVars.palette_N50} />
						</pattern>
						<rect
							fill="url(#stripes)"
							stroke="transparent"
							width={rect.width - 32}
							height="8"
							x="16"
							y={rect.height - 8}
						/>

						<For times={intervals.length}>
							{({ index: i }) => (
								<>
									<line
										stroke={themeCSSVars.palette_N200}
										strokeWidth={2}
										x1={centerX}
										x2={centerX}
										y1={maybeFlipY(minY + intervalLength * i)}
										y2={maybeFlipY(minY + intervalLength * (i + 1))}
									/>
								</>
							)}
						</For>
						<ForEach collection={labels}>
							{({ item: { text }, index: i }) => {
								const y = switchExpr(boundsMode, {
									bounded: () => minY + i * intervalLength,
									unbounded: () => minY + (i + 1) * intervalLength,
								});
								return (
									<>
										<rect
											width={12}
											height={6}
											rx={3}
											fill={themeCSSVars.palette_N200}
											x={centerX - 6}
											y={maybeFlipY(y + 3)}
										/>
										<text
											fill={themeCSSVars.palette_N300}
											fontSize={14}
											textAnchor="start"
											x={centerX + 8}
											y={maybeFlipY(y - 5)}
										>
											{text}
										</text>
									</>
								);
							}}
						</ForEach>
						{boundsMode === "unbounded" && (
							<>
								{/* ^ */}
								<path
									d={`M${-4 + centerX} ${4 + minY}L${0 + centerX} ${0 + minY}L${4 + centerX} ${4 + minY}`}
									fill="none"
									stroke="#C3C9D5"
									strokeWidth="4"
									strokeLinecap="round"
								/>
								{/* v */}
								<path
									d={`M${-4 + centerX} ${-4 + maxY}L${0 + centerX} ${0 + maxY}L${4 + centerX} ${-4 + maxY}`}
									fill="none"
									stroke="#C3C9D5"
									strokeWidth="4"
									strokeLinecap="round"
								/>
							</>
						)}
						{bottomLabel && (
							<text
								x={centerX}
								y={maxY + 25}
								textAnchor="middle"
								fontSize={10}
								fill={bottomLabelColor ?? themeCSSVars.palette_N300}
							>
								{bottomLabel}
							</text>
						)}
						{/* Reduce instead of Map because the end result must have all circles at the beginning of the
						output array and all the text labels at the end to avoid having circles covering any label. */}
						{categorizedBubbles.reduce(
							(output, { bubble, intervalIndex }, bubbleIndex) => {
								const unclampedY = maybeFlipY(
									switchExpr(boundsMode, {
										bounded: () =>
											minY +
											intervalLength * intervalIndex +
											mapBetweenRanges(
												bubble.value - intervals[intervalIndex].min,
												0,
												intervals[intervalIndex].max - intervals[intervalIndex].min,
												0,
												intervalLength,
											),
										unbounded: () =>
											intervalIndex === 0
												? minY
												: intervalIndex === intervals.length - 1
												  ? maxY
												  : minY +
												    intervalLength * intervalIndex +
												    mapBetweenRanges(
															bubble.value - intervals[intervalIndex].min,
															0,
															intervals[intervalIndex].max - intervals[intervalIndex].min,
															0,
															intervalLength,
												    ),
									}),
								);
								const y = clamp(unclampedY, minY, maxY);
								const r = (bubble.size / maxBubbleSize) * maxRadius;
								output[bubbleIndex] = (
									<SmartBubbleFragment
										mode="justCircle"
										key={bubbleIndex}
										centerX={centerX}
										centerY={y}
										color={bubble.color}
										circleOpacity={
											selectedBubbleIndex === bubbleIndex
												? 0.75
												: hoveringBubbleIndex === bubbleIndex
												  ? 0.5
												  : selectedBubbleIndex !== null &&
												      selectedBubbleIndex !== undefined &&
												      selectedBubbleIndex !== bubbleIndex
												    ? 0.1
												    : 0.25
										}
										radius={r}
										text={bubble.label}
										textColor={themeCSSVars.palette_N700}
										onMouseEnter={(e) => onCircleMouseEnter?.(bubble, bubbleIndex, e)}
										onMouseLeave={(e) => onCircleMouseLeave?.(bubble, bubbleIndex, e)}
										onClick={(e) => onCircleClick?.(bubble, bubbleIndex, e)}
										hide={bubble.hide}
									/>
								);
								output[bubbleIndex + categorizedBubbles.length] = (
									<SmartBubbleFragment
										mode="justText"
										key={bubbleIndex + categorizedBubbles.length}
										centerX={centerX}
										centerY={y}
										color={bubble.color}
										radius={r}
										text={bubble.label}
										textColor={themeCSSVars.palette_N700}
										textOpacity={selectedBubbleIndex === bubbleIndex ? 1 : 0}
										onMouseEnter={(e) => onCircleMouseEnter?.(bubble, bubbleIndex, e)}
										onMouseLeave={(e) => onCircleMouseLeave?.(bubble, bubbleIndex, e)}
										onClick={(e) => onCircleClick?.(bubble, bubbleIndex, e)}
										hide={bubble.hide}
									/>
								);
								return output;
							},
							new Array(categorizedBubbles.length * 2),
						)}
					</Svg>
				);
			}}
		</ComputedSizeContainer>
	);
}

export type SmartBubbleFragmentProps = {
	color: string;
	circleOpacity?: number;
	textOpacity?: number;
	centerX: number;
	centerY: number;
	onMouseEnter?(e: SyntheticEvent): void;
	onMouseLeave?(e: SyntheticEvent): void;
	onClick?(e: SyntheticEvent): void;
	textColor: string;
	text: string;
	preferredOverflowPosition?: "top" | "bottom";
	mode?: "textAndCircle" | "justText" | "justCircle";
	hide?: boolean;
} & (
	| {
			radius: number;
			diameter?: undefined;
	  }
	| {
			radius?: undefined;
			diameter: number;
	  }
);

export function SmartBubbleFragment({
	centerX,
	centerY,
	color,
	radius: propsRadius,
	diameter,
	circleOpacity: opacity,
	onMouseEnter,
	onMouseLeave,
	onClick,
	text,
	textColor,
	textOpacity,
	preferredOverflowPosition,
	mode = "textAndCircle",
	hide,
}: SmartBubbleFragmentProps): JSX.Element {
	const radius = propsRadius ?? (diameter ?? 0) / 2;

	if (hide) {
		return <></>;
	}

	return (
		<>
			{mode !== "justText" && (
				<circle
					stroke={themeCSSVars.palette_N0}
					strokeWidth={1}
					fill={color}
					opacity={opacity}
					r={radius}
					cx={centerX}
					cy={centerY}
					className="pointer-events-auto transition-opacity"
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={onClick}
				/>
			)}
			{mode !== "justCircle" && text && (
				<>
					<text
						textAnchor="middle"
						opacity={textOpacity}
						x={centerX}
						y={
							radius >= 8 * text.length
								? centerY + 6
								: preferredOverflowPosition === "top" || (!preferredOverflowPosition && centerY - radius - 6 - 20 > 10)
								  ? centerY - radius - 6
								  : centerY + radius + 20
						}
						fontWeight={700}
						fontSize={20}
						stroke="white"
						strokeWidth={4}
						strokeLinecap="round"
						className="transition-[fill]"
					>
						{text}
					</text>
					<text
						textAnchor="middle"
						opacity={textOpacity}
						x={centerX}
						y={
							radius >= 8 * text.length
								? centerY + 6
								: preferredOverflowPosition === "top" || (!preferredOverflowPosition && centerY - radius - 6 - 20 > 10)
								  ? centerY - radius - 6
								  : centerY + radius + 20
						}
						fontWeight={700}
						fontSize={20}
						className="transition-[fill]"
						fill={textColor}
					>
						{text}
					</text>
				</>
			)}
		</>
	);
}
