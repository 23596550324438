import type {
	ExposureContributionResponse,
	InvestmentExposureEntry,
	InvestmentExposureResponseExposureTypeEnum,
} from "$root/api/api-gen";
import type { ExposureChartData } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ExposureChart, exposureCategoryInfo } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, objectMap } from "@mdotm/mdotui/utils";
import type { ExposureSankeyLikeChartProps } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { customObjectValuesFn } from "$root/utils/experimental";
import { sumArrayLike } from "$root/utils/collections";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { useLocaleFormatters } from "$root/localization/hooks";
import { useMemo } from "react";

export type ExposureListItem =
	| {
			type: "title";
			label: string;
			groupName: string;
	  }
	| {
			type: "entry";
			label: string;
			weight: number;
			groupName: string;
			netLong: boolean;
	  }
	| {
			type: "footer";
			label: string;
			weight: number;
			groupName: string;
	  };

export function exposureEntriesToSplittableProps(composition: InvestmentExposureEntry[]): Array<ExposureListItem> {
	const groups: string[] = [];
	for (const { firstQualityLevel } of composition) {
		if (!groups.includes(firstQualityLevel!)) {
			groups.push(firstQualityLevel!);
		}
	}

	return composition.length === 0
		? []
		: Array.from(
				Object.values(
					objectMap(
						groupBy(composition, (entry) => entry.firstQualityLevel!),
						(entries) => [
							{
								type: "title",
								label: entries?.at(0)?.firstQualityLevel ?? "",
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
							...(entries ?? []).map(
								(x) =>
									({
										type: "entry",
										label: x.secondQualityLevel ?? "",
										weight: x.weight!,
										netLong: x.netLong ?? true,
										groupName: entries?.at(0)?.firstQualityLevel ?? "",
									}) as const,
							),
							{
								type: "footer",
								label: "",
								weight: sumArrayLike(entries ?? [], (x) => x.weight!),
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
						],
					),
				),
		  ).flat();
}

export function Exposure(
	props: PrintableProps<
		{
			comparison: InvestmentExposureResponseExposureTypeEnum;
			composition: Array<InvestmentExposureEntry>;
		},
		Array<ExposureListItem>
	>,
): JSX.Element {
	const chartData = useMemo(() => {
		const data = props.composition.reduce(
			(acc, cur) => {
				if (!acc[cur.firstQualityLevel!]) {
					acc[cur.firstQualityLevel!] = {
						label: cur.firstQualityLevel!,
						weight: 0,
						drillDown: [],
						groupName: cur.firstQualityLevel!,
					};
				}
				acc[cur.firstQualityLevel!]!.weight += cur.weight!;

				acc[cur.firstQualityLevel!]!.drillDown.push({
					label: cur.secondQualityLevel!,
					weight: cur.weight!,
					netLong: cur.netLong ?? true,
				});
				return acc;
			},
			{} as Record<string, ExposureChartData[number]>,
		);

		return Object.values(data);
	}, [props.composition]);

	if (props.list.length === 0) {
		return <></>;
	}
	return (
		<>
			{props.firstRender && (
				<Card style={{ marginBottom: 0 }} title="Exposure" subTitle={exposureCategoryInfo[props.comparison].label}>
					<div className={`border border-[color:${themeCSSVars.palette_N100}] rounded pt-5 mb-4`}>
						<ExposureChart
							animated={false}
							customHeight={`${exposureChartHeight - 20 /* pt-5 */ - 16 /* mb-4 */ - 60 /* card extra spacing */}px`}
							data={chartData}
						/>
					</div>
				</Card>
			)}
			<ForEach collection={props.list}>
				{({ item: subList }) => (
					<CompositionList
						availableHeight={exposureAvailableHeightForItemSlotsByChartHeight(
							props.firstRender ? exposureChartHeight : 0,
							"portrait",
						)}
						list={subList}
					/>
				)}
			</ForEach>
		</>
	);
}

function CompositionList({
	availableHeight,
	list,
}: {
	availableHeight: number;
	list: Array<ExposureListItem>;
}): JSX.Element {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div
			className="columns-3 px-8"
			style={{
				columnFill: "auto",
				height: availableHeight, // Math.ceil(list.length / exposureCompositionListItemsPerRow) * exposureCompositionListRowHeight,
			}}
		>
			{list.map((row, i) => (
				<div className="flex" key={`exposure-singleColumn-item-key-${i}`}>
					<div className="pr-2 rounded-lg shrink-0">
						<div className="w-1 h-full" style={{ backgroundColor: stableColorGenerator(row.groupName) }} />
					</div>

					<div
						className={toClassName({
							"flex border-b border-zinc-500 items-center justify-between flex-1 overflow-hidden": true,
							"font-semibold uppercase": row.type === "title" || row.type === "footer",
						})}
						style={{ height: exposureCompositionListRowHeight }}
					>
						<span className="whitespace-nowrap text-ellipsis w-4/5 overflow-hidden">
							{row.label}

							{row.type === "entry" && row.netLong === false && (
								<>
									&nbsp;-&nbsp;[&nbsp;
									<svg
										className="inline w-[8px] mb-1"
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect opacity="0.3" width="8" height="8" rx="4" fill={stableColorGenerator(row.groupName)} />
									</svg>
									&nbsp;
									<span className="truncate">Net Short</span>&nbsp;]
								</>
							)}
						</span>
						{(row.type === "entry" || row.type === "footer") && (
							<span className="grow">{formatNumber(row.weight)}%</span>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

const exposureCompositionListRowHeight = 40;
const exposureCompositionListItemsPerRow = 3;
export const exposureChartHeight = 280;
const exposurePortraitPageHeight = 890;
const exposureLandscapePageHeight = 320; // TODO: check

export function exposureAvailableItemSlotsByChartHeight(chartHeight: number, layout: "portrait" | "landscape"): number {
	return (
		Math.floor(
			exposureAvailableHeightForItemSlotsByChartHeight(chartHeight, layout) / exposureCompositionListRowHeight,
		) * exposureCompositionListItemsPerRow
	);
}

function exposureAvailableHeightForItemSlotsByChartHeight(
	chartHeight: number,
	layout: "portrait" | "landscape",
): number {
	return Math.max(
		0,
		(layout === "portrait" ? exposurePortraitPageHeight : exposureLandscapePageHeight) -
			(chartHeight > 0 ? chartHeight : 0),
	);
}
