import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { formatNumber } from "$root/localization/formatters";
import { Text } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { InvestmentSummary } from "$root/api/api-gen";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { ReportTemplateItemMap } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";

export function Summary(
	props: PrintableProps<{ portfolio: InvestmentSummary; config: ReportTemplateItemMap["summary"] }, unknown>,
): JSX.Element {
	const {
		name,
		primaryBenchmarkName,
		primaryBenchmarkType,
		primaryBenchmarkIdentifier,
		universeName,
		selectedTrackingErrorConstraint,
		baseCurrency,
		scoreValue,
		scoreIdentifier,
	} = props.portfolio;
	const { t } = useTranslation();

	return (
		<Card>
			<div className="flex flex-wrap gap-x-8 gap-y-4 p-4 bg-[#F7F8F9] rounded">
				{props.config.portfolioName && (
					<div>
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.NAME")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							classList="max-w-[200px] truncate"
							data-title={name ?? "-"}
							title={name ?? "-"}
						>
							{name ?? "-"}
						</Text>
					</div>
				)}
				{props.config.baseCurrency && (
					<div>
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.BASE_CURRENCY")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							classList="max-w-[200px] truncate"
							data-title={name ?? "-"}
							title={name ?? "-"}
						>
							{baseCurrency ?? "-"}
						</Text>
					</div>
				)}
				{props.config.benchmark && (
					<div>
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.BENCHMARK")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							data-title={t("INVESTMENT_SUMMARY.BENCHMARK")}
							title={t("INVESTMENT_SUMMARY.BENCHMARK")}
							classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
						>
							{primaryBenchmarkIdentifier ? primaryBenchmarkName : ""}
						</Text>
					</div>
				)}

				{props.config.universe && (
					<div>
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.UNIVERSE")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							data-title={primaryBenchmarkType ?? "-"}
							title={primaryBenchmarkType ?? "-"}
							classList={{
								"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold":
									Boolean(universeName),
							}}
						>
							{universeName ?? t("INVESTMENT_SUMMARY.NO_UNIVERSE")}
						</Text>
					</div>
				)}

				{props.config.reference && (
					<div>
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.REFERENCE")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							data-title={t("INVESTMENT_SUMMARY.REFERENCE")}
							title={t("INVESTMENT_SUMMARY.REFERENCE")}
							classList={{
								"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold": Boolean(
									selectedTrackingErrorConstraint,
								),
							}}
						>
							{selectedTrackingErrorConstraint?.investmentReference?.name || t("INVESTMENT_SUMMARY.NO_REFERENCE")}
						</Text>
					</div>
				)}
				{props.config.averageScore && (
					<AuthorizationGuard requiredService="CUSTOM_QUALITIES">
						{() => (
							<div>
								<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
									{t("AVERAGE_SCORE")}
								</Text>
								<Text
									type="Body/M/Book"
									as="div"
									data-title={t("AVERAGE_SCORE")}
									title={t("AVERAGE_SCORE")}
									classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
								>
									{scoreIdentifier ? (
										<>
											(<CustomLabels labelKey={scoreIdentifier ?? "-"} fallback="SCORE" />){" "}
											{scoreValue ? formatNumber(scoreValue) : ""}
										</>
									) : (
										"None"
									)}
								</Text>
							</div>
						)}
					</AuthorizationGuard>
				)}
			</div>
		</Card>
	);
}
