const EmptyImportBox = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="83" height="78" fill="none" viewBox="0 0 83 78">
			<g filter="url(#filter0_d_611_121709)">
				<path
					fill="#fff"
					d="M65.423 31.196a10.184 10.184 0 00-6.887-3.02 6.275 6.275 0 00-1.815-3.594 6.25 6.25 0 00-3.613-1.752 11.67 11.67 0 00-3.569-6.793 11.62 11.62 0 00-6.994-3.11 11.543 11.543 0 00-7.396 1.933 11.71 11.71 0 00-4.606 6.128c-2.084.081-4.07.88-5.625 2.282a8.932 8.932 0 00-2.88 5.365 10.28 10.28 0 00-5.68 4.376 10.312 10.312 0 00-1.466 7.027 10.316 10.316 0 003.461 6.29 10.242 10.242 0 006.708 2.498h6.899v11.663a.702.702 0 00.707.71h14.502s.367.135.5 0c.135-.135.207.189.207 0h2.87c.188 0 .367.135.501 0a.708.708 0 00.206-.503v-11.87h6.77c4.356 0 8.863-3.45 9.9-7.701.904-3.702-.134-7.377-2.691-9.938l-.01.009z"
				/>
			</g>
			<path
				fill="#C3C9D5"
				fillRule="evenodd"
				d="M25.148 48.81c-2.48 0-4.882-.892-6.765-2.507a10.342 10.342 0 01-3.49-6.319 10.412 10.412 0 011.473-7.068 10.421 10.421 0 015.659-4.373 9.04 9.04 0 012.9-5.32 9.002 9.002 0 015.605-2.284 11.755 11.755 0 014.614-6.069 11.754 11.754 0 017.47-1.945 11.79 11.79 0 017.042 3.105 11.781 11.781 0 013.579 6.73 6.447 6.447 0 013.587 1.758 6.365 6.365 0 011.83 3.543 10.277 10.277 0 016.854 3.025l.107.108c2.517 2.597 3.49 6.265 2.606 9.87-1.053 4.266-5.533 7.73-9.986 7.73h-7.52v-.1h-.546V46.91h.546v-.125h7.537c3.517 0 7.096-2.713 7.988-6.06.946-3.543-.375-7.166-3.356-9.237a8.21 8.21 0 00-4.667-1.437h-.688a.832.832 0 01-.58-.24.83.83 0 01-.232-.59 4.35 4.35 0 00-1.205-3.177 4.357 4.357 0 00-3.123-1.339c-.107 0-.214 0-.313-.018a.75.75 0 01-.49-.25.92.92 0 01-.233-.508 9.755 9.755 0 00-2.74-6.123 9.701 9.701 0 00-12.53-1.15 9.71 9.71 0 00-3.81 5.515 1.006 1.006 0 01-1.018.758h-.286a6.97 6.97 0 00-4.801 1.919 7.05 7.05 0 00-2.205 4.677.849.849 0 01-.187.482.865.865 0 01-.446.276c-4.49 1.018-7.274 5.373-6.337 9.933a8.03 8.03 0 001.196 2.847c1.615 2.383 4.159 3.749 6.988 3.749h7.327v2.008h-7.336l-.018-.01zm7.015-.222v-1.562h.107v1.562h-.107zm8.497-23.874h2.008v-2.008H40.66v2.008zM27.442 43.34h-1.785v1.785h1.785V43.34zm3.258 0h-1.785v1.785H30.7V43.34zm13.53-18.626h2.008v-2.008H44.23v2.008zm-7.14 0h2.008v-2.008H37.09v2.008zm21.17 20.527h-7.14v-.116h-.175V43.34h.174v-.107h7.15c1.793 0 3.81-1.401 4.4-3.07.553-1.562.347-3.195-.572-4.49a4.803 4.803 0 00-3.918-2.034c-.91 0-1.803.259-2.58.75l-.097.062-1.08-1.696.098-.062a6.79 6.79 0 013.659-1.071 6.848 6.848 0 015.489 2.784c1.303 1.776 1.65 4.034.973 6.203-.866 2.767-3.437 4.623-6.382 4.623v.01z"
				clipRule="evenodd"
			/>
			<path
				fill="#4CB09C"
				fillRule="evenodd"
				d="M42.749 33.639h-2.16v-4.364l-2.24 1.544-1.303-1.598 3.98-2.749c.366-.259.92-.259 1.294 0l3.98 2.75-1.302 1.597-2.24-1.544v4.364h-.01zm8.032 27.667H32.548a.812.812 0 01-.572-.241.812.812 0 01-.24-.572v-24.48c0-.214.08-.42.24-.571a.812.812 0 01.572-.241H45.23c.267 0 .526.107.714.294l5.354 5.355c.188.188.295.446.295.714v18.93c0 .214-.09.419-.24.57a.812.812 0 01-.572.242zm-17.038-2.008h15.842V42.563h-4.543a.776.776 0 01-.571-.241.811.811 0 01-.24-.572V37.21H33.742v22.088zm12.495-18.742h1.928l-1.928-1.928v1.928zm-10.933 4.462h12.718v12.718H35.305V45.018zm2.008 5.355h3.347v-3.347h-3.347v3.347zm3.347 5.355V52.38h-3.347v3.347h3.347zm5.355-3.347h-3.347v3.347h3.347V52.38zm-3.347-5.355v3.347h3.347v-3.347h-3.347z"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="filter0_d_611_121709"
					width="81.667"
					height="76.371"
					x="0.777"
					y="0.889"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_611_121709" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_611_121709" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};

export default EmptyImportBox;
