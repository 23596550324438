import { removeById, replaceById } from "$root/utils/collections";
import type { DraggableListItemProps } from "@mdotm/mdotui/components";
import { DraggableList } from "@mdotm/mdotui/components";
import type { CommonConstraintData } from "../shared";
import type { ReactNode } from "react";
import { Fragment } from "react";

type ExtendedCommonConstraintData = CommonConstraintData & { _id?: string };

export type ConstraintListSectionItemProps<Constraint extends ExtendedCommonConstraintData> = {
	onChange(newItem: Constraint): void;
	onRemove(id: string): void;
	draggableListItemProps?: DraggableListItemProps<Constraint>;
	item: Constraint;
	index: number;
};

export type ConstraintListSectionProps<Constraint extends ExtendedCommonConstraintData> = {
	constraints: Array<Constraint>;
	onChange(newConstraints: Array<Constraint>): void;
	children: (props: ConstraintListSectionItemProps<Constraint>) => ReactNode;
} & (
	| {
			reOrderable?: false;
	  }
	| {
			reOrderable: true;
	  }
);

export function ConstraintListSection<Constraint extends ExtendedCommonConstraintData>({
	reOrderable = false,
	constraints,
	onChange,
	children,
}: ConstraintListSectionProps<Constraint>): JSX.Element {
	return (
		<>
			{reOrderable ? (
				<DraggableList
					data-qualifier="portfolioWizard/constraintList"
					items={constraints}
					itemKey={(item) => item._id ?? item.id}
					onReorder={onChange}
				>
					{(props) =>
						children({
							draggableListItemProps: props,
							item: props.item,
							index: props.index,
							onRemove: (id) => onChange(removeById(constraints, id, (x) => x.id)),
							onChange: (newItem: Constraint) => onChange(replaceById(constraints, newItem, (x) => x.id)),
						})
					}
				</DraggableList>
			) : (
				constraints.map((constraint, index) => (
					<Fragment key={constraint._id ?? constraint.id}>
						{children({
							item: constraint,
							index,
							onRemove: (id) => onChange(removeById(constraints, id, (x) => x.id)),
							onChange: (newItem: Constraint) => onChange(replaceById(constraints, newItem, (x) => x.id)),
						})}
					</Fragment>
				))
			)}
		</>
	);
}
