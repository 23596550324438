import type { IndicatorType, SentimentType } from "../market-view/analysis/sentiment";

export function categorizeStringAsSentiment(str: string): {
	indicator: IndicatorType;
	sentiment: SentimentType;
} | null {
	const normalisedStr = str.toLowerCase();
	switch (normalisedStr) {
		case "low risk":
		case "low risks":
			return { sentiment: "positive", indicator: "regime" };
		case "mid risk":
		case "mid risks":
			return { sentiment: "neutral", indicator: "regime" };
		case "high risk":
		case "high risks":
			return { sentiment: "negative", indicator: "regime" };
		case "low diversification":
		case "low diversifications":
			return { sentiment: "negative", indicator: "driver" };
		case "mid diversification":
		case "mid diversifications":
			return { sentiment: "neutral", indicator: "driver" };
		case "high diversification":
		case "high diversifications":
			return { sentiment: "positive", indicator: "driver" };
		case "high volatility":
			return { sentiment: "negative", indicator: "driver" };
		case "mid volatility":
			return { sentiment: "neutral", indicator: "driver" };
		case "low volatility":
			return { sentiment: "positive", indicator: "driver" };
		case "low return":
		case "low returns":
			return { sentiment: "negative", indicator: "driver" };
		case "mid return":
		case "mid returns":
			return { sentiment: "neutral", indicator: "driver" };
		case "high return":
		case "high returns":
			return { sentiment: "positive", indicator: "driver" };
		case "overweight":
			return { sentiment: "positive", indicator: "positioning" };
		case "strong overweight":
			return { sentiment: "super-positive", indicator: "positioning" };
		case "underweight":
			return { sentiment: "negative", indicator: "positioning" };
		case "strong underweight":
			return { sentiment: "super-negative", indicator: "positioning" };
		case "neutral":
			return { sentiment: "neutral", indicator: "positioning" };
		default:
			return null;
	}
}

export function applySentimentBadges(text: string): string {
	return text.replace(
		/(low risks?)|(mid risks?)|(high risks?)|(low diversifications?)|(mid diversifications?)|(high diversifications?)|(high volatility)|(low volatility)|(mid volatility)|(low returns?)|(mid returns?)|(high returns?)|((strong )?underweight)|((strong )?overweight)|(neutral)/gi,
		(v) => {
			const data = categorizeStringAsSentiment(v);
			if (!data) {
				return v;
			}
			return `<template data-custom-element="sentiment" data-sentiment="${data.sentiment}" data-indicator="${data.indicator}" data-label="${v}"></template>`;
		},
	);
}

export function sentimentToString(indicator: "regime" | "positioning", sentiment: SentimentType): string {
	switch (indicator) {
		case "regime":
			switch (sentiment) {
				case "super-positive":
					return "Low Risk";
				case "positive":
					return "Low Risk";
				case "neutral":
					return "Mid Risk";
				case "negative":
					return "High Risk";
				case "super-negative":
					return "High Risk";
			}
			break;
		case "positioning":
			switch (sentiment) {
				case "super-positive":
					return "Strong Overweight";
				case "positive":
					return "Overweight";
				case "neutral":
					return "Neutral";
				case "negative":
					return "Underweight";
				case "super-negative":
					return "Strong Underweight";
			}
			break;
	}
	throw new Error(`unknown mapping for indicator "${indicator}" and sentiment "${sentiment}"`);
}
