import type { File } from "$root/hooks/useCompositionDownload";
import type { FileResponse } from "$root/api/api-gen";
import { base64 } from "rfc4648";


export function downloadFileBlob(
	file: File,
	opts?: {
		fileName?: string;
	},
): void {
	if (!file.data) {
		throw new Error("missing file");
	}

	downloadBlob(file.data, {
		...opts,
		fileName: opts?.fileName ?? file.name ?? "Untitled",
	});
}

export function downloadFileResponse(
	fileResponse: FileResponse,
	opts?: {
		fileName?: string;
	},
): void {
	const buf = new Uint8Array(fileResponse.data ?? base64.parse(fileResponse.realData as unknown as string));
	const blob = new Blob([buf], { type: "application/octet-stream" });
	downloadBlob(blob, {
		...opts,
		fileName: opts?.fileName ?? fileResponse.filename ?? "Untitled",
	});
}



export function downloadBlob(
	blob: Blob,
	opts: {
		fileName: string;
	},
): void {
	const url = window.URL.createObjectURL(blob);

	const anchor = window.document.createElement("a");
	anchor.tabIndex = -1;
	anchor.style.opacity = "0";
	anchor.style.zIndex = "-1";
	anchor.style.position = "absolute";
	anchor.style.height = "0px";
	anchor.style.width = "0px";
	anchor.style.top = "0px";
	anchor.style.left = "0px";
	anchor.style.overflow = "hidden";
	anchor.href = url;
	anchor.download = opts.fileName;
	document.body.appendChild(anchor);
	anchor.click();
	setTimeout(() => {
		document.body.removeChild(anchor);
		URL.revokeObjectURL(url);
	});
}
