import type { UploadError, UploadErrorCodes } from "$root/api/api-gen";
import { uploadErrorMap } from "$root/components/Portfolio/UploadPortfolio/uploadErrorMap";
import { formatNumber } from "$root/localization/formatters";
import { Banner } from "@mdotm/mdotui/components";
import { stableEmptyArray } from "@mdotm/mdotui/utils";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const ReviewCompositionErrors: FC<{ errors?: Array<UploadError> }> = ({
	errors = stableEmptyArray as Array<UploadError>,
}) => {
	const { t } = useTranslation();

	const handleDownloadErrorReport = useCallback(
		(report?: Array<UploadError>) => {
			if (!report) {
				return;
			}

			const mapReport = report.map(({ code, weight, alias }) => ({
				identifier: alias,
				weight,
				code: code ? t(code) : "-",
			}));
			const reportWS = XLSX.utils.json_to_sheet(mapReport);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
			XLSX.writeFile(wb, "Info.xlsx");
		},
		[t],
	);

	// const IconAlert: Record<"warning" | "info", ElementType> = {
	// 	warning: () => <Icon icon="Icon-full-error" color={PaletteColors.ALERT_RED} />,
	// 	info: () => <Icon icon="info" color={PaletteColors.AZURE} />,
	// };

	const globalUploadStateErrors = useMemo(
		() =>
			errors
				.filter((el) => el.code && uploadErrorMap[el.code].isAliasReferred === false)
				.map((el) => ({
					mappedError: uploadErrorMap[el.code ?? "INVALID_WORKBOOK"],
					errorData: el,
				})),
		[errors],
	);

	const mappedLocalError = (errors ?? []).reduce<{ [keys in UploadErrorCodes]?: Array<UploadError> }>((acc, el) => {
		const { code } = el;
		if (!code) {
			return acc;
		}
		if (!uploadErrorMap[code].isAliasReferred) {
			return acc;
		}

		if (!acc[code]) {
			acc[code] = [];
		}
		acc[code]?.push(el);

		return acc;
	}, {});

	const PTF_ERRORS = t("PORTFOLIO_UPLOAD_ALERTS", { returnObjects: true });

	return (
		<>
			{globalUploadStateErrors && (
				<>
					{globalUploadStateErrors.map((el, i) => (
						<div className="mb-4" key={i}>
							<Banner
								severity={el.mappedError.type === "error" ? "error" : "info"}
								title={
									el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
									el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
										<Trans
											i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.title`}
											values={{
												weight: formatNumber(el.errorData.weight ?? 0),
												remainingWeight: formatNumber(100 - (el.errorData.weight ?? 0), 2),
											}}
										/>
									) : undefined
								}
							>
								{el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
								el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
									<>
										<div className="mt-1">
											<Trans i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.description`} />
										</div>
									</>
								) : (
									<>
										{
											(
												PTF_ERRORS[el.mappedError.translationKey as keyof typeof PTF_ERRORS] as {
													title: string;
													description: string;
												}
											).title
										}
									</>
								)}
							</Banner>
						</div>
					))}
				</>
			)}
			{mappedLocalError && Object.keys(mappedLocalError).length > 0 && (
				<>
					{Object.keys(mappedLocalError).map((key) => {
						const localError = mappedLocalError[key as UploadErrorCodes];
						const { translationKey, type } = uploadErrorMap[key as UploadErrorCodes];
						const typedTranslationKey = translationKey as keyof typeof PTF_ERRORS;
						// const AlertIcon = type === "error" ? IconAlert["warning"] : IconAlert["info"];

						const translation = t(`PORTFOLIO_UPLOAD_ALERTS`, {
							returnObjects: true,
							instrumentNumber: localError?.length ?? 0,
						})[typedTranslationKey] as string | { title: string; description: string };
						const title = typeof translation !== "string" ? translation.title : translation;
						const description = typeof translation !== "string" ? translation.description : "";

						return (
							<div className="mb-4" key={key}>
								<Banner severity={type === "error" ? "error" : "info"} title={title}>
									<Trans
										defaults={description}
										components={{
											download: (
												<button
													type="button"
													className="font-medium underline"
													disabled={!localError}
													onClick={() => handleDownloadErrorReport(localError)}
												/>
											),
										}}
									/>
								</Banner>
							</div>
						);
					})}
				</>
			)}
		</>
	);
};

export default ReviewCompositionErrors;
