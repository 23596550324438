import type { XAxisPlotLinesOptions } from "highcharts";

export type Tband = {
	color: string;
	from: number;
	to: number;
};

export type TgradientColorSeries = {
	linearGradient: {
		x1: number;
		x2: number;
		y1: number;
		y2: number;
	};
	stops: (string | number)[][];
};

export type TseriesSanitaized = {
	borderColor?: string;
	borderWidth?: number;
	acrossPanes?: boolean;
	color: TgradientColorSeries;
	from: number;
	to: number;
};

type TBandsColors = {
	[key: string]: string;
};

export const DateFormatter = (yyyymmdd: string) => {
	const date = new Date(`${yyyymmdd.slice(0, 4)}-${yyyymmdd.slice(4, 6)}-${yyyymmdd.slice(-2)}T00:00:00Z`).getTime();
	return date;
};

const generateSubPlotLinesFromYear = (i: number, startDate: number): XAxisPlotLinesOptions[] => {
	const composePlotLinesObj = (i: number, date: number, month: string): XAxisPlotLinesOptions => ({
		zIndex: 1,
		color: month === "0101" ? "#000" : "#666666",
		width: 1,
		dashStyle: month === "0101" ? "Solid" : "ShortDash",
		value: DateFormatter(String(date + i).concat(month)),
	});
	return ["0101", "0401", "0701", "1001"].map((date) => composePlotLinesObj(i, startDate, date));
};

export const generatePlotLinesFromBands = (start: number, end: number) => {
	const endDate = new Date(end).getFullYear();
	const startDate = new Date(start).getFullYear();
	const plotLines: XAxisPlotLinesOptions[] = [];
	//add plus 1 because index start from 0
	const arrayLength = endDate - startDate + 1;
	for (let i = 0; i < arrayLength; i++) {
		generateSubPlotLinesFromYear(i, startDate).forEach((el) => plotLines.push(el));
	}

	return plotLines;
};

const bandsColors: TBandsColors = {
	"0": "#e5e7eb",
	"-1": "#FCFCFC",
	"1": "#94D0C4",
	"2": "#FFD966",
	"3": "#F28C79",
};

const compareJSObjects = (obj1: number[], obj2: number[]) => obj1.toString() === obj2.toString();

const retriveRange = (serie: number[], currentIndex: number, step: number) => {
	return [
		[Number((currentIndex * step).toFixed(2)), bandsColors[String(serie[currentIndex])]],
		[Number((currentIndex * step + step).toFixed(2)), bandsColors[String(serie[currentIndex])]],
	];
};

const convertSeriesToRgbs = (serie: number[]) => {
	const stops = [];
	const step = 1 / serie.length;
	for (let i = 0; i < serie.length; i++) {
		const range = retriveRange(serie, i, step);
		stops.push(...range);
	}
	return stops;
};

const compareSeries = (
	current: number[],
	next: number[],
	from: number,
	to: number,
	isLastSeries: boolean,
): TseriesSanitaized | undefined => {
	const areObjectsEquals = compareJSObjects(current, next);
	if (!areObjectsEquals || isLastSeries) {
		const linearGradients = convertSeriesToRgbs(current);
		return {
			borderWidth: 0,
			color: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: linearGradients,
			},
			from,
			to,
		};
	}
};

export const processSectors = (series: number[][], timeinms: string[]) => {
	const sanitzedSeries: TseriesSanitaized[] = [];
	let startDate: number = DateFormatter(timeinms[0]);

	for (let i = 0; i < series.length; i++) {
		const currentSeries = series[i];
		const nextIndex = i === series.length - 1 ? i : i + 1;
		const nextSeries = series[nextIndex];
		const dateInMs = DateFormatter(timeinms[nextIndex]);
		const sanitaizedSerie = compareSeries(currentSeries, nextSeries, startDate, dateInMs, i === series.length - 1);
		if (sanitaizedSerie) {
			startDate = dateInMs;
			sanitzedSeries.push(sanitaizedSerie);
		}
	}

	return sanitzedSeries;
};
