import { hasAccess } from "$root/components/AuthorizationGuard";
import { CopyableText } from "$root/components/CopyableText";
import CustomLabelsEditor from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import PortfolioExposureSummary from "$root/functional-areas/compare-portfolio/PortfolioExposureSummary";
import type { ReadOnlyTagOptions } from "$root/functional-areas/instruments/edit-tags";
import { TagButton } from "$root/functional-areas/instruments/edit-tags";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Controller,
	Icon,
	NullableNumberInput,
	TextInput,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { Set } from "immutable";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { CompositionEntry } from "./shared";
import { isIdentifierCodeValid } from "./shared";
import type { UseEditorBuilderResult } from "./useEditorBuilder";
import type { CompositionEditorAreaProps } from "../EditorComposition";

// TODO:change all the onChange to one onChange
type CommonEditorColumnsKey = "name" | "assetClass" | "microAssetClass" | "alias";
export type InstrumentEditorColumnsProps = {
	compositionBuilder: UseEditorBuilderResult;
	area: CompositionEditorAreaProps;
	onDelete?(id: string): void;
} & (
	| {
			entity: "INVESTMENT" | "TARGET_INVESTMENT" | "BENCHMARK" | "INVESTMENT_DRAFT";
			onChangeIdentifier?(key: string, identifier: string): void;
			onChangeWeight?(key: string, weight: number | null): void;
	  }
	| {
			entity: "INVESTMENT_ENHANCEMENT";
			onChangeWeight?(key: string, weight: number | null): void;
	  }
	| {
			entity: "UNIVERSE";
			existingTags: ReadOnlyTagOptions;
			onChangeIdentifier?(key: string, identifier: string): void;
			onChangeTag?(key: string, tagLabel?: string): void;
			onChangeScore?(key: string, number: number | null): void;
	  }
);

export function useInstrumentEditorColumns(props: InstrumentEditorColumnsProps): TableColumn<CompositionEntry>[] {
	const { compositionBuilder } = props;
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const user = useUserValue();

	const deleted = compositionBuilder.getDeleted();
	const totalWeight = compositionBuilder.getTotalWeight();

	const composition = useMemo(
		() => Array.from(compositionBuilder.getComposition().removeAll(deleted).values()),
		[compositionBuilder, deleted],
	);
	const currentIdentifiers = useMemo(() => {
		return Set(composition.flatMap((x) => (x.alias ? [x.alias] : [])));
	}, [composition]);

	const score = useMemo(() => {
		return composition.flatMap((x) => (x.score !== undefined || x.score !== null ? [x.score] : []));
	}, [composition]);

	const tags = useMemo(() => {
		return composition.flatMap((x) => (!x.tagLabel ? [x.tagLabel] : []));
	}, [composition]);

	const commonColumns = useMemo<Record<CommonEditorColumnsKey, TableColumn<CompositionEntry>>>(
		() => ({
			name: {
				header: "name",
				content: (row) => row.instrument ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
			},
			assetClass: {
				header: "Asset class",
				content: (row) => row.assetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("assetClass"),
				name: "assetClass",
			},
			microAssetClass: {
				header: "Micro asset class",
				content: (row) => row.microAssetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
			alias: {
				header: "Identifier",
				content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
		}),
		[],
	);

	const defaultColumns = useCallback(
		(
			onChangeIdentifier?: (key: string, identifier: string) => void,
			onChangeWeight?: (key: string, weight: number | null) => void,
			onDelete?: (keys: string) => void,
		): Array<TableColumn<CompositionEntry>> => {
			const cellClassList = ({ id }: CompositionEntry) => ({
				"line-through opacity-50": deleted.has(id ?? "-"),
			});
			return [
				{
					header: "name",
					content: (row) => {
						if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
							return row.instrument ?? "-";
						}

						const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
							quality: x.firstQualityLevel,
							weight: x.weight,
						}));

						return (
							<AutoTooltip
								overrideColor={themeCSSVars.palette_N300}
								position="right"
								trigger={({ innerRef }) => (
									<span ref={innerRef} className="font-semibold">
										{row.instrument ?? "-"}
									</span>
								)}
							>
								<TooltipContent>
									<div className="w-[280px]">
										<PortfolioExposureSummary
											composition={exposureComposition ?? []}
											onCompare={(e) => {
												e.stopPropagation();
												console.log("compare");
											}}
											title={row.instrument ?? "-"}
										/>
									</div>
								</TooltipContent>
							</AutoTooltip>
						);
					},
					sortFn: builtInCaseInsensitiveSortFor("instrument"),
					name: "instrument",
					relativeWidth: 3.8,
				},
				{
					header: "identifier",
					content: (instrument) => {
						if (instrument.rowType === "ADD") {
							return (
								<Controller value={instrument.alias} onChange={(alias) => onChangeIdentifier?.(instrument.id, alias)}>
									{function RenderBody({ value, onCommit, onChange }) {
										const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
										const isDuplicated = useMemo(() => {
											if (currentIdentifiers.has(value)) {
												return true;
											}
											return Boolean(
												currentIdentifiers.find((v, k) => {
													if (k === instrument.id || deleted.has(instrument.id)) {
														return false;
													}
													return v === value;
												}),
											);
										}, [value]);

										return (
											<AutoTooltip
												severity="error"
												disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
												trigger={({ innerRef }) => (
													<TextInput
														size="x-small"
														value={value}
														onChangeText={onChange}
														onBlur={() => onCommit(value)}
														classList={{
															"w-full": true,
															[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
																value.length > 0 && (isIdentifierValid === false || isDuplicated),
														}}
														placeholder="Insert a valid identifier"
														disabled={deleted.has(instrument.id)}
														innerRef={innerRef}
														name="identifiers"
														onClick={(e) => e.stopPropagation()}
														data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
													/>
												)}
											>
												{isDuplicated
													? "identifier is duplicated"
													: isIdentifierValid === false
													  ? "insert a valid identifier"
													  : ""}
											</AutoTooltip>
										);
									}}
								</Controller>
							);
						}

						return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? ""} />;
					},
					sortFn: builtInSortFnFor("alias"),
					name: "alias",
					cellClassList,
				},
				{ ...commonColumns.assetClass, cellClassList },
				{
					...commonColumns.microAssetClass,
					cellClassList,
					footerCellClassList: "!justify-end font-semibold",
					footer: compositionBuilder.getComposition().toArray().length > 0 ? "Total" : undefined,
				},
				{
					header: "weight",
					align: "end",
					cellClassList: "tabular-nums",
					content: (instrument) => (
						<div className="flex flex-1 gap-2 justify-end">
							<Controller
								value={instrument.weight?.toNumber() ?? null}
								onChange={(weight) => onChangeWeight?.(instrument.id, weight)}
							>
								{({ value, onChange, onCommit }) => (
									<NullableNumberInput
										classList="grow"
										min={0}
										max={100}
										step={0.01}
										inputAppearance={{
											classList: "text-right",
										}}
										disabled={deleted.has(instrument.id ?? "-")}
										size="x-small"
										value={value}
										onChange={(v) => onChange(v ?? 0)}
										onBlur={() => onCommit(value)}
										rightContent={<Icon icon="Percentile" />}
										name="weights"
										onClick={(e) => e.stopPropagation()}
										data-qualifier="CompositionEditor/Table/Column(Weight)/Input"
									/>
								)}
							</Controller>
							<CommonItemActions.DeleteRestore
								deleted={deleted}
								item={instrument.id ?? "-"}
								onDeletedChange={() => onDelete?.(instrument.id)}
							/>
						</div>
					),
					footerCellClassList: "font-semibold",
					footer: () =>
						compositionBuilder.getComposition().toArray().length > 0
							? `${formatNumber(totalWeight.toNumber())}%`
							: undefined,
				},
			];
		},
		[
			commonColumns.assetClass,
			commonColumns.microAssetClass,
			compositionBuilder,
			currentIdentifiers,
			deleted,
			formatNumber,
			totalWeight,
		],
	);

	const investmentEnhancementColumns = useCallback(
		(
			onChangeWeight?: (key: string, weight: number | null) => void,
			onDelete?: (keys: string) => void,
		): Array<TableColumn<CompositionEntry>> => {
			const cellClassList = ({ id }: CompositionEntry) => ({
				"line-through opacity-50": deleted.has(id ?? "-"),
			});
			return [
				{ ...commonColumns.name, cellClassList, relativeWidth: 3.8 },
				{
					header: "identifier",
					content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
					sortFn: builtInSortFnFor("alias"),
					name: "alias",
					cellClassList,
				},
				{ ...commonColumns.assetClass, cellClassList },
				{ ...commonColumns.microAssetClass, cellClassList },
				{
					header: "Weigth current",
					align: "end",
					cellClassList: "tabular-nums",
					content: (row) => (row.previousWeight ? `${formatNumber(row.previousWeight)}%` : ""),
					sortFn: builtInSortFnFor("previousWeight"),
					name: "previousWeight",
				},
				{
					header: "Optimised",
					align: "end",
					cellClassList: "tabular-nums",
					content: (row) => (row.weight ? `${formatNumber(row.weight?.toNumber())}%` : ""),
					sortFn: builtInSortFnFor("weight"),
					name: "weight",
				},
				{
					header: "Difference",
					align: "end",
					cellClassList: "tabular-nums",
					content: (instrument) => {
						const deltaWeight = Number((instrument.weight?.toNumber() ?? 0) - (instrument.previousWeight ?? 0)).toFixed(
							2,
						);
						return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight?.toNumber() ?? 0} />;
					},
					width: 110,
					footerCellClassList: "font-semibold",
					footer: () => "Total",
					name: "difference",
					sortFn: (rowa, rowb) => {
						const deltaA = Math.round((rowa.weight?.toNumber() ?? 0) - (rowa.previousWeight ?? 0)).toFixed(2);
						const deltaB = Math.round((rowb.weight?.toNumber() ?? 0) - (rowb.previousWeight ?? 0)).toFixed(2);

						if (deltaA > deltaB) {
							return 1;
						}

						if (deltaA < deltaB) {
							return -1;
						}

						return 0;
					},
				},
				{
					header: "Custom weights",
					align: "end",
					content: (instrument) => (
						<div className="flex flex-1 gap-2">
							<Controller
								value={instrument.weight?.toNumber() ?? null}
								onChange={(weight) => onChangeWeight?.(instrument.id, weight)}
							>
								{({ value, onChange, onCommit }) => (
									<NullableNumberInput
										min={0}
										max={100}
										step={0.01}
										inputAppearance={{ classList: "text-right" }}
										disabled={deleted.has(instrument.id ?? "-")}
										size="x-small"
										value={value}
										onChange={(v) => onChange(v ?? 0)}
										onBlur={() => onCommit(value)}
										rightContent={<Icon icon="Percentile" />}
										name="weigths"
										onClick={(e) => e.stopPropagation()}
										data-qualifier="CompositionEditor/Table/Column(CustomWeight)/Input"
									/>
								)}
							</Controller>
						</div>
					),
					width: 100,
					footerCellClassList: "font-semibold",
					footer: () => `${formatNumber(totalWeight.toNumber())}%`,
				},
				{
					header: "",
					align: "end",
					content: (instrument) => (
						<CommonItemActions.DeleteRestore
							deleted={deleted}
							item={instrument.id ?? "-"}
							onDeletedChange={() => onDelete?.(instrument.id)}
						/>
					),
				},
			];
		},
		[commonColumns.assetClass, commonColumns.microAssetClass, commonColumns.name, deleted, formatNumber, totalWeight],
	);

	const universeColumns = useCallback(
		(
			existingTags: ReadOnlyTagOptions,
			onChangeIdentifier?: (key: string, identifier: string) => void,
			onDelete?: (keys: string) => void,
			onChangeTag?: (key: string, tagLabel?: string) => void,
			onChangeScore?: (key: string, number: number | null) => void,
		): Array<TableColumn<CompositionEntry>> => {
			const cellClassList = ({ id }: CompositionEntry) => ({
				"line-through opacity-50": deleted.has(id ?? "-"),
			});
			return [
				{ ...commonColumns.name, cellClassList, relativeWidth: 3.6 },
				{
					header: "identifier",
					content: (instrument) => {
						if (instrument.rowType === "ADD") {
							return (
								<Controller
									value={instrument.alias}
									onChange={(identifier) => onChangeIdentifier?.(instrument.id, identifier)}
								>
									{function RenderBody({ value, onCommit, onChange }) {
										const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
										const isDuplicated = useMemo(() => {
											if (currentIdentifiers.has(value)) {
												return true;
											}
											return Boolean(
												currentIdentifiers.find((v, k) => {
													if (k === instrument.id || deleted.has(instrument.id)) {
														return false;
													}
													return v === value;
												}),
											);
										}, [value]);

										return (
											<AutoTooltip
												severity="error"
												disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
												trigger={({ innerRef }) => (
													<TextInput
														size="x-small"
														value={value}
														onChangeText={onChange}
														onBlur={() => onCommit(value)}
														classList={{
															[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
																value.length > 0 && (isIdentifierValid === false || isDuplicated),
														}}
														placeholder="Insert a valid identifier"
														disabled={deleted.has(instrument.id)}
														innerRef={innerRef}
														name="identifiers"
														onClick={(e) => e.stopPropagation()}
														data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
													/>
												)}
											>
												{isDuplicated
													? "identifier is duplicated"
													: isIdentifierValid === false
													  ? "insert a valid identifier"
													  : ""}
											</AutoTooltip>
										);
									}}
								</Controller>
							);
						}

						return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? "-"} />;
					},
					sortFn: builtInSortFnFor("alias"),
					name: "alias",
					cellClassList: (instrument) => ({
						...cellClassList(instrument),
						"flex-grow": true,
					}),
					relativeWidth: 1.5,
				},
				{ ...commonColumns.assetClass, cellClassList, relativeWidth: 1.9 },
				{
					...commonColumns.microAssetClass,
					cellClassList,
					relativeWidth: 1.5,
					footerCellClassList: "flex justify-end font-semibold",
					footer: () => `Number of instrument`,
				},
				{
					header: "Tags",
					content: ({ tagLabel, id }) => {
						// const currentTag = compositionBuilder.getTags().get(id);
						return (
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
							<div onClick={(e) => e.stopPropagation()}>
								<TagButton
									options={existingTags}
									color={existingTags.find(({ value }) => value === tagLabel)?.color}
									value={tagLabel ?? null}
									onClick={(newTag, e) => {
										e?.stopPropagation();
										onChangeTag?.(id, newTag);
									}}
									disabled={deleted.has(id)}
									enableDebounce
								/>
							</div>
						);
					},
					relativeWidth: 1.5,
					footerCellClassList: "font-semibold",
					footer: () => `Tagged: ${tags.length}`,
				},
				{
					header:
						props.area.name === "edit" && props.area.uuid ? (
							<CustomLabelsEditor labelKey={`${props.area.uuid}_score1`} fallback={t("SCORE")} mode="view" />
						) : (
							t("SCORE")
						),
					cellClassList: " w-full",
					content: (row) => (
						<div className="[&>div]:w-full [&>div]:flex flex">
							<Controller value={row.score ?? null} onChange={(newScore) => onChangeScore?.(row.id, newScore)}>
								{({ value, onChange, onCommit }) => (
									<NullableNumberInput
										min={0}
										max={100}
										step={0.01}
										inputAppearance={{ classList: "text-right" }}
										disabled={deleted.has(row.id ?? "-")}
										size="x-small"
										value={value}
										onKeyDown={(e) => {
											if (e.repeat) {
												e.preventDefault();
											}
										}}
										onChange={(v) => {
											const convertedNumber = String(v);
											if (convertedNumber.includes(".") && convertedNumber.split(".")[1].length >= 2) {
												const [integers, decimals] = convertedNumber.split(".");
												const lastDecimalNumber = decimals.at(-1);
												const firstDecimalNumber = decimals.at(0);
												const newValue = Number(`${integers}.${firstDecimalNumber}${lastDecimalNumber}`);
												onChange(newValue);
												return;
											}
											onChange(v);
										}}
										onBlur={() => onCommit(value)}
										name="score"
										onClick={(e) => e.stopPropagation()}
										data-qualifier="CompositionEditor/Table/Column(Score)/Input"
									/>
								)}
							</Controller>
						</div>
					),
					relativeWidth: 1,
					footerCellClassList: "font-semibold",
					footer: () => `Scored: ${score.length}`, // change score
					hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
				},
				{
					header: "",
					align: "end",
					content: (instrument) => (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						<span onClick={(e) => e.stopPropagation()}>
							<CommonItemActions.DeleteRestore
								deleted={deleted}
								item={instrument.id ?? "-"}
								onDeletedChange={() => onDelete?.(instrument.id)}
							/>
						</span>
					),
					relativeWidth: 0.3,
				},
			];
		},
		[
			commonColumns.assetClass,
			commonColumns.microAssetClass,
			commonColumns.name,
			currentIdentifiers,
			deleted,
			props.area,
			score.length,
			t,
			tags.length,
			user,
		],
	);

	return useMemo(() => {
		if (props.entity === "UNIVERSE") {
			return universeColumns(
				props.existingTags,
				props.onChangeIdentifier,
				props.onDelete,
				props.onChangeTag,
				props.onChangeScore,
			);
		}

		if (props.entity === "INVESTMENT_ENHANCEMENT") {
			return investmentEnhancementColumns(props.onChangeWeight, props.onDelete);
		}

		return defaultColumns(props.onChangeIdentifier, props.onChangeWeight, props.onDelete);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultColumns, investmentEnhancementColumns, universeColumns, props.entity, props.onDelete]);
}
