import { useTranslation } from "react-i18next";
import type { DefaultTabTitleProps } from "@mdotm/mdotui/components";
import { Tab, TabGroup, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import type { RefObject } from "react";
import { useState } from "react";
import { stableEmptyObject } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";

const tabTitle = (title: string, activeBgClass: string) =>
	function TabTitle(titleProps: DefaultTabTitleProps) {
		return (
			<button
				ref={titleProps.innerRef as RefObject<HTMLButtonElement>}
				type="button"
				className={toClassName({
					"mt-auto block": true,
					"ml-2": !titleProps.active && titleProps.index === 0,
					"mx-2": !titleProps.active && titleProps.index === 1,
					"mr-2": !titleProps.active && titleProps.index === 2,
				})}
				{...titleProps}
			>
				<span
					className={toClassName({
						"flex items-center relative border-t border-x border-[#D1D2DC] rounded-t": true,
						"pt-1.5 px-1.5": titleProps.active,
					})}
				>
					<span
						className={toClassName({
							"block transition-colors text-[11px] font-medium uppercase px-10 min-w-[9.3rem] pt-2.5 pb-2 rounded-t":
								true,
							[`${activeBgClass} text-white`]: titleProps.active,
							[`text-[color:${themeCSSVars.palette_N400}]`]: !titleProps.active,
						})}
					>
						{title}
					</span>
				</span>
			</button>
		);
	};

export type ScenarioTabsProps = {
	onTabChange(index: number): void;
	tabIndex: number;
};

export default function ScenarioTabs({ onTabChange, tabIndex }: ScenarioTabsProps): JSX.Element {
	const { t } = useTranslation();
	const [tabIndicatorData, setTabIndicatorData] = useState<{ left?: number; width?: number }>(stableEmptyObject);

	return (
		<div style={{ width: "100%", minHeight: 150 }}>
			<div aria-label="Scenario tabs" className="relative z-0">
				<div className="absolute z-10 inset-x-0 top-[41px] border-b border-b-[#D1D2DC]" />
				<div className="absolute z-10 inset-x-0 top-[41px] border-b border-b-white" style={tabIndicatorData} />
				<div className="relative z-0">
					<TabGroup
						enableTransitions={false}
						tabIndex={tabIndex}
						onTabChange={onTabChange}
						indicator={(indicatorProps) => {
							setTabIndicatorData((cur) => (fastDeepEqual(cur, indicatorProps) ? cur : indicatorProps));
							return null;
						}}
					>
						<Tab
							classList={{ "mt-4": false, "mt-2.5": true }}
							title={tabTitle(t("SCENARIO_DESCRIPTION.SCENARIOS.GROWTH.TITLE"), `bg-[${themeCSSVars.palette_P500}]`)}
						>
							<Text as="p">{t("SCENARIO_DESCRIPTION.SCENARIOS.GROWTH.DESCRIPTION")}</Text>
						</Tab>
						<Tab
							classList={{ "mt-4": false, "mt-2.5": true }}
							title={tabTitle(
								t("SCENARIO_DESCRIPTION.SCENARIOS.LATERAL_PHASE.TITLE"),
								`bg-[${themeCSSVars.palette_A500}]`,
							)}
						>
							<Text as="p">{t("SCENARIO_DESCRIPTION.SCENARIOS.LATERAL_PHASE.DESCRIPTION")}</Text>
						</Tab>
						<Tab
							classList={{ "mt-4": false, "mt-2.5": true }}
							title={tabTitle(
								t("SCENARIO_DESCRIPTION.SCENARIOS.HIGH_STRESS.TITLE"),
								`bg-[${themeCSSVars.palette_D500}]`,
							)}
						>
							<Text as="p">{t("SCENARIO_DESCRIPTION.SCENARIOS.HIGH_STRESS.DESCRIPTION")}</Text>
						</Tab>
					</TabGroup>
				</div>
			</div>
		</div>
	);
}
