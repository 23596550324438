/* tslint:disable */
/* eslint-disable */
/**
 * mdotm-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcMetrics
 */
export interface AcMetrics {
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'returnYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'return1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'return3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'return6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'return1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcMetrics
     */
    'mdotm_trace'?: number;
}
/**
 * 
 * @export
 * @interface AccessControl
 */
export interface AccessControl {
    /**
     * 
     * @type {string}
     * @memberof AccessControl
     */
    'userId'?: string;
    /**
     * 
     * @type {Set<AccessPermission>}
     * @memberof AccessControl
     */
    'permissions'?: Set<AccessPermission>;
    /**
     * 
     * @type {string}
     * @memberof AccessControl
     */
    'roleBasedOnPermissions'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessPermission = {
    Read: 'READ',
    Delete: 'DELETE',
    Grantor: 'GRANTOR',
    CreateProposal: 'CREATE_PROPOSAL',
    EditComposition: 'EDIT_COMPOSITION',
    EditSettings: 'EDIT_SETTINGS',
    Owner: 'OWNER',
    TransferOwnership: 'TRANSFER_OWNERSHIP'
} as const;

export type AccessPermission = typeof AccessPermission[keyof typeof AccessPermission];


/**
 * 
 * @export
 * @interface AclEditStarter
 */
export interface AclEditStarter {
    /**
     * 
     * @type {Array<RichAccessControl>}
     * @memberof AclEditStarter
     */
    'customerEntityAcl'?: Array<RichAccessControl>;
    /**
     * 
     * @type {Array<AclEntityMinInfo>}
     * @memberof AclEditStarter
     */
    'linkedAclEntities'?: Array<AclEntityMinInfo>;
}
/**
 * 
 * @export
 * @interface AclEntityMinInfo
 */
export interface AclEntityMinInfo {
    /**
     * 
     * @type {string}
     * @memberof AclEntityMinInfo
     */
    'entityType'?: AclEntityMinInfoEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AclEntityMinInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AclEntityMinInfo
     */
    'identifier'?: string;
}

export const AclEntityMinInfoEntityTypeEnum = {
    Investment: 'INVESTMENT',
    Universe: 'UNIVERSE',
    Benchmark: 'BENCHMARK',
    MarketView: 'MARKET_VIEW'
} as const;

export type AclEntityMinInfoEntityTypeEnum = typeof AclEntityMinInfoEntityTypeEnum[keyof typeof AclEntityMinInfoEntityTypeEnum];

/**
 * 
 * @export
 * @interface AiConversationDto
 */
export interface AiConversationDto {
    /**
     * 
     * @type {string}
     * @memberof AiConversationDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiConversationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiConversationDto
     */
    'creationTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AiConversationDto
     */
    'favourite'?: boolean;
    /**
     * 
     * @type {Array<AiConversationMessage>}
     * @memberof AiConversationDto
     */
    'messages'?: Array<AiConversationMessage>;
    /**
     * 
     * @type {string}
     * @memberof AiConversationDto
     */
    'lastMessageTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiConversationDto
     */
    'firstMessageContent'?: string;
}
/**
 * 
 * @export
 * @interface AiConversationMessage
 */
export interface AiConversationMessage {
    /**
     * 
     * @type {string}
     * @memberof AiConversationMessage
     */
    'type'?: AiConversationMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiConversationMessage
     */
    'sender'?: AiConversationMessageSenderEnum;
    /**
     * 
     * @type {string}
     * @memberof AiConversationMessage
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiConversationMessage
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiConversationMessage
     */
    'uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AiConversationMessage
     */
    'favourite'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AiConversationMessage
     */
    'feedback'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AiConversationMessage
     */
    'clarificationRequest'?: boolean;
}

export const AiConversationMessageTypeEnum = {
    UserInput: 'USER_INPUT',
    AiResponse: 'AI_RESPONSE',
    AiInputReformulation: 'AI_INPUT_REFORMULATION',
    AiError: 'AI_ERROR'
} as const;

export type AiConversationMessageTypeEnum = typeof AiConversationMessageTypeEnum[keyof typeof AiConversationMessageTypeEnum];
export const AiConversationMessageSenderEnum = {
    User: 'USER',
    Ai: 'AI'
} as const;

export type AiConversationMessageSenderEnum = typeof AiConversationMessageSenderEnum[keyof typeof AiConversationMessageSenderEnum];

/**
 * 
 * @export
 * @interface AlertDto
 */
export interface AlertDto {
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'type'?: string;
    /**
     * 
     * @type {AlertValueDto}
     * @memberof AlertDto
     */
    'value'?: AlertValueDto;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'element'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'previousColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'delta'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'scoreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'currentColor'?: string;
    /**
     * 
     * @type {Array<DriverDto>}
     * @memberof AlertDto
     */
    'drivers'?: Array<DriverDto>;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'isinNew'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'isinOld'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'marketScenario'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'sphereForecast'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'entity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'constraintRelation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'minThreshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'investmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'maxThreshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'minDelta'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'maxDelta'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'horizon'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'enhanceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'threshold'?: number;
}
/**
 * 
 * @export
 * @interface AlertValueDto
 */
export interface AlertValueDto {
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'sphereForecast'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'marketScenario'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'isinOld'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'isinNew'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'enhanceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'horizon'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'userSet'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'currentValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'previousValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'element'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'currentColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'previousColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertValueDto
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'threshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'url'?: string;
    /**
     * 
     * @type {Array<DriverDto>}
     * @memberof AlertValueDto
     */
    'drivers'?: Array<DriverDto>;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'scoreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'constraintRelation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'entity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'investmentName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertValueDto
     */
    'delta'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'minThreshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'maxThreshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'minDelta'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'maxDelta'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertValueDto
     */
    'commentary'?: string;
}
/**
 * 
 * @export
 * @interface AllocationConstraints
 */
export interface AllocationConstraints {
    /**
     * 
     * @type {Array<AssetClassesConstraint>}
     * @memberof AllocationConstraints
     */
    'assetClassConstraints'?: Array<AssetClassesConstraint>;
    /**
     * 
     * @type {Array<InstrumentsConstraint>}
     * @memberof AllocationConstraints
     */
    'instrumentsConstraints'?: Array<InstrumentsConstraint>;
    /**
     * 
     * @type {Array<TagsConstraint>}
     * @memberof AllocationConstraints
     */
    'tagsConstraints'?: Array<TagsConstraint>;
    /**
     * 
     * @type {Array<CurrencyConstraint>}
     * @memberof AllocationConstraints
     */
    'currencyConstraints'?: Array<CurrencyConstraint>;
    /**
     * 
     * @type {InstrumentMaxWeightConstraint}
     * @memberof AllocationConstraints
     */
    'instrumentMaxWeightConstraint'?: InstrumentMaxWeightConstraint;
    /**
     * 
     * @type {ScoresConstraint}
     * @memberof AllocationConstraints
     */
    'scoresConstraint'?: ScoresConstraint;
    /**
     * 
     * @type {LockInstrumentConstraint}
     * @memberof AllocationConstraints
     */
    'lockGroupConstraint'?: LockInstrumentConstraint;
    /**
     * 
     * @type {Array<ForEachConstraint>}
     * @memberof AllocationConstraints
     */
    'forEachConstraints'?: Array<ForEachConstraint>;
    /**
     * 
     * @type {ValidationOperation}
     * @memberof AllocationConstraints
     */
    'pendingValidationOperation'?: ValidationOperation;
}
/**
 * 
 * @export
 * @interface AllocationConstraintsResponse
 */
export interface AllocationConstraintsResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof AllocationConstraintsResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AllocationConstraintsResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<AssetClassesConstraint>}
     * @memberof AllocationConstraintsResponse
     */
    'assetClassConstraints'?: Array<AssetClassesConstraint>;
    /**
     * 
     * @type {Array<InstrumentsConstraint>}
     * @memberof AllocationConstraintsResponse
     */
    'instrumentsConstraints'?: Array<InstrumentsConstraint>;
    /**
     * 
     * @type {Array<TagsConstraint>}
     * @memberof AllocationConstraintsResponse
     */
    'tagsConstraints'?: Array<TagsConstraint>;
    /**
     * 
     * @type {Array<CurrencyConstraint>}
     * @memberof AllocationConstraintsResponse
     */
    'currencyConstraints'?: Array<CurrencyConstraint>;
    /**
     * 
     * @type {InstrumentMaxWeightConstraint}
     * @memberof AllocationConstraintsResponse
     */
    'instrumentMaxWeightConstraint'?: InstrumentMaxWeightConstraint;
    /**
     * 
     * @type {ScoresConstraint}
     * @memberof AllocationConstraintsResponse
     */
    'scoresConstraint'?: ScoresConstraint;
    /**
     * 
     * @type {LockInstrumentConstraint}
     * @memberof AllocationConstraintsResponse
     */
    'lockInstrumentGroup'?: LockInstrumentConstraint;
    /**
     * 
     * @type {Array<ForEachConstraint>}
     * @memberof AllocationConstraintsResponse
     */
    'forEachConstraint'?: Array<ForEachConstraint>;
}
/**
 * 
 * @export
 * @interface AllocationConstraintsSaveRequest
 */
export interface AllocationConstraintsSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationConstraintsSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {Array<AssetClassesConstraint>}
     * @memberof AllocationConstraintsSaveRequest
     */
    'assetClassConstraints'?: Array<AssetClassesConstraint>;
    /**
     * 
     * @type {Array<InstrumentsConstraint>}
     * @memberof AllocationConstraintsSaveRequest
     */
    'instrumentConstraints'?: Array<InstrumentsConstraint>;
    /**
     * 
     * @type {Array<TagsConstraint>}
     * @memberof AllocationConstraintsSaveRequest
     */
    'tagConstraints'?: Array<TagsConstraint>;
    /**
     * 
     * @type {InstrumentMaxWeightConstraint}
     * @memberof AllocationConstraintsSaveRequest
     */
    'instrumentMaxWeightConstraint'?: InstrumentMaxWeightConstraint;
    /**
     * 
     * @type {ScoresConstraint}
     * @memberof AllocationConstraintsSaveRequest
     */
    'scoresConstraint'?: ScoresConstraint;
    /**
     * 
     * @type {Array<CurrencyConstraint>}
     * @memberof AllocationConstraintsSaveRequest
     */
    'currencyConstraints'?: Array<CurrencyConstraint>;
    /**
     * 
     * @type {LockInstrumentConstraint}
     * @memberof AllocationConstraintsSaveRequest
     */
    'lockInstrumentGroup'?: LockInstrumentConstraint;
    /**
     * 
     * @type {Array<ForEachConstraint>}
     * @memberof AllocationConstraintsSaveRequest
     */
    'forEachConstraint'?: Array<ForEachConstraint>;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationConstraintsSaveRequest
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface AllocatorStepValues
 */
export interface AllocatorStepValues {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AllocatorStepValues
     */
    'leafContributionToExposure'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AnnualizedDistribution
 */
export interface AnnualizedDistribution {
    /**
     * 
     * @type {number}
     * @memberof AnnualizedDistribution
     */
    'mean'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualizedDistribution
     */
    'standardDeviation'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AnnualizedDistribution
     */
    'data'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AssetClass
 */
export interface AssetClass {
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'identifier'?: AssetClassIdentifierEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'granularityIdentifier'?: AssetClassGranularityIdentifierEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'granularity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'macroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'macroGeography'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClass
     */
    'microGeography'?: string;
}

export const AssetClassIdentifierEnum = {
    MoneyMarket: 'MONEY_MARKET',
    Alternative: 'ALTERNATIVE',
    Equity: 'EQUITY',
    FixedIncome: 'FIXED_INCOME',
    EmergingMarkets: 'EMERGING_MARKETS',
    PacificExJapan: 'PACIFIC_EX_JAPAN',
    UnitedKingdom: 'UNITED_KINGDOM',
    Europe: 'EUROPE',
    Japan: 'JAPAN',
    Usa: 'USA',
    CommoditiesCommodities: 'COMMODITIES_COMMODITIES',
    CommoditiesAgriculture: 'COMMODITIES_AGRICULTURE',
    CommoditiesEnergy: 'COMMODITIES_ENERGY',
    CommoditiesIndustrialMetals: 'COMMODITIES_INDUSTRIAL_METALS',
    CommoditiesPreciousMetals: 'COMMODITIES_PRECIOUS_METALS',
    EquityEmergingMarkets: 'EQUITY_EMERGING_MARKETS',
    EquityEurope: 'EQUITY_EUROPE',
    EquityJapan: 'EQUITY_JAPAN',
    EquityUsa: 'EQUITY_USA',
    EquityUsaCountry: 'EQUITY_USA_COUNTRY',
    EquityCommunicationServicesEurope: 'EQUITY_COMMUNICATION_SERVICES_EUROPE',
    EquityConsumerDiscretionaryEurope: 'EQUITY_CONSUMER_DISCRETIONARY_EUROPE',
    EquityConsumerStaplesEurope: 'EQUITY_CONSUMER_STAPLES_EUROPE',
    EquityEnergyEurope: 'EQUITY_ENERGY_EUROPE',
    EquityFinancialsEurope: 'EQUITY_FINANCIALS_EUROPE',
    EquityHealthCareEurope: 'EQUITY_HEALTH_CARE_EUROPE',
    EquityIndustrialsEurope: 'EQUITY_INDUSTRIALS_EUROPE',
    EquityInformationTechnologyEurope: 'EQUITY_INFORMATION_TECHNOLOGY_EUROPE',
    EquityMaterialsEurope: 'EQUITY_MATERIALS_EUROPE',
    EquityRealEstateEurope: 'EQUITY_REAL_ESTATE_EUROPE',
    EquityUtilitiesEurope: 'EQUITY_UTILITIES_EUROPE',
    EquityCommunicationServicesUsa: 'EQUITY_COMMUNICATION_SERVICES_USA',
    EquityConsumerDiscretionaryUsa: 'EQUITY_CONSUMER_DISCRETIONARY_USA',
    EquityConsumerStaplesUsa: 'EQUITY_CONSUMER_STAPLES_USA',
    EquityEnergyUsa: 'EQUITY_ENERGY_USA',
    EquityFinancialsUsa: 'EQUITY_FINANCIALS_USA',
    EquityHealthCareUsa: 'EQUITY_HEALTH_CARE_USA',
    EquityIndustrialsUsa: 'EQUITY_INDUSTRIALS_USA',
    EquityInformationTechnologyUsa: 'EQUITY_INFORMATION_TECHNOLOGY_USA',
    EquityMaterialsUsa: 'EQUITY_MATERIALS_USA',
    EquityRealEstateUsa: 'EQUITY_REAL_ESTATE_USA',
    EquityUtilitiesUsa: 'EQUITY_UTILITIES_USA',
    FixedIncomeEurope: 'FIXED_INCOME_EUROPE',
    FixedIncomeUsaAndCanada: 'FIXED_INCOME_USA_AND_CANADA',
    FixedIncomeEmergingMarkets: 'FIXED_INCOME_EMERGING_MARKETS',
    FixedIncomeCorporateEmergingMarkets: 'FIXED_INCOME_CORPORATE_EMERGING_MARKETS',
    FixedIncomeGovernmentEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_EMERGING_MARKETS',
    FixedIncomeCorporateMediumEurope: 'FIXED_INCOME_CORPORATE_MEDIUM_EUROPE',
    FixedIncomeCorporateShortEurope: 'FIXED_INCOME_CORPORATE_SHORT_EUROPE',
    FixedIncomeCorporateLongEurope: 'FIXED_INCOME_CORPORATE_LONG_EUROPE',
    FixedIncomeCorporateEurope: 'FIXED_INCOME_CORPORATE_EUROPE',
    FixedIncomeGovernmentEurope: 'FIXED_INCOME_GOVERNMENT_EUROPE',
    FixedIncomeGovernmentInflationLinkedEurope: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_EUROPE',
    FixedIncomeGovernmentLongEurope: 'FIXED_INCOME_GOVERNMENT_LONG_EUROPE',
    FixedIncomeGovernmentMediumEurope: 'FIXED_INCOME_GOVERNMENT_MEDIUM_EUROPE',
    FixedIncomeGovernmentShortEurope: 'FIXED_INCOME_GOVERNMENT_SHORT_EUROPE',
    FixedIncomeHighYieldEurope: 'FIXED_INCOME_HIGH_YIELD_EUROPE',
    FixedIncomeConvertiblesEurope: 'FIXED_INCOME_CONVERTIBLES_EUROPE',
    FixedIncomeCorporateMediumUsa: 'FIXED_INCOME_CORPORATE_MEDIUM_USA',
    FixedIncomeCorporateShortUsa: 'FIXED_INCOME_CORPORATE_SHORT_USA',
    FixedIncomeCorporateLongUsa: 'FIXED_INCOME_CORPORATE_LONG_USA',
    FixedIncomeCorporateUsa: 'FIXED_INCOME_CORPORATE_USA',
    FixedIncomeGovernmentUsa: 'FIXED_INCOME_GOVERNMENT_USA',
    FixedIncomeGovernmentInflationLinkedUsa: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_USA',
    FixedIncomeGovernmentLongUsa: 'FIXED_INCOME_GOVERNMENT_LONG_USA',
    FixedIncomeGovernmentMediumUsa: 'FIXED_INCOME_GOVERNMENT_MEDIUM_USA',
    FixedIncomeGovernmentShortUsa: 'FIXED_INCOME_GOVERNMENT_SHORT_USA',
    FixedIncomeHighYieldUsa: 'FIXED_INCOME_HIGH_YIELD_USA',
    FixedIncomeLeveragedLoansUsa: 'FIXED_INCOME_LEVERAGED_LOANS_USA',
    FixedIncomeConvertiblesUsa: 'FIXED_INCOME_CONVERTIBLES_USA',
    MoneyMarketEurope: 'MONEY_MARKET_EUROPE',
    EquityItaly: 'EQUITY_ITALY',
    EquityAustralia: 'EQUITY_AUSTRALIA',
    EquityBrazil: 'EQUITY_BRAZIL',
    EquityCanada: 'EQUITY_CANADA',
    EquityChina: 'EQUITY_CHINA',
    EquityCommunicationServicesChina: 'EQUITY_COMMUNICATION_SERVICES_CHINA',
    EquityConsumerDiscretionaryChina: 'EQUITY_CONSUMER_DISCRETIONARY_CHINA',
    EquityConsumerStaplesChina: 'EQUITY_CONSUMER_STAPLES_CHINA',
    EquityEnergyChina: 'EQUITY_ENERGY_CHINA',
    EquityFinancialsChina: 'EQUITY_FINANCIALS_CHINA',
    EquityHealthCareChina: 'EQUITY_HEALTH_CARE_CHINA',
    EquityIndustrialsChina: 'EQUITY_INDUSTRIALS_CHINA',
    EquityInformationTechnologyChina: 'EQUITY_INFORMATION_TECHNOLOGY_CHINA',
    EquityMaterialsChina: 'EQUITY_MATERIALS_CHINA',
    EquityRealEstateChina: 'EQUITY_REAL_ESTATE_CHINA',
    EquityUtilitiesChina: 'EQUITY_UTILITIES_CHINA',
    EquityEafe: 'EQUITY_EAFE',
    EquityEmea: 'EQUITY_EMEA',
    EquityCommunicationServicesEmergingMarkets: 'EQUITY_COMMUNICATION_SERVICES_EMERGING_MARKETS',
    EquityConsumerDiscretionaryEmergingMarkets: 'EQUITY_CONSUMER_DISCRETIONARY_EMERGING_MARKETS',
    EquityConsumerStaplesEmergingMarkets: 'EQUITY_CONSUMER_STAPLES_EMERGING_MARKETS',
    EquityEnergyEmergingMarkets: 'EQUITY_ENERGY_EMERGING_MARKETS',
    EquityFinancialsEmergingMarkets: 'EQUITY_FINANCIALS_EMERGING_MARKETS',
    EquityHealthCareEmergingMarkets: 'EQUITY_HEALTH_CARE_EMERGING_MARKETS',
    EquityIndustrialsEmergingMarkets: 'EQUITY_INDUSTRIALS_EMERGING_MARKETS',
    EquityInformationTechnologyEmergingMarkets: 'EQUITY_INFORMATION_TECHNOLOGY_EMERGING_MARKETS',
    EquityMaterialsEmergingMarkets: 'EQUITY_MATERIALS_EMERGING_MARKETS',
    EquityRealEstateEmergingMarkets: 'EQUITY_REAL_ESTATE_EMERGING_MARKETS',
    EquityUtilitiesEmergingMarkets: 'EQUITY_UTILITIES_EMERGING_MARKETS',
    EquityEmergingMarketsExChina: 'EQUITY_EMERGING_MARKETS_EX_CHINA',
    EquityEuropeExUk: 'EQUITY_EUROPE_EX_UK',
    EquityCommunicationServicesEurozone: 'EQUITY_COMMUNICATION_SERVICES_EUROZONE',
    EquityConsumerDiscretionaryEurozone: 'EQUITY_CONSUMER_DISCRETIONARY_EUROZONE',
    EquityConsumerStaplesEurozone: 'EQUITY_CONSUMER_STAPLES_EUROZONE',
    EquityEnergyEurozone: 'EQUITY_ENERGY_EUROZONE',
    EquityFinancialsEurozone: 'EQUITY_FINANCIALS_EUROZONE',
    EquityHealthCareEurozone: 'EQUITY_HEALTH_CARE_EUROZONE',
    EquityIndustrialsEurozone: 'EQUITY_INDUSTRIALS_EUROZONE',
    EquityInformationTechnologyEurozone: 'EQUITY_INFORMATION_TECHNOLOGY_EUROZONE',
    EquityMaterialsEurozone: 'EQUITY_MATERIALS_EUROZONE',
    EquityRealEstateEurozone: 'EQUITY_REAL_ESTATE_EUROZONE',
    EquityUtilitiesEurozone: 'EQUITY_UTILITIES_EUROZONE',
    EquityEurozone: 'EQUITY_EUROZONE',
    EquityFrance: 'EQUITY_FRANCE',
    EquityGermany: 'EQUITY_GERMANY',
    EquityCommunicationServices: 'EQUITY_COMMUNICATION_SERVICES',
    EquityConsumerDiscretionary: 'EQUITY_CONSUMER_DISCRETIONARY',
    EquityConsumerStaples: 'EQUITY_CONSUMER_STAPLES',
    EquityEnergy: 'EQUITY_ENERGY',
    EquityFinancials: 'EQUITY_FINANCIALS',
    EquityHealthCare: 'EQUITY_HEALTH_CARE',
    EquityIndustrials: 'EQUITY_INDUSTRIALS',
    EquityInformationTechnology: 'EQUITY_INFORMATION_TECHNOLOGY',
    EquityMaterials: 'EQUITY_MATERIALS',
    EquityRealEstate: 'EQUITY_REAL_ESTATE',
    EquityUtilities: 'EQUITY_UTILITIES',
    EquitySmallCap: 'EQUITY_SMALL_CAP',
    EquityInfrastructure: 'EQUITY_INFRASTRUCTURE',
    EquityGlobalExEurope: 'EQUITY_GLOBAL_EX_EUROPE',
    EquityGlobalExUk: 'EQUITY_GLOBAL_EX_UK',
    EquityHongKong: 'EQUITY_HONG_KONG',
    EquityIndia: 'EQUITY_INDIA',
    EquitySmallCapJapan: 'EQUITY_SMALL_CAP_JAPAN',
    EquityCommunicationServicesJapan: 'EQUITY_COMMUNICATION_SERVICES_JAPAN',
    EquityConsumerDiscretionaryJapan: 'EQUITY_CONSUMER_DISCRETIONARY_JAPAN',
    EquityConsumerStaplesJapan: 'EQUITY_CONSUMER_STAPLES_JAPAN',
    EquityEnergyJapan: 'EQUITY_ENERGY_JAPAN',
    EquityFinancialsJapan: 'EQUITY_FINANCIALS_JAPAN',
    EquityHealthCareJapan: 'EQUITY_HEALTH_CARE_JAPAN',
    EquityIndustrialsJapan: 'EQUITY_INDUSTRIALS_JAPAN',
    EquityInformationTechnologyJapan: 'EQUITY_INFORMATION_TECHNOLOGY_JAPAN',
    EquityMaterialsJapan: 'EQUITY_MATERIALS_JAPAN',
    EquityRealEstateJapan: 'EQUITY_REAL_ESTATE_JAPAN',
    EquityUtilitiesJapan: 'EQUITY_UTILITIES_JAPAN',
    EquityKorea: 'EQUITY_KOREA',
    EquityLatinAmerica: 'EQUITY_LATIN_AMERICA',
    EquityPacificExJapan: 'EQUITY_PACIFIC_EX_JAPAN',
    EquitySingapore: 'EQUITY_SINGAPORE',
    EquitySpain: 'EQUITY_SPAIN',
    EquitySwitzerland: 'EQUITY_SWITZERLAND',
    EquityCommunicationServicesSwitzerland: 'EQUITY_COMMUNICATION_SERVICES_SWITZERLAND',
    EquityConsumerDiscretionarySwitzerland: 'EQUITY_CONSUMER_DISCRETIONARY_SWITZERLAND',
    EquityConsumerStaplesSwitzerland: 'EQUITY_CONSUMER_STAPLES_SWITZERLAND',
    EquityEnergySwitzerland: 'EQUITY_ENERGY_SWITZERLAND',
    EquityFinancialsSwitzerland: 'EQUITY_FINANCIALS_SWITZERLAND',
    EquityHealthCareSwitzerland: 'EQUITY_HEALTH_CARE_SWITZERLAND',
    EquityIndustrialsSwitzerland: 'EQUITY_INDUSTRIALS_SWITZERLAND',
    EquityInformationTechnologySwitzerland: 'EQUITY_INFORMATION_TECHNOLOGY_SWITZERLAND',
    EquityMaterialsSwitzerland: 'EQUITY_MATERIALS_SWITZERLAND',
    EquityRealEstateSwitzerland: 'EQUITY_REAL_ESTATE_SWITZERLAND',
    EquityUtilitiesSwitzerland: 'EQUITY_UTILITIES_SWITZERLAND',
    EquityTurkey: 'EQUITY_TURKEY',
    EquityCommunicationServicesUnitedKingdom: 'EQUITY_COMMUNICATION_SERVICES_UNITED_KINGDOM',
    EquityConsumerDiscretionaryUnitedKingdom: 'EQUITY_CONSUMER_DISCRETIONARY_UNITED_KINGDOM',
    EquityConsumerStaplesUnitedKingdom: 'EQUITY_CONSUMER_STAPLES_UNITED_KINGDOM',
    EquityEnergyUnitedKingdom: 'EQUITY_ENERGY_UNITED_KINGDOM',
    EquityFinancialsUnitedKingdom: 'EQUITY_FINANCIALS_UNITED_KINGDOM',
    EquityHealthCareUnitedKingdom: 'EQUITY_HEALTH_CARE_UNITED_KINGDOM',
    EquityIndustrialsUnitedKingdom: 'EQUITY_INDUSTRIALS_UNITED_KINGDOM',
    EquityInformationTechnologyUnitedKingdom: 'EQUITY_INFORMATION_TECHNOLOGY_UNITED_KINGDOM',
    EquityMaterialsUnitedKingdom: 'EQUITY_MATERIALS_UNITED_KINGDOM',
    EquityRealEstateUnitedKingdom: 'EQUITY_REAL_ESTATE_UNITED_KINGDOM',
    EquityUtilitiesUnitedKingdom: 'EQUITY_UTILITIES_UNITED_KINGDOM',
    EquityUnitedKingdom: 'EQUITY_UNITED_KINGDOM',
    EquitySmallCapUsa: 'EQUITY_SMALL_CAP_USA',
    EquityMidCapUsa: 'EQUITY_MID_CAP_USA',
    MoneyMarketUnitedKingdom: 'MONEY_MARKET_UNITED_KINGDOM',
    MoneyMarketSwitzerland: 'MONEY_MARKET_SWITZERLAND',
    MoneyMarketAustralia: 'MONEY_MARKET_AUSTRALIA',
    MoneyMarketJapan: 'MONEY_MARKET_JAPAN',
    FixedIncomeGovernmentMediumAustralia: 'FIXED_INCOME_GOVERNMENT_MEDIUM_AUSTRALIA',
    FixedIncomeGovernmentLongAustralia: 'FIXED_INCOME_GOVERNMENT_LONG_AUSTRALIA',
    FixedIncomeGovernmentShortChina: 'FIXED_INCOME_GOVERNMENT_SHORT_CHINA',
    FixedIncomeGovernmentChina: 'FIXED_INCOME_GOVERNMENT_CHINA',
    FixedIncomeGovernmentLocalEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_LOCAL_EMERGING_MARKETS',
    FixedIncomeGovernmentLongFrance: 'FIXED_INCOME_GOVERNMENT_LONG_FRANCE',
    FixedIncomeGovernmentMediumFrance: 'FIXED_INCOME_GOVERNMENT_MEDIUM_FRANCE',
    FixedIncomeGovernmentShortFrance: 'FIXED_INCOME_GOVERNMENT_SHORT_FRANCE',
    FixedIncomeGovernmentLongGermany: 'FIXED_INCOME_GOVERNMENT_LONG_GERMANY',
    FixedIncomeGovernmentMediumGermany: 'FIXED_INCOME_GOVERNMENT_MEDIUM_GERMANY',
    FixedIncomeGovernmentShortGermany: 'FIXED_INCOME_GOVERNMENT_SHORT_GERMANY',
    FixedIncomeGovernmentInflationLinked: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    MoneyMarketUsa: 'MONEY_MARKET_USA',
    FixedIncomeHighYield: 'FIXED_INCOME_HIGH_YIELD',
    FixedIncomeConvertibles: 'FIXED_INCOME_CONVERTIBLES',
    FixedIncomeGovernmentMedium: 'FIXED_INCOME_GOVERNMENT_MEDIUM',
    FixedIncomeGovernmentLong: 'FIXED_INCOME_GOVERNMENT_LONG',
    FixedIncomeGovernmentShort: 'FIXED_INCOME_GOVERNMENT_SHORT',
    FixedIncomeCorporateShort: 'FIXED_INCOME_CORPORATE_SHORT',
    FixedIncomeCorporateMedium: 'FIXED_INCOME_CORPORATE_MEDIUM',
    FixedIncomeCorporateLong: 'FIXED_INCOME_CORPORATE_LONG',
    FixedIncomeGovernmentAggregate: 'FIXED_INCOME_GOVERNMENT_AGGREGATE',
    FixedIncomeCorporateAggregate: 'FIXED_INCOME_CORPORATE_AGGREGATE',
    FixedIncomeGovernmentMediumItaly: 'FIXED_INCOME_GOVERNMENT_MEDIUM_ITALY',
    FixedIncomeGovernmentLongItaly: 'FIXED_INCOME_GOVERNMENT_LONG_ITALY',
    FixedIncomeGovernmentShortItaly: 'FIXED_INCOME_GOVERNMENT_SHORT_ITALY',
    FixedIncomeGovernmentMediumJapan: 'FIXED_INCOME_GOVERNMENT_MEDIUM_JAPAN',
    FixedIncomeGovernmentLongJapan: 'FIXED_INCOME_GOVERNMENT_LONG_JAPAN',
    FixedIncomeGovernmentMediumSpain: 'FIXED_INCOME_GOVERNMENT_MEDIUM_SPAIN',
    FixedIncomeGovernmentLongSpain: 'FIXED_INCOME_GOVERNMENT_LONG_SPAIN',
    FixedIncomeGovernmentShortSpain: 'FIXED_INCOME_GOVERNMENT_SHORT_SPAIN',
    FixedIncomeGovernmentMediumUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_MEDIUM_UNITED_KINGDOM',
    FixedIncomeGovernmentLinkedUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_LINKED_UNITED_KINGDOM',
    FixedIncomeCorporateMediumUnitedKingdom: 'FIXED_INCOME_CORPORATE_MEDIUM_UNITED_KINGDOM',
    AsiaOceania: 'ASIA_OCEANIA',
    EquityEmergingMarketsAsiaExChina: 'EQUITY_EMERGING_MARKETS_ASIA_EX_CHINA',
    EquityAsia: 'EQUITY_ASIA',
    FixedIncomeCorporateFinancialsEurope: 'FIXED_INCOME_CORPORATE_FINANCIALS_EUROPE',
    FixedIncomeHighYieldExFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_EUROPE',
    FixedIncomeCorporateExFinancialsEurope: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_EUROPE',
    FixedIncomeHighYieldFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_EUROPE',
    FixedIncomeCorporateFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_FINANCIALS_USA_AND_CANADA',
    FixedIncomeCorporateExFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldExFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_USA_AND_CANADA',
    FixedIncomeCorporateGlobal: 'FIXED_INCOME_CORPORATE_GLOBAL',
    FixedIncomeGovernmentGlobal: 'FIXED_INCOME_GOVERNMENT_GLOBAL',
    EquityFrontierMarket: 'EQUITY_FRONTIER_MARKET',
    FixedIncomeCorporateAsia: 'FIXED_INCOME_CORPORATE_ASIA',
    FixedIncomeGovernmentAsia: 'FIXED_INCOME_GOVERNMENT_ASIA',
    FixedIncomeHighYieldAsia: 'FIXED_INCOME_HIGH_YIELD_ASIA',
    FixedIncomeGovernmentLocalCurrencyAsia: 'FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_ASIA',
    MoneyMarketCanada: 'MONEY_MARKET_CANADA',
    MoneyMarketSingapore: 'MONEY_MARKET_SINGAPORE'
} as const;

export type AssetClassIdentifierEnum = typeof AssetClassIdentifierEnum[keyof typeof AssetClassIdentifierEnum];
export const AssetClassGranularityIdentifierEnum = {
    AssetClass: 'ASSET_CLASS',
    CommoditiesGeography: 'COMMODITIES_GEOGRAPHY',
    CommoditiesType: 'COMMODITIES_TYPE',
    EquityGeography: 'EQUITY_GEOGRAPHY',
    EquitySector: 'EQUITY_SECTOR',
    EquitySectorAndGeography: 'EQUITY_SECTOR_AND_GEOGRAPHY',
    FixedIncomeGeography: 'FIXED_INCOME_GEOGRAPHY',
    FixedIncomeType: 'FIXED_INCOME_TYPE',
    FixedIncomeTypeAndGeography: 'FIXED_INCOME_TYPE_AND_GEOGRAPHY',
    MoneyMarketGeography: 'MONEY_MARKET_GEOGRAPHY',
    Geography: 'GEOGRAPHY'
} as const;

export type AssetClassGranularityIdentifierEnum = typeof AssetClassGranularityIdentifierEnum[keyof typeof AssetClassGranularityIdentifierEnum];

/**
 * 
 * @export
 * @interface AssetClassBreakdownEntry
 */
export interface AssetClassBreakdownEntry {
    /**
     * 
     * @type {string}
     * @memberof AssetClassBreakdownEntry
     */
    'assetClass'?: AssetClassBreakdownEntryAssetClassEnum;
    /**
     * 
     * @type {number}
     * @memberof AssetClassBreakdownEntry
     */
    'value'?: number;
}

export const AssetClassBreakdownEntryAssetClassEnum = {
    MoneyMarket: 'MONEY_MARKET',
    Alternative: 'ALTERNATIVE',
    Equity: 'EQUITY',
    FixedIncome: 'FIXED_INCOME',
    EmergingMarkets: 'EMERGING_MARKETS',
    PacificExJapan: 'PACIFIC_EX_JAPAN',
    UnitedKingdom: 'UNITED_KINGDOM',
    Europe: 'EUROPE',
    Japan: 'JAPAN',
    Usa: 'USA',
    CommoditiesCommodities: 'COMMODITIES_COMMODITIES',
    CommoditiesAgriculture: 'COMMODITIES_AGRICULTURE',
    CommoditiesEnergy: 'COMMODITIES_ENERGY',
    CommoditiesIndustrialMetals: 'COMMODITIES_INDUSTRIAL_METALS',
    CommoditiesPreciousMetals: 'COMMODITIES_PRECIOUS_METALS',
    EquityEmergingMarkets: 'EQUITY_EMERGING_MARKETS',
    EquityEurope: 'EQUITY_EUROPE',
    EquityJapan: 'EQUITY_JAPAN',
    EquityUsa: 'EQUITY_USA',
    EquityUsaCountry: 'EQUITY_USA_COUNTRY',
    EquityCommunicationServicesEurope: 'EQUITY_COMMUNICATION_SERVICES_EUROPE',
    EquityConsumerDiscretionaryEurope: 'EQUITY_CONSUMER_DISCRETIONARY_EUROPE',
    EquityConsumerStaplesEurope: 'EQUITY_CONSUMER_STAPLES_EUROPE',
    EquityEnergyEurope: 'EQUITY_ENERGY_EUROPE',
    EquityFinancialsEurope: 'EQUITY_FINANCIALS_EUROPE',
    EquityHealthCareEurope: 'EQUITY_HEALTH_CARE_EUROPE',
    EquityIndustrialsEurope: 'EQUITY_INDUSTRIALS_EUROPE',
    EquityInformationTechnologyEurope: 'EQUITY_INFORMATION_TECHNOLOGY_EUROPE',
    EquityMaterialsEurope: 'EQUITY_MATERIALS_EUROPE',
    EquityRealEstateEurope: 'EQUITY_REAL_ESTATE_EUROPE',
    EquityUtilitiesEurope: 'EQUITY_UTILITIES_EUROPE',
    EquityCommunicationServicesUsa: 'EQUITY_COMMUNICATION_SERVICES_USA',
    EquityConsumerDiscretionaryUsa: 'EQUITY_CONSUMER_DISCRETIONARY_USA',
    EquityConsumerStaplesUsa: 'EQUITY_CONSUMER_STAPLES_USA',
    EquityEnergyUsa: 'EQUITY_ENERGY_USA',
    EquityFinancialsUsa: 'EQUITY_FINANCIALS_USA',
    EquityHealthCareUsa: 'EQUITY_HEALTH_CARE_USA',
    EquityIndustrialsUsa: 'EQUITY_INDUSTRIALS_USA',
    EquityInformationTechnologyUsa: 'EQUITY_INFORMATION_TECHNOLOGY_USA',
    EquityMaterialsUsa: 'EQUITY_MATERIALS_USA',
    EquityRealEstateUsa: 'EQUITY_REAL_ESTATE_USA',
    EquityUtilitiesUsa: 'EQUITY_UTILITIES_USA',
    FixedIncomeEurope: 'FIXED_INCOME_EUROPE',
    FixedIncomeUsaAndCanada: 'FIXED_INCOME_USA_AND_CANADA',
    FixedIncomeEmergingMarkets: 'FIXED_INCOME_EMERGING_MARKETS',
    FixedIncomeCorporateEmergingMarkets: 'FIXED_INCOME_CORPORATE_EMERGING_MARKETS',
    FixedIncomeGovernmentEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_EMERGING_MARKETS',
    FixedIncomeCorporateMediumEurope: 'FIXED_INCOME_CORPORATE_MEDIUM_EUROPE',
    FixedIncomeCorporateShortEurope: 'FIXED_INCOME_CORPORATE_SHORT_EUROPE',
    FixedIncomeCorporateLongEurope: 'FIXED_INCOME_CORPORATE_LONG_EUROPE',
    FixedIncomeCorporateEurope: 'FIXED_INCOME_CORPORATE_EUROPE',
    FixedIncomeGovernmentEurope: 'FIXED_INCOME_GOVERNMENT_EUROPE',
    FixedIncomeGovernmentInflationLinkedEurope: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_EUROPE',
    FixedIncomeGovernmentLongEurope: 'FIXED_INCOME_GOVERNMENT_LONG_EUROPE',
    FixedIncomeGovernmentMediumEurope: 'FIXED_INCOME_GOVERNMENT_MEDIUM_EUROPE',
    FixedIncomeGovernmentShortEurope: 'FIXED_INCOME_GOVERNMENT_SHORT_EUROPE',
    FixedIncomeHighYieldEurope: 'FIXED_INCOME_HIGH_YIELD_EUROPE',
    FixedIncomeConvertiblesEurope: 'FIXED_INCOME_CONVERTIBLES_EUROPE',
    FixedIncomeCorporateMediumUsa: 'FIXED_INCOME_CORPORATE_MEDIUM_USA',
    FixedIncomeCorporateShortUsa: 'FIXED_INCOME_CORPORATE_SHORT_USA',
    FixedIncomeCorporateLongUsa: 'FIXED_INCOME_CORPORATE_LONG_USA',
    FixedIncomeCorporateUsa: 'FIXED_INCOME_CORPORATE_USA',
    FixedIncomeGovernmentUsa: 'FIXED_INCOME_GOVERNMENT_USA',
    FixedIncomeGovernmentInflationLinkedUsa: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_USA',
    FixedIncomeGovernmentLongUsa: 'FIXED_INCOME_GOVERNMENT_LONG_USA',
    FixedIncomeGovernmentMediumUsa: 'FIXED_INCOME_GOVERNMENT_MEDIUM_USA',
    FixedIncomeGovernmentShortUsa: 'FIXED_INCOME_GOVERNMENT_SHORT_USA',
    FixedIncomeHighYieldUsa: 'FIXED_INCOME_HIGH_YIELD_USA',
    FixedIncomeLeveragedLoansUsa: 'FIXED_INCOME_LEVERAGED_LOANS_USA',
    FixedIncomeConvertiblesUsa: 'FIXED_INCOME_CONVERTIBLES_USA',
    MoneyMarketEurope: 'MONEY_MARKET_EUROPE',
    EquityItaly: 'EQUITY_ITALY',
    EquityAustralia: 'EQUITY_AUSTRALIA',
    EquityBrazil: 'EQUITY_BRAZIL',
    EquityCanada: 'EQUITY_CANADA',
    EquityChina: 'EQUITY_CHINA',
    EquityCommunicationServicesChina: 'EQUITY_COMMUNICATION_SERVICES_CHINA',
    EquityConsumerDiscretionaryChina: 'EQUITY_CONSUMER_DISCRETIONARY_CHINA',
    EquityConsumerStaplesChina: 'EQUITY_CONSUMER_STAPLES_CHINA',
    EquityEnergyChina: 'EQUITY_ENERGY_CHINA',
    EquityFinancialsChina: 'EQUITY_FINANCIALS_CHINA',
    EquityHealthCareChina: 'EQUITY_HEALTH_CARE_CHINA',
    EquityIndustrialsChina: 'EQUITY_INDUSTRIALS_CHINA',
    EquityInformationTechnologyChina: 'EQUITY_INFORMATION_TECHNOLOGY_CHINA',
    EquityMaterialsChina: 'EQUITY_MATERIALS_CHINA',
    EquityRealEstateChina: 'EQUITY_REAL_ESTATE_CHINA',
    EquityUtilitiesChina: 'EQUITY_UTILITIES_CHINA',
    EquityEafe: 'EQUITY_EAFE',
    EquityEmea: 'EQUITY_EMEA',
    EquityCommunicationServicesEmergingMarkets: 'EQUITY_COMMUNICATION_SERVICES_EMERGING_MARKETS',
    EquityConsumerDiscretionaryEmergingMarkets: 'EQUITY_CONSUMER_DISCRETIONARY_EMERGING_MARKETS',
    EquityConsumerStaplesEmergingMarkets: 'EQUITY_CONSUMER_STAPLES_EMERGING_MARKETS',
    EquityEnergyEmergingMarkets: 'EQUITY_ENERGY_EMERGING_MARKETS',
    EquityFinancialsEmergingMarkets: 'EQUITY_FINANCIALS_EMERGING_MARKETS',
    EquityHealthCareEmergingMarkets: 'EQUITY_HEALTH_CARE_EMERGING_MARKETS',
    EquityIndustrialsEmergingMarkets: 'EQUITY_INDUSTRIALS_EMERGING_MARKETS',
    EquityInformationTechnologyEmergingMarkets: 'EQUITY_INFORMATION_TECHNOLOGY_EMERGING_MARKETS',
    EquityMaterialsEmergingMarkets: 'EQUITY_MATERIALS_EMERGING_MARKETS',
    EquityRealEstateEmergingMarkets: 'EQUITY_REAL_ESTATE_EMERGING_MARKETS',
    EquityUtilitiesEmergingMarkets: 'EQUITY_UTILITIES_EMERGING_MARKETS',
    EquityEmergingMarketsExChina: 'EQUITY_EMERGING_MARKETS_EX_CHINA',
    EquityEuropeExUk: 'EQUITY_EUROPE_EX_UK',
    EquityCommunicationServicesEurozone: 'EQUITY_COMMUNICATION_SERVICES_EUROZONE',
    EquityConsumerDiscretionaryEurozone: 'EQUITY_CONSUMER_DISCRETIONARY_EUROZONE',
    EquityConsumerStaplesEurozone: 'EQUITY_CONSUMER_STAPLES_EUROZONE',
    EquityEnergyEurozone: 'EQUITY_ENERGY_EUROZONE',
    EquityFinancialsEurozone: 'EQUITY_FINANCIALS_EUROZONE',
    EquityHealthCareEurozone: 'EQUITY_HEALTH_CARE_EUROZONE',
    EquityIndustrialsEurozone: 'EQUITY_INDUSTRIALS_EUROZONE',
    EquityInformationTechnologyEurozone: 'EQUITY_INFORMATION_TECHNOLOGY_EUROZONE',
    EquityMaterialsEurozone: 'EQUITY_MATERIALS_EUROZONE',
    EquityRealEstateEurozone: 'EQUITY_REAL_ESTATE_EUROZONE',
    EquityUtilitiesEurozone: 'EQUITY_UTILITIES_EUROZONE',
    EquityEurozone: 'EQUITY_EUROZONE',
    EquityFrance: 'EQUITY_FRANCE',
    EquityGermany: 'EQUITY_GERMANY',
    EquityCommunicationServices: 'EQUITY_COMMUNICATION_SERVICES',
    EquityConsumerDiscretionary: 'EQUITY_CONSUMER_DISCRETIONARY',
    EquityConsumerStaples: 'EQUITY_CONSUMER_STAPLES',
    EquityEnergy: 'EQUITY_ENERGY',
    EquityFinancials: 'EQUITY_FINANCIALS',
    EquityHealthCare: 'EQUITY_HEALTH_CARE',
    EquityIndustrials: 'EQUITY_INDUSTRIALS',
    EquityInformationTechnology: 'EQUITY_INFORMATION_TECHNOLOGY',
    EquityMaterials: 'EQUITY_MATERIALS',
    EquityRealEstate: 'EQUITY_REAL_ESTATE',
    EquityUtilities: 'EQUITY_UTILITIES',
    EquitySmallCap: 'EQUITY_SMALL_CAP',
    EquityInfrastructure: 'EQUITY_INFRASTRUCTURE',
    EquityGlobalExEurope: 'EQUITY_GLOBAL_EX_EUROPE',
    EquityGlobalExUk: 'EQUITY_GLOBAL_EX_UK',
    EquityHongKong: 'EQUITY_HONG_KONG',
    EquityIndia: 'EQUITY_INDIA',
    EquitySmallCapJapan: 'EQUITY_SMALL_CAP_JAPAN',
    EquityCommunicationServicesJapan: 'EQUITY_COMMUNICATION_SERVICES_JAPAN',
    EquityConsumerDiscretionaryJapan: 'EQUITY_CONSUMER_DISCRETIONARY_JAPAN',
    EquityConsumerStaplesJapan: 'EQUITY_CONSUMER_STAPLES_JAPAN',
    EquityEnergyJapan: 'EQUITY_ENERGY_JAPAN',
    EquityFinancialsJapan: 'EQUITY_FINANCIALS_JAPAN',
    EquityHealthCareJapan: 'EQUITY_HEALTH_CARE_JAPAN',
    EquityIndustrialsJapan: 'EQUITY_INDUSTRIALS_JAPAN',
    EquityInformationTechnologyJapan: 'EQUITY_INFORMATION_TECHNOLOGY_JAPAN',
    EquityMaterialsJapan: 'EQUITY_MATERIALS_JAPAN',
    EquityRealEstateJapan: 'EQUITY_REAL_ESTATE_JAPAN',
    EquityUtilitiesJapan: 'EQUITY_UTILITIES_JAPAN',
    EquityKorea: 'EQUITY_KOREA',
    EquityLatinAmerica: 'EQUITY_LATIN_AMERICA',
    EquityPacificExJapan: 'EQUITY_PACIFIC_EX_JAPAN',
    EquitySingapore: 'EQUITY_SINGAPORE',
    EquitySpain: 'EQUITY_SPAIN',
    EquitySwitzerland: 'EQUITY_SWITZERLAND',
    EquityCommunicationServicesSwitzerland: 'EQUITY_COMMUNICATION_SERVICES_SWITZERLAND',
    EquityConsumerDiscretionarySwitzerland: 'EQUITY_CONSUMER_DISCRETIONARY_SWITZERLAND',
    EquityConsumerStaplesSwitzerland: 'EQUITY_CONSUMER_STAPLES_SWITZERLAND',
    EquityEnergySwitzerland: 'EQUITY_ENERGY_SWITZERLAND',
    EquityFinancialsSwitzerland: 'EQUITY_FINANCIALS_SWITZERLAND',
    EquityHealthCareSwitzerland: 'EQUITY_HEALTH_CARE_SWITZERLAND',
    EquityIndustrialsSwitzerland: 'EQUITY_INDUSTRIALS_SWITZERLAND',
    EquityInformationTechnologySwitzerland: 'EQUITY_INFORMATION_TECHNOLOGY_SWITZERLAND',
    EquityMaterialsSwitzerland: 'EQUITY_MATERIALS_SWITZERLAND',
    EquityRealEstateSwitzerland: 'EQUITY_REAL_ESTATE_SWITZERLAND',
    EquityUtilitiesSwitzerland: 'EQUITY_UTILITIES_SWITZERLAND',
    EquityTurkey: 'EQUITY_TURKEY',
    EquityCommunicationServicesUnitedKingdom: 'EQUITY_COMMUNICATION_SERVICES_UNITED_KINGDOM',
    EquityConsumerDiscretionaryUnitedKingdom: 'EQUITY_CONSUMER_DISCRETIONARY_UNITED_KINGDOM',
    EquityConsumerStaplesUnitedKingdom: 'EQUITY_CONSUMER_STAPLES_UNITED_KINGDOM',
    EquityEnergyUnitedKingdom: 'EQUITY_ENERGY_UNITED_KINGDOM',
    EquityFinancialsUnitedKingdom: 'EQUITY_FINANCIALS_UNITED_KINGDOM',
    EquityHealthCareUnitedKingdom: 'EQUITY_HEALTH_CARE_UNITED_KINGDOM',
    EquityIndustrialsUnitedKingdom: 'EQUITY_INDUSTRIALS_UNITED_KINGDOM',
    EquityInformationTechnologyUnitedKingdom: 'EQUITY_INFORMATION_TECHNOLOGY_UNITED_KINGDOM',
    EquityMaterialsUnitedKingdom: 'EQUITY_MATERIALS_UNITED_KINGDOM',
    EquityRealEstateUnitedKingdom: 'EQUITY_REAL_ESTATE_UNITED_KINGDOM',
    EquityUtilitiesUnitedKingdom: 'EQUITY_UTILITIES_UNITED_KINGDOM',
    EquityUnitedKingdom: 'EQUITY_UNITED_KINGDOM',
    EquitySmallCapUsa: 'EQUITY_SMALL_CAP_USA',
    EquityMidCapUsa: 'EQUITY_MID_CAP_USA',
    MoneyMarketUnitedKingdom: 'MONEY_MARKET_UNITED_KINGDOM',
    MoneyMarketSwitzerland: 'MONEY_MARKET_SWITZERLAND',
    MoneyMarketAustralia: 'MONEY_MARKET_AUSTRALIA',
    MoneyMarketJapan: 'MONEY_MARKET_JAPAN',
    FixedIncomeGovernmentMediumAustralia: 'FIXED_INCOME_GOVERNMENT_MEDIUM_AUSTRALIA',
    FixedIncomeGovernmentLongAustralia: 'FIXED_INCOME_GOVERNMENT_LONG_AUSTRALIA',
    FixedIncomeGovernmentShortChina: 'FIXED_INCOME_GOVERNMENT_SHORT_CHINA',
    FixedIncomeGovernmentChina: 'FIXED_INCOME_GOVERNMENT_CHINA',
    FixedIncomeGovernmentLocalEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_LOCAL_EMERGING_MARKETS',
    FixedIncomeGovernmentLongFrance: 'FIXED_INCOME_GOVERNMENT_LONG_FRANCE',
    FixedIncomeGovernmentMediumFrance: 'FIXED_INCOME_GOVERNMENT_MEDIUM_FRANCE',
    FixedIncomeGovernmentShortFrance: 'FIXED_INCOME_GOVERNMENT_SHORT_FRANCE',
    FixedIncomeGovernmentLongGermany: 'FIXED_INCOME_GOVERNMENT_LONG_GERMANY',
    FixedIncomeGovernmentMediumGermany: 'FIXED_INCOME_GOVERNMENT_MEDIUM_GERMANY',
    FixedIncomeGovernmentShortGermany: 'FIXED_INCOME_GOVERNMENT_SHORT_GERMANY',
    FixedIncomeGovernmentInflationLinked: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    MoneyMarketUsa: 'MONEY_MARKET_USA',
    FixedIncomeHighYield: 'FIXED_INCOME_HIGH_YIELD',
    FixedIncomeConvertibles: 'FIXED_INCOME_CONVERTIBLES',
    FixedIncomeGovernmentMedium: 'FIXED_INCOME_GOVERNMENT_MEDIUM',
    FixedIncomeGovernmentLong: 'FIXED_INCOME_GOVERNMENT_LONG',
    FixedIncomeGovernmentShort: 'FIXED_INCOME_GOVERNMENT_SHORT',
    FixedIncomeCorporateShort: 'FIXED_INCOME_CORPORATE_SHORT',
    FixedIncomeCorporateMedium: 'FIXED_INCOME_CORPORATE_MEDIUM',
    FixedIncomeCorporateLong: 'FIXED_INCOME_CORPORATE_LONG',
    FixedIncomeGovernmentAggregate: 'FIXED_INCOME_GOVERNMENT_AGGREGATE',
    FixedIncomeCorporateAggregate: 'FIXED_INCOME_CORPORATE_AGGREGATE',
    FixedIncomeGovernmentMediumItaly: 'FIXED_INCOME_GOVERNMENT_MEDIUM_ITALY',
    FixedIncomeGovernmentLongItaly: 'FIXED_INCOME_GOVERNMENT_LONG_ITALY',
    FixedIncomeGovernmentShortItaly: 'FIXED_INCOME_GOVERNMENT_SHORT_ITALY',
    FixedIncomeGovernmentMediumJapan: 'FIXED_INCOME_GOVERNMENT_MEDIUM_JAPAN',
    FixedIncomeGovernmentLongJapan: 'FIXED_INCOME_GOVERNMENT_LONG_JAPAN',
    FixedIncomeGovernmentMediumSpain: 'FIXED_INCOME_GOVERNMENT_MEDIUM_SPAIN',
    FixedIncomeGovernmentLongSpain: 'FIXED_INCOME_GOVERNMENT_LONG_SPAIN',
    FixedIncomeGovernmentShortSpain: 'FIXED_INCOME_GOVERNMENT_SHORT_SPAIN',
    FixedIncomeGovernmentMediumUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_MEDIUM_UNITED_KINGDOM',
    FixedIncomeGovernmentLinkedUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_LINKED_UNITED_KINGDOM',
    FixedIncomeCorporateMediumUnitedKingdom: 'FIXED_INCOME_CORPORATE_MEDIUM_UNITED_KINGDOM',
    AsiaOceania: 'ASIA_OCEANIA',
    EquityEmergingMarketsAsiaExChina: 'EQUITY_EMERGING_MARKETS_ASIA_EX_CHINA',
    EquityAsia: 'EQUITY_ASIA',
    FixedIncomeCorporateFinancialsEurope: 'FIXED_INCOME_CORPORATE_FINANCIALS_EUROPE',
    FixedIncomeHighYieldExFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_EUROPE',
    FixedIncomeCorporateExFinancialsEurope: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_EUROPE',
    FixedIncomeHighYieldFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_EUROPE',
    FixedIncomeCorporateFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_FINANCIALS_USA_AND_CANADA',
    FixedIncomeCorporateExFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldExFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_USA_AND_CANADA',
    FixedIncomeCorporateGlobal: 'FIXED_INCOME_CORPORATE_GLOBAL',
    FixedIncomeGovernmentGlobal: 'FIXED_INCOME_GOVERNMENT_GLOBAL',
    EquityFrontierMarket: 'EQUITY_FRONTIER_MARKET',
    FixedIncomeCorporateAsia: 'FIXED_INCOME_CORPORATE_ASIA',
    FixedIncomeGovernmentAsia: 'FIXED_INCOME_GOVERNMENT_ASIA',
    FixedIncomeHighYieldAsia: 'FIXED_INCOME_HIGH_YIELD_ASIA',
    FixedIncomeGovernmentLocalCurrencyAsia: 'FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_ASIA',
    MoneyMarketCanada: 'MONEY_MARKET_CANADA',
    MoneyMarketSingapore: 'MONEY_MARKET_SINGAPORE'
} as const;

export type AssetClassBreakdownEntryAssetClassEnum = typeof AssetClassBreakdownEntryAssetClassEnum[keyof typeof AssetClassBreakdownEntryAssetClassEnum];

/**
 * 
 * @export
 * @interface AssetClassExport
 */
export interface AssetClassExport {
    /**
     * 
     * @type {string}
     * @memberof AssetClassExport
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClassExport
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClassExport
     */
    'commentary'?: string;
}
/**
 * 
 * @export
 * @interface AssetClassGlobalRisk
 */
export interface AssetClassGlobalRisk {
    /**
     * 
     * @type {AssetClassIdentifier}
     * @memberof AssetClassGlobalRisk
     */
    'macroName'?: AssetClassIdentifier;
    /**
     * 
     * @type {number}
     * @memberof AssetClassGlobalRisk
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetClassGlobalRisk
     */
    'valueOnMap'?: number;
    /**
     * 
     * @type {Array<SectorGlobalRisk>}
     * @memberof AssetClassGlobalRisk
     */
    'sectors'?: Array<SectorGlobalRisk>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetClassIdentifier = {
    MoneyMarket: 'MONEY_MARKET',
    Alternative: 'ALTERNATIVE',
    Equity: 'EQUITY',
    FixedIncome: 'FIXED_INCOME',
    EmergingMarkets: 'EMERGING_MARKETS',
    PacificExJapan: 'PACIFIC_EX_JAPAN',
    UnitedKingdom: 'UNITED_KINGDOM',
    Europe: 'EUROPE',
    Usa: 'USA',
    Japan: 'JAPAN',
    UsaAndCanada: 'USA_AND_CANADA',
    Commodities: 'COMMODITIES',
    CommoditiesAgriculture: 'COMMODITIES_AGRICULTURE',
    CommoditiesEnergy: 'COMMODITIES_ENERGY',
    CommoditiesIndustrialMetals: 'COMMODITIES_INDUSTRIAL_METALS',
    CommoditiesPreciousMetals: 'COMMODITIES_PRECIOUS_METALS',
    EquityEmergingMarkets: 'EQUITY_EMERGING_MARKETS',
    EquityEurope: 'EQUITY_EUROPE',
    EquityJapan: 'EQUITY_JAPAN',
    EquityUsaAndCanada: 'EQUITY_USA_AND_CANADA',
    EquityCommunicationServicesEurope: 'EQUITY_COMMUNICATION_SERVICES_EUROPE',
    EquityConsumerDiscretionaryEurope: 'EQUITY_CONSUMER_DISCRETIONARY_EUROPE',
    EquityConsumerStaplesEurope: 'EQUITY_CONSUMER_STAPLES_EUROPE',
    EquityEnergyEurope: 'EQUITY_ENERGY_EUROPE',
    EquityFinancialsEurope: 'EQUITY_FINANCIALS_EUROPE',
    EquityHealthCareEurope: 'EQUITY_HEALTH_CARE_EUROPE',
    EquityIndustrialsEurope: 'EQUITY_INDUSTRIALS_EUROPE',
    EquityInformationTechnologyEurope: 'EQUITY_INFORMATION_TECHNOLOGY_EUROPE',
    EquityMaterialsEurope: 'EQUITY_MATERIALS_EUROPE',
    EquityRealEstateEurope: 'EQUITY_REAL_ESTATE_EUROPE',
    EquityUtilitiesEurope: 'EQUITY_UTILITIES_EUROPE',
    EquityCommunicationServicesUsa: 'EQUITY_COMMUNICATION_SERVICES_USA',
    EquityConsumerDiscretionaryUsa: 'EQUITY_CONSUMER_DISCRETIONARY_USA',
    EquityConsumerStaplesUsa: 'EQUITY_CONSUMER_STAPLES_USA',
    EquityEnergyUsa: 'EQUITY_ENERGY_USA',
    EquityFinancialsUsa: 'EQUITY_FINANCIALS_USA',
    EquityHealthCareUsa: 'EQUITY_HEALTH_CARE_USA',
    EquityIndustrialsUsa: 'EQUITY_INDUSTRIALS_USA',
    EquityInformationTechnologyUsa: 'EQUITY_INFORMATION_TECHNOLOGY_USA',
    EquityMaterialsUsa: 'EQUITY_MATERIALS_USA',
    EquityRealEstateUsa: 'EQUITY_REAL_ESTATE_USA',
    EquityUtilitiesUsa: 'EQUITY_UTILITIES_USA',
    FixedIncomeEurope: 'FIXED_INCOME_EUROPE',
    FixedIncomeUsaAndCanada: 'FIXED_INCOME_USA_AND_CANADA',
    FixedIncomeEmergingMarkets: 'FIXED_INCOME_EMERGING_MARKETS',
    FixedIncomeCorporateEmergingMarkets: 'FIXED_INCOME_CORPORATE_EMERGING_MARKETS',
    FixedIncomeCorporateMediumEurope: 'FIXED_INCOME_CORPORATE_MEDIUM_EUROPE',
    FixedIncomeCorporateShortEurope: 'FIXED_INCOME_CORPORATE_SHORT_EUROPE',
    FixedIncomeCorporateLongEurope: 'FIXED_INCOME_CORPORATE_LONG_EUROPE',
    FixedIncomeCorporateEurope: 'FIXED_INCOME_CORPORATE_EUROPE',
    FixedIncomeGovernmentEurope: 'FIXED_INCOME_GOVERNMENT_EUROPE',
    FixedIncomeSpreadEurope: 'FIXED_INCOME_SPREAD_EUROPE',
    FixedIncomeSpreadUsa: 'FIXED_INCOME_SPREAD_USA',
    FixedIncomeGovernmentInflationLinkedEurope: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_EUROPE',
    FixedIncomeGovernmentLongEurope: 'FIXED_INCOME_GOVERNMENT_LONG_EUROPE',
    FixedIncomeGovernmentMediumEurope: 'FIXED_INCOME_GOVERNMENT_MEDIUM_EUROPE',
    FixedIncomeGovernmentShortEurope: 'FIXED_INCOME_GOVERNMENT_SHORT_EUROPE',
    FixedIncomeHighYieldEurope: 'FIXED_INCOME_HIGH_YIELD_EUROPE',
    FixedIncomeConvertiblesEurope: 'FIXED_INCOME_CONVERTIBLES_EUROPE',
    FixedIncomeCorporateMediumUsa: 'FIXED_INCOME_CORPORATE_MEDIUM_USA',
    FixedIncomeCorporateShortUsa: 'FIXED_INCOME_CORPORATE_SHORT_USA',
    FixedIncomeCorporateLongUsa: 'FIXED_INCOME_CORPORATE_LONG_USA',
    FixedIncomeCorporateUsa: 'FIXED_INCOME_CORPORATE_USA',
    FixedIncomeGovernmentUsa: 'FIXED_INCOME_GOVERNMENT_USA',
    FixedIncomeGovernmentInflationLinkedUsa: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_USA',
    FixedIncomeGovernmentLongUsa: 'FIXED_INCOME_GOVERNMENT_LONG_USA',
    FixedIncomeGovernmentMediumUsa: 'FIXED_INCOME_GOVERNMENT_MEDIUM_USA',
    FixedIncomeGovernmentShortUsa: 'FIXED_INCOME_GOVERNMENT_SHORT_USA',
    FixedIncomeHighYieldUsa: 'FIXED_INCOME_HIGH_YIELD_USA',
    FixedIncomeLeveragedLoansUsa: 'FIXED_INCOME_LEVERAGED_LOANS_USA',
    FixedIncomeConvertiblesUsa: 'FIXED_INCOME_CONVERTIBLES_USA',
    MoneyMarketEurope: 'MONEY_MARKET_EUROPE',
    EquityItaly: 'EQUITY_ITALY',
    EquityAustralia: 'EQUITY_AUSTRALIA',
    EquityCanada: 'EQUITY_CANADA',
    EquityChina: 'EQUITY_CHINA',
    EquityCommunicationServicesChina: 'EQUITY_COMMUNICATION_SERVICES_CHINA',
    EquityConsumerDiscretionaryChina: 'EQUITY_CONSUMER_DISCRETIONARY_CHINA',
    EquityConsumerStaplesChina: 'EQUITY_CONSUMER_STAPLES_CHINA',
    EquityEnergyChina: 'EQUITY_ENERGY_CHINA',
    EquityFinancialsChina: 'EQUITY_FINANCIALS_CHINA',
    EquityHealthCareChina: 'EQUITY_HEALTH_CARE_CHINA',
    EquityIndustrialsChina: 'EQUITY_INDUSTRIALS_CHINA',
    EquityInformationTechnologyChina: 'EQUITY_INFORMATION_TECHNOLOGY_CHINA',
    EquityMaterialsChina: 'EQUITY_MATERIALS_CHINA',
    EquityRealEstateChina: 'EQUITY_REAL_ESTATE_CHINA',
    EquityUtilitiesChina: 'EQUITY_UTILITIES_CHINA',
    EquityEmea: 'EQUITY_EMEA',
    EquityCommunicationServicesEmergingMarkets: 'EQUITY_COMMUNICATION_SERVICES_EMERGING_MARKETS',
    EquityConsumerDiscretionaryEmergingMarkets: 'EQUITY_CONSUMER_DISCRETIONARY_EMERGING_MARKETS',
    EquityConsumerStaplesEmergingMarkets: 'EQUITY_CONSUMER_STAPLES_EMERGING_MARKETS',
    EquityEnergyEmergingMarkets: 'EQUITY_ENERGY_EMERGING_MARKETS',
    EquityFinancialsEmergingMarkets: 'EQUITY_FINANCIALS_EMERGING_MARKETS',
    EquityHealthCareEmergingMarkets: 'EQUITY_HEALTH_CARE_EMERGING_MARKETS',
    EquityIndustrialsEmergingMarkets: 'EQUITY_INDUSTRIALS_EMERGING_MARKETS',
    EquityInformationTechnologyEmergingMarkets: 'EQUITY_INFORMATION_TECHNOLOGY_EMERGING_MARKETS',
    EquityMaterialsEmergingMarkets: 'EQUITY_MATERIALS_EMERGING_MARKETS',
    EquityRealEstateEmergingMarkets: 'EQUITY_REAL_ESTATE_EMERGING_MARKETS',
    EquityUtilitiesEmergingMarkets: 'EQUITY_UTILITIES_EMERGING_MARKETS',
    EquityCommunicationServicesEurozone: 'EQUITY_COMMUNICATION_SERVICES_EUROZONE',
    EquityConsumerDiscretionaryEurozone: 'EQUITY_CONSUMER_DISCRETIONARY_EUROZONE',
    EquityConsumerStaplesEurozone: 'EQUITY_CONSUMER_STAPLES_EUROZONE',
    EquityEnergyEurozone: 'EQUITY_ENERGY_EUROZONE',
    EquityFinancialsEurozone: 'EQUITY_FINANCIALS_EUROZONE',
    EquityHealthCareEurozone: 'EQUITY_HEALTH_CARE_EUROZONE',
    EquityIndustrialsEurozone: 'EQUITY_INDUSTRIALS_EUROZONE',
    EquityInformationTechnologyEurozone: 'EQUITY_INFORMATION_TECHNOLOGY_EUROZONE',
    EquityMaterialsEurozone: 'EQUITY_MATERIALS_EUROZONE',
    EquityRealEstateEurozone: 'EQUITY_REAL_ESTATE_EUROZONE',
    EquityUtilitiesEurozone: 'EQUITY_UTILITIES_EUROZONE',
    EquityEurozone: 'EQUITY_EUROZONE',
    EquityFrance: 'EQUITY_FRANCE',
    EquityGermany: 'EQUITY_GERMANY',
    EquityCommunicationServices: 'EQUITY_COMMUNICATION_SERVICES',
    EquityConsumerDiscretionary: 'EQUITY_CONSUMER_DISCRETIONARY',
    EquityConsumerStaples: 'EQUITY_CONSUMER_STAPLES',
    EquityEnergy: 'EQUITY_ENERGY',
    EquityFinancials: 'EQUITY_FINANCIALS',
    EquityHealthCare: 'EQUITY_HEALTH_CARE',
    EquityIndustrials: 'EQUITY_INDUSTRIALS',
    EquityInformationTechnology: 'EQUITY_INFORMATION_TECHNOLOGY',
    EquityMaterials: 'EQUITY_MATERIALS',
    EquityRealEstate: 'EQUITY_REAL_ESTATE',
    EquityUtilities: 'EQUITY_UTILITIES',
    EquitySmallCap: 'EQUITY_SMALL_CAP',
    EquityInfrastructure: 'EQUITY_INFRASTRUCTURE',
    EquityHongKong: 'EQUITY_HONG_KONG',
    EquitySmallCapJapan: 'EQUITY_SMALL_CAP_JAPAN',
    EquityCommunicationServicesJapan: 'EQUITY_COMMUNICATION_SERVICES_JAPAN',
    EquityConsumerDiscretionaryJapan: 'EQUITY_CONSUMER_DISCRETIONARY_JAPAN',
    EquityConsumerStaplesJapan: 'EQUITY_CONSUMER_STAPLES_JAPAN',
    EquityEnergyJapan: 'EQUITY_ENERGY_JAPAN',
    EquityFinancialsJapan: 'EQUITY_FINANCIALS_JAPAN',
    EquityHealthCareJapan: 'EQUITY_HEALTH_CARE_JAPAN',
    EquityIndustrialsJapan: 'EQUITY_INDUSTRIALS_JAPAN',
    EquityInformationTechnologyJapan: 'EQUITY_INFORMATION_TECHNOLOGY_JAPAN',
    EquityMaterialsJapan: 'EQUITY_MATERIALS_JAPAN',
    EquityRealEstateJapan: 'EQUITY_REAL_ESTATE_JAPAN',
    EquityUtilitiesJapan: 'EQUITY_UTILITIES_JAPAN',
    EquityLatinAmerica: 'EQUITY_LATIN_AMERICA',
    EquityPacificExJapan: 'EQUITY_PACIFIC_EX_JAPAN',
    EquitySingapore: 'EQUITY_SINGAPORE',
    EquitySpain: 'EQUITY_SPAIN',
    EquitySwitzerland: 'EQUITY_SWITZERLAND',
    EquityCommunicationServicesSwitzerland: 'EQUITY_COMMUNICATION_SERVICES_SWITZERLAND',
    EquityConsumerDiscretionarySwitzerland: 'EQUITY_CONSUMER_DISCRETIONARY_SWITZERLAND',
    EquityConsumerStaplesSwitzerland: 'EQUITY_CONSUMER_STAPLES_SWITZERLAND',
    EquityEnergySwitzerland: 'EQUITY_ENERGY_SWITZERLAND',
    EquityFinancialsSwitzerland: 'EQUITY_FINANCIALS_SWITZERLAND',
    EquityHealthCareSwitzerland: 'EQUITY_HEALTH_CARE_SWITZERLAND',
    EquityIndustrialsSwitzerland: 'EQUITY_INDUSTRIALS_SWITZERLAND',
    EquityInformationTechnologySwitzerland: 'EQUITY_INFORMATION_TECHNOLOGY_SWITZERLAND',
    EquityMaterialsSwitzerland: 'EQUITY_MATERIALS_SWITZERLAND',
    EquityRealEstateSwitzerland: 'EQUITY_REAL_ESTATE_SWITZERLAND',
    EquityUtilitiesSwitzerland: 'EQUITY_UTILITIES_SWITZERLAND',
    EquityCommunicationServicesUnitedKingdom: 'EQUITY_COMMUNICATION_SERVICES_UNITED_KINGDOM',
    EquityConsumerDiscretionaryUnitedKingdom: 'EQUITY_CONSUMER_DISCRETIONARY_UNITED_KINGDOM',
    EquityConsumerStaplesUnitedKingdom: 'EQUITY_CONSUMER_STAPLES_UNITED_KINGDOM',
    EquityEnergyUnitedKingdom: 'EQUITY_ENERGY_UNITED_KINGDOM',
    EquityFinancialsUnitedKingdom: 'EQUITY_FINANCIALS_UNITED_KINGDOM',
    EquityHealthCareUnitedKingdom: 'EQUITY_HEALTH_CARE_UNITED_KINGDOM',
    EquityIndustrialsUnitedKingdom: 'EQUITY_INDUSTRIALS_UNITED_KINGDOM',
    EquityInformationTechnologyUnitedKingdom: 'EQUITY_INFORMATION_TECHNOLOGY_UNITED_KINGDOM',
    EquityMaterialsUnitedKingdom: 'EQUITY_MATERIALS_UNITED_KINGDOM',
    EquityRealEstateUnitedKingdom: 'EQUITY_REAL_ESTATE_UNITED_KINGDOM',
    EquityUtilitiesUnitedKingdom: 'EQUITY_UTILITIES_UNITED_KINGDOM',
    EquityUnitedKingdom: 'EQUITY_UNITED_KINGDOM',
    EquitySmallCapUsa: 'EQUITY_SMALL_CAP_USA',
    EquityMidCapUsa: 'EQUITY_MID_CAP_USA',
    MoneyMarketUnitedKingdom: 'MONEY_MARKET_UNITED_KINGDOM',
    MoneyMarketSwitzerland: 'MONEY_MARKET_SWITZERLAND',
    MoneyMarketAustralia: 'MONEY_MARKET_AUSTRALIA',
    MoneyMarketJapan: 'MONEY_MARKET_JAPAN',
    FixedIncomeGovernmentMediumAustralia: 'FIXED_INCOME_GOVERNMENT_MEDIUM_AUSTRALIA',
    FixedIncomeGovernmentLongAustralia: 'FIXED_INCOME_GOVERNMENT_LONG_AUSTRALIA',
    FixedIncomeGovernmentShortChina: 'FIXED_INCOME_GOVERNMENT_SHORT_CHINA',
    FixedIncomeGovernmentChina: 'FIXED_INCOME_GOVERNMENT_CHINA',
    FixedIncomeGovernmentLocalCurrencyEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_EMERGING_MARKETS',
    FixedIncomeGovernmentLongFrance: 'FIXED_INCOME_GOVERNMENT_LONG_FRANCE',
    FixedIncomeGovernmentMediumFrance: 'FIXED_INCOME_GOVERNMENT_MEDIUM_FRANCE',
    FixedIncomeGovernmentShortFrance: 'FIXED_INCOME_GOVERNMENT_SHORT_FRANCE',
    FixedIncomeGovernmentLongGermany: 'FIXED_INCOME_GOVERNMENT_LONG_GERMANY',
    FixedIncomeGovernmentMediumGermany: 'FIXED_INCOME_GOVERNMENT_MEDIUM_GERMANY',
    FixedIncomeGovernmentShortGermany: 'FIXED_INCOME_GOVERNMENT_SHORT_GERMANY',
    FixedIncomeGovernmentInflationLinked: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    MoneyMarketUsa: 'MONEY_MARKET_USA',
    FixedIncomeHighYield: 'FIXED_INCOME_HIGH_YIELD',
    FixedIncomeConvertibles: 'FIXED_INCOME_CONVERTIBLES',
    FixedIncomeGovernmentMedium: 'FIXED_INCOME_GOVERNMENT_MEDIUM',
    FixedIncomeGovernmentLong: 'FIXED_INCOME_GOVERNMENT_LONG',
    FixedIncomeGovernmentShort: 'FIXED_INCOME_GOVERNMENT_SHORT',
    FixedIncomeCorporateShort: 'FIXED_INCOME_CORPORATE_SHORT',
    FixedIncomeCorporateMedium: 'FIXED_INCOME_CORPORATE_MEDIUM',
    FixedIncomeCorporateLong: 'FIXED_INCOME_CORPORATE_LONG',
    FixedIncomeGovernmentAggregate: 'FIXED_INCOME_GOVERNMENT_AGGREGATE',
    FixedIncomeCorporateAggregate: 'FIXED_INCOME_CORPORATE_AGGREGATE',
    FixedIncomeGovernmentMediumItaly: 'FIXED_INCOME_GOVERNMENT_MEDIUM_ITALY',
    FixedIncomeGovernmentLongItaly: 'FIXED_INCOME_GOVERNMENT_LONG_ITALY',
    FixedIncomeGovernmentShortItaly: 'FIXED_INCOME_GOVERNMENT_SHORT_ITALY',
    FixedIncomeGovernmentMediumJapan: 'FIXED_INCOME_GOVERNMENT_MEDIUM_JAPAN',
    FixedIncomeGovernmentLongJapan: 'FIXED_INCOME_GOVERNMENT_LONG_JAPAN',
    FixedIncomeGovernmentMediumSpain: 'FIXED_INCOME_GOVERNMENT_MEDIUM_SPAIN',
    FixedIncomeGovernmentLongSpain: 'FIXED_INCOME_GOVERNMENT_LONG_SPAIN',
    FixedIncomeGovernmentShortSpain: 'FIXED_INCOME_GOVERNMENT_SHORT_SPAIN',
    FixedIncomeGovernmentMediumUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_MEDIUM_UNITED_KINGDOM',
    FixedIncomeGovernmentLinkedUnitedKingdom: 'FIXED_INCOME_GOVERNMENT_LINKED_UNITED_KINGDOM',
    FixedIncomeCorporateMediumUnitedKingdom: 'FIXED_INCOME_CORPORATE_MEDIUM_UNITED_KINGDOM',
    AsiaOceania: 'ASIA_OCEANIA',
    EquityEmergingMarketsAsiaExChina: 'EQUITY_EMERGING_MARKETS_ASIA_EX_CHINA',
    EquityAsia: 'EQUITY_ASIA',
    FixedIncomeCorporateFinancialsEurope: 'FIXED_INCOME_CORPORATE_FINANCIALS_EUROPE',
    FixedIncomeCorporateExFinancialsEurope: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_EUROPE',
    FixedIncomeHighYieldFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_EUROPE',
    FixedIncomeHighYieldExFinancialsEurope: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_EUROPE',
    FixedIncomeCorporateFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_FINANCIALS_USA_AND_CANADA',
    FixedIncomeCorporateExFinancialsUsaAndCanada: 'FIXED_INCOME_CORPORATE_EX_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_FINANCIALS_USA_AND_CANADA',
    FixedIncomeHighYieldExFinancialsUsaAndCanada: 'FIXED_INCOME_HIGH_YIELD_EX_FINANCIALS_USA_AND_CANADA',
    EquityUsa: 'EQUITY_USA',
    CommoditiesGold: 'COMMODITIES_GOLD',
    FixedIncomeGovernmentStrongCurrencyEmergingMarkets: 'FIXED_INCOME_GOVERNMENT_STRONG_CURRENCY_EMERGING_MARKETS',
    EquitySmallCapEurope: 'EQUITY_SMALL_CAP_EUROPE',
    FixedIncomeCorporateShortInvestmentGradeGlobal: 'FIXED_INCOME_CORPORATE_SHORT_INVESTMENT_GRADE_GLOBAL',
    FixedIncomeGovernmentInflationLinkedGlobal: 'FIXED_INCOME_GOVERNMENT_INFLATION_LINKED_GLOBAL',
    CurrencyUsd: 'CURRENCY_USD',
    EquityBrazil: 'EQUITY_BRAZIL',
    EquityEafe: 'EQUITY_EAFE',
    EquityEmergingMarketsExChina: 'EQUITY_EMERGING_MARKETS_EX_CHINA',
    EquityEuropeExUk: 'EQUITY_EUROPE_EX_UK',
    EquityGlobalExEurope: 'EQUITY_GLOBAL_EX_EUROPE',
    EquityGlobalExUk: 'EQUITY_GLOBAL_EX_UK',
    EquityIndia: 'EQUITY_INDIA',
    EquityTurkey: 'EQUITY_TURKEY',
    EquityKorea: 'EQUITY_KOREA',
    FixedIncomeCorporateGlobal: 'FIXED_INCOME_CORPORATE_GLOBAL',
    FixedIncomeGovernmentGlobal: 'FIXED_INCOME_GOVERNMENT_GLOBAL',
    EquityFrontierMarket: 'EQUITY_FRONTIER_MARKET',
    FixedIncomeCorporateAsia: 'FIXED_INCOME_CORPORATE_ASIA',
    FixedIncomeGovernmentAsia: 'FIXED_INCOME_GOVERNMENT_ASIA',
    FixedIncomeHighYieldAsia: 'FIXED_INCOME_HIGH_YIELD_ASIA',
    FixedIncomeGovernmentLocalCurrencyAsia: 'FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY_ASIA',
    MoneyMarketCanada: 'MONEY_MARKET_CANADA',
    MoneyMarketSingapore: 'MONEY_MARKET_SINGAPORE'
} as const;

export type AssetClassIdentifier = typeof AssetClassIdentifier[keyof typeof AssetClassIdentifier];


/**
 * 
 * @export
 * @interface AssetClassRegime
 */
export interface AssetClassRegime {
    /**
     * 
     * @type {Array<LabelValue>}
     * @memberof AssetClassRegime
     */
    'detectedScenario'?: Array<LabelValue>;
    /**
     * 
     * @type {string}
     * @memberof AssetClassRegime
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AssetClassRegime
     */
    'regimePeriod'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<LabelValue>}
     * @memberof AssetClassRegime
     */
    'comparables'?: Array<LabelValue>;
}
/**
 * 
 * @export
 * @interface AssetClassRegimeTimeSeries
 */
export interface AssetClassRegimeTimeSeries {
    /**
     * 
     * @type {number}
     * @memberof AssetClassRegimeTimeSeries
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetClassRegimeTimeSeries
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AssetClassRegimeTimeSeries
     */
    'regimePeriod'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: Array<Array<number>>; }}
     * @memberof AssetClassRegimeTimeSeries
     */
    'comparables'?: { [key: string]: Array<Array<number>>; };
}
/**
 * 
 * @export
 * @interface AssetClassRegimeV2
 */
export interface AssetClassRegimeV2 {
    /**
     * 
     * @type {number}
     * @memberof AssetClassRegimeV2
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetClassRegimeV2
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AssetClassRegimeV2
     */
    'regimePeriod'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<LabelValueV2>}
     * @memberof AssetClassRegimeV2
     */
    'comparables'?: Array<LabelValueV2>;
}
/**
 * 
 * @export
 * @interface AssetClassSummary
 */
export interface AssetClassSummary {
    /**
     * 
     * @type {Volatility}
     * @memberof AssetClassSummary
     */
    'volatility'?: Volatility;
    /**
     * 
     * @type {number}
     * @memberof AssetClassSummary
     */
    'expectedReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetClassSummary
     */
    'expectedVolatility'?: number;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof AssetClassSummary
     */
    'exposureLabel'?: Array<Chip>;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof AssetClassSummary
     */
    'driverLabel'?: Array<Chip>;
    /**
     * 
     * @type {string}
     * @memberof AssetClassSummary
     */
    'type'?: string;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof AssetClassSummary
     */
    'actualRegime'?: Array<Chip>;
    /**
     * 
     * @type {ValueWithData}
     * @memberof AssetClassSummary
     */
    'prevValue'?: ValueWithData;
    /**
     * 
     * @type {Return}
     * @memberof AssetClassSummary
     */
    'return'?: Return;
}
/**
 * 
 * @export
 * @interface AssetClassTickerBreakdownEntry
 */
export interface AssetClassTickerBreakdownEntry {
    /**
     * 
     * @type {string}
     * @memberof AssetClassTickerBreakdownEntry
     */
    'ticker'?: string;
    /**
     * 
     * @type {Array<AssetClassBreakdownEntry>}
     * @memberof AssetClassTickerBreakdownEntry
     */
    'breakdown'?: Array<AssetClassBreakdownEntry>;
}
/**
 * 
 * @export
 * @interface AssetClassesConstraint
 */
export interface AssetClassesConstraint {
    /**
     * 
     * @type {string}
     * @memberof AssetClassesConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetClassesConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AssetClassesConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetClassesConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AssetClassesConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetClassesConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof AssetClassesConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {number}
     * @memberof AssetClassesConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetClassesConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<AssetClass>}
     * @memberof AssetClassesConstraint
     */
    'assetClassesIdentifiers'?: Array<AssetClass>;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof AssetClassesConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface AssetClassesModel
 */
export interface AssetClassesModel {
    /**
     * 
     * @type {string}
     * @memberof AssetClassesModel
     * @deprecated
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClassesModel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetClassesModel
     */
    'label'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetClassesModel
     */
    'sector'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Author
 */
export interface Author {
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BenchmarkIds = {
    _10Equity90FixedIncome: '_10_EQUITY_90_FIXED_INCOME',
    _30Equity70FixedIncome: '_30_EQUITY_70_FIXED_INCOME',
    _40Equity60FixedIncome: '_40_EQUITY_60_FIXED_INCOME',
    _50Equity50FixedIncome: '_50_EQUITY_50_FIXED_INCOME',
    _60Equity40FixedIncome: '_60_EQUITY_40_FIXED_INCOME',
    _70Equity30FixedIncome: '_70_EQUITY_30_FIXED_INCOME',
    _90Equity10FixedIncome: '_90_EQUITY_10_FIXED_INCOME',
    _50EquityEu50EquityUs: '_50_EQUITY_EU_50_EQUITY_US',
    _100EquityUs: '_100_EQUITY_US',
    _100EquityEu: '_100_EQUITY_EU',
    _100EquityGlobal: '_100_EQUITY_GLOBAL',
    _100FixedIncome: '_100_FIXED_INCOME',
    _0EquityUs: '_0_EQUITY_US',
    _100EquityUs3000: '_100_EQUITY_US_3000',
    _70MoneyMarket30FixedIncome: '_70_MONEY_MARKET_30_FIXED_INCOME',
    _50Equity25FixedIncome20Alternative5MoneyMarket: '_50_EQUITY_25_FIXED_INCOME_20_ALTERNATIVE_5_MONEY_MARKET',
    _30Equity45FixedIncome20Alternative5MoneyMarket: '_30_EQUITY_45_FIXED_INCOME_20_ALTERNATIVE_5_MONEY_MARKET',
    _100EquityItaly: '_100_EQUITY_ITALY',
    _100EquityItalySmallMid: '_100_EQUITY_ITALY_SMALL_MID',
    _100EquityFrance: '_100_EQUITY_FRANCE',
    _100EquityGermany: '_100_EQUITY_GERMANY',
    _100EquityEmergingMarkets: '_100_EQUITY_EMERGING_MARKETS',
    _100EquityJapan: '_100_EQUITY_JAPAN',
    _45EquityGlobal55FixedIncomeEurope: '_45_EQUITY_GLOBAL_55_FIXED_INCOME_EUROPE',
    _100MoneyMarketUsd: '_100_MONEY_MARKET_USD',
    _100MoneyMarketEur: '_100_MONEY_MARKET_EUR',
    _100MoneyMarketChf: '_100_MONEY_MARKET_CHF',
    _100MoneyMarketGbp: '_100_MONEY_MARKET_GBP',
    _100MoneyMarketCad: '_100_MONEY_MARKET_CAD',
    _100MoneyMarketJpy: '_100_MONEY_MARKET_JPY',
    _100MoneyMarketAud: '_100_MONEY_MARKET_AUD'
} as const;

export type BenchmarkIds = typeof BenchmarkIds[keyof typeof BenchmarkIds];


/**
 * 
 * @export
 * @interface BenchmarkInvestmentReports
 */
export interface BenchmarkInvestmentReports {
    /**
     * 
     * @type {{ [key: string]: Array<PerformanceAttributionEntry>; }}
     * @memberof BenchmarkInvestmentReports
     */
    'realizedPerformanceAttribution'?: { [key: string]: Array<PerformanceAttributionEntry>; };
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof BenchmarkInvestmentReports
     */
    'performance'?: Array<Array<number>>;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performanceYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performanceMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performance1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performance3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performance6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performance1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'performanceSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatilityYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatilityMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatility3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatility6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatility1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'volatilitySinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortinoYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortinoMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortino1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortino3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortino6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortino1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'sortinoSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatioYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatioMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatio1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatio3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatio6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatio1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'efficiencyRatioSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdownYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdownMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdown1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdown3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdown6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdown1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     */
    'maxDrawdownSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     * @deprecated
     */
    'efficiencyRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkInvestmentReports
     * @deprecated
     */
    'maxDrawDown'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BenchmarkInvestmentReports
     */
    'marketViewExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BenchmarkInvestmentReports
     */
    'marketViewFullExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {RealizedSyntheticMetrics}
     * @memberof BenchmarkInvestmentReports
     */
    'realizedSyntheticMetrics'?: RealizedSyntheticMetrics;
}
/**
 * 
 * @export
 * @interface BenchmarkMinInfo
 */
export interface BenchmarkMinInfo {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkMinInfo
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkMinInfo
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface BenchmarkModel
 */
export interface BenchmarkModel {
    /**
     * 
     * @type {Array<AccessControl>}
     * @memberof BenchmarkModel
     */
    'acl'?: Array<AccessControl>;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BenchmarkModel
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     */
    'lastReportsUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof BenchmarkModel
     */
    'actualAction'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof BenchmarkModel
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {BenchmarkReportsModel}
     * @memberof BenchmarkModel
     */
    'reports'?: BenchmarkReportsModel;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof BenchmarkModel
     */
    'uploadComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof BenchmarkModel
     */
    'uploadErrors'?: Array<UploadError>;
    /**
     * 
     * @type {EntityError}
     * @memberof BenchmarkModel
     */
    'currentError'?: EntityError;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     * @deprecated
     */
    'actionUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkModel
     * @deprecated
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface BenchmarkReports
 */
export interface BenchmarkReports {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkReports
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkReports
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof BenchmarkReports
     */
    'tickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof BenchmarkReports
     */
    'currencyComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReports
     */
    'extendedProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReports
     */
    'flagshipProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReports
     */
    'hedgedProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {{ [key: string]: CurrencyBenchmarkReports; }}
     * @memberof BenchmarkReports
     */
    'currencyReports'?: { [key: string]: CurrencyBenchmarkReports; };
    /**
     * 
     * @type {Array<string>}
     * @memberof BenchmarkReports
     */
    'batchOrdering'?: Array<BenchmarkReportsBatchOrderingEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof BenchmarkReports
     */
    'executedBatchStep'?: Set<BenchmarkReportsExecutedBatchStepEnum>;
}

export const BenchmarkReportsBatchOrderingEnum = {
    Essentials: 'ESSENTIALS',
    Anagraphic: 'ANAGRAPHIC',
    RealizedSyntheticMetrics: 'REALIZED_SYNTHETIC_METRICS',
    ExAnteSyntheticMetrics: 'EX_ANTE_SYNTHETIC_METRICS',
    ProxyCompositions: 'PROXY_COMPOSITIONS',
    ExAnteContributions: 'EX_ANTE_CONTRIBUTIONS',
    RealizedContributions: 'REALIZED_CONTRIBUTIONS',
    RealizedPerformanceAttribution: 'REALIZED_PERFORMANCE_ATTRIBUTION',
    PerformanceLines: 'PERFORMANCE_LINES',
    Factors: 'FACTORS',
    ExplainabilityDetails: 'EXPLAINABILITY_DETAILS',
    QualityExposures: 'QUALITY_EXPOSURES',
    TickerBreakdowns: 'TICKER_BREAKDOWNS'
} as const;

export type BenchmarkReportsBatchOrderingEnum = typeof BenchmarkReportsBatchOrderingEnum[keyof typeof BenchmarkReportsBatchOrderingEnum];
export const BenchmarkReportsExecutedBatchStepEnum = {
    Essentials: 'ESSENTIALS',
    Anagraphic: 'ANAGRAPHIC',
    RealizedSyntheticMetrics: 'REALIZED_SYNTHETIC_METRICS',
    ExAnteSyntheticMetrics: 'EX_ANTE_SYNTHETIC_METRICS',
    ProxyCompositions: 'PROXY_COMPOSITIONS',
    ExAnteContributions: 'EX_ANTE_CONTRIBUTIONS',
    RealizedContributions: 'REALIZED_CONTRIBUTIONS',
    RealizedPerformanceAttribution: 'REALIZED_PERFORMANCE_ATTRIBUTION',
    PerformanceLines: 'PERFORMANCE_LINES',
    Factors: 'FACTORS',
    ExplainabilityDetails: 'EXPLAINABILITY_DETAILS',
    QualityExposures: 'QUALITY_EXPOSURES',
    TickerBreakdowns: 'TICKER_BREAKDOWNS'
} as const;

export type BenchmarkReportsExecutedBatchStepEnum = typeof BenchmarkReportsExecutedBatchStepEnum[keyof typeof BenchmarkReportsExecutedBatchStepEnum];

/**
 * 
 * @export
 * @interface BenchmarkReportsModel
 */
export interface BenchmarkReportsModel {
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof BenchmarkReportsModel
     */
    'tickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof BenchmarkReportsModel
     */
    'currencyComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReportsModel
     */
    'extendedProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReportsModel
     */
    'flagshipProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof BenchmarkReportsModel
     */
    'hedgedProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {{ [key: string]: CurrencyBenchmarkReports; }}
     * @memberof BenchmarkReportsModel
     */
    'currencyReports'?: { [key: string]: CurrencyBenchmarkReports; };
}
/**
 * 
 * @export
 * @interface BenchmarkSnapshot
 */
export interface BenchmarkSnapshot {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSnapshot
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSnapshot
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSnapshot
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof BenchmarkSnapshot
     */
    'composition'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface BenchmarkSummary
 */
export interface BenchmarkSummary {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSummary
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSummary
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof BenchmarkSummary
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof BenchmarkSummary
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSummary
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkSummary
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {RichAcl}
     * @memberof BenchmarkSummary
     */
    'richAcl'?: RichAcl;
}
/**
 * 
 * @export
 * @interface BenchmarkSyntheticMetrics
 */
export interface BenchmarkSyntheticMetrics {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkSyntheticMetrics
     */
    'exAnteAnnualisedReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkSyntheticMetrics
     */
    'exAnteDiversificationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkSyntheticMetrics
     */
    'exAnteEfficiencyRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkSyntheticMetrics
     */
    'exAnteMaxDrawDown'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BenchmarkSyntheticMetrics
     */
    'riskMetrics'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof BenchmarkSyntheticMetrics
     */
    'targetVolatility'?: number;
}
/**
 * 
 * @export
 * @interface BulkActionRequest
 */
export interface BulkActionRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkActionRequest
     */
    'uuids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkActionRequest
     */
    'bulkActionType'?: BulkActionRequestBulkActionTypeEnum;
}

export const BulkActionRequestBulkActionTypeEnum = {
    Creation: 'CREATION',
    Enhancement: 'ENHANCEMENT',
    Upload: 'UPLOAD',
    Edit: 'EDIT',
    EditWithoutAccept: 'EDIT_WITHOUT_ACCEPT',
    FixIssues: 'FIX_ISSUES'
} as const;

export type BulkActionRequestBulkActionTypeEnum = typeof BulkActionRequestBulkActionTypeEnum[keyof typeof BulkActionRequestBulkActionTypeEnum];

/**
 * 
 * @export
 * @interface BulkRunResponse
 */
export interface BulkRunResponse {
    /**
     * 
     * @type {Array<RunResponse>}
     * @memberof BulkRunResponse
     */
    'responses'?: Array<RunResponse>;
    /**
     * 
     * @type {string}
     * @memberof BulkRunResponse
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface BulkStepsAvailability
 */
export interface BulkStepsAvailability {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof BulkStepsAvailability
     */
    'availabilityMap'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BulkStepsAvailability
     */
    'mandatorinessMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<InvestmentMinInfo>}
     * @memberof BulkStepsAvailability
     */
    'investments'?: Array<InvestmentMinInfo>;
    /**
     * 
     * @type {string}
     * @memberof BulkStepsAvailability
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface Chip
 */
export interface Chip {
    /**
     * 
     * @type {string}
     * @memberof Chip
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Chip
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface Commentary
 */
export interface Commentary {
    /**
     * 
     * @type {string}
     * @memberof Commentary
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface CommentaryDto
 */
export interface CommentaryDto {
    /**
     * 
     * @type {string}
     * @memberof CommentaryDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryDto
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface CommentaryModel
 */
export interface CommentaryModel {
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     * @deprecated
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentaryModel
     */
    'modification_time'?: string;
}
/**
 * 
 * @export
 * @interface CommoditiesIndicators
 */
export interface CommoditiesIndicators {
    /**
     * 
     * @type {Array<Sector>}
     * @memberof CommoditiesIndicators
     */
    'sector'?: Array<Sector>;
    /**
     * 
     * @type {number}
     * @memberof CommoditiesIndicators
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface CompatibleBenchmarkEntry
 */
export interface CompatibleBenchmarkEntry {
    /**
     * 
     * @type {string}
     * @memberof CompatibleBenchmarkEntry
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompatibleBenchmarkEntry
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompatibleBenchmarkEntry
     */
    'exposureSummary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompatibleBenchmarkEntry
     */
    'linked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompatibleBenchmarkEntry
     */
    'primary'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompatibleBenchmarkEntry
     */
    'custom'?: boolean;
}
/**
 * 
 * @export
 * @interface Composition
 */
export interface Composition {
    /**
     * 
     * @type {string}
     * @memberof Composition
     */
    'macroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof Composition
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof Composition
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof Composition
     */
    'weight'?: string;
}
/**
 * 
 * @export
 * @interface ConditionalHistoricalTransition
 */
export interface ConditionalHistoricalTransition {
    /**
     * 
     * @type {SingleTransition}
     * @memberof ConditionalHistoricalTransition
     */
    'data'?: SingleTransition;
    /**
     * 
     * @type {number}
     * @memberof ConditionalHistoricalTransition
     */
    'regime'?: number;
}
/**
 * 
 * @export
 * @interface ConstrainedTicker
 */
export interface ConstrainedTicker {
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConstrainedTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConstrainedTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof ConstrainedTicker
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'proxyOverwriteType'?: ConstrainedTickerProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ConstrainedTicker
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConstrainedTicker
     */
    'feasible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConstrainedTicker
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConstrainedTicker
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstrainedTicker
     */
    'identifier'?: string;
}

export const ConstrainedTickerProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type ConstrainedTickerProxyOverwriteTypeEnum = typeof ConstrainedTickerProxyOverwriteTypeEnum[keyof typeof ConstrainedTickerProxyOverwriteTypeEnum];

/**
 * 
 * @export
 * @interface ConstraintCheckStepValues
 */
export interface ConstraintCheckStepValues {
    /**
     * 
     * @type {number}
     * @memberof ConstraintCheckStepValues
     */
    'allocationInFunds'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConstraintCheckStepValues
     */
    'deltaFromClientEnhancement'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConstraintCheckStepValues
     */
    'deltaAllocationInFunds'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConstraintRelation = {
    Max: 'MAX',
    Min: 'MIN',
    Equal: 'EQUAL',
    Between: 'BETWEEN',
    Exclude: 'EXCLUDE'
} as const;

export type ConstraintRelation = typeof ConstraintRelation[keyof typeof ConstraintRelation];


/**
 * 
 * @export
 * @enum {string}
 */

export const ConstraintValidity = {
    Valid: 'VALID',
    Invalid: 'INVALID',
    Unchecked: 'UNCHECKED'
} as const;

export type ConstraintValidity = typeof ConstraintValidity[keyof typeof ConstraintValidity];


/**
 * 
 * @export
 * @interface ConstraintsTemplate
 */
export interface ConstraintsTemplate {
    /**
     * 
     * @type {string}
     * @memberof ConstraintsTemplate
     */
    'constraintType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsTemplate
     */
    'subject'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConstraintsTemplate
     */
    'soft'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConstraintsTemplate
     */
    'relation'?: ConstraintsTemplateRelationEnum;
    /**
     * 
     * @type {number}
     * @memberof ConstraintsTemplate
     */
    'value1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConstraintsTemplate
     */
    'value2'?: number;
}

export const ConstraintsTemplateRelationEnum = {
    Le: 'LE',
    Ge: 'GE',
    Eq: 'EQ',
    Between: 'BETWEEN',
    Exclude: 'EXCLUDE'
} as const;

export type ConstraintsTemplateRelationEnum = typeof ConstraintsTemplateRelationEnum[keyof typeof ConstraintsTemplateRelationEnum];

/**
 * 
 * @export
 * @interface ContributionEntry
 */
export interface ContributionEntry {
    /**
     * 
     * @type {string}
     * @memberof ContributionEntry
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContributionEntry
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContributionEntry
     */
    'geography'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContributionEntry
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ConversationContext
 */
export interface ConversationContext {
    /**
     * 
     * @type {string}
     * @memberof ConversationContext
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationContext
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface ConversationMessageRequest
 */
export interface ConversationMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ConversationMessageRequest
     */
    'message'?: string;
    /**
     * 
     * @type {ConversationContext}
     * @memberof ConversationMessageRequest
     */
    'context'?: ConversationContext;
}
/**
 * 
 * @export
 * @interface ConversationMinInfo
 */
export interface ConversationMinInfo {
    /**
     * 
     * @type {string}
     * @memberof ConversationMinInfo
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMinInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMinInfo
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMinInfo
     */
    'lastMessageTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMinInfo
     */
    'firstMessageContent'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationMinInfo
     */
    'favourite'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateMarketViewRequest
 */
export interface CreateMarketViewRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMarketViewRequest
     */
    'name'?: string;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof CreateMarketViewRequest
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {string}
     * @memberof CreateMarketViewRequest
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {Regimes}
     * @memberof CreateMarketViewRequest
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof CreateMarketViewRequest
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof CreateMarketViewRequest
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof CreateMarketViewRequest
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {MarketViewType}
     * @memberof CreateMarketViewRequest
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMarketViewRequest
     */
    'custom'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currencies = {
    Eur: 'EUR',
    Usd: 'USD',
    Chf: 'CHF',
    Gbp: 'GBP',
    Hrk: 'HRK',
    Mxn: 'MXN',
    Zar: 'ZAR',
    Inr: 'INR',
    Xau: 'XAU',
    Xpd: 'XPD',
    Thb: 'THB',
    Cny: 'CNY',
    Ils: 'ILS',
    Krw: 'KRW',
    Jpy: 'JPY',
    Aud: 'AUD',
    Pln: 'PLN',
    Idr: 'IDR',
    Huf: 'HUF',
    Xpt: 'XPT',
    Rub: 'RUB',
    Isk: 'ISK',
    Hkd: 'HKD',
    Twd: 'TWD',
    Dkk: 'DKK',
    Cad: 'CAD',
    Bgn: 'BGN',
    Nok: 'NOK',
    Ron: 'RON',
    Sgd: 'SGD',
    Czk: 'CZK',
    Sek: 'SEK',
    Nzd: 'NZD',
    Brl: 'BRL',
    Xag: 'XAG'
} as const;

export type Currencies = typeof Currencies[keyof typeof Currencies];


/**
 * 
 * @export
 * @interface CurrencyBenchmarkReports
 */
export interface CurrencyBenchmarkReports {
    /**
     * 
     * @type {BenchmarkSyntheticMetrics}
     * @memberof CurrencyBenchmarkReports
     */
    'syntheticMetrics'?: BenchmarkSyntheticMetrics;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof CurrencyBenchmarkReports
     */
    'performance'?: Array<Array<number>>;
    /**
     * 
     * @type {Array<FactorValue>}
     * @memberof CurrencyBenchmarkReports
     */
    'factors'?: Array<FactorValue>;
}
/**
 * 
 * @export
 * @interface CurrencyConstraint
 */
export interface CurrencyConstraint {
    /**
     * 
     * @type {string}
     * @memberof CurrencyConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof CurrencyConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<Currencies>}
     * @memberof CurrencyConstraint
     */
    'currencies'?: Array<Currencies>;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof CurrencyConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface CurrentAndPreviousDto
 */
export interface CurrentAndPreviousDto {
    /**
     * 
     * @type {ValueWithData}
     * @memberof CurrentAndPreviousDto
     */
    'currentValue'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof CurrentAndPreviousDto
     */
    'prevValue'?: ValueWithData;
}
/**
 * 
 * @export
 * @interface CustomMarketViewMicroAssetClass
 */
export interface CustomMarketViewMicroAssetClass {
    /**
     * 
     * @type {MarketViewMicroAssetClasses}
     * @memberof CustomMarketViewMicroAssetClass
     */
    'microAssetClass'?: MarketViewMicroAssetClasses;
    /**
     * 
     * @type {string}
     * @memberof CustomMarketViewMicroAssetClass
     */
    'alias'?: string;
}
/**
 * 
 * @export
 * @interface CustomMarketViewSettings
 */
export interface CustomMarketViewSettings {
    /**
     * 
     * @type {boolean}
     * @memberof CustomMarketViewSettings
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {MarketViewType}
     * @memberof CustomMarketViewSettings
     */
    'type'?: MarketViewType;
    /**
     * 
     * @type {string}
     * @memberof CustomMarketViewSettings
     */
    'customMarketViewName'?: string;
    /**
     * 
     * @type {Array<MarketScenarioMinInfo>}
     * @memberof CustomMarketViewSettings
     */
    'customMarketViewScenarios'?: Array<MarketScenarioMinInfo>;
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof CustomMarketViewSettings
     */
    'assetClasses'?: Array<MarketViewMicroAssetClasses>;
}
/**
 * 
 * @export
 * @interface CustomReportDataDTO
 */
export interface CustomReportDataDTO {
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomReportDataDTO
     */
    'clientLogo'?: FileResponseContentType;
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomReportDataDTO
     */
    'backgroundImage'?: FileResponseContentType;
    /**
     * 
     * @type {CustomReportFooterDTO}
     * @memberof CustomReportDataDTO
     */
    'footer'?: CustomReportFooterDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomReportDataDTO
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomReportDataDTO
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomReportDataDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomReportDataDTO
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface CustomReportFooterDTO
 */
export interface CustomReportFooterDTO {
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomReportFooterDTO
     */
    'logo'?: FileResponseContentType;
    /**
     * 
     * @type {string}
     * @memberof CustomReportFooterDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CustomReportRequestDto
 */
export interface CustomReportRequestDto {
    /**
     * 
     * @type {Array<PageDto>}
     * @memberof CustomReportRequestDto
     */
    'pages'?: Array<PageDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomReportRequestDto
     */
    'templateType'?: string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {InvestmentImportConverterType}
     * @memberof Customer
     */
    'importServiceType'?: InvestmentImportConverterType;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'importConnectionType'?: CustomerImportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'importUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'importPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'importUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'importPollingFrequencyInMinutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     * @deprecated
     */
    'userWhoReceivePtfFromImport'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'importDirectory'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'port'?: number;
    /**
     * 
     * @type {InvestmentExportConverterType}
     * @memberof Customer
     */
    'exportServiceType'?: InvestmentExportConverterType;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'exportConnectionType'?: CustomerExportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'exportUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'exportPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'exportUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'exportEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'exportDirectory'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'exportPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'clientLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'backgroundImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'footerImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'footerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'profilePhoto'?: string;
    /**
     * 
     * @type {MarketViewSettings}
     * @memberof Customer
     */
    'marketViewSettings'?: MarketViewSettings;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'maxRisk'?: number;
}

export const CustomerImportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type CustomerImportConnectionTypeEnum = typeof CustomerImportConnectionTypeEnum[keyof typeof CustomerImportConnectionTypeEnum];
export const CustomerExportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type CustomerExportConnectionTypeEnum = typeof CustomerExportConnectionTypeEnum[keyof typeof CustomerExportConnectionTypeEnum];

/**
 * 
 * @export
 * @interface CustomerDetailsDTO
 */
export interface CustomerDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDetailsDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailsDTO
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailsDTO
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailsDTO
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {ImportDto}
     * @memberof CustomerDetailsDTO
     */
    'importData'?: ImportDto;
    /**
     * 
     * @type {ExportDto}
     * @memberof CustomerDetailsDTO
     */
    'exportData'?: ExportDto;
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomerDetailsDTO
     */
    'clientLogo'?: FileResponseContentType;
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomerDetailsDTO
     */
    'backgroundImage'?: FileResponseContentType;
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomerDetailsDTO
     */
    'footerImage'?: FileResponseContentType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'footerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'address'?: string;
    /**
     * 
     * @type {MarketViewSettings}
     * @memberof CustomerDetailsDTO
     */
    'marketViewSettings'?: MarketViewSettings;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailsDTO
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailsDTO
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailsDTO
     */
    'maxRisk'?: number;
    /**
     * 
     * @type {FileResponseContentType}
     * @memberof CustomerDetailsDTO
     */
    'profilePhoto'?: FileResponseContentType;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {ImportDto}
     * @memberof CustomerDto
     */
    'importData'?: ImportDto;
    /**
     * 
     * @type {ExportDto}
     * @memberof CustomerDto
     */
    'exportData'?: ExportDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'clientLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'backgroundImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'footerImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'footerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'address'?: string;
    /**
     * 
     * @type {MarketViewSettings}
     * @memberof CustomerDto
     */
    'marketViewSettings'?: MarketViewSettings;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'maxRisk'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'profilePhoto'?: string;
}
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {InvestmentImportConverterType}
     * @memberof CustomerModel
     */
    'importServiceType'?: InvestmentImportConverterType;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'importConnectionType'?: CustomerModelImportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'importUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'importPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'importUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'importPollingFrequencyInMinutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     * @deprecated
     */
    'userWhoReceivePtfFromImport'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModel
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'importDirectory'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'port'?: number;
    /**
     * 
     * @type {InvestmentExportConverterType}
     * @memberof CustomerModel
     */
    'exportServiceType'?: InvestmentExportConverterType;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'exportConnectionType'?: CustomerModelExportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'exportUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'exportPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'exportUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModel
     */
    'exportEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'exportDirectory'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'exportPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'clientLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'backgroundImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'footerImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'footerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'profilePhoto'?: string;
    /**
     * 
     * @type {MarketViewSettings}
     * @memberof CustomerModel
     */
    'marketViewSettings'?: MarketViewSettings;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'maxRisk'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModel
     */
    'enabled'?: boolean;
}

export const CustomerModelImportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type CustomerModelImportConnectionTypeEnum = typeof CustomerModelImportConnectionTypeEnum[keyof typeof CustomerModelImportConnectionTypeEnum];
export const CustomerModelExportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type CustomerModelExportConnectionTypeEnum = typeof CustomerModelExportConnectionTypeEnum[keyof typeof CustomerModelExportConnectionTypeEnum];

/**
 * 
 * @export
 * @interface CustomerModelDTO
 */
export interface CustomerModelDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModelDTO
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModelDTO
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModelDTO
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {ImportDto}
     * @memberof CustomerModelDTO
     */
    'importData'?: ImportDto;
    /**
     * 
     * @type {ExportDto}
     * @memberof CustomerModelDTO
     */
    'exportData'?: ExportDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'clientLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'backgroundImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'footerImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'footerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'disclaimer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'address'?: string;
    /**
     * 
     * @type {MarketViewSettings}
     * @memberof CustomerModelDTO
     */
    'marketViewSettings'?: MarketViewSettings;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModelDTO
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModelDTO
     */
    'maxRisk'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'profilePhoto'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelDTO
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModelDTO
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface DefaultMarketViewSettings
 */
export interface DefaultMarketViewSettings {
    /**
     * 
     * @type {MarketViewType}
     * @memberof DefaultMarketViewSettings
     */
    'type'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultMarketViewSettings
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof DefaultMarketViewSettings
     */
    'assetClasses'?: Array<MarketViewMicroAssetClasses>;
}
/**
 * 
 * @export
 * @interface DefaultMessagesResponse
 */
export interface DefaultMessagesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultMessagesResponse
     */
    'defaultMessages'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DefaultTagLabels = {
    HighRiskProfile: 'High Risk Profile',
    LowRiskProfile: 'Low Risk Profile',
    Esg: 'ESG',
    HouseFunds: 'House Funds'
} as const;

export type DefaultTagLabels = typeof DefaultTagLabels[keyof typeof DefaultTagLabels];


/**
 * 
 * @export
 * @interface DraftSummaryResponse
 */
export interface DraftSummaryResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof DraftSummaryResponse
     */
    'availabilityMap'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DraftSummaryResponse
     */
    'mandatorinessMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof DraftSummaryResponse
     */
    'draftPresent'?: boolean;
}
/**
 * 
 * @export
 * @interface DriverDto
 */
export interface DriverDto {
    /**
     * 
     * @type {string}
     * @memberof DriverDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverDto
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverDto
     */
    'inverted'?: boolean;
}
/**
 * 
 * @export
 * @interface EditExplainabilitySettingsRequest
 */
export interface EditExplainabilitySettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof EditExplainabilitySettingsRequest
     */
    'commentaryLanguage'?: EditExplainabilitySettingsRequestCommentaryLanguageEnum;
}

export const EditExplainabilitySettingsRequestCommentaryLanguageEnum = {
    English: 'ENGLISH',
    Italian: 'ITALIAN'
} as const;

export type EditExplainabilitySettingsRequestCommentaryLanguageEnum = typeof EditExplainabilitySettingsRequestCommentaryLanguageEnum[keyof typeof EditExplainabilitySettingsRequestCommentaryLanguageEnum];

/**
 * 
 * @export
 * @interface EditInvestmentBenchmarksRequest
 */
export interface EditInvestmentBenchmarksRequest {
    /**
     * 
     * @type {string}
     * @memberof EditInvestmentBenchmarksRequest
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<CompatibleBenchmarkEntry>}
     * @memberof EditInvestmentBenchmarksRequest
     */
    'benchmarks'?: Array<CompatibleBenchmarkEntry>;
}
/**
 * 
 * @export
 * @interface EditMarketViewRequest
 */
export interface EditMarketViewRequest {
    /**
     * 
     * @type {string}
     * @memberof EditMarketViewRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Regimes}
     * @memberof EditMarketViewRequest
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof EditMarketViewRequest
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof EditMarketViewRequest
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof EditMarketViewRequest
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {MarketViewType}
     * @memberof EditMarketViewRequest
     */
    'marketViewType'?: MarketViewType;
}
/**
 * 
 * @export
 * @interface EditPortfolioMetricsOrderingRequest
 */
export interface EditPortfolioMetricsOrderingRequest {
    /**
     * 
     * @type {Array<PortfolioMetricPreference>}
     * @memberof EditPortfolioMetricsOrderingRequest
     */
    'portfolioMetricPreferences'?: Array<PortfolioMetricPreference>;
}
/**
 * 
 * @export
 * @interface EditReferenceUniverseRequest
 */
export interface EditReferenceUniverseRequest {
    /**
     * 
     * @type {string}
     * @memberof EditReferenceUniverseRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof EditReferenceUniverseRequest
     */
    'universeComposition'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface EditorBenchmarkInstrumentsResponse
 */
export interface EditorBenchmarkInstrumentsResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorBenchmarkInstrumentsResponse
     */
    'composition'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface EditorCompositionResponse
 */
export interface EditorCompositionResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorCompositionResponse
     */
    'composition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {ReviewTicker}
     * @memberof EditorCompositionResponse
     */
    'cashTicker'?: ReviewTicker;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof EditorCompositionResponse
     */
    'uploadErrors'?: Array<UploadError>;
}
/**
 * 
 * @export
 * @interface EditorExcelVerifyResponse
 */
export interface EditorExcelVerifyResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorExcelVerifyResponse
     */
    'composition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof EditorExcelVerifyResponse
     */
    'errors'?: Array<UploadError>;
}
/**
 * 
 * @export
 * @interface EditorSaveEditOrReviewRequest
 */
export interface EditorSaveEditOrReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'currency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'mandateType'?: EditorSaveEditOrReviewRequestMandateTypeEnum;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'tickerComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {InvestmentReference}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'investmentReference'?: InvestmentReference;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewRequest
     */
    'editPolicy'?: EditorSaveEditOrReviewRequestEditPolicyEnum;
}

export const EditorSaveEditOrReviewRequestMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type EditorSaveEditOrReviewRequestMandateTypeEnum = typeof EditorSaveEditOrReviewRequestMandateTypeEnum[keyof typeof EditorSaveEditOrReviewRequestMandateTypeEnum];
export const EditorSaveEditOrReviewRequestEditPolicyEnum = {
    AndAccept: 'SAVE_AND_ACCEPT',
    WithoutAccept: 'SAVE_WITHOUT_ACCEPT'
} as const;

export type EditorSaveEditOrReviewRequestEditPolicyEnum = typeof EditorSaveEditOrReviewRequestEditPolicyEnum[keyof typeof EditorSaveEditOrReviewRequestEditPolicyEnum];

/**
 * 
 * @export
 * @interface EditorSaveEditOrReviewResponse
 */
export interface EditorSaveEditOrReviewResponse {
    /**
     * 
     * @type {string}
     * @memberof EditorSaveEditOrReviewResponse
     */
    'identifier'?: string;
}
/**
 * 
 * @export
 * @interface EditorSaveNewRequest
 */
export interface EditorSaveNewRequest {
    /**
     * 
     * @type {string}
     * @memberof EditorSaveNewRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof EditorSaveNewRequest
     */
    'currency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveNewRequest
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditorSaveNewRequest
     */
    'mandateType'?: EditorSaveNewRequestMandateTypeEnum;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorSaveNewRequest
     */
    'tickerComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {InvestmentReference}
     * @memberof EditorSaveNewRequest
     */
    'investmentReference'?: InvestmentReference;
}

export const EditorSaveNewRequestMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type EditorSaveNewRequestMandateTypeEnum = typeof EditorSaveNewRequestMandateTypeEnum[keyof typeof EditorSaveNewRequestMandateTypeEnum];

/**
 * 
 * @export
 * @interface EditorSaveNewResponse
 */
export interface EditorSaveNewResponse {
    /**
     * 
     * @type {string}
     * @memberof EditorSaveNewResponse
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditorSaveNewResponse
     */
    'estimatedRetrieveTimeInMs'?: number;
}
/**
 * 
 * @export
 * @interface EditorSelectableBasketsRequest
 */
export interface EditorSelectableBasketsRequest {
    /**
     * 
     * @type {Array<SelectableBasket>}
     * @memberof EditorSelectableBasketsRequest
     */
    'selectableBaskets'?: Array<SelectableBasket>;
    /**
     * 
     * @type {string}
     * @memberof EditorSelectableBasketsRequest
     */
    'entity'?: EditorSelectableBasketsRequestEntityEnum;
}

export const EditorSelectableBasketsRequestEntityEnum = {
    Investment: 'INVESTMENT',
    InvestmentEnhancement: 'INVESTMENT_ENHANCEMENT',
    Universe: 'UNIVERSE',
    TargetInvestment: 'TARGET_INVESTMENT',
    Benchmark: 'BENCHMARK',
    InvestmentDraft: 'INVESTMENT_DRAFT'
} as const;

export type EditorSelectableBasketsRequestEntityEnum = typeof EditorSelectableBasketsRequestEntityEnum[keyof typeof EditorSelectableBasketsRequestEntityEnum];

/**
 * 
 * @export
 * @interface EditorSelectableBasketsResponse
 */
export interface EditorSelectableBasketsResponse {
    /**
     * 
     * @type {Array<SelectableBasket>}
     * @memberof EditorSelectableBasketsResponse
     */
    'selectableBaskets'?: Array<SelectableBasket>;
}
/**
 * 
 * @export
 * @interface EditorSelectableInstrumentsResponse
 */
export interface EditorSelectableInstrumentsResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorSelectableInstrumentsResponse
     */
    'availableTickers'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface EditorSelectablePortfoliosResponse
 */
export interface EditorSelectablePortfoliosResponse {
    /**
     * 
     * @type {Array<InvestmentListEntry>}
     * @memberof EditorSelectablePortfoliosResponse
     */
    'selectablePortfolios'?: Array<InvestmentListEntry>;
}
/**
 * 
 * @export
 * @interface EditorVerifyExcelRequest
 */
export interface EditorVerifyExcelRequest {
    /**
     * 
     * @type {string}
     * @memberof EditorVerifyExcelRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditorVerifyExcelRequest
     */
    'entity'?: EditorVerifyExcelRequestEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof EditorVerifyExcelRequest
     */
    'policy'?: EditorVerifyExcelRequestPolicyEnum;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof EditorVerifyExcelRequest
     */
    'composition'?: Array<ReviewTicker>;
}

export const EditorVerifyExcelRequestEntityEnum = {
    Investment: 'INVESTMENT',
    InvestmentEnhancement: 'INVESTMENT_ENHANCEMENT',
    Universe: 'UNIVERSE',
    TargetInvestment: 'TARGET_INVESTMENT',
    Benchmark: 'BENCHMARK',
    InvestmentDraft: 'INVESTMENT_DRAFT'
} as const;

export type EditorVerifyExcelRequestEntityEnum = typeof EditorVerifyExcelRequestEntityEnum[keyof typeof EditorVerifyExcelRequestEntityEnum];
export const EditorVerifyExcelRequestPolicyEnum = {
    EditorWeights: 'PRIORITY_TO_EDITOR_WEIGHTS',
    ExcelWeights: 'PRIORITY_TO_EXCEL_WEIGHTS'
} as const;

export type EditorVerifyExcelRequestPolicyEnum = typeof EditorVerifyExcelRequestPolicyEnum[keyof typeof EditorVerifyExcelRequestPolicyEnum];

/**
 * 
 * @export
 * @interface EditorVerifyPortfolioResponse
 */
export interface EditorVerifyPortfolioResponse {
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof EditorVerifyPortfolioResponse
     */
    'removedTickers'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface ElementDTO
 */
export interface ElementDTO {
    /**
     * 
     * @type {string}
     * @memberof ElementDTO
     */
    'label'?: string;
    /**
     * 
     * @type {ValueWithData}
     * @memberof ElementDTO
     */
    'prevValue'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof ElementDTO
     */
    'currentValue'?: ValueWithData;
}
/**
 * 
 * @export
 * @interface EnableAlertDto
 */
export interface EnableAlertDto {
    /**
     * 
     * @type {string}
     * @memberof EnableAlertDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnableAlertDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnableAlertDto
     */
    'threshold'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EnableAlertDto
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface EnhancementContributionResponse
 */
export interface EnhancementContributionResponse {
    /**
     * 
     * @type {Array<ContributionEntry>}
     * @memberof EnhancementContributionResponse
     */
    'current'?: Array<ContributionEntry>;
    /**
     * 
     * @type {Array<ContributionEntry>}
     * @memberof EnhancementContributionResponse
     */
    'proposal'?: Array<ContributionEntry>;
    /**
     * 
     * @type {Array<ContributionEntry>}
     * @memberof EnhancementContributionResponse
     */
    'benchmark'?: Array<ContributionEntry>;
}
/**
 * 
 * @export
 * @interface EntityError
 */
export interface EntityError {
    /**
     * 
     * @type {string}
     * @memberof EntityError
     */
    'dateReceived'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityError
     */
    'generatedByAction'?: string;
    /**
     * 
     * @type {ServiceError}
     * @memberof EntityError
     */
    'error'?: ServiceError;
}
/**
 * 
 * @export
 * @interface EquityIndicators
 */
export interface EquityIndicators {
    /**
     * 
     * @type {Array<GeographyWithSector>}
     * @memberof EquityIndicators
     */
    'geography'?: Array<GeographyWithSector>;
    /**
     * 
     * @type {number}
     * @memberof EquityIndicators
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface EquityIndicatorsSector
 */
export interface EquityIndicatorsSector {
    /**
     * 
     * @type {string}
     * @memberof EquityIndicatorsSector
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityIndicatorsSector
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityIndicatorsSector
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityIndicatorsSector
     */
    'why'?: string;
    /**
     * 
     * @type {Array<Forecast>}
     * @memberof EquityIndicatorsSector
     */
    'forecast'?: Array<Forecast>;
    /**
     * 
     * @type {AcMetrics}
     * @memberof EquityIndicatorsSector
     */
    'ac_metrics'?: AcMetrics;
    /**
     * 
     * @type {PositioningDrivers}
     * @memberof EquityIndicatorsSector
     */
    'positioning_drivers'?: PositioningDrivers;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquityIndicatorsSector
     */
    'return_analysis_bands'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof EquityIndicatorsSector
     */
    'mdotm_trace'?: number;
}
/**
 * 
 * @export
 * @interface EventBulkDownload
 */
export interface EventBulkDownload {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventBulkDownload
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {UserBulkReportDto}
     * @memberof EventBulkDownload
     */
    'data'?: UserBulkReportDto;
}
/**
 * 
 * @export
 * @interface EventInstrumentCommentaryUpdate
 */
export interface EventInstrumentCommentaryUpdate {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventInstrumentCommentaryUpdate
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {string}
     * @memberof EventInstrumentCommentaryUpdate
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInstrumentCommentaryUpdate
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInstrumentCommentaryUpdate
     */
    'commentaryStatus'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventInvestmentDraftStep = {
    MainInfo: 'MAIN_INFO',
    InvestableUniverse: 'INVESTABLE_UNIVERSE',
    AllocationConstraints: 'ALLOCATION_CONSTRAINTS',
    RiskConstraints: 'RISK_CONSTRAINTS',
    StrategyConstraints: 'STRATEGY_CONSTRAINTS',
    MarketView: 'MARKET_VIEW'
} as const;

export type EventInvestmentDraftStep = typeof EventInvestmentDraftStep[keyof typeof EventInvestmentDraftStep];


/**
 * 
 * @export
 * @interface EventInvestmentDraftUpdate
 */
export interface EventInvestmentDraftUpdate {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventInvestmentDraftUpdate
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {EventInvestmentType}
     * @memberof EventInvestmentDraftUpdate
     */
    'type'?: EventInvestmentType;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentDraftUpdate
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentDraftUpdate
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentDraftUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {Array<EventInvestmentDraftStep>}
     * @memberof EventInvestmentDraftUpdate
     */
    'stepsToUpdate'?: Array<EventInvestmentDraftStep>;
    /**
     * 
     * @type {boolean}
     * @memberof EventInvestmentDraftUpdate
     */
    'enhancementStepper'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventInvestmentType = {
    PortfolioUpdate: 'PORTFOLIO_UPDATE',
    PortfolioUpload: 'PORTFOLIO_UPLOAD',
    UniverseUpdate: 'UNIVERSE_UPDATE',
    ReferenceUpdate: 'REFERENCE_UPDATE',
    InvestmentDraftConfigurationUpdate: 'INVESTMENT_DRAFT_CONFIGURATION_UPDATE',
    PortfolioImport: 'PORTFOLIO_IMPORT',
    InvestmentReportUpdate: 'INVESTMENT_REPORT_UPDATE',
    None: 'NONE'
} as const;

export type EventInvestmentType = typeof EventInvestmentType[keyof typeof EventInvestmentType];


/**
 * 
 * @export
 * @interface EventInvestmentUpdate
 */
export interface EventInvestmentUpdate {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventInvestmentUpdate
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {EventInvestmentType}
     * @memberof EventInvestmentUpdate
     */
    'type'?: EventInvestmentType;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentUpdate
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentUpdate
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventInvestmentUpdate
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventInvestmentUpdate
     */
    'hasBreachedAlerts'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventLevel = {
    Info: 'INFO',
    Warn: 'WARN',
    Error: 'ERROR'
} as const;

export type EventLevel = typeof EventLevel[keyof typeof EventLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventMarketType = {
    MarketUpdate: 'MARKET_UPDATE',
    HmmUpdate: 'HMM_UPDATE'
} as const;

export type EventMarketType = typeof EventMarketType[keyof typeof EventMarketType];


/**
 * 
 * @export
 * @interface EventMarketUpdate
 */
export interface EventMarketUpdate {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventMarketUpdate
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {EventMarketType}
     * @memberof EventMarketUpdate
     */
    'type'?: EventMarketType;
}
/**
 * 
 * @export
 * @interface EventReportUpdate
 */
export interface EventReportUpdate {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventReportUpdate
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {EventInvestmentType}
     * @memberof EventReportUpdate
     */
    'type'?: EventInvestmentType;
    /**
     * 
     * @type {string}
     * @memberof EventReportUpdate
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReportUpdate
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReportUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof EventReportUpdate
     */
    'executedReports'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface EventSharedEntity
 */
export interface EventSharedEntity {
    /**
     * 
     * @type {EventLevel}
     * @memberof EventSharedEntity
     */
    'level'?: EventLevel;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'sharedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'sharedEntityUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'sharedEntityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'grantorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'permissionLevel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventSharedEntity
     */
    'isCustomMarketView'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventSharedEntity
     */
    'marketViewType'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventTopic = {
    OpenChannel: 'open-channel',
    InvestmentUpdate: 'investment-update',
    InvestmentReportUpdate: 'investment-report-update',
    MarketUpdate: 'market-update',
    BenchmarkUpdate: 'benchmark-update',
    InvestmentDraftConfigurationUpdate: 'investment-draft-configuration-update',
    InvestmentBulkEnhanceUpdate: 'investment-bulk-enhance-update',
    InvestmentImportUpdate: 'investment-import-update',
    UserUpdate: 'user-update',
    BulkReportDownload: 'bulk-report-download',
    CommentaryUpdate: 'commentary-update',
    InstrumentCommentaryUpdate: 'instrument-commentary-update',
    SharedEntity: 'shared-entity',
    DeletedEntity: 'deleted-entity',
    RemovedEntity: 'removed-entity',
    ModifiedEntity: 'modified-entity'
} as const;

export type EventTopic = typeof EventTopic[keyof typeof EventTopic];


/**
 * 
 * @export
 * @interface EventUpdateBenchmark
 */
export interface EventUpdateBenchmark {
    /**
     * 
     * @type {string}
     * @memberof EventUpdateBenchmark
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateBenchmark
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EventUserUpdate
 */
export interface EventUserUpdate {
    /**
     * 
     * @type {UserWithCustomerDto}
     * @memberof EventUserUpdate
     */
    'userInfo'?: UserWithCustomerDto;
    /**
     * 
     * @type {EventLevel}
     * @memberof EventUserUpdate
     */
    'level'?: EventLevel;
}
/**
 * 
 * @export
 * @interface EventsModel
 */
export interface EventsModel {
    /**
     * 
     * @type {string}
     * @memberof EventsModel
     * @deprecated
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsModel
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventsModel
     */
    'eventId'?: string;
    /**
     * 
     * @type {object}
     * @memberof EventsModel
     */
    'message'?: object;
    /**
     * 
     * @type {string}
     * @memberof EventsModel
     */
    'creationDate'?: string;
}
/**
 * 
 * @export
 * @interface EvolutionRegimesProbabilities
 */
export interface EvolutionRegimesProbabilities {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EvolutionRegimesProbabilities
     */
    'growth'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EvolutionRegimesProbabilities
     */
    'lateralPhase'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EvolutionRegimesProbabilities
     */
    'highStress'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ExpectedReturnsVolatility
 */
export interface ExpectedReturnsVolatility {
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUs'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEu'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEm'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsFinancials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuFinancials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsIndustrials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuIndustrials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuConsumerStaples'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsConsumerStaples'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuCommunicationServices'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsCommunicationServices'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuInformationTechnology'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsInformationTechnology'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuConsumerDiscretionary'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsConsumerDiscretionary'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuHealthCare'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsHealthCare'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuMaterials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsMaterials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuUtilities'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsUtilities'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuRealEstate'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUsRealEstate'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsHy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuHy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEmGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEmCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesPreciousMetals'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesIndustrialMetals'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesAgriculture'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeItalyShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeItalyMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeItalyLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeSpainShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeSpainMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeSpainLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeFranceShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeFranceMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeFranceLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeGermanyShortGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeGermanyMediumGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeGermanyLongGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuCorpHyExFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuCorpHyFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuCorpIgExFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuCorpIgFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsCorpHyExFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsCorpHyFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsCorpIgExFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsCorpIgFin'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityPacGeographic'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityGlobGeographic'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuGovInflationLinked'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsGovInflationLinked'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeGlobCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeGlobGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketUs'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketEu'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesGlob'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'alternativesGlob'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpFinancials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmFinancials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpIndustrials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmIndustrials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmConsumerStaples'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpConsumerStaples'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmCommunicationServices'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpCommunicationServices'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmInformationTechnology'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpInformationTechnology'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmConsumerDiscretionary'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpConsumerDiscretionary'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmHealthCare'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpHealthCare'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmMaterials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpMaterials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmUtilities'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpUtilities'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmRealEstate'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityJpRealEstate'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityLatinAmerica'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityFrontierMarket'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEuSmallCap'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityItaly'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equitySpain'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityFrance'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equitySwitzerland'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityGermany'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityUk'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityAustralia'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityHongKong'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCanada'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityKorea'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityEmea'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityAsiaExChina'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equitySingapore'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCn'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnEnergy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnFinancials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnIndustrials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnConsumerStaples'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnCommunicationServices'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnInformationTechnology'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnConsumerDiscretionary'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnHealthCare'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnMaterials'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnUtilities'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'equityCnRealEstate'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuLongCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEuShortCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsLongCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeUsShortCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeCnGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeEmGovLocalCurrency'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeAsiaCorp'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeAsiaGov'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'fixedIncomeAsiaHy'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketUk'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketSwitzerland'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketAustralia'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketJapan'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketCanada'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'moneyMarketSingapore'?: ExpectedReturnsVolatilityAssetClass;
    /**
     * 
     * @type {ExpectedReturnsVolatilityAssetClass}
     * @memberof ExpectedReturnsVolatility
     */
    'commoditiesGold'?: ExpectedReturnsVolatilityAssetClass;
}
/**
 * 
 * @export
 * @interface ExpectedReturnsVolatilityAssetClass
 */
export interface ExpectedReturnsVolatilityAssetClass {
    /**
     * 
     * @type {number}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'expectedReturnsUserDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'expectedVolatilityUserDelta'?: number;
    /**
     * 
     * @type {Regimes}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'expectedReturnsDefaults'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'expectedVolatilityDefaults'?: Regimes;
    /**
     * 
     * @type {number}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'max'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'visible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'marketGeography'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'marketMicroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpectedReturnsVolatilityAssetClass
     */
    'marketAssetClass'?: string;
}
/**
 * 
 * @export
 * @interface ExplainabilityDetails
 */
export interface ExplainabilityDetails {
    /**
     * 
     * @type {Array<TickerExplainabilityDetails>}
     * @memberof ExplainabilityDetails
     */
    'tickerDetails'?: Array<TickerExplainabilityDetails>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ExplainabilityDetails
     */
    'leafWeights'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaUsaEquity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaEuropeEquity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaJapanEquity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaEmergingEquity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaEuropeFixedIncome'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplainabilityDetails
     */
    'betaUsaFixedIncome'?: number;
}
/**
 * 
 * @export
 * @interface ExplainabilitySettingsResponse
 */
export interface ExplainabilitySettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof ExplainabilitySettingsResponse
     */
    'commentaryLanguage'?: ExplainabilitySettingsResponseCommentaryLanguageEnum;
}

export const ExplainabilitySettingsResponseCommentaryLanguageEnum = {
    English: 'ENGLISH',
    Italian: 'ITALIAN'
} as const;

export type ExplainabilitySettingsResponseCommentaryLanguageEnum = typeof ExplainabilitySettingsResponseCommentaryLanguageEnum[keyof typeof ExplainabilitySettingsResponseCommentaryLanguageEnum];

/**
 * 
 * @export
 * @interface ExportDto
 */
export interface ExportDto {
    /**
     * 
     * @type {InvestmentExportConverterType}
     * @memberof ExportDto
     */
    'exportServiceType'?: InvestmentExportConverterType;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'exportConnectionType'?: ExportDtoExportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExportDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExportDto
     */
    'directory'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportDto
     */
    'port'?: number;
}

export const ExportDtoExportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type ExportDtoExportConnectionTypeEnum = typeof ExportDtoExportConnectionTypeEnum[keyof typeof ExportDtoExportConnectionTypeEnum];

/**
 * 
 * @export
 * @interface ExportTicker
 */
export interface ExportTicker {
    /**
     * 
     * @type {string}
     * @memberof ExportTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {Currencies}
     * @memberof ExportTicker
     */
    'currency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof ExportTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTicker
     */
    'customAttributes'?: string;
}
/**
 * 
 * @export
 * @interface ExposureContributionRequest
 */
export interface ExposureContributionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExposureContributionRequest
     */
    'exposureContributionType'?: ExposureContributionRequestExposureContributionTypeEnum;
    /**
     * 
     * @type {Array<ExposureContributionRequestEntry>}
     * @memberof ExposureContributionRequest
     */
    'entries'?: Array<ExposureContributionRequestEntry>;
    /**
     * 
     * @type {number}
     * @memberof ExposureContributionRequest
     */
    'instrumentsWeight'?: number;
}

export const ExposureContributionRequestExposureContributionTypeEnum = {
    MacroAssetClass: 'MACRO_ASSET_CLASS',
    MicroAssetClass: 'MICRO_ASSET_CLASS',
    Geography: 'GEOGRAPHY',
    MacroGeography: 'MACRO_GEOGRAPHY',
    Currency: 'CURRENCY',
    Tag: 'TAG'
} as const;

export type ExposureContributionRequestExposureContributionTypeEnum = typeof ExposureContributionRequestExposureContributionTypeEnum[keyof typeof ExposureContributionRequestExposureContributionTypeEnum];

/**
 * 
 * @export
 * @interface ExposureContributionRequestEntry
 */
export interface ExposureContributionRequestEntry {
    /**
     * 
     * @type {string}
     * @memberof ExposureContributionRequestEntry
     */
    'entityUuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExposureContributionRequestEntry
     */
    'composedWeight'?: number;
}
/**
 * 
 * @export
 * @interface ExposureContributionResponse
 */
export interface ExposureContributionResponse {
    /**
     * 
     * @type {Array<ExposureContributionResponseEntry>}
     * @memberof ExposureContributionResponse
     */
    'portfolioComposition'?: Array<ExposureContributionResponseEntry>;
    /**
     * 
     * @type {Array<ExposureContributionResponseEntry>}
     * @memberof ExposureContributionResponse
     */
    'contributionComposition'?: Array<ExposureContributionResponseEntry>;
}
/**
 * 
 * @export
 * @interface ExposureContributionResponseEntry
 */
export interface ExposureContributionResponseEntry {
    /**
     * 
     * @type {string}
     * @memberof ExposureContributionResponseEntry
     */
    'quality'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureContributionResponseEntry
     */
    'entityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureContributionResponseEntry
     */
    'entityUuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExposureContributionResponseEntry
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureContributionResponseEntry
     */
    'composedWeight'?: number;
}
/**
 * 
 * @export
 * @interface ExposureTicker
 */
export interface ExposureTicker {
    /**
     * 
     * @type {number}
     * @memberof ExposureTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'platformName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'macroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExposureTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExposureTicker
     */
    'microGeography'?: string;
}
/**
 * 
 * @export
 * @interface FactorValue
 */
export interface FactorValue {
    /**
     * 
     * @type {number}
     * @memberof FactorValue
     */
    'value'?: number;
    /**
     * 
     * @type {FactorsEnum}
     * @memberof FactorValue
     */
    'keyEnum'?: FactorsEnum;
}
/**
 * 
 * @export
 * @interface FactorsDto
 */
export interface FactorsDto {
    /**
     * 
     * @type {string}
     * @memberof FactorsDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactorsDto
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactorsDto
     */
    'value'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactorsDto
     */
    'relevant'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FactorsEnum = {
    Equity: 'Equity',
    InterestRates: 'InterestRates',
    Credit: 'Credit',
    Commodities: 'Commodities',
    EmergingMarkets: 'EmergingMarkets',
    RealEstate: 'RealEstate',
    LocalInflationUsa: 'LocalInflationUSA',
    LocalInflationEurope: 'LocalInflationEurope',
    LocalInflationJapan: 'LocalInflationJapan',
    EquityLocalEurope: 'EquityLocalEurope',
    EquityLocalUsa: 'EquityLocalUSA',
    EquityLocalJapan: 'EquityLocalJapan',
    ForeignCurrency: 'ForeignCurrency',
    EquityShortVolatility: 'EquityShortVolatility',
    FixedIncomeCarry: 'FixedIncomeCarry',
    TrendFollowing: 'TrendFollowing',
    ForeignCurrencyCarry: 'ForeignCurrencyCarry',
    Investment: 'Investment',
    LowRisk: 'LowRisk',
    Momentum: 'Momentum',
    Quality: 'Quality',
    Size: 'Size',
    Value: 'Value',
    Crowding: 'Crowding'
} as const;

export type FactorsEnum = typeof FactorsEnum[keyof typeof FactorsEnum];


/**
 * 
 * @export
 * @interface FactorsMarketDto
 */
export interface FactorsMarketDto {
    /**
     * 
     * @type {Array<FactorsDto>}
     * @memberof FactorsMarketDto
     */
    'market'?: Array<FactorsDto>;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {boolean}
     * @memberof Feature
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface FeatureState
 */
export interface FeatureState {
    /**
     * 
     * @type {Feature}
     * @memberof FeatureState
     */
    'feature'?: Feature;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureState
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureState
     */
    'strategyId'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof FeatureState
     */
    'parameterNames'?: Set<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FeatureState
     */
    'parameterMap'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureState
     * @deprecated
     */
    'users'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FeedbackRequest
 */
export interface FeedbackRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedbackRequest
     */
    'rating'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    'referenceMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    'area'?: string;
}
/**
 * 
 * @export
 * @interface FeedbackResponse
 */
export interface FeedbackResponse {
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackResponse
     */
    'rating'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'referenceMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'dateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof FileResponse
     */
    'data'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'filename'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileResponse
     */
    'realData'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FileResponseContentType
 */
export interface FileResponseContentType {
    /**
     * 
     * @type {Array<number>}
     * @memberof FileResponseContentType
     */
    'data'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FileResponseContentType
     */
    'filename'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileResponseContentType
     */
    'realData'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileResponseContentType
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface FixedIncomeIndicators
 */
export interface FixedIncomeIndicators {
    /**
     * 
     * @type {Array<GeographyFixedIncomeIndicators>}
     * @memberof FixedIncomeIndicators
     */
    'geography'?: Array<GeographyFixedIncomeIndicators>;
    /**
     * 
     * @type {number}
     * @memberof FixedIncomeIndicators
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface FlexibleExpectedReturnsVolatility
 */
export interface FlexibleExpectedReturnsVolatility {
    /**
     * 
     * @type {Array<FlexibleExpectedReturnsVolatilityAssetClass>}
     * @memberof FlexibleExpectedReturnsVolatility
     */
    'assetClasses'?: Array<FlexibleExpectedReturnsVolatilityAssetClass>;
}
/**
 * 
 * @export
 * @interface FlexibleExpectedReturnsVolatilityAssetClass
 */
export interface FlexibleExpectedReturnsVolatilityAssetClass {
    /**
     * 
     * @type {string}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'assetClass'?: FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum;
    /**
     * 
     * @type {MarketViewMicroAssetClasses}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'microAssetClass'?: MarketViewMicroAssetClasses;
    /**
     * 
     * @type {number}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'expectedReturnsUserDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'expectedVolatilityUserDelta'?: number;
    /**
     * 
     * @type {Regimes}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'expectedReturnsDefaults'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'expectedVolatilityDefaults'?: Regimes;
    /**
     * 
     * @type {string}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'marketGeography'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'marketMicroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexibleExpectedReturnsVolatilityAssetClass
     */
    'marketAssetClass'?: string;
}

export const FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum = {
    Equity: 'EQUITY',
    FixedIncome: 'FIXED_INCOME',
    Commodities: 'COMMODITIES',
    Alternative: 'ALTERNATIVE',
    MoneyMarket: 'MONEY_MARKET',
    Currency: 'CURRENCY'
} as const;

export type FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum = typeof FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum[keyof typeof FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum];

/**
 * 
 * @export
 * @interface ForEachConstraint
 */
export interface ForEachConstraint {
    /**
     * 
     * @type {string}
     * @memberof ForEachConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof ForEachConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ForEachConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForEachConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof ForEachConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {Array<ConstrainedTicker>}
     * @memberof ForEachConstraint
     */
    'tickers'?: Array<ConstrainedTicker>;
    /**
     * 
     * @type {number}
     * @memberof ForEachConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ForEachConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof ForEachConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface Forecast
 */
export interface Forecast {
    /**
     * 
     * @type {string}
     * @memberof Forecast
     */
    'time_horizon'?: string;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'median'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    '1st_quartile'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    '3rd_quartile'?: number;
    /**
     * 
     * @type {string}
     * @memberof Forecast
     */
    'conf_level'?: string;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'exp_vol'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'current'?: number;
    /**
     * 
     * @type {number}
     * @memberof Forecast
     */
    'mdotm_trace'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ForecastHorizons = {
    OneMonth: 'ONE_MONTH',
    ThreeMonths: 'THREE_MONTHS',
    SixMonths: 'SIX_MONTHS',
    TwelveMonths: 'TWELVE_MONTHS',
    EighteenMonths: 'EIGHTEEN_MONTHS',
    TwentyFourMonths: 'TWENTY_FOUR_MONTHS',
    ThirtySixMonths: 'THIRTY_SIX_MONTHS'
} as const;

export type ForecastHorizons = typeof ForecastHorizons[keyof typeof ForecastHorizons];


/**
 * 
 * @export
 * @interface FromToDate
 */
export interface FromToDate {
    /**
     * 
     * @type {string}
     * @memberof FromToDate
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof FromToDate
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface FtpLoginDto
 */
export interface FtpLoginDto {
    /**
     * 
     * @type {string}
     * @memberof FtpLoginDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof FtpLoginDto
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof FtpLoginDto
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof FtpLoginDto
     */
    'host'?: string;
}
/**
 * 
 * @export
 * @interface GatewayEntityError
 */
export interface GatewayEntityError {
    /**
     * 
     * @type {string}
     * @memberof GatewayEntityError
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayEntityError
     */
    'dateReceived'?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayEntityError
     */
    'generatedByAction'?: string;
    /**
     * 
     * @type {ServiceError}
     * @memberof GatewayEntityError
     */
    'error'?: ServiceError;
}
/**
 * 
 * @export
 * @interface GenericPoolListNotificationGroupedDTO
 */
export interface GenericPoolListNotificationGroupedDTO {
    /**
     * 
     * @type {Array<NotificationGroupedDTO>}
     * @memberof GenericPoolListNotificationGroupedDTO
     */
    'elements'?: Array<NotificationGroupedDTO>;
    /**
     * 
     * @type {number}
     * @memberof GenericPoolListNotificationGroupedDTO
     */
    'pool'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericPoolListNotificationGroupedDTO
     */
    'totalPool'?: number;
}
/**
 * 
 * @export
 * @interface GeoGlobalRisk
 */
export interface GeoGlobalRisk {
    /**
     * 
     * @type {AssetClassIdentifier}
     * @memberof GeoGlobalRisk
     */
    'geo'?: AssetClassIdentifier;
    /**
     * 
     * @type {Array<AssetClassGlobalRisk>}
     * @memberof GeoGlobalRisk
     */
    'assetClassess'?: Array<AssetClassGlobalRisk>;
}
/**
 * 
 * @export
 * @interface GeographyFixedIncomeIndicators
 */
export interface GeographyFixedIncomeIndicators {
    /**
     * 
     * @type {string}
     * @memberof GeographyFixedIncomeIndicators
     */
    'label'?: string;
    /**
     * 
     * @type {Array<Type>}
     * @memberof GeographyFixedIncomeIndicators
     */
    'type'?: Array<Type>;
}
/**
 * 
 * @export
 * @interface GeographyWithSector
 */
export interface GeographyWithSector {
    /**
     * 
     * @type {string}
     * @memberof GeographyWithSector
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeographyWithSector
     */
    'commentary'?: string;
    /**
     * 
     * @type {number}
     * @memberof GeographyWithSector
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeographyWithSector
     */
    'why'?: string;
    /**
     * 
     * @type {Array<Forecast>}
     * @memberof GeographyWithSector
     */
    'forecast'?: Array<Forecast>;
    /**
     * 
     * @type {AcMetrics}
     * @memberof GeographyWithSector
     */
    'ac_metrics'?: AcMetrics;
    /**
     * 
     * @type {PositioningDrivers}
     * @memberof GeographyWithSector
     */
    'positioning_drivers'?: PositioningDrivers;
    /**
     * 
     * @type {Array<number>}
     * @memberof GeographyWithSector
     */
    'return_analysis_bands'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof GeographyWithSector
     */
    'mdotm_trace'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeographyWithSector
     */
    'gov_corp_indicator'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeographyWithSector
     */
    'ig_hy_indicator'?: number;
    /**
     * 
     * @type {Sectors}
     * @memberof GeographyWithSector
     */
    'sector'?: Sectors;
}
/**
 * 
 * @export
 * @interface GlobalRisk
 */
export interface GlobalRisk {
    /**
     * 
     * @type {string}
     * @memberof GlobalRisk
     */
    'dateTime'?: string;
    /**
     * 
     * @type {Array<GeoGlobalRisk>}
     * @memberof GlobalRisk
     */
    'risks'?: Array<GeoGlobalRisk>;
}
/**
 * 
 * @export
 * @interface HistoricalTransition
 */
export interface HistoricalTransition {
    /**
     * 
     * @type {SingleTransition}
     * @memberof HistoricalTransition
     */
    'growth'?: SingleTransition;
    /**
     * 
     * @type {SingleTransition}
     * @memberof HistoricalTransition
     */
    'lateralPhase'?: SingleTransition;
    /**
     * 
     * @type {SingleTransition}
     * @memberof HistoricalTransition
     */
    'highStress'?: SingleTransition;
}
/**
 * 
 * @export
 * @interface ImportConstraints
 */
export interface ImportConstraints {
    /**
     * 
     * @type {string}
     * @memberof ImportConstraints
     */
    'constraintType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportConstraints
     */
    'subject'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportConstraints
     */
    'soft'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportConstraints
     */
    'relation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportConstraints
     */
    'value1'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportConstraints
     */
    'value2'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportConstraints
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface ImportDto
 */
export interface ImportDto {
    /**
     * 
     * @type {InvestmentImportConverterType}
     * @memberof ImportDto
     */
    'importServiceType'?: InvestmentImportConverterType;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'importConnectionType'?: ImportDtoImportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportDto
     */
    'pollingFrequencyInMinutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'userWhoReceivePtfFromImport'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'directory'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportDto
     */
    'port'?: number;
}

export const ImportDtoImportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type ImportDtoImportConnectionTypeEnum = typeof ImportDtoImportConnectionTypeEnum[keyof typeof ImportDtoImportConnectionTypeEnum];

/**
 * 
 * @export
 * @interface ImportTicker
 */
export interface ImportTicker {
    /**
     * 
     * @type {string}
     * @memberof ImportTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {Currencies}
     * @memberof ImportTicker
     */
    'currency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof ImportTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTicker
     */
    'tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportTicker
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface IndexTicker
 */
export interface IndexTicker {
    /**
     * 
     * @type {number}
     * @memberof IndexTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'microGeography'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndexTicker
     */
    'granularity'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndexTicker
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface Instructions
 */
export interface Instructions {
    /**
     * 
     * @type {string}
     * @memberof Instructions
     */
    'macroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instructions
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof Instructions
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {Array<Composition>}
     * @memberof Instructions
     */
    'composition'?: Array<Composition>;
}
/**
 * 
 * @export
 * @interface InstrumentErrorMessages
 */
export interface InstrumentErrorMessages {
    /**
     * 
     * @type {number}
     * @memberof InstrumentErrorMessages
     */
    'rowNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstrumentErrorMessages
     */
    'message'?: InstrumentErrorMessagesMessageEnum;
}

export const InstrumentErrorMessagesMessageEnum = {
    NullEmptyIsin: 'NULL_EMPTY_ISIN',
    InvalidIsin: 'INVALID_ISIN'
} as const;

export type InstrumentErrorMessagesMessageEnum = typeof InstrumentErrorMessagesMessageEnum[keyof typeof InstrumentErrorMessagesMessageEnum];

/**
 * 
 * @export
 * @interface InstrumentImportResponse
 */
export interface InstrumentImportResponse {
    /**
     * 
     * @type {Array<ProxyPreferenceTicker>}
     * @memberof InstrumentImportResponse
     */
    'reviewTickers'?: Array<ProxyPreferenceTicker>;
    /**
     * 
     * @type {Array<InstrumentErrorMessages>}
     * @memberof InstrumentImportResponse
     */
    'warningMessages'?: Array<InstrumentErrorMessages>;
}
/**
 * 
 * @export
 * @interface InstrumentMaxWeightConstraint
 */
export interface InstrumentMaxWeightConstraint {
    /**
     * 
     * @type {string}
     * @memberof InstrumentMaxWeightConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InstrumentMaxWeightConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstrumentMaxWeightConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMaxWeightConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof InstrumentMaxWeightConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface InstrumentMinWeight
 */
export interface InstrumentMinWeight {
    /**
     * 
     * @type {number}
     * @memberof InstrumentMinWeight
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMinWeight
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMinWeight
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMinWeight
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentMinWeight
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof InstrumentMinWeight
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface InstrumentsConstraint
 */
export interface InstrumentsConstraint {
    /**
     * 
     * @type {string}
     * @memberof InstrumentsConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InstrumentsConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstrumentsConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof InstrumentsConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InstrumentsConstraint
     */
    'tickerIdentifiers'?: Array<RichTicker>;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof InstrumentsConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface InstrumentsListFilter
 */
export interface InstrumentsListFilter {
    /**
     * 
     * @type {Array<SelectableBasket>}
     * @memberof InstrumentsListFilter
     */
    'baskets'?: Array<SelectableBasket>;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsListFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof InstrumentsListFilter
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof InstrumentsListFilter
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstrumentsListFilter
     */
    'sortDirection'?: InstrumentsListFilterSortDirectionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstrumentsListFilter
     */
    'macroAssetClasses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstrumentsListFilter
     */
    'microAssetClasses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InstrumentsListFilter
     */
    'searchQuery'?: string;
}

export const InstrumentsListFilterSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type InstrumentsListFilterSortDirectionEnum = typeof InstrumentsListFilterSortDirectionEnum[keyof typeof InstrumentsListFilterSortDirectionEnum];

/**
 * 
 * @export
 * @interface InvestableUniverse
 */
export interface InvestableUniverse {
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverse
     */
    'universeIdentifier'?: string;
}
/**
 * 
 * @export
 * @interface InvestableUniverseResponse
 */
export interface InvestableUniverseResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof InvestableUniverseResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof InvestableUniverseResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverseResponse
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverseResponse
     */
    'universeName'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestableUniverseResponse
     */
    'universeComposition'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface InvestableUniverseSaveRequest
 */
export interface InvestableUniverseSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverseSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {InvestableUniverseSelectionStrategy}
     * @memberof InvestableUniverseSaveRequest
     */
    'investableUniverseSelectionStrategy'?: InvestableUniverseSelectionStrategy;
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverseSaveRequest
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestableUniverseSaveRequest
     */
    'universeName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvestableUniverseSelectionStrategy = {
    CreateNew: 'CREATE_NEW',
    Expand: 'EXPAND',
    SellInstruments: 'SELL_INSTRUMENTS'
} as const;

export type InvestableUniverseSelectionStrategy = typeof InvestableUniverseSelectionStrategy[keyof typeof InvestableUniverseSelectionStrategy];


/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentActions = {
    Upload: 'UPLOAD',
    Creation: 'CREATION',
    Enhancement: 'ENHANCEMENT',
    Optimization: 'OPTIMIZATION',
    Edit: 'EDIT',
    Evolve: 'EVOLVE',
    RetrievingDataForUpload: 'RETRIEVING_DATA_FOR_UPLOAD',
    Clone: 'CLONE',
    EditEnhancementProxyLiveInvestment: 'EDIT_ENHANCEMENT_PROXY_LIVE_INVESTMENT',
    EditProxyLiveInvestment: 'EDIT_PROXY_LIVE_INVESTMENT',
    EnhancementFromMarketView: 'ENHANCEMENT_FROM_MARKET_VIEW',
    EditWithoutAccept: 'EDIT_WITHOUT_ACCEPT',
    EditBenchmarks: 'EDIT_BENCHMARKS',
    EditStaticConfiguration: 'EDIT_STATIC_CONFIGURATION'
} as const;

export type InvestmentActions = typeof InvestmentActions[keyof typeof InvestmentActions];


/**
 * 
 * @export
 * @interface InvestmentAllocationConstraintsValidationErrorResponse
 */
export interface InvestmentAllocationConstraintsValidationErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAllocationConstraintsValidationErrorResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentAllocationConstraintsValidationErrorResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentAllocationConstraintsValidationErrorResponse
     */
    'creation'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentAllocationConstraintsValidationResponse
 */
export interface InvestmentAllocationConstraintsValidationResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAllocationConstraintsValidationResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentAllocationConstraintsValidationResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {AllocationConstraints}
     * @memberof InvestmentAllocationConstraintsValidationResponse
     */
    'allocationConstraints'?: AllocationConstraints;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentAllocationConstraintsValidationResponse
     */
    'creation'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentAttributionResponse
 */
export interface InvestmentAttributionResponse {
    /**
     * 
     * @type {Array<PerformanceAttributionEntry>}
     * @memberof InvestmentAttributionResponse
     */
    'current'?: Array<PerformanceAttributionEntry>;
}
/**
 * 
 * @export
 * @interface InvestmentBenchmark
 */
export interface InvestmentBenchmark {
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmark
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmark
     */
    'benchmarkType'?: InvestmentBenchmarkBenchmarkTypeEnum;
}

export const InvestmentBenchmarkBenchmarkTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentBenchmarkBenchmarkTypeEnum = typeof InvestmentBenchmarkBenchmarkTypeEnum[keyof typeof InvestmentBenchmarkBenchmarkTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentBenchmarkDTO
 */
export interface InvestmentBenchmarkDTO {
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmarkDTO
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmarkDTO
     */
    'benchmarkName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmarkDTO
     */
    'benchmarkType'?: InvestmentBenchmarkDTOBenchmarkTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBenchmarkDTO
     */
    'volatility'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentBenchmarkDTO
     */
    'available'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentBenchmarkDTO
     */
    'status'?: InvestmentStatuses;
}

export const InvestmentBenchmarkDTOBenchmarkTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentBenchmarkDTOBenchmarkTypeEnum = typeof InvestmentBenchmarkDTOBenchmarkTypeEnum[keyof typeof InvestmentBenchmarkDTOBenchmarkTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentBenchmarkMinInfo
 */
export interface InvestmentBenchmarkMinInfo {
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmarkMinInfo
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentBenchmarkMinInfo
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentBenchmarkMinInfo
     */
    'primary'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentBenchmarkMinInfo
     */
    'ready'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentCommentaryResponse
 */
export interface InvestmentCommentaryResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentCommentaryResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentCommentaryResponse
     */
    'shortCommentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentCommentaryResponse
     */
    'status'?: InvestmentCommentaryResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentCommentaryResponse
     */
    'commentaryDate'?: string;
}

export const InvestmentCommentaryResponseStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type InvestmentCommentaryResponseStatusEnum = typeof InvestmentCommentaryResponseStatusEnum[keyof typeof InvestmentCommentaryResponseStatusEnum];

/**
 * 
 * @export
 * @interface InvestmentComposition
 */
export interface InvestmentComposition {
    /**
     * 
     * @type {Array<ExposureTicker>}
     * @memberof InvestmentComposition
     */
    'composition'?: Array<ExposureTicker>;
}
/**
 * 
 * @export
 * @interface InvestmentCompositionResponse
 */
export interface InvestmentCompositionResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof InvestmentCompositionResponse
     */
    'composition'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface InvestmentCompositionsResponse
 */
export interface InvestmentCompositionsResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentCompositionsResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentCompositionsResponse
     */
    'composition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentCompositionsResponse
     */
    'enhancementComposition'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface InvestmentConfiguration
 */
export interface InvestmentConfiguration {
    /**
     * 
     * @type {MainInfo}
     * @memberof InvestmentConfiguration
     */
    'mainInfo'?: MainInfo;
    /**
     * 
     * @type {InvestableUniverse}
     * @memberof InvestmentConfiguration
     */
    'investableUniverse'?: InvestableUniverse;
    /**
     * 
     * @type {AllocationConstraints}
     * @memberof InvestmentConfiguration
     */
    'allocationConstraints'?: AllocationConstraints;
    /**
     * 
     * @type {RiskConstraints}
     * @memberof InvestmentConfiguration
     */
    'riskConstraints'?: RiskConstraints;
    /**
     * 
     * @type {StrategyConstraints}
     * @memberof InvestmentConfiguration
     */
    'strategyConstraints'?: StrategyConstraints;
    /**
     * 
     * @type {MarketView}
     * @memberof InvestmentConfiguration
     */
    'marketView'?: MarketView;
}
/**
 * 
 * @export
 * @interface InvestmentConfigurationResponse
 */
export interface InvestmentConfigurationResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof InvestmentConfigurationResponse
     */
    'availabilityMap'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof InvestmentConfigurationResponse
     */
    'mandatorinessMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {MainInfoResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'mainInfo'?: MainInfoResponse;
    /**
     * 
     * @type {InvestableUniverseResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'investableUniverse'?: InvestableUniverseResponse;
    /**
     * 
     * @type {AllocationConstraintsResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'allocationConstraints'?: AllocationConstraintsResponse;
    /**
     * 
     * @type {RiskConstraintsResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'riskConstraints'?: RiskConstraintsResponse;
    /**
     * 
     * @type {StrategyConstraintsResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'strategyConstraints'?: StrategyConstraintsResponse;
    /**
     * 
     * @type {MarketViewResponse}
     * @memberof InvestmentConfigurationResponse
     */
    'marketView'?: MarketViewResponse;
}
/**
 * 
 * @export
 * @interface InvestmentConfigurationSaveRequest
 */
export interface InvestmentConfigurationSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {MainInfoSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'mainInfo'?: MainInfoSaveRequest;
    /**
     * 
     * @type {InvestableUniverseSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'investableUniverse'?: InvestableUniverseSaveRequest;
    /**
     * 
     * @type {AllocationConstraintsSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'allocationConstraints'?: AllocationConstraintsSaveRequest;
    /**
     * 
     * @type {RiskConstraintsSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'riskConstraints'?: RiskConstraintsSaveRequest;
    /**
     * 
     * @type {StrategyConstraintsSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'strategyConstraints'?: StrategyConstraintsSaveRequest;
    /**
     * 
     * @type {MarketViewSaveRequest}
     * @memberof InvestmentConfigurationSaveRequest
     */
    'marketView'?: MarketViewSaveRequest;
}
/**
 * 
 * @export
 * @interface InvestmentContributionResponse
 */
export interface InvestmentContributionResponse {
    /**
     * 
     * @type {Array<ContributionEntry>}
     * @memberof InvestmentContributionResponse
     */
    'current'?: Array<ContributionEntry>;
}
/**
 * 
 * @export
 * @interface InvestmentConversionError
 */
export interface InvestmentConversionError {
    /**
     * 
     * @type {string}
     * @memberof InvestmentConversionError
     */
    'investmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentConversionError
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface InvestmentConversionIdentifierItems
 */
export interface InvestmentConversionIdentifierItems {
    /**
     * 
     * @type {Array<InvestmentConversionError>}
     * @memberof InvestmentConversionIdentifierItems
     */
    'errors'?: Array<InvestmentConversionError>;
    /**
     * 
     * @type {Array<InvestmentIdentifierConversion>}
     * @memberof InvestmentConversionIdentifierItems
     */
    'convertions'?: Array<InvestmentIdentifierConversion>;
}
/**
 * 
 * @export
 * @interface InvestmentConversionNamedItems
 */
export interface InvestmentConversionNamedItems {
    /**
     * 
     * @type {Array<InvestmentConversionError>}
     * @memberof InvestmentConversionNamedItems
     */
    'errors'?: Array<InvestmentConversionError>;
    /**
     * 
     * @type {Array<InvestmentNamedConversion>}
     * @memberof InvestmentConversionNamedItems
     */
    'convertions'?: Array<InvestmentNamedConversion>;
}
/**
 * 
 * @export
 * @interface InvestmentEditNoteRequest
 */
export interface InvestmentEditNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentEditNoteRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentEditNoteRequest
     */
    'historyUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentEditNoteRequest
     */
    'noteText'?: string;
}
/**
 * 
 * @export
 * @interface InvestmentEnhancedComposition
 */
export interface InvestmentEnhancedComposition {
    /**
     * 
     * @type {Array<ExposureTicker>}
     * @memberof InvestmentEnhancedComposition
     */
    'composition'?: Array<ExposureTicker>;
    /**
     * 
     * @type {Array<ExposureTicker>}
     * @memberof InvestmentEnhancedComposition
     */
    'enhancedComposition'?: Array<ExposureTicker>;
}
/**
 * 
 * @export
 * @interface InvestmentExport
 */
export interface InvestmentExport {
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'date'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof InvestmentExport
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'benchmark'?: string;
    /**
     * 
     * @type {Array<ExportTicker>}
     * @memberof InvestmentExport
     */
    'composition'?: Array<ExportTicker>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'commentaryType'?: InvestmentExportCommentaryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'universe'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExport
     */
    'reference'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvestmentExport
     */
    'targetVolatility'?: number;
    /**
     * 
     * @type {Array<ConstraintsTemplate>}
     * @memberof InvestmentExport
     */
    'constraints'?: Array<ConstraintsTemplate>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvestmentExport
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {MarketViewExport}
     * @memberof InvestmentExport
     */
    'marketView'?: MarketViewExport;
}

export const InvestmentExportCommentaryTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type InvestmentExportCommentaryTypeEnum = typeof InvestmentExportCommentaryTypeEnum[keyof typeof InvestmentExportCommentaryTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentExportConverterType = {
    GuardianConverter: 'GUARDIAN_CONVERTER',
    AnalysisConverter: 'ANALYSIS_CONVERTER',
    BloombergConverter: 'BLOOMBERG_CONVERTER',
    SphereTemplateConverter: 'SPHERE_TEMPLATE_CONVERTER',
    EasimTemplateConverter: 'EASIM_TEMPLATE_CONVERTER'
} as const;

export type InvestmentExportConverterType = typeof InvestmentExportConverterType[keyof typeof InvestmentExportConverterType];


/**
 * 
 * @export
 * @interface InvestmentExposureEntry
 */
export interface InvestmentExposureEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentExposureEntry
     */
    'firstQualityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentExposureEntry
     */
    'secondQualityLevel'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvestmentExposureEntry
     */
    'weight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentExposureEntry
     */
    'netLong'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentExposureResponse
 */
export interface InvestmentExposureResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentExposureResponse
     */
    'exposureType'?: InvestmentExposureResponseExposureTypeEnum;
    /**
     * 
     * @type {Array<InvestmentExposureEntry>}
     * @memberof InvestmentExposureResponse
     */
    'investmentComposition'?: Array<InvestmentExposureEntry>;
    /**
     * 
     * @type {Array<InvestmentExposureEntry>}
     * @memberof InvestmentExposureResponse
     */
    'enhancementComposition'?: Array<InvestmentExposureEntry>;
    /**
     * 
     * @type {Array<InvestmentExposureEntry>}
     * @memberof InvestmentExposureResponse
     */
    'benchmarkComposition'?: Array<InvestmentExposureEntry>;
}

export const InvestmentExposureResponseExposureTypeEnum = {
    MacroAssetClassVsMicroAssetClass: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS',
    MacroAssetClassVsGeography: 'MACRO_ASSET_CLASS_VS_GEOGRAPHY',
    MicroAssetClassVsGeography: 'MICRO_ASSET_CLASS_VS_GEOGRAPHY',
    GeographyVsMacroAssetClass: 'GEOGRAPHY_VS_MACRO_ASSET_CLASS',
    GeographyVsMicroAssetClass: 'GEOGRAPHY_VS_MICRO_ASSET_CLASS',
    MacroAssetClass: 'MACRO_ASSET_CLASS',
    MicroAssetClass: 'MICRO_ASSET_CLASS',
    MacroGeography: 'MACRO_GEOGRAPHY',
    MicroGeography: 'MICRO_GEOGRAPHY',
    MacroAssetClassVsMacroGeography: 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY',
    MacroAssetClassVsMicroGeography: 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY',
    MicroAssetClassVsMacroGeography: 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY',
    MicroAssetClassVsMicroGeography: 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY',
    MacroGeographyVsMicroGeography: 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY',
    Tag: 'TAG',
    Currency: 'CURRENCY'
} as const;

export type InvestmentExposureResponseExposureTypeEnum = typeof InvestmentExposureResponseExposureTypeEnum[keyof typeof InvestmentExposureResponseExposureTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentFactorsResponse
 */
export interface InvestmentFactorsResponse {
    /**
     * 
     * @type {Array<FactorsDto>}
     * @memberof InvestmentFactorsResponse
     */
    'current'?: Array<FactorsDto>;
    /**
     * 
     * @type {Array<FactorsDto>}
     * @memberof InvestmentFactorsResponse
     */
    'proposal'?: Array<FactorsDto>;
    /**
     * 
     * @type {Array<FactorsDto>}
     * @memberof InvestmentFactorsResponse
     */
    'benchmark'?: Array<FactorsDto>;
}
/**
 * 
 * @export
 * @interface InvestmentHistorySummaryEntry
 */
export interface InvestmentHistorySummaryEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentHistorySummaryEntry
     */
    'historyUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentHistorySummaryEntry
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentHistorySummaryEntry
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {Array<InvestmentSubPortfolioEntry>}
     * @memberof InvestmentHistorySummaryEntry
     */
    'subPortfolios'?: Array<InvestmentSubPortfolioEntry>;
    /**
     * 
     * @type {InvestmentNote}
     * @memberof InvestmentHistorySummaryEntry
     */
    'note'?: InvestmentNote;
    /**
     * 
     * @type {RichAccessControl}
     * @memberof InvestmentHistorySummaryEntry
     */
    'actionAuthor'?: RichAccessControl;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentHistorySummaryEntry
     */
    'universeSnapshot'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentIdentifierConversion
 */
export interface InvestmentIdentifierConversion {
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentIdentifierConversion
     */
    'exist'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentIdentifierConversion
     */
    'investmentStatus'?: InvestmentStatuses;
    /**
     * 
     * @type {InvestmentIdentifierImport}
     * @memberof InvestmentIdentifierConversion
     */
    'investmentImport'?: InvestmentIdentifierImport;
}
/**
 * 
 * @export
 * @interface InvestmentIdentifierImport
 */
export interface InvestmentIdentifierImport {
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'name'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof InvestmentIdentifierImport
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {Array<ImportTicker>}
     * @memberof InvestmentIdentifierImport
     */
    'composition'?: Array<ImportTicker>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'syncDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'commentaryType'?: InvestmentIdentifierImportCommentaryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvestmentIdentifierImport
     */
    'targetVolatility'?: number;
    /**
     * 
     * @type {Array<ImportConstraints>}
     * @memberof InvestmentIdentifierImport
     */
    'constraints'?: Array<ImportConstraints>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentIdentifierImport
     */
    'creation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvestmentIdentifierImport
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'marketViewIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentIdentifierImport
     */
    'referenceIdentifier'?: string;
}

export const InvestmentIdentifierImportCommentaryTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type InvestmentIdentifierImportCommentaryTypeEnum = typeof InvestmentIdentifierImportCommentaryTypeEnum[keyof typeof InvestmentIdentifierImportCommentaryTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentImportConverterType = {
    AmundiConverter: 'AMUNDI_CONVERTER',
    AnalysisConverter: 'ANALYSIS_CONVERTER',
    GuardianConverter: 'GUARDIAN_CONVERTER',
    SphereTemplateConverter: 'SPHERE_TEMPLATE_CONVERTER',
    BloombergConverter: 'BLOOMBERG_CONVERTER'
} as const;

export type InvestmentImportConverterType = typeof InvestmentImportConverterType[keyof typeof InvestmentImportConverterType];


/**
 * 
 * @export
 * @interface InvestmentImportNamesItems
 */
export interface InvestmentImportNamesItems {
    /**
     * 
     * @type {Array<InvestmentConversionError>}
     * @memberof InvestmentImportNamesItems
     */
    'errors'?: Array<InvestmentConversionError>;
    /**
     * 
     * @type {Array<InvestmentNamesImport>}
     * @memberof InvestmentImportNamesItems
     */
    'investments'?: Array<InvestmentNamesImport>;
}
/**
 * 
 * @export
 * @interface InvestmentImportResponse
 */
export interface InvestmentImportResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentImportResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentImportResponse
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {string}
     * @memberof InvestmentImportResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentImportResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentImportResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface InvestmentListEntry
 */
export interface InvestmentListEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'lastReportsUpdate'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentListEntry
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentListEntry
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentListEntry
     */
    'alertsLegacy'?: Array<string>;
    /**
     * 
     * @type {Array<AlertDto>}
     * @memberof InvestmentListEntry
     */
    'alerts'?: Array<AlertDto>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'scoreIdentifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'syncDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentListEntry
     */
    'importedExternally'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentListEntry
     */
    'canBulkEnhance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentListEntry
     */
    'canUseAsMixedPortfolio'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'performance1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'sortino1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'maxDrawdown1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'volatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'efficiencyRatio1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'benchmarkPerformance1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'benchmarkSortino1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'benchmarkMaxDrawdown1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'benchmarkVolatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'benchmarkEfficiencyRatio1M'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'benchmarkName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'universeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentListEntry
     */
    'commentaryType'?: InvestmentListEntryCommentaryTypeEnum;
    /**
     * 
     * @type {RichAcl}
     * @memberof InvestmentListEntry
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {InvestmentNote}
     * @memberof InvestmentListEntry
     */
    'lastActionNote'?: InvestmentNote;
    /**
     * 
     * @type {Array<InvestmentExposureEntry>}
     * @memberof InvestmentListEntry
     */
    'macroAssetClassExposure'?: Array<InvestmentExposureEntry>;
    /**
     * 
     * @type {Array<InvestmentExposureEntry>}
     * @memberof InvestmentListEntry
     */
    'macroAssetClassExposureEnhanced'?: Array<InvestmentExposureEntry>;
    /**
     * 
     * @type {MarketViewMonitoringMetric}
     * @memberof InvestmentListEntry
     */
    'marketView'?: MarketViewMonitoringMetric;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListEntry
     */
    'nofInstruments'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentListEntry
     */
    'fromUpload'?: boolean;
}

export const InvestmentListEntryCommentaryTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type InvestmentListEntryCommentaryTypeEnum = typeof InvestmentListEntryCommentaryTypeEnum[keyof typeof InvestmentListEntryCommentaryTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentMinInfo
 */
export interface InvestmentMinInfo {
    /**
     * 
     * @type {string}
     * @memberof InvestmentMinInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentMinInfo
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface InvestmentNamedConversion
 */
export interface InvestmentNamedConversion {
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentNamedConversion
     */
    'exist'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentNamedConversion
     */
    'investmentStatus'?: InvestmentStatuses;
    /**
     * 
     * @type {InvestmentNamesImport}
     * @memberof InvestmentNamedConversion
     */
    'investmentImport'?: InvestmentNamesImport;
}
/**
 * 
 * @export
 * @interface InvestmentNamesImport
 */
export interface InvestmentNamesImport {
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'name'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof InvestmentNamesImport
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {Array<ImportTicker>}
     * @memberof InvestmentNamesImport
     */
    'composition'?: Array<ImportTicker>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'syncDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'commentaryType'?: InvestmentNamesImportCommentaryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvestmentNamesImport
     */
    'targetVolatility'?: number;
    /**
     * 
     * @type {Array<ImportConstraints>}
     * @memberof InvestmentNamesImport
     */
    'constraints'?: Array<ImportConstraints>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentNamesImport
     */
    'creation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvestmentNamesImport
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'benchmarkName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'universeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'marketViewName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNamesImport
     */
    'referenceName'?: string;
}

export const InvestmentNamesImportCommentaryTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type InvestmentNamesImportCommentaryTypeEnum = typeof InvestmentNamesImportCommentaryTypeEnum[keyof typeof InvestmentNamesImportCommentaryTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentNote
 */
export interface InvestmentNote {
    /**
     * 
     * @type {string}
     * @memberof InvestmentNote
     */
    'status'?: InvestmentNoteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNote
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentNote
     */
    'date'?: string;
    /**
     * 
     * @type {Author}
     * @memberof InvestmentNote
     */
    'author'?: Author;
}

export const InvestmentNoteStatusEnum = {
    Modified: 'MODIFIED',
    Created: 'CREATED'
} as const;

export type InvestmentNoteStatusEnum = typeof InvestmentNoteStatusEnum[keyof typeof InvestmentNoteStatusEnum];

/**
 * 
 * @export
 * @interface InvestmentPerformance
 */
export interface InvestmentPerformance {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof InvestmentPerformance
     */
    'current'?: Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof InvestmentPerformance
     */
    'benchmark'?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface InvestmentProjectedListEntry
 */
export interface InvestmentProjectedListEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentProjectedListEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProjectedListEntry
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentProjectedListEntry
     */
    'status'?: InvestmentStatuses;
}
/**
 * 
 * @export
 * @interface InvestmentProxyLiveEditEnhancementResponse
 */
export interface InvestmentProxyLiveEditEnhancementResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentProxyLiveEditEnhancementResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {InvestmentReports}
     * @memberof InvestmentProxyLiveEditEnhancementResponse
     */
    'investmentReports'?: InvestmentReports;
}
/**
 * 
 * @export
 * @interface InvestmentProxyLiveEditErrorResponse
 */
export interface InvestmentProxyLiveEditErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentProxyLiveEditErrorResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {GatewayEntityError}
     * @memberof InvestmentProxyLiveEditErrorResponse
     */
    'gatewayEntityError'?: GatewayEntityError;
}
/**
 * 
 * @export
 * @interface InvestmentProxyLiveEditResponse
 */
export interface InvestmentProxyLiveEditResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentProxyLiveEditResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {InvestmentReports}
     * @memberof InvestmentProxyLiveEditResponse
     */
    'investmentReports'?: InvestmentReports;
}
/**
 * 
 * @export
 * @interface InvestmentReference
 */
export interface InvestmentReference {
    /**
     * 
     * @type {string}
     * @memberof InvestmentReference
     */
    'referenceIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReference
     */
    'referenceType'?: InvestmentReferenceReferenceTypeEnum;
}

export const InvestmentReferenceReferenceTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentReferenceReferenceTypeEnum = typeof InvestmentReferenceReferenceTypeEnum[keyof typeof InvestmentReferenceReferenceTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentReferenceDTO
 */
export interface InvestmentReferenceDTO {
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceDTO
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceDTO
     */
    'type'?: InvestmentReferenceDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvestmentReferenceDTO
     */
    'volatility'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentReferenceDTO
     */
    'available'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentReferenceDTO
     */
    'status'?: InvestmentStatuses;
}

export const InvestmentReferenceDTOTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentReferenceDTOTypeEnum = typeof InvestmentReferenceDTOTypeEnum[keyof typeof InvestmentReferenceDTOTypeEnum];

/**
 * 
 * @export
 * @interface InvestmentReferenceListEntry
 */
export interface InvestmentReferenceListEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     */
    'lastReportsUpdate'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentReferenceListEntry
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentReferenceListEntry
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {string}
     * @memberof InvestmentReferenceListEntry
     * @deprecated
     */
    'type'?: string;
    /**
     * 
     * @type {Array<InvestmentMinInfo>}
     * @memberof InvestmentReferenceListEntry
     */
    'referralInvestments'?: Array<InvestmentMinInfo>;
    /**
     * 
     * @type {RichAcl}
     * @memberof InvestmentReferenceListEntry
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentReferenceListEntry
     */
    'benchmark'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvestmentReferenceListEntry
     */
    'nofPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentReferenceListEntry
     */
    'nofInstruments'?: number;
}
/**
 * 
 * @export
 * @interface InvestmentReports
 */
export interface InvestmentReports {
    /**
     * 
     * @type {string}
     * @memberof InvestmentReports
     */
    'uuid'?: string;
    /**
     * 
     * @type {SyntheticMetrics}
     * @memberof InvestmentReports
     */
    'syntheticMetrics'?: SyntheticMetrics;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentReports
     */
    'tickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentReports
     */
    'currencyComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof InvestmentReports
     */
    'proxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof InvestmentReports
     */
    'proxyHedgedComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof InvestmentReports
     */
    'proxyFlagshipComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof InvestmentReports
     */
    'compositionVolatilityContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof InvestmentReports
     */
    'realizedVolatilityContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof InvestmentReports
     */
    'realizedPerformanceContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof InvestmentReports
     */
    'performance'?: Array<Array<number>>;
    /**
     * 
     * @type {{ [key: string]: BenchmarkInvestmentReports; }}
     * @memberof InvestmentReports
     */
    'benchmarksInvestmentReports'?: { [key: string]: BenchmarkInvestmentReports; };
    /**
     * 
     * @type {Array<FactorValue>}
     * @memberof InvestmentReports
     */
    'factors'?: Array<FactorValue>;
    /**
     * 
     * @type {ExplainabilityDetails}
     * @memberof InvestmentReports
     */
    'explainabilityDetails'?: ExplainabilityDetails;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof InvestmentReports
     */
    'marketViewExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<MarketViewTickerBreakdownEntry>}
     * @memberof InvestmentReports
     */
    'marketViewTickerBreakdown'?: Array<MarketViewTickerBreakdownEntry>;
    /**
     * 
     * @type {Array<AssetClassTickerBreakdownEntry>}
     * @memberof InvestmentReports
     */
    'macroAssetClassTickerBreakdown'?: Array<AssetClassTickerBreakdownEntry>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof InvestmentReports
     */
    'assetClassConstraintsExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof InvestmentReports
     */
    'marketViewFullExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentReports
     */
    'batchOrdering'?: Array<InvestmentReportsBatchOrderingEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof InvestmentReports
     */
    'executedBatchStep'?: Set<InvestmentReportsExecutedBatchStepEnum>;
}

export const InvestmentReportsBatchOrderingEnum = {
    Essentials: 'ESSENTIALS',
    Anagraphic: 'ANAGRAPHIC',
    RealizedSyntheticMetrics: 'REALIZED_SYNTHETIC_METRICS',
    ExAnteSyntheticMetrics: 'EX_ANTE_SYNTHETIC_METRICS',
    ProxyCompositions: 'PROXY_COMPOSITIONS',
    ExAnteContributions: 'EX_ANTE_CONTRIBUTIONS',
    RealizedContributions: 'REALIZED_CONTRIBUTIONS',
    RealizedPerformanceAttribution: 'REALIZED_PERFORMANCE_ATTRIBUTION',
    PerformanceLines: 'PERFORMANCE_LINES',
    Factors: 'FACTORS',
    ExplainabilityDetails: 'EXPLAINABILITY_DETAILS',
    QualityExposures: 'QUALITY_EXPOSURES',
    TickerBreakdowns: 'TICKER_BREAKDOWNS'
} as const;

export type InvestmentReportsBatchOrderingEnum = typeof InvestmentReportsBatchOrderingEnum[keyof typeof InvestmentReportsBatchOrderingEnum];
export const InvestmentReportsExecutedBatchStepEnum = {
    Essentials: 'ESSENTIALS',
    Anagraphic: 'ANAGRAPHIC',
    RealizedSyntheticMetrics: 'REALIZED_SYNTHETIC_METRICS',
    ExAnteSyntheticMetrics: 'EX_ANTE_SYNTHETIC_METRICS',
    ProxyCompositions: 'PROXY_COMPOSITIONS',
    ExAnteContributions: 'EX_ANTE_CONTRIBUTIONS',
    RealizedContributions: 'REALIZED_CONTRIBUTIONS',
    RealizedPerformanceAttribution: 'REALIZED_PERFORMANCE_ATTRIBUTION',
    PerformanceLines: 'PERFORMANCE_LINES',
    Factors: 'FACTORS',
    ExplainabilityDetails: 'EXPLAINABILITY_DETAILS',
    QualityExposures: 'QUALITY_EXPOSURES',
    TickerBreakdowns: 'TICKER_BREAKDOWNS'
} as const;

export type InvestmentReportsExecutedBatchStepEnum = typeof InvestmentReportsExecutedBatchStepEnum[keyof typeof InvestmentReportsExecutedBatchStepEnum];

/**
 * 
 * @export
 * @interface InvestmentRiskConstraintsObtainBandsErrorResponse
 */
export interface InvestmentRiskConstraintsObtainBandsErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentRiskConstraintsObtainBandsErrorResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentRiskConstraintsObtainBandsErrorResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentRiskConstraintsObtainBandsErrorResponse
     */
    'creation'?: boolean;
}
/**
 * 
 * @export
 * @interface InvestmentRiskConstraintsObtainBandsResponse
 */
export interface InvestmentRiskConstraintsObtainBandsResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestmentRiskConstraintsObtainBandsResponse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentRiskConstraintsObtainBandsResponse
     */
    'operationUuid'?: string;
    /**
     * 
     * @type {RiskConstraints}
     * @memberof InvestmentRiskConstraintsObtainBandsResponse
     */
    'riskConstraints'?: RiskConstraints;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentRiskConstraintsObtainBandsResponse
     */
    'creation'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentStatuses = {
    Calculating: 'CALCULATING',
    Error: 'ERROR',
    Ready: 'READY',
    ProposalReady: 'PROPOSAL_READY',
    Accepted: 'ACCEPTED',
    Review: 'REVIEW',
    RetrievingData: 'RETRIEVING_DATA',
    Draft: 'DRAFT'
} as const;

export type InvestmentStatuses = typeof InvestmentStatuses[keyof typeof InvestmentStatuses];


/**
 * 
 * @export
 * @interface InvestmentSubPortfolioEntry
 */
export interface InvestmentSubPortfolioEntry {
    /**
     * 
     * @type {string}
     * @memberof InvestmentSubPortfolioEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSubPortfolioEntry
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSubPortfolioEntry
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface InvestmentSummary
 */
export interface InvestmentSummary {
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'uuid'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentSummary
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentSummary
     */
    'actualAction'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentSummary
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentSummary
     */
    'hasProposalDraft'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentSummary
     */
    'hasVisibleProposal'?: boolean;
    /**
     * 
     * @type {Currencies}
     * @memberof InvestmentSummary
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'universeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'primaryBenchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'primaryBenchmarkName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'primaryBenchmarkType'?: InvestmentSummaryPrimaryBenchmarkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'referenceIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'referenceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'referenceType'?: InvestmentSummaryReferenceTypeEnum;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof InvestmentSummary
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof InvestmentSummary
     */
    'selectedRiskConstraint'?: RiskConstraint;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'riskValue'?: number;
    /**
     * 
     * @type {MaxTrackingErrorDTO}
     * @memberof InvestmentSummary
     */
    'selectedTrackingErrorConstraint'?: MaxTrackingErrorDTO;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'trackingErrorValue'?: number;
    /**
     * 
     * @type {Array<AlertDto>}
     * @memberof InvestmentSummary
     */
    'alerts'?: Array<AlertDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentSummary
     * @deprecated
     */
    'alertsLegacy'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'linkedPortfolios'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'scoreIdentifier'?: string;
    /**
     * 
     * @type {ScoresConstraint}
     * @memberof InvestmentSummary
     */
    'scoresConstraint'?: ScoresConstraint;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'scoreValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'lastActionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'syncDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentSummary
     */
    'importedExternally'?: boolean;
    /**
     * 
     * @type {RichAcl}
     * @memberof InvestmentSummary
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {MarketScenarioMinInfo}
     * @memberof InvestmentSummary
     */
    'marketView'?: MarketScenarioMinInfo;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'historicalVar953Y'?: number;
    /**
     * 
     * @type {InvestmentNote}
     * @memberof InvestmentSummary
     */
    'lastActionNote'?: InvestmentNote;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'mandateType'?: InvestmentSummaryMandateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'commentaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentSummary
     */
    'commentaryStatus'?: InvestmentSummaryCommentaryStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentSummary
     */
    'createdFromUpload'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentSummary
     */
    'reference'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummary
     */
    'nofInstruments'?: number;
}

export const InvestmentSummaryPrimaryBenchmarkTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentSummaryPrimaryBenchmarkTypeEnum = typeof InvestmentSummaryPrimaryBenchmarkTypeEnum[keyof typeof InvestmentSummaryPrimaryBenchmarkTypeEnum];
export const InvestmentSummaryReferenceTypeEnum = {
    StandardBenchmark: 'STANDARD_BENCHMARK',
    CustomBenchmark: 'CUSTOM_BENCHMARK',
    ReferenceInvestment: 'REFERENCE_INVESTMENT',
    CurrentPortfolio: 'CURRENT_PORTFOLIO'
} as const;

export type InvestmentSummaryReferenceTypeEnum = typeof InvestmentSummaryReferenceTypeEnum[keyof typeof InvestmentSummaryReferenceTypeEnum];
export const InvestmentSummaryMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type InvestmentSummaryMandateTypeEnum = typeof InvestmentSummaryMandateTypeEnum[keyof typeof InvestmentSummaryMandateTypeEnum];
export const InvestmentSummaryCommentaryStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type InvestmentSummaryCommentaryStatusEnum = typeof InvestmentSummaryCommentaryStatusEnum[keyof typeof InvestmentSummaryCommentaryStatusEnum];

/**
 * 
 * @export
 * @interface InvestmentUploadReviewDetails
 */
export interface InvestmentUploadReviewDetails {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof InvestmentUploadReviewDetails
     */
    'uploadComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof InvestmentUploadReviewDetails
     */
    'uploadErrors'?: Array<UploadError>;
}
/**
 * 
 * @export
 * @interface InvestmentsEnhancedModel
 */
export interface InvestmentsEnhancedModel {
    /**
     * 
     * @type {Array<AccessControl>}
     * @memberof InvestmentsEnhancedModel
     */
    'acl'?: Array<AccessControl>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'lastReportsUpdate'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentsEnhancedModel
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentsEnhancedModel
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsEnhancedModel
     */
    'createdFromUpload'?: boolean;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentsEnhancedModel
     */
    'actualAction'?: InvestmentActions;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsEnhancedModel
     */
    'referenceInvestment'?: boolean;
    /**
     * 
     * @type {InvestmentConfiguration}
     * @memberof InvestmentsEnhancedModel
     */
    'configuration'?: InvestmentConfiguration;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'initialTickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'tickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {ReportsModel}
     * @memberof InvestmentsEnhancedModel
     */
    'reports'?: ReportsModel;
    /**
     * 
     * @type {EntityError}
     * @memberof InvestmentsEnhancedModel
     */
    'currentError'?: EntityError;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsEnhancedModel
     */
    'importedExternally'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'importStatus'?: InvestmentsEnhancedModelImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'portfolioDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'shortCommentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'commentaryDate'?: string;
    /**
     * 
     * @type {Array<SubPortfolio>}
     * @memberof InvestmentsEnhancedModel
     */
    'initialSubPortfolios'?: Array<SubPortfolio>;
    /**
     * 
     * @type {Note}
     * @memberof InvestmentsEnhancedModel
     */
    'note'?: Note;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     * @deprecated
     */
    'actionUserId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsEnhancedModel
     */
    'visible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     */
    'commentaryStatus'?: InvestmentsEnhancedModelCommentaryStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvestmentsEnhancedModel
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {ReferenceUniverse}
     * @memberof InvestmentsEnhancedModel
     */
    'referenceUniverse'?: ReferenceUniverse;
    /**
     * 
     * @type {BenchmarkSnapshot}
     * @memberof InvestmentsEnhancedModel
     */
    'benchmarkSnapshot'?: BenchmarkSnapshot;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentsEnhancedModel
     */
    'actionBeforeEnhancement'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentsEnhancedModel
     */
    'statusBeforeEnhancement'?: InvestmentStatuses;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'currentCompositionOnEnhancementDate'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'currentCurrencyCompositionOnEnhancementDate'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'currentProxyCompositionOnEnhancementDate'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof InvestmentsEnhancedModel
     */
    'currentHedgedCompositionOnEnhancementDate'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<FactorValue>}
     * @memberof InvestmentsEnhancedModel
     */
    'currentFactorOnEnhancementDate'?: Array<FactorValue>;
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof InvestmentsEnhancedModel
     */
    'compositionVolatilityContributionAtEnhancementDate'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {SyntheticMetrics}
     * @memberof InvestmentsEnhancedModel
     */
    'syntheticMetricsOnEnhancementDate'?: SyntheticMetrics;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof InvestmentsEnhancedModel
     */
    'uploadErrors'?: Array<UploadError>;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsEnhancedModel
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsEnhancedModel
     * @deprecated
     */
    '_id'?: string;
}

export const InvestmentsEnhancedModelImportStatusEnum = {
    Ok: 'OK',
    Waiting: 'WAITING',
    Error: 'ERROR'
} as const;

export type InvestmentsEnhancedModelImportStatusEnum = typeof InvestmentsEnhancedModelImportStatusEnum[keyof typeof InvestmentsEnhancedModelImportStatusEnum];
export const InvestmentsEnhancedModelCommentaryStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type InvestmentsEnhancedModelCommentaryStatusEnum = typeof InvestmentsEnhancedModelCommentaryStatusEnum[keyof typeof InvestmentsEnhancedModelCommentaryStatusEnum];

/**
 * 
 * @export
 * @interface InvestmentsModel
 */
export interface InvestmentsModel {
    /**
     * 
     * @type {Array<AccessControl>}
     * @memberof InvestmentsModel
     */
    'acl'?: Array<AccessControl>;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'lastReportsUpdate'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentsModel
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof InvestmentsModel
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsModel
     */
    'createdFromUpload'?: boolean;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof InvestmentsModel
     */
    'actualAction'?: InvestmentActions;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsModel
     */
    'referenceInvestment'?: boolean;
    /**
     * 
     * @type {InvestmentConfiguration}
     * @memberof InvestmentsModel
     */
    'configuration'?: InvestmentConfiguration;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsModel
     */
    'initialTickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof InvestmentsModel
     */
    'tickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {ReportsModel}
     * @memberof InvestmentsModel
     */
    'reports'?: ReportsModel;
    /**
     * 
     * @type {EntityError}
     * @memberof InvestmentsModel
     */
    'currentError'?: EntityError;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsModel
     */
    'importedExternally'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'importStatus'?: InvestmentsModelImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'portfolioDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'shortCommentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'commentaryDate'?: string;
    /**
     * 
     * @type {Array<SubPortfolio>}
     * @memberof InvestmentsModel
     */
    'initialSubPortfolios'?: Array<SubPortfolio>;
    /**
     * 
     * @type {Note}
     * @memberof InvestmentsModel
     */
    'note'?: Note;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'actionUserId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsModel
     */
    'visible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    'commentaryStatus'?: InvestmentsModelCommentaryStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvestmentsModel
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {ReferenceUniverse}
     * @memberof InvestmentsModel
     */
    'referenceUniverse'?: ReferenceUniverse;
    /**
     * 
     * @type {BenchmarkSnapshot}
     * @memberof InvestmentsModel
     */
    'benchmarkSnapshot'?: BenchmarkSnapshot;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof InvestmentsModel
     */
    'uploadComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof InvestmentsModel
     */
    'uploadErrors'?: Array<UploadError>;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsModel
     */
    'ready'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsModel
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsModel
     */
    '_id'?: string;
}

export const InvestmentsModelImportStatusEnum = {
    Ok: 'OK',
    Waiting: 'WAITING',
    Error: 'ERROR'
} as const;

export type InvestmentsModelImportStatusEnum = typeof InvestmentsModelImportStatusEnum[keyof typeof InvestmentsModelImportStatusEnum];
export const InvestmentsModelCommentaryStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type InvestmentsModelCommentaryStatusEnum = typeof InvestmentsModelCommentaryStatusEnum[keyof typeof InvestmentsModelCommentaryStatusEnum];

/**
 * 
 * @export
 * @interface InvestmentsReportTemplate
 */
export interface InvestmentsReportTemplate {
    /**
     * 
     * @type {string}
     * @memberof InvestmentsReportTemplate
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsReportTemplate
     */
    'templateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsReportTemplate
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentsReportTemplate
     */
    'format'?: string;
    /**
     * 
     * @type {object}
     * @memberof InvestmentsReportTemplate
     */
    'data'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentsReportTemplate
     */
    'visible'?: boolean;
}
/**
 * 
 * @export
 * @interface JwtResponse
 */
export interface JwtResponse {
    /**
     * 
     * @type {string}
     * @memberof JwtResponse
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface LabelValue
 */
export interface LabelValue {
    /**
     * 
     * @type {string}
     * @memberof LabelValue
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof LabelValue
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof LabelValue
     */
    'prev_value'?: number;
}
/**
 * 
 * @export
 * @interface LabelValueV2
 */
export interface LabelValueV2 {
    /**
     * 
     * @type {string}
     * @memberof LabelValueV2
     */
    'label'?: string;
    /**
     * 
     * @type {ValueWithData}
     * @memberof LabelValueV2
     */
    'value'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof LabelValueV2
     */
    'prev_value'?: ValueWithData;
}
/**
 * 
 * @export
 * @interface LeafStepValues
 */
export interface LeafStepValues {
    /**
     * 
     * @type {string}
     * @memberof LeafStepValues
     */
    'leafType'?: LeafStepValuesLeafTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LeafStepValues
     */
    'allocationInFunds'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeafStepValues
     */
    'rankedExpectedReturns'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeafStepValues
     */
    'rankedExpectedVolatility'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeafStepValues
     */
    'rankedExpectedDiversification'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeafStepValues
     */
    'rankedWeights'?: number;
}

export const LeafStepValuesLeafTypeEnum = {
    Alternative: 'ALTERNATIVE',
    Commodities: 'COMMODITIES',
    EmergingEquity: 'EMERGING_EQUITY',
    FixedIncome: 'FIXED_INCOME',
    GlobalEquity: 'GLOBAL_EQUITY',
    JapanEquity: 'JAPAN_EQUITY',
    MoneyMarket: 'MONEY_MARKET',
    OtherEquity: 'OTHER_EQUITY',
    SectorsEu: 'SECTORS_EU',
    SectorsUs: 'SECTORS_US',
    SingleStocks: 'SINGLE_STOCKS'
} as const;

export type LeafStepValuesLeafTypeEnum = typeof LeafStepValuesLeafTypeEnum[keyof typeof LeafStepValuesLeafTypeEnum];

/**
 * 
 * @export
 * @interface LockInstrumentConstraint
 */
export interface LockInstrumentConstraint {
    /**
     * 
     * @type {string}
     * @memberof LockInstrumentConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof LockInstrumentConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LockInstrumentConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LockInstrumentConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof LockInstrumentConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {Array<ConstrainedTicker>}
     * @memberof LockInstrumentConstraint
     */
    'tickers'?: Array<ConstrainedTicker>;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof LockInstrumentConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface LogDto
 */
export interface LogDto {
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    'level'?: LogDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    'message'?: string;
}

export const LogDtoLevelEnum = {
    Error: 'ERROR',
    Warn: 'WARN',
    Info: 'INFO',
    Debug: 'DEBUG'
} as const;

export type LogDtoLevelEnum = typeof LogDtoLevelEnum[keyof typeof LogDtoLevelEnum];

/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MacroContextDriverDTO
 */
export interface MacroContextDriverDTO {
    /**
     * 
     * @type {string}
     * @memberof MacroContextDriverDTO
     */
    'label'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MacroContextDriverDTO
     */
    'splitLabel'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MacroContextDriverDTO
     */
    'current'?: string;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'relevance'?: number;
    /**
     * 
     * @type {string}
     * @memberof MacroContextDriverDTO
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof MacroContextDriverDTO
     */
    'trend'?: string;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'returnTimeSeries'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'returnVsPeers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'volatilityTimeSeries'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'volatilityVsPeers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'diversificationVsPeers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'maxReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'minReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'maxVolatility'?: number;
    /**
     * 
     * @type {number}
     * @memberof MacroContextDriverDTO
     */
    'minVolatility'?: number;
}
/**
 * 
 * @export
 * @interface MainInfo
 */
export interface MainInfo {
    /**
     * 
     * @type {InvestmentBenchmark}
     * @memberof MainInfo
     */
    'primaryBenchmark'?: InvestmentBenchmark;
    /**
     * 
     * @type {Array<InvestmentBenchmark>}
     * @memberof MainInfo
     */
    'secondaryBenchmarks'?: Array<InvestmentBenchmark>;
    /**
     * 
     * @type {Currencies}
     * @memberof MainInfo
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof MainInfo
     */
    'mandateType'?: MainInfoMandateTypeEnum;
    /**
     * 
     * @type {InvestmentReference}
     * @memberof MainInfo
     */
    'reference'?: InvestmentReference;
    /**
     * 
     * @type {TargetVolatility}
     * @memberof MainInfo
     */
    'targetVolatility'?: TargetVolatility;
}

export const MainInfoMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type MainInfoMandateTypeEnum = typeof MainInfoMandateTypeEnum[keyof typeof MainInfoMandateTypeEnum];

/**
 * 
 * @export
 * @interface MainInfoResponse
 */
export interface MainInfoResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof MainInfoResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof MainInfoResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof MainInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentBenchmarkDTO}
     * @memberof MainInfoResponse
     */
    'primaryBenchmark'?: InvestmentBenchmarkDTO;
    /**
     * 
     * @type {Array<InvestmentBenchmarkDTO>}
     * @memberof MainInfoResponse
     */
    'secondaryBenchmarks'?: Array<InvestmentBenchmarkDTO>;
    /**
     * 
     * @type {Currencies}
     * @memberof MainInfoResponse
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof MainInfoResponse
     */
    'mandateType'?: MainInfoResponseMandateTypeEnum;
    /**
     * 
     * @type {InvestmentReferenceDTO}
     * @memberof MainInfoResponse
     */
    'investmentReference'?: InvestmentReferenceDTO;
    /**
     * 
     * @type {TargetVolatility}
     * @memberof MainInfoResponse
     */
    'targetVolatility'?: TargetVolatility;
}

export const MainInfoResponseMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type MainInfoResponseMandateTypeEnum = typeof MainInfoResponseMandateTypeEnum[keyof typeof MainInfoResponseMandateTypeEnum];

/**
 * 
 * @export
 * @interface MainInfoSaveRequest
 */
export interface MainInfoSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof MainInfoSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MainInfoSaveRequest
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentBenchmarkDTO}
     * @memberof MainInfoSaveRequest
     */
    'primaryBenchmark'?: InvestmentBenchmarkDTO;
    /**
     * 
     * @type {Currencies}
     * @memberof MainInfoSaveRequest
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {string}
     * @memberof MainInfoSaveRequest
     */
    'mandateType'?: MainInfoSaveRequestMandateTypeEnum;
    /**
     * 
     * @type {InvestmentReferenceDTO}
     * @memberof MainInfoSaveRequest
     */
    'investmentReference'?: InvestmentReferenceDTO;
    /**
     * 
     * @type {TargetVolatility}
     * @memberof MainInfoSaveRequest
     */
    'targetVolatility'?: TargetVolatility;
}

export const MainInfoSaveRequestMandateTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type MainInfoSaveRequestMandateTypeEnum = typeof MainInfoSaveRequestMandateTypeEnum[keyof typeof MainInfoSaveRequestMandateTypeEnum];

/**
 * 
 * @export
 * @interface MarketForecastDTO
 */
export interface MarketForecastDTO {
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'sector'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'assetClass'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'expVol'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'confLevel'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'thirdQuartile'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'firstQuartile'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'median'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketForecastDTO
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'timeHorizon'?: string;
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketForecastDTO
     */
    'prevValue'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketForecastDTO
     */
    'currentValue'?: ValueWithData;
    /**
     * 
     * @type {string}
     * @memberof MarketForecastDTO
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface MarketOutlookDTO
 */
export interface MarketOutlookDTO {
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookDTO
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookDTO
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookDTO
     */
    'sector'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookDTO
     */
    'label'?: string;
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketOutlookDTO
     */
    'currentValue'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketOutlookDTO
     */
    'prevValue'?: ValueWithData;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookDTO
     */
    'type'?: string;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof MarketOutlookDTO
     */
    'exposureLabel'?: Array<Chip>;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof MarketOutlookDTO
     */
    'driverLabel'?: Array<Chip>;
}
/**
 * 
 * @export
 * @interface MarketOutlookFocusDTO
 */
export interface MarketOutlookFocusDTO {
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketOutlookFocusDTO
     */
    'prevValue'?: ValueWithData;
    /**
     * 
     * @type {ValueWithData}
     * @memberof MarketOutlookFocusDTO
     */
    'currentValue'?: ValueWithData;
    /**
     * 
     * @type {Array<ElementDTO>}
     * @memberof MarketOutlookFocusDTO
     */
    'elements'?: Array<ElementDTO>;
}
/**
 * 
 * @export
 * @interface MarketOutlookFocusTimeSeriesDTO
 */
export interface MarketOutlookFocusTimeSeriesDTO {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MarketOutlookFocusTimeSeriesDTO
     */
    'values'?: Array<Array<number>>;
    /**
     * 
     * @type {{ [key: string]: Array<Array<number>>; }}
     * @memberof MarketOutlookFocusTimeSeriesDTO
     */
    'elements'?: { [key: string]: Array<Array<number>>; };
}
/**
 * 
 * @export
 * @interface MarketOutlookTimeSeriesDTO
 */
export interface MarketOutlookTimeSeriesDTO {
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'sector'?: string;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'values'?: Array<Array<number>>;
    /**
     * 
     * @type {boolean}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'inverted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketOutlookTimeSeriesDTO
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface MarketOverviewDTO
 */
export interface MarketOverviewDTO {
    /**
     * 
     * @type {CurrentAndPreviousDto}
     * @memberof MarketOverviewDTO
     */
    'commoditiesIndicators'?: CurrentAndPreviousDto;
    /**
     * 
     * @type {CurrentAndPreviousDto}
     * @memberof MarketOverviewDTO
     */
    'equityIndicators'?: CurrentAndPreviousDto;
    /**
     * 
     * @type {CurrentAndPreviousDto}
     * @memberof MarketOverviewDTO
     */
    'fixedIncomeIndicators'?: CurrentAndPreviousDto;
}
/**
 * 
 * @export
 * @interface MarketOverviewTimeSeriesDTO
 */
export interface MarketOverviewTimeSeriesDTO {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MarketOverviewTimeSeriesDTO
     */
    'commoditiesIndicators'?: Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MarketOverviewTimeSeriesDTO
     */
    'equityIndicators'?: Array<Array<number>>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MarketOverviewTimeSeriesDTO
     */
    'fixedIncomeIndicators'?: Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface MarketRegimeAnalysis
 */
export interface MarketRegimeAnalysis {
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeAnalysis
     */
    'prevRegime'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeAnalysis
     */
    'currRegime'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketRegimeAnalysis
     */
    'lastDay'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeAnalysis
     */
    'consecutiveDay'?: number;
}
/**
 * 
 * @export
 * @interface MarketRegimeProbabilityDTO
 */
export interface MarketRegimeProbabilityDTO {
    [key: string]: MarketRegimeProbabilityEntryDTO | any;

    /**
     * 
     * @type {boolean}
     * @memberof MarketRegimeProbabilityDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface MarketRegimeProbabilityEntryDTO
 */
export interface MarketRegimeProbabilityEntryDTO {
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeProbabilityEntryDTO
     */
    'high_risk_prob'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeProbabilityEntryDTO
     */
    'mid_risk_prob'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketRegimeProbabilityEntryDTO
     */
    'low_risk_prob'?: number;
}
/**
 * 
 * @export
 * @interface MarketReportDTO
 */
export interface MarketReportDTO {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MarketReportDTO
     */
    'synthetic_category'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof MarketReportDTO
     */
    'synthetic_value'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof MarketReportDTO
     */
    'daily_category'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof MarketReportDTO
     */
    'daily_value'?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {FixedIncomeIndicators}
     * @memberof MarketReportDTO
     */
    'fixed_income_indicators'?: FixedIncomeIndicators;
    /**
     * 
     * @type {CommoditiesIndicators}
     * @memberof MarketReportDTO
     */
    'commodities_indicators'?: CommoditiesIndicators;
    /**
     * 
     * @type {EquityIndicators}
     * @memberof MarketReportDTO
     */
    'equity_indicators'?: EquityIndicators;
    /**
     * 
     * @type {string}
     * @memberof MarketReportDTO
     */
    'creation_time'?: string;
}
/**
 * 
 * @export
 * @interface MarketScenario
 */
export interface MarketScenario {
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof MarketScenario
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof MarketScenario
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {number}
     * @memberof MarketScenario
     */
    'yield'?: number;
    /**
     * 
     * @type {FromToDate}
     * @memberof MarketScenario
     */
    'historicalInvestmentHorizon'?: FromToDate;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketScenario
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketScenario
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketScenario
     */
    'editable'?: boolean;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketScenario
     */
    'regimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketScenario
     */
    'regimeUserProbability'?: Regimes;
    /**
     * 
     * @type {ExpectedReturnsVolatility}
     * @memberof MarketScenario
     */
    'assetClasses'?: ExpectedReturnsVolatility;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketScenarioIds = {
    LehmanCrash: 'LEHMAN_CRASH',
    MarketRebound: 'MARKET_REBOUND',
    SovDebtCrisisGreeceBailout: 'SOV_DEBT_CRISIS_GREECE_BAILOUT',
    AsianFinancialCrisis: 'ASIAN_FINANCIAL_CRISIS',
    End2018MarketCrisis: 'END_2018_MARKET_CRISIS',
    MarketRebound2019: 'MARKET_REBOUND_2019',
    CovidPandemicSellOff: 'COVID_PANDEMIC_SELL_OFF',
    SphereForecastOneMonth: 'SPHERE_FORECAST_ONE_MONTH',
    SphereForecastThreeMonths: 'SPHERE_FORECAST_THREE_MONTHS',
    SphereForecastSixMonths: 'SPHERE_FORECAST_SIX_MONTHS',
    SphereStratacticalView: 'SPHERE_STRATACTICAL_VIEW',
    SphereForecastPositioningOneMonth: 'SPHERE_FORECAST_POSITIONING_ONE_MONTH',
    UserForecastPositioningOneMonth: 'USER_FORECAST_POSITIONING_ONE_MONTH',
    UserForecastPositioningThreeMonths: 'USER_FORECAST_POSITIONING_THREE_MONTHS',
    UserForecastPositioningSixMonths: 'USER_FORECAST_POSITIONING_SIX_MONTHS',
    UserForecastPositioningTwelveMonths: 'USER_FORECAST_POSITIONING_TWELVE_MONTHS',
    UserForecastPositioningEighteenMonths: 'USER_FORECAST_POSITIONING_EIGHTEEN_MONTHS',
    UserForecastPositioningTwentyFourMonths: 'USER_FORECAST_POSITIONING_TWENTY_FOUR_MONTHS',
    UserForecastPositioningThirtySixMonths: 'USER_FORECAST_POSITIONING_THIRTY_SIX_MONTHS',
    CurrentPortfolio: 'CURRENT_PORTFOLIO',
    CustomPortfolioMarketView: 'CUSTOM_PORTFOLIO_MARKET_VIEW'
} as const;

export type MarketScenarioIds = typeof MarketScenarioIds[keyof typeof MarketScenarioIds];


/**
 * 
 * @export
 * @interface MarketScenarioMinInfo
 */
export interface MarketScenarioMinInfo {
    /**
     * 
     * @type {string}
     * @memberof MarketScenarioMinInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketScenarioMinInfo
     */
    'label'?: string;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketScenarioMinInfo
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketScenarioMinInfo
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketScenarioMinInfo
     */
    'customMarketView'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MarketScenarioMinInfo
     */
    'userCustomScenario'?: boolean;
}
/**
 * 
 * @export
 * @interface MarketView
 */
export interface MarketView {
    /**
     * 
     * @type {boolean}
     * @memberof MarketView
     */
    'edited'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketView
     */
    'lastEdit'?: string;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketView
     */
    'regimeDefaultProbability'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketView
     */
    'regimeUserProbability'?: Regimes;
    /**
     * 
     * @type {ExpectedReturnsVolatility}
     * @memberof MarketView
     */
    'expectedReturnsVolatility'?: ExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof MarketView
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {string}
     * @memberof MarketView
     */
    'scenarioIdentifier'?: string;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketView
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketView
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketView
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketView
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MarketViewAssetClassAlias
 */
export interface MarketViewAssetClassAlias {
    /**
     * 
     * @type {MarketViewMicroAssetClasses}
     * @memberof MarketViewAssetClassAlias
     */
    'microAssetClass'?: MarketViewMicroAssetClasses;
    /**
     * 
     * @type {string}
     * @memberof MarketViewAssetClassAlias
     */
    'alias'?: string;
}
/**
 * 
 * @export
 * @interface MarketViewAssetClassAliases
 */
export interface MarketViewAssetClassAliases {
    /**
     * 
     * @type {Array<MarketViewAssetClassAlias>}
     * @memberof MarketViewAssetClassAliases
     */
    'assetClassAliases'?: Array<MarketViewAssetClassAlias>;
}
/**
 * 
 * @export
 * @interface MarketViewAssetClassLabel
 */
export interface MarketViewAssetClassLabel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewAssetClassLabel
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewAssetClassLabel
     */
    'tickerName'?: string;
}
/**
 * 
 * @export
 * @interface MarketViewBreakdownEntry
 */
export interface MarketViewBreakdownEntry {
    /**
     * 
     * @type {MarketViewMicroAssetClasses}
     * @memberof MarketViewBreakdownEntry
     */
    'microAssetClass'?: MarketViewMicroAssetClasses;
    /**
     * 
     * @type {number}
     * @memberof MarketViewBreakdownEntry
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewBulkSaveRequest
 */
export interface MarketViewBulkSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketViewBulkSaveRequest
     */
    'scenarioIdentifier'?: string;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewBulkSaveRequest
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewBulkSaveRequest
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof MarketViewBulkSaveRequest
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof MarketViewBulkSaveRequest
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewBulkSaveRequest
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketViewBulkSaveRequest
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewBulkSaveRequest
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketViewBulkSaveRequest
     */
    'marketViewName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewBulkSaveRequest
     */
    'bulkUUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewBulkSaveRequest
     */
    'investmentUuid'?: string;
}
/**
 * 
 * @export
 * @interface MarketViewDetails
 */
export interface MarketViewDetails {
    /**
     * 
     * @type {string}
     * @memberof MarketViewDetails
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewDetails
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewDetails
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewDetails
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewDetails
     */
    'endDate'?: string;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketViewDetails
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewDetails
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewDetails
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof MarketViewDetails
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof MarketViewDetails
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewDetails
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewDetails
     */
    'custom'?: boolean;
    /**
     * 
     * @type {RichAcl}
     * @memberof MarketViewDetails
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewDetails
     */
    'expired'?: boolean;
}
/**
 * 
 * @export
 * @interface MarketViewExport
 */
export interface MarketViewExport {
    /**
     * 
     * @type {string}
     * @memberof MarketViewExport
     */
    'marketViewName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewExport
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewExport
     */
    'forecastHorizon'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewExport
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewExport
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {Array<AssetClassExport>}
     * @memberof MarketViewExport
     */
    'assetClasses'?: Array<AssetClassExport>;
}
/**
 * 
 * @export
 * @interface MarketViewListEntry
 */
export interface MarketViewListEntry {
    /**
     * 
     * @type {string}
     * @memberof MarketViewListEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewListEntry
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewListEntry
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewListEntry
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewListEntry
     */
    'endDate'?: string;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketViewListEntry
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewListEntry
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewListEntry
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewListEntry
     */
    'type'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewListEntry
     */
    'custom'?: boolean;
    /**
     * 
     * @type {RichAcl}
     * @memberof MarketViewListEntry
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewListEntry
     */
    'expired'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketViewMicroAssetClasses = {
    UsaEquityLargeCap: 'USA_EQUITY_LARGE_CAP',
    EuEquityLargeCap: 'EU_EQUITY_LARGE_CAP',
    JapanEquityLargeCap: 'JAPAN_EQUITY_LARGE_CAP',
    PacificExJapanEquityLargeCap: 'PACIFIC_EX_JAPAN_EQUITY_LARGE_CAP',
    EmergingMarketsEquity: 'EMERGING_MARKETS_EQUITY',
    UkEquity: 'UK_EQUITY',
    ChinaEquity: 'CHINA_EQUITY',
    EmergingMarketsExChinaEquity: 'EMERGING_MARKETS_EX_CHINA_EQUITY',
    UsaFixedIncomeShortGovernment: 'USA_FIXED_INCOME_SHORT_GOVERNMENT',
    UsaFixedIncomeMediumGovernment: 'USA_FIXED_INCOME_MEDIUM_GOVERNMENT',
    UsaFixedIncomeLongGovernment: 'USA_FIXED_INCOME_LONG_GOVERNMENT',
    UsaFixedIncomeCorporateInvestmentGrade: 'USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE',
    UsaFixedIncomeCorporateHighYield: 'USA_FIXED_INCOME_CORPORATE_HIGH_YIELD',
    UsaFixedIncomeGovernment: 'USA_FIXED_INCOME_GOVERNMENT',
    EuFixedIncomeShortGovernment: 'EU_FIXED_INCOME_SHORT_GOVERNMENT',
    EuFixedIncomeMediumGovernment: 'EU_FIXED_INCOME_MEDIUM_GOVERNMENT',
    EuFixedIncomeLongGovernment: 'EU_FIXED_INCOME_LONG_GOVERNMENT',
    EuFixedIncomeCorporateInvestmentGrade: 'EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE',
    EuFixedIncomeCorporateHighYield: 'EU_FIXED_INCOME_CORPORATE_HIGH_YIELD',
    EuFixedIncomeGovernment: 'EU_FIXED_INCOME_GOVERNMENT',
    EmergingMarketsFixedIncomeCorporate: 'EMERGING_MARKETS_FIXED_INCOME_CORPORATE',
    EmergingMarketsFixedIncomeGovernmentStrongCurrency: 'EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT_STRONG_CURRENCY',
    EmergingMarketsFixedIncomeGovernmentLocalCurrency: 'EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY',
    ChinaFixedIncomeGovernment: 'CHINA_FIXED_INCOME_GOVERNMENT',
    CommoditiesGold: 'COMMODITIES_GOLD',
    CommoditiesPreciousMetals: 'COMMODITIES_PRECIOUS_METALS',
    CommoditiesIndustrialMetals: 'COMMODITIES_INDUSTRIAL_METALS',
    CommoditiesEnergy: 'COMMODITIES_ENERGY',
    CommoditiesAgriculture: 'COMMODITIES_AGRICULTURE',
    CommoditiesGlobal: 'COMMODITIES_GLOBAL',
    UsaEquityConsumerDiscretionary: 'USA_EQUITY_CONSUMER_DISCRETIONARY',
    UsaEquityConsumerStaples: 'USA_EQUITY_CONSUMER_STAPLES',
    UsaEquityEnergy: 'USA_EQUITY_ENERGY',
    UsaEquityFinancials: 'USA_EQUITY_FINANCIALS',
    UsaEquityHealthcare: 'USA_EQUITY_HEALTHCARE',
    UsaEquityIndustrials: 'USA_EQUITY_INDUSTRIALS',
    UsaEquityInformationTechnology: 'USA_EQUITY_INFORMATION_TECHNOLOGY',
    UsaEquityMaterials: 'USA_EQUITY_MATERIALS',
    UsaEquityRealEstate: 'USA_EQUITY_REAL_ESTATE',
    UsaEquityCommunicationServices: 'USA_EQUITY_COMMUNICATION_SERVICES',
    UsaEquityUtilities: 'USA_EQUITY_UTILITIES',
    CanadaEquity: 'CANADA_EQUITY',
    FranceEquity: 'FRANCE_EQUITY',
    GermanyEquity: 'GERMANY_EQUITY',
    AustraliaEquity: 'AUSTRALIA_EQUITY',
    HongKongEquity: 'HONG_KONG_EQUITY',
    SingaporeEquity: 'SINGAPORE_EQUITY',
    LatinAmericaEquity: 'LATIN_AMERICA_EQUITY',
    EmeaEquity: 'EMEA_EQUITY',
    ItalyEquity: 'ITALY_EQUITY',
    SpainEquity: 'SPAIN_EQUITY',
    SwitzerlandEquity: 'SWITZERLAND_EQUITY',
    EuEquityConsumerDiscretionary: 'EU_EQUITY_CONSUMER_DISCRETIONARY',
    EuEquityConsumerStaples: 'EU_EQUITY_CONSUMER_STAPLES',
    EuEquityEnergy: 'EU_EQUITY_ENERGY',
    EuEquityFinancials: 'EU_EQUITY_FINANCIALS',
    EuEquityHealthcare: 'EU_EQUITY_HEALTHCARE',
    EuEquityIndustrials: 'EU_EQUITY_INDUSTRIALS',
    EuEquityInformationTechnology: 'EU_EQUITY_INFORMATION_TECHNOLOGY',
    EuEquityMaterials: 'EU_EQUITY_MATERIALS',
    EuEquityRealEstate: 'EU_EQUITY_REAL_ESTATE',
    EuEquityCommunicationServices: 'EU_EQUITY_COMMUNICATION_SERVICES',
    EuEquityUtilities: 'EU_EQUITY_UTILITIES',
    EuEquitySmallCap: 'EU_EQUITY_SMALL_CAP',
    ItalyFixedIncomeShortGovernment: 'ITALY_FIXED_INCOME_SHORT_GOVERNMENT',
    ItalyFixedIncomeMediumGovernment: 'ITALY_FIXED_INCOME_MEDIUM_GOVERNMENT',
    ItalyFixedIncomeLongGovernment: 'ITALY_FIXED_INCOME_LONG_GOVERNMENT',
    SpainFixedIncomeShortGovernment: 'SPAIN_FIXED_INCOME_SHORT_GOVERNMENT',
    SpainFixedIncomeMediumGovernment: 'SPAIN_FIXED_INCOME_MEDIUM_GOVERNMENT',
    SpainFixedIncomeLongGovernment: 'SPAIN_FIXED_INCOME_LONG_GOVERNMENT',
    FranceFixedIncomeShortGovernment: 'FRANCE_FIXED_INCOME_SHORT_GOVERNMENT',
    FranceFixedIncomeMediumGovernment: 'FRANCE_FIXED_INCOME_MEDIUM_GOVERNMENT',
    FranceFixedIncomeLongGovernment: 'FRANCE_FIXED_INCOME_LONG_GOVERNMENT',
    GermanyFixedIncomeShortGovernment: 'GERMANY_FIXED_INCOME_SHORT_GOVERNMENT',
    GermanyFixedIncomeMediumGovernment: 'GERMANY_FIXED_INCOME_MEDIUM_GOVERNMENT',
    GermanyFixedIncomeLongGovernment: 'GERMANY_FIXED_INCOME_LONG_GOVERNMENT',
    EuFixedIncomeCorporateHighYieldExFin: 'EU_FIXED_INCOME_CORPORATE_HIGH_YIELD_EX_FIN',
    EuFixedIncomeCorporateHighYieldFin: 'EU_FIXED_INCOME_CORPORATE_HIGH_YIELD_FIN',
    EuFixedIncomeCorporateInvestmentGradeExFin: 'EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_EX_FIN',
    EuFixedIncomeCorporateInvestmentGradeFin: 'EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_FIN',
    UsaFixedIncomeCorporateHighYieldExFin: 'USA_FIXED_INCOME_CORPORATE_HIGH_YIELD_EX_FIN',
    UsaFixedIncomeCorporateHighYieldFin: 'USA_FIXED_INCOME_CORPORATE_HIGH_YIELD_FIN',
    UsaFixedIncomeCorporateInvestmentGradeExFin: 'USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_EX_FIN',
    UsaFixedIncomeCorporateInvestmentGradeFin: 'USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_FIN',
    GlobalFixedIncomeCorporateShortInvestmentGrade: 'GLOBAL_FIXED_INCOME_CORPORATE_SHORT_INVESTMENT_GRADE',
    GlobalFixedIncomeGovernmentInflationLinked: 'GLOBAL_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    MoneyMarket: 'MONEY_MARKET',
    UsdCurrency: 'USD_CURRENCY',
    UsaFixedIncome: 'USA_FIXED_INCOME',
    EuFixedIncome: 'EU_FIXED_INCOME',
    EmergingMarketsFixedIncomeCorporateInvestmentGrade: 'EMERGING_MARKETS_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE',
    EmergingMarketsFixedIncomeStrongCurrency: 'EMERGING_MARKETS_FIXED_INCOME_STRONG_CURRENCY',
    EmergingMarketsFixedIncomeLocalCurrency: 'EMERGING_MARKETS_FIXED_INCOME_LOCAL_CURRENCY',
    EmergingMarketsFixedIncomeGovernment: 'EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT',
    AlternativeGlobal: 'ALTERNATIVE_GLOBAL',
    EquityGlobal: 'EQUITY_GLOBAL',
    ChinaEquityConsumerDiscretionary: 'CHINA_EQUITY_CONSUMER_DISCRETIONARY',
    ChinaEquityConsumerStaples: 'CHINA_EQUITY_CONSUMER_STAPLES',
    ChinaEquityEnergy: 'CHINA_EQUITY_ENERGY',
    ChinaEquityFinancials: 'CHINA_EQUITY_FINANCIALS',
    ChinaEquityHealthcare: 'CHINA_EQUITY_HEALTHCARE',
    ChinaEquityIndustrials: 'CHINA_EQUITY_INDUSTRIALS',
    ChinaEquityInformationTechnology: 'CHINA_EQUITY_INFORMATION_TECHNOLOGY',
    ChinaEquityMaterials: 'CHINA_EQUITY_MATERIALS',
    ChinaEquityRealEstate: 'CHINA_EQUITY_REAL_ESTATE',
    ChinaEquityCommunicationServices: 'CHINA_EQUITY_COMMUNICATION_SERVICES',
    ChinaEquityUtilities: 'CHINA_EQUITY_UTILITIES',
    EmergingMarketsEquityConsumerDiscretionary: 'EMERGING_MARKETS_EQUITY_CONSUMER_DISCRETIONARY',
    EmergingMarketsEquityConsumerStaples: 'EMERGING_MARKETS_EQUITY_CONSUMER_STAPLES',
    EmergingMarketsEquityEnergy: 'EMERGING_MARKETS_EQUITY_ENERGY',
    EmergingMarketsEquityFinancials: 'EMERGING_MARKETS_EQUITY_FINANCIALS',
    EmergingMarketsEquityHealthcare: 'EMERGING_MARKETS_EQUITY_HEALTHCARE',
    EmergingMarketsEquityIndustrials: 'EMERGING_MARKETS_EQUITY_INDUSTRIALS',
    EmergingMarketsEquityInformationTechnology: 'EMERGING_MARKETS_EQUITY_INFORMATION_TECHNOLOGY',
    EmergingMarketsEquityMaterials: 'EMERGING_MARKETS_EQUITY_MATERIALS',
    EmergingMarketsEquityRealEstate: 'EMERGING_MARKETS_EQUITY_REAL_ESTATE',
    EmergingMarketsEquityCommunicationServices: 'EMERGING_MARKETS_EQUITY_COMMUNICATION_SERVICES',
    EmergingMarketsEquityUtilities: 'EMERGING_MARKETS_EQUITY_UTILITIES',
    JapanEquityConsumerDiscretionary: 'JAPAN_EQUITY_CONSUMER_DISCRETIONARY',
    JapanEquityConsumerStaples: 'JAPAN_EQUITY_CONSUMER_STAPLES',
    JapanEquityEnergy: 'JAPAN_EQUITY_ENERGY',
    JapanEquityFinancials: 'JAPAN_EQUITY_FINANCIALS',
    JapanEquityHealthcare: 'JAPAN_EQUITY_HEALTHCARE',
    JapanEquityIndustrials: 'JAPAN_EQUITY_INDUSTRIALS',
    JapanEquityInformationTechnology: 'JAPAN_EQUITY_INFORMATION_TECHNOLOGY',
    JapanEquityMaterials: 'JAPAN_EQUITY_MATERIALS',
    JapanEquityRealEstate: 'JAPAN_EQUITY_REAL_ESTATE',
    JapanEquityCommunicationServices: 'JAPAN_EQUITY_COMMUNICATION_SERVICES',
    JapanEquityUtilities: 'JAPAN_EQUITY_UTILITIES',
    KoreaEquity: 'KOREA_EQUITY',
    EuFixedIncomeCorporateShort: 'EU_FIXED_INCOME_CORPORATE_SHORT',
    EuFixedIncomeCorporateLong: 'EU_FIXED_INCOME_CORPORATE_LONG',
    EuFixedIncomeGovernmentInflationLinked: 'EU_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    UsFixedIncomeCorporateShort: 'US_FIXED_INCOME_CORPORATE_SHORT',
    UsFixedIncomeCorporateLong: 'US_FIXED_INCOME_CORPORATE_LONG',
    UsFixedIncomeGovernmentInflationLinked: 'US_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED',
    GlobalFixedIncomeCorporate: 'GLOBAL_FIXED_INCOME_CORPORATE',
    GlobalFixedIncomeGovernment: 'GLOBAL_FIXED_INCOME_GOVERNMENT',
    FrontierMarketEquity: 'FRONTIER_MARKET_EQUITY',
    AsiaFixedIncomeCorporate: 'ASIA_FIXED_INCOME_CORPORATE',
    AsiaFixedIncomeGovernment: 'ASIA_FIXED_INCOME_GOVERNMENT',
    AsiaFixedIncomeHighYield: 'ASIA_FIXED_INCOME_HIGH_YIELD',
    AsiaFixedIncomeGovernmentLocalCurrency: 'ASIA_FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY',
    UsaMoneyMarket: 'USA_MONEY_MARKET',
    EuMoneyMarket: 'EU_MONEY_MARKET',
    UkMoneyMarket: 'UK_MONEY_MARKET',
    SwitzerlandMoneyMarket: 'SWITZERLAND_MONEY_MARKET',
    SingaporeMoneyMarket: 'SINGAPORE_MONEY_MARKET',
    AustraliaMoneyMarket: 'AUSTRALIA_MONEY_MARKET',
    CanadaMoneyMarket: 'CANADA_MONEY_MARKET'
} as const;

export type MarketViewMicroAssetClasses = typeof MarketViewMicroAssetClasses[keyof typeof MarketViewMicroAssetClasses];


/**
 * 
 * @export
 * @interface MarketViewMonitoringMetric
 */
export interface MarketViewMonitoringMetric {
    /**
     * 
     * @type {string}
     * @memberof MarketViewMonitoringMetric
     */
    'scenarioName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewMonitoringMetric
     */
    'scenarioIdentifier'?: string;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewMonitoringMetric
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewMonitoringMetric
     */
    'custom'?: boolean;
    /**
     * 
     * @type {FromToDate}
     * @memberof MarketViewMonitoringMetric
     */
    'forecastDates'?: FromToDate;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketViewMonitoringMetric
     */
    'forecastHorizon'?: ForecastHorizons;
}
/**
 * 
 * @export
 * @interface MarketViewResponse
 */
export interface MarketViewResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof MarketViewResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof MarketViewResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof MarketViewResponse
     */
    'scenarioIdentifier'?: string;
    /**
     * 
     * @type {MarketScenario}
     * @memberof MarketViewResponse
     */
    'scenarioData'?: MarketScenario;
}
/**
 * 
 * @export
 * @interface MarketViewSaveRequest
 */
export interface MarketViewSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof MarketViewSaveRequest
     */
    'scenarioIdentifier'?: string;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewSaveRequest
     */
    'defaultRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {Regimes}
     * @memberof MarketViewSaveRequest
     */
    'userRegimeProbabilities'?: Regimes;
    /**
     * 
     * @type {FlexibleExpectedReturnsVolatility}
     * @memberof MarketViewSaveRequest
     */
    'flexibleExpectedReturnsVolatility'?: FlexibleExpectedReturnsVolatility;
    /**
     * 
     * @type {PositioningIndicators}
     * @memberof MarketViewSaveRequest
     */
    'positioningIndicators'?: PositioningIndicators;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewSaveRequest
     */
    'marketViewType'?: MarketViewType;
    /**
     * 
     * @type {ForecastHorizons}
     * @memberof MarketViewSaveRequest
     */
    'forecastHorizon'?: ForecastHorizons;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewSaveRequest
     */
    'custom'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketViewSaveRequest
     */
    'marketViewName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewSaveRequest
     */
    'investmentUuid'?: string;
}
/**
 * 
 * @export
 * @interface MarketViewSettings
 */
export interface MarketViewSettings {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketViewSettings
     */
    'activeMarketViewTypes'?: Array<MarketViewSettingsActiveMarketViewTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof MarketViewSettings
     */
    'customMarketViewName'?: string;
    /**
     * 
     * @type {MarketViewType}
     * @memberof MarketViewSettings
     */
    'customMarketViewType'?: MarketViewType;
    /**
     * 
     * @type {Array<MarketScenarioIds>}
     * @memberof MarketViewSettings
     */
    'customMarketViewScenarioIds'?: Array<MarketScenarioIds>;
    /**
     * 
     * @type {Array<CustomMarketViewMicroAssetClass>}
     * @memberof MarketViewSettings
     * @deprecated
     */
    'customMarketViewMicroAssetClasses'?: Array<CustomMarketViewMicroAssetClass>;
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof MarketViewSettings
     */
    'customMarketViewAssetClasses'?: Array<MarketViewMicroAssetClasses>;
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof MarketViewSettings
     */
    'defaultExpectedReturnMarketViewAssetClasses'?: Array<MarketViewMicroAssetClasses>;
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof MarketViewSettings
     */
    'defaultPositioningMarketViewAssetClasses'?: Array<MarketViewMicroAssetClasses>;
    /**
     * 
     * @type {Array<MarketViewAssetClassAlias>}
     * @memberof MarketViewSettings
     */
    'marketViewAssetClassAliases'?: Array<MarketViewAssetClassAlias>;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewSettings
     */
    'commentaryEditorVisible'?: boolean;
}

export const MarketViewSettingsActiveMarketViewTypesEnum = {
    ExpectedReturnsVolatility: 'EXPECTED_RETURNS_VOLATILITY',
    PositioningIndicators: 'POSITIONING_INDICATORS',
    Custom: 'CUSTOM'
} as const;

export type MarketViewSettingsActiveMarketViewTypesEnum = typeof MarketViewSettingsActiveMarketViewTypesEnum[keyof typeof MarketViewSettingsActiveMarketViewTypesEnum];

/**
 * 
 * @export
 * @interface MarketViewTickerBreakdownEntry
 */
export interface MarketViewTickerBreakdownEntry {
    /**
     * 
     * @type {string}
     * @memberof MarketViewTickerBreakdownEntry
     */
    'ticker'?: string;
    /**
     * 
     * @type {Array<MarketViewBreakdownEntry>}
     * @memberof MarketViewTickerBreakdownEntry
     */
    'breakdown'?: Array<MarketViewBreakdownEntry>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketViewType = {
    ExpectedReturnsVolatility: 'EXPECTED_RETURNS_VOLATILITY',
    PositioningIndicators: 'POSITIONING_INDICATORS'
} as const;

export type MarketViewType = typeof MarketViewType[keyof typeof MarketViewType];


/**
 * 
 * @export
 * @interface MaxNumberOfInstruments
 */
export interface MaxNumberOfInstruments {
    /**
     * 
     * @type {number}
     * @memberof MaxNumberOfInstruments
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxNumberOfInstruments
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxNumberOfInstruments
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxNumberOfInstruments
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxNumberOfInstruments
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof MaxNumberOfInstruments
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface MaxTrackingError
 */
export interface MaxTrackingError {
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingError
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingError
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingError
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingError
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {InvestmentReference}
     * @memberof MaxTrackingError
     */
    'reference'?: InvestmentReference;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof MaxTrackingError
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface MaxTrackingErrorDTO
 */
export interface MaxTrackingErrorDTO {
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingErrorDTO
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingErrorDTO
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingErrorDTO
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxTrackingErrorDTO
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {InvestmentReferenceDTO}
     * @memberof MaxTrackingErrorDTO
     */
    'investmentReference'?: InvestmentReferenceDTO;
    /**
     * 
     * @type {boolean}
     * @memberof MaxTrackingErrorDTO
     */
    'target'?: boolean;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof MaxTrackingErrorDTO
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface MinNumberOfInstruments
 */
export interface MinNumberOfInstruments {
    /**
     * 
     * @type {number}
     * @memberof MinNumberOfInstruments
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinNumberOfInstruments
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinNumberOfInstruments
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinNumberOfInstruments
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinNumberOfInstruments
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof MinNumberOfInstruments
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface MonitoringMetric
 */
export interface MonitoringMetric {
    /**
     * 
     * @type {string}
     * @memberof MonitoringMetric
     */
    'category'?: MonitoringMetricCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof MonitoringMetric
     */
    'entity'?: string;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof MonitoringMetric
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringMetric
     */
    'target'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoringMetric
     */
    'minWarningDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringMetric
     */
    'maxWarningDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringMetric
     */
    'minThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringMetric
     */
    'maxThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringMetric
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringMetric
     */
    'identifier'?: string;
}

export const MonitoringMetricCategoryEnum = {
    AssetAllocation: 'ASSET_ALLOCATION',
    Tag: 'TAG',
    WeightOnSingleInstrument: 'WEIGHT_ON_SINGLE_INSTRUMENT',
    Instrument: 'INSTRUMENT',
    AverageScore: 'AVERAGE_SCORE',
    Risk: 'RISK',
    TargetTrackingError: 'TARGET_TRACKING_ERROR',
    TargetVolatility: 'TARGET_VOLATILITY',
    Currency: 'CURRENCY',
    NumberOfInstruments: 'NUMBER_OF_INSTRUMENTS',
    LockInstrument: 'LOCK_INSTRUMENT',
    ForEach: 'FOR_EACH'
} as const;

export type MonitoringMetricCategoryEnum = typeof MonitoringMetricCategoryEnum[keyof typeof MonitoringMetricCategoryEnum];

/**
 * 
 * @export
 * @interface MonitoringMetricsResponse
 */
export interface MonitoringMetricsResponse {
    /**
     * 
     * @type {Array<MonitoringMetric>}
     * @memberof MonitoringMetricsResponse
     */
    'monitoringMetrics'?: Array<MonitoringMetric>;
    /**
     * 
     * @type {MarketViewMonitoringMetric}
     * @memberof MonitoringMetricsResponse
     */
    'marketViewMonitoringMetric'?: MarketViewMonitoringMetric;
}
/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'authorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'status'?: NoteStatusEnum;
}

export const NoteStatusEnum = {
    Modified: 'MODIFIED',
    Created: 'CREATED'
} as const;

export type NoteStatusEnum = typeof NoteStatusEnum[keyof typeof NoteStatusEnum];

/**
 * 
 * @export
 * @interface NotificationCustomFields
 */
export interface NotificationCustomFields {
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'entity'?: string;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof NotificationCustomFields
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'threshold'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationCustomFields
     */
    'minThreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationCustomFields
     */
    'maxThreshold'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'investmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'category'?: NotificationCustomFieldsCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationCustomFields
     */
    'minWarningDelta'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationCustomFields
     */
    'maxWarningDelta'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationCustomFields
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationCustomFields
     */
    'errorList'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationCustomFields
     */
    'successList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'zipId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'sharedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'sharedEntityUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'sharedEntityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'grantorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'permissionLevel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationCustomFields
     */
    'isCustomMarketView'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationCustomFields
     */
    'marketViewType'?: string;
}

export const NotificationCustomFieldsCategoryEnum = {
    AssetAllocation: 'ASSET_ALLOCATION',
    Tag: 'TAG',
    WeightOnSingleInstrument: 'WEIGHT_ON_SINGLE_INSTRUMENT',
    Instrument: 'INSTRUMENT',
    AverageScore: 'AVERAGE_SCORE',
    Risk: 'RISK',
    TargetTrackingError: 'TARGET_TRACKING_ERROR',
    TargetVolatility: 'TARGET_VOLATILITY',
    Currency: 'CURRENCY',
    NumberOfInstruments: 'NUMBER_OF_INSTRUMENTS',
    LockInstrument: 'LOCK_INSTRUMENT',
    ForEach: 'FOR_EACH'
} as const;

export type NotificationCustomFieldsCategoryEnum = typeof NotificationCustomFieldsCategoryEnum[keyof typeof NotificationCustomFieldsCategoryEnum];

/**
 * 
 * @export
 * @interface NotificationGroupedDTO
 */
export interface NotificationGroupedDTO {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationGroupedDTO
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationGroupedDTO
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationGroupedDTO
     */
    'type'?: string;
    /**
     * 
     * @type {Array<NotificationInfoDTO>}
     * @memberof NotificationGroupedDTO
     */
    'subNotifications'?: Array<NotificationInfoDTO>;
}
/**
 * 
 * @export
 * @interface NotificationInfoDTO
 */
export interface NotificationInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'investmentUUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'investmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'subType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'microAc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'max'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'marketViewCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'marketViewDuration'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'sphereRegimesProbabilities'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'userRegimesProbabilities'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'threshold'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'regime'?: string;
    /**
     * 
     * @type {Array<Chip>}
     * @memberof NotificationInfoDTO
     */
    'driverLabel'?: Array<Chip>;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     */
    'outlookKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationInfoDTO
     */
    'read'?: boolean;
    /**
     * 
     * @type {NotificationCustomFields}
     * @memberof NotificationInfoDTO
     */
    'customFields'?: NotificationCustomFields;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfoDTO
     * @deprecated
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface PageDto
 */
export interface PageDto {
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PageResponseReviewTicker
 */
export interface PageResponseReviewTicker {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof PageResponseReviewTicker
     */
    'items'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Pagination}
     * @memberof PageResponseReviewTicker
     */
    'pagination'?: Pagination;
}
/**
 * 
 * @export
 * @interface PageWidgetsConfiguration
 */
export interface PageWidgetsConfiguration {
    /**
     * 
     * @type {string}
     * @memberof PageWidgetsConfiguration
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageWidgetsConfiguration
     */
    'page'?: string;
    /**
     * 
     * @type {Array<WidgetConfiguration>}
     * @memberof PageWidgetsConfiguration
     */
    'configuration'?: Array<WidgetConfiguration>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Pages = {
    Dashboard: 'DASHBOARD',
    Outlook: 'OUTLOOK',
    AssetClass: 'ASSET_CLASS',
    PortfolioDetails: 'PORTFOLIO_DETAILS',
    PortfolioEnhancement: 'PORTFOLIO_ENHANCEMENT',
    PortfolioEnhancementCurrentSs: 'PORTFOLIO_ENHANCEMENT_CURRENT_SS',
    Hmm: 'HMM',
    PortfolioReference: 'PORTFOLIO_REFERENCE',
    UniverseDetails: 'UNIVERSE_DETAILS'
} as const;

export type Pages = typeof Pages[keyof typeof Pages];


/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PaperPlaneWebhookResponseDTO
 */
export interface PaperPlaneWebhookResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'done'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'object'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'webhook_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaperPlaneWebhookResponseDTO
     */
    'file_name'?: string;
}
/**
 * 
 * @export
 * @interface PartialInvestableUniverseTicker
 */
export interface PartialInvestableUniverseTicker {
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialInvestableUniverseTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialInvestableUniverseTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof PartialInvestableUniverseTicker
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'proxyOverwriteType'?: PartialInvestableUniverseTickerProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PartialInvestableUniverseTicker
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialInvestableUniverseTicker
     */
    'inInvestmentComposition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialInvestableUniverseTicker
     */
    'inUniverseComposition'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialInvestableUniverseTicker
     */
    'identifier'?: string;
}

export const PartialInvestableUniverseTickerProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type PartialInvestableUniverseTickerProxyOverwriteTypeEnum = typeof PartialInvestableUniverseTickerProxyOverwriteTypeEnum[keyof typeof PartialInvestableUniverseTickerProxyOverwriteTypeEnum];

/**
 * 
 * @export
 * @interface PastMarketRegime
 */
export interface PastMarketRegime {
    /**
     * 
     * @type {Performance}
     * @memberof PastMarketRegime
     */
    'performance'?: Performance;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PastMarketRegime
     */
    'regimes'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PastMarketRegime
     */
    'rollingVolatility'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PastMarketRegime
     */
    'rollingReturn'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface Performance
 */
export interface Performance {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Performance
     */
    'data'?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof Performance
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PerformanceAttributionEntry
 */
export interface PerformanceAttributionEntry {
    /**
     * 
     * @type {string}
     * @memberof PerformanceAttributionEntry
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceAttributionEntry
     */
    'type'?: PerformanceAttributionEntryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PerformanceAttributionEntry
     */
    'value'?: number;
}

export const PerformanceAttributionEntryTypeEnum = {
    Allocation: 'ALLOCATION',
    Selection: 'SELECTION',
    Interaction: 'INTERACTION'
} as const;

export type PerformanceAttributionEntryTypeEnum = typeof PerformanceAttributionEntryTypeEnum[keyof typeof PerformanceAttributionEntryTypeEnum];

/**
 * 
 * @export
 * @interface PerformanceContributionEntry
 */
export interface PerformanceContributionEntry {
    /**
     * 
     * @type {string}
     * @memberof PerformanceContributionEntry
     */
    'label'?: string;
    /**
     * 
     * @type {Array<MarketViewAssetClassLabel>}
     * @memberof PerformanceContributionEntry
     */
    'marketViewAssetClassLabel'?: Array<MarketViewAssetClassLabel>;
    /**
     * 
     * @type {number}
     * @memberof PerformanceContributionEntry
     */
    'portfolioExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceContributionEntry
     */
    'benchmarkExposure'?: number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceContributionEntry
     */
    'geographicalLink'?: string;
}
/**
 * 
 * @export
 * @interface PortfolioExAnteMetric
 */
export interface PortfolioExAnteMetric {
    /**
     * 
     * @type {string}
     * @memberof PortfolioExAnteMetric
     */
    'type'?: PortfolioExAnteMetricTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PortfolioExAnteMetric
     */
    'current'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioExAnteMetric
     */
    'benchmark'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioExAnteMetric
     */
    'proposal'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioExAnteMetric
     */
    'fromUserConstraint'?: boolean;
}

export const PortfolioExAnteMetricTypeEnum = {
    Return3Y: 'RETURN_3Y',
    EfficiencyRatio3Y: 'EFFICIENCY_RATIO_3Y',
    MaxDrawdown3Y: 'MAX_DRAWDOWN_3Y',
    DiversificationRatio3Y: 'DIVERSIFICATION_RATIO_3Y',
    TrackingError3Y: 'TRACKING_ERROR_3Y',
    ParametricVar951Y: 'PARAMETRIC_VAR_95_1Y',
    ParametricVar9751Y: 'PARAMETRIC_VAR_975_1Y',
    ParametricVar991Y: 'PARAMETRIC_VAR_99_1Y',
    ParametricVar952Y: 'PARAMETRIC_VAR_95_2Y',
    ParametricVar9752Y: 'PARAMETRIC_VAR_975_2Y',
    ParametricVar992Y: 'PARAMETRIC_VAR_99_2Y',
    ParametricVar953Y: 'PARAMETRIC_VAR_95_3Y',
    ParametricVar9753Y: 'PARAMETRIC_VAR_975_3Y',
    ParametricVar993Y: 'PARAMETRIC_VAR_99_3Y',
    HistoricalVar951Y: 'HISTORICAL_VAR_95_1Y',
    HistoricalVar9751Y: 'HISTORICAL_VAR_975_1Y',
    HistoricalVar991Y: 'HISTORICAL_VAR_99_1Y',
    HistoricalVar952Y: 'HISTORICAL_VAR_95_2Y',
    HistoricalVar9752Y: 'HISTORICAL_VAR_975_2Y',
    HistoricalVar992Y: 'HISTORICAL_VAR_99_2Y',
    HistoricalVar953Y: 'HISTORICAL_VAR_95_3Y',
    HistoricalVar9753Y: 'HISTORICAL_VAR_975_3Y',
    HistoricalVar993Y: 'HISTORICAL_VAR_99_3Y',
    Volatility1Y: 'VOLATILITY_1Y',
    Volatility2Y: 'VOLATILITY_2Y',
    Volatility3Y: 'VOLATILITY_3Y'
} as const;

export type PortfolioExAnteMetricTypeEnum = typeof PortfolioExAnteMetricTypeEnum[keyof typeof PortfolioExAnteMetricTypeEnum];

/**
 * 
 * @export
 * @interface PortfolioExAnteMetricsResponse
 */
export interface PortfolioExAnteMetricsResponse {
    /**
     * 
     * @type {Array<PortfolioExAnteMetric>}
     * @memberof PortfolioExAnteMetricsResponse
     */
    'portfolioExAnteMetrics'?: Array<PortfolioExAnteMetric>;
}
/**
 * 
 * @export
 * @interface PortfolioMetric
 */
export interface PortfolioMetric {
    /**
     * 
     * @type {string}
     * @memberof PortfolioMetric
     */
    'type'?: PortfolioMetricTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PortfolioMetric
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioMetric
     */
    'benchmarkValue'?: number;
}

export const PortfolioMetricTypeEnum = {
    PerformanceSinceInception: 'PERFORMANCE_SINCE_INCEPTION',
    PerformanceYtd: 'PERFORMANCE_YTD',
    Performance1Y: 'PERFORMANCE_1Y',
    Performance6M: 'PERFORMANCE_6M',
    Performance3M: 'PERFORMANCE_3M',
    Performance1M: 'PERFORMANCE_1M',
    SortinoSinceInception: 'SORTINO_SINCE_INCEPTION',
    SortinoYtd: 'SORTINO_YTD',
    Sortino1Y: 'SORTINO_1Y',
    Sortino6M: 'SORTINO_6M',
    Sortino3M: 'SORTINO_3M',
    Sortino1M: 'SORTINO_1M',
    MaxDrawdownSinceInception: 'MAX_DRAWDOWN_SINCE_INCEPTION',
    MaxDrawdownYtd: 'MAX_DRAWDOWN_YTD',
    MaxDrawdown1Y: 'MAX_DRAWDOWN_1Y',
    MaxDrawdown6M: 'MAX_DRAWDOWN_6M',
    MaxDrawdown3M: 'MAX_DRAWDOWN_3M',
    MaxDrawdown1M: 'MAX_DRAWDOWN_1M',
    VolatilitySinceInception: 'VOLATILITY_SINCE_INCEPTION',
    VolatilityYtd: 'VOLATILITY_YTD',
    Volatility1Y: 'VOLATILITY_1Y',
    Volatility6M: 'VOLATILITY_6M',
    Volatility3M: 'VOLATILITY_3M',
    Volatility1M: 'VOLATILITY_1M',
    EfficiencyRatioSinceInception: 'EFFICIENCY_RATIO_SINCE_INCEPTION',
    EfficiencyRatioYtd: 'EFFICIENCY_RATIO_YTD',
    EfficiencyRatio1Y: 'EFFICIENCY_RATIO_1Y',
    EfficiencyRatio6M: 'EFFICIENCY_RATIO_6M',
    EfficiencyRatio3M: 'EFFICIENCY_RATIO_3M',
    EfficiencyRatio1M: 'EFFICIENCY_RATIO_1M'
} as const;

export type PortfolioMetricTypeEnum = typeof PortfolioMetricTypeEnum[keyof typeof PortfolioMetricTypeEnum];

/**
 * 
 * @export
 * @interface PortfolioMetricPreference
 */
export interface PortfolioMetricPreference {
    /**
     * 
     * @type {string}
     * @memberof PortfolioMetricPreference
     */
    'metricType'?: PortfolioMetricPreferenceMetricTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioMetricPreference
     */
    'enabled'?: boolean;
}

export const PortfolioMetricPreferenceMetricTypeEnum = {
    PerformanceSinceInception: 'PERFORMANCE_SINCE_INCEPTION',
    PerformanceYtd: 'PERFORMANCE_YTD',
    Performance1Y: 'PERFORMANCE_1Y',
    Performance6M: 'PERFORMANCE_6M',
    Performance3M: 'PERFORMANCE_3M',
    Performance1M: 'PERFORMANCE_1M',
    SortinoSinceInception: 'SORTINO_SINCE_INCEPTION',
    SortinoYtd: 'SORTINO_YTD',
    Sortino1Y: 'SORTINO_1Y',
    Sortino6M: 'SORTINO_6M',
    Sortino3M: 'SORTINO_3M',
    Sortino1M: 'SORTINO_1M',
    MaxDrawdownSinceInception: 'MAX_DRAWDOWN_SINCE_INCEPTION',
    MaxDrawdownYtd: 'MAX_DRAWDOWN_YTD',
    MaxDrawdown1Y: 'MAX_DRAWDOWN_1Y',
    MaxDrawdown6M: 'MAX_DRAWDOWN_6M',
    MaxDrawdown3M: 'MAX_DRAWDOWN_3M',
    MaxDrawdown1M: 'MAX_DRAWDOWN_1M',
    VolatilitySinceInception: 'VOLATILITY_SINCE_INCEPTION',
    VolatilityYtd: 'VOLATILITY_YTD',
    Volatility1Y: 'VOLATILITY_1Y',
    Volatility6M: 'VOLATILITY_6M',
    Volatility3M: 'VOLATILITY_3M',
    Volatility1M: 'VOLATILITY_1M',
    EfficiencyRatioSinceInception: 'EFFICIENCY_RATIO_SINCE_INCEPTION',
    EfficiencyRatioYtd: 'EFFICIENCY_RATIO_YTD',
    EfficiencyRatio1Y: 'EFFICIENCY_RATIO_1Y',
    EfficiencyRatio6M: 'EFFICIENCY_RATIO_6M',
    EfficiencyRatio3M: 'EFFICIENCY_RATIO_3M',
    EfficiencyRatio1M: 'EFFICIENCY_RATIO_1M'
} as const;

export type PortfolioMetricPreferenceMetricTypeEnum = typeof PortfolioMetricPreferenceMetricTypeEnum[keyof typeof PortfolioMetricPreferenceMetricTypeEnum];

/**
 * 
 * @export
 * @interface PortfolioMetricsOrderingResponse
 */
export interface PortfolioMetricsOrderingResponse {
    /**
     * 
     * @type {Array<PortfolioMetricPreference>}
     * @memberof PortfolioMetricsOrderingResponse
     */
    'portfolioMetricPreferences'?: Array<PortfolioMetricPreference>;
}
/**
 * 
 * @export
 * @interface PortfolioMetricsResponse
 */
export interface PortfolioMetricsResponse {
    /**
     * 
     * @type {Array<PortfolioMetric>}
     * @memberof PortfolioMetricsResponse
     */
    'portfolioMetrics'?: Array<PortfolioMetric>;
}
/**
 * 
 * @export
 * @interface PortfolioOrUniverseStatusChange
 */
export interface PortfolioOrUniverseStatusChange {
    /**
     * 
     * @type {string}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'portfolioName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'portfolioUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'portfolioType'?: string;
    /**
     * 
     * @type {Array<AlertDto>}
     * @memberof PortfolioOrUniverseStatusChange
     */
    'breachedAlerts'?: Array<AlertDto>;
}
/**
 * 
 * @export
 * @interface PositioningDrivers
 */
export interface PositioningDrivers {
    /**
     * 
     * @type {number}
     * @memberof PositioningDrivers
     */
    'mdotm_trace'?: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningDrivers
     */
    'return'?: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningDrivers
     */
    'volatility'?: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningDrivers
     */
    'diversification'?: number;
    /**
     * 
     * @type {string}
     * @memberof PositioningDrivers
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PositioningIndicator
 */
export interface PositioningIndicator {
    /**
     * 
     * @type {string}
     * @memberof PositioningIndicator
     */
    'assetClass'?: PositioningIndicatorAssetClassEnum;
    /**
     * 
     * @type {MarketViewMicroAssetClasses}
     * @memberof PositioningIndicator
     */
    'microAssetClass'?: MarketViewMicroAssetClasses;
    /**
     * 
     * @type {number}
     * @memberof PositioningIndicator
     */
    'defaultDynamicBeta'?: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningIndicator
     */
    'defaultPositioning'?: number;
    /**
     * 
     * @type {number}
     * @memberof PositioningIndicator
     */
    'userPositioning'?: number;
    /**
     * 
     * @type {string}
     * @memberof PositioningIndicator
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof PositioningIndicator
     */
    'marketMicroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PositioningIndicator
     */
    'marketAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PositioningIndicator
     */
    'marketGeography'?: string;
}

export const PositioningIndicatorAssetClassEnum = {
    Equity: 'EQUITY',
    FixedIncome: 'FIXED_INCOME',
    Commodities: 'COMMODITIES',
    Alternative: 'ALTERNATIVE',
    MoneyMarket: 'MONEY_MARKET',
    Currency: 'CURRENCY'
} as const;

export type PositioningIndicatorAssetClassEnum = typeof PositioningIndicatorAssetClassEnum[keyof typeof PositioningIndicatorAssetClassEnum];

/**
 * 
 * @export
 * @interface PositioningIndicators
 */
export interface PositioningIndicators {
    /**
     * 
     * @type {Array<PositioningIndicator>}
     * @memberof PositioningIndicators
     */
    'positioningIndicators'?: Array<PositioningIndicator>;
}
/**
 * 
 * @export
 * @interface PreAllocatorStepValues
 */
export interface PreAllocatorStepValues {
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'allocationInFunds'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'deltaAllocationInFunds'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'usEquityProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'euEquityProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'japanEquityProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'emEquityProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'euFixedIncomeProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'usFixedIncomeProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'otherProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'usEquityBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'euEquityBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'japanEquityBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'emEquityBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'euFixedIncomeBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'usFixedIncomeBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'otherBetaWeightedProxyExposure'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'weightedBeta'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'deltaFromClientEnhancement'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreAllocatorStepValues
     */
    'clientFundPortfolioFunctor'?: number;
}
/**
 * 
 * @export
 * @interface ProxyPreferenceTicker
 */
export interface ProxyPreferenceTicker {
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProxyPreferenceTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProxyPreferenceTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof ProxyPreferenceTicker
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'proxyOverwriteType'?: ProxyPreferenceTickerProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProxyPreferenceTicker
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'descriptionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'descriptionStatus'?: ProxyPreferenceTickerDescriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'descriptionCreator'?: ProxyPreferenceTickerDescriptionCreatorEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'commentaryLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPreferenceTicker
     */
    'identifier'?: string;
}

export const ProxyPreferenceTickerProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type ProxyPreferenceTickerProxyOverwriteTypeEnum = typeof ProxyPreferenceTickerProxyOverwriteTypeEnum[keyof typeof ProxyPreferenceTickerProxyOverwriteTypeEnum];
export const ProxyPreferenceTickerDescriptionStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type ProxyPreferenceTickerDescriptionStatusEnum = typeof ProxyPreferenceTickerDescriptionStatusEnum[keyof typeof ProxyPreferenceTickerDescriptionStatusEnum];
export const ProxyPreferenceTickerDescriptionCreatorEnum = {
    User: 'USER',
    Sphere: 'SPHERE'
} as const;

export type ProxyPreferenceTickerDescriptionCreatorEnum = typeof ProxyPreferenceTickerDescriptionCreatorEnum[keyof typeof ProxyPreferenceTickerDescriptionCreatorEnum];

/**
 * 
 * @export
 * @interface ProxyPreferencesEditRequest
 */
export interface ProxyPreferencesEditRequest {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof ProxyPreferencesEditRequest
     */
    'proxyPreferences'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface ProxyPreferencesResponse
 */
export interface ProxyPreferencesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProxyPreferencesResponse
     */
    'editable'?: boolean;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof ProxyPreferencesResponse
     */
    'proxyPreferences'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface ProxyTicker
 */
export interface ProxyTicker {
    /**
     * 
     * @type {number}
     * @memberof ProxyTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'platformName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'macroAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'geography'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'microGeography'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProxyTicker
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface RealizedSyntheticMetrics
 */
export interface RealizedSyntheticMetrics {
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatility3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatility6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatilityYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatility1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performance1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performance1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performanceMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatilityMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performance3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performance6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performanceSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'performanceYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'volatilitySinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatioSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatioYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatio1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatio6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatio3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatio1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortinoSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortinoYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortino1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortino6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortino3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortino1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdownSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdownYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdown1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdown6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdown3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdown1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'sortinoMTD'?: number;
    /**
     * 
     * @type {RealizedSyntheticMetrics}
     * @memberof RealizedSyntheticMetrics
     */
    'realizedSyntheticMetrics'?: RealizedSyntheticMetrics;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'efficiencyRatioMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedSyntheticMetrics
     */
    'maxDrawdownMTD'?: number;
}
/**
 * 
 * @export
 * @interface ReferenceUniverse
 */
export interface ReferenceUniverse {
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverse
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverse
     */
    'date'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof ReferenceUniverse
     */
    'universeComposition'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface ReferenceUniverseDetails
 */
export interface ReferenceUniverseDetails {
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseDetails
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseDetails
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof ReferenceUniverseDetails
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof ReferenceUniverseDetails
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseDetails
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseDetails
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {Array<InvestmentMinInfo>}
     * @memberof ReferenceUniverseDetails
     */
    'referralInvestments'?: Array<InvestmentMinInfo>;
    /**
     * 
     * @type {RichAcl}
     * @memberof ReferenceUniverseDetails
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof ReferenceUniverseDetails
     */
    'universeComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof ReferenceUniverseDetails
     */
    'uploadComposition'?: Array<ReviewTicker>;
    /**
     * 
     * @type {Array<UploadError>}
     * @memberof ReferenceUniverseDetails
     */
    'uploadErrors'?: Array<UploadError>;
    /**
     * 
     * @type {number}
     * @memberof ReferenceUniverseDetails
     */
    'nofPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceUniverseDetails
     */
    'nofInstruments'?: number;
}
/**
 * 
 * @export
 * @interface ReferenceUniverseListEntry
 */
export interface ReferenceUniverseListEntry {
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseListEntry
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseListEntry
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseListEntry
     */
    'name'?: string;
    /**
     * 
     * @type {InvestmentActions}
     * @memberof ReferenceUniverseListEntry
     */
    'action'?: InvestmentActions;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof ReferenceUniverseListEntry
     */
    'status'?: InvestmentStatuses;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseListEntry
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceUniverseListEntry
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {Array<InvestmentMinInfo>}
     * @memberof ReferenceUniverseListEntry
     */
    'referralInvestments'?: Array<InvestmentMinInfo>;
    /**
     * 
     * @type {RichAcl}
     * @memberof ReferenceUniverseListEntry
     */
    'richAcl'?: RichAcl;
    /**
     * 
     * @type {number}
     * @memberof ReferenceUniverseListEntry
     */
    'nofPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceUniverseListEntry
     */
    'nofInstruments'?: number;
}
/**
 * 
 * @export
 * @interface RegimeBySector
 */
export interface RegimeBySector {
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'industrials'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'consumerDiscretionary'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'consumerStaples'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'utilities'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'materials'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'financials'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'communicationsServices'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'healthCare'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeBySector
     */
    'informationTechnology'?: number;
}
/**
 * 
 * @export
 * @interface RegimeCounter
 */
export interface RegimeCounter {
    /**
     * 
     * @type {number}
     * @memberof RegimeCounter
     */
    'growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeCounter
     */
    'lateralPhase'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeCounter
     */
    'highStress'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegimeCounter
     */
    'uncertain'?: number;
}
/**
 * 
 * @export
 * @interface RegimeTransitionMatrices
 */
export interface RegimeTransitionMatrices {
    /**
     * 
     * @type {HistoricalTransition}
     * @memberof RegimeTransitionMatrices
     */
    'historicalTransition'?: HistoricalTransition;
    /**
     * 
     * @type {ConditionalHistoricalTransition}
     * @memberof RegimeTransitionMatrices
     */
    'conditionalHistoricalTransition'?: ConditionalHistoricalTransition;
}
/**
 * 
 * @export
 * @interface Regimes
 */
export interface Regimes {
    /**
     * 
     * @type {number}
     * @memberof Regimes
     */
    'a'?: number;
    /**
     * 
     * @type {number}
     * @memberof Regimes
     */
    'b'?: number;
    /**
     * 
     * @type {number}
     * @memberof Regimes
     */
    'c'?: number;
}
/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ReportDto
     */
    'synthetic_category'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ReportDto
     */
    'synthetic_value'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ReportDto
     */
    'daily_category'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReportDto
     */
    'daily_value'?: { [key: string]: { [key: string]: number; }; };
}
/**
 * 
 * @export
 * @interface ReportsModel
 */
export interface ReportsModel {
    /**
     * 
     * @type {SyntheticMetrics}
     * @memberof ReportsModel
     */
    'syntheticMetrics'?: SyntheticMetrics;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof ReportsModel
     * @deprecated
     */
    'currencyComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof ReportsModel
     */
    'proxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof ReportsModel
     */
    'proxyHedgedComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof ReportsModel
     */
    'proxyFlagshipComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof ReportsModel
     */
    'compositionVolatilityContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof ReportsModel
     */
    'realizedVolatilityContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {{ [key: string]: Array<ContributionEntry>; }}
     * @memberof ReportsModel
     */
    'realizedPerformanceContribution'?: { [key: string]: Array<ContributionEntry>; };
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof ReportsModel
     */
    'performance'?: Array<Array<number>>;
    /**
     * 
     * @type {{ [key: string]: BenchmarkInvestmentReports; }}
     * @memberof ReportsModel
     */
    'benchmarksInvestmentReports'?: { [key: string]: BenchmarkInvestmentReports; };
    /**
     * 
     * @type {Array<FactorValue>}
     * @memberof ReportsModel
     */
    'factors'?: Array<FactorValue>;
    /**
     * 
     * @type {ExplainabilityDetails}
     * @memberof ReportsModel
     */
    'explainabilityDetails'?: ExplainabilityDetails;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ReportsModel
     */
    'marketViewExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ReportsModel
     */
    'marketViewFullExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<MarketViewTickerBreakdownEntry>}
     * @memberof ReportsModel
     */
    'marketViewTickerBreakdown'?: Array<MarketViewTickerBreakdownEntry>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ReportsModel
     */
    'assetClassConstraintsExposure'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<AssetClassTickerBreakdownEntry>}
     * @memberof ReportsModel
     */
    'macroAssetClassTickerBreakdown'?: Array<AssetClassTickerBreakdownEntry>;
}
/**
 * 
 * @export
 * @interface Return
 */
export interface Return {
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    'returnYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    'return1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    'return3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    'return6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    'return1Y'?: number;
}
/**
 * 
 * @export
 * @interface ReturnAnalysisBandsDto
 */
export interface ReturnAnalysisBandsDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnAnalysisBandsDto
     */
    'type'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReturnAnalysisBandsDto
     */
    'prevBands'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReturnAnalysisBandsDto
     */
    'bands'?: Array<number>;
    /**
     * 
     * @type {AssetClassRegime}
     * @memberof ReturnAnalysisBandsDto
     */
    'regime'?: AssetClassRegime;
}
/**
 * 
 * @export
 * @interface ReturnAnalysisDto
 */
export interface ReturnAnalysisDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReturnAnalysisDto
     */
    'prevPerformance'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReturnAnalysisDto
     */
    'performance'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReturnAnalysisDto
     */
    'prevDates'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReturnAnalysisDto
     */
    'dates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReturnAnalysisDto
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ReviewTicker
 */
export interface ReviewTicker {
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof ReviewTicker
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'proxyOverwriteType'?: ReviewTickerProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'previousWeight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewTicker
     */
    'needsCustomProxy'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'linkedPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewTicker
     */
    'linkedUniverses'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'granularity'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewTicker
     */
    'delisted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'descriptionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'descriptionStatus'?: ReviewTickerDescriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'descriptionCreator'?: ReviewTickerDescriptionCreatorEnum;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     * @deprecated
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     * @deprecated
     */
    'commentaryStatus'?: ReviewTickerCommentaryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     * @deprecated
     */
    'commentaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     * @deprecated
     */
    'commentaryCreator'?: ReviewTickerCommentaryCreatorEnum;
    /**
     * 
     * @type {string}
     * @memberof ReviewTicker
     */
    'identifier'?: string;
}

export const ReviewTickerProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type ReviewTickerProxyOverwriteTypeEnum = typeof ReviewTickerProxyOverwriteTypeEnum[keyof typeof ReviewTickerProxyOverwriteTypeEnum];
export const ReviewTickerDescriptionStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type ReviewTickerDescriptionStatusEnum = typeof ReviewTickerDescriptionStatusEnum[keyof typeof ReviewTickerDescriptionStatusEnum];
export const ReviewTickerDescriptionCreatorEnum = {
    User: 'USER',
    Sphere: 'SPHERE'
} as const;

export type ReviewTickerDescriptionCreatorEnum = typeof ReviewTickerDescriptionCreatorEnum[keyof typeof ReviewTickerDescriptionCreatorEnum];
export const ReviewTickerCommentaryStatusEnum = {
    Calculating: 'CALCULATING',
    Completed: 'COMPLETED',
    Empty: 'EMPTY',
    Error: 'ERROR',
    MissingData: 'MISSING_DATA'
} as const;

export type ReviewTickerCommentaryStatusEnum = typeof ReviewTickerCommentaryStatusEnum[keyof typeof ReviewTickerCommentaryStatusEnum];
export const ReviewTickerCommentaryCreatorEnum = {
    User: 'USER',
    Sphere: 'SPHERE'
} as const;

export type ReviewTickerCommentaryCreatorEnum = typeof ReviewTickerCommentaryCreatorEnum[keyof typeof ReviewTickerCommentaryCreatorEnum];

/**
 * 
 * @export
 * @interface RichAccessControl
 */
export interface RichAccessControl {
    /**
     * 
     * @type {string}
     * @memberof RichAccessControl
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<AccessPermission>}
     * @memberof RichAccessControl
     */
    'permissions'?: Array<AccessPermission>;
    /**
     * 
     * @type {string}
     * @memberof RichAccessControl
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichAccessControl
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichAccessControl
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface RichAcl
 */
export interface RichAcl {
    /**
     * 
     * @type {Array<AccessPermission>}
     * @memberof RichAcl
     */
    'currentUserPermissions'?: Array<AccessPermission>;
    /**
     * 
     * @type {Array<RichAccessControl>}
     * @memberof RichAcl
     */
    'acl'?: Array<RichAccessControl>;
}
/**
 * 
 * @export
 * @interface RichTicker
 */
export interface RichTicker {
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof RichTicker
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof RichTicker
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof RichTicker
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'proxyOverwriteType'?: RichTickerProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RichTicker
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTicker
     */
    'identifier'?: string;
}

export const RichTickerProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type RichTickerProxyOverwriteTypeEnum = typeof RichTickerProxyOverwriteTypeEnum[keyof typeof RichTickerProxyOverwriteTypeEnum];

/**
 * 
 * @export
 * @interface RichTickerWithNonTradableCause
 */
export interface RichTickerWithNonTradableCause {
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'isin'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'instrument'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'assetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'microAssetClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof RichTickerWithNonTradableCause
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof RichTickerWithNonTradableCause
     */
    'tickerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'tagLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'alias'?: string;
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof RichTickerWithNonTradableCause
     */
    'proxies'?: Array<IndexTicker>;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'proxyOverwriteType'?: RichTickerWithNonTradableCauseProxyOverwriteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RichTickerWithNonTradableCause
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'customAttributes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RichTickerWithNonTradableCause
     */
    'notTradableCauses'?: Array<RichTickerWithNonTradableCauseNotTradableCausesEnum>;
    /**
     * 
     * @type {string}
     * @memberof RichTickerWithNonTradableCause
     */
    'identifier'?: string;
}

export const RichTickerWithNonTradableCauseProxyOverwriteTypeEnum = {
    Live: 'LIVE',
    CustomHistorical: 'CUSTOM_HISTORICAL',
    HiddenLive: 'HIDDEN_LIVE',
    PortfolioMixed: 'PORTFOLIO_MIXED'
} as const;

export type RichTickerWithNonTradableCauseProxyOverwriteTypeEnum = typeof RichTickerWithNonTradableCauseProxyOverwriteTypeEnum[keyof typeof RichTickerWithNonTradableCauseProxyOverwriteTypeEnum];
export const RichTickerWithNonTradableCauseNotTradableCausesEnum = {
    MissingCloses: 'MISSING_CLOSES',
    MissingRequiredQualitiesForAutoproxy: 'MISSING_REQUIRED_QUALITIES_FOR_AUTOPROXY',
    MissingPrimaryCurrency: 'MISSING_PRIMARY_CURRENCY',
    Delisted: 'DELISTED',
    NoResponseFromF2Db: 'NO_RESPONSE_FROM_F2DB',
    NotHandledByMpu: 'NOT_HANDLED_BY_MPU',
    NotSupportedCurrency: 'NOT_SUPPORTED_CURRENCY'
} as const;

export type RichTickerWithNonTradableCauseNotTradableCausesEnum = typeof RichTickerWithNonTradableCauseNotTradableCausesEnum[keyof typeof RichTickerWithNonTradableCauseNotTradableCausesEnum];

/**
 * 
 * @export
 * @interface RiskConstraint
 */
export interface RiskConstraint {
    /**
     * 
     * @type {number}
     * @memberof RiskConstraint
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskConstraint
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskConstraint
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskConstraint
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {RiskConstraintType}
     * @memberof RiskConstraint
     */
    'type'?: RiskConstraintType;
    /**
     * 
     * @type {boolean}
     * @memberof RiskConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RiskConstraint
     */
    'var'?: boolean;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof RiskConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RiskConstraintType = {
    ParametricVar951Y: 'PARAMETRIC_VAR_95_1Y',
    ParametricVar9751Y: 'PARAMETRIC_VAR_975_1Y',
    ParametricVar991Y: 'PARAMETRIC_VAR_99_1Y',
    ParametricVar952Y: 'PARAMETRIC_VAR_95_2Y',
    ParametricVar9752Y: 'PARAMETRIC_VAR_975_2Y',
    ParametricVar992Y: 'PARAMETRIC_VAR_99_2Y',
    ParametricVar953Y: 'PARAMETRIC_VAR_95_3Y',
    ParametricVar9753Y: 'PARAMETRIC_VAR_975_3Y',
    ParametricVar993Y: 'PARAMETRIC_VAR_99_3Y',
    HistoricalVar951Y: 'HISTORICAL_VAR_95_1Y',
    HistoricalVar9751Y: 'HISTORICAL_VAR_975_1Y',
    HistoricalVar991Y: 'HISTORICAL_VAR_99_1Y',
    HistoricalVar952Y: 'HISTORICAL_VAR_95_2Y',
    HistoricalVar9752Y: 'HISTORICAL_VAR_975_2Y',
    HistoricalVar992Y: 'HISTORICAL_VAR_99_2Y',
    HistoricalVar953Y: 'HISTORICAL_VAR_95_3Y',
    HistoricalVar9753Y: 'HISTORICAL_VAR_975_3Y',
    HistoricalVar993Y: 'HISTORICAL_VAR_99_3Y',
    Volatility1Y: 'VOLATILITY_1Y',
    Volatility2Y: 'VOLATILITY_2Y',
    Volatility3Y: 'VOLATILITY_3Y'
} as const;

export type RiskConstraintType = typeof RiskConstraintType[keyof typeof RiskConstraintType];


/**
 * 
 * @export
 * @interface RiskConstraints
 */
export interface RiskConstraints {
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical951YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical952YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical953YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical971YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical972YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical973YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical991YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical992YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'historical993YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric951YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric952YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric953YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric971YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric972YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric973YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric991YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric992YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'parametric993YVarConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'volatility1YConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'volatility2YConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraints
     */
    'volatility3YConstraint'?: RiskConstraint;
    /**
     * 
     * @type {RiskConstraintType}
     * @memberof RiskConstraints
     */
    'selectedRiskConstraintType'?: RiskConstraintType;
    /**
     * 
     * @type {ValidationOperation}
     * @memberof RiskConstraints
     */
    'pendingValidationOperation'?: ValidationOperation;
    /**
     * 
     * @type {MaxTrackingError}
     * @memberof RiskConstraints
     */
    'maxTrackingError'?: MaxTrackingError;
}
/**
 * 
 * @export
 * @interface RiskConstraintsResponse
 */
export interface RiskConstraintsResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof RiskConstraintsResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RiskConstraintsResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraintsResponse
     */
    'selectedRiskConstraint'?: RiskConstraint;
    /**
     * 
     * @type {MaxTrackingErrorDTO}
     * @memberof RiskConstraintsResponse
     */
    'maxTrackingError'?: MaxTrackingErrorDTO;
}
/**
 * 
 * @export
 * @interface RiskConstraintsSaveRequest
 */
export interface RiskConstraintsSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof RiskConstraintsSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {RiskConstraint}
     * @memberof RiskConstraintsSaveRequest
     */
    'selectedRiskConstraint'?: RiskConstraint;
    /**
     * 
     * @type {MaxTrackingErrorDTO}
     * @memberof RiskConstraintsSaveRequest
     */
    'maxTrackingError'?: MaxTrackingErrorDTO;
}
/**
 * 
 * @export
 * @interface Rounding
 */
export interface Rounding {
    /**
     * 
     * @type {number}
     * @memberof Rounding
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rounding
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rounding
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rounding
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rounding
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof Rounding
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface RoundingValuesStepValues
 */
export interface RoundingValuesStepValues {
    /**
     * 
     * @type {number}
     * @memberof RoundingValuesStepValues
     */
    'deltaAllocationInFunds'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RoundingValuesStepValues
     */
    'fundsExposureInAssetClass'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof RoundingValuesStepValues
     */
    'activeConstraintAttribution'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoundingValuesStepValues
     */
    'deltaFromClientEnhancement'?: number;
}
/**
 * 
 * @export
 * @interface RunResponse
 */
export interface RunResponse {
    /**
     * 
     * @type {string}
     * @memberof RunResponse
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof RunResponse
     */
    'investmentName'?: string;
}
/**
 * 
 * @export
 * @interface ScenarioDescription
 */
export interface ScenarioDescription {
    /**
     * 
     * @type {AnnualizedDistribution}
     * @memberof ScenarioDescription
     */
    'annualizedVolatilityDistribution'?: AnnualizedDistribution;
    /**
     * 
     * @type {AnnualizedDistribution}
     * @memberof ScenarioDescription
     */
    'annualizedReturnDistribution'?: AnnualizedDistribution;
}
/**
 * 
 * @export
 * @interface ScenariosDescription
 */
export interface ScenariosDescription {
    /**
     * 
     * @type {ScenarioDescription}
     * @memberof ScenariosDescription
     */
    'growth'?: ScenarioDescription;
    /**
     * 
     * @type {ScenarioDescription}
     * @memberof ScenariosDescription
     */
    'lateralPhase'?: ScenarioDescription;
    /**
     * 
     * @type {ScenarioDescription}
     * @memberof ScenariosDescription
     */
    'highStress'?: ScenarioDescription;
}
/**
 * 
 * @export
 * @interface ScoreNamesResponse
 */
export interface ScoreNamesResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScoreNamesResponse
     */
    'scoreNames'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ScoresConstraint
 */
export interface ScoresConstraint {
    /**
     * 
     * @type {string}
     * @memberof ScoresConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScoresConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScoresConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScoresConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScoresConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScoresConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof ScoresConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {number}
     * @memberof ScoresConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScoresConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScoresConstraint
     */
    'scoreIdentifiers'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ScoresConstraint
     */
    'considerNullAsZero'?: boolean;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof ScoresConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface Sector
 */
export interface Sector {
    /**
     * 
     * @type {string}
     * @memberof Sector
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof Sector
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof Sector
     */
    'why'?: string;
    /**
     * 
     * @type {Array<Forecast>}
     * @memberof Sector
     */
    'forecast'?: Array<Forecast>;
    /**
     * 
     * @type {AcMetrics}
     * @memberof Sector
     */
    'ac_metrics'?: AcMetrics;
    /**
     * 
     * @type {PositioningDrivers}
     * @memberof Sector
     */
    'positioning_drivers'?: PositioningDrivers;
    /**
     * 
     * @type {Array<number>}
     * @memberof Sector
     */
    'return_analysis_bands'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Sector
     */
    'mdotm_trace'?: number;
}
/**
 * 
 * @export
 * @interface SectorGlobalRisk
 */
export interface SectorGlobalRisk {
    /**
     * 
     * @type {AssetClassIdentifier}
     * @memberof SectorGlobalRisk
     */
    'assetClassIdentifier'?: AssetClassIdentifier;
    /**
     * 
     * @type {number}
     * @memberof SectorGlobalRisk
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectorGlobalRisk
     */
    'valueOnMap'?: number;
    /**
     * 
     * @type {Array<SubSectorGlobalRisk>}
     * @memberof SectorGlobalRisk
     */
    'subSectors'?: Array<SubSectorGlobalRisk>;
}
/**
 * 
 * @export
 * @interface Sectors
 */
export interface Sectors {
    /**
     * 
     * @type {Array<string>}
     * @memberof Sectors
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'energy'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'financials'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'industrials'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'consumer_staples'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'communication_services'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'information_technology'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'consumer_discretionary'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'health_care'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'materials'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'utilities'?: EquityIndicatorsSector;
    /**
     * 
     * @type {EquityIndicatorsSector}
     * @memberof Sectors
     */
    'real_estate'?: EquityIndicatorsSector;
}
/**
 * 
 * @export
 * @interface SectorsBreakDown
 */
export interface SectorsBreakDown {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'industrials'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'consumerDiscretionary'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'consumerStaples'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'utilities'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'materials'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'financials'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'communicationsServices'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'healthCare'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'energy'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SectorsBreakDown
     */
    'informationTechnology'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface SelectableAllocationConstraintsResponse
 */
export interface SelectableAllocationConstraintsResponse {
    /**
     * 
     * @type {Array<AssetClass>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableAssetClasses'?: Array<AssetClass>;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableInstruments'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableTags'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableScores'?: Array<string>;
    /**
     * 
     * @type {Array<Currencies>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableCurrencies'?: Array<Currencies>;
    /**
     * 
     * @type {Array<ConstrainedTicker>}
     * @memberof SelectableAllocationConstraintsResponse
     */
    'selectableLockableInstruments'?: Array<ConstrainedTicker>;
}
/**
 * 
 * @export
 * @interface SelectableBasket
 */
export interface SelectableBasket {
    /**
     * 
     * @type {string}
     * @memberof SelectableBasket
     */
    'basketName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectableBasket
     */
    'basketIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectableBasket
     */
    'basketType'?: SelectableBasketBasketTypeEnum;
}

export const SelectableBasketBasketTypeEnum = {
    Investment: 'INVESTMENT',
    TargetInvestment: 'TARGET_INVESTMENT',
    Universe: 'UNIVERSE',
    Benchmark: 'BENCHMARK',
    Template: 'TEMPLATE'
} as const;

export type SelectableBasketBasketTypeEnum = typeof SelectableBasketBasketTypeEnum[keyof typeof SelectableBasketBasketTypeEnum];

/**
 * 
 * @export
 * @interface SelectableInvestableUniverse
 */
export interface SelectableInvestableUniverse {
    /**
     * 
     * @type {string}
     * @memberof SelectableInvestableUniverse
     */
    'universeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectableInvestableUniverse
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SelectableInvestableUniverse
     */
    'available'?: boolean;
    /**
     * 
     * @type {InvestmentStatuses}
     * @memberof SelectableInvestableUniverse
     */
    'status'?: InvestmentStatuses;
}
/**
 * 
 * @export
 * @interface SelectableMainInfoResponse
 */
export interface SelectableMainInfoResponse {
    /**
     * 
     * @type {Array<Currencies>}
     * @memberof SelectableMainInfoResponse
     */
    'availableCurrencies'?: Array<Currencies>;
    /**
     * 
     * @type {Array<InvestmentBenchmarkDTO>}
     * @memberof SelectableMainInfoResponse
     */
    'availablePrimaryBenchmarks'?: Array<InvestmentBenchmarkDTO>;
    /**
     * 
     * @type {Array<InvestmentReferenceDTO>}
     * @memberof SelectableMainInfoResponse
     */
    'availableInvestmentReferences'?: Array<InvestmentReferenceDTO>;
}
/**
 * 
 * @export
 * @interface SelectableMarketViewAssetClassesResponse
 */
export interface SelectableMarketViewAssetClassesResponse {
    /**
     * 
     * @type {Array<MarketViewMicroAssetClasses>}
     * @memberof SelectableMarketViewAssetClassesResponse
     */
    'selectableAssetClasses'?: Array<MarketViewMicroAssetClasses>;
}
/**
 * 
 * @export
 * @interface SelectableMarketViewScenarioIdsResponse
 */
export interface SelectableMarketViewScenarioIdsResponse {
    /**
     * 
     * @type {Array<MarketScenarioMinInfo>}
     * @memberof SelectableMarketViewScenarioIdsResponse
     */
    'marketScenarioIds'?: Array<MarketScenarioMinInfo>;
}
/**
 * 
 * @export
 * @interface SelectableMarketViewsResponse
 */
export interface SelectableMarketViewsResponse {
    /**
     * 
     * @type {Array<MarketScenarioMinInfo>}
     * @memberof SelectableMarketViewsResponse
     */
    'returnsVolatilityDefaultScenarios'?: Array<MarketScenarioMinInfo>;
    /**
     * 
     * @type {Array<MarketScenarioMinInfo>}
     * @memberof SelectableMarketViewsResponse
     */
    'positioningDefaultScenarios'?: Array<MarketScenarioMinInfo>;
    /**
     * 
     * @type {Array<MarketViewListEntry>}
     * @memberof SelectableMarketViewsResponse
     */
    'userScenarios'?: Array<MarketViewListEntry>;
    /**
     * 
     * @type {Array<MarketScenarioMinInfo>}
     * @memberof SelectableMarketViewsResponse
     */
    'customDefaultScenarios'?: Array<MarketScenarioMinInfo>;
}
/**
 * 
 * @export
 * @interface SelectableProxiesResponse
 */
export interface SelectableProxiesResponse {
    /**
     * 
     * @type {Array<IndexTicker>}
     * @memberof SelectableProxiesResponse
     */
    'availableProxies'?: Array<IndexTicker>;
}
/**
 * 
 * @export
 * @interface SelectableRiskConstraintsResponse
 */
export interface SelectableRiskConstraintsResponse {
    /**
     * 
     * @type {Array<RiskConstraint>}
     * @memberof SelectableRiskConstraintsResponse
     */
    'availableVarConstraints'?: Array<RiskConstraint>;
    /**
     * 
     * @type {Array<RiskConstraint>}
     * @memberof SelectableRiskConstraintsResponse
     */
    'availableVolatilityConstraints'?: Array<RiskConstraint>;
    /**
     * 
     * @type {Array<InvestmentReferenceDTO>}
     * @memberof SelectableRiskConstraintsResponse
     */
    'availableInvestmentReferences'?: Array<InvestmentReferenceDTO>;
    /**
     * 
     * @type {MaxTrackingErrorDTO}
     * @memberof SelectableRiskConstraintsResponse
     */
    'availableTargetTrackingError'?: MaxTrackingErrorDTO;
}
/**
 * 
 * @export
 * @interface SelectableStrategyConstraintsResponse
 */
export interface SelectableStrategyConstraintsResponse {
    /**
     * 
     * @type {TargetMaxTurnover}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'targetMaxTurnover'?: TargetMaxTurnover;
    /**
     * 
     * @type {TargetMinOperationWeight}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'targetMinOperationWeight'?: TargetMinOperationWeight;
    /**
     * 
     * @type {TargetTransactionCostsInBps}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'targetTransactionCostsInBps'?: TargetTransactionCostsInBps;
    /**
     * 
     * @type {InstrumentMinWeight}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'instrumentMinWeight'?: InstrumentMinWeight;
    /**
     * 
     * @type {MaxNumberOfInstruments}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'maxNumberOfInstruments'?: MaxNumberOfInstruments;
    /**
     * 
     * @type {MinNumberOfInstruments}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'minNumberOfInstruments'?: MinNumberOfInstruments;
    /**
     * 
     * @type {Rounding}
     * @memberof SelectableStrategyConstraintsResponse
     */
    'rounding'?: Rounding;
}
/**
 * 
 * @export
 * @interface ServiceError
 */
export interface ServiceError {
    /**
     * 
     * @type {number}
     * @memberof ServiceError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceError
     */
    'traceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceError
     */
    'spanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceError
     */
    'instanceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceError
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceError
     */
    'params'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceError
     */
    'link'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceError
     */
    'debug'?: { [key: string]: string; };
    /**
     * 
     * @type {ServiceError}
     * @memberof ServiceError
     */
    'previous'?: ServiceError;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ServiceType = {
    Reports: 'REPORTS',
    Investments: 'INVESTMENTS',
    Signals: 'SIGNALS',
    Expert: 'EXPERT',
    CustomReportCb1: 'CUSTOM_REPORT_CB1',
    HelpCenter: 'HELP_CENTER',
    ExpertView: 'EXPERT_VIEW',
    CustomQualities: 'CUSTOM_QUALITIES',
    Import: 'IMPORT',
    Export: 'EXPORT',
    NotificationCenter: 'NOTIFICATION_CENTER',
    AskSphere: 'ASK_SPHERE',
    ShowSphereStrategicalMarketViewsOnly: 'SHOW_SPHERE_STRATEGICAL_MARKET_VIEWS_ONLY',
    CustomMarketViewSettingsEditor: 'CUSTOM_MARKET_VIEW_SETTINGS_EDITOR',
    CustomMarketViewCommentaryEditor: 'CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR',
    MandateTypeSelectionVisible: 'MANDATE_TYPE_SELECTION_VISIBLE',
    MixedPortfolios: 'MIXED_PORTFOLIOS',
    NumberOfInstrumentsCheckBypass: 'NUMBER_OF_INSTRUMENTS_CHECK_BYPASS',
    PortfolioStudioCommentaryTab: 'PORTFOLIO_STUDIO_COMMENTARY_TAB',
    InvestmentsReportTemplateEditor: 'INVESTMENTS_REPORT_TEMPLATE_EDITOR',
    CommentaryBuilder: 'COMMENTARY_BUILDER'
} as const;

export type ServiceType = typeof ServiceType[keyof typeof ServiceType];


/**
 * 
 * @export
 * @interface SetMarketReportsVaFileRequest
 */
export interface SetMarketReportsVaFileRequest {
    /**
     * 
     * @type {any}
     * @memberof SetMarketReportsVaFileRequest
     */
    'reports': any;
}
/**
 * 
 * @export
 * @interface SingleTransition
 */
export interface SingleTransition {
    /**
     * 
     * @type {number}
     * @memberof SingleTransition
     */
    'growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTransition
     */
    'lateralPhase'?: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTransition
     */
    'highStress'?: number;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    'timeout'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StepAvailabilityStatus = {
    Ready: 'READY',
    Empty: 'EMPTY',
    Calculating: 'CALCULATING',
    ReviewRequired: 'REVIEW_REQUIRED'
} as const;

export type StepAvailabilityStatus = typeof StepAvailabilityStatus[keyof typeof StepAvailabilityStatus];


/**
 * 
 * @export
 * @interface StepsAvailability
 */
export interface StepsAvailability {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof StepsAvailability
     */
    'availabilityMap'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof StepsAvailability
     */
    'mandatorinessMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface StrategyConstraints
 */
export interface StrategyConstraints {
    /**
     * 
     * @type {TargetMaxTurnover}
     * @memberof StrategyConstraints
     */
    'targetMaxTurnover'?: TargetMaxTurnover;
    /**
     * 
     * @type {TargetMinOperationWeight}
     * @memberof StrategyConstraints
     */
    'targetMinOperationWeight'?: TargetMinOperationWeight;
    /**
     * 
     * @type {TargetTransactionCostsInBps}
     * @memberof StrategyConstraints
     */
    'targetTransactionCostsInBps'?: TargetTransactionCostsInBps;
    /**
     * 
     * @type {MinNumberOfInstruments}
     * @memberof StrategyConstraints
     */
    'minNumberOfInstruments'?: MinNumberOfInstruments;
    /**
     * 
     * @type {MaxNumberOfInstruments}
     * @memberof StrategyConstraints
     */
    'maxNumberOfInstruments'?: MaxNumberOfInstruments;
    /**
     * 
     * @type {InstrumentMinWeight}
     * @memberof StrategyConstraints
     */
    'instrumentMinWeight'?: InstrumentMinWeight;
    /**
     * 
     * @type {Rounding}
     * @memberof StrategyConstraints
     */
    'rounding'?: Rounding;
}
/**
 * 
 * @export
 * @interface StrategyConstraintsResponse
 */
export interface StrategyConstraintsResponse {
    /**
     * 
     * @type {{ [key: string]: StepAvailabilityStatus; }}
     * @memberof StrategyConstraintsResponse
     */
    'stepsAvailability'?: { [key: string]: StepAvailabilityStatus; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof StrategyConstraintsResponse
     */
    'stepsMandatoriness'?: { [key: string]: boolean; };
    /**
     * 
     * @type {TargetMaxTurnover}
     * @memberof StrategyConstraintsResponse
     */
    'targetMaxTurnover'?: TargetMaxTurnover;
    /**
     * 
     * @type {TargetMinOperationWeight}
     * @memberof StrategyConstraintsResponse
     */
    'targetMinOperationWeight'?: TargetMinOperationWeight;
    /**
     * 
     * @type {TargetTransactionCostsInBps}
     * @memberof StrategyConstraintsResponse
     */
    'targetTransactionCostsInBps'?: TargetTransactionCostsInBps;
    /**
     * 
     * @type {MinNumberOfInstruments}
     * @memberof StrategyConstraintsResponse
     */
    'minNumberOfInstruments'?: MinNumberOfInstruments;
    /**
     * 
     * @type {MaxNumberOfInstruments}
     * @memberof StrategyConstraintsResponse
     */
    'maxNumberOfInstruments'?: MaxNumberOfInstruments;
    /**
     * 
     * @type {InstrumentMinWeight}
     * @memberof StrategyConstraintsResponse
     */
    'instrumentMinWeight'?: InstrumentMinWeight;
    /**
     * 
     * @type {Rounding}
     * @memberof StrategyConstraintsResponse
     */
    'rounding'?: Rounding;
}
/**
 * 
 * @export
 * @interface StrategyConstraintsSaveRequest
 */
export interface StrategyConstraintsSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof StrategyConstraintsSaveRequest
     */
    'investmentUuid'?: string;
    /**
     * 
     * @type {TargetMaxTurnover}
     * @memberof StrategyConstraintsSaveRequest
     */
    'targetMaxTurnover'?: TargetMaxTurnover;
    /**
     * 
     * @type {TargetMinOperationWeight}
     * @memberof StrategyConstraintsSaveRequest
     */
    'targetMinOperationWeight'?: TargetMinOperationWeight;
    /**
     * 
     * @type {TargetTransactionCostsInBps}
     * @memberof StrategyConstraintsSaveRequest
     */
    'targetTransactionCostsInBps'?: TargetTransactionCostsInBps;
    /**
     * 
     * @type {MinNumberOfInstruments}
     * @memberof StrategyConstraintsSaveRequest
     */
    'minNumberOfInstruments'?: MinNumberOfInstruments;
    /**
     * 
     * @type {MaxNumberOfInstruments}
     * @memberof StrategyConstraintsSaveRequest
     */
    'maxNumberOfInstruments'?: MaxNumberOfInstruments;
    /**
     * 
     * @type {InstrumentMinWeight}
     * @memberof StrategyConstraintsSaveRequest
     */
    'instrumentMinWeight'?: InstrumentMinWeight;
    /**
     * 
     * @type {Rounding}
     * @memberof StrategyConstraintsSaveRequest
     */
    'rounding'?: Rounding;
    /**
     * 
     * @type {boolean}
     * @memberof StrategyConstraintsSaveRequest
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface SubPortfolio
 */
export interface SubPortfolio {
    /**
     * 
     * @type {string}
     * @memberof SubPortfolio
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubPortfolio
     */
    'entityType'?: SubPortfolioEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubPortfolio
     */
    'name'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof SubPortfolio
     */
    'initialTickerComposition'?: Array<RichTicker>;
    /**
     * 
     * @type {Array<ProxyTicker>}
     * @memberof SubPortfolio
     */
    'initialProxyComposition'?: Array<ProxyTicker>;
    /**
     * 
     * @type {Currencies}
     * @memberof SubPortfolio
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {number}
     * @memberof SubPortfolio
     */
    'initialWeight'?: number;
}

export const SubPortfolioEntityTypeEnum = {
    Investment: 'INVESTMENT',
    InvestmentEnhancement: 'INVESTMENT_ENHANCEMENT',
    Universe: 'UNIVERSE',
    TargetInvestment: 'TARGET_INVESTMENT',
    Benchmark: 'BENCHMARK',
    InvestmentDraft: 'INVESTMENT_DRAFT'
} as const;

export type SubPortfolioEntityTypeEnum = typeof SubPortfolioEntityTypeEnum[keyof typeof SubPortfolioEntityTypeEnum];

/**
 * 
 * @export
 * @interface SubSectorGlobalRisk
 */
export interface SubSectorGlobalRisk {
    /**
     * 
     * @type {AssetClassIdentifier}
     * @memberof SubSectorGlobalRisk
     */
    'assetClassIdentifier'?: AssetClassIdentifier;
    /**
     * 
     * @type {number}
     * @memberof SubSectorGlobalRisk
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubSectorGlobalRisk
     */
    'valueOnMap'?: number;
}
/**
 * 
 * @export
 * @interface SyncInfoDto
 */
export interface SyncInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SyncInfoDto
     */
    'lastExecutionTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncInfoDto
     */
    'frequency'?: number;
}
/**
 * 
 * @export
 * @interface SyntheticMetrics
 */
export interface SyntheticMetrics {
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'trackingError'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'exAnteAnnualisedReturn'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'exAnteDiversificationRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'exAnteEfficiencyRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'exAnteMaxDrawDown'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'longTermVolatility'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SyntheticMetrics
     */
    'riskMetrics'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performanceYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performanceMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performance1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performance3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performance6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performance1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'performanceSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatilityYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatilityMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatility3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatility6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatility1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'volatilitySinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortinoYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortinoMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortino1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortino3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortino6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortino1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'sortinoSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatioYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatioMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatio1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatio3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatio6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatio1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'efficiencyRatioSinceInception'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdownYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdownMTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdown1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdown3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdown6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdown1Y'?: number;
    /**
     * 
     * @type {number}
     * @memberof SyntheticMetrics
     */
    'maxDrawdownSinceInception'?: number;
    /**
     * 
     * @type {RealizedSyntheticMetrics}
     * @memberof SyntheticMetrics
     */
    'realizedSyntheticMetrics'?: RealizedSyntheticMetrics;
}
/**
 * 
 * @export
 * @interface TagsConstraint
 */
export interface TagsConstraint {
    /**
     * 
     * @type {string}
     * @memberof TagsConstraint
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagsConstraint
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagsConstraint
     */
    'target'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagsConstraint
     */
    'validationDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagsConstraint
     */
    'suggestedMinWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagsConstraint
     */
    'suggestedMaxWeight'?: number;
    /**
     * 
     * @type {ConstraintRelation}
     * @memberof TagsConstraint
     */
    'relation'?: ConstraintRelation;
    /**
     * 
     * @type {number}
     * @memberof TagsConstraint
     */
    'minWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagsConstraint
     */
    'maxWeight'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TagsConstraint
     */
    'tagIdentifiers'?: Array<string>;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof TagsConstraint
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface TargetMaxTurnover
 */
export interface TargetMaxTurnover {
    /**
     * 
     * @type {number}
     * @memberof TargetMaxTurnover
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMaxTurnover
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMaxTurnover
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMaxTurnover
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMaxTurnover
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof TargetMaxTurnover
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface TargetMinOperationWeight
 */
export interface TargetMinOperationWeight {
    /**
     * 
     * @type {number}
     * @memberof TargetMinOperationWeight
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMinOperationWeight
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMinOperationWeight
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMinOperationWeight
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetMinOperationWeight
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof TargetMinOperationWeight
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface TargetTransactionCostsInBps
 */
export interface TargetTransactionCostsInBps {
    /**
     * 
     * @type {number}
     * @memberof TargetTransactionCostsInBps
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetTransactionCostsInBps
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetTransactionCostsInBps
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetTransactionCostsInBps
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetTransactionCostsInBps
     */
    'priority'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof TargetTransactionCostsInBps
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface TargetVolatility
 */
export interface TargetVolatility {
    /**
     * 
     * @type {number}
     * @memberof TargetVolatility
     */
    'suggestedMinValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetVolatility
     */
    'suggestedMaxValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetVolatility
     */
    'suggestedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof TargetVolatility
     */
    'selectedValue'?: number;
    /**
     * 
     * @type {ConstraintValidity}
     * @memberof TargetVolatility
     */
    'validity'?: ConstraintValidity;
}
/**
 * 
 * @export
 * @interface TemplateDto
 */
export interface TemplateDto {
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateImportType = {
    AmundiPortfolioImport: 'AMUNDI_PORTFOLIO_IMPORT',
    AnalysisPortfolioImport: 'ANALYSIS_PORTFOLIO_IMPORT',
    BloombergPortfolioImport: 'BLOOMBERG_PORTFOLIO_IMPORT',
    BloombergUniverseImport: 'BLOOMBERG_UNIVERSE_IMPORT',
    CustomInstrumentDescription: 'CUSTOM_INSTRUMENT_DESCRIPTION',
    GuardianPortfolioImport: 'GUARDIAN_PORTFOLIO_IMPORT',
    SpherePortfolioImport: 'SPHERE_PORTFOLIO_IMPORT',
    SphereUniverseImport: 'SPHERE_UNIVERSE_IMPORT'
} as const;

export type TemplateImportType = typeof TemplateImportType[keyof typeof TemplateImportType];


/**
 * 
 * @export
 * @interface TemplateModel
 */
export interface TemplateModel {
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'content'?: string;
    /**
     * 
     * @type {TemplateType}
     * @memberof TemplateModel
     */
    'type'?: TemplateType;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     * @deprecated
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateModel
     */
    'creation_time'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TemplateType = {
    PortfolioReady: 'PORTFOLIO_READY',
    FirstPrompt: 'FIRST_PROMPT',
    SecondPrompt: 'SECOND_PROMPT',
    PortfolioEnhance: 'PORTFOLIO_ENHANCE',
    OutlookFocus: 'OUTLOOK_FOCUS',
    Dashboard: 'DASHBOARD',
    ComparePtf: 'COMPARE_PTF',
    TopNAssetClassOverallPerformance: 'TOP_N_ASSET_CLASS_OVERALL_PERFORMANCE',
    PortfolioRisk: 'PORTFOLIO_RISK',
    TopNAssetClassExposed: 'TOP_N_ASSET_CLASS_EXPOSED',
    AcExpectationNextTimeHorizon: 'AC_EXPECTATION_NEXT_TIME_HORIZON'
} as const;

export type TemplateType = typeof TemplateType[keyof typeof TemplateType];


/**
 * 
 * @export
 * @interface TickerExplainabilityDetails
 */
export interface TickerExplainabilityDetails {
    /**
     * 
     * @type {string}
     * @memberof TickerExplainabilityDetails
     */
    'tickerName'?: string;
    /**
     * 
     * @type {{ [key: string]: LeafStepValues; }}
     * @memberof TickerExplainabilityDetails
     */
    'leafSteps'?: { [key: string]: LeafStepValues; };
    /**
     * 
     * @type {PreAllocatorStepValues}
     * @memberof TickerExplainabilityDetails
     */
    'preAllocatorStep'?: PreAllocatorStepValues;
    /**
     * 
     * @type {RoundingValuesStepValues}
     * @memberof TickerExplainabilityDetails
     */
    'roundingStep'?: RoundingValuesStepValues;
    /**
     * 
     * @type {AllocatorStepValues}
     * @memberof TickerExplainabilityDetails
     */
    'allocatorStep'?: AllocatorStepValues;
    /**
     * 
     * @type {ConstraintCheckStepValues}
     * @memberof TickerExplainabilityDetails
     */
    'constraintCheckStep'?: ConstraintCheckStepValues;
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'why'?: string;
    /**
     * 
     * @type {Array<Forecast>}
     * @memberof Type
     */
    'forecast'?: Array<Forecast>;
    /**
     * 
     * @type {AcMetrics}
     * @memberof Type
     */
    'ac_metrics'?: AcMetrics;
    /**
     * 
     * @type {PositioningDrivers}
     * @memberof Type
     */
    'positioning_drivers'?: PositioningDrivers;
    /**
     * 
     * @type {Array<number>}
     * @memberof Type
     */
    'return_analysis_bands'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'mdotm_trace'?: number;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'commentary'?: string;
}
/**
 * 
 * @export
 * @interface UniverseCompositionResponse
 */
export interface UniverseCompositionResponse {
    /**
     * 
     * @type {Array<ReviewTicker>}
     * @memberof UniverseCompositionResponse
     */
    'composition'?: Array<ReviewTicker>;
}
/**
 * 
 * @export
 * @interface UniverseImport
 */
export interface UniverseImport {
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'name'?: string;
    /**
     * 
     * @type {Currencies}
     * @memberof UniverseImport
     */
    'baseCurrency'?: Currencies;
    /**
     * 
     * @type {Array<ImportTicker>}
     * @memberof UniverseImport
     */
    'composition'?: Array<ImportTicker>;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'syncDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'commentaryType'?: UniverseImportCommentaryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UniverseImport
     */
    'targetVolatility'?: number;
    /**
     * 
     * @type {Array<ImportConstraints>}
     * @memberof UniverseImport
     */
    'constraints'?: Array<ImportConstraints>;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UniverseImport
     */
    'creation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UniverseImport
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'benchmarkIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'universeIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'marketViewIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UniverseImport
     */
    'referenceIdentifier'?: string;
}

export const UniverseImportCommentaryTypeEnum = {
    VarBased: 'VAR_BASED',
    BenchmarkBased: 'BENCHMARK_BASED'
} as const;

export type UniverseImportCommentaryTypeEnum = typeof UniverseImportCommentaryTypeEnum[keyof typeof UniverseImportCommentaryTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const UniverseImportConverterType = {
    SphereTemplateConverter: 'SPHERE_TEMPLATE_CONVERTER',
    BloombergConverter: 'BLOOMBERG_CONVERTER'
} as const;

export type UniverseImportConverterType = typeof UniverseImportConverterType[keyof typeof UniverseImportConverterType];


/**
 * 
 * @export
 * @interface UniverseImportItems
 */
export interface UniverseImportItems {
    /**
     * 
     * @type {Array<InvestmentConversionError>}
     * @memberof UniverseImportItems
     */
    'errors'?: Array<InvestmentConversionError>;
    /**
     * 
     * @type {Array<InvestmentIdentifierImport>}
     * @memberof UniverseImportItems
     */
    'investments'?: Array<InvestmentIdentifierImport>;
}
/**
 * 
 * @export
 * @interface UpdateUniverseCompositionRequest
 */
export interface UpdateUniverseCompositionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUniverseCompositionRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUniverseCompositionRequest
     */
    'template'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUniverseCompositionRequest
     */
    'universeName'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof UpdateUniverseCompositionRequest
     */
    'composition'?: Array<RichTicker>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUniverseCompositionRequest
     */
    'hidden'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'surname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUser
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'role'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUser
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {Array<ServiceType>}
     * @memberof UpdateUser
     */
    'services'?: Array<ServiceType>;
    /**
     * 
     * @type {Array<InvestmentImportConverterType>}
     * @memberof UpdateUser
     */
    'importFormats'?: Array<InvestmentImportConverterType>;
    /**
     * 
     * @type {Array<InvestmentExportConverterType>}
     * @memberof UpdateUser
     */
    'exportFormats'?: Array<InvestmentExportConverterType>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUser
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'importDirectory'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserProxyPreferencesResponse
 */
export interface UpdateUserProxyPreferencesResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProxyPreferencesResponse
     */
    'userMail'?: string;
    /**
     * 
     * @type {Array<RichTickerWithNonTradableCause>}
     * @memberof UpdateUserProxyPreferencesResponse
     */
    'tickers'?: Array<RichTickerWithNonTradableCause>;
}
/**
 * 
 * @export
 * @interface UploadError
 */
export interface UploadError {
    /**
     * 
     * @type {UploadErrorCodes}
     * @memberof UploadError
     */
    'code'?: UploadErrorCodes;
    /**
     * 
     * @type {string}
     * @memberof UploadError
     */
    'alias'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadError
     */
    'weight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UploadError
     */
    'warning'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UploadErrorCodes = {
    InvalidWorkbook: 'INVALID_WORKBOOK',
    MoreThanMaxInstruments: 'MORE_THAN_MAX_INSTRUMENTS',
    InvalidAlias: 'INVALID_ALIAS',
    DuplicateRow: 'DUPLICATE_ROW',
    Delisted: 'DELISTED',
    WeightOver100: 'WEIGHT_OVER_100',
    WeightUnder100: 'WEIGHT_UNDER_100',
    EmptySsPortfolio: 'EMPTY_SS_PORTFOLIO',
    MissingData: 'MISSING_DATA'
} as const;

export type UploadErrorCodes = typeof UploadErrorCodes[keyof typeof UploadErrorCodes];


/**
 * 
 * @export
 * @interface UploadRetrieveDataResponse
 */
export interface UploadRetrieveDataResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadRetrieveDataResponse
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRetrieveDataResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRetrieveDataResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRetrieveDataResponse
     * @deprecated
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRetrieveDataResponse
     */
    'entityType'?: UploadRetrieveDataResponseEntityTypeEnum;
    /**
     * 
     * @type {Array<RichTickerWithNonTradableCause>}
     * @memberof UploadRetrieveDataResponse
     */
    'tickers'?: Array<RichTickerWithNonTradableCause>;
}

export const UploadRetrieveDataResponseEntityTypeEnum = {
    Investment: 'INVESTMENT',
    InvestmentEnhancement: 'INVESTMENT_ENHANCEMENT',
    Universe: 'UNIVERSE',
    TargetInvestment: 'TARGET_INVESTMENT',
    Benchmark: 'BENCHMARK',
    InvestmentDraft: 'INVESTMENT_DRAFT'
} as const;

export type UploadRetrieveDataResponseEntityTypeEnum = typeof UploadRetrieveDataResponseEntityTypeEnum[keyof typeof UploadRetrieveDataResponseEntityTypeEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'surname'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<ServiceType>}
     * @memberof User
     */
    'services'?: Array<ServiceType>;
    /**
     * 
     * @type {Array<InvestmentImportConverterType>}
     * @memberof User
     */
    'importFormats'?: Array<InvestmentImportConverterType>;
    /**
     * 
     * @type {Array<InvestmentExportConverterType>}
     * @memberof User
     */
    'exportFormats'?: Array<InvestmentExportConverterType>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'importDirectory'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UserAlertDto
 */
export interface UserAlertDto {
    /**
     * 
     * @type {string}
     * @memberof UserAlertDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertDto
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAlertDto
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserAlertDto
     */
    'threshold'?: number;
    /**
     * 
     * @type {Array<UserAlertDto>}
     * @memberof UserAlertDto
     */
    'children'?: Array<UserAlertDto>;
}
/**
 * 
 * @export
 * @interface UserBulkReportDto
 */
export interface UserBulkReportDto {
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'zipId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBulkReportDto
     */
    'successList'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserBulkReportDto
     */
    'errorList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'creationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserBulkReportDto
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'gridDownloadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkReportDto
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface UserConversationsListResponse
 */
export interface UserConversationsListResponse {
    /**
     * 
     * @type {Array<ConversationMinInfo>}
     * @memberof UserConversationsListResponse
     */
    'conversations'?: Array<ConversationMinInfo>;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'modification_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'reset_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'id_role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'reset_token_expiration'?: string;
}
/**
 * 
 * @export
 * @interface UserMarketViewColumnOrdering
 */
export interface UserMarketViewColumnOrdering {
    /**
     * 
     * @type {Array<UserMarketViewColumnPreference>}
     * @memberof UserMarketViewColumnOrdering
     */
    'userMarketViewColumnPreferences'?: Array<UserMarketViewColumnPreference>;
}
/**
 * 
 * @export
 * @interface UserMarketViewColumnPreference
 */
export interface UserMarketViewColumnPreference {
    /**
     * 
     * @type {string}
     * @memberof UserMarketViewColumnPreference
     */
    'preferencesType'?: UserMarketViewColumnPreferencePreferencesTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserMarketViewColumnPreference
     */
    'enabled'?: boolean;
}

export const UserMarketViewColumnPreferencePreferencesTypeEnum = {
    Name: 'NAME',
    Type: 'TYPE',
    SharingViews: 'SHARING_VIEWS',
    Creation: 'CREATION',
    ForecastHorizon: 'FORECAST_HORIZON',
    Expiration: 'EXPIRATION'
} as const;

export type UserMarketViewColumnPreferencePreferencesTypeEnum = typeof UserMarketViewColumnPreferencePreferencesTypeEnum[keyof typeof UserMarketViewColumnPreferencePreferencesTypeEnum];

/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'surname'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModel
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<ServiceType>}
     * @memberof UserModel
     */
    'services'?: Array<ServiceType>;
    /**
     * 
     * @type {Array<InvestmentImportConverterType>}
     * @memberof UserModel
     */
    'importFormats'?: Array<InvestmentImportConverterType>;
    /**
     * 
     * @type {Array<InvestmentExportConverterType>}
     * @memberof UserModel
     */
    'exportFormats'?: Array<InvestmentExportConverterType>;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'importDirectory'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UserModel
     */
    'customFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'modification_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'reset_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'reset_token_expiration'?: string;
}
/**
 * 
 * @export
 * @interface UserPortfolioColumnOrdering
 */
export interface UserPortfolioColumnOrdering {
    /**
     * 
     * @type {Array<UserPortfolioColumnPreference>}
     * @memberof UserPortfolioColumnOrdering
     */
    'userPortfolioColumnPreferences'?: Array<UserPortfolioColumnPreference>;
}
/**
 * 
 * @export
 * @interface UserPortfolioColumnPreference
 */
export interface UserPortfolioColumnPreference {
    /**
     * 
     * @type {string}
     * @memberof UserPortfolioColumnPreference
     */
    'preferencesType'?: UserPortfolioColumnPreferencePreferencesTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserPortfolioColumnPreference
     */
    'enabled'?: boolean;
}

export const UserPortfolioColumnPreferencePreferencesTypeEnum = {
    Name: 'NAME',
    Status: 'STATUS',
    InceptionDate: 'INCEPTION_DATE',
    Warnings: 'WARNINGS',
    CurrentAction: 'CURRENT_ACTION',
    LastStatusUpdate: 'LAST_STATUS_UPDATE',
    AverageScore: 'AVERAGE_SCORE',
    AutoSync: 'AUTO_SYNC',
    Performance1M: 'PERFORMANCE_1M',
    Sortino1M: 'SORTINO_1M',
    MaxDrawdown1M: 'MAX_DRAWDOWN_1M',
    Volatility1M: 'VOLATILITY_1M',
    EfficiencyRatio1M: 'EFFICIENCY_RATIO_1M',
    BenchmarkPerformance1M: 'BENCHMARK_PERFORMANCE_1M',
    BenchmarkSortino1M: 'BENCHMARK_SORTINO_1M',
    BenchmarkMaxDrawdown1M: 'BENCHMARK_MAX_DRAWDOWN_1M',
    BenchmarkVolatility1M: 'BENCHMARK_VOLATILITY_1M',
    BenchmarkEfficiencyRatio1M: 'BENCHMARK_EFFICIENCY_RATIO_1M',
    CurrencyName: 'CURRENCY_NAME',
    BenchmarkName: 'BENCHMARK_NAME',
    UniverseName: 'UNIVERSE_NAME',
    CommentaryType: 'COMMENTARY_TYPE',
    SharingViews: 'SHARING_VIEWS',
    MarketViewName: 'MARKET_VIEW_NAME'
} as const;

export type UserPortfolioColumnPreferencePreferencesTypeEnum = typeof UserPortfolioColumnPreferencePreferencesTypeEnum[keyof typeof UserPortfolioColumnPreferencePreferencesTypeEnum];

/**
 * 
 * @export
 * @interface UserProxyPreferencesResponse
 */
export interface UserProxyPreferencesResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProxyPreferencesResponse
     */
    'userMail'?: string;
    /**
     * 
     * @type {Array<RichTicker>}
     * @memberof UserProxyPreferencesResponse
     */
    'tickers'?: Array<RichTicker>;
}
/**
 * 
 * @export
 * @interface UserReferenceColumnOrdering
 */
export interface UserReferenceColumnOrdering {
    /**
     * 
     * @type {Array<UserReferenceColumnPreference>}
     * @memberof UserReferenceColumnOrdering
     */
    'userReferenceColumnPreferences'?: Array<UserReferenceColumnPreference>;
}
/**
 * 
 * @export
 * @interface UserReferenceColumnPreference
 */
export interface UserReferenceColumnPreference {
    /**
     * 
     * @type {string}
     * @memberof UserReferenceColumnPreference
     */
    'preferencesType'?: UserReferenceColumnPreferencePreferencesTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserReferenceColumnPreference
     */
    'enabled'?: boolean;
}

export const UserReferenceColumnPreferencePreferencesTypeEnum = {
    Name: 'NAME',
    SharingViews: 'SHARING_VIEWS',
    InceptionDate: 'INCEPTION_DATE',
    LastStatusUpdate: 'LAST_STATUS_UPDATE',
    Status: 'STATUS',
    Type: 'TYPE',
    LinkedPortfolios: 'LINKED_PORTFOLIOS'
} as const;

export type UserReferenceColumnPreferencePreferencesTypeEnum = typeof UserReferenceColumnPreferencePreferencesTypeEnum[keyof typeof UserReferenceColumnPreferencePreferencesTypeEnum];

/**
 * 
 * @export
 * @interface UserUniverseColumnOrdering
 */
export interface UserUniverseColumnOrdering {
    /**
     * 
     * @type {Array<UserUniverseColumnPreference>}
     * @memberof UserUniverseColumnOrdering
     */
    'userUniverseColumnPreferences'?: Array<UserUniverseColumnPreference>;
}
/**
 * 
 * @export
 * @interface UserUniverseColumnPreference
 */
export interface UserUniverseColumnPreference {
    /**
     * 
     * @type {string}
     * @memberof UserUniverseColumnPreference
     */
    'preferencesType'?: UserUniverseColumnPreferencePreferencesTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserUniverseColumnPreference
     */
    'enabled'?: boolean;
}

export const UserUniverseColumnPreferencePreferencesTypeEnum = {
    Name: 'NAME',
    InceptionDate: 'INCEPTION_DATE',
    SharingViews: 'SHARING_VIEWS',
    Status: 'STATUS',
    LastStatusUpdate: 'LAST_STATUS_UPDATE',
    NoOfInstruments: 'NO_OF_INSTRUMENTS',
    LinkedPortfolios: 'LINKED_PORTFOLIOS'
} as const;

export type UserUniverseColumnPreferencePreferencesTypeEnum = typeof UserUniverseColumnPreferencePreferencesTypeEnum[keyof typeof UserUniverseColumnPreferencePreferencesTypeEnum];

/**
 * 
 * @export
 * @interface UserWithCustomerDto
 */
export interface UserWithCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     * @deprecated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'modificationTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithCustomerDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'resetToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'resetTokenExpiration'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UserWithCustomerDto
     */
    'customFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'role'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithCustomerDto
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     * @deprecated
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<ServiceType>}
     * @memberof UserWithCustomerDto
     */
    'services'?: Array<ServiceType>;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'theme'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserWithCustomerDto
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithCustomerDto
     */
    'maxPortfolios'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithCustomerDto
     */
    'tokenDuration'?: number;
    /**
     * 
     * @type {Array<InvestmentImportConverterType>}
     * @memberof UserWithCustomerDto
     */
    'importFormats'?: Array<InvestmentImportConverterType>;
    /**
     * 
     * @type {Array<InvestmentExportConverterType>}
     * @memberof UserWithCustomerDto
     */
    'exportFormats'?: Array<InvestmentExportConverterType>;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'importConnectionType'?: UserWithCustomerDtoImportConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'exportConnectionType'?: UserWithCustomerDtoExportConnectionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithCustomerDto
     */
    'importEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithCustomerDto
     */
    'exportEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'importDirectory'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'lowRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'midRiskProfile'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithCustomerDto
     */
    'highRiskProfile'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserWithCustomerDto
     */
    'minRisk'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithCustomerDto
     */
    'maxRisk'?: number;
}

export const UserWithCustomerDtoImportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type UserWithCustomerDtoImportConnectionTypeEnum = typeof UserWithCustomerDtoImportConnectionTypeEnum[keyof typeof UserWithCustomerDtoImportConnectionTypeEnum];
export const UserWithCustomerDtoExportConnectionTypeEnum = {
    Sftp: 'SFTP',
    FileCloud: 'FILE_CLOUD'
} as const;

export type UserWithCustomerDtoExportConnectionTypeEnum = typeof UserWithCustomerDtoExportConnectionTypeEnum[keyof typeof UserWithCustomerDtoExportConnectionTypeEnum];

/**
 * 
 * @export
 * @interface V2MarketRegimeProbabilityDTO
 */
export interface V2MarketRegimeProbabilityDTO {
    /**
     * 
     * @type {string}
     * @memberof V2MarketRegimeProbabilityDTO
     */
    'LastUpdate'?: string;
    /**
     * 
     * @type {V2MarketRegimeProbabilityDTORegimeProbability}
     * @memberof V2MarketRegimeProbabilityDTO
     */
    'RegimeProbability'?: V2MarketRegimeProbabilityDTORegimeProbability;
}
/**
 * 
 * @export
 * @interface V2MarketRegimeProbabilityDTORegimeProbability
 */
export interface V2MarketRegimeProbabilityDTORegimeProbability {
    [key: string]: MarketRegimeProbabilityEntryDTO | any;

    /**
     * 
     * @type {boolean}
     * @memberof V2MarketRegimeProbabilityDTORegimeProbability
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidationOperation
 */
export interface ValidationOperation {
    /**
     * 
     * @type {string}
     * @memberof ValidationOperation
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationOperation
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface ValueWithData
 */
export interface ValueWithData {
    /**
     * 
     * @type {number}
     * @memberof ValueWithData
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof ValueWithData
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface Volatility
 */
export interface Volatility {
    /**
     * 
     * @type {number}
     * @memberof Volatility
     */
    'volatilityYTD'?: number;
    /**
     * 
     * @type {number}
     * @memberof Volatility
     */
    'volatility1M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Volatility
     */
    'volatility3M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Volatility
     */
    'volatility6M'?: number;
    /**
     * 
     * @type {number}
     * @memberof Volatility
     */
    'volatility1Y'?: number;
}
/**
 * 
 * @export
 * @interface WidgetConfiguration
 */
export interface WidgetConfiguration {
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'w'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'h'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'minH'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'maxH'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'minW'?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetConfiguration
     */
    'maxW'?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetConfiguration
     */
    'i'?: string;
}

/**
 * AIConversationsControllerApi - axios parameter creator
 * @export
 */
export const AIConversationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Debug endpoint to test the feature directly.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askChatGPT: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('askChatGPT', 'body', body)
            const localVarPath = `/v1/user-ai-conversations/ask-debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new conversation inputting the first message, and waits for the response.
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation: async (conversationMessageRequest: ConversationMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationMessageRequest' is not null or undefined
            assertParamExists('createConversation', 'conversationMessageRequest', conversationMessageRequest)
            const localVarPath = `/v1/user-ai-conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a conversation.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversation: async (conversationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('deleteConversation', 'conversationUuid', conversationUuid)
            const localVarPath = `/v1/user-ai-conversations/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a message in an existent conversation, and waits for the response.
         * @param {string} conversationUuid 
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueueMessageInConversation: async (conversationUuid: string, conversationMessageRequest: ConversationMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('enqueueMessageInConversation', 'conversationUuid', conversationUuid)
            // verify required parameter 'conversationMessageRequest' is not null or undefined
            assertParamExists('enqueueMessageInConversation', 'conversationMessageRequest', conversationMessageRequest)
            const localVarPath = `/v1/user-ai-conversations/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (conversationUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('getConversation', 'conversationUuid', conversationUuid)
            const localVarPath = `/v1/user-ai-conversations/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user-ai-conversations/default-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the conversations of the user, exposing only the essential infos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllConversations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user-ai-conversations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renames a conversation.
         * @param {string} conversationUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameConversation: async (conversationUuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('renameConversation', 'conversationUuid', conversationUuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameConversation', 'name', name)
            const localVarPath = `/v1/user-ai-conversations/rename/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the conversation as favourite.
         * @param {string} conversationUuid 
         * @param {boolean} isFavourite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConversationAsFavourite: async (conversationUuid: string, isFavourite: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('setConversationAsFavourite', 'conversationUuid', conversationUuid)
            // verify required parameter 'isFavourite' is not null or undefined
            assertParamExists('setConversationAsFavourite', 'isFavourite', isFavourite)
            const localVarPath = `/v1/user-ai-conversations/favourite/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isFavourite !== undefined) {
                localVarQueryParameter['is-favourite'] = isFavourite;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the conversation message feedback.
         * @param {string} conversationUuid 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConversationMessageFeedback: async (conversationUuid: string, feedbackRequest: FeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationUuid' is not null or undefined
            assertParamExists('setConversationMessageFeedback', 'conversationUuid', conversationUuid)
            // verify required parameter 'feedbackRequest' is not null or undefined
            assertParamExists('setConversationMessageFeedback', 'feedbackRequest', feedbackRequest)
            const localVarPath = `/v1/user-ai-conversations/feedback/{conversation-uuid}`
                .replace(`{${"conversation-uuid"}}`, encodeURIComponent(String(conversationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIConversationsControllerApi - functional programming interface
 * @export
 */
export const AIConversationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIConversationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Debug endpoint to test the feature directly.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async askChatGPT(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.askChatGPT(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new conversation inputting the first message, and waits for the response.
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConversation(conversationMessageRequest: ConversationMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConversation(conversationMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a conversation.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConversation(conversationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationMinInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConversation(conversationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a message in an existent conversation, and waits for the response.
         * @param {string} conversationUuid 
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueueMessageInConversation(conversationUuid: string, conversationMessageRequest: ConversationMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueueMessageInConversation(conversationUuid, conversationMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(conversationUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(conversationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the conversations of the user, exposing only the essential infos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllConversations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConversationsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllConversations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renames a conversation.
         * @param {string} conversationUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameConversation(conversationUuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationMinInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameConversation(conversationUuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the conversation as favourite.
         * @param {string} conversationUuid 
         * @param {boolean} isFavourite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setConversationAsFavourite(conversationUuid: string, isFavourite: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationMinInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setConversationAsFavourite(conversationUuid, isFavourite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the conversation message feedback.
         * @param {string} conversationUuid 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setConversationMessageFeedback(conversationUuid: string, feedbackRequest: FeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationMinInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setConversationMessageFeedback(conversationUuid, feedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AIConversationsControllerApi - factory interface
 * @export
 */
export const AIConversationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIConversationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Debug endpoint to test the feature directly.
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askChatGPT(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.askChatGPT(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new conversation inputting the first message, and waits for the response.
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(conversationMessageRequest: ConversationMessageRequest, options?: any): AxiosPromise<AiConversationDto> {
            return localVarFp.createConversation(conversationMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a conversation.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversation(conversationUuid: string, options?: any): AxiosPromise<ConversationMinInfo> {
            return localVarFp.deleteConversation(conversationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a message in an existent conversation, and waits for the response.
         * @param {string} conversationUuid 
         * @param {ConversationMessageRequest} conversationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueueMessageInConversation(conversationUuid: string, conversationMessageRequest: ConversationMessageRequest, options?: any): AxiosPromise<AiConversationDto> {
            return localVarFp.enqueueMessageInConversation(conversationUuid, conversationMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {string} conversationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(conversationUuid: string, options?: any): AxiosPromise<AiConversationDto> {
            return localVarFp.getConversation(conversationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the conversation using the uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultMessages(options?: any): AxiosPromise<DefaultMessagesResponse> {
            return localVarFp.getDefaultMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the conversations of the user, exposing only the essential infos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllConversations(options?: any): AxiosPromise<UserConversationsListResponse> {
            return localVarFp.listAllConversations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renames a conversation.
         * @param {string} conversationUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameConversation(conversationUuid: string, name: string, options?: any): AxiosPromise<ConversationMinInfo> {
            return localVarFp.renameConversation(conversationUuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the conversation as favourite.
         * @param {string} conversationUuid 
         * @param {boolean} isFavourite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConversationAsFavourite(conversationUuid: string, isFavourite: boolean, options?: any): AxiosPromise<ConversationMinInfo> {
            return localVarFp.setConversationAsFavourite(conversationUuid, isFavourite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the conversation message feedback.
         * @param {string} conversationUuid 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConversationMessageFeedback(conversationUuid: string, feedbackRequest: FeedbackRequest, options?: any): AxiosPromise<ConversationMinInfo> {
            return localVarFp.setConversationMessageFeedback(conversationUuid, feedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AIConversationsControllerApi - object-oriented interface
 * @export
 * @class AIConversationsControllerApi
 * @extends {BaseAPI}
 */
export class AIConversationsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Debug endpoint to test the feature directly.
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public askChatGPT(body: string, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).askChatGPT(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new conversation inputting the first message, and waits for the response.
     * @param {ConversationMessageRequest} conversationMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public createConversation(conversationMessageRequest: ConversationMessageRequest, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).createConversation(conversationMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a conversation.
     * @param {string} conversationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public deleteConversation(conversationUuid: string, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).deleteConversation(conversationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a message in an existent conversation, and waits for the response.
     * @param {string} conversationUuid 
     * @param {ConversationMessageRequest} conversationMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public enqueueMessageInConversation(conversationUuid: string, conversationMessageRequest: ConversationMessageRequest, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).enqueueMessageInConversation(conversationUuid, conversationMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the conversation using the uuid.
     * @param {string} conversationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public getConversation(conversationUuid: string, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).getConversation(conversationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the conversation using the uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public getDefaultMessages(options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).getDefaultMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the conversations of the user, exposing only the essential infos.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public listAllConversations(options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).listAllConversations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renames a conversation.
     * @param {string} conversationUuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public renameConversation(conversationUuid: string, name: string, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).renameConversation(conversationUuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the conversation as favourite.
     * @param {string} conversationUuid 
     * @param {boolean} isFavourite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public setConversationAsFavourite(conversationUuid: string, isFavourite: boolean, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).setConversationAsFavourite(conversationUuid, isFavourite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the conversation message feedback.
     * @param {string} conversationUuid 
     * @param {FeedbackRequest} feedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIConversationsControllerApi
     */
    public setConversationMessageFeedback(conversationUuid: string, feedbackRequest: FeedbackRequest, options?: AxiosRequestConfig) {
        return AIConversationsControllerApiFp(this.configuration).setConversationMessageFeedback(conversationUuid, feedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if investment exists. Used for a cleaning endpoint.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCheckIfInvestmentExists: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('appControllerCheckIfInvestmentExists', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('appControllerCheckIfInvestmentExists', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/check-if-exists/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEnhancementWithPreviousSettings: async (xApiKey: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('bulkEnhancementWithPreviousSettings', 'xApiKey', xApiKey)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('bulkEnhancementWithPreviousSettings', 'requestBody', requestBody)
            const localVarPath = `/v2/admin/investments/bulk-enhancement-with-previous-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {Instructions} instructions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateNewDataFromInstructions: async (xApiKey: string, instructions: Instructions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('calculateNewDataFromInstructions', 'xApiKey', xApiKey)
            // verify required parameter 'instructions' is not null or undefined
            assertParamExists('calculateNewDataFromInstructions', 'instructions', instructions)
            const localVarPath = `/v2/admin/create-new-data-from-instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instructions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint to generate the alerts associated to a specific portfolio.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPtfAlertsMail: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('checkPtfAlertsMail', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('checkPtfAlertsMail', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/alert-mail/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force clean market view prefills cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanMarketViewCache: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('cleanMarketViewCache', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/clean-market-view-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for clear cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMarketCache: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('clearMarketCache', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/market-cache/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for clear report cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPdfCache: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('clearPdfCache', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/cache/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate retrieve and store commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSaveCommentaryForDashboard: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createAndSaveCommentaryForDashboard', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/chatgpt/commentary/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCache: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCache', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/create-market-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary2: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentary2', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/create-market-commentary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for create outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary3: async (xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentary3', 'xApiKey', xApiKey)
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('createCommentary3', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('createCommentary3', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('createCommentary3', 'sector', sector)
            const localVarPath = `/v2/admin/create-market-commentary/asset-class/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for retrieve outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForAssetClass: async (xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForAssetClass', 'xApiKey', xApiKey)
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('createCommentaryForAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('createCommentaryForAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('createCommentaryForAssetClass', 'sector', sector)
            const localVarPath = `/v2/admin/chatgpt/commentary/asset-class/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate and retrieve commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForDashboard: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForDashboard', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/chatgpt/commentary/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestment: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createCommentaryForInvestment', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestment', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create commentary for a specific investment Enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentEnhanced: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createCommentaryForInvestmentEnhanced', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestmentEnhanced', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment-enhanced/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process investment enhanced with error, return the uuid of investment not processed
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentEnhancedWithErrors: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestmentEnhancedWithErrors', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment-enhanced/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create commentary for a list of investments
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestments: async (xApiKey: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestments', 'xApiKey', xApiKey)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createCommentaryForInvestments', 'requestBody', requestBody)
            const localVarPath = `/v2/admin/commentary/investments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestments1: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestments1', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/chatgpt/commentary/investments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments enhanced
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentsEnhanced: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createCommentaryForInvestmentsEnhanced', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/chatgpt/commentary/investments-enhanced`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHistoricalData: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createHistoricalData', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/create-historical-data-from-instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate prompt for a specific asset class 
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromptForAssetClass: async (xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createPromptForAssetClass', 'xApiKey', xApiKey)
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('createPromptForAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('createPromptForAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('createPromptForAssetClass', 'sector', sector)
            const localVarPath = `/v2/admin/createPrompt/asset-class/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootUser: async (email: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('createRootUser', 'email', email)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('createRootUser', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/create-root-user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enhancementWithPreviousSettings: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('enhancementWithPreviousSettings', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('enhancementWithPreviousSettings', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/enhancement-with-previous-settings/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for update market with yesterday data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUpdateMarket: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('forceUpdateMarket', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/force-update-market`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all reference universes that are in ready state.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReadyRefenceUniversesList: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getAdminReadyRefenceUniversesList', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/reference-universes/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all active reference universes.
         * @param {string} universeUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReferenceUniverseDetails: async (universeUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeUuid' is not null or undefined
            assertParamExists('getAdminReferenceUniverseDetails', 'universeUuid', universeUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getAdminReferenceUniverseDetails', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/reference-universes/{universe-uuid}`
                .replace(`{${"universe-uuid"}}`, encodeURIComponent(String(universeUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersEmails: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getAllUsersEmails', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/all-users-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkCompositions: async (xApiKey: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getBenchmarkCompositions', 'xApiKey', xApiKey)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getBenchmarkCompositions', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v2/admin/benchmark-compositions/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary4: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentary4', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getCommentary4', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaryForInvestmentEnhanced: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentaryForInvestmentEnhanced', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getCommentaryForInvestmentEnhanced', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment-enhanced/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaryMertrics: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentaryMertrics', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getCommentaryMertrics', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/investment/metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentCompositions: async (xApiKey: string, investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getInvestmentCompositions', 'xApiKey', xApiKey)
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentCompositions', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/admin/investment-compositions/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentWithErrors: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getInvestmentWithErrors', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/commentary/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download history the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPreviousPtf: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getJsonOfPreviousPtf', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getJsonOfPreviousPtf', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investment/history/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPtf: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getJsonOfPtf', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getJsonOfPtf', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investment/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download the json of ptf enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPtfEnhanced: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getJsonOfPtfEnhanced', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getJsonOfPtfEnhanced', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investment/enhanced/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences: async (xApiKey: string, userEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getUserPreferences', 'xApiKey', xApiKey)
            // verify required parameter 'userEmail' is not null or undefined
            assertParamExists('getUserPreferences', 'userEmail', userEmail)
            const localVarPath = `/v2/admin/user-preferences/{user-email}`
                .replace(`{${"user-email"}}`, encodeURIComponent(String(userEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check endpoint of the component.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('healthCheck', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import investment for a specific Customer
         * @param {string} xApiKey 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvestmentForCustomer: async (xApiKey: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('importInvestmentForCustomer', 'xApiKey', xApiKey)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('importInvestmentForCustomer', 'email', email)
            const localVarPath = `/v2/admin/import-investment-for-customer/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for sent market notifications.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMarketNotification: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('processMarketNotification', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/insert-market-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for calculate all notifications for all user but not sent it
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processNotification: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('processNotification', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/insert-all-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replica last market data available until today
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replicaDataUntilToday: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('replicaDataUntilToday', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/replica-last-market-until-today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrieveCompositionForInvestment: async (userId: string, investmentName: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('retrieveCompositionForInvestment', 'userId', userId)
            // verify required parameter 'investmentName' is not null or undefined
            assertParamExists('retrieveCompositionForInvestment', 'investmentName', investmentName)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retrieveCompositionForInvestment', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investment/composition/{user-id}/{investment-name}`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"investment-name"}}`, encodeURIComponent(String(investmentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current composition of the investment enhanced 
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrieveCompositionForInvestmentEnhanced: async (userId: string, investmentName: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('retrieveCompositionForInvestmentEnhanced', 'userId', userId)
            // verify required parameter 'investmentName' is not null or undefined
            assertParamExists('retrieveCompositionForInvestmentEnhanced', 'investmentName', investmentName)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retrieveCompositionForInvestmentEnhanced', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investment-enhanced/composition/{user-id}/{investment-name}`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"investment-name"}}`, encodeURIComponent(String(investmentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarEdit: async (identifier: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('retryBenchmarEdit', 'identifier', identifier)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryBenchmarEdit', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/benchmarks/retry/edit/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarkUpload: async (identifier: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('retryBenchmarkUpload', 'identifier', identifier)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryBenchmarkUpload', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/benchmarks/retry/upload/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarksBulk: async (xApiKey: string, bulkActionRequest: BulkActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryBenchmarksBulk', 'xApiKey', xApiKey)
            // verify required parameter 'bulkActionRequest' is not null or undefined
            assertParamExists('retryBenchmarksBulk', 'bulkActionRequest', bulkActionRequest)
            const localVarPath = `/v2/admin/benchmarks/bulk-retry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBulk: async (xApiKey: string, bulkActionRequest: BulkActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryBulk', 'xApiKey', xApiKey)
            // verify required parameter 'bulkActionRequest' is not null or undefined
            assertParamExists('retryBulk', 'bulkActionRequest', bulkActionRequest)
            const localVarPath = `/v2/admin/investments/bulk-retry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryCreate: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retryCreate', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryCreate', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/retry/creation/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryEditWithoutAccept: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retryEditWithoutAccept', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryEditWithoutAccept', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/retry/edit-without-accept/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for enhancement retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryEnhance: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retryEnhance', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryEnhance', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/retry/enhancement/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryOptimize: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retryOptimize', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryOptimize', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/retry/fix-issues/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryUpload: async (uuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retryUpload', 'uuid', uuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('retryUpload', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/investments/retry/upload/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the hmm report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {SetMarketReportsVaFileRequest} [setMarketReportsVaFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports1: async (xApiKey: string, setMarketReportsVaFileRequest?: SetMarketReportsVaFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setHmmReports1', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/hmm/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setMarketReportsVaFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the market report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {any} reports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReportsVaFile: async (xApiKey: string, reports: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setMarketReportsVaFile', 'xApiKey', xApiKey)
            // verify required parameter 'reports' is not null or undefined
            assertParamExists('setMarketReportsVaFile', 'reports', reports)
            const localVarPath = `/v2/admin/market/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


            if (reports !== undefined) { 
                localVarFormParams.append('reports', reports as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administration endpoint for update market with today data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketFromTodayReports: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateMarketFromTodayReports', 'xApiKey', xApiKey)
            const localVarPath = `/v2/admin/update-market-manually`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recomputation of old missing validation configurations
         * @param {string} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateConstraintsConfiguration: async (uuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/admin/investment/configuration/validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if investment exists. Used for a cleaning endpoint.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerCheckIfInvestmentExists(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerCheckIfInvestmentExists(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEnhancementWithPreviousSettings(xApiKey: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEnhancementWithPreviousSettings(xApiKey, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {Instructions} instructions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateNewDataFromInstructions(xApiKey: string, instructions: Instructions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateNewDataFromInstructions(xApiKey, instructions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint to generate the alerts associated to a specific portfolio.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPtfAlertsMail(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPtfAlertsMail(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Force clean market view prefills cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanMarketViewCache(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanMarketViewCache(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for clear cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearMarketCache(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearMarketCache(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for clear report cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearPdfCache(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearPdfCache(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate retrieve and store commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndSaveCommentaryForDashboard(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndSaveCommentaryForDashboard(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCache(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCache(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentary2(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentary2(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for create outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentary3(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentary3(xApiKey, assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for retrieve outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForAssetClass(xApiKey, assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate and retrieve commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForDashboard(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForDashboard(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestment(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestment(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create commentary for a specific investment Enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process investment enhanced with error, return the uuid of investment not processed
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestmentEnhancedWithErrors(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestmentEnhancedWithErrors(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create commentary for a list of investments
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestments(xApiKey: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestments(xApiKey, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestments1(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestments1(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments enhanced
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForInvestmentsEnhanced(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForInvestmentsEnhanced(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHistoricalData(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHistoricalData(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate prompt for a specific asset class 
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPromptForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPromptForAssetClass(xApiKey, assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRootUser(email: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRootUser(email, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enhancementWithPreviousSettings(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enhancementWithPreviousSettings(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for update market with yesterday data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceUpdateMarket(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceUpdateMarket(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all reference universes that are in ready state.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminReadyRefenceUniversesList(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminReadyRefenceUniversesList(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all active reference universes.
         * @param {string} universeUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminReferenceUniverseDetails(universeUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceUniverse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminReferenceUniverseDetails(universeUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersEmails(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersEmails(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkCompositions(xApiKey: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCompositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkCompositions(xApiKey, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentary4(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentary4(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentaryMertrics(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentaryMertrics(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentCompositions(xApiKey: string, investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCompositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentCompositions(xApiKey, investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentWithErrors(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentWithErrors(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download history the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJsonOfPreviousPtf(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJsonOfPreviousPtf(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJsonOfPtf(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJsonOfPtf(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download the json of ptf enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJsonOfPtfEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJsonOfPtfEnhanced(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferences(xApiKey: string, userEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProxyPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferences(xApiKey, userEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check endpoint of the component.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import investment for a specific Customer
         * @param {string} xApiKey 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importInvestmentForCustomer(xApiKey: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importInvestmentForCustomer(xApiKey, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for sent market notifications.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMarketNotification(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMarketNotification(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for calculate all notifications for all user but not sent it
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processNotification(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processNotification(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replica last market data available until today
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replicaDataUntilToday(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replicaDataUntilToday(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async retrieveCompositionForInvestment(userId: string, investmentName: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentComposition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCompositionForInvestment(userId, investmentName, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current composition of the investment enhanced 
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async retrieveCompositionForInvestmentEnhanced(userId: string, investmentName: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentEnhancedComposition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCompositionForInvestmentEnhanced(userId, investmentName, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryBenchmarEdit(identifier: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryBenchmarEdit(identifier, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryBenchmarkUpload(identifier: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryBenchmarkUpload(identifier, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryBenchmarksBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryBenchmarksBulk(xApiKey, bulkActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryBulk(xApiKey, bulkActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryCreate(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryCreate(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryEditWithoutAccept(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryEditWithoutAccept(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for enhancement retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryEnhance(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryEnhance(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryOptimize(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryOptimize(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryUpload(uuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryUpload(uuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the hmm report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {SetMarketReportsVaFileRequest} [setMarketReportsVaFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHmmReports1(xApiKey: string, setMarketReportsVaFileRequest?: SetMarketReportsVaFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHmmReports1(xApiKey, setMarketReportsVaFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the market report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {any} reports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMarketReportsVaFile(xApiKey: string, reports: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMarketReportsVaFile(xApiKey, reports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administration endpoint for update market with today data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketFromTodayReports(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketFromTodayReports(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recomputation of old missing validation configurations
         * @param {string} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateConstraintsConfiguration(uuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentConfigurationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateConstraintsConfiguration(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if investment exists. Used for a cleaning endpoint.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCheckIfInvestmentExists(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.appControllerCheckIfInvestmentExists(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEnhancementWithPreviousSettings(xApiKey: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.bulkEnhancementWithPreviousSettings(xApiKey, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {Instructions} instructions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateNewDataFromInstructions(xApiKey: string, instructions: Instructions, options?: any): AxiosPromise<ReportDto> {
            return localVarFp.calculateNewDataFromInstructions(xApiKey, instructions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint to generate the alerts associated to a specific portfolio.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPtfAlertsMail(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkPtfAlertsMail(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force clean market view prefills cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanMarketViewCache(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.cleanMarketViewCache(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for clear cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearMarketCache(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.clearMarketCache(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for clear report cache.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPdfCache(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.clearPdfCache(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate retrieve and store commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndSaveCommentaryForDashboard(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createAndSaveCommentaryForDashboard(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCache(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createCache(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for create market commentaries
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary2(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createCommentary2(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for create outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary3(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<void> {
            return localVarFp.createCommentary3(xApiKey, assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for retrieve outlook focus commentaries for asset class geo and sector selected
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForAssetClass(xApiKey, assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate and retrieve commentary for dashboard
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForDashboard(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForDashboard(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestment(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForInvestment(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create commentary for a specific investment Enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process investment enhanced with error, return the uuid of investment not processed
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentEnhancedWithErrors(xApiKey: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.createCommentaryForInvestmentEnhancedWithErrors(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create commentary for a list of investments
         * @param {string} xApiKey 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestments(xApiKey: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.createCommentaryForInvestments(xApiKey, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestments1(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createCommentaryForInvestments1(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create and save chatgpt commentaries for all investments enhanced
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForInvestmentsEnhanced(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createCommentaryForInvestmentsEnhanced(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHistoricalData(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.createHistoricalData(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate prompt for a specific asset class 
         * @param {string} xApiKey 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromptForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<string> {
            return localVarFp.createPromptForAssetClass(xApiKey, assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootUser(email: string, xApiKey: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.createRootUser(email, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enhancementWithPreviousSettings(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.enhancementWithPreviousSettings(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for update market with yesterday data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUpdateMarket(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.forceUpdateMarket(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all reference universes that are in ready state.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReadyRefenceUniversesList(xApiKey: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAdminReadyRefenceUniversesList(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all active reference universes.
         * @param {string} universeUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReferenceUniverseDetails(universeUuid: string, xApiKey: string, options?: any): AxiosPromise<ReferenceUniverse> {
            return localVarFp.getAdminReferenceUniverseDetails(universeUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersEmails(xApiKey: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllUsersEmails(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkCompositions(xApiKey: string, benchmarkIdentifier: string, options?: any): AxiosPromise<InvestmentCompositionsResponse> {
            return localVarFp.getBenchmarkCompositions(xApiKey, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary4(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCommentary4(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve commentary for a specific investment
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaryMertrics(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCommentaryMertrics(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment, and the enhancement one if present.
         * @param {string} xApiKey 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentCompositions(xApiKey: string, investmentUuid: string, options?: any): AxiosPromise<InvestmentCompositionsResponse> {
            return localVarFp.getInvestmentCompositions(xApiKey, investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentWithErrors(xApiKey: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getInvestmentWithErrors(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download history the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPreviousPtf(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<any> {
            return localVarFp.getJsonOfPreviousPtf(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download the json of ptf
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPtf(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<any> {
            return localVarFp.getJsonOfPtf(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download the json of ptf enhanced
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonOfPtfEnhanced(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<any> {
            return localVarFp.getJsonOfPtfEnhanced(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all user emails.
         * @param {string} xApiKey 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences(xApiKey: string, userEmail: string, options?: any): AxiosPromise<UserProxyPreferencesResponse> {
            return localVarFp.getUserPreferences(xApiKey, userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check endpoint of the component.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.healthCheck(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import investment for a specific Customer
         * @param {string} xApiKey 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvestmentForCustomer(xApiKey: string, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.importInvestmentForCustomer(xApiKey, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for sent market notifications.
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMarketNotification(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.processMarketNotification(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for calculate all notifications for all user but not sent it
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processNotification(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.processNotification(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replica last market data available until today
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replicaDataUntilToday(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.replicaDataUntilToday(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrieveCompositionForInvestment(userId: string, investmentName: string, xApiKey: string, options?: any): AxiosPromise<InvestmentComposition> {
            return localVarFp.retrieveCompositionForInvestment(userId, investmentName, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current composition of the investment enhanced 
         * @param {string} userId 
         * @param {string} investmentName 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrieveCompositionForInvestmentEnhanced(userId: string, investmentName: string, xApiKey: string, options?: any): AxiosPromise<InvestmentEnhancedComposition> {
            return localVarFp.retrieveCompositionForInvestmentEnhanced(userId, investmentName, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarEdit(identifier: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryBenchmarEdit(identifier, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for Benchmark creation retry.
         * @param {string} identifier 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarkUpload(identifier: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryBenchmarkUpload(identifier, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBenchmarksBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryBenchmarksBulk(xApiKey, bulkActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for any investment bulk retry.
         * @param {string} xApiKey 
         * @param {BulkActionRequest} bulkActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryBulk(xApiKey, bulkActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryCreate(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryCreate(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryEditWithoutAccept(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryEditWithoutAccept(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for enhancement retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryEnhance(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryEnhance(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for optimization retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryOptimize(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryOptimize(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for creation retry.
         * @param {string} uuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryUpload(uuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.retryUpload(uuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the hmm report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {SetMarketReportsVaFileRequest} [setMarketReportsVaFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports1(xApiKey: string, setMarketReportsVaFileRequest?: SetMarketReportsVaFileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setHmmReports1(xApiKey, setMarketReportsVaFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Utility endpoints. Allow setting the market report via file, instead of plain JSON.
         * @param {string} xApiKey 
         * @param {any} reports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReportsVaFile(xApiKey: string, reports: any, options?: any): AxiosPromise<void> {
            return localVarFp.setMarketReportsVaFile(xApiKey, reports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administration endpoint for update market with today data
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketFromTodayReports(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateMarketFromTodayReports(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recomputation of old missing validation configurations
         * @param {string} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateConstraintsConfiguration(uuid?: string, options?: any): AxiosPromise<Array<InvestmentConfigurationResponse>> {
            return localVarFp.validateConstraintsConfiguration(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @summary Check if investment exists. Used for a cleaning endpoint.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public appControllerCheckIfInvestmentExists(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).appControllerCheckIfInvestmentExists(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for creation retry.
     * @param {string} xApiKey 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public bulkEnhancementWithPreviousSettings(xApiKey: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).bulkEnhancementWithPreviousSettings(xApiKey, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment, and the enhancement one if present.
     * @param {string} xApiKey 
     * @param {Instructions} instructions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public calculateNewDataFromInstructions(xApiKey: string, instructions: Instructions, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).calculateNewDataFromInstructions(xApiKey, instructions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint to generate the alerts associated to a specific portfolio.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public checkPtfAlertsMail(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).checkPtfAlertsMail(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force clean market view prefills cache.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public cleanMarketViewCache(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).cleanMarketViewCache(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for clear cache.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public clearMarketCache(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).clearMarketCache(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for clear report cache.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public clearPdfCache(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).clearPdfCache(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate retrieve and store commentary for dashboard
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createAndSaveCommentaryForDashboard(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createAndSaveCommentaryForDashboard(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for create market commentaries
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCache(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCache(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for create market commentaries
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentary2(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentary2(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for create outlook focus commentaries for asset class geo and sector selected
     * @param {string} xApiKey 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentary3(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentary3(xApiKey, assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for retrieve outlook focus commentaries for asset class geo and sector selected
     * @param {string} xApiKey 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForAssetClass(xApiKey, assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate and retrieve commentary for dashboard
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForDashboard(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForDashboard(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create commentary for a specific investment
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestment(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestment(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create commentary for a specific investment Enhanced
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process investment enhanced with error, return the uuid of investment not processed
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestmentEnhancedWithErrors(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestmentEnhancedWithErrors(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create commentary for a list of investments
     * @param {string} xApiKey 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestments(xApiKey: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestments(xApiKey, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create and save chatgpt commentaries for all investments
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestments1(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestments1(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create and save chatgpt commentaries for all investments enhanced
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createCommentaryForInvestmentsEnhanced(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createCommentaryForInvestmentsEnhanced(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment, and the enhancement one if present.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createHistoricalData(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createHistoricalData(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate prompt for a specific asset class 
     * @param {string} xApiKey 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createPromptForAssetClass(xApiKey: string, assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createPromptForAssetClass(xApiKey, assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public createRootUser(email: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).createRootUser(email, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for creation retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public enhancementWithPreviousSettings(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).enhancementWithPreviousSettings(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for update market with yesterday data
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public forceUpdateMarket(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).forceUpdateMarket(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all reference universes that are in ready state.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAdminReadyRefenceUniversesList(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAdminReadyRefenceUniversesList(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all active reference universes.
     * @param {string} universeUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAdminReferenceUniverseDetails(universeUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAdminReferenceUniverseDetails(universeUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all user emails.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAllUsersEmails(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getAllUsersEmails(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment, and the enhancement one if present.
     * @param {string} xApiKey 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getBenchmarkCompositions(xApiKey: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getBenchmarkCompositions(xApiKey, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve commentary for a specific investment
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCommentary4(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getCommentary4(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve commentary for a specific investment enhanced
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCommentaryForInvestmentEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getCommentaryForInvestmentEnhanced(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve commentary for a specific investment
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCommentaryMertrics(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getCommentaryMertrics(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment, and the enhancement one if present.
     * @param {string} xApiKey 
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getInvestmentCompositions(xApiKey: string, investmentUuid: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getInvestmentCompositions(xApiKey, investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getInvestmentWithErrors(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getInvestmentWithErrors(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download history the json of ptf
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getJsonOfPreviousPtf(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getJsonOfPreviousPtf(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download the json of ptf
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getJsonOfPtf(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getJsonOfPtf(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download the json of ptf enhanced
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getJsonOfPtfEnhanced(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getJsonOfPtfEnhanced(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all user emails.
     * @param {string} xApiKey 
     * @param {string} userEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUserPreferences(xApiKey: string, userEmail: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getUserPreferences(xApiKey, userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check endpoint of the component.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public healthCheck(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).healthCheck(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import investment for a specific Customer
     * @param {string} xApiKey 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public importInvestmentForCustomer(xApiKey: string, email: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).importInvestmentForCustomer(xApiKey, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for sent market notifications.
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public processMarketNotification(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).processMarketNotification(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for calculate all notifications for all user but not sent it
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public processNotification(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).processNotification(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replica last market data available until today
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public replicaDataUntilToday(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).replicaDataUntilToday(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment
     * @param {string} userId 
     * @param {string} investmentName 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retrieveCompositionForInvestment(userId: string, investmentName: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retrieveCompositionForInvestment(userId, investmentName, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current composition of the investment enhanced 
     * @param {string} userId 
     * @param {string} investmentName 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retrieveCompositionForInvestmentEnhanced(userId: string, investmentName: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retrieveCompositionForInvestmentEnhanced(userId, investmentName, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for Benchmark creation retry.
     * @param {string} identifier 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryBenchmarEdit(identifier: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryBenchmarEdit(identifier, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for Benchmark creation retry.
     * @param {string} identifier 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryBenchmarkUpload(identifier: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryBenchmarkUpload(identifier, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for any investment bulk retry.
     * @param {string} xApiKey 
     * @param {BulkActionRequest} bulkActionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryBenchmarksBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryBenchmarksBulk(xApiKey, bulkActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for any investment bulk retry.
     * @param {string} xApiKey 
     * @param {BulkActionRequest} bulkActionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryBulk(xApiKey: string, bulkActionRequest: BulkActionRequest, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryBulk(xApiKey, bulkActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for creation retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryCreate(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryCreate(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for optimization retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryEditWithoutAccept(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryEditWithoutAccept(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for enhancement retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryEnhance(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryEnhance(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for optimization retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryOptimize(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryOptimize(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for creation retry.
     * @param {string} uuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public retryUpload(uuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).retryUpload(uuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Utility endpoints. Allow setting the hmm report via file, instead of plain JSON.
     * @param {string} xApiKey 
     * @param {SetMarketReportsVaFileRequest} [setMarketReportsVaFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public setHmmReports1(xApiKey: string, setMarketReportsVaFileRequest?: SetMarketReportsVaFileRequest, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).setHmmReports1(xApiKey, setMarketReportsVaFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Utility endpoints. Allow setting the market report via file, instead of plain JSON.
     * @param {string} xApiKey 
     * @param {any} reports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public setMarketReportsVaFile(xApiKey: string, reports: any, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).setMarketReportsVaFile(xApiKey, reports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administration endpoint for update market with today data
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateMarketFromTodayReports(xApiKey: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).updateMarketFromTodayReports(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recomputation of old missing validation configurations
     * @param {string} [uuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public validateConstraintsConfiguration(uuid?: string, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).validateConstraintsConfiguration(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change user password.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('changePassword', 'userDTO', userDTO)
            const localVarPath = `/auth/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary In case a user forgot the password, it sends an email to the user to reset it.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgot: async (userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('forgot', 'userDTO', userDTO)
            const localVarPath = `/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in as a Platform user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('login', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the user password, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset: async (userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('reset', 'userDTO', userDTO)
            const localVarPath = `/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user email, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmail: async (userDTO: UserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('retrieveEmail', 'userDTO', userDTO)
            const localVarPath = `/auth/retrieve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change user password.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(userDTO: UserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary In case a user forgot the password, it sends an email to the user to reset it.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgot(userDTO: UserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgot(userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log in as a Platform user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the user password, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reset(userDTO: UserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reset(userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user email, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveEmail(userDTO: UserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveEmail(userDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Change user password.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userDTO: UserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary In case a user forgot the password, it sends an email to the user to reset it.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgot(userDTO: UserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.forgot(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in as a Platform user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): AxiosPromise<JwtResponse> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the user password, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset(userDTO: UserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.reset(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user email, authenticating with the reset token.
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmail(userDTO: UserDTO, options?: any): AxiosPromise<string> {
            return localVarFp.retrieveEmail(userDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change user password.
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public changePassword(userDTO: UserDTO, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).changePassword(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary In case a user forgot the password, it sends an email to the user to reset it.
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public forgot(userDTO: UserDTO, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).forgot(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in as a Platform user.
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public login(login: Login, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the user password, authenticating with the reset token.
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public reset(userDTO: UserDTO, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).reset(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user email, authenticating with the reset token.
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public retrieveEmail(userDTO: UserDTO, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).retrieveEmail(userDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * B2BMarketControllerApi - axios parameter creator
 * @export
 */
export const B2BMarketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveForecast: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b2b/market/forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningIndicators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b2b/market/positioning-indicators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeProbability: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b2b/market/regimes/probabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeProbability2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b2b/market/regimes/probabilities-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * B2BMarketControllerApi - functional programming interface
 * @export
 */
export const B2BMarketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = B2BMarketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveForecast(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketForecastDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveForecast(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePositioningIndicators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketForecastDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePositioningIndicators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRegimeProbability(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketRegimeProbabilityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRegimeProbability(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRegimeProbability2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2MarketRegimeProbabilityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRegimeProbability2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * B2BMarketControllerApi - factory interface
 * @export
 */
export const B2BMarketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = B2BMarketControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveForecast(options?: any): AxiosPromise<Array<MarketForecastDTO>> {
            return localVarFp.retrieveForecast(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningIndicators(options?: any): AxiosPromise<Array<MarketForecastDTO>> {
            return localVarFp.retrievePositioningIndicators(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeProbability(options?: any): AxiosPromise<MarketRegimeProbabilityDTO> {
            return localVarFp.retrieveRegimeProbability(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeProbability2(options?: any): AxiosPromise<V2MarketRegimeProbabilityDTO> {
            return localVarFp.retrieveRegimeProbability2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * B2BMarketControllerApi - object-oriented interface
 * @export
 * @class B2BMarketControllerApi
 * @extends {BaseAPI}
 */
export class B2BMarketControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2BMarketControllerApi
     */
    public retrieveForecast(options?: AxiosRequestConfig) {
        return B2BMarketControllerApiFp(this.configuration).retrieveForecast(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2BMarketControllerApi
     */
    public retrievePositioningIndicators(options?: AxiosRequestConfig) {
        return B2BMarketControllerApiFp(this.configuration).retrievePositioningIndicators(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2BMarketControllerApi
     */
    public retrieveRegimeProbability(options?: AxiosRequestConfig) {
        return B2BMarketControllerApiFp(this.configuration).retrieveRegimeProbability(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2BMarketControllerApi
     */
    public retrieveRegimeProbability2(options?: AxiosRequestConfig) {
        return B2BMarketControllerApiFp(this.configuration).retrieveRegimeProbability2(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BackofficeControllerApi - axios parameter creator
 * @export
 */
export const BackofficeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForAssetClassFromPrompt: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', templateDto: TemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('createCommentaryForAssetClassFromPrompt', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('createCommentaryForAssetClassFromPrompt', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('createCommentaryForAssetClassFromPrompt', 'sector', sector)
            // verify required parameter 'templateDto' is not null or undefined
            assertParamExists('createCommentaryForAssetClassFromPrompt', 'templateDto', templateDto)
            const localVarPath = `/backoffice/createCommentary/asset-class/from-prompt/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForDashboardFromPrompt: async (templateDto: TemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateDto' is not null or undefined
            assertParamExists('createCommentaryForDashboardFromPrompt', 'templateDto', templateDto)
            const localVarPath = `/backoffice/createCommentary/from-prompt/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeControllerApi - functional programming interface
 * @export
 */
export const BackofficeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackofficeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForAssetClassFromPrompt(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', templateDto: TemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForAssetClassFromPrompt(assetClass, geography, sector, templateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryForDashboardFromPrompt(templateDto: TemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryForDashboardFromPrompt(templateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackofficeControllerApi - factory interface
 * @export
 */
export const BackofficeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackofficeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForAssetClassFromPrompt(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', templateDto: TemplateDto, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForAssetClassFromPrompt(assetClass, geography, sector, templateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryForDashboardFromPrompt(templateDto: TemplateDto, options?: any): AxiosPromise<string> {
            return localVarFp.createCommentaryForDashboardFromPrompt(templateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackofficeControllerApi - object-oriented interface
 * @export
 * @class BackofficeControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeControllerApi extends BaseAPI {
    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {TemplateDto} templateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeControllerApi
     */
    public createCommentaryForAssetClassFromPrompt(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', templateDto: TemplateDto, options?: AxiosRequestConfig) {
        return BackofficeControllerApiFp(this.configuration).createCommentaryForAssetClassFromPrompt(assetClass, geography, sector, templateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TemplateDto} templateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeControllerApi
     */
    public createCommentaryForDashboardFromPrompt(templateDto: TemplateDto, options?: AxiosRequestConfig) {
        return BackofficeControllerApiFp(this.configuration).createCommentaryForDashboardFromPrompt(templateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BenchmarksControllerApi - axios parameter creator
 * @export
 */
export const BenchmarksControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmark: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('deleteBenchmark', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/benchmarks/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a copy of the given benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBenchmark: async (benchmarkIdentifier: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('duplicateBenchmark', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('duplicateBenchmark', 'name', name)
            const localVarPath = `/v4/benchmarks/duplicate/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportComposition1: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('exportComposition1', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/benchmarks/export/composition/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL2: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getACL2', 'uuid', uuid)
            const localVarPath = `/v4/benchmarks/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter2: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getAclEditStarter2', 'uuid', uuid)
            const localVarPath = `/v4/benchmarks/{uuid}/acl-edit-starter`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns composition of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkComposition: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getBenchmarkComposition', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/benchmarks/reports/composition/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of benchmarks usable on the creation/upload flow, available for the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/benchmarks/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the header informations of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkSummary: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getBenchmarkSummary', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/benchmarks/reports/summary/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the full benchmark information.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullBenchmark: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getFullBenchmark', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/benchmarks/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL2: async (uuid: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserACL2', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserACL2', 'userId', userId)
            const localVarPath = `/v4/benchmarks/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission2: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('grantUserPermission2', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('grantUserPermission2', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('grantUserPermission2', 'permission', permission)
            const localVarPath = `/v4/benchmarks/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission2: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('hasUserPermission2', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasUserPermission2', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('hasUserPermission2', 'permission', permission)
            const localVarPath = `/v4/benchmarks/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks wether the user can use this name for the given benchmark.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isBenchmarkNameAvailable: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('isBenchmarkNameAvailable', 'name', name)
            const localVarPath = `/v4/benchmarks/name-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the name of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameBenchmark: async (benchmarkIdentifier: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('renameBenchmark', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameBenchmark', 'name', name)
            const localVarPath = `/v4/benchmarks/rename/{benchmark-identifier}`
                .replace(`{${"benchmark-identifier"}}`, encodeURIComponent(String(benchmarkIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission2: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('revokeUserPermission2', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('revokeUserPermission2', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('revokeUserPermission2', 'permission', permission)
            const localVarPath = `/v4/benchmarks/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL2: async (uuid: string, richAccessControl: Array<RichAccessControl>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setACL2', 'uuid', uuid)
            // verify required parameter 'richAccessControl' is not null or undefined
            assertParamExists('setACL2', 'richAccessControl', richAccessControl)
            const localVarPath = `/v4/benchmarks/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richAccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL2: async (uuid: string, userId: string, accessPermission: Array<AccessPermission>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setUserACL2', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserACL2', 'userId', userId)
            // verify required parameter 'accessPermission' is not null or undefined
            assertParamExists('setUserACL2', 'accessPermission', accessPermission)
            const localVarPath = `/v4/benchmarks/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenchmarksControllerApi - functional programming interface
 * @export
 */
export const BenchmarksControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenchmarksControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenchmark(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenchmark(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a copy of the given benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateBenchmark(benchmarkIdentifier: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkMinInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateBenchmark(benchmarkIdentifier, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportComposition1(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportComposition1(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getACL2(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getACL2(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAclEditStarter2(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclEditStarter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAclEditStarter2(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns composition of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkComposition(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RichTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkComposition(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of benchmarks usable on the creation/upload flow, available for the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentReferenceListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the header informations of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkSummary(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkSummary(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the full benchmark information.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullBenchmark(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullBenchmark(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserACL2(uuid: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichAccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserACL2(uuid, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantUserPermission2(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserPermission2(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks wether the user can use this name for the given benchmark.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isBenchmarkNameAvailable(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isBenchmarkNameAvailable(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the name of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameBenchmark(benchmarkIdentifier: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameBenchmark(benchmarkIdentifier, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserPermission2(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setACL2(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setACL2(uuid, richAccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserACL2(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserACL2(uuid, userId, accessPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenchmarksControllerApi - factory interface
 * @export
 */
export const BenchmarksControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenchmarksControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmark(benchmarkIdentifier: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenchmark(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a copy of the given benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBenchmark(benchmarkIdentifier: string, name: string, options?: any): AxiosPromise<BenchmarkMinInfo> {
            return localVarFp.duplicateBenchmark(benchmarkIdentifier, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportComposition1(benchmarkIdentifier: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportComposition1(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL2(uuid: string, options?: any): AxiosPromise<Array<AccessControl>> {
            return localVarFp.getACL2(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter2(uuid: string, options?: any): AxiosPromise<AclEditStarter> {
            return localVarFp.getAclEditStarter2(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns composition of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkComposition(benchmarkIdentifier: string, options?: any): AxiosPromise<Array<RichTicker>> {
            return localVarFp.getBenchmarkComposition(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of benchmarks usable on the creation/upload flow, available for the given type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkList(options?: any): AxiosPromise<Array<InvestmentReferenceListEntry>> {
            return localVarFp.getBenchmarkList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the header informations of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkSummary(benchmarkIdentifier: string, options?: any): AxiosPromise<BenchmarkSummary> {
            return localVarFp.getBenchmarkSummary(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the full benchmark information.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullBenchmark(benchmarkIdentifier: string, options?: any): AxiosPromise<BenchmarkModel> {
            return localVarFp.getFullBenchmark(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL2(uuid: string, userId: string, options?: any): AxiosPromise<RichAccessControl> {
            return localVarFp.getUserACL2(uuid, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.grantUserPermission2(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasUserPermission2(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks wether the user can use this name for the given benchmark.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isBenchmarkNameAvailable(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isBenchmarkNameAvailable(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the name of the benchmark.
         * @param {string} benchmarkIdentifier 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameBenchmark(benchmarkIdentifier: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.renameBenchmark(benchmarkIdentifier, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.revokeUserPermission2(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL2(uuid: string, richAccessControl: Array<RichAccessControl>, options?: any): AxiosPromise<void> {
            return localVarFp.setACL2(uuid, richAccessControl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL2(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserACL2(uuid, userId, accessPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenchmarksControllerApi - object-oriented interface
 * @export
 * @class BenchmarksControllerApi
 * @extends {BaseAPI}
 */
export class BenchmarksControllerApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the benchmark.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public deleteBenchmark(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).deleteBenchmark(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a copy of the given benchmark.
     * @param {string} benchmarkIdentifier 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public duplicateBenchmark(benchmarkIdentifier: string, name: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).duplicateBenchmark(benchmarkIdentifier, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public exportComposition1(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).exportComposition1(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getACL2(uuid: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getACL2(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getAclEditStarter2(uuid: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getAclEditStarter2(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns composition of the benchmark.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getBenchmarkComposition(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getBenchmarkComposition(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of benchmarks usable on the creation/upload flow, available for the given type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getBenchmarkList(options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getBenchmarkList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the header informations of the benchmark.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getBenchmarkSummary(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getBenchmarkSummary(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the full benchmark information.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getFullBenchmark(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getFullBenchmark(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public getUserACL2(uuid: string, userId: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).getUserACL2(uuid, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public grantUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).grantUserPermission2(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public hasUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).hasUserPermission2(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks wether the user can use this name for the given benchmark.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public isBenchmarkNameAvailable(name: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).isBenchmarkNameAvailable(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the name of the benchmark.
     * @param {string} benchmarkIdentifier 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public renameBenchmark(benchmarkIdentifier: string, name: string, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).renameBenchmark(benchmarkIdentifier, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public revokeUserPermission2(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).revokeUserPermission2(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {Array<RichAccessControl>} richAccessControl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public setACL2(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).setACL2(uuid, richAccessControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {Array<AccessPermission>} accessPermission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksControllerApi
     */
    public setUserACL2(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig) {
        return BenchmarksControllerApiFp(this.configuration).setUserACL2(uuid, userId, accessPermission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BucketControllerApi - axios parameter creator
 * @export
 */
export const BucketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/s3/uploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BucketControllerApi - functional programming interface
 * @export
 */
export const BucketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BucketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BucketControllerApi - factory interface
 * @export
 */
export const BucketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BucketControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BucketControllerApi - object-oriented interface
 * @export
 * @class BucketControllerApi
 * @extends {BaseAPI}
 */
export class BucketControllerApi extends BaseAPI {
    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketControllerApi
     */
    public uploadFile(file: any, options?: AxiosRequestConfig) {
        return BucketControllerApiFp(this.configuration).uploadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CherryBankControllerApi - axios parameter creator
 * @export
 */
export const CherryBankControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExcelWithTaskConfigurations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/report/standard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommoditiesContinuousTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/commodities/timeseries/continuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommoditiesTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/commodities/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquities: async (geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/equities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (geography !== undefined) {
                localVarQueryParameter['geography'] = geography;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquitiesTimeSeries: async (geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/equities/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (geography !== undefined) {
                localVarQueryParameter['geography'] = geography;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquitiesTimeSeriesContinuous: async (geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/equities/timeseries/continuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (geography !== undefined) {
                localVarQueryParameter['geography'] = geography;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFii: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/fiis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFiiContinuousTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/fiis/timeseries/continuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFiiTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/fiis/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSector: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/sector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSectorContinuousTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/sector/timeseries/continuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSectorTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/sector/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCherryBankCommodities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/dashboard/commodities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v5/cherrybank/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CherryBankControllerApi - functional programming interface
 * @export
 */
export const CherryBankControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CherryBankControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExcelWithTaskConfigurations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExcelWithTaskConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetCommoditiesTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetCommoditiesTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetEquities(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetEquities(geography, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetEquitiesTimeSeries(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetEquitiesTimeSeries(geography, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetEquitiesTimeSeriesContinuous(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetEquitiesTimeSeriesContinuous(geography, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetFii(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetFii(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetFiiContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetFiiContinuousTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetFiiTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetFiiTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async dashboardControllerGetSector(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetSector(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async dashboardControllerGetSectorContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetSectorContinuousTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async dashboardControllerGetSectorTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetSectorTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCherryBankCommodities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCherryBankCommodities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CherryBankControllerApi - factory interface
 * @export
 */
export const CherryBankControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CherryBankControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExcelWithTaskConfigurations(options?: any): AxiosPromise<any> {
            return localVarFp.createExcelWithTaskConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommoditiesTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetCommoditiesTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquities(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.dashboardControllerGetEquities(geography, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquitiesTimeSeries(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetEquitiesTimeSeries(geography, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetEquitiesTimeSeriesContinuous(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetEquitiesTimeSeriesContinuous(geography, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFii(options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.dashboardControllerGetFii(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFiiContinuousTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetFiiContinuousTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFiiTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetFiiTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSector(options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.dashboardControllerGetSector(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSectorContinuousTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetSectorContinuousTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerGetSectorTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.dashboardControllerGetSectorTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCherryBankCommodities(options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.retrieveCherryBankCommodities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReport(options?: any): AxiosPromise<FileResponse> {
            return localVarFp.retrieveReport(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CherryBankControllerApi - object-oriented interface
 * @export
 * @class CherryBankControllerApi
 * @extends {BaseAPI}
 */
export class CherryBankControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public createExcelWithTaskConfigurations(options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).createExcelWithTaskConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetCommoditiesContinuousTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetCommoditiesTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetCommoditiesTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetEquities(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetEquities(geography, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetEquitiesTimeSeries(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetEquitiesTimeSeries(geography, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} [geography] 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetEquitiesTimeSeriesContinuous(geography?: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetEquitiesTimeSeriesContinuous(geography, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetFii(options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetFii(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetFiiContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetFiiContinuousTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetFiiTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetFiiTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetSector(options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetSector(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetSectorContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetSectorContinuousTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public dashboardControllerGetSectorTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).dashboardControllerGetSectorTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public retrieveCherryBankCommodities(options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).retrieveCherryBankCommodities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CherryBankControllerApi
     */
    public retrieveReport(options?: AxiosRequestConfig) {
        return CherryBankControllerApiFp(this.configuration).retrieveReport(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class0GenericCommentariesApi - axios parameter creator
 * @export
 */
export const Class0GenericCommentariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateInvestmentCommentary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('calculateInvestmentCommentary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/commentaries/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompt: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getPrompt', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/commentaries/prompt/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class0GenericCommentariesApi - functional programming interface
 * @export
 */
export const Class0GenericCommentariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class0GenericCommentariesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateInvestmentCommentary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateInvestmentCommentary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrompt(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrompt(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class0GenericCommentariesApi - factory interface
 * @export
 */
export const Class0GenericCommentariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class0GenericCommentariesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateInvestmentCommentary(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculateInvestmentCommentary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompt(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPrompt(investmentUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Class0GenericCommentariesApi - object-oriented interface
 * @export
 * @class Class0GenericCommentariesApi
 * @extends {BaseAPI}
 */
export class Class0GenericCommentariesApi extends BaseAPI {
    /**
     * 
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class0GenericCommentariesApi
     */
    public calculateInvestmentCommentary(investmentUuid: string, options?: AxiosRequestConfig) {
        return Class0GenericCommentariesApiFp(this.configuration).calculateInvestmentCommentary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class0GenericCommentariesApi
     */
    public getPrompt(investmentUuid: string, options?: AxiosRequestConfig) {
        return Class0GenericCommentariesApiFp(this.configuration).getPrompt(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Class1EnhancementCommentariesApi - axios parameter creator
 * @export
 */
export const Class1EnhancementCommentariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedInvestmentCommentary: async (investmentEnhancedUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentEnhancedUuid' is not null or undefined
            assertParamExists('getEnhancedInvestmentCommentary', 'investmentEnhancedUuid', investmentEnhancedUuid)
            const localVarPath = `/v2/commentaries/enhancement/commentary/{investment-enhanced-uuid}`
                .replace(`{${"investment-enhanced-uuid"}}`, encodeURIComponent(String(investmentEnhancedUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedPrompt: async (investmentEnhancedUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentEnhancedUuid' is not null or undefined
            assertParamExists('getEnhancedPrompt', 'investmentEnhancedUuid', investmentEnhancedUuid)
            const localVarPath = `/v2/commentaries/enhancement/prompt/{investment-enhanced-uuid}`
                .replace(`{${"investment-enhanced-uuid"}}`, encodeURIComponent(String(investmentEnhancedUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Class1EnhancementCommentariesApi - functional programming interface
 * @export
 */
export const Class1EnhancementCommentariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Class1EnhancementCommentariesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancedInvestmentCommentary(investmentEnhancedUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancedPrompt(investmentEnhancedUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancedPrompt(investmentEnhancedUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Class1EnhancementCommentariesApi - factory interface
 * @export
 */
export const Class1EnhancementCommentariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Class1EnhancementCommentariesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEnhancedInvestmentCommentary(investmentEnhancedUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedPrompt(investmentEnhancedUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEnhancedPrompt(investmentEnhancedUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Class1EnhancementCommentariesApi - object-oriented interface
 * @export
 * @class Class1EnhancementCommentariesApi
 * @extends {BaseAPI}
 */
export class Class1EnhancementCommentariesApi extends BaseAPI {
    /**
     * 
     * @param {string} investmentEnhancedUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class1EnhancementCommentariesApi
     */
    public getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: AxiosRequestConfig) {
        return Class1EnhancementCommentariesApiFp(this.configuration).getEnhancedInvestmentCommentary(investmentEnhancedUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentEnhancedUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Class1EnhancementCommentariesApi
     */
    public getEnhancedPrompt(investmentEnhancedUuid: string, options?: AxiosRequestConfig) {
        return Class1EnhancementCommentariesApiFp(this.configuration).getEnhancedPrompt(investmentEnhancedUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentariesControllerApi - axios parameter creator
 * @export
 */
export const CommentariesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCommentaryForInstrument: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('calculateCommentaryForInstrument', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('calculateCommentaryForInstrument', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/commentary/instrument/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateInvestmentCommentary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('calculateInvestmentCommentary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/commentaries/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePromptForInstrument: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('calculatePromptForInstrument', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('calculatePromptForInstrument', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/prompt/instrument/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the commentary that has been saved in memory with the given label.
         * @summary Deletes a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentary: async (label: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('deleteCommentary', 'label', label)
            const localVarPath = `/v2/commentaries/{label}`
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentaryFromCache: async (isin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('deleteCommentaryFromCache', 'isin', isin)
            const localVarPath = `/v2/commentaries/commentary/instrument/cache/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary1: async (label: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('getCommentary1', 'label', label)
            const localVarPath = `/v2/commentaries/{label}`
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary2: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('getCommentary2', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('getCommentary2', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('getCommentary2', 'sector', sector)
            const localVarPath = `/v2/commentaries/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a dashboard commentary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/commentaries/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedInvestmentCommentary: async (investmentEnhancedUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentEnhancedUuid' is not null or undefined
            assertParamExists('getEnhancedInvestmentCommentary', 'investmentEnhancedUuid', investmentEnhancedUuid)
            const localVarPath = `/v2/commentaries/enhancement/commentary/{investment-enhanced-uuid}`
                .replace(`{${"investment-enhanced-uuid"}}`, encodeURIComponent(String(investmentEnhancedUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedPrompt: async (investmentEnhancedUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentEnhancedUuid' is not null or undefined
            assertParamExists('getEnhancedPrompt', 'investmentEnhancedUuid', investmentEnhancedUuid)
            const localVarPath = `/v2/commentaries/enhancement/prompt/{investment-enhanced-uuid}`
                .replace(`{${"investment-enhanced-uuid"}}`, encodeURIComponent(String(investmentEnhancedUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompt: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getPrompt', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/commentaries/prompt/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryFromCache: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('retrieveCommentaryFromCache', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('retrieveCommentaryFromCache', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/commentary/instrument/cache/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the given commentary in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Inserts a specific commentary by label.
         * @param {string} label 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommentary: async (label: string, commentary: Commentary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('setCommentary', 'label', label)
            // verify required parameter 'commentary' is not null or undefined
            assertParamExists('setCommentary', 'commentary', commentary)
            const localVarPath = `/v2/commentaries/{label}`
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommentaryMacroContext: async (commentary: Commentary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentary' is not null or undefined
            assertParamExists('setCommentaryMacroContext', 'commentary', commentary)
            const localVarPath = `/v2/commentaries/macro-context`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentariesControllerApi - functional programming interface
 * @export
 */
export const CommentariesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentariesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateCommentaryForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateCommentaryForInstrument(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateInvestmentCommentary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateInvestmentCommentary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePromptForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePromptForInstrument(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the commentary that has been saved in memory with the given label.
         * @summary Deletes a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentary(label: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentary(label, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentaryFromCache(isin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentaryFromCache(isin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentary1(label: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentary1(label, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentary2(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentary2(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a dashboard commentary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentary3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentary3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancedInvestmentCommentary(investmentEnhancedUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancedPrompt(investmentEnhancedUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancedPrompt(investmentEnhancedUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrompt(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrompt(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCommentaryFromCache(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCommentaryFromCache(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves the given commentary in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Inserts a specific commentary by label.
         * @param {string} label 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCommentary(label: string, commentary: Commentary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCommentary(label, commentary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCommentaryMacroContext(commentary: Commentary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCommentaryMacroContext(commentary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentariesControllerApi - factory interface
 * @export
 */
export const CommentariesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentariesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCommentaryForInstrument(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculateCommentaryForInstrument(isin, instrument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateInvestmentCommentary(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculateInvestmentCommentary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePromptForInstrument(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculatePromptForInstrument(isin, instrument, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the commentary that has been saved in memory with the given label.
         * @summary Deletes a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentary(label: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCommentary(label, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentaryFromCache(isin: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCommentaryFromCache(isin, options).then((request) => request(axios, basePath));
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {string} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary1(label: string, options?: any): AxiosPromise<CommentaryDto> {
            return localVarFp.getCommentary1(label, options).then((request) => request(axios, basePath));
        },
        /**
         * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Get a specific commentary by label.
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary2(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<CommentaryDto> {
            return localVarFp.getCommentary2(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a dashboard commentary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary3(options?: any): AxiosPromise<CommentaryDto> {
            return localVarFp.getCommentary3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEnhancedInvestmentCommentary(investmentEnhancedUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentEnhancedUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancedPrompt(investmentEnhancedUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEnhancedPrompt(investmentEnhancedUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompt(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getPrompt(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryFromCache(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.retrieveCommentaryFromCache(isin, instrument, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the given commentary in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
         * @summary Inserts a specific commentary by label.
         * @param {string} label 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommentary(label: string, commentary: Commentary, options?: any): AxiosPromise<void> {
            return localVarFp.setCommentary(label, commentary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCommentaryMacroContext(commentary: Commentary, options?: any): AxiosPromise<void> {
            return localVarFp.setCommentaryMacroContext(commentary, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentariesControllerApi - object-oriented interface
 * @export
 * @class CommentariesControllerApi
 * @extends {BaseAPI}
 */
export class CommentariesControllerApi extends BaseAPI {
    /**
     * 
     * @summary calculate commentary for instrument
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public calculateCommentaryForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).calculateCommentaryForInstrument(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public calculateInvestmentCommentary(investmentUuid: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).calculateInvestmentCommentary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary calculate prompt for instrument
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public calculatePromptForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).calculatePromptForInstrument(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the commentary that has been saved in memory with the given label.
     * @summary Deletes a specific commentary by label.
     * @param {string} label 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public deleteCommentary(label: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).deleteCommentary(label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete commentary from cache
     * @param {string} isin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public deleteCommentaryFromCache(isin: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).deleteCommentaryFromCache(isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
     * @summary Get a specific commentary by label.
     * @param {string} label 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getCommentary1(label: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getCommentary1(label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Shows the commentary that has been saved in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
     * @summary Get a specific commentary by label.
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getCommentary2(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getCommentary2(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a dashboard commentary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getCommentary3(options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getCommentary3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentEnhancedUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getEnhancedInvestmentCommentary(investmentEnhancedUuid: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getEnhancedInvestmentCommentary(investmentEnhancedUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentEnhancedUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getEnhancedPrompt(investmentEnhancedUuid: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getEnhancedPrompt(investmentEnhancedUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public getPrompt(investmentUuid: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).getPrompt(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary RetrieveInstrumentFromCache
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public retrieveCommentaryFromCache(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).retrieveCommentaryFromCache(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the given commentary in memory with the given label. This value will be injected inside the market report when created. The comment is saved ad a file, so if durning the deploy you delete everything, the commentaries will be lost.
     * @summary Inserts a specific commentary by label.
     * @param {string} label 
     * @param {Commentary} commentary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public setCommentary(label: string, commentary: Commentary, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).setCommentary(label, commentary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Commentary} commentary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentariesControllerApi
     */
    public setCommentaryMacroContext(commentary: Commentary, options?: AxiosRequestConfig) {
        return CommentariesControllerApiFp(this.configuration).setCommentaryMacroContext(commentary, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConstantsControllerApi - axios parameter creator
 * @export
 */
export const ConstantsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTagLabels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/default-tag-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorVerifyExcelRequest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/editor-verify-excel-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentActions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/investment-actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/constants/investment-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConstantsControllerApi - functional programming interface
 * @export
 */
export const ConstantsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConstantsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrencies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Currencies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultTagLabels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultTagLabels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultTagLabels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorVerifyExcelRequest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorVerifyExcelRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorVerifyExcelRequest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentActions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentActions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentStatuses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConstantsControllerApi - factory interface
 * @export
 */
export const ConstantsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConstantsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarks(options?: any): AxiosPromise<BenchmarkIds> {
            return localVarFp.getBenchmarks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencies(options?: any): AxiosPromise<Currencies> {
            return localVarFp.getCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTagLabels(options?: any): AxiosPromise<DefaultTagLabels> {
            return localVarFp.getDefaultTagLabels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorVerifyExcelRequest(options?: any): AxiosPromise<EditorVerifyExcelRequest> {
            return localVarFp.getEditorVerifyExcelRequest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentActions(options?: any): AxiosPromise<InvestmentActions> {
            return localVarFp.getInvestmentActions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentStatuses(options?: any): AxiosPromise<InvestmentStatuses> {
            return localVarFp.getInvestmentStatuses(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConstantsControllerApi - object-oriented interface
 * @export
 * @class ConstantsControllerApi
 * @extends {BaseAPI}
 */
export class ConstantsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getBenchmarks(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getBenchmarks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getCurrencies(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getDefaultTagLabels(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getDefaultTagLabels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getEditorVerifyExcelRequest(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getEditorVerifyExcelRequest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getInvestmentActions(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getInvestmentActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy operation just for automatic DTO generation (don\'t remove it)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantsControllerApi
     */
    public getInvestmentStatuses(options?: AxiosRequestConfig) {
        return ConstantsControllerApiFp(this.configuration).getInvestmentStatuses(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerControllerApi - axios parameter creator
 * @export
 */
export const CustomerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCustomer1: async (customer: Customer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createCustomer1', 'customer', customer)
            const localVarPath = `/v2/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerModel} customerModel 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCustomer1: async (customerModel: CustomerModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerModel' is not null or undefined
            assertParamExists('updateCustomer1', 'customerModel', customerModel)
            const localVarPath = `/v2/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerControllerApi - functional programming interface
 * @export
 */
export const CustomerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createCustomer1(customer: Customer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer1(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerModel} customerModel 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateCustomer1(customerModel: CustomerModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer1(customerModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerControllerApi - factory interface
 * @export
 */
export const CustomerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCustomer1(customer: Customer, options?: any): AxiosPromise<CustomerModel> {
            return localVarFp.createCustomer1(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerModel} customerModel 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateCustomer1(customerModel: CustomerModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomer1(customerModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerControllerApi - object-oriented interface
 * @export
 * @class CustomerControllerApi
 * @extends {BaseAPI}
 */
export class CustomerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create new customer. (ROOT)
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustomerControllerApi
     */
    public createCustomer1(customer: Customer, options?: AxiosRequestConfig) {
        return CustomerControllerApiFp(this.configuration).createCustomer1(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerModel} customerModel 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CustomerControllerApi
     */
    public updateCustomer1(customerModel: CustomerModel, options?: AxiosRequestConfig) {
        return CustomerControllerApiFp(this.configuration).updateCustomer1(customerModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerControllerV3Api - axios parameter creator
 * @export
 */
export const CustomerControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerWithDto: async (customerDto: CustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerDto' is not null or undefined
            assertParamExists('createCustomerWithDto', 'customerDto', customerDto)
            const localVarPath = `/v3/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer details by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerById', 'id', id)
            const localVarPath = `/v3/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Expose all the import types. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServicesType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/customers/import/service-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer. (ROOT)
         * @param {CustomerModelDTO} customerModelDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (customerModelDTO: CustomerModelDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerModelDTO' is not null or undefined
            assertParamExists('updateCustomer', 'customerModelDTO', customerModelDTO)
            const localVarPath = `/v3/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerModelDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('users', 'customerId', customerId)
            const localVarPath = `/v3/customers/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/customers/sync/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerControllerV3Api - functional programming interface
 * @export
 */
export const CustomerControllerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerControllerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerWithDto(customerDto: CustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerModelDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerWithDto(customerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer details by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Expose all the import types. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveServicesType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentImportConverterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveServicesType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer. (ROOT)
         * @param {CustomerModelDTO} customerModelDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(customerModelDTO: CustomerModelDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerModelDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(customerModelDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async users(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.users(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async users1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.users1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerControllerV3Api - factory interface
 * @export
 */
export const CustomerControllerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerControllerV3ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerWithDto(customerDto: CustomerDto, options?: any): AxiosPromise<CustomerModelDTO> {
            return localVarFp.createCustomerWithDto(customerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer details by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(id: string, options?: any): AxiosPromise<CustomerDetailsDTO> {
            return localVarFp.getCustomerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Expose all the import types. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServicesType(options?: any): AxiosPromise<Array<InvestmentImportConverterType>> {
            return localVarFp.retrieveServicesType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer. (ROOT)
         * @param {CustomerModelDTO} customerModelDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(customerModelDTO: CustomerModelDTO, options?: any): AxiosPromise<CustomerModelDTO> {
            return localVarFp.updateCustomer(customerModelDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users(customerId: string, options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.users(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the active users for a given customer. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        users1(options?: any): AxiosPromise<SyncInfoDto> {
            return localVarFp.users1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerControllerV3Api - object-oriented interface
 * @export
 * @class CustomerControllerV3Api
 * @extends {BaseAPI}
 */
export class CustomerControllerV3Api extends BaseAPI {
    /**
     * 
     * @summary Create new customer. (ROOT)
     * @param {CustomerDto} customerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public createCustomerWithDto(customerDto: CustomerDto, options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).createCustomerWithDto(customerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer details by id.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public getCustomerById(id: string, options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).getCustomerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Expose all the import types. (ROOT)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public retrieveServicesType(options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).retrieveServicesType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer. (ROOT)
     * @param {CustomerModelDTO} customerModelDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public updateCustomer(customerModelDTO: CustomerModelDTO, options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).updateCustomer(customerModelDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the active users for a given customer. (ROOT)
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public users(customerId: string, options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).users(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the active users for a given customer. (ROOT)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerControllerV3Api
     */
    public users1(options?: AxiosRequestConfig) {
        return CustomerControllerV3ApiFp(this.configuration).users1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardControllerApi - axios parameter creator
 * @export
 */
export const DashboardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPostHmm: async (xApiKey: string, reportDto: ReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('dashboardControllerPostHmm', 'xApiKey', xApiKey)
            // verify required parameter 'reportDto' is not null or undefined
            assertParamExists('dashboardControllerPostHmm', 'reportDto', reportDto)
            const localVarPath = `/hmm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"trace\" or \"daily\") to create or update the entire data related to markets. If daily is uploaded, only the asset class metrics will be considered.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPostTrace1: async (xApiKey: string, marketReportDTO: MarketReportDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('dashboardControllerPostTrace1', 'xApiKey', xApiKey)
            // verify required parameter 'marketReportDTO' is not null or undefined
            assertParamExists('dashboardControllerPostTrace1', 'marketReportDTO', marketReportDTO)
            const localVarPath = `/dashboard/trace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketReportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerPostHmm(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerPostHmm(xApiKey, reportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"trace\" or \"daily\") to create or update the entire data related to markets. If daily is uploaded, only the asset class metrics will be considered.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerPostTrace1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerPostTrace1(xApiKey, marketReportDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPostHmm(xApiKey: string, reportDto: ReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardControllerPostHmm(xApiKey, reportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"trace\" or \"daily\") to create or update the entire data related to markets. If daily is uploaded, only the asset class metrics will be considered.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerPostTrace1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardControllerPostTrace1(xApiKey, marketReportDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} xApiKey 
     * @param {ReportDto} reportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public dashboardControllerPostHmm(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).dashboardControllerPostHmm(xApiKey, reportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json (key \"trace\" or \"daily\") to create or update the entire data related to markets. If daily is uploaded, only the asset class metrics will be considered.
     * @param {string} xApiKey 
     * @param {MarketReportDTO} marketReportDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public dashboardControllerPostTrace1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).dashboardControllerPostTrace1(xApiKey, marketReportDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardControllerV3Api - axios parameter creator
 * @export
 */
export const DashboardControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommentary: async (type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/dashboard/commentary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReturnAnalysisBands: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('dashboardControllerGetReturnAnalysisBands', 'type', type)
            const localVarPath = `/v3/dashboard/return-analysis-bands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetTrace: async (projection?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/dashboard/trace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projection !== undefined) {
                localVarQueryParameter['projection'] = projection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {any} [trace] file detail
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerPostTrace: async (xApiKey: string, trace?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('dashboardControllerPostTrace', 'xApiKey', xApiKey)
            const localVarPath = `/v3/dashboard/trace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


            if (trace !== undefined) { 
                localVarFormParams.append('trace', trace as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerV3Api - functional programming interface
 * @export
 */
export const DashboardControllerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardControllerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetCommentary(type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentaryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetCommentary(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetReturnAnalysisBands(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReturnAnalysisBandsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetReturnAnalysisBands(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetTrace(projection?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetTrace(projection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {any} [trace] file detail
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async dashboardControllerPostTrace(xApiKey: string, trace?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerPostTrace(xApiKey, trace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardControllerV3Api - factory interface
 * @export
 */
export const DashboardControllerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardControllerV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetCommentary(type?: string, options?: any): AxiosPromise<Array<CommentaryModel>> {
            return localVarFp.dashboardControllerGetCommentary(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReturnAnalysisBands(type: string, options?: any): AxiosPromise<Array<ReturnAnalysisBandsDto>> {
            return localVarFp.dashboardControllerGetReturnAnalysisBands(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetTrace(projection?: string, options?: any): AxiosPromise<string> {
            return localVarFp.dashboardControllerGetTrace(projection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {any} [trace] file detail
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        dashboardControllerPostTrace(xApiKey: string, trace?: any, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardControllerPostTrace(xApiKey, trace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardControllerV3Api - object-oriented interface
 * @export
 * @class DashboardControllerV3Api
 * @extends {BaseAPI}
 */
export class DashboardControllerV3Api extends BaseAPI {
    /**
     * 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerV3Api
     */
    public dashboardControllerGetCommentary(type?: string, options?: AxiosRequestConfig) {
        return DashboardControllerV3ApiFp(this.configuration).dashboardControllerGetCommentary(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerV3Api
     */
    public dashboardControllerGetReturnAnalysisBands(type: string, options?: AxiosRequestConfig) {
        return DashboardControllerV3ApiFp(this.configuration).dashboardControllerGetReturnAnalysisBands(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerV3Api
     */
    public dashboardControllerGetTrace(projection?: string, options?: AxiosRequestConfig) {
        return DashboardControllerV3ApiFp(this.configuration).dashboardControllerGetTrace(projection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {any} [trace] file detail
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DashboardControllerV3Api
     */
    public dashboardControllerPostTrace(xApiKey: string, trace?: any, options?: AxiosRequestConfig) {
        return DashboardControllerV3ApiFp(this.configuration).dashboardControllerPostTrace(xApiKey, trace, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EntityEditorControllerApi - axios parameter creator
 * @export
 */
export const EntityEditorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the instruments of the benchmark to add to the editor.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkInstruments: async (benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getBenchmarkInstruments', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v2/editor/benchmark-instruments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditComposition: async (identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getEditorEditComposition', 'identifier', identifier)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorEditComposition', 'entity', entity)
            const localVarPath = `/v1/editor/edit/composition/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectableBaskets: async (identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getEditorEditSelectableBaskets', 'identifier', identifier)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorEditSelectableBaskets', 'entity', entity)
            const localVarPath = `/v1/editor/edit/selectable-baskets/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectableInstruments: async (identifier: string, editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getEditorEditSelectableInstruments', 'identifier', identifier)
            // verify required parameter 'editorSelectableBasketsRequest' is not null or undefined
            assertParamExists('getEditorEditSelectableInstruments', 'editorSelectableBasketsRequest', editorSelectableBasketsRequest)
            const localVarPath = `/v1/editor/edit/selectable-instruments/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSelectableBasketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the portfolios that can be used inside the composition on edit mode.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectablePortfolios: async (identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getEditorEditSelectablePortfolios', 'identifier', identifier)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorEditSelectablePortfolios', 'entity', entity)
            const localVarPath = `/v1/editor/edit/selectable-portfolios/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewComposition: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorNewComposition', 'entity', entity)
            const localVarPath = `/v1/editor/new/composition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets2: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorNewSelectableBaskets2', 'entity', entity)
            const localVarPath = `/v1/editor/new/selectable-baskets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableInstruments: async (editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editorSelectableBasketsRequest' is not null or undefined
            assertParamExists('getEditorNewSelectableInstruments', 'editorSelectableBasketsRequest', editorSelectableBasketsRequest)
            const localVarPath = `/v1/editor/new/selectable-instruments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSelectableBasketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all benchmarks usable as primary benchmarks in upload, and currencies.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} [entity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableMainInfo: async (entity?: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/editor/new/selectable-main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the portfoliose that can be used in the composition, in creation mode.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectablePortfolios: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorNewSelectablePortfolios', 'entity', entity)
            const localVarPath = `/v1/editor/new/selectable-portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorReviewComposition: async (identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getEditorReviewComposition', 'identifier', identifier)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorReviewComposition', 'entity', entity)
            const localVarPath = `/v1/editor/review/composition/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorReviewSelectableProxies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/editor/review/selectable-proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the template Excel for user upload insertion.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorUploadTemplate: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getEditorUploadTemplate', 'entity', entity)
            const localVarPath = `/v2/editor/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorEditEntity: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('saveEditorEditEntity', 'entity', entity)
            // verify required parameter 'editorSaveEditOrReviewRequest' is not null or undefined
            assertParamExists('saveEditorEditEntity', 'editorSaveEditOrReviewRequest', editorSaveEditOrReviewRequest)
            const localVarPath = `/v1/editor/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSaveEditOrReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply the modifications
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorEditEntityAsDraft: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('saveEditorEditEntityAsDraft', 'entity', entity)
            // verify required parameter 'editorSaveEditOrReviewRequest' is not null or undefined
            assertParamExists('saveEditorEditEntityAsDraft', 'editorSaveEditOrReviewRequest', editorSaveEditOrReviewRequest)
            const localVarPath = `/v1/editor/edit/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSaveEditOrReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorNewEntity: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('saveEditorNewEntity', 'entity', entity)
            // verify required parameter 'editorSaveNewRequest' is not null or undefined
            assertParamExists('saveEditorNewEntity', 'editorSaveNewRequest', editorSaveNewRequest)
            const localVarPath = `/v1/editor/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSaveNewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the given investment as a draft
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorNewEntityAsDraft: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('saveEditorNewEntityAsDraft', 'entity', entity)
            // verify required parameter 'editorSaveNewRequest' is not null or undefined
            assertParamExists('saveEditorNewEntityAsDraft', 'editorSaveNewRequest', editorSaveNewRequest)
            const localVarPath = `/v1/editor/new/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSaveNewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorReviewEntity: async (entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('saveEditorReviewEntity', 'entity', entity)
            // verify required parameter 'editorSaveEditOrReviewRequest' is not null or undefined
            assertParamExists('saveEditorReviewEntity', 'editorSaveEditOrReviewRequest', editorSaveEditOrReviewRequest)
            const localVarPath = `/v1/editor/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editorSaveEditOrReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {any} excelComposition 
         * @param {EditorVerifyExcelRequest} requestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEditorExcel: async (excelComposition: any, requestData: EditorVerifyExcelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'excelComposition' is not null or undefined
            assertParamExists('verifyEditorExcel', 'excelComposition', excelComposition)
            // verify required parameter 'requestData' is not null or undefined
            assertParamExists('verifyEditorExcel', 'requestData', requestData)
            const localVarPath = `/v2/editor/verify-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (excelComposition !== undefined) { 
                localVarFormParams.append('excel-composition', excelComposition as any);
            }
    
            if (requestData !== undefined) { 
                localVarFormParams.append('request-data', new Blob([JSON.stringify(requestData)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {string} portfolioUuid 
         * @param {number} portfolioWeight 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEditorPortfolio: async (portfolioUuid: string, portfolioWeight: number, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioUuid' is not null or undefined
            assertParamExists('verifyEditorPortfolio', 'portfolioUuid', portfolioUuid)
            // verify required parameter 'portfolioWeight' is not null or undefined
            assertParamExists('verifyEditorPortfolio', 'portfolioWeight', portfolioWeight)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('verifyEditorPortfolio', 'entity', entity)
            const localVarPath = `/v2/editor/verify-portfolio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioUuid !== undefined) {
                localVarQueryParameter['portfolio-uuid'] = portfolioUuid;
            }

            if (portfolioWeight !== undefined) {
                localVarQueryParameter['portfolio-weight'] = portfolioWeight;
            }

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityEditorControllerApi - functional programming interface
 * @export
 */
export const EntityEditorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityEditorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the instruments of the benchmark to add to the editor.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkInstruments(benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorBenchmarkInstrumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkInstruments(benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorEditComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorEditComposition(identifier, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorEditSelectableBaskets(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableBasketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorEditSelectableBaskets(identifier, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorEditSelectableInstruments(identifier: string, editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableInstrumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorEditSelectableInstruments(identifier, editorSelectableBasketsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the portfolios that can be used inside the composition on edit mode.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorEditSelectablePortfolios(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectablePortfoliosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorEditSelectablePortfolios(identifier, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewComposition(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewComposition(entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewSelectableBaskets2(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableBasketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectableBaskets2(entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewSelectableInstruments(editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableInstrumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectableInstruments(editorSelectableBasketsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all benchmarks usable as primary benchmarks in upload, and currencies.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} [entity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewSelectableMainInfo(entity?: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectableMainInfo(entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the portfoliose that can be used in the composition, in creation mode.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewSelectablePortfolios(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectablePortfoliosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectablePortfolios(entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorReviewComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorReviewComposition(identifier, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorReviewSelectableProxies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableProxiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorReviewSelectableProxies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the template Excel for user upload insertion.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorUploadTemplate(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorUploadTemplate(entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEditorEditEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSaveEditOrReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEditorEditEntity(entity, editorSaveEditOrReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply the modifications
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEditorEditEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSaveNewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEditorEditEntityAsDraft(entity, editorSaveEditOrReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEditorNewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSaveNewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEditorNewEntity(entity, editorSaveNewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the given investment as a draft
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEditorNewEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSaveNewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEditorNewEntityAsDraft(entity, editorSaveNewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEditorReviewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSaveEditOrReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEditorReviewEntity(entity, editorSaveEditOrReviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {any} excelComposition 
         * @param {EditorVerifyExcelRequest} requestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEditorExcel(excelComposition: any, requestData: EditorVerifyExcelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorExcelVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEditorExcel(excelComposition, requestData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {string} portfolioUuid 
         * @param {number} portfolioWeight 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEditorPortfolio(portfolioUuid: string, portfolioWeight: number, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorVerifyPortfolioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEditorPortfolio(portfolioUuid, portfolioWeight, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntityEditorControllerApi - factory interface
 * @export
 */
export const EntityEditorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityEditorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the instruments of the benchmark to add to the editor.
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkInstruments(benchmarkIdentifier: string, options?: any): AxiosPromise<EditorBenchmarkInstrumentsResponse> {
            return localVarFp.getBenchmarkInstruments(benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorCompositionResponse> {
            return localVarFp.getEditorEditComposition(identifier, entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectableBaskets(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorSelectableBasketsResponse> {
            return localVarFp.getEditorEditSelectableBaskets(identifier, entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectableInstruments(identifier: string, editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: any): AxiosPromise<EditorSelectableInstrumentsResponse> {
            return localVarFp.getEditorEditSelectableInstruments(identifier, editorSelectableBasketsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the portfolios that can be used inside the composition on edit mode.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorEditSelectablePortfolios(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorSelectablePortfoliosResponse> {
            return localVarFp.getEditorEditSelectablePortfolios(identifier, entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewComposition(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorCompositionResponse> {
            return localVarFp.getEditorNewComposition(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets2(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorSelectableBasketsResponse> {
            return localVarFp.getEditorNewSelectableBaskets2(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableInstruments(editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: any): AxiosPromise<EditorSelectableInstrumentsResponse> {
            return localVarFp.getEditorNewSelectableInstruments(editorSelectableBasketsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all benchmarks usable as primary benchmarks in upload, and currencies.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} [entity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableMainInfo(entity?: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<SelectableMainInfoResponse> {
            return localVarFp.getEditorNewSelectableMainInfo(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the portfoliose that can be used in the composition, in creation mode.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectablePortfolios(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorSelectablePortfoliosResponse> {
            return localVarFp.getEditorNewSelectablePortfolios(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {string} identifier 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorReviewComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorCompositionResponse> {
            return localVarFp.getEditorReviewComposition(identifier, entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorReviewSelectableProxies(options?: any): AxiosPromise<SelectableProxiesResponse> {
            return localVarFp.getEditorReviewSelectableProxies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the template Excel for user upload insertion.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorUploadTemplate(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<any> {
            return localVarFp.getEditorUploadTemplate(entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorEditEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: any): AxiosPromise<EditorSaveEditOrReviewResponse> {
            return localVarFp.saveEditorEditEntity(entity, editorSaveEditOrReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply the modifications
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorEditEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: any): AxiosPromise<EditorSaveNewResponse> {
            return localVarFp.saveEditorEditEntityAsDraft(entity, editorSaveEditOrReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorNewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: any): AxiosPromise<EditorSaveNewResponse> {
            return localVarFp.saveEditorNewEntity(entity, editorSaveNewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the given investment as a draft
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveNewRequest} editorSaveNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorNewEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: any): AxiosPromise<EditorSaveNewResponse> {
            return localVarFp.saveEditorNewEntityAsDraft(entity, editorSaveNewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEditorReviewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: any): AxiosPromise<EditorSaveEditOrReviewResponse> {
            return localVarFp.saveEditorReviewEntity(entity, editorSaveEditOrReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {any} excelComposition 
         * @param {EditorVerifyExcelRequest} requestData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEditorExcel(excelComposition: any, requestData: EditorVerifyExcelRequest, options?: any): AxiosPromise<EditorExcelVerifyResponse> {
            return localVarFp.verifyEditorExcel(excelComposition, requestData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
         * @param {string} portfolioUuid 
         * @param {number} portfolioWeight 
         * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEditorPortfolio(portfolioUuid: string, portfolioWeight: number, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: any): AxiosPromise<EditorVerifyPortfolioResponse> {
            return localVarFp.verifyEditorPortfolio(portfolioUuid, portfolioWeight, entity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityEditorControllerApi - object-oriented interface
 * @export
 * @class EntityEditorControllerApi
 * @extends {BaseAPI}
 */
export class EntityEditorControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the instruments of the benchmark to add to the editor.
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getBenchmarkInstruments(benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getBenchmarkInstruments(benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {string} identifier 
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorEditComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorEditComposition(identifier, entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the baskets that could be used for filtering.
     * @param {string} identifier 
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorEditSelectableBaskets(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorEditSelectableBaskets(identifier, entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {string} identifier 
     * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorEditSelectableInstruments(identifier: string, editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorEditSelectableInstruments(identifier, editorSelectableBasketsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the portfolios that can be used inside the composition on edit mode.
     * @param {string} identifier 
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorEditSelectablePortfolios(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorEditSelectablePortfolios(identifier, entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorNewComposition(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorNewComposition(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the baskets that could be used for filtering.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorNewSelectableBaskets2(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorNewSelectableBaskets2(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {EditorSelectableBasketsRequest} editorSelectableBasketsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorNewSelectableInstruments(editorSelectableBasketsRequest: EditorSelectableBasketsRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorNewSelectableInstruments(editorSelectableBasketsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all benchmarks usable as primary benchmarks in upload, and currencies.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} [entity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorNewSelectableMainInfo(entity?: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorNewSelectableMainInfo(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the portfoliose that can be used in the composition, in creation mode.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorNewSelectablePortfolios(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorNewSelectablePortfolios(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {string} identifier 
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorReviewComposition(identifier: string, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorReviewComposition(identifier, entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of available live proxies.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorReviewSelectableProxies(options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorReviewSelectableProxies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the template Excel for user upload insertion.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public getEditorUploadTemplate(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).getEditorUploadTemplate(entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public saveEditorEditEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).saveEditorEditEntity(entity, editorSaveEditOrReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply the modifications
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public saveEditorEditEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).saveEditorEditEntityAsDraft(entity, editorSaveEditOrReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {EditorSaveNewRequest} editorSaveNewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public saveEditorNewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).saveEditorNewEntity(entity, editorSaveNewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the given investment as a draft
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {EditorSaveNewRequest} editorSaveNewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public saveEditorNewEntityAsDraft(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveNewRequest: EditorSaveNewRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).saveEditorNewEntityAsDraft(entity, editorSaveNewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {EditorSaveEditOrReviewRequest} editorSaveEditOrReviewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public saveEditorReviewEntity(entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', editorSaveEditOrReviewRequest: EditorSaveEditOrReviewRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).saveEditorReviewEntity(entity, editorSaveEditOrReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
     * @param {any} excelComposition 
     * @param {EditorVerifyExcelRequest} requestData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public verifyEditorExcel(excelComposition: any, requestData: EditorVerifyExcelRequest, options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).verifyEditorExcel(excelComposition, requestData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary It verifies the correctness of the ticker inside the given excel, returning composition and eventual errors.
     * @param {string} portfolioUuid 
     * @param {number} portfolioWeight 
     * @param {'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT'} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityEditorControllerApi
     */
    public verifyEditorPortfolio(portfolioUuid: string, portfolioWeight: number, entity: 'INVESTMENT' | 'INVESTMENT_ENHANCEMENT' | 'UNIVERSE' | 'TARGET_INVESTMENT' | 'BENCHMARK' | 'INVESTMENT_DRAFT', options?: AxiosRequestConfig) {
        return EntityEditorControllerApiFp(this.configuration).verifyEditorPortfolio(portfolioUuid, portfolioWeight, entity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorHandlingTestControllerApi - axios parameter creator
 * @export
 */
export const ErrorHandlingTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atError: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('atError', 'xApiKey', xApiKey)
            const localVarPath = `/v1/error-handling-test/generate-at-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beError: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('beError', 'xApiKey', xApiKey)
            const localVarPath = `/v1/error-handling-test/generate-be-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwError: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('cwError', 'xApiKey', xApiKey)
            const localVarPath = `/v1/error-handling-test/generate-cw-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwError: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('gwError', 'xApiKey', xApiKey)
            const localVarPath = `/v1/error-handling-test/generate-gw-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paError: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('paError', 'xApiKey', xApiKey)
            const localVarPath = `/v1/error-handling-test/generate-pa-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorHandlingTestControllerApi - functional programming interface
 * @export
 */
export const ErrorHandlingTestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorHandlingTestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atError(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atError(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beError(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beError(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cwError(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cwError(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gwError(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gwError(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paError(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paError(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorHandlingTestControllerApi - factory interface
 * @export
 */
export const ErrorHandlingTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorHandlingTestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atError(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.atError(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beError(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.beError(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cwError(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.cwError(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gwError(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.gwError(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paError(xApiKey: string, options?: any): AxiosPromise<string> {
            return localVarFp.paError(xApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorHandlingTestControllerApi - object-oriented interface
 * @export
 * @class ErrorHandlingTestControllerApi
 * @extends {BaseAPI}
 */
export class ErrorHandlingTestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorHandlingTestControllerApi
     */
    public atError(xApiKey: string, options?: AxiosRequestConfig) {
        return ErrorHandlingTestControllerApiFp(this.configuration).atError(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorHandlingTestControllerApi
     */
    public beError(xApiKey: string, options?: AxiosRequestConfig) {
        return ErrorHandlingTestControllerApiFp(this.configuration).beError(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorHandlingTestControllerApi
     */
    public cwError(xApiKey: string, options?: AxiosRequestConfig) {
        return ErrorHandlingTestControllerApiFp(this.configuration).cwError(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorHandlingTestControllerApi
     */
    public gwError(xApiKey: string, options?: AxiosRequestConfig) {
        return ErrorHandlingTestControllerApiFp(this.configuration).gwError(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorHandlingTestControllerApi
     */
    public paError(xApiKey: string, options?: AxiosRequestConfig) {
        return ErrorHandlingTestControllerApiFp(this.configuration).paError(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastEvent: async (topic: string, eventMarketUpdate: EventMarketUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('broadcastEvent', 'topic', topic)
            // verify required parameter 'eventMarketUpdate' is not null or undefined
            assertParamExists('broadcastEvent', 'eventMarketUpdate', eventMarketUpdate)
            const localVarPath = `/v1/event/broadcast/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventMarketUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastTestEvent: async (topic: string, eventMarketUpdate: EventMarketUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('broadcastTestEvent', 'topic', topic)
            // verify required parameter 'eventMarketUpdate' is not null or undefined
            assertParamExists('broadcastTestEvent', 'eventMarketUpdate', eventMarketUpdate)
            const localVarPath = `/v1/event/broadcast-test/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventMarketUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventBenchmarkUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-benchmark-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Bulk Download and is not intended for actual data retrieval.
         * @summary Get Event Bulk Download Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventBulkDownload: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-bulk-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCommentaryUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-commentary-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Draft Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDraftUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-draft-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Investment Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventInvestmentUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-investment-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Market Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventMarketUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-market-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Report Update and is not intended for actual data retrieval.
         * @summary Get Event Report Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReportUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-report-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Share Entity and is not intended for actual data retrieval.
         * @summary Get Event Shared Entity Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSharedEntity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-share-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventTopic: async (topic: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('getEventTopic', 'topic', topic)
            const localVarPath = `/v1/event/topic/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event User Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUserUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/event-structure/event-user-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('register', 'token', token)
            const localVarPath = `/v1/event/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEvent: async (topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('sendEvent', 'topic', topic)
            // verify required parameter 'eventInvestmentUpdate' is not null or undefined
            assertParamExists('sendEvent', 'eventInvestmentUpdate', eventInvestmentUpdate)
            const localVarPath = `/v1/event/send/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventInvestmentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEvent: async (id: string, topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendTestEvent', 'id', id)
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('sendTestEvent', 'topic', topic)
            // verify required parameter 'eventInvestmentUpdate' is not null or undefined
            assertParamExists('sendTestEvent', 'eventInvestmentUpdate', eventInvestmentUpdate)
            const localVarPath = `/v1/event/send-test/{id}/{topic}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventInvestmentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/event/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastEvent(topic, eventMarketUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async broadcastTestEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.broadcastTestEvent(topic, eventMarketUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventBenchmarkUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUpdateBenchmark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventBenchmarkUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Bulk Download and is not intended for actual data retrieval.
         * @summary Get Event Bulk Download Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventBulkDownload(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventBulkDownload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventBulkDownload(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventCommentaryUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventInstrumentCommentaryUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCommentaryUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Draft Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventDraftUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventInvestmentDraftUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventDraftUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Investment Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventInvestmentUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventInvestmentUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventInvestmentUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Market Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventMarketUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventMarketUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventMarketUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Report Update and is not intended for actual data retrieval.
         * @summary Get Event Report Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventReportUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventReportUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventReportUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Share Entity and is not intended for actual data retrieval.
         * @summary Get Event Shared Entity Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventSharedEntity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSharedEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventSharedEntity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventTopic(topic: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTopic(topic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event User Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventUserUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventUserUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventUserUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEvent(topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEvent(topic, eventInvestmentUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestEvent(id: string, topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestEvent(id, topic, eventInvestmentUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.broadcastEvent(topic, eventMarketUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventMarketUpdate} eventMarketUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        broadcastTestEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.broadcastTestEvent(topic, eventMarketUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventBenchmarkUpdate(options?: any): AxiosPromise<EventUpdateBenchmark> {
            return localVarFp.getEventBenchmarkUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Bulk Download and is not intended for actual data retrieval.
         * @summary Get Event Bulk Download Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventBulkDownload(options?: any): AxiosPromise<EventBulkDownload> {
            return localVarFp.getEventBulkDownload(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Benchmark Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCommentaryUpdate(options?: any): AxiosPromise<EventInstrumentCommentaryUpdate> {
            return localVarFp.getEventCommentaryUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Draft Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDraftUpdate(options?: any): AxiosPromise<EventInvestmentDraftUpdate> {
            return localVarFp.getEventDraftUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Investment Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventInvestmentUpdate(options?: any): AxiosPromise<EventInvestmentUpdate> {
            return localVarFp.getEventInvestmentUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event Market Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventMarketUpdate(options?: any): AxiosPromise<EventMarketUpdate> {
            return localVarFp.getEventMarketUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Report Update and is not intended for actual data retrieval.
         * @summary Get Event Report Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReportUpdate(options?: any): AxiosPromise<EventReportUpdate> {
            return localVarFp.getEventReportUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event Share Entity and is not intended for actual data retrieval.
         * @summary Get Event Shared Entity Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSharedEntity(options?: any): AxiosPromise<EventSharedEntity> {
            return localVarFp.getEventSharedEntity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventTopic(topic: string, options?: any): AxiosPromise<EventTopic> {
            return localVarFp.getEventTopic(topic, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
         * @summary Get Event User Update Structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUserUpdate(options?: any): AxiosPromise<EventUserUpdate> {
            return localVarFp.getEventUserUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(token: string, options?: any): AxiosPromise<SseEmitter> {
            return localVarFp.register(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEvent(topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.sendEvent(topic, eventInvestmentUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} topic 
         * @param {EventInvestmentUpdate} eventInvestmentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEvent(id: string, topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.sendTestEvent(id, topic, eventInvestmentUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(options?: any): AxiosPromise<string> {
            return localVarFp.verify(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} topic 
     * @param {EventMarketUpdate} eventMarketUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public broadcastEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).broadcastEvent(topic, eventMarketUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topic 
     * @param {EventMarketUpdate} eventMarketUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public broadcastTestEvent(topic: string, eventMarketUpdate: EventMarketUpdate, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).broadcastTestEvent(topic, eventMarketUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event Benchmark Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventBenchmarkUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventBenchmarkUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event Bulk Download and is not intended for actual data retrieval.
     * @summary Get Event Bulk Download Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventBulkDownload(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventBulkDownload(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event Benchmark Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventCommentaryUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventCommentaryUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event Draft Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventDraftUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventDraftUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event Investment Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventInvestmentUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventInvestmentUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event Market Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventMarketUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventMarketUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event Report Update and is not intended for actual data retrieval.
     * @summary Get Event Report Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventReportUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventReportUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event Share Entity and is not intended for actual data retrieval.
     * @summary Get Event Shared Entity Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventSharedEntity(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventSharedEntity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventTopic(topic: string, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventTopic(topic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint exists to trace the payload structure of the Event User Update and is not intended for actual data retrieval.
     * @summary Get Event User Update Structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEventUserUpdate(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEventUserUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public register(token: string, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).register(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topic 
     * @param {EventInvestmentUpdate} eventInvestmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public sendEvent(topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).sendEvent(topic, eventInvestmentUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} topic 
     * @param {EventInvestmentUpdate} eventInvestmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public sendTestEvent(id: string, topic: string, eventInvestmentUpdate: EventInvestmentUpdate, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).sendTestEvent(id, topic, eventInvestmentUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public verify(options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).verify(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventManagerControllerApi - axios parameter creator
 * @export
 */
export const EventManagerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventManagerControllerApi - functional programming interface
 * @export
 */
export const EventManagerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventManagerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventManagerControllerApi - factory interface
 * @export
 */
export const EventManagerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventManagerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllEvents(options?: any): AxiosPromise<Array<EventsModel>> {
            return localVarFp.retrieveAllEvents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventManagerControllerApi - object-oriented interface
 * @export
 * @class EventManagerControllerApi
 * @extends {BaseAPI}
 */
export class EventManagerControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventManagerControllerApi
     */
    public retrieveAllEvents(options?: AxiosRequestConfig) {
        return EventManagerControllerApiFp(this.configuration).retrieveAllEvents(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureTogglesSecureControllerApi - axios parameter creator
 * @export
 */
export const FeatureTogglesSecureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureDisable: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('featureDisable', 'featureName', featureName)
            const localVarPath = `/v1/feature-toggles/disable/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureEnable: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('featureEnable', 'featureName', featureName)
            const localVarPath = `/v1/feature-toggles/enable/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/feature-toggles/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/feature-toggles/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureStates1: async (featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('featureStates1', 'featureName', featureName)
            const localVarPath = `/v1/feature-toggles/state/{featureName}`
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureTogglesSecureControllerApi - functional programming interface
 * @export
 */
export const FeatureTogglesSecureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureTogglesSecureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureDisable(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureDisable(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureEnable(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureEnable(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<Feature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureStates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<FeatureState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureStates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureStates1(featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureStates1(featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureTogglesSecureControllerApi - factory interface
 * @export
 */
export const FeatureTogglesSecureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureTogglesSecureControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureDisable(featureName: string, options?: any): AxiosPromise<FeatureState> {
            return localVarFp.featureDisable(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureEnable(featureName: string, options?: any): AxiosPromise<FeatureState> {
            return localVarFp.featureEnable(featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureList(options?: any): AxiosPromise<Set<Feature>> {
            return localVarFp.featureList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureStates(options?: any): AxiosPromise<Set<FeatureState>> {
            return localVarFp.featureStates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureStates1(featureName: string, options?: any): AxiosPromise<FeatureState> {
            return localVarFp.featureStates1(featureName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureTogglesSecureControllerApi - object-oriented interface
 * @export
 * @class FeatureTogglesSecureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureTogglesSecureControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureTogglesSecureControllerApi
     */
    public featureDisable(featureName: string, options?: AxiosRequestConfig) {
        return FeatureTogglesSecureControllerApiFp(this.configuration).featureDisable(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureTogglesSecureControllerApi
     */
    public featureEnable(featureName: string, options?: AxiosRequestConfig) {
        return FeatureTogglesSecureControllerApiFp(this.configuration).featureEnable(featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureTogglesSecureControllerApi
     */
    public featureList(options?: AxiosRequestConfig) {
        return FeatureTogglesSecureControllerApiFp(this.configuration).featureList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureTogglesSecureControllerApi
     */
    public featureStates(options?: AxiosRequestConfig) {
        return FeatureTogglesSecureControllerApiFp(this.configuration).featureStates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureTogglesSecureControllerApi
     */
    public featureStates1(featureName: string, options?: AxiosRequestConfig) {
        return FeatureTogglesSecureControllerApiFp(this.configuration).featureStates1(featureName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbacksControllerApi - axios parameter creator
 * @export
 */
export const FeedbacksControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback: async (feedbackRequest: FeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackRequest' is not null or undefined
            assertParamExists('addFeedback', 'feedbackRequest', feedbackRequest)
            const localVarPath = `/v2/feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFeedbacks: async (fromDate: string, toDate: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('exportFeedbacks', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('exportFeedbacks', 'toDate', toDate)
            const localVarPath = `/v2/feedbacks/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeedback: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbacksControllerApi - functional programming interface
 * @export
 */
export const FeedbacksControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbacksControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeedback(feedbackRequest: FeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeedback(feedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportFeedbacks(fromDate: string, toDate: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportFeedbacks(fromDate, toDate, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeedback(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedbackResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeedback(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbacksControllerApi - factory interface
 * @export
 */
export const FeedbacksControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbacksControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedbackRequest} feedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback(feedbackRequest: FeedbackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addFeedback(feedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFeedbacks(fromDate: string, toDate: string, userId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportFeedbacks(fromDate, toDate, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeedback(userId?: string, options?: any): AxiosPromise<Array<FeedbackResponse>> {
            return localVarFp.listFeedback(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbacksControllerApi - object-oriented interface
 * @export
 * @class FeedbacksControllerApi
 * @extends {BaseAPI}
 */
export class FeedbacksControllerApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackRequest} feedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbacksControllerApi
     */
    public addFeedback(feedbackRequest: FeedbackRequest, options?: AxiosRequestConfig) {
        return FeedbacksControllerApiFp(this.configuration).addFeedback(feedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbacksControllerApi
     */
    public exportFeedbacks(fromDate: string, toDate: string, userId?: string, options?: AxiosRequestConfig) {
        return FeedbacksControllerApiFp(this.configuration).exportFeedbacks(fromDate, toDate, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbacksControllerApi
     */
    public listFeedback(userId?: string, options?: AxiosRequestConfig) {
        return FeedbacksControllerApiFp(this.configuration).listFeedback(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HmmControllerApi - axios parameter creator
 * @export
 */
export const HmmControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEvolutionOfRegime: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveEvolutionOfRegime', 'region', region)
            const localVarPath = `/hmm/evolution-of-regime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns global risk map
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGlobalRiskMap: async (valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valueOnMap' is not null or undefined
            assertParamExists('retrieveGlobalRiskMap', 'valueOnMap', valueOnMap)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('retrieveGlobalRiskMap', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('retrieveGlobalRiskMap', 'endDate', endDate)
            const localVarPath = `/hmm/global-risk-map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (valueOnMap !== undefined) {
                localVarQueryParameter['valueOnMap'] = valueOnMap;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary return the list of available dates
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGlobalRiskMapDates: async (valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valueOnMap' is not null or undefined
            assertParamExists('retrieveGlobalRiskMapDates', 'valueOnMap', valueOnMap)
            const localVarPath = `/hmm/global-risk-map-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (valueOnMap !== undefined) {
                localVarQueryParameter['valueOnMap'] = valueOnMap;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketView: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveMarketView', 'region', region)
            const localVarPath = `/hmm/market-regime-analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePastMarketRegime: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrievePastMarketRegime', 'region', region)
            const localVarPath = `/hmm/past-market-regime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeCounter: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveRegimeCounter', 'region', region)
            const localVarPath = `/hmm/regime-counter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {string} days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimesTransitionMatrices: async (region: string, days: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveRegimesTransitionMatrices', 'region', region)
            // verify required parameter 'days' is not null or undefined
            assertParamExists('retrieveRegimesTransitionMatrices', 'days', days)
            const localVarPath = `/hmm/regime-transition-matrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return region for HMM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hmm/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveScenarioDescription: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveScenarioDescription', 'region', region)
            const localVarPath = `/hmm/scenario-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectorsBreakDown: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveSectorsBreakDown', 'region', region)
            const localVarPath = `/hmm/sectors-breakdown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectorsWithRegime: async (region: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('retrieveSectorsWithRegime', 'region', region)
            const localVarPath = `/hmm/regime-with-sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HmmControllerApi - functional programming interface
 * @export
 */
export const HmmControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HmmControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveEvolutionOfRegime(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvolutionRegimesProbabilities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveEvolutionOfRegime(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns global risk map
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveGlobalRiskMap(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalRisk>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveGlobalRiskMap(valueOnMap, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary return the list of available dates
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveGlobalRiskMapDates(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveGlobalRiskMapDates(valueOnMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveMarketView(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketRegimeAnalysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMarketView(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePastMarketRegime(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PastMarketRegime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePastMarketRegime(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRegimeCounter(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegimeCounter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRegimeCounter(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {string} days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRegimesTransitionMatrices(region: string, days: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegimeTransitionMatrices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRegimesTransitionMatrices(region, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return region for HMM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRegions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveScenarioDescription(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenariosDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveScenarioDescription(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSectorsBreakDown(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorsBreakDown>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSectorsBreakDown(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSectorsWithRegime(region: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegimeBySector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSectorsWithRegime(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HmmControllerApi - factory interface
 * @export
 */
export const HmmControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HmmControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEvolutionOfRegime(region: string, options?: any): AxiosPromise<EvolutionRegimesProbabilities> {
            return localVarFp.retrieveEvolutionOfRegime(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns global risk map
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGlobalRiskMap(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', startDate: string, endDate: string, options?: any): AxiosPromise<Array<GlobalRisk>> {
            return localVarFp.retrieveGlobalRiskMap(valueOnMap, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary return the list of available dates
         * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGlobalRiskMapDates(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveGlobalRiskMapDates(valueOnMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketView(region: string, options?: any): AxiosPromise<MarketRegimeAnalysis> {
            return localVarFp.retrieveMarketView(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePastMarketRegime(region: string, options?: any): AxiosPromise<PastMarketRegime> {
            return localVarFp.retrievePastMarketRegime(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimeCounter(region: string, options?: any): AxiosPromise<RegimeCounter> {
            return localVarFp.retrieveRegimeCounter(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {string} days 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegimesTransitionMatrices(region: string, days: string, options?: any): AxiosPromise<RegimeTransitionMatrices> {
            return localVarFp.retrieveRegimesTransitionMatrices(region, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return region for HMM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRegions(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveScenarioDescription(region: string, options?: any): AxiosPromise<ScenariosDescription> {
            return localVarFp.retrieveScenarioDescription(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectorsBreakDown(region: string, options?: any): AxiosPromise<SectorsBreakDown> {
            return localVarFp.retrieveSectorsBreakDown(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return data for HMM
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectorsWithRegime(region: string, options?: any): AxiosPromise<RegimeBySector> {
            return localVarFp.retrieveSectorsWithRegime(region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HmmControllerApi - object-oriented interface
 * @export
 * @class HmmControllerApi
 * @extends {BaseAPI}
 */
export class HmmControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveEvolutionOfRegime(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveEvolutionOfRegime(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns global risk map
     * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveGlobalRiskMap(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveGlobalRiskMap(valueOnMap, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary return the list of available dates
     * @param {'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY'} valueOnMap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveGlobalRiskMapDates(valueOnMap: 'REGIME_PROBABILITIES' | 'VALUE_AT_RISK' | 'VOLATILITY', options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveGlobalRiskMapDates(valueOnMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveMarketView(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveMarketView(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrievePastMarketRegime(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrievePastMarketRegime(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveRegimeCounter(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveRegimeCounter(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {string} days 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveRegimesTransitionMatrices(region: string, days: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveRegimesTransitionMatrices(region, days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return region for HMM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveRegions(options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveScenarioDescription(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveScenarioDescription(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveSectorsBreakDown(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveSectorsBreakDown(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return data for HMM
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HmmControllerApi
     */
    public retrieveSectorsWithRegime(region: string, options?: AxiosRequestConfig) {
        return HmmControllerApiFp(this.configuration).retrieveSectorsWithRegime(region, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstrumentCommentaryDeleteApi - axios parameter creator
 * @export
 */
export const InstrumentCommentaryDeleteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentaryFromCache: async (isin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('deleteCommentaryFromCache', 'isin', isin)
            const localVarPath = `/v2/commentaries/commentary/instrument/cache/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentCommentaryDeleteApi - functional programming interface
 * @export
 */
export const InstrumentCommentaryDeleteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentCommentaryDeleteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentaryFromCache(isin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentaryFromCache(isin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentCommentaryDeleteApi - factory interface
 * @export
 */
export const InstrumentCommentaryDeleteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentCommentaryDeleteApiFp(configuration)
    return {
        /**
         * 
         * @summary delete commentary from cache
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentaryFromCache(isin: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCommentaryFromCache(isin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentCommentaryDeleteApi - object-oriented interface
 * @export
 * @class InstrumentCommentaryDeleteApi
 * @extends {BaseAPI}
 */
export class InstrumentCommentaryDeleteApi extends BaseAPI {
    /**
     * 
     * @summary delete commentary from cache
     * @param {string} isin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCommentaryDeleteApi
     */
    public deleteCommentaryFromCache(isin: string, options?: AxiosRequestConfig) {
        return InstrumentCommentaryDeleteApiFp(this.configuration).deleteCommentaryFromCache(isin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstrumentCommentaryGenerationApi - axios parameter creator
 * @export
 */
export const InstrumentCommentaryGenerationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCommentaryForInstrument: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('calculateCommentaryForInstrument', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('calculateCommentaryForInstrument', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/commentary/instrument/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentCommentaryGenerationApi - functional programming interface
 * @export
 */
export const InstrumentCommentaryGenerationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentCommentaryGenerationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateCommentaryForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateCommentaryForInstrument(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentCommentaryGenerationApi - factory interface
 * @export
 */
export const InstrumentCommentaryGenerationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentCommentaryGenerationApiFp(configuration)
    return {
        /**
         * 
         * @summary calculate commentary for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCommentaryForInstrument(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculateCommentaryForInstrument(isin, instrument, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentCommentaryGenerationApi - object-oriented interface
 * @export
 * @class InstrumentCommentaryGenerationApi
 * @extends {BaseAPI}
 */
export class InstrumentCommentaryGenerationApi extends BaseAPI {
    /**
     * 
     * @summary calculate commentary for instrument
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCommentaryGenerationApi
     */
    public calculateCommentaryForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return InstrumentCommentaryGenerationApiFp(this.configuration).calculateCommentaryForInstrument(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstrumentCommentaryObtainApi - axios parameter creator
 * @export
 */
export const InstrumentCommentaryObtainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryFromCache: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('retrieveCommentaryFromCache', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('retrieveCommentaryFromCache', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/commentary/instrument/cache/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentCommentaryObtainApi - functional programming interface
 * @export
 */
export const InstrumentCommentaryObtainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentCommentaryObtainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCommentaryFromCache(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCommentaryFromCache(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentCommentaryObtainApi - factory interface
 * @export
 */
export const InstrumentCommentaryObtainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentCommentaryObtainApiFp(configuration)
    return {
        /**
         * 
         * @summary RetrieveInstrumentFromCache
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryFromCache(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.retrieveCommentaryFromCache(isin, instrument, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentCommentaryObtainApi - object-oriented interface
 * @export
 * @class InstrumentCommentaryObtainApi
 * @extends {BaseAPI}
 */
export class InstrumentCommentaryObtainApi extends BaseAPI {
    /**
     * 
     * @summary RetrieveInstrumentFromCache
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCommentaryObtainApi
     */
    public retrieveCommentaryFromCache(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return InstrumentCommentaryObtainApiFp(this.configuration).retrieveCommentaryFromCache(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstrumentCommentaryPromptApi - axios parameter creator
 * @export
 */
export const InstrumentCommentaryPromptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePromptForInstrument: async (isin: string, instrument: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('calculatePromptForInstrument', 'isin', isin)
            // verify required parameter 'instrument' is not null or undefined
            assertParamExists('calculatePromptForInstrument', 'instrument', instrument)
            const localVarPath = `/v2/commentaries/prompt/instrument/{isin}/{instrument}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)))
                .replace(`{${"instrument"}}`, encodeURIComponent(String(instrument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentCommentaryPromptApi - functional programming interface
 * @export
 */
export const InstrumentCommentaryPromptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentCommentaryPromptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePromptForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePromptForInstrument(isin, instrument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentCommentaryPromptApi - factory interface
 * @export
 */
export const InstrumentCommentaryPromptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentCommentaryPromptApiFp(configuration)
    return {
        /**
         * 
         * @summary calculate prompt for instrument
         * @param {string} isin 
         * @param {string} instrument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePromptForInstrument(isin: string, instrument: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculatePromptForInstrument(isin, instrument, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentCommentaryPromptApi - object-oriented interface
 * @export
 * @class InstrumentCommentaryPromptApi
 * @extends {BaseAPI}
 */
export class InstrumentCommentaryPromptApi extends BaseAPI {
    /**
     * 
     * @summary calculate prompt for instrument
     * @param {string} isin 
     * @param {string} instrument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCommentaryPromptApi
     */
    public calculatePromptForInstrument(isin: string, instrument: string, options?: AxiosRequestConfig) {
        return InstrumentCommentaryPromptApiFp(this.configuration).calculatePromptForInstrument(isin, instrument, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstrumentCustomizationControllerApi - axios parameter creator
 * @export
 */
export const InstrumentCustomizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInstrumentSphereCommentary: async (isin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('generateInstrumentSphereCommentary', 'isin', isin)
            const localVarPath = `/v2/instruments-customization/instruments/commentary/generate/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateInstrumentSphereCommentary1: async (isin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isin' is not null or undefined
            assertParamExists('generateInstrumentSphereCommentary1', 'isin', isin)
            const localVarPath = `/v1/instruments-customization/instruments/commentary/generate/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/selectable-baskets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments-customization/selectable-baskets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrument: async (tickerName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tickerName' is not null or undefined
            assertParamExists('getInstrument', 'tickerName', tickerName)
            const localVarPath = `/v2/instruments-customization/instruments/{ticker-name}`
                .replace(`{${"ticker-name"}}`, encodeURIComponent(String(tickerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInstrument1: async (tickerName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tickerName' is not null or undefined
            assertParamExists('getInstrument1', 'tickerName', tickerName)
            const localVarPath = `/v1/instruments-customization/instruments/{ticker-name}`
                .replace(`{${"ticker-name"}}`, encodeURIComponent(String(tickerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {Array<SelectableBasket>} selectableBasket 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInstruments: async (selectableBasket: Array<SelectableBasket>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectableBasket' is not null or undefined
            assertParamExists('getInstruments', 'selectableBasket', selectableBasket)
            const localVarPath = `/v1/instruments-customization/instruments/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectableBasket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/selectable-proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments-customization/selectable-proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/selectable-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments-customization/selectable-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/selectable-currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments-customization/selectable-currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/selectable-micro-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instruments-customization/selectable-micro-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a set of asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns instruments list.
         * @param {InstrumentsListFilter} instrumentsListFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstruments: async (instrumentsListFilter: InstrumentsListFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instrumentsListFilter' is not null or undefined
            assertParamExists('listInstruments', 'instrumentsListFilter', instrumentsListFilter)
            const localVarPath = `/v2/instruments-customization/instruments/list/filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instrumentsListFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a set of micro asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMicroAssetClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/instruments-customization/micro-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrument: async (reviewTicker: ReviewTicker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewTicker' is not null or undefined
            assertParamExists('saveInstrument', 'reviewTicker', reviewTicker)
            const localVarPath = `/v2/instruments-customization/instruments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewTicker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrument1: async (reviewTicker: ReviewTicker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewTicker' is not null or undefined
            assertParamExists('saveInstrument1', 'reviewTicker', reviewTicker)
            const localVarPath = `/v1/instruments-customization/instruments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewTicker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrumentProxies: async (reviewTicker: ReviewTicker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewTicker' is not null or undefined
            assertParamExists('saveInstrumentProxies', 'reviewTicker', reviewTicker)
            const localVarPath = `/v2/instruments-customization/instruments/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewTicker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrumentProxies1: async (reviewTicker: ReviewTicker, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewTicker' is not null or undefined
            assertParamExists('saveInstrumentProxies1', 'reviewTicker', reviewTicker)
            const localVarPath = `/v1/instruments-customization/instruments/proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewTicker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrumentUserCommentary: async (ticker: string, commentary: Commentary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('saveInstrumentUserCommentary', 'ticker', ticker)
            // verify required parameter 'commentary' is not null or undefined
            assertParamExists('saveInstrumentUserCommentary', 'commentary', commentary)
            const localVarPath = `/v2/instruments-customization/instruments/commentary/{ticker}`
                .replace(`{${"ticker"}}`, encodeURIComponent(String(ticker)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrumentUserCommentary1: async (ticker: string, commentary: Commentary, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticker' is not null or undefined
            assertParamExists('saveInstrumentUserCommentary1', 'ticker', ticker)
            // verify required parameter 'commentary' is not null or undefined
            assertParamExists('saveInstrumentUserCommentary1', 'commentary', commentary)
            const localVarPath = `/v1/instruments-customization/instruments/commentary/{ticker}`
                .replace(`{${"ticker"}}`, encodeURIComponent(String(ticker)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstrumentCustomizationControllerApi - functional programming interface
 * @export
 */
export const InstrumentCustomizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstrumentCustomizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateInstrumentSphereCommentary(isin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInstrumentSphereCommentary(isin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async generateInstrumentSphereCommentary1(isin: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInstrumentSphereCommentary1(isin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorNewSelectableBaskets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableBasketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectableBaskets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getEditorNewSelectableBaskets1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableBasketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorNewSelectableBaskets1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstrument(tickerName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrument(tickerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInstrument1(tickerName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrument1(tickerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {Array<SelectableBasket>} selectableBasket 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getInstruments(selectableBasket: Array<SelectableBasket>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditorSelectableInstrumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstruments(selectableBasket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxyPreferencesSelectableProxies1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableProxiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxyPreferencesSelectableProxies1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxyPreferencesSelectableProxies2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableProxiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxyPreferencesSelectableProxies2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxySelectableAssetClasses1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableAssetClasses1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableAssetClasses2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableAssetClasses2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxySelectableCurrencies1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currencies>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableCurrencies1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableCurrencies2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currencies>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableCurrencies2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxySelectableMicroAssetClasses1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableMicroAssetClasses1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableMicroAssetClasses2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableMicroAssetClasses2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a set of asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssetClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssetClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns instruments list.
         * @param {InstrumentsListFilter} instrumentsListFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstruments(instrumentsListFilter: InstrumentsListFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstruments(instrumentsListFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a set of micro asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMicroAssetClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMicroAssetClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveInstrument(reviewTicker: ReviewTicker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrument(reviewTicker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async saveInstrument1(reviewTicker: ReviewTicker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrument1(reviewTicker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveInstrumentProxies(reviewTicker: ReviewTicker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrumentProxies(reviewTicker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async saveInstrumentProxies1(reviewTicker: ReviewTicker, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrumentProxies1(reviewTicker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveInstrumentUserCommentary(ticker: string, commentary: Commentary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrumentUserCommentary(ticker, commentary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async saveInstrumentUserCommentary1(ticker: string, commentary: Commentary, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewTicker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveInstrumentUserCommentary1(ticker, commentary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstrumentCustomizationControllerApi - factory interface
 * @export
 */
export const InstrumentCustomizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstrumentCustomizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInstrumentSphereCommentary(isin: string, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.generateInstrumentSphereCommentary(isin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate custom commentary for instrument
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateInstrumentSphereCommentary1(isin: string, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.generateInstrumentSphereCommentary1(isin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets(options?: any): AxiosPromise<EditorSelectableBasketsResponse> {
            return localVarFp.getEditorNewSelectableBaskets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the baskets that could be used for filtering.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getEditorNewSelectableBaskets1(options?: any): AxiosPromise<EditorSelectableBasketsResponse> {
            return localVarFp.getEditorNewSelectableBaskets1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstrument(tickerName: string, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.getInstrument(tickerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the full info of a single instrument.
         * @param {string} tickerName 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInstrument1(tickerName: string, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.getInstrument1(tickerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
         * @param {Array<SelectableBasket>} selectableBasket 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getInstruments(selectableBasket: Array<SelectableBasket>, options?: any): AxiosPromise<EditorSelectableInstrumentsResponse> {
            return localVarFp.getInstruments(selectableBasket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies1(options?: any): AxiosPromise<SelectableProxiesResponse> {
            return localVarFp.getProxyPreferencesSelectableProxies1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies2(options?: any): AxiosPromise<SelectableProxiesResponse> {
            return localVarFp.getProxyPreferencesSelectableProxies2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses1(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableAssetClasses1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses2(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableAssetClasses2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies1(options?: any): AxiosPromise<Array<Currencies>> {
            return localVarFp.getProxySelectableCurrencies1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies2(options?: any): AxiosPromise<Array<Currencies>> {
            return localVarFp.getProxySelectableCurrencies2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses1(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableMicroAssetClasses1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses2(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableMicroAssetClasses2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a set of asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssetClasses(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listAssetClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns instruments list.
         * @param {InstrumentsListFilter} instrumentsListFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstruments(instrumentsListFilter: InstrumentsListFilter, options?: any): AxiosPromise<PageResponseReviewTicker> {
            return localVarFp.listInstruments(instrumentsListFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a set of micro asset classes used for filtering.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMicroAssetClasses(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listMicroAssetClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrument(reviewTicker: ReviewTicker, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrument(reviewTicker, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrument1(reviewTicker: ReviewTicker, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrument1(reviewTicker, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrumentProxies(reviewTicker: ReviewTicker, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrumentProxies(reviewTicker, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Overwrites ONLY the proxy live preferences of the user.
         * @param {ReviewTicker} reviewTicker 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrumentProxies1(reviewTicker: ReviewTicker, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrumentProxies1(reviewTicker, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveInstrumentUserCommentary(ticker: string, commentary: Commentary, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrumentUserCommentary(ticker, commentary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a custom commentary
         * @param {string} ticker 
         * @param {Commentary} commentary 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveInstrumentUserCommentary1(ticker: string, commentary: Commentary, options?: any): AxiosPromise<ReviewTicker> {
            return localVarFp.saveInstrumentUserCommentary1(ticker, commentary, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstrumentCustomizationControllerApi - object-oriented interface
 * @export
 * @class InstrumentCustomizationControllerApi
 * @extends {BaseAPI}
 */
export class InstrumentCustomizationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Generate custom commentary for instrument
     * @param {string} isin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public generateInstrumentSphereCommentary(isin: string, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).generateInstrumentSphereCommentary(isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate custom commentary for instrument
     * @param {string} isin 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public generateInstrumentSphereCommentary1(isin: string, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).generateInstrumentSphereCommentary1(isin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the baskets that could be used for filtering.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getEditorNewSelectableBaskets(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getEditorNewSelectableBaskets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the baskets that could be used for filtering.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getEditorNewSelectableBaskets1(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getEditorNewSelectableBaskets1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the full info of a single instrument.
     * @param {string} tickerName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getInstrument(tickerName: string, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getInstrument(tickerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the full info of a single instrument.
     * @param {string} tickerName 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getInstrument1(tickerName: string, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getInstrument1(tickerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new investment in review calculating, estimating the time necessary for the procedure.
     * @param {Array<SelectableBasket>} selectableBasket 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getInstruments(selectableBasket: Array<SelectableBasket>, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getInstruments(selectableBasket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of available live proxies.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxyPreferencesSelectableProxies1(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxyPreferencesSelectableProxies1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of available live proxies.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxyPreferencesSelectableProxies2(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxyPreferencesSelectableProxies2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableAssetClasses1(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableAssetClasses1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableAssetClasses2(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableAssetClasses2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the currencies selectable per preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableCurrencies1(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableCurrencies1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the currencies selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableCurrencies2(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableCurrencies2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the micro asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableMicroAssetClasses1(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableMicroAssetClasses1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the micro asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public getProxySelectableMicroAssetClasses2(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).getProxySelectableMicroAssetClasses2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a set of asset classes used for filtering.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public listAssetClasses(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).listAssetClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns instruments list.
     * @param {InstrumentsListFilter} instrumentsListFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public listInstruments(instrumentsListFilter: InstrumentsListFilter, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).listInstruments(instrumentsListFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a set of micro asset classes used for filtering.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public listMicroAssetClasses(options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).listMicroAssetClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
     * @param {ReviewTicker} reviewTicker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrument(reviewTicker: ReviewTicker, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrument(reviewTicker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Overwrites the proxy live preferences of the user, and the commentaries. Alternative to the split logic.
     * @param {ReviewTicker} reviewTicker 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrument1(reviewTicker: ReviewTicker, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrument1(reviewTicker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Overwrites ONLY the proxy live preferences of the user.
     * @param {ReviewTicker} reviewTicker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrumentProxies(reviewTicker: ReviewTicker, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrumentProxies(reviewTicker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Overwrites ONLY the proxy live preferences of the user.
     * @param {ReviewTicker} reviewTicker 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrumentProxies1(reviewTicker: ReviewTicker, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrumentProxies1(reviewTicker, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a custom commentary
     * @param {string} ticker 
     * @param {Commentary} commentary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrumentUserCommentary(ticker: string, commentary: Commentary, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrumentUserCommentary(ticker, commentary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a custom commentary
     * @param {string} ticker 
     * @param {Commentary} commentary 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InstrumentCustomizationControllerApi
     */
    public saveInstrumentUserCommentary1(ticker: string, commentary: Commentary, options?: AxiosRequestConfig) {
        return InstrumentCustomizationControllerApiFp(this.configuration).saveInstrumentUserCommentary1(ticker, commentary, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationsControllerApi - axios parameter creator
 * @export
 */
export const IntegrationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal format, notifying conversion errors, and attaching to the InvestmentImport DTO some information about for ex. status and actions.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCheckFrom: async (type: InvestmentImportConverterType, importPortfolios: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('convertCheckFrom', 'type', type)
            // verify required parameter 'importPortfolios' is not null or undefined
            assertParamExists('convertCheckFrom', 'importPortfolios', importPortfolios)
            const localVarPath = `/v2/integrations/convert/check/from/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (importPortfolios !== undefined) { 
                localVarFormParams.append('import-portfolios', importPortfolios as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFrom: async (type: InvestmentImportConverterType, importPortfolios: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('convertFrom', 'type', type)
            // verify required parameter 'importPortfolios' is not null or undefined
            assertParamExists('convertFrom', 'importPortfolios', importPortfolios)
            const localVarPath = `/v2/integrations/convert/from/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (importPortfolios !== undefined) { 
                localVarFormParams.append('import-portfolios', importPortfolios as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides the conversion of all names to the corresponding identifiers
         * @param {InvestmentConversionNamedItems} investmentConversionNamedItems 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertNamesToIdentifiers: async (investmentConversionNamedItems: InvestmentConversionNamedItems, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentConversionNamedItems' is not null or undefined
            assertParamExists('convertNamesToIdentifiers', 'investmentConversionNamedItems', investmentConversionNamedItems)
            const localVarPath = `/v2/integrations/convert/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentConversionNamedItems, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Convertion to an external excel format.
         * @param {InvestmentExportConverterType} type 
         * @param {Array<InvestmentExport>} investmentExport 
         * @param {boolean} [persist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertTo: async (type: InvestmentExportConverterType, investmentExport: Array<InvestmentExport>, persist?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('convertTo', 'type', type)
            // verify required parameter 'investmentExport' is not null or undefined
            assertParamExists('convertTo', 'investmentExport', investmentExport)
            const localVarPath = `/v2/integrations/convert/to/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentExport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {UniverseImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertUniverseFrom: async (type: UniverseImportConverterType, importPortfolios: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('convertUniverseFrom', 'type', type)
            // verify required parameter 'importPortfolios' is not null or undefined
            assertParamExists('convertUniverseFrom', 'importPortfolios', importPortfolios)
            const localVarPath = `/v2/integrations/convert/universe/from/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (importPortfolios !== undefined) { 
                localVarFormParams.append('import-portfolios', importPortfolios as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export on the internal excel format.
         * @param {string} investmentUuid 
         * @param {boolean} [enhancement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvestment: async (investmentUuid: string, enhancement?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportInvestment', 'investmentUuid', investmentUuid)
            const localVarPath = `/v2/integrations/export/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enhancement !== undefined) {
                localVarQueryParameter['enhancement'] = enhancement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Excel template to fill the desired integration
         * @param {TemplateImportType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadTemplate: async (type: TemplateImportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getUploadTemplate', 'type', type)
            const localVarPath = `/v2/integrations/template/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {any} importInstruments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInstruments: async (importInstruments: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importInstruments' is not null or undefined
            assertParamExists('importInstruments', 'importInstruments', importInstruments)
            const localVarPath = `/v2/integrations/instruments/convert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (importInstruments !== undefined) { 
                localVarFormParams.append('import-instruments', importInstruments as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import an investment on the internal format as a portfolio.
         * @param {InvestmentIdentifierImport} investmentIdentifierImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvestment: async (investmentIdentifierImport: InvestmentIdentifierImport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentIdentifierImport' is not null or undefined
            assertParamExists('importInvestment', 'investmentIdentifierImport', investmentIdentifierImport)
            const localVarPath = `/v2/integrations/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentIdentifierImport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import an universe on the internal format as a portfolio.
         * @param {UniverseImport} universeImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUniverse: async (universeImport: UniverseImport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeImport' is not null or undefined
            assertParamExists('importUniverse', 'universeImport', universeImport)
            const localVarPath = `/v2/integrations/import/universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(universeImport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentExportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExportConverterType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/investment/export-converter-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of possibles value of ImportConnectionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveImportConnectionType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/import-connection-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInvestmentImportConverterType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/investment/import-converter-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of possibles value of UniverseImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUniverseImportConverterType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/universe/import-converter-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Executes the customise instruments
         * @param {Array<ProxyPreferenceTicker>} proxyPreferenceTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstrumentsByInputList: async (proxyPreferenceTicker: Array<ProxyPreferenceTicker>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proxyPreferenceTicker' is not null or undefined
            assertParamExists('updateInstrumentsByInputList', 'proxyPreferenceTicker', proxyPreferenceTicker)
            const localVarPath = `/v2/integrations/instruments/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proxyPreferenceTicker, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsControllerApi - functional programming interface
 * @export
 */
export const IntegrationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal format, notifying conversion errors, and attaching to the InvestmentImport DTO some information about for ex. status and actions.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertCheckFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentConversionNamedItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertCheckFrom(type, importPortfolios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentImportNamesItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertFrom(type, importPortfolios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Provides the conversion of all names to the corresponding identifiers
         * @param {InvestmentConversionNamedItems} investmentConversionNamedItems 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertNamesToIdentifiers(investmentConversionNamedItems: InvestmentConversionNamedItems, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentConversionIdentifierItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertNamesToIdentifiers(investmentConversionNamedItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Convertion to an external excel format.
         * @param {InvestmentExportConverterType} type 
         * @param {Array<InvestmentExport>} investmentExport 
         * @param {boolean} [persist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertTo(type: InvestmentExportConverterType, investmentExport: Array<InvestmentExport>, persist?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertTo(type, investmentExport, persist, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {UniverseImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertUniverseFrom(type: UniverseImportConverterType, importPortfolios: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UniverseImportItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertUniverseFrom(type, importPortfolios, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export on the internal excel format.
         * @param {string} investmentUuid 
         * @param {boolean} [enhancement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportInvestment(investmentUuid: string, enhancement?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportInvestment(investmentUuid, enhancement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Excel template to fill the desired integration
         * @param {TemplateImportType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadTemplate(type: TemplateImportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadTemplate(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {any} importInstruments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importInstruments(importInstruments: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importInstruments(importInstruments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import an investment on the internal format as a portfolio.
         * @param {InvestmentIdentifierImport} investmentIdentifierImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importInvestment(investmentIdentifierImport: InvestmentIdentifierImport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importInvestment(investmentIdentifierImport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import an universe on the internal format as a portfolio.
         * @param {UniverseImport} universeImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importUniverse(universeImport: UniverseImport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importUniverse(universeImport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentExportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveExportConverterType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentExportConverterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveExportConverterType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of possibles value of ImportConnectionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveImportConnectionType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveImportConnectionType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInvestmentImportConverterType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentImportConverterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInvestmentImportConverterType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of possibles value of UniverseImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUniverseImportConverterType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniverseImportConverterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUniverseImportConverterType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Executes the customise instruments
         * @param {Array<ProxyPreferenceTicker>} proxyPreferenceTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInstrumentsByInputList(proxyPreferenceTicker: Array<ProxyPreferenceTicker>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInstrumentsByInputList(proxyPreferenceTicker, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsControllerApi - factory interface
 * @export
 */
export const IntegrationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal format, notifying conversion errors, and attaching to the InvestmentImport DTO some information about for ex. status and actions.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCheckFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: any): AxiosPromise<InvestmentConversionNamedItems> {
            return localVarFp.convertCheckFrom(type, importPortfolios, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {InvestmentImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: any): AxiosPromise<InvestmentImportNamesItems> {
            return localVarFp.convertFrom(type, importPortfolios, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides the conversion of all names to the corresponding identifiers
         * @param {InvestmentConversionNamedItems} investmentConversionNamedItems 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertNamesToIdentifiers(investmentConversionNamedItems: InvestmentConversionNamedItems, options?: any): AxiosPromise<InvestmentConversionIdentifierItems> {
            return localVarFp.convertNamesToIdentifiers(investmentConversionNamedItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Convertion to an external excel format.
         * @param {InvestmentExportConverterType} type 
         * @param {Array<InvestmentExport>} investmentExport 
         * @param {boolean} [persist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertTo(type: InvestmentExportConverterType, investmentExport: Array<InvestmentExport>, persist?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.convertTo(type, investmentExport, persist, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {UniverseImportConverterType} type 
         * @param {any} importPortfolios 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertUniverseFrom(type: UniverseImportConverterType, importPortfolios: any, options?: any): AxiosPromise<UniverseImportItems> {
            return localVarFp.convertUniverseFrom(type, importPortfolios, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export on the internal excel format.
         * @param {string} investmentUuid 
         * @param {boolean} [enhancement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvestment(investmentUuid: string, enhancement?: boolean, options?: any): AxiosPromise<InvestmentExport> {
            return localVarFp.exportInvestment(investmentUuid, enhancement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Excel template to fill the desired integration
         * @param {TemplateImportType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadTemplate(type: TemplateImportType, options?: any): AxiosPromise<any> {
            return localVarFp.getUploadTemplate(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
         * @param {any} importInstruments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInstruments(importInstruments: any, options?: any): AxiosPromise<InstrumentImportResponse> {
            return localVarFp.importInstruments(importInstruments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import an investment on the internal format as a portfolio.
         * @param {InvestmentIdentifierImport} investmentIdentifierImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvestment(investmentIdentifierImport: InvestmentIdentifierImport, options?: any): AxiosPromise<InvestmentImportResponse> {
            return localVarFp.importInvestment(investmentIdentifierImport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import an universe on the internal format as a portfolio.
         * @param {UniverseImport} universeImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUniverse(universeImport: UniverseImport, options?: any): AxiosPromise<InvestmentImportResponse> {
            return localVarFp.importUniverse(universeImport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentExportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExportConverterType(options?: any): AxiosPromise<Array<InvestmentExportConverterType>> {
            return localVarFp.retrieveExportConverterType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of possibles value of ImportConnectionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveImportConnectionType(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveImportConnectionType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of possibles value of InvestmentImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInvestmentImportConverterType(options?: any): AxiosPromise<Array<InvestmentImportConverterType>> {
            return localVarFp.retrieveInvestmentImportConverterType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of possibles value of UniverseImportConverterType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUniverseImportConverterType(options?: any): AxiosPromise<Array<UniverseImportConverterType>> {
            return localVarFp.retrieveUniverseImportConverterType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Executes the customise instruments
         * @param {Array<ProxyPreferenceTicker>} proxyPreferenceTicker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstrumentsByInputList(proxyPreferenceTicker: Array<ProxyPreferenceTicker>, options?: any): AxiosPromise<Array<ReviewTicker>> {
            return localVarFp.updateInstrumentsByInputList(proxyPreferenceTicker, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsControllerApi - object-oriented interface
 * @export
 * @class IntegrationsControllerApi
 * @extends {BaseAPI}
 */
export class IntegrationsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Executes the conversion from the excel format to the internal format, notifying conversion errors, and attaching to the InvestmentImport DTO some information about for ex. status and actions.
     * @param {InvestmentImportConverterType} type 
     * @param {any} importPortfolios 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public convertCheckFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).convertCheckFrom(type, importPortfolios, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
     * @param {InvestmentImportConverterType} type 
     * @param {any} importPortfolios 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public convertFrom(type: InvestmentImportConverterType, importPortfolios: any, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).convertFrom(type, importPortfolios, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides the conversion of all names to the corresponding identifiers
     * @param {InvestmentConversionNamedItems} investmentConversionNamedItems 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public convertNamesToIdentifiers(investmentConversionNamedItems: InvestmentConversionNamedItems, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).convertNamesToIdentifiers(investmentConversionNamedItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Convertion to an external excel format.
     * @param {InvestmentExportConverterType} type 
     * @param {Array<InvestmentExport>} investmentExport 
     * @param {boolean} [persist] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public convertTo(type: InvestmentExportConverterType, investmentExport: Array<InvestmentExport>, persist?: boolean, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).convertTo(type, investmentExport, persist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
     * @param {UniverseImportConverterType} type 
     * @param {any} importPortfolios 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public convertUniverseFrom(type: UniverseImportConverterType, importPortfolios: any, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).convertUniverseFrom(type, importPortfolios, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export on the internal excel format.
     * @param {string} investmentUuid 
     * @param {boolean} [enhancement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public exportInvestment(investmentUuid: string, enhancement?: boolean, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).exportInvestment(investmentUuid, enhancement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Excel template to fill the desired integration
     * @param {TemplateImportType} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public getUploadTemplate(type: TemplateImportType, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).getUploadTemplate(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Executes the conversion from the excel format to the internal InvestmentImport format, notifying eventual conversion errors.
     * @param {any} importInstruments 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public importInstruments(importInstruments: any, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).importInstruments(importInstruments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import an investment on the internal format as a portfolio.
     * @param {InvestmentIdentifierImport} investmentIdentifierImport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public importInvestment(investmentIdentifierImport: InvestmentIdentifierImport, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).importInvestment(investmentIdentifierImport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import an universe on the internal format as a portfolio.
     * @param {UniverseImport} universeImport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public importUniverse(universeImport: UniverseImport, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).importUniverse(universeImport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of possibles value of InvestmentExportConverterType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public retrieveExportConverterType(options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).retrieveExportConverterType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of possibles value of ImportConnectionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public retrieveImportConnectionType(options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).retrieveImportConnectionType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of possibles value of InvestmentImportConverterType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public retrieveInvestmentImportConverterType(options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).retrieveInvestmentImportConverterType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of possibles value of UniverseImportConverterType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public retrieveUniverseImportConverterType(options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).retrieveUniverseImportConverterType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Executes the customise instruments
     * @param {Array<ProxyPreferenceTicker>} proxyPreferenceTicker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsControllerApi
     */
    public updateInstrumentsByInputList(proxyPreferenceTicker: Array<ProxyPreferenceTicker>, options?: AxiosRequestConfig) {
        return IntegrationsControllerApiFp(this.configuration).updateInstrumentsByInputList(proxyPreferenceTicker, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentBenchmarksControllerApi - axios parameter creator
 * @export
 */
export const InvestmentBenchmarksControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the benchmarks that could be associated with the current investment, included the ones that are yet associated with it, with some information useful for the benchmark selection screen.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompatibleBenchmarks: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCompatibleBenchmarks', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/benchmarks/available-benchmarks/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentBenchmarks: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentBenchmarks', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/benchmarks/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {EditInvestmentBenchmarksRequest} editInvestmentBenchmarksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentBenchmarks: async (editInvestmentBenchmarksRequest: EditInvestmentBenchmarksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editInvestmentBenchmarksRequest' is not null or undefined
            assertParamExists('setInvestmentBenchmarks', 'editInvestmentBenchmarksRequest', editInvestmentBenchmarksRequest)
            const localVarPath = `/v3/investments/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editInvestmentBenchmarksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentBenchmarksControllerApi - functional programming interface
 * @export
 */
export const InvestmentBenchmarksControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentBenchmarksControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the benchmarks that could be associated with the current investment, included the ones that are yet associated with it, with some information useful for the benchmark selection screen.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompatibleBenchmarks(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompatibleBenchmarkEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompatibleBenchmarks(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentBenchmarks(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentBenchmarkMinInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentBenchmarks(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {EditInvestmentBenchmarksRequest} editInvestmentBenchmarksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvestmentBenchmarks(editInvestmentBenchmarksRequest: EditInvestmentBenchmarksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvestmentBenchmarks(editInvestmentBenchmarksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentBenchmarksControllerApi - factory interface
 * @export
 */
export const InvestmentBenchmarksControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentBenchmarksControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the benchmarks that could be associated with the current investment, included the ones that are yet associated with it, with some information useful for the benchmark selection screen.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompatibleBenchmarks(investmentUuid: string, options?: any): AxiosPromise<Array<CompatibleBenchmarkEntry>> {
            return localVarFp.getCompatibleBenchmarks(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentBenchmarks(investmentUuid: string, options?: any): AxiosPromise<Array<InvestmentBenchmarkMinInfo>> {
            return localVarFp.getInvestmentBenchmarks(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the minimal info of all the benchmarks associated to the given investment.
         * @param {EditInvestmentBenchmarksRequest} editInvestmentBenchmarksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentBenchmarks(editInvestmentBenchmarksRequest: EditInvestmentBenchmarksRequest, options?: any): AxiosPromise<InvestmentsModel> {
            return localVarFp.setInvestmentBenchmarks(editInvestmentBenchmarksRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentBenchmarksControllerApi - object-oriented interface
 * @export
 * @class InvestmentBenchmarksControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentBenchmarksControllerApi extends BaseAPI {
    /**
     * 
     * @summary Returns the benchmarks that could be associated with the current investment, included the ones that are yet associated with it, with some information useful for the benchmark selection screen.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBenchmarksControllerApi
     */
    public getCompatibleBenchmarks(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBenchmarksControllerApiFp(this.configuration).getCompatibleBenchmarks(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the minimal info of all the benchmarks associated to the given investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBenchmarksControllerApi
     */
    public getInvestmentBenchmarks(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBenchmarksControllerApiFp(this.configuration).getInvestmentBenchmarks(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the minimal info of all the benchmarks associated to the given investment.
     * @param {EditInvestmentBenchmarksRequest} editInvestmentBenchmarksRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBenchmarksControllerApi
     */
    public setInvestmentBenchmarks(editInvestmentBenchmarksRequest: EditInvestmentBenchmarksRequest, options?: AxiosRequestConfig) {
        return InvestmentBenchmarksControllerApiFp(this.configuration).setInvestmentBenchmarks(editInvestmentBenchmarksRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentBulkBulkStaticConfigurationControllerV1Api - axios parameter creator
 * @export
 */
export const InvestmentBulkBulkStaticConfigurationControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates and empty draft of bulk configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftConfiguration: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createDraftConfiguration', 'requestBody', requestBody)
            const localVarPath = `/v1/investments/bulk/static/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView2: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationMarketView2', 'bulkUuid', bulkUuid)
            const localVarPath = `/v1/investments/bulk/static/configuration/steps/market-view/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investments/bulk/static/configuration/steps/market-view/selectable-market-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability2: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationStepsAvailability2', 'bulkUuid', bulkUuid)
            const localVarPath = `/v1/investments/bulk/static/configuration/availability/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationMarketView1: async (bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('loadStaticConfigurationMarketView1', 'bulkUuid', bulkUuid)
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadStaticConfigurationMarketView1', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v1/investments/bulk/static/configuration/steps/market-view/load/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration2: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('runEnhancementConfiguration2', 'bulkUuid', bulkUuid)
            const localVarPath = `/v1/investments/bulk/static/configuration/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMarketView1: async (marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewBulkSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationMarketView1', 'marketViewBulkSaveRequest', marketViewBulkSaveRequest)
            const localVarPath = `/v1/investments/bulk/static/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewBulkSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentBulkBulkStaticConfigurationControllerV1Api - functional programming interface
 * @export
 */
export const InvestmentBulkBulkStaticConfigurationControllerV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentBulkBulkStaticConfigurationControllerV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates and empty draft of bulk configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftConfiguration(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkStepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftConfiguration(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationMarketView2(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationMarketView2(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableMarketViews2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableMarketViews2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationStepsAvailability2(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkStepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationStepsAvailability2(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadStaticConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadStaticConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runEnhancementConfiguration2(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runEnhancementConfiguration2(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationMarketView1(marketViewBulkSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentBulkBulkStaticConfigurationControllerV1Api - factory interface
 * @export
 */
export const InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates and empty draft of bulk configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftConfiguration(requestBody: Array<string>, options?: any): AxiosPromise<BulkStepsAvailability> {
            return localVarFp.createDraftConfiguration(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView2(bulkUuid: string, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.getEnhancementConfigurationMarketView2(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews2(options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableMarketViews2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability2(bulkUuid: string, options?: any): AxiosPromise<BulkStepsAvailability> {
            return localVarFp.getEnhancementConfigurationStepsAvailability2(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadStaticConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration2(bulkUuid: string, options?: any): AxiosPromise<BulkRunResponse> {
            return localVarFp.runEnhancementConfiguration2(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setStaticConfigurationMarketView1(marketViewBulkSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentBulkBulkStaticConfigurationControllerV1Api - object-oriented interface
 * @export
 * @class InvestmentBulkBulkStaticConfigurationControllerV1Api
 * @extends {BaseAPI}
 */
export class InvestmentBulkBulkStaticConfigurationControllerV1Api extends BaseAPI {
    /**
     * 
     * @summary Creates and empty draft of bulk configuration, or reset the current if present.
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public createDraftConfiguration(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).createDraftConfiguration(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all info about market view selected by the user in this step
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public getEnhancementConfigurationMarketView2(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).getEnhancementConfigurationMarketView2(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all market view allowed in bulk enhance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public getEnhancementConfigurationSelectableMarketViews2(options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).getEnhancementConfigurationSelectableMarketViews2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the general availability status of the steps of the configuration flow.
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public getEnhancementConfigurationStepsAvailability2(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).getEnhancementConfigurationStepsAvailability2(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} bulkUuid 
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public loadStaticConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).loadStaticConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective bulk enhancement.
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public runEnhancementConfiguration2(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).runEnhancementConfiguration2(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkBulkStaticConfigurationControllerV1Api
     */
    public setStaticConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentBulkBulkStaticConfigurationControllerV1ApiFp(this.configuration).setStaticConfigurationMarketView1(marketViewBulkSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentBulkEnhancementConfigurationControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentBulkEnhancementConfigurationControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an empty draft of bulk enhancement configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBulkEnhancement: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createDraftBulkEnhancement', 'requestBody', requestBody)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView1: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationMarketView1', 'bulkUuid', bulkUuid)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/steps/market-view/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/steps/market-view/selectable-market-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability1: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationStepsAvailability1', 'bulkUuid', bulkUuid)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/availability/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load the information about a specific market view by identifier
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationMarketView1: async (bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('loadEnhancementConfigurationMarketView1', 'bulkUuid', bulkUuid)
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadEnhancementConfigurationMarketView1', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/steps/market-view/load/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration1: async (bulkUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUuid' is not null or undefined
            assertParamExists('runEnhancementConfiguration1', 'bulkUuid', bulkUuid)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/{bulk-uuid}`
                .replace(`{${"bulk-uuid"}}`, encodeURIComponent(String(bulkUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save market view fields selected by the user 
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMarketView1: async (marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewBulkSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationMarketView1', 'marketViewBulkSaveRequest', marketViewBulkSaveRequest)
            const localVarPath = `/v4/investments/bulk-enhancement/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewBulkSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentBulkEnhancementConfigurationControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentBulkEnhancementConfigurationControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentBulkEnhancementConfigurationControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of bulk enhancement configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftBulkEnhancement(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkStepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftBulkEnhancement(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationMarketView1(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationMarketView1(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableMarketViews1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableMarketViews1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationStepsAvailability1(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkStepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationStepsAvailability1(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load the information about a specific market view by identifier
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadEnhancementConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadEnhancementConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runEnhancementConfiguration1(bulkUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runEnhancementConfiguration1(bulkUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save market view fields selected by the user 
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentBulkEnhancementConfigurationControllerV4Api - factory interface
 * @export
 */
export const InvestmentBulkEnhancementConfigurationControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentBulkEnhancementConfigurationControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of bulk enhancement configuration, or reset the current if present.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBulkEnhancement(requestBody: Array<string>, options?: any): AxiosPromise<BulkStepsAvailability> {
            return localVarFp.createDraftBulkEnhancement(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all info about market view selected by the user in this step
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView1(bulkUuid: string, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.getEnhancementConfigurationMarketView1(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all market view allowed in bulk enhance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews1(options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableMarketViews1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability1(bulkUuid: string, options?: any): AxiosPromise<BulkStepsAvailability> {
            return localVarFp.getEnhancementConfigurationStepsAvailability1(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load the information about a specific market view by identifier
         * @param {string} bulkUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadEnhancementConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective bulk enhancement.
         * @param {string} bulkUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration1(bulkUuid: string, options?: any): AxiosPromise<BulkRunResponse> {
            return localVarFp.runEnhancementConfiguration1(bulkUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save market view fields selected by the user 
         * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentBulkEnhancementConfigurationControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentBulkEnhancementConfigurationControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentBulkEnhancementConfigurationControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Creates an empty draft of bulk enhancement configuration, or reset the current if present.
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public createDraftBulkEnhancement(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).createDraftBulkEnhancement(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all info about market view selected by the user in this step
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationMarketView1(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationMarketView1(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all market view allowed in bulk enhance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableMarketViews1(options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableMarketViews1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the general availability status of the steps of the configuration flow.
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationStepsAvailability1(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationStepsAvailability1(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load the information about a specific market view by identifier
     * @param {string} bulkUuid 
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public loadEnhancementConfigurationMarketView1(bulkUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).loadEnhancementConfigurationMarketView1(bulkUuid, marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective bulk enhancement.
     * @param {string} bulkUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public runEnhancementConfiguration1(bulkUuid: string, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).runEnhancementConfiguration1(bulkUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save market view fields selected by the user 
     * @param {MarketViewBulkSaveRequest} marketViewBulkSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentBulkEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest: MarketViewBulkSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentBulkEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationMarketView1(marketViewBulkSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a commentary for the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createCommentary1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a commentary for a list of investment.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryBulk: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createCommentaryBulk', 'requestBody', requestBody)
            const localVarPath = `/v4/investments/commentary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('deleteInvestment', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a copy of the given investment.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateInvestment: async (investmentUuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('duplicateInvestment', 'investmentUuid', investmentUuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('duplicateInvestment', 'name', name)
            const localVarPath = `/v4/investments/duplicate/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the investment note
         * @param {InvestmentEditNoteRequest} investmentEditNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInvestmentNote: async (investmentEditNoteRequest: InvestmentEditNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentEditNoteRequest' is not null or undefined
            assertParamExists('editInvestmentNote', 'investmentEditNoteRequest', investmentEditNoteRequest)
            const localVarPath = `/v4/investments/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentEditNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL1: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getACL1', 'uuid', uuid)
            const localVarPath = `/v4/investments/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter1: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getAclEditStarter1', 'uuid', uuid)
            const localVarPath = `/v4/investments/{uuid}/acl-edit-starter`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the active investments of a user (no references).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the active references of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentReferenceList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/references/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the active investments projections of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectedInvestmentList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/projected-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL1: async (uuid: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserACL1', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserACL1', 'userId', userId)
            const localVarPath = `/v4/investments/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission1: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('grantUserPermission1', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('grantUserPermission1', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('grantUserPermission1', 'permission', permission)
            const localVarPath = `/v4/investments/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission1: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('hasUserPermission1', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasUserPermission1', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('hasUserPermission1', 'permission', permission)
            const localVarPath = `/v4/investments/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInvestmentNameAvailable: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('isInvestmentNameAvailable', 'name', name)
            const localVarPath = `/v4/investments/name-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the investment note
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInvestmentNote: async (investmentUuid: string, historyUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('removeInvestmentNote', 'investmentUuid', investmentUuid)
            // verify required parameter 'historyUuid' is not null or undefined
            assertParamExists('removeInvestmentNote', 'historyUuid', historyUuid)
            const localVarPath = `/v4/investments/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investmentUuid'] = investmentUuid;
            }

            if (historyUuid !== undefined) {
                localVarQueryParameter['historyUuid'] = historyUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameInvestment: async (investmentUuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('renameInvestment', 'investmentUuid', investmentUuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameInvestment', 'name', name)
            const localVarPath = `/v4/investments/rename/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission1: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('revokeUserPermission1', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('revokeUserPermission1', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('revokeUserPermission1', 'permission', permission)
            const localVarPath = `/v4/investments/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL1: async (uuid: string, richAccessControl: Array<RichAccessControl>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setACL1', 'uuid', uuid)
            // verify required parameter 'richAccessControl' is not null or undefined
            assertParamExists('setACL1', 'richAccessControl', richAccessControl)
            const localVarPath = `/v4/investments/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richAccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL1: async (uuid: string, userId: string, accessPermission: Array<AccessPermission>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setUserACL1', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserACL1', 'userId', userId)
            // verify required parameter 'accessPermission' is not null or undefined
            assertParamExists('setUserACL1', 'accessPermission', accessPermission)
            const localVarPath = `/v4/investments/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a commentary for the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentary1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentary1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a commentary for a list of investment.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentaryBulk(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentaryBulk(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestment(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestment(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a copy of the given investment.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateInvestment(investmentUuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateInvestment(investmentUuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the investment note
         * @param {InvestmentEditNoteRequest} investmentEditNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editInvestmentNote(investmentEditNoteRequest: InvestmentEditNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editInvestmentNote(investmentEditNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getACL1(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getACL1(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAclEditStarter1(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclEditStarter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAclEditStarter1(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the active investments of a user (no references).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the active references of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentReferenceList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentReferenceListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentReferenceList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the active investments projections of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectedInvestmentList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentProjectedListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectedInvestmentList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserACL1(uuid: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichAccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserACL1(uuid, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantUserPermission1(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserPermission1(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isInvestmentNameAvailable(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isInvestmentNameAvailable(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove the investment note
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInvestmentNote(investmentUuid: string, historyUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInvestmentNote(investmentUuid, historyUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameInvestment(investmentUuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameInvestment(investmentUuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserPermission1(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setACL1(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setACL1(uuid, richAccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserACL1(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserACL1(uuid, userId, accessPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentControllerV4Api - factory interface
 * @export
 */
export const InvestmentControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a commentary for the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary1(investmentUuid: string, options?: any): AxiosPromise<InvestmentsModel> {
            return localVarFp.createCommentary1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a commentary for a list of investment.
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentaryBulk(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.createCommentaryBulk(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment(investmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInvestment(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a copy of the given investment.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateInvestment(investmentUuid: string, name: string, options?: any): AxiosPromise<InvestmentsModel> {
            return localVarFp.duplicateInvestment(investmentUuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the investment note
         * @param {InvestmentEditNoteRequest} investmentEditNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInvestmentNote(investmentEditNoteRequest: InvestmentEditNoteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.editInvestmentNote(investmentEditNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL1(uuid: string, options?: any): AxiosPromise<Array<AccessControl>> {
            return localVarFp.getACL1(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter1(uuid: string, options?: any): AxiosPromise<AclEditStarter> {
            return localVarFp.getAclEditStarter1(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the active investments of a user (no references).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentList(options?: any): AxiosPromise<Array<InvestmentListEntry>> {
            return localVarFp.getInvestmentList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the active references of a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentReferenceList(options?: any): AxiosPromise<Array<InvestmentReferenceListEntry>> {
            return localVarFp.getInvestmentReferenceList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the active investments projections of a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectedInvestmentList(options?: any): AxiosPromise<Array<InvestmentProjectedListEntry>> {
            return localVarFp.getProjectedInvestmentList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL1(uuid: string, userId: string, options?: any): AxiosPromise<RichAccessControl> {
            return localVarFp.getUserACL1(uuid, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.grantUserPermission1(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasUserPermission1(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInvestmentNameAvailable(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isInvestmentNameAvailable(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the investment note
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInvestmentNote(investmentUuid: string, historyUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeInvestmentNote(investmentUuid, historyUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} investmentUuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameInvestment(investmentUuid: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.renameInvestment(investmentUuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.revokeUserPermission1(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL1(uuid: string, richAccessControl: Array<RichAccessControl>, options?: any): AxiosPromise<void> {
            return localVarFp.setACL1(uuid, richAccessControl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL1(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserACL1(uuid, userId, accessPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Create a commentary for the investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public createCommentary1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).createCommentary1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a commentary for a list of investment.
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public createCommentaryBulk(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).createCommentaryBulk(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public deleteInvestment(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).deleteInvestment(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a copy of the given investment.
     * @param {string} investmentUuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public duplicateInvestment(investmentUuid: string, name: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).duplicateInvestment(investmentUuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the investment note
     * @param {InvestmentEditNoteRequest} investmentEditNoteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public editInvestmentNote(investmentEditNoteRequest: InvestmentEditNoteRequest, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).editInvestmentNote(investmentEditNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getACL1(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getACL1(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getAclEditStarter1(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getAclEditStarter1(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the active investments of a user (no references).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getInvestmentList(options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getInvestmentList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the active references of a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getInvestmentReferenceList(options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getInvestmentReferenceList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the active investments projections of a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getProjectedInvestmentList(options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getProjectedInvestmentList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public getUserACL1(uuid: string, userId: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).getUserACL1(uuid, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public grantUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).grantUserPermission1(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public hasUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).hasUserPermission1(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public isInvestmentNameAvailable(name: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).isInvestmentNameAvailable(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the investment note
     * @param {string} investmentUuid 
     * @param {string} historyUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public removeInvestmentNote(investmentUuid: string, historyUuid: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).removeInvestmentNote(investmentUuid, historyUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name for a new universe.
     * @param {string} investmentUuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public renameInvestment(investmentUuid: string, name: string, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).renameInvestment(investmentUuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public revokeUserPermission1(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).revokeUserPermission1(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {Array<RichAccessControl>} richAccessControl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public setACL1(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).setACL1(uuid, richAccessControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {Array<AccessPermission>} accessPermission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentControllerV4Api
     */
    public setUserACL1(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig) {
        return InvestmentControllerV4ApiFp(this.configuration).setUserACL1(uuid, userId, accessPermission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentCreationConfigurationControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentCreationConfigurationControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an empty draft of creation configuration, or reset the current if present.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftCreationConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationAllocationConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationInvestableUniverse: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/investable-universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationMainInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationMarketView: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationRiskConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableAllocationConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/allocation-constraints/selectable-allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableInvestableUniverses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/investable-universe/selectable-investable-universes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableMainInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/main-info/selectable-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableMarketViews: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/market-view/selectable-market-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableRiskConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/risk-constraints/selectable-risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableStrategyConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/strategy-constraints/selectable-strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationStepsAvailability: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationStrategyConstraints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/steps/strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCreationConfigurationInvestableUniverse: async (universeIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeIdentifier' is not null or undefined
            assertParamExists('loadCreationConfigurationInvestableUniverse', 'universeIdentifier', universeIdentifier)
            const localVarPath = `/v4/investments/creation/configuration/steps/investable-universe/load`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universe-identifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCreationConfigurationMarketView: async (marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadCreationConfigurationMarketView', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v4/investments/creation/configuration/steps/market-view/load`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCreationConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveConfigurationAsDraft: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/investments/creation/configuration/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationAllocationConstraints: async (allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allocationConstraintsSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationAllocationConstraints', 'allocationConstraintsSaveRequest', allocationConstraintsSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationInvestableUniverse: async (investableUniverseSaveRequest: InvestableUniverseSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investableUniverseSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationInvestableUniverse', 'investableUniverseSaveRequest', investableUniverseSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/investable-universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investableUniverseSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationMainInfo: async (mainInfoSaveRequest: MainInfoSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mainInfoSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationMainInfo', 'mainInfoSaveRequest', mainInfoSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mainInfoSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationMarketView: async (marketViewSaveRequest: MarketViewSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationMarketView', 'marketViewSaveRequest', marketViewSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationRiskConstraints: async (riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskConstraintsSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationRiskConstraints', 'riskConstraintsSaveRequest', riskConstraintsSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationStrategyConstraints: async (strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyConstraintsSaveRequest' is not null or undefined
            assertParamExists('setCreationConfigurationStrategyConstraints', 'strategyConstraintsSaveRequest', strategyConstraintsSaveRequest)
            const localVarPath = `/v4/investments/creation/configuration/steps/strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strategyConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentCreationConfigurationControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentCreationConfigurationControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentCreationConfigurationControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of creation configuration, or reset the current if present.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftCreationConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftCreationConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationAllocationConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationAllocationConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationInvestableUniverse(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationInvestableUniverse(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationMainInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationMainInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationMarketView(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationMarketView(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationRiskConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationRiskConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableAllocationConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableAllocationConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableInvestableUniverses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectableInvestableUniverse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableInvestableUniverses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableMainInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableMainInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableMarketViews(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableMarketViews(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableRiskConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableRiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableRiskConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationSelectableStrategyConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableStrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationSelectableStrategyConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationStepsAvailability(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationStepsAvailability(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationConfigurationStrategyConstraints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationConfigurationStrategyConstraints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftStatus2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftStatus2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCreationConfigurationInvestableUniverse(universeIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialInvestableUniverseTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCreationConfigurationInvestableUniverse(universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCreationConfigurationMarketView(marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCreationConfigurationMarketView(marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCreationConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCreationConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveConfigurationAsDraft(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveConfigurationAsDraft(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationMainInfo(mainInfoSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationMarketView(marketViewSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentCreationConfigurationControllerV4Api - factory interface
 * @export
 */
export const InvestmentCreationConfigurationControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentCreationConfigurationControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of creation configuration, or reset the current if present.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftCreationConfiguration(options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.createDraftCreationConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationAllocationConstraints(options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.getCreationConfigurationAllocationConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationInvestableUniverse(options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.getCreationConfigurationInvestableUniverse(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationMainInfo(options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.getCreationConfigurationMainInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationMarketView(options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.getCreationConfigurationMarketView(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationRiskConstraints(options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.getCreationConfigurationRiskConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableAllocationConstraints(options?: any): AxiosPromise<SelectableAllocationConstraintsResponse> {
            return localVarFp.getCreationConfigurationSelectableAllocationConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableInvestableUniverses(options?: any): AxiosPromise<Array<SelectableInvestableUniverse>> {
            return localVarFp.getCreationConfigurationSelectableInvestableUniverses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableMainInfo(options?: any): AxiosPromise<SelectableMainInfoResponse> {
            return localVarFp.getCreationConfigurationSelectableMainInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableMarketViews(options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getCreationConfigurationSelectableMarketViews(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableRiskConstraints(options?: any): AxiosPromise<SelectableRiskConstraintsResponse> {
            return localVarFp.getCreationConfigurationSelectableRiskConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationSelectableStrategyConstraints(options?: any): AxiosPromise<SelectableStrategyConstraintsResponse> {
            return localVarFp.getCreationConfigurationSelectableStrategyConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationStepsAvailability(options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.getCreationConfigurationStepsAvailability(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationConfigurationStrategyConstraints(options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.getCreationConfigurationStrategyConstraints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus2(options?: any): AxiosPromise<DraftSummaryResponse> {
            return localVarFp.getDraftStatus2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCreationConfigurationInvestableUniverse(universeIdentifier: string, options?: any): AxiosPromise<Array<PartialInvestableUniverseTicker>> {
            return localVarFp.loadCreationConfigurationInvestableUniverse(universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCreationConfigurationMarketView(marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadCreationConfigurationMarketView(marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCreationConfiguration(options?: any): AxiosPromise<RunResponse> {
            return localVarFp.runCreationConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective creation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveConfigurationAsDraft(options?: any): AxiosPromise<RunResponse> {
            return localVarFp.saveConfigurationAsDraft(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.setCreationConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setCreationConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentCreationConfigurationControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentCreationConfigurationControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentCreationConfigurationControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Creates an empty draft of creation configuration, or reset the current if present.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public createDraftCreationConfiguration(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).createDraftCreationConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the allocation constraints selected by the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationAllocationConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationAllocationConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the investment universe selected by the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationInvestableUniverse(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationInvestableUniverse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationMainInfo(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationMainInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationMarketView(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationMarketView(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationRiskConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationRiskConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableAllocationConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableAllocationConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the reference universe selectable for the enhancement of the current investment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableInvestableUniverses(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableInvestableUniverses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableMainInfo(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableMainInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableMarketViews(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableMarketViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableRiskConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableRiskConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationSelectableStrategyConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationSelectableStrategyConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the general availability status of the steps of the configuration flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationStepsAvailability(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationStepsAvailability(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getCreationConfigurationStrategyConstraints(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getCreationConfigurationStrategyConstraints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of the current draft.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public getDraftStatus2(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).getDraftStatus2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
     * @param {string} universeIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public loadCreationConfigurationInvestableUniverse(universeIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).loadCreationConfigurationInvestableUniverse(universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public loadCreationConfigurationMarketView(marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).loadCreationConfigurationMarketView(marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective creation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public runCreationConfiguration(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).runCreationConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective creation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public saveConfigurationAsDraft(options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).saveConfigurationAsDraft(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
     * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the main info of the investment.
     * @param {MainInfoSaveRequest} mainInfoSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {MarketViewSaveRequest} marketViewSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentCreationConfigurationControllerV4Api
     */
    public setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentCreationConfigurationControllerV4ApiFp(this.configuration).setCreationConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentDraftConfigurationControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentDraftConfigurationControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftDraftConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createDraftDraftConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationAllocationConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationAllocationConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/allocation-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationInvestableUniverse: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationInvestableUniverse', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/investable-universe/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationMainInfo: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationMainInfo', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/main-info/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationMarketView: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationMarketView', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/market-view/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationRiskConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationRiskConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/risk-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableAllocationConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableAllocationConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/allocation-constraints/selectable-allocation-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableInvestableUniverses: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableInvestableUniverses', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/investable-universe/selectable-investable-universes/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableMainInfo: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableMainInfo', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/main-info/selectable-fields/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableMarketViews: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableMarketViews', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/market-view/selectable-market-views/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableRiskConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableRiskConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/risk-constraints/selectable-risk-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableStrategyConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationSelectableStrategyConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/strategy-constraints/selectable-strategy-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationStepsAvailability: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationStepsAvailability', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/availability/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationStrategyConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftConfigurationStrategyConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/steps/strategy-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftStatus1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadDraftConfigurationInvestableUniverse: async (investmentUuid: string, universeIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadDraftConfigurationInvestableUniverse', 'investmentUuid', investmentUuid)
            // verify required parameter 'universeIdentifier' is not null or undefined
            assertParamExists('loadDraftConfigurationInvestableUniverse', 'universeIdentifier', universeIdentifier)
            const localVarPath = `/v4/investments/draft/configuration/steps/investable-universe/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universe-identifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadDraftConfigurationMarketView: async (investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadDraftConfigurationMarketView', 'investmentUuid', investmentUuid)
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadDraftConfigurationMarketView', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v4/investments/draft/configuration/steps/market-view/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDraftConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('runDraftConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/draft/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationAllocationConstraints: async (allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allocationConstraintsSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationAllocationConstraints', 'allocationConstraintsSaveRequest', allocationConstraintsSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationInvestableUniverse: async (investableUniverseSaveRequest: InvestableUniverseSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investableUniverseSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationInvestableUniverse', 'investableUniverseSaveRequest', investableUniverseSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/investable-universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investableUniverseSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationMainInfo: async (mainInfoSaveRequest: MainInfoSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mainInfoSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationMainInfo', 'mainInfoSaveRequest', mainInfoSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mainInfoSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationMarketView: async (marketViewSaveRequest: MarketViewSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationMarketView', 'marketViewSaveRequest', marketViewSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationRiskConstraints: async (riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskConstraintsSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationRiskConstraints', 'riskConstraintsSaveRequest', riskConstraintsSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationStrategyConstraints: async (strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyConstraintsSaveRequest' is not null or undefined
            assertParamExists('setDraftConfigurationStrategyConstraints', 'strategyConstraintsSaveRequest', strategyConstraintsSaveRequest)
            const localVarPath = `/v4/investments/draft/configuration/steps/strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strategyConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentDraftConfigurationControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentDraftConfigurationControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentDraftConfigurationControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftDraftConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftDraftConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationAllocationConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationInvestableUniverse(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationInvestableUniverse(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationMainInfo(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationMainInfo(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationMarketView(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationMarketView(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationRiskConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableAllocationConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectableInvestableUniverse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableInvestableUniverses(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableMainInfo(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableMarketViews(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableRiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableRiskConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableStrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationSelectableStrategyConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationStepsAvailability(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationStepsAvailability(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftConfigurationStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftConfigurationStrategyConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftStatus1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftStatus1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadDraftConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialInvestableUniverseTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadDraftConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadDraftConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadDraftConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDraftConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDraftConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationMainInfo(mainInfoSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationMarketView(marketViewSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentDraftConfigurationControllerV4Api - factory interface
 * @export
 */
export const InvestmentDraftConfigurationControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentDraftConfigurationControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftDraftConfiguration(investmentUuid: string, options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.createDraftDraftConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationAllocationConstraints(investmentUuid: string, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.getDraftConfigurationAllocationConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationInvestableUniverse(investmentUuid: string, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.getDraftConfigurationInvestableUniverse(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationMainInfo(investmentUuid: string, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.getDraftConfigurationMainInfo(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationMarketView(investmentUuid: string, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.getDraftConfigurationMarketView(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationRiskConstraints(investmentUuid: string, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.getDraftConfigurationRiskConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableAllocationConstraintsResponse> {
            return localVarFp.getDraftConfigurationSelectableAllocationConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: any): AxiosPromise<Array<SelectableInvestableUniverse>> {
            return localVarFp.getDraftConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableMainInfo(investmentUuid: string, options?: any): AxiosPromise<SelectableMainInfoResponse> {
            return localVarFp.getDraftConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableMarketViews(investmentUuid: string, options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getDraftConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableRiskConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableRiskConstraintsResponse> {
            return localVarFp.getDraftConfigurationSelectableRiskConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableStrategyConstraintsResponse> {
            return localVarFp.getDraftConfigurationSelectableStrategyConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationStepsAvailability(investmentUuid: string, options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.getDraftConfigurationStepsAvailability(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftConfigurationStrategyConstraints(investmentUuid: string, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.getDraftConfigurationStrategyConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus1(investmentUuid: string, options?: any): AxiosPromise<DraftSummaryResponse> {
            return localVarFp.getDraftStatus1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadDraftConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: any): AxiosPromise<Array<PartialInvestableUniverseTicker>> {
            return localVarFp.loadDraftConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadDraftConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadDraftConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDraftConfiguration(investmentUuid: string, options?: any): AxiosPromise<RunResponse> {
            return localVarFp.runDraftConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.setDraftConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setDraftConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentDraftConfigurationControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentDraftConfigurationControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentDraftConfigurationControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public createDraftDraftConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).createDraftDraftConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the allocation constraints selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationAllocationConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the investment universe selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationInvestableUniverse(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationInvestableUniverse(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationMainInfo(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationMainInfo(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationMarketView(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationMarketView(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationRiskConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableAllocationConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the reference universe selectable for the enhancement of the current investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableRiskConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationSelectableStrategyConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the general availability status of the steps of the configuration flow.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationStepsAvailability(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationStepsAvailability(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftConfigurationStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftConfigurationStrategyConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of the current draft.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public getDraftStatus1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).getDraftStatus1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
     * @param {string} investmentUuid 
     * @param {string} universeIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public loadDraftConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).loadDraftConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public loadDraftConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).loadDraftConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective enhancement.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public runDraftConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).runDraftConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
     * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the main info of the investment.
     * @param {MainInfoSaveRequest} mainInfoSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {MarketViewSaveRequest} marketViewSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentDraftConfigurationControllerV4Api
     */
    public setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentDraftConfigurationControllerV4ApiFp(this.configuration).setDraftConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentEnhancementConfigurationControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentEnhancementConfigurationControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftEnhancementConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createDraftEnhancementConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getDraftStatus', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationAllocationConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationAllocationConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/allocation-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationInvestableUniverse: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationInvestableUniverse', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/investable-universe/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMainInfo: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationMainInfo', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/main-info/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationMarketView', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/market-view/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationRiskConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationRiskConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/risk-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableAllocationConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableAllocationConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/allocation-constraints/selectable-allocation-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableInvestableUniverses: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableInvestableUniverses', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/investable-universe/selectable-investable-universes/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get modifiable main info
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMainInfo: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableMainInfo', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/main-info/{investment-uuid}/selectable-fields`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableMarketViews', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/market-view/selectable-market-views/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableRiskConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableRiskConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/risk-constraints/selectable-risk-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableStrategyConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationSelectableStrategyConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/strategy-constraints/selectable-strategy-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationStepsAvailability', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/availability/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStrategyConstraints: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getEnhancementConfigurationStrategyConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/strategy-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationInvestableUniverse: async (investmentUuid: string, universeIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadEnhancementConfigurationInvestableUniverse', 'investmentUuid', investmentUuid)
            // verify required parameter 'universeIdentifier' is not null or undefined
            assertParamExists('loadEnhancementConfigurationInvestableUniverse', 'universeIdentifier', universeIdentifier)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/investable-universe/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universe-identifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationMarketView: async (investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadEnhancementConfigurationMarketView', 'investmentUuid', investmentUuid)
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadEnhancementConfigurationMarketView', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/market-view/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('runEnhancementConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationAllocationConstraints: async (allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allocationConstraintsSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationAllocationConstraints', 'allocationConstraintsSaveRequest', allocationConstraintsSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationInvestableUniverse: async (investableUniverseSaveRequest: InvestableUniverseSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investableUniverseSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationInvestableUniverse', 'investableUniverseSaveRequest', investableUniverseSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/investable-universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investableUniverseSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMainInfo: async (mainInfoSaveRequest: MainInfoSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mainInfoSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationMainInfo', 'mainInfoSaveRequest', mainInfoSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mainInfoSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMarketView: async (marketViewSaveRequest: MarketViewSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationMarketView', 'marketViewSaveRequest', marketViewSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationRiskConstraints: async (riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskConstraintsSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationRiskConstraints', 'riskConstraintsSaveRequest', riskConstraintsSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationStrategyConstraints: async (strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyConstraintsSaveRequest' is not null or undefined
            assertParamExists('setEnhancementConfigurationStrategyConstraints', 'strategyConstraintsSaveRequest', strategyConstraintsSaveRequest)
            const localVarPath = `/v4/investments/enhancement/configuration/steps/strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strategyConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentEnhancementConfigurationControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentEnhancementConfigurationControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentEnhancementConfigurationControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftEnhancementConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraftStatus(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraftStatus(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationAllocationConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationInvestableUniverse(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationInvestableUniverse(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationMainInfo(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationMainInfo(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationMarketView(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationMarketView(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationRiskConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectableInvestableUniverse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get modifiable main info
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableMainInfo(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableMarketViews(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableRiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableRiskConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableStrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationStepsAvailability(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepsAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationStepsAvailability(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnhancementConfigurationStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnhancementConfigurationStrategyConstraints(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadEnhancementConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialInvestableUniverseTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadEnhancementConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadEnhancementConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadEnhancementConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runEnhancementConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationMainInfo(mainInfoSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationMarketView(marketViewSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentEnhancementConfigurationControllerV4Api - factory interface
 * @export
 */
export const InvestmentEnhancementConfigurationControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentEnhancementConfigurationControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftEnhancementConfiguration(investmentUuid: string, options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.createDraftEnhancementConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of the current draft.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftStatus(investmentUuid: string, options?: any): AxiosPromise<DraftSummaryResponse> {
            return localVarFp.getDraftStatus(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the allocation constraints selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationAllocationConstraints(investmentUuid: string, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationAllocationConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the investment universe selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationInvestableUniverse(investmentUuid: string, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.getEnhancementConfigurationInvestableUniverse(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMainInfo(investmentUuid: string, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.getEnhancementConfigurationMainInfo(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationMarketView(investmentUuid: string, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.getEnhancementConfigurationMarketView(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationRiskConstraints(investmentUuid: string, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationRiskConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableAllocationConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: any): AxiosPromise<Array<SelectableInvestableUniverse>> {
            return localVarFp.getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get modifiable main info
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMainInfo(investmentUuid: string, options?: any): AxiosPromise<SelectableMainInfoResponse> {
            return localVarFp.getEnhancementConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableMarketViews(investmentUuid: string, options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableRiskConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableRiskConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableRiskConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: any): AxiosPromise<SelectableStrategyConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the general availability status of the steps of the configuration flow.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStepsAvailability(investmentUuid: string, options?: any): AxiosPromise<StepsAvailability> {
            return localVarFp.getEnhancementConfigurationStepsAvailability(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnhancementConfigurationStrategyConstraints(investmentUuid: string, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.getEnhancementConfigurationStrategyConstraints(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: any): AxiosPromise<Array<PartialInvestableUniverseTicker>> {
            return localVarFp.loadEnhancementConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEnhancementConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadEnhancementConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the effective enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runEnhancementConfiguration(investmentUuid: string, options?: any): AxiosPromise<RunResponse> {
            return localVarFp.runEnhancementConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the main info of the investment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.setEnhancementConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setEnhancementConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentEnhancementConfigurationControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentEnhancementConfigurationControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentEnhancementConfigurationControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Creates an empty draft of enhancement configuration, or reset the current if present.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public createDraftEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).createDraftEnhancementConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of the current draft.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getDraftStatus(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getDraftStatus(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the allocation constraints selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationAllocationConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the investment universe selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationInvestableUniverse(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationInvestableUniverse(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationMainInfo(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationMainInfo(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationMarketView(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationMarketView(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationRiskConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableAllocationConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the reference universe selectable for the enhancement of the current investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get modifiable main info
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableRiskConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableRiskConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationSelectableStrategyConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the general availability status of the steps of the configuration flow.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationStepsAvailability(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationStepsAvailability(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public getEnhancementConfigurationStrategyConstraints(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).getEnhancementConfigurationStrategyConstraints(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
     * @param {string} investmentUuid 
     * @param {string} universeIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public loadEnhancementConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).loadEnhancementConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public loadEnhancementConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).loadEnhancementConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the effective enhancement.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public runEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).runEnhancementConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
     * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the main info of the investment.
     * @param {MainInfoSaveRequest} mainInfoSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {MarketViewSaveRequest} marketViewSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementConfigurationControllerV4Api
     */
    public setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentEnhancementConfigurationControllerV4ApiFp(this.configuration).setEnhancementConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentEnhancementControllerV4Api - axios parameter creator
 * @export
 */
export const InvestmentEnhancementControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accepts a pending enhancement, transforming the enhancement reports into the last current snapshot.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptEnhancement: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('acceptEnhancement', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/accept/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a commentary for the investment enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('createCommentary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Launches an enhancement using the current configuration.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixVar: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('fixVar', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/fix-var/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejects a pending enhancement, deleting the enhancement reports.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectEnhancement: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('rejectEnhancement', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/reject/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentEnhancementControllerV4Api - functional programming interface
 * @export
 */
export const InvestmentEnhancementControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentEnhancementControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accepts a pending enhancement, transforming the enhancement reports into the last current snapshot.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptEnhancement(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptEnhancement(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a commentary for the investment enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsEnhancedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Launches an enhancement using the current configuration.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixVar(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixVar(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rejects a pending enhancement, deleting the enhancement reports.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectEnhancement(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectEnhancement(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentEnhancementControllerV4Api - factory interface
 * @export
 */
export const InvestmentEnhancementControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentEnhancementControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Accepts a pending enhancement, transforming the enhancement reports into the last current snapshot.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptEnhancement(investmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptEnhancement(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a commentary for the investment enhancement.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentary(investmentUuid: string, options?: any): AxiosPromise<InvestmentsEnhancedModel> {
            return localVarFp.createCommentary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Launches an enhancement using the current configuration.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixVar(investmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.fixVar(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rejects a pending enhancement, deleting the enhancement reports.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectEnhancement(investmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.rejectEnhancement(investmentUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentEnhancementControllerV4Api - object-oriented interface
 * @export
 * @class InvestmentEnhancementControllerV4Api
 * @extends {BaseAPI}
 */
export class InvestmentEnhancementControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Accepts a pending enhancement, transforming the enhancement reports into the last current snapshot.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementControllerV4Api
     */
    public acceptEnhancement(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementControllerV4ApiFp(this.configuration).acceptEnhancement(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a commentary for the investment enhancement.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementControllerV4Api
     */
    public createCommentary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementControllerV4ApiFp(this.configuration).createCommentary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Launches an enhancement using the current configuration.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementControllerV4Api
     */
    public fixVar(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementControllerV4ApiFp(this.configuration).fixVar(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rejects a pending enhancement, deleting the enhancement reports.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementControllerV4Api
     */
    public rejectEnhancement(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementControllerV4ApiFp(this.configuration).rejectEnhancement(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentEnhancementExportControllerApi - axios parameter creator
 * @export
 */
export const InvestmentEnhancementExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports the composition of the enhancement of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'FULL_COMPOSITION' | 'TRADES_ONLY'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEnhancedComposition: async (uuid: string, type?: 'FULL_COMPOSITION' | 'TRADES_ONLY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportEnhancedComposition', 'uuid', uuid)
            const localVarPath = `/v4/investments/enhancement/export/composition/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportExposure1: async (uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportExposure1', 'uuid', uuid)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('exportExposure1', 'type', type)
            const localVarPath = `/v4/investments/enhancement/export/exposure/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFactors1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportFactors1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/export/factors/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGraphOfExposure1: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportGraphOfExposure1', 'uuid', uuid)
            const localVarPath = `/v4/investments/enhancement/export/exposure/graph/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMultiGraphOfExposure1: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportMultiGraphOfExposure1', 'uuid', uuid)
            const localVarPath = `/v4/investments/enhancement/export/exposure/multi-graph/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentEnhancementExportControllerApi - functional programming interface
 * @export
 */
export const InvestmentEnhancementExportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentEnhancementExportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports the composition of the enhancement of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'FULL_COMPOSITION' | 'TRADES_ONLY'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEnhancedComposition(uuid: string, type?: 'FULL_COMPOSITION' | 'TRADES_ONLY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEnhancedComposition(uuid, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportExposure1(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportExposure1(uuid, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportFactors1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportFactors1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportGraphOfExposure1(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportGraphOfExposure1(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportMultiGraphOfExposure1(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportMultiGraphOfExposure1(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentEnhancementExportControllerApi - factory interface
 * @export
 */
export const InvestmentEnhancementExportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentEnhancementExportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports the composition of the enhancement of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'FULL_COMPOSITION' | 'TRADES_ONLY'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEnhancedComposition(uuid: string, type?: 'FULL_COMPOSITION' | 'TRADES_ONLY', options?: any): AxiosPromise<any> {
            return localVarFp.exportEnhancedComposition(uuid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportExposure1(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: any): AxiosPromise<any> {
            return localVarFp.exportExposure1(uuid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFactors1(investmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportFactors1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGraphOfExposure1(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportGraphOfExposure1(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMultiGraphOfExposure1(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportMultiGraphOfExposure1(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentEnhancementExportControllerApi - object-oriented interface
 * @export
 * @class InvestmentEnhancementExportControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentEnhancementExportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Exports the composition of the enhancement of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {'FULL_COMPOSITION' | 'TRADES_ONLY'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementExportControllerApi
     */
    public exportEnhancedComposition(uuid: string, type?: 'FULL_COMPOSITION' | 'TRADES_ONLY', options?: AxiosRequestConfig) {
        return InvestmentEnhancementExportControllerApiFp(this.configuration).exportEnhancedComposition(uuid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementExportControllerApi
     */
    public exportExposure1(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig) {
        return InvestmentEnhancementExportControllerApiFp(this.configuration).exportExposure1(uuid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the factors of investment and benchmark with delta.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementExportControllerApi
     */
    public exportFactors1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementExportControllerApiFp(this.configuration).exportFactors1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementExportControllerApi
     */
    public exportGraphOfExposure1(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementExportControllerApiFp(this.configuration).exportGraphOfExposure1(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementExportControllerApi
     */
    public exportMultiGraphOfExposure1(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementExportControllerApiFp(this.configuration).exportMultiGraphOfExposure1(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentEnhancementReportsControllerApi - axios parameter creator
 * @export
 */
export const InvestmentEnhancementReportsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaries1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentaries1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/enhancement/reports/commentaries/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/enhancement/reports/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompositionVolatilityContribution1: async (investmentUuid: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCompositionVolatilityContribution1', 'investmentUuid', investmentUuid)
            // verify required parameter 'horizon' is not null or undefined
            assertParamExists('getCompositionVolatilityContribution1', 'horizon', horizon)
            const localVarPath = `/v3/investments/enhancement/reports/composition-volatility-contribution/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (horizon !== undefined) {
                localVarQueryParameter['horizon'] = horizon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureDecomposition1: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getExposureDecomposition1', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getExposureDecomposition1', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/enhancement/reports/exposure-decomposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investment-uuid'] = investmentUuid;
            }

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentComposition1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentComposition1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/enhancement/reports/composition/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentEnhancementSummary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentEnhancementSummary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/enhancement/reports/summary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentFactors1: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentFactors1', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getInvestmentFactors1', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/enhancement/reports/factors/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringMetrics1: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getMonitoringMetrics1', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/enhancement/reports/monitoring-metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioExAnteMetrics1: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getPortfolioExAnteMetrics1', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getPortfolioExAnteMetrics1', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/enhancement/reports/portfolio-exante-metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoLevelsInvestmentExposure1: async (investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure1', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure1', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'exposureType' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure1', 'exposureType', exposureType)
            const localVarPath = `/v3/investments/enhancement/reports/exposure-levels/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (exposureType !== undefined) {
                localVarQueryParameter['exposure-type'] = exposureType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentEnhancementReportsControllerApi - functional programming interface
 * @export
 */
export const InvestmentEnhancementReportsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentEnhancementReportsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentaries1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCommentaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentaries1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompositionVolatilityContribution1(investmentUuid: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnhancementContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompositionVolatilityContribution1(investmentUuid, horizon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExposureDecomposition1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformanceContributionEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExposureDecomposition1(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentComposition1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentComposition1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentEnhancementSummary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentEnhancementSummary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentFactors1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentFactorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentFactors1(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonitoringMetrics1(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitoringMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonitoringMetrics1(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioExAnteMetrics1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioExAnteMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioExAnteMetrics1(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoLevelsInvestmentExposure1(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentExposureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoLevelsInvestmentExposure1(investmentUuid, benchmarkIdentifier, exposureType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentEnhancementReportsControllerApi - factory interface
 * @export
 */
export const InvestmentEnhancementReportsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentEnhancementReportsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaries1(investmentUuid: string, options?: any): AxiosPromise<InvestmentCommentaryResponse> {
            return localVarFp.getCommentaries1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentary(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCommentary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompositionVolatilityContribution1(investmentUuid: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: any): AxiosPromise<EnhancementContributionResponse> {
            return localVarFp.getCompositionVolatilityContribution1(investmentUuid, horizon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureDecomposition1(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<Array<PerformanceContributionEntry>> {
            return localVarFp.getExposureDecomposition1(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentComposition1(investmentUuid: string, options?: any): AxiosPromise<InvestmentCompositionResponse> {
            return localVarFp.getInvestmentComposition1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentEnhancementSummary(investmentUuid: string, options?: any): AxiosPromise<InvestmentSummary> {
            return localVarFp.getInvestmentEnhancementSummary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentFactors1(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<InvestmentFactorsResponse> {
            return localVarFp.getInvestmentFactors1(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringMetrics1(investmentUuid: string, options?: any): AxiosPromise<MonitoringMetricsResponse> {
            return localVarFp.getMonitoringMetrics1(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioExAnteMetrics1(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<PortfolioExAnteMetricsResponse> {
            return localVarFp.getPortfolioExAnteMetrics1(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoLevelsInvestmentExposure1(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: any): AxiosPromise<InvestmentExposureResponse> {
            return localVarFp.getTwoLevelsInvestmentExposure1(investmentUuid, benchmarkIdentifier, exposureType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentEnhancementReportsControllerApi - object-oriented interface
 * @export
 * @class InvestmentEnhancementReportsControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentEnhancementReportsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Returns commentary of investment enhanced
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getCommentaries1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getCommentaries1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns commentary of investment enhanced
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getCommentary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getCommentary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report for the given time frame, if available.
     * @param {string} investmentUuid 
     * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getCompositionVolatilityContribution1(investmentUuid: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getCompositionVolatilityContribution1(investmentUuid, horizon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getExposureDecomposition1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getExposureDecomposition1(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the ticker composition with some additional information.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getInvestmentComposition1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getInvestmentComposition1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a synthetic view on the investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getInvestmentEnhancementSummary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getInvestmentEnhancementSummary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the investment composition performance line, and the benchmark realized performance..
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getInvestmentFactors1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getInvestmentFactors1(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns commentary of investment
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getMonitoringMetrics1(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getMonitoringMetrics1(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getPortfolioExAnteMetrics1(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getPortfolioExAnteMetrics1(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentEnhancementReportsControllerApi
     */
    public getTwoLevelsInvestmentExposure1(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig) {
        return InvestmentEnhancementReportsControllerApiFp(this.configuration).getTwoLevelsInvestmentExposure1(investmentUuid, benchmarkIdentifier, exposureType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentExportControllerApi - axios parameter creator
 * @export
 */
export const InvestmentExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportComposition: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportComposition', 'uuid', uuid)
            const localVarPath = `/v4/investments/export/composition/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportExposure: async (uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportExposure', 'uuid', uuid)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('exportExposure', 'type', type)
            const localVarPath = `/v4/investments/export/exposure/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFactors: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportFactors', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/export/factors/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGraphOfExposure: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportGraphOfExposure', 'uuid', uuid)
            const localVarPath = `/v4/investments/export/exposure/graph/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file, with the given historical uuid.
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {string} [subportfolioUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryCompositionEntry: async (investmentUuid: string, historicalUuid: string, subportfolioUuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportHistoryCompositionEntry', 'investmentUuid', investmentUuid)
            // verify required parameter 'historicalUuid' is not null or undefined
            assertParamExists('exportHistoryCompositionEntry', 'historicalUuid', historicalUuid)
            const localVarPath = `/v4/investments/export/history-composition/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (historicalUuid !== undefined) {
                localVarQueryParameter['historical-uuid'] = historicalUuid;
            }

            if (subportfolioUuid !== undefined) {
                localVarQueryParameter['subportfolio-uuid'] = subportfolioUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the history portfolio in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryPortfolio: async (investmentUuid: string, historicalUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportHistoryPortfolio', 'investmentUuid', investmentUuid)
            // verify required parameter 'historicalUuid' is not null or undefined
            assertParamExists('exportHistoryPortfolio', 'historicalUuid', historicalUuid)
            const localVarPath = `/v4/investments/export/history-portfolio/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (historicalUuid !== undefined) {
                localVarQueryParameter['historical-uuid'] = historicalUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the history universe in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryUniverse: async (investmentUuid: string, historicalUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('exportHistoryUniverse', 'investmentUuid', investmentUuid)
            // verify required parameter 'historicalUuid' is not null or undefined
            assertParamExists('exportHistoryUniverse', 'historicalUuid', historicalUuid)
            const localVarPath = `/v4/investments/export/history-universe/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (historicalUuid !== undefined) {
                localVarQueryParameter['historical-uuid'] = historicalUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMultiGraphOfExposure: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportMultiGraphOfExposure', 'uuid', uuid)
            const localVarPath = `/v4/investments/export/exposure/multi-graph/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPerformance: async (uuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportPerformance', 'uuid', uuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('exportPerformance', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v4/investments/export/performance/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentExportControllerApi - functional programming interface
 * @export
 */
export const InvestmentExportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentExportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportComposition(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportComposition(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportExposure(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportExposure(uuid, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportFactors(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportFactors(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportGraphOfExposure(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportGraphOfExposure(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file, with the given historical uuid.
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {string} [subportfolioUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportHistoryCompositionEntry(investmentUuid: string, historicalUuid: string, subportfolioUuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportHistoryCompositionEntry(investmentUuid, historicalUuid, subportfolioUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the history portfolio in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportHistoryPortfolio(investmentUuid: string, historicalUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportHistoryPortfolio(investmentUuid, historicalUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the history universe in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportHistoryUniverse(investmentUuid: string, historicalUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportHistoryUniverse(investmentUuid, historicalUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportMultiGraphOfExposure(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportMultiGraphOfExposure(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPerformance(uuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPerformance(uuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentExportControllerApi - factory interface
 * @export
 */
export const InvestmentExportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentExportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportComposition(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportComposition(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportExposure(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: any): AxiosPromise<any> {
            return localVarFp.exportExposure(uuid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the factors of investment and benchmark with delta.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFactors(investmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportFactors(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGraphOfExposure(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportGraphOfExposure(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file, with the given historical uuid.
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {string} [subportfolioUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryCompositionEntry(investmentUuid: string, historicalUuid: string, subportfolioUuid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportHistoryCompositionEntry(investmentUuid, historicalUuid, subportfolioUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the history portfolio in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryPortfolio(investmentUuid: string, historicalUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportHistoryPortfolio(investmentUuid, historicalUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the history universe in SPHERE format
         * @param {string} investmentUuid 
         * @param {string} historicalUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportHistoryUniverse(investmentUuid: string, historicalUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportHistoryUniverse(investmentUuid, historicalUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMultiGraphOfExposure(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportMultiGraphOfExposure(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports the composition of a portfolio as an Excel file.
         * @param {string} uuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPerformance(uuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportPerformance(uuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentExportControllerApi - object-oriented interface
 * @export
 * @class InvestmentExportControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentExportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportComposition(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportComposition(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportExposure(uuid: string, type: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportExposure(uuid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the factors of investment and benchmark with delta.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportFactors(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportFactors(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportGraphOfExposure(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportGraphOfExposure(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file, with the given historical uuid.
     * @param {string} investmentUuid 
     * @param {string} historicalUuid 
     * @param {string} [subportfolioUuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportHistoryCompositionEntry(investmentUuid: string, historicalUuid: string, subportfolioUuid?: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportHistoryCompositionEntry(investmentUuid, historicalUuid, subportfolioUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the history portfolio in SPHERE format
     * @param {string} investmentUuid 
     * @param {string} historicalUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportHistoryPortfolio(investmentUuid: string, historicalUuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportHistoryPortfolio(investmentUuid, historicalUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the history universe in SPHERE format
     * @param {string} investmentUuid 
     * @param {string} historicalUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportHistoryUniverse(investmentUuid: string, historicalUuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportHistoryUniverse(investmentUuid, historicalUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportMultiGraphOfExposure(uuid: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportMultiGraphOfExposure(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports the composition of a portfolio as an Excel file.
     * @param {string} uuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentExportControllerApi
     */
    public exportPerformance(uuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentExportControllerApiFp(this.configuration).exportPerformance(uuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentReportsControllerApi - axios parameter creator
 * @export
 */
export const InvestmentReportsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaries: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCommentaries', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/commentaries/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompositionVolatilityContribution: async (investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCompositionVolatilityContribution', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getCompositionVolatilityContribution', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'horizon' is not null or undefined
            assertParamExists('getCompositionVolatilityContribution', 'horizon', horizon)
            const localVarPath = `/v3/investments/reports/composition-volatility-contribution/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (horizon !== undefined) {
                localVarQueryParameter['horizon'] = horizon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentCommentary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getCurrentCommentary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/commentary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureDecomposition: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getExposureDecomposition', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getExposureDecomposition', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/reports/exposure-decomposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investment-uuid'] = investmentUuid;
            }

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentComposition: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentComposition', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/composition/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentFactors: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentFactors', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getInvestmentFactors', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/reports/factors/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a synthetic view on the history of an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentHistorySummary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentHistorySummary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/history-summary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentPerformance: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentPerformance', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getInvestmentPerformance', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/reports/performance/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentSummary: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getInvestmentSummary', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/summary/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringMetrics: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getMonitoringMetrics', 'investmentUuid', investmentUuid)
            const localVarPath = `/v3/investments/reports/monitoring-metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioExAnteMetrics: async (investmentUuid: string, benchmarkIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getPortfolioExAnteMetrics', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getPortfolioExAnteMetrics', 'benchmarkIdentifier', benchmarkIdentifier)
            const localVarPath = `/v3/investments/reports/portfolio-exante-metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {PortfolioMetricsOrderingResponse} portfolioMetricsOrderingResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioMetrics: async (investmentUuid: string, benchmarkIdentifier: string, portfolioMetricsOrderingResponse: PortfolioMetricsOrderingResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getPortfolioMetrics', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getPortfolioMetrics', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'portfolioMetricsOrderingResponse' is not null or undefined
            assertParamExists('getPortfolioMetrics', 'portfolioMetricsOrderingResponse', portfolioMetricsOrderingResponse)
            const localVarPath = `/v3/investments/reports/portfolio-metrics/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioMetricsOrderingResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedPerformanceAttribution: async (investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getRealizedPerformanceAttribution', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getRealizedPerformanceAttribution', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'horizon' is not null or undefined
            assertParamExists('getRealizedPerformanceAttribution', 'horizon', horizon)
            const localVarPath = `/v3/investments/reports/realized-performance-attribution/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (horizon !== undefined) {
                localVarQueryParameter['horizon'] = horizon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedPerformanceContribution: async (investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getRealizedPerformanceContribution', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getRealizedPerformanceContribution', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'horizon' is not null or undefined
            assertParamExists('getRealizedPerformanceContribution', 'horizon', horizon)
            const localVarPath = `/v3/investments/reports/realized-performance-contribution/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (horizon !== undefined) {
                localVarQueryParameter['horizon'] = horizon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedVolatilityContribution: async (investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getRealizedVolatilityContribution', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getRealizedVolatilityContribution', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'horizon' is not null or undefined
            assertParamExists('getRealizedVolatilityContribution', 'horizon', horizon)
            const localVarPath = `/v3/investments/reports/realized-volatility-contribution/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (horizon !== undefined) {
                localVarQueryParameter['horizon'] = horizon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoLevelsInvestmentExposure: async (investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure', 'investmentUuid', investmentUuid)
            // verify required parameter 'benchmarkIdentifier' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure', 'benchmarkIdentifier', benchmarkIdentifier)
            // verify required parameter 'exposureType' is not null or undefined
            assertParamExists('getTwoLevelsInvestmentExposure', 'exposureType', exposureType)
            const localVarPath = `/v3/investments/reports/exposure-levels/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (benchmarkIdentifier !== undefined) {
                localVarQueryParameter['benchmark-identifier'] = benchmarkIdentifier;
            }

            if (exposureType !== undefined) {
                localVarQueryParameter['exposure-type'] = exposureType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentReportsControllerApi - functional programming interface
 * @export
 */
export const InvestmentReportsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentReportsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentaries(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCommentaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentaries(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompositionVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompositionVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCurrentCommentary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentCommentary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExposureDecomposition(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformanceContributionEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExposureDecomposition(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentComposition(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentComposition(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentFactors(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentFactorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentFactors(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a synthetic view on the history of an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentHistorySummary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentHistorySummaryEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentHistorySummary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentPerformance(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentPerformance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentPerformance(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentSummary(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentSummary(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonitoringMetrics(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitoringMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonitoringMetrics(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioExAnteMetrics(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioExAnteMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioExAnteMetrics(investmentUuid, benchmarkIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {PortfolioMetricsOrderingResponse} portfolioMetricsOrderingResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioMetrics(investmentUuid: string, benchmarkIdentifier: string, portfolioMetricsOrderingResponse: PortfolioMetricsOrderingResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioMetrics(investmentUuid, benchmarkIdentifier, portfolioMetricsOrderingResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealizedPerformanceAttribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentAttributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealizedPerformanceAttribution(investmentUuid, benchmarkIdentifier, horizon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealizedPerformanceContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealizedPerformanceContribution(investmentUuid, benchmarkIdentifier, horizon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealizedVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealizedVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoLevelsInvestmentExposure(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentExposureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoLevelsInvestmentExposure(investmentUuid, benchmarkIdentifier, exposureType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentReportsControllerApi - factory interface
 * @export
 */
export const InvestmentReportsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentReportsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns commentary of investment enhanced
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentaries(investmentUuid: string, options?: any): AxiosPromise<InvestmentCommentaryResponse> {
            return localVarFp.getCommentaries(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompositionVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: any): AxiosPromise<InvestmentContributionResponse> {
            return localVarFp.getCompositionVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentCommentary(investmentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCurrentCommentary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureDecomposition(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<Array<PerformanceContributionEntry>> {
            return localVarFp.getExposureDecomposition(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the ticker composition with some additional information.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentComposition(investmentUuid: string, options?: any): AxiosPromise<InvestmentCompositionResponse> {
            return localVarFp.getInvestmentComposition(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the investment composition performance line, and the benchmark realized performance..
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentFactors(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<InvestmentFactorsResponse> {
            return localVarFp.getInvestmentFactors(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a synthetic view on the history of an investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentHistorySummary(investmentUuid: string, options?: any): AxiosPromise<Array<InvestmentHistorySummaryEntry>> {
            return localVarFp.getInvestmentHistorySummary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentPerformance(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<InvestmentPerformance> {
            return localVarFp.getInvestmentPerformance(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a synthetic view on the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentSummary(investmentUuid: string, options?: any): AxiosPromise<InvestmentSummary> {
            return localVarFp.getInvestmentSummary(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns commentary of investment
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringMetrics(investmentUuid: string, options?: any): AxiosPromise<MonitoringMetricsResponse> {
            return localVarFp.getMonitoringMetrics(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioExAnteMetrics(investmentUuid: string, benchmarkIdentifier: string, options?: any): AxiosPromise<PortfolioExAnteMetricsResponse> {
            return localVarFp.getPortfolioExAnteMetrics(investmentUuid, benchmarkIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {PortfolioMetricsOrderingResponse} portfolioMetricsOrderingResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioMetrics(investmentUuid: string, benchmarkIdentifier: string, portfolioMetricsOrderingResponse: PortfolioMetricsOrderingResponse, options?: any): AxiosPromise<PortfolioMetricsResponse> {
            return localVarFp.getPortfolioMetrics(investmentUuid, benchmarkIdentifier, portfolioMetricsOrderingResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedPerformanceAttribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: any): AxiosPromise<InvestmentAttributionResponse> {
            return localVarFp.getRealizedPerformanceAttribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedPerformanceContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: any): AxiosPromise<InvestmentContributionResponse> {
            return localVarFp.getRealizedPerformanceContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report for the given time frame, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealizedVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: any): AxiosPromise<InvestmentContributionResponse> {
            return localVarFp.getRealizedVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report, if available.
         * @param {string} investmentUuid 
         * @param {string} benchmarkIdentifier 
         * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoLevelsInvestmentExposure(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: any): AxiosPromise<InvestmentExposureResponse> {
            return localVarFp.getTwoLevelsInvestmentExposure(investmentUuid, benchmarkIdentifier, exposureType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentReportsControllerApi - object-oriented interface
 * @export
 * @class InvestmentReportsControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentReportsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Returns commentary of investment enhanced
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getCommentaries(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getCommentaries(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getCompositionVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getCompositionVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns commentary of investment
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getCurrentCommentary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getCurrentCommentary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the investment/benchmark exposure, used for the scenario analysis calculations.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getExposureDecomposition(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getExposureDecomposition(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the ticker composition with some additional information.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getInvestmentComposition(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getInvestmentComposition(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the investment composition performance line, and the benchmark realized performance..
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getInvestmentFactors(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getInvestmentFactors(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a synthetic view on the history of an investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getInvestmentHistorySummary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getInvestmentHistorySummary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getInvestmentPerformance(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getInvestmentPerformance(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a synthetic view on the investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getInvestmentSummary(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getInvestmentSummary(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns commentary of investment
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getMonitoringMetrics(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getMonitoringMetrics(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getPortfolioExAnteMetrics(investmentUuid: string, benchmarkIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getPortfolioExAnteMetrics(investmentUuid, benchmarkIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {PortfolioMetricsOrderingResponse} portfolioMetricsOrderingResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getPortfolioMetrics(investmentUuid: string, benchmarkIdentifier: string, portfolioMetricsOrderingResponse: PortfolioMetricsOrderingResponse, options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getPortfolioMetrics(investmentUuid, benchmarkIdentifier, portfolioMetricsOrderingResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report for the given time frame, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getRealizedPerformanceAttribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getRealizedPerformanceAttribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report for the given time frame, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getRealizedPerformanceContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getRealizedPerformanceContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report for the given time frame, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION'} horizon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getRealizedVolatilityContribution(investmentUuid: string, benchmarkIdentifier: string, horizon: 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR' | 'YEAR_TO_DATE' | 'FROM_INCEPTION', options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getRealizedVolatilityContribution(investmentUuid, benchmarkIdentifier, horizon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report, if available.
     * @param {string} investmentUuid 
     * @param {string} benchmarkIdentifier 
     * @param {'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY'} exposureType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentReportsControllerApi
     */
    public getTwoLevelsInvestmentExposure(investmentUuid: string, benchmarkIdentifier: string, exposureType: 'MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS_VS_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_GEOGRAPHY' | 'GEOGRAPHY_VS_MACRO_ASSET_CLASS' | 'GEOGRAPHY_VS_MICRO_ASSET_CLASS' | 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'MACRO_GEOGRAPHY' | 'MICRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY' | 'MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY' | 'MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY' | 'TAG' | 'CURRENCY', options?: AxiosRequestConfig) {
        return InvestmentReportsControllerApiFp(this.configuration).getTwoLevelsInvestmentExposure(investmentUuid, benchmarkIdentifier, exposureType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentsEnhancementStaticConfigurationControllerApi - axios parameter creator
 * @export
 */
export const InvestmentsEnhancementStaticConfigurationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticEnhancementConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticEnhancementConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/enhancement/static/configuration/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsEnhancementStaticConfigurationControllerApi - functional programming interface
 * @export
 */
export const InvestmentsEnhancementStaticConfigurationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsEnhancementStaticConfigurationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticEnhancementConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentsEnhancementStaticConfigurationControllerApi - factory interface
 * @export
 */
export const InvestmentsEnhancementStaticConfigurationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsEnhancementStaticConfigurationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticEnhancementConfiguration(investmentUuid: string, options?: any): AxiosPromise<InvestmentConfigurationResponse> {
            return localVarFp.getStaticEnhancementConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentsEnhancementStaticConfigurationControllerApi - object-oriented interface
 * @export
 * @class InvestmentsEnhancementStaticConfigurationControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentsEnhancementStaticConfigurationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return the configuration of the investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsEnhancementStaticConfigurationControllerApi
     */
    public getStaticEnhancementConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsEnhancementStaticConfigurationControllerApiFp(this.configuration).getStaticEnhancementConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentsExposureCompareControllerApi - axios parameter creator
 * @export
 */
export const InvestmentsExposureCompareControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns exposure contribution of the single portfolios.
         * @param {ExposureContributionRequest} exposureContributionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureContribution: async (exposureContributionRequest: ExposureContributionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exposureContributionRequest' is not null or undefined
            assertParamExists('getExposureContribution', 'exposureContributionRequest', exposureContributionRequest)
            const localVarPath = `/investments/exposure-compare/exposure-contribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exposureContributionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns exposure contribution based on the investment history
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG'} exposureContributionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureContributionByHistoryInvestmentUuid: async (investmentUuid: string, historyUuid: string, exposureContributionType: 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getExposureContributionByHistoryInvestmentUuid', 'investmentUuid', investmentUuid)
            // verify required parameter 'historyUuid' is not null or undefined
            assertParamExists('getExposureContributionByHistoryInvestmentUuid', 'historyUuid', historyUuid)
            // verify required parameter 'exposureContributionType' is not null or undefined
            assertParamExists('getExposureContributionByHistoryInvestmentUuid', 'exposureContributionType', exposureContributionType)
            const localVarPath = `/investments/exposure-compare/history-investment-exposure-contribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investmentUuid'] = investmentUuid;
            }

            if (historyUuid !== undefined) {
                localVarQueryParameter['historyUuid'] = historyUuid;
            }

            if (exposureContributionType !== undefined) {
                localVarQueryParameter['exposureContributionType'] = exposureContributionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsExposureCompareControllerApi - functional programming interface
 * @export
 */
export const InvestmentsExposureCompareControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsExposureCompareControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns exposure contribution of the single portfolios.
         * @param {ExposureContributionRequest} exposureContributionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExposureContribution(exposureContributionRequest: ExposureContributionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExposureContribution(exposureContributionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns exposure contribution based on the investment history
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG'} exposureContributionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExposureContributionByHistoryInvestmentUuid(investmentUuid: string, historyUuid: string, exposureContributionType: 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExposureContributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExposureContributionByHistoryInvestmentUuid(investmentUuid, historyUuid, exposureContributionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentsExposureCompareControllerApi - factory interface
 * @export
 */
export const InvestmentsExposureCompareControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsExposureCompareControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns exposure contribution of the single portfolios.
         * @param {ExposureContributionRequest} exposureContributionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureContribution(exposureContributionRequest: ExposureContributionRequest, options?: any): AxiosPromise<ExposureContributionResponse> {
            return localVarFp.getExposureContribution(exposureContributionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns exposure contribution based on the investment history
         * @param {string} investmentUuid 
         * @param {string} historyUuid 
         * @param {'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG'} exposureContributionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExposureContributionByHistoryInvestmentUuid(investmentUuid: string, historyUuid: string, exposureContributionType: 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG', options?: any): AxiosPromise<ExposureContributionResponse> {
            return localVarFp.getExposureContributionByHistoryInvestmentUuid(investmentUuid, historyUuid, exposureContributionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentsExposureCompareControllerApi - object-oriented interface
 * @export
 * @class InvestmentsExposureCompareControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentsExposureCompareControllerApi extends BaseAPI {
    /**
     * 
     * @summary Returns exposure contribution of the single portfolios.
     * @param {ExposureContributionRequest} exposureContributionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsExposureCompareControllerApi
     */
    public getExposureContribution(exposureContributionRequest: ExposureContributionRequest, options?: AxiosRequestConfig) {
        return InvestmentsExposureCompareControllerApiFp(this.configuration).getExposureContribution(exposureContributionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns exposure contribution based on the investment history
     * @param {string} investmentUuid 
     * @param {string} historyUuid 
     * @param {'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG'} exposureContributionType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsExposureCompareControllerApi
     */
    public getExposureContributionByHistoryInvestmentUuid(investmentUuid: string, historyUuid: string, exposureContributionType: 'MACRO_ASSET_CLASS' | 'MICRO_ASSET_CLASS' | 'GEOGRAPHY' | 'MACRO_GEOGRAPHY' | 'CURRENCY' | 'TAG', options?: AxiosRequestConfig) {
        return InvestmentsExposureCompareControllerApiFp(this.configuration).getExposureContributionByHistoryInvestmentUuid(investmentUuid, historyUuid, exposureContributionType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentsReportTemplateControllerApi - axios parameter creator
 * @export
 */
export const InvestmentsReportTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if a template name is available 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTemplateNameAvailability: async (templateName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('checkTemplateNameAvailability', 'templateName', templateName)
            const localVarPath = `/v1/investments-report-templates/templates/name-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateName !== undefined) {
                localVarQueryParameter['template-name'] = templateName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new template associated with customer of logged in user
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPTFReportTemplate: async (investmentsReportTemplate: InvestmentsReportTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentsReportTemplate' is not null or undefined
            assertParamExists('createPTFReportTemplate', 'investmentsReportTemplate', investmentsReportTemplate)
            const localVarPath = `/v1/investments-report-templates/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentsReportTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing template
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePTFReportTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deletePTFReportTemplate', 'templateId', templateId)
            const localVarPath = `/v1/investments-report-templates/templates/{template-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {string} templateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentReportTemplate: async (templateUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateUuid' is not null or undefined
            assertParamExists('getInvestmentReportTemplate', 'templateUuid', templateUuid)
            const localVarPath = `/v1/investments-report-templates/templates/{template-uuid}`
                .replace(`{${"template-uuid"}}`, encodeURIComponent(String(templateUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentReportTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investments-report-templates/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing template
         * @param {string} templateUuid 
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePTFReportTemplate: async (templateUuid: string, investmentsReportTemplate: InvestmentsReportTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateUuid' is not null or undefined
            assertParamExists('updatePTFReportTemplate', 'templateUuid', templateUuid)
            // verify required parameter 'investmentsReportTemplate' is not null or undefined
            assertParamExists('updatePTFReportTemplate', 'investmentsReportTemplate', investmentsReportTemplate)
            const localVarPath = `/v1/investments-report-templates/templates/{template-uuid}`
                .replace(`{${"template-uuid"}}`, encodeURIComponent(String(templateUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentsReportTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsReportTemplateControllerApi - functional programming interface
 * @export
 */
export const InvestmentsReportTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsReportTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if a template name is available 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTemplateNameAvailability(templateName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkTemplateNameAvailability(templateName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new template associated with customer of logged in user
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPTFReportTemplate(investmentsReportTemplate: InvestmentsReportTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsReportTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPTFReportTemplate(investmentsReportTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing template
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePTFReportTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePTFReportTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {string} templateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestmentReportTemplate(templateUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsReportTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestmentReportTemplate(templateUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestmentReportTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentsReportTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestmentReportTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing template
         * @param {string} templateUuid 
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePTFReportTemplate(templateUuid: string, investmentsReportTemplate: InvestmentsReportTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentsReportTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePTFReportTemplate(templateUuid, investmentsReportTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentsReportTemplateControllerApi - factory interface
 * @export
 */
export const InvestmentsReportTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsReportTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if a template name is available 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTemplateNameAvailability(templateName: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkTemplateNameAvailability(templateName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new template associated with customer of logged in user
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPTFReportTemplate(investmentsReportTemplate: InvestmentsReportTemplate, options?: any): AxiosPromise<InvestmentsReportTemplate> {
            return localVarFp.createPTFReportTemplate(investmentsReportTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing template
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePTFReportTemplate(templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePTFReportTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {string} templateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestmentReportTemplate(templateUuid: string, options?: any): AxiosPromise<InvestmentsReportTemplate> {
            return localVarFp.getInvestmentReportTemplate(templateUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list templates of the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestmentReportTemplates(options?: any): AxiosPromise<Array<InvestmentsReportTemplate>> {
            return localVarFp.listInvestmentReportTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing template
         * @param {string} templateUuid 
         * @param {InvestmentsReportTemplate} investmentsReportTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePTFReportTemplate(templateUuid: string, investmentsReportTemplate: InvestmentsReportTemplate, options?: any): AxiosPromise<InvestmentsReportTemplate> {
            return localVarFp.updatePTFReportTemplate(templateUuid, investmentsReportTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentsReportTemplateControllerApi - object-oriented interface
 * @export
 * @class InvestmentsReportTemplateControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentsReportTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Check if a template name is available 
     * @param {string} templateName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public checkTemplateNameAvailability(templateName: string, options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).checkTemplateNameAvailability(templateName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new template associated with customer of logged in user
     * @param {InvestmentsReportTemplate} investmentsReportTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public createPTFReportTemplate(investmentsReportTemplate: InvestmentsReportTemplate, options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).createPTFReportTemplate(investmentsReportTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing template
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public deletePTFReportTemplate(templateId: string, options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).deletePTFReportTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list templates of the logged in user
     * @param {string} templateUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public getInvestmentReportTemplate(templateUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).getInvestmentReportTemplate(templateUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list templates of the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public listInvestmentReportTemplates(options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).listInvestmentReportTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing template
     * @param {string} templateUuid 
     * @param {InvestmentsReportTemplate} investmentsReportTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsReportTemplateControllerApi
     */
    public updatePTFReportTemplate(templateUuid: string, investmentsReportTemplate: InvestmentsReportTemplate, options?: AxiosRequestConfig) {
        return InvestmentsReportTemplateControllerApiFp(this.configuration).updatePTFReportTemplate(templateUuid, investmentsReportTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvestmentsStaticConfigurationControllerApi - axios parameter creator
 * @export
 */
export const InvestmentsStaticConfigurationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfiguration: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfiguration', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/configuration/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableAllocationConstraints: async (investmentUuid: string, universeIdentifier?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableAllocationConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/allocation-constraints/selectable-allocation-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universeIdentifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableInvestableUniverses: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableInvestableUniverses', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/investable-universe/selectable-investable-universes/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableMainInfo: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableMainInfo', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/main-info/selectable-fields/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets market views that can be used
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableMarketViews: async (investmentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableMarketViews', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/market-view/selectable-market-views/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [referenceIdentifier] 
         * @param {'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO'} [referenceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableRiskConstraints: async (investmentUuid: string, referenceIdentifier?: string, referenceType?: 'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableRiskConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/risk-constraints/selectable-risk-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (referenceIdentifier !== undefined) {
                localVarQueryParameter['referenceIdentifier'] = referenceIdentifier;
            }

            if (referenceType !== undefined) {
                localVarQueryParameter['referenceType'] = referenceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableStrategyConstraints: async (investmentUuid: string, universeIdentifier?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getStaticConfigurationSelectableStrategyConstraints', 'investmentUuid', investmentUuid)
            const localVarPath = `/v4/investments/static/configuration/steps/strategy-constraints/selectable-strategy-constraints/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universeIdentifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationInvestableUniverse: async (investmentUuid: string, universeIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadStaticConfigurationInvestableUniverse', 'investmentUuid', investmentUuid)
            // verify required parameter 'universeIdentifier' is not null or undefined
            assertParamExists('loadStaticConfigurationInvestableUniverse', 'universeIdentifier', universeIdentifier)
            const localVarPath = `/v4/investments/static/configuration/steps/investable-universe/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (universeIdentifier !== undefined) {
                localVarQueryParameter['universe-identifier'] = universeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationMarketView: async (investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('loadStaticConfigurationMarketView', 'investmentUuid', investmentUuid)
            // verify required parameter 'marketViewIdentifier' is not null or undefined
            assertParamExists('loadStaticConfigurationMarketView', 'marketViewIdentifier', marketViewIdentifier)
            const localVarPath = `/v4/investments/static/configuration/steps/market-view/load/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewIdentifier !== undefined) {
                localVarQueryParameter['market-view-identifier'] = marketViewIdentifier;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves all the investment configuration at once.
         * @param {InvestmentConfigurationSaveRequest} investmentConfigurationSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfiguration: async (investmentConfigurationSaveRequest: InvestmentConfigurationSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentConfigurationSaveRequest' is not null or undefined
            assertParamExists('setStaticConfiguration', 'investmentConfigurationSaveRequest', investmentConfigurationSaveRequest)
            const localVarPath = `/v4/investments/static/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentConfigurationSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationAllocationConstraints: async (allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allocationConstraintsSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationAllocationConstraints', 'allocationConstraintsSaveRequest', allocationConstraintsSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/allocation-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationInvestableUniverse: async (investableUniverseSaveRequest: InvestableUniverseSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investableUniverseSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationInvestableUniverse', 'investableUniverseSaveRequest', investableUniverseSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/investable-universe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investableUniverseSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the main info of the investment. In static mode, only the name can be changed, at the moment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMainInfo: async (mainInfoSaveRequest: MainInfoSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mainInfoSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationMainInfo', 'mainInfoSaveRequest', mainInfoSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/main-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mainInfoSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMarketView: async (marketViewSaveRequest: MarketViewSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationMarketView', 'marketViewSaveRequest', marketViewSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationRiskConstraints: async (riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskConstraintsSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationRiskConstraints', 'riskConstraintsSaveRequest', riskConstraintsSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/risk-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationStrategyConstraints: async (strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyConstraintsSaveRequest' is not null or undefined
            assertParamExists('setStaticConfigurationStrategyConstraints', 'strategyConstraintsSaveRequest', strategyConstraintsSaveRequest)
            const localVarPath = `/v4/investments/static/configuration/steps/strategy-constraints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(strategyConstraintsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentsStaticConfigurationControllerApi - functional programming interface
 * @export
 */
export const InvestmentsStaticConfigurationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentsStaticConfigurationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfiguration(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfiguration(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableAllocationConstraints(investmentUuid: string, universeIdentifier?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableAllocationConstraints(investmentUuid, universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectableInvestableUniverse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableInvestableUniverses(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableMainInfo(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets market views that can be used
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableMarketViews(investmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [referenceIdentifier] 
         * @param {'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO'} [referenceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableRiskConstraints(investmentUuid: string, referenceIdentifier?: string, referenceType?: 'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableRiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableRiskConstraints(investmentUuid, referenceIdentifier, referenceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticConfigurationSelectableStrategyConstraints(investmentUuid: string, universeIdentifier?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableStrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticConfigurationSelectableStrategyConstraints(investmentUuid, universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadStaticConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialInvestableUniverseTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadStaticConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadStaticConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadStaticConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves all the investment configuration at once.
         * @param {InvestmentConfigurationSaveRequest} investmentConfigurationSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfiguration(investmentConfigurationSaveRequest: InvestmentConfigurationSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfiguration(investmentConfigurationSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestableUniverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the main info of the investment. In static mode, only the name can be changed, at the moment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationMainInfo(mainInfoSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationMarketView(marketViewSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StrategyConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvestmentsStaticConfigurationControllerApi - factory interface
 * @export
 */
export const InvestmentsStaticConfigurationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentsStaticConfigurationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Return the configuration of the investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfiguration(investmentUuid: string, options?: any): AxiosPromise<InvestmentConfigurationResponse> {
            return localVarFp.getStaticConfiguration(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableAllocationConstraints(investmentUuid: string, universeIdentifier?: string, options?: any): AxiosPromise<SelectableAllocationConstraintsResponse> {
            return localVarFp.getStaticConfigurationSelectableAllocationConstraints(investmentUuid, universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the reference universe selectable for the enhancement of the current investment.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: any): AxiosPromise<Array<SelectableInvestableUniverse>> {
            return localVarFp.getStaticConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Populate any screen with the main info selected by the user.
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableMainInfo(investmentUuid: string, options?: any): AxiosPromise<SelectableMainInfoResponse> {
            return localVarFp.getStaticConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets market views that can be used
         * @param {string} investmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableMarketViews(investmentUuid: string, options?: any): AxiosPromise<SelectableMarketViewsResponse> {
            return localVarFp.getStaticConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [referenceIdentifier] 
         * @param {'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO'} [referenceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableRiskConstraints(investmentUuid: string, referenceIdentifier?: string, referenceType?: 'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO', options?: any): AxiosPromise<SelectableRiskConstraintsResponse> {
            return localVarFp.getStaticConfigurationSelectableRiskConstraints(investmentUuid, referenceIdentifier, referenceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} [universeIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticConfigurationSelectableStrategyConstraints(investmentUuid: string, universeIdentifier?: string, options?: any): AxiosPromise<SelectableStrategyConstraintsResponse> {
            return localVarFp.getStaticConfigurationSelectableStrategyConstraints(investmentUuid, universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
         * @param {string} investmentUuid 
         * @param {string} universeIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: any): AxiosPromise<Array<PartialInvestableUniverseTicker>> {
            return localVarFp.loadStaticConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the tags that can be used on the current flow to set constraints.
         * @param {string} investmentUuid 
         * @param {string} marketViewIdentifier 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadStaticConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.loadStaticConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves all the investment configuration at once.
         * @param {InvestmentConfigurationSaveRequest} investmentConfigurationSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfiguration(investmentConfigurationSaveRequest: InvestmentConfigurationSaveRequest, options?: any): AxiosPromise<InvestmentConfigurationResponse> {
            return localVarFp.setStaticConfiguration(investmentConfigurationSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: any): AxiosPromise<AllocationConstraintsResponse> {
            return localVarFp.setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
         * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: any): AxiosPromise<InvestableUniverseResponse> {
            return localVarFp.setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the main info of the investment. In static mode, only the name can be changed, at the moment.
         * @param {MainInfoSaveRequest} mainInfoSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: any): AxiosPromise<MainInfoResponse> {
            return localVarFp.setStaticConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {MarketViewSaveRequest} marketViewSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: any): AxiosPromise<MarketViewResponse> {
            return localVarFp.setStaticConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: any): AxiosPromise<RiskConstraintsResponse> {
            return localVarFp.setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
         * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: any): AxiosPromise<StrategyConstraintsResponse> {
            return localVarFp.setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestmentsStaticConfigurationControllerApi - object-oriented interface
 * @export
 * @class InvestmentsStaticConfigurationControllerApi
 * @extends {BaseAPI}
 */
export class InvestmentsStaticConfigurationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return the configuration of the investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfiguration(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfiguration(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tickers, asset classes, constraints, that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} [universeIdentifier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableAllocationConstraints(investmentUuid: string, universeIdentifier?: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableAllocationConstraints(investmentUuid, universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the reference universe selectable for the enhancement of the current investment.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableInvestableUniverses(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableInvestableUniverses(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Populate any screen with the main info selected by the user.
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableMainInfo(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableMainInfo(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets market views that can be used
     * @param {string} investmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableMarketViews(investmentUuid: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableMarketViews(investmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} [referenceIdentifier] 
     * @param {'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO'} [referenceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableRiskConstraints(investmentUuid: string, referenceIdentifier?: string, referenceType?: 'STANDARD_BENCHMARK' | 'CUSTOM_BENCHMARK' | 'REFERENCE_INVESTMENT' | 'CURRENT_PORTFOLIO', options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableRiskConstraints(investmentUuid, referenceIdentifier, referenceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} [universeIdentifier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public getStaticConfigurationSelectableStrategyConstraints(investmentUuid: string, universeIdentifier?: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).getStaticConfigurationSelectableStrategyConstraints(investmentUuid, universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the composition of the selected universe, merged with the investment current composition, highlighting the eventual conflicts.
     * @param {string} investmentUuid 
     * @param {string} universeIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public loadStaticConfigurationInvestableUniverse(investmentUuid: string, universeIdentifier: string, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).loadStaticConfigurationInvestableUniverse(investmentUuid, universeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the tags that can be used on the current flow to set constraints.
     * @param {string} investmentUuid 
     * @param {string} marketViewIdentifier 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public loadStaticConfigurationMarketView(investmentUuid: string, marketViewIdentifier: string, custom?: boolean, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).loadStaticConfigurationMarketView(investmentUuid, marketViewIdentifier, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves all the investment configuration at once.
     * @param {InvestmentConfigurationSaveRequest} investmentConfigurationSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfiguration(investmentConfigurationSaveRequest: InvestmentConfigurationSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfiguration(investmentConfigurationSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {AllocationConstraintsSaveRequest} allocationConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest: AllocationConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationAllocationConstraints(allocationConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the universe selection of the user, validating the choice selecting the conflicts using the given strategy.
     * @param {InvestableUniverseSaveRequest} investableUniverseSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest: InvestableUniverseSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationInvestableUniverse(investableUniverseSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the main info of the investment. In static mode, only the name can be changed, at the moment.
     * @param {MainInfoSaveRequest} mainInfoSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationMainInfo(mainInfoSaveRequest: MainInfoSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationMainInfo(mainInfoSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {MarketViewSaveRequest} marketViewSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationMarketView(marketViewSaveRequest: MarketViewSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationMarketView(marketViewSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {RiskConstraintsSaveRequest} riskConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest: RiskConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationRiskConstraints(riskConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the allocation constraints selected by the user, populates the missing fields, and starts the validation.
     * @param {StrategyConstraintsSaveRequest} strategyConstraintsSaveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentsStaticConfigurationControllerApi
     */
    public setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest: StrategyConstraintsSaveRequest, options?: AxiosRequestConfig) {
        return InvestmentsStaticConfigurationControllerApiFp(this.configuration).setStaticConfigurationStrategyConstraints(strategyConstraintsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogControllerApi - axios parameter creator
 * @export
 */
export const LogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LogDto} logDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log: async (logDto: LogDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logDto' is not null or undefined
            assertParamExists('log', 'logDto', logDto)
            const localVarPath = `/v1/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogControllerApi - functional programming interface
 * @export
 */
export const LogControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LogDto} logDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async log(logDto: LogDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.log(logDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogControllerApi - factory interface
 * @export
 */
export const LogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LogDto} logDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        log(logDto: LogDto, options?: any): AxiosPromise<void> {
            return localVarFp.log(logDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogControllerApi - object-oriented interface
 * @export
 * @class LogControllerApi
 * @extends {BaseAPI}
 */
export class LogControllerApi extends BaseAPI {
    /**
     * 
     * @param {LogDto} logDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public log(logDto: LogDto, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).log(logDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailTestControllerApi - axios parameter creator
 * @export
 */
export const MailTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} mail 
         * @param {string} type 
         * @param {PortfolioOrUniverseStatusChange} portfolioOrUniverseStatusChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPortfolioChangeWithApiKey: async (mail: string, type: string, portfolioOrUniverseStatusChange: PortfolioOrUniverseStatusChange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('testPortfolioChangeWithApiKey', 'mail', mail)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('testPortfolioChangeWithApiKey', 'type', type)
            // verify required parameter 'portfolioOrUniverseStatusChange' is not null or undefined
            assertParamExists('testPortfolioChangeWithApiKey', 'portfolioOrUniverseStatusChange', portfolioOrUniverseStatusChange)
            const localVarPath = `/test/portfolio-change/{mail}/{type}`
                .replace(`{${"mail"}}`, encodeURIComponent(String(mail)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioOrUniverseStatusChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailTestControllerApi - functional programming interface
 * @export
 */
export const MailTestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailTestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} mail 
         * @param {string} type 
         * @param {PortfolioOrUniverseStatusChange} portfolioOrUniverseStatusChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPortfolioChangeWithApiKey(mail: string, type: string, portfolioOrUniverseStatusChange: PortfolioOrUniverseStatusChange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPortfolioChangeWithApiKey(mail, type, portfolioOrUniverseStatusChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailTestControllerApi - factory interface
 * @export
 */
export const MailTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailTestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} mail 
         * @param {string} type 
         * @param {PortfolioOrUniverseStatusChange} portfolioOrUniverseStatusChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPortfolioChangeWithApiKey(mail: string, type: string, portfolioOrUniverseStatusChange: PortfolioOrUniverseStatusChange, options?: any): AxiosPromise<void> {
            return localVarFp.testPortfolioChangeWithApiKey(mail, type, portfolioOrUniverseStatusChange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailTestControllerApi - object-oriented interface
 * @export
 * @class MailTestControllerApi
 * @extends {BaseAPI}
 */
export class MailTestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} mail 
     * @param {string} type 
     * @param {PortfolioOrUniverseStatusChange} portfolioOrUniverseStatusChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailTestControllerApi
     */
    public testPortfolioChangeWithApiKey(mail: string, type: string, portfolioOrUniverseStatusChange: PortfolioOrUniverseStatusChange, options?: AxiosRequestConfig) {
        return MailTestControllerApiFp(this.configuration).testPortfolioChangeWithApiKey(mail, type, portfolioOrUniverseStatusChange, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketControllerApi - axios parameter creator
 * @export
 */
export const MarketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAssetClass: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/asset-class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFactors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/factors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFactorsByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveFactorsByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveFactorsByAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveFactorsByAssetClass', 'sector', sector)
            const localVarPath = `/market/factors/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveForecastByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveForecastByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveForecastByAssetClass', 'geography', geography)
            const localVarPath = `/market/forecast/{asset-class}/{geography}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGeography: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/geography`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMacroContextDriver: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveMacroContextDriver', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveMacroContextDriver', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveMacroContextDriver', 'sector', sector)
            const localVarPath = `/market/outlook/focus/macro-context-driver/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketRegimeWithElements: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElements', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElements', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElements', 'sector', sector)
            const localVarPath = `/market/outlook/focus/regime/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketRegimeWithElementsTimeSeries: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElementsTimeSeries', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElementsTimeSeries', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveMarketRegimeWithElementsTimeSeries', 'sector', sector)
            const localVarPath = `/market/outlook/focus/regime/timeseries/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveOutlookByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveOutlookByAssetClass', 'geography', geography)
            const localVarPath = `/market/outlook/{asset-class}/{geography}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookContinuousTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/outlook/timeseries/continuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookFocusByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveOutlookFocusByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveOutlookFocusByAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveOutlookFocusByAssetClass', 'sector', sector)
            // verify required parameter 'positioningDriver' is not null or undefined
            assertParamExists('retrieveOutlookFocusByAssetClass', 'positioningDriver', positioningDriver)
            const localVarPath = `/market/outlook/focus/{asset-class}/{geography}/{sector}/{positioning-driver}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)))
                .replace(`{${"positioning-driver"}}`, encodeURIComponent(String(positioningDriver)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookFocusTimeSeriesByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveOutlookFocusTimeSeriesByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveOutlookFocusTimeSeriesByAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveOutlookFocusTimeSeriesByAssetClass', 'sector', sector)
            // verify required parameter 'positioningDriver' is not null or undefined
            assertParamExists('retrieveOutlookFocusTimeSeriesByAssetClass', 'positioningDriver', positioningDriver)
            const localVarPath = `/market/outlook/focus/timeseries/{asset-class}/{geography}/{sector}/{positioning-driver}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)))
                .replace(`{${"positioning-driver"}}`, encodeURIComponent(String(positioningDriver)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/outlook/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeriesByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveOutlookTimeSeriesByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveOutlookTimeSeriesByAssetClass', 'geography', geography)
            const localVarPath = `/market/outlook/timeseries/{asset-class}/{geography}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeriesContinuousByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveOutlookTimeSeriesContinuousByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveOutlookTimeSeriesContinuousByAssetClass', 'geography', geography)
            const localVarPath = `/market/outlook/timeseries/continuous/{asset-class}/{geography}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOverview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOverviewTimeSeries: async (fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/overview/timeseries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningContinuousTimeSeries: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrievePositioningContinuousTimeSeries', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrievePositioningContinuousTimeSeries', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrievePositioningContinuousTimeSeries', 'sector', sector)
            const localVarPath = `/market/positioning/timeseries/continuous/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningDriver: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/positioning-drivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningTimeSeries: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrievePositioningTimeSeries', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrievePositioningTimeSeries', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrievePositioningTimeSeries', 'sector', sector)
            const localVarPath = `/market/positioning/timeseries/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from-date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVarByAssetClass: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveVarByAssetClass', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveVarByAssetClass', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveVarByAssetClass', 'sector', sector)
            const localVarPath = `/market/var/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketControllerApi - functional programming interface
 * @export
 */
export const MarketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAssetClass(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAssetClass(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFactors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FactorsEnum>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFactors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveFactorsByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorsMarketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveFactorsByAssetClass(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveForecastByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketForecastDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveForecastByAssetClass(assetClass, geography, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveGeography(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveGeography(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveMacroContextDriver(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MacroContextDriverDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMacroContextDriver(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveMarketRegimeWithElements(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetClassRegimeV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMarketRegimeWithElements(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveMarketRegimeWithElementsTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetClassRegimeTimeSeries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMarketRegimeWithElementsTimeSeries(assetClass, geography, sector, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookByAssetClass(assetClass, geography, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookContinuousTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookFocusByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOutlookFocusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookFocusByAssetClass(assetClass, geography, sector, positioningDriver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookFocusTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOutlookFocusTimeSeriesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookFocusTimeSeriesByAssetClass(assetClass, geography, sector, positioningDriver, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookTimeSeriesByAssetClass(assetClass, geography, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketOutlookTimeSeriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass, geography, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOverview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveOverviewTimeSeries(fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOverviewTimeSeriesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveOverviewTimeSeries(fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePositioningContinuousTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOutlookTimeSeriesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePositioningContinuousTimeSeries(assetClass, geography, sector, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePositioningDriver(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePositioningDriver(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePositioningTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketOutlookTimeSeriesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePositioningTimeSeries(assetClass, geography, sector, fromDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSectors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSectors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveVarByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelValueV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveVarByAssetClass(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketControllerApi - factory interface
 * @export
 */
export const MarketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAssetClass(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveAssetClass(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFactors(options?: any): AxiosPromise<Array<FactorsEnum>> {
            return localVarFp.retrieveFactors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFactorsByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<FactorsMarketDto> {
            return localVarFp.retrieveFactorsByAssetClass(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveForecastByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: any): AxiosPromise<Array<MarketForecastDTO>> {
            return localVarFp.retrieveForecastByAssetClass(assetClass, geography, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGeography(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveGeography(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMacroContextDriver(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<Array<MacroContextDriverDTO>> {
            return localVarFp.retrieveMacroContextDriver(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketRegimeWithElements(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<AssetClassRegimeV2> {
            return localVarFp.retrieveMarketRegimeWithElements(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMarketRegimeWithElementsTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: any): AxiosPromise<AssetClassRegimeTimeSeries> {
            return localVarFp.retrieveMarketRegimeWithElementsTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlook(options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.retrieveOutlook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: any): AxiosPromise<Array<MarketOutlookDTO>> {
            return localVarFp.retrieveOutlookByAssetClass(assetClass, geography, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookContinuousTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.retrieveOutlookContinuousTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookFocusByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', options?: any): AxiosPromise<MarketOutlookFocusDTO> {
            return localVarFp.retrieveOutlookFocusByAssetClass(assetClass, geography, sector, positioningDriver, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookFocusTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', fromDate?: string, options?: any): AxiosPromise<MarketOutlookFocusTimeSeriesDTO> {
            return localVarFp.retrieveOutlookFocusTimeSeriesByAssetClass(assetClass, geography, sector, positioningDriver, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeries(fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.retrieveOutlookTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.retrieveOutlookTimeSeriesByAssetClass(assetClass, geography, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: any): AxiosPromise<Array<MarketOutlookTimeSeriesDTO>> {
            return localVarFp.retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass, geography, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOverview(options?: any): AxiosPromise<MarketOverviewDTO> {
            return localVarFp.retrieveOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOverviewTimeSeries(fromDate?: string, options?: any): AxiosPromise<MarketOverviewTimeSeriesDTO> {
            return localVarFp.retrieveOverviewTimeSeries(fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningContinuousTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: any): AxiosPromise<MarketOutlookTimeSeriesDTO> {
            return localVarFp.retrievePositioningContinuousTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningDriver(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrievePositioningDriver(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {string} [fromDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePositioningTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: any): AxiosPromise<MarketOutlookTimeSeriesDTO> {
            return localVarFp.retrievePositioningTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSectors(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.retrieveSectors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVarByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<Array<LabelValueV2>> {
            return localVarFp.retrieveVarByAssetClass(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketControllerApi - object-oriented interface
 * @export
 * @class MarketControllerApi
 * @extends {BaseAPI}
 */
export class MarketControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveAssetClass(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveAssetClass(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveFactors(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveFactors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveFactorsByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveFactorsByAssetClass(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveForecastByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveForecastByAssetClass(assetClass, geography, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveGeography(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveGeography(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveMacroContextDriver(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveMacroContextDriver(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveMarketRegimeWithElements(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveMarketRegimeWithElements(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveMarketRegimeWithElementsTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveMarketRegimeWithElementsTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlook(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookByAssetClass(assetClass, geography, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookContinuousTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookContinuousTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookFocusByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookFocusByAssetClass(assetClass, geography, sector, positioningDriver, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME'} positioningDriver 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookFocusTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', positioningDriver: 'RETURN' | 'VOLATILITY' | 'DIVERSIFICATION' | 'REGIME', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookFocusTimeSeriesByAssetClass(assetClass, geography, sector, positioningDriver, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookTimeSeriesByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookTimeSeriesByAssetClass(assetClass, geography, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOutlookTimeSeriesContinuousByAssetClass(assetClass, geography, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOverview(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveOverviewTimeSeries(fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveOverviewTimeSeries(fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrievePositioningContinuousTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrievePositioningContinuousTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrievePositioningDriver(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrievePositioningDriver(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {string} [fromDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrievePositioningTimeSeries(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', fromDate?: string, options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrievePositioningTimeSeries(assetClass, geography, sector, fromDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveSectors(options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveSectors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketControllerApi
     */
    public retrieveVarByAssetClass(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return MarketControllerApiFp(this.configuration).retrieveVarByAssetClass(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketReportTestControllerApi - axios parameter creator
 * @export
 */
export const MarketReportTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketOlderThanTenDays: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('deleteMarketOlderThanTenDays', 'xApiKey', xApiKey)
            const localVarPath = `/v1/market-reports/older-than-ten-days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLastHmmReport: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('findLastHmmReport', 'xApiKey', xApiKey)
            const localVarPath = `/v1/market-reports/last-hmm-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLastMarketReport: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('findLastMarketReport', 'xApiKey', xApiKey)
            const localVarPath = `/v1/market-reports/last-market-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findYesterdayHMMReport: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('findYesterdayHMMReport', 'xApiKey', xApiKey)
            const localVarPath = `/v1/market-reports/yesterday-hmm-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findYesterdayMarketReport: async (xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('findYesterdayMarketReport', 'xApiKey', xApiKey)
            const localVarPath = `/v1/market-reports/yesterday-market-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports2: async (xApiKey: string, reportDto: ReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setHmmReports2', 'xApiKey', xApiKey)
            // verify required parameter 'reportDto' is not null or undefined
            assertParamExists('setHmmReports2', 'reportDto', reportDto)
            const localVarPath = `/v1/market-reports/hmm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReports1: async (xApiKey: string, marketReportDTO: MarketReportDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setMarketReports1', 'xApiKey', xApiKey)
            // verify required parameter 'marketReportDTO' is not null or undefined
            assertParamExists('setMarketReports1', 'marketReportDTO', marketReportDTO)
            const localVarPath = `/v1/market-reports/market`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketReportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketReportTestControllerApi - functional programming interface
 * @export
 */
export const MarketReportTestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketReportTestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMarketOlderThanTenDays(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMarketOlderThanTenDays(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLastHmmReport(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLastHmmReport(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLastMarketReport(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketReportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLastMarketReport(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findYesterdayHMMReport(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findYesterdayHMMReport(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findYesterdayMarketReport(xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketReportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findYesterdayMarketReport(xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHmmReports2(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHmmReports2(xApiKey, reportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMarketReports1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMarketReports1(xApiKey, marketReportDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketReportTestControllerApi - factory interface
 * @export
 */
export const MarketReportTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketReportTestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketOlderThanTenDays(xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMarketOlderThanTenDays(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLastHmmReport(xApiKey: string, options?: any): AxiosPromise<ReportDto> {
            return localVarFp.findLastHmmReport(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLastMarketReport(xApiKey: string, options?: any): AxiosPromise<MarketReportDTO> {
            return localVarFp.findLastMarketReport(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findYesterdayHMMReport(xApiKey: string, options?: any): AxiosPromise<ReportDto> {
            return localVarFp.findYesterdayHMMReport(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findYesterdayMarketReport(xApiKey: string, options?: any): AxiosPromise<MarketReportDTO> {
            return localVarFp.findYesterdayMarketReport(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports2(xApiKey: string, reportDto: ReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.setHmmReports2(xApiKey, reportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReports1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setMarketReports1(xApiKey, marketReportDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketReportTestControllerApi - object-oriented interface
 * @export
 * @class MarketReportTestControllerApi
 * @extends {BaseAPI}
 */
export class MarketReportTestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public deleteMarketOlderThanTenDays(xApiKey: string, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).deleteMarketOlderThanTenDays(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public findLastHmmReport(xApiKey: string, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).findLastHmmReport(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public findLastMarketReport(xApiKey: string, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).findLastMarketReport(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public findYesterdayHMMReport(xApiKey: string, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).findYesterdayHMMReport(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public findYesterdayMarketReport(xApiKey: string, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).findYesterdayMarketReport(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
     * @param {string} xApiKey 
     * @param {ReportDto} reportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public setHmmReports2(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).setHmmReports2(xApiKey, reportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json to create or update the entire data related to markets.
     * @param {string} xApiKey 
     * @param {MarketReportDTO} marketReportDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketReportTestControllerApi
     */
    public setMarketReports1(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig) {
        return MarketReportTestControllerApiFp(this.configuration).setMarketReports1(xApiKey, marketReportDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketUniverseControllerV3Api - axios parameter creator
 * @export
 */
export const MarketUniverseControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universeControllerGetAsset: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/universe/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketUniverseControllerV3Api - functional programming interface
 * @export
 */
export const MarketUniverseControllerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketUniverseControllerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async universeControllerGetAsset(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetClassesModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.universeControllerGetAsset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketUniverseControllerV3Api - factory interface
 * @export
 */
export const MarketUniverseControllerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketUniverseControllerV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universeControllerGetAsset(options?: any): AxiosPromise<Array<AssetClassesModel>> {
            return localVarFp.universeControllerGetAsset(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketUniverseControllerV3Api - object-oriented interface
 * @export
 * @class MarketUniverseControllerV3Api
 * @extends {BaseAPI}
 */
export class MarketUniverseControllerV3Api extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketUniverseControllerV3Api
     */
    public universeControllerGetAsset(options?: AxiosRequestConfig) {
        return MarketUniverseControllerV3ApiFp(this.configuration).universeControllerGetAsset(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketViewControllerApi - axios parameter creator
 * @export
 */
export const MarketViewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new market view with the given data.
         * @param {CreateMarketViewRequest} createMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMarketView: async (createMarketViewRequest: CreateMarketViewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMarketViewRequest' is not null or undefined
            assertParamExists('createMarketView', 'createMarketViewRequest', createMarketViewRequest)
            const localVarPath = `/v3/market-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMarketViewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the specified market view.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketView: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteMarketView', 'uuid', uuid)
            const localVarPath = `/v3/market-view/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a copy of the given market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateMarketView: async (uuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('duplicateMarketView', 'uuid', uuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('duplicateMarketView', 'name', name)
            const localVarPath = `/v3/market-view/duplicate/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modifies the specified market view with the given data.
         * @param {string} uuid 
         * @param {EditMarketViewRequest} editMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMarketView: async (uuid: string, editMarketViewRequest: EditMarketViewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('editMarketView', 'uuid', uuid)
            // verify required parameter 'editMarketViewRequest' is not null or undefined
            assertParamExists('editMarketView', 'editMarketViewRequest', editMarketViewRequest)
            const localVarPath = `/v3/market-view/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editMarketViewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL3: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getACL3', 'uuid', uuid)
            const localVarPath = `/v3/market-view/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter3: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getAclEditStarter3', 'uuid', uuid)
            const localVarPath = `/v3/market-view/{uuid}/acl-edit-starter`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the details of the selected market view, or of the template to start from.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketView: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getMarketView', 'uuid', uuid)
            const localVarPath = `/v3/market-view/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of all available market views.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/market-view/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the info of the given scenario. The API gives the possibility to select the also an investment for the current ptf entry. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} scenarioId 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewScenario: async (scenarioId: string, investmentUuid?: string, useEnhancement?: boolean, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('getMarketViewScenario', 'scenarioId', scenarioId)
            const localVarPath = `/v3/market-view/scenarios/{scenario-id}`
                .replace(`{${"scenario-id"}}`, encodeURIComponent(String(scenarioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investment-uuid'] = investmentUuid;
            }

            if (useEnhancement !== undefined) {
                localVarQueryParameter['use-enhancement'] = useEnhancement;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of available scenarios. You can also give the investment horizon (that will be translated to forecast horizon) to filter the user market view accordingly.PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {boolean} [includeHistorical] 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {MarketViewType} [marketViewType] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewScenarioList: async (includeHistorical?: boolean, investmentUuid?: string, useEnhancement?: boolean, marketViewType?: MarketViewType, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/market-view/scenarios/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeHistorical !== undefined) {
                localVarQueryParameter['include-historical'] = includeHistorical;
            }

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investment-uuid'] = investmentUuid;
            }

            if (useEnhancement !== undefined) {
                localVarQueryParameter['use-enhancement'] = useEnhancement;
            }

            if (marketViewType !== undefined) {
                localVarQueryParameter['market-view-type'] = marketViewType;
            }

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the portfolio/benchmark exposure for market view calculations. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} investmentUuid 
         * @param {boolean} useEnhancement 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMarketViewScenarioPerformanceContributions: async (investmentUuid: string, useEnhancement: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('getMarketViewScenarioPerformanceContributions', 'investmentUuid', investmentUuid)
            // verify required parameter 'useEnhancement' is not null or undefined
            assertParamExists('getMarketViewScenarioPerformanceContributions', 'useEnhancement', useEnhancement)
            const localVarPath = `/v3/market-view/scenarios/performance-contribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (investmentUuid !== undefined) {
                localVarQueryParameter['investment-uuid'] = investmentUuid;
            }

            if (useEnhancement !== undefined) {
                localVarQueryParameter['use-enhancement'] = useEnhancement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the available macro types per user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewUserSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/market-view/market-view-user-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL3: async (uuid: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserACL3', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserACL3', 'userId', userId)
            const localVarPath = `/v3/market-view/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission3: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('grantUserPermission3', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('grantUserPermission3', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('grantUserPermission3', 'permission', permission)
            const localVarPath = `/v3/market-view/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission3: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('hasUserPermission3', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasUserPermission3', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('hasUserPermission3', 'permission', permission)
            const localVarPath = `/v3/market-view/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new market view.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isMarketViewNameAvailable: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('isMarketViewNameAvailable', 'name', name)
            const localVarPath = `/v3/market-view/name-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the name of the market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameMarketView: async (uuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('renameMarketView', 'uuid', uuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameMarketView', 'name', name)
            const localVarPath = `/v3/market-view/rename/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission3: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('revokeUserPermission3', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('revokeUserPermission3', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('revokeUserPermission3', 'permission', permission)
            const localVarPath = `/v3/market-view/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL3: async (uuid: string, richAccessControl: Array<RichAccessControl>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setACL3', 'uuid', uuid)
            // verify required parameter 'richAccessControl' is not null or undefined
            assertParamExists('setACL3', 'richAccessControl', richAccessControl)
            const localVarPath = `/v3/market-view/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richAccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL3: async (uuid: string, userId: string, accessPermission: Array<AccessPermission>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setUserACL3', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserACL3', 'userId', userId)
            // verify required parameter 'accessPermission' is not null or undefined
            assertParamExists('setUserACL3', 'accessPermission', accessPermission)
            const localVarPath = `/v3/market-view/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketViewControllerApi - functional programming interface
 * @export
 */
export const MarketViewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketViewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new market view with the given data.
         * @param {CreateMarketViewRequest} createMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMarketView(createMarketViewRequest: CreateMarketViewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMarketView(createMarketViewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the specified market view.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMarketView(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMarketView(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a copy of the given market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateMarketView(uuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateMarketView(uuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modifies the specified market view with the given data.
         * @param {string} uuid 
         * @param {EditMarketViewRequest} editMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editMarketView(uuid: string, editMarketViewRequest: EditMarketViewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editMarketView(uuid, editMarketViewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getACL3(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getACL3(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAclEditStarter3(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclEditStarter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAclEditStarter3(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the details of the selected market view, or of the template to start from.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketView(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketView(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of all available market views.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketViewListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the info of the given scenario. The API gives the possibility to select the also an investment for the current ptf entry. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} scenarioId 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewScenario(scenarioId: string, investmentUuid?: string, useEnhancement?: boolean, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketScenario>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewScenario(scenarioId, investmentUuid, useEnhancement, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of available scenarios. You can also give the investment horizon (that will be translated to forecast horizon) to filter the user market view accordingly.PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {boolean} [includeHistorical] 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {MarketViewType} [marketViewType] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewScenarioList(includeHistorical?: boolean, investmentUuid?: string, useEnhancement?: boolean, marketViewType?: MarketViewType, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketScenarioMinInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewScenarioList(includeHistorical, investmentUuid, useEnhancement, marketViewType, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the portfolio/benchmark exposure for market view calculations. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} investmentUuid 
         * @param {boolean} useEnhancement 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMarketViewScenarioPerformanceContributions(investmentUuid: string, useEnhancement: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformanceContributionEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewScenarioPerformanceContributions(investmentUuid, useEnhancement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the available macro types per user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewUserSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewUserSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserACL3(uuid: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichAccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserACL3(uuid, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantUserPermission3(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserPermission3(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new market view.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isMarketViewNameAvailable(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isMarketViewNameAvailable(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the name of the market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameMarketView(uuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameMarketView(uuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserPermission3(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setACL3(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setACL3(uuid, richAccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserACL3(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserACL3(uuid, userId, accessPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketViewControllerApi - factory interface
 * @export
 */
export const MarketViewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketViewControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new market view with the given data.
         * @param {CreateMarketViewRequest} createMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMarketView(createMarketViewRequest: CreateMarketViewRequest, options?: any): AxiosPromise<MarketViewDetails> {
            return localVarFp.createMarketView(createMarketViewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the specified market view.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketView(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMarketView(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a copy of the given market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateMarketView(uuid: string, name: string, options?: any): AxiosPromise<MarketViewDetails> {
            return localVarFp.duplicateMarketView(uuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modifies the specified market view with the given data.
         * @param {string} uuid 
         * @param {EditMarketViewRequest} editMarketViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMarketView(uuid: string, editMarketViewRequest: EditMarketViewRequest, options?: any): AxiosPromise<MarketViewDetails> {
            return localVarFp.editMarketView(uuid, editMarketViewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL3(uuid: string, options?: any): AxiosPromise<Array<AccessControl>> {
            return localVarFp.getACL3(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter3(uuid: string, options?: any): AxiosPromise<AclEditStarter> {
            return localVarFp.getAclEditStarter3(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the details of the selected market view, or of the template to start from.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketView(uuid: string, options?: any): AxiosPromise<MarketViewDetails> {
            return localVarFp.getMarketView(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of all available market views.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewList(options?: any): AxiosPromise<Array<MarketViewListEntry>> {
            return localVarFp.getMarketViewList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the info of the given scenario. The API gives the possibility to select the also an investment for the current ptf entry. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} scenarioId 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewScenario(scenarioId: string, investmentUuid?: string, useEnhancement?: boolean, custom?: boolean, options?: any): AxiosPromise<MarketScenario> {
            return localVarFp.getMarketViewScenario(scenarioId, investmentUuid, useEnhancement, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of available scenarios. You can also give the investment horizon (that will be translated to forecast horizon) to filter the user market view accordingly.PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {boolean} [includeHistorical] 
         * @param {string} [investmentUuid] 
         * @param {boolean} [useEnhancement] 
         * @param {MarketViewType} [marketViewType] 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewScenarioList(includeHistorical?: boolean, investmentUuid?: string, useEnhancement?: boolean, marketViewType?: MarketViewType, custom?: boolean, options?: any): AxiosPromise<Array<MarketScenarioMinInfo>> {
            return localVarFp.getMarketViewScenarioList(includeHistorical, investmentUuid, useEnhancement, marketViewType, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the portfolio/benchmark exposure for market view calculations. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
         * @param {string} investmentUuid 
         * @param {boolean} useEnhancement 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMarketViewScenarioPerformanceContributions(investmentUuid: string, useEnhancement: boolean, options?: any): AxiosPromise<Array<PerformanceContributionEntry>> {
            return localVarFp.getMarketViewScenarioPerformanceContributions(investmentUuid, useEnhancement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the available macro types per user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewUserSettings(options?: any): AxiosPromise<MarketViewSettings> {
            return localVarFp.getMarketViewUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL3(uuid: string, userId: string, options?: any): AxiosPromise<RichAccessControl> {
            return localVarFp.getUserACL3(uuid, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.grantUserPermission3(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasUserPermission3(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new market view.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isMarketViewNameAvailable(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isMarketViewNameAvailable(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the name of the market view.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameMarketView(uuid: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.renameMarketView(uuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.revokeUserPermission3(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL3(uuid: string, richAccessControl: Array<RichAccessControl>, options?: any): AxiosPromise<void> {
            return localVarFp.setACL3(uuid, richAccessControl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL3(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserACL3(uuid, userId, accessPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketViewControllerApi - object-oriented interface
 * @export
 * @class MarketViewControllerApi
 * @extends {BaseAPI}
 */
export class MarketViewControllerApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new market view with the given data.
     * @param {CreateMarketViewRequest} createMarketViewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public createMarketView(createMarketViewRequest: CreateMarketViewRequest, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).createMarketView(createMarketViewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the specified market view.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public deleteMarketView(uuid: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).deleteMarketView(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a copy of the given market view.
     * @param {string} uuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public duplicateMarketView(uuid: string, name: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).duplicateMarketView(uuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modifies the specified market view with the given data.
     * @param {string} uuid 
     * @param {EditMarketViewRequest} editMarketViewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public editMarketView(uuid: string, editMarketViewRequest: EditMarketViewRequest, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).editMarketView(uuid, editMarketViewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getACL3(uuid: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getACL3(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getAclEditStarter3(uuid: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getAclEditStarter3(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the details of the selected market view, or of the template to start from.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketView(uuid: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketView(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of all available market views.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketViewList(options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketViewList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the info of the given scenario. The API gives the possibility to select the also an investment for the current ptf entry. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
     * @param {string} scenarioId 
     * @param {string} [investmentUuid] 
     * @param {boolean} [useEnhancement] 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketViewScenario(scenarioId: string, investmentUuid?: string, useEnhancement?: boolean, custom?: boolean, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketViewScenario(scenarioId, investmentUuid, useEnhancement, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of available scenarios. You can also give the investment horizon (that will be translated to forecast horizon) to filter the user market view accordingly.PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
     * @param {boolean} [includeHistorical] 
     * @param {string} [investmentUuid] 
     * @param {boolean} [useEnhancement] 
     * @param {MarketViewType} [marketViewType] 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketViewScenarioList(includeHistorical?: boolean, investmentUuid?: string, useEnhancement?: boolean, marketViewType?: MarketViewType, custom?: boolean, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketViewScenarioList(includeHistorical, investmentUuid, useEnhancement, marketViewType, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the portfolio/benchmark exposure for market view calculations. PS: It should be a GET, but it is problematic to use query params w. the currently used investment enum system. Will clean implementation after enum migrations.
     * @param {string} investmentUuid 
     * @param {boolean} useEnhancement 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketViewScenarioPerformanceContributions(investmentUuid: string, useEnhancement: boolean, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketViewScenarioPerformanceContributions(investmentUuid, useEnhancement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the available macro types per user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getMarketViewUserSettings(options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getMarketViewUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public getUserACL3(uuid: string, userId: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).getUserACL3(uuid, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public grantUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).grantUserPermission3(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public hasUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).hasUserPermission3(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name for a new market view.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public isMarketViewNameAvailable(name: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).isMarketViewNameAvailable(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the name of the market view.
     * @param {string} uuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public renameMarketView(uuid: string, name: string, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).renameMarketView(uuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public revokeUserPermission3(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).revokeUserPermission3(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {Array<RichAccessControl>} richAccessControl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public setACL3(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).setACL3(uuid, richAccessControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {Array<AccessPermission>} accessPermission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewControllerApi
     */
    public setUserACL3(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig) {
        return MarketViewControllerApiFp(this.configuration).setUserACL3(uuid, userId, accessPermission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsControllerApi - axios parameter creator
 * @export
 */
export const NotificationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert1: async (name: string, enableAlertDto: EnableAlertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addAlert1', 'name', name)
            // verify required parameter 'enableAlertDto' is not null or undefined
            assertParamExists('addAlert1', 'enableAlertDto', enableAlertDto)
            const localVarPath = `/user/notification/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableAlertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert1: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteAlert1', 'name', name)
            const localVarPath = `/user/notification/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAlerts1: async (enableAlertDto: Array<EnableAlertDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableAlertDto' is not null or undefined
            assertParamExists('editAlerts1', 'enableAlertDto', enableAlertDto)
            const localVarPath = `/user/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableAlertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserAlert1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsControllerApi - functional programming interface
 * @export
 */
export const NotificationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAlert1(name: string, enableAlertDto: EnableAlertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAlert1(name, enableAlertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlert1(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlert1(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAlerts1(enableAlertDto: Array<EnableAlertDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editAlerts1(enableAlertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllUserAlert1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAlertDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllUserAlert1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsControllerApi - factory interface
 * @export
 */
export const NotificationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert1(name: string, enableAlertDto: EnableAlertDto, options?: any): AxiosPromise<string> {
            return localVarFp.addAlert1(name, enableAlertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert1(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAlert1(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAlerts1(enableAlertDto: Array<EnableAlertDto>, options?: any): AxiosPromise<string> {
            return localVarFp.editAlerts1(enableAlertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserAlert1(options?: any): AxiosPromise<UserAlertDto> {
            return localVarFp.retrieveAllUserAlert1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsControllerApi - object-oriented interface
 * @export
 * @class NotificationsControllerApi
 * @extends {BaseAPI}
 */
export class NotificationsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {EnableAlertDto} enableAlertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public addAlert1(name: string, enableAlertDto: EnableAlertDto, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).addAlert1(name, enableAlertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public deleteAlert1(name: string, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).deleteAlert1(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<EnableAlertDto>} enableAlertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public editAlerts1(enableAlertDto: Array<EnableAlertDto>, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).editAlerts1(enableAlertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all alerts of system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public retrieveAllUserAlert1(options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).retrieveAllUserAlert1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OutlookControllerV2Api - axios parameter creator
 * @export
 */
export const OutlookControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReturnAnalysis: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('dashboardControllerGetReturnAnalysis', 'type', type)
            const localVarPath = `/v2/outlook/return-analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutlookControllerV2Api - functional programming interface
 * @export
 */
export const OutlookControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutlookControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetReturnAnalysis(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnAnalysisDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetReturnAnalysis(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OutlookControllerV2Api - factory interface
 * @export
 */
export const OutlookControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutlookControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReturnAnalysis(type: string, options?: any): AxiosPromise<ReturnAnalysisDto> {
            return localVarFp.dashboardControllerGetReturnAnalysis(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutlookControllerV2Api - object-oriented interface
 * @export
 * @class OutlookControllerV2Api
 * @extends {BaseAPI}
 */
export class OutlookControllerV2Api extends BaseAPI {
    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutlookControllerV2Api
     */
    public dashboardControllerGetReturnAnalysis(type: string, options?: AxiosRequestConfig) {
        return OutlookControllerV2ApiFp(this.configuration).dashboardControllerGetReturnAnalysis(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OutlookControllerV3Api - axios parameter creator
 * @export
 */
export const OutlookControllerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAcMetricsStandard: async (assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetClass' is not null or undefined
            assertParamExists('retrieveAcMetricsStandard', 'assetClass', assetClass)
            // verify required parameter 'geography' is not null or undefined
            assertParamExists('retrieveAcMetricsStandard', 'geography', geography)
            // verify required parameter 'sector' is not null or undefined
            assertParamExists('retrieveAcMetricsStandard', 'sector', sector)
            const localVarPath = `/v3/outlook/outlook/asset-class-summary/{asset-class}/{geography}/{sector}`
                .replace(`{${"asset-class"}}`, encodeURIComponent(String(assetClass)))
                .replace(`{${"geography"}}`, encodeURIComponent(String(geography)))
                .replace(`{${"sector"}}`, encodeURIComponent(String(sector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutlookControllerV3Api - functional programming interface
 * @export
 */
export const OutlookControllerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutlookControllerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAcMetricsStandard(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetClassSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAcMetricsStandard(assetClass, geography, sector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OutlookControllerV3Api - factory interface
 * @export
 */
export const OutlookControllerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutlookControllerV3ApiFp(configuration)
    return {
        /**
         * 
         * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
         * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
         * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAcMetricsStandard(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: any): AxiosPromise<AssetClassSummary> {
            return localVarFp.retrieveAcMetricsStandard(assetClass, geography, sector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutlookControllerV3Api - object-oriented interface
 * @export
 * @class OutlookControllerV3Api
 * @extends {BaseAPI}
 */
export class OutlookControllerV3Api extends BaseAPI {
    /**
     * 
     * @param {'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ'} assetClass 
     * @param {'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp'} geography 
     * @param {'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy'} sector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutlookControllerV3Api
     */
    public retrieveAcMetricsStandard(assetClass: 'EQUITY' | 'FIXED_INCOME' | 'COMMODITIES' | 'FIXEDINCOME' | 'equity' | 'fixed_income' | 'fixedIncome' | 'commodities' | 'CO' | 'FI' | 'EQ', geography: 'EM' | 'JP' | 'US' | 'EU' | 'GLOBAL' | 'em' | 'jp' | 'us' | 'eu' | 'global' | 'UK' | 'uk' | 'EU_SMALL_MID_CAP' | 'eu_small_mid_cap' | 'PACIFIC_EX_JP' | 'pacific_ex_jp', sector: 'ALL' | 'GLOBAL' | 'PRECIOUS_METALS' | 'ENERGY' | 'FINANCIALS' | 'INDUSTRIALS' | 'CONSUMER_STAPLES' | 'COMMUNICATION_SERVICES' | 'INFORMATION_TECHNOLOGY' | 'CONSUMER_DISCRETIONARY' | 'HEALTHCARE' | 'HEALTH_CARE' | 'MATERIALS' | 'UTILITIES' | 'REAL_ESTATE' | 'AGRICULTURE' | 'INDUSTRIAL_METALS' | 'LONG_GOV' | 'MEDIUM_GOV' | 'SHORT_GOV' | 'CORP' | 'GOV' | 'HY' | 'all' | 'global' | 'precious_metals' | 'energy' | 'financials' | 'industrials' | 'consumer_staples' | 'communication_services' | 'information_technology' | 'consumer_discretionary' | 'healthcare' | 'health_care' | 'materials' | 'utilities' | 'real_estate' | 'agriculture' | 'industrial_metals' | 'long_gov' | 'medium_gov' | 'short_gov' | 'corp' | 'gov' | 'commodities' | 'hy', options?: AxiosRequestConfig) {
        return OutlookControllerV3ApiFp(this.configuration).retrieveAcMetricsStandard(assetClass, geography, sector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageConfigurationsControllerApi - axios parameter creator
 * @export
 */
export const PageConfigurationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the pages of which the system can handle custom widget configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/available-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the layout of the widgets used by the user on a given page, if customized.
         * @param {string} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWidgetsConfigurationsControllerGetPageConfiguration: async (page: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('pageWidgetsConfigurationsControllerGetPageConfiguration', 'page', page)
            const localVarPath = `/page-configurations/{page}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modifies the personal layout of the user for a given page.
         * @param {PageWidgetsConfiguration} pageWidgetsConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWidgetsConfigurationsControllerPutConfiguration: async (pageWidgetsConfiguration: PageWidgetsConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageWidgetsConfiguration' is not null or undefined
            assertParamExists('pageWidgetsConfigurationsControllerPutConfiguration', 'pageWidgetsConfiguration', pageWidgetsConfiguration)
            const localVarPath = `/page-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageWidgetsConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageConfigurationsControllerApi - functional programming interface
 * @export
 */
export const PageConfigurationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageConfigurationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the pages of which the system can handle custom widget configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pages>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the layout of the widgets used by the user on a given page, if customized.
         * @param {string} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWidgetsConfigurationsControllerGetPageConfiguration(page: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWidgetsConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWidgetsConfigurationsControllerGetPageConfiguration(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modifies the personal layout of the user for a given page.
         * @param {PageWidgetsConfiguration} pageWidgetsConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration: PageWidgetsConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWidgetsConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageConfigurationsControllerApi - factory interface
 * @export
 */
export const PageConfigurationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageConfigurationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the pages of which the system can handle custom widget configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePages(options?: any): AxiosPromise<Array<Pages>> {
            return localVarFp.getAvailablePages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the layout of the widgets used by the user on a given page, if customized.
         * @param {string} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWidgetsConfigurationsControllerGetPageConfiguration(page: string, options?: any): AxiosPromise<PageWidgetsConfiguration> {
            return localVarFp.pageWidgetsConfigurationsControllerGetPageConfiguration(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modifies the personal layout of the user for a given page.
         * @param {PageWidgetsConfiguration} pageWidgetsConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration: PageWidgetsConfiguration, options?: any): AxiosPromise<PageWidgetsConfiguration> {
            return localVarFp.pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageConfigurationsControllerApi - object-oriented interface
 * @export
 * @class PageConfigurationsControllerApi
 * @extends {BaseAPI}
 */
export class PageConfigurationsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the pages of which the system can handle custom widget configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageConfigurationsControllerApi
     */
    public getAvailablePages(options?: AxiosRequestConfig) {
        return PageConfigurationsControllerApiFp(this.configuration).getAvailablePages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the layout of the widgets used by the user on a given page, if customized.
     * @param {string} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageConfigurationsControllerApi
     */
    public pageWidgetsConfigurationsControllerGetPageConfiguration(page: string, options?: AxiosRequestConfig) {
        return PageConfigurationsControllerApiFp(this.configuration).pageWidgetsConfigurationsControllerGetPageConfiguration(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modifies the personal layout of the user for a given page.
     * @param {PageWidgetsConfiguration} pageWidgetsConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageConfigurationsControllerApi
     */
    public pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration: PageWidgetsConfiguration, options?: AxiosRequestConfig) {
        return PageConfigurationsControllerApiFp(this.configuration).pageWidgetsConfigurationsControllerPutConfiguration(pageWidgetsConfiguration, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaperplaneControllerApi - axios parameter creator
 * @export
 */
export const PaperplaneControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaperPlaneWebhookResponseDTO} paperPlaneWebhookResponseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (paperPlaneWebhookResponseDTO: PaperPlaneWebhookResponseDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paperPlaneWebhookResponseDTO' is not null or undefined
            assertParamExists('getDocuments', 'paperPlaneWebhookResponseDTO', paperPlaneWebhookResponseDTO)
            const localVarPath = `/paperplane/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paperPlaneWebhookResponseDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaperplaneControllerApi - functional programming interface
 * @export
 */
export const PaperplaneControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaperplaneControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PaperPlaneWebhookResponseDTO} paperPlaneWebhookResponseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(paperPlaneWebhookResponseDTO: PaperPlaneWebhookResponseDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(paperPlaneWebhookResponseDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaperplaneControllerApi - factory interface
 * @export
 */
export const PaperplaneControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaperplaneControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PaperPlaneWebhookResponseDTO} paperPlaneWebhookResponseDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(paperPlaneWebhookResponseDTO: PaperPlaneWebhookResponseDTO, options?: any): AxiosPromise<void> {
            return localVarFp.getDocuments(paperPlaneWebhookResponseDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaperplaneControllerApi - object-oriented interface
 * @export
 * @class PaperplaneControllerApi
 * @extends {BaseAPI}
 */
export class PaperplaneControllerApi extends BaseAPI {
    /**
     * 
     * @param {PaperPlaneWebhookResponseDTO} paperPlaneWebhookResponseDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaperplaneControllerApi
     */
    public getDocuments(paperPlaneWebhookResponseDTO: PaperPlaneWebhookResponseDTO, options?: AxiosRequestConfig) {
        return PaperplaneControllerApiFp(this.configuration).getDocuments(paperPlaneWebhookResponseDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdfControllerApi - axios parameter creator
 * @export
 */
export const PdfControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadReportsById', 'id', id)
            const localVarPath = `/v3/pdf/reports-download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportsByIdStandard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadReportsByIdStandard', 'id', id)
            const localVarPath = `/v3/pdf/reports-download/{id}/standard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomReportPdf: async (urlPage: string, templateType: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlPage' is not null or undefined
            assertParamExists('generateCustomReportPdf', 'urlPage', urlPage)
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('generateCustomReportPdf', 'templateType', templateType)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('generateCustomReportPdf', 'name', name)
            const localVarPath = `/v3/pdf/custom-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (urlPage !== undefined) {
                localVarQueryParameter['url-page'] = urlPage;
            }

            if (templateType !== undefined) {
                localVarQueryParameter['template-type'] = templateType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomReportRequestDto} customReportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomReportsPdf: async (customReportRequestDto: CustomReportRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customReportRequestDto' is not null or undefined
            assertParamExists('generateCustomReportsPdf', 'customReportRequestDto', customReportRequestDto)
            const localVarPath = `/v3/pdf/custom-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customReportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateInvestmentsPdf: async (urlPage: string, templateType: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlPage' is not null or undefined
            assertParamExists('generateInvestmentsPdf', 'urlPage', urlPage)
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('generateInvestmentsPdf', 'templateType', templateType)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('generateInvestmentsPdf', 'name', name)
            const localVarPath = `/v3/pdf/investments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (urlPage !== undefined) {
                localVarQueryParameter['url-page'] = urlPage;
            }

            if (templateType !== undefined) {
                localVarQueryParameter['template-type'] = templateType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfControllerApi - functional programming interface
 * @export
 */
export const PdfControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponseContentType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportsByIdStandard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportsByIdStandard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCustomReportPdf(urlPage: string, templateType: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCustomReportPdf(urlPage, templateType, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomReportRequestDto} customReportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCustomReportsPdf(customReportRequestDto: CustomReportRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCustomReportsPdf(customReportRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async generateInvestmentsPdf(urlPage: string, templateType: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInvestmentsPdf(urlPage, templateType, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdfControllerApi - factory interface
 * @export
 */
export const PdfControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportsById(id: string, options?: any): AxiosPromise<FileResponseContentType> {
            return localVarFp.downloadReportsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportsByIdStandard(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadReportsByIdStandard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomReportPdf(urlPage: string, templateType: string, name: string, options?: any): AxiosPromise<any> {
            return localVarFp.generateCustomReportPdf(urlPage, templateType, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomReportRequestDto} customReportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomReportsPdf(customReportRequestDto: CustomReportRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.generateCustomReportsPdf(customReportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} urlPage 
         * @param {string} templateType 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateInvestmentsPdf(urlPage: string, templateType: string, name: string, options?: any): AxiosPromise<any> {
            return localVarFp.generateInvestmentsPdf(urlPage, templateType, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfControllerApi - object-oriented interface
 * @export
 * @class PdfControllerApi
 * @extends {BaseAPI}
 */
export class PdfControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfControllerApi
     */
    public downloadReportsById(id: string, options?: AxiosRequestConfig) {
        return PdfControllerApiFp(this.configuration).downloadReportsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfControllerApi
     */
    public downloadReportsByIdStandard(id: string, options?: AxiosRequestConfig) {
        return PdfControllerApiFp(this.configuration).downloadReportsByIdStandard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} urlPage 
     * @param {string} templateType 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfControllerApi
     */
    public generateCustomReportPdf(urlPage: string, templateType: string, name: string, options?: AxiosRequestConfig) {
        return PdfControllerApiFp(this.configuration).generateCustomReportPdf(urlPage, templateType, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomReportRequestDto} customReportRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfControllerApi
     */
    public generateCustomReportsPdf(customReportRequestDto: CustomReportRequestDto, options?: AxiosRequestConfig) {
        return PdfControllerApiFp(this.configuration).generateCustomReportsPdf(customReportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} urlPage 
     * @param {string} templateType 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PdfControllerApi
     */
    public generateInvestmentsPdf(urlPage: string, templateType: string, name: string, options?: AxiosRequestConfig) {
        return PdfControllerApiFp(this.configuration).generateInvestmentsPdf(urlPage, templateType, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortfolioStudioPreferencesApi - axios parameter creator
 * @export
 */
export const PortfolioStudioPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the preferences related to the given custom market view type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMarketViewSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the preferences related to the given default market view type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultMarketViewSettings: async (marketViewType: MarketViewType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewType' is not null or undefined
            assertParamExists('getDefaultMarketViewSettings', 'marketViewType', marketViewType)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/default/{market-view-type}`
                .replace(`{${"market-view-type"}}`, encodeURIComponent(String(marketViewType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current explainability settings preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExplainabilitySettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/explainability-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the alias preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewAssetClassAliases: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current ordering preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioMetricsOrderingPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/metrics-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the selectable asset classes, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectableAssetClasses: async (marketViewType: MarketViewType, custom?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewType' is not null or undefined
            assertParamExists('getSelectableAssetClasses', 'marketViewType', marketViewType)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/selectable-asset-classes/{market-view-type}`
                .replace(`{${"market-view-type"}}`, encodeURIComponent(String(marketViewType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (custom !== undefined) {
                localVarQueryParameter['custom'] = custom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the selectable scenario ids, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectableScenarioIds: async (marketViewType: MarketViewType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewType' is not null or undefined
            assertParamExists('getSelectableScenarioIds', 'marketViewType', marketViewType)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/custom/selectable-scenario-ids/{market-view-type}`
                .replace(`{${"market-view-type"}}`, encodeURIComponent(String(marketViewType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current user market view column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMarketViewColumnMetricsOrderingPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/market-view-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current user portfolio column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioColumnMetricsOrderingPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/portfolio-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current user reference column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserReferenceColumnOrderingPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/reference-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current user universe column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUniverseColumnMetricsOrderingPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolio-studio-preferences/universe-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the preferences related to the given custom market view.
         * @param {CustomMarketViewSettings} customMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomMarketViewSettings: async (customMarketViewSettings: CustomMarketViewSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customMarketViewSettings' is not null or undefined
            assertParamExists('setCustomMarketViewSettings', 'customMarketViewSettings', customMarketViewSettings)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customMarketViewSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the preferences related to the given default market view type.
         * @param {DefaultMarketViewSettings} defaultMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultMarketViewSettings: async (defaultMarketViewSettings: DefaultMarketViewSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defaultMarketViewSettings' is not null or undefined
            assertParamExists('setDefaultMarketViewSettings', 'defaultMarketViewSettings', defaultMarketViewSettings)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defaultMarketViewSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current explainability settings preferences.
         * @param {EditExplainabilitySettingsRequest} editExplainabilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExplainabilitySettings: async (editExplainabilitySettingsRequest: EditExplainabilitySettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editExplainabilitySettingsRequest' is not null or undefined
            assertParamExists('setExplainabilitySettings', 'editExplainabilitySettingsRequest', editExplainabilitySettingsRequest)
            const localVarPath = `/v1/portfolio-studio-preferences/explainability-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editExplainabilitySettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the alias preferences.
         * @param {MarketViewAssetClassAliases} marketViewAssetClassAliases 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketViewAssetClassAliases: async (marketViewAssetClassAliases: MarketViewAssetClassAliases, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewAssetClassAliases' is not null or undefined
            assertParamExists('setMarketViewAssetClassAliases', 'marketViewAssetClassAliases', marketViewAssetClassAliases)
            const localVarPath = `/v2/portfolio-studio-preferences/market-view-settings/market-view-settings/aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketViewAssetClassAliases, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current ordering preferences
         * @param {EditPortfolioMetricsOrderingRequest} editPortfolioMetricsOrderingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPortfolioMetricsOrderingPreferences: async (editPortfolioMetricsOrderingRequest: EditPortfolioMetricsOrderingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPortfolioMetricsOrderingRequest' is not null or undefined
            assertParamExists('setPortfolioMetricsOrderingPreferences', 'editPortfolioMetricsOrderingRequest', editPortfolioMetricsOrderingRequest)
            const localVarPath = `/v1/portfolio-studio-preferences/metrics-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPortfolioMetricsOrderingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current user market view column ordering preferences
         * @param {UserMarketViewColumnOrdering} userMarketViewColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserMarketViewColumnMetricsOrderingPreferences: async (userMarketViewColumnOrdering: UserMarketViewColumnOrdering, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMarketViewColumnOrdering' is not null or undefined
            assertParamExists('setUserMarketViewColumnMetricsOrderingPreferences', 'userMarketViewColumnOrdering', userMarketViewColumnOrdering)
            const localVarPath = `/v1/portfolio-studio-preferences/market-view-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMarketViewColumnOrdering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current user portfolio column ordering preferences
         * @param {UserPortfolioColumnOrdering} userPortfolioColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPortfolioColumnMetricsOrderingPreferences: async (userPortfolioColumnOrdering: UserPortfolioColumnOrdering, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPortfolioColumnOrdering' is not null or undefined
            assertParamExists('setUserPortfolioColumnMetricsOrderingPreferences', 'userPortfolioColumnOrdering', userPortfolioColumnOrdering)
            const localVarPath = `/v1/portfolio-studio-preferences/portfolio-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPortfolioColumnOrdering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current user reference column ordering preferences
         * @param {UserReferenceColumnOrdering} userReferenceColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserReferenceColumnOrderingPreferences: async (userReferenceColumnOrdering: UserReferenceColumnOrdering, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userReferenceColumnOrdering' is not null or undefined
            assertParamExists('setUserReferenceColumnOrderingPreferences', 'userReferenceColumnOrdering', userReferenceColumnOrdering)
            const localVarPath = `/v1/portfolio-studio-preferences/reference-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userReferenceColumnOrdering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the current user universe column ordering preferences
         * @param {UserUniverseColumnOrdering} userUniverseColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserUniverseColumnMetricsOrderingPreferences: async (userUniverseColumnOrdering: UserUniverseColumnOrdering, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUniverseColumnOrdering' is not null or undefined
            assertParamExists('setUserUniverseColumnMetricsOrderingPreferences', 'userUniverseColumnOrdering', userUniverseColumnOrdering)
            const localVarPath = `/v1/portfolio-studio-preferences/universe-user-column-ordering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUniverseColumnOrdering, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioStudioPreferencesApi - functional programming interface
 * @export
 */
export const PortfolioStudioPreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioStudioPreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the preferences related to the given custom market view type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomMarketViewSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomMarketViewSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomMarketViewSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the preferences related to the given default market view type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultMarketViewSettings(marketViewType: MarketViewType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultMarketViewSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultMarketViewSettings(marketViewType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current explainability settings preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExplainabilitySettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExplainabilitySettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExplainabilitySettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the alias preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewAssetClassAliases(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewAssetClassAliases>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewAssetClassAliases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current ordering preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortfolioMetricsOrderingPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioMetricsOrderingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolioMetricsOrderingPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the selectable asset classes, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectableAssetClasses(marketViewType: MarketViewType, custom?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewAssetClassesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectableAssetClasses(marketViewType, custom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the selectable scenario ids, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectableScenarioIds(marketViewType: MarketViewType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableMarketViewScenarioIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectableScenarioIds(marketViewType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current user market view column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMarketViewColumnMetricsOrderingPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMarketViewColumnOrdering>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMarketViewColumnMetricsOrderingPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current user portfolio column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPortfolioColumnMetricsOrderingPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPortfolioColumnOrdering>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPortfolioColumnMetricsOrderingPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current user reference column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserReferenceColumnOrderingPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReferenceColumnOrdering>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserReferenceColumnOrderingPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current user universe column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUniverseColumnMetricsOrderingPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUniverseColumnOrdering>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUniverseColumnMetricsOrderingPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the preferences related to the given custom market view.
         * @param {CustomMarketViewSettings} customMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCustomMarketViewSettings(customMarketViewSettings: CustomMarketViewSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCustomMarketViewSettings(customMarketViewSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the preferences related to the given default market view type.
         * @param {DefaultMarketViewSettings} defaultMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDefaultMarketViewSettings(defaultMarketViewSettings: DefaultMarketViewSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultMarketViewSettings(defaultMarketViewSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current explainability settings preferences.
         * @param {EditExplainabilitySettingsRequest} editExplainabilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setExplainabilitySettings(editExplainabilitySettingsRequest: EditExplainabilitySettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setExplainabilitySettings(editExplainabilitySettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the alias preferences.
         * @param {MarketViewAssetClassAliases} marketViewAssetClassAliases 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMarketViewAssetClassAliases(marketViewAssetClassAliases: MarketViewAssetClassAliases, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMarketViewAssetClassAliases(marketViewAssetClassAliases, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current ordering preferences
         * @param {EditPortfolioMetricsOrderingRequest} editPortfolioMetricsOrderingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest: EditPortfolioMetricsOrderingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current user market view column ordering preferences
         * @param {UserMarketViewColumnOrdering} userMarketViewColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering: UserMarketViewColumnOrdering, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current user portfolio column ordering preferences
         * @param {UserPortfolioColumnOrdering} userPortfolioColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering: UserPortfolioColumnOrdering, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current user reference column ordering preferences
         * @param {UserReferenceColumnOrdering} userReferenceColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering: UserReferenceColumnOrdering, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the current user universe column ordering preferences
         * @param {UserUniverseColumnOrdering} userUniverseColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering: UserUniverseColumnOrdering, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioStudioPreferencesApi - factory interface
 * @export
 */
export const PortfolioStudioPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioStudioPreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the preferences related to the given custom market view type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMarketViewSettings(options?: any): AxiosPromise<CustomMarketViewSettings> {
            return localVarFp.getCustomMarketViewSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the preferences related to the given default market view type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultMarketViewSettings(marketViewType: MarketViewType, options?: any): AxiosPromise<DefaultMarketViewSettings> {
            return localVarFp.getDefaultMarketViewSettings(marketViewType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current explainability settings preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExplainabilitySettings(options?: any): AxiosPromise<ExplainabilitySettingsResponse> {
            return localVarFp.getExplainabilitySettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the alias preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewAssetClassAliases(options?: any): AxiosPromise<MarketViewAssetClassAliases> {
            return localVarFp.getMarketViewAssetClassAliases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current ordering preferences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioMetricsOrderingPreferences(options?: any): AxiosPromise<PortfolioMetricsOrderingResponse> {
            return localVarFp.getPortfolioMetricsOrderingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the selectable asset classes, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {boolean} [custom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectableAssetClasses(marketViewType: MarketViewType, custom?: boolean, options?: any): AxiosPromise<SelectableMarketViewAssetClassesResponse> {
            return localVarFp.getSelectableAssetClasses(marketViewType, custom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the selectable scenario ids, based on the given portfolio type.
         * @param {MarketViewType} marketViewType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectableScenarioIds(marketViewType: MarketViewType, options?: any): AxiosPromise<SelectableMarketViewScenarioIdsResponse> {
            return localVarFp.getSelectableScenarioIds(marketViewType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current user market view column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMarketViewColumnMetricsOrderingPreferences(options?: any): AxiosPromise<UserMarketViewColumnOrdering> {
            return localVarFp.getUserMarketViewColumnMetricsOrderingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current user portfolio column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioColumnMetricsOrderingPreferences(options?: any): AxiosPromise<UserPortfolioColumnOrdering> {
            return localVarFp.getUserPortfolioColumnMetricsOrderingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current user reference column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserReferenceColumnOrderingPreferences(options?: any): AxiosPromise<UserReferenceColumnOrdering> {
            return localVarFp.getUserReferenceColumnOrderingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current user universe column ordering preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUniverseColumnMetricsOrderingPreferences(options?: any): AxiosPromise<UserUniverseColumnOrdering> {
            return localVarFp.getUserUniverseColumnMetricsOrderingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the preferences related to the given custom market view.
         * @param {CustomMarketViewSettings} customMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCustomMarketViewSettings(customMarketViewSettings: CustomMarketViewSettings, options?: any): AxiosPromise<void> {
            return localVarFp.setCustomMarketViewSettings(customMarketViewSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the preferences related to the given default market view type.
         * @param {DefaultMarketViewSettings} defaultMarketViewSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultMarketViewSettings(defaultMarketViewSettings: DefaultMarketViewSettings, options?: any): AxiosPromise<void> {
            return localVarFp.setDefaultMarketViewSettings(defaultMarketViewSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current explainability settings preferences.
         * @param {EditExplainabilitySettingsRequest} editExplainabilitySettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setExplainabilitySettings(editExplainabilitySettingsRequest: EditExplainabilitySettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setExplainabilitySettings(editExplainabilitySettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the alias preferences.
         * @param {MarketViewAssetClassAliases} marketViewAssetClassAliases 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketViewAssetClassAliases(marketViewAssetClassAliases: MarketViewAssetClassAliases, options?: any): AxiosPromise<void> {
            return localVarFp.setMarketViewAssetClassAliases(marketViewAssetClassAliases, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current ordering preferences
         * @param {EditPortfolioMetricsOrderingRequest} editPortfolioMetricsOrderingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest: EditPortfolioMetricsOrderingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current user market view column ordering preferences
         * @param {UserMarketViewColumnOrdering} userMarketViewColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering: UserMarketViewColumnOrdering, options?: any): AxiosPromise<void> {
            return localVarFp.setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current user portfolio column ordering preferences
         * @param {UserPortfolioColumnOrdering} userPortfolioColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering: UserPortfolioColumnOrdering, options?: any): AxiosPromise<void> {
            return localVarFp.setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current user reference column ordering preferences
         * @param {UserReferenceColumnOrdering} userReferenceColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering: UserReferenceColumnOrdering, options?: any): AxiosPromise<void> {
            return localVarFp.setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the current user universe column ordering preferences
         * @param {UserUniverseColumnOrdering} userUniverseColumnOrdering 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering: UserUniverseColumnOrdering, options?: any): AxiosPromise<void> {
            return localVarFp.setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortfolioStudioPreferencesApi - object-oriented interface
 * @export
 * @class PortfolioStudioPreferencesApi
 * @extends {BaseAPI}
 */
export class PortfolioStudioPreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Returns the preferences related to the given custom market view type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getCustomMarketViewSettings(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getCustomMarketViewSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the preferences related to the given default market view type.
     * @param {MarketViewType} marketViewType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getDefaultMarketViewSettings(marketViewType: MarketViewType, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getDefaultMarketViewSettings(marketViewType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current explainability settings preferences.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getExplainabilitySettings(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getExplainabilitySettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the alias preferences.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getMarketViewAssetClassAliases(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getMarketViewAssetClassAliases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current ordering preferences.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getPortfolioMetricsOrderingPreferences(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getPortfolioMetricsOrderingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the selectable asset classes, based on the given portfolio type.
     * @param {MarketViewType} marketViewType 
     * @param {boolean} [custom] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getSelectableAssetClasses(marketViewType: MarketViewType, custom?: boolean, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getSelectableAssetClasses(marketViewType, custom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the selectable scenario ids, based on the given portfolio type.
     * @param {MarketViewType} marketViewType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getSelectableScenarioIds(marketViewType: MarketViewType, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getSelectableScenarioIds(marketViewType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current user market view column ordering preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getUserMarketViewColumnMetricsOrderingPreferences(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getUserMarketViewColumnMetricsOrderingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current user portfolio column ordering preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getUserPortfolioColumnMetricsOrderingPreferences(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getUserPortfolioColumnMetricsOrderingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current user reference column ordering preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getUserReferenceColumnOrderingPreferences(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getUserReferenceColumnOrderingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current user universe column ordering preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public getUserUniverseColumnMetricsOrderingPreferences(options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).getUserUniverseColumnMetricsOrderingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the preferences related to the given custom market view.
     * @param {CustomMarketViewSettings} customMarketViewSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setCustomMarketViewSettings(customMarketViewSettings: CustomMarketViewSettings, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setCustomMarketViewSettings(customMarketViewSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the preferences related to the given default market view type.
     * @param {DefaultMarketViewSettings} defaultMarketViewSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setDefaultMarketViewSettings(defaultMarketViewSettings: DefaultMarketViewSettings, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setDefaultMarketViewSettings(defaultMarketViewSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current explainability settings preferences.
     * @param {EditExplainabilitySettingsRequest} editExplainabilitySettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setExplainabilitySettings(editExplainabilitySettingsRequest: EditExplainabilitySettingsRequest, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setExplainabilitySettings(editExplainabilitySettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the alias preferences.
     * @param {MarketViewAssetClassAliases} marketViewAssetClassAliases 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setMarketViewAssetClassAliases(marketViewAssetClassAliases: MarketViewAssetClassAliases, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setMarketViewAssetClassAliases(marketViewAssetClassAliases, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current ordering preferences
     * @param {EditPortfolioMetricsOrderingRequest} editPortfolioMetricsOrderingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest: EditPortfolioMetricsOrderingRequest, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setPortfolioMetricsOrderingPreferences(editPortfolioMetricsOrderingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current user market view column ordering preferences
     * @param {UserMarketViewColumnOrdering} userMarketViewColumnOrdering 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering: UserMarketViewColumnOrdering, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setUserMarketViewColumnMetricsOrderingPreferences(userMarketViewColumnOrdering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current user portfolio column ordering preferences
     * @param {UserPortfolioColumnOrdering} userPortfolioColumnOrdering 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering: UserPortfolioColumnOrdering, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setUserPortfolioColumnMetricsOrderingPreferences(userPortfolioColumnOrdering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current user reference column ordering preferences
     * @param {UserReferenceColumnOrdering} userReferenceColumnOrdering 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering: UserReferenceColumnOrdering, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setUserReferenceColumnOrderingPreferences(userReferenceColumnOrdering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the current user universe column ordering preferences
     * @param {UserUniverseColumnOrdering} userUniverseColumnOrdering 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioStudioPreferencesApi
     */
    public setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering: UserUniverseColumnOrdering, options?: AxiosRequestConfig) {
        return PortfolioStudioPreferencesApiFp(this.configuration).setUserUniverseColumnMetricsOrderingPreferences(userUniverseColumnOrdering, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProtocolsControllerApi - axios parameter creator
 * @export
 */
export const ProtocolsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FtpLoginDto} ftpLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftpCheck: async (ftpLoginDto: FtpLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ftpLoginDto' is not null or undefined
            assertParamExists('ftpCheck', 'ftpLoginDto', ftpLoginDto)
            const localVarPath = `/v1/protocols/ftp/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ftpLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProtocolsControllerApi - functional programming interface
 * @export
 */
export const ProtocolsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProtocolsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FtpLoginDto} ftpLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ftpCheck(ftpLoginDto: FtpLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ftpCheck(ftpLoginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProtocolsControllerApi - factory interface
 * @export
 */
export const ProtocolsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProtocolsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FtpLoginDto} ftpLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftpCheck(ftpLoginDto: FtpLoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.ftpCheck(ftpLoginDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProtocolsControllerApi - object-oriented interface
 * @export
 * @class ProtocolsControllerApi
 * @extends {BaseAPI}
 */
export class ProtocolsControllerApi extends BaseAPI {
    /**
     * 
     * @param {FtpLoginDto} ftpLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtocolsControllerApi
     */
    public ftpCheck(ftpLoginDto: FtpLoginDto, options?: AxiosRequestConfig) {
        return ProtocolsControllerApiFp(this.configuration).ftpCheck(ftpLoginDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProxyPreferencesControllerV4Api - axios parameter creator
 * @export
 */
export const ProxyPreferencesControllerV4ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user.
         * @param {ProxyPreferencesEditRequest} proxyPreferencesEditRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        editProxyPreferences: async (proxyPreferencesEditRequest: ProxyPreferencesEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proxyPreferencesEditRequest' is not null or undefined
            assertParamExists('editProxyPreferences', 'proxyPreferencesEditRequest', proxyPreferencesEditRequest)
            const localVarPath = `/v4/proxy-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proxyPreferencesEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the proxy live preferences of the user.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/proxy-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/proxy-preferences/selectable-proxies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/proxy-preferences/selectable-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/proxy-preferences/selectable-currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/proxy-preferences/selectable-micro-asset-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProxyPreferencesControllerV4Api - functional programming interface
 * @export
 */
export const ProxyPreferencesControllerV4ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProxyPreferencesControllerV4ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user.
         * @param {ProxyPreferencesEditRequest} proxyPreferencesEditRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async editProxyPreferences(proxyPreferencesEditRequest: ProxyPreferencesEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editProxyPreferences(proxyPreferencesEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the proxy live preferences of the user.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxyPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProxyPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxyPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxyPreferencesSelectableProxies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableProxiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxyPreferencesSelectableProxies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableAssetClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableAssetClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableCurrencies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currencies>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableCurrencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProxySelectableMicroAssetClasses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProxySelectableMicroAssetClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProxyPreferencesControllerV4Api - factory interface
 * @export
 */
export const ProxyPreferencesControllerV4ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProxyPreferencesControllerV4ApiFp(configuration)
    return {
        /**
         * 
         * @summary Overwrites the proxy live preferences of the user.
         * @param {ProxyPreferencesEditRequest} proxyPreferencesEditRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        editProxyPreferences(proxyPreferencesEditRequest: ProxyPreferencesEditRequest, options?: any): AxiosPromise<void> {
            return localVarFp.editProxyPreferences(proxyPreferencesEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the proxy live preferences of the user.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferences(options?: any): AxiosPromise<ProxyPreferencesResponse> {
            return localVarFp.getProxyPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of available live proxies.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxyPreferencesSelectableProxies(options?: any): AxiosPromise<SelectableProxiesResponse> {
            return localVarFp.getProxyPreferencesSelectableProxies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableAssetClasses(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableAssetClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the currencies selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableCurrencies(options?: any): AxiosPromise<Array<Currencies>> {
            return localVarFp.getProxySelectableCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the micro asset classes selectable per preference.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProxySelectableMicroAssetClasses(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getProxySelectableMicroAssetClasses(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProxyPreferencesControllerV4Api - object-oriented interface
 * @export
 * @class ProxyPreferencesControllerV4Api
 * @extends {BaseAPI}
 */
export class ProxyPreferencesControllerV4Api extends BaseAPI {
    /**
     * 
     * @summary Overwrites the proxy live preferences of the user.
     * @param {ProxyPreferencesEditRequest} proxyPreferencesEditRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public editProxyPreferences(proxyPreferencesEditRequest: ProxyPreferencesEditRequest, options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).editProxyPreferences(proxyPreferencesEditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the proxy live preferences of the user.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public getProxyPreferences(options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).getProxyPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of available live proxies.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public getProxyPreferencesSelectableProxies(options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).getProxyPreferencesSelectableProxies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public getProxySelectableAssetClasses(options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).getProxySelectableAssetClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the currencies selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public getProxySelectableCurrencies(options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).getProxySelectableCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the micro asset classes selectable per preference.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProxyPreferencesControllerV4Api
     */
    public getProxySelectableMicroAssetClasses(options?: AxiosRequestConfig) {
        return ProxyPreferencesControllerV4ApiFp(this.configuration).getProxySelectableMicroAssetClasses(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferenceUniversesControllerApi - axios parameter creator
 * @export
 */
export const ReferenceUniversesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the specified universe, if it is possible to do it (if there are not referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUniverse: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteUniverse', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a copy of the given universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateUniverse: async (uuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('duplicateUniverse', 'uuid', uuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('duplicateUniverse', 'name', name)
            const localVarPath = `/v4/reference-universes/duplicate/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modifies the specified universe with the given data.
         * @param {string} uuid 
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUniverse: async (uuid: string, editReferenceUniverseRequest: EditReferenceUniverseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('editUniverse', 'uuid', uuid)
            // verify required parameter 'editReferenceUniverseRequest' is not null or undefined
            assertParamExists('editUniverse', 'editReferenceUniverseRequest', editReferenceUniverseRequest)
            const localVarPath = `/v4/reference-universes/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReferenceUniverseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export composition for universe
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUniverseComposition: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportUniverseComposition', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/export/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export composition for universe to bbg format
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUniverseToBbgComposition: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('exportUniverseToBbgComposition', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/export/bbg/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getACL', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getAclEditStarter', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/{uuid}/acl-edit-starter`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the instruments you can use to edit the universe.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditUniverseAvailbaleInstruments: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getEditUniverseAvailbaleInstruments', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/available-instruments/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdotmUniversesTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/reference-universes/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the information of the given universe (both persisted and non persisted ones such as referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverse: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUniverse', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the composition of universe with instrument commentary
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverseComposition: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUniverseComposition', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/composition/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return side data used on the upload review screen, such as the upload composition and errors
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverseUploadReviewDetails: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUniverseUploadReviewDetails', 'uuid', uuid)
            const localVarPath = `/v4/reference-universes/upload-review-details/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL: async (uuid: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUserACL', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserACL', 'userId', userId)
            const localVarPath = `/v4/reference-universes/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the reference universes, with the data required in the list view.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUniverses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/reference-universes/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('grantUserPermission', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('grantUserPermission', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('grantUserPermission', 'permission', permission)
            const localVarPath = `/v4/reference-universes/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('hasUserPermission', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('hasUserPermission', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('hasUserPermission', 'permission', permission)
            const localVarPath = `/v4/reference-universes/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert a new universe with the full composition.
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUniverse: async (editReferenceUniverseRequest: EditReferenceUniverseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editReferenceUniverseRequest' is not null or undefined
            assertParamExists('insertUniverse', 'editReferenceUniverseRequest', editReferenceUniverseRequest)
            const localVarPath = `/v4/reference-universes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReferenceUniverseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUniverseNameAvailable: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('isUniverseNameAvailable', 'name', name)
            const localVarPath = `/v4/reference-universes/name-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameUniverse: async (uuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('renameUniverse', 'uuid', uuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameUniverse', 'name', name)
            const localVarPath = `/v4/reference-universes/rename/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission: async (uuid: string, userId: string, permission: AccessPermission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('revokeUserPermission', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('revokeUserPermission', 'userId', userId)
            // verify required parameter 'permission' is not null or undefined
            assertParamExists('revokeUserPermission', 'permission', permission)
            const localVarPath = `/v4/reference-universes/{uuid}/acl/{userId}/{permission}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"permission"}}`, encodeURIComponent(String(permission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL: async (uuid: string, richAccessControl: Array<RichAccessControl>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setACL', 'uuid', uuid)
            // verify required parameter 'richAccessControl' is not null or undefined
            assertParamExists('setACL', 'richAccessControl', richAccessControl)
            const localVarPath = `/v4/reference-universes/{uuid}/acl`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richAccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL: async (uuid: string, userId: string, accessPermission: Array<AccessPermission>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('setUserACL', 'uuid', uuid)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserACL', 'userId', userId)
            // verify required parameter 'accessPermission' is not null or undefined
            assertParamExists('setUserACL', 'accessPermission', accessPermission)
            const localVarPath = `/v4/reference-universes/{uuid}/acl/{userId}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceUniversesControllerApi - functional programming interface
 * @export
 */
export const ReferenceUniversesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceUniversesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the specified universe, if it is possible to do it (if there are not referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUniverse(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUniverse(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a copy of the given universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateUniverse(uuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceUniverseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateUniverse(uuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modifies the specified universe with the given data.
         * @param {string} uuid 
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUniverse(uuid: string, editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceUniverseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUniverse(uuid, editReferenceUniverseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export composition for universe
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportUniverseComposition(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportUniverseComposition(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export composition for universe to bbg format
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportUniverseToBbgComposition(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportUniverseToBbgComposition(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getACL(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessControl>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getACL(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAclEditStarter(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AclEditStarter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAclEditStarter(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the instruments you can use to edit the universe.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditUniverseAvailbaleInstruments(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RichTicker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditUniverseAvailbaleInstruments(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMdotmUniversesTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferenceUniverseListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMdotmUniversesTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the information of the given universe (both persisted and non persisted ones such as referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniverse(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceUniverseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniverse(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the composition of universe with instrument commentary
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniverseComposition(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UniverseCompositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniverseComposition(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return side data used on the upload review screen, such as the upload composition and errors
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniverseUploadReviewDetails(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentUploadReviewDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniverseUploadReviewDetails(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserACL(uuid: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichAccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserACL(uuid, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the reference universes, with the data required in the list view.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUniverses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferenceUniverseListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUniverses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantUserPermission(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserPermission(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Insert a new universe with the full composition.
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertUniverse(editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceUniverseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertUniverse(editReferenceUniverseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUniverseNameAvailable(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUniverseNameAvailable(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameUniverse(uuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameUniverse(uuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserPermission(uuid, userId, permission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setACL(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setACL(uuid, richAccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserACL(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserACL(uuid, userId, accessPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceUniversesControllerApi - factory interface
 * @export
 */
export const ReferenceUniversesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceUniversesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the specified universe, if it is possible to do it (if there are not referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUniverse(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUniverse(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a copy of the given universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateUniverse(uuid: string, name: string, options?: any): AxiosPromise<ReferenceUniverseDetails> {
            return localVarFp.duplicateUniverse(uuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modifies the specified universe with the given data.
         * @param {string} uuid 
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUniverse(uuid: string, editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: any): AxiosPromise<ReferenceUniverseDetails> {
            return localVarFp.editUniverse(uuid, editReferenceUniverseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export composition for universe
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUniverseComposition(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportUniverseComposition(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export composition for universe to bbg format
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUniverseToBbgComposition(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportUniverseToBbgComposition(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getACL(uuid: string, options?: any): AxiosPromise<Array<AccessControl>> {
            return localVarFp.getACL(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAclEditStarter(uuid: string, options?: any): AxiosPromise<AclEditStarter> {
            return localVarFp.getAclEditStarter(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the instruments you can use to edit the universe.
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditUniverseAvailbaleInstruments(uuid: string, options?: any): AxiosPromise<Array<RichTicker>> {
            return localVarFp.getEditUniverseAvailbaleInstruments(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdotmUniversesTemplates(options?: any): AxiosPromise<Array<ReferenceUniverseListEntry>> {
            return localVarFp.getMdotmUniversesTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the information of the given universe (both persisted and non persisted ones such as referral investments).
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverse(uuid: string, options?: any): AxiosPromise<ReferenceUniverseDetails> {
            return localVarFp.getUniverse(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the composition of universe with instrument commentary
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverseComposition(uuid: string, options?: any): AxiosPromise<UniverseCompositionResponse> {
            return localVarFp.getUniverseComposition(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return side data used on the upload review screen, such as the upload composition and errors
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniverseUploadReviewDetails(uuid: string, options?: any): AxiosPromise<InvestmentUploadReviewDetails> {
            return localVarFp.getUniverseUploadReviewDetails(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserACL(uuid: string, userId: string, options?: any): AxiosPromise<RichAccessControl> {
            return localVarFp.getUserACL(uuid, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the reference universes, with the data required in the list view.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUniverses(options?: any): AxiosPromise<Array<ReferenceUniverseListEntry>> {
            return localVarFp.getUserUniverses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.grantUserPermission(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasUserPermission(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert a new universe with the full composition.
         * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUniverse(editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: any): AxiosPromise<ReferenceUniverseDetails> {
            return localVarFp.insertUniverse(editReferenceUniverseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUniverseNameAvailable(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isUniverseNameAvailable(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks whether the user can use this name for a new universe.
         * @param {string} uuid 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameUniverse(uuid: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.renameUniverse(uuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {AccessPermission} permission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: any): AxiosPromise<void> {
            return localVarFp.revokeUserPermission(uuid, userId, permission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Array<RichAccessControl>} richAccessControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setACL(uuid: string, richAccessControl: Array<RichAccessControl>, options?: any): AxiosPromise<void> {
            return localVarFp.setACL(uuid, richAccessControl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} userId 
         * @param {Array<AccessPermission>} accessPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserACL(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: any): AxiosPromise<void> {
            return localVarFp.setUserACL(uuid, userId, accessPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceUniversesControllerApi - object-oriented interface
 * @export
 * @class ReferenceUniversesControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceUniversesControllerApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the specified universe, if it is possible to do it (if there are not referral investments).
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public deleteUniverse(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).deleteUniverse(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a copy of the given universe.
     * @param {string} uuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public duplicateUniverse(uuid: string, name: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).duplicateUniverse(uuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modifies the specified universe with the given data.
     * @param {string} uuid 
     * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public editUniverse(uuid: string, editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).editUniverse(uuid, editReferenceUniverseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export composition for universe
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public exportUniverseComposition(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).exportUniverseComposition(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export composition for universe to bbg format
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public exportUniverseToBbgComposition(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).exportUniverseToBbgComposition(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getACL(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getACL(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getAclEditStarter(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getAclEditStarter(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the instruments you can use to edit the universe.
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getEditUniverseAvailbaleInstruments(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getEditUniverseAvailbaleInstruments(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name for a new universe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getMdotmUniversesTemplates(options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getMdotmUniversesTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the information of the given universe (both persisted and non persisted ones such as referral investments).
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getUniverse(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getUniverse(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the composition of universe with instrument commentary
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getUniverseComposition(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getUniverseComposition(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return side data used on the upload review screen, such as the upload composition and errors
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getUniverseUploadReviewDetails(uuid: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getUniverseUploadReviewDetails(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getUserACL(uuid: string, userId: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getUserACL(uuid, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the reference universes, with the data required in the list view.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public getUserUniverses(options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).getUserUniverses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public grantUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).grantUserPermission(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public hasUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).hasUserPermission(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert a new universe with the full composition.
     * @param {EditReferenceUniverseRequest} editReferenceUniverseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public insertUniverse(editReferenceUniverseRequest: EditReferenceUniverseRequest, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).insertUniverse(editReferenceUniverseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name for a new universe.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public isUniverseNameAvailable(name: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).isUniverseNameAvailable(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks whether the user can use this name for a new universe.
     * @param {string} uuid 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public renameUniverse(uuid: string, name: string, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).renameUniverse(uuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {AccessPermission} permission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public revokeUserPermission(uuid: string, userId: string, permission: AccessPermission, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).revokeUserPermission(uuid, userId, permission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {Array<RichAccessControl>} richAccessControl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public setACL(uuid: string, richAccessControl: Array<RichAccessControl>, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).setACL(uuid, richAccessControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {string} userId 
     * @param {Array<AccessPermission>} accessPermission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceUniversesControllerApi
     */
    public setUserACL(uuid: string, userId: string, accessPermission: Array<AccessPermission>, options?: AxiosRequestConfig) {
        return ReferenceUniversesControllerApiFp(this.configuration).setUserACL(uuid, userId, accessPermission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScoreNamesControllerApi - axios parameter creator
 * @export
 */
export const ScoreNamesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllScoreNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/score-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scoreKey 
         * @param {string} scoreName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertScoreName: async (scoreKey: string, scoreName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scoreKey' is not null or undefined
            assertParamExists('insertScoreName', 'scoreKey', scoreKey)
            // verify required parameter 'scoreName' is not null or undefined
            assertParamExists('insertScoreName', 'scoreName', scoreName)
            const localVarPath = `/v1/score-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scoreKey !== undefined) {
                localVarQueryParameter['score-key'] = scoreKey;
            }

            if (scoreName !== undefined) {
                localVarQueryParameter['score-name'] = scoreName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScoreNamesControllerApi - functional programming interface
 * @export
 */
export const ScoreNamesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScoreNamesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllScoreNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoreNamesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllScoreNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} scoreKey 
         * @param {string} scoreName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertScoreName(scoreKey: string, scoreName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertScoreName(scoreKey, scoreName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScoreNamesControllerApi - factory interface
 * @export
 */
export const ScoreNamesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScoreNamesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllScoreNames(options?: any): AxiosPromise<ScoreNamesResponse> {
            return localVarFp.getAllScoreNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} scoreKey 
         * @param {string} scoreName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertScoreName(scoreKey: string, scoreName: string, options?: any): AxiosPromise<void> {
            return localVarFp.insertScoreName(scoreKey, scoreName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScoreNamesControllerApi - object-oriented interface
 * @export
 * @class ScoreNamesControllerApi
 * @extends {BaseAPI}
 */
export class ScoreNamesControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreNamesControllerApi
     */
    public getAllScoreNames(options?: AxiosRequestConfig) {
        return ScoreNamesControllerApiFp(this.configuration).getAllScoreNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} scoreKey 
     * @param {string} scoreName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreNamesControllerApi
     */
    public insertScoreName(scoreKey: string, scoreName: string, options?: AxiosRequestConfig) {
        return ScoreNamesControllerApiFp(this.configuration).insertScoreName(scoreKey, scoreName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateControllerApi - axios parameter creator
 * @export
 */
export const TemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewVersionOfTemplate: async (templateType: TemplateType, templateDto: TemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('createNewVersionOfTemplate', 'templateType', templateType)
            // verify required parameter 'templateDto' is not null or undefined
            assertParamExists('createNewVersionOfTemplate', 'templateDto', templateDto)
            const localVarPath = `/template/{template-type}`
                .replace(`{${"template-type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveActiveTemplateByType: async (templateType: TemplateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('retrieveActiveTemplateByType', 'templateType', templateType)
            const localVarPath = `/template/{template-type}/active`
                .replace(`{${"template-type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveActiveTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryTemplateTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveHistoryOfTemplate: async (templateType: TemplateType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('retrieveHistoryOfTemplate', 'templateType', templateType)
            const localVarPath = `/template/{template-type}/history`
                .replace(`{${"template-type"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateControllerApi - functional programming interface
 * @export
 */
export const TemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewVersionOfTemplate(templateType: TemplateType, templateDto: TemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewVersionOfTemplate(templateType, templateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveActiveTemplateByType(templateType: TemplateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveActiveTemplateByType(templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveActiveTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveActiveTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCommentaryTemplateTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCommentaryTemplateTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveHistoryOfTemplate(templateType: TemplateType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveHistoryOfTemplate(templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateControllerApi - factory interface
 * @export
 */
export const TemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewVersionOfTemplate(templateType: TemplateType, templateDto: TemplateDto, options?: any): AxiosPromise<void> {
            return localVarFp.createNewVersionOfTemplate(templateType, templateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveActiveTemplateByType(templateType: TemplateType, options?: any): AxiosPromise<string> {
            return localVarFp.retrieveActiveTemplateByType(templateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveActiveTemplates(options?: any): AxiosPromise<Array<TemplateModel>> {
            return localVarFp.retrieveActiveTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCommentaryTemplateTypes(options?: any): AxiosPromise<Array<TemplateType>> {
            return localVarFp.retrieveCommentaryTemplateTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TemplateType} templateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveHistoryOfTemplate(templateType: TemplateType, options?: any): AxiosPromise<Array<TemplateModel>> {
            return localVarFp.retrieveHistoryOfTemplate(templateType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateControllerApi - object-oriented interface
 * @export
 * @class TemplateControllerApi
 * @extends {BaseAPI}
 */
export class TemplateControllerApi extends BaseAPI {
    /**
     * 
     * @param {TemplateType} templateType 
     * @param {TemplateDto} templateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public createNewVersionOfTemplate(templateType: TemplateType, templateDto: TemplateDto, options?: AxiosRequestConfig) {
        return TemplateControllerApiFp(this.configuration).createNewVersionOfTemplate(templateType, templateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TemplateType} templateType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public retrieveActiveTemplateByType(templateType: TemplateType, options?: AxiosRequestConfig) {
        return TemplateControllerApiFp(this.configuration).retrieveActiveTemplateByType(templateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public retrieveActiveTemplates(options?: AxiosRequestConfig) {
        return TemplateControllerApiFp(this.configuration).retrieveActiveTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public retrieveCommentaryTemplateTypes(options?: AxiosRequestConfig) {
        return TemplateControllerApiFp(this.configuration).retrieveCommentaryTemplateTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TemplateType} templateType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public retrieveHistoryOfTemplate(templateType: TemplateType, options?: AxiosRequestConfig) {
        return TemplateControllerApiFp(this.configuration).retrieveHistoryOfTemplate(templateType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateControllerApi - axios parameter creator
 * @export
 */
export const UpdateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Initializes the standard benchmarks taking them from gateway.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeBenchmarks: async (xApiKey: string, benchmarkReports: BenchmarkReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('initializeBenchmarks', 'xApiKey', xApiKey)
            // verify required parameter 'benchmarkReports' is not null or undefined
            assertParamExists('initializeBenchmarks', 'benchmarkReports', benchmarkReports)
            const localVarPath = `/v3/update/benchmarks/initialize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveEntityError: async (xApiKey: string, gatewayEntityError: GatewayEntityError, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('receiveEntityError', 'xApiKey', xApiKey)
            // verify required parameter 'gatewayEntityError' is not null or undefined
            assertParamExists('receiveEntityError', 'gatewayEntityError', gatewayEntityError)
            const localVarPath = `/v3/update/investments/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayEntityError, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {UploadRetrieveDataResponse} uploadRetrieveDataResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntityReview: async (xApiKey: string, uploadRetrieveDataResponse: UploadRetrieveDataResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setEntityReview', 'xApiKey', xApiKey)
            // verify required parameter 'uploadRetrieveDataResponse' is not null or undefined
            assertParamExists('setEntityReview', 'uploadRetrieveDataResponse', uploadRetrieveDataResponse)
            const localVarPath = `/v3/update/entity/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRetrieveDataResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports: async (xApiKey: string, reportDto: ReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setHmmReports', 'xApiKey', xApiKey)
            // verify required parameter 'reportDto' is not null or undefined
            assertParamExists('setHmmReports', 'reportDto', reportDto)
            const localVarPath = `/v3/update/hmm/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Response on constraints validation
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationResponse} investmentAllocationConstraintsValidationResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationAllocationConstraintValidationResponse: async (xApiKey: string, investmentAllocationConstraintsValidationResponse: InvestmentAllocationConstraintsValidationResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setInvestmentConfigurationAllocationConstraintValidationResponse', 'xApiKey', xApiKey)
            // verify required parameter 'investmentAllocationConstraintsValidationResponse' is not null or undefined
            assertParamExists('setInvestmentConfigurationAllocationConstraintValidationResponse', 'investmentAllocationConstraintsValidationResponse', investmentAllocationConstraintsValidationResponse)
            const localVarPath = `/v3/update/investments/configuration/allocation-constraints-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentAllocationConstraintsValidationResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Error response on constraint validation (should launch the event).
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationErrorResponse} investmentAllocationConstraintsValidationErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationAllocationConstraintValidationResponseError: async (xApiKey: string, investmentAllocationConstraintsValidationErrorResponse: InvestmentAllocationConstraintsValidationErrorResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setInvestmentConfigurationAllocationConstraintValidationResponseError', 'xApiKey', xApiKey)
            // verify required parameter 'investmentAllocationConstraintsValidationErrorResponse' is not null or undefined
            assertParamExists('setInvestmentConfigurationAllocationConstraintValidationResponseError', 'investmentAllocationConstraintsValidationErrorResponse', investmentAllocationConstraintsValidationErrorResponse)
            const localVarPath = `/v3/update/investments/configuration/allocation-constraints-validation/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentAllocationConstraintsValidationErrorResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Risk constraints prefills.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsResponse} investmentRiskConstraintsObtainBandsResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationRiskConstraintsBandsResponse: async (xApiKey: string, investmentRiskConstraintsObtainBandsResponse: InvestmentRiskConstraintsObtainBandsResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setInvestmentConfigurationRiskConstraintsBandsResponse', 'xApiKey', xApiKey)
            // verify required parameter 'investmentRiskConstraintsObtainBandsResponse' is not null or undefined
            assertParamExists('setInvestmentConfigurationRiskConstraintsBandsResponse', 'investmentRiskConstraintsObtainBandsResponse', investmentRiskConstraintsObtainBandsResponse)
            const localVarPath = `/v3/update/investments/configuration/risk-constraints-bands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentRiskConstraintsObtainBandsResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Risk constraints prefills error.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsErrorResponse} investmentRiskConstraintsObtainBandsErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationRiskConstraintsBandsResponseError: async (xApiKey: string, investmentRiskConstraintsObtainBandsErrorResponse: InvestmentRiskConstraintsObtainBandsErrorResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setInvestmentConfigurationRiskConstraintsBandsResponseError', 'xApiKey', xApiKey)
            // verify required parameter 'investmentRiskConstraintsObtainBandsErrorResponse' is not null or undefined
            assertParamExists('setInvestmentConfigurationRiskConstraintsBandsResponseError', 'investmentRiskConstraintsObtainBandsErrorResponse', investmentRiskConstraintsObtainBandsErrorResponse)
            const localVarPath = `/v3/update/investments/configuration/risk-constraints-bands/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentRiskConstraintsObtainBandsErrorResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReports: async (xApiKey: string, marketReportDTO: MarketReportDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setMarketReports', 'xApiKey', xApiKey)
            // verify required parameter 'marketReportDTO' is not null or undefined
            assertParamExists('setMarketReports', 'marketReportDTO', marketReportDTO)
            const localVarPath = `/v3/update/market/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketReportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {UpdateUserProxyPreferencesResponse} updateUserProxyPreferencesResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPreferences: async (xApiKey: string, updateUserProxyPreferencesResponse: UpdateUserProxyPreferencesResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('setUserPreferences', 'xApiKey', xApiKey)
            // verify required parameter 'updateUserProxyPreferencesResponse' is not null or undefined
            assertParamExists('setUserPreferences', 'updateUserProxyPreferencesResponse', updateUserProxyPreferencesResponse)
            const localVarPath = `/v3/update/user-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProxyPreferencesResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkAsyncErrors: async (xApiKey: string, gatewayEntityError: GatewayEntityError, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateBenchmarkAsyncErrors', 'xApiKey', xApiKey)
            // verify required parameter 'gatewayEntityError' is not null or undefined
            assertParamExists('updateBenchmarkAsyncErrors', 'gatewayEntityError', gatewayEntityError)
            const localVarPath = `/v3/update/benchmarks/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayEntityError, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports after an edit.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkEditReports: async (xApiKey: string, benchmarkReports: BenchmarkReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateBenchmarkEditReports', 'xApiKey', xApiKey)
            // verify required parameter 'benchmarkReports' is not null or undefined
            assertParamExists('updateBenchmarkEditReports', 'benchmarkReports', benchmarkReports)
            const localVarPath = `/v3/update/benchmarks/edit/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkReports: async (xApiKey: string, benchmarkReports: BenchmarkReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateBenchmarkReports', 'xApiKey', xApiKey)
            // verify required parameter 'benchmarkReports' is not null or undefined
            assertParamExists('updateBenchmarkReports', 'benchmarkReports', benchmarkReports)
            const localVarPath = `/v3/update/benchmarks/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports following an edit enhance.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditInvestmentReports: async (xApiKey: string, investmentReports: InvestmentReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateEditInvestmentReports', 'xApiKey', xApiKey)
            // verify required parameter 'investmentReports' is not null or undefined
            assertParamExists('updateEditInvestmentReports', 'investmentReports', investmentReports)
            const localVarPath = `/v3/update/investments/edit/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnhancementInvestmentReports: async (xApiKey: string, investmentReports: InvestmentReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateEnhancementInvestmentReports', 'xApiKey', xApiKey)
            // verify required parameter 'investmentReports' is not null or undefined
            assertParamExists('updateEnhancementInvestmentReports', 'investmentReports', investmentReports)
            const localVarPath = `/v3/update/investments/enhancement/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentFinalizeClone: async (investmentUuid: string, xApiKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentUuid' is not null or undefined
            assertParamExists('updateInvestmentFinalizeClone', 'investmentUuid', investmentUuid)
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateInvestmentFinalizeClone', 'xApiKey', xApiKey)
            const localVarPath = `/v3/update/investments/clone/{investment-uuid}`
                .replace(`{${"investment-uuid"}}`, encodeURIComponent(String(investmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditEnhancementResponse} investmentProxyLiveEditEnhancementResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditEnhanceReports: async (xApiKey: string, investmentProxyLiveEditEnhancementResponse: InvestmentProxyLiveEditEnhancementResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditEnhanceReports', 'xApiKey', xApiKey)
            // verify required parameter 'investmentProxyLiveEditEnhancementResponse' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditEnhanceReports', 'investmentProxyLiveEditEnhancementResponse', investmentProxyLiveEditEnhancementResponse)
            const localVarPath = `/v3/update/investments/proxy-live-edit/enhancement/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentProxyLiveEditEnhancementResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditErrorResponse} investmentProxyLiveEditErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditError: async (xApiKey: string, investmentProxyLiveEditErrorResponse: InvestmentProxyLiveEditErrorResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditError', 'xApiKey', xApiKey)
            // verify required parameter 'investmentProxyLiveEditErrorResponse' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditError', 'investmentProxyLiveEditErrorResponse', investmentProxyLiveEditErrorResponse)
            const localVarPath = `/v3/update/investments/proxy-live-edit/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentProxyLiveEditErrorResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditResponse} investmentProxyLiveEditResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditReports: async (xApiKey: string, investmentProxyLiveEditResponse: InvestmentProxyLiveEditResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditReports', 'xApiKey', xApiKey)
            // verify required parameter 'investmentProxyLiveEditResponse' is not null or undefined
            assertParamExists('updateInvestmentProxyLiveEditReports', 'investmentProxyLiveEditResponse', investmentProxyLiveEditResponse)
            const localVarPath = `/v3/update/investments/proxy-live-edit/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentProxyLiveEditResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentReports: async (xApiKey: string, investmentReports: InvestmentReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateInvestmentReports', 'xApiKey', xApiKey)
            // verify required parameter 'investmentReports' is not null or undefined
            assertParamExists('updateInvestmentReports', 'investmentReports', investmentReports)
            const localVarPath = `/v3/update/investments/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(investmentReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniverseAsyncErrors: async (xApiKey: string, gatewayEntityError: GatewayEntityError, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateUniverseAsyncErrors', 'xApiKey', xApiKey)
            // verify required parameter 'gatewayEntityError' is not null or undefined
            assertParamExists('updateUniverseAsyncErrors', 'gatewayEntityError', gatewayEntityError)
            const localVarPath = `/v3/update/reference-universes/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayEntityError, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives from the Gateway the Updated composition of the universe, modifying the universe consequentially.
         * @param {string} xApiKey 
         * @param {UpdateUniverseCompositionRequest} updateUniverseCompositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniverseComposition: async (xApiKey: string, updateUniverseCompositionRequest: UpdateUniverseCompositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updateUniverseComposition', 'xApiKey', xApiKey)
            // verify required parameter 'updateUniverseCompositionRequest' is not null or undefined
            assertParamExists('updateUniverseComposition', 'updateUniverseCompositionRequest', updateUniverseCompositionRequest)
            const localVarPath = `/v3/update/reference-universes/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-api-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUniverseCompositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateControllerApi - functional programming interface
 * @export
 */
export const UpdateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Initializes the standard benchmarks taking them from gateway.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeBenchmarks(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeBenchmarks(xApiKey, benchmarkReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveEntityError(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveEntityError(xApiKey, gatewayEntityError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {UploadRetrieveDataResponse} uploadRetrieveDataResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEntityReview(xApiKey: string, uploadRetrieveDataResponse: UploadRetrieveDataResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEntityReview(xApiKey, uploadRetrieveDataResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHmmReports(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHmmReports(xApiKey, reportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Response on constraints validation
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationResponse} investmentAllocationConstraintsValidationResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey: string, investmentAllocationConstraintsValidationResponse: InvestmentAllocationConstraintsValidationResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey, investmentAllocationConstraintsValidationResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Error response on constraint validation (should launch the event).
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationErrorResponse} investmentAllocationConstraintsValidationErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey: string, investmentAllocationConstraintsValidationErrorResponse: InvestmentAllocationConstraintsValidationErrorResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey, investmentAllocationConstraintsValidationErrorResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Risk constraints prefills.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsResponse} investmentRiskConstraintsObtainBandsResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey: string, investmentRiskConstraintsObtainBandsResponse: InvestmentRiskConstraintsObtainBandsResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey, investmentRiskConstraintsObtainBandsResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Risk constraints prefills error.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsErrorResponse} investmentRiskConstraintsObtainBandsErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey: string, investmentRiskConstraintsObtainBandsErrorResponse: InvestmentRiskConstraintsObtainBandsErrorResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey, investmentRiskConstraintsObtainBandsErrorResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMarketReports(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMarketReports(xApiKey, marketReportDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {UpdateUserProxyPreferencesResponse} updateUserProxyPreferencesResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPreferences(xApiKey: string, updateUserProxyPreferencesResponse: UpdateUserProxyPreferencesResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPreferences(xApiKey, updateUserProxyPreferencesResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchmarkAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchmarkAsyncErrors(xApiKey, gatewayEntityError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports after an edit.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchmarkEditReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchmarkEditReports(xApiKey, benchmarkReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchmarkReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchmarkReports(xApiKey, benchmarkReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports following an edit enhance.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEditInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEditInvestmentReports(xApiKey, investmentReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnhancementInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnhancementInvestmentReports(xApiKey, investmentReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentFinalizeClone(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentFinalizeClone(investmentUuid, xApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditEnhancementResponse} investmentProxyLiveEditEnhancementResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentProxyLiveEditEnhanceReports(xApiKey: string, investmentProxyLiveEditEnhancementResponse: InvestmentProxyLiveEditEnhancementResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentProxyLiveEditEnhanceReports(xApiKey, investmentProxyLiveEditEnhancementResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditErrorResponse} investmentProxyLiveEditErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentProxyLiveEditError(xApiKey: string, investmentProxyLiveEditErrorResponse: InvestmentProxyLiveEditErrorResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentProxyLiveEditError(xApiKey, investmentProxyLiveEditErrorResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditResponse} investmentProxyLiveEditResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentProxyLiveEditReports(xApiKey: string, investmentProxyLiveEditResponse: InvestmentProxyLiveEditResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentProxyLiveEditReports(xApiKey, investmentProxyLiveEditResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestmentReports(xApiKey, investmentReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUniverseAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUniverseAsyncErrors(xApiKey, gatewayEntityError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receives from the Gateway the Updated composition of the universe, modifying the universe consequentially.
         * @param {string} xApiKey 
         * @param {UpdateUniverseCompositionRequest} updateUniverseCompositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUniverseComposition(xApiKey: string, updateUniverseCompositionRequest: UpdateUniverseCompositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUniverseComposition(xApiKey, updateUniverseCompositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateControllerApi - factory interface
 * @export
 */
export const UpdateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Initializes the standard benchmarks taking them from gateway.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeBenchmarks(xApiKey: string, benchmarkReports: BenchmarkReports, options?: any): AxiosPromise<void> {
            return localVarFp.initializeBenchmarks(xApiKey, benchmarkReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveEntityError(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: any): AxiosPromise<void> {
            return localVarFp.receiveEntityError(xApiKey, gatewayEntityError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {UploadRetrieveDataResponse} uploadRetrieveDataResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEntityReview(xApiKey: string, uploadRetrieveDataResponse: UploadRetrieveDataResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setEntityReview(xApiKey, uploadRetrieveDataResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {ReportDto} reportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHmmReports(xApiKey: string, reportDto: ReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.setHmmReports(xApiKey, reportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Response on constraints validation
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationResponse} investmentAllocationConstraintsValidationResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey: string, investmentAllocationConstraintsValidationResponse: InvestmentAllocationConstraintsValidationResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey, investmentAllocationConstraintsValidationResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Error response on constraint validation (should launch the event).
         * @param {string} xApiKey 
         * @param {InvestmentAllocationConstraintsValidationErrorResponse} investmentAllocationConstraintsValidationErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey: string, investmentAllocationConstraintsValidationErrorResponse: InvestmentAllocationConstraintsValidationErrorResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey, investmentAllocationConstraintsValidationErrorResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Risk constraints prefills.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsResponse} investmentRiskConstraintsObtainBandsResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey: string, investmentRiskConstraintsObtainBandsResponse: InvestmentRiskConstraintsObtainBandsResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey, investmentRiskConstraintsObtainBandsResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Risk constraints prefills error.
         * @param {string} xApiKey 
         * @param {InvestmentRiskConstraintsObtainBandsErrorResponse} investmentRiskConstraintsObtainBandsErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey: string, investmentRiskConstraintsObtainBandsErrorResponse: InvestmentRiskConstraintsObtainBandsErrorResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey, investmentRiskConstraintsObtainBandsErrorResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json to create or update the entire data related to markets.
         * @param {string} xApiKey 
         * @param {MarketReportDTO} marketReportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMarketReports(xApiKey: string, marketReportDTO: MarketReportDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setMarketReports(xApiKey, marketReportDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
         * @param {string} xApiKey 
         * @param {UpdateUserProxyPreferencesResponse} updateUserProxyPreferencesResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPreferences(xApiKey: string, updateUserProxyPreferencesResponse: UpdateUserProxyPreferencesResponse, options?: any): AxiosPromise<void> {
            return localVarFp.setUserPreferences(xApiKey, updateUserProxyPreferencesResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenchmarkAsyncErrors(xApiKey, gatewayEntityError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports after an edit.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkEditReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenchmarkEditReports(xApiKey, benchmarkReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Benchmark reports.
         * @param {string} xApiKey 
         * @param {BenchmarkReports} benchmarkReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenchmarkReports(xApiKey, benchmarkReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports following an edit enhance.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEditInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: any): AxiosPromise<void> {
            return localVarFp.updateEditInvestmentReports(xApiKey, investmentReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnhancementInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: any): AxiosPromise<void> {
            return localVarFp.updateEnhancementInvestmentReports(xApiKey, investmentReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} investmentUuid 
         * @param {string} xApiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentFinalizeClone(investmentUuid: string, xApiKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateInvestmentFinalizeClone(investmentUuid, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditEnhancementResponse} investmentProxyLiveEditEnhancementResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditEnhanceReports(xApiKey: string, investmentProxyLiveEditEnhancementResponse: InvestmentProxyLiveEditEnhancementResponse, options?: any): AxiosPromise<void> {
            return localVarFp.updateInvestmentProxyLiveEditEnhanceReports(xApiKey, investmentProxyLiveEditEnhancementResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditErrorResponse} investmentProxyLiveEditErrorResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditError(xApiKey: string, investmentProxyLiveEditErrorResponse: InvestmentProxyLiveEditErrorResponse, options?: any): AxiosPromise<void> {
            return localVarFp.updateInvestmentProxyLiveEditError(xApiKey, investmentProxyLiveEditErrorResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
         * @param {string} xApiKey 
         * @param {InvestmentProxyLiveEditResponse} investmentProxyLiveEditResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentProxyLiveEditReports(xApiKey: string, investmentProxyLiveEditResponse: InvestmentProxyLiveEditResponse, options?: any): AxiosPromise<void> {
            return localVarFp.updateInvestmentProxyLiveEditReports(xApiKey, investmentProxyLiveEditResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the daily Investment reports.
         * @param {string} xApiKey 
         * @param {InvestmentReports} investmentReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: any): AxiosPromise<void> {
            return localVarFp.updateInvestmentReports(xApiKey, investmentReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway an error on the execution generated asynchronously.
         * @param {string} xApiKey 
         * @param {GatewayEntityError} gatewayEntityError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniverseAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: any): AxiosPromise<void> {
            return localVarFp.updateUniverseAsyncErrors(xApiKey, gatewayEntityError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives from the Gateway the Updated composition of the universe, modifying the universe consequentially.
         * @param {string} xApiKey 
         * @param {UpdateUniverseCompositionRequest} updateUniverseCompositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUniverseComposition(xApiKey: string, updateUniverseCompositionRequest: UpdateUniverseCompositionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUniverseComposition(xApiKey, updateUniverseCompositionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateControllerApi - object-oriented interface
 * @export
 * @class UpdateControllerApi
 * @extends {BaseAPI}
 */
export class UpdateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Initializes the standard benchmarks taking them from gateway.
     * @param {string} xApiKey 
     * @param {BenchmarkReports} benchmarkReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public initializeBenchmarks(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).initializeBenchmarks(xApiKey, benchmarkReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway an error on the execution generated asynchronously.
     * @param {string} xApiKey 
     * @param {GatewayEntityError} gatewayEntityError 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public receiveEntityError(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).receiveEntityError(xApiKey, gatewayEntityError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
     * @param {string} xApiKey 
     * @param {UploadRetrieveDataResponse} uploadRetrieveDataResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setEntityReview(xApiKey: string, uploadRetrieveDataResponse: UploadRetrieveDataResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setEntityReview(xApiKey, uploadRetrieveDataResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
     * @param {string} xApiKey 
     * @param {ReportDto} reportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setHmmReports(xApiKey: string, reportDto: ReportDto, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setHmmReports(xApiKey, reportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Response on constraints validation
     * @param {string} xApiKey 
     * @param {InvestmentAllocationConstraintsValidationResponse} investmentAllocationConstraintsValidationResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey: string, investmentAllocationConstraintsValidationResponse: InvestmentAllocationConstraintsValidationResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setInvestmentConfigurationAllocationConstraintValidationResponse(xApiKey, investmentAllocationConstraintsValidationResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Error response on constraint validation (should launch the event).
     * @param {string} xApiKey 
     * @param {InvestmentAllocationConstraintsValidationErrorResponse} investmentAllocationConstraintsValidationErrorResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey: string, investmentAllocationConstraintsValidationErrorResponse: InvestmentAllocationConstraintsValidationErrorResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setInvestmentConfigurationAllocationConstraintValidationResponseError(xApiKey, investmentAllocationConstraintsValidationErrorResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Risk constraints prefills.
     * @param {string} xApiKey 
     * @param {InvestmentRiskConstraintsObtainBandsResponse} investmentRiskConstraintsObtainBandsResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey: string, investmentRiskConstraintsObtainBandsResponse: InvestmentRiskConstraintsObtainBandsResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setInvestmentConfigurationRiskConstraintsBandsResponse(xApiKey, investmentRiskConstraintsObtainBandsResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Risk constraints prefills error.
     * @param {string} xApiKey 
     * @param {InvestmentRiskConstraintsObtainBandsErrorResponse} investmentRiskConstraintsObtainBandsErrorResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey: string, investmentRiskConstraintsObtainBandsErrorResponse: InvestmentRiskConstraintsObtainBandsErrorResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setInvestmentConfigurationRiskConstraintsBandsResponseError(xApiKey, investmentRiskConstraintsObtainBandsErrorResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json to create or update the entire data related to markets.
     * @param {string} xApiKey 
     * @param {MarketReportDTO} marketReportDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setMarketReports(xApiKey: string, marketReportDTO: MarketReportDTO, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setMarketReports(xApiKey, marketReportDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api allow to upload a file .json (key \"hmm\") to create or update the entire data related to hmm.
     * @param {string} xApiKey 
     * @param {UpdateUserProxyPreferencesResponse} updateUserProxyPreferencesResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public setUserPreferences(xApiKey: string, updateUserProxyPreferencesResponse: UpdateUserProxyPreferencesResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).setUserPreferences(xApiKey, updateUserProxyPreferencesResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway an error on the execution generated asynchronously.
     * @param {string} xApiKey 
     * @param {GatewayEntityError} gatewayEntityError 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateBenchmarkAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateBenchmarkAsyncErrors(xApiKey, gatewayEntityError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the daily Benchmark reports after an edit.
     * @param {string} xApiKey 
     * @param {BenchmarkReports} benchmarkReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateBenchmarkEditReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateBenchmarkEditReports(xApiKey, benchmarkReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the daily Benchmark reports.
     * @param {string} xApiKey 
     * @param {BenchmarkReports} benchmarkReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateBenchmarkReports(xApiKey: string, benchmarkReports: BenchmarkReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateBenchmarkReports(xApiKey, benchmarkReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the Investment reports following an edit enhance.
     * @param {string} xApiKey 
     * @param {InvestmentReports} investmentReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateEditInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateEditInvestmentReports(xApiKey, investmentReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the Investment reports linked to the enhance, modifying the portfolio status consequentially.
     * @param {string} xApiKey 
     * @param {InvestmentReports} investmentReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateEnhancementInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateEnhancementInvestmentReports(xApiKey, investmentReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the daily Investment reports.
     * @param {string} investmentUuid 
     * @param {string} xApiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateInvestmentFinalizeClone(investmentUuid: string, xApiKey: string, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateInvestmentFinalizeClone(investmentUuid, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
     * @param {string} xApiKey 
     * @param {InvestmentProxyLiveEditEnhancementResponse} investmentProxyLiveEditEnhancementResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateInvestmentProxyLiveEditEnhanceReports(xApiKey: string, investmentProxyLiveEditEnhancementResponse: InvestmentProxyLiveEditEnhancementResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateInvestmentProxyLiveEditEnhanceReports(xApiKey, investmentProxyLiveEditEnhancementResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
     * @param {string} xApiKey 
     * @param {InvestmentProxyLiveEditErrorResponse} investmentProxyLiveEditErrorResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateInvestmentProxyLiveEditError(xApiKey: string, investmentProxyLiveEditErrorResponse: InvestmentProxyLiveEditErrorResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateInvestmentProxyLiveEditError(xApiKey, investmentProxyLiveEditErrorResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the response in case of a regular proxy live edit, that updates reports, dequeue the linked operation, eventually launches the final notification mail.
     * @param {string} xApiKey 
     * @param {InvestmentProxyLiveEditResponse} investmentProxyLiveEditResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateInvestmentProxyLiveEditReports(xApiKey: string, investmentProxyLiveEditResponse: InvestmentProxyLiveEditResponse, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateInvestmentProxyLiveEditReports(xApiKey, investmentProxyLiveEditResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the daily Investment reports.
     * @param {string} xApiKey 
     * @param {InvestmentReports} investmentReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateInvestmentReports(xApiKey: string, investmentReports: InvestmentReports, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateInvestmentReports(xApiKey, investmentReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway an error on the execution generated asynchronously.
     * @param {string} xApiKey 
     * @param {GatewayEntityError} gatewayEntityError 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateUniverseAsyncErrors(xApiKey: string, gatewayEntityError: GatewayEntityError, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateUniverseAsyncErrors(xApiKey, gatewayEntityError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives from the Gateway the Updated composition of the universe, modifying the universe consequentially.
     * @param {string} xApiKey 
     * @param {UpdateUniverseCompositionRequest} updateUniverseCompositionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateControllerApi
     */
    public updateUniverseComposition(xApiKey: string, updateUniverseCompositionRequest: UpdateUniverseCompositionRequest, options?: AxiosRequestConfig) {
        return UpdateControllerApiFp(this.configuration).updateUniverseComposition(xApiKey, updateUniverseCompositionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCustomer: async (customer: Customer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createCustomer', 'customer', customer)
            const localVarPath = `/v2/users/create/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new user. (ADMIN)
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createUser', 'user', user)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get custom data for customer pdf report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customReportData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/custom-report-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Impersonate a user. (ROOT)
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('impersonateUser', 'email', email)
            const localVarPath = `/v2/users/impersonate/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all customers. (ROOT)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listAllCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/list/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all users. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUser: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('updateUser', 'updateUser', updateUser)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createCustomer(customer: Customer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new user. (ADMIN)
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get custom data for customer pdf report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customReportData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomReportDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customReportData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Impersonate a user. (ROOT)
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateUser(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateUser(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all customers. (ROOT)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listAllCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllCustomer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all users. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUser: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer. (ROOT)
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createCustomer(customer: Customer, options?: any): AxiosPromise<CustomerModel> {
            return localVarFp.createCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new user. (ADMIN)
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user: User, options?: any): AxiosPromise<UserModel> {
            return localVarFp.createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get custom data for customer pdf report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customReportData(options?: any): AxiosPromise<CustomReportDataDTO> {
            return localVarFp.customReportData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Impersonate a user. (ROOT)
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser(email: string, options?: any): AxiosPromise<JwtResponse> {
            return localVarFp.impersonateUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoUser(options?: any): AxiosPromise<UserWithCustomerDto> {
            return localVarFp.infoUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all customers. (ROOT)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listAllCustomer(options?: any): AxiosPromise<Array<CustomerModel>> {
            return localVarFp.listAllCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all users. (ROOT)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsers(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.listAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUser: UpdateUser, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userId, updateUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create new customer. (ROOT)
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createCustomer(customer: Customer, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new user. (ADMIN)
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createUser(user: User, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get custom data for customer pdf report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public customReportData(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).customReportData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Impersonate a user. (ROOT)
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public impersonateUser(email: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).impersonateUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public infoUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).infoUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all customers. (ROOT)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public listAllCustomer(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).listAllCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all users. (ROOT)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public listAllUsers(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).listAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUser} updateUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUser(userId: string, updateUser: UpdateUser, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUser(userId, updateUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserNotificationControllerV2Api - axios parameter creator
 * @export
 */
export const UserNotificationControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert: async (name: string, enableAlertDto: EnableAlertDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addAlert', 'name', name)
            // verify required parameter 'enableAlertDto' is not null or undefined
            assertParamExists('addAlert', 'enableAlertDto', enableAlertDto)
            const localVarPath = `/v2/user/alert/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableAlertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReadNotification: async (identifier: string, body: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('changeReadNotification', 'identifier', identifier)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changeReadNotification', 'body', body)
            const localVarPath = `/v2/user/notification/{identifier}/read`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReadNotification1: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('changeReadNotification1', 'requestBody', requestBody)
            const localVarPath = `/v2/user/notification/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteAlert', 'name', name)
            const localVarPath = `/v2/user/alert/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: async (identifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('deleteNotification', 'identifier', identifier)
            const localVarPath = `/v2/user/notification/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications: async (notificationIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationIds' is not null or undefined
            assertParamExists('deleteNotifications', 'notificationIds', notificationIds)
            const localVarPath = `/v2/user/notification/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationIds) {
                localVarQueryParameter['notificationIds'] = notificationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAlerts: async (enableAlertDto: Array<EnableAlertDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableAlertDto' is not null or undefined
            assertParamExists('editAlerts', 'enableAlertDto', enableAlertDto)
            const localVarPath = `/v2/user/alert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableAlertDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/notification/read/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserAlert: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/alert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserMarketNotificationsFromPool: async (pool?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/notification/market`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pool !== undefined) {
                localVarQueryParameter['pool'] = pool;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserPortfolioNotificationsFromPool: async (pool?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/user/notification/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication mdotm-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pool !== undefined) {
                localVarQueryParameter['pool'] = pool;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserNotificationControllerV2Api - functional programming interface
 * @export
 */
export const UserNotificationControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserNotificationControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAlert(name: string, enableAlertDto: EnableAlertDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAlert(name, enableAlertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeReadNotification(identifier: string, body: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeReadNotification(identifier, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeReadNotification1(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeReadNotification1(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlert(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlert(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotification(identifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotification(identifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotifications(notificationIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotifications(notificationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAlerts(enableAlertDto: Array<EnableAlertDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editAlerts(enableAlertDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllUserAlert(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAlertDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllUserAlert(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllUserMarketNotificationsFromPool(pool?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericPoolListNotificationGroupedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllUserMarketNotificationsFromPool(pool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllUserPortfolioNotificationsFromPool(pool?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericPoolListNotificationGroupedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllUserPortfolioNotificationsFromPool(pool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserNotificationControllerV2Api - factory interface
 * @export
 */
export const UserNotificationControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserNotificationControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {EnableAlertDto} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert(name: string, enableAlertDto: EnableAlertDto, options?: any): AxiosPromise<string> {
            return localVarFp.addAlert(name, enableAlertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} identifier 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReadNotification(identifier: string, body: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.changeReadNotification(identifier, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeReadNotification1(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.changeReadNotification1(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAlert(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification(identifier: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotification(identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(notificationIds: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotifications(notificationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<EnableAlertDto>} enableAlertDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAlerts(enableAlertDto: Array<EnableAlertDto>, options?: any): AxiosPromise<string> {
            return localVarFp.editAlerts(enableAlertDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications(options?: any): AxiosPromise<void> {
            return localVarFp.readAllNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all alerts of system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserAlert(options?: any): AxiosPromise<UserAlertDto> {
            return localVarFp.retrieveAllUserAlert(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserMarketNotificationsFromPool(pool?: number, options?: any): AxiosPromise<GenericPoolListNotificationGroupedDTO> {
            return localVarFp.retrieveAllUserMarketNotificationsFromPool(pool, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pool] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllUserPortfolioNotificationsFromPool(pool?: number, options?: any): AxiosPromise<GenericPoolListNotificationGroupedDTO> {
            return localVarFp.retrieveAllUserPortfolioNotificationsFromPool(pool, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserNotificationControllerV2Api - object-oriented interface
 * @export
 * @class UserNotificationControllerV2Api
 * @extends {BaseAPI}
 */
export class UserNotificationControllerV2Api extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {EnableAlertDto} enableAlertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public addAlert(name: string, enableAlertDto: EnableAlertDto, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).addAlert(name, enableAlertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} identifier 
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public changeReadNotification(identifier: string, body: boolean, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).changeReadNotification(identifier, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public changeReadNotification1(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).changeReadNotification1(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public deleteAlert(name: string, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).deleteAlert(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public deleteNotification(identifier: string, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).deleteNotification(identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} notificationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public deleteNotifications(notificationIds: Array<string>, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).deleteNotifications(notificationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<EnableAlertDto>} enableAlertDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public editAlerts(enableAlertDto: Array<EnableAlertDto>, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).editAlerts(enableAlertDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public readAllNotifications(options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).readAllNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all alerts of system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public retrieveAllUserAlert(options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).retrieveAllUserAlert(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pool] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public retrieveAllUserMarketNotificationsFromPool(pool?: number, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).retrieveAllUserMarketNotificationsFromPool(pool, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pool] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationControllerV2Api
     */
    public retrieveAllUserPortfolioNotificationsFromPool(pool?: number, options?: AxiosRequestConfig) {
        return UserNotificationControllerV2ApiFp(this.configuration).retrieveAllUserPortfolioNotificationsFromPool(pool, options).then((request) => request(this.axios, this.basePath));
    }
}


