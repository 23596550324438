import { useLocaleFormatters } from "$root/localization/hooks";
import { PaletteColors } from "$root/styles/themePalette";
import type { IconName } from "@mdotm/mdotui/components";
import { Icon } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";

export function InfoDelta({ diff, enh }: { diff: number; enh: number }): JSX.Element {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const state:
		| {
				type: "increased";
				text: string;
		  }
		| {
				type: "removed";
				text: string;
		  }
		| {
				type: "reduced";
				text: string;
		  }
		| null =
		diff > 0
			? {
					type: "increased",
					text: `+${formatNumber(diff, 2)}%`,
			  }
			: diff < 0 && enh === 0
			  ? {
						type: "removed",
						text: t("EDIT_COMPOSITION.TABLE.REMOVED"),
			    }
			  : diff < 0
			    ? {
							type: "reduced",
							text: `${formatNumber(diff)}%`,
			      }
			    : null;

	const typeToIcon: Record<NonNullable<typeof state>["type"], { icon: IconName; color: string }> = {
		increased: { icon: "down-1", color: PaletteColors.GREENY_BLUE },
		removed: { icon: "Icon-full-error", color: PaletteColors.ERROR_RED },
		reduced: { icon: "down", color: PaletteColors.YELLOW },
	};

	return (
		<>
			{!state ? (
				<></>
			) : (
				<div className="w-full flex justify-end gap-2">
					<Icon icon={typeToIcon[state.type].icon} color={typeToIcon[state.type].color} size={16} />
					<div className="w-14 text-right tabular-nums">{state.text}</div>
				</div>
			)}
		</>
	);
}
