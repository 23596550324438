import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const Min: FC<Omit<SvgProps, "viewBox" | "children">> = (props) => {
	return (
		<Svg viewBox={{ width: 15, height: 9 }} fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 8C14.4477 8 14 7.55228 14 7L14 2C14 1.44772 14.4477 1 15 1L15 8Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 8C14.4477 8 14 7.55228 14 7L14 2C14 1.44772 14.4477 1 15 1L15 8Z"
				fill="#8C8EA8"
				fillOpacity="0.5"
			/>
			<path d="M15 8V8C14.4477 8 14 7.55228 14 7L14 2C14 1.44772 14.4477 1 15 1V1" stroke="#8C8EA8" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 4.5C14 4.77614 13.7761 5 13.5 5L0.499999 5C0.223857 5 3.91405e-08 4.77614 8.74227e-08 4.5C1.35705e-07 4.22386 0.223857 4 0.499999 4L13.5 4C13.7761 4 14 4.22386 14 4.5Z"
				fill="#8C8EA8"
			/>
		</Svg>
	);
};

export default Min;
