import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const Max: FC<Omit<SvgProps, "viewBox" | "children">> = (props) => {
	return (
		<Svg viewBox={{ width: 15, height: 9 }} fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 1C0.552285 1 1 1.44772 1 2L1 7C1 7.55228 0.552284 8 -6.79955e-07 8L0 1Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 1C0.552285 1 1 1.44772 1 2L1 7C1 7.55228 0.552284 8 -6.79955e-07 8L0 1Z"
				fill="#8C8EA8"
				fillOpacity="0.5"
			/>
			<path d="M0 1V1C0.552285 1 1 1.44772 1 2L1 7C1 7.55228 0.552284 8 -6.79955e-07 8V8" stroke="#8C8EA8" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 4.5C1 4.22386 1.22386 4 1.5 4L14.5 4C14.7761 4 15 4.22386 15 4.5C15 4.77614 14.7761 5 14.5 5L1.5 5C1.22386 5 1 4.77614 1 4.5Z"
				fill="#8C8EA8"
			/>
		</Svg>
	);
};

export default Max;
