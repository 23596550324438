import type { MaybePromise } from "$root/utils/types";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo, useState } from "react";
import { QuotedQuestionBox } from "./QuotedQuestionBox";
import {
	Button,
	ClickableArea,
	Controller,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	TextArea,
} from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { noop } from "@mdotm/mdotui/utils";
import { unpromisify } from "$root/utils/functions";
import { FavouriteIcon } from "../icons/FavouriteIcon";
import { TinyDropdownMenuTrigger } from "$root/components/DropdownMenuTrigger";

export type HistoryBoxProps = {
	message: string;
	favourite?: boolean;
	onToggleFavourite(): MaybePromise<void>;
	onRestore(): MaybePromise<void>;
	onDelete(): MaybePromise<void>;
	onRename(newName: string): MaybePromise<void>;
};

export function HistoryBox({
	message,
	favourite,
	onDelete,
	onRename,
	onRestore,
	onToggleFavourite,
}: HistoryBoxProps): JSX.Element {
	const [isEdit, setIsEdit] = useState(false);
	const selection: "favourite" | "default" = useMemo(() => (favourite ? "favourite" : "default"), [favourite]);
	const colorMap: Record<"favourite" | "default", { color: string; stroke: string }> = {
		favourite: {
			color: themeCSSVars.palette_B500,
			stroke: themeCSSVars.palette_B500,
		},
		default: {
			color: "transparent",
			stroke: themeCSSVars.palette_N200,
		},
	};

	const { run: doRename, loading: renameLoading } = useAsync({
		asyncFn: ({ param }: { param: string }) => onRename(param),
	});
	const { run: doRestore, loading: restoreLoading } = useAsync({ asyncFn: onRestore });
	const { run: doToggleFavourite, loading: toggleFavouriteLoading } = useAsync({ asyncFn: onToggleFavourite });

	if (isEdit) {
		return (
			<QuotedQuestionBox>
				<Controller value={message}>
					{({ value, onChange }) => {
						return (
							<div className="flex gap-2">
								<TextArea
									disabled={renameLoading}
									rows={value.length > 0 ? 3 : 1}
									classList="relative z-0 !w-full [&_textarea]:!resize-none"
									value={value}
									onChangeText={onChange}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											if (e.shiftKey) {
												e.preventDefault();
												e.stopPropagation();
											} else {
												doRename(value).then(() => {
													setIsEdit(false);
												}, noop);
											}
										}
									}}
								/>
								<div className="flex flex-col items-end gap-2">
									<Button
										disabled={renameLoading}
										unstyled
										onClick={() => setIsEdit(false)}
										classList="!rounded hover:bg-slate-500 hover:text-white"
									>
										<Icon icon="Close" size={20} />
									</Button>
									<Button
										disabled={renameLoading}
										unstyled
										classList="!rounded hover:bg-slate-500 hover:text-white p-0.5"
										onClick={unpromisify(async () => {
											await doRename(value);
											setIsEdit(false);
										})}
									>
										<Icon icon="Icon-Ptf-status-SMALL" size={16} />
									</Button>
								</div>
							</div>
						);
					}}
				</Controller>
			</QuotedQuestionBox>
		);
	}

	return (
		<QuotedQuestionBox
			classList={{
				flex: true,
				"opacity-75": restoreLoading,
				[`hover:bg-[${themeCSSVars.palette_B50}]`]: true,
			}}
		>
			<Button
				disabled={toggleFavouriteLoading}
				unstyled
				onClick={unpromisify(() => doToggleFavourite())}
				type="button"
				classList="mb-auto"
			>
				<FavouriteIcon
					color={colorMap[selection].color}
					stroke={colorMap[selection].stroke}
					style={{ width: "24px" }}
				/>
			</Button>
			<div className="min-w-0 flex-grow" style={{ wordBreak: "break-word" }}>
				<ClickableArea onClick={unpromisify(() => doRestore())}>{`“${message}”`}</ClickableArea>
			</div>
			<div className="mb-auto ml-auto">
				<DropdownMenu
					trigger={(props) => (
						<TinyDropdownMenuTrigger {...props}>
							<Icon icon="More" classList="rotate-90" size={20} color={themeCSSVars.palette_N700} />
						</TinyDropdownMenuTrigger>
					)}
					actions={[
						({ onClose }) => (
							<DropdownMenuActionButton
								icon="Delete"
								onClickAsync={async () => {
									console.log("delete");
									await onDelete();
									onClose();
								}}
							>
								Delete
							</DropdownMenuActionButton>
						),
						({ onClose }) => (
							<DropdownMenuActionButton
								icon="Edit"
								onClick={() => {
									console.log("edit");
									setIsEdit(true);
									onClose();
								}}
							>
								Rename
							</DropdownMenuActionButton>
						),
					]}
				/>
			</div>
		</QuotedQuestionBox>
	);
}
