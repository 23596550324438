import type { MarketViewMicroAssetClasses } from "$root/api/api-gen";
import type { marketViewAssetClassMap } from "$root/functional-areas/market-view/market-view-asset-class-map";
// all the missing sector currently are not used for calculate the d performance contribution by asset class
// because we use only us and eu geo
export const assetClassRetrocompatibilityMap = {
	equityUs: "USA_EQUITY_LARGE_CAP",
	equityEu: "EU_EQUITY_LARGE_CAP",
	equityEm: "EMERGING_MARKETS_EQUITY",
	equityJp: "JAPAN_EQUITY_LARGE_CAP",
	equityEuEnergy: "EU_EQUITY_ENERGY",
	equityEuFinancials: "EU_EQUITY_FINANCIALS",
	equityEuIndustrials: "EU_EQUITY_INDUSTRIALS",
	equityEuConsumerStaples: "EU_EQUITY_CONSUMER_STAPLES",
	equityEuCommunicationServices: "EU_EQUITY_COMMUNICATION_SERVICES",
	equityEuInformationTechnology: "EU_EQUITY_INFORMATION_TECHNOLOGY",
	equityEuConsumerDiscretionary: "EU_EQUITY_CONSUMER_DISCRETIONARY",
	equityEuHealthCare: "EU_EQUITY_HEALTHCARE",
	equityEuMaterials: "EU_EQUITY_MATERIALS",
	equityEuUtilities: "EU_EQUITY_UTILITIES",
	equityEuRealEstate: "EU_EQUITY_REAL_ESTATE",
	equityUsEnergy: "USA_EQUITY_ENERGY",
	equityUsFinancials: "USA_EQUITY_FINANCIALS",
	equityUsIndustrials: "USA_EQUITY_INDUSTRIALS",
	equityUsConsumerStaples: "USA_EQUITY_CONSUMER_STAPLES",
	equityUsCommunicationServices: "USA_EQUITY_COMMUNICATION_SERVICES",
	equityUsInformationTechnology: "USA_EQUITY_INFORMATION_TECHNOLOGY",
	equityUsConsumerDiscretionary: "USA_EQUITY_CONSUMER_DISCRETIONARY",
	equityUsHealthCare: "USA_EQUITY_HEALTHCARE",
	equityUsMaterials: "USA_EQUITY_MATERIALS",
	equityUsUtilities: "USA_EQUITY_UTILITIES",
	equityUsRealEstate: "USA_EQUITY_REAL_ESTATE",

	// missing japan equity sector
	equityJpEnergy: "JAPAN_EQUITY_LARGE_CAP",
	equityJpFinancials: "JAPAN_EQUITY_LARGE_CAP",
	equityJpIndustrials: "JAPAN_EQUITY_LARGE_CAP",
	equityJpConsumerStaples: "JAPAN_EQUITY_LARGE_CAP",
	equityJpCommunicationServices: "JAPAN_EQUITY_LARGE_CAP",
	equityJpInformationTechnology: "JAPAN_EQUITY_LARGE_CAP",
	equityJpConsumerDiscretionary: "JAPAN_EQUITY_LARGE_CAP",
	equityJpHealthCare: "JAPAN_EQUITY_LARGE_CAP",
	equityJpMaterials: "JAPAN_EQUITY_LARGE_CAP",
	equityJpUtilities: "JAPAN_EQUITY_LARGE_CAP",
	equityJpRealEstate: "JAPAN_EQUITY_LARGE_CAP",

	// missing em equity sector
	equityEmEnergy: "EMERGING_MARKETS_EQUITY",
	equityEmFinancials: "EMERGING_MARKETS_EQUITY",
	equityEmIndustrials: "EMERGING_MARKETS_EQUITY",
	equityEmConsumerStaples: "EMERGING_MARKETS_EQUITY",
	equityEmCommunicationServices: "EMERGING_MARKETS_EQUITY",
	equityEmInformationTechnology: "EMERGING_MARKETS_EQUITY",
	equityEmConsumerDiscretionary: "EMERGING_MARKETS_EQUITY",
	equityEmHealthCare: "EMERGING_MARKETS_EQUITY",
	equityEmMaterials: "EMERGING_MARKETS_EQUITY",
	equityEmUtilities: "EMERGING_MARKETS_EQUITY",
	equityEmRealEstate: "EMERGING_MARKETS_EQUITY",

	fixedIncomeUsLongGov: "USA_FIXED_INCOME_LONG_GOVERNMENT",
	fixedIncomeUsMediumGov: "USA_FIXED_INCOME_MEDIUM_GOVERNMENT",
	fixedIncomeUsShortGov: "USA_FIXED_INCOME_SHORT_GOVERNMENT",
	fixedIncomeUsHy: "USA_FIXED_INCOME_CORPORATE_HIGH_YIELD",
	fixedIncomeUsCorp: "USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE",

	fixedIncomeEuLongGov: "EU_FIXED_INCOME_LONG_GOVERNMENT",
	fixedIncomeEuMediumGov: "EU_FIXED_INCOME_MEDIUM_GOVERNMENT",
	fixedIncomeEuShortGov: "EU_FIXED_INCOME_SHORT_GOVERNMENT",
	fixedIncomeEuHy: "EU_FIXED_INCOME_CORPORATE_HIGH_YIELD",
	fixedIncomeEuCorp: "EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE",

	fixedIncomeEmGov: "EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT",
	fixedIncomeEmCorp: "EMERGING_MARKETS_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE",

	commoditiesPreciousMetals: "COMMODITIES_PRECIOUS_METALS",
	commoditiesIndustrialMetals: "COMMODITIES_INDUSTRIAL_METALS",
	commoditiesEnergy: "COMMODITIES_ENERGY",
	commoditiesAgriculture: "COMMODITIES_AGRICULTURE",
} satisfies {
	[key in keyof typeof marketViewAssetClassMap]: MarketViewMicroAssetClasses;
};


