import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import { CircularProgressBar, Dialog, DialogHeader } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import HighchartsReact from "highcharts-react-official";
import type { FC } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SentimentBadge } from "../market-view/analysis/SentimentBadge";
import type { SentimentType } from "../market-view/analysis/sentiment";

export type TableParams = {
	assetClass: string;
	current: number;
	prev?: number;
	tooltip: Array<{ label: string; value: string; date?: string }>;
	index: number;
};

function CustomizedChart({ timeSeries }: { timeSeries: { indicatorSerie: [number, number, boolean][] } }): JSX.Element {
	const valueLabels = useMemo(() => ["SU", "U", "N", "O", "SO"], []);
	const labelsByVal = useMemo(() => ({ 1.25: "SU", 2: "U", 3: "N", 4: "O", 4.75: "SO" }), []);
	const plotLines = useMemo(
		() => [
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 1,
				width: 1,
			},
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 1.5,
				width: 1,
			},
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 2.5,
				width: 1,
			},
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 3.5,
				width: 1,
			},
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 4.5,
				width: 1,
			},
			{
				color: "#CCCCCC",
				dashStyle: "ShortDot",
				value: 5,
				width: 1,
			},
		],
		[],
	);

	const colorCategories = useMemo(
		() => [
			{
				name: "Strong underweigth",
				color: themeCSSVars.global_palette_chart_red_800,
				Component: ColoredRectangle,
				infLim: 1,
				supLim: 1.5,
			},
			{
				name: "Underweight",
				color: themeCSSVars.global_palette_chart_red_600,
				Component: ColoredRectangle,
				infLim: 1.6,
				supLim: 2.5,
			},
			{
				name: "Neutral",
				color: themeCSSVars.global_palette_secondary_600,
				Component: ColoredRectangle,
				infLim: 2.6,
				supLim: 3.5,
			},
			{
				name: "Overweigth",
				color: themeCSSVars.global_palette_primary_600,
				Component: ColoredRectangle,
				infLim: 3.6,
				supLim: 4.5,
			},
			{
				name: "Strong overweigth",
				color: themeCSSVars.global_palette_primary_900,
				Component: ColoredRectangle,
				infLim: 4.6,
				supLim: 5,
			},
		],
		[],
	);

	const colorList = useMemo(() => colorCategories.map((el) => el.color), [colorCategories]);

	const chartRef = useRef<HighchartsReact.RefObject>(null);

	const series = useMemo(() => {
		// Sorting Requested By Highcharts
		const formattedSeries = {
			indicatorSerie: timeSeries.indicatorSerie
				.map(([x, y, isInverted]) => ({
					x,
					y,
					isInverted,
				}))
				.sort((a, b) => a.x - b.x),
		};

		// tricky fix for highcharts start compare
		// TODO: find a better way
		const { indicatorSerie } = formattedSeries;
		indicatorSerie[0].y = indicatorSerie[0].y === 0 ? 0.00001 : indicatorSerie[0].y;
		return formattedSeries;
	}, [timeSeries.indicatorSerie]);

	const { formatDate, formatNumber } = useLocaleFormatters();
	const customTruncate = useCallback((n: number, isInverted: boolean) => {
		const diff = isInverted ? 0.01 : -0.01;
		const truncatedNumber = Math.trunc(Math.round(n + diff) - 1);
		return truncatedNumber;
	}, []);

	const options = useMemo(() => {
		const minDate = Math.min(series.indicatorSerie[0].x);
		const maxDate = Math.max(series.indicatorSerie[series.indicatorSerie.length - 1].x);
		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
				backgroundColor: themeCSSVars.global_palette_neutral_50,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			xAxis: [
				{
					type: "datetime",
					min: minDate,
					max: maxDate,
					endOnTick: false,
					startOnTick: false,
					minTickInterval: Math.min(maxDate + 86400000 - minDate, 1000 * 60 * 60 * 24),
					minRange: Math.min(maxDate + 86400000 - minDate, 1000 * 60 * 60 * 24 * 7),
				},
			],
			yAxis: {
				opposite: false,
				title: {
					enabled: false,
				},
				gridLine: 1,
				gridLineColor: "transparent",
				tickInterval: 0.25,
				min: 1,
				max: 5.1,
				labels: {
					y: 5,
					formatter(this: { value: number }) {
						const sValue = this.value;
						const label = labelsByVal[sValue as keyof typeof labelsByVal] ?? "";
						return `${label}`;
					},
				},
				plotLines,
			},
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				enabled: true,
				shared: false,
				useHTML: true,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: themeCSSVars.global_palette_neutral_0,

				formatter(this: {
					points: {
						x: number;
						y: number;
						isInverted: boolean;
						point: {
							change: number;
							marker: {
								fillColor: string;
							};
						};
						series: Highcharts.Series & { options: { marker: { fillColor: string } } };
					}[];
					x: number;
					y: number;
				}) {
					const labelConversion: Record<string, string> = {
						SU: "Strong Underweight",
						U: "Underweight",
						N: "Neutral",
						O: "Overweight",
						SO: "Strong Overweight",
					};

					const content = `<div style="min-width: 12.5rem">
							<div style="text-align: center;
								border-radius: 0.25rem;
								background-color: #eeeef1;
								color: #9699b3;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								line-height: 1.25rem;
								padding: 0.2rem 0;">
								<div>${formatDate(this.x)}</div>
							</div>
							<table style="width: 100%">
								<tbody>${(this.points || [])
									.map((point) => {
										return `
											<tr>
												<td style="border-bottom: solid 1px transparent; padding: 5px 8px 0 0">
												<span style="display: flex; flex-direction: row; align-items: center">
													<span style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 100px; background-color: ${
														point.point.marker.fillColor
													}">
												</span>
												${point.series.name}</span>
												</td>
												<td
													style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
												>
													${labelConversion[valueLabels[point.y <= 1 ? 0 : customTruncate(point.y, point.isInverted)]]} (${formatNumber(
														point.y,
														2,
													)})
												</td>
											</tr>`;
									})
									.reverse()
									.join("")}
								</tbody>
							</table>
						</div>`;
					return content;
				},
			},
			series: [
				{
					data: series.indicatorSerie.map((serieIndic) => ({
						...serieIndic,
						marker: { fillColor: colorList[customTruncate(serieIndic.y, serieIndic.isInverted)] },
					})),
					color: colorCategories[0].color,
					name: "Positioning",
					zones: colorCategories.map((value) => ({
						value: value.supLim,
						color: value.color,
						fillColor: "transparent",
					})),
				},
			],
			time: {
				// timezoneOffset: new Date().getTimezoneOffset(),
				timezoneOffset: 12 * 60, // Method to Fix Backend Date Time
				useUTC: false,
			},
			exporting: {
				enabled: false,
			},
		};
	}, [
		colorCategories,
		colorList,
		customTruncate,
		formatDate,
		formatNumber,
		labelsByVal,
		plotLines,
		series.indicatorSerie,
		valueLabels,
	]);

	return (
		<>
			<div
				className={toClassName({
					[`bg-[${themeCSSVars.global_palette_neutral_50}] pb-4 pt-2 flex-1 mt-4`]: true,
				})}
			>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="stockChart"
					options={options}
					ref={chartRef}
				/>
			</div>
			<div style={{ flex: 0 }}>
				<GraphLegend style={{ position: "static", padding: "1rem 1rem", margin: 0 }}>
					{colorCategories.map(({ name, color, Component }) => (
						<div key={color} className="legend-container light more-space book">
							<Component variant="vertical" color={color} />
							{name}
						</div>
					))}
				</GraphLegend>
			</div>
		</>
	);
}

type DataSeriesBlockProps = {
	assetClass?: string;
	assetClassTimeSeries?: { [key: string]: [number, number, boolean][] };
};

const DataSeriesBlock: FC<DataSeriesBlockProps> = (props) => {
	if (!props.assetClass || !props.assetClassTimeSeries?.[props.assetClass]) {
		return (
			<div
				className={`bg-[${themeCSSVars.global_palette_neutral_50}] rounded h-[424px] flex justify-center items-center text-center pb-4 pt-2 mt-4`}
			>
				Unable to load data
			</div>
		);
	}

	return <CustomizedChart timeSeries={{ indicatorSerie: props.assetClassTimeSeries[props.assetClass] }} />;
};

type TimeSeriesModalProps = {
	assetClass: string;
	currentRegime?: { value?: number; label: string };
	previousRegime?: { value?: number; label: string };
	series?: { [key: string]: [number, number, boolean][] };
	disabled?: boolean;
	isLoading?: boolean;
	onClick?(): void;
};

const TimeSeriesModal: FC<TimeSeriesModalProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { t } = useTranslation();
	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });
	const label = useMemo(
		() => assetClassLabels[props.assetClass as keyof typeof assetClassLabels],
		[props.assetClass, assetClassLabels],
	);

	const positioningSentiment: Record<number, SentimentType> = {
		1: "super-negative",
		2: "negative",
		3: "neutral",
		4: "positive",
		5: "super-positive",
	};
	return (
		<div>
			<button
				className="flex items-center"
				type="button"
				onClick={() => {
					setIsOpen(true);
					props.onClick?.();
				}}
				disabled={props.disabled}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
					<path
						d="M2 8.00029V3.33529C2 2.59891 2.59695 2.00195 3.33333 2.00195H12.6667C13.403 2.00195 14 2.59891 14 3.33529V12.667C14 13.4033 13.403 14.0003 12.6667 14.0003H9.33333"
						stroke={props.disabled ? themeCSSVars.global_palette_neutral_400 : "#4CB09C"}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8 11.667C8 13.3238 6.65685 14.667 5 14.667C3.34315 14.667 2 13.3238 2 11.667C2 10.0101 3.34315 8.66699 5 8.66699C5.79565 8.66699 6.55871 8.98306 7.12132 9.54567C7.68393 10.1083 8 10.8713 8 11.667Z"
						stroke={props.disabled ? themeCSSVars.global_palette_neutral_400 : "#4CB09C"}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11.995 4.33105L9.51706 7.74698L7.32972 5.99698L6.33069 7.3298"
						stroke={props.disabled ? themeCSSVars.global_palette_neutral_400 : "#4CB09C"}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M4.92865 10.6553V11.8273L5.84998 12.3893"
						stroke={props.disabled ? themeCSSVars.global_palette_neutral_400 : "#4CB09C"}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<Dialog show={isOpen} size="xxlarge" onClose={() => setIsOpen(false)} header="Historical positioning">
				<div className="my-4">
					<div className="flex justify-between">
						<p className="text-base font-semibold text-gray-600">{label ? label : props.assetClass}</p>
						<div className="flex gap-2">
							{props.previousRegime?.value && positioningSentiment[props.previousRegime.value] && (
								<div className="flex items-center space-x-2">
									<p>Previous</p>
									<SentimentBadge sentiment={positioningSentiment[props.previousRegime.value]} indicator="positioning">
										{props.previousRegime.label}
									</SentimentBadge>
								</div>
							)}
							{props.currentRegime?.value && positioningSentiment[props.currentRegime?.value] && (
								<div className="flex items-center space-x-2">
									<p>Current</p>

									<SentimentBadge sentiment={positioningSentiment[props.currentRegime?.value]} indicator="positioning">
										{props.currentRegime.label}
									</SentimentBadge>
								</div>
							)}
						</div>
					</div>
					{props.isLoading ? (
						<div
							className={`bg-[${themeCSSVars.global_palette_neutral_50}] rounded h-[424px] flex justify-center items-center text-center pb-4 pt-2 mt-4`}
						>
							<CircularProgressBar value="indeterminate" />
						</div>
					) : (
						<DataSeriesBlock assetClass={props.assetClass} assetClassTimeSeries={props.series} />
					)}
				</div>
			</Dialog>
		</div>
	);
};

export default TimeSeriesModal;
