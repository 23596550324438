import { InvestmentActions, InvestmentNote, InvestmentStatuses } from "$root/api/api-gen";
import { formatDate } from "$root/localization/formatters";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { typeToStringMap } from "$root/widgets-architecture/widgets/PortfolioHistoryBlock";
import { ActionText, Icon, Text } from "@mdotm/mdotui/components";
import { ClassList, ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, invertSortFn } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import type { MouseEventHandler, MouseEvent } from "react";
import { useEffect, useRef, useState } from "react";

// export type QualityEnum = "EQUITY" | "FIXED-INCOME" | "COMMODITIES" | "ALTERNATIVE" | "MONEY-MARKET";

export type MockMacroComposition = {
	quality?: string;
	weight?: number;
};

type CompositionTooltipProps = {
	title?: string;
	subtitle?: string;
	subtitleClassList?: HTMLParagraphElement["className"];
	composition: Array<MockMacroComposition>;
	compared?: boolean;
	note?: InvestmentNote;
	action?: InvestmentActions;
} & (
	| {
			onRemove?: MouseEventHandler<HTMLButtonElement>;
			onCompare?: undefined;
	  }
	| {
			onCompare?: MouseEventHandler<"button"> & ((e: MouseEvent<Element, globalThis.MouseEvent>) => void);
			onRemove?: undefined;
	  }
);

const exposureColorMap = Map([
	["EQUITY", "#33c8ff"],
	["FIXED-INCOME", "#ffd866"],
	["FIXED_INCOME", "#ffd866"],
	["COMMODITIES", "#f69b6f"],
	["ALTERNATIVE", "#dd8596"],
	["MONEY-MARKET", "#8c99d9"],

	["Equity", "#33c8ff"],
	["Fixed Income", "#ffd866"],
	["Commodities", "#f69b6f"],
	["Alternative", "#dd8596"],
	["Money Market", "#8c99d9"],
]);

function PortfolioExposureSummary(props: CompositionTooltipProps): JSX.Element {
	const [isClamped, setIsClamped] = useState(false);
	const [expand, setExpand] = useState(false);
	const { composition, title, subtitle, compared, subtitleClassList } = props;
	const descriptionRef = useRef<HTMLParagraphElement>(null);
	const ascCompositionSorted = composition.sort(invertSortFn(builtInSortFnFor("weight")));

	useEffect(() => {
		if (descriptionRef && descriptionRef.current) {
			setIsClamped(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
		}
	}, []);

	return (
		<>
			<div className="mb-2 flex justify-between items-start">
				<div
					className={toClassName({
						"max-w-[calc(100%_-_70px)]": props.onCompare !== undefined,
						"max-w-[calc(100%_-_20px)]": props.onRemove !== undefined,
					})}
				>
					<Text as="p" type="Body/L/Bold" classList="truncate text-ellipsis" title={title}>
						{title}
					</Text>
					{subtitle && (
						<Text
							as="p"
							type="Body/M/Book"
							classList={toClassName({
								"truncate text-ellipsis": true,
								[subtitleClassList ?? ""]: subtitleClassList !== undefined,
							})}
							title={subtitle}
						>
							{subtitle}
						</Text>
					)}
				</div>
				<div className="shrink-0 flex">
					{props.onCompare && (
						<ActionText as="button" onClick={props.onCompare} color={themeCSSVars.palette_P400}>
							<Text as="span" type="Body/L/Bold" color={themeCSSVars.palette_P400}>
								{compared ? "Remove" : "Compare"}
							</Text>
						</ActionText>
					)}
					{props.onRemove && (
						<button type="button" className="" onClick={props.onRemove}>
							<Icon icon="Close" size={16} color={themeCSSVars.palette_N400} />
						</button>
					)}
				</div>
			</div>
			<hr
				className={toClassName({
					[`border-dashed border-[width:1px] border-[color:${themeCSSVars.palette_N200}]`]: true,
					"mb-4": Boolean(props.note?.text) === false,
					"mb-1": Boolean(props.note?.text),
				})}
			/>
			{props.note?.text && props.action && (
				<article className="mb-2">
					<Text as="p" type="Body/M/Bold" classList="truncate pr-2">
						Last status: {typeToStringMap[props.action].title}&nbsp;
						<Text as="span" type="Body/S/Book">
							{formatDate(props.note.date)}
						</Text>
					</Text>
					<p
						ref={descriptionRef}
						className={toClassName({
							"line-clamp-2": expand === false,
							"line-clamp-none": expand,
						})}
					>
						{props.note?.text}
						{isClamped && (
							<ActionText as="span" onClick={() => setExpand((isExpanded) => !isExpanded)}>
								{isClamped ? "See more" : "Hide"}
							</ActionText>
						)}
					</p>

					{/* <hr className={`border-dotted border-[width:1px] border-[color:${themeCSSVars.palette_N200}]`} /> */}
				</article>
			)}
			<div className="flex space-x-1 w-full mt-auto">
				<ForEach collection={ascCompositionSorted}>
					{({ item }) => (
						<div
							className="overflow-hidden py-1 flex-1 text-center"
							style={{ backgroundColor: exposureColorMap.get(item.quality ?? "") ?? "", width: `${item.weight}%` }}
						>
							<Text as="p" type="Body/S/Book" classList="px-1 text-center truncate" title={item.quality ?? "_"}>
								{item.quality ?? "_"}
							</Text>
							<Text as="p" type="Body/S/Bold" classList="text-center">
								{item.weight ?? 0}%
							</Text>
						</div>
					)}
				</ForEach>
			</div>
		</>
	);
}

export default PortfolioExposureSummary;
