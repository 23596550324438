import HighchartsReact from "highcharts-react-official";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import type { Chart } from "highcharts";
import { forwardRef, useMemo } from "react";

type SmallHeatmapChartProps = {
	xCategories: { name: string; color: string }[];
	yCategories: { name: string; color: string }[];
	data: (number | undefined)[][];
	legend: boolean;
};

const SmallHeatmapChart = forwardRef<{ chart: Chart }, SmallHeatmapChartProps>(function SmallHeatmapChartInner(
	{ xCategories, yCategories, data, legend },
	ref,
) {
	const heatmapOptions = useMemo(() => {
		return {
			chart: {
				type: "heatmap",
				style: {
					fontFamily: "Gotham,sans-serif",
				},
			},
			credits: {
				enabled: false,
			},
			title: { text: undefined },
			subtitle: { text: undefined },
			xAxis: {
				categories: xCategories,
				labels: {
					formatter(this: { value: { name: string; color: string } }) {
						return `<span class="xlabel" style="color: ${this.value.color}; font-weight: bold;">${this.value.name}</span>`;
					},
				},
				opposite: true,
				lineColor: "#FFFFFF",
				gridLineColor: "#FFFFFF",
				gridLineWidth: 10,
				minorGridLineWidth: 10,
				minorGridLineColor: "#FFFFFF",
				gridZIndex: 4,
			},
			yAxis: {
				categories: yCategories,

				labels: {
					useHTML: true, //Set to true
					style: {
						width: "80px",
						whiteSpace: "normal", //set to normal
					},
					formatter(this: { value: { name: string; color: string } }) {
						return `<div class="ylabel" style="color: ${this.value.color}; font-weight: bold; word-wrap: break-word;word-break: break-all;width:80px">${this.value.name}</div>`;
					},
				},
				title: null,
				reversed: true,
				lineColor: "#FFFFFF",
				gridLineColor: "#FFFFFF",
				gridLineWidth: 10,
				minorGridLineWidth: 10,
				minorGridLineColor: "#FFFFFF",
				gridZIndex: 4,
			},
			colorAxis: {
				min: 0,
				minColor: "#ccc",
				maxColor: "#000",
			},
			legend: {
				enabled: legend,
				align: "center",
				layout: "horizontal",
				margin: 4,
				verticalAlign: "bottom",
				symbolHeight: 8,
			},
			tooltip: {
				enabled: false,
			},
			series: [
				{
					borderRadius: 12,
					className: "heatmap-element",
					data,
					dataLabels: {
						enabled: true,
						formatter(this: { point: { value: number } }) {
							return `${this.point.value}%`;
						},
					},
				},
			],
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
					},
				],
			},
			exporting: {
				enabled: false,
			},
		};
	}, [xCategories, yCategories, legend, data]);

	return (
		<div className="SmallHeatmapChart" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<div style={{ flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="chart"
					options={heatmapOptions}
					ref={ref as any}
				/>
			</div>
		</div>
	);
});

export default SmallHeatmapChart;
