import { ActionText, Button, FloatingContent, FormField, Icon, NullableNumberInput } from "@mdotm/mdotui/components";
import { useCallback, useState } from "react";

type EditScoreButtonProps = {
	disabled?: boolean;
	onApply?(value: number | null): void;
};

const EditScoreButton = (props: EditScoreButtonProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState<number | null>(null);

	const handleFloatingStatus = useCallback(() => setIsOpen((prev) => !prev), []);

	const onApply = useCallback(() => {
		props.onApply?.(value);
		handleFloatingStatus();
	}, [handleFloatingStatus, props, value]);

	return (
		<FloatingContent
			open={isOpen}
			position="bottom"
			onClickAway={handleFloatingStatus}
			trigger={({ innerRef }) => (
				<Button
					palette="tertiary"
					size="x-small"
					innerRef={innerRef}
					onClick={handleFloatingStatus}
					disabled={props.disabled}
				>
					<Icon icon="score" size={20} />
					&nbsp; Assign score
				</Button>
			)}
		>
			<div className="mt-2 pt-6 pb-4 px-4 bg-white rounded-md shadow-lg">
				<div className="flex gap-2 items-end">
					<FormField label="New score">
						<NullableNumberInput
							min={0}
							max={100}
							step={0.01}
							inputAppearance={{ classList: "text-right !w-32" }}
							size="small"
							value={value}
							onChange={setValue}
							name="score"
						/>
					</FormField>
					<Button size="small" palette="primary" onClick={onApply}>
						Apply
					</Button>
				</div>
			</div>
		</FloatingContent>
	);
};

export default EditScoreButton;
