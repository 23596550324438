import type { StylableProps } from "@mdotm/mdotui/components";
import { Button, CircularProgressBar } from "@mdotm/mdotui/components";
import { propagateRef, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import Editor from "@monaco-editor/react";
import type { editor } from "monaco-editor";
import type { ForwardedRef } from "react";
import { useRef, useState } from "react";
import { configuration, prettify, provider, CompletionItemProvider } from "./languages/xml";
import { unpromisify } from "@mdotm/mdotui/utils";

export type TextEditorProps = StylableProps & {
	content: string;
	language: string;
	editorRef: ForwardedRef<editor.IStandaloneCodeEditor>;
};

export function TextEditor({
	content,
	language,
	editorRef: externalEditorRef,
	style,
	classList,
}: TextEditorProps): JSX.Element {
	const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
	const [supportsFormatting, setSupportsFormatting] = useState(false);
	return (
		<div style={style} className={toClassName({ "flex flex-col": true, ...toClassListRecord(classList) })}>
			<p>Actions</p>
			<div className="flex gap-4 mb-4">
				{supportsFormatting && (
					<Button
						onClick={unpromisify(() => editorRef.current?.getAction("editor.action.formatDocument")?.run())}
						size="x-small"
						palette="secondary"
					>
						Format
					</Button>
				)}
			</div>
			<div className="flex-1">
				<Editor
					loading={<CircularProgressBar value="indeterminate" />}
					className="h-full min-h-0"
					language={language}
					defaultLanguage={language}
					defaultValue={content}
					onMount={(el, m) => {
						m.languages.register({ id: "custom-xml" });
						m.languages.registerDocumentRangeFormattingEditProvider("custom-xml", {
							provideDocumentRangeFormattingEdits: (model, _ranges, _options, _token) => {
								return [
									{
										range: model.getFullModelRange(),
										text: prettify(model.getValue()),
									},
								];
							},
						});
						m.languages.setMonarchTokensProvider("custom-xml", provider);
						m.languages.setLanguageConfiguration("custom-xml", configuration);
						m.languages.registerCompletionItemProvider("custom-xml", CompletionItemProvider);

						editorRef.current = el;
						propagateRef(el, externalEditorRef);
						setSupportsFormatting(
							el.getSupportedActions().some((x: { id: string }) => x.id === "editor.action.formatDocument"),
						);
						el.onDidChangeModelLanguageConfiguration(() =>
							setSupportsFormatting(
								el.getSupportedActions().some((x: { id: string }) => x.id === "editor.action.formatDocument"),
							),
						);
					}}
				/>
			</div>
		</div>
	);
}
