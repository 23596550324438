import type { MarketForecastDTO } from "$root/api/api-gen";
import DashedLineIcon from "$root/components/icons/forecast/DashedLineIcon";
import FirstQuartile from "$root/components/icons/forecast/firstQuartile";
import LineIcon from "$root/components/icons/forecast/LineIcon";
import Max from "$root/components/icons/forecast/Max";
import Median from "$root/components/icons/forecast/Median";
import Min from "$root/components/icons/forecast/Min";
import ThirdQuartile1 from "$root/components/icons/forecast/ThirdQuartile1";
import { CurrentPositionTooltipIcon, PreviousPositionTooltipIcon } from "$root/components/OutlookPositioningGraph";
import NewWidgetStandardTooltip from "$root/components/Tooltips/NewWidgetStandardTooltip";
import { formatDate } from "$root/localization/formatters";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PaletteColors } from "$root/styles/themePalette";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { builtInSort, builtInSortFnFor } from "$root/utils/collections";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { composeOutlookLink } from "$root/functional-areas/outlook/useOutlookComposer";

interface Props {
	forecasts: MarketForecastDTO[];
	firstFilter: string;
	secondFilter: string;
	timeSelectorValue: string;
}

type TablePros = {
	// eslint-disable-next-line react/no-unused-prop-types
	confLevel: string;
	// eslint-disable-next-line react/no-unused-prop-types
	expVol: number;
	firstQuartile: number;
	thirdQuartile: number;
	max: number;
	min: number;
	median: number;
	returns: {
		firstQuartile: number;
		thirdQuartile: number;
		current: number;
		min: number;
		max: number;
		median: number;
		previous: number;
		type: string;
	};
	// eslint-disable-next-line react/no-unused-prop-types
	tooltip: Array<{ label: string; value: string }>;
	// eslint-disable-next-line react/no-unused-prop-types
	sectorLabel: string;
	// eslint-disable-next-line react/no-unused-prop-types
	sectorValue: string;
	// eslint-disable-next-line react/no-unused-prop-types
	sectorType: string;
	// eslint-disable-next-line react/no-unused-prop-types
	index: number;
	// eslint-disable-next-line react/no-unused-prop-types
	geography: string;
};

type ForecastGraphLineProps = {
	columnIndex: number;
	middleIndex: number;
	tableIndex: number;
};

/**
 * Given a number on range min - max
 *
 * Find the percentage of the x number of the range
 *  * Example:
 * ```ts
 * range min = -45 & max = 45 & x = 0
 * (0 - (-45) / 45 - (-45)) * 100
 * (45 / 90) * 100 = 50%
 * percentage of number in range = 50%
 * ```
 */
const getPercentagePositionRange = (nToFind: number, minRange: number, maxRange: number) => {
	const range = maxRange - minRange;
	const positionOnRange = nToFind - minRange;
	return ((positionOnRange / range) * 100).toFixed(2);
};

const ForecastGraphLine: React.FC<ForecastGraphLineProps> = ({ columnIndex, middleIndex, tableIndex }) => {
	if (columnIndex === middleIndex) {
		return <LineIcon classList={`ml-1 LineIcon ${tableIndex % 2 === 0 ? "Four" : "Three"}`} />;
	}
	return <DashedLineIcon classList={`ml-1 DashedLineIcon ${tableIndex % 2 === 0 ? "Four" : "Three"}`} />;
};

const ForecastGraph: React.FC<TablePros & { minCeil: number; headerUnit: number; columnNumber: number }> = ({
	returns: { min, max, firstQuartile, thirdQuartile, median, current, previous },
	index,
	minCeil,
	headerUnit,
	columnNumber,
}) => {
	const getTheMiddleColumn = Math.floor(columnNumber / 2).toFixed(0);

	const overallMax = Math.abs(minCeil + 0 * headerUnit).toFixed(0);
	const toPercentage = (val: string) => val.replace(",", ".").concat("%");
	const generateBarSize = (bar: number) => ((bar * 100) / Number(overallMax)).toPrecision(2);

	const negativeBarPercent = toPercentage(generateBarSize(Math.abs(min)));
	const positiveBarPercent = toPercentage(generateBarSize(Math.abs(max)));
	const firstQuartileBarPercent = toPercentage(generateBarSize(Math.abs(firstQuartile)));
	const thirdQuartileBarPercent = toPercentage(generateBarSize(Math.abs(thirdQuartile)));
	const toNumberOverallMax = Number(overallMax);
	const currentPositionPercentage = toPercentage(
		getPercentagePositionRange(current, -toNumberOverallMax, toNumberOverallMax),
	);
	const previousPositionPercentage = toPercentage(
		getPercentagePositionRange(previous, -toNumberOverallMax, toNumberOverallMax),
	);

	const medianPositionPercentage = toPercentage(
		getPercentagePositionRange(median, -toNumberOverallMax, toNumberOverallMax),
	);

	return (
		<div className="relative h-full w-full px-6">
			<div className="flex justify-between w-full h-full">
				{Array.from({ length: columnNumber }).map((_el, i) => (
					<div key={i} className="h-fit my-auto">
						<ForecastGraphLine columnIndex={i} middleIndex={Number(getTheMiddleColumn)} tableIndex={index} />
					</div>
				))}
				{/* min max range*/}
				<div className="absolute h-full w-[calc(100%_-_48px)] ">
					<div className="grid grid-cols-2 h-full w-full mx-auto mt-2	ml-0.5">
						<div className="flex items-center justify-end">
							<div className="h-0.5 bg-[#BFC4CE]" style={{ width: negativeBarPercent }} />
						</div>
						<div className="flex items-center justify-start ">
							<div className="h-0.5 bg-[#BFC4CE]" style={{ width: positiveBarPercent }} />
						</div>
					</div>
				</div>
				{/* quartile */}
				<div className="absolute h-full w-[calc(100%_-_48px)]">
					<div className="grid grid-cols-2 h-full w-full mx-auto relative mt-2 ml-0.5">
						<div className="flex items-center justify-end">
							<div
								className="h-3 bg-[#BFC4CE] rounded-tl-[1px] rounded-bl-[1px] relative z-20"
								style={{ width: firstQuartileBarPercent }}
							/>
						</div>
						<div className="flex items-center justify-start ">
							<div
								className="h-3 bg-[#BFC4CE] rounded-tr-[1px] rounded-br-[1px] relative z-20"
								style={{ width: thirdQuartileBarPercent }}
							/>
						</div>
					</div>
				</div>
				{/* //median */}
				<div className="absolute h-full w-[calc(100%_-_48px)] z-30 median">
					<div className="mx-auto mt-2 w-full h-full flex items-center ml-0.5">
						<div className="h-3 w-0.5 bg-[#585D68] relative ml-[2px] z-30" style={{ left: medianPositionPercentage }} />
					</div>
				</div>
				{/* //current */}
				<div className="absolute h-full w-[calc(100%_-_48px)] z-20 current">
					<div className="mx-auto w-full mt-2	ml-0.5">
						<div className=" relative w-fit" style={{ left: currentPositionPercentage }}>
							<div className="relative right-1 top-px z-30">
								<svg
									viewBox="0 0 100 100"
									xmlns="http://www.w3.org/2000/svg"
									width="10"
									height="10"
									fill={PaletteColors.AZURE}
								>
									<circle cx="50" cy="50" r="50" />
								</svg>
							</div>

							<div className="h-2.5 w-0.5 bg-[#BFC4CE] relative" />
						</div>
					</div>
				</div>
				{/* //prev */}
				<div className="absolute h-full w-[calc(100%_-_48px)] z-10 previous">
					<div className="mx-auto w-full mt-2	">
						<div className=" relative w-fit" style={{ left: previousPositionPercentage }}>
							<div className="relative right-[3px] top-px z-30">
								<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 11" fill="none">
									<circle cx="5" cy="5.5" r="4" fill="white" stroke="#8C8EA8" strokeWidth="2" />
								</svg>
							</div>

							<div className="h-2.5 w-0.5 bg-[#BFC4CE] relative " />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const ForecastTooltip: React.FC<{ data: TablePros } & { timeSelectorValue: string }> = ({
	data: { returns },
	timeSelectorValue,
}) => {
	const { t } = useTranslation();
	const sanitaizeDecimaNumber = (value: number) => `${value.toFixed(2)}%`;
	const forecastIndicators: {
		[key in keyof Omit<TablePros["returns"], "type">]?: { icon: React.ReactNode; title: string };
	} = {
		min: {
			icon: <Min />,
			title: t("FORECAST.MIN"),
		},
		firstQuartile: {
			icon: <FirstQuartile />,
			title: t("FORECAST.1QUAR"),
		},
		median: {
			icon: <Median />,
			title: t("FORECAST.MEDIAN"),
		},
		thirdQuartile: {
			icon: <ThirdQuartile1 />,
			title: t("FORECAST.3QUAR"),
		},
		max: {
			icon: <Max />,
			title: t("FORECAST.MAX"),
		},
	};

	const forecastTimeSelectorValue: Record<string, string> = {
		"1 month": "30 days ago",
		"3 month": "90 days ago",
		"6 months": "180 days ago",
	};

	const { current, previous } = returns;
	const currentDate = formatDate(new Date());

	return (
		<div>
			<div>
				<article>
					<div className="relative">
						<div className="absolute top-[4px] left-[0px] drop-shadow-xl">
							<CurrentPositionTooltipIcon color={PaletteColors.AZURE} />
						</div>
						<div className="flex-column ml-[14px]">
							<div className="flex justify-between text-[#1D2433] text-[12px] leading-[16px]">
								<p>Current forecast:</p>
								<p>
									<strong className="pl-[4px]">{`${current}%`}</strong>
								</p>
							</div>
							<p className="text-[#667085] text-[10px] leading-[14px]">{`${currentDate}`}</p>
						</div>
					</div>
				</article>
				<article className="mt-[8px]">
					<div className="relative">
						<div className="absolute top-[4px] left-[0px] drop-shadow-xl">
							<PreviousPositionTooltipIcon color={PaletteColors.SANTAS_GREY} />
						</div>
						<div className="flex-column ml-[14px]">
							<div className="flex justify-between text-[#1D2433] text-[12px] leading-[16px]">
								<p>Previous forecast:</p>
								<p>
									<strong className="pl-[4px]">{`${previous}%`}</strong>
								</p>
							</div>
							<p className="text-[#667085] text-[10px] leading-[14px]">
								{forecastTimeSelectorValue[timeSelectorValue.toLowerCase()] ?? "-"}
							</p>
						</div>
					</div>
				</article>
			</div>

			<hr className="my-2" />
			{(Object.keys(forecastIndicators) as Array<keyof typeof forecastIndicators>).map((filedName) => {
				if (!returns[filedName] || !forecastIndicators[filedName]) {
					return <></>;
				}
				const fieldValue = sanitaizeDecimaNumber(returns[filedName]);
				return (
					<div className="grid grid-cols-12 text-[#1D2433] items-center mt-[8px]" key={filedName}>
						<span className="col-span-1">{forecastIndicators[filedName]?.icon}</span>
						<div className="col-span-9">
							<p>{forecastIndicators[filedName]?.title}</p>
						</div>
						<div className="col-span-2 flex">
							<p className="ml-auto">
								<strong>{fieldValue}</strong>
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const ForecastTable = ({ forecasts, firstFilter, secondFilter, timeSelectorValue }: Props): JSX.Element => {
	const INITIAL_MIN = 100.0;
	const INITIAL_MAX = -100.0;

	const { t } = useTranslation();
	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });

	const { formatNumber } = useLocaleFormatters();

	const positioningTableHeader: Record<string, string> = React.useMemo(
		() => ({
			FI: t("OUTLOOK.TYPE"),
			CO: t("OUTLOOK.TYPE"),
			MARKETS: t("OUTLOOK.GEOGRAPHY"),
			EU: t("OUTLOOK.SECTOR"),
			US: t("OUTLOOK.SECTOR"),
		}),
		[t],
	);

	const { rows, minCeil, headerUnit } = React.useMemo(() => {
		const { data, max, min } = forecasts.reduce<{ data: TablePros[]; min: number; max: number }>(
			({ data: dataAcc, min: minAcc, max: maxAcc }, forecast, i) => {
				const label = forecast.type ? assetClassLabels[forecast.type as keyof typeof assetClassLabels] : "-";

				if (forecast.min && forecast.min < minAcc) {
					minAcc = forecast.min;
				}

				if (forecast.max && forecast.max > maxAcc) {
					maxAcc = forecast.max;
				}

				return {
					data: [
						...dataAcc,
						{
							index: i,
							min: forecast.min ?? 0,
							max: forecast.max ?? 0,
							median: forecast.median ?? 0,
							firstQuartile: forecast.firstQuartile ?? 0,
							thirdQuartile: forecast.thirdQuartile ?? 0,
							confLevel: forecast.confLevel ?? "",
							expVol: forecast.expVol ?? 0,
							returns: {
								min: forecast.min ?? 0,
								max: forecast.max ?? 0,
								median: forecast.median ?? 0,
								firstQuartile: forecast.firstQuartile ?? 0,
								thirdQuartile: forecast.thirdQuartile ?? 0,
								current: forecast.currentValue?.value ?? 0,
								previous: forecast.prevValue?.value ?? 0,
								type: label,
							},
							tooltip: [
								{
									label: t("FORECAST.MIN"),
									value: forecast.min ? `${forecast.min.toFixed(2)}%` : "0%",
								},
								{
									label: t("FORECAST.MAX"),
									value: forecast.max ? `${forecast.max.toFixed(2)}% ` : "0%",
								},
								{
									label: t("FORECAST.MEDIAN"),
									value: forecast.median ? `${forecast.median.toFixed(2)}%` : "0%",
								},
								{
									label: t("FORECAST.1QUAR"),
									value: forecast.firstQuartile ? `${forecast.firstQuartile.toFixed(2)}%` : "0%",
								},
								{
									label: t("FORECAST.3QUAR"),
									value: forecast.thirdQuartile ? `${forecast.thirdQuartile.toFixed(2)}%` : "0%",
								},
								{
									label: "Previous",
									value: forecast.prevValue?.value ? `${forecast.prevValue?.value.toFixed(2)}%` : "0%",
								},
								{
									label: "Curent",
									value: forecast.currentValue?.value ? `${forecast.currentValue?.value.toFixed(2)}%` : "0%",
								},
							],
							sectorLabel: label,
							sectorType: label.toUpperCase().trim().replace(" ", "_"),
							sectorValue: forecast.sector ?? "",
							geography: forecast.geography ?? "",
						},
					],
					min: minAcc,
					max: maxAcc,
				};
			},
			{ data: [] as TablePros[], min: INITIAL_MIN, max: INITIAL_MAX },
		);
		const MAX_MIN_MIN = Math.abs(min) > Math.abs(max) ? Math.abs(min) : Math.abs(max);
		const MAX_MIN_CEIL = Math.ceil(MAX_MIN_MIN / 5) * 5;

		return {
			rows: data,
			minCeil: -MAX_MIN_CEIL,
			maxCeil: MAX_MIN_MIN,
			unit: 100 / (MAX_MIN_CEIL * 2),
			headerUnit: (MAX_MIN_CEIL * 2) / 10,
		};
	}, [INITIAL_MIN, INITIAL_MAX, assetClassLabels, forecasts, t]);

	const percentageCalc = React.useCallback((index: number, minPoint: number, unit: number) => {
		const percentage = (minPoint + index * unit).toFixed(0);
		return index === 5 ? Math.abs(Number(percentage)) : percentage;
	}, []);

	const column = React.useMemo<TableColumn<TablePros>[]>(
		() => [
			{
				header: secondFilter ? positioningTableHeader[secondFilter] : positioningTableHeader[firstFilter],
				content: ({ sectorLabel, sectorValue, geography }) => {
					const to = composeOutlookLink(firstFilter, secondFilter, sectorValue, geography);
					return (
						<Link to={to} className="font-semibold underline w-full">
							{sectorLabel}
						</Link>
					);
				},
				sortFn: builtInSortFnFor("sectorLabel"),
				name: "sectorLabel",
				relativeWidth: 20,
			},
			{
				header: t("FORECAST.EXPECTED_RE"),
				cellClassList: "tabular-nums",
				content: ({ returns: { current } }) => `${formatNumber(current)}%`,
				sortFn: (a, b) => builtInSort(a.returns.current, b.returns.current),
				name: "expectedRe",
				relativeWidth: 14,
				align: "end",
			},
			{
				header: t("FORECAST.ANNUALIZED_EXPECTED_RETURNS"),
				cellClassList: "tabular-nums",
				content: ({ expVol }) => `${formatNumber(expVol)}%`,
				sortFn: builtInSortFnFor("expVol"),
				name: "expVol",
				relativeWidth: 20,
				align: "end",
			},
			{
				headerCellTitleClassList: "grow",
				header: () => (
					<div className="w-[calc(100%_-_2px)] px-6 mx-auto h-5">
						{/* <div className="text-center mb-1">{t("FORECAST.RETURN")}</div> */}
						<div className="percents flex justify-between">
							{[-100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100].map((_v, index) => (
								<div
									className={
										index === 0
											? "First w-[3px] relative"
											: index === 10
											  ? "Last w-[3px] relative"
											  : "Middle w-[3px] relative"
									}
									key={(minCeil + index * headerUnit).toFixed(0)}
								>
									<p className="absolute -left-[10px]">{percentageCalc(index, minCeil, headerUnit)}%</p>
								</div>
							))}
						</div>
					</div>
				),
				cellClassList: "grow",
				minWidth: 450,
				content: (forecastProps) => (
					<NewWidgetStandardTooltip
						title={forecastProps.returns.type}
						content={<ForecastTooltip data={forecastProps} timeSelectorValue={timeSelectorValue} />}
					>
						{({ innerRef }) => (
							<div ref={innerRef} className="w-full h-full">
								<ForecastGraph {...forecastProps} headerUnit={headerUnit} minCeil={minCeil} columnNumber={11} />
							</div>
						)}
					</NewWidgetStandardTooltip>
				),
				relativeWidth: 46,
			},
		],
		[
			firstFilter,
			formatNumber,
			headerUnit,
			minCeil,
			percentageCalc,
			positioningTableHeader,
			secondFilter,
			t,
			timeSelectorValue,
		],
	);

	return <Table columns={column} rows={rows} visibleRows={8} />;
};

export default ForecastTable;
