import type { PortfolioExAnteMetric } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { TableV2 } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";

const ExAnteMetrics = ({
	benchmark: showBechmark,
	variant,
	list,
}: PrintableProps<{ benchmark: boolean; variant: ReportTemplateVariant }, PortfolioExAnteMetric>): JSX.Element => {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const columns = [
		{
			name: "name",
			minWidth: 200,
			header: t("METRICS"),
			content: ({ type }) => t(`EXANTE_METRICS.METRICS.${type!}`),
		},
		{
			name: "current",
			width: 160,
			cellClassList: "tabular-nums",
			header: "Current",
			align: "end",
			content: ({ current, type }) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return `${formatNumber(current)}${label.toLowerCase().includes("ratio") ? "" : "%"}`;
			},
		},
		{
			name: "benchmark",
			width: 160,
			cellClassList: "tabular-nums",
			header: "Benchmark",
			align: "end",
			content: ({ benchmark: benchmarkValue, type }) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return `${formatNumber(benchmarkValue)}${label.toLowerCase().includes("ratio") ? "" : "%"}`;
			},
			hidden: !showBechmark,
		},
		{
			name: "proposal",
			width: 160,
			cellClassList: "tabular-nums",
			header: "Proposal",
			align: "end",
			content: ({ type, proposal }) => {
				const label = t(`EXANTE_METRICS.METRICS.${type!}`);
				return `${formatNumber(proposal)}${label.toLowerCase().includes("ratio") ? "" : "%"}`;
			},
			hidden: variant === "current",
		},
	] satisfies TableV2.TableColumn<PortfolioExAnteMetric>[];

	return (
		<Card title="Ex-ante portfolio metrics">
			{/* TODO: test new table */}
			<TableV2.BaseTable columns={columns} rows={list} noDataText="No metrics" />
		</Card>
	);
};

export default ExAnteMetrics;
