import type { EditorCompositionResponse } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { generateUniqueDOMId, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import BigNumber from "bignumber.js";
import { Map } from "immutable";
import { createContext } from "react";
import { useEditorBuilder, type UseEditorBuilderResult } from "./tools/useEditorBuilder";
import { RowTypeEnum, type CompositionEntry, type EditorTableAreaEnum } from "./tools/shared";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";

const EditorCompositionContext = createContext<null | { compositionBuilder: UseEditorBuilderResult }>(null);
export type CompositionEditorAreaProps =
	| {
			name: "create";
			uuid?: undefined;
	  }
	| {
			name: "edit";
			uuid: string;
	  };
export const EditorCompositionContextProvider = (props: {
	entity: UploadEntity;
	children: NodeOrFn<{
		compositionBuilder: UseEditorBuilderResult;
		entity: UploadEntity;
		area: CompositionEditorAreaProps;
	}>;
	area: CompositionEditorAreaProps;
}): JSX.Element => {
	const { children, entity, area } = props;

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const compositionBuilder = useEditorBuilder({ composition: Map() });

	function init({ cashTicker, composition }: EditorCompositionResponse) {
		const compositionWithDomId = composition?.map((x): CompositionEntry => {
			const id = generateUniqueDOMId();
			return { ...x, weight: BigNumber(x.weight ?? 0), id, rowType: RowTypeEnum.SELECT, alias: x.alias ?? "" };
		});

		compositionBuilder.reset({
			composition: Map(compositionWithDomId?.map((x): [string, CompositionEntry] => [x.id, x])),
			cashId: compositionWithDomId?.find((x) => x.ticker === cashTicker?.ticker)?.id ?? cashTicker?.ticker,
		});
	}

	const editorComposition = useQueryNoRefetch(["queryEditorComposition", entity], {
		queryFn: () =>
			props.area.name === "edit"
				? axiosExtract(editorApi.getEditorEditComposition(props.area.uuid, entity))
				: axiosExtract(editorApi.getEditorNewComposition(entity)),
		onSuccess: init,
	});

	return (
		<EditorCompositionContext.Provider value={{ compositionBuilder }}>
			<ReactQueryWrapperBase query={editorComposition}>
				{(response, query) => renderNodeOrFn(children, { compositionBuilder, area, entity })}
			</ReactQueryWrapperBase>
		</EditorCompositionContext.Provider>
	);
};
