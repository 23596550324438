import type {
	InvestmentStatuses,
	ReferenceUniverseListEntry,
	UserUniverseColumnOrdering,
	UserUniverseColumnPreference,
	UserUniverseColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import { spawnUniverseImportDialog } from "$root/functional-areas/universe/Import";
import { useUserValue } from "$root/functional-areas/user";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { nullary, unpromisify } from "$root/utils/functions";
import { parallelize } from "$root/utils/promise";
import { useDebouncedMemo } from "$root/utils/react-extra";
import type { DropdownMenuProps } from "@mdotm/mdotui/components";
import {
	BatchActions,
	Button,
	DropdownMenuActionButton,
	Icon,
	TableV2,
	TextInput,
	contextMenuHandler,
	sortRows,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { Map } from "immutable";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CrudModal } from "..";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useUniverseColumn } from "./columns";

type UniverseListProps = {
	universes: ReferenceUniverseListEntry[];
	deleteMultiSelectCtx: MultiSelectCtx<string>;
	columnsPreferences?: UserUniverseColumnPreference[];
	refetch: {
		universe: QueryObserverBaseResult<ReferenceUniverseListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserUniverseColumnOrdering>["refetch"];
	};
};

const UniverseList: FC<UniverseListProps> = ({ universes, deleteMultiSelectCtx, refetch, columnsPreferences }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [isPortfolioNameAvailable, setIsPorfolioNameAvailable] = useState(true);
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);

	const [modalData, setModalData] = useState<{
		open: boolean;
		action: UsePerformCrudActions | "share";
		portfolioName: string;
		uuid: string;
	}>({
		open: false,
		action: "delete",
		portfolioName: "",
		uuid: "",
	});

	const { performAction } = usePerformCrud("universe");
	const { t } = useTranslation();

	const history = useHistory();
	const user = useUserValue();
	const { aclSpawn } = useUpdateAccessListControlDialog("UNIVERSE");
	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const integrationsV2Api = useApiGen(IntegrationsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const filteredRows = useMemo(
		() =>
			universes.filter((item) =>
				[item.name ?? "", item.status ?? ""].some((el) => el.toLowerCase().includes(debouncedLowerCaseSearchQuery)),
			),
		[debouncedLowerCaseSearchQuery, universes],
	);

	const universeByUid = useMemo(() => Map(universes.map((p) => [p.uuid ?? "", p])), [universes]);

	const { column: checkboxColumn, rowClassList } = TableV2.useSelectableTableColumn({
		rows: filteredRows,
		multiSelectCtx: deleteMultiSelectCtx,
		selectBy: (u) => u.uuid!,
	});

	const { universeListOrderByName, setUniverseListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useUniverseColumn(columnsPreferences);

	const getDropdownActions = useCallback(
		({
			uuid,
			name,
			status,
			referralInvestments,
			richAcl,
		}: ReferenceUniverseListEntry): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			const isDisabled = !uuid || (referralInvestments ?? []).length > 0 || ["RETRIEVING_DATA"].includes(status ?? "");
			return [
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.universe.canDelete} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Delete"
							disabled={isDisabled}
							onClick={() => {
								setModalData({ open: true, action: "delete", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Delete)"
						>
							Delete
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<DropdownMenuActionButton
						icon="Content-Copy"
						disabled={status ? invalidStatus.includes(status as InvestmentStatuses) : true}
						onClick={() => {
							setModalData({ open: true, action: "duplicate", portfolioName: name ?? "", uuid: uuid ?? "" });
							onClose();
						}}
						data-qualifier="PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Duplicate)"
					>
						Duplicate
					</DropdownMenuActionButton>
				),
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.universe.canRename} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Edit"
							onClick={() => {
								setModalData({ open: true, action: "rename", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Rename)"
						>
							Rename
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard
						permissionChecker={(userId, acl) => validateACLPermissions(userId, acl, roleByArea.universe.EDITOR)}
						acl={richAcl?.acl ?? []}
					>
						<DropdownMenuActionButton
							icon="share"
							onClickAsync={async () => {
								await aclSpawn(name, uuid, refetch.universe);
								onClose();
							}}
							data-qualifier="PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Share)"
						>
							Share
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
			];
		},
		[refetch, aclSpawn],
	);

	const columns = useMemo<TableV2.TableColumn<ReferenceUniverseListEntry>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[checkboxColumn, getDropdownActions, visibleColumns],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: universeListOrderByName }) : filteredRows),
		[columns, filteredRows, universeListOrderByName],
	);

	const onCloseModal = useCallback(() => {
		setModalData({ open: false, action: modalData.action, uuid: "", portfolioName: "" });
		setIsPorfolioNameAvailable((prev) => (prev === true ? prev : true));
	}, [modalData]);

	const onDelete = useCallback(
		async (uuid: string, name: string) => {
			try {
				await performAction({ action: "delete", uuid });

				trackMixPanelEvent(`Portfolio`, {
					Type: "Delete",
					ID: uuid,
					Name: name,
				});
				await refetch.universe({ throwOnError: true });
				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_UNIVERSE", { name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "universe", `to delete universe "${uuid}"`);
				platformToast({ children: t("SOMETHING_WENT_WRONG"), severity: "error", icon: "Portfolio" });
			} finally {
				onCloseModal();
				deleteMultiSelectCtx.actions.remove(uuid);
			}
		},
		[deleteMultiSelectCtx.actions, onCloseModal, performAction, refetch, t],
	);

	const onSubmit = useCallback(
		async (action: Exclude<UsePerformCrudActions, "delete">, uuid: string, name: string) => {
			try {
				const { data: isNameAvailable } = await referenceUniverseV4Api.isUniverseNameAvailable(name);
				if (!isNameAvailable) {
					setIsPorfolioNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });
				await refetch.universe({ throwOnError: true });
				const notficationMessage: Record<typeof action, string> = {
					duplicate: t("PORTFOLIOS.DUPLICATE_OK_MESSAGE", { portfolioName: name }),
					rename: t("PORTFOLIOS.RENAME_OK_MESSAGE", { portfolioName: name }),
				};

				platformToast({
					children: notficationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				onCloseModal();
			} catch (error) {
				reportPlatformError(error, "ERROR", "universe", `to ${action} universe "${uuid}"`);
				platformToast({ children: t("SOMETHING_WENT_WRONG"), severity: "error", icon: "Portfolio" });
			}
		},
		[referenceUniverseV4Api, performAction, refetch, t, onCloseModal],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserUniverseColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userUniverseColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserUniverseColumnOrdering;
				await portfolioStudioPreferencesApi.setUserUniverseColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));
	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>
			<div className="flex justify-between items-center">
				<TextInput
					value={searchQuery}
					name="search"
					maxLength={60}
					onChangeText={setSearchQuery}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
					leftContent={<Icon icon="Search" size="1.4em" />}
				/>
				<div className="flex gap-4">
					<AuthorizationGuard
						requiredServices={["IMPORT"]}
						requiredParam={({ importFormats }) => importFormats !== undefined && importFormats.length > 0}
					>
						{() => (
							<ReactQueryWrapper
								queryFn={async () => {
									const importConverters = await axiosExtract(integrationsV2Api.retrieveUniverseImportConverterType());
									return importConverters.filter((x) => user.importFormats?.includes(x));
								}}
								queryKey={["importFormat", "universe"]}
							>
								{(converters) => (
									<Button
										size="small"
										palette="secondary"
										onClick={() =>
											spawnUniverseImportDialog({
												converters,
												onSubmit: async (investments) => {
													try {
														const failed: unknown[] = [];
														const callStack = investments.map(
															(investment) => () =>
																integrationsV2Api.importUniverse(investment).catch((e) => failed.push(e)),
														);
														await parallelize(callStack);

														if (callStack.length > 0 && failed.length === callStack.length) {
															throw new Error("No universe imported", { cause: failed });
														}

														platformToast({
															children:
																callStack.length === 0
																	? "No universe were imported"
																	: "Sphere has taken over your request",
															severity: callStack.length === 0 ? "warning" : "info",
															icon: "Dowload",
														});
													} catch (error) {
														platformToast({
															children: "No universe imported",
															severity: "error",
															icon: "Dowload",
														});
														throw error;
													} finally {
														unpromisify(async () => {
															await refetch.universe({ throwOnError: true });
														})();
													}
												},
											})
										}
									>
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import external
									</Button>
								)}
							</ReactQueryWrapper>
						)}
					</AuthorizationGuard>
					<Button
						size="small"
						palette="secondary"
						onClick={() => history.push("/portfolios/upload/universe")}
						data-qualifier="PortfolioStudio/UniverseList/NewUniverse"
					>
						<Icon icon="Outline1" size={16} />
						New universe
					</Button>
				</div>
			</div>
			<BatchActions
				classList="my-2"
				selected={deleteMultiSelectCtx.data.selection.size}
				total={universes.length}
				actions={[
					{
						label: "Delete",
						icon: "Delete",
						onClick: nullary(deleteMultiSelectCtx.actions.confirmSelection),
						disabled: deleteMultiSelectCtx.data.selection.some(
							(id) =>
								(universeByUid?.get(id)?.referralInvestments ?? []).length !== 0 ||
								aclByArea.portfolio.canDelete(user.id, universeByUid?.get(id)?.richAcl?.acl ?? []) === false,
						),
					},
				]}
			/>

			{columns && (
				<TableV2.BaseHScrollTable
					onRowClick={(row) => row.uuid && deleteMultiSelectCtx.actions.toggle(row.uuid)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ uuid }) => {
						const selected = deleteMultiSelectCtx.data.selection?.has(uuid!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					// TODO: refactor
					style={{ maxHeight: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					orderBy={universeListOrderByName}
					onOrderByChange={setUniverseListOrderByName}
				/>
			)}
			{modalData.action === "delete" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
					portfolioName={modalData.portfolioName}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					onSubmit={() => onDelete(modalData.uuid, modalData.portfolioName)}
				/>
			)}

			{modalData.action === "duplicate" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("duplicate", modalData.uuid, name)}
					portfolioName={modalData.portfolioName}
				/>
			)}

			{modalData.action === "rename" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("rename", modalData.uuid, name)}
					portfolioName={modalData.portfolioName}
				/>
			)}
		</>
	);
};

export default UniverseList;

const invalidStatus: Array<InvestmentStatuses> = ["REVIEW", "CALCULATING", "RETRIEVING_DATA", "PROPOSAL_READY"];
