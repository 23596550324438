import type { AxiosResponse } from "axios";


export type CustomAxiosError = {
	code: number;
	message: string;
};

export async function axiosExtract<T>(axiosPromise: Promise<AxiosResponse<T>>): Promise<T> {
	const { data } = await axiosPromise;
	return data;
}
