import WaterfallChart from "$root/ui-lib/charts/WaterfallChart";
import { useMemo } from "react";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";

// type horizonType = "ONE_MONTH" | "THREE_MONTHS" | "SIX_MONTHS" | "ONE_YEAR" | "YEAR_TO_DATE" | "FROM_INCEPTION";
type PerformanceAttributionProps = CommonSetUpReportProps<UnionMapData["portfolio-details"]>;

const horizonOptions = [
	{
		label: "1m",
		value: "ONE_MONTH",
	},
	{
		label: "3m",
		value: "THREE_MONTHS",
	},
	{
		label: "6m",
		value: "SIX_MONTHS",
	},
	{
		label: "YTD",
		value: "YEAR_TO_DATE",
	},
	{
		label: "1y",
		value: "ONE_YEAR",
	},
	{
		label: "All",
		value: "FROM_INCEPTION",
	},
];

const PerformanceAttribution = ({
	data,
}: PrintableProps<{ data: PerformanceAttributionProps }, number>): JSX.Element => {
	const { performanceAttribution } = data;
	const processedData = useMemo(() => {
		const computedSeries = (performanceAttribution.current ?? []).map((el) => ({
			label: `<div class="h-[40px] flex flex-col text-end"><p><strong>${el.assetClass}</strong></p><p>${el.type}</p></div>`,
			tooltipTitle: `${el.assetClass} - ${el.type}`,
			value: el.value ?? 0,
		}));
		return computedSeries;
	}, [performanceAttribution]);

	if (processedData.length === 0) {
		return <></>;
	}

	return (
		<Card title="Performance attribution">
			<p className="mb-2">
				The <strong>allocation effect</strong> refers to the returns generated by allocating portfolio weights to
				specific asset classes. It determines whether taking active positions in a particular segment against the
				benchmark was a contributor or a detractor to the overall portfolio performance.
			</p>
			<p className="mb-2">
				The <strong>selection effect</strong> refers to the impact of the selection of specific stocks within a segment
				on the portfolio’s overall return. The weight of the segment in the portfolio determines the size of the
				effect—the larger the segment, the larger the effect is.
			</p>
			<p className="mb-4">
				The <strong>interaction effect</strong> captures the value added that is not attributable solely to the asset
				allocation and stock selection decisions. It is positive when outperformance is overweighted and when
				underperformance is underweighted.
			</p>
			<p className="font-semibold text-lg">{horizonOptions[3].label}</p>
			<WaterfallChart animated={false} dataSeries={processedData} height="360px" />
		</Card>
	);
};

export default PerformanceAttribution;
