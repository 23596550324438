import { createContext } from "react";

export const UniverseFiltersContext = createContext<{
	firstFilter: string;
	secondFilter: string;
	thirdFilter: string;
}>({
	firstFilter: "",
	secondFilter: "",
	thirdFilter: "",
});
