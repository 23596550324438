import type { PortfolioMetric } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TableV2 } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";

export function PortfolioMetrics({
	benchmark,
	list,
}: PrintableProps<{ benchmark: boolean }, PortfolioMetric>): JSX.Element {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = [
		{
			name: "type",
			minWidth: 200,
			header: "METRICS",
			content: ({ type }) => t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-",
		},
		{
			name: "current",
			width: 160,
			cellClassList: "tabular-nums",
			header: "Current",
			align: "end",
			content: ({ value, type }) => {
				const label = t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-";
				return `${formatNumber(value)}${label.toLowerCase().includes("ratio") ? "" : "%"}`;
			},
		},
		{
			name: "benchmark",
			width: 160,
			cellClassList: "tabular-nums",
			header: "Benchmark",
			align: "end",
			content: ({ benchmarkValue, type }) => {
				const label = t(`PERFORMANCE_METRICS.METRICS.FULL.${type!}`) ?? "-";
				return `${formatNumber(benchmarkValue)}${label.toLowerCase().includes("ratio") ? "" : "%"}`;
			},
			hidden: !benchmark,
		},
	] satisfies TableV2.TableColumn<PortfolioMetric>[];

	return (
		<Card title="Portfolio metrics">
			{/* TODO: test new table */}
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText={t("COMPOSITION.NO_COMPOSITION")} />
		</Card>
	);
}
