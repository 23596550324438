import { useSystemValue } from "$root/functional-areas/system";
import { Redirect } from "react-router";
import Pexels from "$root/assets/images/pexels-ludvig-hedenborg-3753160_3.png";
import Rover from "$root/assets/images/Rover.svg";
import SphereLogo from "$root/assets/images/SPHERE_logo.svg";
import "./maintenance.scss";
import { Text } from "@mdotm/mdotui/components";

const MaintenanceType = {
	infrastractureIssue: {
		title: "THE PLATFORM IS UNDER MAINTENANCE",
		description: "We apologize for any inconvenience, we will be back online in a few hours.",
	},
	trafficIssue: {
		title: "Temporarily Experiencing High Traffic",
		description:
			"We're enhancing server capabilities to accommodate demand. Please check back in a few hours for the optimal Sphere experience. Thank you for your patience!",
	},
};

const Maintenance = (): JSX.Element => {
	const { systemMode } = useSystemValue();
	return systemMode !== "MAINTENANCE" ? (
		<Redirect to="/" />
	) : (
		<>
			<div className="firstFather">
				<div className="container">
					<div className="topContainer">
						<img className="imageTop" src={Pexels} alt="" />
						<div className="wrapperIcon">
							<img className="icon" src={Rover} alt="" />
						</div>
					</div>
					<div />
					<div className="bottomContainer">
						<h1 className="title">{MaintenanceType["trafficIssue"].title}</h1>
						<h5 className="description">{MaintenanceType["trafficIssue"].description}</h5>
						<h5 className="description2">
							In case you need additional information, please send an email to&nbsp;
							<a
								style={{ color: "black" }}
								href="mailto:support@mdotm.ai?subject=Additional%20Information&body=Dear%20MDOTM%20Team,%20I%E2%80%99d%20like%20to%20have%20more%20information%20on%20the%20current%20platform%20outage.%20Thank%20you"
							>
								support@mdotm.ai
							</a>
						</h5>
					</div>
				</div>
				<img className="logo" src={SphereLogo} alt="" />
			</div>

			<div className="text-center">
				<Text type="Body/M/Book" color="#8C8EA8">
					<span>MDOTM Srl - Via passione 8, 20122, Milano - P.iva 09601510960</span>
					<span className="footerDivider">|</span>
					<a
						href="https://www.iubenda.com/privacy-policy/48016693"
						className="footerPolicy iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
						title="Privacy Policy "
					>
						Privacy Policy
					</a>
				</Text>
			</div>
		</>
	);
};

export default Maintenance;
