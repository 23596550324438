import type { KeyboardEvent } from "react";

// Math Functions
export function roundValue(value: number): number {
	return Math.round((value + Number.EPSILON) * 100) / 100;
}

// Custom Round
export function roundCustomByStep(value: number, step: number): number {
	const isZ = value === 0;
	const _roundValue = step;
	const absValue = Math.abs(value);
	const rounded = isZ ? 0 : Math.ceil(absValue / _roundValue) * _roundValue;
	return value >= 0 ? rounded : rounded * -1;
}

// Obj Sum
export function objSum<T>(value: T[], selector: keyof T): number {
	const sum = value.reduce((a, c) => {
		const val = c[selector];
		if (typeof val === "number") {
			return a + val;
		} else {
			return a;
		}
	}, 0);
	return sum;
}

export function objSumRounded<T>(value: T[], selector: keyof T): number {
	const sum = objSum(value, selector);
	return roundValue(sum);
}

// DrillDown Selector
export function DrillDownByValueMap<
	T extends Record<string, unknown>,
	K extends Record<string, (value: T[]) => ReturnType<K[F]>>,
	F extends keyof K,
>(
	MyObjArr: T[],
	primarySelector: keyof T,
	secondarySelector: keyof T,
	aggregator: K,
	sortFn?: (a: Record<F, ReturnType<K[F]>>, b: Record<F, ReturnType<K[F]>>) => number,
): {
	primarySelector: keyof T;
	secondarySelector: keyof T;
	drilldownData: {
		index: number;
		value: T[keyof T];
		computedData: Record<F, ReturnType<K[F]>>;
		drilldown: {
			index: number;
			value: T[keyof T];
			computedData: Record<F, ReturnType<K[F]>>;
		}[];
	}[];
} {
	const defaultComputedData = Object.fromEntries(Object.keys(aggregator).map((el) => [el, undefined]));
	const firstSelectionOptions = [...new Set(MyObjArr.map((el) => el[primarySelector]))];
	const selectionTree = firstSelectionOptions
		.map((firstSelectionOption, index) => {
			const filteredObjArr = MyObjArr.filter((el) => el[primarySelector] === firstSelectionOption);
			const secondSelectionOptions = [...new Set(filteredObjArr.map((el) => el[secondarySelector]))];
			const firstSelection = {
				index,
				value: firstSelectionOption,
				computedData: JSON.parse(JSON.stringify(defaultComputedData)) as Record<F, ReturnType<K[F]>>,
			};
			Object.entries(aggregator).map(
				([key, fun]) =>
					(firstSelection.computedData[key as keyof typeof firstSelection.computedData] = fun(filteredObjArr)),
			);
			const secondSelection = secondSelectionOptions.map((secondarySelectionOption, i) => {
				const secondFilteredObjArr = filteredObjArr.filter((el) => el[secondarySelector] === secondarySelectionOption);
				const secondSelectionObj = {
					index: i,
					value: secondarySelectionOption,
					computedData: JSON.parse(JSON.stringify(defaultComputedData)) as Record<F, ReturnType<K[F]>>,
				};
				Object.entries(aggregator).map(
					([key, fun]) =>
						(secondSelectionObj.computedData[key as keyof typeof secondSelectionObj.computedData] =
							fun(secondFilteredObjArr)),
				);
				return secondSelectionObj;
			});
			return {
				firstSelection,
				secondSelection: sortFn
					? secondSelection.sort((a, b) => sortFn(a.computedData, b.computedData))
					: secondSelection,
			};
		})
		.map((el) => ({ ...el.firstSelection, drilldown: el.secondSelection }));
	return {
		primarySelector,
		secondarySelector,
		drilldownData: sortFn ? selectionTree.sort((a, b) => sortFn(a.computedData, b.computedData)) : selectionTree,
	};
}

// const ExampleData = [
// 	{
// 		assetClass: "Equity",
// 		microAssetClass: "Energy",
// 		geography: "USA and Canada",
// 		value: 0.17,
// 	},
// 	{
// 		assetClass: "Commodities",
// 		microAssetClass: "Precious Metals",
// 		geography: "Global",
// 		value: 0.03,
// 	},
// 	{
// 		assetClass: "Fixed Income",
// 		microAssetClass: "Government Inflation Linked",
// 		geography: "Europe",
// 		value: 0.01,
// 	},
// 	{
// 		assetClass: "Fixed Income",
// 		microAssetClass: "Corporate",
// 		geography: "Europe",
// 		value: 0.09,
// 	}
// ];

// DrillDownByValueMap(
// 	Obj1,
// 	Selettore 1,
// 	Selettore 2,
// 	{
// 		A: (val) => val.reduce((a, c) => a + c.value, 0),
// 		B: (val) => val.reduce((a, c) => a + c.value, 0) * 3,
// 	},
// );

// Graph Functions

// Generate Markers
export function getGraphMarkers(
	max: number,
	min: number,
	markerStep = 1,
	decorator = "",
	markersLimit = 20,
): { value: number; label: string }[] {
	const markers = [];
	const defMarkers = [];
	for (let i = min; i <= max; i = i + markerStep) {
		markers.push({
			value: i,
			label: `${i}${decorator}`,
		});
	}
	const markersLastIndex = markers.length - 1;
	if (markers.length > markersLimit) {
		if (markers.length % 2 !== 0) {
			defMarkers.push(markers[0], markers[markersLastIndex / 2], markers[markersLastIndex]);
		} else {
			defMarkers.push(markers[0], markers[markersLastIndex]);
		}
		return defMarkers;
	} else {
		return markers;
	}
}

/**
 * Custom `Object.entries` function that return the typed version
 * @returns Array< [key, value] >
 */
export const customObjectEntriesFn = Object.entries as <T, K extends keyof T>(obj: T) => Array<[K, T[K]]>;

/**
 * Custom `Object.values` function that return the typed version
 * @returns Array< T >
 */
export const customObjectValuesFn = Object.values as <T, K extends keyof T>(obj: T) => Array<T[K]>;

/**
 * Custom `Object.keys` function that return the typed version
 * @returns Array< T >
 */
export const customObjectKeysFn = Object.keys as <T, K extends keyof T>(obj: T) => Array<K>;

/**
 * function prevent enter key to submit the form
 *
 * @param e as KeyboardEvent
 * @returns
 */
export function preventSubmitOnPressEnter<T>(e: KeyboardEvent<T>): KeyboardEvent<T> | void {
	return e.key !== "Enter" ? e : e.preventDefault();
}
