import { builtInSortFnFor, groupBy } from "@mdotm/mdotui/utils";
import type {
	EditPortfolioV4StepPayloadMap,
	EditablePortfolioConstraintHelper,
	EditablePortfolioStrategyHelper,
	EditableRiskConstraintHelper,
	PortfolioConstraintTypePayloadMap,
} from "./shared";
import type {
	MainInfoResponse,
	InvestableUniverseResponse,
	AllocationConstraintsResponse,
	MarketViewResponse,
	RiskConstraintsResponse,
	StrategyConstraintsResponse,
} from "$root/api/api-gen";
import {
	RiskConstraintType,
	type AllocationConstraintsSaveRequest,
	type InvestableUniverseSaveRequest,
	type MainInfoSaveRequest,
	type MarketViewSaveRequest,
	type RiskConstraintsSaveRequest,
	type StrategyConstraintsSaveRequest,
	ConstraintRelation,
	ConstraintValidity,
} from "$root/api/api-gen";
import { generateUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import { findWithIndex, maybeMap } from "$root/utils/optional";
import { removeEmptyItems } from "$root/utils/collections";
import type { EditPortfolioV4Props } from "./EditPortfolio";

type EditPortfolioV4BackendRequestPayloadMap = {
	mainInfo: MainInfoSaveRequest;
	investableUniverse: InvestableUniverseSaveRequest;
	portfolioConstraints: AllocationConstraintsSaveRequest;
	riskConstraints: RiskConstraintsSaveRequest;
	portfolioStrategy: StrategyConstraintsSaveRequest;
	marketView: MarketViewSaveRequest;
};

type EditPortfolioV4BackendResponsePayloadMap = {
	mainInfo: MainInfoResponse;
	investableUniverse: InvestableUniverseResponse;
	portfolioConstraints: AllocationConstraintsResponse;
	riskConstraints: RiskConstraintsResponse;
	portfolioStrategy: StrategyConstraintsResponse;
	marketView: MarketViewResponse;
};

export const responseToStepMapper: {
	[K in keyof EditPortfolioV4StepPayloadMap]: (
		backendData: EditPortfolioV4BackendResponsePayloadMap[K],
		area: EditPortfolioV4Props["area"],
	) => EditPortfolioV4StepPayloadMap[K];
} = {
	mainInfo: (a, area) => ({
		baseCurrency: a.baseCurrency ?? "EUR",
		name: a.name ?? "",
		primaryBenchmark: a.primaryBenchmark,
		mandateType: a.mandateType,
		investmentUuid: area.portfolioUid,
		investmentReference: a.investmentReference,
		targetVolatility: a.targetVolatility ?? { selectedValue: null },
	}),
	investableUniverse: (a) => ({
		investableUniverseSelectionStrategy: undefined,
		universeIdentifier: a.universeIdentifier,
		universeName: a.universeName || "",
		universeComposition: a.universeComposition ?? [],
	}),
	portfolioConstraints: (a, area) => ({
		list: [
			...(a.assetClassConstraints ?? []).map((b) => ({
				priority: b.priority,
				data: {
					type: "assetClassConstraints",
					id: generateUniqueDOMId(),
					maxWeight: b.maxWeight ?? null,
					minWeight: b.minWeight ?? null,
					suggestedMaxWeight: b.suggestedMaxWeight ?? null,
					suggestedMinWeight: b.suggestedMinWeight ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
					assetClassesIdentifiers: b.assetClassesIdentifiers || [],
					identifier: b.identifier || "-",
					relation: b.relation || ConstraintRelation.Between,
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					target: b.target ?? false,
				} satisfies EditablePortfolioConstraintHelper<"assetClassConstraints">,
			})),
			...(a.instrumentsConstraints ?? []).map((b) => ({
				priority: b.priority,
				data: {
					type: "instrumentConstraints",
					id: generateUniqueDOMId(),
					identifier: b.identifier ?? "-",
					tickerIdentifiers: b.tickerIdentifiers ?? [],
					maxWeight: b.maxWeight ?? null,
					minWeight: b.minWeight ?? null,
					suggestedMaxWeight: b.suggestedMaxWeight ?? null,
					suggestedMinWeight: b.suggestedMinWeight ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					relation: b.relation ?? "BETWEEN",
					target: b.target ?? false,
				} satisfies EditablePortfolioConstraintHelper<"instrumentConstraints">,
			})),
			...(!a.instrumentMaxWeightConstraint
				? []
				: [
						{
							priority: a.instrumentMaxWeightConstraint.priority,
							data: {
								type: "instrumentMaxWeightConstraint",
								id: generateUniqueDOMId(),
								identifier: a.instrumentMaxWeightConstraint.identifier ?? null,
								readonly:
									a.instrumentMaxWeightConstraint.validity === ConstraintValidity.Valid ||
									area.name === "settings-current" ||
									area.name === "settings-enhanced",
								weight: a.instrumentMaxWeightConstraint.weight ?? null,
								validity: a.instrumentMaxWeightConstraint.validity ?? ConstraintValidity.Unchecked,
								suggestedMaxWeight: a.instrumentMaxWeightConstraint.suggestedMaxWeight ?? null,
								suggestedMinWeight: a.instrumentMaxWeightConstraint.suggestedMinWeight ?? null,
								// TODO: why not suggestedWeight? Is it correct to assume that it's tied to the suggestedMax?
								target: a.instrumentMaxWeightConstraint.target ?? false,
							} satisfies EditablePortfolioConstraintHelper<"instrumentMaxWeightConstraint">,
						},
				  ]),
			...(a.tagsConstraints ?? []).map((b) => ({
				priority: b.priority,
				data: {
					type: "tagConstraints",
					id: generateUniqueDOMId(),
					maxWeight: b.maxWeight ?? null,
					minWeight: b.minWeight ?? null,
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					target: b.target ?? true,
					identifier: b.identifier ?? "",
					relation: b.relation ?? ConstraintRelation.Between,
					tagIdentifiers: b.tagIdentifiers ?? [],
					tagLabel: "", // TODO
					suggestedMaxWeight: b.suggestedMaxWeight ?? null,
					suggestedMinWeight: b.suggestedMinWeight ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioConstraintHelper<"tagConstraints">,
			})),
			...(!a.scoresConstraint
				? []
				: [
						{
							priority: a.scoresConstraint.priority,
							data: {
								type: "scoresConstraint",
								id: generateUniqueDOMId(),
								identifier: a.scoresConstraint.identifier ?? "",
								instrumentWithouthScorePolicy: a.scoresConstraint.considerNullAsZero
									? "INCLUDE_WITH_ZERO_AS_SCORE"
									: "EXCLUDE",
								maxWeight: a.scoresConstraint.maxWeight ?? null,
								minWeight: a.scoresConstraint.minWeight ?? null,
								readonly:
									a.scoresConstraint.validity === ConstraintValidity.Valid ||
									area.name === "settings-current" ||
									area.name === "settings-enhanced",
								relation: a.scoresConstraint.relation ?? ConstraintRelation.Between,
								// relation: "EQUAL"!, // BE sets it to average by default, we don't need to provide it.
								scoreIdentifiers: a.scoresConstraint.scoreIdentifiers ?? [],
								suggestedMaxWeight: a.scoresConstraint.suggestedMaxWeight ?? null,
								suggestedMinWeight: a.scoresConstraint.suggestedMinWeight ?? null,
								target: a.scoresConstraint.target ?? false,
								validity: a.scoresConstraint.validity ?? ConstraintValidity.Unchecked,
							} satisfies EditablePortfolioConstraintHelper<"scoresConstraint">,
						},
				  ]),
			...(a.currencyConstraints ?? []).map((b) => ({
				priority: b.priority,
				data: {
					type: "currencyConstraints",
					id: generateUniqueDOMId(),
					identifier: b.identifier ?? "",
					currencies: b.currencies ?? [],
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					maxWeight: b.maxWeight ?? null,
					minWeight: b.minWeight ?? null,
					suggestedMaxWeight: b.suggestedMaxWeight ?? null,
					suggestedMinWeight: b.suggestedMinWeight ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
					relation: b.relation ?? ConstraintRelation.Between,
					target: b.target ?? false,
				} satisfies EditablePortfolioConstraintHelper<"currencyConstraints">,
			})),
			...(a.lockInstrumentGroup ? [a.lockInstrumentGroup] : []).map((b) => ({
				priority: b.priority,
				data: {
					type: "lockInstrumentGroup",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					identifier: b.identifier ?? null,
					target: b.target ?? false,
					relation: b.relation ?? ConstraintRelation.Equal,
					tickerIdentifiers: b.tickers ?? [],
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioConstraintHelper<"lockInstrumentGroup">,
			})),
			...(a.forEachConstraint ?? []).map((b) => ({
				priority: b.priority,
				data: {
					type: "forEachConstraint",
					id: generateUniqueDOMId(),
					identifier: b.identifier ?? "-",
					tickerIdentifiers: b.tickers ?? [],
					maxWeight: b.maxWeight ?? null,
					minWeight: b.minWeight ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					relation: b.relation ?? "BETWEEN",
					target: b.target ?? false,
				} satisfies EditablePortfolioConstraintHelper<"forEachConstraint">,
			})),
		]
			.sort(builtInSortFnFor("priority"))
			.map(({ data }) => data),
	}),
	riskConstraints: (a, area) => ({
		list: [
			...(!a.selectedRiskConstraint
				? []
				: a.selectedRiskConstraint!.type === RiskConstraintType.Volatility1Y ||
				    a.selectedRiskConstraint!.type === RiskConstraintType.Volatility2Y ||
				    a.selectedRiskConstraint!.type === RiskConstraintType.Volatility3Y
				  ? [
							{
								id: "1",
								readonly:
									a.selectedRiskConstraint.validity === ConstraintValidity.Valid ||
									area.name === "settings-current" ||
									area.name === "settings-enhanced",
								type: "volatility" as const,
								subType: a.selectedRiskConstraint.type ?? null,
								value: a.selectedRiskConstraint.selectedValue ?? null,
								suggestedMaxValue: a.selectedRiskConstraint.suggestedMaxValue ?? null,
								suggestedMinValue: a.selectedRiskConstraint.suggestedMinValue ?? null,
								suggestedValue: a.selectedRiskConstraint.suggestedValue ?? null,
								target: a.selectedRiskConstraint.target ?? false,
								validity: a.selectedRiskConstraint.validity ?? ConstraintValidity.Unchecked,
							} satisfies EditableRiskConstraintHelper<"volatility">,
				    ]
				  : a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar951Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar952Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar953Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar951Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar952Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar953Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar9751Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar9752Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar9753Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar9751Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar9752Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar9753Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar991Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar992Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.HistoricalVar993Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar991Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar992Y ||
				      a.selectedRiskConstraint!.type === RiskConstraintType.ParametricVar993Y
				    ? [
								{
									id: "2",
									readonly:
										a.selectedRiskConstraint.validity === ConstraintValidity.Valid ||
										area.name === "settings-current" ||
										area.name === "settings-enhanced",
									type: "var" as const,
									subType: a.selectedRiskConstraint.type ?? null,
									value: a.selectedRiskConstraint.selectedValue ?? null,
									suggestedMaxValue: a.selectedRiskConstraint.suggestedMaxValue ?? null,
									suggestedMinValue: a.selectedRiskConstraint.suggestedMinValue ?? null,
									suggestedValue: a.selectedRiskConstraint.suggestedValue ?? null,
									target: a.selectedRiskConstraint.target ?? false,
									validity: a.selectedRiskConstraint.validity ?? ConstraintValidity.Unchecked,
								} satisfies EditableRiskConstraintHelper<"var">,
				      ]
				    : []),
			...(!a.maxTrackingError
				? []
				: [
						{
							id: "3",
							readonly:
								a.maxTrackingError.validity === ConstraintValidity.Valid ||
								area.name === "settings-current" ||
								area.name === "settings-enhanced",
							type: "maxTrackingError" as const,
							value: a.maxTrackingError?.selectedValue ?? null,
							suggestedMaxValue: a.maxTrackingError?.suggestedMaxValue ?? null,
							suggestedMinValue: a.maxTrackingError?.suggestedMinValue ?? null,
							suggestedValue: a.maxTrackingError?.suggestedValue ?? null,
							investmentReference: a.maxTrackingError?.investmentReference ?? null,
							validity: a.maxTrackingError.validity ?? ConstraintValidity.Unchecked,
							target: a.maxTrackingError.target ?? false,
						} satisfies EditableRiskConstraintHelper<"maxTrackingError">,
				  ]),
		],
	}),
	portfolioStrategy: (a, area) => ({
		// sort elements
		list: removeEmptyItems([
			maybeMap(a.targetMaxTurnover, (b) => ({
				priority: b.priority,
				data: {
					type: "targetMaxTurnover",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"targetMaxTurnover">,
			})),
			maybeMap(a.targetMinOperationWeight, (b) => ({
				priority: b.priority,
				data: {
					type: "targetMinOperationWeight",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"targetMinOperationWeight">,
			})),
			maybeMap(a.targetTransactionCostsInBps, (b) => ({
				priority: b.priority,
				data: {
					type: "targetTransactionCostsInBps",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.suggestedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"targetTransactionCostsInBps">,
			})),
			maybeMap(a.minNumberOfInstruments, (b) => ({
				priority: b.priority,
				data: {
					type: "minNumberOfInstruments",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"minNumberOfInstruments">,
			})),
			maybeMap(a.maxNumberOfInstruments, (b) => ({
				priority: b.priority,
				data: {
					type: "maxNumberOfInstruments",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"maxNumberOfInstruments">,
			})),
			maybeMap(a.instrumentMinWeight, (b) => ({
				priority: b.priority,
				data: {
					type: "instrumentMinWeight",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"instrumentMinWeight">,
			})),
			maybeMap(a.rounding, (b) => ({
				priority: b.priority,
				data: {
					type: "instrumentRoundingWeight",
					id: generateUniqueDOMId(),
					readonly:
						b.validity === ConstraintValidity.Valid ||
						area.name === "settings-current" ||
						area.name === "settings-enhanced",
					selectedValue: b.selectedValue ?? null,
					suggestedMaxValue: b.suggestedMaxValue ?? null,
					suggestedMinValue: b.suggestedMinValue ?? null,
					suggestedValue: b.selectedValue ?? null,
					validity: b.validity ?? ConstraintValidity.Unchecked,
				} satisfies EditablePortfolioStrategyHelper<"instrumentRoundingWeight">,
			}))
		])
			.sort(builtInSortFnFor("priority"))
			.map(({ data }) => data),
	}),
	marketView: (mv) => ({
		scenarioData: mv.scenarioData,
		scenarioIdentifier: mv.scenarioIdentifier,
		forecastHorizon: undefined,
		custom: undefined,
		name: undefined,
	}),
};

export function makeStepToRequestMapper(investmentUuid: string | undefined): {
	[K in keyof EditPortfolioV4StepPayloadMap]: (
		frontendData: EditPortfolioV4StepPayloadMap[K],
	) => EditPortfolioV4BackendRequestPayloadMap[K];
} {
	return {
		mainInfo: (a) => ({
			...a,
			investmentUuid,
			targetVolatility: a.targetVolatility?.selectedValue
				? { ...a.targetVolatility, selectedValue: a.targetVolatility?.selectedValue }
				: undefined,
		}),
		investableUniverse: (a) =>
			({
				investableUniverseSelectionStrategy: a.investableUniverseSelectionStrategy,
				universeIdentifier: a.universeIdentifier,
				universeName: a.universeName ?? undefined,
				investmentUuid,
			}) satisfies EditPortfolioV4BackendRequestPayloadMap["investableUniverse"],
		portfolioConstraints: (a) => {
			const grouped = groupBy(
				a.list.map((x, priority) => ({ ...x, priority })),
				(x) => x.type,
			) as {
				[K in keyof PortfolioConstraintTypePayloadMap]?: Array<
					PortfolioConstraintTypePayloadMap[K] & { priority: number }
				>;
			};
			return {
				assetClassConstraints: (grouped.assetClassConstraints ?? []).map((x) => ({
					maxWeight: x.maxWeight ?? undefined,
					minWeight: x.minWeight ?? undefined,
					suggestedMaxWeight: undefined,
					suggestedMinWeight: undefined,
					validity: undefined,
					priority: x.priority,
					relation: x.relation,
					target: x.target,
					assetClassesIdentifiers: x.assetClassesIdentifiers,
					identifier: x.identifier ?? undefined,
				})) satisfies NonNullable<
					EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["assetClassConstraints"]
				>,
				instrumentConstraints: (grouped.instrumentConstraints ?? []).map((x) => ({
					identifier: x.identifier ?? undefined,
					tickerIdentifiers: x.tickerIdentifiers,
					maxWeight: x.maxWeight ?? undefined,
					minWeight: x.minWeight ?? undefined,
					relation: x.relation,
					target: x.target,
					suggestedMaxWeight: undefined,
					suggestedMinWeight: undefined,
					validity: undefined,
					priority: x.priority,
				})) satisfies NonNullable<
					EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["instrumentConstraints"]
				>,
				instrumentMaxWeightConstraint: (() => {
					const x = grouped.instrumentMaxWeightConstraint?.[0];
					if (!x) {
						return undefined;
					}
					return {
						identifier: x.identifier ?? undefined,
						target: x.target,
						validity: undefined,
						weight: x.weight ?? undefined,
						suggestedWeight: undefined,
						priority: x.priority,
						suggestedMaxWeight: undefined, // TODO: as this is the request, I expect to be able to NOT pass the suggested weights
						suggestedMinWeight: undefined,
					};
				})() satisfies EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["instrumentMaxWeightConstraint"],
				tagConstraints: (grouped.tagConstraints ?? []).map((x) => ({
					max: x.maxWeight ?? undefined,
					min: x.minWeight ?? undefined,
					tagLabel: x.tagLabel,
					priority: x.priority,
					identifier: x.identifier || undefined,
					maxWeight: x.maxWeight ?? undefined,
					minWeight: x.minWeight ?? undefined,
					relation: x.relation,
					suggestedMaxWeight: undefined,
					suggestedMinWeight: undefined,
					validity: undefined,
					validationDone: undefined,
					tagIdentifiers: x.tagIdentifiers,
					target: x.target,
				})) satisfies NonNullable<EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["tagConstraints"]>,
				scoresConstraint: (() => {
					const x = grouped.scoresConstraint?.[0];
					if (!x) {
						return undefined;
					}
					return {
						considerNullAsZero: x.instrumentWithouthScorePolicy === "INCLUDE_WITH_ZERO_AS_SCORE",
						identifier: x.identifier || undefined,
						maxWeight: x.maxWeight ?? undefined,
						minWeight: x.minWeight ?? undefined,
						priority: x.priority,
						relation: x.relation,
						// relation: undefined, // BE sets it to average by default, we don't need to provide it. When reading it in the response, we consider it as an EQUAL constraint
						validity: undefined,
						validationDone: undefined,
						suggestedMaxWeight: undefined,
						suggestedMinWeight: undefined,
						scoreIdentifiers: x.scoreIdentifiers ?? undefined,
						target: x.target,
					};
				})() satisfies EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["scoresConstraint"],
				currencyConstraints: (grouped.currencyConstraints ?? []).map((x) => ({
					maxWeight: x.maxWeight ?? undefined,
					minWeight: x.minWeight ?? undefined,
					suggestedMaxWeight: undefined,
					suggestedMinWeight: undefined,
					validity: undefined,
					priority: x.priority,
					relation: x.relation,
					target: x.target,
					currencies: x.currencies,
					identifier: x.identifier ?? undefined,
					validationDone: undefined,
				})) satisfies EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["currencyConstraints"],
				lockInstrumentGroup: (grouped.lockInstrumentGroup ?? []).flatMap((b) =>
					b.tickerIdentifiers.filter((x) => !x.deleted).length > 0
						? [
								{
									priority: b.priority,
									identifier: b.identifier ?? undefined,
									target: b.target ?? false,
									relation: b.relation ?? ConstraintRelation.Equal,
									tickers: b.tickerIdentifiers.filter((x) => !x.deleted),
									validity: b.validity ?? ConstraintValidity.Unchecked,
									validationDone: undefined,
								},
						  ]
						: [],
				)?.[0] satisfies EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["lockInstrumentGroup"],
				forEachConstraint: (grouped.forEachConstraint ?? []).flatMap((x) =>
					x.tickerIdentifiers.filter((x) => !x.deleted).length > 0
						? [
								{
									identifier: x.identifier ?? undefined,
									tickers: x.tickerIdentifiers.filter((x) => !x.deleted),
									maxWeight: x.maxWeight ?? undefined,
									minWeight: x.minWeight ?? undefined,
									relation: x.relation,
									target: x.target,
									validity: undefined,
									priority: x.priority,
									validationDone: undefined,
								},
						  ]
						: [],
				) satisfies EditPortfolioV4BackendRequestPayloadMap["portfolioConstraints"]["forEachConstraint"],
				investmentUuid,
			};
		},
		riskConstraints: (a) =>
			({
				selectedRiskConstraint:
					removeEmptyItems([
						maybeMap(a.list.find((b) => b.type === "var") as EditableRiskConstraintHelper<"var">, (b) => ({
							selectedValue: b.value ?? undefined,
							validity: undefined,
							suggestedMaxValue: b.suggestedMaxValue ?? undefined,
							suggestedMinValue: b.suggestedMinValue ?? undefined,
							suggestedValue: b.suggestedValue ?? undefined,
							type: b.subType ?? undefined,
						})),
						maybeMap(
							a.list.find((b) => b.type === "volatility") as EditableRiskConstraintHelper<"volatility">,
							(b) => ({
								selectedValue: b.value ?? undefined,
								validity: undefined,
								suggestedMaxValue: b.suggestedMaxValue ?? undefined,
								suggestedMinValue: b.suggestedMinValue ?? undefined,
								suggestedValue: b.suggestedValue ?? undefined,
								type: b.subType ?? undefined,
							}),
						),
					])[0] ?? undefined,
				investmentUuid,
				maxTrackingError: maybeMap(
					a.list.find((b) => b.type === "maxTrackingError") as EditableRiskConstraintHelper<"maxTrackingError">,
					(te) => ({
						investmentReference: te.investmentReference ?? undefined,
						selectedValue: te.value ?? undefined,
						suggestedMaxValue: te.suggestedMaxValue ?? undefined,
						suggestedMinValue: te.suggestedMinValue ?? undefined,
						suggestedValue: te.suggestedValue ?? undefined,
						target: te.target ?? false,
						validity: undefined,
					}),
				),
			}) satisfies NonNullable<EditPortfolioV4BackendRequestPayloadMap["riskConstraints"]>,
		portfolioStrategy: ({ list }) =>
			({
				targetMaxTurnover: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "targetMaxTurnover"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				targetMinOperationWeight: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "targetMinOperationWeight"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				maxNumberOfInstruments: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "maxNumberOfInstruments"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				minNumberOfInstruments: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "minNumberOfInstruments"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				instrumentMinWeight: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "instrumentMinWeight"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				targetTransactionCostsInBps: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "targetTransactionCostsInBps"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				rounding: maybeMap(
					(() => findWithIndex(list, (b) => b.type === "instrumentRoundingWeight"))(),
					({ item, index }) => ({
						selectedValue: item.selectedValue ?? undefined,
						suggestedMaxValue: item.suggestedMaxValue ?? undefined,
						suggestedMinValue: item.suggestedMinValue ?? undefined,
						suggestedValue: item.suggestedValue ?? undefined,
						validity: undefined,
						priority: index + 1,
					}),
				),
				investmentUuid,
			}) satisfies NonNullable<EditPortfolioV4BackendRequestPayloadMap["portfolioStrategy"]>,
		marketView: (a) =>
			({
				investmentUuid,
				scenarioIdentifier: a.scenarioIdentifier,
				custom: a.custom,
				flexibleExpectedReturnsVolatility: a.scenarioData?.flexibleExpectedReturnsVolatility,
				defaultRegimeProbabilities: a.scenarioData?.regimeProbabilities,
				userRegimeProbabilities: a.scenarioData?.regimeUserProbability,
				forecastHorizon: a.scenarioData?.forecastHorizon,
				positioningIndicators: a.scenarioData?.positioningIndicators,
				marketViewType: a.scenarioData?.marketViewType,
				marketViewName: a.name,
			}) satisfies NonNullable<EditPortfolioV4BackendRequestPayloadMap["marketView"]>,
	};
}
