import type { InvestmentReferenceListEntry, InvestmentStatuses } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	CustomerControllerV3ApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	PdfControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useUserValue } from "$root/functional-areas/user";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import EllipsisText from "$root/ui-lib/ellipsisText";
import { downloadFileResponse } from "$root/utils/files";
import { nullary } from "$root/utils/functions";
import { parallelize } from "$root/utils/promise";
import { noRefetchDefaults, useQueryNoRefetch } from "$root/utils/react-query";
import type { DataAttributesProps, IconName } from "@mdotm/mdotui/components";
import { AsyncButton, Banner, Button, DefaultTabTitle, Dialog, Input, Tab, TabGroup } from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { noop, switchExpr } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { Set } from "immutable";
import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import MarketViewsList from "./MarketViewList";
import PortfolioList from "./PortfolioList";
import ReferenceList from "./ReferenceList";
import UniverseList from "./UniverseList";

export type CrudModalProps = {
	title: string;
	show: boolean;
	onCancel: () => void;
	onClose: () => void;
	portfolioName: string;
	submitAttribute?: DataAttributesProps;
	cancelAttribute?: DataAttributesProps;
	name?: string;
} & (
	| {
			action: Extract<UsePerformCrudActions, "delete">;
			onSubmit: () => Promise<void>;
	  }
	| {
			action: Extract<UsePerformCrudActions, "rename" | "duplicate">;
			isInvalid: boolean;
			onSubmit: (name: string) => Promise<void>;
	  }
);
export interface SortingFn {
	<T>(arg: { rowA: T; rowB: T }): 0 | 1 | -1;
}

export const statusIconMap: Record<InvestmentStatuses, { icon: IconName; size: number; title: string }> = {
	ERROR: {
		icon: "Close",
		title: "Error",
		size: 16,
	},
	READY: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Ready",
		size: 14,
	},
	PROPOSAL_READY: {
		icon: "Icon-full-small",
		title: "Proposal Ready",
		size: 12,
	},
	CALCULATING: {
		icon: "calulating",
		title: "Calculating",
		size: 12,
	},
	ACCEPTED: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Accepted",
		size: 14,
	},
	RETRIEVING_DATA: {
		icon: "calulating",
		size: 14,
		title: "Retrieving data",
	},
	REVIEW: {
		icon: "Edit",
		size: 14,
		title: "Review",
	},
	DRAFT: {
		icon: "Edit",
		size: 14,
		title: "Draft",
	},
};

export const sortingFn: SortingFn = (arg) => {
	const { rowA, rowB } = arg;
	if (rowA < rowB) {
		return 1;
	}
	if (rowA > rowB) {
		return -1;
	}
	return 0;
};

export function checkUndefined<T>(el: T | undefined, df: T): T {
	return el ? el : df;
}

export const PortfolioStudioTab = {
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
	MarketViews: "MarketViews",
} as const;

export type PortfolioStudioTab = (typeof PortfolioStudioTab)[keyof typeof PortfolioStudioTab];

const tabTitle: Record<PortfolioStudioTab, string> = {
	MarketViews: "Market Views",
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
};

const portfolioStudioDefaultTabOrdering = [
	PortfolioStudioTab.Portfolios,
	PortfolioStudioTab.References,
	PortfolioStudioTab.Universes,
	PortfolioStudioTab.MarketViews,
];

const cancelCrudModalQualifier = "CrudModal/Cancel";
const submitCrudModalQualifier = "CrudModal/Submit";
const renameCrudModalQualifier = "CrudModal/Rename";
export const CrudModal: FC<CrudModalProps> = (props) => {
	const { t } = useTranslation();
	const { watch, setValue, reset } = useForm({
		defaultValues: {
			portfolioName: `${props.portfolioName}${props.action === "duplicate" ? "_copy" : ""}`,
			invalidPortfolioName: [] as string[],
		},
	});

	const observedPortfolioName = watch("portfolioName");
	const observedInvalidPortfolioName = watch("invalidPortfolioName");

	useEffect(() => {
		if (props.show === true) {
			reset({
				invalidPortfolioName: [],
				portfolioName: `${props.portfolioName}${props.action === "duplicate" ? "_copy" : ""}`,
			});
		}
	}, [props.action, props.portfolioName, props.show, reset]);

	const bannerErrorMap: Record<Exclude<CrudModalProps["action"], "delete">, string> = {
		duplicate: t("PORTFOLIOS.MODAL.DUPLICATE.ERROR"),
		rename: t("PORTFOLIOS.MODAL.RENAME.ERROR"),
	};

	const onSubmit = (name: string) => {
		if (props.action === "delete") {
			return props.onSubmit();
		}

		return props.onSubmit(name);
	};

	const onClose = (callback: () => void) => {
		setValue("invalidPortfolioName", []);
		callback();
	};

	const isAsyncButtonDisabled =
		props.action === "duplicate" || props.action === "rename"
			? observedInvalidPortfolioName.includes(observedPortfolioName) || !!observedPortfolioName === false
			: !!observedPortfolioName === false;

	return (
		<Dialog
			size="large"
			show={props.show}
			onClose={() => onClose(props.onClose)}
			header={
				<div className="text-xl font-bold">
					<EllipsisText text={props.title} />
				</div>
			}
			footer={
				<div className="flex justify-between">
					<Button
						palette="tertiary"
						size="small"
						data-qualifier={props.name ? `${props.name}/${cancelCrudModalQualifier}` : cancelCrudModalQualifier}
						onClick={() => onClose(props.onCancel)}
						{...(props.cancelAttribute ?? {})}
					>
						{t("PORTFOLIOS.MODAL.CANCEL")}
					</Button>
					<AsyncButton
						palette="primary"
						size="small"
						data-qualifier={props.name ? `${props.name}/${submitCrudModalQualifier}` : submitCrudModalQualifier}
						onClickAsync={async () => {
							if (!observedInvalidPortfolioName.includes(observedPortfolioName)) {
								setValue("invalidPortfolioName", [...observedInvalidPortfolioName, observedPortfolioName]);
							}
							await onSubmit(observedPortfolioName);
						}}
						{...(props.submitAttribute ?? {})}
						disabled={isAsyncButtonDisabled}
					>
						{props.action === "delete" ? t("PORTFOLIOS.DELETE") : t("PORTFOLIOS.MODAL.PROCEED")}
					</AsyncButton>
				</div>
			}
		>
			<div>
				{props.action === "delete" && (
					<Trans
						i18nKey="PORTFOLIOS.MODAL.SINGLE_DELETE.DESCRIPTION"
						components={{
							strong: <strong />,
						}}
						values={{ portfolio: observedPortfolioName }}
					/>
				)}

				{(props.action === "duplicate" || props.action === "rename") && (
					<div className="grid gap-2">
						<Input
							type="text"
							size="small"
							value={observedPortfolioName}
							onChange={(e) => setValue("portfolioName", e.target.value)}
							onBlur={(e) => setValue("portfolioName", e.target.value.trim())}
							invalid={props.isInvalid && observedInvalidPortfolioName.includes(observedPortfolioName)}
							data-qualifier={props.name ? `${props.name}/${renameCrudModalQualifier}` : renameCrudModalQualifier}
						/>

						{props.isInvalid && observedInvalidPortfolioName.includes(observedPortfolioName) && (
							<Banner
								severity="error"
								variant="box"
								title={bannerErrorMap[props.action]}
								classList={{ "animate-shake": true }}
							>
								<></>
							</Banner>
						)}
					</div>
				)}
			</div>
		</Dialog>
	);
};

type PortfoliosStudioProps = {
	tab?: string;
	zipId?: string;
};

const PortfoliosStudio = ({ tab, zipId }: PortfoliosStudioProps): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const user = useUserValue();

	const currentTab = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		if (!queryParamTab) {
			return 0;
		}
		return portfolioStudioDefaultTabOrdering.findIndex((v) => v === queryParamTab);
	}, [location.search]);

	const setCurrentTab = useCallback(
		(newTab: number) => {
			if (currentTab === newTab) {
				return;
			}
			const newSearchParams = new URLSearchParams(location.search);
			newSearchParams.set(
				"tab",
				String(portfolioStudioDefaultTabOrdering.find((_t, i) => i === newTab) ?? portfolioStudioDefaultTabOrdering[0]),
			);
			history.replace({ pathname: location.pathname, search: newSearchParams.toString() });
		},
		[currentTab, history, location.pathname, location.search],
	);

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const referenceUniverseApi = useApiGen(ReferenceUniversesControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const benchmarkApi = useApiGen(BenchmarksControllerApiFactory);
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);

	const deleteMultiSelectCtx = useMultiSelect<string>();

	const handleChange = useCallback(
		(newValue: number) => {
			setCurrentTab(newValue);
			deleteMultiSelectCtx.actions.reset();
		},
		[deleteMultiSelectCtx.actions, setCurrentTab],
	);

	useQueryNoRefetch(["queryGeneratedReport", zipId, user], {
		enabled: zipId !== undefined,
		queryFn: async () => {
			const zip = await axiosExtract(pdfControllerV3Api.downloadReportsById(zipId!));
			return zip;
		},
		onError: (error: { response: { status: number }; message: string }) => {
			if (error.response.status === 400) {
				platformToast({
					children: "Unable to find an existing report in your account",
					icon: "Portfolio",
					severity: "error",
				});
			} else {
				platformToast({
					children: "Something went wrong, please try later",
					icon: "Portfolio",
					severity: "error",
				});
			}

			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
			reportPlatformError(error, "ERROR", "portfolio", "unable to download the generated zip");
			throw new Error(String(error));
		},
		onSuccess: (zip) => {
			downloadFileResponse(zip, {
				fileName: `${user.name ? `${user.name}_` : ""}Reports_${format(new Date(), "MMddyyyy")}.zip`,
			});
			//TODO i need to check the expiration date
			// if expiration date is exceeded have not notify user with platformToast
			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
		},
	});
	const currentTabName = portfolioStudioDefaultTabOrdering[currentTab];
	const [visitedTabs, setVisitedTabs] = useState<Set<typeof currentTabName>>(() => Set());
	useEffect(() => {
		setVisitedTabs((v) => v.add(currentTabName));
	}, [currentTabName]);

	//splitted platform requests
	const portfoliosStudio = useQueries({
		queries: [
			{
				queryKey: ["investmentQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentList()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["referenceQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentReferenceList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["universeQueryStudio"],
				queryFn: () => axiosExtract(referenceUniverseApi.getUserUniverses()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketQueryStudio"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewList()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["benchmarkQueryStudio"],
				queryFn: () => axiosExtract(benchmarkApi.getBenchmarkList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketStudioTypes"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewUserSettings()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserPortfolioColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserPortfolioColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserReferenceColumnOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserReferenceColumnOrderingPreferences()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserUniverseColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserUniverseColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserMarketViewColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserMarketViewColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
		],
	});

	const [
		portfolio,
		reference,
		universe,
		marketViews,
		benchmarks,
		marketViewSetting,
		userPortfolioColumnPreferences,
		userReferenceColumnPreferences,
		userUniverseColumnPreferences,
		userMarketViewColumnPreferences,
	] = portfoliosStudio;

	const dataStudio = useMemo(
		() =>
			({
				Portfolios: { list: portfolio, settings: userPortfolioColumnPreferences },
				References: {
					reference,
					benchmarks,
					settings: userReferenceColumnPreferences,
				},
				Universes: { list: universe, settings: userUniverseColumnPreferences },
				MarketViews: { list: marketViews, settings: userMarketViewColumnPreferences },
			}) satisfies Record<PortfolioStudioTab, unknown>,
		[
			benchmarks,
			marketViews,
			portfolio,
			reference,
			universe,
			userMarketViewColumnPreferences,
			userPortfolioColumnPreferences,
			userReferenceColumnPreferences,
			userUniverseColumnPreferences,
		],
	);

	const { data: userSyncInfo } = useQueryNoRefetch(["queryUserSyncInfo"], {
		enabled: user.automaticImport,
		queryFn: () => axiosExtract(customerV3Api.users1()),
	});

	useGroupEventBus("investment-update", () => portfolio.refetch().catch(noop), { timeout: 10000 });
	useGroupEventBus("benchmark-update", () => benchmarks.refetch().catch(noop), { timeout: 10000 });

	useEventBus("shared-entity", (e) => {
		if (e.level === "INFO") {
			if (e.sharedEntityType === "INVESTMENT") {
				portfolio.refetch().catch(noop);
			}

			if (e.sharedEntityType === "UNIVERSE") {
				universe.refetch().catch(noop);
			}

			if (e.sharedEntityType === "BENCHMARK") {
				benchmarks.refetch().catch(noop);
			}

			if (e.sharedEntityType === "MARKET_VIEW") {
				marketViews.refetch().catch(noop);
			}
		}
		benchmarks.refetch().catch(noop);
	});

	const { t } = useTranslation();

	const performMultiDelete = useCallback(async () => {
		if (!deleteMultiSelectCtx.data.selection || deleteMultiSelectCtx.data.selection.size === 0) {
			return;
		}

		const selection = deleteMultiSelectCtx.data.selection.toArray();

		function deleteBasedOnTabArea(portfolioStudioTab: PortfolioStudioTab, type?: "References" | "Benchmark") {
			if (portfolioStudioTab === PortfolioStudioTab.Portfolios) {
				return { delete: investmentApi.deleteInvestment };
			}

			if (portfolioStudioTab === PortfolioStudioTab.Universes) {
				return { delete: referenceUniverseApi.deleteUniverse };
			}

			if (portfolioStudioTab === PortfolioStudioTab.References && type === "References") {
				return { delete: investmentApi.deleteInvestment };
			}

			if (portfolioStudioTab === PortfolioStudioTab.References && type === "Benchmark") {
				return { delete: benchmarkApi.deleteBenchmark };
			}

			return { delete: marketViewApi.deleteMarketView };
		}

		function isItemBenchmarkOrTarget(
			id: string,
			benchmark?: Array<InvestmentReferenceListEntry>,
			target?: Array<InvestmentReferenceListEntry>,
		): "References" | "Benchmark" | undefined {
			if (benchmark?.some(({ uuid }) => uuid === id)) {
				return "Benchmark";
			}

			return target?.some(({ uuid }) => uuid === id) ? "References" : undefined;
		}

		function createListOfDeletableItem(portfolioStudioTab: PortfolioStudioTab, ids: string[]) {
			if (portfolioStudioTab !== PortfolioStudioTab.References) {
				return ids.map((id) => ({
					id,
					type: undefined,
					name: dataStudio[portfolioStudioTab].list.data?.find(({ uuid }) => uuid === id)?.name,
				}));
			}

			return ids.map((id) => {
				const referenceType = isItemBenchmarkOrTarget(
					id,
					dataStudio[portfolioStudioTab].benchmarks.data,
					dataStudio[portfolioStudioTab].reference.data,
				);
				return {
					id,
					type: referenceType,
					name:
						referenceType === "Benchmark"
							? dataStudio[portfolioStudioTab].benchmarks.data?.find(({ uuid }) => uuid === id)?.name
							: referenceType === "References"
							  ? dataStudio[portfolioStudioTab].reference.data?.find(({ uuid }) => uuid === id)?.name
							  : "-",
				};
			});
		}

		const filteredSelection = createListOfDeletableItem(currentTabName, selection);
		const succeededDeletions = [] as Array<{ name?: string; type?: string }>;
		const deleteStack = filteredSelection.map(
			(x) => () =>
				deleteBasedOnTabArea(currentTabName, x.type)
					.delete(x.id)
					.then(() => {
						succeededDeletions.push({ name: x.name, type: x.type });
					})
					.catch(() => {
						platformToast({
							children: switchExpr(currentTabName, {
								MarketViews: () => `Failed to delete your market view “${x.name ?? "-"}”`,
								Universes: () => `Failed to delete your universe “${x.name ?? "-"}”`,
								Portfolios: () => `Failed to delete your portfolio “${x.name ?? "-"}”`,
								References: () =>
									x.type === "Benchmark"
										? `Failed to delete your custom benchmark “${x.name ?? "-"}”`
										: `Failed to delete your portfolio target “${x.name ?? "-"}”`,
							}),
							severity: "error",
							icon: currentTabName === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
						});
					}),
		);

		try {
			await parallelize(deleteStack, { concurrency: 32 });
			if (currentTabName === PortfolioStudioTab.References) {
				await dataStudio[currentTabName].benchmarks.refetch({ throwOnError: true });
				await dataStudio[currentTabName].reference.refetch({ throwOnError: true });
			} else {
				await dataStudio[currentTabName].list.refetch({ throwOnError: true });
			}

			if (succeededDeletions.length > 1) {
				const multiDeleteSuccessKey = switchExpr(currentTabName, {
					MarketViews: () => "PUSH_NOTIFICATION.MULTI_MARKET_DELETED_TITLE_SUCCESS" as const,
					Universes: () => "PUSH_NOTIFICATION.MULTI_UNIVERSE_DELETED_TITLE_SUCCESS" as const,
					Portfolios: () => "PUSH_NOTIFICATION.MULTI_PORTFOLIO_DELETED_TITLE_SUCCESS" as const,
					References: () => "PUSH_NOTIFICATION.MULTI_REFERENCES_DELETED_TITLE_SUCCESS" as const,
				});

				platformToast({
					children: t(multiDeleteSuccessKey, { count: succeededDeletions.length }),
					severity: "success",
					icon: currentTabName === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
				});
			}

			if (succeededDeletions.length === 1) {
				const deletedItem = succeededDeletions[0];
				platformToast({
					children: switchExpr(currentTabName, {
						MarketViews: () => `Successfully deleted your market view “${deletedItem.name ?? "-"}”`,
						Universes: () => `Successfully deleted your universe “${deletedItem.name ?? "-"}”`,
						Portfolios: () => `Successfully deleted your portfolio “${deletedItem.name ?? "-"}”`,
						References: () =>
							deletedItem.type === "Benchmark"
								? `Successfully deleted your custom benchmark “${deletedItem.name ?? "-"}”`
								: `Successfully deleted your portfolio target “${deletedItem.name ?? "-"}”`,
					}),
					severity: "success",
					icon: currentTabName === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
				});
			}
		} catch (err) {
			reportPlatformError(
				err,
				"ERROR",
				"portfolio",
				`multi delete investments ${selection.map((x) => `"${x}"`).join(", ")}`,
			);
		} finally {
			deleteMultiSelectCtx.actions.reset();
		}
	}, [
		deleteMultiSelectCtx.data.selection,
		deleteMultiSelectCtx.actions,
		currentTabName,
		marketViewApi.deleteMarketView,
		investmentApi.deleteInvestment,
		referenceUniverseApi.deleteUniverse,
		benchmarkApi.deleteBenchmark,
		dataStudio,
		t,
	]);

	const iconWall = useMemo((): ReactNode => {
		if (currentTabName === PortfolioStudioTab.References) {
			if (dataStudio[currentTabName].benchmarks.isLoading || dataStudio[currentTabName].reference.isLoading) {
				return <IconWalls.LoadingData />;
			}

			if (dataStudio[currentTabName].benchmarks.isError || dataStudio[currentTabName].reference.isError) {
				return <IconWalls.ErrorData />;
			}

			if (
				(dataStudio[currentTabName].benchmarks.data?.length ?? 0) +
					(dataStudio[currentTabName].reference.data?.length ?? 0) ===
				0
			) {
				return (
					<IconWalls.ReferenceListEmptyData
						onCreate={() => history.push("/portfolios/upload/benchmark")}
						onUpload={() => history.push(`/portfolios/upload/target`)}
					/>
				);
			}

			return null;
		}

		if (dataStudio[currentTabName].list.isLoading || dataStudio[currentTabName].settings.isLoading) {
			return <IconWalls.LoadingData />;
		}

		if (
			dataStudio[currentTabName].list.isError ||
			dataStudio[currentTabName].settings.isError ||
			portfoliosStudio === undefined
		) {
			return <IconWalls.ErrorData />;
		}

		if (currentTabName === PortfolioStudioTab.Universes) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return (
					<IconWalls.UniverseListEmptyData
						onUpload={() => history.push(`/portfolios/upload/universe`)}
						refetch={universe.refetch}
					/>
				);
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.Portfolios) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return <IconWalls.PortfolioListEmptyData refetch={portfolio.refetch} />;
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.MarketViews) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return <IconWalls.MarketViewListEmptyData marketViewSetting={marketViewSetting.data} />;
			}
		}
		return null;
	}, [
		currentTabName,
		dataStudio,
		history,
		marketViewSetting.data,
		portfolio.refetch,
		universe.refetch,
		portfoliosStudio,
	]);

	const tabs = useMemo(() => {
		const tabComponents = {
			[PortfolioStudioTab.Portfolios]: (
				<PortfolioList
					portfolios={portfolio?.data ?? []}
					columnsPreferences={userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences}
					userSyncInfo={userSyncInfo}
					deleteMultiSelectCtx={deleteMultiSelectCtx}
					isVisible={currentTab === 0}
					refetch={{
						investments: portfolio.refetch,
						columnsPreferences: userPortfolioColumnPreferences.refetch,
					}}
				/>
			),
			[PortfolioStudioTab.References]: (
				<ReferenceList
					references={reference?.data ?? []}
					benchmarks={benchmarks?.data ?? []}
					columnsPreferences={userReferenceColumnPreferences.data?.userReferenceColumnPreferences}
					deleteMultiSelectCtx={deleteMultiSelectCtx}
					refetch={{
						benchmarks: benchmarks.refetch,
						references: reference.refetch,
						columnsPreferences: userReferenceColumnPreferences.refetch,
					}}
				/>
			),
			[PortfolioStudioTab.Universes]: (
				<UniverseList
					universes={universe?.data ?? []}
					deleteMultiSelectCtx={deleteMultiSelectCtx}
					columnsPreferences={userUniverseColumnPreferences.data?.userUniverseColumnPreferences}
					refetch={{
						universe: universe.refetch,
						columnsPreferences: userUniverseColumnPreferences.refetch,
					}}
				/>
			),
			[PortfolioStudioTab.MarketViews]: (
				<MarketViewsList
					markets={marketViews?.data ?? []}
					marketViewSetting={marketViewSetting.data}
					columnsPreferences={userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences}
					deleteMultiSelectCtx={deleteMultiSelectCtx}
					refetch={{
						marketView: marketViews.refetch,
						columnsPreferences: userMarketViewColumnPreferences.refetch,
					}}
				/>
			),
		};
		return portfolioStudioDefaultTabOrdering.map((tabName, idx) => (
			<Tab
				key={tabName + idx}
				title={(props) => (
					<DefaultTabTitle {...props} data-qualifier={`PortfolioStudio/TabItem(${tabName})`}>
						{tabTitle[tabName]}
					</DefaultTabTitle>
				)}
			>
				{iconWall || <div className="rounded-lg bg-white px-4 py-6">{tabComponents[tabName]}</div>}
			</Tab>
		));
	}, [
		benchmarks?.data,
		benchmarks.refetch,
		currentTab,
		deleteMultiSelectCtx,
		iconWall,
		marketViewSetting.data,
		marketViews?.data,
		marketViews.refetch,
		portfolio?.data,
		portfolio.refetch,
		reference?.data,
		reference.refetch,
		universe?.data,
		universe.refetch,
		userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences,
		userMarketViewColumnPreferences.refetch,
		userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences,
		userPortfolioColumnPreferences.refetch,
		userReferenceColumnPreferences.data?.userReferenceColumnPreferences,
		userReferenceColumnPreferences.refetch,
		userSyncInfo,
		userUniverseColumnPreferences.data?.userUniverseColumnPreferences,
		userUniverseColumnPreferences.refetch,
	]);

	return (
		<>
			<PageHeader title="Portfolio studio" />

			<div className="relative z-0 overflow-hidden">
				<Link
					to={typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
					})}
					className="underline text-base font-semibold absolute top-2 right-0 z-10"
					style={{ textUnderlinePosition: "under" }}
				>
					{t("WORKSPACE_SETTING.INSTRUMENTS_CUSTOMISATION")}
				</Link>
				<TabGroup palette="primary" tabIndex={currentTab} onTabChange={handleChange} aria-label="ptf-studios tabs">
					{tabs}
				</TabGroup>
			</div>
			<Dialog
				size="small"
				show={deleteMultiSelectCtx.data.state === "confirmed"}
				onClose={nullary(() => deleteMultiSelectCtx.actions.activate(deleteMultiSelectCtx.data.selection.toArray()))}
				header={t("PORTFOLIOS.MODAL.BULK_DELETE.TITLE")}
				footer={
					<div className="flex justify-between">
						<Button
							palette="tertiary"
							size="small"
							onClick={nullary(() =>
								deleteMultiSelectCtx.actions.activate(deleteMultiSelectCtx.data.selection.toArray()),
							)}
						>
							{t("BUTTON.CANCEL")}
						</Button>
						<AsyncButton palette="primary" size="small" onClickAsync={performMultiDelete}>
							{t("BUTTON.DELETE")}
						</AsyncButton>
					</div>
				}
			>
				{t("PORTFOLIOS.MODAL.BULK_DELETE.DESCRIPTION", { size: deleteMultiSelectCtx.data.selection.size })}
			</Dialog>
		</>
	);
};

export default PortfoliosStudio;
