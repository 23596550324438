import EllipsisText from "$root/ui-lib/ellipsisText";
import { Card } from "../Card";
import type { PageHeaderFooterProps } from "../../configuration";

const Header = (props: PageHeaderFooterProps & { title: string; subtitle?: string }): JSX.Element => {
	const { pageIndex, title, totalPages, subtitle } = props;

	return (
		<Card>
			<EllipsisText text={title} className="text-[28px]" />
			<div className="flex ">
				{subtitle && <h4 className="text-[18px]">{subtitle}</h4>}
				<h4 className="mt-auto text-right grow shrink-0">
					Pag {pageIndex + 1}/{totalPages}
				</h4>
			</div>
		</Card>
	);
};

export default Header;
