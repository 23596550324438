import Shere_background from "$root/assets/images/bg_login.jpg";
import Shere_logo from "$root/assets/images/logo-MDOTM-white.png";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { SyntheticEvent } from "react";
import { useCallback, useState, type FC, useContext } from "react";
import type { ReportProps } from "../../configuration/hooks/useExtractReports";
import { getDataUri, generateImageObjectUrlFromFileResponse } from "../../configuration/utils";
import { PoweredBySphere } from "./Icons/PoweredBySphere";
import { CustomReportEditorContext, updateCustomReportFile, updateCustomReportText } from "$root/pages/Report/context";
import UploadButton from "$root/components/UploadButton";

type StartingPageProps = {
	title: string;
	subtitle: string;
	customData?: ReportProps;
};

const StartingPage: FC<StartingPageProps> = ({ title, subtitle, customData }) => {
	const reportCtx = useContext(CustomReportEditorContext);
	const editable = Boolean(reportCtx);

	const [images, setImages] = useState({
		backgroundImage: {
			src: customData?.backgroundImage?.realData
				? generateImageObjectUrlFromFileResponse(customData.backgroundImage)
				: Shere_background,
			file: null,
		},
		clientLogo: {
			src: customData?.clientLogo?.realData
				? generateImageObjectUrlFromFileResponse(customData.clientLogo)
				: Shere_logo,
			file: null,
		},
	});

	const handleFileChange = useCallback(
		(name: keyof typeof images) => async (file: File | null) => {
			const url = await getDataUri(file);
			setImages((cur) => ({ ...cur, [name]: { src: url, file } }));
			if (reportCtx) {
				updateCustomReportFile(reportCtx.update, name, file);
			}
		},
		[reportCtx],
	);

	const handleTextChange = useCallback(
		(name: "footerDescription") => (e: SyntheticEvent) => {
			if (reportCtx && e.currentTarget.textContent !== null) {
				updateCustomReportText(reportCtx.update, name, e.currentTarget.textContent);
			}
		},
		[reportCtx],
	);

	return (
		<div className="h-[297mm] min-h-56 relative @container">
			<DropzoneArea
				classList="group dropzone"
				onChange={unpromisify(handleFileChange("backgroundImage"))}
				accept="image/*"
				disabled={!editable}
			>
				<img
					src={images.backgroundImage.src}
					alt="sphere_bg"
					className="object-cover h-[297mm] w-[210mm] md:w-full"
					style={{ objectPosition: "28% 0" }}
					loading="lazy"
				/>
				{editable && (
					<div className="absolute top-0 right-0 pr-10 pt-10 z-10 flex">
						<UploadButton
							classList="transition-opacity opacity-0 group-hover-[.dropzone]:opacity-100 focus:opacity-100"
							label=""
							size="small"
							onChange={unpromisify(handleFileChange("backgroundImage"))}
							accept="image/*"
						/>
					</div>
				)}
			</DropzoneArea>
			{!editable && customData?.backgroundImage?.realData && (
				<div className="absolute top-3 inset-x-0">
					<div className="flex -ml-3">
						<div className="mx-auto">
							<PoweredBySphere />
						</div>
					</div>
				</div>
			)}

			<div className="absolute top-[20%] inset-x-0">
				<DropzoneArea
					onChange={unpromisify(handleFileChange("clientLogo"))}
					accept="image/*"
					disabled={!editable}
					classList="z-10 group dropzone"
				>
					<div className="flex h-20">
						<img src={images.clientLogo.src} alt="sphere_logo" className="object-cover mx-auto" />
					</div>
					{editable && (
						<div className="absolute inset-0 items-center justify-center z-10 flex">
							<UploadButton
								classList="transition-opacity opacity-0 group-hover-[.dropzone]:opacity-100 focus:opacity-100"
								label=""
								size="small"
								onChange={unpromisify(handleFileChange("clientLogo"))}
								accept="image/*"
							/>
						</div>
					)}
				</DropzoneArea>
			</div>

			<div className="absolute bottom-[25%] inset-x-0">
				<div className="w-full text-center ml-auto py-4 px-6 bg-[#2F3541]/75">
					<p className="text-2xl text-white font-semibold">{title}</p>
					<p className="text-xl text-white">{subtitle}</p>
				</div>
			</div>
			<div className="absolute bottom-0 inset-x-0">
				<p
					className="text-white w-fit mx-auto mb-4 text-[0.425rem] text-center xl:text-xs min-w-[100px]"
					contentEditable={editable}
					suppressContentEditableWarning
					onBlur={handleTextChange("footerDescription")}
				>
					{customData?.footer?.description ??
						"MDOTM Ltd (FRN: 824056) is an appointed representative of Thornbridge Investment Management LLP (FRN: 713859) which is authorised and regulated by the Financial Conduct Authority."}
				</p>
			</div>
		</div>
	);
};

export default StartingPage;
