import Page from "$root/components/PageLayout";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { Text } from "@mdotm/mdotui/components";

export function Page404(): JSX.Element {
	const location = useLocation();

	return (
		<Page hideAppBar hideSideBar>
			<div className="flex items-center justify-center h-screen">
				<div className="text-center">
					<div className="flex gap-4 items-center">
						<Text type="Title/L">404</Text> |{" "}
						<h2>
							Page <strong>{location.pathname}</strong> not found
						</h2>
					</div>
					<Link
						to="/"
						className={`hover:underline hover:rotate-90 font-semibold text-[color:${themeCSSVars.global_palette_primary_600}]`}
					>
						Back to home page
					</Link>
				</div>
			</div>
		</Page>
	);
}

export default Page404;
