import { toClassName } from "$root/utils/react-dom-extra";
import type { FC } from "react";

const EllipsisText: FC<{ text: string | undefined; deleted?: boolean; className?: string }> = ({
	text,
	deleted,
	className,
}) => (
	<p
		title={text}
		className={toClassName({
			"truncate w-full pr-2": true,
			"line-through opacity-60": Boolean(deleted),
			[className ?? ""]: className !== undefined,
		})}
	>
		{text ?? ""}
	</p>
);

export default EllipsisText;
