import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { passwordRegex } from "$root/functional-areas/user/password";
import { useApiGen } from "$root/api/hooks";
import { AuthControllerApiFactory } from "$root/api/api-gen";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Form,
	FormField,
	LinkButton,
	SubmitButton,
	TextInput,
	Icon,
	PasswordInputWithTooltip,
} from "@mdotm/mdotui/components";
import { exclude } from "$root/utils/objects";
import { reportPlatformError } from "$root/api/error-reporting";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { platformToast } from "$root/notification-system/toast";
import { login } from "$root/functional-areas/user/auth";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";

const ForgotPassword = (): JSX.Element => {
	const { t } = useTranslation();
	const search = useLocation().search;
	const [token, setToken] = useState(new URLSearchParams(search).get("token"));
	const [email, setEmail] = useState("");

	const { push } = useTypedNavigation();
	const usersApi = useApiGen(AuthControllerApiFactory);

	const handleChangePasswordSubmit = useCallback(
		async ({ newPassword }: { newPassword: string }) => {
			try {
				await usersApi.reset({
					email,
					password: newPassword,
					reset_token: token || "",
				});
				await login({ email, password: newPassword });
				push("Index", {});
				platformToast({
					children: t("changepwd.CHANGE_SUCCESS"),
					severity: "success",
					icon: "Top-menu-user",
				});
			} catch (err) {
				reportPlatformError(err, "ERROR", "user", `reset password for username "${email}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Top-menu-user",
				});
			}
		},
		[email, push, t, token, usersApi],
	);
	const changePasswordForm = useForm({
		defaultValues: {
			cnfPassword: "",
			newPassword: "",
		},
		resolver: yupResolver(
			Yup.object({
				newPassword: Yup.string().required(t("REQUIRED_FIELD")).matches(passwordRegex(), t("forgotpwd.NOT_MEET_REQ")),
				cnfPassword: Yup.string()
					.required(t("REQUIRED_FIELD"))
					.oneOf([Yup.ref("newPassword")], t("forgotpwd.FIELD_NOT_MATCH")),
			}),
		),
	});
	const handleRecoverySubmit = useCallback(
		async ({ email: inputEmail }: { email: string }) => {
			try {
				await usersApi.forgot({ email: inputEmail });
				platformToast({
					children: t("forgotpwd.FORGOT_SUCCESS"),
					severity: "success",
					icon: "Top-menu-user",
				});
				// history.push("/");
			} catch (err) {
				reportPlatformError(err, "ERROR", "user", `recover password for username "${inputEmail}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Top-menu-user",
				});
				throw err;
			}
		},
		[t, usersApi],
	);

	const userFetcher = useMutation(
		async (currToken: string) => {
			const { data: fetchedEmail } = await usersApi.retrieveEmail({
				reset_token: currToken,
			});
			return fetchedEmail;
		},
		{
			onError(err) {
				console.error(err);
				platformToast({
					children: t("forgotpwd.INVALID_LINK"),
					severity: "error",
					icon: "Top-menu-user",
				});
				setToken("");
			},
			onSuccess(fetchedEmail) {
				setEmail(fetchedEmail);
			},
		},
	);

	useEffect(() => {
		if (token && userFetcher.status === "idle") {
			userFetcher.mutate(token);
		}
	}, [token, userFetcher]);

	const sendEmailForm = useForm({
		defaultValues: {
			email: "",
		},
		resolver: yupResolver(
			Yup.object({
				email: Yup.string().required(t("REQUIRED_FIELD")).email(),
			}),
		),
	});

	return (
		<div className="flex flex-col items-center pt-12">
			<div>
				<div className="text-white text-center text-3xl drop-shadow mb-4">
					<h1>
						{t("login.WELCOME_1")}
						<br />
						{t("login.WELCOME_2")}
					</h1>
				</div>
				<div className="px-20 py-6 bg-white shadow rounded">
					{token ? (
						<>
							<div className="mb-4">
								<h2 className="text-xl text-center">{t("forgotpwd.CHANGE_PWD")}</h2>
							</div>

							<Form
								noValidate
								onSubmitAsync={() => changePasswordForm.handleSubmit(handleChangePasswordSubmit)()}
								classList="space-y-4 w-[15.625rem]"
							>
								<FormField label={t("login.USERNAME_LABEL")} classList="w-full">
									<TextInput
										data-qualifier="forgotPassword(step2)-email"
										value={email}
										readOnly
										placeholder={t("login.USERNAME_PLACEHOLDER")}
										rightContent={<Icon icon="Top-menu-user" />}
									/>
								</FormField>
								<FormField label={t("changepwd.NEW_PWD")} classList="w-full">
									{({ id }) => (
										<FormController
											control={changePasswordForm.control}
											name="newPassword"
											render={({ field }) => (
												<PasswordInputWithTooltip
													id={id}
													data-qualifier="forgotPassword(step2)-newPassword"
													autoComplete="new-password"
													error={
														changePasswordForm.formState.errors.newPassword && (
															<>
																8 characters or longer.
																<br />
																At least:
																<br />
																- one lower-case letter,
																<br />
																- one upper-case letter,
																<br />
																- one number,
																<br />- one symbol: !@#$%^&_*
															</>
														)
													}
													{...exclude(field, ["ref"])}
												/>
											)}
										/>
									)}
								</FormField>
								<FormField label={t("changepwd.CONFIRM_PWD")} classList="w-full">
									{({ id }) => (
										<FormController
											control={changePasswordForm.control}
											name="cnfPassword"
											render={({ field }) => (
												<PasswordInputWithTooltip
													id={id}
													data-qualifier="forgotPassword(step2)-newPasswordCheck"
													autoComplete="new-password"
													error={changePasswordForm.formState.errors.cnfPassword?.message}
													{...exclude(field, ["ref"])}
												/>
											)}
										/>
									)}
								</FormField>
								<div className="flex flex-col items-center pt-4 space-y-4">
									<SubmitButton data-qualifier="forgotPassword(step2)-submit">{t("forgotpwd.FORGOT_PWD")}</SubmitButton>
									<LinkButton
										data-qualifier="forgotPassword(step2)-login"
										href="/login"
										palette="primary"
										classList="font-bold"
										size="x-small"
									>
										{t("forgotpwd.LOGIN")}
									</LinkButton>
								</div>
							</Form>
						</>
					) : (
						<>
							<div className="mb-4">
								<h2 className="text-xl text-center">{t("forgotpwd.WELCOME")}</h2>
							</div>

							<Form
								noValidate
								onSubmitAsync={() => sendEmailForm.handleSubmit(handleRecoverySubmit)()}
								classList="space-y-4 w-[15.625rem]"
							>
								<FormField
									label={t("login.USERNAME_LABEL")}
									classList="w-full"
									error={sendEmailForm.formState.errors.email?.message}
								>
									{({ id, invalid }) => (
										<FormController
											control={sendEmailForm.control}
											name="email"
											render={({ field: { onChange, ref, ...controllerProps } }) => (
												<TextInput
													data-qualifier="forgotPassword(step1)-email"
													required
													autoComplete="email"
													placeholder={t("login.USERNAME_PLACEHOLDER")}
													autoFocus
													id={id}
													invalid={invalid}
													rightContent={<Icon icon="Top-menu-user" />}
													onChangeText={onChange}
													innerRef={ref}
													{...controllerProps}
												/>
											)}
										/>
									)}
								</FormField>
								<div className="flex flex-col items-center pt-4 space-y-4">
									<SubmitButton data-qualifier="forgotPassword(step1)-submit">{t("forgotpwd.FORGOT_PWD")}</SubmitButton>
									<LinkButton
										data-qualifier="forgotPassword(step1)-login"
										href="/login"
										palette="primary"
										classList="font-bold"
										size="x-small"
									>
										{t("forgotpwd.LOGIN")}
									</LinkButton>
								</div>
							</Form>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
