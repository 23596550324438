export function ShareEntityIcon(): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="136"
			height="136"
			fill="none"
			viewBox="0 0 136 136"
		>
			<path fill="url(#pattern0)" d="M0 0H136V136H0z" />
			<path fill="#fff" d="M0 0H120V120H0z" transform="translate(8 8)" />
			<path
				fill="#C3C9D5"
				fillRule="evenodd"
				d="M34.707 108.774a3.827 3.827 0 013.314-.906v.001c1.191.237 2.188 1.02 2.671 2.097a3.49 3.49 0 01-.243 3.315c-.636 1.001-1.738 1.639-2.953 1.712a3.796 3.796 0 01-3.148-1.348 3.506 3.506 0 01-.664-3.262l-6.403-3.779a3.801 3.801 0 01-2.628.979 3.789 3.789 0 01-2.587-1.076A3.532 3.532 0 0121 103.99a3.53 3.53 0 011.066-2.516 3.784 3.784 0 012.587-1.076 3.805 3.805 0 012.629.979l6.402-3.78a3.484 3.484 0 01.4-2.88 3.727 3.727 0 012.477-1.653 3.826 3.826 0 012.935.656 3.567 3.567 0 011.471 2.535 3.515 3.515 0 01-.953 2.757 3.788 3.788 0 01-2.754 1.175c-.95 0-1.864-.35-2.553-.98l-6.403 3.779c.199.656.199 1.352 0 2.008l6.403 3.78zm1.416-13.486a1.782 1.782 0 00-.113.1v-.001a.739.739 0 00-.043.043 1.667 1.667 0 00-.476 1.163c0 .451.188.883.518 1.202a1.8 1.8 0 001.247.497c.467 0 .916-.18 1.246-.499.33-.318.517-.751.517-1.202 0-.45-.185-.883-.516-1.203a1.808 1.808 0 00-2.367-.11m-11.404 10.418c.468-.001.918-.18 1.25-.5.33-.32.517-.754.517-1.206 0-.452-.186-.886-.517-1.206a1.8 1.8 0 00-1.25-.499c-.47 0-.919.179-1.25.499-.332.32-.518.754-.518 1.206 0 .453.186.887.518 1.206.331.32.78.5 1.25.5zm12.528 7.389c.466 0 .915-.179 1.245-.498l.002-.001a1.67 1.67 0 00.516-1.202c0-.451-.185-.883-.515-1.203a1.798 1.798 0 00-1.245-.5c-.47 0-.919.18-1.25.5-.331.32-.518.753-.518 1.205 0 .452.187.884.518 1.202.33.319.78.497 1.247.497z"
				clipRule="evenodd"
			/>
			<g filter="url(#filter0_d_120_45611)">
				<path
					fill="#fff"
					d="M99.115 47.54a13.094 13.094 0 00-8.854-3.88 8.066 8.066 0 00-2.334-4.622 8.035 8.035 0 00-4.646-2.253 15.004 15.004 0 00-4.588-8.734 14.941 14.941 0 00-8.992-3.997 14.84 14.84 0 00-9.51 2.484 15.058 15.058 0 00-5.92 7.879 11.447 11.447 0 00-7.233 2.934c-2.001 1.79-3.312 4.229-3.703 6.897a13.218 13.218 0 00-7.302 5.627 13.258 13.258 0 00-1.886 9.034 13.263 13.263 0 004.45 8.087 13.167 13.167 0 008.624 3.212h8.87c0 .243.093-.173.265 0 .173.173.402 0 .644 0h18.646s.472.173.644 0c.172-.173.264.243.264 0h3.692c.241 0 .471.173.644 0 .172-.173.264.243.264 0h8.705c5.6 0 11.395-4.436 12.729-9.901 1.161-4.76-.173-9.485-3.462-12.778l-.011.012z"
				/>
			</g>
			<path
				fill="#C3C9D5"
				fillRule="evenodd"
				d="M38.636 66.965a13.366 13.366 0 008.697 3.224l.023.011h9.432V67.62h-9.42c-3.638 0-6.908-1.756-8.985-4.82a10.32 10.32 0 01-1.537-3.66c-1.205-5.864 2.375-11.463 8.147-12.772.23-.057.424-.183.573-.355.138-.172.23-.39.241-.62a9.063 9.063 0 012.834-6.013 8.962 8.962 0 016.174-2.467h.367c.31.012.585-.08.826-.252.241-.184.413-.436.482-.723a12.484 12.484 0 0113.23-9.317 12.472 12.472 0 017.78 3.706 12.541 12.541 0 013.523 7.872c.023.24.138.47.298.654.161.183.39.298.631.32.127.024.264.024.402.024 1.503 0 2.972.631 4.016 1.721a5.593 5.593 0 011.55 4.085c0 .287.102.562.298.757.195.195.47.31.745.31h.884c2.157 0 4.223.631 6.001 1.848 3.833 2.662 5.531 7.32 4.315 11.876-1.148 4.303-5.75 7.791-10.27 7.791H80.2v.16H79.5v2.296h.701v.126h9.669c5.726 0 11.486-4.452 12.84-9.937 1.136-4.636-.115-9.352-3.35-12.691l-.138-.138a13.212 13.212 0 00-8.813-3.89 8.183 8.183 0 00-2.352-4.555 8.29 8.29 0 00-4.613-2.26 15.148 15.148 0 00-4.601-8.652 15.16 15.16 0 00-9.054-3.994 15.112 15.112 0 00-9.604 2.502 15.113 15.113 0 00-5.932 7.803 11.574 11.574 0 00-7.206 2.937 11.621 11.621 0 00-3.73 6.839 13.4 13.4 0 00-7.275 5.623 13.388 13.388 0 00-1.893 9.087 13.298 13.298 0 004.487 8.124zm17.716.929v2.008h.138v-2.008h-.138zM37.296 27H35v2.295h2.295V27zm4.188 0h-2.295v2.295h2.295V27zM98.59 85.582h2.581V83H98.59v2.582zm4.59 0h2.581V83h-2.581v2.582zm-9.18 0h2.582V83H94v2.582z"
				clipRule="evenodd"
			/>
			<g filter="url(#filter1_d_120_45611)">
				<path fill="#fff" d="M52 47l23.941.731L85 57.971V96l-33-.731V47z" />
			</g>
			<path
				fill="#94D0C4"
				fillRule="evenodd"
				d="M84.912 54.218c.206.218.332.504.332.814l.012.012v40.338a.903.903 0 01-.907.906H52.906a.903.903 0 01-.906-.906V47.906a.903.903 0 01.907-.906h24.305c.31 0 .597.115.815.333l6.885 6.885zm-3.58-.333l-2.972-2.972v2.972h2.971zM54.294 93.97h28.654V56.18h-5.978a.903.903 0 01-.907-.907v-5.978h-21.77V93.97zm6.556-26.108h16.351v16.352H60.851V67.862zm2.582 6.885h4.303v-4.303h-4.303v4.303zm4.303 6.885v-4.303h-4.303v4.303h4.303zm6.884-4.303h-4.303v4.303h4.303v-4.303zm-4.303-6.885v4.303h4.303v-4.303h-4.303z"
				clipRule="evenodd"
			/>
			<defs>
				<pattern id="pattern0" width="0.029" height="0.029" patternContentUnits="objectBoundingBox">
					<use transform="scale(.00735)" xlinkHref="#image0_120_45611" />
				</pattern>
				<filter
					id="filter0_d_120_45611"
					width="97"
					height="74.283"
					x="20"
					y="12.003"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_120_45611" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_120_45611" result="shape" />
				</filter>
				<filter
					id="filter1_d_120_45611"
					width="61"
					height="77"
					x="38"
					y="35"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_120_45611" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_120_45611" result="shape" />
				</filter>
				<image
					id="image0_120_45611"
					width="4"
					height="4"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAfSURBVHgBlcchAQAADAIwor/bFQHoAxLL3IDy1K0JA4lVC4UYicp2AAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
}
