import { LeavePrompt } from "$root/components/LeavePrompt";
import { Button, Form, Icon, SubmitButton, Tab, TabGroup, DefaultTabTitle } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Control, FormState, UseFormSetValue } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { MultipleNotificationBox } from "./boxes/MultipleNotificationBox";
import { NotificationBox } from "./boxes/NotificationBox";
import { ConstraintBreachIcon } from "./icons/ConstraintBreachIcon";
import { ConstraintBreachWarningIcon } from "./icons/ConstraintBreachWarningIcon";
import { InstrumentChangeIcon } from "./icons/InstrumentChangeIcon";
import { MarketChangeIcon } from "./icons/MarketChangeIcon";
import { PositioningChangeIcon } from "./icons/PositioningChangeIcon";
import { StatusChangeIcon } from "./icons/StatusChangeIcon";
import type { NotificationMap } from "./map";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useUserValue } from "../user";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { ShareEntityIcon } from "./icons/ShareEntityIcon";
import { NewMarketChangeIcon } from "./icons/NewMarketChangeIcon";
import { switchExpr } from "@mdotm/mdotui/utils";

const PortfolioEvents = ({
	control,
	formState,
	setValue,
}: {
	control: Control<NotificationMap, any>;
	formState: FormState<NotificationMap>;
	setValue: UseFormSetValue<NotificationMap>;
}) => {
	const { t } = useTranslation();
	return (
		<div className="grid gap-4 mb-8">
			<NotificationBox
				infoGraph={<StatusChangeIcon />}
				checkbox={{
					children: "Status change",
					name: "PortfolioStatusUpdateAlertCheck.checked",
					value: useWatch({ control, name: "PortfolioStatusUpdateAlertCheck.checked" }),
					control,
					formState,
				}}
			>
				<p className="mb-2 text-sm">
					{t("NOTIFICATION_SETTINGS.PORTFOLIO_EVENTS.STATUS_CHANGE.UPDATE_DESCRIPTION.TITLE")}
				</p>
				<ul className="list-disc pl-8 text-sm">
					{t("NOTIFICATION_SETTINGS.PORTFOLIO_EVENTS.STATUS_CHANGE.UPDATE_DESCRIPTION.VALUES", {
						returnObjects: true,
					}).map((value) => (
						<li key={value}>{value}</li>
					))}
				</ul>
			</NotificationBox>
			<div className={`h-px w-full bg-[${themeCSSVars.palette_N200}]`} />
			<MultipleNotificationBox
				infoGraph={<ConstraintBreachIcon />}
				label="Constraint breach"
				onChange={(newValue) => {
					setValue("PortfolioConstraintsAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("RiskConstraintAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("PortfolioStrategyBreachedAlertCheck.checked", newValue, { shouldDirty: true });
				}}
				checkboxes={[
					{
						children: "Portfolio constraints",
						name: "PortfolioConstraintsAlertCheck.checked",
						value: useWatch({ control, name: "PortfolioConstraintsAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Risk constraints",
						name: "RiskConstraintAlertCheck.checked",
						value: useWatch({ control, name: "RiskConstraintAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Operative constraints",
						name: "PortfolioStrategyBreachedAlertCheck.checked",
						value: useWatch({ control, name: "PortfolioStrategyBreachedAlertCheck.checked" }),
						control,
						formState,
					},
				]}
			>
				<p className="mb-2 text-sm">A notification will be sent when one or more constraints have been breached</p>
			</MultipleNotificationBox>
			<div className={`h-px w-full bg-[${themeCSSVars.palette_N200}]`} />
			<MultipleNotificationBox
				infoGraph={<ConstraintBreachWarningIcon />}
				label="Constraint breach - early warning"
				onChange={(newValue) => {
					setValue("PortfolioConstraintWarningAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("RiskConstraintWarningAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("PortfolioStrategyWarningAlertCheck.checked", newValue, { shouldDirty: true });
				}}
				checkboxes={[
					{
						children: "Portfolio constraints",
						name: "PortfolioConstraintWarningAlertCheck.checked",
						value: useWatch({ control, name: "PortfolioConstraintWarningAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Risk constraints",
						name: "RiskConstraintWarningAlertCheck.checked",
						value: useWatch({ control, name: "RiskConstraintWarningAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Operative constraints",
						name: "PortfolioStrategyWarningAlertCheck.checked",
						value: useWatch({ control, name: "PortfolioStrategyWarningAlertCheck.checked" }),
						control,
						formState,
					},
				]}
			>
				<p className="mb-2 text-sm">
					A notification will be sent when one or more constraints is close to be breached.{" "}
				</p>
			</MultipleNotificationBox>
			<div className={`h-px w-full bg-[${themeCSSVars.palette_N200}]`} />
			<NotificationBox
				infoGraph={<InstrumentChangeIcon />}
				checkbox={{
					children: "Instrument changes",
					name: "InstrumentsChangesChildAlertCheck.checked",
					value: useWatch({ control, name: "InstrumentsChangesChildAlertCheck.checked" }),
					control,
					formState,
				}}
			>
				<p className="mb-2 text-sm">
					A notification will be sent when an instrument present in your account has changed, including:{" "}
				</p>
				<ul className="list-disc pl-8 text-sm">
					<li>ISIN delisted</li>
					<li>ISIN changed</li>
				</ul>
			</NotificationBox>
			<div className={`h-px w-full bg-[${themeCSSVars.palette_N200}]`} />
			<NotificationBox
				infoGraph={<NewMarketChangeIcon />}
				checkbox={{
					children: "Market scenario changes",
					name: "MarketViewExpiredAlertCheck.checked",
					value: useWatch({ control, name: "MarketViewExpiredAlertCheck.checked" }),
					control,
					formState,
				}}
			>
				<p className="mb-2 text-sm">A notification will be sent when your market view forecast horizon expires.</p>
			</NotificationBox>
		</div>
	);
};

const MarketEvents = ({
	control,
	formState,
	setValue,
}: {
	control: Control<NotificationMap, any>;
	formState: FormState<NotificationMap>;
	setValue: UseFormSetValue<NotificationMap>;
}) => {
	return (
		<div className="grid gap-4 mb-8">
			<MultipleNotificationBox
				infoGraph={<PositioningChangeIcon />}
				label="Positioning changes"
				onChange={(newValue) => {
					setValue("EquityMarketPositioningAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("FixedIncomeMarketPositioningAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("CommoditiesMarketPositioningAlertCheck.checked", newValue, { shouldDirty: true });
				}}
				checkboxes={[
					{
						children: "Equity",
						name: "EquityMarketPositioningAlertCheck.checked",
						value: useWatch({ control, name: "EquityMarketPositioningAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Fixed income",
						name: "FixedIncomeMarketPositioningAlertCheck.checked",
						value: useWatch({ control, name: "FixedIncomeMarketPositioningAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Commodities",
						name: "CommoditiesMarketPositioningAlertCheck.checked",
						value: useWatch({ control, name: "CommoditiesMarketPositioningAlertCheck.checked" }),
						control,
						formState,
					},
				]}
			>
				<p className="mb-2 text-sm">
					Notify me every time Sphere detects a regime shift on the macro and micro asset class. Please select the macro
					asset classes you want to monitoring
				</p>
			</MultipleNotificationBox>
			<div className={`h-px w-full bg-[${themeCSSVars.palette_N200}]`} />
			<MultipleNotificationBox
				infoGraph={<MarketChangeIcon />}
				label="Asset Classes regime changes"
				onChange={(newValue) => {
					setValue("EquityMarketRegimeChangesAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("FixedIncomeMarketRegimeChangesAlertCheck.checked", newValue, { shouldDirty: true });
					setValue("CommoditiesMarketRegimeChangesAlertCheck.checked", newValue, { shouldDirty: true });
				}}
				checkboxes={[
					{
						children: "Equity",
						name: "EquityMarketRegimeChangesAlertCheck.checked",
						value: useWatch({ control, name: "EquityMarketRegimeChangesAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Fixed income",
						name: "FixedIncomeMarketRegimeChangesAlertCheck.checked",
						value: useWatch({ control, name: "FixedIncomeMarketRegimeChangesAlertCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Commodities",
						name: "CommoditiesMarketRegimeChangesAlertCheck.checked",
						value: useWatch({ control, name: "CommoditiesMarketRegimeChangesAlertCheck.checked" }),
						control,
						formState,
					},
				]}
			>
				<p className="mb-2 text-sm">
					Notify me every time Sphere detects a macro or micro asset class shift on the suggested positioning. Please
					select the macro asset classes you want to monitor.
				</p>
			</MultipleNotificationBox>
		</div>
	);
};

const SharingEvents = ({
	control,
	formState,
	setValue,
}: {
	control: Control<NotificationMap, any>;
	formState: FormState<NotificationMap>;
	setValue: UseFormSetValue<NotificationMap>;
}) => {
	return (
		<div className="grid gap-4 mb-8">
			<MultipleNotificationBox
				infoGraph={<ShareEntityIcon />}
				label="Let me know when:"
				onChange={(newValue) => {
					setValue("SharingEntityEmailOnEditCheck.checked", newValue, { shouldDirty: true });
					setValue("SharingEntityEmailOnShareCheck.checked", newValue, { shouldDirty: true });
					setValue("SharingEntityEmailOnOwnerPassedCheck.checked", newValue, { shouldDirty: true });
				}}
				checkboxes={[
					{
						children: "A file is shared with me",
						name: "SharingEntityEmailOnShareCheck.checked",
						value: useWatch({ control, name: "SharingEntityEmailOnShareCheck.checked" }),
						control,
						formState,
					},
					{
						children: "Someone modifies a file that i own",
						name: "SharingEntityEmailOnEditCheck.checked",
						value: useWatch({ control, name: "SharingEntityEmailOnEditCheck.checked" }),
						control,
						formState,
					},
					{
						children: "When the ownership of a file is passed to me",
						name: "SharingEntityEmailOnOwnerPassedCheck.checked",
						value: useWatch({ control, name: "SharingEntityEmailOnOwnerPassedCheck.checked" }),
						control,
						formState,
					},
				]}
			/>
		</div>
	);
};

type NotificationSettingsProps = {
	notifications: NotificationMap;
	onSubmit(items: NotificationMap): Promise<void>;
};

const NotificationSettings = (props: NotificationSettingsProps): JSX.Element => {
	const user = useUserValue();

	const TABS = useMemo(() => {
		const services: Array<"Portfolio events" | "Market events" | "Sharing options"> = [];
		if (user.availableServices.includes("INVESTMENTS")) {
			services.push("Portfolio events");
		}
		if (user.availableServices.includes("REPORTS")) {
			services.push("Market events");
		}
		services.push("Sharing options");
		return services;
	}, [user.availableServices]);

	const [currentTab, setCurrentTab] = useState<number>(0);

	const common = z.object({
		checked: z.boolean(),
		type: z.string(),
	});

	const validation = {
		PortfolioStatusUpdateAlertCheck: common,
		PortfolioConstraintsAlertCheck: common,
		RiskConstraintAlertCheck: common,
		PortfolioStrategyBreachedAlertCheck: common,
		PortfolioConstraintWarningAlertCheck: common,
		RiskConstraintWarningAlertCheck: common,
		PortfolioStrategyWarningAlertCheck: common,
		InstrumentsChangesChildAlertCheck: common,
		MarketViewExpiredAlertCheck: common,
		// GrowthMarketViewAlignmentAlertCheck: common.merge(
		// 	z.object({ threshold: z.number({ invalid_type_error: "Insert a valid value" }) }),
		// ),
		// LateralPhaseMarketViewAlignmentAlertCheck: common.merge(
		// 	z.object({ threshold: z.number({ invalid_type_error: "Insert a valid value" }) }),
		// ),
		// HighStressMarketViewAlignmentAlertCheck: common.merge(
		// 	z.object({ threshold: z.number({ invalid_type_error: "Insert a valid value" }) }),
		// ),
		EquityMarketPositioningAlertCheck: common,
		FixedIncomeMarketPositioningAlertCheck: common,
		CommoditiesMarketPositioningAlertCheck: common,
		EquityMarketRegimeChangesAlertCheck: common,
		FixedIncomeMarketRegimeChangesAlertCheck: common,
		CommoditiesMarketRegimeChangesAlertCheck: common,
		SharingEntityEmailOnEditCheck: common,
		SharingEntityEmailOnOwnerPassedCheck: common,
		SharingEntityEmailOnShareCheck: common,
	};

	const { control, reset, setValue, formState, handleSubmit } = useForm<NotificationMap>({
		defaultValues: props.notifications,
		resolver: zodResolver(z.object(validation)),
	});

	// formState.dirtyFields --> use form dirty field if we want to filters the submitted data
	const { t } = useTranslation();

	useEffect(() => {
		reset(props.notifications);
	}, [reset, props.notifications]);

	const history = useHistory();
	const tabRef = useRef<HTMLDivElement>(null);
	return (
		<>
			<TabGroup palette="primary" tabIndex={currentTab} onTabChange={setCurrentTab}>
				{TABS.map((tabname, idx) => (
					<Tab
						classList="rounded bg-white px-4 py-6 overflow-hidden"
						key={idx}
						innerRef={tabRef}
						title={(titleProps) => {
							return (
								<DefaultTabTitle
									{...titleProps}
									iconSize={16}
									icon={switchExpr(tabname, {
										"Market events": () => "News-category-Market-view-aligment" as const,
										"Portfolio events": () => "Portfolio" as const,
										"Sharing options": () => "share" as const,
									})}
								>
									{tabname}
								</DefaultTabTitle>
							);
						}}
					>
						<Form noValidate onSubmitAsync={() => handleSubmit(props.onSubmit, console.log)()}>
							{TABS.indexOf("Portfolio events") === currentTab && (
								<PortfolioEvents control={control} formState={formState} setValue={setValue} />
							)}
							{TABS.indexOf("Market events") === currentTab && (
								<MarketEvents control={control} formState={formState} setValue={setValue} />
							)}
							{TABS.indexOf("Sharing options") === currentTab && (
								<SharingEvents control={control} formState={formState} setValue={setValue} />
							)}

							<div className={`h-px mb-4 w-full bg-[${themeCSSVars.palette_N200}]`} />
							<div className="flex items-center justify-between">
								<Button size="small" onClick={() => history.goBack()} palette="tertiary">
									Cancel
								</Button>
								<SubmitButton disabled={formState.isDirty === false}>Save</SubmitButton>
							</div>
						</Form>
					</Tab>
				))}
			</TabGroup>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={formState.isDirty}
				pathToNotBlock={["/login"]}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
};

export default NotificationSettings;
