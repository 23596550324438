import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";

export function FavouriteIcon({
	color,
	style,
	classList,
	stroke,
}: { color: string; stroke: string } & StylableProps): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="14"
			fill="none"
			viewBox="0 0 16 14"
			style={style}
			className={toClassName(classList)}
		>
			<path
				fill={color}
				stroke={stroke}
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M8 .93l1.957 3.996 4.376.645-3.166 3.108.747 4.39L8 10.995 4.086 13.07l.747-4.39L1.667 5.57l4.376-.645L8 .931z"
				clipRule="evenodd"
			/>
		</svg>
	);
}
