import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const ThirdQuartile1: FC<Omit<SvgProps, "viewBox" | "children">> = (props) => {
	return (
		<Svg viewBox={{ width: 15, height: 9 }} fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 1L8 1C8.55229 1 9 1.44772 9 2L9 7C9 7.55229 8.55229 8 8 8L-6.79955e-07 8L0 1Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 1L8 1C8.55229 1 9 1.44772 9 2L9 7C9 7.55229 8.55229 8 8 8L-6.79955e-07 8L0 1Z"
				fill="#8C8EA8"
				fillOpacity="0.5"
			/>
			<path d="M0 1L8 1C8.55229 1 9 1.44772 9 2L9 7C9 7.55229 8.55229 8 8 8L-6.79955e-07 8" stroke="#8C8EA8" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 4.5C9 4.22386 9.22386 4 9.5 4L14.5 4C14.7761 4 15 4.22386 15 4.5C15 4.77614 14.7761 5 14.5 5L9.5 5C9.22386 5 9 4.77614 9 4.5Z"
				fill="#8C8EA8"
			/>
		</Svg>
	);
};

export default ThirdQuartile1;
