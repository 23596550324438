import type { TFunction } from "react-i18next";

type MarketDataVariants = "dashboard" | "outlook";
type OutlookMarketDataVariants = "LOW" | "MID_LOW" | "NEUTRAL" | "MID_HIGH" | "HIGH";
type DashboardMarketDataVariants = "STRONG_UNDERWEIGHT" | "UNDERWEIGHT" | "NEUTRAL" | "OVERWEIGHT" | "S_OVERWEIGHT";

export const POSITIONING: Record<
	MarketDataVariants,
	{ [key: number]: OutlookMarketDataVariants | DashboardMarketDataVariants }
> = {
	outlook: {
		1: "LOW",
		2: "MID_LOW",
		3: "NEUTRAL",
		4: "MID_HIGH",
		5: "HIGH",
	},
	dashboard: {
		1: "STRONG_UNDERWEIGHT",
		2: "UNDERWEIGHT",
		3: "NEUTRAL",
		4: "OVERWEIGHT",
		5: "S_OVERWEIGHT",
	},
};
//not typesafe
export function handlePreviousMarketData<T extends keyof ReturnType<TFunction>>(
	t: TFunction,
	i18n: T,
	marketSection: MarketDataVariants,
	current: { value: number; data?: string },
	previous?: { value?: number; data?: string },
): { previous: number | undefined; tooltip: { label: string; value: string; data?: string | undefined }[] } {
	const traduction = t(i18n, { returnObjects: true });

	const sanitaizedPrevValue = previous?.value === current.value ? undefined : previous?.value;
	const currentI18n = POSITIONING[marketSection][current.value];
	const tooltip: Array<{ label: string; value: string; data?: string }> = [
		{ label: t("CURRENT"), value: traduction[currentI18n], data: current?.data },
	];

	if (sanitaizedPrevValue) {
		const previousI18n = POSITIONING[marketSection][sanitaizedPrevValue];
		tooltip.push({ label: t("PREVIOUS"), value: traduction[previousI18n], data: previous?.data });
	}

	return { previous: sanitaizedPrevValue, tooltip };
}
