import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import { type FC } from "react";

const CreateMarketViewPositioningIconV2: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 114,
		height: 112,
	},
	...props
}) => {
	return (
		<Svg viewBox={{ width: 114, height: 112 }} style={style} {...props}>
			<g filter="url(#filter0_d_1099_60986)">
				<g filter="url(#filter1_d_1099_60986)">
					<rect width="49.778" height="20.222" x="32.111" y="36.889" fill="#fff" rx="4" />
				</g>
				<rect
					width="47.578"
					height="18.022"
					x="33.211"
					y="37.989"
					fill="#fff"
					stroke="#C3C9D5"
					strokeWidth="2.2"
					rx="2.9"
				/>
				<ellipse cx="72.556" cy="47" fill="#C3C9D5" rx="2.333" ry="2.333" transform="rotate(180 72.556 47)" />
				<ellipse cx="64.778" cy="47" fill="#C3C9D5" rx="2.333" ry="2.333" transform="rotate(180 64.778 47)" />
				<ellipse cx="57" cy="47" fill="#C3C9D5" rx="2.333" ry="2.333" transform="rotate(180 57 47)" />
				<ellipse cx="49.222" cy="47" fill="#C3C9D5" rx="2.333" ry="2.333" transform="rotate(180 49.222 47)" />
				<ellipse cx="41.444" cy="47" fill="#C3C9D5" rx="2.333" ry="2.333" transform="rotate(180 41.444 47)" />
				<path stroke="#C3C9D5" strokeWidth="3" d="M39.5 47h35" />
				<circle cx="74.111" cy="47" r="3.889" fill="#4CB09C" transform="rotate(180 74.111 47)" />
				<path
					fill="#C3C9D5"
					fillRule="evenodd"
					d="M46.111 63.333h-.778a.778.778 0 000 1.556h.778v.778a.778.778 0 101.556 0v-.778h.778a.778.778 0 000-1.556h-.778v-.777a.778.778 0 00-1.556 0v.777zm.843.844a1.765 1.765 0 000-.131 1.578 1.578 0 00-.13 0 1.775 1.775 0 000 .13 1.578 1.578 0 00.13 0zM43 30.667v.777a.778.778 0 101.556 0v-.777h.778a.778.778 0 000-1.556h-.778v-.778a.778.778 0 00-1.556 0v.778h-.777a.778.778 0 100 1.556H43zm.843-.844a1.573 1.573 0 00-.13 0 1.578 1.578 0 000 .131 1.578 1.578 0 00.13 0 1.57 1.57 0 000-.13zM36 69.556v.777a.778.778 0 101.556 0v-.777h.778a.778.778 0 000-1.556h-.778v-.778a.778.778 0 00-1.556 0V68h-.777a.778.778 0 100 1.556H36zm.843-.844a1.77 1.77 0 00-.13 0 1.578 1.578 0 000 .131 1.775 1.775 0 00.13 0 1.578 1.578 0 000-.13zM69.445 63.333h-.778a.778.778 0 000 1.556h.778v.778a.778.778 0 101.555 0v-.778h.778a.778.778 0 100-1.556H71v-.777a.778.778 0 00-1.555 0v.777zm.843.844a1.765 1.765 0 000-.131 1.578 1.578 0 00-.13 0 1.775 1.775 0 000 .13 1.578 1.578 0 00.13 0zM53.111 26h-.778a.778.778 0 000 1.556h.778v.777a.778.778 0 101.556 0v-.777h.778a.778.778 0 000-1.556h-.778v-.778a.778.778 0 00-1.556 0V26zm.713.712a1.775 1.775 0 000 .131 1.775 1.775 0 00.13 0 1.775 1.775 0 000-.13 1.775 1.775 0 00-.13 0z"
					clipRule="evenodd"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1099_60986"
					width="120"
					height="120"
					x="-3"
					y="-5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="16" />
					<feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.188235 0 0 0 0 0.235294 0 0 0 0.16 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1099_60986" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1099_60986" result="shape" />
				</filter>
				<filter
					id="filter1_d_1099_60986"
					width="77.778"
					height="48.222"
					x="18.111"
					y="24.889"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1099_60986" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1099_60986" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CreateMarketViewPositioningIconV2;
