import type { DialogBoxSize, DialogProps } from "@mdotm/mdotui/components";
import { Button, Dialog, StackingContext, SubmitButton } from "@mdotm/mdotui/components";
import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	WrapIfStrOrNumOrEmpty,
	adaptAnimatedNodeProvider,
	renderNodeOrFn,
	spawn,
} from "@mdotm/mdotui/react-extensions";
import type { FormEvent, ReactNode } from "react";

export type YesNoDialogCommon = {
	children?: ReactNode;
	header?: string | ReactNode;
	yesButton?: string | NodeOrFn;
	onSubmitAsync?: TrackableAsyncFn<void, FormEvent>;
	noButton?: string | NodeOrFn<{ onClick(): void }>;
	size?: DialogBoxSize;
};

export type SpawnYesNoDialogParams = YesNoDialogCommon & {
	zIndex?: number;
};

export function spawnYesNoDialog(params?: SpawnYesNoDialogParams): Promise<boolean> {
	const { zIndex: overrideZindex, onSubmitAsync, ...forward } = params ?? {};

	return spawn<boolean>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<StackingContext.Provider value={{ zIndex: (overrideZindex ?? zIndex) + 1 }}>
						<YesNoDialog
							show={show}
							onAnimationStateChange={(state) => state === "hidden" && onHidden()}
							onClose={() => resolve(false)}
							onSubmitAsync={async (p) => {
								await onSubmitAsync?.(p);
								resolve(true);
							}}
							{...forward}
						/>
					</StackingContext.Provider>
				)}
			</StackingContext.Consumer>
		)),
	).promise;
}

export type YesNoDialogProps = YesNoDialogCommon & {
	show: boolean;
	onClose?(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

export function YesNoDialog(props: YesNoDialogProps): JSX.Element {
	return (
		<Dialog
			size={props.size ?? "small"}
			show={props.show}
			onAnimationStateChange={props.onAnimationStateChange}
			onClose={props.onClose}
			onSubmitAsync={props.onSubmitAsync}
			header={props.header ?? "Do you want to continue?"}
			footer={
				<div className="flex justify-between">
					<WrapIfStrOrNumOrEmpty
						wrapper={(content) => (
							<Button palette="tertiary" size="small" type="button" onClick={() => props.onClose?.()}>
								{content}
							</Button>
						)}
					>
						{renderNodeOrFn(props.noButton ?? "No", { onClick: () => props.onClose?.() })}
					</WrapIfStrOrNumOrEmpty>
					<WrapIfStrOrNumOrEmpty
						wrapper={(content) => (
							<SubmitButton palette="primary" size="small">
								{content}
							</SubmitButton>
						)}
					>
						{renderNodeOrFn(props.yesButton ?? "Yes")}
					</WrapIfStrOrNumOrEmpty>
				</div>
			}
		>
			{props.children}
		</Dialog>
	);
}
