import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsStock from "highcharts/modules/stock";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsBoost from "highcharts/modules/boost";
// @ts-ignore
import HC_rounded from "highcharts-rounded-corners";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsBellCurve from "highcharts/modules/histogram-bellcurve";
import HighchartsTreemap from "highcharts/modules/treemap";

HighchartsTreemap(Highcharts);
HighchartsBellCurve(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HC_rounded(Highcharts);
HighchartsStock(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);

export default Highcharts;
