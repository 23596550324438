import type { StylableProps } from "@mdotm/mdotui/components";
import { AutoTooltip, Icon, Text, TinyIconButton } from "@mdotm/mdotui/components";
import type { MouseEvent } from "react";
import { useRef, useState } from "react";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useLocaleFormatters } from "$root/localization/hooks";
import type {
	InlineTextFormImperativeHandles,
	InlineTextFormMode,
} from "$root/components/smart-text-input/InlineTextForm";
import { InlineTextForm } from "$root/components/smart-text-input/InlineTextForm";
import { flushSync } from "react-dom";
import { ReviewTickerCommentaryCreatorEnum } from "$root/api/api-gen";
import type { MaybePromise } from "@mdotm/mdotui/headless";

export type InstrumentCommentaryFormCellProps = {
	commentaryAuthor: ReviewTickerCommentaryCreatorEnum | null | undefined;
	commentaryText?: string;
	lastDescriptionUpdate?: string;
	onSubmitAsync?(newCommentaryText: string): MaybePromise<void>;
	onGenerate?(): MaybePromise<void>;
	isGenerationDisabled?: boolean;
	isGenerating?: boolean;
	onClick?(e: MouseEvent): void;
} & StylableProps;

export function InstrumentCommentaryFormCell(props: InstrumentCommentaryFormCellProps): JSX.Element {
	const { formatDate } = useLocaleFormatters();
	const inlineTextFormHandleRefs = useRef<InlineTextFormImperativeHandles | null>(null);
	const [mode, setMode] = useState<InlineTextFormMode>("view");

	return (
		<div
			onClick={props.onClick}
			className={overrideClassName("flex flex-col justify-center p-2", props.classList)}
			style={props.style}
		>
			{mode === "view" && (
				<div className="flex items-center justify-between">
					{props.commentaryText && (
						<div
							className="flex items-center gap-1 pr-4"
							style={{
								visibility: props.lastDescriptionUpdate ? "visible" : "hidden",
							}}
						>
							<Icon color={themeCSSVars.palette_N400} size={12} icon="Clock" />
							<Text type="Body/S/Book">Last description update {formatDate(props.lastDescriptionUpdate)}</Text>
						</div>
					)}
					<div className="ml-auto flex items-center" onClick={(e) => e.stopPropagation()}>
						{props.onGenerate && (
							<AutoTooltip
								disabled={!props.isGenerationDisabled}
								overrideColor={themeCSSVars.palette_N200}
								mode="hover"
								position="top"
								trigger={({ innerRef }) => (
									<span ref={innerRef}>
										<TinyIconButton
											color={!props.isGenerationDisabled ? themeCSSVars.palette_P500 : themeCSSVars.palette_N200}
											icon="sphere-ai"
											overrideLoading={props.isGenerating}
											disabled={props.isGenerationDisabled}
											onClickAsync={async () => {
												await props.onGenerate?.();
											}}
										/>
									</span>
								)}
							>
								Description generation is currently not available because there is not enough data.
							</AutoTooltip>
						)}
						{props.onSubmitAsync && (
							<TinyIconButton
								color={themeCSSVars.palette_P500}
								icon={props.commentaryText ? "Edit" : "commentary-add"}
								disabled={props.isGenerating}
								onClick={() => {
									// TODO: refactor
									flushSync(() => {
										inlineTextFormHandleRefs.current?.setMode("edit");
										setMode("edit");
									});
									inlineTextFormHandleRefs.current?.focus();
								}}
							/>
						)}
					</div>
				</div>
			)}
			{mode === "view" &&
			!props.commentaryText ? // 		<Text type="Body/M/Book" classList={`!text-[color:${themeCSSVars.palette_N300}]`}> // 	props.isGenerationDisabled ? (
			// 			Description generation is currently not available because there is not enough data.
			// 		</Text>
			// 	) : null
			// ) : (
			null : (
				<div onClick={(e) => mode === "edit" && e.stopPropagation()}>
					<InlineTextForm
						handlesRef={inlineTextFormHandleRefs}
						// TODO: a nicer API would be that if you don't pass onEdit, canEdit is just an internal boolean that checks if onEdit != null
						canEdit={props.onSubmitAsync != null}
						onEdit={async (newCommentaryText) => {
							await props.onSubmitAsync?.(newCommentaryText);
						}}
						value={props.commentaryText}
						mode={mode}
						onModeChange={setMode}
						noDataText=""
						useMarkdown={props.commentaryAuthor === ReviewTickerCommentaryCreatorEnum.Sphere}
						editButton={<></>}
					/>
				</div>
			)}
		</div>
	);
}
