import { TextArea, type StylableProps } from "@mdotm/mdotui/components";
import { toClassName, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import type { KeyboardEvent } from "react";
import { useState, useEffect } from "react";
import { ExpandIcon } from "../icons/ExpandIcon";
import { RemitIcon } from "../icons/RemitIcon";
import { useImperativeHandlesRef, type ImperativeHandlesRefProps } from "$root/utils/react-extra";

export type SmartTextAreaImperativeHandles = {
	setExpand(expand: boolean): void;
};

export type SmartTextAreaProps = StylableProps & {
	value: string;
	onChangeText(newValue: string): void;
	onEnter?(e: KeyboardEvent): void;
	maxRows?: number;
	minRows?: number;
} & ImperativeHandlesRefProps<SmartTextAreaImperativeHandles>;

export function SmartTextArea({
	value,
	onChangeText,
	style,
	classList,
	onEnter,
	handlesRef,
	maxRows = 7,
	minRows = 1,
}: SmartTextAreaProps): JSX.Element {
	const [isExpandInvisible, setIsExpandInvisible] = useState(true);
	const [expand, setExpand] = useState(false);

	const [textAreaEl, setTextAreaEl] = useState<HTMLTextAreaElement | null>(null);
	const rowHeight = 21;
	const baseHeight = rowHeight * minRows + 13;
	const maxHeight = rowHeight * maxRows;

	useImperativeHandlesRef(handlesRef, { setExpand });

	useEffect(() => {
		if (!textAreaEl) {
			return;
		}
		if (expand) {
			textAreaEl.style.height = `${maxHeight}px`;
		} else {
			noop(value); // track
			textAreaEl.style.height = "auto"; // forces reflow
			const autoHeight = Math.max(baseHeight, Math.min(maxHeight, textAreaEl.scrollHeight));
			textAreaEl.style.height = `${autoHeight}px`;
			setIsExpandInvisible(autoHeight <= baseHeight);
		}
	}, [baseHeight, expand, maxHeight, textAreaEl, value]);
	return (
		<div style={style} className={toClassName({ "relative z-0": true, ...toClassListRecord(classList) })}>
			<TextArea
				rows={1}
				classList="relative z-0 !w-full [&_textarea]:pr-8 [&_textarea]:!resize-none [&_textarea]:overflow-hidden"
				value={value}
				onChangeText={onChangeText}
				onKeyDown={(e) => {
					if (e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
						e.stopPropagation();
						onEnter?.(e);
					}
				}}
				innerRef={setTextAreaEl}
			/>
			<button
				type="button"
				className="absolute z-10 top-1 right-2 cursor-pointer aria-hidden:!hidden"
				onClick={() => {
					setExpand(!expand);
				}}
				aria-hidden={isExpandInvisible}
			>
				{expand ? <RemitIcon /> : <ExpandIcon />}
			</button>
		</div>
	);
}
