import type { Chip } from "$root/api/api-gen";
import { sentimentToString } from "$root/functional-areas/commentary/sentiment-badges";
import type { IndicatorType, SentimentType } from "$root/functional-areas/market-view/analysis/sentiment";
import { customObjectValuesFn } from "$root/utils/experimental";
import { capitalizeString } from "$root/utils/strings";

// TODO: unify all feelsConverter and positioning strings
export function feelsConverter({
	type,
	value,
}: Chip): { label: string; indicator: IndicatorType; sentiment: SentimentType } | null {
	if (!type || !value) {
		return null;
	}

	const levelRiskMap = {
		positive: { ratio: { min: 1, max: 1 }, label: "Low", trend: "positive", indicator: "regime" },
		neutral: { ratio: { min: 2, max: 2 }, label: "Mid", trend: "neutral", indicator: "regime" },
		negative: { ratio: { min: 3, max: 3 }, label: "High", trend: "negative", indicator: "regime" },
	} as const;

	const levelPositioningMap = {
		"super-positive": { ratio: { min: 5, max: 5 }, label: "Strong", trend: "super-positive", indicator: "positioning" },
		positive: { ratio: { min: 4, max: 4 }, label: "", trend: "positive", indicator: "positioning" },
		neutral: { ratio: { min: 3, max: 3 }, label: "", trend: "neutral", indicator: "positioning" },
		negative: { ratio: { min: 2, max: 2 }, label: "", trend: "negative", indicator: "positioning" },
		"super-negative": { ratio: { min: 1, max: 1 }, label: "Strong", trend: "super-negative", indicator: "positioning" },
	} as const;

	const invertedLevelMap = {
		positive: { ratio: { min: 1, max: 2 }, label: "High", trend: "negative", indicator: "driver" },
		neutral: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
		negative: { ratio: { min: 4, max: 5 }, label: "Low", trend: "positive", indicator: "driver" },
	} as const;

	const levelsMap = {
		positive: { ratio: { min: 4, max: 5 }, label: "High", trend: "positive", indicator: "driver" },
		neutral: { ratio: { min: 3, max: 3 }, label: "Mid", trend: "neutral", indicator: "driver" },
		negative: { ratio: { min: 1, max: 2 }, label: "Low", trend: "negative", indicator: "driver" },
	} as const;

	const level = customObjectValuesFn(
		type === "POSITIONING"
			? levelPositioningMap
			: type === "VOLATILITY"
			  ? invertedLevelMap
			  : type === "RISK"
			    ? levelRiskMap
			    : levelsMap,
	).find((x) => value >= x.ratio.min && value <= x.ratio.max);

	return {
		label:
			type === "POSITIONING"
				? sentimentToString(level!.indicator as any, level!.trend)
				: `${level?.label} ${capitalizeString(type.toLowerCase())}`,
		sentiment: level!.trend!,
		indicator: level!.indicator!,
	};
}
