import { FormController } from "$root/third-party-integrations/react-hook-form";
import { valueByPath } from "$root/utils/objects";
import type { StylableProps, AutocompleteProps } from "@mdotm/mdotui/components";
import { FormField, Autocomplete } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import type { Control, FieldValues, FormState, Path } from "react-hook-form";

export type AutocompleteFieldProps<T extends FieldValues> = StylableProps & {
	control: Control<T, any>;
	formState: FormState<T>;
	name: Path<T>;
	label: ReactNode;
} & Omit<AutocompleteProps, "value">;

export function AutocompleteField<T extends FieldValues>({
	label,
	name,
	formState,
	control,
	classList,
	style,
	...forward
}: AutocompleteFieldProps<T>): JSX.Element {
	return (
		<FormField
			classList={classList}
			style={style}
			label={label}
			error={(valueByPath(formState.errors, name) as { message?: string })?.message}
		>
			{(fieldProps) => (
				<FormController
					control={control}
					name={name}
					render={({ field: { ref, ...controllerProps } }) => (
						<Autocomplete
							innerRef={ref}
							{...fieldProps}
							{...controllerProps}
							{...forward}
							suggestions={forward.suggestions}
						/>
					)}
				/>
			)}
		</FormField>
	);
}
