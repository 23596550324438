let mounted = false;

const initIubendaPriv = (): void => {
	if (mounted) {
		return;
	}
	mounted = true;
	const iubPrivScript = document.createElement("script");
	const tag = document.getElementsByTagName("script")[0];
	iubPrivScript.src = "https://cdn.iubenda.com/iubenda.js";

	tag.parentNode?.insertBefore(iubPrivScript, tag);
};

export default initIubendaPriv;
