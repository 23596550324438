import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import { type FC } from "react";

const CreateMarketViewRegimeIconV2: FC<Omit<SvgProps, "children">> = ({
	style = {
		width: 112,
		height: 115,
	},
	viewBox = { width: 112, height: 115 },
	...props
}) => {
	return (
		<Svg viewBox={viewBox} style={style} {...props}>
			<g filter="url(#filter0_d_1097_45398)">
				<path
					fill="#C3C9D5"
					fillRule="evenodd"
					d="M39.666 34.222V35a.778.778 0 001.556 0v-.778H42a.778.778 0 100-1.555h-.778v-.778a.778.778 0 00-1.556 0v.778h-.777a.778.778 0 000 1.555h.777zm.844-.843a1.57 1.57 0 00-.131 0 1.573 1.573 0 000 .13 1.578 1.578 0 00.13 0 1.578 1.578 0 000-.13zM76.222 73.111v.778a.778.778 0 001.556 0v-.778h.777a.778.778 0 000-1.555h-.777v-.778a.778.778 0 10-1.556 0v.778h-.778a.778.778 0 100 1.555h.778zm.843-.843a1.578 1.578 0 00-.13 0 1.57 1.57 0 000 .13 1.57 1.57 0 00.13 0 1.578 1.578 0 000-.13zM46.666 30.333h-.777a.778.778 0 000 1.556h.777v.778a.778.778 0 101.556 0v-.778H49a.778.778 0 100-1.556h-.778v-.777a.778.778 0 00-1.556 0v.777zm.713.713a1.578 1.578 0 000 .13 1.578 1.578 0 00.13 0 1.57 1.57 0 000-.13 1.57 1.57 0 00-.13 0zM46.666 66.111v.778a.778.778 0 001.556 0v-.778H49a.778.778 0 100-1.555h-.778v-.778a.778.778 0 00-1.556 0v.778h-.777a.778.778 0 000 1.555h.777zm.844-.843a1.57 1.57 0 00-.131 0 1.57 1.57 0 000 .13 1.578 1.578 0 00.13 0 1.578 1.578 0 000-.13z"
					clipRule="evenodd"
				/>
				<g filter="url(#filter1_d_1097_45398)">
					<path
						fill="#fff"
						d="M57.555 29.278a4 4 0 014-4H69.5a4 4 0 014 4v3.22a4 4 0 01-3.216 3.923l-1.78.356a1.75 1.75 0 00-1.22.933c-.643 1.284-2.478 1.291-3.12.008a1.742 1.742 0 00-1.237-.933l-2.109-.395a4 4 0 01-3.263-3.932v-3.18z"
					/>
				</g>
				<g filter="url(#filter2_d_1097_45398)">
					<rect width="46.667" height="18.667" x="32.666" y="39.667" fill="#fff" rx="4" />
				</g>
				<rect
					width="44.467"
					height="16.467"
					x="33.767"
					y="40.767"
					fill="#fff"
					stroke="#C3C9D5"
					strokeWidth="2.2"
					rx="2.9"
				/>
				<path
					fill="#4CB09C"
					d="M74.581 25.667v10.13c0 .39-.156.763-.428 1.043-.272.272-2.69.427-1.042.427h-4.729l-1.999 4.006a.73.73 0 01-.389.358.773.773 0 01-.528 0 .73.73 0 01-.39-.358l-2.006-4.006h-4.737c-.389 0-.762-.155-1.042-.428a1.484 1.484 0 01-.428-1.042v-10.13c0-.39.156-.763.428-1.043a1.484 1.484 0 011.042-.427h14.778c.389 0 .762.155 1.042.427.272.273.428.654.428 1.043zm-8.851 13.63l1.54-3.095a.714.714 0 01.272-.296.751.751 0 01.39-.109h5.18v-10.13H58.332v10.13h5.188c.14 0 .272.04.389.11.117.07.21.17.272.295l1.54 3.095h.008z"
				/>
				<path
					fill="#4CB09C"
					fillRule="evenodd"
					d="M63.277 27.222a1.054 1.054 0 110 2.109 1.054 1.054 0 010-2.109zm3.534 6.237a1.055 1.055 0 112.11 0 1.055 1.055 0 01-2.11 0zm-4.284-.18l5.517-5.517a.471.471 0 01.668.003.473.473 0 01-.003.674L63.2 33.95a.474.474 0 01-.672-.67z"
					clipRule="evenodd"
				/>
				<path stroke="#C3C9D5" strokeLinecap="round" strokeWidth="4" d="M39.333 48.556L72.666 48.556" />
				<rect width="5.444" height="9.333" x="63" y="44.333" fill="#4CB09C" rx="2.722" />
			</g>
			<defs>
				<filter
					id="filter0_d_1097_45398"
					width="120"
					height="120"
					x="-4"
					y="-3"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="16" />
					<feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.188235 0 0 0 0 0.235294 0 0 0 0.16 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1097_45398" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1097_45398" result="shape" />
				</filter>
				<filter
					id="filter1_d_1097_45398"
					width="43.944"
					height="41.399"
					x="43.555"
					y="13.278"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1097_45398" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1097_45398" result="shape" />
				</filter>
				<filter
					id="filter2_d_1097_45398"
					width="74.666"
					height="46.667"
					x="18.666"
					y="27.667"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1097_45398" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1097_45398" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CreateMarketViewRegimeIconV2;
