import type {
	ContributionEntry,
	EnhancementContributionResponse,
	InvestmentContributionResponse,
} from "$root/api/api-gen";
import { InfoDelta } from "$root/components/InfoDelta";
import { formatNumber } from "$root/localization/formatters";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import {
	DrillDownByValueMap,
	customObjectValuesFn,
	getGraphMarkers,
	objSumRounded,
	roundCustomByStep,
} from "$root/utils/experimental";
import { TableWithGroups } from "@mdotm/mdotui/components";
import * as Immutable from "immutable";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { groupBy } from "@mdotm/mdotui/utils";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";

export type ExAnteVolatilityContributionSelectionProps =
	| {
			selector: "assetClass";
			vsSelector: "microAssetClass";
	  }
	| {
			selector: "assetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "microAssetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "geography";
			vsSelector: "assetClass";
	  }
	| {
			selector: "geography";
			vsSelector: "microAssetClass";
	  };

const selectOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
	},
] as const;

const _horizonOptions = [
	{
		label: "1m",
		value: "ONE_MONTH",
	},
	{
		label: "3m",
		value: "THREE_MONTHS",
	},
	{
		label: "6m",
		value: "SIX_MONTHS",
	},
	{
		label: "YTD",
		value: "YEAR_TO_DATE",
	},
	{
		label: "1y",
		value: "ONE_YEAR",
	},
	{
		label: "All",
		value: "FROM_INCEPTION",
	},
];

type DrillDownProps = {
	index: number;
	value: string | number | ContributionEntry | undefined;
	computedData: Record<"volatilityValue" | "benchmarkVolatility" | "enhancedVolatility" | "max" | "min", number>;
};

type GetExAnteVolatilityProps = {
	graphLimit: {
		maxVolatility: number;
		minVolatility: number;
	};
	graphColumnMarkers: Array<{
		value: number;
		label: string;
	}>;
	list: Array<{ group: DrillDownProps & { hideHeader: boolean }; drilldown: DrillDownProps }>;
	opts: ExAnteVolatilityContributionSelectionProps;
};

export function getExAnteVolatilityContributionSplittableProps(
	compositionVolatility: EnhancementContributionResponse | InvestmentContributionResponse,
	comparison: ExAnteVolatilityContributionSelectionProps,
): GetExAnteVolatilityProps {
	const contribution = {
		current: compositionVolatility.current,
		benchmark: "benchmark" in compositionVolatility ? compositionVolatility.benchmark ?? [] : [],
		proposal: "proposal" in compositionVolatility ? compositionVolatility.proposal ?? [] : [],
	};

	const aggregateData = {
		volatility: (contribution.current ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalVolatility: el,
			volatilityValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
		benchmarkVolatility: (contribution.benchmark ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalBenchmarkVolatility: el,
			benchmarkVolatility: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
		enhancedVolatility: (contribution.proposal ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalEnhancedVolatility: el,
			enhancedVolatility: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
	};

	const decupledByID = [
		...new Set([
			...aggregateData.volatility.map((el) => el.id),
			...aggregateData.benchmarkVolatility.map((el) => el.id),
			...aggregateData.enhancedVolatility.map((el) => el.id),
		]),
	];

	const result = decupledByID.map((id) => {
		const currentVTK = aggregateData.volatility.find((el) => el.id === id);
		const currentBVTK = aggregateData.benchmarkVolatility.find((el) => el.id === id);
		const currentEVTK = aggregateData.enhancedVolatility.find((el) => el.id === id);
		return {
			...currentVTK,
			...currentBVTK,
			...currentEVTK,
		};
	});

	const opts = selectOptions.find(
		({ selector, vsSelector }) => comparison.selector === selector && comparison.vsSelector === vsSelector,
	)!;

	const computedContribution = DrillDownByValueMap(
		result ?? [],
		opts.selector,
		opts.vsSelector,
		{
			volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
			benchmarkVolatility: (val) => objSumRounded(val, "benchmarkVolatility"),
			enhancedVolatility: (val) => objSumRounded(val, "enhancedVolatility"),
			max: (val) =>
				Math.max(
					objSumRounded(val, "volatilityValue"),
					objSumRounded(val, "benchmarkVolatility"),
					objSumRounded(val, "enhancedVolatility"),
				),
			min: (val) =>
				Math.min(
					objSumRounded(val, "volatilityValue"),
					objSumRounded(val, "benchmarkVolatility"),
					objSumRounded(val, "enhancedVolatility"),
				),
		},
		(a, b) => b.volatilityValue - a.volatilityValue,
	);
	const { drilldownData } = computedContribution;

	const list = drilldownData.flatMap(({ drilldown, ...props }) =>
		drilldown.map((item, i) => ({
			drilldown: { ...item },
			group: { ...props, hideHeader: i > 0 },
		})),
	);

	const graphLimit = drilldownData.reduce<{ maxVolatility: number; minVolatility: number }>(
		(acc, group) => {
			const rowValues = group.drilldown.reduce(
				(a, row) => {
					return {
						maxVolatility: Math.max(a.maxVolatility, row.computedData.max, group.computedData.max),
						minVolatility: Math.min(a.minVolatility, row.computedData.min, group.computedData.min),
					};
				},
				{ maxVolatility: 0, minVolatility: 0 },
			);
			return {
				maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
				minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
			};
		},
		{ maxVolatility: 0, minVolatility: 0 },
	);

	const scaledDrilldown = {
		maxVolatility: roundCustomByStep(graphLimit.maxVolatility, 1),
		minVolatility: roundCustomByStep(graphLimit.minVolatility, 1),
	};

	const graphColumnMarkers = getGraphMarkers(scaledDrilldown.maxVolatility, scaledDrilldown.minVolatility, 1, "%", 12);

	return { list, graphLimit, graphColumnMarkers, opts: comparison };
}

const ExAnteVolatilityContribution = ({
	variant,
	comparison,
	graphLimit,
	graphColumnMarkers,
	list,
	firstRender,
}: PrintableProps<
	{
		variant: ReportTemplateVariant;
		data: InvestmentContributionResponse | EnhancementContributionResponse;
		comparison: ExAnteVolatilityContributionSelectionProps;
		graphLimit: GetExAnteVolatilityProps["graphLimit"];
		graphColumnMarkers: GetExAnteVolatilityProps["graphColumnMarkers"];
	},
	GetExAnteVolatilityProps["list"][number]
>): JSX.Element => {
	const { t } = useTranslation();
	const contributionT = t(`PORTFOLIO_EXANTE_CONTRIBUTION_VOLATILITY`, {
		returnObjects: true,
	});

	const opts = selectOptions.find(
		(opt) => opt.selector === comparison.selector && opt.vsSelector === comparison.vsSelector,
	)!;

	const tableGroupRows = useMemo(() => {
		const groupList = groupBy(list, ({ group }) => String(group.index));
		return customObjectValuesFn(groupList).map((group) => ({
			...group![0].group,
			rows:
				group?.map(({ drilldown }) => ({
					...drilldown,
				})) ?? [],
		}));
	}, [list]);

	if (list.length === 0) {
		return <></>;
	}

	return (
		<Card
			title={firstRender ? "Ex-ante contribution volatility" : undefined}
			subTitle={firstRender ? opts.label : undefined}
			classList="pointer-events-none"
		>
			<TableWithGroups
				groupRowKey={(r) => r.index}
				expandGroupByKey={Immutable.Set(tableGroupRows.map(({ index }) => index))}
				groupedRows={tableGroupRows}
				groupRowClassList={(row) => (row.hideHeader ? "hidden" : "flex-1 !transition-none")}
				classList="pointer-events-none [&_.transition-\[height\]]:!transition-none [&_.transition-\[transform\,opacity\]]:!transition-none"
				columns={
					variant === "proposal"
						? [
								{
									header: contributionT.TABLE[opts.selector as keyof typeof contributionT.TABLE],
									groupContent: (groupedRow) => <strong>{groupedRow.value as any /* TODO: fix types */}</strong>,
									content: (row) => row.value as any,
									relativeWidth: 2.8,
								},
								{
									header: "CURRENT",
									groupContent: (groupedRow) => (
										<strong className="text-[#005C8B] tabular-nums">
											{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
										</strong>
									),
									content: (row) => (
										<span className="text-[#005C8B] tabular-nums">
											{formatNumber(row.computedData.volatilityValue, 2)}%
										</span>
									),
									relativeWidth: 1.2,
								},
								{
									header: "PROPOSAL",
									groupContent: (groupedRow) => (
										<strong className="text-[#00AEEF] tabular-nums">
											{formatNumber(groupedRow.computedData.enhancedVolatility, 2)}%
										</strong>
									),
									content: (row) => (
										<span className="text-[#00AEEF] tabular-nums">
											{formatNumber(row.computedData.enhancedVolatility, 2)}%
										</span>
									),
									relativeWidth: 1.2,
								},
								{
									header: "DIFFERENCE",
									groupContent: ({ computedData: { volatilityValue, enhancedVolatility } }) => (
										<div className="font-bold w-[80px]">
											<InfoDelta
												diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)}
												enh={enhancedVolatility ?? 0}
											/>
										</div>
									),
									content: ({ computedData: { volatilityValue, enhancedVolatility } }) => (
										<div className="w-[80px]">
											<InfoDelta
												diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)}
												enh={enhancedVolatility ?? 0}
											/>
										</div>
									),
									relativeWidth: 1.4,
								},
								{
									orderable: false,
									header: (
										<div className="flex flex-1 justify-between">
											{graphColumnMarkers.map((m, i) => (
												<div key={`marker-${i}`} className="">
													{m.label}
												</div>
											))}
										</div>
									),
									groupContent: ({ computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility } }) => {
										return (
											<BarGraphPCSvg
												classList="w-full"
												options={{
													animated: false,
													resize: true,
													marksLabels: false,
													markerStep: 1,
													scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
												}}
												data={[
													{ value: volatilityValue, color: "#005C8B" },
													{ value: enhancedVolatility, color: "#00AEEF" },
													{ value: benchmarkVolatility, color: "#C6ACD9" },
												]}
											/>
										);
									},
									content: ({ computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility } }) => {
										return (
											<BarGraphPCSvg
												classList="w-full"
												options={{
													animated: false,
													resize: true,
													marksLabels: false,
													markerStep: 1,
													scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
												}}
												data={[
													{ value: volatilityValue, color: "#005C8B" },
													{ value: enhancedVolatility, color: "#00AEEF" },
													{ value: benchmarkVolatility, color: "#C6ACD9" },
												]}
											/>
										);
									},
									relativeWidth: 5.0,
								},
						  ]
						: [
								{
									header: contributionT.TABLE[selectOptions[0].selector as keyof typeof contributionT.TABLE],
									groupContent: (groupedRow) => <strong>{groupedRow.value as any /* TODO: fix types */}</strong>,
									content: (row) => row.value as any,
									relativeWidth: 2.8,
								},
								{
									header: "PORTFOLIO VOLATILITY",
									groupContent: (groupedRow) => (
										<strong className="text-[#572C7F] tabular-nums">
											{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
										</strong>
									),
									content: (row) => (
										<span className="text-[#572C7F] tabular-nums">
											{formatNumber(row.computedData.volatilityValue, 2)}%
										</span>
									),
									relativeWidth: 1.2,
								},
								{
									header: (
										<div className="flex flex-1 justify-between">
											{graphColumnMarkers.map((m, i) => (
												<div key={`marker-${i}`} className="">
													{m.label}
												</div>
											))}
										</div>
									),
									groupContent: ({ computedData: { volatilityValue, benchmarkVolatility } }) => {
										return (
											<BarGraphPCSvg
												classList="w-full"
												options={{
													animated: false,
													resize: true,
													marksLabels: false,
													markerStep: 1,
													scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
												}}
												data={[
													{ value: volatilityValue, color: "#572C7F" },
													{ value: benchmarkVolatility, color: "#C6ACD9" },
												]}
											/>
										);
									},
									content: ({ computedData: { volatilityValue, benchmarkVolatility } }) => {
										return (
											<BarGraphPCSvg
												classList="w-full"
												options={{
													animated: false,
													resize: true,
													marksLabels: false,
													markerStep: 1,
													scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
												}}
												data={[
													{ value: benchmarkVolatility, color: "#C6ACD9" },
													{ value: volatilityValue, color: "#572C7F" },
												]}
											/>
										);
									},
									relativeWidth: 5.6,
								},
						  ]
				}
			/>
		</Card>
	);
};
export default ExAnteVolatilityContribution;
