import {
	BenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	ReferenceUniversesControllerApiFactory
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCallback } from "react";

export type UsePerformCrudActions = "delete" | "rename" | "duplicate";
type UsePerformDelete = {
	action: Extract<UsePerformCrudActions, "delete">;
};

type UsePerformUpdate = {
	action: Extract<UsePerformCrudActions, "rename" | "duplicate">;
	name: string;
};

type UsePerformRename = UsePerformUpdate;
type UsePerformDuplicate = UsePerformUpdate;

type UsePerformCrud = {
	uuid: string;
} & (UsePerformDelete | UsePerformRename | UsePerformDuplicate);

const usePerformCrud = (
	type: "universe" | "portfolio" | "market" | "benchmark",
): { performAction: (props: UsePerformCrud) => Promise<void> } => {
	const universeApi = useApiGen(ReferenceUniversesControllerApiFactory);
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const benchmarkApi = useApiGen(BenchmarksControllerApiFactory);

	const performPortfolioCrud = useCallback(
		async (props: UsePerformCrud) => {
			const { action, uuid } = props;
			switch (action) {
				case "delete":
					await investmentApi.deleteInvestment(uuid);
					return;
				case "duplicate":
					await investmentApi.duplicateInvestment(uuid, props.name);
					return;
				case "rename":
					await investmentApi.renameInvestment(uuid, props.name);
					break;
			}
		},
		[investmentApi],
	);

	const performUniverseCrud = useCallback(
		async (props: UsePerformCrud) => {
			const { action, uuid } = props;
			switch (action) {
				case "delete":
					await universeApi.deleteUniverse(uuid);
					return;
				case "duplicate":
					await universeApi.duplicateUniverse(uuid, props.name);
					return;
				case "rename":
					await universeApi.renameUniverse(uuid, props.name);
					break;
			}
		},
		[universeApi],
	);

	const performMarketCrud = useCallback(
		async (props: UsePerformCrud) => {
			const { action, uuid } = props;
			switch (action) {
				case "delete":
					await marketViewApi.deleteMarketView(uuid);
					return;
				case "rename":
					await marketViewApi.renameMarketView(uuid, props.name);
					return;
				case "duplicate":
					await marketViewApi.duplicateMarketView(uuid, props.name);
					break;
			}
		},
		[marketViewApi],
	);

	const performBenchmarkCrud = useCallback(
		async (props: UsePerformCrud) => {
			const { action, uuid } = props;
			switch (action) {
				case "delete":
					await benchmarkApi.deleteBenchmark(uuid);
					return;
				case "rename":
					await benchmarkApi.renameBenchmark(uuid, props.name);
					return;
				case "duplicate":
					await benchmarkApi.duplicateBenchmark(uuid, props.name);
					break;
			}
		},
		[benchmarkApi],
	);

	const performActionMap: Record<typeof type, (props: UsePerformCrud) => Promise<void>> = {
		portfolio: performPortfolioCrud,
		universe: performUniverseCrud,
		market: performMarketCrud,
		benchmark: performBenchmarkCrud,
	};

	return { performAction: performActionMap[type] };
};

export default usePerformCrud;
