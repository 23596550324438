import { useCurrentRoutesMap, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { guestUser, useUserState, useUserValue } from "$root/functional-areas/user";
import type { ActionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	Badge,
	CircularProgressBar,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	Select,
	Text,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ChangePassword from "./ChangePassword";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { DataDisplayOverlay } from "./DataDisplayOverlay";
import { SphereAIAvatarAlt } from "$root/functional-areas/sphere-ai/icons/SphereAIAvatar";
import { flushSync } from "react-dom";
import type { SystemMode } from "$root/functional-areas/system";
import { useSystemState } from "$root/functional-areas/system";
import AuthorizationGuard, { hasAccess } from "./AuthorizationGuard";
import { filterMap } from "$root/utils/collections";
import { Sidebar } from "./Sidebar";
import { SphereAISidebarContent } from "$root/functional-areas/sphere-ai/SphereAISidebarContent";
import { useNotificationValue } from "$root/functional-areas/notification-center/NotificationStore";
import { NotificationCenterSidebarContent } from "$root/functional-areas/notification-center";
import env from "$root/env";
import { customObjectValuesFn } from "$root/utils/experimental";
import { UserCircle } from "$root/functional-areas/acl/UserCircle";
import ReactQueryWrapper from "./ReactQueryWrapper";
import { useApiGen } from "$root/api/hooks";
import { CustomerControllerV3ApiFactory } from "$root/api/api-gen";
import { generateImageObjectUrlFromFileResponse } from "./EvolvedPrint/configuration/utils";
import { axiosExtract } from "$root/third-party-integrations/axios";

type SignInInterfaceProps = {
	setChangePasswordOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SignInInterface({ setChangePasswordOpen }: SignInInterfaceProps): JSX.Element {
	const history = useHistory();
	const { push } = useTypedNavigation();
	const { t } = useTranslation();
	const { user, setUser } = useUserState();
	const { systemStatus, setSystemStatus } = useSystemState();
	const notificationCenter = useNotificationValue();
	const { email, name, surname } = user;
	const resetUser = () => setUser(guestUser);
	const emailAsUser = email?.substring(0, email.indexOf("@"));

	const [mountSphereAIChat, setMountSphereAIChat] = useState(false);

	const [showSidebar, setShowSidebar] = useState<null | "chat" | "notifications">(null);

	const currentRoutesMap = useCurrentRoutesMap();
	const pagesInAppBar = useMemo(
		() =>
			filterMap(customObjectValuesFn(currentRoutesMap), (x) => {
				const guard = x.guard;
				return x.topBarOptions?.show && guard({ user }).passed
					? {
							value: {
								...x,
								topBarOptions: x.topBarOptions,
							},
					  }
					: null;
			}),
		[currentRoutesMap, user],
	);

	const handleSignOut = () => {
		resetUser();
		push("Login", {});
	};

	const customerApi = useApiGen(CustomerControllerV3ApiFactory);

	return (
		<>
			<div className="mr-4 h-full flex items-center">
				<DataDisplayOverlay classList="mr-4" dataSource="User Data" data={user} />
				<AuthorizationGuard requiredService="ASK_SPHERE">
					{() => (
						<>
							{env.featureFlags.sphereAI.enabled && (
								<button
									type="button"
									className="block"
									onClick={() => {
										if (!mountSphereAIChat) {
											flushSync(() => {
												setMountSphereAIChat(true);
											});
										}
										setShowSidebar((cur) => (cur === "chat" ? null : "chat"));
									}}
								>
									{/* bg: themeCSSVars.palette_graph_B100 */}
									<Badge
										size="large"
										backgroundColor={showSidebar === "chat" ? themeCSSVars.palette_graph_B100 : "transparent"}
										color={themeCSSVars.palette_graph_B500}
										rightContent={<SphereAIAvatarAlt classList="w-full h-full" />}
									>
										Ask Sphere AI
									</Badge>
								</button>
							)}
						</>
					)}
				</AuthorizationGuard>
				<AuthorizationGuard requiredService="HELP_CENTER">
					{() => (
						<>
							<div className={`h-full border-r mx-3 border-r-[${themeCSSVars.palette_N50}]`} />
							<button
								className={`flex items-center text-[${themeCSSVars.Button_bg_primary}]`}
								type="button"
								onClick={() => {
									Intercom.showHelp();
								}}
							>
								<Icon icon="Tooltip-questionmark" size={28} />
							</button>
						</>
					)}
				</AuthorizationGuard>
				<AuthorizationGuard requiredService="NOTIFICATION_CENTER">
					{() => (
						<>
							<div className={`h-full border-r mx-3 border-r-[${themeCSSVars.palette_N50}]`} />

							<div className="relative">
								<button
									className={`flex items-center text-[${themeCSSVars.Button_bg_primary}]`}
									type="button"
									onClick={() => setShowSidebar((cur) => (cur === "notifications" ? null : "notifications"))}
								>
									<Icon icon="Property-1Notifications" size={28} />
								</button>
								{notificationCenter.markAsRead === false && (
									<span className="flex absolute h-2 w-2 top-0 right-0 -mr-0.5">
										<span
											className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-[color:${themeCSSVars.palette_D500}] opacity-75`}
										/>
										<span
											className={`relative inline-flex rounded-full h-2 w-2 bg-[color:${themeCSSVars.palette_D500}]`}
										/>
									</span>
								)}
							</div>
						</>
					)}
				</AuthorizationGuard>
				<AuthorizationGuard requiredRole="ROOT">
					{() => (
						<>
							<div className={`h-full border-r mx-3 border-r-[${themeCSSVars.palette_N50}]`} />
							<Select
								unstyled
								classList="flex items-center truncate"
								strategy="fixed"
								options={[
									{ value: "MAINTENANCE", label: "Maintenance" },
									{ value: "DEFAULT", label: "Default" },
									{ value: "READONLY", label: "ReadOnly" },
								]}
								value={systemStatus.systemMode}
								i18n={{ triggerPlaceholder: () => "System Mode" }}
								onChange={(newMode: SystemMode) => {
									if (newMode === "MAINTENANCE" && !hasAccess(user, { requiredRole: "ROOT" })) {
										handleSignOut();
									}
									setSystemStatus({ ...systemStatus, systemMode: newMode });
								}}
							/>
						</>
					)}
				</AuthorizationGuard>
				<div className={`h-full border-r mx-3 border-r-[${themeCSSVars.palette_N50}]`} />

				<ReactQueryWrapper
					enabled={user.customerId !== undefined}
					queryFn={() => axiosExtract(customerApi.getCustomerById(user.customerId!))}
					queryKey={[user.customerId, "clientLogo"]}
					loadingFallback={<CircularProgressBar value="indeterminate" classList="w-4 h-4 mr-4" />}
					errorFallback={null}
				>
					{({ profilePhoto }) =>
						profilePhoto && (
							<img
								alt="company logo"
								src={generateImageObjectUrlFromFileResponse(profilePhoto)}
								className="mr-4 h-12 max-w-[100px] object-contain"
							/>
						)
					}
				</ReactQueryWrapper>
				<DropdownMenu
					strategy="fixed"
					trigger={({ innerRef, open: _open, ...forwardProps }) => (
						<button
							data-qualifier="appbar/userMenu"
							className={`flex items-center text-[${themeCSSVars.Button_bg_primary}]`}
							ref={innerRef}
							type="button"
							{...forwardProps}
						>
							<UserCircle variant="name" palette="light" firstName={name ?? emailAsUser} lastName={surname ?? ""} />
						</button>
					)}
					actions={[
						...pagesInAppBar.map<ActionWithOptionalGroup<string>>((pageInAppBar) => ({
							group: t("appbar.PROFILE_SETTINGS"),
							children: ({ onClose }) => (
								<DropdownMenuActionButton
									data-qualifier={`appbar/pageLink(${pageInAppBar.name})`}
									classList="whitespace-nowrap"
									icon={pageInAppBar.topBarOptions!.icon}
									onClick={() => {
										onClose();
										history.push(pageInAppBar.baseUrl);
									}}
									key={pageInAppBar.topBarOptions!.title}
								>
									{pageInAppBar.topBarOptions!.title ?? "title not found"}
								</DropdownMenuActionButton>
							),
						})),
						{
							group: t("appbar.PROFILE_SETTINGS"),
							children: ({ onClose }) => (
								<DropdownMenuActionButton
									data-qualifier="appbar/changePassword"
									classList="whitespace-nowrap"
									icon="Top-menu-password"
									onClick={() => {
										onClose();
										setChangePasswordOpen(true);
									}}
								>
									{t("appbar.CHANGE_PWD")}
								</DropdownMenuActionButton>
							),
						},
						{
							group: "logout",
							children: ({ onClose }) => (
								<DropdownMenuActionButton
									data-qualifier="appbar/logout"
									classList="whitespace-nowrap"
									icon="Left1"
									onClick={() => {
										onClose();
										handleSignOut();
									}}
								>
									{t("appbar.LOGOUT")}
								</DropdownMenuActionButton>
							),
						},
					]}
				/>
			</div>

			<AuthorizationGuard requiredService="ASK_SPHERE">
				{() => (
					<>
						{env.featureFlags.sphereAI.enabled && mountSphereAIChat && (
							<Sidebar open={showSidebar === "chat"} onClose={() => setShowSidebar(null)} width={440}>
								{(props) => <SphereAISidebarContent {...props} />}
							</Sidebar>
						)}
					</>
				)}
			</AuthorizationGuard>

			<AuthorizationGuard requiredService="NOTIFICATION_CENTER">
				{() => (
					<>
						{env.featureFlags.notificationCenter.enabled && (
							<Sidebar open={showSidebar === "notifications"} onClose={() => setShowSidebar(null)} width={440}>
								{(props) => <NotificationCenterSidebarContent {...props} />}
							</Sidebar>
						)}
					</>
				)}
			</AuthorizationGuard>
		</>
	);
}

export const appBarHeight = 48;

function _MyAppBar(): JSX.Element {
	const { signedIn } = useUserValue();
	const [changePasswordOpen, setChangePasswordOpen] = useState(false);

	// MDOT
	// - add arrow between user profile
	return (
		<>
			<div
				className="bg-white border-b-2 border-[#DFE2E7] fixed inset-x-0 top-0 z-50 flex items-center justify-between"
				style={{
					height: appBarHeight,
				}}
			>
				<div data-comment="left-placeholder" />
				{signedIn && <SignInInterface setChangePasswordOpen={setChangePasswordOpen} />}
			</div>
			<div
				style={{
					height: appBarHeight,
				}}
				data-comment="placeholder"
			/>
			<ChangePassword open={changePasswordOpen} onClose={() => setChangePasswordOpen(false)} />
		</>
	);
}

export const MyAppBar = withTranslation()(_MyAppBar);
