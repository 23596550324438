import { InvestmentsReportTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import EndingPage from "$root/components/EvolvedPrint/components/pdf/EndingPage";
import StartingPage from "$root/components/EvolvedPrint/components/pdf/StartingPage";
import MainBlock from "$root/components/EvolvedPrint/configuration";
import useExtractReportsData, {
	DefaultTemplateId,
} from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { getDataForTemplate } from "$root/functional-areas/reports/data-providers";
import { defaultPortfolioTemplates, DefaultReportTemplateName } from "$root/functional-areas/reports/default-templates";
import Portrait from "$root/functional-areas/reports/documents/Portrait";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import * as dateFn from "date-fns";
import { lazy } from "react";
import {
	latestVersion,
	migrateToLatest,
	type ReportTemplate,
	type ReportTemplateStatus,
	type ReportTemplateUnion,
	type ReportTemplateVariant,
} from "../PortfolioStudioSettings/ReportEditor/report-latest";
import "./print.scss";

const Theme = lazy(() => import("../../components/EvolvedPrint/layout/portrait"));

const Report = (props: {
	templateId: string;
	objectId?: string;
	variant?: string;
	historyEventUuid?: string;
}): JSX.Element => {
	const iubenda = document.querySelector(".iubenda-tp-btn");
	const initIubenda = document.querySelector("#iubenda-cs-banner");
	if (iubenda) {
		iubenda?.setAttribute("style", "display: none!important");
	}
	if (initIubenda) {
		initIubenda?.setAttribute("style", "display: none!important");
	}

	const isStatic = (Object.values(DefaultTemplateId) as string[]).includes(props.templateId);
	if (isStatic) {
		return <StaticReport {...props} />;
	} else {
		return <DynamicReport {...props} />;
	}
};

function DynamicReport(props: {
	templateId: string;
	objectId?: string;
	variant?: string;
	historyEventUuid?: string;
	status?: string;
}) {
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const { data, format, templateName } = defaultPortfolioTemplates[0];

	async function loadTemplate(templateId: string): Promise<ReportTemplate> {
		const template = (await axiosExtract(
			investmentsReportTemplateApi.getInvestmentReportTemplate(templateId),
		)) as ReportTemplateUnion;

		if (template.data.version !== latestVersion) {
			const migratedTemplate = migrateToLatest(template);
			return migratedTemplate;
		}

		return template as ReportTemplate;
	}

	const query = useQueryNoRefetch({
		queryKey: ["report", props.templateId, props.objectId],
		queryFn: async () => {
			let template: ReportTemplate = {
				data,
				format,
				templateName,
				uuid: DefaultReportTemplateName.sphere,
				visible: true,
			};

			if (props.templateId !== DefaultReportTemplateName.sphere) {
				template = await loadTemplate(props.templateId);
			}

			return getDataForTemplate(
				props.objectId!,
				template,
				props.variant as ReportTemplateVariant,
				props.status as ReportTemplateStatus,
			);
		},
	});
	return (
		<ReactQueryWrapperBase query={query}>
			{({ common, componentAndPropsList }) => (
				<Portrait
					title={common.portfolio.name ?? "Untitled"}
					subtitle={dateFn.format(new Date(), "do LLLL yyyy")}
					componentAndPropsList={componentAndPropsList}
				/>
			)}
		</ReactQueryWrapperBase>
	);
}

function StaticReport(props: { templateId: string; objectId?: string; variant?: string; historyEventUuid?: string }) {
	const {
		state: { data, isLoading, isError },
		// TODO: support custom id
	} = useExtractReportsData(
		props.templateId as DefaultTemplateId,
		props.variant as ReportTemplateVariant,
		props.objectId,
		props.historyEventUuid,
	);

	if (isError) {
		return (
			<Theme>
				<div data-size="A4portrait" className="page_brake">
					<div className="h-full flex justify-center items-center">
						<p className="text-3xl">ERROR GENERATING PDF</p>
					</div>
				</div>
			</Theme>
		);
	}

	if (!data || isLoading) {
		return (
			<Theme>
				<div data-size="A4portrait" className="page_brake">
					<div className="h-full flex justify-center items-center">
						<CircularProgressBar value="indeterminate" />
					</div>
				</div>
			</Theme>
		);
	}

	return (
		<Theme>
			<section className="indicators">
				<div data-size="A4portrait" className="page_brake">
					<StartingPage
						customData={data.payload.report}
						title={data.payload.report.title}
						subtitle={data.payload.report.subtitile}
					/>
				</div>
				<div data-size="A4Template">
					<MainBlock {...data} layout="portrait" />
				</div>
				<div data-size="A4portrait">
					<EndingPage
						address={data.payload.report.address}
						mail={data.payload.report.email}
						disclaimer={data.payload.report.disclaimer}
						website={data.payload.report.website}
					/>
				</div>
			</section>
		</Theme>
	);
}

export default Report;
