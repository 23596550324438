import type { InvestmentCommentaryResponseStatusEnum, InvestmentSummary } from "$root/api/api-gen";
import {
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import FakeAiLoader from "$root/components/FakeAiLoader";
import {
	animationProgressState,
	getAnimationProgressById,
	simulateAnimationProgress,
} from "$root/components/FakeAiLoader/atom";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus, waitForEvent } from "$root/event-bus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { PortfolioCommentaryGenerationPolicy } from "$root/functional-areas/portfolio/policies/commentary";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TransientNotification } from "$root/notification-system/transient";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { Card } from "$root/widgets-architecture/layout/Card";
import { ActionText, AsyncButton, Button, CircularProgressBar, Icon, Text } from "@mdotm/mdotui/components";
import { AbortError } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, unpromisify } from "@mdotm/mdotui/utils";
import EventEmitter from "eventemitter3";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PortfolioQueryWidgetBase, WidgetStatus } from "./PortfolioWidgetStatus";

export type PortfolioCommentaryProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	setCustomActions: (node: ReactNode | null) => void;
	reportExcutionCounter: number;
};

// import { useTranslation } from "react-i18next";

export function PortfolioCommentary({
	portfolio,
	enhanced,
	setCustomActions,
	reportExcutionCounter,
}: PortfolioCommentaryProps): JSX.Element {
	const { lastActionDate } = portfolio ?? {};
	const { formatDate } = useLocaleFormatters();
	//animation atom progress
	const commentaryGenerationProgress = getAnimationProgressById(portfolio?.uuid ?? "-");
	const { setAnimationProgress } = animationProgressState();

	const [commentaryGenerationAbortController, setCommentaryGenerationAbortController] = useState(new AbortController());
	const [commentaryGenerationDoneEmitter, setCommentaryGenerationDoneEmitter] = useState(new EventEmitter<"done">());
	const [commentaryGenerationStatus, setCommentaryGenerationStatus] =
		useState<null | InvestmentCommentaryResponseStatusEnum>(null);

	const resetEvents = useCallback(() => {
		setCommentaryGenerationDoneEmitter(new EventEmitter<"done">());
		setCommentaryGenerationAbortController(new AbortController());
	}, []);

	const thinkingBoxAnimation = useCallback(async () => {
		commentaryGenerationAbortController.abort(new AbortError("done"));
		let cleanup = noop;
		await new Promise((resolve) => {
			commentaryGenerationDoneEmitter.addListener("done", resolve);
			cleanup = () => commentaryGenerationDoneEmitter.removeListener("done", resolve);
		});
		cleanup();
	}, [commentaryGenerationAbortController, commentaryGenerationDoneEmitter]);

	const enhanceInvestmentReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const investmentV4Api = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentEnhancementV4Api = useApiGen(InvestmentEnhancementControllerV4ApiFactory);

	const commentaryQuery = useQueryNoRefetch(["portfolioCommentaryPage", portfolio, enhanced, reportExcutionCounter], {
		enabled: Boolean(portfolio),
		queryFn: async () => {
			const commentary = await axiosExtract(
				enhanced
					? enhanceInvestmentReportApi.getCommentaries1(portfolio?.uuid ?? "")
					: investmentReportApi.getCommentaries(portfolio?.uuid ?? ""),
			);

			if (commentary.status === "CALCULATING" && (!commentary.commentary || !commentary.shortCommentary)) {
				return {
					data: undefined,
					widgetStatus: WidgetStatus.CALCULATING as const,
				};
			}

			if (!commentary.commentary && !commentary.shortCommentary) {
				return {
					data: undefined,
					widgetStatus: WidgetStatus.EMPTY as const,
				};
			}

			return {
				data: { ...commentary, used: 990 /* TODO */, total: 1000 /* TODO */ },
				widgetStatus: WidgetStatus.READY as const,
			};
		},
		onError: (e) => console.warn(e),
	});

	const { data: rq } = commentaryQuery;
	const { data: commentaryResponse, widgetStatus } = rq ?? {};

	const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	useEventBus("commentary-update", {
		filter: objMatchFn({ uuid: portfolio?.uuid }),
		listener: (x) => {
			timeoutIdRef.current = setTimeout(
				unpromisify(async () => {
					if (commentaryGenerationStatus === "CALCULATING") {
						await thinkingBoxAnimation();
						setCommentaryGenerationStatus("COMPLETED");
						resetEvents();
					}

					if (commentaryGenerationProgress) {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== portfolio?.uuid));
					}
					await commentaryQuery.refetch();
				}),
				3000,
			);
		},
	});

	useEffect(() => {
		const uuid = portfolio?.uuid;
		if (commentaryGenerationProgress !== undefined && uuid && commentaryQuery.data) {
			if (commentaryResponse?.status && commentaryResponse.status !== "CALCULATING") {
				setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));

				return;
			}

			if (commentaryResponse?.status === "CALCULATING") {
				unpromisify(async () => {
					try {
						setCommentaryGenerationStatus("CALCULATING");
						await waitForEvent("commentary-update", {
							filter: objMatchFn({ uuid }),
							signal: AbortSignal.timeout(60000),
						});
					} catch (error) {
						await thinkingBoxAnimation();
						const { data } = await commentaryQuery.refetch();
						if (data?.data?.status === "ERROR") {
							setCommentaryGenerationStatus("ERROR");
						} else {
							setCommentaryGenerationStatus(null);
						}
						resetEvents();
						throw error;
					} finally {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));
					}
				})();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentaryResponse?.status]);

	useEffect(
		() => () => {
			if (timeoutIdRef.current !== null) {
				clearTimeout(timeoutIdRef.current);
			}
		},
		[],
	);

	const generateCommentary = useCallback(async () => {
		try {
			setCommentaryGenerationStatus("CALCULATING");
			if (enhanced) {
				investmentEnhancementV4Api.createCommentary(portfolio.uuid!).catch(noop);
			} else {
				investmentV4Api.createCommentary1(portfolio.uuid!).catch(noop);
			}

			await waitForEvent("commentary-update", {
				filter: objMatchFn({ uuid: portfolio.uuid ?? "" }),
				signal: AbortSignal.timeout(60000),
			});
		} catch (error) {
			if (commentaryGenerationAbortController.signal.aborted) {
				resetEvents();
			} else {
				await thinkingBoxAnimation();
			}
			const { data } = await commentaryQuery.refetch();
			if (data?.data?.status === "ERROR") {
				setCommentaryGenerationStatus("ERROR");
			}
			throw error;
		}
	}, [
		commentaryGenerationAbortController.signal.aborted,
		commentaryQuery,
		enhanced,
		investmentEnhancementV4Api,
		investmentV4Api,
		portfolio.uuid,
		resetEvents,
		thinkingBoxAnimation,
	]);

	useEffect(() => {
		if (commentaryResponse && portfolio && !enhanced) {
			const policy = new PortfolioCommentaryGenerationPolicy(commentaryResponse.used, commentaryResponse.total);
			setCustomActions(
				<AuthorizationGuard
					permissionChecker={aclByArea.portfolio.canEditComposition}
					acl={portfolio?.richAcl?.acl ?? []}
				>
					<AsyncButton
						// disabled={!policy.canGenerate()}
						disabled={
							portfolio?.status === "CALCULATING" ||
							portfolio?.status === "ERROR" ||
							commentaryResponse.status === "CALCULATING" ||
							commentaryGenerationStatus === "CALCULATING"
						}
						size="small"
						palette="secondary"
						onClickAsync={generateCommentary}
					>
						Generate comment
					</AsyncButton>
				</AuthorizationGuard>,
			);
		} else {
			setCustomActions(<></>);
		}
	}, [
		commentaryGenerationStatus,
		commentaryQuery,
		commentaryResponse,
		enhanced,
		generateCommentary,
		portfolio,
		portfolio?.richAcl?.acl,
		setCustomActions,
	]);

	const defaultFakeLoader = useMemo(
		() =>
			commentaryGenerationProgress
				? simulateAnimationProgress(
						commentaryGenerationProgress.date,
						commentaryGenerationProgress.progress,
						commentaryGenerationProgress.step,
						commentaryGenerationProgress.step !== "preparing" ? 0.175 : 0.02,
				  )
				: undefined,
		[commentaryGenerationProgress],
	);

	return (
		<>
			<TransientNotification
				variant="warning"
				dismissible={false}
				location="in-page"
				autoHide={null}
				// TODO: based on API response
				trigger={false}
			>
				Some of the descriptions for the instruments contained in this portfolio are expired.{" "}
				<Text color="inherit" type="Body/M/Bold">
					Customize your
				</Text>{" "}
				<ActionText
					color="inherit"
					type="Body/M/Bold"
					href={typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
					})}
				>
					instrument descriptions
				</ActionText>
			</TransientNotification>
			<Card
				title={
					<div className="flex justify-between grow">
						<Text type="Body/XL/Bold">Commentary - Generative AI</Text>
						<div>
							{commentaryResponse?.commentaryDate && (
								<div className="flex space-x-1 items-center">
									<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} />
									<Text type="Body/S/Book" as="p">
										Generated on&nbsp;
										<span>
											{formatDate(commentaryResponse?.commentaryDate, { hour: "2-digit", minute: "2-digit" })}
										</span>
									</Text>
								</div>
							)}
						</div>
					</div>
				}
			>
				{commentaryGenerationStatus === "CALCULATING" && (
					<FakeAiLoader
						signal={commentaryGenerationAbortController.signal}
						onDone={() => commentaryGenerationDoneEmitter.emit("done")}
						defaultValue={defaultFakeLoader}
						persist={(progress, step) => {
							const uuid = portfolio?.uuid;
							if (uuid) {
								setAnimationProgress((prevAnimationProgress) => {
									const indexOfAnimation = prevAnimationProgress.findIndex((animation) => animation.id === uuid);

									if (indexOfAnimation > -1) {
										prevAnimationProgress[indexOfAnimation].progress = progress;
										prevAnimationProgress[indexOfAnimation].step = step;
										prevAnimationProgress[indexOfAnimation].date = new Date();
										return prevAnimationProgress;
									}

									return [...prevAnimationProgress, { id: uuid, progress, step, date: new Date() }];
								});
							}
						}}
					/>
				)}
				<PortfolioQueryWidgetBase
					query={commentaryQuery}
					errorFallback={
						<Text type="Body/M/Book">
							Generative AI commentary is not available at the moment. It will be generated soon!
						</Text>
					}
				>
					{({ commentary, shortCommentary }, { isFetching }) => (
						<>
							<div className="grid grid-cols-12 gap-6">
								<div className="col-span-12 lg:col-span-3 mt-2">
									<Card
										style={{
											background: themeCSSVars.palette_P50,
										}}
										title={
											<Text type="Body/XL/Bold" color={themeCSSVars.palette_P500}>
												Summary
											</Text>
										}
									>
										{isFetching ? (
											<div className="h-80 flex">
												<CircularProgressBar value="indeterminate" classList="m-auto" />
											</div>
										) : shortCommentary ? (
											<MarkdownRenderer componentOverrides={markdownOverrides}>{shortCommentary}</MarkdownRenderer>
										) : (
											<Text type="Body/M/Book">Currently unavailable</Text>
										)}
									</Card>
								</div>
								<div className="col-span-12 lg:col-span-9">
									<div className="max-w-[1200px]">
										{isFetching ? (
											<div className="h-80 flex">
												<CircularProgressBar value="indeterminate" classList="m-auto" />
											</div>
										) : (
											<MarkdownRenderer componentOverrides={markdownOverrides}>{commentary ?? ""}</MarkdownRenderer>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</PortfolioQueryWidgetBase>
			</Card>
		</>
	);
}

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	h1: ({ node: _node, ...props }) => (
		<h1 className="text-[18px] font-bold pb-2 pt-2 " {...props}>
			{props.children}
		</h1>
	),
	h2: ({ node: _node, ...props }) => (
		<h2 className="text-[18px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h2>
	),
	h3: ({ node: _node, ...props }) => (
		<h3 className="text-[16px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h3>
	),
	ul: ({ node: _node, ...props }) => <ul className="list-disc pl-4 text-[16px]" {...props} />,
	ol: ({ node: _node, ...props }) => <ul className="list-decimal pl-4 text-[16px]" {...props} />,
	li: ({ node: _node, ...props }) => <li className="text-[16px]" {...props} />,
	p: ({ node: _node, ...props }) => <div className="text text-[16px] pb-2" {...props} />,
	blockquote: ({ node: _node, ...props }) => (
		<blockquote className="bg-sky-100 px-2 py-1 rounded text-[16px]" {...props} />
	),
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-4" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-4 py-2 !font-bold !text-[12px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
	code: ({ node: _node, ...props }) => <span className="text-[16px]" {...props} />,
	pre: ({ node: _node, ...props }) => <i className="text-[16px]" {...props} />,
};
