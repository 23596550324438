import { unpromisify } from "$root/utils/functions";
import { noop } from "$root/utils/runtime";
import type { LogDtoLevelEnum } from "../api-gen";
import { LogControllerApiFactory } from "../api-gen";
import { getApiGen } from "../factory";

const reportPlatformErrorConfig = {
	uid: null as null | string,
};

export function configReportPlatformError(config: Partial<typeof reportPlatformErrorConfig>): void {
	Object.assign(reportPlatformErrorConfig, config);
}

const mappedLogSeverity: Record<LogDtoLevelEnum, (message?: any, ...optionalParams: any[]) => void> = {
	DEBUG: console.debug,
	ERROR: console.error,
	INFO: console.log,
	WARN: console.warn,
};

export type PlatformErrorAreas =
	| "user"
	| "dashboard"
	| "outlook"
	| "outlook-focus"
	| "hmm"
	| "custom-report"
	| "portfolio"
	| "universe"
	| "market-views"
	| "proxy"
	| "global"
	| "advanced-settings"
	| "alerts"
	| "ask-intelligence"
	| "benchmark";

export async function reportPlatformErrorPromise(
	error: unknown,
	severity: LogDtoLevelEnum,
	area?: PlatformErrorAreas,
	attemptedOperation?: string,
): Promise<void> {
	try {
		if (attemptedOperation) {
			const message = `An error occurred while performing the following operation: ${attemptedOperation}, trace: (${error})`;
			mappedLogSeverity[severity](message, error);
			await getApiGen(LogControllerApiFactory).log({ level: severity, area, message });
		} else {
			const message = `An error occurred (user: "${reportPlatformErrorConfig.uid}"), trace: (${error})`;
			mappedLogSeverity[severity](message, error);
			await getApiGen(LogControllerApiFactory).log({ level: severity, area, message });
		}
	} catch (err) {
		// ignored, this function should never throw
	}
}

export function reportPlatformError(
	error: unknown,
	severity: LogDtoLevelEnum,
	area?: PlatformErrorAreas,
	attemptedOperation?: string,
): void {
	// eslint-disable-next-line promise/no-promise-in-callback
	unpromisify(() => reportPlatformErrorPromise(error, severity, area, attemptedOperation).catch(noop))();
}
