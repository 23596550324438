import type { DropdownMenuProps, MessageSeverity } from "@mdotm/mdotui/components";
import { DropdownMenu, Icon } from "@mdotm/mdotui/components";
import { colorBySeverity } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import { useState } from "react";
import type { Crumb } from "./Breadcrumbs";
import { Breadcrumbs } from "./Breadcrumbs";
import { useSize } from "@mdotm/mdotui/react-extensions";
import { appBarHeight } from "./AppBar";
import { PaletteColors } from "$root/styles/themePalette";

export type PageHeaderProps = {
	title: ReactNode;
	titleMenuActions?: DropdownMenuProps<HTMLButtonElement, "">["actions"];
	titleAction?: ReactNode;
	subTitle?: ReactNode;
	severity?: MessageSeverity | "calculating";
	crumbs?: Crumb[];
	/**
	 * Specify in which section you are so data-qualifier can have a context
	 */
	name?: string;
};

const dropdownQualifier = "DropdownMenu";
const titleQualifier = "Title";

export function PageHeader({
	title,
	titleAction,
	titleMenuActions,
	subTitle,
	severity,
	crumbs,
	name,
}: PageHeaderProps): JSX.Element {
	const [outerDiv, setOuterDiv] = useState<HTMLDivElement | null>(null);
	const outerDivHeight = useSize(outerDiv)?.height ?? 0;
	const [stickyPart, setStickyPart] = useState<HTMLDivElement | null>(null);
	const stickyPartHeight = useSize(stickyPart)?.height ?? 0;
	const CustomMappedColorBySeverity = { ...colorBySeverity, calculating: PaletteColors.YELLOW } satisfies Record<
		NonNullable<PageHeaderProps["severity"]>,
		string
	>;
	return (
		<div
			ref={setOuterDiv}
			className={`shadow bg-white pt-4 flex sticky z-40 flex-col min-w-0 ${!severity ? "" : "border-b-2"}`}
			style={{
				margin: "-0.9375rem -0.9375rem 16px", // TODO: delegate padding to a Page component, so this negative margin can be removed
				top: -outerDivHeight + appBarHeight + stickyPartHeight - 1, // -1px for the border
				...(!severity
					? {}
					: {
							borderColor: CustomMappedColorBySeverity[severity],
					  }),
			}}
		>
			{crumbs && (
				<div style={{ paddingLeft: "24px", paddingRight: "24px" }} className="w-full">
					<Breadcrumbs crumbs={crumbs} />
				</div>
			)}
			<div style={{ paddingLeft: "24px", paddingRight: "24px" }} className="flex justify-between items-center pb-4">
				<div className="font-title text-3xl font-bold pt-1 min-w-0">
					<div className="flex items-center">
						<div className="truncate flex-0 pr-2">{title}</div>
						{titleMenuActions && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forwardProps }) => (
									<button
										type="button"
										ref={innerRef}
										aria-expanded={open}
										{...forwardProps}
										data-qualifier={name ? `${name}/${dropdownQualifier}` : dropdownQualifier}
									>
										<Icon classList="rotate-90" icon="More" />
									</button>
								)}
								actions={titleMenuActions}
							/>
						)}
					</div>
				</div>
				<div data-qualifier={name ? `${name}/${titleQualifier}` : titleQualifier}>{titleAction}</div>
			</div>
			<div ref={setStickyPart} className="border-t border-[#DFE2E7]">
				<div style={{ padding: "0 24px" }}>{subTitle}</div>
			</div>
		</div>
	);
}
