import { useTranslation } from "react-i18next";

// interface IInvestmentHorizonLabel {
// 	value: number;
// 	sliderLabel: string;
// 	apiValue: string;
// 	typography: string;
// 	marketviewLabel: string;
// }

// interface IInvestmentHorizonLabels {
// 	multiAsset: IInvestmentHorizonLabel[];
// 	singleStock: IInvestmentHorizonLabel[];
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useInvestmentHorizonSetup = () => {
	const { t } = useTranslation();
	const MULTI = t("PORTFOLIOS.PORTFOLIOS_TYPE_OPTIONS", { returnObjects: true })[0].value as string;
	const LABELS = t("PORTFOLIOS.PORTFOLIOS_INVESTMENT_HORIZON_LABELS", {
		returnObjects: true,
	});

	const getInvestmentHorizonLabels = (type: string) => {
		return type === MULTI ? LABELS.multiAsset : LABELS.singleStock;
	};

	const getInvestmentHorizonSliderValue = (type: string, label: string): number => {
		return (getInvestmentHorizonLabels(type).find((x) => x.sliderLabel === label)?.value as number) || 0;
	};

	const getInvestmentHorizonSliderValueByApiValue = (type: string, apiValue: string): number => {
		return getInvestmentHorizonLabels(type).find((x) => x.apiValue === apiValue)?.value || 0;
	};

	const getInvestmentHorizonSliderValueByNewApiValue = (type: string, newApiValue: string): number => {
		return getInvestmentHorizonLabels(type).find((x) => x.newApiValue === newApiValue)?.value || 0;
	};

	const getInvestmentHorizonApiValue = (type: string, value: number): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.value === value)?.apiValue || "";
	};

	const getInvestmentHorizonNewApiValue = (type: string, value: number): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.value === value)?.newApiValue || "";
	};

	const getInvestmentHorizonLabel = (type: string, value: number): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.value === value)?.sliderLabel || "";
	};

	const getInvestmentHorizonTypography = (type: string, value: number): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.value === value)?.typography || "";
	};

	const getInvestmentHorizonMarketView = (type: string, value: number): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.value === value)?.marketviewLabel || "";
	};

	const getInvestmentHorizonTypographyFromNewApiValue = (type: string, newApiValue: string): string => {
		return getInvestmentHorizonLabels(type).find((x) => x.newApiValue === newApiValue)?.typography || "";
	};

	return {
		getInvestmentHorizonLabels,
		getInvestmentHorizonSliderValue,
		getInvestmentHorizonApiValue,
		getInvestmentHorizonLabel,
		getInvestmentHorizonTypography,
		getInvestmentHorizonMarketView,
		getInvestmentHorizonSliderValueByApiValue,
		getInvestmentHorizonSliderValueByNewApiValue,
		getInvestmentHorizonNewApiValue,
		getInvestmentHorizonTypographyFromNewApiValue,
	};
};

export default useInvestmentHorizonSetup;
