import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const Median: FC<Omit<SvgProps, "viewBox" | "children">> = (props) => {
	return (
		<Svg viewBox={{ width: 13, height: 9 }} fill="none" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M13 8L0 8V1L13 1V8Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M13 8L0 8V1L13 1V8Z" fill="#8C8EA8" fillOpacity="0.5" />
			<path d="M13 8L0 8M0 1L13 1" stroke="#8C8EA8" />
			<rect x="5.5" y="1" width="2" height="7" fill="#2F3541" />
		</Svg>
	);
};

export default Median;
