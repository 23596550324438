export function HistorySectionIcon(): JSX.Element {
	return (
		<svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_857_179614)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M70.14 12.55C69.79 12.2 69.3 12 68.8 12H31.01C30.51 12 30.03 12.2 29.67 12.55C29.32 12.9 29.12 13.39 29.12 13.89V17.09L23.97 19.67C23.76 19.77 23.6 19.95 23.51 20.17C23.43 20.39 23.43 20.63 23.51 20.85C23.6 21.07 23.76 21.25 23.97 21.35L29.12 23.92V27.12C29.12 27.62 29.32 28.1 29.67 28.46C30.02 28.81 30.51 29.01 31.01 29.01H68.8C69.3 29.01 69.78 28.81 70.14 28.46C70.49 28.11 70.69 27.62 70.69 27.12V13.89C70.69 13.39 70.49 12.91 70.14 12.55ZM53.69 48.85C54.19 48.85 54.67 48.65 55.03 48.3C55.38 47.95 55.58 47.46 55.58 46.96V43.76L60.73 41.19C60.94 41.09 61.1 40.91 61.19 40.69C61.27 40.47 61.27 40.23 61.19 40.01C61.1 39.79 60.94 39.61 60.73 39.51L55.58 36.94V33.74C55.58 33.24 55.38 32.76 55.03 32.4C54.68 32.05 54.19 31.85 53.69 31.85H15.89C15.39 31.85 14.91 32.05 14.55 32.4C14.2 32.75 14 33.24 14 33.74V46.97C14 47.47 14.2 47.95 14.55 48.31C14.9 48.66 15.39 48.86 15.89 48.86H53.69V48.85ZM31.01 51.68H68.8C69.3 51.68 69.79 51.88 70.14 52.23C70.49 52.59 70.69 53.07 70.69 53.57V66.8C70.69 67.3 70.49 67.79 70.14 68.14C69.78 68.49 69.3 68.69 68.8 68.69H31.01C30.51 68.69 30.02 68.49 29.67 68.14C29.32 67.78 29.12 67.3 29.12 66.8V63.6L23.97 61.03C23.76 60.93 23.6 60.75 23.51 60.53C23.43 60.31 23.43 60.07 23.51 59.85C23.6 59.63 23.76 59.45 23.97 59.35L29.12 56.77V53.57C29.12 53.07 29.32 52.58 29.67 52.23C30.03 51.88 30.51 51.68 31.01 51.68Z"
					fill="white"
				/>
			</g>
			<path
				d="M53.69 48.85H15.89C15.39 48.85 14.91 48.65 14.55 48.3C14.2 47.95 14 47.46 14 46.96V33.73C14 33.23 14.2 32.75 14.55 32.39C14.9 32.04 15.39 31.84 15.89 31.84H53.69C54.19 31.84 54.67 32.04 55.03 32.39C55.38 32.74 55.58 33.23 55.58 33.73V36.93L60.73 39.5C60.94 39.6 61.1 39.78 61.19 40C61.27 40.22 61.27 40.46 61.19 40.68C61.1 40.9 60.94 41.08 60.73 41.18L55.58 43.75V46.95C55.58 47.45 55.38 47.93 55.03 48.29C54.68 48.64 54.19 48.84 53.69 48.84V48.85ZM15.89 33.73V46.96H53.69V43.18C53.69 43 53.74 42.83 53.83 42.68C53.92 42.53 54.05 42.41 54.21 42.33L58.19 40.35L54.21 38.37C54.05 38.29 53.92 38.17 53.83 38.02C53.74 37.87 53.69 37.7 53.69 37.52V33.74H15.89V33.73Z"
				fill="#C3C9D5"
			/>
			<path
				d="M68.7992 29.01H31.0092C30.5092 29.01 30.0292 28.81 29.6692 28.46C29.3192 28.11 29.1192 27.62 29.1192 27.12V23.92L23.9692 21.35C23.7592 21.25 23.5992 21.07 23.5092 20.85C23.4292 20.63 23.4292 20.39 23.5092 20.17C23.5992 19.95 23.7592 19.77 23.9692 19.67L29.1192 17.09V13.89C29.1192 13.39 29.3192 12.91 29.6692 12.55C30.0192 12.2 30.5092 12 31.0092 12H68.7992C69.2992 12 69.7792 12.2 70.1392 12.55C70.4892 12.9 70.6892 13.39 70.6892 13.89V27.12C70.6892 27.62 70.4892 28.1 70.1392 28.46C69.7892 28.81 69.2992 29.01 68.7992 29.01ZM26.5092 20.5L30.4892 22.49C30.6492 22.57 30.7792 22.69 30.8692 22.84C30.9592 22.99 31.0092 23.16 31.0092 23.34V27.12H68.7992V13.89H31.0092V17.67C31.0092 17.85 30.9592 18.02 30.8692 18.17C30.7792 18.32 30.6492 18.44 30.4892 18.52L26.5092 20.51V20.5Z"
				fill="#C3C9D5"
			/>
			<path d="M24.3906 39.4H28.1706V41.29H24.3906V39.4Z" fill="#C3C9D5" />
			<path d="M30.0605 39.4H33.8405V41.29H30.0605V39.4Z" fill="#C3C9D5" />
			<path d="M35.7305 39.4H39.5105V41.29H35.7305V39.4Z" fill="#C3C9D5" />
			<path d="M41.4004 39.4H45.1804V41.29H41.4004V39.4Z" fill="#C3C9D5" />
			<path d="M34.7891 17.67H48.0191V19.56H34.7891V17.67Z" fill="#C3C9D5" />
			<path d="M49.9102 17.67H51.8002V19.56H49.9102V17.67Z" fill="#C3C9D5" />
			<path d="M53.6895 17.67H55.5795V19.56H53.6895V17.67Z" fill="#C3C9D5" />
			<path d="M57.4609 17.67H65.0209V19.56H57.4609V17.67Z" fill="#C3C9D5" />
			<path d="M34.7891 21.45H65.0291V23.34H34.7891V21.45Z" fill="#C3C9D5" />
			<path
				d="M68.7992 68.6901H31.0092C30.5092 68.6901 30.0292 68.4901 29.6692 68.1401C29.3192 67.7901 29.1192 67.3001 29.1192 66.8001V63.6001L23.9692 61.0301C23.7592 60.9301 23.5992 60.7501 23.5092 60.5301C23.4292 60.3101 23.4292 60.0701 23.5092 59.8501C23.5992 59.6301 23.7592 59.4501 23.9692 59.3501L29.1192 56.7701V53.5701C29.1192 53.0701 29.3192 52.5901 29.6692 52.2301C30.0192 51.8801 30.5092 51.6801 31.0092 51.6801H68.7992C69.2992 51.6801 69.7792 51.8801 70.1392 52.2301C70.4892 52.5801 70.6892 53.0701 70.6892 53.5701V66.8001C70.6892 67.3001 70.4892 67.7801 70.1392 68.1401C69.7892 68.4901 69.2992 68.6901 68.7992 68.6901ZM26.5092 60.1901L30.4892 62.1701C30.6492 62.2501 30.7792 62.3701 30.8692 62.5201C30.9592 62.6701 31.0092 62.8401 31.0092 63.0201V66.8001H68.7992V53.5701H31.0092V57.3501C31.0092 57.5301 30.9592 57.7001 30.8692 57.8501C30.7792 58.0001 30.6492 58.1201 30.4892 58.2001L26.5092 60.1801V60.1901Z"
				fill="#4CB09C"
			/>
			<path d="M34.7891 57.3501H57.4691V59.2401H34.7891V57.3501Z" fill="#4CB09C" />
			<path d="M34.7891 61.13H65.0291V63.02H34.7891V61.13Z" fill="#4CB09C" />
			<path d="M63.1289 57.3501H65.0189V59.2401H63.1289V57.3501Z" fill="#4CB09C" />
			<path d="M59.3496 57.3501H61.2396V59.2401H59.3496V57.3501Z" fill="#4CB09C" />
			<defs>
				<filter
					id="filter0_d_857_179614"
					x="0"
					y="0"
					width="84.6895"
					height="84.6899"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_179614" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_857_179614" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}
