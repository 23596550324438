export const exposureCompositionListRowHeight = 40;
export const exposureCompositionListItemsPerRow = 3;
export const exposureChartHeight = 280;
export const exposureMixedChartHeight = 880; // TODO: handle landscape?
export const exposurePortraitPageHeight = 890;
export const exposureLandscapePageHeight = 320; // TODO: check

export function exposureAvailableItemSlotsByChartHeight(chartHeight: number, layout: "portrait" | "landscape"): number {
	return (
		Math.floor(
			exposureAvailableHeightForItemSlotsByChartHeight(chartHeight, layout) / exposureCompositionListRowHeight,
		) * exposureCompositionListItemsPerRow
	);
}

export function exposureAvailableHeightForItemSlotsByChartHeight(
	chartHeight: number,
	layout: "portrait" | "landscape",
): number {
	return Math.max(
		0,
		(layout === "portrait" ? exposurePortraitPageHeight : exposureLandscapePageHeight) -
			(chartHeight > 0 ? chartHeight : 0),
	);
}
