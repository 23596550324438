export type PortfolioCommentaryGenerationPolicyUsageLevel = "low" | "warning" | "danger";

export class PortfolioCommentaryGenerationPolicy {
	constructor(
		public used: number,
		public total: number,
	) {}

	canGenerate(): boolean {
		return this.total > this.used;
	}
	remaining(): number {
		return this.total - this.used;
	}
	usageLevel(): PortfolioCommentaryGenerationPolicyUsageLevel {
		if (this.used > this.total * 0.95) {
			return "danger";
		}
		if (this.used > this.total * 0.9) {
			return "warning";
		}
		return "low";
	}
}
