import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const DashedLineIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 3,
		height: 35,
		fill: "none",
		stroke: "#BFC4CE",
	},
	...props
}) => {
	return (
		<Svg
			viewBox={{ width: 3, height: 32 }}
			style={style}
			strokeWidth="2"
			strokeLinejoin="round"
			strokeLinecap="round"
			{...props}
		>
			<path d="M1.74897 35V0" strokeDasharray="1 5" />
		</Svg>
	);
};

export default DashedLineIcon;
