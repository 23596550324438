import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const CloudClaculatingDrawIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 86,
		height: "auto",
	},
	...other
}): JSX.Element => {
	return (
		<Svg style={style} viewBox={{ width: 86, height: 93 }} {...other}>
			<g filter="url(#filter0_d_1780_13412)">
				<path
					d="M66 27L65 25C64.4501 23.4891 63.2789 23.2984 62 22.3718C60.7211 21.4453 60.4014 21.6529 58.8398 21.634C58.4844 21.6357 58.1294 21.6615 57.7773 21.7111C57.1022 18.9488 55.5589 16.4964 53.3895 14.7388C51.2202 12.9812 48.5477 12.0179 45.7914 12.0001C43.8651 11.9905 41.9639 12.4525 40.2435 13.3484C38.5231 14.2442 37.0323 15.5484 35.8933 17.1543C34.0765 16.5797 32.1182 16.7217 30.3968 17.5526C28.6754 18.3836 27.313 19.8446 26.573 21.6533C25.1035 21.7979 23.7005 22.3568 22.5172 23.2691C21.3339 24.1814 20.4156 27.5853 19.8625 29H19.21C17.8207 29.0178 16.4942 29.6014 15.5187 30.6242C14.5432 31.6469 13.9974 33.0262 14 34.4624L14 61.8074C13.9975 63.2493 14.5487 64.6333 15.5325 65.6557C16.5163 66.678 17.8523 67.255 19.2473 67.2601H34.5512L30.385 74.0039H25.0911C24.7268 74.0341 24.3869 74.205 24.139 74.4826C23.8911 74.7602 23.7535 75.1242 23.7535 75.5019C23.7535 75.8797 23.8911 76.2436 24.139 76.5212C24.3869 76.7989 24.7268 76.9698 25.0911 77H60.8996C61.2701 77.0005 61.6267 76.8538 61.8956 76.5902C62.1645 76.3266 62.3251 75.9663 62.3442 75.5838C62.3456 75.1843 62.195 74.8002 61.9248 74.5145C61.7907 74.3743 61.631 74.263 61.4551 74.1869C61.2791 74.1108 61.0903 74.0716 60.8996 74.0713H55.5963L51.4488 67.3276H66.734C68.1315 67.325 69.4709 66.7494 70.4582 65.7271C71.4455 64.7048 72 63.3193 72 61.8748V35.5C72.0012 34.7839 72 33 72 33C72 33 71.4395 31.6386 70.9505 31.1314C70.4615 30.6242 68.6396 29.2752 68 29C67.3604 28.7248 66 27.6733 66 27Z"
					fill="white"
				/>
				<path
					d="M67.9737 29.0002C67.9737 29.1389 68.0203 29.2776 68.0389 29.4255L68.1601 30.6276C68.1488 31.1161 68.0895 31.6023 67.983 32.0794C68.3279 32.2898 68.6122 32.5851 68.8082 32.9367C69.0041 33.2883 69.1051 33.6842 69.1014 34.0859V62.4273C69.1039 62.7364 69.0443 63.0429 68.9263 63.329C68.8082 63.6151 68.6339 63.875 68.4136 64.0935C68.1932 64.3121 67.9313 64.485 67.6429 64.6022C67.3546 64.7194 67.0456 64.7784 66.7341 64.776H19.2474C18.937 64.7772 18.6295 64.7172 18.3428 64.5994C18.056 64.4815 17.7958 64.3083 17.5772 64.0897C17.3586 63.8711 17.186 63.6115 17.0695 63.3261C16.9531 63.0407 16.895 62.7352 16.8987 62.4273V34.0859C16.8949 33.6842 16.996 33.2883 17.1919 32.9367C17.3879 32.5851 17.6722 32.2898 18.0171 32.0794C17.9121 31.6018 17.8498 31.116 17.8307 30.6276C17.8307 30.6276 17.8866 29.4348 17.9332 29.0002C16.8013 29.294 15.8003 29.9533 15.0881 30.8743C14.3759 31.7952 13.9931 32.9252 14.0001 34.0859V62.4273C14.0001 63.8097 14.5523 65.1357 15.5359 66.115C16.5194 67.0942 17.854 67.6468 19.2474 67.6517H34.5512L30.3851 74.1245H25.0912C24.7269 74.1535 24.387 74.3175 24.1391 74.584C23.8912 74.8504 23.7535 75.1997 23.7535 75.5623C23.7535 75.9249 23.8912 76.2742 24.1391 76.5407C24.387 76.8071 24.7269 76.9712 25.0912 77.0002H60.8996C61.282 76.9978 61.648 76.846 61.9184 76.5777C62.1888 76.3095 62.3418 75.9463 62.3442 75.5669C62.3457 75.3783 62.3094 75.1912 62.2374 75.0166C62.1654 74.842 62.0591 74.6833 61.9248 74.5498C61.792 74.4136 61.6326 74.3056 61.4563 74.2325C61.28 74.1594 61.0906 74.1226 60.8996 74.1245H55.5964L51.4489 67.6517H66.7341C68.1307 67.6517 69.4701 67.1013 70.4576 66.1215C71.4452 65.1417 72 63.8129 72 62.4273V34.0859C71.9986 32.9137 71.6012 31.7757 70.8713 30.8538C70.1415 29.9319 69.1213 29.2793 67.9737 29.0002V29.0002ZM52.2131 74.1614H33.8243L37.9811 67.6887H48.0097L52.2131 74.1614Z"
					fill="#D1D2DC"
				/>
				<g filter="url(#filter1_d_1780_13412)">
					<path
						d="M44.2909 49.5236V44.6211H41.9608V49.5236C41.4066 49.7505 40.9329 50.138 40.6007 50.6363C40.2685 51.1345 40.093 51.7208 40.0967 52.3197C40.0967 53.123 40.4159 53.8935 40.9839 54.4615C41.552 55.0296 42.3225 55.3487 43.1258 55.3487C43.9292 55.3487 44.6996 55.0296 45.2677 54.4615C45.8358 53.8935 46.1549 53.123 46.1549 52.3197C46.1537 51.7219 45.9762 51.1378 45.6446 50.6405C45.313 50.1431 44.8421 49.7546 44.2909 49.5236ZM43.1258 51.6113C43.2641 51.6113 43.3992 51.6523 43.5142 51.7291C43.6291 51.8059 43.7187 51.9151 43.7716 52.0428C43.8245 52.1706 43.8384 52.3111 43.8114 52.4467C43.7844 52.5823 43.7179 52.7069 43.6201 52.8046C43.5223 52.9024 43.3978 52.969 43.2622 52.9959C43.1266 53.0229 42.9861 53.0091 42.8583 52.9561C42.7306 52.9032 42.6214 52.8136 42.5446 52.6987C42.4678 52.5837 42.4268 52.4486 42.4268 52.3103C42.4292 52.1257 42.5036 51.9493 42.6342 51.8187C42.7648 51.6882 42.9412 51.6137 43.1258 51.6113Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter2_d_1780_13412)">
					<path
						d="M66.5289 28.1056C66.3169 26.2459 65.4306 24.5283 64.0377 23.278C62.6448 22.0277 60.8419 21.3312 58.9702 21.3205C58.6116 21.3218 58.2536 21.3467 57.8983 21.395C57.2251 18.7234 55.6836 16.3511 53.5158 14.6506C51.348 12.9502 48.6769 12.0179 45.9218 12.0002C43.9939 11.9894 42.0908 12.4357 40.3687 13.3024C38.6465 14.1691 37.1543 15.4317 36.0144 16.9865C34.2049 16.4399 32.2574 16.5798 30.5446 17.3794C28.8318 18.1791 27.4741 19.5823 26.7314 21.3205C23.3201 21.5907 20.0953 24.4614 19.6852 27.6769C19.6852 28.0031 19.592 29.2427 19.592 29.2427C19.655 31.2406 20.4925 33.1356 21.9275 34.5271C23.3624 35.9186 25.2824 36.6975 27.2813 36.6989H33.1064V34.3688H27.2813C24.4852 34.3688 22.239 32.1133 21.9221 29.0004C21.9219 27.6657 22.4205 26.3793 23.3201 25.3934C23.8293 24.8565 24.44 24.426 25.1167 24.1268C25.7935 23.8277 26.5228 23.6658 27.2626 23.6505L28.409 23.6971L28.6607 22.8303C28.8771 22.0979 29.2476 21.4202 29.7474 20.8427C30.2471 20.2651 30.8645 19.8011 31.5582 19.4816C32.2519 19.1621 33.0058 18.9946 33.7696 18.9902C34.5333 18.9859 35.2891 19.1448 35.9864 19.4564L36.9184 19.8758L37.459 19.0184C38.5489 17.2791 40.1475 15.9174 42.0379 15.1179C43.9283 14.3185 46.0189 14.12 48.0259 14.5495C50.033 14.979 51.8593 16.0157 53.2569 17.5188C54.6546 19.0219 55.5559 20.9186 55.8386 22.9515L56.025 24.3216L57.3484 23.9022C58.0854 23.668 58.8643 23.5961 59.6317 23.6914C60.399 23.7866 61.1367 24.0468 61.794 24.4541C62.4513 24.8614 63.0127 25.4061 63.4396 26.0508C63.8666 26.6955 64.1489 27.425 64.2673 28.1891C64.3857 28.9533 64.3374 29.734 64.1256 30.4777C63.9137 31.2213 63.5435 31.9104 63.0403 32.4975C62.537 33.0846 61.9127 33.5558 61.2101 33.8788C60.5076 34.2018 59.7434 34.369 58.9702 34.3688H53.145V36.6989H58.9702C60.9674 36.6951 62.885 35.9152 64.318 34.5239C65.751 33.1327 66.5872 31.239 66.6501 29.2427L66.5289 28.1056Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter3_d_1780_13412)">
					<path
						d="M52.0172 18.2541C50.4005 16.6377 48.2079 15.7297 45.9218 15.7297C43.6356 15.7297 41.4431 16.6377 39.8263 18.2541L39.6586 18.4218L41.3083 20.0622L41.4667 19.9038C42.6491 18.7273 44.2492 18.0669 45.9171 18.0669C47.5851 18.0669 49.1852 18.7273 50.3676 19.9038L50.5353 20.0622L52.213 18.4218L52.0172 18.2541Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter4_d_1780_13412)">
					<path
						d="M58.9702 30.6407H58.7372V32.9708H58.9702C60.0207 32.9708 61.0283 32.5535 61.7711 31.8106C62.514 31.0678 62.9313 30.0602 62.9313 29.0097C62.9313 27.9591 62.514 26.9516 61.7711 26.2087C61.0283 25.4659 60.0207 25.0486 58.9702 25.0486H58.7372V27.3786H58.9702C59.4028 27.3786 59.8176 27.5505 60.1235 27.8564C60.4294 28.1622 60.6012 28.5771 60.6012 29.0097C60.6012 29.4423 60.4294 29.8571 60.1235 30.163C59.8176 30.4689 59.4028 30.6407 58.9702 30.6407Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter5_d_1780_13412)">
					<path d="M44.2908 29.7087H41.9607V43.2231H44.2908V29.7087Z" fill="#4CB09C" />
				</g>
				<g filter="url(#filter6_d_1780_13412)">
					<path
						d="M61.7662 44.6211C61.1682 44.6211 60.5836 44.7981 60.086 45.1299C59.5884 45.4616 59.2002 45.9332 58.9701 46.4852H51.7469V33.4368H49.4169V48.8152H58.9795C59.1903 49.3213 59.5346 49.7607 59.9756 50.0865C60.4165 50.4123 60.9377 50.6123 61.4834 50.6652C62.0291 50.7181 62.5789 50.6218 63.0742 50.3868C63.5695 50.1517 63.9918 49.7866 64.2959 49.3304C64.6 48.8743 64.7746 48.3441 64.8011 47.7965C64.8276 47.2488 64.7049 46.7043 64.4462 46.2209C64.1875 45.7375 63.8025 45.3334 63.3321 45.0517C62.8618 44.7699 62.3238 44.6211 61.7755 44.6211H61.7662ZM62.4652 47.6502C62.4652 47.7885 62.4242 47.9236 62.3474 48.0386C62.2706 48.1535 62.1615 48.2431 62.0337 48.296C61.906 48.3489 61.7654 48.3628 61.6299 48.3358C61.4943 48.3088 61.3697 48.2422 61.2719 48.1445C61.1742 48.0467 61.1076 47.9222 61.0806 47.7866C61.0537 47.651 61.0675 47.5104 61.1204 47.3827C61.1733 47.255 61.2629 47.1458 61.3779 47.069C61.4928 46.9922 61.628 46.9512 61.7662 46.9512C61.9509 46.9536 62.1273 47.028 62.2578 47.1586C62.3884 47.2892 62.4628 47.4656 62.4652 47.6502Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter7_d_1780_13412)">
					<path d="M51.7469 29.7087H49.4169V32.0388H51.7469V29.7087Z" fill="#4CB09C" />
				</g>
				<g filter="url(#filter8_d_1780_13412)">
					<path
						d="M34.5044 46.4852H27.2625C26.9932 45.8388 26.5083 45.3057 25.8902 44.9766C25.2721 44.6474 24.5591 44.5426 23.8724 44.6799C23.1858 44.8173 22.5679 45.1883 22.1239 45.7298C21.68 46.2713 21.4374 46.95 21.4374 47.6502C21.4374 48.3505 21.68 49.0291 22.1239 49.5706C22.5679 50.1121 23.1858 50.4831 23.8724 50.6205C24.5591 50.7578 25.2721 50.653 25.8902 50.3239C26.5083 49.9947 26.9932 49.4616 27.2625 48.8152H36.8345V38.0969H34.5044V46.4852ZM25.1841 47.6502C25.1841 47.7885 25.1431 47.9236 25.0663 48.0386C24.9895 48.1535 24.8803 48.2431 24.7526 48.296C24.6249 48.3489 24.4843 48.3628 24.3487 48.3358C24.2131 48.3088 24.0886 48.2422 23.9908 48.1445C23.8931 48.0467 23.8265 47.9222 23.7995 47.7866C23.7725 47.651 23.7864 47.5104 23.8393 47.3827C23.8922 47.255 23.9818 47.1458 24.0968 47.069C24.2117 46.9922 24.3469 46.9512 24.4851 46.9512C24.6697 46.9536 24.8461 47.028 24.9767 47.1586C25.1073 47.2892 25.1817 47.4656 25.1841 47.6502Z"
						fill="#4CB09C"
					/>
				</g>
				<g filter="url(#filter9_d_1780_13412)">
					<path d="M36.8346 29.7087H34.5045V36.6989H36.8346V29.7087Z" fill="#4CB09C" />
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1780_13412"
					x="0"
					y="0"
					width="86.0005"
					height="93.0002"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter1_d_1780_13412"
					x="38.0967"
					y="42.6211"
					width="10.0582"
					height="14.7276"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter2_d_1780_13412"
					x="17.592"
					y="10"
					width="51.058"
					height="28.6989"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter3_d_1780_13412"
					x="37.6586"
					y="13.7297"
					width="16.5544"
					height="8.33255"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter4_d_1780_13412"
					x="56.7372"
					y="23.0486"
					width="8.19409"
					height="11.9222"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter5_d_1780_13412"
					x="39.9607"
					y="27.7087"
					width="6.33008"
					height="17.5144"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter6_d_1780_13412"
					x="47.4169"
					y="31.4368"
					width="19.3878"
					height="21.2425"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter7_d_1780_13412"
					x="47.4169"
					y="27.7087"
					width="6.33008"
					height="6.33006"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter8_d_1780_13412"
					x="19.4374"
					y="36.0969"
					width="19.3971"
					height="16.5824"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
				<filter
					id="filter9_d_1780_13412"
					x="32.5045"
					y="27.7087"
					width="6.33008"
					height="10.9902"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0 0.937255 0 0 0 0.1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1780_13412" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1780_13412" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CloudClaculatingDrawIcon;
