import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { ContextContent } from "$root/utils/react-extra";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { withContext } from "$root/utils/react-extra";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { PortfolioMetricsBase } from "../PortfolioMetrics/PortfolioMetricsBase";
import { WidgetStatus, portfolioWidgetMissingDataReason } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";

const ExanteMetricsBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const enhanced = props.enhanced;
	const benchmarkId = props.selectedBenchmark ?? props.portfolio?.primaryBenchmarkIdentifier;
	const { t } = useTranslation();
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const reportEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const isCalculating = useMemo(
		() =>
			(props.portfolio?.action === "UPLOAD" || props.portfolio?.action === "CREATION") &&
			props.portfolio?.status === "CALCULATING",
		[props.portfolio?.action, props.portfolio?.status],
	);

	return (
		<PortfolioMetricsBase
			hasBenchmark={Boolean(benchmarkId)}
			title={t("EXANTE_METRICS.TITLE")}
			widgetTooltip={t("EXANTE_METRICS.TOOLTIP")}
			noDataText={t("EXANTE_METRICS.NO_DATA")}
			queryKey={["Exante H Metrics", uuid, benchmarkId, props.portfolio?.status, props.reportExcutionCounter]}
			/* saveHandler={async () => {
				await sleep(1000); // TODO: actual api call
			}} */
			metricsProvider={async () => {
				const { portfolioExAnteMetrics } = enhanced
					? await axiosExtract(reportEnhanceApi.getPortfolioExAnteMetrics1(uuid ?? "", benchmarkId ?? ""))
					: await axiosExtract(reportInvestmentApi.getPortfolioExAnteMetrics(uuid ?? "", benchmarkId ?? ""));

				if (!portfolioExAnteMetrics || isCalculating) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				const metrics = portfolioExAnteMetrics?.flatMap((metric) =>
					metric.type
						? [
								{
									label: t(`EXANTE_METRICS.METRICS.${metric.type}`),
									name: metric.type,
									visible: true,
									current: metric.current,
									benchmark: metric.benchmark,
									proposal: metric.proposal,
								},
						  ]
						: [],
				);

				return {
					data: metrics,
					widgetStatus: WidgetStatus.READY,
				};
			}}
		/>
	);
};

export default withContext(PortfolioContext)(ExanteMetricsBlock);
