import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import { type FC } from "react";

const CreatePortfolioIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 71,
		height: 68,
	},
	...props
}) => {
	return (
		<Svg viewBox={{ width: 71, height: 68 }} style={style} {...props}>
			<path
				fill="#4CB09C"
				d="M28.81 12.57c-7.684 0-8.112-9.116-8.12-10.943 0-.04 0-.031 0 0V1.4a.912.912 0 00-.257-.684.915.915 0 00-.669-.273.934.934 0 00-.668.273.952.952 0 00-.265.676v.234c0 1.827-.428 10.943-8.12 10.943a.936.936 0 00-.933.933c0 .514.42.934.933.934 7.684 0 8.112 9.115 8.12 10.943v.233a.945.945 0 00.257.677.96.96 0 00.676.288.922.922 0 00.67-.28.92.92 0 00.264-.677v-.148-.093c.008-1.828.435-10.943 8.12-10.943.513 0 .933-.42.933-.934a.936.936 0 00-.933-.933h-.008zm-4.286.933c-2.177 1.09-3.81 3.143-4.767 6.013-.957-2.863-2.583-4.924-4.768-6.013 2.185-1.088 3.811-3.142 4.768-6.012.956 2.862 2.582 4.924 4.767 6.012zM7.546 10.075c.497-.513 1.135-.762 1.96-.762.513 0 .933-.42.933-.934a.936.936 0 00-.933-.933c-.817 0-1.447-.241-1.945-.739-1.05-1.058-1.174-2.987-1.19-3.539v-.536h-.047a.945.945 0 00-.217-.358.93.93 0 00-1.035-.195.926.926 0 00-.56.895H4.8v-.008l-.288.101c0 .404-.07 2.489-1.174 3.632-.498.514-1.136.763-1.96.763a.936.936 0 00-.934.933c0 .513.42.933.934.933.809 0 1.446.241 1.936.74 1.058 1.072 1.183 3.017 1.19 3.577v.225c.016.498.42.895.934.903a.94.94 0 00.933-.964h-.295l.287-.086c0-.405.063-2.49 1.175-3.633l.008-.015zm-.903-1.696a4.256 4.256 0 00-.435.397 4.802 4.802 0 00-.762 1.042 5.212 5.212 0 00-.763-1.042 6.916 6.916 0 00-.435-.397c.14-.109.28-.233.404-.365.304-.304.568-.661.794-1.074.217.39.474.74.762 1.043.14.14.288.272.435.396zM15.004 26.043c-.59 0-1.05-.171-1.4-.53-.622-.63-.863-1.788-.886-2.644v-.505h-.055a.943.943 0 00-.218-.358.917.917 0 00-1.034-.202.94.94 0 00-.568.886c0 .016.031 1.875-.87 2.808-.35.366-.817.545-1.409.545a.936.936 0 00-.933.933c0 .513.42.933.933.933.592 0 1.05.171 1.4.53.63.637.871 1.85.887 2.675v.202c.016.498.42.894.933.902a.941.941 0 00.933-.964c0-.016-.03-1.875.872-2.808.35-.366.816-.545 1.408-.545.513 0 .933-.42.933-.933a.936.936 0 00-.933-.933l.007.008zm-2.566.933c-.063.054-.125.117-.187.179a4.263 4.263 0 00-.467.583 3.492 3.492 0 00-.466-.583c-.063-.062-.125-.125-.187-.18a4.17 4.17 0 00.653-.762 3.837 3.837 0 00.654.763z"
			/>
			<g filter="url(#filter0_d_445_5961)">
				<path
					fill="#fff"
					d="M53.777 28.336h-.77v-7.708a3.854 3.854 0 00-3.85-3.85H21.413a3.856 3.856 0 00-3.858 3.85V47.6a3.854 3.854 0 003.85 3.85H49.15a3.854 3.854 0 003.85-3.85v-3.858h.77a2.31 2.31 0 002.31-2.31V30.646a2.31 2.31 0 00-2.31-2.31h.008z"
				/>
			</g>
			<path
				fill="#C3C9D5"
				d="M53.87 28.336h-.677v-7.607a3.957 3.957 0 00-3.95-3.951H21.506a3.957 3.957 0 00-3.952 3.95v26.974a3.957 3.957 0 003.952 3.951H49.25a3.957 3.957 0 003.951-3.95v-3.757h.677a2.413 2.413 0 002.41-2.411V30.747a2.413 2.413 0 00-2.41-2.411h-.008zm-2.411-4.698a3.862 3.862 0 00-2.217-.7H21.507a2.22 2.22 0 01-2.217-2.217c0-1.22.972-2.193 2.17-2.216h27.813a2.22 2.22 0 012.194 2.216v2.91l-.008.007zm-32.169.342c.661.459 1.423.7 2.217.7H49.25a2.22 2.22 0 012.217 2.217v4.526H45.4s-.047 0-.078.016a.66.66 0 00-.17.031c-.048.016-.102.031-.157.062a.772.772 0 00-.132.086c-.054.047-.093.085-.14.132a.131.131 0 00-.046.047l-3.858 5.592s-.016.023-.008.023a.566.566 0 00-.07.171l-.016.032s-.03.07-.038.108c-.008.047 0 .086 0 .125v.078c0 .038 0 .085.007.132.008.039.024.07.04.1l.023.063a.684.684 0 00.062.132l3.873 5.243.04.039a.776.776 0 00.21.17l.022.016s.04.031.063.04a.896.896 0 00.365.085h6.067v3.756a2.22 2.22 0 01-2.217 2.217H21.507a2.22 2.22 0 01-2.217-2.217V23.98zm35.257 17.555a.677.677 0 01-.677.676h-8.042l-3.22-4.355 3.243-4.698h8.019c.218 0 .443-.031.677-.11v8.486zM53.2 31.415V30.07h.677a.677.677 0 010 1.353H53.2v-.007z"
			/>
			<path
				fill="#C3C9D5"
				d="M48.472 35.273a2.413 2.413 0 00-2.41 2.411 2.413 2.413 0 002.41 2.412 2.413 2.413 0 002.411-2.412 2.413 2.413 0 00-2.41-2.41zm.677 2.411a.677.677 0 11-1.354 0 .677.677 0 011.354 0z"
			/>
			<defs>
				<filter
					id="filter0_d_445_5961"
					width="66.523"
					height="62.673"
					x="3.556"
					y="4.778"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_445_5961" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_445_5961" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CreatePortfolioIcon;
