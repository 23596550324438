import type { CSSProperties, FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "@mdotm/mdotui/components";
import { PaletteColors } from "$root/styles/themePalette";

interface TProps {
	border?: CSSProperties["border"];
	backgroundColor?: CSSProperties["backgroundColor"];
	color?: CSSProperties["color"];
	content: {
		label: string;
		component?: ReactNode;
	};
	type?: "lastAction" | "status";
}

export const LabelRounded: FC<TProps> = ({ content, color, backgroundColor, border, type = "lastAction" }) => {
	const { t } = useTranslation();
	// TODO il basePath potrebbe diventare dinamico se il componente viene utilizzato altrove, il tutto per gestire le traduzioni dinamiche
	if (content.label) {
		switch (content.label) {
			case "ACCEPTED":
				backgroundColor = "#DBEFEB";
				content.component = <Icon icon="Icon-Ptf-status-SMALL" color={PaletteColors.GREENY_BLUE} size={15} />;
				break;
			case "READY":
				backgroundColor = "#DBEFEB";
				content.component = <Icon icon="Icon-Ptf-status-SMALL" color={PaletteColors.GREENY_BLUE} size={15} />;
				break;
			case "PROPOSAL_READY":
				backgroundColor = "#E5F6FD";
				content.component = <Icon icon="Icon-full-small" color={PaletteColors.AZURE} size={15} />;
				break;
			case "PROPOSAL":
				backgroundColor = "#E5F6FD";
				content.component = <Icon icon="Icon-full-small" color={PaletteColors.FADED_BLUE} size={10} />;
				break;
			case "ERROR":
				backgroundColor = "#FBD9D2";
				content.component = <Icon icon="Close" color={PaletteColors.NEON_RED} size={10} />;
				break;
			case "CALCULATING":
				backgroundColor = "#FFF0CD";
				content.component = <Icon icon="calulating" color={PaletteColors.YELLOW} size={10} />;
				break;
			case "REVIEW":
				backgroundColor = "#E5F6FD";
				content.component = <Icon icon="Edit" color={PaletteColors.AZURE} size={15} />;
				break;
			case "RETRIEVING_DATA":
				backgroundColor = "#FFF0CD";
				content.component = <Icon icon="calulating" color={PaletteColors.YELLOW} size={10} />;
				break;

			default:
				break;
		}
	}
	const basePath = "PORTFOLIO_ACTION_STATUS.";
	// TODO: better types
	const resultLabelTranslate = `${basePath}${content.label.toUpperCase()}`;
	const label = t(resultLabelTranslate as any) as string | null;
	return (
		<Container type={type} style={{ color, backgroundColor, border }}>
			<WrapperIcon>{content.component}</WrapperIcon>
			{content.label !== "-" ? label ?? "" : "..."}
		</Container>
	);
};

interface Props {
	type?: "lastAction" | "status";
}

const Container = styled("div")<Props>`
	display: flex;
	align-items: center;
	margin: 0 0;
	border: ${(Props) => (Props.type === "lastAction" ? "1px solid #00000010" : "none")};
	background-color: ${(Props) => (Props.type === "lastAction" ? "transparent" : "#e5f6fd")};
	padding: 3px 8px;
	border-radius: 20px;
	font-weight: bold;
	color: #616161;
`;
const WrapperIcon = styled("div")<Props>`
	display: flex;
	margin-right: 5px;
`;
