import type { InvestmentExportConverterType } from "$root/api/api-gen";
import { IntegrationsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { parallelize } from "$root/utils/promise";

export type File = { data?: Blob; name?: string; type?: string };
const useCompositionDownload = () => {
	const integrationsV2Api = useApiGen(IntegrationsControllerApiFactory);

	async function investmentConvertionToFile(
		format: InvestmentExportConverterType,
		uuid: string,
		enhancement?: boolean,
		persist?: boolean,
	) {
		const composition = await axiosExtract(integrationsV2Api.exportInvestment(uuid, enhancement ?? false));
		const { data, headers } = await integrationsV2Api.convertTo(format, [composition], persist, {
			responseType: "blob",
		});
		const fileType = headers["content-type"];
		const fileName = headers["content-disposition"].split("filename=")[1];
		return { data: data as Blob, name: fileName, type: fileType };
	}

	async function multiInvestmentsConversionToFile(
		format: InvestmentExportConverterType,
		list: Array<{ uuid: string; enhancement?: boolean }>,
		persist: boolean = true,
	) {
		const exportInvestmentCalls = list.map(
			(investment) => () =>
				axiosExtract(integrationsV2Api.exportInvestment(investment.uuid, investment.enhancement ?? false)),
		);
		const compositions = await parallelize(exportInvestmentCalls, { concurrency: 3 });
		const { data, headers } = await integrationsV2Api.convertTo(format, compositions, persist, {
			responseType: "blob",
		});
		const fileType = headers["content-type"];
		const fileName = headers["content-disposition"].split("filename=")[1];
		return { data: data as Blob, name: fileName, type: fileType };
	}

	return { investmentConvertionToFile, multiInvestmentsConversionToFile };
};

export default useCompositionDownload;
