import type { AclEntityMinInfoEntityTypeEnum, RichAccessControl } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import type { PlatformErrorAreas } from "$root/api/error-reporting";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { switchExpr } from "@mdotm/mdotui/utils";
import { useCallback } from "react";
import { useUserValue } from "../../user";
import Disclaimer from "../Disclaimer";
import type { Areas } from "../checkers/all";
import { spawnShareDialogSmart } from "../spawn-share-dialog";

function useControllerByEntity(entity: AclEntityMinInfoEntityTypeEnum) {
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const benchmarkApi = useApiGen(BenchmarksControllerApiFactory);
	const referenceUniversesV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);

	const controller = switchExpr(entity, {
		INVESTMENT: () => ({ set: investmentApi.setACL1, get: investmentApi.getAclEditStarter1 }),
		UNIVERSE: () => ({ set: referenceUniversesV4Api.setACL, get: referenceUniversesV4Api.getAclEditStarter }),
		MARKET_VIEW: () => ({ set: marketViewApi.setACL3, get: marketViewApi.getAclEditStarter3 }),
		BENCHMARK: () => ({ set: benchmarkApi.setACL2, get: benchmarkApi.getAclEditStarter2 }),
	});

	return controller;
}

const areasByEntity = {
	BENCHMARK: "benchmark",
	INVESTMENT: "portfolio",
	MARKET_VIEW: "marketView",
	UNIVERSE: "universe",
} satisfies { [key in AclEntityMinInfoEntityTypeEnum]: Areas };

const platformErrorByEntity = {
	BENCHMARK: "benchmark",
	INVESTMENT: "portfolio",
	MARKET_VIEW: "market-views",
	UNIVERSE: "universe",
} satisfies { [key in AclEntityMinInfoEntityTypeEnum]: PlatformErrorAreas };

/**
 * Given an entity class, spawn the dialog to update the permission
 *
 * @param {("INVESTMENT" | "UNIVERSE" | "BENCHMARK" | "MARKET_VIEW")} entity "INVESTMENT" | "UNIVERSE" | "BENCHMARK" | "MARKET_VIEW"
 * @returns {Object} { aclSpawn }
 */
export function useUpdateAccessListControlDialog(entity: AclEntityMinInfoEntityTypeEnum): {
	aclSpawn: (name?: string, uuid?: string, refetch?: () => Promise<unknown>) => Promise<SpawnResult<void>>;
} {
	const user = useUserValue();
	const entityController = useControllerByEntity(entity);

	const updateAccessPermission = useCallback(
		async (acl: RichAccessControl[], uuid?: string, refetch?: () => Promise<unknown>) => {
			if (!uuid) {
				throw new Error(`missing ${entity} uuid`);
			}
			await entityController.set(uuid, acl);
			await refetch?.();
		},
		[entityController, entity],
	);

	const aclSpawn = useCallback(
		async (name?: string, uuid?: string, refetch?: () => Promise<unknown>) => {
			try {
				return await spawnShareDialogSmart({
					userId: user.id,
					saveAclHandler: ({ acl }) => updateAccessPermission(acl, uuid, refetch),
					objectName: name ?? "-",
					disclaimerProvider: (entities) => <Disclaimer entity={entity} entities={entities} />,
					customerAndLinkedAclProvider: () => axiosExtract(entityController.get(uuid!)),
					area: areasByEntity[entity],
				});
			} catch (err) {
				reportPlatformError(
					err,
					"WARN",
					platformErrorByEntity[entity],
					`share entity "${name ?? "-"}" with uuid "${uuid}"`,
				);
				throw err;
			}
		},
		[entity, entityController, updateAccessPermission, user.id],
	);

	return { aclSpawn };
}
