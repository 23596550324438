import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const EmptyBox: FC<Omit<SvgProps, "viewBox" | "children">> = ({ style = { width: 73, height: 71 }, ...others }) => {
	return (
		<Svg style={style} viewBox={{ height: 71, width: 73 }} {...others}>
			<g>
				<g filter="url(#b)">
					<path d="M20.27 16.46h31.356l6.6 22.978v14.648H14.66V39.438l5.61-22.978Z" fill="#fff" />
				</g>
				<path
					d="M27.68 16.58h1.367c.432 0 .72-.288.72-.72s-.288-.72-.72-.72H27.68c-.432 0-.72.288-.72.72s.36.72.72.72ZM57.918 50.18c-.432 0-.72.288-.72.72v1.44c0 .432-.288.72-.72.72H16.16c-.432 0-.72-.288-.72-.72V37.22H29.91c0 .288.072.576.144.864.072.36.504.648.864.504.36-.072.648-.504.504-.864a6.34 6.34 0 0 1-.144-1.224c0-.432-.288-.72-.72-.72H15.584l5.184-18.19c.216-.576.72-1.008 1.296-1.008h2.735c.433 0 .72-.288.72-.72s-.287-.72-.72-.72h-2.735c-1.296 0-2.376.864-2.664 2.16L14 36.356V52.34c0 1.224.936 2.16 2.16 2.16h40.319c1.224 0 2.16-.936 2.16-2.16V50.9c0-.36-.289-.72-.72-.72Z"
					fill="#D1D2DC"
				/>
				<path
					d="m58.636 36.355-5.4-19.055c-.287-1.296-1.367-2.16-2.663-2.16H31.926c-.432 0-.72.288-.72.72s.288.72.72.72h18.647c.576 0 1.08.432 1.224 1.008l5.256 18.19H42.077c-.432 0-.72.288-.72.72a5.009 5.009 0 0 1-5.04 5.04 5.125 5.125 0 0 1-3.6-1.512.696.696 0 0 0-1.007 0 .696.696 0 0 0 0 1.008 6.37 6.37 0 0 0 4.68 2.016c3.312 0 6.047-2.52 6.408-5.76h14.4v10.872c0 .432.287.72.72.72.431 0 .72-.288.72-.72l-.002-11.807ZM16.88 50.034c0 .864.72 1.584 1.584 1.584h.576c.431 0 .72-.288.72-.72s-.289-.72-.72-.72h-.72v-2.16c0-.432-.288-.72-.72-.72s-.72.288-.72.72v2.016ZM17.6 45.858c.431 0 .72-.288.72-.72v-1.44c0-.432-.288-.72-.72-.72s-.72.288-.72.72v1.44c0 .432.288.72.72.72Z"
					fill="#D1D2DC"
				/>
				<path
					d="M55.038 41.538c.433 0 .72-.288.72-.72v-.936c0-.72-.504-1.224-1.223-1.224h-.936c-.432 0-.72.288-.72.72s.288.72.72.72h.72v.72c0 .432.288.72.72.72Z"
					fill="#D1D2DC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M38.219 22.896c0 .5-.395.904-.882.904a.893.893 0 0 1-.881-.904v-9.492c0-.5.394-.904.881-.904s.882.405.882.904v9.492Zm5.7.815a.88.88 0 0 1-1.08.64.907.907 0 0 1-.623-1.108l2.396-9.169a.88.88 0 0 1 1.08-.64c.47.13.749.626.623 1.108l-2.396 9.17Zm-11.268-.468a.907.907 0 0 1-.623 1.108.88.88 0 0 1-1.08-.64l-2.396-9.17a.907.907 0 0 1 .624-1.106.88.88 0 0 1 1.08.639l2.395 9.17Z"
					fill="#4CB09C"
				/>
			</g>
			<defs>
				<filter
					id="a"
					x={0}
					y={0.5}
					width={72.639}
					height={70}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={7} />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_317_39868" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_317_39868" result="shape" />
				</filter>
				<filter
					id="b"
					x={0.66}
					y={4.46}
					width={71.567}
					height={65.626}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={7} />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_317_39868" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_317_39868" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default EmptyBox;
