import { useState, type FC } from "react";
import { PaletteColors } from "$root/styles/themePalette";
import "./style.scss";
import { PositioninGraphTooltip } from "$root/components/OutlookPositioningGraph";
import NewWidgetStandardTooltip from "$root/components/Tooltips/NewWidgetStandardTooltip";
import { toClassName, useSize } from "@mdotm/mdotui/react-extensions";

type ChartStepperLinedCircleProps = {
	value?: number;
	tooltip: Array<{ label: string; value: string; data?: string }>;
};

export type ChartStepperLinedProps = {
	columnNumber: number;
	current: ChartStepperLinedCircleProps;
	previous: ChartStepperLinedCircleProps;
	lastDashboardUpdate: string;
	labels: string[];
	title?: string;
	line?: "dotted" | "solid";
	showRect?: boolean;
	reverse?: boolean;
};

const PrimaryCircle: FC<ChartStepperLinedProps["current"] & { columnNumber: number; color?: string }> = (props) => {
	if (props.value === undefined) {
		return <></>;
	}

	const shadowMap = {
		[PaletteColors.SANTAS_GREY]: "santas_grey",
		[PaletteColors.AZURE]: "azure",
		[PaletteColors.NEON_RED]: "neon_red",
		[PaletteColors.GREENY_BLUE]: "grenny_blue",
	};

	const cx = Math.floor((100 / (props.columnNumber - 1)) * props.value);
	const defaultColor = PaletteColors.AZURE;

	return (
		<g>
			<circle cx={`${cx}%`} cy="50%" r="11" fill="transparent" className="currentValue__hidden" />
			<circle cx={`${cx}%`} cy="50%" r="6" fill={props.color ?? defaultColor} className="currentValue" />
			<circle
				cx={`${cx}%`}
				cy="50%"
				r="11"
				fill="transparent"
				className="currentValue__ring"
				data-variable={shadowMap[props.color ?? defaultColor]}
			/>
		</g>
	);
};

const SecondaryCircle: FC<ChartStepperLinedProps["previous"] & { columnNumber: number; color?: string }> = (props) => {
	if (props.value === undefined) {
		return <></>;
	}
	const cx = Math.floor((100 / (props.columnNumber - 1)) * props.value);
	const defaultColor = PaletteColors.SANTAS_GREY;

	return (
		<g>
			<circle cx={`${cx}%`} cy="50%" r="4" fill={props.color ?? defaultColor} />
			<circle cx={`${cx}%`} cy="50%" r="2" fill="white" />
			<circle cx={`${cx}%`} cy="50%" r="5" fill="transparent" className="previousValue__ring" />
		</g>
	);
};

const ChartStepperLined: FC<ChartStepperLinedProps> = (props) => {
	const {
		columnNumber,
		current,
		previous,
		labels,
		title,
		line = "solid",
		showRect,
		reverse,
		lastDashboardUpdate,
	} = props;
	const [size, setSize] = useState<HTMLDivElement | null>(null);
	const labelContainerSize = useSize(size)?.width;

	const middleColumn = Math.floor(columnNumber / 2).toFixed(0);

	const iconColor: Record<number, string> = {
		0: reverse ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED,
		1: reverse ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED,
		2: PaletteColors.AZURE,
		3: reverse ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE,
		4: reverse ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE,
	};

	const currentValueRect: Record<number, { x: string; w: string }> = {
		0: { x: "0%", w: "50%" },
		1: { x: "25%", w: "25%" },
		2: { x: "0%", w: "0%" },
		3: { x: "50%", w: "25%" },
		4: { x: "50%", w: "50%" },
	};

	return (
		<div className="relative w-full">
			<div className="flex w-full ChartStepperLined h-12">
				<NewWidgetStandardTooltip
					content={
						<PositioninGraphTooltip
							color={showRect ? iconColor[current.value ?? 3] : iconColor[2]}
							tooltip={[...current.tooltip, ...previous.tooltip]}
							lastDashboardUpdate={lastDashboardUpdate}
						/>
					}
					style={{ marginBottom: "4px" }}
					title={title}
				>
					{({ innerRef }) => (
						<div ref={innerRef} className="h-18 w-full">
							<svg className="h-full w-full" overflow="visible">
								{/* // horizontal line */}
								<line x1="0" y1="50%" x2="100%" y2="50%" stroke="rgba(159, 159, 163, 1)" strokeWidth={0.5} />
								{/* // group of dotted lines */}
								<g
									stroke="rgba(159, 159, 163, 1)"
									strokeDasharray={line === "solid" ? "1" : "4"}
									strokeLinecap={line === "solid" ? "square" : "round"}
								>
									{Array.from({ length: columnNumber }).map((_e, i) => {
										const x = Math.floor((100 / (columnNumber - 1)) * i);
										if (Number(middleColumn) === i) {
											return (
												<line x1={`${x}%`} y1="25%" x2={`${x}%`} y2="75%" key={i} strokeDasharray={1} strokeWidth={2} />
											);
										}

										return <line x1={`${x}%`} y1="25%" x2={`${x}%`} y2="75%" key={i} />;
									})}
								</g>

								{showRect && (
									<rect
										fill={iconColor[current.value ?? 2]}
										x={currentValueRect[current.value ?? 2].x}
										width={currentValueRect[current.value ?? 2].w}
										y="38%"
										height="11"
										opacity={0.3}
									/>
								)}
								<PrimaryCircle
									columnNumber={columnNumber}
									tooltip={current.tooltip}
									value={current.value}
									color={showRect ? iconColor[current.value ?? 2] : undefined}
								/>
								<SecondaryCircle columnNumber={columnNumber} tooltip={previous.tooltip} value={previous.value} />
							</svg>
						</div>
					)}
				</NewWidgetStandardTooltip>
			</div>
			<div
				className={toClassName({
					"absolute inset-x-0": true,
					"bottom-[-20px]": (labelContainerSize ?? 0) < 250,
					"bottom-[-10px]": (labelContainerSize ?? 0) > 250,
				})}
			>
				<div className="flex justify-between items-center" ref={setSize}>
					{labels.map((l, i) => (
						<p
							className={toClassName({
								"text-xs break-normal [&:last-child]:text-right": true,
								"max-w-[70px]": (labelContainerSize ?? 0) < 250,
							})}
							key={i}
						>
							{l}
						</p>
					))}
				</div>
			</div>
		</div>
	);
};

export default ChartStepperLined;
