function ExmptyBox(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="79" height="87" fill="none" viewBox="0 0 79 87">
			<g filter="url(#filter0_d_1544_7963)">
				<path
					fill="#fff"
					d="M54.574 14.23c-6.06 0-11.877 4.576-17.373 4.576-2.84 0-5.261-1.24-7.593-3.9v-1.791c0-.616-.5-1.115-1.115-1.115h-4.46c-.615 0-1.114.5-1.114 1.115V61.25h-3.345c-.616 0-1.115.499-1.115 1.114v2.23h-3.345c-.615 0-1.115.5-1.115 1.115v3.345c0 .615.5 1.115 1.115 1.115h22.298c.616 0 1.115-.5 1.115-1.115V65.71c0-.615-.499-1.115-1.115-1.115h-3.344v-2.23c0-.615-.5-1.114-1.115-1.114h-3.345V41.576c8.664 7.537 17.28-1.596 24.965-1.596 2.939 0 5.428 1.328 7.834 4.18a1.115 1.115 0 001.967-.72V19.92c0-.263-.093-.517-.263-.718-2.822-3.346-5.942-4.972-9.538-4.972z"
				/>
			</g>
			<path
				fill="#C3C9D5"
				d="M54.574 14.23c-6.06 0-11.877 4.576-17.373 4.576-2.84 0-5.261-1.24-7.593-3.9v-1.791c0-.616-.5-1.115-1.115-1.115h-4.46c-.615 0-1.114.5-1.114 1.115V61.25h-3.345c-.616 0-1.115.499-1.115 1.114v2.23h-3.345c-.615 0-1.115.5-1.115 1.115v3.345c0 .615.5 1.115 1.115 1.115h22.298c.616 0 1.115-.5 1.115-1.115V65.71c0-.615-.499-1.115-1.115-1.115h-3.344v-2.23c0-.615-.5-1.114-1.115-1.114h-3.345V41.576c8.664 7.537 17.28-1.596 24.965-1.596 2.939 0 5.428 1.328 7.834 4.18a1.115 1.115 0 001.967-.72V19.92c0-.263-.093-.517-.263-.718-2.822-3.346-5.942-4.972-9.538-4.972zM36.297 67.94H16.229v-1.115h20.069v1.115zm-4.46-3.345h-11.15v-1.114h11.15v1.114zm-6.69-3.344V14.23h2.23v47.02h-2.23zm29.426-23.5c-6.068 0-11.87 4.576-17.37 4.576h-.002c-2.833 0-5.247-1.234-7.572-3.875V18.076c2.294 1.988 4.79 2.96 7.571 2.96 6.06 0 11.876-4.576 17.372-4.576 2.832 0 5.247 1.233 7.571 3.875v15.918c-5.689-4.933-11.488-2.643-16.667-.596-2.452.969-4.768 1.884-7.073 2.141a1.115 1.115 0 00.247 2.216c2.6-.29 5.166-1.303 7.646-2.283 5.718-2.26 10.669-4.216 15.847 1.665v1.316c-2.294-1.99-4.79-2.962-7.57-2.962zM8.344 33.69c1.844 0 3.345-1.501 3.345-3.345 0-1.845-1.5-3.345-3.345-3.345A3.348 3.348 0 005 30.345c0 1.844 1.5 3.344 3.344 3.344zm0-4.46c.615 0 1.115.5 1.115 1.115 0 .614-.5 1.114-1.115 1.114s-1.115-.5-1.115-1.114c0-.615.5-1.115 1.115-1.115zM64.17 49.906a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0z"
			/>
			<path
				fill="#C3C9D5"
				d="M46.332 56.597a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM19.575 44.331a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM15.23 16.115a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM35.23 8.115a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM66.4 16.46a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM36.297 38.757a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM66.46 53C64 53 62 55 62 57.46c0 2.459 2 4.46 4.46 4.46 2.459 0 4.46-2.001 4.46-4.46 0-2.46-2.001-4.46-4.46-4.46zm0 6.69c-1.23 0-2.23-1.001-2.23-2.23 0-1.23 1-2.23 2.23-2.23s2.23 1 2.23 2.23c0 1.229-1 2.23-2.23 2.23z"
			/>
			<path
				fill="#4CB09C"
				d="M8.344 33.69c1.844 0 3.345-1.501 3.345-3.345 0-1.845-1.5-3.345-3.345-3.345A3.348 3.348 0 005 30.345c0 1.844 1.5 3.344 3.344 3.344zm0-4.46c.615 0 1.115.5 1.115 1.115 0 .614-.5 1.114-1.115 1.114s-1.115-.5-1.115-1.114c0-.615.5-1.115 1.115-1.115zM35.23 8.115a1.115 1.115 0 11-2.23 0 1.115 1.115 0 012.23 0zM66.46 53C64 53 62 55 62 57.46c0 2.459 2 4.46 4.46 4.46 2.459 0 4.46-2.001 4.46-4.46 0-2.46-2.001-4.46-4.46-4.46zm0 6.69c-1.23 0-2.23-1.001-2.23-2.23 0-1.23 1-2.23 2.23-2.23s2.23 1 2.23 2.23c0 1.229-1 2.23-2.23 2.23z"
			/>
			<defs>
				<filter
					id="filter0_d_1544_7963"
					width="78.374"
					height="86.17"
					x="0"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1544_7963" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_1544_7963" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}

export default ExmptyBox;
