import type { AclEntityMinInfoEntityTypeEnum, RichAcl } from "$root/api/api-gen";
import { UsersList } from "$root/functional-areas/acl/UsersList";
import type { Areas, Roles } from "$root/functional-areas/acl/checkers/all";
import { aclToUsersListEntries, roleByArea, roleFromAcl } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import { useUserValue } from "$root/functional-areas/user";
import { useMemo, useState } from "react";

const permissionByRole: Record<Roles, string> = {
	EDITOR: "Edit",
	OWNER: "Edit",
	VIEWER: "View",
};

const EntityStatus = (props: {
	entity: AclEntityMinInfoEntityTypeEnum;
	accessControl?: RichAcl;
	entityName?: string;
	entityId?: string;
	refetch?: () => Promise<unknown>;
}): JSX.Element => {
	const [spawned, setSpawned] = useState(false);
	const { accessControl, entity, refetch } = props;
	const user = useUserValue();

	const { aclSpawn } = useUpdateAccessListControlDialog(entity);

	const areasByEntity = {
		BENCHMARK: "benchmark",
		INVESTMENT: "portfolio",
		MARKET_VIEW: "marketView",
		UNIVERSE: "universe",
	} satisfies { [key in AclEntityMinInfoEntityTypeEnum]: Areas };

	const isEditor = useMemo(() => {
		return validateACLPermissions(user.id, accessControl?.acl ?? [], roleByArea[areasByEntity[entity]].EDITOR);
	}, [accessControl?.acl, areasByEntity, entity, user.id]);

	const currentUserRole = useMemo(
		() => roleFromAcl(areasByEntity[entity], user.id, accessControl?.acl ?? []),
		[accessControl?.acl, areasByEntity, entity, user.id],
	);

	if (!accessControl) {
		return (
			<div className="flex items-center">
				<span className="mr-2 whitespace-nowrap">Permission</span>
				...
			</div>
		);
	}

	return (
		<>
			<div className="flex items-center">
				<span className="mr-2 whitespace-nowrap">Permission</span>
				<p className="font-semibold">{permissionByRole[currentUserRole]}</p>
			</div>
			<div className="flex items-center">
				<span className="mr-2 whitespace-nowrap">Sharing with</span>
				<div className="flex space-x-2">
					<UsersList
						users={aclToUsersListEntries(areasByEntity[entity], accessControl?.acl ?? [])}
						visibleSlots={3}
						onAdd={
							isEditor && spawned === false
								? async () => {
										setSpawned(true);
										await aclSpawn(props?.entityName, props?.entityId, refetch)
											.then(() => setSpawned(false))
											.finally(() => setSpawned(false));
								  }
								: undefined
						}
						size="small"
					/>
				</div>
			</div>
		</>
	);
};

export default EntityStatus;
