import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";

function TextDescriptionDrawIcon({
	style = { height: "auto", width: 80 },
	...props
}: Omit<SvgProps, "viewBox" | "children">): JSX.Element {
	return (
		<Svg style={style} viewBox={{ height: 89, width: 80 }} {...props}>
			<g filter="url(#filter0_d_2585_3747)">
				<path
					fill="#fff"
					d="M64.435 22.617a1.142 1.142 0 00-.808-.335h-3.428v-3.427a1.145 1.145 0 00-1.142-1.143H29.352V16.57a4.57 4.57 0 10-9.14 0v1.142h-4.57a1.145 1.145 0 00-1.143 1.143v47.983a1.145 1.145 0 001.143 1.142h3.428v3.427a1.145 1.145 0 001.142 1.143h43.415a1.145 1.145 0 001.142-1.142V23.425c0-.303-.12-.594-.334-.808z"
				/>
			</g>
			<g clipPath="url(#clip0_2585_3747)">
				<path
					fill="#C3C9D5"
					d="M63.627 22.282h-3.428v-3.427a1.145 1.145 0 00-1.142-1.143H29.352V16.57a4.569 4.569 0 10-9.14 0v1.142h-4.57a1.145 1.145 0 00-1.143 1.143v47.983a1.145 1.145 0 001.143 1.142h3.428v3.427a1.145 1.145 0 001.142 1.143h43.415a1.145 1.145 0 001.142-1.142V23.425a1.145 1.145 0 00-1.142-1.143zm-46.843-2.285h3.428v3.428a3.427 3.427 0 106.854 0v-1.143h-2.285v1.143a1.142 1.142 0 11-2.285 0V16.57a2.286 2.286 0 014.57 0v1.142H24.78v2.285h33.133v45.698h-41.13V19.998zm45.7 50.268h-41.13V67.98h37.703a1.145 1.145 0 001.142-1.142v-42.27h2.285v45.697z"
				/>
				<path
					fill="#8792AB"
					d="M21.355 49h31.99v2.285h-31.99V49zM21.355 53.57h31.99v2.285h-31.99V53.57zM21.355 58.14h27.42v2.285h-27.42V58.14zM21.5 31h24v2.285h-24V31zM21.5 35.57h31.99v2.285H21.5V35.57zM21.5 40.14h27.42v2.285H21.5V40.14z"
				/>
			</g>
			<circle cx="65.499" cy="68" r="14" fill="#fff" />
			<circle cx="65.499" cy="68" r="11" fill="#4CB09C" />
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M71.18 62.933c.07-.32.198-.714.279-.795.081-.081.476-.21.795-.28l.715-.16-.715-.16c-.32-.071-.714-.199-.796-.28-.08-.08-.207-.476-.279-.795l-.16-.713-.16.713c-.071.32-.198.715-.28.797-.08.08-.475.207-.795.278l-.713.16.713.16c.32.07.714.199.795.28.082.081.209.476.28.795l.16.712.16-.712zm-12.466 1.141c.494-.11 1.352-.36 1.55-.56.2-.199.45-1.057.561-1.551l.16-.713.159.713c.11.494.36 1.352.56 1.551.2.2 1.057.45 1.551.56l.714.16-.713.16c-.495.11-1.352.36-1.552.56-.2.199-.45 1.057-.56 1.551l-.16.714-.159-.714c-.11-.494-.36-1.352-.56-1.552-.2-.199-1.058-.449-1.552-.56l-.713-.16.714-.159zm6.002 3.236c.838-.837 1.678-4.558 1.687-4.596l.16-.714.159.714v.002c.024.105.856 3.764 1.687 4.594.837.838 4.559 1.679 4.596 1.687l.714.16-.714.16c-.037.008-3.759.848-4.596 1.686-.825.824-1.65 4.438-1.686 4.592l-.001.004-.16.715-.16-.715c-.008-.037-.848-3.758-1.686-4.596-.837-.838-4.559-1.678-4.596-1.686l-.715-.16.714-.16c.038-.008 3.759-.848 4.597-1.687z"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="filter0_d_2585_3747"
					width="78.27"
					height="88.55"
					x="0.5"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2585_3747" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_2585_3747" result="shape" />
				</filter>
				<clipPath id="clip0_2585_3747">
					<path fill="#fff" d="M0 0H50.27V68.55H0z" transform="translate(14.5 4)" />
				</clipPath>
			</defs>
		</Svg>
	);
}

export default TextDescriptionDrawIcon;
