import { PaletteColors } from "$root/styles/themePalette";
import type * as React from "react";
import type { AutoTooltipProps } from "@mdotm/mdotui/components";
import { AutoTooltip, TooltipContent } from "@mdotm/mdotui/components";

interface WidgetStandardTooltipProps<Trigger extends HTMLElement> {
	title?: string;
	content?: React.ReactNode;
	children: AutoTooltipProps<Trigger>["trigger"];
	style?: React.CSSProperties;
	className?: string;
}

export default function NewWidgetStandardTooltip<Trigger extends HTMLElement>({
	className,
	title,
	children,
	content,
	...forward
}: WidgetStandardTooltipProps<Trigger>): JSX.Element {
	return (
		<AutoTooltip {...forward} overrideColor="white" trigger={children}>
			<TooltipContent>
				{title && (
					<div
						className={className}
						style={{
							position: "relative",
							height: 24,
							textAlign: "center",
							borderRadius: 2,
							backgroundColor: PaletteColors.PALE_GREY_TWO,
							color: "#1D2433",
							fontFamily: '"Gotham", serif',
							fontSize: 12,
							fontWeight: "bold",
							lineHeight: "16px",
							padding: 4,
							marginBottom: 2,
						}}
					>
						{title}
					</div>
				)}
				{content}
			</TooltipContent>
		</AutoTooltip>
	);
}
