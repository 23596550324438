import type {
	FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum,
	InvestmentActions,
	InvestmentExposureResponseExposureTypeEnum,
	InvestmentNote,
	MarketScenario,
	MarketViewMicroAssetClasses,
} from "$root/api/api-gen";
import {
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	MarketViewControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { appDrawerZIndexOffset } from "$root/components/AppDrawer";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { useCustomScore } from "$root/components/CustomLabels";
import { applyDeltaToSelectedMarketView } from "$root/components/Portfolio/MarketView/utilsV2";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { formatDate } from "$root/localization/formatters";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { customObjectKeysFn, customObjectValuesFn } from "$root/utils/experimental";
import { noRefetchDefaults } from "$root/utils/react-query";
import {
	ExposureChart,
	aggregateExposureData,
	exposureCategoryInfo,
} from "$root/widgets-architecture/widgets/ExposureEvolve";
import {
	aggregateHistory,
	spawnPortfolioHistoryDialog,
	typeToStringMap,
} from "$root/widgets-architecture/widgets/PortfolioHistoryBlock";
import type { DefaultCollapsibleHeaderProps, TextProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	CircularProgressBar,
	Collapsible,
	Icon,
	LinkButton,
	ScrollWrapper,
	Select,
	StackingContext,
	Svg,
	Text,
} from "@mdotm/mdotui/components";
import type { ClassList, NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	ForEach,
	renderNodeOrFn,
	toClassListRecord,
	toClassName,
	useEventListener,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, stableEmptyArray } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { Map, Set } from "immutable";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { positioningPresets, positioningValueTypeMap } from "../market-view/PositioningBadge";
import { SentimentBadge } from "../market-view/analysis/SentimentBadge";
import type { SentimentType } from "../market-view/analysis/sentiment";
import { useUserValue } from "../user";
import type { MockMacroComposition } from "./PortfolioExposureSummary";
import PortfolioExposureSummary from "./PortfolioExposureSummary";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";

const exposureOptionsCategories = [
	"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	"MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<InvestmentExposureResponseExposureTypeEnum>;

const exposureOptions = exposureOptionsCategories.map((category) => ({
	label: exposureCategoryInfo[category].label,
	value: category,
}));

const positioningSentiment: Record<number, SentimentType> = {
	1: "super-negative",
	2: "negative",
	3: "neutral",
	4: "positive",
	5: "super-positive",
};

export type CompareDataItem = {
	id: string;
	portfolioName: string;
	description?: string;
	composition: Array<MockMacroComposition>;
	numberOfInstrument: number;
	uuid?: string;
	enhanced?: boolean;
	note?: InvestmentNote;
	action?: InvestmentActions;
};

export type CompareOverlayProps = {
	show: boolean;
	onClose(): void;
	onRemove?(compareDataId: string, compareDataIndex: number): void;
	compareData: Array<CompareDataItem>;
};

export function CompareOverlay({ compareData, onRemove, onClose, show }: CompareOverlayProps): JSX.Element {
	const [expand, setExpand] = useState(false);
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const user = useUserValue();
	const { getScoreLabel } = useCustomScore();

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const noteApi = useApiGen(InvestmentControllerV4ApiFactory);

	const investmentsSummaryQuery = useQueries({
		queries: compareData.map((x) => ({
			enabled: x.uuid !== undefined,
			queryKey: [`querySummary(${x.id})`],
			queryFn: () =>
				axiosExtract(
					x.enhanced
						? investmentEnhancementReportApi.getInvestmentEnhancementSummary(x.uuid!)
						: investmentReportApi.getInvestmentSummary(x.uuid!),
				).then((res) => ({
					...res,
					numberOfInstrument: x.numberOfInstrument,
					id: x.id,
					enhanced: x.enhanced,
				})),
			...noRefetchDefaults,
		})),
	});

	const investmentSummary = investmentsSummaryQuery.map(({ data }) => data);
	const isLoadingSummary = investmentsSummaryQuery.some(({ isLoading }) => isLoading);

	const marketViewsQuery = useQueries({
		queries: investmentSummary.map((item) => ({
			enabled:
				item?.uuid !== undefined &&
				item.marketView !== null &&
				item.marketView !== undefined &&
				item.marketView.id !== undefined &&
				item.marketView.marketViewType !== undefined &&
				item.marketView.userCustomScenario !== undefined,
			queryKey: [`queryMarketView(${item?.id})`],
			queryFn: () =>
				axiosExtract(
					marketViewApi.getMarketViewScenario(
						item!.marketView!.id!,
						item!.uuid!,
						false,
						item!.marketView!.userCustomScenario!,
					),
				),
			...noRefetchDefaults,
		})),
	});

	function aggregateMarketViews(markets: Array<MarketScenario | undefined>) {
		const marketViewsGroupedByMacro = markets.map((m) => {
			if (!m || !m.marketViewType) {
				return {};
			}

			const { flexibleExpectedReturnsVolatility, positioningIndicators, marketViewType } = m;

			if (marketViewType === "EXPECTED_RETURNS_VOLATILITY") {
				const regimes = {
					lower: m?.regimeUserProbability?.a ?? 0,
					upper: (m?.regimeUserProbability?.a ?? 0) + (m?.regimeUserProbability?.b ?? 0),
				};

				const appliedAssetClasses = applyDeltaToSelectedMarketView(
					{
						a: regimes.lower,
						b: regimes.upper - regimes.lower,
						c: 100 - regimes.upper,
					},
					flexibleExpectedReturnsVolatility?.assetClasses,
				);

				const groups = groupBy(appliedAssetClasses ?? [], (x) => x.assetClass!);
				return { marketViewType, groups };
			}

			const groups = groupBy(positioningIndicators?.positioningIndicators ?? [], (x) => x.assetClass!);
			return { marketViewType, groups };
		});

		const { assetClassesMap } = marketViewsGroupedByMacro.reduce<{
			assetClassesMap: Map<FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum, Set<MarketViewMicroAssetClasses>>;
		}>(
			(acc, x) => {
				if (x.marketViewType === "EXPECTED_RETURNS_VOLATILITY") {
					const entries = customObjectValuesFn(x.groups).flat();
					entries.forEach((entry) => {
						if (entry && entry.assetClass && entry.microAssetClass) {
							const acEntry = acc.assetClassesMap.get(entry.assetClass);
							if (acEntry) {
								acc.assetClassesMap = acc.assetClassesMap.set(entry.assetClass, acEntry.add(entry.microAssetClass));
							}

							if (!acEntry) {
								acc.assetClassesMap = acc.assetClassesMap.set(entry.assetClass, Set([entry.microAssetClass]));
							}
						}
					});
				}

				if (x.marketViewType === "POSITIONING_INDICATORS") {
					const entries = customObjectValuesFn(x.groups).flat();
					entries.forEach((entry) => {
						if (entry && entry.assetClass && entry.microAssetClass) {
							const acEntry = acc.assetClassesMap.get(entry.assetClass);
							if (acEntry) {
								acc.assetClassesMap = acc.assetClassesMap.set(entry.assetClass, acEntry.add(entry.microAssetClass));
							}

							if (!acEntry) {
								acc.assetClassesMap = acc.assetClassesMap.set(entry.assetClass, Set([entry.microAssetClass]));
							}
						}
					});
				}

				return acc;
			},
			{ assetClassesMap: Map() },
		);

		const macrosAssetClasses = Array.from(assetClassesMap.keys()).map((macro) => macro);

		return { macrosAssetClasses, assetClassesMap, marketViewsGroupedByMacro };
	}

	const marketViews = useMemo(() => marketViewsQuery.map(({ data }) => data), [marketViewsQuery]);
	const aggregatedMarketViews = useMemo(() => aggregateMarketViews(marketViews), [marketViews]);

	const [selectedExposure, setSelectedExposure] = useState<InvestmentExposureResponseExposureTypeEnum>(
		"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	);
	const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null);

	useEventListener(scrollEl, "scroll", () => {
		if (!scrollEl) {
			return;
		}
		if (scrollEl.scrollLeft > 2) {
			scrollEl.setAttribute("data-show-left-header-shadow", "1");
		} else {
			scrollEl.removeAttribute("data-show-left-header-shadow");
		}
		if (scrollEl.scrollTop > 2) {
			scrollEl.setAttribute("data-show-top-header-shadow", "1");
		} else {
			scrollEl.removeAttribute("data-show-top-header-shadow");
		}
	});

	if (compareData.length < 1 || !show) {
		return <></>;
	}

	return createPortal(
		<>
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<div
						style={{
							zIndex: zIndex + (expand ? appDrawerZIndexOffset.open : appDrawerZIndexOffset.closed),
							borderColor: themeCSSVars.palette_N200,
						}}
						className={toClassName({
							"fixed bottom-0 inset-x-0 border-t transition-[height] flex flex-col shadow-2xl bg-white": true,
							"h-[220px]": !expand,
							"h-[90vh]": expand,
						})}
					>
						<StackingContext.Provider
							value={{ zIndex: zIndex + (expand ? appDrawerZIndexOffset.open : appDrawerZIndexOffset.closed) + 1 }}
						>
							<>
								<div
									className="flex items-center justify-end"
									style={{
										backgroundColor: themeCSSVars.palette_N20,
									}}
								>
									<button
										onClick={() => {
											onClose();
											setExpand(false);
										}}
										type="button"
										className="p-4"
									>
										<Icon icon="Close" size={16} color={themeCSSVars.palette_N400} />
									</button>
								</div>
								<ScrollWrapper
									innerRef={setScrollEl}
									direction="horizontal"
									showScrollingArrows
									outerContainerAppearance={{
										classList: "min-h-0 h-full",
									}}
									classList={{
										"overflow-y-auto min-h-0 group": true,
										"overflow-y-hidden": false,
									}}
									startShadow /* TODO: shadow + sticky is weird, validate with a designer */
									endShadow
								>
									<div
										className="flex flex-row sticky group-data-[show-top-header-shadow]:shadow-lg z-10 top-0 min-w-[calc(100vw_-_8px)]"
										style={{
											backgroundColor: themeCSSVars.palette_N20,
										}}
									>
										<div
											className={toClassName({
												"w-[160px] pb-4 flex flex-col px-4 sticky z-10 left-0 group-data-[show-left-header-shadow]:shadow-lg":
													true,
												"h-[172px]": expand === false,
												"h-[152px]": expand,
											})}
											style={{
												backgroundColor: themeCSSVars.palette_N20,
											}}
										>
											<Text as="div" type="Title/S" classList="whitespace-pre-line">
												Compare{"\n"}portfolios
											</Text>
											{expand ? (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
												<div
													className="flex items-center mt-auto space-x-1 cursor-pointer"
													onClick={() => setExpand(false)}
												>
													<ActionText onClick={() => setExpand(false)}>View less</ActionText>
													<Icon icon="Down" />
												</div>
											) : (
												<>
													{isLoadingSummary ? (
														<div className="flex items-center mt-auto space-x-1 pointer-events-none">
															<CircularProgressBar value="indeterminate" classList="w-4 h-4" />
														</div>
													) : (
														// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
														<div
															className="flex items-center mt-auto space-x-1 cursor-pointer"
															onClick={() => setExpand(true)}
														>
															<ActionText onClick={() => setExpand(true)}>View more</ActionText>
															<Icon icon="Up" />
														</div>
													)}
												</>
											)}
										</div>

										<ForEach collection={compareData}>
											{({ item, index }) => (
												<div className="w-[312px] border-r border-transparent px-4 mb-4">
													<div
														className={toClassName({
															"shadow-md bg-white rounded-md p-2 flex flex-col": true,
															"rounded-tl rounded-tr border-t-4": true,
															[`border-[color:${themeCSSVars.palette_N200}]`]: !item.enhanced,
															[`border-[color:${themeCSSVars.palette_S200}]`]: item.enhanced,
															"h-[100%]": expand === false,
															"max-h-[115px] h-full": expand,
														})}
													>
														<PortfolioExposureSummary
															onRemove={onRemove && (() => onRemove(item.id, index))}
															composition={item.composition}
															title={item.portfolioName}
															subtitleClassList={
																item.enhanced ? `!text-[color:${themeCSSVars.palette_S400}]` : undefined
															}
															note={expand ? undefined : item.note}
															action={expand ? undefined : item.action}
														/>
													</div>
												</div>
											)}
										</ForEach>
									</div>

									<CollapsibleSection
										title="Main info"
										items={investmentSummary}
										leftHeaderContentClassList="flex flex-col"
										leftHeaderContent={() => (
											<div className="pr-2">
												<ForEach
													collection={
														hasAccess(user, { requiredService: "CUSTOM_QUALITIES" })
															? [
																	"Benchmark",
																	"Universe",
																	"Currency",
																	"N. of instruments",
																	"Average score",
																	"VaR(95% 3Y)",
															  ]
															: ["Benchmark", "Universe", "Currency", "N. of instruments", "VaR(95% 3Y)"]
													}
												>
													{({ item }) => (
														<Text
															type="Body/S/Book"
															as="div"
															classList={`py-1.5 border-dotted border-b border-[color:${themeCSSVars.palette_N200}] truncate`}
															title={item}
														>
															{item}
														</Text>
													)}
												</ForEach>
											</div>
										)}
										cellContentClassList="flex flex-col"
										cellContent={({ item }) => (
											<ForEach
												collection={
													hasAccess(user, { requiredService: "CUSTOM_QUALITIES" })
														? [
																item?.primaryBenchmarkName,
																item?.universeName,
																item?.baseCurrency,
																item?.numberOfInstrument,
																`(${getScoreLabel(item?.scoreIdentifier ?? "-", "SCORE")}) ${formatNumber(
																	item?.scoreValue,
																	0,
																)}`,
																`${formatNumber(item?.historicalVar953Y, 0)}%`,
														  ]
														: [
																item?.primaryBenchmarkName,
																item?.universeName,
																item?.baseCurrency,
																item?.numberOfInstrument,
																`${formatNumber(item?.historicalVar953Y, 0)}%`,
														  ]
												}
											>
												{({ item: quality }) => (
													<Text
														type="Body/S/Bold"
														as="div"
														classList={`py-1.5 border-dotted border-b border-[color:${themeCSSVars.palette_N200}] truncate`}
													>
														{quality ?? "-"}
													</Text>
												)}
											</ForEach>
										)}
									/>

									<CollapsibleSection
										title="Exposure"
										items={investmentSummary}
										extraTitleContent={
											<Select onChange={setSelectedExposure} value={selectedExposure} options={exposureOptions} />
										}
										cellContentClassList="flex flex-col"
										cellContent={({ item }) => (
											<ReactQueryWrapper
												enabled={
													item !== undefined && item.uuid !== undefined && item.primaryBenchmarkIdentifier !== undefined
												}
												queryKey={[`queryExposure(${item?.id})`, selectedExposure]}
												queryFn={() =>
													axiosExtract(
														item?.enhanced
															? investmentEnhancementReportApi.getTwoLevelsInvestmentExposure1(
																	item!.uuid!,
																	item!.primaryBenchmarkIdentifier!,
																	selectedExposure,
															  )
															: investmentReportApi.getTwoLevelsInvestmentExposure(
																	item!.uuid!,
																	item!.primaryBenchmarkIdentifier!,
																	selectedExposure,
															  ),
													)
												}
											>
												{({ investmentComposition, enhancementComposition }) => (
													<ExposureChart
														data={Object.values(
															aggregateExposureData(
																(item?.enhanced ? enhancementComposition : investmentComposition) ?? [],
															),
														)}
														hideLabel
													/>
												)}
											</ReactQueryWrapper>
										)}
									/>

									<CollapsibleSection
										title="History"
										items={investmentSummary}
										cellContentClassList="flex flex-col"
										cellContent={({ item }) => (
											<ReactQueryWrapper
												enabled={item !== undefined && item.uuid !== undefined}
												queryKey={[`queryHistory(${item?.id})`, selectedExposure]}
												queryFn={() => axiosExtract(investmentReportApi.getInvestmentHistorySummary(item!.uuid!))}
											>
												{(response, { refetch }) => {
													const events = aggregateHistory(response);
													const latestEvent = events[0];
													return (
														<div className="h-40 flex flex-col py-4">
															<Text type="Body/M/Bold" as="p">
																{typeToStringMap[latestEvent.type].title}{" "}
																<Text type="Body/M/Book" as="span">
																	{formatDate(latestEvent.date)}
																</Text>
															</Text>
															{latestEvent.note && (
																<Text type="Body/S/Bold" as="p">
																	Note (Created by {latestEvent.note.author?.name ?? ""} on{" "}
																	{formatDate(latestEvent.note.date)})
																</Text>
															)}
															<p className="line-clamp-4">{latestEvent.note?.note}</p>
															<LinkButton
																onClick={() =>
																	spawnPortfolioHistoryDialog({
																		events,
																		user,
																		portfolio: { ...item },
																		onEditNote: async (historyItemId, note) => {
																			await noteApi.editInvestmentNote({
																				historyUuid: historyItemId,
																				investmentUuid: item!.uuid!,
																				noteText: note,
																			});
																			const newEvents = await refetch();
																			return aggregateHistory(newEvents.data ?? stableEmptyArray);
																		},
																		onDeleteNote: async (historyItemId) => {
																			const shouldDelete = await spawnYesNoDialog({
																				header: "Are you sure you want to delete this item?",
																				children: (
																					<p>
																						This will permanently remove the note.{"\n"}Please be aware that this action
																						cannot be undone.
																					</p>
																				),
																				zIndex:
																					zIndex +
																					(expand ? appDrawerZIndexOffset.open : appDrawerZIndexOffset.closed) +
																					1,
																			});
																			if (shouldDelete) {
																				await noteApi.removeInvestmentNote(item!.uuid!, historyItemId);
																				const newEvents = await refetch();
																				return aggregateHistory(newEvents.data ?? stableEmptyArray);
																			}
																			return events;
																		},
																		zIndex:
																			zIndex + (expand ? appDrawerZIndexOffset.open : appDrawerZIndexOffset.closed),
																	})
																}
																classList="w-fit mt-auto"
																size="x-small"
															>
																View all history
															</LinkButton>
														</div>
													);
												}}
											</ReactQueryWrapper>
										)}
									/>

									{aggregatedMarketViews.macrosAssetClasses.length > 0 && (
										<Collapsible
											expand
											header={(forward) => <CollapsibleSectionHeader title="Market views" {...forward} />}
										>
											<ForEach collection={aggregatedMarketViews.macrosAssetClasses}>
												{({ item: macroAssetClass }) => {
													const microCollection =
														aggregatedMarketViews.assetClassesMap.get(macroAssetClass)?.toArray() ?? [];
													return (
														<CollapsibleSection
															titleTextType="Body/XL/Bold"
															title={
																<div className="flex items-center gap-2">
																	<Svg
																		classList="flex min-w-[8px]"
																		viewBox={{
																			width: 8,
																			height: 8,
																		}}
																		fill="none"
																	>
																		<circle
																			opacity="0.3"
																			r="4"
																			cx="4"
																			cy="4"
																			fill={stableColorGenerator(macroAssetClass)}
																		/>
																	</Svg>
																	<div>{t(`MACRO_ASSET_CLASSES.${macroAssetClass}`)}</div>
																</div>
															}
															items={aggregatedMarketViews.marketViewsGroupedByMacro ?? []}
															leftHeaderContentClassList="flex flex-col overflow-hidden"
															leftHeaderContent={() => (
																<ForEach collection={microCollection}>
																	{({ item: groupedMicroAssetClasses }) => (
																		<div
																			className={`h-[40px] flex items-center overflow-hidden border-[color:${themeCSSVars.palette_N50}] border-b`}
																		>
																			<Text
																				type="Body/S/Book"
																				as="p"
																				classList="flex-1 line-clamp-2 overflow-hidden"
																				title={t(`MARKET_STUDIO_SETTINGS.${groupedMicroAssetClasses}`)}
																			>
																				{t(`MARKET_STUDIO_SETTINGS.${groupedMicroAssetClasses}`)}
																			</Text>
																		</div>
																	)}
																</ForEach>
															)}
															cellContentClassList="flex flex-col"
															cellContent={({ item }) =>
																item.marketViewType === "EXPECTED_RETURNS_VOLATILITY" ? (
																	<ForEach collection={microCollection}>
																		{({ item: micro }) => {
																			const entry = item.groups[macroAssetClass]?.find(
																				(x) => x.microAssetClass === micro,
																			);
																			return (
																				<div
																					className={`h-[40px] flex items-center truncate w-full flex-1 border-[color:${themeCSSVars.palette_N50}] border-b`}
																				>
																					{entry && (
																						<div className="text-right w-full tabular-nums flex space-x-0.5 items-center">
																							{formatNumber(entry.expectedReturnsUserDelta, 2)}
																							&nbsp;
																							<Icon icon="Percentile" color={themeCSSVars.palette_N300} size={16} />
																						</div>
																					)}
																				</div>
																			);
																		}}
																	</ForEach>
																) : item.marketViewType === "POSITIONING_INDICATORS" ? (
																	<ForEach collection={microCollection}>
																		{({ item: micro }) => {
																			const entry = item.groups[macroAssetClass]?.find(
																				(x) => x.microAssetClass === micro,
																			);

																			const positioning = customObjectKeysFn(positioningValueTypeMap).find(
																				(index) => entry?.userPositioning && Number(index) === entry.userPositioning,
																			);

																			return (
																				<div
																					className={`"h-[40px] flex items-center truncate w-full flex-1 border-[color:${themeCSSVars.palette_N50}] border-b`}
																				>
																					{positioning ? (
																						<SentimentBadge
																							sentiment={positioningSentiment[positioning]}
																							indicator="positioning"
																						>
																							{positioningPresets[positioningValueTypeMap[positioning]].label}
																						</SentimentBadge>
																					) : (
																						""
																					)}
																				</div>
																			);
																		}}
																	</ForEach>
																) : (
																	<></>
																)
															}
														/>
													);
												}}
											</ForEach>
										</Collapsible>
									)}
								</ScrollWrapper>
							</>
						</StackingContext.Provider>
					</div>
				)}
			</StackingContext.Consumer>
		</>,
		document.body,
	);
}

type CollapsibleSectionProps<TCollapsible> = {
	title: ReactNode;
	titleTextType?: TextProps["type"];
	extraTitleContent?: ReactNode;
	items: TCollapsible[];
	leftHeaderContent?: NodeOrFn;
	leftHeaderContentClassList?: ClassList;
	cellContent: NodeOrFn<{ item: TCollapsible }>;
	cellContentClassList?: ClassList;
};

function CollapsibleSection<TCollapsible>({
	title,
	titleTextType,
	extraTitleContent,
	leftHeaderContent,
	leftHeaderContentClassList,
	cellContent,
	cellContentClassList,
	items,
}: CollapsibleSectionProps<TCollapsible>) {
	return (
		<>
			<Collapsible
				expand
				header={(forward) => (
					<CollapsibleSectionHeader
						{...forward}
						titleTextType={titleTextType}
						title={title}
						extraTitleContent={extraTitleContent}
					/>
				)}
			>
				<div className="flex flex-row pt-2">
					<div
						className={toClassName({
							"w-[160px] sticky left-0 px-4 bg-white": true,
							"group-data-[show-left-header-shadow]:shadow-lg": Boolean(leftHeaderContent),
							...toClassListRecord(leftHeaderContentClassList),
						})}
					>
						{renderNodeOrFn(leftHeaderContent)}
					</div>
					<ForEach collection={items}>
						{({ item, index }) => (
							<div
								className={toClassName({
									"w-[312px] px-4 border-r border-dotted": true,
									[`border-[color:${themeCSSVars.palette_N300}]`]: index < items.length - 1,
									"border-transparent": index === items.length - 1,
									...toClassListRecord(cellContentClassList),
								})}
							>
								{renderNodeOrFn(cellContent, { item })}
							</div>
						)}
					</ForEach>
				</div>
			</Collapsible>
		</>
	);
}

function CollapsibleSectionHeader({
	titleTextType = "Title/S",
	title,
	extraTitleContent,
	innerRef,
	onClick,
	transitionDuration,
	expand,
}: Omit<DefaultCollapsibleHeaderProps, "children"> & {
	title: ReactNode;
	titleTextType?: TextProps["type"];
	extraTitleContent?: ReactNode;
}) {
	return (
		<div className="border-b">
			<div className="px-4 py-4 flex items-center gap-4 sticky left-0 w-min">
				<button
					type="button"
					onClick={onClick}
					ref={innerRef as any}
					className="flex items-center gap-4"
					style={{
						borderColor: themeCSSVars.palette_N50,
					}}
				>
					<Text type={titleTextType} color={themeCSSVars.palette_N700} as="div" classList="whitespace-nowrap">
						{title}
					</Text>
					<span
						style={{
							transitionDuration: `${transitionDuration}ms`,
						}}
						className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex"
						aria-expanded={expand}
					>
						<Icon icon="Down" color={themeCSSVars.palette_N400} size={24} />
					</span>
				</button>
				{extraTitleContent}
			</div>
		</div>
	);
}
