import { useMemo } from "react";

export type SectorVariants =
	| "GLOBAL"
	| "PRECIOUS_METALS"
	| "ENERGY"
	| "FINANCIALS"
	| "INDUSTRIALS"
	| "CONSUMER_STAPLES"
	| "COMMUNICATION_SERVICES"
	| "INFORMATION_TECHNOLOGY"
	| "CONSUMER_DISCRETIONARY"
	| "HEALTHCARE"
	| "MATERIALS"
	| "UTILITIES"
	| "REAL_ESTATE"
	| "AGRICULTURE"
	| "INDUSTRIAL_METALS"
	| "LONG_GOV"
	| "MEDIUM_GOV"
	| "SHORT_GOV"
	| "CORP"
	| "GOV"
	| "HY"
	| "ALL";

export const MAP_ASSET_CLASS: Record<string, "EQUITY" | "FIXED_INCOME" | "COMMODITIES" | "FIXEDINCOME"> = {
	FI: "FIXEDINCOME",
	CO: "COMMODITIES",
	EQ: "EQUITY",
};

export const MAP_GEOGRAPHY: Record<
	string,
	"EM" | "JP" | "US" | "EU" | "GLOBAL" | "UK" | "EU_SMALL_MID_CAP" | "PACIFIC_EX_JP"
> = {
	MARKETS: "GLOBAL",
	EU: "EU",
	US: "US",
	JP: "JP",
	EM: "EM",
	UK: "UK",
	EU_SMALL_MID_CAP: "EU_SMALL_MID_CAP",
	PACIFIC_EX_JP: "PACIFIC_EX_JP",
	"": "GLOBAL",
};

export const MAP_SECTOR: Record<string, SectorVariants> = {
	ALL_SECTORS: "ALL",
	GLOBAL: "GLOBAL",
	PRECIOUS_METALS: "PRECIOUS_METALS",
	ENERGY: "ENERGY",
	FINANCIALS: "FINANCIALS",
	INDUSTRIALS: "INDUSTRIALS",
	CONSUMER_STAPLES: "CONSUMER_STAPLES",
	COMMUNICATION_SERVICES: "COMMUNICATION_SERVICES",
	INFORMATION_TECHNOLOGY: "INFORMATION_TECHNOLOGY",
	CONSUMER_DISCRETIONARY: "CONSUMER_DISCRETIONARY",
	HEALTHCARE: "HEALTHCARE",
	HEALTH_CARE: "HEALTHCARE",
	MATERIALS: "MATERIALS",
	UTILITIES: "UTILITIES",
	REAL_ESTATE: "REAL_ESTATE",
	AGRICULTURE: "AGRICULTURE",
	INDUSTRIAL_METALS: "INDUSTRIAL_METALS",
	LONG_GOV: "LONG_GOV",
	MEDIUM_GOV: "MEDIUM_GOV",
	SHORT_GOV: "SHORT_GOV",
	CORP: "CORP",
	GOV: "GOV",
	HY: "HY",
	ALL: "ALL",
};

const useOutlookComposition = (
	firstFilter: string,
	secondFilter: string,
	thirdFilter: string,
): {
	assetClass: "EQUITY" | "COMMODITIES" | "FIXED_INCOME" | "FIXEDINCOME";
	geography: "EU" | "JP" | "US" | "UK" | "EM" | "GLOBAL" | "EU_SMALL_MID_CAP" | "PACIFIC_EX_JP";
	sector: SectorVariants;
} => {
	const clearSectors = (ac: string, s?: string) => {
		const defaultConf: Record<string, SectorVariants> = {
			commodities: "PRECIOUS_METALS",
			fixedIncome: "ALL",
			equity: "ALL",
		};
		if (!s) {
			return defaultConf[ac];
		}
		return MAP_SECTOR[s] ?? "ALL";
	};

	const { assetClass, geography, sector } = useMemo(() => {
		const sanitaizedAssetClass = firstFilter ? MAP_ASSET_CLASS[firstFilter] : "EQUITY";
		const sanitaizedGeoFilter = secondFilter && MAP_GEOGRAPHY[secondFilter] ? MAP_GEOGRAPHY[secondFilter] : "GLOBAL";
		const sanitaizedSectorFilter =
			thirdFilter && firstFilter !== "CO"
				? clearSectors(sanitaizedAssetClass, thirdFilter)
				: clearSectors(sanitaizedAssetClass, secondFilter);

		return {
			assetClass: sanitaizedAssetClass,
			geography: sanitaizedGeoFilter,
			sector: sanitaizedSectorFilter,
		};
	}, [firstFilter, secondFilter, thirdFilter]);

	return { assetClass, geography, sector };
};

export default useOutlookComposition;
