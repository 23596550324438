import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";

export function ChatIcon({ color, style, classList }: { color: string } & StylableProps): JSX.Element {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
			className={toClassName(classList)}
		>
			<path
				d="M13.7141 4.37337H11.6507V2.34671C11.6507 2.07795 11.542 1.82021 11.3486 1.63017C11.1551 1.44014 10.8927 1.33337 10.6191 1.33337H2.36566C2.09204 1.33337 1.82963 1.44014 1.63615 1.63017C1.44268 1.82021 1.33398 2.07795 1.33398 2.34671V10.4534C1.33429 10.5487 1.36196 10.642 1.41382 10.7226C1.46569 10.8031 1.53964 10.8677 1.62718 10.9088C1.71472 10.95 1.81231 10.966 1.90872 10.9551C2.00513 10.9443 2.09646 10.9069 2.17222 10.8473L4.42901 9.06004V10.96C4.42901 11.2288 4.5377 11.4865 4.73118 11.6766C4.92465 11.8666 5.18706 11.9734 5.46068 11.9734H11.4953L13.9075 13.8873C13.9988 13.9598 14.1125 13.9996 14.2299 14C14.3667 14 14.4979 13.9467 14.5947 13.8516C14.6914 13.7566 14.7457 13.6278 14.7457 13.4934V5.38671C14.7457 5.11795 14.6371 4.86021 14.4436 4.67017C14.2501 4.48014 13.9877 4.37337 13.7141 4.37337ZM4.07759 8.03277L2.36566 9.39254V2.34671H10.6191V7.92004H4.40193C4.28387 7.92006 4.16938 7.95985 4.07759 8.03277ZM13.7141 12.4325L12.0021 11.0728C11.9109 11.0003 11.7971 10.9605 11.6797 10.96H5.46068V8.93337H10.6191C10.8927 8.93337 11.1551 8.82661 11.3486 8.63658C11.542 8.44654 11.6507 8.18879 11.6507 7.92004V5.38671H13.7141V12.4325Z"
				fill={color}
			/>
		</svg>
	);
}
