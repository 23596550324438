import type { AclEntityMinInfo, RichAccessControl } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { platformToast } from "$root/notification-system/toast";
import type { MaybeAsync } from "$root/utils/promise";
import type { NodeOrFn, SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import type { ShareDialogProps } from "./ShareDialog";
import { ShareDialog } from "./ShareDialog";
import { getRolePermissionByArea } from "./shared";

export type SpawnShareDialogParams = Omit<ShareDialogProps, "onClose" | "show">;

export function spawnShareDialog(params: SpawnShareDialogParams): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<ShareDialog
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (ownerId, acl) => {
					await params.onSubmitAsync(ownerId, acl);
					resolve();
				}}
			/>
		)),
	);
}

export type SpawnShareDialogSmartParams = {
	objectName: string;
	disclaimerProvider: NodeOrFn<Array<AclEntityMinInfo>>;
	userId: string;
	customerAndLinkedAclProvider: MaybeAsync<{
		customerEntityAcl?: Array<RichAccessControl>;
		linkedAclEntities?: Array<AclEntityMinInfo>;
	}>;
	saveAclHandler: MaybeAsync<void, [{ acl: RichAccessControl[] }]>;
	area: ShareDialogProps["area"];
};

export async function spawnShareDialogSmart({
	objectName,
	disclaimerProvider,
	userId,
	customerAndLinkedAclProvider,
	saveAclHandler,
	area,
}: SpawnShareDialogSmartParams): Promise<SpawnResult<void>> {
	const { customerEntityAcl, linkedAclEntities } = await customerAndLinkedAclProvider();
	const currentOwnerId = customerEntityAcl?.find(({ permissions }) => permissions?.includes("OWNER"))?.userId;
	if (!currentOwnerId) {
		platformToast({
			icon: "Icon-full-error",
			severity: "error",
			children: `No owner found for "${objectName}", unable to share`,
		});
		throw new Error("missing owner");
	}
	return spawnShareDialog({
		acl: customerEntityAcl?.filter((x) => (x.permissions?.length ?? 0) > 0) ?? [],
		usersProvider: (userNameQuery) =>
			customerEntityAcl?.filter((x) => x.name?.toLowerCase().includes(userNameQuery.toLowerCase())) ?? [],
		currentUserId: userId,
		onSubmitAsync: async (ownerId, acl) => {
			try {
				if (ownerId !== currentOwnerId) {
					await saveAclHandler({
						acl: acl.map((user) => {
							if (currentOwnerId === user.userId) {
								return { ...user, permissions: getRolePermissionByArea(area, "EDITOR") };
							}
							return user;
						}),
					});
				} else {
					await saveAclHandler({ acl });
				}

				platformToast({
					children: "Successfully updated the permission",
					severity: "success",
					icon: "Portfolio",
				});
			} catch (err) {
				platformToast({
					children: "Something went wrong while updating the permission",
					severity: "error",
					icon: "Portfolio",
				});
				// TODO: better area?
				reportPlatformError(err, "ERROR", "user", `change ACL on entity "${objectName}" in area "${area}"`);
			}
		},
		ownerId: currentOwnerId,
		objectName,
		disclaimerProvider,
		aclLinkedEntities: linkedAclEntities ?? [],
		area,
	});
}
