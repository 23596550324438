import type { RichTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, type InvestmentListEntry, type ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CopyableText } from "$root/components/CopyableText";
import CustomLabelsEditor from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import PortfolioExposureSummary from "$root/functional-areas/compare-portfolio/PortfolioExposureSummary";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	CircularProgressBar,
	Controller,
	Icon,
	NullableNumberInput,
	TextInput,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import BigNumber from "bignumber.js";
import type { Map, Set } from "immutable";
import { Dispatch, SetStateAction, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import type { rowType } from ".";
import type { UseCompositionBuilderResult } from "../../universe/composition";
import type { ReadOnlyTagOptions } from "../edit-tags";
import { TagButton } from "../edit-tags";
import { isIdentifierCodeValid } from "../indentifier";
import { useApiGen } from "$root/api/hooks";
import { unpromisify } from "$root/utils/functions";
import { axiosExtract } from "$root/third-party-integrations/axios";

type CommonEditorColumnsKey = "name" | "assetClass" | "microAssetClass" | "alias";

export type EditorTableMode = "edit" | "new";
export type EditorCompositionIntruments = ReviewTicker & {
	rowType: rowType;
	id: string;
	investment?: InvestmentListEntry;
};

export function useInstrumentEditorColumns(props: {
	compositionBuilder: UseCompositionBuilderResult;
	existingIdentifiers: Set<string | undefined>;
	onDelete(deleteId: Set<string>, instrument: EditorCompositionIntruments): void;
	entity: UploadEntity;
	existingTags: ReadOnlyTagOptions;
	uuid?: string;
	mode?: EditorTableMode;
	compareSelection?: Map<string, InvestmentListEntry>;
	onCompare?(selection: EditorCompositionIntruments[], action: "remove" | "add"): void;
	instrumentNumber: number;
	exceededWeight?: Map<string, RichTicker[]>;
	onExceededWeight?(id: string, removedTickers?: RichTicker[]): void;
}): TableColumn<EditorCompositionIntruments>[] {
	const {
		compositionBuilder,
		existingIdentifiers,
		existingTags,
		entity,
		onDelete,
		mode,
		uuid,
		onCompare,
		compareSelection,
		instrumentNumber,
		exceededWeight,
		onExceededWeight,
	} = props;
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const user = useUserValue();

	const deleted = compositionBuilder.getDeleted();
	const identifiers = compositionBuilder.getIdentifiers();
	const totalWeight = compositionBuilder.getTotalWeight();

	const commonColumns = useMemo<Record<CommonEditorColumnsKey, TableColumn<EditorCompositionIntruments>>>(
		() => ({
			name: {
				header: "name",
				content: (row) => row.instrument ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
			},
			assetClass: {
				header: "Asset class",
				content: (row) => row.assetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("assetClass"),
				name: "assetClass",
			},
			microAssetClass: {
				header: "Micro asset class",
				content: (row) => row.microAssetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
			alias: {
				header: "Identifier",
				content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
		}),
		[],
	);

	const defaultColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});
		return [
			{
				header: "name",
				content: (row) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return row.instrument ?? "-";
					}

					const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
						quality: x.firstQualityLevel,
						weight: x.weight,
					}));

					return (
						<>
							<AutoTooltip
								overrideColor={themeCSSVars.palette_N300}
								position="right"
								trigger={({ innerRef }) => (
									<span ref={innerRef} className="font-semibold truncate pr-14 items-center">
										{row.instrument ?? "-"}{" "}
									</span>
								)}
							>
								<TooltipContent>
									<div className="w-[280px]">
										<PortfolioExposureSummary
											compared={compareSelection?.has(row.id)}
											composition={exposureComposition ?? []}
											onCompare={(e) => {
												e.stopPropagation();
												onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
											}}
											title={row.instrument ?? "-"}
										/>
									</div>
								</TooltipContent>
							</AutoTooltip>
							<div className="absolute right-0">
								<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
							</div>
						</>
					);
				},
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
				cellClassList: "relative",
				footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
				footer: `Total instruments ${instrumentNumber}`,
			},
			{
				header: "identifier",
				content: (instrument) => {
					if (instrument.rowType === "add") {
						return (
							<Controller
								value={compositionBuilder.getIdentifier(instrument.id)}
								onChange={(identifier) => compositionBuilder.updateIdentifier(instrument.id, identifier)}
							>
								{function RenderBody({ value, onCommit, onChange }) {
									const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
									const isDuplicated = useMemo(() => {
										if (existingIdentifiers.has(value)) {
											return true;
										}
										return Boolean(
											identifiers.find((v, k) => {
												if (k === instrument.id || deleted.has(instrument.id)) {
													return false;
												}
												return v === value;
											}),
										);
									}, [value]);

									return (
										<AutoTooltip
											severity="error"
											disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
											position="left"
											trigger={({ innerRef }) => (
												<TextInput
													size="x-small"
													value={value}
													onChangeText={onChange}
													onBlur={() => onCommit(value)}
													classList={{
														"w-full": true,
														[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
															value.length > 0 && (isIdentifierValid === false || isDuplicated),
														[`[&>input]:border-[${themeCSSVars.palette_N600}]`]: value.length === 0,
													}}
													placeholder="Insert a valid identifier"
													disabled={deleted.has(instrument.id)}
													innerRef={innerRef}
													name="identifiers"
													onClick={(e) => e.stopPropagation()}
													data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
												/>
											)}
										>
											{isDuplicated
												? "identifier is duplicated"
												: isIdentifierValid === false
												  ? "insert a valid identifier"
												  : ""}
										</AutoTooltip>
									);
								}}
							</Controller>
						);
					}

					return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? ""} />;
				},
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList,
			},
			{ ...commonColumns.assetClass, cellClassList },
			{
				...commonColumns.microAssetClass,
				cellClassList,
				footerCellClassList: "!justify-end font-semibold",
				footer: compositionBuilder.getComposition().toArray().length > 0 ? "Total" : undefined,
			},
			{
				header: "weight",
				align: "end",
				cellClassList: "tabular-nums",
				content: (instrument) => (
					<div className="flex flex-1 gap-2 justify-end">
						<Controller
							value={compositionBuilder.getWeight(instrument.id ?? "-")?.toNumber() ?? null}
							onChange={(newValue: number | null) =>
								compositionBuilder.setWeight(instrument.id, newValue === null ? null : BigNumber(newValue))
							}
						>
							{function Block({ value, onChange, onCommit }) {
								const counterRef = useRef(0);
								const isLoadingRef = useRef(false);
								const isMinWeightValid = useMemo(() => (value ?? 0) >= 0.01, [value]);
								const editorApi = useApiGen(EntityEditorControllerApiFactory);

								async function isWeightValid() {
									try {
										isLoadingRef.current = true;
										if (instrument.investment?.uuid === undefined) {
											throw Error("missing uuid");
										}
										if (value === null || value < 0.01) {
											throw Error("weight too low");
										}
										const res = await axiosExtract(
											editorApi.verifyEditorPortfolio(instrument.investment?.uuid, value, entity),
										);

										onExceededWeight?.(instrument.id, res.removedTickers);
									} catch (error) {
										console.error(error);
									} finally {
										isLoadingRef.current = false;
									}
								}

								return (
									<AutoTooltip
										overrideColor={themeCSSVars.palette_N300}
										disabled={isMinWeightValid || deleted.has(instrument.id ?? "-")}
										position="left"
										trigger={({ innerRef }) => (
											<NullableNumberInput
												classList="grow"
												min={0}
												max={100}
												step={0.01}
												innerRef={innerRef}
												inputAppearance={{
													classList: {
														"text-right": true,
														[`!border-[color:${themeCSSVars.palette_N600}]`]: isMinWeightValid === false,
													},
												}}
												disabled={deleted.has(instrument.id ?? "-")}
												size="x-small"
												value={value}
												onChange={(v) => onChange(v ?? 0)}
												onBlur={unpromisify(async () => {
													onCommit(value);
													counterRef.current += 1;
													if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
														await isWeightValid();
													}
												})}
												rightContent={
													isLoadingRef.current ? (
														<CircularProgressBar value="indeterminate" classList="h-3 w-3" />
													) : (
														<Icon icon="Percentile" />
													)
												}
												// avoid user change value when scroll
												onScroll={(e) => e.currentTarget.blur()}
												onWheel={(e) => e.currentTarget.blur()}
												name="weigths"
												onClick={(e) => e.stopPropagation()}
												data-qualifier="CompositionEditor/Table/Column(Weight)/Input"
											/>
										)}
									>
										Requested minimum weight of 0.01
									</AutoTooltip>
								);
							}}
						</Controller>
						<CommonItemActions.DeleteRestore
							deleted={deleted}
							item={instrument.id ?? "-"}
							onDeletedChange={(x) => onDelete(x, instrument)}
						/>
					</div>
				),
				footerCellClassList: "font-semibold",
				footer: () =>
					compositionBuilder.getComposition().toArray().length > 0
						? `${formatNumber(totalWeight.toNumber())}%`
						: undefined,
			},
		];
	}, [
		instrumentNumber,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		compositionBuilder,
		deleted,
		compareSelection,
		onCompare,
		existingIdentifiers,
		identifiers,
		entity,
		onExceededWeight,
		onDelete,
		formatNumber,
		totalWeight,
	]);

	const investmentEnhancementColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});
		return [
			{
				header: "name",
				content: (row) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return row.instrument ?? "-";
					}

					const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
						quality: x.firstQualityLevel,
						weight: x.weight,
					}));

					return (
						<>
							<AutoTooltip
								overrideColor={themeCSSVars.palette_N300}
								position="right"
								trigger={({ innerRef }) => (
									<span ref={innerRef} className="font-semibold truncate pr-14">
										{row.instrument ?? "-"}
									</span>
								)}
							>
								<TooltipContent>
									<div className="w-[280px]">
										<PortfolioExposureSummary
											compared={compareSelection?.has(row.id)}
											composition={exposureComposition ?? []}
											onCompare={(e) => {
												e.stopPropagation();
												onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
											}}
											title={row.instrument ?? "-"}
										/>
									</div>
								</TooltipContent>
							</AutoTooltip>
							<div className="absolute right-0 ">
								<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
							</div>
						</>
					);
				},
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
				cellClassList: "relative",
				relativeWidth: 3.8,
				footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
				footer: `Total instruments ${instrumentNumber}`,
			},
			{
				header: "identifier",
				content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList,
			},
			{ ...commonColumns.assetClass, cellClassList },
			{ ...commonColumns.microAssetClass, cellClassList },
			{
				header: "Weigth current",
				align: "end",
				cellClassList: "tabular-nums",
				content: (row) => (row.previousWeight ? `${formatNumber(row.previousWeight)}%` : ""),
				sortFn: builtInSortFnFor("previousWeight"),
				name: "previousWeight",
			},
			{
				header: "Optimised",
				align: "end",
				cellClassList: "tabular-nums",
				content: (row) => (row.weight ? `${formatNumber(row.weight)}%` : ""),
				sortFn: builtInSortFnFor("weight"),
				name: "weight",
			},
			{
				header: "Difference",
				align: "end",
				cellClassList: "tabular-nums",
				content: (instrument) => {
					const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
					return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
				},
				width: 110,
				footerCellClassList: "font-semibold",
				footer: () => "Total",
				name: "difference",
				sortFn: (rowa, rowb) => {
					const deltaA = Math.round((rowa.weight ?? 0) - (rowa.previousWeight ?? 0)).toFixed(2);
					const deltaB = Math.round((rowb.weight ?? 0) - (rowb.previousWeight ?? 0)).toFixed(2);

					if (deltaA > deltaB) {
						return 1;
					}

					if (deltaA < deltaB) {
						return -1;
					}

					return 0;
				},
			},
			{
				header: "Custom weights",
				align: "end",
				content: (instrument) => (
					<div className="flex flex-1 gap-2">
						<Controller
							value={compositionBuilder.getWeight(instrument.id ?? "-")?.toNumber() ?? null}
							onChange={(newValue: number | null) =>
								compositionBuilder.setWeight(instrument.id, newValue === null ? null : BigNumber(newValue))
							}
						>
							{function Block({ value, onChange, onCommit }) {
								const counterRef = useRef(0);
								const isLoadingRef = useRef(false);

								const editorApi = useApiGen(EntityEditorControllerApiFactory);
								const isMinWeightValid = useMemo(() => (value ?? 0) >= 0.01, [value]);

								async function isWeightValid() {
									try {
										isLoadingRef.current = true;
										if (instrument.investment?.uuid === undefined) {
											throw Error("missing uuid");
										}

										if (value === null || value < 0.01) {
											throw Error("weight too low");
										}

										const res = await axiosExtract(
											editorApi.verifyEditorPortfolio(instrument.investment?.uuid, value, entity),
										);

										onExceededWeight?.(instrument.id, res.removedTickers);
									} catch (error) {
										console.error(error);
									} finally {
										isLoadingRef.current = false;
									}
								}

								return (
									<AutoTooltip
										severity="error"
										overrideColor={themeCSSVars.palette_N300}
										disabled={isMinWeightValid || deleted.has(instrument.id ?? "-")}
										position="left"
										trigger={({ innerRef }) => (
											<NullableNumberInput
												min={0}
												max={100}
												step={0.01}
												innerRef={innerRef}
												inputAppearance={{
													classList: {
														"text-right": true,
														[`!border-[color:${themeCSSVars.palette_N600}]`]: isMinWeightValid === false,
													},
												}}
												disabled={deleted.has(instrument.id ?? "-")}
												size="x-small"
												value={value}
												onChange={(v) => onChange(v ?? 0)}
												onBlur={unpromisify(async () => {
													onCommit(value);
													counterRef.current += 1;
													if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
														await isWeightValid();
													}
												})}
												rightContent={
													isLoadingRef.current ? (
														<CircularProgressBar value="indeterminate" classList="h-3 w-3" />
													) : (
														<Icon icon="Percentile" />
													)
												}
												name="weigths"
												onClick={(e) => e.stopPropagation()}
												data-qualifier="CompositionEditor/Table/Column(CustomWeight)/Input"
												// avoid user change value when scroll
												onScroll={(e) => e.currentTarget.blur()}
												onWheel={(e) => e.currentTarget.blur()}
											/>
										)}
									>
										Requested minimum weight of 0.01
									</AutoTooltip>
								);
							}}
						</Controller>
					</div>
				),
				width: 100,
				footerCellClassList: "font-semibold",
				footer: () => `${formatNumber(totalWeight.toNumber())}%`,
			},
			{
				header: "",
				align: "end",
				content: (instrument) => (
					<CommonItemActions.DeleteRestore
						deleted={deleted}
						item={instrument.id ?? "-"}
						onDeletedChange={(x) => onDelete(x, instrument)}
					/>
				),
			},
		];
	}, [
		instrumentNumber,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		deleted,
		compareSelection,
		onCompare,
		formatNumber,
		compositionBuilder,
		entity,
		onExceededWeight,
		totalWeight,
		onDelete,
	]);

	const universeColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});
		return [
			{ ...commonColumns.name, cellClassList, relativeWidth: 3.6 },
			{
				header: "identifier",
				content: (instrument) => {
					if (instrument.rowType === "add") {
						return (
							<Controller
								value={compositionBuilder.getIdentifier(instrument.id)}
								onChange={(identifier) => compositionBuilder.updateIdentifier(instrument.id, identifier)}
							>
								{function RenderBody({ value, onCommit, onChange }) {
									const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
									const isDuplicated = useMemo(() => {
										if (existingIdentifiers.has(value)) {
											return true;
										}
										return Boolean(
											identifiers.find((v, k) => {
												if (k === instrument.id || deleted.has(instrument.id)) {
													return false;
												}
												return v === value;
											}),
										);
									}, [value]);

									return (
										<AutoTooltip
											severity="error"
											disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
											trigger={({ innerRef }) => (
												<TextInput
													size="x-small"
													value={value}
													onChangeText={onChange}
													onBlur={() => onCommit(value)}
													classList={{
														[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
															value.length > 0 && (isIdentifierValid === false || isDuplicated),
														[`[&>input]:border-[${themeCSSVars.palette_N600}]`]: value.length === 0,
													}}
													placeholder="Insert a valid identifier"
													disabled={deleted.has(instrument.id)}
													innerRef={innerRef}
													name="identifiers"
													onClick={(e) => e.stopPropagation()}
													data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
												/>
											)}
										>
											{isDuplicated
												? "identifier is duplicated"
												: isIdentifierValid === false
												  ? "insert a valid identifier"
												  : ""}
										</AutoTooltip>
									);
								}}
							</Controller>
						);
					}

					return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? "-"} />;
				},
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList: (instrument) => ({
					...cellClassList(instrument),
					"flex-grow": true,
				}),
				relativeWidth: 1.5,
			},
			{ ...commonColumns.assetClass, cellClassList, relativeWidth: 1.9 },
			{
				...commonColumns.microAssetClass,
				cellClassList,
				relativeWidth: 1.5,
				footerCellClassList: "flex justify-end font-semibold",
				footer: () => `Number of instrument`,
			},
			{
				header: "Tags",
				content: ({ id }) => {
					const currentTag = compositionBuilder.getTags().get(id);
					return (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						<div onClick={(e) => e.stopPropagation()}>
							<TagButton
								options={existingTags}
								color={existingTags.find(({ value }) => value === currentTag)?.color}
								value={currentTag ?? null}
								onClick={(newTag, e) => {
									e?.stopPropagation();
									compositionBuilder.updateTag(id, newTag);
								}}
								disabled={deleted.has(id)}
								enableDebounce
							/>
						</div>
					);
				},
				relativeWidth: 1.5,
				footerCellClassList: "font-semibold",
				footer: () => `Tagged: ${compositionBuilder.getTags().size}`,
			},
			{
				header:
					mode === "edit" && uuid ? (
						<CustomLabelsEditor labelKey={`${uuid}_score1`} fallback={t("SCORE")} mode="view" />
					) : (
						t("SCORE")
					),
				cellClassList: " w-full",
				content: (row) => (
					<div className="[&>div]:w-full [&>div]:flex flex">
						<Controller
							value={compositionBuilder.getScore(row.id ?? "-")?.toNumber() ?? null}
							onChange={(newValue: number | null) =>
								compositionBuilder.setScore(row.id, newValue === null ? null : BigNumber(newValue))
							}
						>
							{({ value, onChange, onCommit }) => (
								<NullableNumberInput
									min={0}
									max={100}
									step={0.01}
									inputAppearance={{ classList: "text-right" }}
									disabled={deleted.has(row.id ?? "-")}
									size="x-small"
									value={value}
									onKeyDown={(e) => {
										if (e.repeat) {
											e.preventDefault();
										}
									}}
									onChange={(v) => {
										const convertedNumber = String(v);
										if (convertedNumber.includes(".") && convertedNumber.split(".")[1].length >= 2) {
											const [integers, decimals] = convertedNumber.split(".");
											const lastDecimalNumber = decimals.at(-1);
											const firstDecimalNumber = decimals.at(0);
											const newValue = Number(`${integers}.${firstDecimalNumber}${lastDecimalNumber}`);
											onChange(newValue);
											return;
										}
										onChange(v);
									}}
									onBlur={() => onCommit(value)}
									name="score"
									onClick={(e) => e.stopPropagation()}
									data-qualifier="CompositionEditor/Table/Column(Score)/Input"
									// avoid user change value when scroll
									onScroll={(e) => e.currentTarget.blur()}
									onWheel={(e) => e.currentTarget.blur()}
								/>
							)}
						</Controller>
					</div>
				),
				relativeWidth: 1,
				footerCellClassList: "font-semibold",
				footer: () =>
					`Scored: ${
						compositionBuilder
							.getScores()
							.toArray()
							.filter(([_key, value]) => value !== null).length
					}`, // change score
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			{
				header: "",
				align: "end",
				content: (instrument) => (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
					<span onClick={(e) => e.stopPropagation()}>
						<CommonItemActions.DeleteRestore
							deleted={deleted}
							item={instrument.id ?? "-"}
							onDeletedChange={(x) => onDelete(x, instrument)}
						/>
					</span>
				),
				relativeWidth: 0.3,
			},
		];
	}, [
		commonColumns.name,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		mode,
		uuid,
		t,
		user,
		deleted,
		compositionBuilder,
		existingIdentifiers,
		identifiers,
		existingTags,
		onDelete,
	]);

	return useMemo(() => {
		if (entity === "UNIVERSE") {
			return universeColumns;
		}

		if (entity === "INVESTMENT_ENHANCEMENT") {
			return investmentEnhancementColumns;
		}

		return defaultColumns;
	}, [defaultColumns, entity, investmentEnhancementColumns, universeColumns]);
}
