import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export const FeedbackHandTypes = ["negative", "positive"] as const;
export type FeedbackHandType = (typeof FeedbackHandTypes)[number];

const feedbackHandTypeToComponentMap: Record<FeedbackHandType, (props: { color: string }) => JSX.Element> = {
	negative: ({ color }) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				opacity="0.5"
				d="M0.978281 9.72734C1.35161 10.2273 1.93828 10.5073 2.55828 10.4873H5.27161C5.32495 10.4873 5.37161 10.5073 5.40495 10.5473C5.43828 10.594 5.45828 10.654 5.44495 10.714L5.11161 12.854C5.03161 13.2873 5.11161 13.734 5.32495 14.114C5.54495 14.494 5.89161 14.7873 6.31161 14.934C6.50495 14.994 6.69828 15.0273 6.89161 15.0273C7.49161 15.0273 8.05828 14.7473 8.41161 14.2607L10.4316 11.254C10.6516 11.9607 11.2316 12.314 12.1916 12.314H12.8716C14.1249 12.314 14.7383 11.7273 14.7383 10.514V3.86734C14.7383 2.66068 14.1249 2.06734 12.8716 2.06734L12.1916 2.07401C11.8449 2.02068 11.4849 2.08734 11.1783 2.25401C10.8716 2.42068 10.6249 2.68734 10.4783 3.00734L9.02495 1.88734C8.55828 1.52068 7.97161 1.32068 7.37828 1.33401H4.79828C4.26495 1.33401 3.74495 1.51401 3.31828 1.83401C2.89161 2.15401 2.57161 2.60068 2.41828 3.10734L0.791615 8.04734C0.571614 8.60734 0.644948 9.23401 0.978281 9.72734Z"
				fill={color}
				className="transition-[fill]"
			/>
			<path
				d="M12.8716 2.07401H12.1916C11.8449 2.02068 11.4849 2.08734 11.1783 2.25401C10.8716 2.42068 10.6249 2.68734 10.4783 3.00734L9.02495 1.88734C8.55828 1.52068 7.97161 1.32068 7.37828 1.33401H4.79828C4.26495 1.33401 3.74495 1.51401 3.31828 1.83401C2.89161 2.15401 2.57161 2.60068 2.41828 3.10734L0.791615 8.04734C0.571614 8.60734 0.644948 9.23401 0.978281 9.72734C1.35161 10.2273 1.93828 10.5073 2.55828 10.4873H5.27161C5.32495 10.4873 5.37161 10.5073 5.40495 10.5473C5.43828 10.594 5.45828 10.654 5.44495 10.714L5.11161 12.854C5.03161 13.2873 5.11161 13.734 5.32495 14.114C5.54495 14.494 5.89161 14.7873 6.31161 14.934C6.50495 14.994 6.69828 15.0273 6.89161 15.0273C7.49161 15.0273 8.05828 14.7473 8.41161 14.2607L10.4316 11.254C10.6516 11.9607 11.2316 12.314 12.1916 12.314H12.8716C14.1249 12.314 14.7383 11.7273 14.7383 10.514V3.86734C14.7383 2.66068 14.1249 2.06734 12.8716 2.06734V2.07401ZM10.3249 9.58734L7.56495 13.694C7.45828 13.8273 7.31161 13.9207 7.15161 13.9673C6.99161 14.014 6.81828 14.014 6.65828 13.9673C6.47161 13.9007 6.31828 13.7673 6.21828 13.6007C6.11828 13.434 6.08495 13.234 6.11828 13.0407L6.45828 10.8607C6.51828 10.5073 6.41161 10.1473 6.17828 9.87401C5.95161 9.61401 5.61828 9.46068 5.27161 9.46734H2.55828C2.27161 9.48734 1.99161 9.36068 1.80495 9.14068C1.65828 8.90734 1.63828 8.62068 1.75161 8.37401L3.39161 3.38734C3.49161 3.09401 3.68495 2.83401 3.93161 2.64734C4.18495 2.46068 4.48495 2.35401 4.79828 2.34734H7.37828C7.72495 2.34068 8.07161 2.44734 8.35828 2.64734L10.3316 4.17401V9.58068L10.3249 9.58734ZM13.7183 10.514C13.7183 11.1207 13.6049 11.294 12.8716 11.294H12.1916C11.4583 11.294 11.3449 11.1207 11.3449 10.514V3.86734C11.3449 3.26068 11.4583 3.08734 12.1916 3.08734H12.8716C13.6049 3.08734 13.7183 3.26068 13.7183 3.86734V10.514Z"
				fill={color}
				className="transition-[fill]"
			/>
		</svg>
	),
	positive: ({ color }) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				opacity="0.5"
				d="M14.426 6.63398C14.0527 6.13398 13.466 5.85398 12.846 5.87398H10.1327C10.0793 5.87398 10.0327 5.85398 9.99935 5.81398C9.96601 5.76732 9.94601 5.70732 9.95935 5.64732L10.2927 3.50732C10.3727 3.07398 10.2927 2.62732 10.0793 2.24732C9.85935 1.86732 9.51268 1.57398 9.09268 1.42732C8.89935 1.36732 8.70602 1.33398 8.51268 1.33398C7.91268 1.33398 7.34601 1.61398 6.99268 2.10065L4.97268 5.10732C4.75268 4.40065 4.17268 4.04732 3.21268 4.04732H2.53268C1.27935 4.04732 0.666016 4.63398 0.666016 5.84732V12.494C0.666016 13.7007 1.27935 14.294 2.53268 14.294L3.21268 14.2873C3.55935 14.3407 3.91935 14.274 4.22602 14.1073C4.53268 13.9407 4.77935 13.674 4.92602 13.354L6.37935 14.474C6.84601 14.8407 7.43268 15.0407 8.02602 15.0273H10.606C11.1393 15.0273 11.6593 14.8473 12.086 14.5273C12.5127 14.2073 12.8327 13.7607 12.986 13.254L14.6127 8.31398C14.8327 7.75398 14.7593 7.12732 14.426 6.63398Z"
				fill={color}
				className="transition-[fill]"
			/>
			<path
				d="M2.53268 14.2873H3.21268C3.55935 14.3407 3.91935 14.274 4.22602 14.1073C4.53268 13.9407 4.77935 13.674 4.92602 13.354L6.37935 14.474C6.84601 14.8407 7.43268 15.0407 8.02602 15.0273H10.606C11.1393 15.0273 11.6593 14.8473 12.086 14.5273C12.5127 14.2073 12.8327 13.7607 12.986 13.254L14.6127 8.31398C14.8327 7.75398 14.7593 7.12732 14.426 6.63398C14.0527 6.13398 13.466 5.85398 12.846 5.87398H10.1327C10.0793 5.87398 10.0327 5.85398 9.99935 5.81398C9.96601 5.76732 9.94601 5.70732 9.95935 5.64732L10.2927 3.50732C10.3727 3.07398 10.2927 2.62732 10.0793 2.24732C9.85935 1.86732 9.51268 1.57398 9.09268 1.42732C8.89935 1.36732 8.70602 1.33398 8.51268 1.33398C7.91268 1.33398 7.34601 1.61398 6.99268 2.10065L4.97268 5.10732C4.75268 4.40065 4.17268 4.04732 3.21268 4.04732H2.53268C1.27935 4.04732 0.666016 4.63398 0.666016 5.84732V12.494C0.666016 13.7007 1.27935 14.294 2.53268 14.294V14.2873ZM5.07935 6.77398L7.83935 2.66732C7.94602 2.53398 8.09268 2.44065 8.25268 2.39398C8.41268 2.34732 8.58602 2.34732 8.74601 2.39398C8.93268 2.46065 9.08601 2.59398 9.18602 2.76065C9.28601 2.92732 9.31935 3.12732 9.28602 3.32065L8.94602 5.50065C8.88601 5.85398 8.99268 6.21398 9.22602 6.48732C9.45268 6.74732 9.78601 6.90065 10.1327 6.89398H12.846C13.1327 6.87398 13.4127 7.00065 13.5993 7.22065C13.746 7.45398 13.766 7.74065 13.6527 7.98732L12.0127 12.974C11.9127 13.2673 11.7193 13.5273 11.4727 13.714C11.2193 13.9007 10.9193 14.0073 10.606 14.014H8.02602C7.67935 14.0207 7.33268 13.914 7.04602 13.714L5.07268 12.1873V6.78065L5.07935 6.77398ZM1.68602 5.84732C1.68602 5.24065 1.79935 5.06732 2.53268 5.06732H3.21268C3.94602 5.06732 4.05935 5.24065 4.05935 5.84732V12.494C4.05935 13.1007 3.94602 13.274 3.21268 13.274H2.53268C1.79935 13.274 1.68602 13.1007 1.68602 12.494V5.84732Z"
				fill={color}
				className="transition-[fill]"
			/>
		</svg>
	),
};

export type FeedbackHandProps = {
	active?: boolean;
	type: FeedbackHandType;
};

export type FeedbackFaceComponentProps = {
	color: string;
};

export function FeedbackHand({ active, type }: FeedbackHandProps): JSX.Element {
	const color = !active ? themeCSSVars.palette_N300 : themeCSSVars.palette_B500;
	const Component = feedbackHandTypeToComponentMap[type];
	return (
		<div
			className={toClassName({
				"transition-transform": true,
				"scale-110": active,
			})}
		>
			<Component color={color} />
		</div>
	);
}
