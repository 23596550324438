import type { NotificationGroupedDTO } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";

export type NotificationMarker = {
	markAsRead: boolean;
	latestPortfolioPool: NotificationGroupedDTO[];
	latestMarketPool: NotificationGroupedDTO[];
};

export const NotificationMarkerDefault: NotificationMarker = {
	latestMarketPool: [],
	latestPortfolioPool: [],
	markAsRead: false,
};

export const useNotificationCenterStore = createPersistentAtom<NotificationMarker>(
	NotificationMarkerDefault,
	"notification-center",
);

export function useNotificationValue(): NotificationMarker {
	return useNotificationCenterStore((x) => x.value);
}

export function useNotificationCenterState(): {
	notificationCenterStatus: NotificationMarker;
	setNotificationCenterStatus: (newValue: NotificationMarker | Updater<NotificationMarker>) => void;
} {
	const { value: notificationCenterStatus, set: setNotificationCenterStatus } = useNotificationCenterStore();
	return { notificationCenterStatus, setNotificationCenterStatus };
}
