import { formatDate } from "$root/localization/formatters";
import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type DuplicateDialogCommon = Omit<EntityManagementBaseDialogCommon, "currentName"> & { originalName: string };
export type DuplicateDialogProps = Omit<EntityManagementBaseDialogProps, "currentName"> & { originalName: string };
export type SpawnDuplicateDialogParams = Omit<SpawnEntityManagementBaseDialogParams, "currentName"> & {
	originalName: string;
};

function withDefaults<T extends SpawnDuplicateDialogParams | DuplicateDialogProps>(
	params: T,
): EntityManagementBaseDialogCommon & T {
	return {
		header: params.header ?? (params.entityType ? `Duplicate ${params.entityType}` : "Duplicate"),
		inputLabel: params.inputLabel ?? "Duplicate name",
		suggestedName: `${params.originalName} (Copy ${formatDate(Date.now())})`,
		size: "medium",
		...params,
	};
}

export function spawnDuplicateDialog(params: SpawnDuplicateDialogParams): Promise<string | null> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function DuplicateDialog(props: DuplicateDialogProps): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
