import { CircularProgressBar, Label, Text } from "@mdotm/mdotui/components";
import { useUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { switchExpr } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { MarketScenarioWithMdotmCommentary } from "./MarketViewPositioning";
import { MarketViewSettings } from "$root/api/api-gen";

export function MarketViewSummary(props: {
	marketScenario?: MarketScenarioWithMdotmCommentary /*  & { creationDate?: string } TODO: consider using only the "from" as a "creationDate" */;
	marketViewSettings?: MarketViewSettings;
	scenarioLabel: string | ReactNode;
	loading?: boolean;
}): JSX.Element {
	const typeLabelId = useUniqueDOMId();
	const scenarioLabelId = useUniqueDOMId();
	const forecastLabelId = useUniqueDOMId();
	const fromLabelId = useUniqueDOMId();
	const toLabelId = useUniqueDOMId();
	const createdLabelId = useUniqueDOMId();
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();

	return (
		<div className="flex flex-row pb-8 @5xl:[&>*:nth-child(even)]:mx-4">
			{typeof props.scenarioLabel === "string" ? (
				<div>
					<Label htmlFor={scenarioLabelId}>Market scenario</Label>
					<Text as="div" id={scenarioLabelId} type="Body/L/Bold" classList="py-1">
						{props.scenarioLabel}
					</Text>
				</div>
			) : (
				props.scenarioLabel
			)}
			<div className="mx-2 h-12 self-center border-r" style={{ borderColor: themeCSSVars.palette_N100 }} />
			<div className="min-w-[120px] flex flex-col">
				<Label htmlFor={typeLabelId}>Type</Label>
				{!props.loading ? (
					<Text as="div" id={typeLabelId} type="Body/L/Bold" classList="py-1">
						{props.marketViewSettings
							? props.marketViewSettings.customMarketViewName
							: props.marketScenario?.marketViewType
							  ? switchExpr(props.marketScenario.marketViewType!, {
										EXPECTED_RETURNS_VOLATILITY: () => "Expected returns",
										POSITIONING_INDICATORS: () => "Under/over weight",
							    })
							  : "-"}
					</Text>
				) : (
					<CircularProgressBar value="indeterminate" classList="w-4 h-4 mt-auto mb-1" />
				)}
			</div>
			{/* {props.marketScenario?.forecastHorizon && ( */}
			<>
				<div className="mx-2 h-12 self-center border-r" style={{ borderColor: themeCSSVars.palette_N100 }} />
				<div className="flex flex-col">
					<Label htmlFor={forecastLabelId}>{t("TABLE.HEADERS.FORECAST_HORIZON")}</Label>
					{props.marketScenario?.forecastHorizon && !props.loading ? (
						<Text as="div" id={forecastLabelId} type="Body/L/Bold" classList="py-1">
							{t(`INVESTMENT_HORIZON.${props.marketScenario.forecastHorizon!}`) ?? "-"}
						</Text>
					) : (
						<CircularProgressBar value="indeterminate" classList="w-4 h-4 mt-auto mb-1" />
					)}
				</div>
			</>
			{/* )} */}
			<>
				<div className="mx-2 h-12 self-center border-r" style={{ borderColor: themeCSSVars.palette_N100 }} />
				<div className="flex flex-col">
					<Label htmlFor={fromLabelId}>{t("TABLE.HEADERS.CREATION")}</Label>
					{props.marketScenario?.historicalInvestmentHorizon?.from && !props.loading ? (
						<Text as="div" id={fromLabelId} type="Body/L/Bold" classList="py-1">
							{formatDate(props.marketScenario.historicalInvestmentHorizon.from)}
						</Text>
					) : (
						<CircularProgressBar value="indeterminate" classList="w-4 h-4 mt-auto mb-1" />
					)}
				</div>
				<div className="mx-2 h-12 self-center border-r" style={{ borderColor: themeCSSVars.palette_N100 }} />
				<div className="flex flex-col">
					<Label htmlFor={toLabelId}>{t("TABLE.HEADERS.EXPIRATION")}</Label>
					{props.marketScenario?.historicalInvestmentHorizon?.to && !props.loading ? (
						<Text as="div" id={toLabelId} type="Body/L/Bold" classList="py-1">
							{formatDate(props.marketScenario.historicalInvestmentHorizon.to)}
						</Text>
					) : (
						<CircularProgressBar value="indeterminate" classList="w-4 h-4 mt-auto mb-1" />
					)}
				</div>
			</>
			{/* {props.marketScenario.creationDate && (
				<>
					<div className="mx-2 h-12 self-center border-r" style={{ borderColor: themeCSSVars.palette_N100 }} />
					<div>
						<Label htmlFor={createdLabelId}>Created</Label>
						<Text as="div" id={createdLabelId} type="Body/L/Bold" classList="py-1">
							{formatDate(props.marketScenario.creationDate)}
						</Text>
					</div>
				</>
			)} */}
		</div>
	);
}
