import type { UseTransientNotificationParams } from "../hooks";
import { useTransientNotification } from "../hooks";

export type TransientNotificationProps = UseTransientNotificationParams;

/**
 * Render a transient notification using a snackbar UI element.
 * The notification will appear once the specified `trigger` property
 * goes from `false` to `true` and hidden when it goes from `true` to `false`.
 *
 * If you want to skip the first notification and show it only when the trigger wait for the next falling and rising
 * edge, you can set `pullDown` to false.
 * See {@link TransientNotificationProps.pullDown} for more details.
 *
 * @param props {@link TransientNotificationProps}
 */
export function TransientNotification(props: TransientNotificationProps): JSX.Element {
	useTransientNotification(props);

	return <></>;
}

export { TransientNotificationSlot, TransientNotificationProvider } from "./slots";
export type { TransientNotificationSlotProps } from "./slots";
