import type { StylableProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { IndicatorType, SentimentType } from "../sentiment";

export type SentimentSymbolProps = { sentiment: SentimentType; indicator: IndicatorType } & StylableProps;

export function SentimentSymbol({ sentiment, indicator, style, classList }: SentimentSymbolProps): JSX.Element {
	return (
		<Svg
			viewBox={{
				width: 16,
				height: 16,
			}}
			style={style}
			classList={classList}
		>
			<Switch
				case={sentiment}
				match={{
					"super-positive": () => (
						<path
							d="M14.8678 9.58633C15.2401 10.2123 14.7818 11 14.0454 11H8.04542H7.95458H7.95455C7.21815 11 6.75992 10.2123 7.13213 9.58633L7.99999 8.12674L8 8.12676L10.1776 4.4644C10.5457 3.8452 11.4543 3.8452 11.8224 4.4644L14.8678 9.58633ZM1.95458 11H5.68861C5.40533 10.3363 5.41238 9.54367 5.84282 8.81973L7.12743 6.65922L5.82242 4.4644C5.45425 3.8452 4.54575 3.8452 4.17758 4.4644L1.13216 9.58633C0.759946 10.2123 1.21818 11 1.95458 11Z"
							fill={themeCSSVars.palette_P500}
						/>
					),
					positive: () => (
						<path
							d="M4.62342 11.3335C3.92209 11.3335 3.48567 10.5833 3.84016 9.98715L6.74056 5.10911C7.0912 4.5194 7.95644 4.5194 8.30708 5.10911L11.2075 9.98715C11.562 10.5833 11.1256 11.3335 10.4242 11.3335L4.62342 11.3335Z"
							fill={themeCSSVars.palette_P300}
						/>
					),
					neutral: () => (
						<circle
							cx="8.00001"
							cy="8.00016"
							r="4.66667"
							fill={indicator === "regime" ? themeCSSVars.palette_A300 : themeCSSVars.palette_S300}
						/>
					),
					negative: () => (
						<path
							d="M10.7099 4.6665C11.4113 4.6665 11.8477 5.41666 11.4932 6.01285L8.59278 10.8909C8.24215 11.4806 7.3769 11.4806 7.02627 10.8909L4.12586 6.01285C3.77138 5.41666 4.20779 4.6665 4.90912 4.6665L10.7099 4.6665Z"
							fill={themeCSSVars.palette_graph_R300}
						/>
					),

					"super-negative": () => (
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1.62123 4.6665L5.35526 4.6665C5.07198 5.33017 5.07904 6.12283 5.50948 6.84677L6.79408 9.00728L5.48908 11.2021C5.12091 11.8213 4.2124 11.8213 3.84424 11.2021L0.798814 6.08017C0.426603 5.45417 0.884835 4.6665 1.62123 4.6665ZM7.48653 4.6755C7.53025 4.66958 7.57518 4.6665 7.62121 4.6665C7.62122 4.6665 7.62122 4.6665 7.62123 4.6665L7.71208 4.6665H13.7121C14.4485 4.6665 14.9067 5.45417 14.5345 6.08017L11.4891 11.2021C11.1209 11.8213 10.2124 11.8213 9.84424 11.2021L7.66666 7.53975L7.66664 7.53977L6.79879 6.08017C6.44984 5.49329 6.83078 4.76433 7.48653 4.6755Z"
							fill={themeCSSVars.palette_graph_R500}
						/>
					),
				}}
			/>
		</Svg>
	);
}
