import useWidgetsData from "$root/hooks/useWidgetsData";
import { useLocaleFormatters } from "$root/localization/hooks";
import { useTranslation } from "react-i18next";

export function OutlookWidgetData(): JSX.Element {
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const { currentWidgetsData } = useWidgetsData();

	return (
		<div className="flex items-center space-x-6 text-xs font-bold">
			<div>
				<span className="text-[#8C8EA8]">{t("DASHBOARD.CURRENCY")}</span>&nbsp;
				<span>{t("DASHBOARD.LOCAL")}</span>
			</div>
			<div>
				<span className="text-[#8C8EA8]">{t("HORIZON")}</span>&nbsp;
				<span>{t("TIME_HORIZON")}</span>
			</div>
			<div>
				<span className="text-[#8C8EA8]">{t("LAST_UPDATE")}:</span>&nbsp;
				<span>{formatDate(new Date(Date.parse(currentWidgetsData.creation_time.toString())))}</span>
			</div>
		</div>
	);
}
