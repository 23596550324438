import type { InvestmentListEntry, ReviewTicker } from "$root/api/api-gen";
import BigNumber from "bignumber.js";
import type { UseEditorBuilderResult } from "./useEditorBuilder";
import type { MaybeArray } from "@mdotm/mdotui/utils";

export enum RowTypeEnum {
	UPLOAD = "UPLOAD",
	ADD = "ADD",
	SELECT = "SELECT",
	CASH = "CASH",
}
export type rowType = keyof typeof RowTypeEnum;

export enum EditorTableAreaEnum {
	CREATE = "create",
	EDIT = "edit",
	EDIT_DRAFT = "edit draft"
}
/**
 *  type definition for editor table area
 *
 *  @enum "CREATE" "EDIT"
 */
export type EditorTableArea = keyof typeof EditorTableAreaEnum;

/**
 *  type definition for every single row
 */
export type CompositionEntry = Omit<ReviewTicker, "weight" | "alias"> & {
	rowType: rowType;
	id: string;
	investment?: InvestmentListEntry;
	weight: BigNumber | null;
	alias: string;
};
/**
 * check if eather a valid isin or cusip
 * @param identifier
 * @returns
 */
export function isIdentifierCodeValid(identifier: string): boolean {
	const isinCusipRegExp = new RegExp(/^((([A-Z]){2}){1}((\d)|(\w)){10}|([a-zA-Z0-9]{9}))+$/);
	if (identifier.length === 0) {
		return false;
	}
	return isinCusipRegExp.test(identifier);
}

export type DeleteMode = "hard" | "soft";

export function instrumentHelpersFactory(compositionBuilder: UseEditorBuilderResult) {
	const deleted = compositionBuilder.getDeleted();

	function onDelete(mode: DeleteMode, keys: MaybeArray<string>) {
		if (mode === "hard") {
			return compositionBuilder.delete("hard", keys);
		}
		return compositionBuilder.delete(
			"soft",
			keys,
			typeof keys === "string" ? !deleted.has(keys) : keys.some((x) => !deleted.has(x)),
		);
	}

	function onChangeWeight(key: string, weight: number | null) {
		return compositionBuilder.setWeight(key, weight === null ? null : BigNumber(weight));
	}

	function onChangeIdentifier(key: string, identifier: string) {
		const entry = compositionBuilder.getComposition().get(key);
		if (entry) {
			return compositionBuilder.set(key, { ...entry, alias: identifier });
		}
	}

	function onChangeTagLabel(key: string, tagLabel?: string) {
		const entry = compositionBuilder.getComposition().get(key);
		if (entry) {
			return compositionBuilder.set(key, { ...entry, tagLabel });
		}
	}

	function onChangeScore(key: string, score: number | null) {
		const entry = compositionBuilder.getComposition().get(key);
		if (entry) {
			return compositionBuilder.set(key, { ...entry, score: score ?? undefined });
		}
	}

	function onBulkChangeTagLabel(keys: Array<string>, tagLabel?: string) {
		keys.forEach((k) => {
			const entry = compositionBuilder.getComposition().get(k);
			if (entry) {
				compositionBuilder.set(k, { ...entry, tagLabel });
			}
		});
	}

	function onBulkChangeScore(keys: Array<string>, score: number | null) {
		keys.forEach((k) => {
			const entry = compositionBuilder.getComposition().get(k);
			if (entry) {
				compositionBuilder.set(k, { ...entry, score: score ?? undefined });
			}
		});
	}

	return {
		onDelete,
		onChangeWeight,
		onChangeIdentifier,
		onChangeTagLabel,
		onChangeScore,
		onBulkChangeTagLabel,
		onBulkChangeScore,
	};
}
