import { themeCSSVars } from "@mdotm/mdotui/themes";

export function RemitIcon({ color = themeCSSVars.palette_N300 }: { color?: string }): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
			<path
				fill={color}
				fillRule="evenodd"
				d="M9.391 3.869c.17-.17.41-.27.65-.27v.01c.26 0 .51.11.69.3.18.19.27.45.24.71v5.419c0 .24-.1.48-.27.65-.17.17-.41.27-.65.27H4.513c-.33 0-.64-.17-.8-.46a.942.942 0 010-.92.94.94 0 01.8-.46h3.299l-5.538-5.53a.916.916 0 01-.24-.9c.09-.32.34-.57.66-.66.32-.08.66.01.89.24l5.537 5.54v-3.29c0-.24.1-.48.27-.65zm6.777 10.989h3.319c.33 0 .63-.18.8-.46.16-.29.16-.64 0-.92a.907.907 0 00-.8-.46h-5.538c-.24 0-.48.1-.65.27-.17.17-.27.41-.27.65v5.54c0 .33.18.63.46.8.29.16.64.16.92 0 .29-.16.46-.47.46-.8v-3.29l5.537 5.54c.23.23.58.33.9.24.32-.09.57-.34.66-.66.09-.32 0-.66-.24-.9l-5.558-5.55z"
				clipRule="evenodd"
			/>
		</svg>
	);
}
