import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const SadMachineDrawIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 91,
		height: "auto",
	},
	...others
}) => {
	return (
		<Svg viewBox={{ width: 91, height: 81 }} style={style} {...others}>
			<g filter="url(#filter0_d_1774_13244)">
				<path
					d="M70.3709 12H19.6093C18.1181 12.0053 16.6899 12.603 15.6383 13.6619C14.5867 14.7209 13.9975 16.1545 14.0001 17.6481V48.2334C13.9949 48.9754 14.136 49.7112 14.4152 50.3986C14.6945 51.086 15.1066 51.7113 15.6277 52.2388C16.1488 52.7663 16.7688 53.1856 17.452 53.4726C18.1353 53.7597 18.8684 53.9087 19.6093 53.9113H35.9686L31.5151 60.8966H25.8561C25.4667 60.9279 25.1033 61.1049 24.8383 61.3925C24.5734 61.68 24.4262 62.057 24.4262 62.4483C24.4262 62.8396 24.5734 63.2165 24.8383 63.5041C25.1033 63.7916 25.4667 63.9687 25.8561 64H64.134C64.5428 63.9974 64.9341 63.8336 65.2231 63.5441C65.5122 63.2546 65.6757 62.8627 65.6783 62.4533C65.6772 62.0429 65.5164 61.6491 65.23 61.3556C65.0866 61.2104 64.9159 61.0951 64.7278 61.0163C64.5397 60.9375 64.3379 60.8968 64.134 60.8966H58.4651L54.0315 53.9113H70.3709C71.8647 53.9087 73.2965 53.3125 74.3519 52.2535C75.4073 51.1946 76 49.7595 76 48.2633V17.6481C76 16.1518 75.4073 14.7167 74.3519 13.6578C73.2965 12.5989 71.8647 12.0026 70.3709 12V12Z"
					fill="white"
				/>
				<path
					d="M70.5799 12H19.6299C18.1332 12.0053 16.6997 12.6043 15.6441 13.6654C14.5886 14.7266 13.9972 16.1633 13.9999 17.66V48.31C13.9946 49.0536 14.1362 49.791 14.4165 50.4798C14.6969 51.1686 15.1104 51.7953 15.6335 52.324C16.1565 52.8526 16.7788 53.2728 17.4646 53.5604C18.1504 53.848 18.8862 53.9974 19.6299 54H36.0499L31.5799 61H25.8999C25.509 61.0314 25.1443 61.2088 24.8783 61.497C24.6124 61.7851 24.4647 62.1629 24.4647 62.555C24.4647 62.9471 24.6124 63.3249 24.8783 63.613C25.1443 63.9012 25.509 64.0786 25.8999 64.11H64.3199C64.7302 64.1074 65.1229 63.9432 65.413 63.6531C65.7031 63.363 65.8673 62.9703 65.8699 62.56C65.8688 62.1487 65.7073 61.7541 65.4199 61.46C65.276 61.3145 65.1047 61.199 64.9159 61.12C64.7271 61.041 64.5245 61.0003 64.3199 61H58.6299L54.1799 54H70.5799C72.0793 53.9974 73.5164 53.3999 74.5757 52.3387C75.635 51.2775 76.2299 49.8394 76.2299 48.34V17.66C76.2299 16.1606 75.635 14.7225 74.5757 13.6613C73.5164 12.6001 72.0793 12.0026 70.5799 12V12ZM54.9999 61H35.2699L39.7299 54H50.4899L54.9999 61ZM73.1199 17.66V48.31C73.1239 48.6451 73.061 48.9777 72.9349 49.2882C72.8088 49.5988 72.622 49.881 72.3855 50.1185C72.149 50.3559 71.8674 50.5438 71.5574 50.6712C71.2474 50.7985 70.9151 50.8627 70.5799 50.86H19.6299C19.296 50.8614 18.9653 50.7961 18.6569 50.6681C18.3486 50.5401 18.0689 50.3518 17.8343 50.1143C17.5996 49.8769 17.4147 49.595 17.2903 49.2852C17.1659 48.9753 17.1046 48.6438 17.1099 48.31V17.66C17.1046 17.3262 17.1659 16.9947 17.2903 16.6848C17.4147 16.375 17.5996 16.0931 17.8343 15.8557C18.0689 15.6182 18.3486 15.4299 18.6569 15.3019C18.9653 15.1739 19.296 15.1086 19.6299 15.11H70.5799C70.9151 15.1073 71.2474 15.1715 71.5574 15.2988C71.8674 15.4262 72.149 15.6141 72.3855 15.8515C72.622 16.089 72.8088 16.3712 72.9349 16.6818C73.061 16.9923 73.1239 17.3249 73.1199 17.66Z"
					fill="#D1D2DC"
				/>
				<path
					d="M38.8799 32.1101C39.0277 32.2553 39.2027 32.3698 39.395 32.447C39.5872 32.5243 39.7928 32.5627 39.9999 32.5601C40.2039 32.5612 40.406 32.5219 40.5948 32.4447C40.7835 32.3675 40.9552 32.2538 41.0999 32.1101C41.3882 31.8165 41.5497 31.4215 41.5497 31.0101C41.5497 30.5987 41.3882 30.2037 41.0999 29.9101L39.4599 28.2801L41.0899 26.6501C41.3698 26.364 41.5289 25.9813 41.5345 25.5811C41.5401 25.1809 41.3917 24.7939 41.1199 24.5001C40.9753 24.3472 40.8019 24.2243 40.6098 24.1385C40.4176 24.0527 40.2103 24.0057 39.9999 24.0001C39.7919 23.9977 39.5856 24.0372 39.3932 24.1162C39.2007 24.1952 39.0262 24.3122 38.8799 24.4601L37.2699 26.0801L35.6599 24.4601C35.3717 24.1687 34.9798 24.0033 34.5699 24.0001C34.1552 23.9986 33.7565 24.1602 33.4599 24.4501C33.1693 24.7424 33.0061 25.1379 33.0061 25.5501C33.0061 25.9623 33.1693 26.3578 33.4599 26.6501L35.0799 28.2801L33.4499 29.9201C33.1945 30.2196 33.0618 30.6045 33.0782 30.9977C33.0947 31.391 33.2592 31.7635 33.5388 32.0405C33.8184 32.3175 34.1923 32.4787 34.5857 32.4916C34.9791 32.5045 35.3628 32.3682 35.6599 32.1101L37.2699 30.4901L38.8799 32.1101Z"
					fill="#4CB09C"
				/>
				<path
					d="M57.4899 24.4501C57.1928 24.1688 56.7991 24.012 56.3899 24.012C55.9807 24.012 55.5871 24.1688 55.2899 24.4501L53.6799 26.0801L52.0699 24.4601C51.7866 24.1735 51.4028 24.0085 50.9999 24.0001C50.7957 23.9974 50.5931 24.0359 50.4041 24.1132C50.2151 24.1905 50.0437 24.3051 49.8999 24.4501C49.6085 24.7384 49.4431 25.1303 49.4399 25.5401C49.4303 25.9104 49.5668 26.2697 49.8199 26.5401L51.5099 28.2501L49.8599 29.9201C49.6045 30.2196 49.4717 30.6045 49.4882 30.9978C49.5047 31.391 49.6692 31.7635 49.9488 32.0405C50.2284 32.3176 50.6023 32.4787 50.9957 32.4916C51.3891 32.5045 51.7728 32.3683 52.0699 32.1101L53.6799 30.4901L55.2899 32.1101C55.5835 32.3985 55.9785 32.5601 56.3899 32.5601C56.5939 32.5612 56.796 32.522 56.9848 32.4448C57.1735 32.3675 57.3452 32.2538 57.4899 32.1101C57.7782 31.8166 57.9397 31.4216 57.9397 31.0101C57.9397 30.5987 57.7782 30.2037 57.4899 29.9101L55.8699 28.2801L57.4999 26.6501C57.7893 26.3565 57.9506 25.9603 57.9488 25.5481C57.9469 25.1358 57.7819 24.7411 57.4899 24.4501Z"
					fill="#4CB09C"
				/>
				<path
					d="M45.4801 37.4C43.6029 37.4087 41.758 37.8895 40.1153 38.7982C38.4726 39.707 37.085 41.0143 36.0801 42.6C35.86 42.9472 35.7849 43.3669 35.8709 43.769C35.9569 44.171 36.1972 44.5232 36.5401 44.75C36.7947 44.9107 37.0891 44.9973 37.3901 45C37.4965 45.0099 37.6037 45.0099 37.7101 45C37.9133 44.9579 38.1062 44.8761 38.2777 44.7595C38.4493 44.6428 38.5962 44.4934 38.7101 44.32C39.4315 43.1702 40.4307 42.2204 41.6155 41.5581C42.8003 40.8958 44.1328 40.5422 45.4901 40.53C46.8473 40.5431 48.1794 40.8971 49.364 41.5593C50.5487 42.2215 51.548 43.1708 52.2701 44.32C52.3811 44.4914 52.5247 44.6392 52.6928 44.7551C52.8609 44.8709 53.0502 44.9526 53.2498 44.9953C53.4495 45.038 53.6556 45.041 53.8564 45.004C54.0572 44.9671 54.2487 44.891 54.4201 44.78C54.5915 44.669 54.7393 44.5254 54.8552 44.3573C54.9711 44.1892 55.0527 43.9999 55.0954 43.8003C55.1381 43.6006 55.1411 43.3945 55.1041 43.1937C55.0672 42.9929 54.9911 42.8014 54.8801 42.63C53.8788 41.0388 52.4927 39.7255 50.8498 38.8114C49.2069 37.8973 47.3601 37.4119 45.4801 37.4Z"
					fill="#4CB09C"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1774_13244"
					x="-0.000244141"
					y="0"
					width="90.2301"
					height="80.11"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1774_13244" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1774_13244" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default SadMachineDrawIcon;
