import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import { useCallback, useEffect, useRef, useState } from "react";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import type { Option } from "$root/components/shared";
import { useTranslation } from "react-i18next";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { CommentaryContext } from "$root/widgets-architecture/contexts/commentary";
import type { HMMRegion } from "$root/widgets-architecture/contexts/region";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useApiGen } from "$root/api/hooks";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useHistory, useLocation } from "react-router";
import { getQueryParam } from "$root/utils/url";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PageHeader } from "$root/components/PageHeader";
import { ProgressBar, Select } from "@mdotm/mdotui/components";
import { useEventBus } from "$root/event-bus";
import { noop } from "@mdotm/mdotui/utils";
import { useFunctionalAreas } from "$root/App/context";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";

const PAGE_NAME = "HMM";
const DEFAULT_REGION: HMMRegion = "eu";

type HmmOption = Array<Option<string>>;

export default function RegimeAnalysisToolPage(): JSX.Element {
	const [selectedRegions, setSelectedRegions] = useState<HMMRegion | null>(null);
	const hmmApi = useApiGen(HmmControllerApiFactory);

	const history = useHistory();
	const location = useLocation();
	const historyRef = useRef(history);
	const locationRef = useRef(location);
	const optionTreeDataRef = useRef<HmmOption | null>(null);
	const ignoreNextHistoryUpdateRef = useRef(false);
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();

	const updateFilterUsingSearchString = useCallback((optionTree: HmmOption, searchString: string) => {
		const candidateSelection = getQueryParam(searchString, "region") || DEFAULT_REGION;
		const assetRegionOption = optionTree.find((opt) => opt.value === candidateSelection);
		if (!assetRegionOption) {
			setSelectedRegions(optionTree[0].value as HMMRegion);
		} else {
			setSelectedRegions(candidateSelection as HMMRegion);
		}
	}, []);

	const { isLoading, refetch, data } = useQueryNoRefetch(["regionOptions"], {
		queryFn: async () => {
			const processRegion = (region?: string) => {
				if (!region) {
					return "-";
				}
				if (region === "eu" || region === "us" || region === "jp" || region === "global") {
					return t(`GEOGRAPHY.${region as HMMRegion}`);
				}
				return region;
			};

			const { data: regions } = await hmmApi.retrieveRegions();
			const regionsOptions: Option<HMMRegion>[] = [...regions].map((el) => ({ //[...regions, "global"]
				value: el as HMMRegion,
				label: processRegion(el),
			}));
			return { regionsOptions, updatedAt: new Date() };
		},
		onSuccess({ regionsOptions }) {
			updateFilterUsingSearchString(regionsOptions, location.search);
		},
	});

	useEffect(() => {
		historyRef.current = history;
	}, [history]);

	useEffect(() => {
		locationRef.current = location;
	}, [location]);

	useEffect(() => {
		optionTreeDataRef.current = data?.regionsOptions ?? null;
	}, [data]);

	useEffect(() => {
		if (ignoreNextHistoryUpdateRef.current) {
			ignoreNextHistoryUpdateRef.current = false;
			return;
		}
		if (optionTreeDataRef.current) {
			updateFilterUsingSearchString(optionTreeDataRef.current, location.search);
		}
	}, [location.search, updateFilterUsingSearchString]);

	useEffect(() => {
		if (selectedRegions) {
			const href = `?region=${encodeURIComponent(selectedRegions)}`;
			if (locationRef.current.search !== href) {
				ignoreNextHistoryUpdateRef.current = true;
				historyRef.current.push(href);
			}
		}
	}, [selectedRegions]);

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useFunctionalAreas(
		() => ({
			areas: "hmm",
			data: { region: selectedRegions ?? undefined },
		}),
		[selectedRegions],
	);

	const { regionsOptions, updatedAt } = data ?? {};

	return (
		<AuthorizationGuard requiredService="SIGNALS" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
			{() => (
				<>
					<PageHeader
						title={t("HMM") + (updatedAt ? " - " + formatDate(new Date(updatedAt)) : "")}
						titleAction={
							regionsOptions && (
								<Select
									strategy="fixed"
									classList="!w-36"
									value={selectedRegions}
									onChange={setSelectedRegions}
									options={regionsOptions ?? []}
								/>
							)
						}
					/>
					<div
						style={{
							marginBottom: "1rem",
						}}
					>
						{isLoading && <ProgressBar value="indeterminate" />}
					</div>
					{selectedRegions && (
						<RegionContext.Provider value={{ region: selectedRegions as HMMRegion }}>
							<CommentaryContext.Provider value={{ fixedCommentary: true }}>
								{/* {selectedRegions === "global" ? ( */}
									{/* <WidgetsMapper widgetName="WorldGridMapBlock" /> */}
								{/* ) : ( */}
									<WidgetsGrid gridName={PAGE_NAME} isDraggable={false} />
								{/* )} */}
							</CommentaryContext.Provider>
						</RegionContext.Provider>
					)}
				</>
			)}
		</AuthorizationGuard>
	);
}
