import type {
	InvestmentIdentifierImport,
	TemplateImportType,
	UniverseImportConverterType,
	UniverseImportItems,
} from "$root/api/api-gen";
import { IntegrationsControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import BaseDropzoneBlock, { Converted, DropzoneState } from "$root/components/Upload/BaseDropzoneBlock";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadBlob } from "$root/utils/files";
import { parallelize } from "$root/utils/promise";
import { capitalizeString } from "$root/utils/strings";
import type { DialogProps, Option } from "@mdotm/mdotui/components";
import {
	ActionText,
	Banner,
	Button,
	Collapsible,
	DefaultCollapsibleHeader,
	Dialog,
	DialogFooter,
	FormField,
	Select,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, ForEach, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import XLSX from "xlsx";

const concurrency = 32;
function ConversionBlock({
	converter,
	status,
	converted,
	onSubmit,
	onChangeStatus,
}: {
	status: DropzoneState;
	onChangeStatus(status: DropzoneState): void;
	converter: UniverseImportConverterType;
	onSubmit(conversions: Array<Converted<UniverseImportItems>>): void;
	converted: Array<Converted<UniverseImportItems>>;
}) {
	const { t } = useTranslation();
	const integrationsV2Api = useApiGen(IntegrationsControllerApiFactory);

	const reset = useCallback(() => {
		onChangeStatus("empty");
		onSubmit([]);
	}, [onChangeStatus, onSubmit]);

	const onChange = useCallback(
		async (files: File[], type: UniverseImportConverterType) => {
			try {
				reset();
				onChangeStatus("loading");
				const filesToConverter = files.map(
					(file) => () => axiosExtract(integrationsV2Api.convertUniverseFrom(type, file)),
				);
				const responseStack = await parallelize(filesToConverter, { concurrency });
				const assocFileWithConversion = files.map((file, i) => ({
					filename: file.name,
					conversion: responseStack[i],
				}));

				const succededResponses = assocFileWithConversion.filter(
					({ conversion }) => (conversion as UniverseImportItems | undefined) !== undefined,
				);

				const failedResponses = assocFileWithConversion.filter(
					({ conversion }) => (conversion as UniverseImportItems | undefined) === undefined,
				);

				if (succededResponses.length > 0) {
					onSubmit(assocFileWithConversion);
					onChangeStatus("success");
					return;
				}

				if (failedResponses.length > 0) {
					failedResponses.map((x) =>
						platformToast({
							children: `Failed to convert ${x.filename ?? "-"}`,
							severity: "error",
							icon: "Portfolio",
						}),
					);

					onChangeStatus("error");
				}
			} catch (error) {
				onChangeStatus("error");

				throw error;
			}
		},
		[integrationsV2Api, onChangeStatus, onSubmit, reset],
	);

	const failedConversion = useMemo(() => converted.filter(({ conversion }) => conversion === undefined), [converted]);

	return (
		<>
			<BaseDropzoneBlock
				converted={converted}
				disabled={status === "loading"}
				onDelete={reset}
				status={status}
				onChange={(x) => {
					if (x === null) {
						return reset();
					}

					unpromisify(() => onChange(x, converter))();
				}}
			/>
			{status === "loading" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}
			{failedConversion.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Failed to convert these files">
						{failedConversion.map(({ filename }) => filename).join(", ")}
					</Banner>
				</div>
			)}
		</>
	);
}

const converterToTemplateType = {
	BLOOMBERG_CONVERTER: "BLOOMBERG_UNIVERSE_IMPORT",
	SPHERE_TEMPLATE_CONVERTER: "SPHERE_UNIVERSE_IMPORT",
} satisfies { [key in UniverseImportConverterType]: TemplateImportType };

type InvestmentDialogProps = {
	converters: Array<UniverseImportConverterType>;
	onSubmit(convertedFiles: InvestmentIdentifierImport[]): MaybePromise<void>;
	qualifier?: string;
	show: boolean;
	onClose(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

function UniverseImport({
	converters,
	onSubmit,
	show,
	onClose,
	onAnimationStateChange,
}: InvestmentDialogProps): JSX.Element {
	const { t } = useTranslation();
	const options = useMemo(
		() =>
			converters.map(
				(converter): Option<UniverseImportConverterType> => ({
					label: capitalizeString(t(`IMPORT.${converter}`).toLowerCase()),
					value: converter,
				}),
			),
		[converters, t],
	);

	const [selectedConverter, setSelectedConverter] = useState(converters[0]);
	const [converted, setConverted] = useState<Array<Converted<UniverseImportItems>>>([]);
	const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneState>("empty");

	const flatConversion = useMemo(() => converted.flatMap((x) => x.conversion.investments ?? []), [converted]);

	const conversionWithErrors = useMemo(
		() => converted.filter((x) => x.conversion.errors && x.conversion.errors.length > 0),
		[converted],
	);

	const disabled = useMemo(
		() => conversionWithErrors.length > 0 || converted.length === 0,
		[conversionWithErrors.length, converted.length],
	);

	const onSubmitAsync = useCallback(async () => {
		const fileToSubmit = flatConversion.filter((investments) => investments);
		await onSubmit(fileToSubmit);
	}, [flatConversion, onSubmit]);

	const handleDownloadErrorReport = useCallback((report: Array<Converted<UniverseImportItems>>) => {
		const xlsSheet = report.flatMap(
			({ conversion, filename }) =>
				conversion.errors?.map(({ errorMessage, investmentName }) => ({
					File: filename,
					Portfolio: investmentName,
					Message: errorMessage,
				})) ?? [],
		);

		const reportWS = XLSX.utils.json_to_sheet(xlsSheet);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
		XLSX.writeFile(wb, "Errors file.xlsx");
	}, []);

	const integrationsV2Api = useApiGen(IntegrationsControllerApiFactory);
	const handleDownloadTemplate = useCallback(
		(convertTo: UniverseImportConverterType) =>
			integrationsV2Api
				.getUploadTemplate(converterToTemplateType[convertTo], {
					responseType: "blob",
				})
				.then(({ data: template, headers }) => {
					// const fileType = headers["content-type"];
					const fileName = headers["content-disposition"].split("filename=")[1];
					downloadBlob(template, { fileName });
				})
				.catch((e) => {
					platformToast({ children: "Oops, Something went wrong, pleas re-try", severity: "error", icon: "Portfolio" });
					reportPlatformError(e, "ERROR", "portfolio", "unable to download template");
					throw e;
				}),
		[integrationsV2Api],
	);

	return (
		<Dialog
			size="large"
			show={show}
			onClose={onClose}
			onSubmitAsync={onSubmitAsync}
			onAnimationStateChange={onAnimationStateChange}
			header="Import from external template"
			classList="max-h-[830px]"
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" disabled={disabled}>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<ol className="list-decimal ml-4 mb-5">
				<li>
					Download the <ActionText onClickAsync={() => handleDownloadTemplate(selectedConverter)}>template</ActionText>
				</li>
				<li>Insert your universe composition</li>
				<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
			</ol>
			<div className="mb-4">
				<FormField label="Import an excel file exported from your custom format:">
					{(forward) => (
						<Select
							{...forward}
							options={options}
							value={selectedConverter}
							onChange={(converter) => {
								setConverted([]);
								setDropzoneStatus("empty");
								if (converter) {
									setSelectedConverter(converter);
								}
							}}
							classList="w-[413px]"
						/>
					)}
				</FormField>
			</div>
			<ConversionBlock
				converted={converted}
				onSubmit={setConverted}
				status={dropzoneStatus}
				onChangeStatus={setDropzoneStatus}
				converter={selectedConverter}
			/>

			{!disabled && dropzoneStatus === "success" && (
				<div className="mb-4">
					<Banner severity="success" title="File uploaded correctly">
						Your universes has been converted from Sphere. Click &quot;
						<strong>Save</strong>&quot; to start analyzing and processing your instruments.
					</Banner>
				</div>
			)}

			{conversionWithErrors.length > 0 && (
				<Banner severity="error" title="Please review the template format and upload the file again" classList="mb-4">
					<p>The uploaded files have some formatting errors</p>
					{"\n"}
					<ActionText as="button" onClick={() => handleDownloadErrorReport(conversionWithErrors)}>
						Download errors file
					</ActionText>
				</Banner>
			)}

			<ForEach collection={conversionWithErrors}>
				{({ item }) => (
					<Collapsible
						header={(params) => (
							<DefaultCollapsibleHeader
								{...params}
								classList={{
									[`!bg-transparent h-[40px] flex items-center border-b border-[${themeCSSVars.palette_N200}]`]: true,
								}}
							>
								{item.filename}
							</DefaultCollapsibleHeader>
						)}
					>
						<ForEach collection={item.conversion.errors ?? []}>
							{({ item: e }) => (
								<div className="h-[40px] border-b border-[#eeeef1] transition-[background] data-[forceHover]:bg-[#EFF0F3] hover:bg-[#EFF0F3] flex space-x-2 items-center px-2">
									<div className="w-14">
										<p className="pr-1 truncate">{e.investmentName}</p>
									</div>
									<p className="pr-1 truncate">{e.errorMessage}</p>
								</div>
							)}
						</ForEach>
					</Collapsible>
				)}
			</ForEach>
		</Dialog>
	);
}

type SpawnUniverseImportDialogParams = Omit<InvestmentDialogProps, "onClose" | "show">;
export function spawnUniverseImportDialog(params: SpawnUniverseImportDialogParams): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<UniverseImport
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={async (payload) => {
					await params.onSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
export default UniverseImport;
