import { themeCSSVars } from "@mdotm/mdotui/themes";

export function ExpandIcon({ color = themeCSSVars.palette_N300 }: { color?: string }): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24">
			<path
				fill={color}
				fillRule="evenodd"
				d="M8.438 3.84H5.109l5.538 5.534c.24.23.33.57.24.89a.91.91 0 01-.65.65.937.937 0 01-.89-.24L3.84 5.162v3.271c0 .33-.18.64-.46.8a.94.94 0 01-.92 0 .941.941 0 01-.459-.8V2.92c0-.24.1-.48.27-.65.17-.17.41-.27.65-.27h5.518c.33 0 .64.18.8.46.16.29.16.64 0 .92a.94.94 0 01-.8.46zm11.987 11.117c.17-.17.41-.27.65-.27h.01c.26 0 .5.11.68.3.17.19.26.45.23.71v5.383c0 .24-.1.48-.27.65-.17.17-.41.27-.65.27h-5.509c-.33 0-.64-.18-.8-.46a.943.943 0 010-.92.94.94 0 01.8-.46h3.29l-5.51-5.514c-.24-.23-.33-.57-.24-.89a.91.91 0 01.65-.65c.32-.08.66.01.89.24l5.509 5.512v-3.251c0-.24.1-.48.27-.65z"
				clipRule="evenodd"
			/>
		</svg>
	);
}
