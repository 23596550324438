import { FormController } from "$root/third-party-integrations/react-hook-form";
import { valueByPath } from "$root/utils/objects";
import type { SelectProps, StylableProps } from "@mdotm/mdotui/components";
import { FormField, Label, Select } from "@mdotm/mdotui/components";
import { NodeOrFn, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import equal from "fast-deep-equal";
import type { Control, FieldValues, FormState, Path } from "react-hook-form";

export type SelectFieldProps<T extends FieldValues, TOptions, TGroups extends string> = StylableProps & {
	control: Control<T, any>;
	formState: FormState<T>;
	name: Path<T>;
	label: NodeOrFn<{ htmlFor: string }>;
	unselectOnMatch?: boolean;
	hideBanner?: boolean;
} & Omit<SelectProps<TOptions, TGroups>, "value">;

export function SelectField<T extends FieldValues, TOptions, TGroups extends string>({
	label,
	formState,
	name,
	control,
	classList,
	style,
	unselectOnMatch,
	hideBanner = false,
	...forward
}: SelectFieldProps<T, TOptions, TGroups>): JSX.Element {
	return (
		<FormField
			classList={classList}
			style={style}
			label={({ htmlFor }) =>
				typeof label === "string" ? (
					<Label htmlFor={htmlFor} classList="mb-px">
						{label}
					</Label>
				) : (
					renderNodeOrFn(label, { htmlFor })
				)
			}
			error={hideBanner ? undefined : (valueByPath(formState.errors, name) as { message?: string })?.message}
		>
			{(fieldProps) => (
				<FormController
					control={control}
					name={name}
					render={({ field: { ref, ...controllerProps } }) => (
						<Select
							innerRef={ref}
							{...fieldProps}
							{...controllerProps}
							onChange={(e) => {
								if (unselectOnMatch && equal(controllerProps.value, e)) {
									return controllerProps.onChange(null);
								}
								return controllerProps.onChange(e);
							}}
							{...(forward as any)}
						/>
					)}
				/>
			)}
		</FormField>
	);
}
