import type { InvestmentsReportTemplate } from "$root/api/api-gen";
import type {
	ReportTemplateStatus,
	ReportTemplateVariant,
	ReportTemplateItem,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";

import { generateUniqueDOMId } from "@mdotm/mdotui/react-extensions";

export const DefaultReportTemplateName = {
	sphere: "sphere",
} as const;

export type DefaultReportTemplateName = (typeof DefaultReportTemplateName)[keyof typeof DefaultReportTemplateName];

export type DefaultReportTemplate = {
	id: DefaultReportTemplateName;
	templateName: string;
	format: "pdf" | "xlsx";
	visible: boolean;
	data: {
		status: ReportTemplateStatus;
		version: number;
		templateItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
		templateDraftItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
	};
};
export const defaultTemplateName = "Untitled template";

const sphereTemplateDefault: Record<ReportTemplateVariant, Array<ReportTemplateItem>> = {
	current: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "portfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_SINCE_INCEPTION: true,
				EFFICIENCY_RATIO_YTD: true,
				EFFICIENCY_RATIO_1M: true,
				EFFICIENCY_RATIO_3M: true,
				EFFICIENCY_RATIO_6M: true,
				EFFICIENCY_RATIO_1Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_SINCE_INCEPTION: true,
				MAX_DRAWDOWN_YTD: true,
				MAX_DRAWDOWN_1M: true,
				MAX_DRAWDOWN_3M: true,
				MAX_DRAWDOWN_6M: true,
				MAX_DRAWDOWN_1Y: true,
			},
			performance: {
				PERFORMANCE_SINCE_INCEPTION: true,
				PERFORMANCE_YTD: true,
				PERFORMANCE_1M: true,
				PERFORMANCE_3M: true,
				PERFORMANCE_6M: true,
				PERFORMANCE_1Y: true,
			},
			sortino: {
				SORTINO_SINCE_INCEPTION: true,
				SORTINO_YTD: true,
				SORTINO_1M: true,
				SORTINO_3M: true,
				SORTINO_6M: true,
				SORTINO_1Y: true,
			},
			volatility: {
				VOLATILITY_SINCE_INCEPTION: true,
				VOLATILITY_YTD: true,
				VOLATILITY_1M: true,
				VOLATILITY_3M: true,
				VOLATILITY_6M: true,
				VOLATILITY_1Y: true,
			},
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "performance",
			benchmark: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			detailed: true,
			summary: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				{ column: "ASSET_CLASS", enabled: true },
				{ column: "IDENTIFIER", enabled: true },
				{ column: "INSTRUMENT_NAME", enabled: true },
				{ column: "MICRO_ASSET_CLASS", enabled: true },
				{ column: "SCORE", enabled: true },
				{ column: "WEIGHT", enabled: true },
			],
			proposalColumnPreferences: [
				{ column: "ASSET_CLASS", enabled: true },
				{ column: "IDENTIFIER", enabled: true },
				{ column: "INSTRUMENT_NAME", enabled: true },
				{ column: "MICRO_ASSET_CLASS", enabled: true },
				{ column: "SCORE", enabled: true },
				{ column: "CURRENT_WEIGHT", enabled: true },
				{ column: "ENHANCED_WEIGHT", enabled: true },
				{ column: "DIFFERENCE", enabled: true },
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
			MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY: true,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: false,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: false,
			CURRENCY: false,
			TAG: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAttribution",
			FROM_INCEPTION: false,
			YEAR_TO_DATE: true,
			ONE_MONTH: false,
			THREE_MONTHS: false,
			SIX_MONTHS: false,
			ONE_YEAR: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAndVolatilityContribution",
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			content: "",
		},
	],
	proposal: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				// HISTORICAL_VAR_95_1Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
				HISTORICAL_VAR_95_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			// VOLATILITY_1Y: true, VOLATILITY_2Y: true,
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			summary: true,
			detailed: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				{ column: "ASSET_CLASS", enabled: true },
				{ column: "IDENTIFIER", enabled: true },
				{ column: "INSTRUMENT_NAME", enabled: true },
				{ column: "MICRO_ASSET_CLASS", enabled: true },
				{ column: "SCORE", enabled: true },
				{ column: "WEIGHT", enabled: true },
			],
			proposalColumnPreferences: [
				{ column: "ASSET_CLASS", enabled: true },
				{ column: "IDENTIFIER", enabled: true },
				{ column: "INSTRUMENT_NAME", enabled: true },
				{ column: "MICRO_ASSET_CLASS", enabled: true },
				{ column: "SCORE", enabled: true },
				{ column: "CURRENT_WEIGHT", enabled: true },
				{ column: "ENHANCED_WEIGHT", enabled: true },
				{ column: "DIFFERENCE", enabled: true },
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
			MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: false,
			MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY: false,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: false,
			CURRENCY: false,
			TAG: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			content: "",
		},
	],
};

export const defaultPortfolioTemplates: Array<Omit<DefaultReportTemplate, "visible">> = [
	{
		id: DefaultReportTemplateName.sphere,
		templateName: "Sphere Template",
		format: "pdf",
		data: {
			version: 1,
			status: "ready",
			templateItemsByVariant: sphereTemplateDefault,
			templateDraftItemsByVariant: sphereTemplateDefault,
		},
	},
];

export function isDefaultReportTemplate(
	obj: InvestmentsReportTemplate | DefaultReportTemplate,
): obj is DefaultReportTemplate {
	return !("uuid" in obj);
}
