import type { IUser } from "$root/functional-areas/user";

const initHotjar = (HotjarId: number, HotjarSv: number, iubendaTag: string, bypassIubenda: boolean): void => {
	const HotjarScript = document.createElement("script");
	HotjarScript.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HotjarId},hjsv:${HotjarSv}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

	if (!bypassIubenda) {
		HotjarScript.type = "text/plain";
		HotjarScript.setAttribute("class", "_iub_cs_activate-inline");
		HotjarScript.setAttribute("data-iub-purposes", `${iubendaTag}`);
	} else {
		HotjarScript.type = "application/javascript";
	}
	document.head.appendChild(HotjarScript);
};

export const initHotjarCUser = (user: IUser): void => {
	if ((window as any).hj && typeof (window as any).hj === "function") {
		(window as any).hj("identify", user.email, { email: user.email, isImpersonating: user.impersonating });
	}
};

export default initHotjar;
