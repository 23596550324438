import { PdfControllerApiFactory } from "$root/api/api-gen";
import { makeAuthController } from "$root/api/factory";
import env from "$root/env";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadBlob } from "$root/utils/files";
import { useUserStore } from "./user";

export async function downloadPdf(pdfRelativeUrl: string, title: string): Promise<void> {
	const pdfV3Api = makeAuthController({
		controllerFactory: PdfControllerApiFactory,
		token: useUserStore.getState().value.token,
	});

	const pageUrl = encodeURIComponent(`${env.appOrigin}/login?from=${pdfRelativeUrl}`);
	const pdf = await axiosExtract(
		pdfV3Api.generateInvestmentsPdf(pageUrl, "TPLPortraitNoMargin", title, { responseType: "blob" }),
	);
	downloadBlob(new Blob([pdf]), { fileName: `${title}.pdf` });
}
