import type { CSSProperties, ContextType, DependencyList, ReactNode } from "react";
import type React from "react";
import { createContext, useContext, useEffect } from "react";

export type WidgetAlert = {
	variant: "warning" | "info";
	shortTitle?: React.ReactNode;
	title?: React.ReactNode;
	content?: React.ReactNode;
};

export type WidgetOptions = {
	actionHeader?: (() => React.ReactNode) | React.ReactNode;
	title: React.ReactNode;
	subTitle?: React.ReactNode;
	isDraggable?: boolean;
	style?: CSSProperties;
	alerts?: WidgetAlert[];
	hideAlertsNumber?: boolean;
	alertsActive?: boolean;
	alertsFootnote?: ReactNode;
};

export const WidgetBlockContext = createContext({
	setWidgetOptions(_opts: WidgetOptions): void {
		// noop
	},
});

export const GridBlockContext = createContext<{ blockWidth: number; setBlockWidth(newWidth: number): void } | null>(
	null,
);

export function useGridBlock(): ContextType<typeof GridBlockContext> {
	return useContext(GridBlockContext);
}

export function useWidgetOptions(optsProvider: () => WidgetOptions, deps: DependencyList): void {
	const { setWidgetOptions } = useContext(WidgetBlockContext);
	useEffect(() => {
		setWidgetOptions(optsProvider());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setWidgetOptions, ...deps]);
}
