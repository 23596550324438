import "react-toastify/dist/ReactToastify.css";
import "./toast-overrides.css";
import { toast, ToastContainer } from "react-toastify";
import type { CircularProgressBarProps, IconName, MessageSeverity } from "@mdotm/mdotui/components";
import { Button, CircularProgressBar, Text } from "@mdotm/mdotui/components";
import { Icon } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { renderNodeOrFn, type NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { useState, type CSSProperties, useCallback } from "react";
import useAnimationFrame from "$root/hooks/useAnimationFrame";

export function ToastMountPoint(): JSX.Element {
	return <ToastContainer />;
}

export type PlatformToastParams = {
	icon: IconName;
	severity: MessageSeverity;
	children: NodeOrFn;
	/** @default 5000 */
	autoClose?: number | false;
	/** @default true */
	dismissible?: boolean;
	onClose?: <T = Record<string, unknown>>(props: T) => void;
};

const appearanceBySeverity: Record<
	MessageSeverity,
	{
		accentColor: string;
		circleColor: string;
		iconColor: string;
		textColor: string;
	}
> = {
	success: {
		accentColor: themeCSSVars.palette_P500,
		circleColor: themeCSSVars.palette_P200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	info: {
		accentColor: themeCSSVars.palette_S500,
		circleColor: themeCSSVars.palette_S200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	warning: {
		accentColor: themeCSSVars.palette_W500,
		circleColor: themeCSSVars.palette_W200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	error: {
		accentColor: themeCSSVars.palette_D500,
		circleColor: themeCSSVars.palette_D200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
};

export function ToastContent(props: { children: NodeOrFn; onClick?(): void }): JSX.Element {
	return (
		<Button onClick={props.onClick} unstyled classList="!m-0 !p-0">
			<Text type="Body/L/Book" as="div" classList="whitespace-pre-line text-left">
				{renderNodeOrFn(props.children)}
			</Text>
		</Button>
	);
}

export function platformToast({
	icon,
	severity,
	children,
	autoClose = 5000,
	dismissible = true,
	onClose,
}: PlatformToastParams): ReturnType<typeof toast> {
	return toast(typeof children === "string" ? <Text type="Body/L/Bold">{children}</Text> : renderNodeOrFn(children), {
		className: `border border-[${themeCSSVars.palette_N200}]`,
		bodyClassName:
			"before:block before:absolute before:inset-y-0 before:left-0 before:border-2 before:border-[color:var(--platform-toast-accent-color)]",
		bodyStyle: {
			"--platform-toast-accent-color": appearanceBySeverity[severity].accentColor,
			"--platform-toast-icon-color": appearanceBySeverity[severity].iconColor,
			color: appearanceBySeverity[severity].textColor,
		} as CSSProperties,
		autoClose,
		hideProgressBar: true,
		type: severity,
		onClose,
		closeButton: dismissible,
		icon: () => (
			<div className="w-[32px] h-[32px] min-w-[32px] min-h-[32px] relative">
				{typeof autoClose === "number" && (
					<div className="absolute z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
						<CircularProgressBarThatFollowsTheDefaultBar
							accentColor={appearanceBySeverity[severity].circleColor}
							innerDiameter={34}
							outerDiameter={42}
						/>
					</div>
				)}
				<div className="absolute z-10 w-[32px] h-[32px] min-w-[32px] min-h-[32px] bg-[var(--platform-toast-accent-color)] border-white text-[var(--platform-toast-icon-color)] rounded-full">
					<Icon icon={icon} size={20} classList="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			</div>
		),
	});
}

function CircularProgressBarThatFollowsTheDefaultBar({
	...forward
}: Omit<CircularProgressBarProps, "value" | "innerRef">) {
	const [elements, _setElements] = useState<{
		toastEl: HTMLElement;
		progressBarEl: HTMLElement;
	} | null>(null);
	const setElements = useCallback((el: SVGSVGElement | null) => {
		if (!el) {
			return null;
		}
		const toastEl = el.closest<HTMLElement>(".Toastify__toast");
		if (!toastEl) {
			return null;
		}
		const progressBarEl = toastEl.querySelector<HTMLElement>('[role="progressbar"]');
		if (!progressBarEl) {
			return null;
		}
		_setElements({ toastEl, progressBarEl });
	}, []);
	const [value, setValue] = useState(1);
	// It's not the prettiest solution, but I haven't found a way of getting the current progress value from react-toastify. Feel free to improve this code
	useAnimationFrame(
		() => {
			if (!elements) {
				return;
			}
			setValue(elements.progressBarEl.getBoundingClientRect().width / elements.toastEl.offsetWidth);
		},
		{ pause: !elements || value === 0 },
	);
	return (
		<CircularProgressBar {...forward} classList="[&>circle]:transition-none" innerRef={setElements} value={value} />
	);
}
