import type { MarketScenario } from "$root/api/api-gen";
import type { CommonStepProps, StepMetadata } from "$root/components/MultiStepFormV2/MultiStepFormV2";
import { defaultInitialStepMetadata } from "$root/components/MultiStepFormV2/utils";
import type { EditMulti_MultiStepPortfolioContext, EditPortfolioMultiArea } from "./EditPortfolioMulti";

export const editPortfolioMultiV4OrderedSteps = ["marketView"] as const;
export type EditPortfolioMultiV4StepName = (typeof editPortfolioMultiV4OrderedSteps)[number];

export const defaultEditPortfolioMultiV4StepData = {
	marketView: {
		scenarioIdentifier: undefined as string | undefined,
		scenarioData: undefined as MarketScenario | undefined,
		custom: undefined as boolean | undefined,
		name: undefined as string | undefined,
	},
} satisfies Record<EditPortfolioMultiV4StepName, unknown>;

const commonMetadata = {
	marketView: {
		...defaultInitialStepMetadata(),
		name: "Market View",
		optional: false,
	},
};

export const editPortfolioMultiV4InitialStepsMetadataByMode: Record<
	EditPortfolioMultiArea,
	Record<EditPortfolioMultiV4StepName, StepMetadata>
> = {
	"create-bulk-proposal": commonMetadata,
	"edit-bulk-settings": commonMetadata,
};

export type EditPortfolioMultiV4StepPayloadMap = {
	[K in keyof typeof defaultEditPortfolioMultiV4StepData]: (typeof defaultEditPortfolioMultiV4StepData)[K];
};

export const enumInstrumentWithouthScorePolicy = {
	Exclude: "EXCLUDE",
	IncludeWithZero: "INCLUDE_WITH_ZERO_AS_SCORE",
} as const;

export type InstrumentWithouthScorePolicy =
	(typeof enumInstrumentWithouthScorePolicy)[keyof typeof enumInstrumentWithouthScorePolicy];

// #endregion

export type StepPropsFor<T extends EditPortfolioMultiV4StepName> = CommonStepProps<
	typeof defaultEditPortfolioMultiV4StepData,
	T,
	EditMulti_MultiStepPortfolioContext
>;

export type StepMultiPropsFor<T extends EditPortfolioMultiV4StepName> = CommonStepProps<
	typeof defaultEditPortfolioMultiV4StepData,
	T,
	EditMulti_MultiStepPortfolioContext
>;
