import type { FlexibleExpectedReturnsVolatilityAssetClass, Regimes } from "$root/api/api-gen/api";
import type { NonUndefined } from "react-hook-form";
import type { MarketViewCompleteType } from ".";

export const round2Dec = (value: number): number => {
	const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
	return rounded;
};
// round by 5
export const roundCustom = (value: number): number => {
	const roundValue = 1;
	const absValue = Math.abs(value);
	const rounded = Math.ceil(absValue / roundValue + Number.EPSILON) * roundValue;
	return value > 0 ? rounded : rounded * -1;
};

const calculateRegime = (rA: number, rB: number) => (rA * rB) / 100;

export const regimeUtility = (rA?: number, rB?: number, rC?: number): { a: number; b: number; c: number } => ({
	a: rA ?? 0,
	b: rB ?? 0,
	c: rC ?? 0,
});

export const applyDeltaToSelectedMarketView = (
	regimeProbabilities: Regimes,
	assetClass?: Array<FlexibleExpectedReturnsVolatilityAssetClass>,
): Array<FlexibleExpectedReturnsVolatilityAssetClass> => {
	if (!assetClass) {
		return [];
	}
	const { a, b, c } = regimeProbabilities;
	// const keysOfAssetClass = assetClass.flatMap((x) => (x.microAssetClass ? [x.microAssetClass] : []));

	const ASSET_CLASS_MAP = assetClass?.map((flexibleAssetClass) => {
		const {
			expectedReturnsDefaults: eR,
			expectedVolatilityDefaults: eV,
			expectedVolatilityUserDelta,
			expectedReturnsUserDelta,
		} = flexibleAssetClass ?? {};

		const { a: regimeA, b: regimeB, c: regimeC } = regimeUtility(a, b, c);
		const { a: eReturnA, b: eReturnB, c: eReturnC } = regimeUtility(eR?.a, eR?.b, eR?.c);
		const { a: eVolatilityA, b: eVolatilityB, c: eVolatilityC } = regimeUtility(eV?.a, eV?.b, eV?.c);
		const deltaReturns = expectedReturnsUserDelta ?? 0;
		const deltaVolatility = expectedVolatilityUserDelta ?? 0;

		const expectedReturn =
			calculateRegime(eReturnA, regimeA) +
			calculateRegime(eReturnB, regimeB) +
			calculateRegime(eReturnC, regimeC) +
			deltaReturns;

		const expectedVolatility =
			calculateRegime(eVolatilityA, regimeA) +
			calculateRegime(eVolatilityB, regimeB) +
			calculateRegime(eVolatilityC, regimeC) +
			deltaVolatility;

		return {
			...flexibleAssetClass,
			expectedReturnsUserDelta: round2Dec(expectedReturn),
			expectedVolatilityUserDelta: round2Dec(expectedVolatility),
		};
	});
	return ASSET_CLASS_MAP;
};

export const removeDeltaFromSelectedMarketView = (
	deltaMarketView: Array<FlexibleExpectedReturnsVolatilityAssetClass>,
	regimesMarketView: Array<FlexibleExpectedReturnsVolatilityAssetClass>,
	principalRegimes: MarketViewCompleteType["principalRegimes"],
): {
	edited: boolean;
	lastEdit: string;
	flexibleExpectedReturnsVolatility: Array<FlexibleExpectedReturnsVolatilityAssetClass>;
} => {
	const originalAssetClasses = regimesMarketView;

	const { a, b, c } = principalRegimes;
	const { a: principalRA, b: principalRB, c: principalRC } = regimeUtility(a, b, c);

	const newAssetClasses = originalAssetClasses.map((originalFlexibleAssetClass) => {
		const currentOriginalAC = originalFlexibleAssetClass;
		const correlatedMVDeltaAC = deltaMarketView.find(
			(x) => x.microAssetClass === originalFlexibleAssetClass.microAssetClass,
		)!;

		const { expectedReturnsDefaults: eR, expectedVolatilityDefaults: eV } = currentOriginalAC ?? {}; // Regimes Tilt
		const { expectedReturnsUserDelta: eRd, expectedVolatilityUserDelta: eVd } = correlatedMVDeltaAC ?? {}; //Tilt

		const { a: eReturnA, b: eReturnB, c: eReturnC } = regimeUtility(eR?.a, eR?.b, eR?.c);
		const { a: eVolatilityA, b: eVolatilityB, c: eVolatilityC } = regimeUtility(eV?.a, eV?.b, eV?.c);

		const totalERRegimes =
			calculateRegime(eReturnA, principalRA) +
			calculateRegime(eReturnB, principalRB) +
			calculateRegime(eReturnC, principalRC);

		const totalEVRegimes =
			calculateRegime(eVolatilityA, principalRA) +
			calculateRegime(eVolatilityB, principalRB) +
			calculateRegime(eVolatilityC, principalRC);

		const expectedReturnsUserDelta = (eRd ?? 0) - totalERRegimes;
		const expectedVolatilityUserDelta = (eVd ?? 0) - totalEVRegimes;

		return {
			...currentOriginalAC,
			expectedReturnsUserDelta: round2Dec(expectedReturnsUserDelta),
			expectedVolatilityUserDelta: round2Dec(expectedVolatilityUserDelta),
			commentary: correlatedMVDeltaAC?.commentary,
		};
	});

	return {
		edited: true,
		lastEdit: "",
		flexibleExpectedReturnsVolatility: newAssetClasses,
	};
};

export function extractData<T, K extends keyof T>(data: T, field: K): NonUndefined<T[K]> {
	const selectedProperties = data[field];
	if (selectedProperties === undefined) {
		throw Error(`missing property ${String(field)} from scenario`, { cause: data });
	}
	return selectedProperties as NonUndefined<T[K]>;
}
