import { PaletteColors } from "$root/styles/themePalette";
import type { CSSProperties } from "react";
import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";

export type DashedRectangleProps = {
	color?: string;
	style?: CSSProperties;
	viewBox?: SvgProps["viewBox"];
	className?: string;
	variant?: "vertical" | "horizontal";
};

const defaultProps = {
	color: PaletteColors.BLUEY_GREY,
	variant: "horizontal",
	style: {
		color: PaletteColors.BLUEY_GREY,
		fontSize: 20,
		width: 15,
		height: 5,
	},
	viewBoxVertical: { width: 5, height: 15 },
	viewBoxHorizontal: { width: 15, height: 5 },
};

const DashedRectangle: React.FC<DashedRectangleProps> = ({
	viewBox,
	className,
	color = defaultProps.color,
	variant = defaultProps.variant,
	style = defaultProps.style,
}) => {
	return (
		<Svg
			viewBox={viewBox ?? (variant === "horizontal" ? defaultProps.viewBoxHorizontal : defaultProps.viewBoxVertical)}
			style={{
				...style,
				color,
				width: variant === "horizontal" ? "0.9375rem" : "0.3125rem",
				height: variant === "horizontal" ? "0.3125rem" : "0.9375rem",
			}}
			classList={className}
		>
			{variant === "horizontal" ? (
				<path
					d="M2 2.5h15"
					stroke={color}
					strokeWidth="4"
					fill="none"
					fillRule="evenodd"
					strokeDasharray="2,6"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			) : (
				<path
					d="M2 2.5v15"
					stroke={color}
					strokeWidth="4"
					fill="none"
					fillRule="evenodd"
					strokeDasharray="2,6"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			)}
		</Svg>
	);
};

export default DashedRectangle;
