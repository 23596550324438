import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import useInvestmentHorizonSetup from "$root/components/Portfolio/common_hooks/useInvestmentHorizonSetup";
import { formatNumber } from "$root/localization/formatters";
import { Text } from "@mdotm/mdotui/components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";
import { toClassName } from "@mdotm/mdotui/react-extensions";

type SummaryProps =
	| UnionMapData["portfolio-details"]
	| UnionMapData["portfolio-enhanced"]
	| UnionMapData["portfolio-reference"];

const SummaryBlock: FC<SummaryProps> = ({ portfolio }) => {
	const {
		name,
		primaryBenchmarkName,
		primaryBenchmarkType,
		primaryBenchmarkIdentifier,
		universeName,
		selectedTrackingErrorConstraint,
		baseCurrency,
		scoreValue,
		scoreIdentifier,
	} = portfolio;
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-5 gap-4 p-4 bg-[#F7F8F9] rounded">
			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.NAME")}
				</Text>
				<Text
					type="Body/M/Book"
					as="div"
					classList="max-w-[200px] truncate"
					data-title={name ?? "-"}
					title={name ?? "-"}
				>
					{name ?? "-"}
				</Text>
			</div>
			{/* <div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.TYPE")}
				</Text>
				<Text type="Body/M/Book" as="div" data-title="Multi Asset" title="Multi Asset">
					Multi Asset
				</Text>
			</div> */}
			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.BASE_CURRENCY")}
				</Text>
				<Text
					type="Body/M/Book"
					as="div"
					classList="max-w-[200px] truncate"
					data-title={name ?? "-"}
					title={name ?? "-"}
				>
					{baseCurrency ?? "-"}
				</Text>
			</div>
			<div className="col-span-3">
				<div className="block mt-auto">
					<div className="block mt-auto">
						<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
							{t("INVESTMENT_SUMMARY.BENCHMARK")}
						</Text>
						<Text
							type="Body/M/Book"
							as="div"
							data-title={t("INVESTMENT_SUMMARY.BENCHMARK")}
							title={t("INVESTMENT_SUMMARY.BENCHMARK")}
							classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
						>
							{primaryBenchmarkIdentifier ? primaryBenchmarkName : ""}
						</Text>
					</div>
				</div>
			</div>

			{/* --------- */}

			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.UNIVERSE")}
				</Text>
				<Text
					type="Body/M/Book"
					as="div"
					data-title={primaryBenchmarkType ?? "-"}
					title={primaryBenchmarkType ?? "-"}
					classList={{
						"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold":
							Boolean(universeName),
					}}
				>
					{universeName ?? t("INVESTMENT_SUMMARY.NO_UNIVERSE")}
				</Text>
			</div>

			<div
				className={toClassName({
					"col-span-2": selectedTrackingErrorConstraint?.investmentReference?.name !== undefined,
				})}
			>
				<div className="block mt-auto">
					<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
						{t("INVESTMENT_SUMMARY.REFERENCE")}
					</Text>
					<Text
						type="Body/M/Book"
						as="div"
						data-title={t("INVESTMENT_SUMMARY.REFERENCE")}
						title={t("INVESTMENT_SUMMARY.REFERENCE")}
						classList={{
							"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold": Boolean(
								selectedTrackingErrorConstraint,
							),
						}}
					>
						{selectedTrackingErrorConstraint?.investmentReference?.name || t("INVESTMENT_SUMMARY.NO_REFERENCE")}
					</Text>
				</div>
			</div>
			<AuthorizationGuard requiredService="CUSTOM_QUALITIES">
				{() => (
					<div className="col-span-2">
						<div className="block mt-auto">
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("AVERAGE_SCORE")}
							</Text>
							<Text
								type="Body/M/Book"
								as="div"
								data-title={t("AVERAGE_SCORE")}
								title={t("AVERAGE_SCORE")}
								classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
							>
								{scoreIdentifier ? (
									<>
										(<CustomLabels labelKey={scoreIdentifier ?? "-"} fallback="SCORE" />){" "}
										{scoreValue ? formatNumber(scoreValue) : ""}
									</>
								) : (
									"None"
								)}
							</Text>
						</div>
					</div>
				)}
			</AuthorizationGuard>
		</div>
	);
};

const ReferenceSummaryBlock: FC<SummaryProps> = ({ portfolio }) => {
	const { name, forecastHorizon } = portfolio;
	const { t } = useTranslation();

	const { getInvestmentHorizonLabels } = useInvestmentHorizonSetup();
	const investmentHorizon = getInvestmentHorizonLabels("multiAsset").find((x) => x.sliderLabel === forecastHorizon)
		?.typography; //[CORE] has any sense ?

	return (
		<div className="flex space-x-4 p-4 bg-[#F7F8F9] rounded">
			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.NAME")}
				</Text>
				<Text
					type="Body/M/Book"
					as="div"
					classList="max-w-[200px] truncate"
					data-title={name ?? "-"}
					title={name ?? "-"}
				>
					{name ?? "-"}
				</Text>
			</div>
			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.TYPE")}
				</Text>
				<Text type="Body/M/Book" as="div" data-title="Multi Asset" title="Multi Asset">
					Multi Asset
				</Text>
			</div>
			<div className="block mt-auto">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)" as="div">
					{t("INVESTMENT_SUMMARY.PORTFOLIO_TYPE")}
				</Text>
				<Text
					type="Body/M/Bold"
					data-title={t("INVESTMENT_SUMMARY.TYPE_REFERENCE")}
					title={t("INVESTMENT_SUMMARY.TYPE_REFERENCE")}
					classList="underline"
				>
					{/* {t("INVESTMENT_SUMMARY.TYPE_REFERENCE")} */}
					Target portfolio
				</Text>
			</div>
			<div className="block mt-auto col-span-2">
				<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
					{t("INVESTMENT_SUMMARY.INVESTMENT_HORIZON")}
				</Text>
				<Text
					type="Body/M/Book"
					as="div"
					classList="truncate whitespace-nowrap"
					data-title={name ?? "-"}
					title={name ?? "-"}
				>
					{investmentHorizon ?? "-"}
				</Text>
			</div>
			{/* --------- */}
		</div>
	);
};

function Summary({ data }: PrintableProps<{ data: SummaryProps }, number>): JSX.Element {
	const { portfolio } = data;

	if (!portfolio) {
		return <></>;
	}
	if (portfolio.reference) {
		return (
			<Card>
				<ReferenceSummaryBlock {...data} />
			</Card>
		);
	}

	return (
		<Card>
			<SummaryBlock {...data} />{" "}
		</Card>
	);
}

export default Summary;
