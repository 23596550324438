import { debugValue } from "@mdotm/mdotui/utils";

function hexToRGB(hex: string, alpha: number) {
	var r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
	} else {
		return "rgb(" + r + ", " + g + ", " + b + ")";
	}
}

function adjust(color: string, amount: number) {
	return (
		"#" +
		color
			.replace(/^#/, "")
			.replace(/../g, (color) =>
				("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).slice(-2),
			)
	);
}

function getRandomColor() {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

export default function colorGenerator(
	index: number,
	colors: string[] = [
		"#00AEEF",
		"#C63450",
		"#F26D2D",
		"#007CB7",
		"#FFBF00",
		"#EA401F",
		"#3C51B5",
		"#4CB09C",
		"#F46782",
		"#8C8EA8",
	],
): string {
	let generatedColor = "#000";
	const shadowLevels = 4;
	const maxShadow = 80;
	// const colorDef = [
	// 	"#00AEEF",
	// 	"#C63450",
	// 	"#F26D2D",
	// 	"#007CB7",
	// 	"#FFBF00",
	// 	"#EA401F",
	// 	"#3C51B5",
	// 	"#4CB09C",
	// 	"#F46782",
	// 	"#8C8EA8",
	// ];
	const colorLen = colors.length;

	if (index < colorLen * shadowLevels) {
		const colorLevel = Math.floor(index / colorLen);
		const colorIndex = index - colorLevel * colorLen;
		generatedColor = adjust(colors[colorIndex], -(colorLevel * maxShadow) / shadowLevels);
	} else {
		generatedColor = getRandomColor();
	}

	return generatedColor;
}

const stableColorGeneratorMap = new Map</* name */ string, /* color */ string>([
	["EQUITY", "#00AEEF"],
	["FIXED-INCOME", "#FFBF00"],
	["FIXED_INCOME", "#FFBF00"],
	["COMMODITIES", "#F26D2D"],
	["ALTERNATIVE", "#C63450"],
	["MONEY-MARKET", "#3C51B5"],

	["Equity", "#00AEEF"],
	["Fixed Income", "#FFBF00"],
	["Commodities", "#F26D2D"],
	["Alternative", "#C63450"],
	["Money Market", "#3C51B5"],

	["equity", "#00AEEF"],
	["fixedIncome", "#FFBF00"],
	["commodities", "#F26D2D"],
	["alternative", "#C63450"],
	["moneyMarket", "#3C51B5"],
]);

const stableColorGeneratorColors = [
	"#00AEEF",
	"#C63450",
	"#F26D2D",
	"#007CB7",
	"#FFBF00",
	"#EA401F",
	"#3C51B5",
	"#4CB09C",
	"#F46782",
	"#8C8EA8",
];

export function stableColorGenerator(key: string, opacity?: number): string {
	const candidate = stableColorGeneratorMap.get(key);

	if (candidate) {
		return opacity ? hexToRGB(candidate, opacity) : candidate;
	}

	const generatedColor = colorGenerator(stableColorGeneratorMap.size, stableColorGeneratorColors);
	stableColorGeneratorMap.set(key, generatedColor);

	if (opacity !== undefined) {
		const [r, g, b] = generatedColor.replace(/[^\d,]/g, "").split(",");
		return `rgba(${r}, ${g}, ${b}, ${opacity}})`;
	}

	return generatedColor;
}
