import { ScrollWrapper, Text } from "@mdotm/mdotui/components";
import { HistorySectionIcon } from "./icons/HistorySectionIcon";
import { AIConversationsControllerApiFactory, type UserConversationsListResponse } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { reportPlatformError } from "$root/api/error-reporting";
import { HistoryBox } from "./boxes/HistoryBox";

export function SphereAIHistorySection({
	chatHistory,
	setCurrentChat,
	refetch,
}: {
	chatHistory: UserConversationsListResponse["conversations"];
	setCurrentChat: (chatId: string | null) => void;
	refetch: () => Promise<void>;
}): JSX.Element {
	const chatApi = useApiGen(AIConversationsControllerApiFactory);
	const crudFactory = (api: typeof chatApi, _refetch: () => Promise<void>) => {
		const deleteFn = async (conversationUuid?: string) => {
			try {
				if (!conversationUuid) {
					throw new Error("cannot delete conversationUuid of undefined");
				}
				await api.deleteConversation(conversationUuid);
				await refetch();
			} catch (error) {
				reportPlatformError(error, "ERROR", "ask-intelligence");
			}
		};

		const renameFn = async (name: string, conversationUuid?: string) => {
			try {
				if (!conversationUuid) {
					throw new Error("cannot delete conversationUuid of undefined");
				}
				await api.renameConversation(conversationUuid, name);
				await refetch();
			} catch (error) {
				reportPlatformError(error, "ERROR", "ask-intelligence");
			}
		};

		const setConversationAsFavouriteFn = async (isFavourite: boolean, conversationUuid?: string) => {
			try {
				if (!conversationUuid) {
					throw new Error("cannot delete conversationUuid of undefined");
				}
				await api.setConversationAsFavourite(conversationUuid, isFavourite);
				await refetch();
			} catch (error) {
				reportPlatformError(error, "ERROR", "ask-intelligence");
			}
		};

		return { deleteFn, renameFn, setConversationAsFavouriteFn };
	};

	const { deleteFn, renameFn, setConversationAsFavouriteFn } = crudFactory(chatApi, refetch);
	return (
		<div className="h-full w-full flex flex-col bg-white">
			<ScrollWrapper classList="flex-1">
				<div className="flex flex-col justify-center items-center mt-2">
					<HistorySectionIcon />
					<Text type="Title/S">History</Text>
				</div>
				<div className="space-y-4 pt-9 pb-4">
					{chatHistory && chatHistory.length > 0 ? (
						chatHistory.map((q, i) => (
							<HistoryBox
								message={q.name ?? ""}
								favourite={q.favourite}
								onToggleFavourite={async () => {
									if (q.favourite === undefined) {
										return;
									}
									await setConversationAsFavouriteFn(!q.favourite, q.uuid ?? "");
								}}
								onRestore={() => setCurrentChat(q.uuid || null)}
								onDelete={() => deleteFn(q.uuid)}
								onRename={(newName) => renameFn(newName, q.uuid)}
								key={q.uuid ?? i}
							/>
						))
					) : (
						<Text type="Body/L/Book" as="div" classList="px-2 mx-4 py-1.5">
							{`Looks like you haven't asked any questions yet. Get started with Ask Sphere AI!`}
						</Text>
					)}
				</div>
			</ScrollWrapper>
		</div>
	);
}
