import { PaletteColors } from "$root/styles/themePalette";
import deepMerge from "$root/utils/deepMerge";
import type { CSSProperties, FC } from "react";
import { Svg, type SvgProps } from "@mdotm/mdotui/components";

export type DonoutCircleProps = {
	color?: string;
	style?: CSSProperties;
	viewBox?: SvgProps["viewBox"];
	className?: string;
	variant?: "vertical" | "horizontal";
	fullcolor?: boolean;
};

const defaultProps = {
	style: {
		color: PaletteColors.SANTAS_GREY,
		fontSize: 20,
		width: 6,
		height: 6,
	},
	viewBox: { width: 6, height: 6 },
	fullcolor: false,
};

const DonoutCircle: FC<DonoutCircleProps> = (props) => {
	const actualProps = deepMerge([defaultProps, props]);
	delete actualProps.fullcolor;
	const fillColor = props.color || actualProps.style.color;

	return (
		<Svg {...actualProps}>
			<circle fill={fillColor} cx="3" cy="3" r="3" />
			{!props.fullcolor && <circle fill="#fff" cx="3" cy="3" r="1.5" />}
		</Svg>
	);
};

export default DonoutCircle;
