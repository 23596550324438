import Commentary, { getCommentaryProps } from "../components/portfolio/Commentary";
import Composition from "../components/portfolio/Composition";
import ExAnteMetrics from "../components/portfolio/ExAnteMetrics";
import ExAnteVolatilityContribution, {
	getExAnteVolatilityContributionSplittableProps,
} from "../components/portfolio/ExAnteVolatilityContribution";
import Exposure, { exposureEntriesToSplittableProps } from "../components/portfolio/Exposure";
import ExposureFactors, { getExposureFactorsProps } from "../components/portfolio/ExposureFactors";
import PortfolioPerformance from "../components/portfolio/Performance";
import PerformanceAttribution from "../components/portfolio/PerformanceAttribution";
import PerformanceMetrics, { getPerformanceMetricsProps } from "../components/portfolio/PerformanceMetrics";

import { builtInSortFnFor, chunk } from "@mdotm/mdotui/utils";
import { match } from "ts-pattern";
import type { ComponentAndPropsPair } from ".";
import { type ComponentAndPropsPairPartial, type PrintableComponent } from ".";
import OutlookTables, { getOutlookTableProps } from "../components/market/OutlookTables";
import Box from "../components/mock/Box";
import AssetClassOverview from "../components/portfolio/AssetClassOverview";
import ExposureCompare, { exposureCompareEntriesToSplittableProps } from "../components/portfolio/ExposureCompare";
import PerformanceVolatilityContribution, {
	getPerformanceVolatilityContributionProps,
} from "../components/portfolio/PerformanceVolatilityContribution";
import Summary from "../components/portfolio/Summary";
import type { CustomReportDataUnion, UnionMapData } from "./hooks/useExtractReports";
import type { InvestmentExposureResponseExposureTypeEnum } from "$root/api/api-gen";
import ExposureMixed, { exposureMixedEntriesToSplittableProps } from "../components/portfolio/ExposureMixed";
import {
	exposureAvailableItemSlotsByChartHeight,
	exposureChartHeight,
	exposureMixedChartHeight,
} from "../components/portfolio/exposure-shared";

export type UnionMapDataKeys = keyof UnionMapData;
function jsxHelper<
	T extends [ComponentAndPropsPairPartial<object, unknown>, ...ComponentAndPropsPairPartial<object, unknown>[]],
>(params: {
	[K in keyof T]: T[K] & {
		component: T[K] extends ComponentAndPropsPairPartial<infer TCommon, infer TSplittable>
			? PrintableComponent<TCommon, TSplittable>
			: never;
	};
}): T {
	return params.map((item) => ({
		...item,
		component: (props: any) => {
			const C = item.component;
			return <C {...props} />;
		},
	})) as unknown as T;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const templateMaps = (props: CustomReportDataUnion & { layout: "portrait" | "landscape" }) => {
	return match(props)
		.with({ template: "portfolio-details" }, ({ payload: data }) => {
			// Commentary
			const commentary = getCommentaryProps(data.commentary);

			//exAnteVolatilityContribution
			const exAnteVolatilityContributionMacroVsMicro = getExAnteVolatilityContributionSplittableProps(data, {
				selector: "assetClass",
				vsSelector: "microAssetClass",
			});

			const exAnteVolatilityContributionMacroVsGeo = getExAnteVolatilityContributionSplittableProps(data, {
				selector: "assetClass",
				vsSelector: "geography",
			});

			//performanceVolatilityContribution
			const performanceVolatilityContributionMacroVsMicro = getPerformanceVolatilityContributionProps(data, {
				selector: "assetClass",
				vsSelector: "microAssetClass",
			});

			const performanceVolatilityContributionMacroVsGeo = getPerformanceVolatilityContributionProps(data, {
				selector: "assetClass",
				vsSelector: "geography",
			});

			//Exposure factor
			const exposureFactorsData = getExposureFactorsProps(data);

			const componentAndPropsList = jsxHelper([
				{
					// TODO: fix type issue
					// @ts-ignore
					component: Summary,
					commonProps: { data },
					splittableProps: [],
				},
				{
					component: PerformanceMetrics,
					commonProps: { data },
					splittableProps: getPerformanceMetricsProps(data),
				},
				{
					component: ExAnteMetrics,
					commonProps: { data },
					splittableProps: [],
				},
				{
					component: PortfolioPerformance,
					commonProps: { data },
					splittableProps: [],
				},
				{
					component: Commentary,
					commonProps: { data },
					splittableProps: commentary,
					startsFromBlankPage: !!commentary.length,
				},
				{
					component: Composition,
					commonProps: { data },
					splittableProps: data.investmentComposition.composition?.sort(builtInSortFnFor("weight", "desc")) ?? [],
				},
				{
					component: Exposure,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsMicro.investmentComposition ?? [],
					},
					splittableProps: (() => {
						const all = exposureEntriesToSplittableProps(data.exposure.macroVsMicro.investmentComposition ?? []);
						return [all.slice(0, exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout))].concat(
							chunk(
								all.slice(exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout)),
								exposureAvailableItemSlotsByChartHeight(0, props.layout),
							),
						);
					})(),
					startsFromBlankPage: !!data.exposure.macroVsMicro.investmentComposition?.length,
				},
				{
					component: Exposure,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_GEOGRAPHY" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsGeo.investmentComposition ?? [],
					},
					splittableProps: (() => {
						const all = exposureEntriesToSplittableProps(data.exposure.macroVsGeo.investmentComposition ?? []);
						return [all.slice(0, exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout))].concat(
							chunk(
								all.slice(exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout)),
								exposureAvailableItemSlotsByChartHeight(0, props.layout),
							),
						);
					})(),
					startsFromBlankPage: !!data.exposure.macroVsGeo.investmentComposition?.length,
				},
				{
					component: PerformanceAttribution,
					commonProps: { data },
					splittableProps: [],
					startsFromBlankPage: !!data.performanceAttribution.current?.length,
				},
				{
					component: ExAnteVolatilityContribution,
					commonProps: {
						data,
						comparison: exAnteVolatilityContributionMacroVsMicro.opts,
						graphLimit: exAnteVolatilityContributionMacroVsMicro.graphLimit,
						graphColumnMarkers: exAnteVolatilityContributionMacroVsMicro.graphColumnMarkers,
					},
					splittableProps: exAnteVolatilityContributionMacroVsMicro.list,
					startsFromBlankPage: !!exAnteVolatilityContributionMacroVsMicro.list,
				},
				{
					component: ExAnteVolatilityContribution,
					commonProps: {
						data,
						comparison: exAnteVolatilityContributionMacroVsGeo.opts,
						graphLimit: exAnteVolatilityContributionMacroVsGeo.graphLimit,
						graphColumnMarkers: exAnteVolatilityContributionMacroVsGeo.graphColumnMarkers,
					},
					splittableProps: exAnteVolatilityContributionMacroVsGeo.list,
					startsFromBlankPage: !!exAnteVolatilityContributionMacroVsGeo.list,
				},
				{
					component: PerformanceVolatilityContribution,
					commonProps: {
						data,
						comparison: { ...performanceVolatilityContributionMacroVsMicro.opts },
						graphLimit: performanceVolatilityContributionMacroVsMicro.graphLimit,
						graphColumnMarkers: performanceVolatilityContributionMacroVsMicro.graphColumnMarkers,
					},
					splittableProps: performanceVolatilityContributionMacroVsMicro.list,
					startsFromBlankPage: !!performanceVolatilityContributionMacroVsMicro.list.length,
				},
				{
					component: PerformanceVolatilityContribution,
					commonProps: {
						data,
						comparison: { ...performanceVolatilityContributionMacroVsGeo.opts },
						graphLimit: performanceVolatilityContributionMacroVsGeo.graphLimit,
						graphColumnMarkers: performanceVolatilityContributionMacroVsGeo.graphColumnMarkers,
					},
					splittableProps: performanceVolatilityContributionMacroVsGeo.list,
					startsFromBlankPage: !!performanceVolatilityContributionMacroVsGeo.list.length,
				},
				{
					component: ExposureFactors,
					commonProps: {
						data,
						graphLimits: exposureFactorsData.graphLimits,
						graphColumnMarkers: exposureFactorsData.graphColumnMarkers,
					},
					splittableProps: exposureFactorsData.list,
					startsFromBlankPage: !!exposureFactorsData.list.length,
				},
			]);
			return { componentAndPropsList };
		})
		.with({ template: "portfolio-enhanced" }, ({ payload: data }) => {
			// Commentary
			const commentary = getCommentaryProps(data.commentary);

			//exAnteVolatilityContribution
			const exAnteVolatilityContributionMacroVsMicro = getExAnteVolatilityContributionSplittableProps(data, {
				selector: "assetClass",
				vsSelector: "microAssetClass",
			});

			const exAnteVolatilityContributionMacroVsGeo = getExAnteVolatilityContributionSplittableProps(data, {
				selector: "assetClass",
				vsSelector: "geography",
			});

			//Exposure factor
			const exposureFactorsData = getExposureFactorsProps(data);
			const componentAndPropsList = jsxHelper([
				{
					// TODO: fix type issue
					// @ts-ignore
					component: Summary,
					commonProps: { data },
					splittableProps: [],
				},

				{
					component: ExAnteMetrics,
					commonProps: { data },
					splittableProps: [],
				},

				{
					component: Commentary,
					commonProps: { data },
					splittableProps: commentary,
					startsFromBlankPage: !!commentary.length,
				},
				{
					component: Composition,
					commonProps: { data },
					splittableProps: data.investmentComposition.composition?.sort(builtInSortFnFor("weight")).reverse() ?? [],
				},
				{
					//TODO: CHECK
					component: ExposureCompare,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsMicro.investmentComposition ?? [],
					},
					splittableProps: exposureCompareEntriesToSplittableProps(
						data.exposure.macroVsMicro.enhancementComposition ?? [],
						data.exposure.macroVsMicro.investmentComposition ?? [],
					),
					startsFromBlankPage:
						!!data.exposure.macroVsMicro.investmentComposition?.length ||
						!!data.exposure.macroVsMicro.enhancementComposition?.length,
				},
				{
					component: ExposureCompare,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_GEOGRAPHY" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsMicro.investmentComposition ?? [],
					},
					splittableProps: exposureCompareEntriesToSplittableProps(
						data.exposure.macroVsGeo.enhancementComposition ?? [],
						data.exposure.macroVsGeo.investmentComposition ?? [],
					),
					startsFromBlankPage:
						!!data.exposure.macroVsGeo.investmentComposition?.length ||
						!!data.exposure.macroVsGeo.enhancementComposition?.length,
				},
				{
					component: ExAnteVolatilityContribution,
					commonProps: {
						data,
						comparison: exAnteVolatilityContributionMacroVsMicro.opts,
						graphLimit: exAnteVolatilityContributionMacroVsMicro.graphLimit,
						graphColumnMarkers: exAnteVolatilityContributionMacroVsMicro.graphColumnMarkers,
					},
					splittableProps: exAnteVolatilityContributionMacroVsMicro.list,
					startsFromBlankPage: !!exAnteVolatilityContributionMacroVsMicro.list,
				},
				{
					component: ExAnteVolatilityContribution,
					commonProps: {
						data,
						comparison: exAnteVolatilityContributionMacroVsGeo.opts,
						graphLimit: exAnteVolatilityContributionMacroVsGeo.graphLimit,
						graphColumnMarkers: exAnteVolatilityContributionMacroVsGeo.graphColumnMarkers,
					},
					splittableProps: exAnteVolatilityContributionMacroVsGeo.list,
					startsFromBlankPage: !!exAnteVolatilityContributionMacroVsGeo.list,
				},
				{
					component: ExposureFactors,
					commonProps: {
						data,
						graphLimits: exposureFactorsData.graphLimits,
						graphColumnMarkers: exposureFactorsData.graphColumnMarkers,
					},
					splittableProps: exposureFactorsData.list,
					startsFromBlankPage: !!exposureFactorsData.list.length,
				},
			]);
			return { componentAndPropsList };
		})
		.with({ template: "portfolio-reference" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					// TODO: fix type issue
					// @ts-ignore
					component: Summary,
					commonProps: { data },
					splittableProps: [],
				},

				{
					component: Composition,
					commonProps: { data },
					splittableProps: data.investmentComposition.composition?.sort(builtInSortFnFor("weight")).reverse() ?? [],
				},
				{
					component: Exposure,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_GEOGRAPHY" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsGeo.investmentComposition ?? [],
					},
					splittableProps: (() => {
						const all = exposureEntriesToSplittableProps(data.exposure.macroVsGeo.investmentComposition ?? []);
						return [all.slice(0, exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout))].concat(
							chunk(
								all.slice(exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout)),
								exposureAvailableItemSlotsByChartHeight(0, props.layout),
							),
						);
					})(),
					startsFromBlankPage: !!data.exposure.macroVsGeo.investmentComposition?.length,
				},
				{
					component: Exposure,
					commonProps: {
						data,
						comparison: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" as InvestmentExposureResponseExposureTypeEnum,
						composition: data.exposure.macroVsMicro.investmentComposition ?? [],
					},
					splittableProps: (() => {
						const all = exposureEntriesToSplittableProps(data.exposure.macroVsGeo.investmentComposition ?? []);
						return [all.slice(0, exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout))].concat(
							chunk(
								all.slice(exposureAvailableItemSlotsByChartHeight(exposureChartHeight, props.layout)),
								exposureAvailableItemSlotsByChartHeight(0, props.layout),
							),
						);
					})(),
					startsFromBlankPage: !!data.exposure.macroVsMicro.investmentComposition?.length,
				},
			]);

			return { componentAndPropsList };
		})
		.with({ template: "custom-report" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: AssetClassOverview,
					commonProps: { data },
					splittableProps: [],
				},
				{
					component: OutlookTables,
					commonProps: { data, type: "doubleColumns" as const },
					splittableProps: getOutlookTableProps(data),
				},
			]);

			return { componentAndPropsList };
		})
		.with({ template: "mixed-portfolio" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper(
				data.sections.map((section) => {
					const all = exposureMixedEntriesToSplittableProps(section.exposureCompare.portfolioComposition ?? []);
					const splittableProps = section.supportsChart
						? [all.slice(0, exposureAvailableItemSlotsByChartHeight(exposureMixedChartHeight, props.layout))].concat(
								chunk(
									all.slice(exposureAvailableItemSlotsByChartHeight(exposureMixedChartHeight, props.layout)),
									exposureAvailableItemSlotsByChartHeight(0, props.layout),
								),
						  )
						: chunk(all, exposureAvailableItemSlotsByChartHeight(0, props.layout));

					return {
						component: ExposureMixed,
						commonProps: { data: section },
						splittableProps,
					};
				}) as any,
			);

			return { componentAndPropsList };
		})
		.with({ template: "mocked" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: Box,
					commonProps: { data },
					splittableProps: data.list,
				},
			]);

			return { componentAndPropsList };
		})
		.exhaustive() as unknown as {
		componentAndPropsList: [ComponentAndPropsPair<object, unknown>, ...ComponentAndPropsPair<object, unknown>[]];
	}; //TODO : why does the return doesn't match
};

// satisfies { [K in keyof UnionMapData]: (props: CustomReportBodyData<K>) => unknown };
