import type { AccessPermission, RichAccessControl, ServiceType } from "$root/api/api-gen";
import type { ACLPermissionChecker } from "$root/functional-areas/acl/checkers/shared";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUserValue } from "$root/functional-areas/user";
import type { IUser, UserRole } from "$root/functional-areas/user";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { stableEmptyArray } from "@mdotm/mdotui/utils";

export type AuthorizationRequirements = (
	| {
			requiredServices: ServiceType[];
			requiredParam?(user: IUser): boolean;
	  }
	| { requiredService?: ServiceType; requiredParam?(user: IUser): boolean }
) &
	(
		| {
				requiredRoles: UserRole[];
		  }
		| { requiredRole?: UserRole }
	) &
	(
		| ({
				acl: RichAccessControl[];
		  } & (
				| { requiredPermissions: AccessPermission[] }
				| { requiredPermission: AccessPermission }
				| { permissionCheckers: ACLPermissionChecker[] }
				| { permissionChecker: ACLPermissionChecker }
		  ))
		| {
				acl?: undefined;
				requiredPermissions?: undefined;
				requiredPermission?: undefined;
				permissionCheckers?: undefined;
				permissionChecker?: undefined;
		  }
	);

export type AuthorizationGuardProps = AuthorizationRequirements & {
	placeholder?: NodeOrFn;
	children: NodeOrFn;
};

export function hasAccess(user: IUser, requirements: AuthorizationRequirements): boolean {
	const requiredServices =
		("requiredServices" in requirements
			? requirements.requiredServices
			: requirements.requiredService && [requirements.requiredService]) || stableEmptyArray;

	const requiredRoles =
		("requiredRoles" in requirements
			? requirements.requiredRoles
			: requirements.requiredRole && [requirements.requiredRole]) || stableEmptyArray;

	const requiredPermissions =
		("requiredPermissions" in requirements
			? requirements.requiredPermissions
			: "requiredPermission" in requirements && requirements.requiredPermission && [requirements.requiredPermission]) ||
		stableEmptyArray;

	const permissionCheckers =
		("permissionCheckers" in requirements
			? requirements.permissionCheckers
			: "permissionChecker" in requirements && requirements.permissionChecker && [requirements.permissionChecker]) ||
		stableEmptyArray;

	return (
		requiredServices.every((required) => user.availableServices.includes(required)) &&
		(requirements.requiredParam?.(user) ?? true) &&
		requiredRoles.every((required) => user.roles.includes(required)) && // TODO: check permission is not linked to the user
		validateACLPermissions(user.id, requirements.acl || stableEmptyArray, requiredPermissions) &&
		permissionCheckers.every((checker) => checker(user.id, requirements.acl || stableEmptyArray))
	);
}

function AuthorizationGuard(props: AuthorizationGuardProps): JSX.Element {
	const user = useUserValue();

	const pass = hasAccess(user, props);

	return <>{pass ? renderNodeOrFn(props.children) : renderNodeOrFn(props.placeholder) ?? <></>}</>;
}

export default AuthorizationGuard;
