import { Icon, TextInput } from "@mdotm/mdotui/components";
import { useCallback, useState } from "react";
import { useDebounced } from "@mdotm/mdotui/react-extensions";

type DebouncedSearchInputProps = {
	query: string;
	onChange(debouncedQuery: string): void;
	timeout?: number;
	placeholder?: string;
};

export function DebouncedSearchInput(props: DebouncedSearchInputProps): JSX.Element {
	const [query, _setQuery] = useState(props.query);

	const { invoke: dispatchOnChange } = useDebounced((q: string) => props.onChange(q), {
		debounceInterval: props.timeout ?? 200,
	});

	const setQuery = useCallback(
		(q: string) => {
			_setQuery(q);
			dispatchOnChange(q);
		},
		[dispatchOnChange],
	);

	return (
		<TextInput
			leftContent={<Icon icon="Search" />}
			value={query}
			onChangeText={setQuery}
			placeholder={props.placeholder}
		/>
	);
}
