import type { MarketOutlookDTO, Chip } from "$root/api/api-gen";
import { MarketControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import DataSeriesModal from "$root/components/DataSeriesModal";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import DashedRectangle from "$root/components/icons/DashedRectangle";
import EllipsisText from "$root/ui-lib/ellipsisText";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import { PaletteColors } from "$root/styles/themePalette";
import type { TableColumn, OrderBy } from "@mdotm/mdotui/components";
import { CircularProgressBar, Table, TooltipContent } from "@mdotm/mdotui/components";
import { builtInSortFnFor } from "$root/utils/collections";
import type { ContextContent } from "$root/utils/react-extra";
import { ForEach, withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SpiderwebChart from "./CustomChart";
import { useEventBus } from "$root/event-bus";
import { noop } from "@mdotm/mdotui/utils";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import TimeSeriesModal from "$root/functional-areas/outlook/TimeSeriesModal";
import useWidgetsData from "$root/hooks/useWidgetsData";
import {
	composeOutlookLink,
	useDriverComposer,
	usePositionComposer,
} from "$root/functional-areas/outlook/useOutlookComposer";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { SentimentBadge } from "$root/functional-areas/market-view/analysis/SentimentBadge";
import type { SentimentType } from "$root/functional-areas/market-view/analysis/sentiment";
import { feelsConverter } from "./feels-converter";

type PositioningTableProps = {
	positioning: string;
	driver: Chip[];
	geo: string;
	sector: string;
	label: string;
	assetClass?: string;
	previousPositioning?: string;
};

type PositioningSpiderwebChartProps = {
	previous: number[];
	current: number[];
	benchmarks: number[];
	assetClasses: string[];
	dates: { [key: string]: Array<string | undefined> };
};

const Positioning = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter } = props;
	const { assetClass, geography } = useOutlookComposition(firstFilter, secondFilter, "");
	const { t } = useTranslation();
	const marketApi = useApiGen(MarketControllerApiFactory);
	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });
	const { TYPE_DICT } = useDriverComposer();
	const { positioningAreaByLabel, positioningAreaByValue } = usePositionComposer();

	//DTO

	const positioningTableHeader: Record<string, string> = useMemo(
		() => ({
			FI: t("OUTLOOK.TYPE"),
			CO: t("OUTLOOK.TYPE"),
			MARKETS: t("OUTLOOK.GEOGRAPHY"),
			EU: t("OUTLOOK.SECTOR"),
			US: t("OUTLOOK.SECTOR"),
		}),
		[t],
	);

	const { currentWidgetsData } = useWidgetsData();
	const dateRange = new Date(Date.parse(currentWidgetsData.creation_time.toString()));
	dateRange.setFullYear(dateRange.getFullYear() - 1);
	dateRange.setHours(0, 0, 0, 0);

	const { data: outlookTimeSeries } = useQueryNoRefetch(["getOutlookHistorycalTimeseries", assetClass, geography], {
		queryFn: async () => {
			const { data } = await marketApi.retrieveOutlookTimeSeriesContinuousByAssetClass(
				assetClass,
				geography,
				String(dateRange),
			);

			return data;
		},
	});

	const assetClassTimeSeries = useMemo(() => {
		if (!outlookTimeSeries) {
			return undefined;
		}

		return outlookTimeSeries.reduce<{ [key: string]: [number, number, boolean][] }>((acc, el) => {
			const { type, values, inverted } = el;
			if (!type || !values) {
				return acc;
			}

			acc[type] = values
				.filter(([_x, y]) => y ?? false)
				.map(([dateSeconds, y]) => [dateSeconds * 1000, y, inverted]) as [number, number, boolean][];
			return acc;
		}, {});
	}, [outlookTimeSeries]);

	// section: React Query
	const composePositioningTableData = useCallback(
		(data: MarketOutlookDTO[]) => {
			return data.reduce<PositioningTableProps[]>(
				(acc, { type, currentValue, driverLabel, geography: geographyCur, sector, prevValue }) => [
					...acc,
					{
						label: assetClassLabels[type as keyof typeof assetClassLabels] ?? "-",
						assetClass: type,
						positioning: currentValue?.value ? positioningAreaByValue[currentValue.value] : "-",
						previousPositioning: prevValue?.value ? positioningAreaByValue[prevValue?.value] : undefined,
						driver: driverLabel ?? [],
						geo: geographyCur ?? "",
						sector: sector ?? "",
					},
				],
				[],
			);
		},
		[assetClassLabels, positioningAreaByValue],
	);

	const composePositioningSpiderwebChart = useCallback(
		(data: MarketOutlookDTO[]) => {
			const { current, previous, benchmarks, assetClasses, dates } = data.reduce<PositioningSpiderwebChartProps>(
				(
					{
						previous: previousAcc,
						current: currentAcc,
						assetClasses: assetClassesAcc,
						benchmarks: benchmarksAcc,
						dates: datesAcc,
					},
					{ currentValue, prevValue, type },
				) => ({
					previous: [...previousAcc, (prevValue?.value || currentValue?.value) ?? 0],
					current: [...currentAcc, currentValue?.value ?? 0],
					assetClasses: [
						...assetClassesAcc,
						(assetClassLabels[type as keyof typeof assetClassLabels] ?? "-").toUpperCase(),
					],
					benchmarks: [...benchmarksAcc, 3],
					dates: {
						...datesAcc,
						[(assetClassLabels[type as keyof typeof assetClassLabels] ?? "-").toUpperCase()]: [
							currentValue?.data,
							prevValue?.data,
						],
					},
				}),
				{ previous: [], current: [], assetClasses: [], benchmarks: [], dates: {} },
			);

			return {
				series: [
					{
						name: "Current",
						data: current,
						pointPlacement: "on",
						color: PaletteColors.AZURE,
						marker: {
							enabled: false,
						},
						zIndex: 5,
					},
					{
						name: "Previous",
						data: previous,
						pointPlacement: "on",
						color: PaletteColors.BLUEY_GREY,
						marker: {
							enabled: false,
						},
					},
					{
						name: "Benchmark",
						data: benchmarks,
						pointPlacement: "on",
						color: PaletteColors.BLUEY_GREY,
						dashStyle: "Dash",
						marker: {
							enabled: false,
							states: {
								hover: {
									enabled: false,
								},
							},
						},
						opacity: 1,
					},
				],
				assetClasses,
				dates,
			};
		},
		[assetClassLabels],
	);

	const { data, isLoading, refetch } = useQueryNoRefetch(["overUnderWeightPositioning", firstFilter, secondFilter], {
		queryFn: async () => {
			const outlook = await axiosExtract(marketApi.retrieveOutlookByAssetClass(assetClass, geography));
			const rows = composePositioningTableData(outlook);
			const { series, assetClasses, dates } = composePositioningSpiderwebChart(outlook);

			return { rows, series, assetClasses, dates };
		},
		onError: (err) => console.warn(err),
	});

	const { assetClasses, series, rows, dates } = data ?? {};

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	const column = useMemo<TableColumn<PositioningTableProps>[]>(
		() => [
			{
				header: secondFilter ? positioningTableHeader[secondFilter] : positioningTableHeader[firstFilter],
				content: ({ label, sector, geo }) => {
					const to = composeOutlookLink(firstFilter, secondFilter, sector, geo);

					return (
						<Link to={to} className="font-semibold pl-2.5 underline w-full">
							<EllipsisText text={label} />
						</Link>
					);
				},
				relativeWidth: 3.2,
				sortFn: builtInSortFnFor("label"),
				name: "label",
			},
			{
				header: t("OUTLOOK.POSITIONING"),
				cellClassList: "w-full",
				content: (p) => {
					const { positioning, previousPositioning } = p;
					const positioningSentiment: Record<string, SentimentType> = {
						[t("STRONG_UNDERWEIGHT")]: "super-negative",
						[t("UNDERWEIGHT")]: "negative",
						[t("NEUTRAL")]: "neutral",
						[t("OVERWEIGHT")]: "positive",
						[t("S_OVERWEIGHT")]: "super-positive",
					};

					if (!positioningSentiment[positioning]) {
						return <></>;
					}
					const disabled =
						assetClassTimeSeries === undefined ||
						assetClassTimeSeries[p.assetClass ?? ""] === undefined ||
						assetClassTimeSeries[p.assetClass ?? ""].length === 0;

					return (
						<div className="flex justify-between items-center gap-2 grow min-w-0">
							<SentimentBadge sentiment={positioningSentiment[positioning]} indicator="positioning">
								{positioning}
							</SentimentBadge>
							<TimeSeriesModal
								assetClass={p.assetClass ?? ""}
								currentRegime={{ value: positioningAreaByLabel[positioning], label: positioning }}
								previousRegime={{
									value: previousPositioning
										? positioningAreaByLabel[previousPositioning]
										: positioningAreaByLabel[positioning],
									label: previousPositioning ?? "",
								}}
								series={assetClassTimeSeries}
								disabled={disabled}
							/>
						</div>
					);
				},
				relativeWidth: 2.4,
				sortFn: builtInSortFnFor("positioning"),
				name: "positioning",
			},
			{
				header: t("OUTLOOK.WHY"),
				content: ({ driver: drivers }) => (
					<div className="flex gap-0.5 min-w-0">
						<ForEach
							collection={drivers.flatMap((x) => {
								const feel = feelsConverter(x);
								return feel ? [feel!] : [];
							})}
						>
							{({ item }) => (
								<SentimentBadge sentiment={item.sentiment} indicator={item.indicator}>
									{item.label}
								</SentimentBadge>
							)}
						</ForEach>
					</div>
				),
				relativeWidth: 4.4,
			},
		],
		[secondFilter, positioningTableHeader, firstFilter, t, positioningAreaByLabel, assetClassTimeSeries],
	);

	// section: Widget context
	useWidgetOptions(
		() => ({
			actionHeader: (
				<div className="flex">
					<DataSeriesModal firstFilter={firstFilter} secondFilter={secondFilter} typeMode="OUTLOOK" />
					<InfoTooltip>
						<TooltipContent>
							<Trans
								t={t}
								i18nKey="POSITIONING_TOOLTIP"
								components={{
									s: (
										<>
											<br />
											<br />
										</>
									),
									l: (
										<button
											type="button"
											className="underline cursor-pointer font-bold"
											onClick={() => Intercom.showArticle("GeographicAndSectorIndicators")}
										>
											Button
										</button>
									),
								}}
							/>
						</TooltipContent>
					</InfoTooltip>
				</div>
			),
			title: t("POSITIONING"),
		}),
		[t, firstFilter, secondFilter],
	);

	return (
		<>
			<div className="grid grid-cols-5 h-full overflow-hidden">
				<div className="col-span-2 h-96 relative flex items-center justify-center">
					{isLoading ? (
						<div className="w-full flex items-center justify-center">
							<CircularProgressBar value="indeterminate" />
						</div>
					) : (
						<SpiderwebChart
							xAxis={assetClasses ?? []}
							series={series ?? []}
							representing={`${firstFilter}${secondFilter}`}
							notInTooltip={["Benchmark"]}
							valueToTooltip={TYPE_DICT}
							dates={dates ?? {}}
						/>
					)}
				</div>
				<div className="col-span-3 h-full">
					<Table
						columns={column}
						rows={rows ?? []}
						orderBy={defaultPositionOrderBy}
						visibleRows={9}
						noDataText={
							<div className="flex items-center justify-center h-20">
								<p className="!bg-transparent">No data available</p>
							</div>
						}
					/>
				</div>
			</div>
			<GraphLegend>
				<div className="legend-container">
					<ColoredRectangle /> {t("CURRENT")}
				</div>
				<div className="legend-container">
					<ColoredRectangle color={PaletteColors.BLUEY_GREY} /> {t("PREVIOUS")}
				</div>
				<div className="legend-container">
					<DashedRectangle /> {t("NEUTRAL")}
				</div>
			</GraphLegend>
		</>
	);
};

const defaultPositionOrderBy: OrderBy[] = [{ columnName: "label", direction: "asc" }];

export default withContext(UniverseFiltersContext)(Positioning);
