import type { FC } from "react";
import { useMemo } from "react";
import PositioninGraph from "$root/components/OutlookPositioningGraph";
import EllipsisText from "$root/ui-lib/ellipsisText";
import { Icon } from "@mdotm/mdotui/components";
import type { IconName } from "@mdotm/mdotui/components";
import { PaletteColors } from "$root/styles/themePalette";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { builtInSortFnFor } from "$root/utils/collections";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import useWidgetsData from "$root/hooks/useWidgetsData";

type TableVariants = "RETURNS" | "VOLATILITY" | "DIVERSIFICATION";

type OutlookExpectedTableProps = {
	data: Array<TableParams>;
	title: TableVariants;
	className?: string;
	rowClassName?: string;
	sortable?: boolean;
	reverseGraph?: boolean;
};

export type TableParams = {
	dynamics: string;
	dynamicsDescription: string;
	current: number;
	prev?: number;
	tooltip: Array<{ label: string; value: string }>;
	index: number;
};

const TABLE_HEADER: Record<TableVariants, string> = {
	RETURNS: "Historical dynamics",
	VOLATILITY: "Historical dynamics",
	DIVERSIFICATION: "Historical dynamics",
};

const OutlookExpectedTable: FC<OutlookExpectedTableProps> = ({
	data,
	title,
	sortable = false,
	className = "",
	rowClassName = "",
	reverseGraph = false,
}) => {
	const { currentWidgetsData } = useWidgetsData();
	const outLookHeader = TABLE_HEADER[title];
	const indicators: Array<{ icon: IconName; color: string }> = useMemo(
		() => [
			{ icon: "driver-arrow-doubledown", color: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED },
			{ icon: "driver-arrow-singledown", color: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED },
			{ icon: "driver-arrow-center", color: PaletteColors.AZURE },
			{ icon: "driver-arrow-singleup", color: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE },
			{ icon: "driver-arrow-doubleup", color: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE },
		],
		[reverseGraph],
	);

	const columns = useMemo<Array<TableColumn<TableParams>>>(
		() => [
			{
				header: outLookHeader,
				content: ({ dynamics, dynamicsDescription }) => (
					<div className="max-w-[100%] flex items-center justify-between">
						<EllipsisText text={dynamics} />
						<InfoTooltip>{dynamicsDescription}</InfoTooltip>
					</div>
				),
				relativeWidth: 65,
				name: "dynamics",
				...(sortable ? { sortFn: builtInSortFnFor("dynamics") } : {}),
			},
			{
				header: () => (
					<div className="flex justify-between grow px-2">
						{indicators.map(({ color, icon }) => (
							<div key={icon}>
								<Icon icon={icon} color={color} size={16} />
							</div>
						))}
					</div>
				),
				content: (content) => (
					<div className="w-full flex h-10">
						<PositioninGraph
							{...content}
							reverseGraph={reverseGraph}
							tooltip={content.tooltip}
							tootlipTitle={content.dynamics}
							lastDashboardUpdate={currentWidgetsData.creation_time.toString()}
						/>
					</div>
				),
				relativeWidth: 35,
			},
		],
		[outLookHeader, sortable, indicators, reverseGraph, currentWidgetsData.creation_time],
	);

	return (
		<div>
			{/** table */}
			<Table columns={columns} rows={data} classList={className} rowClassList={`OutlookRowsTable ${rowClassName}`} />
		</div>
	);
};

export default OutlookExpectedTable;
