export function SolvedWithAIIcon(): JSX.Element {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="12" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9999 21.1667C17.0625 21.1667 21.1666 17.0626 21.1666 12C21.1666 6.93743 17.0625 2.83337 11.9999 2.83337C6.93731 2.83337 2.83325 6.93743 2.83325 12C2.83325 17.0626 6.93731 21.1667 11.9999 21.1667ZM16.6295 6.65209C16.5566 6.72526 16.4419 7.08076 16.3774 7.36886L16.2333 8.01074L16.0893 7.36886C16.0248 7.08077 15.9101 6.72526 15.8369 6.65209C15.7638 6.57891 15.4083 6.46368 15.1202 6.39972L14.4771 6.2551L15.1204 6.11163C15.4082 6.0471 15.7643 5.93244 15.8369 5.86042C15.9101 5.78667 16.0247 5.43058 16.0893 5.14249L16.2333 4.50004L16.3774 5.14249C16.4419 5.43058 16.5566 5.78667 16.6292 5.85984C16.7023 5.93244 17.0584 6.0471 17.3465 6.11163L17.9907 6.25568L17.3465 6.39972C17.0584 6.46368 16.7029 6.57891 16.6295 6.65209ZM6.54117 7.89268C6.36141 8.07246 5.58817 8.29717 5.14306 8.39685L4.49974 8.54032L5.14277 8.68494C5.58815 8.78463 6.36167 9.00992 6.54145 9.18911C6.72093 9.36889 6.94622 10.1427 7.0459 10.5881L7.18965 11.2311L7.33341 10.5881C7.43339 10.1427 7.65838 9.36889 7.83815 9.18911C8.01793 9.00933 8.79117 8.78462 9.23656 8.68494L9.87958 8.54032L9.23628 8.39685C8.79117 8.29717 8.01793 8.07246 7.83845 7.89268C7.65839 7.71291 7.43339 6.93967 7.33343 6.49428L7.18967 5.85183L7.04591 6.49428C6.94623 6.93968 6.72093 7.71291 6.54117 7.89268ZM12.0727 7.17133C12.0652 7.20525 11.308 10.5587 10.5527 11.3129C9.7973 12.0689 6.44388 12.826 6.40989 12.8335L5.76658 12.9776L6.41018 13.1216C6.44396 13.1291 9.79786 13.8862 10.5527 14.641C11.3081 15.3964 12.0652 18.7504 12.0727 18.7838L12.2167 19.4274L12.3605 18.7838L12.3615 18.7795C12.3932 18.641 13.1378 15.384 13.8805 14.641C14.6356 13.8862 17.9893 13.1291 18.023 13.1216L18.6666 12.9776L18.023 12.8335C17.9892 12.826 14.6356 12.0689 13.8805 11.3129C13.1319 10.5652 12.3825 7.26805 12.3609 7.17315L12.3605 7.17133L12.2167 6.52773L12.0727 7.17133Z"
				fill="#3C51B5"
			/>
		</svg>
	);
}
