import { Card } from "../Card";
import { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import type { InvestmentCommentaryResponse } from "$root/api/api-gen";
import { MarkdownRenderer, MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import ReactDOMServer from "react-dom/server";
import { themeCSSVars } from "@mdotm/mdotui/themes";

type PortfolioCommentaryMDBlockProps =
	| CommonSetUpReportProps<UnionMapData["portfolio-enhanced"]>
	| CommonSetUpReportProps<UnionMapData["portfolio-details"]>;

// export function getCommentaryProps(data: InvestmentCommentaryResponse): string[] {
// 	if (!data.shortCommentary && !data.commentary) {
// 		return [];
// 	}
// 	return [(data.shortCommentary ?? "").split("\n"), (data.commentary ?? "").split("\n")].flat();
// }


export function getCommentaryProps(data: InvestmentCommentaryResponse): string[] {
	if (!data.shortCommentary && !data.commentary) {
		return [];
	}

	const description = `${data.shortCommentary ?? ""} ${data.commentary ?? ""}`;
	const marktdownToHtmlString = ReactDOMServer.renderToStaticMarkup(
		<MarkdownRenderer componentOverrides={markdownConfig}>{description}</MarkdownRenderer>,
	);

	const div = document.createElement("div");
	div.innerHTML = marktdownToHtmlString;
	const markdownContainer = div.children[0]!;
	const nodes: string[] = [];

	for (const x of markdownContainer.children) {
		nodes.push(x.outerHTML);
	}

	return nodes;
}

const Commentary = ({
	data,
	list,
	firstRender,
}: PrintableProps<{ data: PortfolioCommentaryMDBlockProps }, string>): JSX.Element => {
	if (!data.commentary.commentary || !data.commentary.shortCommentary || list.length === 0) {
		return <></>;
	}
	return (
		<Card title={firstRender ? "Commentary - Generative AI" : ""} classList="relative pb-4">
			{list.map((text, i) => (
				<Fragment key={i}>
					{/* <ReactMarkdown
						components={{
							h1: ({ node: _node, ...forward }) => (
								<h1 className="text-[14px] font-bold pb-2 pt-2" {...forward}>
									{forward.children}
								</h1>
							),
							h2: ({ node: _node, ...forward }) => (
								<h2 className="text-[12px] font-bold pb-2 pt-2" {...forward}>
									{forward.children}
								</h2>
							),
							h3: ({ node: _node, ...forward }) => (
								<h3 className="text-[10px] font-bold pb-2 pt-2" {...forward}>
									{forward.children}
								</h3>
							),
							ul: ({ node: _node, ...forward }) => <ul className="list-disc pl-4" {...forward} />,
							ol: ({ node: _node, ...forward }) => <ul className="list-decimal pl-4" {...forward} />,
							li: ({ node: _node, ...forward }) => <li {...forward} />,
							p: ({ node: _node, ...forward }) => <p className="text pb-2" {...forward} />,
							blockquote: ({ node: _node, ...forward }) => (
								<blockquote className="bg-sky-100 px-2 py-1 rounded" {...forward} />
							),
						}}
					>
						{text}
					</ReactMarkdown> */}
					<div dangerouslySetInnerHTML={{ __html: text }} />

					{list.length - 1 === i && (
						<div className="absolute bottom-0">
							<p className="text-[#8c8ea8]">Content generated by Generative AI</p>
						</div>
					)}
				</Fragment>
			))}
		</Card>
	);
};

const markdownConfig: MarkdownRendererProps["componentOverrides"] = {
	h1: ({ node: _node, ...forward }) => (
		<h1 className="text-[14px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h1>
	),
	h2: ({ node: _node, ...forward }) => (
		<h2 className="text-[12px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h2>
	),
	h3: ({ node: _node, ...forward }) => (
		<h3 className="text-[10px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h3>
	),
	ul: ({ node: _node, ...forward }) => <ul className="list-disc pl-4" {...forward} />,
	ol: ({ node: _node, ...forward }) => <ul className="list-decimal pl-4" {...forward} />,
	li: ({ node: _node, ...forward }) => <li {...forward} />,
	p: ({ node: _node, ...forward }) => <p className="text pb-2" {...forward} />,
	blockquote: ({ node: _node, ...forward }) => <blockquote className="bg-sky-100 px-2 py-1 rounded" {...forward} />,
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-2 !text-[10px]" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-2 py-1 !font-bold !text-[10px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
};

export default Commentary;
