import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { FC } from "react";

const FirstQuartile: FC<Omit<SvgProps, "viewBox" | "children">> = (props) => {
	return (
		<Svg viewBox={{ width: 15, height: 9 }} fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 8L7 8C6.44771 8 6 7.55228 6 7L6 2C6 1.44771 6.44772 0.999999 7 0.999999L15 1L15 8Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 8L7 8C6.44771 8 6 7.55228 6 7L6 2C6 1.44771 6.44772 0.999999 7 0.999999L15 1L15 8Z"
				fill="#8C8EA8"
				fillOpacity="0.5"
			/>
			<path d="M15 8L7 8C6.44771 8 6 7.55228 6 7L6 2C6 1.44771 6.44772 0.999999 7 0.999999L15 1" stroke="#8C8EA8" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 4.5C6 4.77614 5.77614 5 5.5 5L0.5 5C0.223857 5 -4.37697e-07 4.77614 -3.89414e-07 4.5C-3.41132e-07 4.22386 0.223858 4 0.5 4L5.5 4C5.77614 4 6 4.22386 6 4.5Z"
				fill="#8C8EA8"
			/>
		</Svg>
	);
};

export default FirstQuartile;
