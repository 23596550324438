import type { FactorsDto, InvestmentFactorsResponse } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { useLocaleFormatters } from "$root/localization/hooks";
import type {
	ReportTemplateItemMap,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import {
	customObjectEntriesFn,
	customObjectValuesFn,
	getGraphMarkers,
	roundCustomByStep,
} from "$root/utils/experimental";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { builtInSort } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type ReducedResultProps = {
	[key: string]: {
		[key in keyof InvestmentFactorsResponse]?: FactorsDto;
	} & { groupKey: string };
};

type GetExposureFactorsSplittableProps = {
	current?: FactorsDto;
	proposal?: FactorsDto;
	benchmark?: FactorsDto;
	groupKey: string;
};

type GraphLimits = {
	maxValue: number;
	minValue: number;
};

type graphColumnMarkers = {
	value: number;
	label: string;
};

export type ExposureFactorsData = {
	investmentFactors: InvestmentFactorsResponse;
	variant: ReportTemplateVariant;
};

export function getExposureFactorsProps(data: ExposureFactorsData): {
	list: GetExposureFactorsSplittableProps[];
	graphLimits: GraphLimits;
	graphColumnMarkers: graphColumnMarkers[];
} {
	const processedFactorList = customObjectValuesFn(
		customObjectEntriesFn(data.investmentFactors).reduce<ReducedResultProps>((acc, [key, factorList]) => {
			factorList?.forEach((factor) => {
				if (factor.key === undefined) {
					acc["undefinedKey"] = {
						...acc["undefinedKey"],
						[key]: factor,
						groupKey: "undefinedKey",
					};
				} else {
					acc[factor.key] = {
						...acc[factor.key],
						[key]: factor,
						groupKey: factor.key,
					};
				}
			});
			return acc;
		}, {}),
	);

	const filteredFactorListWithSignificantOnly = processedFactorList.filter(({ benchmark, current, proposal }) =>
		data.variant === "proposal"
			? [benchmark?.value, current?.value, proposal?.value].some((e) => e !== 0)
			: [benchmark?.value, current?.value].some((e) => e !== 0),
	);

	const limits = filteredFactorListWithSignificantOnly.reduce(
		(a, c) => {
			return {
				maxValue: Math.max(a.maxValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
				minValue: Math.min(a.minValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
			};
		},
		{ maxValue: 0, minValue: 0 },
	);
	const sortedList = filteredFactorListWithSignificantOnly.sort(
		(a, b) => builtInSort(Math.abs(a.current?.value ?? 0), Math.abs(b.current?.value ?? 0)) * -1,
	);
	const graphLimits = {
		maxValue: roundCustomByStep(limits.maxValue, 0.5),
		minValue: roundCustomByStep(limits.minValue, 0.5),
	};

	const graphColumnMarkers = getGraphMarkers(graphLimits.maxValue, graphLimits.minValue, 0.5, "", 10);

	return { list: sortedList, graphColumnMarkers, graphLimits };
}

export function ExposureFactors({
	variant,
	list,
	graphColumnMarkers,
	graphLimits,
	config,
}: PrintableProps<
	{
		variant: ReportTemplateVariant;
		data: ExposureFactorsData;
		graphLimits: GraphLimits;
		graphColumnMarkers: graphColumnMarkers[];
		config: ReportTemplateItemMap["factorExposure"];
	},
	GetExposureFactorsSplittableProps
>): JSX.Element {
	const { benchmark: showBenchmark } = config;
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = useMemo<Array<TableColumn<GetExposureFactorsSplittableProps>>>(
		() =>
			variant === "proposal"
				? [
						{
							relativeWidth: 50,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.FACTORS"),
							content: ({ current }) => current?.label,
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.CURRENT"),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ current }) => formatNumber(current?.value),
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.PROPOSAL"),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ proposal }) => formatNumber(proposal?.value),
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.BENCHMARK"),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ benchmark }) => formatNumber(benchmark?.value),
							hidden: !showBenchmark,
						},
						{
							relativeWidth: 40,
							header: () => (
								<div className="flex grow justify-between">
									{graphColumnMarkers.map((m, i) => (
										<div key={`marker-${i}`}>{m.label}</div>
									))}
								</div>
							),
							content: ({ benchmark, current, proposal }) => (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
									}}
									data={[
										{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" },
										{ value: proposal?.value ?? 0, color: proposal?.relevant ? "#00AEEF" : "red" },
										{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#C6ACD9" : "red" },
									]}
								/>
							),
						},
				  ]
				: [
						{
							relativeWidth: 50,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.FACTORS"),
							content: ({ current }) => current?.label,
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.CURRENT"),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ current }) => formatNumber(current?.value),
						},
						{
							relativeWidth: 10,
							header: t("PORTFOLIOFACTORSEXPOSURE.TABLE.BENCHMARK"),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ benchmark }) => formatNumber(benchmark?.value),
							hidden: !showBenchmark,
						},
						{
							relativeWidth: 50,
							header: () => (
								<div className="flex grow justify-between">
									{graphColumnMarkers.map((m, i) => (
										<div key={`marker-${i}`}>{m.label}</div>
									))}
								</div>
							),
							content: ({ benchmark, current }) => (
								<BarGraphPCSvg
									classList="w-full"
									options={{
										animated: false,
										resize: true,
										marksLabels: false,
										markerStep: 0.5,
										scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
									}}
									data={
										showBenchmark
											? [
													{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" },
													{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#A0A7B6" : "red" },
											  ]
											: [{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" }]
									}
								/>
							),
						},
				  ],
		[variant, t, showBenchmark, formatNumber, graphColumnMarkers, graphLimits.maxValue, graphLimits.minValue],
	);

	if (list.length === 0) {
		return <></>;
	}

	return (
		<Card title="Factors exposure">
			<Table palette="uniform" columns={columns} noDataText={t("COMPOSITION.NO_COMPOSITION")} rows={list} />
		</Card>
	);
}

export default ExposureFactors;
