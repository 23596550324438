import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import type { InstrumentsDialogProps } from "./InstrumentsDialog";
import { InstrumentsDialog } from "./InstrumentsDialog";

type SpawnInstrumentDialogProps = Omit<InstrumentsDialogProps, "show" | "onClose">;
export function spawnInstrumentsDialog(props: SpawnInstrumentDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<InstrumentsDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(instruments) => {
					props.onSubmit(instruments);
					resolve();
				}}
			/>
		)),
	);
}
