import type {
	ContributionEntry,
	EnhancementContributionResponse,
	InvestmentContributionResponse,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import { InfoDelta } from "$root/components/InfoDelta";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import { builtInSort, builtInSortFnFor } from "$root/utils/collections";
import { DrillDownByValueMap, getGraphMarkers, objSumRounded, roundCustomByStep } from "$root/utils/experimental";
import { pxToRem } from "$root/utils/pxToRem";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { ScrollWrapper, Select, TableWithGroups } from "@mdotm/mdotui/components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { HorizonType } from "../const";
import { horizonOptions } from "../const";

type horizonType = "ONE_MONTH" | "THREE_MONTHS" | "SIX_MONTHS" | "ONE_YEAR" | "YEAR_TO_DATE" | "FROM_INCEPTION";
const categoryOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
	},
];
const defaultCategorySelection = { selector: categoryOptions[0].selector, vsSelector: categoryOptions[0].vsSelector };
const ExanteContributionVolatility = (props: ContextContent<typeof PortfolioContext>) => {
	const [horizon] = useState<HorizonType>(horizonOptions[4].value);
	const [category, setCategory] = useState(defaultCategorySelection);

	const { portfolio, selectedBenchmark, enhanced } = props;
	const uuid = props.portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier ?? "";

	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("PORTFOLIO_EXANTE_CONTRIBUTION_VOLATILITY.TITLE"),
			actionHeader: <InfoTooltip>{t("PORTFOLIO_EXANTE_CONTRIBUTION_VOLATILITY.TOOLTIP")}</InfoTooltip>,
		}),
		[t],
	);

	const query = useQueryNoRefetch(
		["ExanteContribution", uuid, enhanced, horizon, benchmarkId, props.portfolio?.status, props.reportExcutionCounter],
		{
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				const exanteContributionVolatilityResponse = enhanced
					? await investmentEnhancementReportApi.getCompositionVolatilityContribution1(uuid, horizon)
					: await investmentReportsApi.getCompositionVolatilityContribution(uuid, benchmarkId, horizon);

				const exanteContributionVolatility = {
					benchmark: enhanced
						? (exanteContributionVolatilityResponse.data as EnhancementContributionResponse).benchmark
						: undefined,
					current: enhanced
						? (exanteContributionVolatilityResponse.data as EnhancementContributionResponse).current
						: (exanteContributionVolatilityResponse.data as InvestmentContributionResponse).current,
					proposal: enhanced
						? (exanteContributionVolatilityResponse.data as EnhancementContributionResponse).proposal
						: undefined,
				};

				if (enhanced && !exanteContributionVolatility.current && !exanteContributionVolatility.proposal) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				if (!enhanced && !exanteContributionVolatility.current && !exanteContributionVolatility.benchmark) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				return {
					data: exanteContributionVolatility,
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(exanteContributionVolatility) => (
				<ExanteContributionVolatilityInner
					ctx={props}
					exanteContributionVolatility={exanteContributionVolatility}
					category={category}
					onChangeCategory={setCategory}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const ExanteContributionVolatilityInner = ({
	ctx,
	exanteContributionVolatility,
	category,
	onChangeCategory,
}: {
	ctx: ContextContent<typeof PortfolioContext>;
	exanteContributionVolatility: {
		benchmark: ContributionEntry[] | undefined;
		current: ContributionEntry[] | undefined;
		proposal: ContributionEntry[] | undefined;
	};
	category: { selector: string; vsSelector: string };
	onChangeCategory(opt: { selector: string; vsSelector: string }): void;
}) => {
	const { enhanced } = ctx;
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const exanteData = useMemo(() => {
		const aggregateData = {
			volatility: (exanteContributionVolatility.current ?? []).map((el) => ({
				assetClass: el.assetClass,
				geography: el.geography,
				microAssetClass: el.microAssetClass,
				originalVolatility: el,
				volatilityValue: el.value,
				id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
			})),
			benchmarkVolatility: (exanteContributionVolatility.benchmark ?? []).map((el) => ({
				assetClass: el.assetClass,
				geography: el.geography,
				microAssetClass: el.microAssetClass,
				originalBenchmarkVolatility: el,
				benchmarkVolatility: el.value,
				id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
			})),
			enhancedVolatility: (exanteContributionVolatility.proposal ?? []).map((el) => ({
				assetClass: el.assetClass,
				geography: el.geography,
				microAssetClass: el.microAssetClass,
				originalEnhancedVolatility: el,
				enhancedVolatility: el.value,
				id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
			})),
		};

		const decupledByID = [
			...new Set([
				...aggregateData.volatility.map((el) => el.id),
				...aggregateData.benchmarkVolatility.map((el) => el.id),
				...aggregateData.enhancedVolatility.map((el) => el.id),
			]),
		];

		const result = decupledByID.map((id) => {
			const currentVTK = aggregateData.volatility.find((el) => el.id === id);
			const currentBVTK = aggregateData.benchmarkVolatility.find((el) => el.id === id);
			const currentEVTK = aggregateData.enhancedVolatility.find((el) => el.id === id);
			return {
				...currentVTK,
				...currentBVTK,
				...currentEVTK,
			};
		});
		return result;
	}, [exanteContributionVolatility]);

	const computedContribution = useMemo(() => {
		return DrillDownByValueMap(
			exanteData ?? [],
			category.selector as keyof (typeof exanteData)[number],
			category.vsSelector as keyof (typeof exanteData)[number],
			{
				volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
				benchmarkVolatility: (val) => objSumRounded(val, "benchmarkVolatility"),
				enhancedVolatility: (val) => objSumRounded(val, "enhancedVolatility"),
				max: (val) =>
					Math.max(
						objSumRounded(val, "volatilityValue"),
						objSumRounded(val, "benchmarkVolatility"),
						objSumRounded(val, "enhancedVolatility"),
					),
				min: (val) =>
					Math.min(
						objSumRounded(val, "volatilityValue"),
						objSumRounded(val, "benchmarkVolatility"),
						objSumRounded(val, "enhancedVolatility"),
					),
			},
			(a, b) => b.volatilityValue - a.volatilityValue,
		);
	}, [exanteData, category.selector, category.vsSelector]);

	// Correction Of Graph Scala
	const graphLimits = useMemo(() => {
		const value = computedContribution.drilldownData.reduce(
			(acc, group) => {
				const rowValues = group.drilldown.reduce(
					(a, row) => {
						return {
							maxVolatility: Math.max(a.maxVolatility, row.computedData.max, group.computedData.max),
							minVolatility: Math.min(a.minVolatility, row.computedData.min, group.computedData.min),
						};
					},
					{ maxVolatility: 0, minVolatility: 0 },
				);
				return {
					maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
					minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
				};
			},
			{ maxVolatility: 0, minVolatility: 0 },
		);
		return {
			maxVolatility: roundCustomByStep(value.maxVolatility, 1),
			minVolatility: roundCustomByStep(value.minVolatility, 1),
		};
	}, [computedContribution.drilldownData]);

	// Generate Markers
	const graphColumnMarkers = useMemo(
		() => ({
			markersVolatility: getGraphMarkers(graphLimits.maxVolatility, graphLimits.minVolatility, 1, "%", 12),
		}),
		[graphLimits],
	);

	// Get constribution translation Object Options
	const constributionT = useMemo(
		() =>
			t(`PORTFOLIO_EXANTE_CONTRIBUTION_VOLATILITY`, {
				returnObjects: true,
			}),
		[t],
	);

	// const selectOptionsList = useMemo(
	// 	() =>
	// 		selectOptions.map((k, index) => ({
	// 			value: index,
	// 			label: k.label,
	// 		})),
	// 	[selectOptions],
	// );

	return (
		<>
			<div className="h-full">
				<div className="flex justify-between">
					<div className="inline-block">
						<Select
							value={category}
							onChange={onChangeCategory}
							options={categoryOptions.map(({ label, selector, vsSelector }) => ({
								label,
								value: { selector, vsSelector },
							}))}
						/>
					</div>
				</div>
				<div className="h-[85%] w-100 pb-3 mt-3 mb-2 px-2 flex flex-col">
					<ScrollWrapper startShadow={false}>
						<TableWithGroups
							headerRowClassList="sticky top-0 z-10 bg-white"
							groupedRows={computedContribution.drilldownData.map((el) => ({ ...el, rows: el.drilldown }))}
							groupRowKey={(r) => r.index}
							columns={
								enhanced
									? [
											{
												header: constributionT.TABLE[category.selector as keyof typeof constributionT.TABLE],
												groupCellClassList: "font-semibold",
												groupContent: (groupedRow) => String(groupedRow.value as any /* TODO: fix types */),
												content: (row) => String(row.value as any) /* TODO: fix types */,
												sortFn: builtInSortFnFor("value"),
												name: "value",
												relativeWidth: 30,
											},
											{
												header: "CURRENT",
												groupCellClassList: "font-semibold text-[#005C8B] tabular-nums",
												groupContent: (groupedRow) => `${formatNumber(groupedRow.computedData.volatilityValue, 2)}%`,
												cellClassList: "text-[#005C8B] tabular-nums",
												content: (row) => `${formatNumber(row.computedData.volatilityValue, 2)}%`,
												relativeWidth: 12,
												align: "end",
												sortFn(a, b) {
													return a.computedData.volatilityValue - b.computedData.volatilityValue;
												},
											},
											{
												header: "PROPOSAL",
												groupCellClassList: "font-semibold text-[#00AEEF] tabular-nums",
												groupContent: (groupedRow) => `${formatNumber(groupedRow.computedData.enhancedVolatility, 2)}%`,
												cellClassList: "text-[#00AEEF] tabular-nums",
												content: (row) => `${formatNumber(row.computedData.enhancedVolatility, 2)}%`,
												relativeWidth: 12,
												sortFn(a, b) {
													return a.computedData.enhancedVolatility - b.computedData.enhancedVolatility;
												},
												align: "end",
											},
											{
												header: "DIFFERENCE",
												groupContent: ({ computedData: { volatilityValue, enhancedVolatility } }) => (
													<div className="font-bold w-[80px]">
														<InfoDelta
															diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)}
															enh={enhancedVolatility ?? 0}
														/>
													</div>
												),
												content: ({ computedData: { volatilityValue, enhancedVolatility } }) => (
													<div className="w-[80px]">
														<InfoDelta
															diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)}
															enh={enhancedVolatility ?? 0}
														/>
													</div>
												),
												relativeWidth: 16,
												sortFn: (a, b) => {
													const deltaA = a.computedData.volatilityValue - a.computedData.enhancedVolatility;
													const deltaB = b.computedData.volatilityValue - b.computedData.enhancedVolatility;
													return builtInSort(deltaA, deltaB);
												},
												name: "difference",
												align: "end",
											},
											{
												orderable: false,
												header: (
													<div className="flex grow justify-between">
														{graphColumnMarkers.markersVolatility.map((m, i) => (
															<div key={`marker-${i}`} className="">
																{m.label}
															</div>
														))}
													</div>
												),
												groupContent: ({
													computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility },
												}) => {
													return (
														<BarGraphPCSvg
															classList="w-full"
															options={{
																resize: true,
																marksLabels: false,
																markerStep: 1,
																scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
															}}
															data={[
																{ value: volatilityValue, color: "#005C8B" },
																{ value: enhancedVolatility, color: "#00AEEF" },
																{ value: benchmarkVolatility, color: "#C6ACD9" },
															]}
														/>
													);
												},
												content: ({ computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility } }) => {
													return (
														<BarGraphPCSvg
															classList="w-full"
															options={{
																resize: true,
																marksLabels: false,
																markerStep: 1,
																scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
															}}
															data={[
																{ value: volatilityValue, color: "#005C8B" },
																{ value: enhancedVolatility, color: "#00AEEF" },
																{ value: benchmarkVolatility, color: "#C6ACD9" },
															]}
														/>
													);
												},
												relativeWidth: 30,
											},
									  ]
									: [
											{
												header: constributionT.TABLE[category.selector as keyof typeof constributionT.TABLE],
												groupContent: (groupedRow) => <strong>{groupedRow.value as any /* TODO: fix types */}</strong>,
												content: (row) => row.value as any /* TODO: fix types */,
												relativeWidth: 30,
											},
											{
												header: "PORTFOLIO VOLATILITY",
												groupContent: (groupedRow) => (
													<strong className="text-[#572C7F] tabular-nums">
														{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
													</strong>
												),
												content: (row) => (
													<span className="text-[#572C7F] tabular-nums">
														{formatNumber(row.computedData.volatilityValue, 2)}%
													</span>
												),
												relativeWidth: 12,
												sortFn(a, b) {
													return a.computedData.volatilityValue - b.computedData.volatilityValue;
												},
												align: "end",
											},
											{
												orderable: false,
												header: (
													<div className="flex grow justify-between">
														{graphColumnMarkers.markersVolatility.map((m, i) => (
															<div key={`marker-${i}`} className="">
																{m.label}
															</div>
														))}
													</div>
												),
												groupContent: ({ computedData: { volatilityValue, benchmarkVolatility } }) => {
													return (
														<BarGraphPCSvg
															classList="w-full"
															options={{
																resize: true,
																marksLabels: false,
																markerStep: 1,
																scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
															}}
															data={[
																{ value: volatilityValue, color: "#572C7F" },
																{ value: benchmarkVolatility, color: "#C6ACD9" },
															]}
														/>
													);
												},
												content: ({ computedData: { volatilityValue, benchmarkVolatility } }) => {
													return (
														<BarGraphPCSvg
															classList="w-full"
															options={{
																resize: true,
																marksLabels: false,
																markerStep: 1,
																scale: { max: graphLimits.maxVolatility, min: graphLimits.minVolatility },
															}}
															data={[
																{ value: benchmarkVolatility, color: "#C6ACD9" },
																{ value: volatilityValue, color: "#572C7F" },
															]}
														/>
													);
												},
												relativeWidth: 58,
											},
									  ]
							}
						/>
					</ScrollWrapper>
				</div>
				{enhanced ? (
					<GraphLegend>
						<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
							<ColoredRectangle color="#005C8B" variant="vertical" /> Current
						</div>
						<div className="legend-container light book">
							<ColoredRectangle color="#00AEEF" variant="vertical" /> Proposal
						</div>
					</GraphLegend>
				) : (
					<GraphLegend>
						<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
							<ColoredRectangle color="#572C7F" variant="vertical" /> Portfolio ex-ante
						</div>
					</GraphLegend>
				)}
			</div>
		</>
	);
};

export default withContext(PortfolioContext)(ExanteContributionVolatility);
