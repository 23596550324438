import { ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadFileBlob } from "$root/utils/files";

export const exportUniverse = {
	async downloadComposition(uuid: string): Promise<void> {
		const { data, headers } = await getApiGen(ReferenceUniversesControllerApiFactory).exportUniverseComposition(
			uuid,
			{ responseType: "blob" },
		);

		const fileType = headers["content-type"];
		const fileName = headers["content-disposition"].split("filename=")[1];

		trackMixPanelEvent("Universe", {
			Type: "Download Composition",
			ID: uuid,
		});

		downloadFileBlob(
			{ data, name: fileName, type: fileType },
			{
				// TODO: remove extension once BE updates their filenames so that it's included
				fileName,
			},
		);
	},
};
