import axios from "axios";
import { eventBus } from "$root/event-bus";

export const preconfiguredAxiosInstance = axios.create({
	timeout: 30 * 60 * 1000, // 10 minutes
});

preconfiguredAxiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.status === 401) {
			eventBus.emit("unauthorized");
		}
		return Promise.reject(error);
	},
);
