import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useState } from "react";

export type RectSize = { width: number; height: number };

/**
 * Measure the size of a DOM element by using a resize observer.
 *
 * Adapted from https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer#quick-start
 *
 * @param target the DOM element to measure.
 * @returns the current size of the target.
 */
export function useSize(target: HTMLElement | null, pause = false): RectSize | null {
	const [size, setSize] = useState<RectSize | null>(null);

	useLayoutEffect(() => {
		if (!pause) {
			setSize(target?.getBoundingClientRect() ?? null);
		}
	}, [pause, target]);

	useResizeObserver(target, (entry) => {
		if (!pause) {
			setSize(entry.contentRect);
		}
	});
	return size;
}

/**
 * Transform a key-value object into a string that can be used in a className attribute.
 * Keys are the classes you want to be able to toggle, while values indicate whether or not a class is
 * included in the resulting string.
 *
 * Example usage:
 * ```tsx
 * export function App() {
 * 	const [toggle, setToggle] = useState(false);
 * 	return (
 * 		<div className={toClassName({ 'bg-red-500': toggle, 'bg-yellow-500': !toggle })}>
 * 			Demo
 * 		</div>
 * 	);
 * }
 * ```
 * You can combine it with an existing className string by interpolating it:
 * ```tsx
 * export function App() {
 * 	const [toggle, setToggle] = useState(false);
 * 	return (
 * 		<div className={`color-sky-500 ${toClassName({ 'bg-red-500': toggle, 'bg-yellow-500': !toggle })}`}>
 * 			Demo
 * 		</div>
 * 	);
 * }
 * ```
 * And you can also toggle multiple classes at once by putting them in one key:
 * ```tsx
 * export function App() {
 * 	const [toggle, setToggle] = useState(false);
 * 	return (
 * 		<div className={`color-sky-500 ${toClassName({ 'bg-red-500 text-white': toggle, 'bg-yellow-500 text-black': !toggle })}`}>
 * 			Demo
 * 		</div>
 * 	);
 * }
 * ```
 *
 * @param classList A key-value object where keys are CSS classes and values are boolean (or null or undefined).
 */
export function toClassName<T extends Record<string, boolean | undefined | null>>(classList: T): string {
	return [...Object.entries(classList)]
		.filter(([_, enabled]) => Boolean(enabled))
		.map(([key]) => key)
		.join(" ");
}
