import type { PortfolioMetricTypeEnum } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { Table } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";
import { t } from "i18next";

type PerformanceMetricsProps = CommonSetUpReportProps<UnionMapData["portfolio-details"]>;

type Columns = {
	label: string;
	name: string;
	type: PortfolioMetricTypeEnum;
	visible: boolean;
	current: number;
	benchmark: number;
};

export function getPerformanceMetricsProps(data: PerformanceMetricsProps): Columns[] {
	return (
		data.performanceMetrics.portfolioMetrics?.flatMap((metrics) => {
			if (!metrics.type) {
				return [];
			}
			return [
				{
					label: t(`PERFORMANCE_METRICS.METRICS.FULL.${metrics.type}`) ?? "-",
					name: t(`PERFORMANCE_METRICS.METRICS.FULL.${metrics.type}`) ?? "-",
					type: metrics.type,
					visible: true,
					current: metrics.value ?? 0,
					benchmark: metrics.benchmarkValue ?? 0,
				},
			];
		}) ?? []
	);
}

function PerformanceMetrics({ list }: PrintableProps<{ data: PerformanceMetricsProps }, Columns>): JSX.Element {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = [
		{
			relativeWidth: 2.0,
			header: "METRICS",
			content: ({ label }) => label,
		},
		{
			relativeWidth: 1.0,
			cellClassList: "tabular-nums",
			header: "Current",
			align: "end",
			content: ({ current, label }) => `${formatNumber(current)}${label.toLowerCase().includes("ratio") ? "" : "%"}`,
		},
		{
			relativeWidth: 1.0,
			cellClassList: "tabular-nums",
			header: "Benchmark",
			align: "end",
			content: ({ benchmark, label }) =>
				`${formatNumber(benchmark)}${label.toLowerCase().includes("ratio") ? "" : "%"}`,
		},
	] satisfies TableColumn<Columns>[];

	return (
		<Card title="Portfolio metrics">
			<Table
				palette="uniform"
				columns={columns}
				rows={list}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
				visibleRows={list.length}
				headerRowStyle={{ height: "40px" }}
			/>
		</Card>
	);
}
export default PerformanceMetrics;
