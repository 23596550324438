const SuccessImportBox = (): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="56" height="67" fill="none" viewBox="0 0 56 67">
			<g filter="url(#filter0_d_613_48851)">
				<path fill="#fff" d="M14.778 12.556l18.621.568 7.046 7.964v29.579l-25.667-.57V12.557z" />
			</g>
			<path
				fill="#C3C9D5"
				fillRule="evenodd"
				d="M40.376 18.17c.161.169.26.392.26.633l.008.009v31.374a.702.702 0 01-.705.705H15.483a.702.702 0 01-.705-.705V13.26a.702.702 0 01.705-.705h18.905c.24 0 .464.089.634.258l5.354 5.355zm-2.784-.26L35.28 15.6v2.311h2.312zM16.563 49.088H38.85V19.695H34.2a.702.702 0 01-.705-.705v-4.65H16.563v34.748zm5.1-20.306H34.38v12.717H21.662V28.782zm2.007 5.355h3.347V30.79H23.67v3.347zm3.347 5.355v-3.347H23.67v3.346h3.347zm5.355-3.347h-3.347v3.346h3.347v-3.346zm-3.347-5.355v3.347h3.347V30.79h-3.347z"
				clipRule="evenodd"
			/>
			<ellipse cx="40.444" cy="46.778" fill="#fff" rx="8.556" ry="8.556" />
			<path
				fill="#3E8E7E"
				fillRule="evenodd"
				d="M40.444 38.222c-4.715 0-8.555 3.84-8.555 8.556 0 4.715 3.84 8.555 8.555 8.555S49 51.493 49 46.778s-3.84-8.556-8.556-8.556zM38.52 48.717l5.393-5.506h-.004c.305-.307.77-.316 1.067-.014.297.303.297.8 0 1.103l-5.934 6.053a.756.756 0 01-1.08 0l-2.998-3.06a.795.795 0 010-1.103.756.756 0 011.08 0l2.476 2.527z"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="filter0_d_613_48851"
					width="53.667"
					height="66.111"
					x="0.778"
					y="0.556"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_613_48851" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_613_48851" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};

export default SuccessImportBox;
