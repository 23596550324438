import styled from "styled-components";
import { pxToRem } from "$root/utils/pxToRem";
import { PaletteColors } from "$root/styles/themePalette";
import { Text } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";

const GraphLegend = styled("div")({
	position: "absolute",
	bottom: pxToRem(12),
	display: "flex",
	flexWrap: "wrap",

	".legend-container": {
		display: "flex",
		alignItems: "center",
		marginRight: pxToRem(5),
		svg: {
			marginRight: pxToRem(5),
		},
		"&.condensed": {
			height: pxToRem(20),
			marginRight: pxToRem(18),

			svg: {
				marginRight: pxToRem(5),
				"&:last-of-type": {
					marginRight: pxToRem(5),
				},
			},
		},
		"&.light": {
			color: PaletteColors.BLUEY_GREY,
		},
		"&.more-space": {
			marginRight: pxToRem(20),
		},
		"&.book": {
			fontFamily: "Gotham Book",
			fontSize: pxToRem(10),
		},
	},
});

export function GraphLegendTextKey({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Text classList="mr-1" type="Body/S/Light-UPPERCASE">
			{children}
		</Text>
	);
}

export function GraphLegendLabel({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Text type="Body/S/Book" color="#8c8ea8">
			{children}
		</Text>
	);
}

export default GraphLegend;
