export const PaletteColors = {
	BLACK: "#000",
	NEON_RED: "#ea401f",
	ERROR_RED: "#FF1D23",
	DARK_SLATE_BLUE: "#24405e",
	SLATE_GREY: "#656d78",
	COOL_GREY: "#aab2bd",
	GOLDEN: "#f2b604",
	GREEN_BLUE: "#08a696",
	ORANGEY_RED: "#f65935",
	SOFT_BLUE: "#598ce5",
	PALE_GREY: "#e6e9ed",
	WHITE: "#ffffff",
	CHARCOAL_GREY: "#292f36",
	SILVER: "#ccd1d9",
	DUCK_EGG_BLUE: "#f0f9fd",
	SALMON: "#fa7f63",
	DUSK_BLUE: "#225378",
	PERRYWINKLE: "#9bb6eb",
	OFF_YELLOW: "#f7de40",
	SEAFOAM_BLUE: "#61d1be",
	FADED_BLUE: "#6892b7",
	BEIGE: "#f3c8bf",
	LIGHT_BLUE_GREY: "#c8d4ee",
	LIGHT_KHAKI: "#f2eab1",
	LIGHT_BLUE_GREY_TWO: "#bde5de",
	CLOUDY_BLUE: "#a5b8c9",
	WHITE_TWO_50: "rgba(244, 244, 244, 0.5)",
	DARK_SLATE_BLUE_50: "rgba(36, 64, 94, 0.5)",
	LIGHTBLUE: "#6ec8f3",
	WARM_PURPLE: "#822e7b",
	WHITE_TWO: "#d8d8d8",
	BLUEY_GREY_20: "rgba(140, 142, 168, 0.2)",
	AZURE: "#00aeef",
	GREENY_BLUE: "#4cb09c",
	GREENY_BLUE_02: "rgba(76, 176, 156, 0.2)",
	CHARCOAL_GREY_TWO: "#2f3541",
	BLUEY_GREY: "#8c8ea8",
	GUNMETAL: "#4b575f",
	PALE_GREY_TWO: "#eeeef1",
	MANATEE: "#8C8EA8",
	WHITE_SMOKE: "#f6f6f6",
	WHITE_GREY: "#f6f6f6",
	TRANQUIL: "#DBEFEB",
	GUN_POWDER_LIGHT: "#3C4556",
	PERIWINKLE: "#D1D2DC",
	LIGHT_GREY: "#9395ae",
	SANTAS_GREY: "#9f9fa3",
	DARK_BLUE: "#3c51b5",
	ALICE_BLUE: "#e5f6fd",
	CERULEAN: "#007cb7",
	UNBLEACHED_SILK: "#fbd9d2",
	YELLOW: "#ffbf00",
	DARK_GREY: "#616161",
	DARK_BLUE_ICON: "#2F3541",
	BG_INFO_GREEN: "#edfdf8",
	INFO_GREEN: "#08875d",
	BG_WARNING_YELLOW: "#fff9e5",
	WARNING_YELLOW: "#dd662e",
	BG_ALERT_RED: "#ffe8e9",
	ALERT_RED: "#e81e25",
};
