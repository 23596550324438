import type { AclEntityMinInfo, AclEntityMinInfoEntityTypeEnum } from "$root/api/api-gen";
import { Text } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";

type DisclaimerProps = {
	entities: AclEntityMinInfo[];
	entity: AclEntityMinInfoEntityTypeEnum;
};

const PortfolioDisclaimer = (props: { entities: AclEntityMinInfo[] }) => {
	const { entities } = props;
	const benchmark = entities.find((x) => x.entityType === "BENCHMARK");
	const universe = entities.find((x) => x.entityType === "UNIVERSE");

	if (!benchmark?.name && !universe?.name) {
		return <></>;
	}

	return (
		<p>
			To make the portfolio usable,&nbsp;
			{benchmark?.name && (
				<>
					Benchmark&nbsp;
					<Text type="Body/M/Bold">{benchmark.name}</Text>
				</>
			)}
			{universe?.name && (
				<>
					{benchmark?.name ? <>and Universe&nbsp;</> : ""}
					<Text type="Body/M/Bold">{universe.name}</Text>
				</>
			)}
			&nbsp; will also be shared with the read only access. If you wish to share the benchmark and the universe with
			editing permissions, you should proceed to their respective pages.
		</p>
	);
};

const Disclaimer = (props: DisclaimerProps): JSX.Element => {
	const DisclaimerMap = {
		INVESTMENT: <PortfolioDisclaimer entities={props.entities} />,
		BENCHMARK: <></>,
		UNIVERSE: <></>,
		MARKET_VIEW: <></>,
	} satisfies Record<DisclaimerProps["entity"], ReactNode>;

	return DisclaimerMap[props.entity];
};

export default Disclaimer;
