import type {
	ComponentAndPropsPair,
	ComponentAndPropsPairPartial,
	PrintableComponent,
} from "$root/components/EvolvedPrint/configuration";

export function jsxHelper<C extends PrintableComponent<any, any>>(
	component: C,
	params: C extends PrintableComponent<infer TCommon, infer TSplittable>
		? ComponentAndPropsPairPartial<TCommon, TSplittable>
		: never,
): C extends PrintableComponent<infer TCommon, infer TSplittable>
	? ComponentAndPropsPair<TCommon, TSplittable>
	: never {
	return {
		...params,
		component: (props: any) => {
			const C = component;
			return <C {...props} />;
		},
	} as any;
}
