export const minute = 60000;

export function sleep(ms: number, opts?: { signal?: AbortSignal }): Promise<void> {
	return new Promise((resolve, reject) => {
		const abortListener = () => {
			if (id) {
				clearTimeout(id);
				reject(opts?.signal?.reason);
			}
		};
		opts?.signal?.addEventListener("abort", abortListener);
		let id: ReturnType<typeof setTimeout> | null = setTimeout(() => {
			id = null;
			opts?.signal?.removeEventListener("abort", abortListener);
			resolve();
		}, ms);
	});
}

export function animationFrame(): Promise<DOMHighResTimeStamp> {
	return new Promise((resolve) => {
		requestAnimationFrame(resolve);
	});
}
