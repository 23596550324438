import type { ReviewTicker } from "$root/api/api-gen";
import type { MaybePromise } from "$root/utils/types";
import { AsyncButton, Button, Controller } from "@mdotm/mdotui/components";
import { Map, Set } from "immutable";
import type { EditProxiedInstrumentSections, ProxiedInstrumentData } from "./EditProxiedInstrumentTable";
import { EditProxiedInstrumentTable } from "./EditProxiedInstrumentTable";
import type { FC } from "react";
import { useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { useTranslation } from "react-i18next";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";

export type EditProxiedInstrumentSectionProps = {
	disabled?: boolean;
	actions?: Array<"delete" | "edit">;
	composition: ReviewTicker[];
	onSaveAsync(proxiedComposition: ReviewTicker[]): MaybePromise<void>;
	onCancel?(): void;
	customActions?: NodeOrFn<{ onSaveAsync(): Promise<void>; onCancel?(): void; isSaveDisabled: boolean }>;
	mode?: "proxy" | "edit-proxy";
	section?: EditProxiedInstrumentSections;
	pathToNotBlock?: string[];
	enableLeavePrompt?: boolean;
	uuid?: string;
};

const qualifierBySection = {
	benchmark: "Benchmark",
	portfolio: "Portfolio",
	target_investment: "Portfolio",
	universe: "Universe",
} satisfies { [key in EditProxiedInstrumentSections]: string };

export function EditProxiedInstrumentSection({
	disabled,
	composition,
	mode,
	actions,
	onSaveAsync,
	onCancel,
	section,
	pathToNotBlock,
	enableLeavePrompt,
	uuid,
	customActions,
}: EditProxiedInstrumentSectionProps): JSX.Element {
	const proxiedInstruments = composition.flatMap((x) =>
		x.needsCustomProxy && Boolean(x.delisted) === false && instrumentDataValidation(x)
			? ([
					[
						x.ticker!,
						{
							assetClass: x.assetClass!,
							currency: x.currency!,
							instrument: x.instrument!,
							microAssetClass: x.microAssetClass!,
							proxies: x.proxies!,
						} satisfies ProxiedInstrumentData,
					],
			  ] satisfies Array<[string, ProxiedInstrumentData]>)
			: [],
	);

	const [controller, setController] = useState({
		proxied: Map<string, ProxiedInstrumentData>(proxiedInstruments),
		deleted: Set<string>(),
		scored: Map<string, number | null>(
			composition.filter((x) => x.ticker && x.score !== undefined).map((x) => [x.ticker!, x.score!]),
		),
		tagged: Map<string, string>(composition.filter((x) => x.ticker && x.tagLabel).map((x) => [x.ticker!, x.tagLabel!])),
	});
	const [dirty, setDirty] = useState(false);
	const { t } = useTranslation();
	function instrumentDataValidation(instrument: ReviewTicker) {
		const isTickerValid = instrument.ticker;
		const isFirstStepFilled =
			instrument?.instrument && instrument.microAssetClass && instrument.assetClass && instrument.currency;

		const isSecondStepFileld = !!instrument?.proxies?.length;
		if (isFirstStepFilled && isSecondStepFileld && isTickerValid) {
			return instrument?.proxies
				?.reduce((sum, el) => sum.plus(el.weight ? BigNumber(el.weight) : BigNumber(0)), BigNumber(0))
				.eq(100);
		}
		return false;
	}

	const defaultActions: FC<{ onSaveAsync(): Promise<void>; onCancel?(): void; isSaveDisabled: boolean }> = ({
		onSaveAsync,
		onCancel,
		isSaveDisabled,
	}) => (
		<div className="flex justify-between">
			<div>
				{onCancel && (
					<Button
						palette="tertiary"
						onClick={onCancel}
						data-qualifier={section ? `${qualifierBySection[section]}/Cancel` : "/Cancel"}
					>
						Cancel
					</Button>
				)}
			</div>

			<div>
				<AsyncButton
					disabled={isSaveDisabled}
					palette="primary"
					data-qualifier={section ? `${qualifierBySection[section]}/Save` : "/Save"}
					onClickAsync={onSaveAsync}
				>
					Save
				</AsyncButton>
			</div>
		</div>
	);

	return (
		<>
			<Controller
				value={controller}
				onChange={(v) => {
					setDirty((isDirty) => (isDirty === false ? true : isDirty));
					setController(v);
				}}
			>
				{function RenderMainBlock({ value: { proxied, deleted, tagged, scored }, onChange, onCommit }) {
					const instrumentsNeedsProxy = useMemo(() => {
						if (mode === "edit-proxy") {
							return false;
						}

						return composition
							.filter((x) => !deleted.has(x.ticker ?? "-"))
							.filter((x) => Boolean(x.delisted) === false)
							.some((x) => x.needsCustomProxy && !proxied.has(x.ticker ?? "-"));
					}, [deleted, proxied]);

					return (
						<>
							<EditProxiedInstrumentTable
								mode={mode}
								actions={actions}
								disabled={disabled}
								deleted={deleted}
								tagged={tagged}
								proxied={proxied}
								scored={scored}
								uuid={uuid}
								onProxiedChange={(newProxied) => {
									onChange({ deleted, proxied: newProxied, scored, tagged });
									onCommit({ deleted, proxied: newProxied, scored, tagged });
								}}
								onDeletedChange={(newDeleted) => {
									onChange({ deleted: newDeleted, proxied, scored, tagged });
									onCommit({ deleted: newDeleted, proxied, scored, tagged });
								}}
								onTagChange={(newTagged) => {
									onChange({ deleted, proxied, scored, tagged: newTagged });
									onCommit({ deleted, proxied, scored, tagged: newTagged });
								}}
								onScoreChange={(newScored) => {
									onChange({ deleted, proxied, scored: newScored, tagged });
									onCommit({ deleted, proxied, scored: newScored, tagged });
								}}
								instrumentThatNeedProxy={composition.filter(
									(row) => row.needsCustomProxy && Boolean(row.delisted) === false,
								)}
								section={section}
								instrumentThatDontNeedProxy={composition.filter((row) => !row.needsCustomProxy || row.delisted)}
							/>
							<div className={`h-px mb-4 w-full bg-[${themeCSSVars.palette_N200}]`} />
							{renderNodeOrFn(customActions ?? defaultActions, {
								isSaveDisabled: disabled || instrumentsNeedsProxy,
								onCancel,
								onSaveAsync: async () => {
									if (mode === "edit-proxy") {
										await onSaveAsync(
											composition
												.filter((x) => !deleted.has(x.ticker ?? "-"))
												.map((x) => ({
													...x,
													...(proxied.get(x.ticker ?? "-") ?? {}), // TODO: test
												})),
										);
										return;
									}

									await onSaveAsync(
										composition
											.filter((x) => !deleted.has(x.ticker ?? "-"))
											.filter((x) => Boolean(x.delisted) === false)
											.map((x) => ({
												...x,
												...(proxied.get(x.ticker ?? "-") ?? {}), // TODO: test
												tagLabel: tagged.get(x.ticker ?? "-") ?? undefined,
												score: scored.get(x.ticker ?? "-") ?? undefined,
											})),
									);
								},
							})}
						</>
					);
				}}
			</Controller>
			<LeavePrompt title={t("LEAVE_PAGE")} when={dirty && enableLeavePrompt === true} pathToNotBlock={pathToNotBlock}>
				{t("EDIT_COMPOSITION.LEAVE")}
			</LeavePrompt>
		</>
	);
}
