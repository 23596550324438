import type { InvestmentExportConverterType, InvestmentImportConverterType, ServiceType } from "$root/api/api-gen";
import { configReportPlatformError } from "$root/api/error-reporting";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { initServerEvents } from "$root/event-bus/server-events";
import { initHotjarCUser } from "$root/third-party-integrations/initHotjar";
import initIntercom, { disposeIntercomUser, initIntercomCUser } from "$root/third-party-integrations/initIntercom";
import initIubendaPriv from "$root/third-party-integrations/initIubendaPriv";
import { configMixPanel, initMixPanelCUser } from "$root/third-party-integrations/initMixPanel";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";

export const UserRole = {
	ROOT: "ROOT",
	guest: "guest",
	manager: "manager",
	admin: "admin",
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export interface IUser {
	id: string;
	email: string;
	signedIn: boolean;
	name?: string;
	surname?: string;
	customerId?: string;
	token?: string;
	availableServices: ServiceType[];
	roles: UserRole[];
	impersonating?: boolean; // Used To check if user is impersonating and disable TrackingService
	customFields?: { [key: string]: object };
	customerName: string;
	importFormats?: Array<InvestmentImportConverterType>;
	exportFormats?: Array<InvestmentExportConverterType>;
	importEnabled?: boolean;
	exportEnabled?: boolean;
	automaticImport?: boolean;
}

// TODO: set guest services
export const guestServices: ServiceType[] = [];
export const guestUser: IUser = {
	id: "",
	email: "",
	signedIn: false,
	token: undefined,
	availableServices: guestServices,
	customerName: "",
	roles: [],
	importFormats: undefined,
	automaticImport: false,
	customerId: undefined,
};

let serverSideEventsRef: { close(): void } | undefined;
export const useUserStore = createPersistentAtom<IUser>(guestUser, "user", {
	onSet: (newUser) => {
		// Init Signed Chat Intercom
		disposeIntercomUser();
		if (newUser.signedIn) {
			initIubendaPriv();
			if (hasAccess(newUser, { requiredService: "HELP_CENTER" })) {
				initIntercom();
				initIntercomCUser(newUser);
			}
		}

		configReportPlatformError({
			uid: newUser.signedIn ? newUser.email : null,
		});
		// Configure and Init Signed Mixpanel
		configMixPanel({
			uid: newUser.email,
			impersonating: newUser.impersonating,
			isInternal: newUser.email ? newUser.email.toLowerCase().includes("mdotm") : false,
		});
		initMixPanelCUser(newUser);

		// Init Signed Hotjar
		initHotjarCUser(newUser);

		// Init Server-Side events
		serverSideEventsRef?.close();
		serverSideEventsRef = undefined;

		if (newUser.signedIn) {
			serverSideEventsRef = initServerEvents({ authToken: newUser.token! });
		}
	},
});
export function useUserValue(): IUser {
	return useUserStore((x) => x.value);
}
export function getCurrentUser(): IUser {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useUserStore((x) => x.value);
}
export function useUserState(): { user: IUser; setUser: (newValue: IUser | Updater<IUser>) => void } {
	const { value: user, set: setUser } = useUserStore();
	return { user, setUser };
}
